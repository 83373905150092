<script>
import { CancelToken, isCancel } from 'axios';
import tap from 'lodash/tap';
import isEqual from 'lodash/isEqual';
import axios from '@/util/axios';

export default {
    props: {
        debounceWait: {
            type: Number,
            default: 500
        },

        filter: {
            type: Object,
            default: () => {
                return {};
            }
        },

        mapper: {
            type: Function,
            default: (items) => {
                return items;
            }
        },

        sort: {
            type: Object,
            default: () => {
                return {};
            }
        },

        predicates: {
            type: Array,
            default: () => {
                return [];
            }
        },

        source: {
            type: String,
            required: true
        },

        withPreflight: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            debounceTimer: null,
            items: [],
            nextPage: 1,
            requestSource: null,
            total: 0,
            loading: false
        };
    },

    computed: {
        loadParameters () {
            return {
                filter: this.filter,
                predicates: this.predicates,
                sort: this.sortDescriptor
            };
        },

        sortDescriptor () {
            if (this.sort.property == null) {
                return null;
            }

            return `${this.sort.type === 'desc' ? '-' : '+'}${this.sort.property}`;
        }
    },

    watch: {
        loadParameters: {
            deep: true,
            handler () {
                clearTimeout(this.debounceTimer);

                this.debounceTimer = setTimeout(() => {
                    this.reset();
                }, this.debounceWait);
            }
        },

        source () {
            this.reset();
        }
    },

    created () {
        if (this.withPreflight) {
            this.submitRequest();
        }
    },

    methods: {
        buildRequest () {
            this.issueRequestSource();

            return axios.get(this.source, {
                cancelToken: this.requestSource.token,
                params: {
                    page: this.nextPage,
                    ...this.loadParameters
                }
            });
        },

        issueRequestSource () {
            // Revoke the latest request source and cancel the latest request
            this.requestSource?.cancel();

            this.requestSource = CancelToken.source();
        },

        async loadNextPage ($state) {
            try {
                this.loading = true;

                const { data } = await this.submitRequest();

                if (data.data.length === 0) {
                    $state.complete();
                    return;
                }

                this.items.push(...this.mapper(data.data));

                this.nextPage += 1;
                this.total = data.meta?.total || data.total;

                $state.loaded();
            } catch (error) {
                if (!isCancel(error)) {
                    throw error;
                }
            } finally {
                this.loading = false;
            }
        },

        reset () {
            // Prevent scheduled resets because a reset is happening at this very moment
            clearTimeout(this.debounceTimer);

            this.items = [];
            this.nextPage = 1;

            this.$emit('reset');
        },

        itemDeleted (deletedItem) {
            this.items = this.items.filter((item) => {
                return !isEqual(item, deletedItem);
            });
        },

        async submitRequest () {
            return tap(await this.buildRequest(), (response) => {
                this.$emit('received-response', response);
            });
        }
    },

    render () {
        return this.$scopedSlots.default({
            items: this.items,
            loadNextPage: this.loadNextPage,
            total: this.total,
            itemDeleted: this.itemDeleted,
            loading: this.loading
        });
    }
};
</script>
