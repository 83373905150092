/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-transmit-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.267 2.142h-3.04M10.75 2.14v2.6M4.928 3.925l-1.08 1.072-1.08 1.07M3.86 5l1.83 1.84M1 10.37v3.02M1 11.89h2.6M2.78 17.713l1.07 1.07 1.07 1.07M3.86 18.79l1.83-1.84M18.71 6.07l-1.08-1.08-1.08-1.072M17.64 5l-1.83 1.84M11.43 13.99a.43.43 0 100 .86.43.43 0 100-.87zM9.23 8.2a1.51 1.51 0 100 3.03 1.51 1.51 0 100-3.04z"/><path pid="1" d="M17.606 9.858h0a7.157 7.157 0 00-8.89-4.83 7.142 7.142 0 00-4.83 8.88c.68 2.32 2.5 4.13 4.82 4.82M12 21.858l.3-1.19h-.01c.54-2.18 2.5-3.71 4.74-3.71h5.95"/><path pid="2" d="M19.33 20.63l3.66-3.67-3.67-3.67"/></g>'
  }
})
