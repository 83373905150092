/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chair': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>seating table</desc><g _fill="currentColor"><path pid="0" d="M17.715 16.994a.246.246 0 00-.206-.032c-.439.124-.921.247-1.446.361a.25.25 0 00-.2.275l.643 5.272a1 1 0 00.99.88.487.487 0 00.12-.01.987.987 0 00.87-1.11l-.665-5.458a.25.25 0 00-.106-.178zM7.937 17.323a23.835 23.835 0 01-1.446-.361.25.25 0 00-.317.21L5.51 22.63a.987.987 0 00.869 1.11.5.5 0 00.121.01 1 1 0 00.99-.88l.642-5.27a.249.249 0 00-.195-.277zM20.342 13.081a1.5 1.5 0 00-2.008-.675c-.006 0-.147.07-.4.174a.5.5 0 01-.685-.4l-.475-3.813a.5.5 0 01.233-.487 2.5 2.5 0 001.162-2.48l-.306-2.141A3.518 3.518 0 0014.4.25H9.6a3.518 3.518 0 00-3.463 3.005L5.831 5.4a2.5 2.5 0 001.162 2.477.5.5 0 01.233.488l-.475 3.813a.5.5 0 01-.685.4c-.252-.1-.393-.17-.4-.173a1.5 1.5 0 00-1.336 2.687A18.735 18.735 0 0012 16.75a18.743 18.743 0 007.671-1.658 1.5 1.5 0 00.671-2.011zm-5.422.38a15.418 15.418 0 01-2.92.289 15.4 15.4 0 01-2.92-.289.5.5 0 01-.4-.552l.52-4.221a.5.5 0 01.5-.438h4.6a.5.5 0 01.5.438l.523 4.221a.5.5 0 01-.403.552z"/></g>'
  }
})
