/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-symptoms-virus-headache-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.32 9.43a3.45 3.45 0 100 6.9 3.45 3.45 0 100-6.91zM11.507 23.25h0a5.184 5.184 0 00-5.26-5.11 5.17 5.17 0 00-5.11 5.1M18.16 12.92a2.67 2.67 0 100 5.35 2.67 2.67 0 100-5.36zM17.72 10.91h.89M18.17 10.91v2.01M21.17 11.97l.63.63M21.48 12.29l-1.42 1.42M22.85 15.16v.89M22.85 15.6h-2M21.8 18.6l-.63.63M21.48 18.92l-1.42-1.42M18.61 20.29h-.89M18.17 20.29v-2.01M15.17 19.23l-.63-.63M14.85 18.92l1.42-1.42M13.48 16.05v-.89M13.48 15.6h2.01M14.54 12.6l.63-.63M14.85 12.29l1.42 1.42M2.83 7.462L1.56 4.311l1.84.38-1.27-3.16M6.391 6.47l.05-3.4 1.54 1.06.05-3.4M10.413 7.462l1.24-3.162 1.07 1.54 1.24-3.17"/></g>'
  }
})
