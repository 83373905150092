/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-warning-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 20.25h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M17.25 18v-3"/><path pid="1" d="M23.063 20.682l-.01-.01c.43.87.08 1.94-.8 2.38-.25.12-.52.18-.8.18h-8.46v-.001c-.98 0-1.78-.8-1.78-1.78-.01-.28.06-.55.18-.8l4.22-8.451v-.01a1.76 1.76 0 012.37-.8c.34.17.62.45.79.79zM4.183 12.754H2.162h0a1.42 1.42 0 01-1.38-1.46 1.4 1.4 0 011.37-1.38h2.02M9.929 4.183V2.162h-.01c.02-.79.66-1.4 1.45-1.38.75.01 1.35.62 1.37 1.37v2.02"/><path pid="2" d="M15.4 5.28l-.01-.01a7.295 7.295 0 00-8.13 0M18.28 9.1h0c-.21-.65-.51-1.26-.89-1.83M7.277 17.4h0c.6.4 1.26.71 1.95.92M5.28 7.277h-.01a7.279 7.279 0 000 8.12"/><path pid="3" d="M6.848 9.511h0c-.01-.43-.17-.84-.48-1.14l-1.86-1.86h0a1.4 1.4 0 010-2 1.4 1.4 0 012 0l1.85 1.86h0c.3.3.71.47 1.13.47M9.511 15.835h0c-.43-.01-.84.16-1.14.47l-1.86 1.85v-.01c-.56.55-1.45.55-2 0a1.41 1.41 0 01-.01-2l1.859-1.86v-.01c.3-.31.47-.71.47-1.14M13.172 6.848h-.01c.42-.01.83-.17 1.13-.48l1.86-1.859h-.01a1.41 1.41 0 012-.01c.55.55.55 1.44 0 2l-1.87 1.85"/></g>'
  }
})
