/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flag-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 23.25V.75M.75 17.708l3.154-.97h-.01c2.64-.82 5.5-.45 7.86.99l-.01-.01a9.613 9.613 0 007.67 1.06l2.98-.86v-.01c.48-.14.81-.58.81-1.09V5.08h0a1.135 1.135 0 00-1.44-1.09l-2.37.67h-.01c-2.6.74-5.39.35-7.68-1.06h0a9.642 9.642 0 00-7.87-1.01l-3.16.96"/></g>'
  }
})
