import numeral from 'numeral';

export default {
    methods: {
        formatNumber (number, format) {
            return numeral(number).format(format || '0,0');
        },

        formatCurrency (price, cents) {
            return numeral((cents && price > 0) ? price / 100 : price).format('$0,0[.]00');
        }
    }
};
