<template>
    <app-modal
        :value="value"
        title="Add Stripe Fees"
        @input="$emit('input', $event)"
    >
        <form-field-wrapper label="Country" :error="form.errors.get('country_code')">
            <country-picker v-model="form.country_code"></country-picker>
        </form-field-wrapper>

        <form-field-wrapper label="Fixed Fee" :error="form.errors.get('fee_fixed')">
            <input
                v-model.number="form.fee_fixed"
                type="number"
                class="form-field"
                min="0"
                step="0.01"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Percentage Fee" :error="form.errors.get('fee_percentage')">
            <input
                v-model.number="form.fee_percentage"
                type="number"
                class="form-field"
                min="0"
                max="100"
                step="0.01"
            >
        </form-field-wrapper>

        <template #footer="{ close }">
            <stateful-button
                class="button-primary"
                :loading="form.processing"
                @click="store(close)"
            >
                Add
            </stateful-button>

            <button class="button" @click="close">Cancel</button>
        </template>
    </app-modal>
</template>

<script>
import Form from '@/validation/Form';

export default {
    name: 'CreateStripeFeesModal',

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            form: new Form({
                country_code: '',
                fee_fixed: null,
                fee_percentage: null
            })
        };
    },

    watch: {
        value: {
            handler () {
                this.form.restore();
            },
            immediate: true
        }
    },

    methods: {
        store (closeFunction) {
            this.form.post(this.route('api.admin.stripe-fees.store'))
                .then(({ data }) => {
                    this.$toasted.global.success('Stripe fees have been added.');

                    this.$emit('create-stripe-fees', data);

                    closeFunction();
                });
        }
    }
};
</script>
