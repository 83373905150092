/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dessert-ice-cream-popsicle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 23.25v-6M13.5 3h0c-.01-.67.12-1.34.37-1.96h0a5.999 5.999 0 00-7.58 3.82c-.2.6-.31 1.23-.31 1.87v9h0c0 .82.67 1.49 1.5 1.49h9-.01c.82 0 1.5-.68 1.5-1.5V8.17l-.01-.01a5.262 5.262 0 01-4.51-5.2z"/></g>'
  }
})
