/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'money-wallet-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>finance money wallet</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.75 18v3c0 .82-.68 1.5-1.5 1.5H3l-.01-.001C1.74 22.48.74 21.48.74 20.23s0 0 0 0V3.73h0a2.24 2.24 0 012.24-2.26h15.75-.01c.82-.01 1.5.67 1.5 1.5v2.25"/><path pid="1" d="M21.75 18h-.01c.82 0 1.5-.68 1.5-1.5V12v0c0-.83-.68-1.5-1.5-1.5h-3.76c-2.08 0-3.75 1.67-3.75 3.75a3.73 3.73 0 003.75 3.74z"/><path pid="2" d="M18 13.87h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38v-.001M21.75 10.5V6.75v0c0-.83-.68-1.5-1.5-1.5H5.625v-.01a1.77 1.77 0 01-1.62-.9"/></g>'
  }
})
