<template>
    <accordion-list v-if="isReadyForModifications" class="my-8">
        <accordion-list-item item-id="advanced-settings">
            <template slot="header">Advanced Settings</template>

            <template slot="content">
                <form-field-wrapper label="Fields" class="mt-8">
                    <div class="flex flex-col space-y-2">
                        <toggle-switch
                            small
                            disabled
                            :value="settings.includeStreetAddressField"
                            @input="newValue => updateSetting('includeStreetAddressField', newValue)"
                        >
                            Street Address
                        </toggle-switch>

                        <toggle-switch
                            small
                            :value="settings.includeSecondStreetAddressField"
                            @input="newValue => updateSetting('includeSecondStreetAddressField', newValue)"
                        >
                            Street Address 2
                        </toggle-switch>

                        <toggle-switch
                            :value="settings.includeCityField"
                            small
                            @input="newValue => updateSetting('includeCityField', newValue)"
                        >
                            City
                        </toggle-switch>

                        <toggle-switch
                            :value="settings.includeStateField"
                            small
                            @input="newValue => updateSetting('includeStateField', newValue)"
                        >
                            State
                        </toggle-switch>

                        <toggle-switch
                            :value="settings.includeZipCodeField"
                            small
                            @input="newValue => updateSetting('includeZipCodeField', newValue)"
                        >
                            Zip Code
                        </toggle-switch>

                        <toggle-switch
                            :value="settings.includeCountryField"
                            small
                            @input="newValue => updateSetting('includeCountryField', newValue)"
                        >
                            Country
                        </toggle-switch>

                        <toggle-switch
                            :value="settings.includePhoneNumberField"
                            small
                            @input="newValue => updateSetting('includePhoneNumberField', newValue)"
                        >
                            Phone Number
                        </toggle-switch>

                        <toggle-switch
                            :value="settings.includeOccupationField"
                            small
                            @input="newValue => updateSetting('includeOccupationField', newValue)"
                        >
                            Occupation
                        </toggle-switch>

                        <toggle-switch
                            :value="settings.includeEmployerField"
                            small
                            @input="newValue => updateSetting('includeEmployerField', newValue)"
                        >
                            Employer
                        </toggle-switch>

                        <toggle-switch
                            :value="settings.includeIndustryField"
                            small
                            @input="newValue => updateSetting('includeIndustryField', newValue)"
                        >
                            Industry
                        </toggle-switch>
                    </div>
                </form-field-wrapper>

                <form-field-wrapper v-if="showStateFieldOptions" label="State Field">
                    <select-list :value="settings.stateField" @input="updateSetting('stateField', $event)">
                        <select-option id="textBox" class="my-2">
                            Text Box
                        </select-option>

                        <select-option id="usStates" class="my-2">
                            U.S. States Dropdown
                        </select-option>
                    </select-list>
                </form-field-wrapper>

                <form-field-wrapper v-if="showDefaultCountryPicker" label="Default Country">
                    <country-picker
                        item-value="name"
                        :value="settings.defaultCountry"
                        @input="updateSetting('defaultCountry', $event)"
                    ></country-picker>
                </form-field-wrapper>
            </template>
        </accordion-list-item>
    </accordion-list>
</template>

<script>
import CustomQuestionSettings from '@/mixins/CustomQuestionSettings';

export default {
    name: 'FormDonorInformationQuestionSettings',

    mixins: [
        CustomQuestionSettings
    ],

    computed: {
        defaultSettings () {
            return {
                defaultCountry: null,
                includeCityField: true,
                includeCountryField: true,
                includeEmployerField: true,
                includeIndustryField: true,
                includeOccupationField: true,
                includePhoneNumberField: true,
                includeSecondStreetAddressField: true,
                includeStateField: true,
                includeStreetAddressField: true,
                includeZipCodeField: true,
                stateField: 'textBox'
            };
        },

        isReadyForModifications () {
            return Object.keys(this.settings).length > 0;
        },

        namespace () {
            return 'donorInformation';
        },

        showDefaultCountryPicker () {
            return this.settings.includeCountryField && this.settings.stateField !== 'usStates';
        },

        showStateFieldOptions () {
            return this.settings.includeStateField;
        }
    },

    watch: {
        'settings.includeCountryField': function (value) {
            if (value) {
                this.settings.stateField = 'textBox';
            }
        },

        'settings.stateField': function (value) {
            this.settings.includeCountryField = value === 'textBox';
        }
    }
};
</script>
