/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-lab-research-magnifier-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.017.75h-3.04M10.5.75v2.6M4.678 2.533L3.606 3.606 2.533 4.678M3.61 3.61l1.83 1.83M.75 8.983v3.02M.75 10.5h2.6M2.533 16.322l1.073 1.07 1.072 1.07M3.61 17.39l1.83-1.83M18.467 4.678l-1.08-1.072-1.08-1.073M17.39 3.61l-1.83 1.83M8.98 6.81a1.51 1.51 0 100 3.03 1.51 1.51 0 100-3.04z"/><path pid="1" d="M17.435 8.755l-.01-.01c-.97-3.83-4.85-6.16-8.68-5.2s-6.16 4.84-5.2 8.67a7.125 7.125 0 004.93 5.12"/><path pid="2" d="M16.26 11.37a4.89 4.89 0 100 9.78 4.89 4.89 0 100-9.78zM23.25 23.25l-3.53-3.53"/></g>'
  }
})
