/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>marker gps</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 4.5a3 3 0 100 6 3 3 0 100-6z"/><path pid="1" d="M12 .75h-.01c3.72-.01 6.75 3.02 6.75 6.75 0 3.25-5.132 10.52-6.45 12.337h-.01c-.13.16-.36.2-.53.08-.04-.03-.06-.06-.09-.09-1.32-1.81-6.45-9.09-6.45-12.34h0C5.2 3.75 8.23.72 11.95.72z"/><path pid="2" d="M17.979 17.784c2.73.54 4.52 1.44 4.52 2.46 0 1.657-4.7 3-10.5 3s-10.5-1.343-10.5-3c0-1.02 1.78-1.93 4.5-2.47"/></g>'
  }
})
