/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-french-fries-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>fast</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M2.73 18.33h18.54M8.73 14.844L7.04 2.3h0c-.05-.42.24-.79.65-.84 0-.01 0-.01.01-.01l2.3-.29h-.01c.41-.06.8.22.86.64l1.82 13.57M3.26 9.331l-.81-3.5-.01-.01c-.09-.38.11-.75.48-.88l4.26-1.57M14.64 14.9l1.69-12.6h-.01a.762.762 0 00-.66-.84c-.01-.01-.01-.01-.02-.01l-2.308-.29h0a.776.776 0 00-.88.64l-.79 5.89"/><path pid="1" d="M20.125 9.33l.8-3.5h-.01a.757.757 0 00-.48-.88l-4.27-1.57"/><path pid="2" d="M23.07 9.626h0c-.16-.19-.4-.3-.64-.31h-3.04 0a.757.757 0 00-.77.63v-.01a6.74 6.74 0 01-6.88 5.5v-.01a6.714 6.714 0 01-6.87-5.5h0a.764.764 0 00-.77-.64H1.52c-.25 0-.49.11-.64.3v-.01c-.16.18-.22.43-.17.66l2.9 11.91-.01-.01c.08.36.42.62.79.61h15.04l-.01-.01c.37 0 .71-.26.8-.62l2.9-11.918v-.01a.77.77 0 00-.17-.67z"/></g>'
  }
})
