/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music-clef-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.73 18.17h0c.16.86.28 1.74.35 2.63h0a2.37 2.37 0 01-2.3 2.44h-.16c-1.36 0-2.45-1.1-2.45-2.45-.01-.01 0-.01 0-.01"/><path pid="1" d="M11.418 15.52l-.01-.01c-.58-.93-.3-2.16.63-2.74.31-.2.66-.3 1.03-.31 1.08 0 1.95.97 1.95 2.44v0c0 1.89-1.54 3.42-3.43 3.42a3.43 3.43 0 01-3.43-3.43c0-2.7 2.03-4.25 4.89-5.381 3.47-1.38 3.42-8.8.97-8.8s-2.94 4.4-2.94 6.848h0c.12 1.79.35 3.58.7 5.34"/></g>'
  }
})
