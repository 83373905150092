/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>meeting stream camera</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="15" height="13.5" x=".75" y="5.261" rx="3"/><path pid="1" d="M18.75 15.011l3.41 1.7-.01-.01c.37.18.82.03 1-.34.05-.11.07-.22.07-.34V7.93c0-.42-.34-.75-.75-.75a.651.651 0 00-.34.07l-3.42 1.7"/></g>'
  }
})
