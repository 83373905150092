/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-polo-shirt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6 8.25V22.5h0c0 .41.33.74.75.74h10.5v-.001c.41-.01.74-.34.74-.75V8.23"/><path pid="1" d="M18 12.75h3.75-.01c.41 0 .75-.34.75-.75V6.75v0c0-3.32-2.69-6-6-6h-1.5v0c0 1.65-1.35 3-3 3-1.66 0-3-1.35-3-3H7.48c-3.32 0-6 2.68-6 6 0 0 0 0 0 0V12h0c0 .41.33.74.75.74h3.75M12 4.5v3.75"/><path pid="2" d="M9 .75L7.9 5.16h-.01c-.11.4.14.8.54.9.17.04.35.02.51-.06l3.03-1.53M15 .75l1.1 4.418-.01-.01c.1.4-.15.8-.55.9-.18.04-.36.02-.52-.06l-3.04-1.53"/></g>'
  }
})
