<template>
    <base-dropdown
        ref="dropdown"
        class="inline-block"
        trigger-class="flex items-center"
        :position-fixed="false"
        placement="bottom"
    >
        <template slot="trigger" slot-scope="{ triggerFunction }">
            <button
                class="relative inline-block h-full text-snow-700 mr-2 cursor-pointer px-1"
                @click="triggerFunction"
            >
                <div
                    class="w-4 h-4 rounded-md border border-snow-300"
                    :style="styles.button"
                ></div>
            </button>
        </template>

        <template #default="{ triggerFunction }">
            <div>
                <color-picker
                    ref="colorPicker"
                    :value="selectedColor"
                    class="absolute mt-2 z-90"
                    :disable-alpha="!hasAlpha"
                    @input="pickedColor($event)"
                ></color-picker>
            </div>
        </template>
    </base-dropdown>
</template>

<script>
import { Sketch as ColorPicker } from 'vue-color';

export default {
    name: 'EditorColorPickerButton',

    components: { ColorPicker },

    props: {
        editor: {
            type: Object,
            required: true
        },

        isMenuActive: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            ignoreNextPickedColorEvent: false,
            selectedColor: { hex: null },
            hasAlpha: true,
            showPicker: false
        };
    },

    computed: {
        styles () {
            return {
                button: {
                    'background-color': this.selectedColor.hex
                        ? `#${this.selectedColor.hex}`
                        : 'transparent'
                }
            };
        },

        defaultColorHex () {
            return '525252';
        }
    },

    watch: {
        isMenuActive (newVal) {
            if (newVal) {
                const color = this.getMarkColor();

                this.selectedColor.hex = color
                    ? color.substr(1) // Remove the # from the color
                    : this.defaultColorHex;

                this.ignoreNextPickedColorEvent = true;

                this.$refs.colorPicker.colorChange({
                    hex: this.selectedColor.hex,
                    source: 'hex'
                });
            }
        }
    },

    methods: {
        getMarkColor () {
            // Needs to be overriden in inherited components
            return undefined;
        },

        setMarkColor (color) {
            // Needs to be overriden in inherited components
        },

        pickedColor (color) {
            if (this.ignoreNextPickedColorEvent) {
                this.ignoreNextPickedColorEvent = false;

                return;
            }

            const previouslyActiveElement = document.activeElement;
            const shouldRestoreFocus = previouslyActiveElement.classList.contains('vc-input__input');

            this.setMarkColor(color);

            /** Restore focus to the color picker afterwards */
            if (shouldRestoreFocus) {
                previouslyActiveElement.focus();
            }
        }
    }
};
</script>
