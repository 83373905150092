/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-increase-rate-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9 10.42a4.57 4.57 0 100 9.14 4.57 4.57 0 100-9.15zM8.24 7h1.52M9 7v3.43M14.12 8.8l1.08 1.08M14.66 9.34l-2.43 2.43M17 14.24v1.52M17 15h-3.43M15.2 20.12l-1.08 1.08M14.66 20.66l-2.43-2.43M9.76 23H8.24M9 23v-3.43M3.88 21.2L2.8 20.12M3.34 20.66l2.43-2.43M1 15.76v-1.52M1 15h3.43M2.8 9.88L3.88 8.8M3.34 9.34l2.43 2.43M17 4l3-3 3 3M20 1v7M20 12v3M20 19v3"/></g>'
  }
})
