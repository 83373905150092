/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-protection-sanitizer-spray-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.9 15.23h0c1.11 1.53.77 3.67-.76 4.79a3.446 3.446 0 01-5.41-3.41M23.12 16.68v1.14M23.12 17.25h-2.57M21.77 21.09l-.81.81M21.37 21.49l-1.82-1.82M17.7 23.25h-1.15M17.12 23.25v-2.57M13.29 21.9l-.81-.81M12.88 21.49l1.82-1.82M11.12 17.82v-1.14M11.12 17.25h2.58M5.537 10.213h-.01c-.59.58-1.54.57-2.13-.02L1.28 8.05h0c-.59-.59-.58-1.54.01-2.13v-.01L4.4 2.83v-.01c.28-.28.66-.44 1.06-.44l1.5.01 2.1 2.13-.01 1.5h-.01c-.01.39-.17.77-.45 1.05z"/></g><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.62.95h1-.01c.55-.01 1 .44 1 .99v2h-3V1.93c0-.56.44-1 1-1z" transform="rotate(45.362 9.122 2.457)"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="2" d="M11.45 7.47l1.26 2.13h-.01a2.067 2.067 0 00-.82 2.83 2.072 2.072 0 002.83.81c.11-.07.22-.14.31-.22h0c1.23.9 2.98.64 3.89-.6.71-.97.72-2.29.02-3.27v-.01a2.1 2.1 0 00-.25-2.95 2.09 2.09 0 00-2.82.1h-.01c-.14.13-.26.28-.35.45l-2.12-1.29M4.87 16.18a2.12 2.12 0 100 4.25 2.12 2.12 0 100-4.26zM4.88 14.59v1.59M7.51 15.68l-1.13 1.13M8.6 18.31H7M7.51 20.94l-1.13-1.13M4.88 22.03v-1.59M2.24 20.94l1.13-1.13M1.15 18.31h1.6M2.24 15.68l1.13 1.13"/></g>'
  }
})
