import upperFirst from 'lodash/upperFirst';

export default {
    methods: {
        translatedMonthName (monthNumber) {
            const monthNames = {
                1: this.$t('label-january'),
                2: this.$t('label-february'),
                3: this.$t('label-march'),
                4: this.$t('label-april'),
                5: this.$t('label-may'),
                6: this.$t('label-june'),
                7: this.$t('label-july'),
                8: this.$t('label-august'),
                9: this.$t('label-september'),
                10: this.$t('label-october'),
                11: this.$t('label-november'),
                12: this.$t('label-december')
            };

            return upperFirst(monthNames[monthNumber]);
        }
    }
};
