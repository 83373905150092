<template>
    <div>
        <div
            :id="blockId"
            v-theme="'wall.background'"
            class="schedule-block relative p-6"
        >
            <div
                v-if="displayTitle"
                v-theme="['wall.title-color', 'wall.title-font', 'wall.title-font-size']"
                class="flex justify-center items-center flex-auto mb-6 font-bold leading-normal"
            >
                <app-icon
                    v-if="icon"
                    :name="icon"
                    class="h-8 w-8 mr-4"
                ></app-icon>

                {{ title }}
            </div>

            <transition-group
                name="event-list"
                class="event-list"
                tag="div"
            >
                <div
                    v-for="event in activeEvents"
                    :key="event.id"
                    v-theme="['wall.text', 'wall.accent-border']"
                    class="event ml-4 pl-12 pb-8 border-l-2 relative md:ml-16"
                >
                    <div
                        v-theme="['wall.accent-border', 'wall.background', 'wall.accent-icon']"
                        class="icon"
                    >
                        <app-icon
                            v-if="event.icon"
                            :name="event.icon"
                            class="h-6 w-6"
                        ></app-icon>
                    </div>

                    <div class="text-left basic-information leading-normal">
                        <p class="name font-bold">{{ event.name }}</p>
                        <p class="date">{{ event.timeAndDate }}</p>
                        <a
                            v-if="event.showLink"
                            :href="normalizeUrl(event.linkUrl)"
                            target="_blank"
                            class="inline-block"
                            rel="nofollow"
                        >
                            {{ event.linkText }}
                        </a>
                    </div>
                    <p class="text-left details mt-4 whitespace-pre-wrap">{{ event.description }}</p>
                </div>
            </transition-group>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import WallBlock from '@/mixins/WallBlock';
import NormalizesUrls from '@/mixins/NormalizesUrls';

export default {
    name: 'WallScheduleBlock',

    mixins: [
        WallBlock,
        NormalizesUrls
    ],

    props: {
        displayTitle: {
            type: Boolean,
            required: true
        },
        events: {
            type: Array,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },

    computed: {
        activeEvents () {
            return this.events.filter((event) => { return event.active; });
        }
    }
};
</script>
