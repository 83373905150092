<template>
    <div>
        <div class="mx-auto w-full max-w-4xl flex flex-col rounded bg-white p-6">
            <div v-if="plan.linked_plan" class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mb-6">
                <div
                    v-if="!wasReferred"
                    class="relative flex-1 flex flex-col border hover:border-gray-400 rounded p-4 transition"
                    :class="{
                        'border-purple-light': plan.billing_period === 'annual' || plan.billing_period === 'one-time',
                        'shadow-md': plan.billing_period === 'annual' || plan.billing_period === 'one-time'
                    }"
                >
                    <button
                        v-if="plan.billing_period !== 'annual' && plan.billing_period !== 'one-time'"
                        class="absolute inset-0 w-full h-full"
                        @click="switchBillingPeriod"
                    ></button>

                    <div class="flex items-center justify-between">
                        <div class="text-2xl font-semibold">Pay Annually</div>
                        <div class="rounded bg-green-light py-1 px-2 text-xs text-green font-medium">Save {{ annualSavings }}%</div>
                    </div>
                    <div class="text-sm text-gray-500">{{ annualPrice / 12 | currency(true) }} USD per month</div>
                </div>

                <div
                    v-tippy
                    class="relative flex-1 border rounded p-4 transition"
                    :class="{
                        'border-purple-light': plan.billing_period === 'monthly',
                        'shadow-md': plan.billing_period === 'monthly',
                        'hover:border-gray-400': !isSwitchingToAnnualOfCurrentPlan,
                        'opacity-50': isSwitchingToAnnualOfCurrentPlan
                    }"
                    :content="isSwitchingToAnnualOfCurrentPlan ? 'This is your current plan.' : null"
                >
                    <button
                        v-if="plan.billing_period !== 'monthly' && !isSwitchingToAnnualOfCurrentPlan"
                        class="absolute inset-0 w-full h-full"
                        @click="switchBillingPeriod"
                    ></button>

                    <div class="flex items-center text-sm">
                        <div class="flex-1">
                            <div class="text-2xl font-semibold">Pay Monthly</div>
                            <div class="text-sm text-gray-500">{{ monthlyPrice | currency(true) }} USD per month</div>
                        </div>

                        <p v-if="wasReferred" class="rounded bg-green-light py-1 px-2 text-xs text-green font-medium">1 Free Month Included — Referral Bonus</p>
                    </div>
                </div>
            </div>

            <div class="bg-white border rounded-md p-4 tracking-wider shadow relative">
                <div v-if="applyingCoupon" class="absolute inset-0 bg-snow-900 flex items-center justify-center rounded-md">
                    <app-icon name="loader" class="w-5 h-5 fill-current"></app-icon>
                </div>

                <div v-if="prorationCredit" class="flex mb-2 items-center">
                    <div class="grow text-sm font-bold text-gray-900">
                        Proration Credit
                    </div>
                    <div class="shrink text-sl text-gray-900 uppercase">
                        -{{ prorationCredit | currency(true) }}
                    </div>
                </div>

                <div v-if="appliedCouponAmount && !wasReferred" class="flex mb-2 items-center">
                    <div class="grow text-sm font-bold text-gray-900">
                        {{ upcomingInvoice.discount.coupon.id }} <button class="button-text button-primary" @click="removeCoupon">(Remove)</button>
                    </div>
                    <div class="shrink text-sl text-gray-900 uppercase">
                        -{{ appliedCouponAmount | currency(true) }}
                    </div>
                </div>

                <div class="flex mb-2 items-center">
                    <div class="grow text-xl font-bold text-gray-900 uppercase">
                        Due Now
                    </div>
                    <div class="shrink text-xl text-gray-900 uppercase">
                        {{ upcomingInvoice.amount_due | currency(true) }}
                    </div>
                </div>

                <p v-if="wasReferred" class="text-xs">
                    <span class="font-semibold">First month free.</span> Your account will be charged {{ plan.price | currency(true) }} USD per month starting {{ nextBillingDate }}.
                </p>

                <button
                    v-if="canApplyCoupon"
                    class="text-xs text-gray-600 block"
                    @click="showCouponInput = true"
                >Apply a promo code</button>

                <div v-if="showCouponInput" class="text-sm">
                    <div class="flex items-center">
                        <input
                            v-model="coupon"
                            class="form-field w-48"
                            type="text"
                            placeholder="Enter coupon code"
                            @keydown.enter="applyCoupon"
                        >

                        <button
                            class="button-text button-primary ml-4 w-24"
                            :disabled="applyingCoupon"
                            @click="applyCoupon"
                        >
                            Apply Coupon
                        </button>

                        <button class="button-text ml-4" @click="removeCoupon">Cancel</button>
                    </div>
                </div>

                <div v-if="couponError" class="mt-4 badge badge-error">
                    {{ couponError }}
                </div>
            </div>

            <div class="mt-6">
                <select-list v-model="selectedCard" full-option-select>
                    <select-option
                        v-if="cardLastFour"
                        id="existing"
                        class="w-full bg-white border rounded-md mb-2 p-3 tracking-wider hover:border-purple-light hover:shadow-md cursor-pointer"
                        :class="{'border-purple-light shadow-md': selectedCard === 'existing'}"
                    >
                        Use existing {{ readableCardBrand(cardBrand) }} ending in <span class="font-bold">{{ cardLastFour }}</span>
                    </select-option>

                    <select-option
                        id="new"
                        class="w-full bg-white border rounded-md mb-2 p-3 tracking-wider hover:border-purple-light hover:shadow-md cursor-pointer"
                        :class="{'border-purple-light shadow-md': selectedCard === 'new'}"
                    >
                        Use a new credit card
                    </select-option>
                </select-list>

                <div v-show="selectedCard === 'new'" class="bg-white border rounded-md p-4 w-full my-2 ">
                    <form-field-wrapper
                        label="Name On Card"
                        :should-show-error="errors.has('name')"
                        :error="errors.first('name')"
                    >
                        <input
                            v-model="billing_details.name"
                            v-validate="'required'"
                            type="text"
                            class="form-field"
                            name="name"
                        >
                    </form-field-wrapper>

                    <form-field-wrapper
                        v-if="isUserB2b"
                        :should-show-error="errors.has('name')"
                        :error="errors.first('name')"
                    >
                        <template #label>
                            <div>
                                <span>Company</span> <span class="font-xs italic font-normal lowercase">optional</span>
                            </div>
                        </template>

                        <input
                            v-model="businessName"
                            type="text"
                            class="form-field"
                            name="businessName"
                        >
                    </form-field-wrapper>

                    <form-field-wrapper :error="cardElementError" label="Credit Card Details">
                        <div ref="card" class="px-4 py-2 border rounded-md bg-white"></div>
                    </form-field-wrapper>

                    <form-field-wrapper
                        label="Billing Address"
                    >
                        <div class="w-full mb-2">
                            <input
                                v-model="billing_details.address.line1"
                                v-validate="'required'"
                                type="text"
                                class="form-field"
                                placeholder="Street"
                                name="street"
                            >

                            <div v-if="errors.has('street')" class="alert alert-error alert-sm mt-2">
                                <p class="w-full font-normal text-center">{{ errors.first('street') }}</p>
                            </div>
                        </div>

                        <div class="flex w-full mb-2">
                            <div class="flex-1 mr-2">
                                <input
                                    v-model="billing_details.address.city"
                                    v-validate="'required'"
                                    type="text"
                                    class="form-field"
                                    placeholder="City"
                                    name="city"
                                >

                                <div v-if="errors.has('city')" class="alert alert-error alert-sm mt-2">
                                    <p class="w-full font-normal text-center">{{ errors.first('city') }}</p>
                                </div>
                            </div>

                            <div class="flex-1 mr-2">
                                <input
                                    v-model="billing_details.address.postal_code"
                                    v-validate="'required'"
                                    type="text"
                                    class="form-field"
                                    placeholder="ZIP code"
                                    name="zip_code"
                                    data-vv-as="zip code"
                                >

                                <div v-if="errors.has('zip_code')" class="alert alert-error alert-sm mt-2">
                                    <p class="w-full font-normal text-center">{{ errors.first('zip_code') }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="w-full mb-2">
                            <country-picker
                                v-model="billing_details.address.country"
                                v-validate="'required'"
                                name="country"
                            ></country-picker>

                            <div v-if="errors.has('country')" class="alert alert-error alert-sm mt-2">
                                <p class="w-full font-normal text-center">{{ errors.first('country') }}</p>
                            </div>
                        </div>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="mt-2 text-xs text-gray-400">
                <template v-if="plan.autoRenews">
                    You are agreeing to a CONTINUOUS SUBSCRIPTION. You will be charged {{ plan.price | currency(true) }}
                    every {{ billingPeriodInDays }} days until you choose to cancel your subscription. The {{ plan.billing_period }} renewal amount
                    may vary based on any promotions you may have applied to this subscription. You may cancel your subscription
                    at any time to prevent future renewals. Subscription start and renewal charges are non-refundable.
                </template>

                <template v-else>
                    You will be charged {{ plan.price | currency(true) }} one-time. Your upgrade includes {{ billingPeriodInDays }}
                    of access to RSVPify’s premium services available under your selected plan or package. The one-time upgrade fee
                    is non-refundable.
                </template>

                <br><br>

                By providing your payment method details and clicking the ‘{{ payButtonText }}’ button
                as your electronic signature, you (1) authorize automatic charges to your payment method, (2) consent
                to receive bills and other communications electronically, and (3) confirm that you have opened and
                read and agree to these

                <a
                    href=" https://rsvpify.com/tos/"
                    target="_blank"
                    class="uppercase text-gray-600"
                >terms &amp; conditions</a>

                and

                <a
                    href="https://rsvpify.com/privacy/"
                    target="_blank"
                    class="uppercase text-gray-600"
                >privacy policy</a>

                and

                <a
                    href="https://rsvpify.com/pull-refund-terms/"
                    target="_blank"
                    class="uppercase text-gray-600"
                >refund policy</a>.
            </div>

            <div class="flex flex-col space-y-2 mt-4">
                <stateful-button
                    class="button button-primary w-full"
                    :loading="loading"
                    :disabled="applyingCoupon"
                    @click="submitUpgrade"
                >
                    {{ payButtonText }}
                </stateful-button>

                <a :href="route('account.upgrade.index')" class="text-xs text-gray-600 block">Cancel and go back</a>
            </div>
        </div>

        <form
            ref="upgradeForm"
            method="POST"
            :action="route('account.upgrade.complete', [planSegment.id, plan.id])"
            data-rewardful
        >
            <input
                type="hidden"
                name="_token"
                :value="csrfToken"
            >

            <input
                type="hidden"
                name="coupon"
                :value="coupon"
            >

            <input
                type="hidden"
                name="paymentMethod"
                :value="paymentMethod"
            >

            <input
                type="hidden"
                name="businessName"
                :value="businessName"
            >
        </form>
    </div>
</template>

<script>
import get from 'lodash/get';
import { DateTime } from 'luxon';
import ReadsCsrfToken from '@/mixins/ReadsCsrfToken';
import HasStripeElements from '@/mixins/HasStripeElements';
import axios from '@/util/axios';
import FormatsCreditCardBrand from '@/mixins/FormatsCreditCardBrand';
import ChecksRewardful from '@/mixins/ChecksRewardful';

export default {
    name: 'UpgradePreview',

    mixins: [
        FormatsCreditCardBrand,
        HasStripeElements,
        ReadsCsrfToken,
        ChecksRewardful
    ],

    props: {
        attemptError: {
            type: String,
            default: null
        },

        cardBrand: {
            type: String,
            default: null
        },

        cardLastFour: {
            type: String,
            default: null
        },

        isUserB2b: {
            type: [Boolean, Number],
            required: true
        },

        plan: {
            type: Object,
            required: true
        },

        planSegment: {
            type: Object,
            required: true
        },

        setupIntent: {
            type: Object,
            required: true
        },

        initialUpcomingInvoice: {
            type: Object,
            required: true
        },

        userPlan: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            applyingCoupon: false,
            businessName: '',
            billing_details: {
                name: this.auth().user().name,
                address: {
                    line1: '',
                    city: '',
                    postal_code: '',
                    country: 'US'
                }
            },
            coupon: '',
            couponError: null,
            loading: false,
            paymentMethod: null,
            selectedCard: this.cardLastFour ? 'existing' : 'new',
            showCouponInput: false,
            upcomingInvoice: this.initialUpcomingInvoice
        };
    },

    computed: {
        monthlyPrice () {
            return this.plan.billing_period === 'monthly' ? this.plan.price : this.plan.linked_plan?.price;
        },

        annualPrice () {
            return this.plan.billing_period === 'annual' || this.plan.billing_period === 'one-time' ? this.plan.price : this.plan.linked_plan?.price;
        },

        annualSavings () {
            return this.plan.billing_period === 'monthly'
                ? this.plan.savingsOverMonthly
                : this.plan.linked_plan?.savingsOverMonthly;
        },

        appliedCouponAmount () {
            return get(this.upcomingInvoice, 'total_discount_amounts.0.amount', 0);
        },

        billingPeriodInDays () {
            return this.plan.isMonthly ? '30 days' : '365 days';
        },

        canApplyCoupon () {
            return !this.showCouponInput && this.upcomingInvoice.amount_due > 0 && !this.wasReferred;
        },

        payButtonText () {
            if (!this.upcomingInvoice.amount_due) {
                return this.plan.isOneTime ? 'Start' : 'Start subscription';
            }

            const amount = this.$options.filters.currency(
                this.upcomingInvoice.amount_due,
                true
            );

            return this.plan.isOneTime ? `Pay ${amount}` : `Pay ${amount} and start subscription`;
        },

        prorationCredit () {
            return this.plan.price - this.upcomingInvoice.amount_due - this.appliedCouponAmount;
        },

        nextBillingDate () {
            if (!this.upcomingInvoice) {
                return null;
            }

            /**
             * This function follows Stripe "billing anchor date."  Stripe will attempt to always bill on the same
             * day of the month that a subscription was started.  If the day doesn't exist in the next month, it will
             * bill on the last day of the month.
             */
            const startDate = DateTime.fromMillis(this.upcomingInvoice.period_start * 1000);
            const nextDate = startDate.plus({ months: 1 });

            if (nextDate.day !== startDate.day) {
                return nextDate.endOf('month').toFormat('M/d/yyyy');
            }
            return nextDate.toFormat('M/d/yyyy');
        },

        isSwitchingToAnnualOfCurrentPlan () {
            return this.userPlan.hasAnnualPlan && this.userPlan.linked_plan_id === this.plan.id;
        }
    },

    mounted () {
        this.initializeStripeCard(this.$refs.card);
        this.applyRewardfulCoupon();

        if (this.attemptError) {
            App.alert().error(
                `We couldn't process your transaction.`,
                this.attemptError
            );
        }
    },

    methods: {
        applyRewardfulCoupon () {
            if (!this.wasReferred) {
                return;
            }

            this.applyCoupon();
        },

        switchBillingPeriod () {
            window.location.href = this.route('account.upgrade.preview', [this.planSegment, this.plan.linked_plan]);
        },

        applyCoupon () {
            this.couponError = null;
            this.applyingCoupon = true;

            const route = this.route('api.account.upgrade.upcoming-invoice', [this.planSegment, this.plan]);

            axios.post(route, { coupon: this.coupon, rewardful_referral_id: this.rewardfulReferralId })
                .then(({ data }) => {
                    this.upcomingInvoice = data;
                    this.showCouponInput = false;
                    this.coupon = data.discount.coupon.id;
                })
                .catch((error) => {
                    this.couponError = error.response.data.error;
                })
                .finally(() => {
                    this.applyingCoupon = false;
                });
        },

        removeCoupon () {
            this.showCouponInput = false;
            this.coupon = null;
            this.couponError = null;

            if (this.appliedCouponAmount) {
                this.applyCoupon();
            }
        },

        async submitUpgrade () {
            if (this.selectedCard === 'new') {
                await this.$validator.validate();

                if (this.errors.any()) {
                    return;
                }
            }

            this.loading = true;

            if (this.selectedCard === 'new') {
                const paymentMethodId = await this.confirmCardSetup(this.setupIntent.client_secret, this.billing_details);

                if (paymentMethodId) {
                    this.paymentMethod = paymentMethodId;
                } else {
                    this.loading = false;

                    return;
                }
            }

            this.$nextTick(() => {
                this.$refs.upgradeForm.submit();
            });
        }
    }
};
</script>
