/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'credit-card-share-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>shopping give pay</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 18.75c3.75 0 2.25.75 12 .75 3 0 6-.75 6.75-3.75M.75 11.14h2.38l-.01-.001c.28 0 .56-.13.74-.36l4.05-3.99 3.82.766c.54.1.9.54.66 1.5v-.01c-.1.38-.42.66-.8.72l-1.88.27H9.7a.83.83 0 00-.76.92V13.535h-.01c-.07.29-.22.55-.45.75l-2.08 1.63"/><path pid="1" d="M12 15.75h9.75l-.01-.001c.82 0 1.5-.68 1.5-1.5V5.99v0c0-.83-.68-1.5-1.5-1.5H10.48c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0V7M12.49 8.25h10.76M20.25 11.25h-1.5"/></g>'
  }
})
