<template>
    <div>
        <div v-theme="'wall.background'" class="relative pt-6">
            <div
                v-if="displayTitle"
                v-theme="['wall.title-color', 'wall.title-font', 'wall.title-font-size']"
                class="flex justify-center items-center flex-auto mb-6 font-bold leading-normal"
            >
                <app-icon
                    v-if="icon"
                    :name="icon"
                    class="h-8 w-8 mr-4"
                ></app-icon>

                {{ title }}
            </div>

            <gmap-map
                ref="map"
                :center="{ lat: 10, lng: 10 }"
                :zoom="7"
                map-type-id="terrain"
                class="w-full h-80"
            >
                <gmap-marker
                    v-for="location in activeLocations"
                    :key="location.id"
                    :position="location.address"
                    :clickable="true"
                    :draggable="false"
                ></gmap-marker>
            </gmap-map>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import WallBlock from '@/mixins/WallBlock';

export default {
    name: 'WallLocationsBlock',

    mixins: [WallBlock],

    props: {
        displayTitle: {
            type: Boolean,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        locations: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },

    computed: {
        activeLocations () {
            return this.locations.filter((location) => { return location.active && location.address !== null; });
        }
    },

    watch: {
        activeLocations () {
            this.fitMarkerBoundsOnMap();
        }
    },

    mounted () {
        this.fitMarkerBoundsOnMap();
    },

    methods: {
        fitMarkerBoundsOnMap () {
            this.$refs.map.$mapPromise.then((mapObject) => {
                const bounds = new google.maps.LatLngBounds();

                this.activeLocations.forEach((location) => {
                    bounds.extend(location.address);
                });

                this.$refs.map.fitBounds(bounds);

                if (this.activeLocations.length === 1) {
                    mapObject.setZoom(14);
                }
            });
        }
    }
};
</script>
