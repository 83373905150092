/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heart-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>love favorite</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 17.25l-4.474-4.67-.01-.01a2.648 2.648 0 01-.5-3.06v0-.01a2.647 2.647 0 013.55-1.19c.25.12.48.29.68.49l.73.73.73-.74h-.01a2.632 2.632 0 013.74 0c.2.2.36.43.49.68v0l-.01-.01c.5 1.02.3 2.25-.5 3.05z"/><path pid="1" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/></g>'
  }
})
