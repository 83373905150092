/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-patient-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 13.82a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM16.68 11.25h1.14M17.25 11.25v2.57M21.09 12.6l.81.81M21.49 13.01l-1.82 1.82M23.25 16.68v1.14M23.25 17.25h-2.57M21.9 21.09l-.81.81M21.49 21.49l-1.82-1.82M17.82 23.25h-1.14M17.25 23.25v-2.57M13.41 21.9l-.81-.81M13.01 21.49l1.82-1.82M11.25 17.82v-1.14M11.25 17.25h2.57M12.6 13.41l.81-.81M13.01 13.01l1.82 1.82M7.5.75A4.125 4.125 0 107.5 9a4.125 4.125 0 100-8.25zM.75 17.25v-.01c0-3.73 3.02-6.76 6.75-6.75.9 0 1.8.18 2.64.54"/></g>'
  }
})
