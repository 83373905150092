export default {
    methods: {
        generateMergeTags (value = '') {
            // This method is meant to be overridden in the components that are using
            // this mixin, and it needs to return string containing merge tags. Simplest
            // use case is just to pass it a string and it will return that string.
            return value;
        },

        insertMergeTag (value = null) {
            this.$emit('insert-merge-tag', this.generateMergeTags(value));
        }
    }
};
