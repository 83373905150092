<template>
    <div v-if="wasReferred" class="bg-blue font-semibold p-4 flex items-center space-x-2 text-white">
        <app-icon
            name="gift"
            class="w-4 h-4 ml-6 text-white flex-none"
        ></app-icon>
        <p>{{ affiliateName ?? 'Someone has' }} invited you to join RSVPify to simplify event planning. You'll get your first month free!</p>
    </div>
</template>

<script>
import ChecksRewardful from '@/mixins/ChecksRewardful';

export default {
    name: 'RewardfulAlert',

    mixins: [ChecksRewardful]
};
</script>
