/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wedding-cake-couple-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 23.25h22.5"/><rect pid="1" width="19.5" height="10.5" x="2.25" y="9.75" rx="1.5"/><path pid="2" d="M12 13.125v0c0 1.44-1.18 2.625-2.625 2.625a2.628 2.628 0 01-2.625-2.625h0a2.631 2.631 0 01-4.51 1.83"/><path pid="3" d="M21.75 14.961v-.01a2.644 2.644 0 01-3.72.04c-.51-.5-.79-1.18-.79-1.88v0c0 1.44-1.18 2.625-2.625 2.625a2.628 2.628 0 01-2.625-2.625M8.25.75a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM15.75.75a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM9 9.75l.78-2.367v-.01c.28-.85-.18-1.77-1.03-2.06-.85-.29-1.77.17-2.06 1.02-.12.33-.12.69 0 1.02l.78 2.36M18 9.75l-.5-3.02h0a1.781 1.781 0 00-2.03-1.48c-.77.11-1.36.71-1.48 1.47l-.5 3.02"/></g>'
  }
})
