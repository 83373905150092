<template>
    <label
        class="inline-flex items-center"
        :class="{ 'flex-row-reverse': hasLeftLabel, 'opacity-75': disabled }"
    >
        <div
            :class="switchClass"
            role="checkbox"
            tabindex="0"
            :aria-checked="isChecked"
            :aria-disabled="disabled"
            data-descriptor="toggle"
            @click="toggle"
            @keydown.space.prevent="toggle"
        ></div>

        <div
            v-if="hasSlot('default')"
            v-tippy
            class="text-gray-700"
            :class="labelSpacing"
            :content="tooltip"
            @click="toggle"
        >
            <slot></slot>
        </div>
    </label>
</template>

<script>
import SlotHelpers from '@/mixins/SlotHelpers';

export default {
    name: 'ToggleSwitch',

    mixins: [SlotHelpers],

    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        labelPosition: {
            type: String,
            default: 'right'
        },
        small: {
            type: Boolean,
            default: false
        },
        tooltip: {
            type: String,
            default: null
        },
        value: {
            type: [Boolean, Number],
            required: true
        }
    },

    computed: {
        hasLeftLabel () {
            return this.labelPosition === 'left';
        },

        hasRightLabel () {
            return this.labelPosition === 'right';
        },

        isChecked () {
            return Boolean(this.value);
        },

        labelSpacing () {
            return {
                'ml-3': this.hasRightLabel,
                'mr-3': this.hasLeftLabel
            };
        },

        switchClass () {
            return {
                switch: true,
                'is-small': this.small,
                'hover:cursor-not-allowed': this.disabled
            };
        }
    },

    methods: {
        toggle () {
            if (!this.disabled) {
                this.$emit('input', !this.value);
            }
        }
    }
};
</script>
