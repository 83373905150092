import axios from '@/util/axios';

export default {
    data () {
        return {
            isUpgradeModalVisible: false,
            isCompRequiresActionModal: false,
            isEventRequiresStripeActionModal: false,
            usedFeaturesList: []
        };
    },

    methods: {
        async getPublishabilityInfo () {
            const { data } = await axios.get(this.route('api.events.check-if-can-be-published', this.event));

            return {
                publishable: data.can_be_published,
                publishingBlocker: data.publishing_blocker,
                usedFeaturesList: data.used_features
            };
        },

        showBlockerModal ({ publishingBlocker, usedFeaturesList }) {
            if (publishingBlocker === 'beyondLimit' && usedFeaturesList && usedFeaturesList.length > 0) {
                this.$set(this, 'usedFeaturesList', usedFeaturesList);
                this.isUpgradeModalVisible = true;
                return;
            }

            if (publishingBlocker === 'compRequiresAction') {
                this.isCompRequiresActionModal = true;
                return;
            }

            if (publishingBlocker === 'paymentMethod') {
                this.isEventRequiresStripeActionModal = true;
                return;
            }

            this.$toasted.global.error('There was a problem publishing your event.');
        },

        openPrimaryBlock () {
            window.location.href = this.route('forms.edit', {
                event: this.event,
                open_primary_block_settings: true
            });
        }
    }
};
