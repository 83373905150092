<template>
    <button
        role="button"
        class="button disabled:opacity-50 disabled:cursor-not-allowed"
        :disabled="isButtonDisabled"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <div v-if="loading" class="absolute top-auto left-auto">
            <app-icon name="loader" class="w-5 h-5 fill-current"></app-icon>
        </div>

        <span :class="textStyles">
            <slot></slot>
        </span>
    </button>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isButtonDisabled () {
            return this.disabled || this.loading;
        },

        textStyles () {
            return {
                'text-transparent': this.loading
            };
        }
    }
};
</script>
