/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down-chevron-square-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sort descending</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="22.5" height="22.5" x=".75" y=".75" rx="2"/><path pid="1" d="M6.64 8.587l5.35 4.16 5.35-4.17h-.01c.36-.31.9-.25 1.2.11.1.12.17.28.19.44v2.16-.01c-.01.44-.22.86-.58 1.12l-5.62 4.37v-.01c-.33.24-.79.24-1.12 0l-5.62-4.37-.01-.01c-.36-.27-.57-.69-.58-1.13V9.06c.05-.48.47-.81.94-.76.16.01.32.08.44.19z"/></g>'
  }
})
