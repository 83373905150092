/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-pay-dollar-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M3.81 16.125l2.33-1.05h-.01c.47-.32.79-.81.88-1.37l.36-2.2 3.151-2.26h-.01c.26-.2.42-.51.42-.84v-.58l-.01-.01a1.035 1.035 0 00-1.38-.97L5.33 8.33v-.01c-1.06.37-2.04.93-2.89 1.64L.66 11.43M.75 21.75l2.3-1.69h-.01a2.8 2.8 0 011.69-.57h6.5M11.25 19.5h3.59c.43 0 .82-.27.97-.68l1.83-5.33M13.329 9.344h0c.43.57 1.13.9 1.85.87 1.13 0 2.06-.7 2.06-1.55 0-.86-.93-1.55-2.07-1.55-1.14 0-2.07-.7-2.07-1.548 0-.86.92-1.55 2.06-1.55v-.001c.72-.03 1.41.29 1.85.87M15.19 10.22v1.03M15.19 3v1.03"/><path pid="1" d="M13.5 16.5h-2.26c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v0h0c0 .82.67 1.49 1.5 1.49M18 13.5h-4.51c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v0h0c0 .82.67 1.49 1.5 1.49h3.11M18.75 2.25h3-.01c.82-.01 1.5.67 1.5 1.5V12c0 .82-.68 1.5-1.5 1.5H10.49M5.25 5.25v-1.5h0c-.01-.83.67-1.51 1.49-1.51h5.25"/></g>'
  }
})
