/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transportation-airplane-flying-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M20.506 8.25L5.24 8.27l-1.1-2.2-2.3-.1h-.01c-.61 0-1.1.49-1.1 1.1 0 .14.03.28.08.42l2.067 4.66-.01-.01c.34.8 1.13 1.33 2.01 1.33h4.04L6.43 18.63h-.01c-.28.53-.07 1.18.47 1.46.15.07.32.12.49.11h2.1-.01c.32-.01.63-.15.84-.4l5.15-6.36h4.95l-.01-.01c1.44.07 2.67-1.05 2.74-2.49v-.11h0a2.687 2.687 0 00-2.69-2.67h-.06z"/><path pid="1" d="M15.72 8.209l-3-4.02-.01-.01c-.21-.28-.54-.44-.88-.44H9.75c-.61 0-1.1.49-1.1 1.1 0 .16.03.33.11.48l1.43 2.91"/></g>'
  }
})
