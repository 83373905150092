<template>
    <div>
        <fetch-infinite
            ref="fetcher"
            v-slot="{ items, loadNextPage }"
            :mapper="mapperPassthrough"
            :predicates="allPredicates"
            :sort="sort"
            :source="source"
            :with-preflight="withPreflight"
            @received-response="response => $emit('received-response', response)"
            @reset="resetLazyLoading"
        >
            <data-table
                ref="table"
                clickable-rows
                :columns="chosenColumns"
                :grouped="inGroupView"
                :has-select-all="hasSelectAll"
                :is-selected-function="isSelectedFunction"
                lazy-load-data
                :no-results-placeholder="emptyPlaceholder"
                remote-sort
                :rows="items"
                :selectable="selectable"
                :total="totalItemCount"
                @change-sort="updateSort"
                @click-row="clickedRow => $emit('click-row', clickedRow)"
                @row-selected="({ row, isSelected }) => $emit('row-selected', { row, isSelected })"
                @scroll-end="loadNextPage"
            >
                <template #header="{ selectedAll, selectedIds }">
                    <slot
                        name="header"
                        :all-predicates="allPredicates"
                        :available-columns="availableColumns"
                        :chosen-columns="chosenColumns"
                        :grouped="inGroupView"
                        :predicates="predicates"
                        :reload="reload"
                        :reset="reset"
                        :search-query="searchQuery"
                        :selected-all="selectedAll"
                        :selected-ids="selectedIds"
                        :set-chosen-columns="(newChosenColumns) => chosenColumns = newChosenColumns"
                        :set-predicates="(newPredicates) => predicates = newPredicates"
                        :set-search-query="(newSearchQuery) => searchQuery = newSearchQuery"
                    ></slot>
                </template>

                <template #row="{ grouped, property, row, rowIndex }">
                    <guest-check-in-state v-if="property === 'checkInState'" :state="row[property]"></guest-check-in-state>

                    <template v-else-if="isOrderColumn(property)">
                        <template v-if="shouldShowOrderColumn(grouped, rowIndex)">{{ row[property] }}</template>

                        <!-- This empty template is required to prevent Vue from displaying the raw value instead of nothing -->
                        <template v-else>&nbsp;</template>
                    </template>

                    <template v-else-if="property === 'reply'">
                        <!-- This empty template is required to prevent Vue from displaying the raw value instead of nothing -->
                        <template v-if="!row.reply.state">&nbsp;</template>

                        <div v-else-if="row.reply.state === 'NotAttending'" class="flex items-center space-x-2">
                            <app-icon
                                name="close-circle"
                                class="text-red min-h-5 min-w-5"
                            ></app-icon>

                            <span>{{ event.declineTitle }}</span>
                        </div>

                        <div v-else class="flex items-center">
                            <guest-reply-icon
                                class="mr-2 min-h-5 min-w-5"
                                :state="row.reply.state"
                                colored
                            ></guest-reply-icon>

                            <span>{{ row.reply.default }}</span>
                        </div>
                    </template>

                    <slot
                        v-else-if="property === 'summary'"
                        name="summary"
                        :grouped="grouped"
                        :row="row"
                        :row-index="rowIndex"
                    ></slot>

                    <slot
                        v-else
                        name="row"
                        :grouped="grouped"
                        :property="property"
                        :row="row"
                    >{{ row[property] }}</slot>
                </template>
            </data-table>
        </fetch-infinite>
    </div>
</template>

<script>
import { cloneDeep, find, get as getValue } from 'lodash';
import { get } from 'vuex-pathify';
import EventTimezone from '@/mixins/EventTimezone';

export default {
    name: 'DataColumns',

    mixins: [
        EventTimezone
    ],

    props: {
        customColumnDefinitions: {
            type: Array,
            default: () => { return []; }
        },

        defaultColumns: {
            type: Array,
            required: true
        },

        emptyPlaceholder: {
            type: String,
            default: 'Nothing to see here.'
        },

        groupable: {
            type: Boolean,
            default: true
        },

        hasSelectAll: {
            type: Boolean,
            default: true
        },

        initialPredicates: {
            type: Array,
            default: () => {
                return [];
            }
        },

        isSelectedFunction: {
            type: Function,
            default: null
        },

        mapper: {
            type: Function,
            default: (items) => {
                return items;
            }
        },

        requiredColumns: {
            type: Array,
            default: () => {
                return [];
            }
        },

        requiredPredicates: {
            type: Array,
            default: () => {
                return [];
            }
        },

        routeResolver: {
            type: Function,
            required: true
        },

        selectable: {
            type: Boolean,
            default: true
        },

        totalItemCount: {
            type: Number,
            default: undefined
        },

        withPreflight: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            chosenColumns: [],
            predicates: cloneDeep(this.initialPredicates),
            searchQuery: getValue(find(this.initialPredicates, { field: 'term' }), 'value', ''),
            sort: {}
        };
    },

    computed: {
        allColumnDefinitions () {
            const definitions = cloneDeep(this.builtInColumnDefinitions);

            this.customColumnDefinitions.forEach((customDefinition) => {
                const matchingDefinition = definitions.find((definition) => {
                    return definition.property === customDefinition.property;
                });

                if (matchingDefinition == null) {
                    definitions.push(customDefinition);
                } else {
                    Object.assign(matchingDefinition, customDefinition);
                }
            });

            return definitions;
        },

        allPredicates () {
            if (this.searchQuery === '') {
                return this.predicates.concat(this.requiredPredicates);
            }

            return this.predicates.concat([{
                field: 'term',
                comparison: 'contains',
                value: this.searchQuery
            }]).concat(this.requiredPredicates);
        },

        availableColumns () {
            const columns = this.allColumnDefinitions.map((columnDefinition) => {
                return {
                    ...columnDefinition,
                    desktop: this.defaultColumns.includes(columnDefinition.property),
                    mobile: false
                };
            });

            return columns.concat(this.summaryColumnDefinition);
        },

        builtInColumnDefinitions () {
            return [
                {
                    label: 'Title',
                    property: 'title',
                    sortable: false
                },
                {
                    label: 'First Name',
                    property: 'firstName',
                    sortable: true
                },
                {
                    label: 'Last Name',
                    property: 'lastName',
                    sortable: true
                },
                {
                    label: 'Email',
                    property: 'email',
                    sortable: true
                },
                {
                    label: 'Tags',
                    property: 'tags',
                    sortable: false
                },
                {
                    label: 'Check-In State',
                    property: 'checkInState',
                    sortable: false
                },
                {
                    label: 'Check-In Time',
                    property: 'checkInTime',
                    sortable: true
                },
                {
                    label: 'Check-Out Time',
                    property: 'checkOutTime',
                    sortable: true
                },
                {
                    label: this.event.setup_type === 'tickets' ? 'Ticket' : 'Reply',
                    property: 'reply',
                    sortable: false
                },
                {
                    label: '+Guests?',
                    property: 'additionalGuests',
                    sortable: false
                },
                {
                    label: 'Confirmation Code',
                    property: 'confirmationCode',
                    sortable: false
                },
                {
                    label: 'Submission Time',
                    property: 'submissionTimestamp',
                    sortable: true
                },
                {
                    label: 'Group Size',
                    property: 'groupSize',
                    sortable: true
                },
                {
                    label: 'Test Submission?',
                    property: 'testMode',
                    sortable: false
                },
                {
                    label: 'Walk-in submission?',
                    property: 'walkIn',
                    sortable: false
                },
                {
                    label: 'Add-ons',
                    property: 'addons',
                    sortable: false
                },
                {
                    label: 'Last Seen Time',
                    property: 'lastSeenAt',
                    sortable: true
                },
                {
                    label: 'Coupon Code',
                    property: 'couponCode',
                    sortable: false
                },
                {
                    label: 'Discount',
                    property: 'orderDiscount',
                    sortable: true
                },
                {
                    label: 'Subtotal',
                    property: 'orderSubtotal',
                    sortable: true
                },
                {
                    label: 'Tax',
                    property: 'orderTax',
                    sortable: true
                },
                {
                    label: 'Total',
                    property: 'orderTotal',
                    sortable: true
                },
                {
                    label: 'Donation',
                    property: 'orderDonation',
                    sortable: true
                },
                {
                    label: 'RSVPify Fee',
                    property: 'orderApplicationFee',
                    sortable: true
                },
                {
                    label: 'Stripe Fee',
                    property: 'orderStripeFee',
                    sortable: true
                },
                {
                    label: 'Refunded Amount',
                    property: 'refundedAmount',
                    sortable: true
                },
                {
                    label: 'Fee-passthrough enabled?',
                    property: 'feePassthrough',
                    sortable: false
                },
                {
                    label: 'Note',
                    property: 'note',
                    sortable: false
                },
                {
                    label: 'Comments',
                    property: 'comments',
                    sortable: false
                },
                {
                    label: 'Guest of',
                    property: 'guestOf',
                    sortable: false
                },
                {
                    label: 'Email Last Opened',
                    property: 'emailLastOpened',
                    sortable: true
                }
            ];
        },

        event: get('Event/event'),

        filtered () {
            return this.allPredicates.length > 0;
        },

        inGroupView () {
            return this.groupable && !this.filtered && !this.sorted;
        },

        retrievableColumns () {
            return this.chosenColumns.filter((column) => {
                return !column.virtual;
            });
        },

        sorted () {
            return !!this.sort.property;
        },

        source () {
            const chosenColumns = this.retrievableColumns.map(({ property }) => {
                return property;
            });

            const route = this.route(this.sourceRouteName, {
                columns: chosenColumns.concat(this.requiredColumns),
                event: this.event
            });

            return route.valueOf();
        },

        sourceRouteName () {
            return this.routeResolver(this.inGroupView);
        },

        summaryColumnDefinition () {
            return {
                label: 'Guests',
                property: 'summary',
                desktop: false,
                mobile: true,
                alwaysActive: true,
                virtual: true
            };
        }
    },

    methods: {
        isOrderColumn (property) {
            return [
                'orderDiscount', 'orderSubtotal', 'orderTax', 'orderTotal', 'orderDonation', 'orderApplicationFee',
                'orderStripeFee', 'refundedAmount'
            ].includes(property);
        },

        mapperPassthrough (items) {
            return this.mapper(items, this.inGroupView);
        },

        reload () {
            this.$refs.fetcher.reset();

            this.$refs.table.resetLazyLoading();
            this.$refs.table.toggleSelectAll(false);
        },

        reset () {
            if (this.inGroupView) {
                return;
            }

            this.predicates = [];
            this.searchQuery = '';
            this.sort = {};

            this.$refs.table.sorts = [];
        },

        resetLazyLoading () {
            this.$refs?.table.resetLazyLoading();
        },

        shouldShowOrderColumn (grouped, rowIndex) {
            if (!grouped) {
                return true;
            }

            return rowIndex === 0;
        },

        updateSort ([newSort]) {
            this.sort = newSort || {};
        }
    }
};
</script>
