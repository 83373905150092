<template>
    <div class="bg-white rounded-xl flex flex-col justify-center space-y-4 p-5 relative">
        <button class="absolute top-0 right-0 m-2.5 button-icon button-sm" @click="$emit('close')">
            <app-icon name="close" class="text-gray-400 h-3 w-3"></app-icon>
        </button>

        <img
            width="max-w-full rounded-2xl"
            :src="asset('images/account/refer-friend.svg')"
            alt="Refer to RSVPify"
        >
        <h1 class="font-semibold text-lg text-black">Refer to RSVPify and get a free month</h1>
        <p class="text-violet-500 text-sm"> Know someone who’d love RSVPify? If they join you’ll both get a free month of RSVPify (valued up to $409)</p>

        <div class="flex flex-col md:flex-row items-center">
            <app-icon
                v-if="loading"
                name="loader"
                class="w-5 h-5 text-purple flex-grow"
            ></app-icon>

            <input
                v-else
                type="text"
                class="form-field bg-gray-100 cursor-not-allowed flex-shrink"
                :value="affiliateLink"
                disabled
            >
            <button
                :disabled="loading"
                class="ml-4 button button-primary flex-none !normal-case !text-xs"
                @click="copyReferralLink"
            >Copy link</button>
        </div>

        <a
            :href="route('account.refer-a-friend')"
            class="button button-primary !normal-case !text-xs"
            target="_blank"
        >
            <div class="flex items-center space-x-2">
                <app-icon name="share-button" class="h-4 w-4"></app-icon>
                <span>Read more</span>
            </div>
        </a>

        <a
            href="https://rsvpify.com/referafriend/terms/"
            class="font-bold text-purple text-xs text-center"
            target="_blank"
        >
            Terms &amp; Conditions
        </a>
    </div>
</template>

<script>
import axios from '@/util/axios';

export default {
    name: 'ReferAFriendPopover',

    data () {
        return {
            affiliateLink: '',
            loading: true
        };
    },

    mounted () {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                axios.get(this.route('api.account.referrals.affiliate-link'))
                    .then((response) => {
                        this.affiliateLink = response.data.link;
                        this.loading = false;
                    });
            }
        });

        observer.observe(this.$el);
    },

    methods: {
        copyReferralLink () {
            this.$copyText(this.affiliateLink);
            this.$toasted.global.success('The referral URL has been copied to your clipboard and is ready for sharing!');
        }
    }
};
</script>
