/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-cocktail-martini-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>bar alcohol liquor</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25 23.25h6M11.25 23.25v-9M18.971 6.53h-.01c.29-.3.29-.77-.01-1.07a.742.742 0 00-.53-.22H4.03c-.42 0-.75.33-.75.75 0 .19.07.38.21.52l7.72 7.72z"/><path pid="1" d="M.75.75h2.82-.01c.56-.01 1.08.32 1.34.82l1.83 3.67M15.827 5.251l-.01-.01c-.05-.25-.08-.5-.08-.76v-.01c0-2.07 1.68-3.75 3.75-3.75a3.75 3.75 0 013.74 3.75 3.764 3.764 0 01-3.76 3.74c-.63-.01-1.24-.16-1.78-.46"/></g>'
  }
})
