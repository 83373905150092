<script>
import { sync } from 'vuex-pathify';
import merge from 'lodash/merge';

export default {
    props: {
        formTheme: {
            type: Object,
            default: () => { return {}; }
        },

        wallTheme: {
            type: Object,
            default: () => { return {}; }
        }
    },

    render (h) {
        return h('div', this.$slots.default);
    },

    computed: {
        ...sync('Theme/*')
    },

    mounted () {
        this.form = merge(this.form, this.formTheme);
        this.wall = merge(this.wall, this.wallTheme);
    }
};
</script>
