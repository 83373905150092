<template>
    <div>
        <div v-if="isBrandingVisible" class="absolute top-0 w-full z-20">
            <a
                :href="hyperLoopBrandedHeaderUrl"
                target="_blank"
            >
                <div class="flex w-full h-10 bg-white text-center items-center justify-center text-purple-900">
                    Create your event with
                    <logo-rsvpify-full class="w-28 h-auto ml-2"></logo-rsvpify-full>
                </div>
            </a>
        </div>
        <div
            class="rsvp-form"
            :class="formBackgroundClass"
            :style="formBackgroundStyle"
        >
            <div
                v-if="!isEditMode"
                class="form-background"
                :class="{ 'wall-inactive': !isWallActive}"
            ></div>

            <div
                v-if="isLoaderVisible"
                class="absolute flex items-center justify-center z-50 bg-snow-900 inset-0"
            >
                <app-icon class="h-8 w-8 text-gray-500" name="loader"></app-icon>
            </div>

            <slot name="header"></slot>

            <div v-if="submission.original_submission && !allowGuestSubmissionEditing" class="flex h-screen items-center p-4">
                <div
                    v-theme="['form.text', 'form.background']"
                    class="w-full rounded-md relative transition-all mt-12 mb-24 px-4 py-12"
                >
                    <div class="text-center">
                        {{ $t('text-already-registered') }}
                    </div>

                    <button
                        v-theme="['form.button']"
                        class="border border-smoke-200 flex items-center mx-auto shadow-md rounded-md p-2 mt-8 -mb-16"
                        @click="goToEventForm"
                    >
                        <app-icon
                            class="mx-2 h-6 w-6"
                            name="arrow-left-circle"
                        ></app-icon>

                        <div class="mx-2 text-left text-sm">{{ $t('button-back') }}</div>
                    </button>
                </div>
            </div>

            <div v-if="submission.hasEditPreview && submission.original_submission" class="flex h-screen items-center p-4">
                <div
                    v-theme="['form.text', 'form.background']"
                    class="w-full rounded-md relative transition-all mt-12 mb-24 px-4 py-12"
                >
                    Test submissions aren’t editable, but your guests will be able to edit their no-charge submissions after you’ve published your event.
                </div>
            </div>

            <div v-else-if="isEmbed && event.plan && !event.plan.features.FormEmbedding.allowed" class="flex items-center h-window-full overflow-y-auto p-6 mb-24">
                <div class="flex my-auto w-full">
                    <div
                        v-theme="['form.text', 'form.background']"
                        class="w-full rounded-md relative transition-all mt-12 mb-24 px-4 py-12 text-center"
                    >
                        Your RSVPify plan or subscription doesn't support embedding. Please upgrade your RSVPify account.
                    </div>
                </div>
            </div>

            <div v-else-if="preview && event.isOverTestLimit" class="flex items-center h-window-full overflow-y-auto p-6 mb-24">
                <div class="flex my-auto w-full">
                    <div
                        v-theme="['form.text', 'form.background']"
                        class="w-full rounded-md relative transition-all mt-12 mb-24 px-4 py-12"
                    >
                        This event is in DRAFT. Please delete some existing test submissions to continue previewing your form experience.
                    </div>
                </div>
            </div>

            <div v-else-if="showFormUnavailableMessage" class="flex flex-col justify-center items-center h-window-full overflow-y-auto p-6 mb-24">
                <img
                    v-if="formLogo"
                    v-theme="'form.logo'"
                    :src="formLogo"
                    alt="logo"
                    class="block mx-auto"
                >

                <div class="flex w-full">
                    <div
                        v-theme="['form.text', 'form.background']"
                        v-bar="{ useScrollbarPseudo: true }"
                        class="max-h-md max-w-3xl mx-auto w-full rounded-md relative transition-all mt-12 mb-24 px-4 py-12"
                    >
                        <tiptap-editor
                            v-model="formUnavailableMessage"
                            read-only
                        ></tiptap-editor>
                    </div>
                </div>
            </div>

            <div v-else>
                <div
                    v-for="section in visibleSections"
                    :key="section.class"
                    :class="styles.section"
                >
                    <component
                        :is="wrapperComponent"
                        v-model="section.blocks"
                        :move="blockMove"
                        :options="getSectionDraggableOptions(section)"
                        class="w-full"
                        :style="wrapperStyle"
                        @add="$emit('drag-block', $event)"
                        @end="$emit('reorder-blocks', $event)"
                    >
                        <form-display-block
                            v-for="(block, index) in getSectionBlocks(section)"
                            :key="getBlockId(block)"
                            ref="blocks"
                            :block="block"
                            :preview="preview"
                            :processing="processing"
                            :data-forced-order="block.properties ? block.properties.forcedOrder : null"
                            :data-index="index"
                            :is-full-width="shouldFormBeFullWidth"
                            :data-pivot-id="block.pivot ? block.pivot.id : null"
                            :show-complete-button-message="showCompleteButtonMessage"
                            @complete-block="blockCompleted"
                            @go-back="scrollBack"
                            @click-inactive-block="scrollToBlock"
                        >

                            <!--
                        This slot allows adding additional content to the
                        rendered blocks in the Form Builder or other pages
                        which might render the RsvpForm
                        -->
                            <slot name="block-default-slot" :block="block"></slot>
                        </form-display-block>
                    </component>
                </div>
            </div>

            <template v-if="!isEditMode && event.plan && event.plan.features.BrandedFooter.enabled">
                <div class="flex items-center justify-center">
                    <a
                        :href="hyperLoopBrandedFooterUrl"
                        target="_blank"
                        class="fixed bottom-0 mb-4 p-2 rounded-xl text-gray-400 flex items-center justify-center border border-grey shadow-outline-gray shadow-md bg-white"
                        :class="poweredByLogoStyles.container"
                        @mouseenter="showPoweredByRsvpifyLogo = false"
                        @mouseleave="showPoweredByRsvpifyLogo = true"
                    >
                        <div class="w-24 h-4 flex items-center justify-center">
                            <img
                                v-if="showPoweredByRsvpifyLogo"
                                :src="asset('images/powered-by/rsvpify-logo-animated.svg')"
                                alt="rsvpify logo"
                            >
                            <div v-else class="w-full">
                                <p class="text-sm font-bold text-purple text-center">Sign up free</p>
                            </div>
                        </div>
                    </a>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Mousetrap from 'mousetrap';
import { get, sync } from 'vuex-pathify';
import draggable from 'vuedraggable';
import VueScrollTo from 'vue-scrollto';
import {
    findIndex, upperFirst, camelCase, find, flatMap, get as getValue
} from 'lodash';
import axios from '@/util/axios';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';
import ForceBlockOrder from '@/mixins/ForceBlockOrder';
import InteractsWithFeatureFlags from '@/mixins/InteractsWithFeatureFlags';
import InteractsWithAbly from '@/mixins/InteractsWithAbly';
import HyperloopUrls from '@/mixins/HyperloopUrls';

const defaultBlockBehaviour = {
    render: {
        when (block, submission, event, paymentSectionCompleted) {
            const { section } = block.pivot.settings;

            if (paymentSectionCompleted) {
                return section === 'ConfirmationSection';
            }

            if (submission.status !== 'Completed') {
                return section === 'PrimarySection';
            }

            if (submission.declined) {
                return section === 'NotAttendingSection';
            }

            return section === 'ConfirmationSection';
        },
        unless (block, submission, event, paymentSectionCompleted) {
            if (block.pivot.settings.isDisplayed === false) {
                return true;
            }

            return false;
        }
    }
};

const hiddenBuilderBlocks = [
    'receipt'
];

export default {
    components: { draggable },

    mixins: [GeneratesUniqueKey, ForceBlockOrder, InteractsWithFeatureFlags, InteractsWithAbly, HyperloopUrls],

    props: {
        activeSection: {
            type: String,
            default: null
        },
        inaccessible: {
            type: Boolean,
            default: false
        },
        isEmbed: {
            type: Boolean,
            default: false
        },
        isOEmbed: {
            type: Boolean,
            default: false
        },
        preview: {
            type: Boolean,
            default: false
        },
        scrollContainerId: {
            type: String,
            default: ''
        },
        sections: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            initializing: true,
            scrollContainerElement: null,
            submissionIsCreated: false,
            processing: false,
            showPoweredByRsvpifyLogo: true
        };
    },

    computed: {
        ...sync('Form/*'),
        ...get('Wall/wall@', {
            isWallActive: 'active'
        }),

        allowGuestSubmissionEditing () {
            return getValue(this.event, 'settings.security.allowGuestSubmissionEditing');
        },

        formBackgroundClass () {
            return {
                'w-full': !this.isEditMode,
                'min-h-screen': this.isInConfirmationScreen
            };
        },

        formBackgroundStyle () {
            if (this.isEditMode && !this.preview) {
                return null;
            }

            return this.$store.get('Form/formBackgroundStyle');
        },

        formLogo: get('Form/form@settings.logo_url'),

        formUnavailableMessage () {
            if (this.form.isClosed && this.form.settings.closedMessage) {
                return this.form.settings.closedMessage;
            }

            if (this.inaccessible) {
                return `<p style="text-align: center;"><span style="font-size: 20px">Sorry, this event is not currently able to accept additional registrations.</span></p><p></p><p style="text-align: center;">Please contact your event’s host directly. If you're this event's owner, please contact the passionate <a href="https://help.rsvpify.com" rel="noopener noreferrer nofollow" target="_blank">RSVPify Support Team</a> for assistance.</p>`;
            }

            if (!this.form.isOpened && this.form.settings.unavailableMessage) {
                return this.form.settings.unavailableMessage;
            }

            if (this.form.settings.unavailableMessage) {
                return this.form.settings.unavailableMessage;
            }

            return 'Sorry this form is closed.';
        },

        errorBag: sync('Submission/errorBag'),
        event: get('Event/event'),

        hyperLoopBrandedFooterUrl () {
            return this.isEmbed
                ? this.hyperloopUrl('embedded_form_footer')
                : this.hyperloopUrl('form_footer');
        },

        hyperLoopBrandedHeaderUrl () {
            return this.isEmbed
                ? this.hyperloopUrl('embedded_form_header')
                : this.hyperloopUrl('form_header');
        },

        isBrandingVisible () {
            if (this.isEditMode) {
                return false;
            }

            if (this.event.plan.features.BrandedRegistrationExperience.enabled) {
                return true;
            }

            if (this.event.plan.features.BrandedConfirmationScreen.enabled && this.isInConfirmationScreen) {
                return true;
            }

            return false;
        },

        isInConfirmationScreen () {
            return this.submission.status === 'Completed';
        },

        isInviteeFlow () {
            return this.event.invite_list.is_enabled;
        },

        isLoaderVisible () {
            if (!this.initializing) {
                return false;
            }

            if (this.preview && this.event.isOverTestLimit) {
                return false;
            }

            if (this.isEmbed && this.event.plan && !this.event.plan.features.FormEmbedding.allowed) {
                return false;
            }

            return !this.isEditMode && !this.showFormUnavailableMessage && !this.submission.hasEditPreview;
        },

        languages: get('Event/event@enabled_languages'),

        securityToken: get('Submission/securityToken'),

        shouldFormBeFullWidth () {
            if (this.isEditMode || this.isEmbed) {
                return false;
            }

            return !this.isWallActive;
        },

        showFormUnavailableMessage () {
            if (this.isEditMode || this.preview) {
                return false;
            }

            if (!this.form.isPublished) {
                return true;
            }

            if (this.form.isOpened === undefined || this.form.isClosed === undefined) {
                return !this.form.isPublished;
            }

            return !this.form.isOpened || this.form.isClosed || this.inaccessible;
        },

        showCompleteButtonMessage () {
            if (!this.activeBlock.id) {
                return false;
            }

            const formBlocks = flatMap(this.sections, (section) => { return section.blocks; });
            const activeBlock = find(formBlocks, { pivot: { id: this.activeBlock.id } });
            const sectionClass = getValue(activeBlock, 'pivot.settings.section');

            // We can't know if this is the last block until you pass the main block
            if (!activeBlock || ['rsvp', 'ticketing'].includes(activeBlock.slug)) {
                return false;
            }

            // No buttons in the Confirmation/NotAttending sections
            if (!sectionClass || sectionClass === 'ConfirmationSection' || sectionClass === 'NotAttendingSection') {
                return false;
            }

            const section = find(this.sections, { class: sectionClass });

            if (!section) {
                return false;
            }

            const renderedSectionBlocks = this.getSectionBlocks(section);

            return this.activeBlock.index === renderedSectionBlocks.length - 1;
        },

        styles () {
            return {
                section: {
                    'p-4 mt-8 rounded-md border': this.isEditMode && !this.preview
                }
            };
        },

        submission: sync('Submission/submission'),
        paymentSectionCompleted: get('Submission/paymentSectionCompleted'),

        poweredByLogoStyles () {
            return {
                container: {
                    'right-0 mr-4': !this.isEmbed && !this.isOEmbed
                }
            };
        },

        visibleSections () {
            if (!this.isEditMode || this.activeSection === null) {
                return this.sections;
            }

            return this.sections.filter((section) => {
                return section.class === this.activeSection;
            });
        },

        wrapperComponent () {
            return this.isEditMode ? 'draggable' : 'div';
        },

        wrapperStyle () {
            return this.wrapperComponent === 'draggable' ? 'min-height: 100px' : '';
        }
    },

    watch: {
        activeBlock: {
            deep: true,
            handler () {
                this.errorBag.clear();
            }
        },

        submission: {
            immediate: true,
            handler () {
                if (this.isPreview) {
                    return;
                }

                if (!this.submission.id) {
                    return;
                }

                const channel = `submissions.${this.submission.id}`;

                if (this.submissionIsCreated && this.submission.status === 'Completed') {
                    this.$echo.leave(channel);
                }

                if (!this.submissionIsCreated) {
                    this.submissionIsCreated = true;
                    this.listenForExpirationEvent(channel);
                }
            }
        }
    },

    created () {
        this.isPreview = this.preview;

        this.languages.forEach(({ name, translations }) => {
            this.$i18n.setLocaleMessage(name, translations);
        });

        this.$i18n.locale = this.languages[0].name;
    },

    mounted () {
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                this.checkSubmissionStatus();
            }
        });

        if (this.showFormUnavailableMessage || this.submission.hasEditPreview) {
            return;
        }

        if (!this.isEditMode) {
            this.scrollContainerElement = this.$el.parentElement;

            this.setFirstVisibleBlockAsActive();

            this.insertSystemBlocks();

            Mousetrap.prototype.stopCallback = (e, element) => {
                // If the element has a no-mousetrap class, don't trigger the enter key press
                if ((` ${element.className} `).indexOf(' no-mousetrap ') > -1) {
                    return true;
                }

                return false;
            };

            Mousetrap.bind(['enter'], () => {
                App.$emit('enter-key-pressed');
            });
        }
    },

    methods: {
        async checkSubmissionStatus () {
            if (!this.submission.id) {
                return;
            }

            const route = this.route('api.submissions.status', {
                submission: this.submission.id,
                uuid: this.submission.uuid,
                securityToken: this.securityToken
            });

            try {
                const { data } = await axios.get(route);

                if (data.status === 'Expired') {
                    this.showExpirationAlert();
                }
            } catch (error) {
                //
            }
        },

        getBlockId (block) {
            if (block.component) {
                return block.component;
            }

            return block.pivot ? block.pivot.id : block.id;
        },

        getSectionBlocks (section) {
            if (this.isEditMode) {
                return section.blocks.filter((block) => {
                    const blockSetupTypeLimitation = getValue(block, 'pivot.settings.setup_type', 'all');

                    const shouldShowForSetupType = this.showAsSetupType === 'all'
                        || blockSetupTypeLimitation === 'all'
                        || blockSetupTypeLimitation === null
                        || blockSetupTypeLimitation === this.showAsSetupType;

                    const blockIsNotHidden = !hiddenBuilderBlocks.includes(block.slug);

                    return shouldShowForSetupType && blockIsNotHidden;
                });
            }

            return section.blocks.filter((block) => {
                const blockSlug = upperFirst(camelCase(block.slug));
                const blockComponentName = `Form${blockSlug}Block`;

                const specificBlockBehaviour = getValue(
                    Vue.options.components,
                    `${blockComponentName}.options.behaviour`,
                    {
                        render: {
                            when () { return true; },
                            unless () { return false; }
                        }
                    }
                );

                const behaviourFunctionParams = [
                    block,
                    this.submission,
                    this.event,
                    this.paymentSectionCompleted
                ];

                return ![defaultBlockBehaviour, specificBlockBehaviour]
                    .flatMap((behaviour) => {
                        return [
                            behaviour.render.when(...behaviourFunctionParams),
                            !behaviour.render.unless(...behaviourFunctionParams)
                        ];
                    })
                    .some((shouldRender) => {
                        return shouldRender === false;
                    });
            });
        },

        getSectionDraggableOptions (section) {
            return {
                handle: '.draggable-handle',
                group: section.class,
                animation: 150
            };
        },

        goToEventForm () {
            window.location.href = this.route('event.show', this.event.subdomain);
        },

        async blockCompleted ({ block, payload }) {
            if (this.processing) {
                return;
            }

            const onSuccess = (result = {}) => {
                App.$emit('complete-block', { block, result });

                this.scrollNext();
            };

            if (block.system) {
                onSuccess();
                return;
            }

            this.processing = true;

            try {
                const { result, successful } = await this.$store.dispatch('Submission/completeBlock', {
                    block,
                    payload
                });

                if (successful) {
                    onSuccess(result);
                }
            } catch (error) {
                if (error?.response?.status === 403) {
                    this.showExpirationAlert();
                    return;
                }

                if (error?.response?.status === 409) {
                    this.$toasted.global.error('The form has been updated. Please refresh your page.');

                    return;
                }

                if (error?.response?.status !== 422) {
                    this.$toasted.global.error('There was an error completing this block.');
                }
            } finally {
                this.processing = false;
            }
        },

        insertSystemBlocks () {
            this.sections.forEach((section) => {
                section.systemBlocks.forEach((systemBlock) => {
                    const blockToInsert = {
                        ...systemBlock,
                        system: true,
                        pivot: {
                            id: systemBlock.id,
                            settings: {
                                section: section.class
                            }
                        }
                    };

                    const index = findIndex(section.blocks, {
                        slug: blockToInsert.after ? blockToInsert.after : blockToInsert.before
                    });

                    if (index !== -1) {
                        section.blocks.splice(
                            index + (blockToInsert.after ? 1 : 0),
                            0,
                            blockToInsert
                        );
                    }
                });
            });

            this.$nextTick(() => {
                this.setFirstVisibleBlockAsActive();
                this.initializing = false;
            });
        },

        listenForExpirationEvent (channel) {
            this.$echo.channel(channel)
                .listen('.Domain\\Submissions\\Events\\SubmissionExpired', () => {
                    this.$echo.leave(channel);

                    this.showExpirationAlert();
                });
        },

        scrollBack () {
            if (this.activeBlock.index > 0) {
                this.scrollToBlock(this.activeBlock.index - 1);
            }
        },

        async scrollNext () {
            this.$nextTick(async () => {
                if (this.activeBlock.index < this.$refs.blocks.length - 1) {
                    this.scrollToBlock(this.activeBlock.index + 1);
                } else {
                    this.processing = true;

                    await this.$store.dispatch('Submission/complete');

                    // Scroll to the top of the confirmation UI after completing
                    // the submission
                    this.scrollContainerElement.scrollTop = 0;

                    this.processing = false;
                }
            });
        },

        scrollToBlock (blockIndex) {
            const block = this.$refs.blocks.find((blockRef) => {
                return parseInt(blockRef.$el.dataset.index, 10) === blockIndex;
            });

            const duration = 1250;

            VueScrollTo.scrollTo(block.$el, duration, {
                container: this.scrollContainerElement,
                duration,
                easing: [0.25, 0.46, 0.45, 0.94],
                cancelable: false
            });

            Object.assign(this.activeBlock, {
                id: block.block.pivot.id,
                index: blockIndex
            });
        },

        setFirstVisibleBlockAsActive () {
            const firstVisibleBlock = this.$refs.blocks.find((blockRef) => {
                return parseInt(blockRef.$el.dataset.index, 10) === 0;
            });

            Object.assign(this.activeBlock, {
                id: firstVisibleBlock.block.pivot.id,
                index: 0
            });
        },

        showExpirationAlert () {
            App.alert().error(
                this.$t('text-submission-timeout-modal-title'),
                this.$t('text-submission-timeout-modal-message'),
                {
                    confirmButtonText: this.$t('text-restart')
                },
                () => {
                    window.location.reload();
                }
            );
        }
    }
};
</script>
