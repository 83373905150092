/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'time-stopwatch-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>timer race</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 5.25a9 9 0 100 18 9 9 0 100-18zM18 7.5l1.88-1.88M19.5 5.25l.75.75M12 5.25V.75M14.25.75h-4.5M12 15l-3.75-4.15"/></g>'
  }
})
