<template>
    <div class="w-full relative">
        <slidable-cards v-cloak v-if="areAttendanceCardsVisible">
            <slidable-card>
                <div slot="footer">
                    <div class="uppercase">Attending</div>
                    <div v-if="showRsvpLimitedAvailability('Attending')" class="text-gray-400">
                        <strong class="tracking-wider">{{ getProductByShortName('Attending').quantity_remaining | number }}</strong> still available
                    </div>
                </div>
                <template slot="icon">
                    <div class="flex items-center justify-center p-3 bg-green-light rounded-full">
                        <app-icon
                            name="check-square"
                            class="w-6 h-6 text-green-dark"
                            stroke
                        ></app-icon>
                    </div>
                </template>
                {{ event.attending_count | number }}
            </slidable-card>

            <slidable-card v-if="isMaybeCardVisible">
                <div slot="footer">
                    <div class="uppercase">Maybe Attending</div>
                    <div v-if="showRsvpLimitedAvailability('Maybe')" class="text-gray-400">
                        <strong class="tracking-wider">{{ getProductByShortName('Maybe').quantity_remaining | number }}</strong> still available
                    </div>
                </div>
                <template slot="icon">
                    <div class="flex items-center justify-center p-3 bg-yellow-light rounded-full">
                        <app-icon
                            name="remove-square"
                            class="w-6 h-6 text-yellow-dark"
                            stroke
                        ></app-icon>
                    </div>
                </template>

                {{ event.maybe_attending_count | number }}
            </slidable-card>

            <slidable-card>
                <span slot="footer" class="uppercase">Not Attending</span>
                <template slot="icon">
                    <div class="flex items-center justify-center p-3 bg-red-light rounded-full">
                        <app-icon
                            name="close-square"
                            class="w-6 h-6 text-red"
                            stroke
                        ></app-icon>
                    </div>
                </template>

                {{ event.not_attending_count | number }}
            </slidable-card>

            <slidable-card v-if="isGrossSalesCardVisible">
                <span slot="footer" class="uppercase">Gross Sales</span>
                <template slot="icon">
                    <div class="flex items-center justify-center p-3 bg-purple-50 rounded-full">
                        <app-icon
                            name="money-bag-dollar"
                            class="w-6 h-6 text-purple"
                            stroke
                        ></app-icon>
                    </div>
                </template>

                <money-field static :value="event.gross_sales"></money-field>
            </slidable-card>

            <slidable-card v-if="isDonationsCardVisible">
                <span slot="footer" class="uppercase">Donations</span>
                <template slot="icon">
                    <div class="flex items-center justify-center p-3 bg-teal-light rounded-full">
                        <app-icon
                            name="gift"
                            class="w-6 h-6 text-teal"
                            stroke
                        ></app-icon>
                    </div>
                </template>

                <money-field static :value="event.total_donations"></money-field>
            </slidable-card>
        </slidable-cards>

        <slidable-cards v-if="event.setup_type !== 'rsvp'" v-cloak>
            <slidable-card v-if="isRtsGrossSalesCardVisible">
                <span slot="footer" class="uppercase">Gross Sales</span>
                <template slot="icon">
                    <div class="flex items-center justify-center p-3 bg-purple-50 rounded-full">
                        <app-icon
                            name="money-bag-dollar"
                            class="w-6 h-6 text-purple"
                            stroke
                        ></app-icon>
                    </div>
                </template>

                <money-field static :value="event.gross_sales"></money-field>
            </slidable-card>

            <slidable-card
                v-for="product in products"
                :key="product.id"
                :title="product.title"
            >
                {{ (product.category === 'add-on' ? product.total_quantity : product.guests_count) | number }}

                <div slot="footer">
                    <div>{{ product.category === 'add-on' ? '&nbsp;' : 'Attendees' }}</div>
                    <div v-if="product.has_limited_availability" class="text-gray-400">
                        <strong class="tracking-wider">{{ product.quantity_remaining | number }}</strong> still available
                    </div>
                </div>
            </slidable-card>
        </slidable-cards>

        <slidable-cards v-cloak v-if="selectedTimeslotDate && availableTimeslots.length > 0">
            <slidable-card
                v-for="timeslot in availableTimeslots"
                :key="timeslot.id"
            >
                <div class="w-full h-full -mt-4">
                    <div class="text-lg font-semibold mb-4">{{ formatTimeRepresentation(timeslot.from) }}</div>

                    <div v-if="timeslot.products.length === 1" class="w-full space-y-2">
                        <div class="text-base font-normal text-gray-400">
                            {{ timeslot.products[0].title }}
                        </div>
                        <div>{{ timeslot.products[0].sold_count | number }}</div>
                        <div v-if="timeslot.products[0].limit !== null" class="text-base font-normal text-gray-400">
                            <b>{{ getProductAvailableQuantity(timeslot.products[0]) | number }}</b> still available
                        </div>
                    </div>
                    <template v-else>
                        <div
                            v-for="product in timeslot.products"
                            :key="product.id"
                            class="flex items-center w-full text-base font-normal my-1"
                        >
                            <div class="w-2/3 pr-4 text-gray-400 text-right">
                                {{ product.title }}:
                            </div>
                            <div class="w-1/3 text-left">
                                <span>{{ product.sold_count | number }}</span>
                                <template v-if="product.limit !== null">
                                    <span class="text-gray-400 px-1">of</span>
                                    <span>{{ product.limit }}</span>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </slidable-card>
        </slidable-cards>

        <slot></slot>
    </div>
</template>

<script>
import find from 'lodash/find';
import { get as getValue } from 'lodash';
import { DateTime } from 'luxon';

export default {
    name: 'DashboardOverviewCards',

    props: {
        event: {
            type: Object,
            required: true
        },

        selectedTimeslotDate: {
            type: Object,
            default: () => { return null; }
        },

        timeslots: {
            type: Array,
            default: () => { return []; }
        },

        products: {
            type: Array,
            default: () => { return []; }
        }
    },

    computed: {
        areAttendanceCardsVisible () {
            if (this.event.classyIntegration) {
                return false;
            }

            return this.selectedTimeslotDate === null;
        },

        availableTimeslots () {
            return this.timeslots.map((timeslot) => {
                return {
                    ...timeslot,
                    from: DateTime.fromISO(timeslot.from, { zone: this.event.timezone.name }),
                    to: DateTime.fromISO(timeslot.to, { zone: this.event.timezone.name }),
                    id: timeslot.from
                };
            });
        },

        isDonationsCardVisible () {
            if (this.event.classyIntegration) {
                return false;
            }

            return this.event.acceptsDonations || this.event.total_donations > 0;
        },

        isGrossSalesCardVisible () {
            if (this.event.classyIntegration) {
                return false;
            }

            return this.event.hasPayableItems || this.event.gross_sales > 0;
        },

        isMaybeCardVisible () {
            return this.areAttendanceCardsVisible && this.event.acceptsMaybeResponse || this.event.maybe_attending_count > 0;
        },

        isRtsGrossSalesCardVisible () {
            if (this.event.classyIntegration) {
                return false;
            }

            return !this.areAttendanceCardsVisible && this.isGrossSalesCardVisible;
        }
    },

    methods: {
        formatTimeRepresentation (time) {
            const timeFormat = getValue(this.event.settings, 'recurringTimeSlots.timeFormat');

            return timeFormat === '24-hours' ? time.toFormat('H:mm') : time.toFormat('h:mm a');
        },

        getProductByShortName (shortName) {
            return find(this.products, { short_guest_state: shortName });
        },

        getProductAvailableQuantity (product) {
            return Math.max(product.limit - product.sold_count, 0);
        },

        showRsvpLimitedAvailability (shortName) {
            if (this.event.setup_type !== 'rsvp') {
                return false;
            }

            return this.getProductByShortName(shortName) && this.getProductByShortName(shortName).has_limited_availability;
        }
    }
};
</script>
