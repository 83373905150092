/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-quarantine-calendar-14-days-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 12.007h2v8M17.25 17.007h-5.5c0-3 4-5 4-5v8"/><rect pid="1" width="22.5" height="20" x=".75" y="3.007" rx="1.5"/></g><g stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="2" d="M.75 8.51h22.5"/><g stroke-linecap="round"><path pid="3" d="M7.25 4.99v-4M16.25 4.99v-4"/></g></g>'
  }
})
