<template>
    <div class="container">
        <div class="panel flex flex-col">
            <page-header icon="users-location-stroke">
                <template #label>
                    <div class="flex items-center space-x-4">
                        <span>Event Sharing</span>

                        <premium-feature
                            v-if="userHasReachedLimit"
                            feature="SharedUserLimit"
                            :tooltip="reachedLimitText"
                            :requires-upgrade="true"
                            block
                        ></premium-feature>
                    </div>
                </template>
                <a :href="route('account.show')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <div class="flex flex-col py-4">
                <div v-if="users.length === 0" class="flex flex-col items-center justify-center">
                    <p class="text-gray-700 text-xl text-center tracking-wide m-2">It's lonely in here...</p>

                    <button
                        type="button"
                        class="button button-primary my-8"
                        @click="showNewUserModal"
                    >
                        <app-icon name="add-circle"></app-icon>
                        Add Collaborator or Event Staff
                    </button>
                </div>

                <div v-else>
                    <data-table
                        class="mt-4 overflow-x-auto"
                        :columns="columns"
                        :rows="filteredUsers"
                        no-results-placeholder="No users found matching your criteria."
                        primary-key="id"
                    >
                        <template #header>
                            <div class="flex-auto">
                                <search-field
                                    v-model="searchTerm"
                                    class="w-full md:w-80"
                                    placeholder="Search by name or email..."
                                ></search-field>
                            </div>

                            <button
                                class="button-icon button-lg button-info"
                                @click="showNewUserModal"
                            >
                                <app-icon name="add-circle" stroke></app-icon>
                            </button>
                        </template>
                        <template slot="row" slot-scope="{ row, property, value }">
                            <!--Note that the Options column is a "fake" column/property name and it does not actually have a label -->
                            <div
                                v-if="property === 'options'"
                                class="flex justify-center items-center text-center space-x-4"
                            >
                                <button class="button-icon" @click="showEditUserModal(row)">
                                    <app-icon
                                        name="edit"
                                        class="h-5 w-5"
                                        stroke
                                    ></app-icon>
                                </button>

                                <button class="button-icon hover:text-red" @click="deleteUser(row)">
                                    <app-icon
                                        v-tippy
                                        name="trash"
                                        class="h-5 w-5"
                                        :content="deleteInvitedUserTooltip(row)"
                                        stroke
                                    ></app-icon>
                                </button>
                            </div>

                            <div v-else-if="property === 'name'">
                                <span v-if="!!value">{{ value }}</span>
                                <span v-else>INVITATION PENDING</span>
                            </div>

                            <div v-else-if="property === 'adminPermissions' || property === 'readOnlyPermissions'">
                                <template v-if="value.length > 0">
                                    <tippy>
                                        <template #trigger>
                                            <button class="border-b border-dashed border-gray-500">
                                                {{ value.length }} Event(s)
                                            </button>
                                        </template>

                                        <div
                                            v-for="event in value"
                                            :key="event.id"
                                        >
                                            {{ event.name }}
                                        </div>
                                    </tippy>
                                </template>

                                <template v-else>
                                    {{ value.length }} Event(s)
                                </template>
                            </div>

                            <div v-else>
                                {{ value }}
                            </div>
                        </template>
                    </data-table>
                </div>

                <shared-event-user-details
                    v-model="showUserModal"
                    :user="user"
                    :events="events"
                    :validation-errors="validationErrors"
                    :saving="saving"
                    @modal-closed="resetModal"
                    @delete-user="deleteUser"
                    @save-user="saveUser"
                    @email-set="loadExistingSharedUserPermissions"
                ></shared-event-user-details>
            </div>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import get from 'lodash/get';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';
import SortingComparators from '@/util/sorting-comparators';
import axios from '@/util/axios';
import Errors from '@/validation/Errors';

export default {
    name: 'SharedEvents',

    mixins: [GeneratesUniqueKey],

    props: {
        events: {
            type: Array,
            required: true
        },

        plan: {
            type: Object,
            required: true
        },

        sharedUsers: {
            type: Array,
            required: true
        },

        invitedUsers: {
            type: Array,
            required: false
        }
    },

    data () {
        return {
            validationErrors: new Errors(),
            showUserModal: false,
            columns: [
                {
                    label: '', property: 'options', desktop: true, mobile: true, minWidth: '100px'
                },
                {
                    label: 'Name', property: 'name', desktop: true, mobile: true, sortable: true, minWidth: '150px'
                },
                {
                    label: 'Email', property: 'email', desktop: true, mobile: true, sortable: true, minWidth: '130px'
                },
                {
                    label: 'Admin',
                    property: 'adminPermissions',
                    desktop: true,
                    mobile: true,
                    sortable: true,
                    minWidth: '170px',
                    comparator: SortingComparators.arrayLength('adminPermissions')
                },
                {
                    label: 'Read-only + Check-in',
                    property: 'readOnlyPermissions',
                    desktop: true,
                    mobile: true,
                    sortable: true,
                    minWidth: '150px',
                    comparator: SortingComparators.arrayLength('readOnlyPermissions')
                }
            ],
            users: cloneDeep(this.sharedUsers.concat(this.invitedUsers)),
            user: {},
            saving: false,
            searchTerm: ''
        };
    },

    computed: {
        filteredUsers () {
            return this.users.filter((user) => {
                const searchRegex = new RegExp(this.searchTerm, 'i');

                return searchRegex.test(`${user.name} ${user.email}`);
            }).map((user) => {
                return {
                    ...user,
                    adminPermissions: filter(user.sharedEvents, { permission: { access_type: 'admin' } }),
                    readOnlyPermissions: filter(user.sharedEvents, { permission: { access_type: 'read-only' } })
                };
            });
        },

        userHasReachedLimit () {
            const limit = this.plan.features.SharedUserLimit;

            if (limit.unlimited) {
                return false;
            }

            return this.users.length >= limit.max;
        },

        reachedLimitText () {
            return `Your ${this.plan.name} plan includes <strong>${this.plan.features.SharedUserLimit.max}</strong> co-manager(s). Upgrade to add co-managers to your event(s).`;
        }
    },

    methods: {
        deleteInvitedUserTooltip (user) {
            return user.invitation_token ? 'Cancel co-manager invitation and remove all access' : null;
        },

        showEditUserModal (user) {
            this.user = cloneDeep(user);

            this.showUserModal = true;
        },

        saveUser ({ user, permissions }) {
            this.saving = true;

            if (user.id) {
                axios.patch(this.route('account.shared-event-users.update', { user: this.user.email }), {
                    permissions
                }).then(({ data }) => {
                    const userIndex = findIndex(this.users, { id: user.id });
                    this.$set(this.users, userIndex, data);

                    this.closeModal();
                    this.$toasted.global.success({
                        message: 'User permissions have been updated.',
                        onComplete: () => {
                            window.location.reload();
                        }
                    });

                    this.validationErrors.clear();
                }).catch((error) => {
                    if (error.response && error.response.status === 422) {
                        this.validationErrors.record(get(error, 'response.data.errors'));
                    } else {
                        this.validationErrors.clear();
                        this.$toasted.global.error(`There was an error updating the user's permissions.`);
                        this.closeModal();
                    }
                }).finally(() => {
                    this.saving = false;
                });
            } else {
                axios.post(this.route('account.shared-event-users.store'), {
                    user,
                    permissions
                }).then(({ data }) => {
                    this.users.push(data);

                    this.closeModal();
                    this.$toasted.global.success({
                        message: 'User has been saved.',
                        onComplete: () => {
                            window.location.reload();
                        }
                    });
                    this.validationErrors.clear();
                }).catch((error) => {
                    if (error.response && error.response.status === 422) {
                        this.validationErrors.record(get(error, 'response.data.errors'));
                    } else {
                        this.validationErrors.clear();
                        this.$toasted.global.error('There was an error when adding this user.');
                        this.closeModal();
                    }
                }).finally(() => {
                    this.saving = false;
                });
            }
        },

        deleteUser (user) {
            const title = user.invitation_token ? 'Are you sure you want to cancel this co-manager invitation?' : 'Are you sure?';
            const message = user.invitation_token ? 'All access will be removed.' : 'This user will no longer be able to view or manage your event(s).';
            const confirm = user.invitation_token ? 'Yes' : 'Remove User';
            const cancel = user.invitation_token ? 'No, go back' : 'No';

            App.alert().confirm(
                title,
                message,
                {
                    confirmButtonText: confirm,
                    cancelButtonText: cancel
                },
                () => {
                    axios.delete(this.route('account.shared-event-users.destroy', { user: user.email }))
                        .then(() => {
                            const userIndex = findIndex(this.users, { id: user.id });
                            this.users.splice(userIndex, 1);

                            this.showUserModal = false;
                            this.$toasted.global.success({
                                message: 'User has been removed.',
                                onComplete: () => {
                                    window.location.reload();
                                }
                            });
                        }).catch(() => {
                            this.$toasted.global.error(`There was an error deleting the user's permissions.`);
                        });
                }
            );
        },

        showNewUserModal () {
            if (this.userHasReachedLimit) {
                App.alert().paymentRequired(
                    'Please upgrade.',
                    this.reachedLimitText
                );
                return;
            }

            this.setEmptyUser();
            this.showUserModal = true;
        },

        setEmptyUser (email, resetPermissions = true) {
            if (resetPermissions) {
                this.user = { permissions: [] };
            }

            if (email) {
                this.user.email = email;
            }

            this.$validator.errors.clear();
        },

        closeModal () {
            this.showUserModal = false;
        },

        resetModal () {
            this.$validator.errors.clear();
            this.setEmptyUser();
        },

        loadExistingSharedUserPermissions (emailAddress) {
            const userIndex = findIndex(this.users, { email: emailAddress });

            if (userIndex === -1) {
                return this.setEmptyUser(emailAddress, false);
            }

            const user = this.users[userIndex];

            if (!user) {
                return this.setEmptyUser(emailAddress);
            }

            this.user = cloneDeep(user);

            return this.user;
        }
    }
};

</script>
