/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'credit-card-dollar': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>amex visa mastercard charge debit fee</desc><g _fill="currentColor"><path pid="0" d="M3 15a1 1 0 01-1-1V8a.5.5 0 01.5-.5h17a.5.5 0 01.5.5v1.151a.981.981 0 00.623.913l.021.008A.985.985 0 0022 9.159V3a3 3 0 00-3-3H3a3 3 0 00-3 3v11a3 3 0 003 3h5.547a.947.947 0 00.9-.742l.015-.058a.922.922 0 00-.868-1.2zM2 3a1 1 0 011-1h16a1 1 0 011 1v1a.5.5 0 01-.5.5h-17A.5.5 0 012 4z"/><path pid="1" d="M8 11H5a1 1 0 000 2h3a1 1 0 000-2zM17.5 11a6.5 6.5 0 106.5 6.5 6.5 6.5 0 00-6.5-6.5zm-1.084 5.294l2.71 1.015a2.079 2.079 0 01-.665 3.906.249.249 0 00-.211.247V22a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-1a.75.75 0 010-1.5H18c.5 0 .75-.173.75-.514a.745.745 0 00-.166-.53l-2.71-1.015a1.99 1.99 0 01-1.124-1.927 2.057 2.057 0 011.794-1.974.25.25 0 00.206-.246V13a.75.75 0 011.5 0v.5a.25.25 0 00.25.25h1a.75.75 0 010 1.5H17c-.406 0-.75.235-.75.514a.745.745 0 00.166.53z"/></g>'
  }
})
