<template>
    <div class="w-full md:w-144">
        <div class="hidden lg:flex justify-center items-center mb-10">
            <div class="flex items-center px-2 bg-gray-300 border rounded-md">
                <a
                    class="block text-gray-500 text-center py-2 px-4 cursor-pointer transition duration-150 ease-in-out leading-0 hover:text-gray-600"
                    :class="{ 'text-purple hover:text-purple': !isMobileView }"
                    @click="isMobileView = false"
                >
                    <app-icon
                        name="technology-computer"
                        class="h-8 w-8"
                        stroke
                    ></app-icon>
                </a>

                <a
                    class="block text-gray-500 text-center py-2 px-4 cursor-pointer transition duration-150 ease-in-out leading-0 hover:text-gray-600"
                    :class="{ 'text-purple hover:text-purple': isMobileView }"
                    @click="isMobileView = true"
                >
                    <app-icon
                        name="technology-mobile"
                        class="h-8 w-8"
                        stroke
                    ></app-icon>
                </a>
            </div>
        </div>

        <div
            ref="desktopScrollContainer"
            class="wall-preview"
            :class="{ 'mobile': isMobileView }"
        >
            <component
                :is="containerElementTagName"
                ref="eventPageContainer"
                class="h-full"
            >
                <event-wall
                    :event="event"
                    preview
                ></event-wall>

                <portal-target name="event-wall-footer" slim></portal-target>
            </component>
        </div>
    </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import EventWall from '../EventWall.vue';
import MobileWindow from './MobileWindow.vue';

export default {
    components: {
        EventWall,
        MobileWindow
    },

    props: {
        event: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            vueBarOptions: {
                useScrollbarPseudo: true
            }
        };
    },

    computed: {
        ...sync('Wall/*'),

        containerElementTagName () {
            return this.isMobileView ? 'mobile-window' : 'div';
        },

        currentScrollContainer () {
            if (this.isMobileView) {
                return this.$refs.eventPageContainer.$refs.mobileScrollContainer;
            }

            return this.$refs.desktopScrollContainer;
        }
    }
};
</script>
