/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-christmas-snowflake-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>winter</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 8.25a3.75 3.75 0 100 7.5 3.75 3.75 0 100-7.5zM12 1.5v21M9 3.75L12 6l3-2.25M9 20.25L12 18l3 2.25M22.5 12h-21M20.25 9L18 12l2.25 3M3.75 9L6 12l-2.25 3"/></g>'
  }
})
