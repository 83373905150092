/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-shorts-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.5 3.75V9M23.24 21.076L21.79 1.44l-.01-.01c-.03-.4-.36-.7-.75-.7H2.92V.729c-.4 0-.73.3-.75.7L.72 21.05H.71c-.03.38.23.72.61.79l7.63 1.36-.01-.01c.4.07.79-.2.87-.61v-.01l1.25-7.632h-.01c.08-.49.53-.81 1.01-.73.37.06.66.35.72.72l1.25 7.63-.01-.01c.06.4.45.68.86.61v-.01l7.63-1.37v-.01c.37-.07.64-.41.61-.8zM21.96 3.75H2.04M13.5 3.75V9"/><path pid="1" d="M1.568 10.292v-.01a6.016 6.016 0 004.43-5.8v-.01c0-.26-.02-.51-.05-.75H2.02zM22.431 10.292l-.01-.01a6.014 6.014 0 01-4.44-5.8h-.01c0-.26.01-.51.04-.75h3.91z"/></g>'
  }
})
