<template>
    <div class="h-full flex flex-col">
        <form-field-wrapper
            label="Block Title"
            :error="form.errors.get('title')"
            :should-show-error="form.errors.has('title')"
        >
            <input
                v-model="form.title"
                class="form-field"
            >
        </form-field-wrapper>

        <form-field-wrapper
            label="Event description (optional)"
            :error="form.errors.get('description')"
            :should-show-error="form.errors.has('description')"
        >
            <textarea
                v-model="form.description"
                class="form-field"
                rows="4"
            ></textarea>
        </form-field-wrapper>

        <form-field-wrapper>
            <div class="pl-2 text-sm text-gray-600 font-semibold tracking-wide flex items-center">
                <guest-reply-icon
                    class="mr-1"
                    state="Attending"
                    colored
                ></guest-reply-icon> Attending Response
            </div>

            <div class="flex items-center">
                <div class="flex grow items-center font-semibold p-2 shadow rounded-md text-sm bg-white border my-2 draggable-block draggable-handle cursor-grab text-gray-500">
                    <div class="flex-auto text-gray-600">
                        {{ attendingProduct.title }}
                    </div>

                    <button
                        class="shrink cursor-pointer hover:text-purple leading-none"
                        data-descriptor="edit-attending-product"
                        @click="editItem(attendingProduct)"
                    >
                        <app-icon
                            name="settings-cog"
                            class="h-5 w-5"
                            stroke
                        ></app-icon>
                    </button>
                </div>
            </div>

            <div class="pl-2 mt-2 text-sm text-gray-600 font-semibold tracking-wide flex items-center">
                <guest-reply-icon
                    class="mr-1"
                    state="Maybe"
                    colored
                ></guest-reply-icon> Maybe Attending Response
            </div>

            <div class="flex items-center">
                <div class="flex grow items-center font-semibold p-2 shadow rounded-md text-sm bg-white border my-2 draggable-block draggable-handle cursor-grab text-gray-500">
                    <div class="flex-auto text-gray-600">
                        {{ maybeProduct.title }}
                    </div>

                    <div class="shrink mr-2 leading-none">
                        <toggle-switch
                            v-model="maybeProduct.active"
                            small
                        ></toggle-switch>
                        <input
                            v-model="maybeProduct.active"
                            type="checkbox"
                            class="hidden"
                        >
                    </div>

                    <button
                        class="shrink cursor-pointer hover:text-purple leading-none"
                        @click="editItem(maybeProduct)"
                    >
                        <app-icon
                            name="settings-cog"
                            class="h-5 w-5"
                            stroke
                        ></app-icon>
                    </button>
                </div>
            </div>

            <div class="pl-2 mt-2 text-sm text-gray-600 font-semibold tracking-wide flex items-center">
                <guest-reply-icon
                    class="mr-1"
                    state="NotAttending"
                    colored
                ></guest-reply-icon> Not Attending Response
            </div>

            <div class="flex items-center">
                <div class="flex grow items-center font-semibold p-2 shadow rounded-md text-sm bg-white border my-2 draggable-block draggable-handle cursor-grab text-gray-500">
                    <div class="flex-auto text-gray-600">
                        {{ declineProduct.title }}
                    </div>

                    <div class="shrink mr-2 leading-none">
                        <toggle-switch
                            v-model="declineProduct.active"
                            small
                        ></toggle-switch>
                        <input
                            v-model="declineProduct.active"
                            type="checkbox"
                            class="hidden"
                        >
                    </div>

                    <button
                        class="shrink cursor-pointer hover:text-purple leading-none"
                        @click="editItem(declineProduct)"
                    >
                        <app-icon
                            name="settings-cog"
                            class="h-5 w-5"
                            stroke
                        ></app-icon>
                    </button>
                </div>
            </div>
        </form-field-wrapper>

        <form-field-wrapper>
            <form-panel
                v-model="form.maxCapacity.enabled"
                class="mt-8"
                label="Set Event Capacity Limit"
                tooltip="Limit the total number of attendees to your event. “Maybe” replies will NOT count toward this limit."
            >
                <template #trigger-appended>
                    <premium-feature feature="LimitedCapacity" class="ml-2"></premium-feature>
                </template>

                <form-field-wrapper
                    :error="form.errors.get('maxCapacity.slots')"
                    :should-show-error="form.errors.has('maxCapacity.slots')"
                >
                    <div class="flex items-end">
                        <input
                            v-model.number="form.maxCapacity.slots"
                            class="form-field w-32 mr-4"
                            type="number"
                            name="number-available"
                            step="1"
                            min="1"
                        >
                        <div class="py-2 bold uppercase tracking-wide">
                            Available
                        </div>
                    </div>
                </form-field-wrapper>

                <form-field-wrapper label="When no longer available...">
                    <select-list v-model="form.maxCapacity.showMessage">
                        <select-option
                            :id="true"
                            label="Disable and display a message"
                            class="my-2"
                        ></select-option>
                        <select-option
                            :id="false"
                            label="Hide completely"
                            class="my-2"
                        ></select-option>
                    </select-list>
                </form-field-wrapper>

                <form-field-wrapper
                    v-if="form.maxCapacity.showMessage"
                    label="Message to display"
                    :error="form.errors.get('maxCapacity.message')"
                    :should-show-error="form.errors.has('maxCapacity.message')"
                >
                    <tiptap-editor
                        v-model="form.maxCapacity.message"
                        class="form-field"
                        simple
                    ></tiptap-editor>
                </form-field-wrapper>
            </form-panel>
        </form-field-wrapper>

        <form-field-wrapper>
            <form-panel
                v-model="form.guestTitles.enabled"
                label="Request Guest Titles/Salutations"
                tooltip="Request Guest Titles/Salutations: Ask guests to select their title or salutation (e.g. Mr., Mrs., Dr., etc.). You can customize the list in Setup > Event Settings > Titles and Salutations."
            >
                <toggle-switch v-model="form.guestTitles.required" class="mt-4 w-full">
                    <div
                        v-tippy
                        content="Guests will be required to select a title/salutation."
                        class="tooltip-text"
                    >
                        Make Required
                    </div>
                </toggle-switch>
            </form-panel>
        </form-field-wrapper>

        <form-panel
            v-model="form.limitedAttendancePerGroup.enabled"
            label="Limit Max Attendees Per Group"
            :tooltip="limitedAttendancePanelTooltip"
            :disabled="!canLimitAttendance"
        >
            <div class="flex items-end">
                <input
                    :value="form.limitedAttendancePerGroup.limit"
                    class="form-field w-32 mr-4"
                    type="number"
                    name="maximum"
                    step="1"
                    min="1"
                    @input="form.limitedAttendancePerGroup.limit = Number($event.target.value)"
                >

                <div class="py-2 bold uppercase tracking-wide">Maximum</div>
            </div>
        </form-panel>

        <div v-if="event.user_id" class="flex-1 flex items-end mt-8">
            <div class="w-full text-center pt-4 border-t">
                <button
                    v-tippy
                    class="button-text font-normal hover:text-teal"
                    content="Switch from simple RSVP response options to ticket tiers."
                    type="button"
                    @click="switchToTicketingSetup"
                >
                    <app-icon name="rotate-back-stroke"></app-icon>
                    <span class="ml-2">Switch to Ticketing</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import find from 'lodash/find';
import axios from 'axios';
import BlockableGeneralSettingsMixin from '@/mixins/BlockableGeneralSettingsMixin';
import LimitedAttendanceSettingsMixin from '@/mixins/LimitedAttendanceSettingsMixin';

export default {
    name: 'FormRsvpBlockGeneralSettings',

    mixins: [
        BlockableGeneralSettingsMixin,
        LimitedAttendanceSettingsMixin
    ],

    props: {
        block: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            itemsProperty: 'products'
        };
    },

    computed: {
        attendingProduct () {
            return find(this.form.products, {
                short_guest_state: 'Attending'
            });
        },

        maybeProduct () {
            return find(this.form.products, {
                short_guest_state: 'Maybe'
            });
        },

        declineProduct () {
            return find(this.form.products, {
                short_guest_state: 'NotAttending'
            });
        }
    },

    methods: {
        productsUpdated (newProducts) {
            newProducts.map((productSelection, index) => {
                productSelection.sort = index + 1;

                return productSelection;
            });

            this.form.products = newProducts;
        },

        async confirmSwitchToTicketingSetup () {
            return App.alert().confirmAsync(
                'You are changing your event\'s setup.',
                'Your current response options (and their settings) will be removed. This action cannot be undone.',
                {
                    confirmButtonText: 'Switch to Ticketing',
                    reverseButtons: true
                }
            );
        },

        async switchToTicketingSetup () {
            try {
                if (!await this.confirmSwitchToTicketingSetup()) {
                    return;
                }

                await axios.patch(this.route('api.events.update', this.event), {
                    setup_type: 'tickets'
                });

                this.$toasted.global.success({
                    message: 'Event\'s new setup saved.',
                    onComplete: () => {
                        window.location.reload();
                    }
                });
            } catch (error) {
                this.$toasted.error('An error occurred while switching the event\'s setup.');
            }
        }
    }
};
</script>
