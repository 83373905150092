/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'breakfast-croissant-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food pastry bakery</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.53 13.61c2.67.68 4.895 3.13 5.98 6.43h0c.25.78 1.1 1.2 1.88.95.38-.13.7-.41.87-.78h-.01c2.33-5.27.31-11.46-4.68-14.33M6.4 5.914h-.01c-4.97 2.84-7 8.96-4.73 14.21l-.01-.01a1.49 1.49 0 001.95.81c.4-.17.7-.5.84-.91 1.09-3.3 3.3-5.75 5.98-6.429"/><path pid="1" d="M12 14.17c2.926 0 4.939-5.94 5.68-8.55h-.01c.21-.75-.18-1.53-.9-1.81l-.01-.01c-1.53-.61-3.16-.91-4.8-.9l-.01-.01c-1.65-.02-3.27.29-4.8.9v-.01c-.72.28-1.11 1.06-.9 1.8.74 2.6 2.75 8.54 5.68 8.54zM1.41 11.85l5.19 4.32M17.4 16.17l5.2-4.33"/></g>'
  }
})
