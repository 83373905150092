<template>
    <div class="container">
        <div class="py-2 font-semibold text-xl text-black border-b">Multi-factor Authentication (MFA)</div>

        <div class="flex flex-col w-full md:w-2/4 mt-4 space-y-4">
            <toggle-switch
                class="w-2/4"
                :disabled="isMfaToggleDisabled"
                :tooltip="mfaToggleTooltip"
                :value="isMfaEnabled"
                @input="toggleMfaActivity"
            >
                Require email MFA upon login
            </toggle-switch>

            <toggle-switch
                :value="isMfaOnSharedUsersEnabled"
                class="mt-2 w-3/4"
                :tooltip="mfaOnSharedUsersTooltip"
                :disabled="isMfaOnSharedUsersToggleDisabled"
                @input="toggleMfaOnSharedUsersActivity"
            >
                Require email MFA for shared users
            </toggle-switch>
        </div>

        <div class="py-2 font-semibold text-xl text-black border-b mt-8">Password</div>

        <div class="w-full md:w-1/4 mt-8">
            <form-field-wrapper label="Current Password" :error="newPasswordForm.errors.get('current_password')">
                <input
                    v-model="newPasswordForm.current_password"
                    class="form-field"
                    type="password"
                    placeholder="Enter Current Password"
                >
            </form-field-wrapper>

            <form-field-wrapper label="New Password" :error="newPasswordForm.errors.get('password')">
                <password-input v-model="newPasswordForm.password" placeholder="Enter New Password"></password-input>
            </form-field-wrapper>

            <form-field-wrapper label="Password Confirmation">
                <input
                    v-model="newPasswordForm.password_confirmation"
                    class="form-field"
                    type="password"
                    placeholder="Re-enter New Password"
                >
            </form-field-wrapper>
        </div>

        <div class="flex mt-8">
            <stateful-button
                class="flex-1 md:flex-none button-primary"
                :loading="newPasswordForm.processing"
                :disabled="!newPasswordForm.isModified()"
                @click="changePassword"
            >
                Change Password
            </stateful-button>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import Form from '@/validation/Form';

export default {
    name: 'MyAccountSecurityTab',

    props: {
        initialUser: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            mfaForm: new Form({
                settings: cloneDeep(this.initialUser.settings)
            }),
            newPasswordForm: new Form({
                current_password: '',
                password: '',
                password_confirmation: ''
            })
        };
    },

    computed: {
        isMfaEnabled () {
            if (this.initialUser.isAdmin || this.initialUser.sharedEventsRequireMfa) {
                return true;
            }

            const { enabled, requiredByAdmin } = this.mfaForm.settings.mfa;

            return requiredByAdmin || enabled;
        },

        isMfaOnSharedUsersEnabled () {
            const { requiredByAdmin, forceOnSharedUsers } = this.mfaForm.settings.mfa;

            return requiredByAdmin || forceOnSharedUsers;
        },

        isMfaOnSharedUsersToggleDisabled () {
            return this.mfaForm.settings.mfa.requiredByAdmin || !this.isMfaEnabled;
        },

        isMfaToggleDisabled () {
            if (this.initialUser.isAdmin) {
                return true;
            }

            if (this.initialUser.sharedEventsRequireMfa) {
                return true;
            }

            if (this.mfaForm.settings.mfa.requiredByAdmin) {
                return true;
            }

            return false;
        },

        mfaOnSharedUsersTooltip () {
            if (this.mfaForm.settings.mfa.requiredByAdmin) {
                return 'An administrator requires Multi-factor Authentication for users your events are shared with.';
            }

            return 'Any user with shared access to your event(s) will also be required to login with MFA.';
        },

        mfaToggleTooltip () {
            if (!this.isMfaToggleDisabled) {
                return 'Add an additional layer of security to your account. RSVPify will send a link to your registered email address to validate new logins.';
            }

            if (this.initialUser.isAdmin) {
                return 'Administrators cannot disable Multi-factor Authentication for their accounts.';
            }

            return 'An administrator or user who has shared event(s) with you requires Multi-factor Authentication for your account.';
        }
    },

    methods: {
        changePassword () {
            this.newPasswordForm.patch(this.route('api.account.update'))
                .then(() => {
                    this.newPasswordForm.restore();
                    this.$toasted.show('Your password has been updated.');
                });
        },

        async saveMfaForm () {
            try {
                await this.mfaForm.patch(this.route('api.account.update'));

                this.$toasted.show('Multi-factor authentication settings updated.');
            } catch (error) {
                this.$toasted.global.error('Something went wrong while updating multi-factor authentication settings.');
            }
        },

        toggleMfaActivity (enabled) {
            this.mfaForm.settings.mfa.enabled = enabled;

            this.saveMfaForm();
        },

        toggleMfaOnSharedUsersActivity (enabled) {
            this.mfaForm.settings.mfa.forceOnSharedUsers = enabled;

            this.saveMfaForm();
        }
    }
};
</script>
