/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-baseball-bat-ball-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.432 20.689l-2.13-2.13h0c-.59-.59-1.54-.59-2.13-.01a1.5 1.5 0 00-.01 2.12l2.122 2.122-.01-.01a1.5 1.5 0 002.12-.01c.58-.59.58-1.54-.01-2.13zM3.311 18.56S15.384 3.3 16.841 1.84v-.01c1.51-1.42 3.88-1.33 5.3.18a3.74 3.74 0 01-.01 5.11c-1.47 1.46-16.72 13.54-16.72 13.54zM10.94 16.31l-3.25-3.25M5.56 15.73l2.71 2.71M20.25 17.25a3 3 0 100 6 3 3 0 100-6z"/></g>'
  }
})
