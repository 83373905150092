<template>
    <div
        v-theme="'form.text'"
        class="px-4 py-12 group"
        :class="{ 'cursor-pointer': !isEditMode }"
        @click="continueWithNewSubmission"
    >
        <tiptap-editor
            v-model="textHtml"
            :read-only="!isEditMode"
            show-embed-video-button
            hint-classes="-my-4 -mx-2"
            with-hint
        ></tiptap-editor>

        <slot name="block-footer" :complete-block="completeBlock"></slot>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import FormBlock from '@/mixins/FormBlock';
import HandleFormEnterKeyPress from '@/mixins/HandleFormEnterKeyPress';
import SelfEditableBlockMixin from '@/mixins/SelfEditableBlockMixin';

export default {
    mixins: [FormBlock, HandleFormEnterKeyPress, SelfEditableBlockMixin],

    behaviour: {
        render: {
            when (block, submission) {
                return !submission.original_submission;
            },
            unless (block, submission) {
                return false;
            }
        }
    },

    props: {
        block: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            loading: false,
            blockId: this.block.pivot.id,
            textHtml: this.block.pivot.settings.html,
            syncSelfEditableSettings: {
                textHtml: 'html'
            }
        };
    },

    computed: {
        event: get('Event/event')
    },

    methods: {
        async completeBlock () {
            return (!this.isEditMode)
                ? this.$emit('complete-block', this.getCompletionObject())
                : false;
        },

        continueWithNewSubmission (event) {
            if (event.target.tagName.toLowerCase() === 'a' && event.target.href) {
                return;
            }

            this.completeBlock();
        }
    }
};
</script>
