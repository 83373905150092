/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'technology-monitor-exchange-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>swap arrow network computer</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25 3.75H5.24c-1.66 0-3 1.34-3 3 0 0 0 0 0 0v3"/><path pid="1" d="M.75 8.25l1.5 1.5 1.5-1.5M6.75 5.25l1.5-1.5-1.5-1.5"/><rect pid="2" width="12" height="7.5" x=".75" y="12.75" rx="1.5"/><path pid="3" d="M6.75 20.25v3M4.5 23.25H9"/><rect pid="4" width="12" height="7.5" x="11.25" y=".75" rx="1.5"/><path pid="5" d="M17.25 8.25v3M15 11.25h4.5"/></g>'
  }
})
