/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left-thick-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M23.25 12.394v-.001c0-1.25-1.01-2.25-2.25-2.25H8.42l3.05-3.06v-.01c.87-.88.87-2.31-.01-3.19-.88-.88-2.31-.88-3.19 0l-6.9 6.89v-.01c-.88.87-.88 2.3-.01 3.18 0 0 0 0 0 0l6.89 6.89-.01-.01c.87.87 2.3.88 3.18 0 .87-.88.88-2.31 0-3.19l-.01-.01-3.06-3.06h12.568a2.26 2.26 0 002.25-2.25l-.001-.01z"/>'
  }
})
