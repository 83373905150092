<template>
    <button
        v-theme="['form.button']"
        class="border border-smoke-200 flex items-center mx-auto shadow-md rounded-md p-2 -mt-8"
        @click="$emit('click')"
    >
        <app-icon
            class="mx-2 h-6 w-6"
            name="arrow-up-circle"
        ></app-icon>

        <div class="mx-2 text-left text-sm">{{ $t('button-back') }}</div>
    </button>
</template>

<script>
export default {
    name: 'RsvpPreviousButton'
};
</script>
