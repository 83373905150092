/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-sanitizer-liquid-drop-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18.251 21.664h3l-.01-.001c1.1 0 2-.9 2-2v-10 0a2 2 0 00-2-2h-4.01c-1.11 0-2 .89-2 2 0 0 0 0 0 0v6"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.251 4.66h4v2.22c0 .42-.35.77-.78.77h-2.45l-.01-.001a.788.788 0 01-.78-.78V4.63z" transform="matrix(1 0 -.00001 1 38.502 12.327)"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="2" d="M23.25 11.66h-8M23.25 17.66h-5M.74 18.83l2.93 2.35-.01-.01c.93.74 2.08 1.15 3.27 1.15h6.03-.01c.96 0 1.75-.79 1.75-1.75v0h0c-.01-.97-.79-1.75-1.75-1.76H8.42"/><path pid="3" d="M.9 13.586h3.5l-.01-.001c1.03 0 2.05.3 2.91.88l2 1.33h0c.77.46 1.02 1.46.55 2.24-.02.02-.03.04-.05.06v0-.01c-.46.68-1.36.92-2.09.55l-2.63-1.58M12.251 9.164v0a2.5 2.5 0 01-5 0c0-1.875 2.5-5 2.5-5s2.5 3.12 2.5 5zM19.25 1.66v3M13.251 3.664l.44-.895h-.01c.33-.68 1.03-1.11 1.78-1.11h6.76"/></g>'
  }
})
