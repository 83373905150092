import { get } from 'vuex-pathify';

export default {
    data () {
        return {
            shouldShowErrorMessages: false
        };
    },

    computed: {
        isPreview: get('Form/isPreview')
    },

    mounted () {
        if (!this.preview) {
            document.addEventListener('keydown', this.handleKeydown);
        }
    },

    beforeDestroy () {
        if (!this.preview) {
            document.removeEventListener('keydown', this.handleKeydown);
        }
    },

    methods: {
        complete () {
            this.shouldShowErrorMessages = true;

            if (!this.errors.any()) {
                this.completeBlock();
            }
        },

        enterWasPressed (event) {
            return event.key === 'Enter' || event.which === 13 || event.keyCode === 13;
        },

        handleKeydown (event) {
            if (this.enterWasPressed(event) && this.isActive) {
                this.complete();
            }
        }
    }
};
