/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'performance-theater-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 5.25v16.5c0 .82-.68 1.5-1.5 1.5-.83 0-1.5-.68-1.5-1.5M3.75 21.75v-10.5M17.25 5.25v16.5h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51"/><path pid="1" d="M20.25 21.75h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51V2.23v0c0-.83-.68-1.5-1.5-1.5H2.23c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v19.5h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51M20.25 21.75v-10.5M.75 5.25h22.5M5.25 23.25h13.5"/></g>'
  }
})
