/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-map-pin-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>marker gps</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25 11.769l-.01-.001c-.2-.01-.38-.04-.56-.11l-5.92-2.37h0a.747.747 0 00-.98.41c-.04.08-.06.18-.06.28v9.35H.71c-.01.61.37 1.16.94 1.39l6 2.4h0c.35.14.75.14 1.11-.01l6.38-2.56v-.01c.35-.15.75-.15 1.11 0l5.91 2.36h0c.38.15.82-.04.97-.42a.93.93 0 00.05-.29v-9.37c0-.62-.38-1.17-.95-1.4l-6-2.4-.01-.01a1.45 1.45 0 00-1.12 0l-.91.36M8.25 11.77v11.48M15.75 9v11.48M11.25 6.75v9M11.25.75a3 3 0 100 6 3 3 0 100-6z"/></g>'
  }
})
