import { Node } from 'tiptap';
import CheckinQrCode from './CheckinQrCode';

export default class CheckinQrCodeNode extends Node {
    get name () {
        return 'checkinQrCode';
    }

    commands ({ type }) {
        return (attrs) => {
            return (state, dispatch) => {
                const { selection } = state;

                const position = selection.$cursor
                    ? selection.$cursor.pos
                    : selection.$to.pos;

                const node = type.create(attrs);
                const transaction = state.tr.insert(position, node);

                dispatch(transaction);
            };
        };
    }

    get schema () {
        return {
            attrs: {
                backgroundColor: '#000000',
                foregroundColor: '#FFFFFF'
            },
            content: 'inline*',
            group: 'block',
            draggable: true,
            selectable: true,
            parseDOM: [{
                tag: 'div[data-checkin-qr-code]',
                getAttrs: (dom) => {
                    return {
                        backgroundColor: dom.getAttribute('data-background-color'),
                        foregroundColor: dom.getAttribute('data-foreground-color')
                    };
                }
            }],
            toDOM: (node) => {
                return ['div', {
                    'data-checkin-qr-code': true,
                    'data-background-color': node.attrs.backgroundColor,
                    'data-foreground-color': node.attrs.foregroundColor
                }, `{checkin-qr-code | backgroundColor=${node.attrs.backgroundColor} | foregroundColor=${node.attrs.foregroundColor}}`];
            }
        };
    }

    get view () {
        return CheckinQrCode;
    }
}
