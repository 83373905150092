/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'science-molecule-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>atom</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 9.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5z"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="1" d="M12 6.75a15 5.25 0 100 10.5 15 5.25 0 100-10.5z" transform="matrix(.7071 -.70711 .7071 .7071 -4.974 12.009)"/><path pid="2" d="M12-3a5.25 15 0 100 30 5.25 15 0 100-30z" transform="matrix(.7071 -.70711 .7071 .7071 -4.974 12.009)"/></g>'
  }
})
