<template>
    <div v-show="menuBlocks.length > 0" v-click-outside="close">
        <button
            id="menu-button"
            class="absolute text-white text-center left-0 m-6 focus:outline-none active:outline-none"
            :class="styles.menuButton"
            @click="toggle"
        >
            <div v-theme="['wall.button']" class="py-1 px-2 shadow-md border-2 border-smoke-200 rounded-md">
                <app-icon
                    v-if="!isOpen"
                    name="navigation-menu"
                    class="h-6 w-6 cursor-pointer"
                ></app-icon>

                <app-icon
                    v-else
                    name="close"
                    class="h-6 w-6 cursor-pointer"
                ></app-icon>
            </div>
        </button>

        <nav
            v-if="!preview || isOpen"
            class="event-menu"
            :class="{ 'menu-open': isOpen }"
        >
            <div :class="styles.menuContent">
                <a
                    v-for="block in menuBlocks"
                    :key="block.pivot.id"
                    v-theme="'wall.menu-item'"
                    class="block py-2 px-4 text-lg font-medium no-underline"
                    role="button"
                    @click="focusBlock(`#block-anchor-${block.pivot.id}`)"
                >
                    {{ getMenuTitle(block) }}
                </a>
            </div>
        </nav>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import getValue from 'lodash/get';
import DeterminesBlockMenuTitle from '@/mixins/DeterminesBlockMenuTitle';

export default {
    name: 'WallMenu',

    mixins: [DeterminesBlockMenuTitle],

    props: {
        event: {
            type: Object,
            required: true
        },
        global: {
            type: Boolean,
            default: false
        },
        isEditMode: {
            type: Boolean,
            default: false
        },
        preview: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            isOpen: false
        };
    },

    computed: {
        activeBlock: get('Form/activeBlock'),

        isBrandingVisible () {
            if (!this.global) {
                return false;
            }

            if (this.event.plan.features.BrandedRegistrationExperience.enabled) {
                return true;
            }

            if (this.event.plan.features.BrandedConfirmationScreen.enabled && this.isInConfirmationScreen) {
                return true;
            }

            return false;
        },

        isInConfirmationScreen () {
            const block = this.event.form.blocks[this.activeBlock.index];

            return ['ConfirmationSection', 'NotAttendingSection']
                .includes(getValue(block, 'pivot.settings.section'));
        },

        menuBlocks () {
            return this.event.wall.blocks.filter((block) => {
                return !!block.pivot && block.pivot.settings.displayInMenu;
            });
        },

        styles () {
            return {
                menuButton: {
                    'mt-16': this.preview && !this.isEditMode,
                    'top-0': !this.isBrandingVisible,
                    'top-6': this.isBrandingVisible
                },
                menuContent: {
                    'mt-18': !this.preview,
                    'mt-28': this.preview
                }
            };
        }
    },

    methods: {
        close () {
            this.isOpen = false;
        },

        focusBlock (elementId) {
            App.$emit('toggle-event-wall-display', true);

            this.$nextTick(() => {
                window.location.href = elementId;

                this.isOpen = false;
            });
        },

        toggle () {
            this.isOpen = !this.isOpen;
        }
    }
};
</script>
