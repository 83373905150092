/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-tank-top-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>beach sun casual</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.25 10.994v-.01c0-.64-.41-1.21-1.01-1.42h0a2.994 2.994 0 01-2-2.83v-5.26c0-.42-.34-.75-.75-.75h-1.51c-.42 0-.75.33-.75.75 0 0 0 0 0 0v1.5c0 1.24-1.01 2.25-2.25 2.25-1.25 0-2.25-1.01-2.25-2.25v-1.5 0c0-.42-.34-.75-.75-.75H7.47c-.42 0-.75.33-.75.75 0 0 0 0 0 0v5.25h0c0 1.27-.81 2.4-2.01 2.82v-.01c-.6.21-1.01.77-1 1.41v11.5h0c0 .41.33.74.75.74h15-.01c.41 0 .75-.34.75-.75z"/>'
  }
})
