/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fruit-cherry-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.87 12.75a5.25 5.25 0 100 10.5 5.25 5.25 0 100-10.5z"/><path pid="1" d="M12 21.24h0c1.79 2.27 5.09 2.66 7.37.87 2.27-1.8 2.66-5.1.87-7.38a5.261 5.261 0 00-7.38-.88c-.33.25-.62.54-.88.87"/><path pid="2" d="M6.374 12.967C7.76 6.756 11.232 2.58 17.624.75c-1.46 3.71-3.55 7.59-1.5 12"/></g>'
  }
})
