/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-can-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 6.75h10.5M10.5 6.75l-2.25-3M13.87 3.75h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M16.125 1.5h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M11.625.75h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38V.729M9 6.75l-2.68 3.33h-.01c-.22.26-.33.59-.33.93v9.52l-.01-.01c0 .29.08.58.25.83l.8 1.2-.01-.01c.27.41.74.67 1.24.67h7.4v-.001c.5-.01.96-.26 1.24-.67l.8-1.2h-.01c.16-.25.25-.54.25-.84v-9.52h0c0-.35-.12-.68-.33-.94l-2.68-3.34"/><path pid="1" d="M12 11.25a3 3 0 100 6 3 3 0 100-6z"/></g>'
  }
})
