/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cards-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>game casino deck</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.712 6.023L1.92 7.055h-.01c-.81.17-1.32.96-1.15 1.77l2.84 13.11-.01-.01c.17.8.96 1.31 1.77 1.14l6.977-1.52"/><path pid="1" d="M3.493 9h-.01c.2-.05.4.08.44.28 0 0 0 0 0 0"/><path pid="2" d="M3.206 9.449h0c-.05-.21.08-.41.28-.45v-.01"/><path pid="3" d="M3.651 9.73h-.01a.38.38 0 01-.45-.29l-.01-.01"/><path pid="4" d="M3.93 9.29h0c.04.2-.09.4-.29.44h-.01"/></g><rect pid="5" width="11.932" height="16.407" x="9.363" y="5.675" rx="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" transform="matrix(.95276 .3037 -.30371 .95276 4.939 -4)"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="6" d="M16.235 11.036l-3.04 2.16 1.22 3.52 3.03-2.17-1.23-3.53zM13.94 7.173l-.01-.01c.19.06.3.27.24.47"/><path pid="7" d="M13.473 7.416v-.01c.06-.2.27-.31.47-.25M13.71 7.887h0a.384.384 0 01-.25-.47v-.01"/><path pid="8" d="M14.18 7.64v-.01c-.07.19-.28.3-.48.24M16.94 19.869l-.01-.01c.19.06.3.27.24.47M16.471 20.11v-.01c.06-.2.27-.31.47-.25M16.71 20.584h0a.39.39 0 01-.25-.48"/><path pid="9" d="M17.185 20.34v-.01c-.07.19-.28.3-.48.24l-.01-.01M18.648 6.32V2.237l-.01-.01c-.04-.82-.69-1.47-1.51-1.5H8.27L8.26.71c-.85-.02-1.55.64-1.59 1.48v13.42h-.01c-.01.75.56 1.39 1.31 1.48"/><path pid="10" d="M9.4 2.99h0c.2 0 .37.16.37.37h-.01M9.026 3.36h-.01c0-.21.16-.38.37-.38M9.4 3.744h0a.393.393 0 01-.38-.38M9.776 3.37v-.01c-.01.2-.17.37-.38.37l-.01-.01"/></g>'
  }
})
