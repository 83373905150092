/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'users-upload-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.851 6.467a.5.5 0 01-.451.283H19a.5.5 0 00-.5.5v2.25a1 1 0 11-2 0v-2.25a.5.5 0 00-.5-.5h-.9a.5.5 0 01-.391-.812l2.4-3a.517.517 0 01.782 0l2.4 3a.5.5 0 01.06.529zM12.023 8.534a.5.5 0 00-.182.771c.3.37.563.767.787 1.187a.5.5 0 00.7.19A7.93 7.93 0 0117.5 9.5a.32.32 0 00.169-.593 6 6 0 00-5.646-.373z"/><ellipse pid="1" rx="3.513" ry="3.5" cx="14.013" cy="3.5"/><path pid="2" d="M11.563 11.733A6.026 6.026 0 000 13.333.6.6 0 00.6 14h9.411a.5.5 0 00.441-.264 7.962 7.962 0 011.024-1.484.5.5 0 00.087-.519z"/><ellipse pid="3" rx="3.513" ry="3.5" cx="5.513" cy="3.5"/></g>'
  }
})
