<template>
    <simple-picker
        :items="items"
        item-label="name"
        item-value="value"
        v-bind="$attrs"
        v-on="$listeners"
    ></simple-picker>
</template>

<script>
export default {
    computed: {
        availablePercentages () {
            return [100, 90, 80, 70, 60, 50, 40, 30, 20, 10];
        },

        items () {
            return this.availablePercentages.map((percentage) => {
                return {
                    name: `Logo Size ${percentage}%`,
                    value: `${percentage}%`
                };
            });
        }
    }
};
</script>
