export default {
    data () {
        return {
            selectedBlockElement: null,
            selectedBlockElementBox: { width: 100, height: 100 }
        };
    },

    created () {
        gsap.registerPlugin(Flip);
    },

    methods: {
        animateBlockToScreenCenter (selector) {
            /**
             * Fix the block container height to the current height because
             * animating the block forces a redraw which looks too jumpy
             */
            const blockContainer = document.querySelector(selector);
            blockContainer.style.height = `${blockContainer.getBoundingClientRect().height}px`;
            this.selectedBlockElement = blockContainer.firstElementChild;

            // Bring the block element in front of the settings/drag buttons
            this.selectedBlockElement.style.zIndex = 100;

            // Remember the initial block dimensions so we can animate properly
            const { width, height } = this.selectedBlockElement.getBoundingClientRect();

            this.selectedBlockElementBox.width = width;
            this.selectedBlockElementBox.height = height;

            this.$nextTick(() => {
                document.body.style.overflowY = 'hidden';

                // Apply and animate box shadow
                gsap.to(this.selectedBlockElement, {
                    duration: 0.5,
                    boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.25) 0px 25px 50px -12px'
                });

                // Move and fit to center
                Flip.fit(this.selectedBlockElement, '#blockPositioner', {
                    duration: 0.5,
                    ease: 'power2.inOut',
                    onComplete: () => {
                        /**
                         * Once animated into the middle of the screen,
                         * remove the fixed height properties and make it
                         * stay centered properly if the block height is being
                         * altered
                         */
                        gsap.set(this.selectedBlockElement, {
                            clearProps: 'height',
                            position: 'fixed',
                            left: 'calc(50% + 185px)',
                            top: '50%',
                            transform: 'translate(-50%, -50%)'
                        });

                        this.selectedBlockElement.classList.add('rounded-md', 'max-h-screen-80', 'overflow-y-auto');
                    }
                });
            });
        },

        animateBlockBackToOriginalPosition () {
            const element = this.selectedBlockElement;
            element.parentElement.style.height = 'auto';

            const state = Flip.getState(element);

            gsap.to(this.selectedBlockElement, {
                duration: 1,
                boxShadow: 'none'
            });

            gsap.set(element, {
                clearProps: 'height,position,left,top,transform'
            });

            this.selectedBlockElement.classList.remove('rounded-md', 'max-h-screen-80', 'overflow-y-auto');

            // Apply and animate box shadow back to 0
            gsap.to(this.selectedBlockElement, {
                duration: 0.5,
                boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.25) 0px 0px 0px 0px'
            });

            Flip.from(state, {
                duration: 0.5,
                ease: 'power2.inOut',
                position: 'relative',
                height: 'auto',
                left: 'auto',
                top: 'auto',
                transform: 'none',
                onComplete: () => {
                    element.style.zIndex = null;
                    document.body.style.overflowY = 'auto';
                }
            });
        }
    }
};
