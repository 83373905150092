/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vip': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M18.25 10.75a.752.752 0 00-.75-.75H17a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h.5a.752.752 0 00.75-.75z"/><path pid="1" d="M3 4.75a2.5 2.5 0 00-2.5 2.5v9.5a2.5 2.5 0 002.5 2.5h18a2.5 2.5 0 002.5-2.5v-9.5a2.5 2.5 0 00-2.5-2.5zm12.25 7.5v-3A.75.75 0 0116 8.5h1.5a2.25 2.25 0 010 4.5H17a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0zM11 10a.75.75 0 010-1.5h2a.75.75 0 010 1.5.25.25 0 00-.25.25v4a.25.25 0 00.25.25.75.75 0 010 1.5h-2a.75.75 0 010-1.5.25.25 0 00.25-.25v-4A.25.25 0 0011 10zm-6.75-.75a.75.75 0 011.5 0v1.5a6.793 6.793 0 00.519 2.591.25.25 0 00.462 0 6.793 6.793 0 00.519-2.591v-1.5a.75.75 0 011.5 0v1.5A8.3 8.3 0 017.1 15.7a.75.75 0 01-1.2 0 8.3 8.3 0 01-1.65-4.95z"/></g>'
  }
})
