<template>
    <form-display-block-inner-wrapper
        :scrollable="!isEditMode"
        :class="styles.wrapper"
        :is-full-width="isFullWidth"
    >
        <div class="w-full scrollbar-none" :class="styles.wrapperChild">
            <img
                v-if="logoImage && shouldShowLogo"
                v-theme="'form.logo'"
                :src="logoImage"
                alt="logo"
                class="pt-20 block mx-auto"
            >

            <premium-feature
                v-if="shouldShowPremiumFeatureIndicator"
                :feature="block.properties.feature"
                block
                class="absolute -mt-6"
            ></premium-feature>

            <div
                v-theme="blockContainerTheme"
                class="rounded-md relative transition-all"
                :class="styles.block"
            >
                <div class="h-full w-full">
                    <component
                        :is="`form-${block.slug}-block`"
                        v-if="isDisplayed"
                        :editable="isEditMode"
                        :block="block"
                        :processing="processing"
                        :show-complete-button-message="showCompleteButtonMessage"
                        @complete-block="selfCompletedBlock($event)"
                        @go-back="$emit('go-back')"
                    >
                        <template #block-header>
                            <transition name="fade">
                                <div v-if="shouldShowPreviousButton" class="absolute left-0 right-0 top-0 mx-auto mt-3">
                                    <rsvp-previous-button
                                        @click="goBack"
                                    ></rsvp-previous-button>
                                </div>
                            </transition>
                        </template>

                        <template #block-footer="{ completeBlock, nextButtonDisabled }">
                            <transition name="fade">
                                <div v-if="shouldShowNextButton" class="absolute left-0 right-0 bottom-0 mx-auto mb-5">
                                    <rsvp-next-button
                                        class="-mb-14"
                                        :loading="processing"
                                        :disabled="nextButtonDisabled"
                                        :show-complete-button-message="showCompleteButtonMessage"
                                        @click="userCompletedBlock(completeBlock) "
                                    >
                                    </rsvp-next-button>
                                </div>
                            </transition>
                        </template>
                    </component>
                </div>
            </div>

            <div v-if="!isEditMode && !isInConfirmationScreen" class="h-64"></div>
        </div>

        <slot v-if="block.pivot"></slot>
    </form-display-block-inner-wrapper>
</template>

<script>
import { get } from 'vuex-pathify';
import getValue from 'lodash/get';
import InteractsWithFeatureFlags from '@/mixins/InteractsWithFeatureFlags';

export default {
    mixins: [InteractsWithFeatureFlags],

    props: {
        block: {
            type: Object,
            required: true
        },
        preview: {
            type: Boolean,
            default: false
        },
        processing: {
            type: Boolean,
            default: false
        },
        isFullWidth: {
            type: Boolean,
            default: false
        },
        showCompleteButtonMessage: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            isDisabledBlock: false
        };
    },

    computed: {
        ...get('Form', [
            'isEditMode',
            'isPreview',
            'activeBlock'
        ]),

        ...get('Form/form@settings', {
            logoImage: 'logo_url'
        }),

        isDisplayed () {
            if (this.block.system) {
                return true;
            }

            return getValue(this.block, 'pivot.settings.isDisplayed');
        },

        blockContainerTheme () {
            return this.hasBackground ? 'form.background' : '';
        },

        hasBackground () {
            return !['summary', 'ticketing', 'rsvp'].includes(this.block.slug);
        },

        isInConfirmationScreen () {
            return ['ConfirmationSection', 'NotAttendingSection']
                .includes(getValue(this.block, 'pivot.settings.section'));
        },

        isActive () {
            return this.activeBlock.id === this.block.pivot.id || this.isInConfirmationScreen;
        },

        shouldShowLogo () {
            return !this.isEditMode && (this.$attrs['data-index'] === 0 || this.block.slug === 'lookup');
        },

        shouldShowPremiumFeatureIndicator () {
            if (!this.isEditMode) {
                return false;
            }

            const feature = this.block?.properties?.feature;

            if (feature === 'CustomQuestionLimit') {
                return this.block.question?.type?.slug !== 'cash-donation-gift';
            }

            return feature != null;
        },

        shouldShowNextButton () {
            if (this.preview && !this.isInConfirmationScreen) {
                return true;
            }

            return this.isActive && !this.isInConfirmationScreen;
        },

        shouldShowPreviousButton () {
            return this.isActive && this.activeBlock.index > 0 && !this.isInConfirmationScreen;
        },

        styles () {
            return {
                wrapper: {
                    'h-window-full overflow-y-auto': !this.isEditMode && !this.isInConfirmationScreen,
                    'p-6 mb-4': this.isEditMode && !(this.isDisplayed === false)
                },
                wrapperChild: {},
                block: {
                    'mt-20 mb-24': !this.isEditMode && !this.isInConfirmationScreen,
                    'my-12': !this.isEditMode && this.isInConfirmationScreen,
                    'mx-4': !this.isEditMode,
                    'opacity-50': (!this.isEditMode && !this.isActive)
                }
            };
        },

        submission: get('Submission/submission')
    },

    methods: {
        completedBlock (valid, payload) {
            if (!this.isActive) {
                return;
            }

            if (valid) {
                this.$emit('complete-block', { block: this.block, payload });
            }
        },

        goBack () {
            this.$emit('go-back');
        },

        // Called when a block completes "itself", i.e. when the user chooses
        // an option in the FormSelectOneQuestion block
        selfCompletedBlock ({ valid, payload }) {
            this.completedBlock(valid, payload);
        },

        // Called when the user clicks the Next button on a block
        async userCompletedBlock (completeBlockFunction) {
            const { valid, payload } = await completeBlockFunction();

            this.completedBlock(valid, payload);
        }
    }
};
</script>
