<template>
    <div>
        <base-dropdown class="inline-block" placement="bottom">
            <template slot="trigger" slot-scope="{ triggerFunction }">
                <div class="flex items-center justify-center bg-white rounded-md border border-gray-300 p-2 ml-2 hover:border-purple hover:cursor-pointer text-gray-600 hover:text-purple" @click="triggerFunction">
                    <app-icon
                        name="layout-three-columns"
                        class="w-6 h-6"
                        stroke
                    ></app-icon>
                    <app-icon
                        name="arrow-down-chevron"
                        class="w-4 h-4 ml-2"
                        stroke
                    ></app-icon>
                </div>
            </template>

            <div class="p-2 bg-white border shadow">
                <search-field
                    v-model="searchTerm"
                    placeholder="Search columns..."
                    small
                    class="text-sm"
                ></search-field>

                <div class="flex flex-col h-auto max-h-2xs overflow-y-auto">
                    <div
                        v-for="column in filteredColumns"
                        :key="column.property"
                        class="flex items-center mt-2 cursor-pointer space-x-2"
                        @click="selectColumn(column)"
                    >
                        <div v-if="shouldDisplayColumn(column)">
                            <input
                                :checked="selected[column.property]"
                                :disabled="column.alwaysActive"
                                type="checkbox"
                            >

                            <span class="text-gray-800 text-sm tracking-wider">{{ column.label }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </base-dropdown>
        <slot
            v-bind="{chosenColumns}"
        ></slot>
    </div>
</template>

<script>
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import filter from 'lodash/filter';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@root/tailwind.config';

const tailwind = resolveConfig(tailwindConfig);

export default {
    name: 'ChoosableColumns',

    props: {
        /**
         * An array of objects that define the columns.
         * The following properties are available on the object.
         *
         * label: The label to display in the menu for the column and table.  If this is empty (typeically actions) the column will NOT be displayed in the menu.
         * property: The property that contains the data of the column.  This field is used to fill in the data table.
         * desktop: Decides if the column is turned on by default on a desktop screen defined as MD or larger.
         * mobile: Decides if the column is turned on for a mobile screen defined as SM or XS.
         * alwaysActive: Decides if the column can be turned on and off.  The column will NOT be displayed if alwaysActive is true AND the column is not displayed on the display type (mobile / desktop)
         * sortable: Determines if the column is sortable.
         * headerClass: Any classes that should be applied to the header when displayed in the table.
         * overflow: The maximum amount of charaters allowed to be displayed.  Anything over the count will be turned into ...
         * tooltip: A tooltip to display when hovering over the column header.
         * groupedSort: Determines if the column is able to be sorted in a group.
         *
         */
        columns: {
            type: Array,
            required: true
        },

        /**
         * The storage key is the key used within local storage to save the selected columns.
         * This only needs to be manually set if there is more than one chooseable columns component
         * on the page as they will cause a conflict.
         */
        storageKey: {
            type: String,
            default: () => {
                return `chosen-columns-${window.location.pathname}`;
            }
        },

        value: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            selected: {},
            searchTerm: ''
        };
    },

    computed: {
        chosenColumns () {
            return filter(this.columns, (column) => {
                return this.selected[column.property];
            });
        },

        displayType () {
            return this.isMobile ? 'mobile' : 'desktop';
        },

        isMobile () {
            const mdScreenWidth = parseInt(tailwind.theme.screens.md.replace('px', ''), 10);
            return this.screenWidth < mdScreenWidth;
        },

        filteredColumns () {
            return filter(this.columns, (column) => {
                return column.label.toLowerCase().includes(this.searchTerm.toLowerCase());
            });
        }
    },

    watch: {
        chosenColumns () {
            this.$emit('input', this.chosenColumns);
        },

        columns () {
            this.restoreColumns();
        },

        isMobile () {
            this.restoreColumns();
        }
    },

    mounted () {
        this.restoreColumns();
    },

    methods: {
        columnIsAvailableOnDevice (column) {
            if (column.alwaysActive) {
                return column[this.displayType];
            }

            return true;
        },

        getRememberedColumns () {
            return JSON.parse(localStorage.getItem(this.storageKey));
        },

        rememberColumns () {
            if (this.isMobile) {
                return;
            }

            localStorage.setItem(this.storageKey, JSON.stringify(map(this.chosenColumns, 'property')));
        },

        resetColumns () {
            forEach(this.columns, (column) => {
                this.$set(this.selected, column.property, false);
            });
        },

        selectColumn (column) {
            if (column.alwaysActive) return;

            this.$set(this.selected, column.property, !this.selected[column.property]);

            this.rememberColumns();
        },

        selectDefaultColumns () {
            forEach(this.columns, (column) => {
                this.$set(this.selected, column.property, column[this.displayType]);
            });
        },

        selectRememberedColumns () {
            const rememberedColumns = this.getRememberedColumns();

            forEach(this.columns, (column) => {
                if (rememberedColumns.includes(column.property) && this.columnIsAvailableOnDevice(column)) {
                    this.$set(this.selected, column.property, true);
                }
            });
        },

        restoreColumns () {
            this.resetColumns();

            forEach(this.columns, (column) => {
                if (column.alwaysActive && this.columnIsAvailableOnDevice(column)) {
                    this.$set(this.selected, column.property, true);
                }
            });

            if (this.getRememberedColumns() !== null && !this.isMobile) {
                this.selectRememberedColumns();
                return;
            }

            this.selectDefaultColumns();
        },

        shouldDisplayColumn (column) {
            if (!this.columnIsAvailableOnDevice(column)) {
                return false;
            }

            if (!column.label) {
                return false;
            }

            return true;
        }
    }
};
</script>
