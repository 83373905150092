<template>
    <multi-page-item-settings
        items-array-name="locations"
        :settings="settings"
        :item-creator-function="createEmptyItem"
        :default-close-handler="defaultCloseHandler"
    >
        <template slot-scope="{ edit, save, close, create, restore, selectedItem }">
            <wall-locations-block-general-settings
                key="general-settings"
                :settings="settings"
                @edit-location="edit"
                @create-location="create"
            ></wall-locations-block-general-settings>

            <wall-locations-block-item-settings
                key="item-settings"
                :location="selectedItem"
                @save-settings="save"
                @cancel="restore"
            ></wall-locations-block-item-settings>
        </template>
    </multi-page-item-settings>
</template>

<script>
import HasDefaultCloseHandler from '@/mixins/HasDefaultCloseHandler';

export default {
    name: 'WallLocationsBlockSettings',

    mixins: [HasDefaultCloseHandler],

    props: {
        block: {
            type: Object,
            required: true
        },
        event: {
            type: Object,
            required: true
        },
        settings: {
            type: Object,
            required: true
        }
    },

    methods: {
        createEmptyItem () {
            return {
                name: '',
                active: true,
                new: true
            };
        }
    }
};
</script>
