/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down-chevron': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sort descending</desc><path pid="0" _fill="currentColor" d="M.24 7.2l10.98 12.471c.4.42 1.08.43 1.5.03.01-.02.02-.03.03-.04L23.72 7.18h-.01a.995.995 0 00-.09-1.4l-.03-.03-1.85-1.56-.01-.01c-.43-.37-1.07-.32-1.44.11l-8.17 9.45v-.01c-.1.1-.25.11-.36.02l-.03-.03-8.17-9.46a1.01 1.01 0 00-.7-.35c-.27-.03-.54.06-.75.23L.25 5.69v-.01a.96.96 0 00-.36.68h-.01c-.03.26.06.53.24.72z"/>'
  }
})
