/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-fork-knife-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>restaurant knife utensils silverware flatware</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75.75v22.5M9.74.75V7.5h-.01a3.009 3.009 0 01-3.01 2.99v0l-.01-.001c-1.66-.01-3-1.35-3-3V.73M15.74 15.75h3c.79.03 1.46-.6 1.5-1.39 0-.04 0-.08-.01-.12-.17-4.308-.34-8.82-3.12-13.157h0a.759.759 0 00-1.05-.22c-.22.13-.34.37-.35.62v21.74"/></g>'
  }
})
