/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-square-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>ok yes correct done task</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 9l3.29 4.61-.01-.01c.48.68 1.44.84 2.12.35.1-.08.2-.17.28-.27L23.22.72"/><path pid="1" d="M21.75 10.5v9.75c0 1.65-1.35 3-3 3h-15l-.01-.001c-1.66-.01-3-1.35-3-3V5.248h0A2.998 2.998 0 013.73 2.23h12.75"/></g>'
  }
})
