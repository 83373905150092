<template>
    <simple-picker
        v-model="internalValue"
        v-theme="['form.text', 'form.accent', 'form.dropdown']"
        :items="question.options"
        :is-item-disabled-function="isOutOfAvailability"
        item-value="id"
        :placeholder-empty-state="$t('placeholder-select-one')"
    >
        <template #input="{ selected }">
            <div class="w-full flex items-center">
                <div class="shrink mr-2">
                    <app-icon
                        v-if="selected.settings.icon"
                        :name="selected.settings.icon"
                        class="w-4 h-4"
                    ></app-icon>
                </div>

                <div class="grow">
                    <div class="grow rounded-md">
                        <template v-if="selected.settings.costsMoney">
                            <money-field static :value="selected.price"></money-field>
                            {{ ` - ` }}
                        </template>
                        {{ selected.title }}
                    </div>
                </div>
            </div>
        </template>

        <template #item="{ item }">
            <div class="w-full flex items-center">
                <div class="shrink mr-2">
                    <app-icon
                        v-if="item.settings.icon"
                        :name="item.settings.icon"
                        class="w-4 h-4"
                    ></app-icon>
                </div>

                <div class="grow">
                    <div class="grow rounded-md">
                        <template v-if="item.settings.costsMoney">
                            <money-field static :value="item.price"></money-field>
                            {{ ` - ` }}
                        </template>
                        {{ item.title }}
                        <span v-if="shouldShowOutOfAvailabilityMessage(item)">
                            <tiptap-editor
                                class="ml-2"
                                read-only
                                :value="item.settings.maxCapacity.message"
                            ></tiptap-editor>
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </simple-picker>
</template>

<script>
import get from 'lodash/get';
import CustomQuestion from '@/mixins/CustomQuestion';

export default {
    name: 'FormDropdownQuestion',

    mixins: [CustomQuestion],

    methods: {
        isOutOfAvailability (option) {
            const availabilitySettings = option.settings.maxCapacity || {};

            return availabilitySettings.enabled && option.quantity_remaining === 0;
        },

        shouldShowOutOfAvailabilityMessage (option) {
            return this.isOutOfAvailability(option) && get(option.settings, 'maxCapacity.showMessage', false);
        }
    }
};
</script>
