/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.5 10.625a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75zM23.25 21.125h0a6.031 6.031 0 00-8.46-1.05c-.39.3-.74.65-1.05 1.04"/><path pid="1" d="M21.875 14.11v-.01c-2.27.65-4.71.08-6.45-1.52M5.5 10.625a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75zM10.24 21.125h0a6.031 6.031 0 00-8.46-1.05c-.39.3-.74.65-1.05 1.04"/><path pid="2" d="M8.87 14.11v-.01c-2.27.65-4.71.08-6.45-1.52M6 2.875a.5.5 0 100 1 .5.5 0 100-1zM12 2.875a.5.5 0 100 1 .5.5 0 100-1zM18 2.875a.5.5 0 100 1 .5.5 0 100-1z"/></g>'
  }
})
