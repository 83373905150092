/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fruit-grapes-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.5.75V.74c-1.2.48-1.89 1.73-1.66 3M5.25 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM12 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM12 18.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM8.25 12.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM15.75 12.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM18.75 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM9.75.75h6"/></g>'
  }
})
