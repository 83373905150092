<template>
    <div class="relative">
        <iframe
            :src="node.attrs.src"
            frameborder="0"
            allowfullscreen="true"
            width="100%"
            height="300px"
        ></iframe>

        <button
            v-if="editable"
            class="absolute top-0 right-0 m-2 block p-2 leading-0 rounded-full text-white bg-purple transition duration-150 ease-in-out cursor-pointer hover:bg-purple-light"
            @click="deleteIFrame"
        >
            <app-icon name="trash" class="h-4 w-4"></app-icon>
        </button>
    </div>
</template>

<script>
export default {
    name: 'EditorEmbed',

    props: {
        editor: {
            type: Object,
            required: true
        },

        getPos: {
            type: Function,
            required: true
        },

        node: {
            type: Object,
            required: true
        },

        updateAttrs: {
            type: Function,
            required: true
        },

        view: {
            type: Object,
            required: true
        }
    },

    computed: {
        editable () {
            return this.editor && this.editor.options.editable;
        }
    },

    methods: {
        deleteIFrame () {
            const transaction = this.view.state.tr;
            const position = this.getPos();

            transaction.delete(position, position + this.node.nodeSize);

            this.view.dispatch(transaction);
        }
    }
};
</script>
