<template>
    <div class="flex flex-col items-center justify-center -mx-6">
        <div v-if="loadingCustomQuestions" class="flex flex-col items-center justify-center space-y-2 min-h-xs">
            <app-icon name="loader" class="w-8 h-8 fill-current"></app-icon>

            <p class="text-center">
                We're preparing your custom questions, it will be ready in just a moment.
            </p>
        </div>

        <div v-else-if="!hasCustomQuestions" class="flex flex-col items-center justify-center space-y-2 min-h-xs">
            <p>You haven't added any custom questions.</p>
            <a
                class="text-purple"
                href="https://help.rsvpify.com/en/articles/4342718-custom-questions"
                target="_blank"
            >What are custom questions?</a>
        </div>

        <div v-else class="flex flex-col w-full">
            <a
                v-for="customQuestion in customQuestions"
                :key="customQuestion.id"
                role="button"
                class="group flex items-center px-6 py-2 hover:bg-gray-100"
                @click="insertMergeTag(customQuestion)"
            >
                <span class="flex-1">{{ customQuestion.title }}</span>
                <span class="button-icon button-primary hidden group-hover:flex">
                    <app-icon name="add-circle" stroke></app-icon>
                </span>
            </a>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import getValue from 'lodash/get';
import DynamicMergeTags from '@/mixins/DynamicMergeTags';
import axios from '@/util/axios';

export default {
    name: 'CustomQuestionAnswerMergeTags',

    mixins: [DynamicMergeTags],

    data () {
        return {
            customQuestions: [],
            loadingCustomQuestions: false
        };
    },

    mounted () {
        this.retrieveCustomQuestions();
    },

    computed: {
        ...get('Event/*'),

        hasCustomQuestions () {
            return this.customQuestions.length > 0;
        }
    },

    methods: {
        generateMergeTags (value) {
            return `{custom_question_answer|id=${value.id}|title=${value.title}}`;
        },

        retrieveCustomQuestions () {
            this.loadingCustomQuestions = true;

            axios.get(this.route('api.events.campaigns.merge-tags-data.custom-questions', this.event))
                .then(({ data }) => {
                    this.customQuestions = data.data.map((customQuestion) => {
                        return {
                            id: customQuestion.id,
                            title: customQuestion.title
                        };
                    });
                }).finally(() => {
                    this.loadingCustomQuestions = false;
                });
        }
    }
};
</script>
