/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-warning-3-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10 15.23h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.38-.38-.375v0M10 12.58V6.67"/><path pid="1" d="M15.47 10.013l-4.14-8.44-.01-.01A1.49 1.49 0 009.33.88c-.3.14-.54.38-.68.67L.86 17.413v-.01c-.31.62-.05 1.37.57 1.67.17.08.35.12.54.12h7.75M18 15a3 3 0 100 6 3 3 0 100-6zM17.5 12.75h1M18 12.75V15M21.36 13.93l.71.71M21.71 14.29l-1.59 1.59M23.25 17.5v1M23.25 18H21M22.07 21.36l-.71.71M21.71 21.71l-1.59-1.59M18.5 23.25h-1M18 23.25V21M14.64 22.07l-.71-.71M14.29 21.71l1.59-1.59M12.75 18.5v-1M12.75 18H15M13.93 14.64l.71-.71M14.29 14.29l1.59 1.59"/></g>'
  }
})
