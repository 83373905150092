/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-wind-breath-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 13.82a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM6.18 11.25h1.14M6.75 11.25v2.57M10.59 12.6l.81.81M10.99 13.01l-1.82 1.82M12.75 16.68v1.14M12.75 17.25h-2.57M11.4 21.09l-.81.81M10.99 21.49l-1.82-1.82M7.32 23.25H6.18M6.75 23.25v-2.57M2.91 21.9l-.81-.81M2.51 21.49l1.82-1.82M.75 17.82v-1.14M.75 17.25h2.57M2.1 13.41l.81-.81M2.51 13.01l1.82 1.82M18.25 3.25h0A2.488 2.488 0 0120.74.74c1.38-.01 2.5 1.11 2.5 2.49a2.5 2.5 0 01-2.5 2.5H1.24M16.75 14.25h0c0 1.38 1.11 2.49 2.5 2.49a2.501 2.501 0 002.49-2.51 2.503 2.503 0 00-2.51-2.5h-5M1.25 8.75h16.5"/></g>'
  }
})
