/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-beer-glass-foam-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>brew pub bar alcohol</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.5 5.122v.63h-.01c-.01 2.22.52 4.42 1.55 6.4v0h0c1.22 2.36 1.74 5.03 1.49 7.69l-.16 1.74h-.01c-.11.79.44 1.52 1.24 1.63.03 0 .07 0 .11.01h5.183-.01c.8-.05 1.41-.74 1.37-1.54-.01-.04-.01-.08-.02-.12l-.16-1.75-.01-.01c-.25-2.66.27-5.33 1.49-7.7v0-.01c.68-1.32 1.14-2.73 1.37-4.2M5.08 9.75H17.6"/><path pid="1" d="M9.37 12h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38v-.001M12.37 15h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38v-.001M10.87 18.75h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M18 .75H5.24c-1.25 0-2.25 1-2.25 2.25 0 1.24 1 2.25 2.25 2.24h8.35l-.01-.01c.39 1.59 2 2.55 3.59 2.16.28-.07.54-.19.79-.33v3.41h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51V3.7l-.001-.001c0-1.66-1.35-3-3-3z"/></g>'
  }
})
