/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-graph-death-rate-stable-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.787 22.5h-21v-21"/><path pid="1" d="M16.162 6.375h-.01c.01-2.7-2.16-4.89-4.85-4.91a4.872 4.872 0 00-4.91 4.84 4.85 4.85 0 001.87 3.86v.3h0c0 .82.67 1.49 1.5 1.49h3l-.01-.001c.82 0 1.5-.68 1.5-1.5v-.32a4.877 4.877 0 001.87-3.82z"/><path pid="2" d="M9.412 6.375v-.001c.2-.01.37.16.37.37h-.001M9.03 6.75h0c-.01-.21.16-.38.37-.38"/><path pid="3" d="M9.412 7.12H9.4a.386.386 0 01-.38-.38s0 0 0 0"/><path pid="4" d="M9.78 6.75h0c0 .2-.17.37-.38.37-.01 0-.01 0-.01-.01M13.162 6.375v-.001c.2-.01.37.16.37.37h-.001M12.78 6.75h0c-.01-.21.16-.38.37-.38"/><path pid="5" d="M13.162 7.12h-.01a.386.386 0 01-.38-.38s0 0 0 0"/><path pid="6" d="M13.53 6.75h0c0 .2-.17.37-.38.37-.01 0-.01 0-.01-.01M11.29 10.5V12M20.822 15.23l2.39 2.39-2.4 2.39"/><path pid="7" d="M.78 17.625h.5-.01c1.64-.01 3.26-.39 4.73-1.12l2.42-1.21h-.01c1.78-.9 3.89-.9 5.68-.01l2.42 1.21h0c1.47.73 3.09 1.11 4.73 1.11h1.92"/></g>'
  }
})
