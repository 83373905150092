<template>
    <portal to="image-cropper-target">
        <div ref="imageCropper" class="fixed inset-0 w-screen h-screen p-12 bg-smoke-700 z-9999 crop-modal">
            <div class="flex flex-col items-center justify-center w-full h-full">
                <div class="w-full h-full m-auto">
                    <img
                        ref="image"
                        :src="image.src"
                        class="max-w-full h-auto"
                    >
                </div>

                <div class="shrink mt-2">
                    <div class="inline-block mr-10">
                        <a
                            class="inline-block p-2 text-white hover:text-purple-200"
                            role="button"
                            @click="rotate(-90)"
                        >
                            <app-icon name="rotate-back" class="h-6 w-6"></app-icon>
                        </a>
                        <a
                            class="inline-block p-2 text-white hover:text-purple-200"
                            role="button"
                            @click="rotate(90)"
                        >
                            <app-icon name="rotate-forward" class="h-6 w-6"></app-icon>
                        </a>
                        <a
                            class="inline-block p-2 hover:text-purple-200"
                            role="button"
                            :class="aspectRationToggleClass"
                            @click="toggleAspectRatioLock"
                        >
                            <app-icon
                                name="grid-lock"
                                class="h-6 w-6"
                                stroke
                            ></app-icon>
                        </a>
                    </div>
                    <a
                        class="inline-block p-2 text-white hover:text-purple-200"
                        role="button"
                        @click="crop"
                    >
                        <app-icon name="check-circle" class="h-6 w-6"></app-icon>
                    </a>
                    <a
                        class="inline-block p-2 text-white hover:text-purple-200"
                        role="button"
                        @click="cancel"
                    >
                        <app-icon name="close-circle" class="h-6 w-6"></app-icon>
                    </a>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
import 'cropperjs/dist/cropper.min.css';
import Cropper from 'cropperjs';
import Mousetrap from 'mousetrap';
import ImageOrientationUtilities from '@/mixins/ImageOrientationUtilities';

export default {
    mixins: [ImageOrientationUtilities],

    props: {
        image: {
            type: Object,
            default: () => { return null; }
        }
    },

    data () {
        return {
            cropper: null,
            lockAspectRatio: false
        };
    },

    computed: {
        aspectRatio () {
            return this.lockAspectRatio ? this.$refs.image.width / this.$refs.image.height : null;
        },

        aspectRationToggleClass () {
            return this.lockAspectRatio ? 'text-white' : 'text-gray-500';
        }
    },

    mounted () {
        this.$nextTick(() => {
            this.cropper = new Cropper(this.$refs.image, {
                ready () {
                    const size = this.cropper.getCanvasData();
                    this.cropper.setCropBoxData({
                        left: size.left,
                        top: size.top,
                        height: size.height,
                        width: size.width
                    });
                }
            });
        });

        const mouseTrap = new Mousetrap(this.$refs.imageCropper);

        mouseTrap.bind('esc', () => {
            this.cancel();
        });
    },

    methods: {
        rotate (degrees) {
            this.cropper.rotate(degrees);
        },

        toggleAspectRatioLock () {
            this.lockAspectRatio = !this.lockAspectRatio;
            this.cropper.setAspectRatio(this.aspectRatio);
        },

        crop () {
            const srcBase64 = this.cropper.getCroppedCanvas().toDataURL();

            this.cropper.getCroppedCanvas({ maxWidth: 1200 }).toBlob(
                (blob) => {
                    this.$emit('input', {
                        src: srcBase64,
                        file: blob
                    });

                    this.$emit('finish-crop');
                },
                this.getMimeTypeFromBase64(srcBase64),
                0.8
            );
        },

        cancel () {
            this.$emit('cancel');
        }
    }
};
</script>
