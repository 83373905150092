/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text-input-area-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>field</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25 15.74v0c0 .82-.68 1.5-1.5 1.5M21.75 6.748h-.01c.82-.01 1.5.67 1.5 1.5 0 0 0 0 0 0M.75 8.24h0c-.01-.83.67-1.51 1.49-1.51M2.25 17.24h-.01a1.51 1.51 0 01-1.5-1.5v-.001M.75 11.25v1.5M23.25 11.25v1.5M5.25 6.75h1.5M9.75 6.75h1.5M17.25 6.75h1.5M5.25 17.25h1.5M9.75 17.25h1.5M17.25 17.25h1.5M14.25 18.75V5.25M9.75 23.24l-.01-.001c2.48 0 4.5-2.02 4.5-4.5h0c0 2.48 2.01 4.49 4.5 4.49M9.75.74h-.01c2.48-.01 4.5 2.01 4.5 4.49h0A4.494 4.494 0 0118.73.72M11.25 14.25h6"/></g>'
  }
})
