/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-bookmark-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>document favorite</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 2.24H2.24c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v18-.01c-.01.82.67 1.5 1.49 1.5h19.5-.01c.82 0 1.5-.68 1.5-1.5v-18 0c0-.83-.68-1.5-1.5-1.5h-10.5"/><path pid="1" d="M11.25 11.24l-3-2.25-3 2.25V1.49h0c-.01-.42.33-.76.74-.76V.72h4.5-.01c.41-.01.75.33.75.75 0 0 0 0 0 0zM5.25 18.75h10.5M5.25 14.25h13.5M18.75 9.75h-4.5"/></g>'
  }
})
