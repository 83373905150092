<template>
    <div class="w-full mx-auto p-4 md:max-w-xl">
        <div class="text-center mb-2">
            <h1 class="text-center text-2xl font-bold mb-4 tracking-wider">Manage your subscription</h1>
            <p class="text-gray-600 text-xs uppercase">Your current subscription</p>
        </div>

        <div class="w-full tracking-wider text-xs bg-white border rounded-md p-4 mb-6 shadow-lg hover:border-purple-light">
            <h2 class="text-3xl font-bold tracking-wider mb-2">{{ currentPlan.name }}</h2>
            <p class="text-base">You will be billed <span class="font-bold">{{ currentPlan.price | currency(true) }}</span> every {{ humanBillingPeriod }} until you choose to cancel your subscription. Cancel any time.</p>
            <div v-if="currentPlan.hasAnnualPlan">
                <hr class="my-3">
                <p class="text-xl">Save <span class="font-bold">{{ currentPlan.savingsOverMonthly }}%</span> with annual billing?</p>
                <a class="font-bold text-base text-teal animate-pulse transition duration-150 ease-in-out hover:text-purple cursor-pointer" :href="annualUpgradeUrl">Switch to annual ⟶</a>
            </div>
        </div>

        <p class="text-center text-xs text-gray-600 uppercase mb-2">Options</p>

        <a
            v-if="downgradePlan"
            class="block w-full tracking-wider text-xs bg-white border rounded-md p-4 mb-4 shadow-lg cursor-pointer hover:border-purple-light"
            :href="route('account.upgrade.preview', [userSegment.id, downgradePlan.id])"
        >
            <div class="flex">
                <app-icon name="arrow-down-circle" class="h-10 w-10 flex-none mr-4"></app-icon>

                <div>
                    <p class="font-bold text-base tracking-wide mb-2">Downgrade to {{ downgradePlan.name }} and retain data</p>
                    <p class="text-gray-600">Retain your data indefinitely and continue to access your event(s) with {{ downgradePlan.name }} ({{ downgradePlan.price | currency(true) }} per month).</p>
                </div>
            </div>
        </a>

        <div
            class="w-full tracking-wider text-xs bg-white border rounded-md p-4 mb-4 shadow-lg cursor-pointer hover:border-purple-light"
            @click="$emit('submit')"
        >
            <div class="flex">
                <app-icon
                    name="button-stop-stroke"
                    stroke
                    class="h-10 w-10 flex-none mr-4"
                ></app-icon>

                <div>
                    <p class="font-bold text-base tracking-wide mb-2">Cancel subscription now</p>
                    <p class="text-gray-600">Cancel your RSVPify subscription entirely.  You will lose access to your event(s).  We'll retain your event(s) and associated data for a minimum period of 30 days from the expiration of your premium plan.</p>
                </div>
            </div>
        </div>

        <a :href="route('account.show')" class="block text-sm text-center font-semibold transition duration-150 ease-in-out hover:text-purple cursor-pointer">Back to My Account</a>
    </div>
</template>

<script>
export default {
    name: 'CancelPlanOverview',

    props: {
        currentPlan: {
            type: Object,
            required: true
        },

        currentPlanSegment: {
            type: Object,
            required: true
        },

        downgradePlan: {
            type: Object,
            default: null
        },

        userSegment: {
            type: Object,
            required: true
        }
    },

    computed: {
        annualUpgradeUrl () {
            if (this.currentPlan.isAnnual) {
                return null;
            }

            return this.route('account.upgrade.preview', {
                plan_segment: this.currentPlanSegment.id,
                plan: this.currentPlan.linked_plan.id
            });
        },

        humanBillingPeriod () {
            if (this.currentPlan.isMonthly) {
                return 'month';
            }

            return 'year';
        }
    }
};
</script>
