/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wedding-celebration-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.5 15.9c-.38-2.77-3.53-2.52-3.95-.18-.31 1.7 1.87 4.33 2.71 5.28l-.01-.01c.14.16.39.21.59.1 1.12-.593 4.08-2.3 4.39-4 .42-2.35-2.45-3.68-3.76-1.22z"/><path pid="1" d="M13.623 13.9v-.01c.18-.61.21-1.26.06-1.88-.843-2.89-4.96-2.69-4.78 1.08-1.88-3.27-5.46-1.24-4.62 1.65.62 2.13 4.831 3.9 6.07 4.388h0c.2.08.43.01.56-.17.21-.3.577-.8.96-1.43M.75.75L.74.74a68.549 68.549 0 0022.5 2.25"/><path pid="2" d="M6.577 2.03l-2.2 2.76v-.01a.759.759 0 01-1.32-.29l-.89-3.42M11.689 2.78l-2.02 2.9h-.01c-.24.34-.71.42-1.05.19a.766.766 0 01-.29-.38L7.19 2.13M16.96 3.157L15.18 6.21V6.2a.77.77 0 01-1.03.27.742.742 0 01-.32-.36l-1.38-3.26M22 3.082l-1.52 3.18h-.01a.76.76 0 01-1.01.35.798.798 0 01-.35-.33l-1.64-3.13M3 21.75h3M4.5 20.25v3M20.25 21.75h3M21.75 20.25v3M19.5 11.25h3M21 9.75v3M.75 9h3M2.25 7.5v3"/></g>'
  }
})
