/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-facebook-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12.5 23.5H1.49c-.56-.01-1-.45-1-1.01V1.489h0A.993.993 0 011.48.47h21-.01c.55-.01 1 .44 1 .99v21c0 .55-.45 1-1 1h-6v-9h2.55l-.01-.001c.25 0 .46-.19.5-.44l.375-3v-.01a.506.506 0 00-.44-.56c-.03-.01-.05-.01-.07-.01h-2.94V9.11c0-.94.75-1.69 1.68-1.69h1.81l-.01-.001c.27 0 .5-.23.5-.5v-3 0c0-.28-.23-.5-.5-.5H18.09c-3.14 0-5.69 2.54-5.69 5.68v1.31H9.89c-.28 0-.5.22-.5.5 0 0 0 0 0 0v3h0c0 .27.22.49.5.49h2.5v9z"/>'
  }
})
