<template>
    <tabbed-modal
        ref="tabbedModal"
        :value="value"
        full-screen
        :z-index="100"
        @input="$emit('input', $event)"
        @closed="closeModal"
    >
        <template #header>
            {{ campaign.title }}
            <p class="font-normal text-gray-600 text-base">Originally sent at {{ campaign.sent_at | dateTimeTz(eventTimezone.name) }}</p>
        </template>

        <template #content="{ initialize }">
            <tabbed-modal-item
                id="report"
                label="Report"
                @hook:mounted="initialize"
            >
                <bounced-email-alert
                    v-if="bounceNotification"
                    :campaign="campaign"
                    :notification="bounceNotification"
                    @fix-bounced="campaign => $emit('fix-bounced', campaign)"
                ></bounced-email-alert>

                <div class="row justify-center mt-8">
                    <div class="col-6 md:col-2 flex-col justify-center md:items-start">
                        <div class="flex flex-col md:flex-row items-center">
                            <div class="flex items-center justify-center p-3 bg-green-light rounded-full">
                                <app-icon
                                    name="email-check"
                                    class="w-6 h-6 text-green-dark"
                                    stroke
                                ></app-icon>
                            </div>

                            <div class="ml-0 md:ml-2 text-3xl font-bold text-gray-600">
                                {{ campaign.recipients_count.sent }}
                            </div>
                        </div>

                        <p
                            v-tippy
                            class="ml-0 md:ml-14 text-center md:text-left text-sm uppercase text-gray-600 tooltip-text hover:cursor-help"
                            content="The count of the unique email addresses that this message was sent to."
                        >
                            Sent
                        </p>
                    </div>

                    <div class="col-6 md:col-2 flex-col justify-center md:items-start">
                        <div class="flex flex-col md:flex-row items-center">
                            <div class="flex items-center justify-center p-3 bg-green-light rounded-full">
                                <app-icon
                                    name="email-search"
                                    class="w-6 h-6 text-green-dark"
                                    stroke
                                ></app-icon>
                            </div>

                            <div class="ml-0 md:ml-2 text-3xl font-bold text-gray-600">
                                {{ campaign.recipients_count.opened }}
                            </div>
                        </div>

                        <p
                            v-tippy
                            class="ml-0 md:ml-14 text-center md:text-left text-sm uppercase text-gray-600 tooltip-text hover:cursor-help"
                            content="The count of the unique number of recipients who have opened your email. Some email services may prevent RSVPify from tracking opens."
                        >
                            Opened
                        </p>
                    </div>

                    <div class="col-6 md:col-2 flex-col mt-8 md:mt-0 justify-center md:items-start">
                        <div class="flex flex-col md:flex-row items-center">
                            <div class="flex items-center justify-center p-3 bg-yellow-light rounded-full">
                                <app-icon
                                    name="email-clock"
                                    class="w-6 h-6 text-yellow-dark"
                                    stroke
                                ></app-icon>
                            </div>

                            <div class="ml-0 md:ml-2 text-3xl font-bold text-gray-600">
                                {{ campaign.recipients_count.deferred }}
                            </div>
                        </div>

                        <p
                            v-tippy
                            class="ml-0 md:ml-14 text-center md:text-left text-sm uppercase text-gray-600 tooltip-text hover:cursor-help"
                            content="The unique count of email deliveries that are having issues being delivered.  Don't worry, we will keep trying to deliver them!"
                        >
                            Deferred
                        </p>
                    </div>

                    <div class="col-6 md:col-2 flex-col mt-8 md:mt-0 justify-center md:items-start">
                        <div class="flex flex-col md:flex-row items-center">
                            <div class="flex items-center justify-center p-3 bg-yellow-light rounded-full">
                                <app-icon
                                    name="email-warning"
                                    class="w-6 h-6 text-yellow-dark"
                                    stroke
                                ></app-icon>
                            </div>

                            <div class="ml-0 md:ml-2 text-3xl font-bold text-gray-600">
                                {{ campaign.recipients_count.bounced }}
                            </div>
                        </div>

                        <p
                            v-tippy
                            class="ml-0 md:ml-14 text-center md:text-left text-sm uppercase text-gray-600 tooltip-text hover:cursor-help"
                            content="The unique count of email deliveries that RSVPify has been able to confirm were undeliverable."
                        >
                            Bounced
                        </p>
                    </div>

                    <div class="col-6 md:col-2 flex-col mt-8 md:mt-0 justify-center md:items-start">
                        <div class="flex flex-col md:flex-row items-center">
                            <div class="flex items-center justify-center p-3 bg-red-light rounded-full">
                                <app-icon
                                    name="email-close"
                                    class="w-6 h-6 text-red"
                                    stroke
                                ></app-icon>
                            </div>

                            <div class="ml-0 md:ml-2 text-3xl font-bold text-gray-600">
                                {{ campaign.recipients_count.suppressed }}
                            </div>
                        </div>

                        <p
                            v-tippy
                            class="ml-0 md:ml-14 text-center md:text-left text-sm uppercase text-gray-600 tooltip-text hover:cursor-help"
                            content="The number of recipients who have been suppressed from receiving emails. These emails have previously bounced or complained to RSVPify."
                        >
                            Suppressed
                        </p>
                    </div>

                    <div class="col-6 md:col-2 flex-col mt-8 md:mt-0 justify-center md:items-start">
                        <div class="flex flex-col md:flex-row items-center">
                            <div class="flex items-center justify-center p-3 bg-red-light rounded-full">
                                <app-icon
                                    name="email-close"
                                    class="w-6 h-6 text-red"
                                    stroke
                                ></app-icon>
                            </div>

                            <div class="ml-0 md:ml-2 text-3xl font-bold text-gray-600">
                                {{ campaign.recipients_count.unsubscribed }}
                            </div>
                        </div>

                        <p
                            v-tippy
                            class="ml-0 md:ml-14 text-center md:text-left text-sm uppercase text-gray-600 tooltip-text hover:cursor-help"
                            content="The number of recipients who have unsubscribed from emails from you from this specific mailing."
                        >
                            Unsubscribed
                        </p>
                    </div>
                </div>

                <infinite-data-table
                    class="text-gray-600"
                    :columns="chosenColumns"
                    has-select-all
                    no-results-placeholder="Sorry, we couldn't find any messages."
                    :predicates="allFilters"
                    remote-sort
                    selectable
                    :sort="sort"
                    :source="dataSource"
                    @change-sort="handleChangedSort"
                >
                    <template #header>
                        <div class="w-full flex items-center space-x-2">
                            <portal-target
                                name="bulk-actions"
                            ></portal-target>

                            <div class="flex-auto">
                                <search-field
                                    v-model="searchQuery"
                                    class="w-full md:w-72"
                                    placeholder="Search by name or email..."
                                ></search-field>
                            </div>

                            <choosable-columns
                                v-model="chosenColumns"
                                class="hidden md:block"
                                :columns="columns"
                                :storage-key="chooseableColumnsStorageKey"
                            ></choosable-columns>

                            <sent-campaign-report-export-button
                                class="hidden md:block"
                                :campaign="campaign"
                            ></sent-campaign-report-export-button>
                        </div>
                    </template>

                    <template #selectable="{ selectedAll, selectedIds, reload, total }">
                        <portal to="bulk-actions">
                            <bulk-actions
                                :ids="selectedIds"
                                :all="selectedAll"
                                :predicates="allFilters"
                                :total="total"
                                class="hidden md:block"
                                :disabled="actionsButton.disabled"
                                :disabled-tooltip="actionsButton.disabledTooltip"
                            >
                                <template #default="bulkActionData">
                                    <bulk-tag
                                        :bulk-action-data="bulkActionData"
                                        :confirmation-message="`Tagging ${bulkActionData.formatted.totalSelected} guests(s).  This may take a few minutes.`"
                                        :modal-title="`Tagging ${bulkActionData.formatted.totalSelected} guests(s)`"
                                        :confirm-button-text="`Tag ${bulkActionData.formatted.totalSelected} guests(s)`"
                                        :endpoint="route('api.events.campaigns.messages.bulk.tag', {event, campaign})"
                                        @rows-tagged="reload"
                                    ></bulk-tag>

                                    <bulk-untag
                                        :bulk-action-data="bulkActionData"
                                        :confirmation-message="`Untagging guest(s).  This may take a few minutes.`"
                                        :modal-title="`Untagging guest(s)`"
                                        :confirm-button-text="`Untag guest(s)`"
                                        :counts-endpoint="route('api.events.campaigns.messages.tags.counts', {event, campaign})"
                                        :endpoint="route('api.events.campaigns.messages.bulk.untag', {event, campaign})"
                                        @rows-untagged="reload"
                                    >
                                    </bulk-untag>
                                </template>
                            </bulk-actions>
                        </portal>
                    </template>

                    <template slot="row" slot-scope="{ row, property, value }">
                        <div v-if="property === 'first_name'">
                            <span
                                v-if="row.sendable_was_deleted"
                                v-tippy
                                content="This invitee or guest was deleted by an event manager. We’ve retained the email address of the recipient for report integrity. You may fully delete the sent campaign and associated data anytime."
                                class="italic"
                            >
                                Record Deleted
                            </span>
                            <span v-else>
                                {{ value }}
                            </span>
                        </div>

                        <div v-else-if="property === 'state'">
                            <p
                                v-tippy
                                :class="stateColumnStyle(row.state)"
                                :content="stateTooltip(row.state, row.unsubscribed_type)"
                            >
                                {{ row.state }}
                                <span v-if="row.unsubscribed_type && row.state === 'Unsubscribed'">
                                    ({{ unsubscribeType(row.unsubscribed_type) }})
                                </span>
                            </p>
                        </div>

                        <div v-else-if="property === 'sent_at'">
                            {{ row.sent_at | dateTimeTz(eventTimezone.name) }}
                        </div>

                        <div v-else>
                            {{ value }}
                        </div>
                    </template>
                </infinite-data-table>
            </tabbed-modal-item>

            <tabbed-modal-item
                id="email-message"
                label="Email Message"
            >
                <email-editor
                    read-only
                    :value="campaign.body"
                    :accent-bar-enabled="campaign.settings.accentBarColorEnabled"
                    :accent-color="campaign.settings.accentBarColor"
                    :event="event"
                    :background-color="campaign.settings.backgroundColor"
                    :body-background-color="campaign.settings.emailBodyBackgroundColor"
                    :logo-url="logoUrl"
                    :logo-width="campaign.settings.logo_width"
                    :hide-rsvpify-footer="!event.owner.plan.features.BrandedEmailFooter.enabled"
                ></email-editor>
            </tabbed-modal-item>

            <tabbed-modal-item id="add-recipients" label="Add Recipients">
                <campaign-audience-builder
                    v-model="recipients"
                    :event="event"
                    mode="custom"
                ></campaign-audience-builder>
            </tabbed-modal-item>
        </template>

        <template v-if="campaign.shortState === 'Aborted' && auth().user().impersonated" #footer-report="{close}">
            <stateful-button
                v-tippy
                content="Resending this campaign will clear existing bounces and allow email sending on this account."
                class="button-warning-dark"
                :loading="resending"
                :disabled="resending"
                @click="resendCampaign(close)"
            >
                <app-icon name="alert-triangle-stroke"></app-icon>
                Resend
            </stateful-button>
        </template>

        <template #footer-add-recipients="{ close }">
            <stateful-button
                class="button-primary"
                :disabled="recipients.length === 0"
                :loading="sending"
                @click="sendToAdditionalRecipients(close)"
            >
                <app-icon
                    class="w-5 h-5 mr-2"
                    name="email-send"
                    stroke
                ></app-icon>
                Send to <strong>{{ recipients.length }}</strong> Recipients
            </stateful-button>

            <button class="button" @click="close">
                Cancel
            </button>

            <div class="mr-4 text-gray-600">
                {{ recipients.length }}
                <span
                    v-tippy
                    class="tooltip-text"
                    :content="`Sending this email requires ${recipients.length} credits. You have ${event.owner.remainingEmailCredits} credits remaining this period. Email credits are included in your selected plan and reset monthly. Need more credits? Chat with us!`"
                >
                    Email Credits
                </span>
            </div>
        </template>
    </tabbed-modal>
</template>

<script>
import axios from '@/util/axios';
import EventTimezone from '@/mixins/EventTimezone';
import BulkActionsButtonAuthorization from '@/mixins/BulkActionsButtonAuthorization';

export default {
    name: 'CampaignReportModal',

    mixins: [BulkActionsButtonAuthorization, EventTimezone],

    props: {
        bounceNotification: {
            type: Object,
            default: null
        },

        campaign: {
            type: Object,
            required: true
        },

        event: {
            type: Object,
            required: true
        },

        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            chosenColumns: [],
            recipients: [],
            searchQuery: '',
            sending: false,
            resending: false,
            sort: {}
        };
    },

    computed: {
        allFilters () {
            if (this.searchQuery === '') {
                return [];
            }

            return [
                {
                    field: 'identifier',
                    comparison: 'contains',
                    value: this.searchQuery
                },
                {
                    field: 'sendable',
                    comparison: 'contains',
                    value: this.searchQuery
                }
            ];
        },

        dataSource () {
            return this.route('api.events.campaigns.messages.index', [this.event.id, this.campaign.id]);
        },

        columns () {
            return [
                {
                    label: 'Title',
                    property: 'title',
                    desktop: true,
                    sortable: false
                },
                {
                    label: 'First Name',
                    property: 'first_name',
                    desktop: true,
                    sortable: false
                },
                {
                    label: 'Last Name',
                    property: 'last_name',
                    desktop: true,
                    sortable: false
                },
                {
                    label: 'Email Status',
                    property: 'state',
                    desktop: true,
                    mobile: true,
                    sortable: true
                },
                {
                    label: 'Email Address',
                    property: 'identifier',
                    desktop: true,
                    mobile: true,
                    sortable: true
                },
                {
                    label: 'Sent At',
                    property: 'sent_at',
                    desktop: true,
                    mobile: true,
                    sortable: true
                }
            ];
        },

        chooseableColumnsStorageKey () {
            return `chosen-columns-email-modal-${window.location.pathname}`;
        },

        logoUrl () {
            if (this.campaign.settings.logo === 'new' && this.campaign.settings.logo_url) {
                return this.campaign.settings.logo_url;
            }

            if (this.event.form.settings.logo_url) {
                return this.event.form.settings.logo_url;
            }

            return null;
        }
    },

    methods: {
        closeModal () {
            this.recipients = [];
        },

        handleChangedSort ([newSort]) {
            this.sort = newSort || {};
        },

        open (tabId = null) {
            this.$refs.tabbedModal.open(tabId);
        },

        resendCampaign (close) {
            this.resending = true;

            const route = this.route('admin.campaigns.resend', [this.campaign]);

            axios.post(route)
                .then(() => {
                    close();
                    this.$toasted.global.success('The campaign has been scheduled to be resent. It may take a few minutes to process.');
                })
                .catch(() => {
                    this.$toasted.global.error('Something went wrong while resending the campaign.');
                })
                .finally(() => {
                    this.resending = false;
                });
        },

        sendToAdditionalRecipients (close) {
            this.sending = true;

            const route = this.route('api.events.campaigns.resend', [this.event, this.campaign]);

            axios.post(route, { recipients: this.recipients })
                .then(() => {
                    close();
                    this.$toasted.global.success('Sent! Your email will be delivered to recipients shortly.');
                })
                .catch(() => {
                    this.$toasted.global.error('Something went wrong while sending the emails to additional recipients.');
                })
                .finally(() => {
                    this.sending = false;
                });
        },

        stateColumnStyle (state) {
            return {
                'text-green': state === 'Opened' || state === 'Sent',
                'text-yellow': state === 'Bounced' || state === 'Deferred',
                'text-red': state === 'Unsubscribed' || state === 'Unsent' || state === 'Suppressed'
            };
        },

        stateTooltip (state, unsubscribedType) {
            if (state === 'Opened') {
                return 'The recipient opened your email.';
            }

            if (state === 'Deferred') {
                return 'Your recipient’s inbox may be full or there may be another temporary issue. We’ll keep trying to deliver this email!';
            }

            if (state === 'Bounced') {
                return 'We weren’t able to deliver your email to this recipient. This email address may not exist or may be unable to receive email at this time.';
            }

            if (state === 'Suppressed') {
                return 'This recipient has previously bounced or complained to RSVPify. We’ve suppressed them from receiving emails.';
            }

            if (state === 'Unsent') {
                return 'We are currently working on sending this email.';
            }

            if (state === 'Unsubscribed' && (unsubscribedType === 'sendgrid_spamreport' || unsubscribedType === 'mailgun_complained')) {
                return 'This recipient marked your email as SPAM and is now unsubscribed from all of your emails.';
            }

            if (state === 'Unsubscribed' && unsubscribedType === 'rsvpify_unsubscribe_from_event') {
                return 'This recipient chose to unsubscribe from all future emails about this event.';
            }

            if (state === 'Unsubscribed' && unsubscribedType === 'rsvpify_unsubscribe_from_user') {
                return 'This recipient chose to unsubscribe from all of your emails.';
            }

            if (state === 'Aborted') {
                return 'Sending was aborted due to a high complaint rate. Please contact support for more information.';
            }

            return 'This email was successfully sent. We haven’t received any error reports from the recipient’s email provider.';
        },

        unsubscribeType (unsubscribedType) {
            if (unsubscribedType === 'rsvpify_unsubscribe_from_user') {
                return 'All';
            }

            if (unsubscribedType === 'rsvpify_unsubscribe_from_event') {
                return 'Event';
            }

            return 'SPAM';
        }
    }
};

</script>
