/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wedding-calendar-ring-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 17.25H2.99a1.51 1.51 0 01-1.5-1.5V3.739c0-.83.67-1.5 1.5-1.5h13.5-.01c.82-.01 1.5.67 1.5 1.5v4.5M1.5 6.75H18M6 3.75v-3M13.5 3.75v-3M18.75 15.75a3.75 3.75 0 100 7.5 3.75 3.75 0 100-7.5z"/><path pid="1" d="M18.75 15.75l-3-2.25 1.5-2.25h3l1.5 2.25-3 2.25z"/></g>'
  }
})
