/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'navigation-menu': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><rect pid="0" x=".5" y="2.5" width="23" height="3" rx="1" ry="1"/><rect pid="1" x=".5" y="10.5" width="23" height="3" rx="1" ry="1"/><rect pid="2" x=".5" y="18.5" width="23" height="3" rx="1" ry="1"/></g>'
  }
})
