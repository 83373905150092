/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-crowd-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.1 14.12a2.06 2.06 0 100 4.12 2.06 2.06 0 100-4.13zM15.763 23.125h-.001c0-2.02-1.64-3.66-3.66-3.66-2.02 0-3.66 1.63-3.66 3.65M19.35 11.61a2.01 2.01 0 100 4.03 2.01 2.01 0 100-4.04zM22.929 20.411v-.01c0-1.98-1.6-3.58-3.58-3.58-.87-.01-1.71.31-2.36.88M4.64 11.61a2.01 2.01 0 100 4.03 2.01 2.01 0 100-4.04zM1.07 20.411l-.01-.01a3.562 3.562 0 013.57-3.58c.86-.01 1.7.31 2.35.88M12 3.125a3 3 0 100 6 3 3 0 100-6zM11.5.88h1M12 .88v2.24M15.36 2.06l.71.71M15.71 2.41L14.12 4M17.25 5.62v1M17.25 6.12H15M16.07 9.48l-.71.71M15.71 9.84l-1.59-1.59M12.5 11.38h-1M12 11.38V9.12M8.64 10.19l-.71-.71M8.29 9.84l1.59-1.59M6.75 6.62v-1M6.75 6.12H9M7.93 2.77l.71-.71M8.29 2.41L9.88 4"/></g>'
  }
})
