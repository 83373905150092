/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'day-sunset-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 17.71h22.5M11.25 7.96v-1.5M18.14 10.81l1.06-1.06M4.36 10.81L3.3 9.75M17.267 14.7h0a6.7 6.7 0 00-8.97-3.07 6.647 6.647 0 00-3.07 3.06"/></g>'
  }
})
