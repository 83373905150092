/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-airplane-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.23 13.57a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM16.66 11h1.14M17.23 11v2.57M21.07 12.35l.81.81M21.47 12.76l-1.81 1.82M23.23 16.43v1.14M23.23 17h-2.57M21.88 20.84l-.81.81M21.47 21.24l-1.81-1.82M17.8 23h-1.14M17.23 23v-2.57M13.39 21.65l-.81-.81M12.99 21.24l1.82-1.82M11.23 17.57v-1.14M11.23 17h2.57M12.58 13.16l.81-.81M12.99 12.76l1.82 1.82M14.573 9.29l.42-1.09 3.2-1.08h-.01c1.26-.43 1.94-1.8 1.51-3.06a2.412 2.412 0 00-3.06-1.52L5.19 6.38 3.66 4.85l-2.271.76V5.6c-.51.16-.78.71-.62 1.21.04.12.1.24.19.34l3.07 3.44-.01-.01c.51.57 1.32.78 2.05.54l3.36-1.14-.69 4.86"/><path pid="1" d="M13.45 3.633L9.87 1.17h0a.98.98 0 00-.86-.13l-1.721.57h-.01c-.51.16-.78.71-.61 1.21.04.14.12.27.23.38l1.95 1.94"/></g>'
  }
})
