<template>
    <section class="panel mt-10">
        <div class="mb-8 uppercase tracking-wide font-semibold text-gray-700">
            Update password
        </div>

        <div class="w-full md:w-1/2">
            <form-field-wrapper
                label="Current Password"
                :error="form.errors.get('current_password')"
                :should-show-error="form.errors.has('current_password')"
            >
                <input
                    v-model="form.current_password"
                    class="form-field"
                    type="password"
                    placeholder="Enter Current Password"
                >
            </form-field-wrapper>

            <form-field-wrapper
                label="New Password"
                :error="form.errors.get('password')"
                :should-show-error="form.errors.has('password')"
            >
                <password-input v-model="form.password" placeholder="Enter New Password"></password-input>
            </form-field-wrapper>

            <form-field-wrapper label="Password Confirmation">
                <input
                    v-model="form.password_confirmation"
                    class="form-field"
                    type="password"
                    placeholder="Re-enter New Password"
                >
            </form-field-wrapper>
        </div>

        <div class="flex mt-8 md:justify-end">
            <stateful-button
                class="flex-1 md:flex-none button-primary"
                :loading="form.processing"
                :disabled="!form.isModified()"
                @click="changePassword"
            >
                Change Password
            </stateful-button>
        </div>
    </section>
</template>

<script>
import Form from '@/validation/Form';

export default {
    name: 'UpdatePassword',

    data () {
        return {
            form: new Form({
                current_password: '',
                password: '',
                password_confirmation: ''
            })
        };
    },

    methods: {
        changePassword () {
            this.form.patch(this.route('api.account.update'))
                .then(() => {
                    this.form.restore();
                    this.$toasted.global.success('Your password has been updated.');
                });
        }
    }
};
</script>
