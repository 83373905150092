<template>
    <div
        v-if="show"
        class="flex group hover:bg-teal-light hover:cursor-pointer hover:border-teal items-center p-6 bg-gray-50 space-x-8 border border-snow rounded"
        @click="performAction"
    >
        <button
            class="flex-none cursor-pointer"
            :class="{'cursor-not-allowed': uncheckable && checked}"
            :disabled="uncheckable"
            @click.stop="$emit('toggle')"
        >
            <app-icon
                v-if="checked"
                v-tippy
                :content="uncheckable ? '' : 'Click to mark incomplete'"
                class="w-6 h-6 text-green"
                name="check-circle-filled"
            ></app-icon>
            <div
                v-if="!checked"
                v-tippy
                :content="tooltip"
                class="w-6 h-6 rounded-full border border-snow"
            ></div>
        </button>
        <div class="w-12 h-12 hidden md:flex items-center rounded-lg justify-center flex-none" :class="`${color}`">
            <app-icon
                class="w-5 h-5 text-white"
                :name="icon.name"
                :stroke="icon.stroke"
            ></app-icon>
        </div>
        <div class="grow">
            <h3 class="font-semibold">{{ title }} <span v-if="optional" class="text-gray-500">(optional)</span></h3>
            <p class="hidden md:block text-gray-500 text-sm">{{ description }}</p>
        </div>
        <button>
            <app-icon
                v-if="!loading"
                class="w-8 h-8 text-gray-500 group-hover:text-teal"
                name="arrow-right-chevron-stroke"
            ></app-icon>

            <app-icon
                v-else
                class="h-8 w-8 text-purple"
                name="loader"
            ></app-icon>
        </button>
    </div>
</template>

<script>
import CompletesTasks from '@/mixins/CompletesTasks';

export default {
    name: 'EventTask',

    mixins: [CompletesTasks],

    props: {
        action: {
            type: Object,
            required: true
        },

        color: {
            type: String,
            required: true
        },

        checked: {
            type: Boolean,
            default: true
        },

        description: {
            type: String,
            required: true
        },

        icon: {
            type: Object,
            required: true
        },

        name: {
            type: String,
            required: true
        },

        optional: {
            type: Boolean,
            default: false
        },

        show: {
            type: Boolean,
            default: true
        },

        title: {
            type: String,
            required: true
        },

        tooltip: {
            type: String,
            default: null
        },

        uncheckable: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            loading: false
        };
    },

    mounted () {
        if (this.action.type === 'publish') {
            App.$on('publish-flow-completed', () => {
                this.loading = false;
                this.$emit('complete');
            });

            App.$on('publish-flow-errored', () => {
                this.publishing = false;
            });
        }

        if (this.name === 'previewed-event') {
            App.$on('previewed-event', () => {
                this.$emit('complete');
            });
        }
    },

    methods: {
        performAction () {
            if (this.loading) {
                return;
            }

            if (this.action.type === 'publish') {
                this.loading = true;
                App.$emit('publish-event');
            }

            if (this.action.type === 'copyPublishedUrl') {
                this.copyPublishedUrl();
                this.$emit('complete');
            }

            if (this.action.type === 'url') {
                window.open(this.action.href, this.action.target ?? '_self');
                return;
            }

            if (this.action.type === 'urlAndComplete') {
                window.open(this.action.href, '_blank');
                this.$emit('complete');
            }
        }
    }
};
</script>
