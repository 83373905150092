/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heart-gift-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M22.65 5.387h0a5.648 5.648 0 00-5.4-3.13c-2.29.12-3.25 1.27-5.26 2.99-2.01-1.72-2.97-2.865-5.25-3h0a5.637 5.637 0 00-5.4 3.12c-2.85 5.86 6.01 12.53 10.65 16.36 4.63-3.83 13.5-10.5 10.65-16.363zM12 5.25v16.5M12 10.5l3.75 3.75M8.25 14.25L12 10.5"/><path pid="1" d="M12 10.5l3.25-3.26v-.01c.74-.75 1.95-.75 2.69-.01s.74 1.95 0 2.69c-.36.35-.85.55-1.35.55zM12 10.5L8.74 7.24l-.01-.01a1.91 1.91 0 00-2.7-.01c-.75.74-.75 1.95-.01 2.69.35.35.84.55 1.34.55z"/></g>'
  }
})
