<template>
    <div class="h-full">
        <slidable-list v-model="menuToDisplay" class="h-full">
            <div key="main-nav" class="flex flex-col h-full">
                <div class="px-4 mb-2 mt-2 md:mt-0">
                    <a
                        v-if="event.classyIntegration"
                        role="button"
                        target="_blank"
                        :class="classyIntegrationButtonStyles"
                        class="whitespace-nowrap group flex flex-col space-y-2 items-center justify-center w-full p-2 text-white text-sm rounded-md cursor-pointer transition duration-150 ease-in-out"
                        @click="syncWithClassy"
                    >
                        <div class="flex items-center justify-center space-x-2">
                            <span v-if="event.classyIntegration.syncing">Syncing now with</span>
                            <span v-else-if="event.classyIntegration.last_sync_status === 'failed'">We could not connect to</span>
                            <span v-else>Re-sync with</span>
                            <logo-classy color="#fff" class="mt-1 h-6 w-12"></logo-classy>
                        </div>
                        <span class="text-center text-xs">
                            <app-icon
                                v-if="event.classyIntegration.syncing"
                                name="loader"
                                class="w-5 h-5 text-white mr-2"
                            ></app-icon>
                            <template v-else>
                                <span v-if="event.classyIntegration.last_sync_at" class="opacity-50">
                                    <template v-if="event.classyIntegration.last_sync_status === 'succeeded'">
                                        Last synced {{ event.classyIntegration.last_sync_diff_for_humans }}
                                    </template>
                                    <template v-else-if="event.classyIntegration.last_sync_status === 'failed'">
                                        Click to Retry
                                    </template>
                                </span>
                                <span v-else>Never synced</span>
                            </template>
                        </span>
                    </a>
                    <a
                        v-else
                        :href="viewEventUrl"
                        target="_blank"
                        class="group flex md:hidden items-center justify-center w-full p-2 bg-violet-600 text-violet-200 text-sm tracking-wider uppercase rounded-md hover:text-purple-50 hover:bg-violet-500 cursor-pointer transition duration-150 ease-in-out"
                    >
                        <app-icon
                            name="view"
                            class="w-5 h-5 text-violet-300 group-hover:text-purple-100 mr-2"
                            stroke
                        ></app-icon>
                        <span>View Event</span>
                    </a>
                </div>

                <div
                    ref="navContainer"
                    v-bar="{ useScrollbarPseudo: true }"
                    class="flex-1 md:flex-0"
                >
                    <div>
                        <accordion-event-nav
                            :event="event"
                            :campaign-counts="campaignCounts"
                            :collapsed="collapsed"
                        ></accordion-event-nav>
                    </div>
                </div>

                <div v-if="showTrialWarning" class="pb-2 text-center text-sm">
                    <span class="font-semibold text-teal">Free Professional Trial</span>
                    <span class="font-light text-gray-500">{{ user.days_left_on_trial }} days left</span>
                </div>

                <div class="flex-col w-full items-center justify-center pt-4 pb-2 px-2 text-gray-300 bg-black">
                    <div class="flex md:hidden flex-col items-center justify-content">
                        <event-status
                            v-if="!event.classyIntegration"
                            :event="event"
                            link
                        ></event-status>

                        <div class="flex items-center justify-center space-x-2 my-4">
                            <p class="text-white text-center leading-snug">{{ event.name }}</p>

                            <copy-published-url v-if="!isEventTemplate && !event.classyIntegration">
                                <template #default="{ copyPublishedUrl }">
                                    <div class="cursor-pointer" @click="copyPublishedUrl">
                                        <app-icon
                                            name="file-copy"
                                            class="w-4 h-4 cursor-pointer hover:text-purple-lighter transition duration-150 ease-in-out"
                                            stroke
                                        ></app-icon>
                                    </div>
                                </template>
                            </copy-published-url>
                        </div>

                        <div v-if="isEventTemplate" class="flex items-center justify-center text-purple-lighter mt-2 mb-4">
                            <p class="uppercase">Template</p>
                            <app-icon
                                name="color-painting-palette"
                                class="w-5 h-5 ml-2"
                                stroke
                            ></app-icon>
                        </div>
                    </div>

                    <div v-if="!collapsed" class="w-full px-2">
                        <button
                            class="hidden md:flex mt-4 items-center justify-center w-full py-2 text-sm tracking-wider text-violet-200 uppercase rounded-md bg-violet-600 p-1 hover:text-white hover:cursor-pointer transition duration-150 ease-in-out whitespace-nowrap"
                            @click="menuToDisplay = 'event-list-nav'"
                        >
                            <app-icon name="arrow-shuffle" class="w-4 h-4 mr-2"></app-icon>
                            Switch Events
                        </button>
                    </div>
                </div>

                <div class="md:hidden">
                    <accordion-list v-model="activeAccordionItem">
                        <accordion-list-item
                            item-id="more-options"
                            :expand-collapse-icon="false"
                        >
                            <template slot="header">
                                <div
                                    class="flex w-full items-center justify-center p-4 cursor-pointer transition duration-150 ease-in-out"
                                    :class="accordionItemHeaderStyle('more-options')"
                                >
                                    <app-icon
                                        name="navigation-menu-horizontal"
                                        class="w-5 h-5 text-gray-300"
                                        stroke
                                    ></app-icon>
                                    <p class="text-sm font-normal tracking-wider uppercase mx-5">More Options</p>
                                    <app-icon
                                        :name="accordionItemChevronIcon('more-options')"
                                        class="w-4 h-4 text-gray-300"
                                        stroke
                                    ></app-icon>
                                </div>
                            </template>

                            <template slot="content">
                                <duplicate-event-action
                                    v-if="!event.classyIntegration"
                                    v-slot="{ duplicate }"
                                    :event="event"
                                    :number-of-used-events="numberOfUsedEvents"
                                >
                                    <div class="flex items-center px-4 py-2 text-gray-300 cursor-pointer hover:text-purple-200 transition duration-150 ease-in-out text-sm" @click="duplicate">
                                        <app-icon
                                            name="duplicate-square"
                                            class="w-5 h-5 mr-5"
                                            stroke
                                        ></app-icon>
                                        Duplicate Event
                                    </div>
                                </duplicate-event-action>

                                <delete-item
                                    v-if="event.user_id === user.id"
                                    :endpoint="route('api.events.destroy', event)"
                                    :confirmation-message="deleteEventConfirmationMessage"
                                    class="flex items-center px-4 py-2 text-gray-300 cursor-pointer hover:text-red-light duration-150 ease-in-out"
                                    @delete-item="eventDeleted"
                                    @error="deleteError('Something went wrong while deleting this event.')"
                                >
                                    <app-icon
                                        name="trash"
                                        class="w-5 h-5 mr-5"
                                        stroke
                                    ></app-icon>
                                    <p class="text-sm">Delete Event</p>
                                </delete-item>

                                <unshare-item
                                    v-else
                                    :endpoint="route('account.shared-event-users.unshare')"
                                    :post-data="{ event_id: event.id }"
                                    class="flex items-center px-4 py-2 text-gray-300 cursor-pointer hover:text-red-light duration-150 ease-in-out"
                                    @unshare-item="eventUnshared"
                                    @error="deleteError('Something went wrong while unsharing this event.')"
                                >
                                    <app-icon name="trash" class="w-5 h-5 mr-5"></app-icon>
                                    <p class="text-sm">Unshare Event</p>
                                </unshare-item>

                                <a :href="route('events.create')" class="flex items-center px-4 py-2 text-gray-300 cursor-pointer hover:text-purple-200 duration-150 ease-in-out">
                                    <app-icon
                                        name="add-circle"
                                        class="w-5 h-5 mr-5"
                                        stroke
                                    ></app-icon>
                                    <p class="text-sm">Create New Event</p>
                                </a>
                            </template>
                        </accordion-list-item>

                        <accordion-list-item
                            item-id="user-options"
                            :expand-collapse-icon="false"
                            class="flex md:hidden"
                        >
                            <template slot="header">
                                <div
                                    class="flex w-full items-center justify-center px-4 py-2 cursor-pointer duration-150 ease-in-out"
                                    :class="accordionItemHeaderStyle('user-options')"
                                >
                                    <img
                                        v-if="user.avatar_url"
                                        :src="user.avatar_url"
                                        class="rounded-full w-9 h-9"
                                        alt="user avatar image"
                                    >

                                    <app-icon
                                        v-else
                                        name="user-circle"
                                        class="w-9 h-9 text-gray-300"
                                        stroke
                                    ></app-icon>

                                    <p class="text-sm font-normal tracking-wider mx-5">{{ user.name }}</p>
                                    <app-icon
                                        :name="accordionItemChevronIcon('user-options')"
                                        class="w-4 h-4 text-gray-300"
                                        stroke
                                    ></app-icon>
                                </div>
                            </template>

                            <template slot="content">
                                <a :href="route('account.show')" class="flex items-center px-4 py-2 text-gray-300 cursor-pointer hover:text-purple-200 duration-150 ease-in-out">
                                    <app-icon
                                        name="user-circle"
                                        class="w-5 h-5 mr-5"
                                        stroke
                                    ></app-icon>
                                    <p class="text-sm">My Account</p>
                                </a>

                                <a :href="route('account.shared-event-users')" class="flex items-center px-4 py-2 text-gray-300 cursor-pointer hover:text-purple-200 duration-150 ease-in-out">
                                    <app-icon name="users-location" class="w-5 h-5 mr-5"></app-icon>
                                    <p class="text-sm">Event Sharing</p>
                                </a>

                                <a
                                    href="https://help.rsvpify.com"
                                    target="_blank"
                                    class="flex items-center px-4 py-2 text-gray-300 cursor-pointer hover:text-purple-200 duration-150 ease-in-out"
                                >
                                    <app-icon
                                        name="help-buoy"
                                        class="w-5 h-5 mr-5"
                                    ></app-icon>
                                    <p class="text-sm">Get Support</p>
                                </a>

                                <a
                                    href="https://community.rsvpify.com"
                                    target="_blank"
                                    class="flex items-center px-4 py-2 text-gray-300 cursor-pointer hover:text-purple-200 duration-150 ease-in-out"
                                >
                                    <app-icon
                                        name="community-chat"
                                        class="h-5 w-5 mr-2 shrink-0"
                                    ></app-icon>
                                    Community Forum
                                </a>

                                <button class="flex items-center px-4 py-2 text-sm text-gray-300 cursor-pointer hover:text-red-light duration-150 ease-in-out" @click="logout">
                                    <app-icon
                                        name="power-button"
                                        class="w-5 h-5 mr-5"
                                    ></app-icon>
                                    Sign Out
                                </button>

                                <form
                                    ref="logoutForm"
                                    :action="route('logout')"
                                    method="POST"
                                >
                                    <input
                                        type="hidden"
                                        name="_token"
                                        :value="csrfToken"
                                    >
                                </form>
                            </template>
                        </accordion-list-item>
                    </accordion-list>
                </div>
            </div>

            <in-event-menu-select-event-list
                key="event-list-nav"
                :events="events"
                @close-event-list="menuToDisplay = 'main-nav'"
            ></in-event-menu-select-event-list>
        </slidable-list>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import Deleteable from '@/mixins/Deleteable';
import ReadsCsrfToken from '@/mixins/ReadsCsrfToken';
import IsEventPublished from '@/mixins/IsEventPublished';
import SyncsWithClassy from '@/mixins/SyncsWithClassy';
import axios from '@/util/axios';

export default {
    name: 'EventNav',

    mixins: [Deleteable, ReadsCsrfToken, IsEventPublished, SyncsWithClassy],

    props: {
        collapsed: {
            type: Boolean,
            default: false
        },

        events: {
            type: Array,
            required: true
        },

        numberOfUsedEvents: {
            type: Number,
            required: true
        },

        user: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            activeAccordionItem: null,
            menuToDisplay: 'main-nav',
            campaignCounts: {
                draft_campaign_count: 0,
                scheduled_campaign_count: 0,
                sent_campaign_count: 0
            }
        };
    },

    mounted () {
        axios.get(window.route('api.events.campaigns.counts', this.event.id))
            .then(({ data }) => {
                this.$set(this, 'campaignCounts', data);
            });

        setTimeout(() => {
            this.$vuebar.refreshScrollbar(this.$refs.navContainer);
        }, 1000);

        if (this.collapsed) {
            document.querySelector('.vb-dragger-styler').style.display = 'none';
        }
    },

    computed: {
        ...get('Event/*'),

        classyIntegrationButtonStyles () {
            const lastSyncFailed = this.event.classyIntegration.last_sync_status === 'failed';

            return {
                'opacity-0': this.collapsed,
                'bg-red': lastSyncFailed,
                'bg-teal hover:bg-teal-dark': !lastSyncFailed
            };
        },

        deleteEventConfirmationMessage () {
            return {
                title: 'Are you sure?',
                text: `Your event "${this.event.name}" and all associated data will be deleted. This cannot be undone.`
            };
        },

        isEventTemplate () {
            return this.event.user_id === null;
        },

        viewEventUrl () {
            if (this.isEventTemplate) {
                return this.event.previewUrl;
            }

            return this.isEventPublished
                ? this.route('event.show', this.event.subdomain)
                : this.event.previewUrl;
        },

        showTrialWarning () {
            return !this.event.isComped && this.user.days_left_on_trial !== null && this.event.user_id === this.user.id;
        }
    },

    watch: {
        collapsed () {
            if (this.collapsed) {
                document.querySelector('.vb-dragger-styler').style.display = 'none';
                return;
            }
            document.querySelector('.vb-dragger-styler').style.display = 'block';
        }
    },

    methods: {
        accordionItemChevronIcon (itemId) {
            return this.activeAccordionItem === itemId ? 'arrow-down-chevron' : 'arrow-up-chevron';
        },

        accordionItemHeaderStyle (itemId) {
            return {
                'bg-violet-800 text-white': this.activeAccordionItem === itemId,
                'bg-black text-purple-lighter hover:text-white hover:bg-violet-700': !(this.activeAccordionItem === itemId)
            };
        },

        eventDeleted () {
            window.location.href = this.route('dashboard');
            this.$toasted.global.success('Your event has been successfully deleted.');
        },

        eventUnshared () {
            window.location.href = this.route('dashboard');
            this.$toasted.global.success('Your event has been successfully unshared.');
        },

        logout () {
            this.$refs.logoutForm.submit();
        },

        eventUrlCopied () {
            this.$toasted.global.success('Event URL has been copied to your clipboard.');
        }
    }
};
</script>
