/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-beer-half-glass-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>brew pub bar alcohol</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M20.25 2.25l-3 7.99v-.01c-.99 2.62-1.22 5.46-.67 8.2l.56 2.84h0c.05.3-.07.6-.33.77h-.01a8.802 8.802 0 01-4.83 1.18v-.01c-1.7.08-3.38-.33-4.84-1.19l-.01-.01a.757.757 0 01-.33-.78l.56-2.85h-.01c.54-2.75.31-5.59-.67-8.21l-3-7.99"/><path pid="1" d="M12 .75a8.25 1.5 0 100 3 8.25 1.5 0 100-3zM12 9.75a4.91 1.5 0 100 3 4.91 1.5 0 100-3zM11.25 18.375h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38"/></g>'
  }
})
