/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-edit-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>document pencil write update change</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M22.63 14.87L15 22.5l-3.75.75.75-3.75 7.63-7.63h-.01c.82-.83 2.16-.83 2.98-.01 0 0 0 0 0 0h0l-.01-.01c.82.82.82 2.16 0 2.98h-.01zM3.75 6.75h10.5M3.75 11.25h10.5M3.75 15.75H9M7.5 20.25H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V2.25h0C.73 1.42 1.41.74 2.23.74h10.62l-.01-.001c.39 0 .77.15 1.06.43l2.872 2.87h0c.28.28.43.66.43 1.06v3.12"/></g>'
  }
})
