/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-map-pin-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>marker gps</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.13 4.875h-.01c.2-.01.37.16.37.37"/><path pid="1" d="M11.755 5.25h0c-.01-.21.16-.38.37-.38M12.13 5.62h-.01a.386.386 0 01-.38-.38s0 0 0 0M12.505 5.25v0c0 .2-.17.375-.375.375"/><path pid="2" d="M12.13.75h-.01c2.48-.01 4.5 2.01 4.5 4.49 0 1.92-2.69 5.57-3.91 7.13v-.01a.759.759 0 01-1.19-.01c-1.23-1.57-3.91-5.22-3.91-7.14h0A4.494 4.494 0 0112.1.7z"/><path pid="3" d="M6.135 9.78h-.01c-.52.1-.94.47-1.11.97l-3.45 10.5h-.01c-.28.78.13 1.63.91 1.91.16.05.32.08.49.08h18.26v-.001c.82-.01 1.49-.68 1.49-1.51a1.64 1.64 0 00-.09-.5l-3.45-10.5-.01-.01c-.18-.5-.6-.87-1.12-.98M7.63 23.25l1.5-9M16.63 23.25l-1.5-9M2.43 18.75h19.42M3.88 14.25h5.25M15.13 14.25h5.25"/></g>'
  }
})
