/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-expand-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 9.14a2.85 2.85 0 100 5.71 2.85 2.85 0 100-5.72zM11.52 7h.96M12 7v2.14M15.2 8.13l.67.67M15.54 8.46l-1.52 1.52M17 11.52v.96M17 12h-2.14M15.87 15.2l-.67.67M15.54 15.54l-1.52-1.52M12.48 17h-.96M12 17v-2.14M8.8 15.87l-.67-.67M8.46 15.54l1.52-1.52M7 12.48v-.96M7 12h2.14M8.13 8.8l.67-.67M8.46 8.46l1.52 1.52M1 1l5 5M1 5V1h4M23 1l-5 5M19 1h4v4M23 23l-5-5M23 19v4h-4M1 23l5-5M5 23H1v-4"/></g>'
  }
})
