<template>
    <div v-if="listsIndividualGuests">
        <template v-if="isGroupProduct">
            <div
                v-for="(group, groupIndex) in guests"
                :key="groupIndex"
                v-theme="['form.accent']"
                class="py-4 border-b"
            >
                <div
                    v-theme="['form.title-text', 'form.accent']"
                    class="text-center text-xl py-2"
                >
                    {{ `${$t('text-group')} #${groupIndex + 1}` }}
                </div>

                <guest-details
                    v-for="(guest, guestIndex) in group"
                    :key="guestIndex"
                    v-model="group[guestIndex]"
                    :error="errorBag.only(`guests.${groupIndex}.${guestIndex}`).all()[0]"
                    :invitees="invitees"
                    :show-invitee-selectors="shouldShowInviteeSelectorForGuest(guest)"
                    :require-name="product.settings.requireName.enabled"
                    :require-title="requireGuestTitle"
                    :require-email="shouldShowEmailFieldForGuest(guest)"
                    :allow-invitee-name-editing="allowNameEditing"
                    @selected-invitee="$emit('selected-invitee')"
                    @updated-invitee-name="$emit('updated-invitee-name', $event)"
                ></guest-details>
            </div>
        </template>

        <template v-else>
            <guest-details
                v-for="(guest, index) in guests"
                :key="index"
                v-model="guests[index]"
                :error="errorBag.only(`guests.${index}`).all()[0]"
                :invitees="invitees"
                :show-invitee-selectors="shouldShowInviteeSelectorForGuest(guest)"
                :require-name="product.settings.requireName.enabled"
                :require-title="requireGuestTitle"
                :require-email="shouldShowEmailFieldForGuest(guest)"
                :allow-invitee-name-editing="allowNameEditing"
                @selected-invitee="$emit('selected-invitee')"
                @updated-invitee-name="$emit('updated-invitee-name', $event)"
            ></guest-details>
        </template>
    </div>
</template>

<script>
import find from 'lodash/find';
import times from 'lodash/times';
import chunk from 'lodash/chunk';
import getValue from 'lodash/get';
import { get } from 'vuex-pathify';
import isNumber from 'lodash/isNumber';
import Errors from '@/validation/Errors';

const defaultGuest = {
    title_id: null,
    first_name: '',
    last_name: '',
    email: '',
    invitee_id: null,
    parent_invitee_id: null,
    uuid: null
};

/**
 * Certain props of the ProductGuests component are used only for the invitee
 * flow and do not change the behaviour of the component if used outside of
 * the invitee flow.
 *
 * Namely, these props are: invitees, selectedInvitees and maxNumberOfInvitees.
 */
export default {
    name: 'ProductGuests',

    props: {
        disableNameEditing: {
            type: Boolean,
            default: false
        },
        errorBag: {
            type: Errors,
            default: () => { return new Errors(); }
        },
        invitees: {
            type: Array,
            default: () => { return []; }
        },
        maxNumberOfInvitees: {
            type: Number,
            default: 99
        },
        selectedInvitees: {
            type: Array,
            default: () => { return []; }
        },
        showInviteeSelectors: {
            type: Boolean,
            default: false
        },
        product: {
            type: Object,
            required: true
        },
        quantity: {
            type: Number,
            required: true
        },
        requireGuestTitle: {
            type: Boolean,
            default: true
        },
        value: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            guests: this.value
        };
    },

    computed: {
        event: get('Event/event'),

        allowNameEditing () {
            if (this.disableNameEditing) {
                return false;
            }

            return getValue(this.event, 'invite_list.settings.allowNameEditing', false) === true;
        },

        isGroupProduct () {
            return this.product.category === 'group';
        },

        listsIndividualGuests () {
            if (this.product.category === 'add-on') {
                return false;
            }

            return this.product.settings.requireName.enabled
                || this.product.settings.requireEmail.enabled
                || this.showInviteeSelectors;
        },

        numberOfSelectedInvitees () {
            return this.selectedInvitees.length;
        }
    },

    watch: {
        guests: {
            deep: true,
            handler () {
                this.$emit('input', this.guests);
            }
        },

        quantity (newValue, oldValue) {
            if (!isNumber(newValue) || !this.listsIndividualGuests) {
                return;
            }

            // In case someone is typing in negative numbers for some reason
            // in the Quantity field of a Product
            const difference = Math.max(newValue, 0) - Math.max(oldValue, 0);

            // If the lookup block is passing guest details, the difference in
            // the number of guests will always be one and the value data
            // property will already contain the pre-filled guest
            if (difference === 1 && oldValue === 0 && this.value.length === 1) {
                this.guests.push(this.value[0]);
                return;
            }

            if (difference < 0) {
                this.guests.splice(
                    this.guests.length + difference,
                    Math.abs(difference)
                );
            } else {
                this.guests.push(
                    ...this.getPlaceholderGuests(difference)
                );
            }
        }
    },

    methods: {
        getPlaceholderGuests (quantity) {
            const guestGeneratorFunction = () => {
                return { ...defaultGuest };
            };

            if (this.isGroupProduct) {
                const { guestsPerOrder } = this.product.settings;

                return chunk(
                    times(quantity * guestsPerOrder, guestGeneratorFunction),
                    guestsPerOrder
                );
            }

            return times(quantity, guestGeneratorFunction);
        },

        shouldShowEmailFieldForGuest (guest) {
            if (this.product.settings.requireEmail.enabled) {
                return true;
            }

            const invitee = find(this.invitees, { id: guest.invitee_id });

            return invitee !== undefined && (!!invitee.prefilledEmail || !!invitee.email);
        },

        shouldShowInviteeSelectorForGuest (guest) {
            if (!this.invitees.length) {
                return false;
            }

            /**
             * Show the invitee selector for guests which already have selected
             * an invitee, or there are still invitees that are available for
             * selection
             */
            return this.numberOfSelectedInvitees < this.maxNumberOfInvitees || !!guest.invitee_id || !!guest.parent_invitee_id;
        }
    }
};
</script>
