/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-drumstick-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>chicken turkey</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.951 1.11v0l-.01-.01a6.14 6.14 0 00-7.34 2.8L8.48 9.42v-.01c-.65 1.15-.44 2.58.49 3.51L11 14.96h0c.92.93 2.36 1.13 3.51.49l5.52-3.12v-.01a6.151 6.151 0 002.79-7.34v0l-.01-.01a6.245 6.245 0 00-3.93-3.93z"/><path pid="1" d="M8.478 18.63l-.01-.01c-.18-.18-.38-.33-.59-.46l3.16-3.17-2.08-2.08-3.17 3.16-.01-.01c-.13-.22-.29-.41-.46-.59l-.01-.01a2.766 2.766 0 00-3.89-.28h-.01c-.99 1.15-.87 2.88.27 3.88.625.625 2.07 1.04 2.59.52-.516.51-.1 1.968.52 2.59l-.01-.01c1 1.14 2.73 1.26 3.88.27v-.01c.98-1.16.86-2.89-.28-3.89z"/></g>'
  }
})
