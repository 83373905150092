<template>
    <div class="flex flex-col h-full">
        <div class="mb-4 mx-4">
            <div class="block w-full relative">
                <input
                    v-model="searchTerm"
                    class="w-full py-2 px-12 bg-gray-800 placeholder-gray-300 text-white rounded-md outline-none border-2 border-transparent focus:border-purple-lighter transition duration-150 ease-in-out"
                    type="text"
                    placeholder="Find an event..."
                >

                <div class="absolute flex items-center px-4 inset-y-0 pointer-events-none text-gray-600">
                    <app-icon
                        class="h-5 w-5"
                        name="search"
                        stroke
                    ></app-icon>
                </div>

                <button
                    v-if="searchTerm"
                    class="absolute flex items-center px-4 inset-y-0 right-0 text-gray-300 hover:text-white"
                    @click="searchTerm = ''"
                >
                    <app-icon
                        class="h-4 w-4"
                        name="close"
                    ></app-icon>
                </button>
            </div>
        </div>

        <div v-bar="{ useScrollbarPseudo: true }" class="flex-1">
            <div class="border-b border-b-smoke-600">
                <div class="flex w-full items-center p-3 bg-gray-800 text-white border-l-4 border-l-purple-lighter transition duration-150 ease-in-out">
                    <app-icon
                        name="calendar"
                        class="w-5 h-5"
                        stroke
                    ></app-icon>
                    <p class="ml-5">My Events</p>
                </div>

                <div v-if="filteredEvents.length === 0" class="flex items-center m-4 text-yellow-light">
                    <app-icon name="alert-triangle" class="h-5 w-5 mr-5"></app-icon>
                    <span>No events found</span>
                </div>

                <div class="flex flex-col my-2 ml-14">
                    <a
                        v-for="event in filteredEvents"
                        :key="event.id"
                        :href="route('events.dashboard.overview', event)"
                        class="py-1 text-sm text-gray-300 hover:text-purple-200 duration-150 ease-in-out"
                    >
                        {{ event.name }}
                    </a>
                </div>

                <a :href="route('events.create')" class="flex w-full items-center p-3 text-gray-300 border-l-4 border-l-transparent hover:bg-gray-800 hover:text-white hover:border-l-purple-lighter cursor-pointer duration-150 ease-in-out">
                    <app-icon
                        name="add-circle"
                        class="w-5 h-5"
                        stroke
                    ></app-icon>
                    <p class="ml-5">Create a new event</p>
                </a>
            </div>
        </div>

        <div class="flex w-full items-center justify-center p-4 bg-black text-gray-300 border-t border-t-gray-600 hover:text-white hover:bg-gray-700 cursor-pointer duration-150 ease-in-out" @click="$emit('close-event-list')">
            <app-icon
                name="arrow-left-circle"
                class="w-5 h-5 mr-3 text-gray-300"
                stroke
            ></app-icon>
            <p class="text-sm tracking-wider">Go Back</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InEventMenuSelectEventList',

    props: {
        events: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            searchTerm: ''
        };
    },

    computed: {
        filteredEvents () {
            return this.events.filter((event) => {
                const searchRegex = new RegExp(this.searchTerm, 'i');

                return searchRegex.test(event.name);
            });
        }
    }
};
</script>
