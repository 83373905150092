<template>
    <form @submit.prevent="save">
        <form-field-wrapper label="Stripe ID" :error="form.errors.get('stripe_id')">
            <input
                v-model="form.stripe_id"
                type="text"
                class="form-field"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Amount" :error="form.errors.get('amount')">
            <input
                v-model="convertedAmount"
                type="number"
                min="0"
                step="0.01"
                class="form-field"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Description" :error="form.errors.get('description')">
            <input
                v-model="form.description"
                type="text"
                class="form-field"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Number" :error="form.errors.get('number')">
            <input
                v-model="form.number"
                type="text"
                class="form-field"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Receipt URL" :error="form.errors.get('receipt_url')">
            <input
                v-model="form.receipt_url"
                type="text"
                class="form-field"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Invoice Date" :error="form.errors.get('invoiced_at')">
            <date-time-picker
                v-model="form.invoiced_at"
                title="Invoice Date"
                has-time
                :min-datetime="null"
            ></date-time-picker>
        </form-field-wrapper>

        <div class="flex justify-end mt-4">
            <stateful-button
                v-if="editing"
                type="button"
                class="button-danger mr-2"
                :loading="deleting"
                @click="destroy"
            >Delete</stateful-button>

            <stateful-button
                v-else
                type="button"
                class="mr-2"
                @click="$emit('cancel')"
            >Cancel</stateful-button>

            <stateful-button
                type="submit"
                class="button-primary"
                :disabled="deleting"
                :loading="form.processing"
            >Save</stateful-button>
        </div>
    </form>
</template>

<script>
import { DateTime } from 'luxon';
import axios from '@/util/axios';
import Form from '@/validation/Form';

export default {
    name: 'InvoiceForm',

    props: {
        initialInvoice: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            required: true
        }
    },

    data () {
        const formData = this.initialInvoice || {
            stripe_id: '',
            amount: 0,
            description: '',
            number: '',
            receipt_url: '',
            invoiced_at: null
        };

        return {
            deleting: false,
            form: new Form({
                ...formData
            })
        };
    },

    computed: {
        convertedAmount: {
            get () {
                return this.form.amount / 100;
            },
            set (newValue) {
                this.form.amount = newValue * 100;
            }
        },

        editing () {
            return this.initialInvoice != null;
        }
    },

    methods: {
        destroy () {
            App.alert().confirm(
                'Are you sure?',
                'Once deleted, an invoice cannot be restored.',
                {},
                () => {
                    this.deleting = true;

                    axios.delete(this.route('api.admin.invoices.destroy', [this.user, this.initialInvoice]))
                        .then(() => {
                            this.$emit('delete-invoice');
                        })
                        .catch(() => {
                            this.$toasted.global.error('There was an error deleting the invoice.');
                        })
                        .finally(() => {
                            this.deleting = false;
                        });
                }
            );
        },

        newApiCall () {
            if (this.editing) {
                return this.form.put(this.route('api.admin.invoices.update', [this.user, this.initialInvoice]));
            }

            return this.form.post(this.route('api.admin.invoices.store', this.user));
        },

        save () {
            this.newApiCall()
                .then((response) => {
                    this.$toasted.global.success('Invoice saved.');

                    this.$emit('save-invoice', response.data.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        return;
                    }

                    this.$toasted.global.error('There was an error saving the invoice.');
                });
        }
    }
};
</script>
