/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-basketball-hoop-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.273 15.75h-2.53l-.01-.001c-1.66-.01-3-1.35-3-3v-2.251C.73 4.27 5.763.74 11.983.74c6.21 0 11.25 3.53 11.25 9.75v2.25c0 1.65-1.35 3-3 3h-2.53"/><path pid="1" d="M16.5 21.75l1.33-6.616v-.01c.07-.41-.2-.8-.61-.88a.784.784 0 00-.14-.02H6.87h0a.75.75 0 00-.76.74c-.01.04 0 .09.01.13l1.33 6.61M9.75 14.25l.75 9M14.25 14.25l-.75 9M6.59 17.25h10.82M7.2 20.25h9.6"/><rect pid="2" width="10.5" height="6" x="6.75" y="5.25" rx="1"/></g>'
  }
})
