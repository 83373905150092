<template>
    <app-modal
        :title="`Duplicate &quot;${originalSeatingChart.title}&quot; chart`"
        :value="value"
        @input="$emit('input', $event)"
    >
        <form-field-wrapper
            label=" Please select a group of guests to add to your chart"
            :error="form.errors.get('audience.target')"
            :should-show-error="form.errors.has('audience.target')"
        >
            <select-list v-model="form.audience.target">
                <select-option
                    id="all"
                    :label="`All invitees and confirmed guests (${allInviteesAndGuestsCount})`"
                    tooltip="Include ALL guests in invite list AND any off-list guests who have replied as well."
                    class="my-2"
                ></select-option>

                <select-option
                    id="secondary-event"
                    :label="secondaryEventsOptionLabel"
                    tooltip="Include ALL guests who have been invited to and/or replied to a selected secondary event."
                    class="my-2"
                    :disabled="!availableSecondaryEvents.length"
                ></select-option>

                <form-field-wrapper
                    v-if="form.audience.target === 'secondary-event'"
                    class="mb-4"
                    :error="form.errors.get('audience.id')"
                    :should-show-error="form.errors.has('audience.id')"
                >
                    <simple-picker
                        v-model="form.audience.id"
                        :items="availableSecondaryEvents"
                        item-label="name"
                        item-value="id"
                        placeholder-empty-state="Select secondary event..."
                    ></simple-picker>
                </form-field-wrapper>

                <select-option
                    id="tagged"
                    :label="tagsOptionLabel"
                    tooltip="Include ALL invitees and guests who have replied that are tagged with selected tag."
                    class="my-2"
                    :disabled="!availableTags.length"
                ></select-option>

                <form-field-wrapper
                    v-if="form.audience.target === 'tagged'"
                    :error="form.errors.get('audience.id')"
                    :should-show-error="form.errors.has('audience.id')"
                >
                    <simple-picker
                        v-model="form.audience.id"
                        :items="availableTags"
                        item-label="name"
                        item-value="id"
                        placeholder-empty-state="Select tag..."
                    ></simple-picker>
                </form-field-wrapper>
            </select-list>
        </form-field-wrapper>

        <template #footer="{ close }">
            <stateful-button
                class="button-primary"
                :loading="form.processing"
                @click="duplicateSeatingChart"
            >
                Duplicate
            </stateful-button>

            <button class="button" @click="close">
                Cancel
            </button>
        </template>
    </app-modal>
</template>

<script>
import { get } from 'vuex-pathify';
import Form from '@/validation/Form';

export default {
    name: 'DuplicateSeatingChartModal',

    props: {
        availableAudience: {
            type: Array,
            required: true
        },

        originalSeatingChart: {
            type: Object,
            required: true
        },

        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            form: new Form({
                audience: {
                    target: null,
                    id: null
                }
            })
        };
    },

    computed: {
        ...get('Event/*'),

        allInviteesAndGuestsCount () {
            return this.availableAudience.find((audience) => {
                return audience.target === 'all';
            }).numberOfSeatables;
        },

        availableSecondaryEvents () {
            return this.availableAudience.filter((audience) => {
                return audience.target === 'secondary-event';
            });
        },

        availableTags () {
            return this.availableAudience.filter((audience) => {
                return audience.target === 'tagged';
            });
        },

        secondaryEventsOptionLabel () {
            if (this.form.audience.target === 'secondary-event' && this.form.audience.id) {
                const selectedSecondaryEvent = this.availableAudience.find((audience) => {
                    return audience.target === 'secondary-event' && audience.id === this.form.audience.id;
                });

                return `Guests and invitees from ${selectedSecondaryEvent.name} (${selectedSecondaryEvent.numberOfSeatables})`;
            }

            return 'Guests and invitees from secondary event...';
        },

        tagsOptionLabel () {
            if (this.form.audience.target === 'tagged' && this.form.audience.id) {
                const selectedTag = this.availableAudience.find((audience) => {
                    return audience.target === 'tagged' && audience.id === this.form.audience.id;
                });

                return `Guests and invitees tagged with ${selectedTag.name} (${selectedTag.numberOfSeatables})`;
            }

            return 'Guests and invitees tagged with...';
        }
    },

    watch: {
        'form.audience.target': function () {
            this.form.audience.id = null;
        }
    },

    methods: {
        duplicateSeatingChart () {
            this.form.post(this.route('api.events.seating-charts.duplicate', [this.event, this.originalSeatingChart]))
                .then((response) => {
                    this.$toasted.global.success({
                        message: 'Seating chart successfully duplicated.',
                        onComplete: () => {
                            window.location.href = this.route('events.seating-charts.index', {
                                event: this.event,
                                newSeatingChartId: response.data.id
                            });
                        }
                    });
                }).catch(() => {
                    this.$toasted.global.error('There was a problem duplicating the seating chart.');
                });
        }
    }
};
</script>
