<template>
    <div>
        <test-mode-limited-capacity-alert></test-mode-limited-capacity-alert>
        <section class="panel">
            <div class="flex flex-col md:flex-row justify-center items-center mb-2">
                <div class="flex flex-auto items-center">
                    <div class="flex rounded-full bg-purple-100 p-2">
                        <app-icon
                            name="money-bag-dollar"
                            class="w-5 h-5 text-purple-light"
                            stroke
                        ></app-icon>
                    </div>

                    <p class="uppercase font-medium text-gray-600 ml-4">Gross Sales</p>
                </div>

                <p class="text-gray-600 text-2xl font-bold mt-4 md:mt-0">
                    {{ grossSales }}
                </p>
            </div>

            <money-chart v-if="chartData" :data="chartData"></money-chart>

            <p class="hidden md:block mt-2 text-sm text-gray-600">Showing Last 30 Days</p>
        </section>

        <div class="mt-6">
            <slidable-cards v-cloak>
                <slidable-card
                    v-for="(orderable, index) in orderables"
                    :key="index"
                    :title="orderable.title"
                >
                    <span slot="footer">
                        {{ orderable.total_quantity | number }}

                        <span v-if="orderable.hasLimitedAvailability"> of {{ orderable.maxAvailableQuantity | number }} sold</span>
                    </span>

                    <money-field static :value="orderable.total_sales"></money-field>
                </slidable-card>
            </slidable-cards>
        </div>

        <dashboard-sales-table></dashboard-sales-table>
    </div>
</template>

<script>
export default {
    name: 'DashboardSales',

    props: {
        chartData: {
            type: Object,
            required: true
        },
        grossSales: {
            type: String,
            required: true
        },
        orderables: {
            type: Array,
            required: true
        }
    }
};
</script>
