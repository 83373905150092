<template>
    <div class="flex flex-col grow">
        <div class="flex flex-wrap">
            <form-field-wrapper label="Section Icon">
                <icon-picker
                    v-model="settings.icon"
                    class="text-gray-800 w-11 h-11"
                ></icon-picker>
            </form-field-wrapper>

            <form-field-wrapper label="Section Title">
                <input
                    v-model="settings.title"
                    class="form-field"
                >
            </form-field-wrapper>

            <form-field-wrapper>
                <toggle-switch v-model="settings.displayTitle">
                    Display Section Icon &amp; Title
                </toggle-switch>

                <toggle-switch
                    v-model="settings.displayInMenu"
                    class="mt-4"
                >
                    Show Section In Menu
                </toggle-switch>
            </form-field-wrapper>

            <form-field-wrapper label="Donation Tiers">
                <draggable-list
                    v-model="settings.tiers"
                    :editable="true"
                    unique-property="id"
                    label-property="title"
                    switchable-property="active"
                    @edit-item="editTier"
                    @delete-item="deleteTier"
                ></draggable-list>

                <a
                    class="button-text button-primary mt-4"
                    role="button"
                    @click="createTier"
                >
                    <app-icon
                        name="add-circle"
                        class="h-5 w-5 mr-2"
                    ></app-icon>
                    <span>Add a Donation Tier</span>
                </a>
            </form-field-wrapper>

            <form-field-wrapper label="Button Text">
                <input
                    id="button-text"
                    v-model="settings.rawButtonText"
                    class="form-field"
                >
                <template slot="help">
                    <div>
                        Hint: You can use <strong class="font-semibold">
                            #AMOUNT#
                        </strong> and <strong class="font-semibold">
                            #TITLE#
                        </strong> placeholders to show the currently selected donation amount/donation title as the button text.
                    </div>
                </template>
            </form-field-wrapper>

            <form-field-wrapper>
                <toggle-switch v-model="settings.requireDonorName">
                    Require Donor Name
                </toggle-switch>

                <toggle-switch v-model="settings.requireDonorEmail" class="mt-4">
                    Require Donor Email Address
                </toggle-switch>
            </form-field-wrapper>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WallDonationBlockGeneralSettings',

    props: {
        settings: {
            type: Object,
            required: true
        }
    },

    methods: {
        createTier () {
            this.$emit('create-tier');
        },

        deleteTier (index) {
            this.settings.tiers.splice(index, 1);
        },

        editTier (tier) {
            this.$emit('edit-tier', tier);
        }
    }
};
</script>
