<template>
    <div class="flex flex-col grow">
        <div class="flex flex-wrap">
            <form-field-wrapper label="Section Icon">
                <icon-picker
                    v-model="form.icon"
                    class="text-gray-800 w-11 h-11"
                ></icon-picker>
            </form-field-wrapper>

            <form-field-wrapper
                label="Section Title"
                :error="form.errors.get('title')"
                :should-show-error="form.errors.has('title')"
            >
                <input
                    v-model="form.title"
                    class="form-field"
                >
            </form-field-wrapper>

            <form-field-wrapper>
                <toggle-switch v-model="form.displayTitle">
                    Display Section Icon &amp; Title
                </toggle-switch>

                <toggle-switch
                    v-model="form.displayInMenu"
                    class="mt-4"
                >
                    Show Section In Menu
                </toggle-switch>
            </form-field-wrapper>

            <form-field-wrapper label="Available Accommodations">
                <a
                    class="button-text button-info"
                    role="button"
                    @click="createItem"
                >
                    <app-icon
                        name="add-circle"
                        class="h-5 w-5 mr-2"
                    ></app-icon>
                    <span>Add an Accommodation</span>
                </a>

                <draggable-list
                    v-model="form.accommodations"
                    :editable="true"
                    unique-property="id"
                    label-property="name"
                    switchable-property="active"
                    @edit-item="editItem"
                    @delete-item="deleteItem"
                ></draggable-list>
            </form-field-wrapper>
        </div>
    </div>
</template>

<script>
import BlockableGeneralSettingsMixin from '@/mixins/BlockableGeneralSettingsMixin';

export default {
    mixins: [BlockableGeneralSettingsMixin],

    data () {
        return {
            itemsProperty: 'accommodations'
        };
    }
};
</script>
