/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'technology-tablet-touch-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.75 23.25l-2.93-2.35-.01-.01a1.62 1.62 0 01-.57-1.68v0h-.01c.21-.88 1.1-1.42 1.98-1.2.11.02.22.07.33.12l1.17.58v-5.25h0c-.01-.83.67-1.51 1.49-1.51v0h-.01c.82-.01 1.5.67 1.5 1.5v3l1.99.33h0c1.44.24 2.5 1.49 2.5 2.95v3.45M.75 17.25h9M9.75 21.75H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V2.24c0-.83.67-1.5 1.5-1.5h12-.01c.82-.01 1.5.67 1.5 1.5 0 0 0 0 0 0v6.75"/><path pid="1" d="M3.75 5.25h3v3h-3zM9.75 5.25h3v3h-3zM3.75 11.25h3v3h-3z"/></g>'
  }
})
