/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-christmas-invitation-snowflake-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>winter card</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.5 12.88V2.25v0c0-.83-.68-1.5-1.5-1.5H5.99c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v10.63"/><path pid="1" d="M21.284 11.46v-.01c.32-.26.79-.21 1.05.12.1.13.16.29.16.46v9.7c0 .82-.68 1.5-1.5 1.5H2.984a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-9.7h-.01c0-.42.33-.75.75-.75.16 0 .33.05.46.16l7.43 5.894-.01-.01c1.08.85 2.61.85 3.7 0zM15.81 15.81l2.94 2.94M8.19 15.81l-2.94 2.94M10.31 4.875L11.99 6l1.68-1.125M12 12.75v-9"/><path pid="2" d="M10.31 11.625l1.68-1.125 1.68 1.125M15.37 6.563l-1.13 1.68 1.12 1.68M7.5 8.25h9M8.625 6.563l1.125 1.68-1.125 1.68"/></g>'
  }
})
