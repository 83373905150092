/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-forbidden': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>event date time appointment block disable</desc><g _fill="currentColor"><path pid="0" d="M9.569 16.5H2.5A.5.5 0 012 16V7.5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v2.07a7.266 7.266 0 012 0V3a1 1 0 00-1-1H15a.25.25 0 01-.25-.25v-1a.75.75 0 10-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H7a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v13.5a2 2 0 002 2h7.569a7.281 7.281 0 010-2z"/><path pid="1" d="M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.459 4.459 0 01-2.386-.7l6.186-6.185A4.457 4.457 0 0122 17.5a4.505 4.505 0 01-4.5 4.5zm0-9a4.457 4.457 0 012.386.7L13.7 19.887A4.459 4.459 0 0113 17.5a4.5 4.5 0 014.5-4.5z"/></g>'
  }
})
