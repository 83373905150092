/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dessert-cupcake-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food sweet bakery</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.244 16.858l-1.15 5.17v-.01c-.16.71-.8 1.22-1.53 1.22H7.354c-.74 0-1.37-.51-1.53-1.23l-1.16-5.18"/><path pid="1" d="M9 7.169c-3.558.72-6.78 2.78-6.78 6.331v-.01A3.495 3.495 0 005.7 17c1.32 0 2.54-.75 3.14-1.94l-.01-.01a3.476 3.476 0 006.23-.01l-.01-.01a3.501 3.501 0 004.69 1.56c1.18-.6 1.93-1.82 1.93-3.15 0-3.548-3.22-5.606-6.779-6.331"/><path pid="2" d="M11.97 3.75a3 3 0 100 6 3 3 0 100-6zM11.973 3.75l-.01-.01c-.21-1.45.8-2.8 2.25-3 .24-.04.5-.04.74 0M8.54 15.58l1.18 7.67M15.4 15.58l-1.18 7.67"/></g>'
  }
})
