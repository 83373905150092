/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flag-check': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>flag check enabled activated</desc><g _fill="currentColor"><path pid="0" d="M1.5 0A1.5 1.5 0 000 1.5v21a1.5 1.5 0 003 0v-21A1.5 1.5 0 001.5 0zM23.382 2.174a1.586 1.586 0 00-1.616-.1 12.136 12.136 0 01-4.126.885 18.636 18.636 0 01-4.74-.979C9.543 1 8.38.768 5.806 1.1A1.5 1.5 0 004.5 2.59v15.02a1.5 1.5 0 001.672 1.49c2.255-.259 3.305-.039 6.442.879A19.314 19.314 0 0017.64 21a14.09 14.09 0 005.08-1.152c.672-.256 1.28-.6 1.28-1.528V3.4a1.5 1.5 0 00-.618-1.226zM14.5 15.5A4.5 4.5 0 1119 11a4.5 4.5 0 01-4.5 4.5z"/><path pid="1" d="M15.755 9.441l-1.9 2.533-.665-.665a.75.75 0 00-1.06 1.06l.815.816a1.313 1.313 0 001.981-.138l2.029-2.7a.751.751 0 00-1.2-.9z"/></g>'
  }
})
