<template>
    <div
        v-click-outside="close"
        class="relative"
        @keydown.escape.prevent="close"
        @keydown.down.prevent="highlightNext"
        @keydown.up.prevent="highlightPrev"
        @keydown.enter.prevent="selectHighlighted"
        @keydown.tab.prevent="close"
    >
        <button
            ref="trigger"
            type="button"
            :class="formFieldStyle"
            :disabled="disabled"
            @click="toggle"
        >
            <div class="flex items-center">
                <div class="grow text-left">
                    <div v-if="!!value" class="dropdown-input">
                        <slot name="input" :selected="selected">
                            {{ selected }}
                        </slot>
                    </div>
                    <div v-else class="dropdown-placeholder text-gray-400">{{ placeholderEmptyState }}</div>
                </div>
                <div class="shrink items-center px-2 text-purple">
                    <slot name="input-icon">
                        <app-icon
                            name="arrow-down-chevron"
                            class="h-4 w-4"
                            stroke
                        ></app-icon>
                    </slot>
                </div>
            </div>
        </button>

        <button class="absolute top-0 right-0 mt-2 mr-12 " @click.prevent="clear">
            <app-icon
                v-if="clearable && value"
                name="close"
                class="h-4 w-4 text-gray-300 hover:text-purple transition duration-150 ease-in-out cursor-pointer svg-icon"
                stroke
            ></app-icon>
        </button>

        <div
            v-if="hasItems || isFilterable"
            v-show="isOpen"
            ref="dropdown"
            class="p-2 left-0 bg-white border shadow z-50 dropdown-background"
        >
            <search-field
                v-if="isFilterable"
                ref="searchInput"
                v-model="searchTerm"
                class="mb-2"
                :placeholder="placeholderSearch"
                small
            ></search-field>

            <ul class="max-h-xs overflow-y-auto">
                <div v-if="!filteredItems.length" class="p-2 text-sm">
                    <template v-if="!searchTerm">No items found.</template>
                    <template v-else>No items matching "{{ searchTerm }}".</template>
                </div>

                <li
                    v-for="(item, index) in filteredItems"
                    :key="index"
                    class="p-2 cursor-pointer hover:bg-gray-100 rounded-md text-sm"
                    :class="itemClasses(item, index)"
                    @click="select(item, index)"
                >
                    <slot
                        name="item"
                        :item="item"
                        :index="index"
                    ></slot>
                </li>

                <li
                    v-if="afterListAction"
                    class="p-2 cursor-pointer hover:bg-gray-100 rounded-md text-sm text-purple text-center"
                    @click="$emit('afterListClicked')"
                >
                    {{ afterListAction }}
                </li>
            </ul>
        </div>

        <div v-if="!hasItems">
            <slot
                name="picker"
                :selected="selected"
                :is-open="isOpen"
            ></slot>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { createPopper } from '@popperjs/core';

export default {
    props: {
        afterListAction: {
            type: String,
            default: null
        },

        clearable: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        filterFunction: {
            type: Function,
            default: null
        },

        formFieldClass: {
            type: String,
            default: 'form-field'
        },

        items: {
            type: Array,
            default: () => { return []; }
        },

        isItemDisabledFunction: {
            type: Function,
            default: () => { return false; }
        },

        placeholderEmptyState: {
            type: String,
            default: 'Select an item ...'
        },

        placeholderSearch: {
            type: String,
            default: 'Search for an item ...'
        },

        startingHighlightedIndex: {
            type: Number,
            default: 0
        },

        value: {
            type: [String, Number, Object],
            default: null
        }
    },

    data () {
        return {
            highlightedIndex: this.startingHighlightedIndex,
            isOpen: false,
            searchTerm: '',
            selected: this.value
        };
    },

    computed: {
        filteredItems () {
            if (!this.searchTerm || !this.isFilterable) {
                return this.items;
            }

            return this.filterFunction(this.searchTerm, this.items);
        },

        formFieldStyle () {
            return {
                'opacity-50 cursor-not-allowed': this.disabled,
                [this.formFieldClass]: true
            };
        },

        isFilterable () {
            return this.filterFunction !== null;
        },

        hasItems () {
            return this.items && this.items.length > 0;
        }
    },

    watch: {
        value (newValue) {
            this.selected = newValue;
        },

        filteredItems () {
            this.popper?.update();
        },

        searchTerm: debounce(function (newVal) {
            this.$emit('updated-search-term', newVal);
        }, 500)
    },

    beforeDestroy () {
        this.destroyPopper();
    },

    methods: {
        toggle () {
            if (this.disabled) {
                return;
            }

            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        },

        open () {
            this.isOpen = true;
            this.$emit('open');

            this.$nextTick(() => {
                this.initializePopper();

                this.popper?.update();

                if (this.isFilterable) {
                    this.$refs.searchInput.focus();
                }
            });
        },

        clear () {
            this.$emit('input', null);
            this.close();
        },

        close () {
            if (this.isOpen) {
                this.isOpen = false;
                this.$emit('close');
            }
        },

        select (item, index) {
            if (this.isItemDisabledFunction(item)) {
                return;
            }

            this.selected = item;
            this.highlightedIndex = index;
            this.searchTerm = '';

            this.$emit('input', this.selected);
            this.close();
        },

        itemClasses (item, index) {
            return {
                'text-purple bg-gray-100 dropdown-selected': this.isItemHighlighted(item, index),
                'opacity-50': this.isItemDisabledFunction(item, index)
            };
        },

        isItemHighlighted (item, index) {
            return this.highlightedIndex === index;
        },

        selectHighlighted () {
            if (!this.isOpen) {
                return;
            }

            if (this.filteredItems.length === 1) {
                this.select(this.filteredItems[0], 0);
            } else if (this.filteredItems.length > 1) {
                this.select(this.filteredItems[this.highlightedIndex], this.highlightedIndex);
            }
        },

        highlightNext () {
            this.highlightedIndex = this.getCyclicItemIndex(this.highlightedIndex + 1);
        },

        highlightPrev () {
            this.highlightedIndex = this.getCyclicItemIndex(this.highlightedIndex - 1);
        },

        getCyclicItemIndex (newItemIndex) {
            const cycleLength = this.filteredItems.length;

            return ((newItemIndex % cycleLength) + cycleLength) % cycleLength;
        },

        initializePopper () {
            if (!this.hasItems || this.popper) {
                return;
            }

            const isInsideFixedElement = this.$el.closest('.modal-wrapper, .fixed') !== null;

            this.popper = createPopper(this.$refs.trigger, this.$refs.dropdown, {
                modifiers: this.resolveModifiers(isInsideFixedElement),
                strategy: isInsideFixedElement ? 'fixed' : 'absolute'
            });
        },

        destroyPopper () {
            if (!this.hasItems || !this.popper) {
                return;
            }

            this.popper.destroy();
        },

        resolveModifiers (isInsideFixedElement) {
            const sameWidth = {
                name: 'sameWidth',
                enabled: true,
                phase: 'beforeWrite',
                fn: ({ state }) => {
                    state.styles.popper.width = `${state.rects.reference.width}px`;
                },
                requires: ['computeStyles'],
                effect: ({ state }) => {
                    state.elements.popper.style.width = `${
                        state.elements.reference.offsetWidth
                    }px`;
                }
            };

            if (!isInsideFixedElement) {
                return [sameWidth];
            }
            return [
                { name: 'flip' },
                { name: 'preventOverflow' },
                sameWidth
            ];
        }
    }
};
</script>
