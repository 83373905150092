/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-football-goal-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.75 2.5v12h13.5v-12M11.5 14.5V22"/></g><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.59 4.72a3 1.875 0 100 3.75 3 1.875 0 100-3.75z" transform="matrix(.60345 -.7974 .79739 .60345 -.666 11.867)"/>'
  }
})
