/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attachment-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>paperclip</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18.75 15.74V7.49v0c0-3.73-3.03-6.75-6.75-6.75-3.73 0-6.75 3.02-6.75 6.75v11.25h0c0 2.48 2.01 4.49 4.5 4.49a4.507 4.507 0 004.49-4.51V7.47v0c0-1.25-1.01-2.25-2.25-2.25-1.25 0-2.25 1-2.25 2.25v9.75"/>'
  }
})
