<template>
    <form-field-wrapper
        class="text-right bg-gray-100"
    >
        <button class="button mr-2" @click="cancel">Cancel</button>

        <stateful-button
            class="button-primary"
            :loading="loading"
            :disabled="disabled"
            @click="save"
        >
            Save &amp; Close
        </stateful-button>
    </form-field-wrapper>
</template>

<script>
export default {
    name: 'EditPartyDetailsFooter',

    props: {
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        cancel () {
            this.$emit('cancel');
        },

        save () {
            this.$emit('save');
        }
    }
};
</script>
