/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-quarantine-house-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 11.52a2.85 2.85 0 100 5.71 2.85 2.85 0 100-5.72zM11.52 9.38h.96M12 9.38v2.14M15.2 10.51l.67.67M15.54 10.85l-1.52 1.51M17 13.91v.95M17 14.38h-2.14M15.87 17.58l-.67.67M15.54 17.92l-1.52-1.52M12.48 19.38h-.96M12 19.38v-2.14M8.8 18.25l-.67-.67M8.46 17.92l1.52-1.52M7 14.86v-.95M7 14.38h2.14M8.13 11.18l.67-.67M8.46 10.85l1.52 1.51"/><path pid="1" d="M3.75 12.38v9h0c0 .82.67 1.49 1.5 1.49h13.5-.01c.82 0 1.5-.68 1.5-1.5v-9M23.25 10.88l-9.89-9.226h0c-.77-.72-1.97-.72-2.73-.01l-9.89 9.22"/></g>'
  }
})
