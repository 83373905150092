/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fruit-banana': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g _fill="currentColor"><path pid="0" d="M5.916 14.538a4.005 4.005 0 001.964-.481 9.585 9.585 0 002.91-2.544 8.106 8.106 0 001.7-7.211.25.25 0 00-.474-.024A9.111 9.111 0 018.5 8.506c-2.275 1.521-4.885 2-6.494 1.2A1.1 1.1 0 00.44 9.99a1.722 1.722 0 00.038 1.54c.69 1.617 2.879 2.97 5.438 3.008z"/><path pid="1" d="M15.948 13.22a10.38 10.38 0 00-1.221-6.926.251.251 0 00-.464.123 10.44 10.44 0 01-2.285 6.011c-1.925 2.5-4.6 3.991-6.665 3.7-.957-.131-1.41.339-1.514.824a1.9 1.9 0 00.587 1.592 6.184 6.184 0 005.3 1.592 8.281 8.281 0 003.068-.96c1.88-1.003 2.892-3.884 3.194-5.956z"/><path pid="2" d="M22.135 6.5c-2.066-3.945-3.573-4.246-4.673-4.465-.715-.142-1.109-.221-1.588-1.438A.912.912 0 0015.2.02a1.27 1.27 0 00-1.109.37l-.454.467a1.42 1.42 0 00-.371 1.129 1.518 1.518 0 00.557 1.054c2.883 2.258 4.234 6.145 3.614 10.4-.526 3.612-2.391 6.634-4.639 7.52-.987.387-1.169 1.073-1.011 1.585a2.119 2.119 0 001.438 1.216c1.458.5 3.739.314 6.138-1.377C23.827 19.229 25.072 12.1 22.135 6.5z"/></g>'
  }
})
