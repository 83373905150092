/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'technology-mobile': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>cell phone android ios iphone samsung</desc><path pid="0" _fill="currentColor" d="M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zM6 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5V19a.5.5 0 01-.5.5h-11A.5.5 0 016 19z"/>'
  }
})
