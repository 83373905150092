/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'season-spring-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>leaf cloud</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.579 20.754s1.21 2.49 3.27 2.308c4.15-.38 3.88-5.907 5.4-7.859h0a9.1 9.1 0 00-8.27.07c-2.16 1.21-2.31 3.27-.41 5.47zM17.52 18.219s-2.7.33-4.63 5.03M5.25 18L1.5 21.75M10.5 18l-3.75 3.75M20.81 10.72v-.01c.39-.9.6-1.87.59-2.86h0C21.36 3.88 18.12.68 14.15.72a7.171 7.171 0 00-7 5.79h0a4.454 4.454 0 00-5.97 2.03 4.445 4.445 0 002.03 5.96c.62.3 1.3.46 1.99.45h4.77"/></g>'
  }
})
