/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'book-open-bookmark-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>library read</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 4.06v17.68M15.49 3.18h-.01c-1.01.16-2 .43-2.95.78h-.01c-.36.12-.74.12-1.09-.01l-.01-.01a13.82 13.82 0 00-9.59-.2h-.01c-.67.21-1.13.83-1.13 1.53V19.6v-.01c-.01.88.71 1.6 1.6 1.61.13 0 .27-.02.4-.06v-.01c2.87-.81 5.92-.65 8.7.44h0c.35.12.73.12 1.08-.01v-.01c2.77-1.1 5.82-1.26 8.7-.45l-.01-.01c.86.21 1.73-.3 1.95-1.16.03-.14.05-.27.05-.4V5.18h0c-.01-.7-.46-1.32-1.12-1.54h0c-.78-.25-1.57-.45-2.37-.58"/><path pid="1" d="M19.875 11.261l-2.25-2.25-2.25 2.25v-7.5h0c-.01-.83.67-1.51 1.49-1.51h1.5-.01c.82-.01 1.5.67 1.5 1.5z"/></g>'
  }
})
