/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music-speaker-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="16.5" height="22.5" x="3.75" y=".75" rx="1.5"/><path pid="1" d="M12 13.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM12 3.75a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/><path pid="2" d="M12 9.75a5.25 5.25 0 100 10.5 5.25 5.25 0 100-10.5z"/></g>'
  }
})
