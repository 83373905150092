import get from 'lodash/get';

export default {
    methods: {
        auth () {
            return {
                user () {
                    return {
                        ...window.user,
                        can (path) {
                            const [ability, model] = path.split('.');

                            return get(window.user.can, `${model}.${ability}`, false);
                        }
                    };
                },
                plan () {
                    return window.user.plan;
                }
            };
        },

        can (path) {
            return this.auth().user().can(path);
        }
    }
};
