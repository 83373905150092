/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fitness-weights-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>gym workout</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25 11.5h7.5M6.75 17.5h-.01a1.51 1.51 0 01-1.5-1.5V6.999h0a1.496 1.496 0 112.99-.02s0 0 0 0v9h0c0 .82-.68 1.5-1.5 1.5l-.01-.001zM2.25 11.5H.75"/><path pid="1" d="M3.75 8.5h-.01c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v3h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51v-3h0c0-.83-.68-1.5-1.5-1.5zM17.25 17.5h-.01c.82 0 1.5-.68 1.5-1.5V7v0c0-.83-.68-1.5-1.5-1.5-.83 0-1.5.67-1.5 1.5v9h0c0 .82.67 1.49 1.5 1.49zM21.75 11.5h1.5"/><path pid="2" d="M20.25 8.5h-.01c.82-.01 1.5.67 1.5 1.5v3c0 .82-.68 1.5-1.5 1.5-.83 0-1.5-.68-1.5-1.5v-3h0c-.01-.83.67-1.51 1.49-1.51z"/></g>'
  }
})
