/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-vaccine-virus-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.267.75h-3.04M10.75.75v2.6M4.928 2.533l-1.08 1.073-1.08 1.072M3.86 3.61l1.83 1.83M1 8.983v3.02M1 10.5h2.6M2.78 16.322l1.07 1.07 1.07 1.07M3.86 17.39l1.83-1.83M18.71 4.678l-1.08-1.072-1.08-1.073M17.64 3.61l-1.83 1.83M9.23 6.81a1.51 1.51 0 100 3.03 1.51 1.51 0 100-3.04z"/><path pid="1" d="M17.538 8.25h0a7.158 7.158 0 00-9.04-4.54 7.143 7.143 0 00-4.54 9.03 7.122 7.122 0 004.03 4.34"/><path pid="2" d="M23 15.75h0a7.67 7.67 0 01-6.01 7.49l-.01-.01a7.672 7.672 0 01-6-7.51v-2.26h0c-.01-.83.67-1.51 1.49-1.51 0-.001 0 0 0 0h9-.01c.82-.01 1.5.67 1.5 1.49 0 0 0 0 0 0zM17 15v4.5M14.75 17.25h4.5"/></g>'
  }
})
