/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'performance-magician-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.875 11.25L18 21.65l-.01-.01c.05.82-.58 1.54-1.4 1.59H5.98v-.001c-.83 0-1.51-.67-1.51-1.5-.01-.04 0-.07 0-.11l1.12-10.4M17.28 15H5.22M1.5 11.25H21M22.5.75L12 7.5M2.25 2.25h3M3.75.75v3M6 6.75h3M7.5 5.25v3"/></g>'
  }
})
