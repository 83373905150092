import {
    cloneDeep, filter, find, get, intersectionWith, map
} from 'lodash';

export default {
    data () {
        return {
            availableProductsForTimeslot: [],
            loadingAvailableProductsForTimeslot: false,
            timeslotTotalRemainingQuantity: null
        };
    },

    methods: {
        filterSoldOutProducts (products) {
            return filter(products, { active: true }).filter((product) => {
                if (this.event
                    && this.event.hasReachedMaxCapacity
                    && product.type === 'primary'
                    && product.category !== 'add-on'
                    && product.short_guest_state === 'Attending'
                ) {
                    return this.event.settings.maxCapacity.showMessage;
                }

                const availabilitySettings = product.settings.maxCapacity || {};

                if (availabilitySettings.enabled && product.quantity_remaining === 0) {
                    return availabilitySettings.showMessage;
                }

                return true;
            });
        }
    },

    computed: {
        activeProducts () {
            if (this.submission.selectedTimeSlot) {
                return this.timeslotFlowActiveProducts;
            }

            return this.regularFlowActiveProducts;
        },

        regularFlowActiveProducts () {
            if (this.isEditMode) {
                return filter(this.products, { active: true });
            }

            return this.filterSoldOutProducts(this.products);
        },

        timeslotFlowActiveProducts () {
            const products = map(intersectionWith(this.products, this.availableProductsForTimeslot, (regularFlowProduct, availableProductForTimeSlot) => {
                return regularFlowProduct.id === availableProductForTimeSlot.id;
            }), (product) => {
                if (this.isEditMode) {
                    product = cloneDeep(product);
                }

                // These settings are set on the backend within the API controller.
                // We are doing this for the MVP version to avoid having to rework most of the limited quantity frontends.
                // We are simply mimicking the old system within recurring. This allows the frontend to not know the difference for now.
                const timeslotProduct = find(this.availableProductsForTimeslot, { id: product.id });

                this.$set(product, 'has_limited_availability', get(timeslotProduct, 'settings.maxCapacity.enabled'));
                this.$set(product, 'quantity_remaining', get(timeslotProduct, 'settings.maxCapacity.quantity_remaining'));
                this.$set(product.settings, 'maxCapacity', get(timeslotProduct, 'settings.maxCapacity'));

                return product;
            });

            return this.filterSoldOutProducts(products);
        },

        products () {
            return this.block.pivot.products;
        }
    }
};
