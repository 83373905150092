/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.47 23.1l-.01-.01c.32.23.76.19 1.05-.09l10.48-10.49v-.01c.28-.29.32-.73.08-1.05l-.01-.01a4.07 4.07 0 010-4.73V6.7c.23-.33.19-.77-.09-1.06L18.26.92h0A.81.81 0 0017.2.83V.82a4.1 4.1 0 01-4.727 0h0a.806.806 0 00-1.05.08L.93 11.38v-.01c-.29.28-.33.72-.1 1.05l-.01-.01c1 1.41 1 3.31 0 4.72H.81c-.24.32-.2.77.09 1.05l4.71 4.71-.01-.01c.28.28.72.31 1.05.08h-.01a4.055 4.055 0 014.72 0z"/><rect pid="1" width="9.792" height="11.424" x="7.104" y="6.288" rx="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" transform="matrix(.7071 .7071 -.70711 .7071 12 -4.971)"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="2" d="M12.58 9.11l-3.47 3.47M13.78 12.53l-1.08 1.08"/></g>'
  }
})
