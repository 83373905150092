<template>
    <app-modal
        :value="value"
        full-screen
        :z-index="100"
        v-on="$listeners"
    >
        <template #header>
            Fix Bounces: {{ campaign.title }}
            <p class="font-normal text-gray-600 text-base">Originally sent at {{ campaign.sent_at | dateTimeTz(eventTimezone.name) }}</p>
        </template>

        <template>
            <infinite-data-table
                ref="table"
                class="text-gray-600"
                :columns="columns"
                no-results-placeholder="Sorry, we couldn't find any bounces."
                remote-sort
                :sort="sort"
                :source="dataSource"
                @change-sort="handleChangedSort"
                @received-response="onReceivedResponse"
            >
                <template slot="row" slot-scope="{ property, value, rowIndex }">
                    <div v-if="property === 'updated_identifier'">
                        <form-field-wrapper
                            :should-show-error="form.errors.has(`bounces.${rowIndex}.identifier`)"
                            :error="form.errors.get(`bounces.${rowIndex}.identifier`)"
                            class-wrapper=""
                        >
                            <input
                                v-model="form.bounces[rowIndex].identifier"
                                class="form-field"
                                type="email"
                                placeholder="Updated email address"
                            >
                        </form-field-wrapper>
                    </div>

                    <div v-else>
                        {{ value }}
                    </div>
                </template>
            </infinite-data-table>
        </template>

        <template #footer="{ close }">
            <stateful-button
                class="button bg-gray-700 text-white shadow-md"
                :loading="loading"
                :disabled="! canSend"
                @click="send"
            >
                <app-icon
                    name="email-send"
                    class="w-5 mr-2"
                    stroke
                ></app-icon>
                <span>Send to <strong>{{ sendingRecipientsCount }}</strong> Recipients</span>
            </stateful-button>

            <button class="button bg-white shadow-md" @click="close">Cancel</button>

            <div class="text-gray-500 mt-4 md:mt-0">
                {{ sendingRecipientsCount }} <span
                    v-tippy
                    class="tooltip-text mr-2"
                    :content="`Sending this email requires ${sendingRecipientsCount} credits. You have ${event.owner.remainingEmailCredits} credits remaining this period. Email credits are included in your selected plan and reset monthly. Need more credits? Chat with us!`"
                >Email Credits</span>
            </div>
        </template>
    </app-modal>
</template>

<script>
import Form from '@/validation/Form';
import EventTimezone from '@/mixins/EventTimezone';

export default {
    name: 'BouncedReportModal',

    mixins: [EventTimezone],

    props: {
        campaign: {
            type: Object,
            required: true
        },

        event: {
            type: Object,
            required: true
        },

        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            loading: false,
            sort: {},
            form: new Form({
                bounces: []
            })
        };
    },

    computed: {
        canSend () {
            return this.sendingRecipientsCount !== 0 && this.event.owner.remainingEmailCredits > this.sendingRecipientsCount;
        },

        dataSource () {
            return this.route('api.events.campaigns.messages.bounces.index', [this.event, this.campaign]);
        },

        columns () {
            return [
                {
                    label: 'Title', property: 'title', desktop: true, sortable: false
                },
                {
                    label: 'First Name', property: 'first_name', desktop: true, sortable: false
                },
                {
                    label: 'Last Name', property: 'last_name', desktop: true, sortable: false
                },
                {
                    label: 'Bounced Email', property: 'identifier', desktop: true, mobile: true, sortable: true
                },
                {
                    label: 'Updated Email', property: 'updated_identifier', desktop: true, mobile: true, sortable: false
                }
            ];
        },

        sendingRecipientsCount () {
            return this.form.bounces.filter((bounce) => {
                return bounce.identifier !== '';
            }).length;
        }
    },

    methods: {
        handleChangedSort ([newSort]) {
            this.sort = newSort || {};
        },

        onReceivedResponse (response) {
            response.data.data.forEach((bounce) => {
                this.form.bounces.push({
                    identifier: '',
                    campaign_message_id: bounce.id
                });
            });
        },

        send () {
            this.loading = true;

            this.form.put(this.route('api.events.campaigns.messages.bounces.update', [this.event, this.campaign]))
                .then(() => {
                    this.$toasted.global.success('The bounced emails have been updated and resent to the recipients.');
                    this.$refs.table.reload();
                    this.$emit('resolved-bounces');
                }, () => {
                    this.$toasted.global.error('We were not able to update the provided email addresses. Please try again.');
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
