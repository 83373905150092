<template>
    <div class="flex-1 p-8">
        <section class="panel flex flex-col space-y-6">
            <page-header icon="table-download-circle" label="Event Types">
                <a :href="route('admin.dashboard')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <div>
                <stateful-button
                    class="button button-primary"
                    form="clone-form"
                    :loading="loading"
                    type="submit"
                    @click="sync"
                >
                    Pull data from the Google Spreadsheets CMS
                </stateful-button>
            </div>

            <div v-if="hasErrors" class="alert alert-error p-2 pl-4 text-sm font-normal">
                <div class="flex flex-col space-y-6">
                    <div v-for="eventType in eventTypesWithError" :key="eventType">
                        <div class="font-extrabold text-sm">{{ eventType }}</div>
                        <div v-for="(errors, field) in errors[eventType]" :key="field">
                            <ul class="list-disc ml-4">
                                <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
import axios from '@/util/axios';
import { keys } from 'lodash';

export default {
    name: 'EventTypesCms',

    data () {
        return {
            loading: false,
            errors: {}
        };
    },

    computed: {
        hasErrors () {
            return this.eventTypesWithError.length > 0;
        },

        eventTypesWithError () {
            return keys(this.errors);
        }
    },

    methods: {
        sync () {
            this.loading = true;
            this.errors = [];

            axios.post(this.route('api.admin.event-types-cms.sync'))
                .then(() => {
                    this.loading = false;
                    this.$toasted.global.success('Event Types CMS data has been synced.');
                })
                .catch((error) => {
                    this.loading = false;

                    this.errors = error.response.data.errors;

                    this.$toasted.global.error('There was an error syncing the Event Types CMS data.');
                });
        }
    }
};
</script>
