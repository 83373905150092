/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>shopping</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M1.061 2.56v6.25h-.01c-.01.79.31 1.55.87 2.12l11.56 11.56-.01-.01c.58.58 1.53.58 2.12 0l6.87-6.88h-.01c.58-.59.58-1.54 0-2.13L10.881 1.9l-.01-.01c-.57-.57-1.33-.88-2.13-.88h-6.27c-.83 0-1.5.67-1.5 1.5z"/><path pid="1" d="M6.31 4.81a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/></g>'
  }
})
