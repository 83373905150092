<template>
    <div>
        <fancy-image-uploader
            v-slot="{ launch }"
            :conversion="imageType"
            @select-image="imageUploaded"
        >
            <button class="menu-button" @click="launch">
                <app-icon name="image-add-circle-stroke" class="h-5 w-5"></app-icon>
            </button>
        </fancy-image-uploader>
    </div>
</template>

<script>
export default {
    name: 'EditorImageUploadButton',

    props: {
        editor: {
            type: Object,
            required: true
        },

        imageType: {
            type: String,
            default: 'original'
        }
    },

    methods: {
        imageUploaded (url) {
            this.editor.commands.image({ src: url });
            this.$emit('insert-image');
        }
    }
};
</script>
