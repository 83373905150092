/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'community-chat': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>community chat</desc><path pid="0" d="M21.8 8.3h-12l-4.6 4.4V8.3h-3A1.5 1.5 0 01.8 6.7V2.3A1.5 1.5 0 012.4.8h19.4a1.5 1.5 0 011.6 1.6v4.5a1.5 1.5 0 01-1.6 1.5z" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="1" d="M2.3 11.3a1.5 1.5 0 00-1.5 1.4v4.6a1.5 1.5 0 001.4 1.4h12l4.6 4.6v-4.6h3a1.5 1.5 0 001.4-1.4v-4.6a1.5 1.5 0 00-1.4-1.4H11.2" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>'
  }
})
