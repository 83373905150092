<script>
import { find, get } from 'lodash';
import GuestPrimaryProductSelector from './GuestPrimaryProductSelector';

export default {
    name: 'GuestSecondaryProductSelector',

    extends: GuestPrimaryProductSelector,

    props: {
        blockableId: {
            type: Number,
            required: true
        },

        isEditing: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isProductUnselected () {
            return !this.selectedProduct;
        },

        canClearProductSelection () {
            return true;
        },

        selectedProduct () {
            if (this.guest.isNewGuest) {
                const selectedProductId = get(this.guest.secondaryEventProducts, this.blockableId);

                return find(this.products, { id: selectedProductId });
            }

            return find(this.guest.secondaryEventProducts, { blockable_id: this.blockableId });
        },

        selectedProductTitle () {
            if (!this.selectedProduct) {
                return `No selection`;
            }

            return this.selectedProduct.title;
        },

        unselectedProductTitle () {
            return `No selection`;
        }
    }
};
</script>
