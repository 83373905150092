/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wine-glass': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="currentColor" d="M18.545 1.265A1.523 1.523 0 0017.06 0H6.94a1.523 1.523 0 00-1.491 1.3l-.94 7.07a.965.965 0 00-.009.13 7.5 7.5 0 006.094 7.359.5.5 0 01.406.491v5.15a.5.5 0 01-.5.5H9a1 1 0 000 2h6a1 1 0 000-2h-1.5a.5.5 0 01-.5-.5v-5.15a.5.5 0 01.406-.491A7.5 7.5 0 0019.5 8.5a.965.965 0 00-.009-.132zM7.127 6a.25.25 0 01-.188-.086.247.247 0 01-.059-.2l.465-3.5A.251.251 0 017.592 2h8.816a.251.251 0 01.247.218l.465 3.5a.25.25 0 01-.247.282z"/>'
  }
})
