/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chess-figures-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.75 18h5.47v-.001c.73-.01 1.35.52 1.48 1.25l.52 1.5h0c.13.81-.42 1.59-1.24 1.72-.09.01-.17.02-.25.02h-5.25M2.81 18v-1.1H2.8c0-2.79 1.26-5.42 3.43-7.16H2.17c-.81 0-1.46-.66-1.46-1.46-.01-.39.15-.76.42-1.04l3.7-3.7V1.48l.36.08h0C8.59 2.31 11 5.33 11 8.81v.91c-3.44 3.43.69 8.27.69 8.27M15.09 9.24L14.05 15M20.25 18l-1.59-8.76M16.875 3a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75z"/><path pid="1" d="M13.42 20.526l-.5-1.5h0c-.21-.62-.78-1.03-1.43-1.03H2.73c-.65 0-1.22.41-1.43 1.02l-.5 1.5v-.01c-.27.78.16 1.63.94 1.89.15.05.31.07.47.07h9.75-.01c.82-.01 1.49-.68 1.49-1.51-.01-.17-.03-.33-.08-.48z"/></g>'
  }
})
