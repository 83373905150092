<template>
    <option-selection
        v-model="selectedOption"
        :allow-custom-choice="question.settings.hasOtherOption"
        class="mt-4"
        :options="question.options"
    ></option-selection>
</template>

<script>
import CustomQuestion from '@/mixins/CustomQuestion';

export default {
    name: 'FormSelectOneQuestion',

    mixins: [CustomQuestion],

    computed: {
        defaultValue () {
            return null;
        },

        selectedOption: {
            get () {
                if (this.internalValue) {
                    return [this.internalValue];
                }

                return [];
            },

            set (selections) {
                this.internalValue = selections[selections.length - 1];
            }
        }
    }
};
</script>
