<template>
    <form-field-wrapper>
        <div
            v-for="option in question.options"
            :key="option.id"
            class="mb-6"
        >
            <decision-input
                :title="option.title"
                :icon="option.settings.icon"
                :price="option.price"
                :description="option.settings.description"
                :value="chosenDonationOption.id === option.id"
                @input="chosenDonationOption = option"
            ></decision-input>
        </div>

        <div v-if="customAmountSettings.active" class="mb-6">
            <decision-input
                :title="customAmountSettings.title"
                :icon="customAmountSettings.settings.icon"
                :description="customAmountSettings.settings.description"
                :value="customAmountOptionSelected"
                @input="selectCustomAmountOption"
            ></decision-input>

            <div v-if="customAmountOptionSelected" class="flex items-center ml-12">
                <money-field
                    v-model="chosenDonationOption.price"
                    v-theme="['form.text', 'form.accent']"
                    input-class="block w-32 appearance-none outline-none py-2 px-4 border rounded-lg bg-transparent leading-normal transition duration-150 ease-in-out"
                    currency-prefix-class="mr-2 py-2 bold uppercase tracking-wide"
                    currency-suffix-class="ml-2 py-2 bold uppercase tracking-wide"
                    no-tooltip
                ></money-field>
            </div>
        </div>

        <div v-if="declineSettings.active" class="mb-6">
            <decision-input
                :title="declineSettings.title"
                :icon="declineSettings.settings.icon"
                :description="declineSettings.settings.description"
                :value="declineOptionSelected"
                @input="selectDeclineOption"
            ></decision-input>
        </div>
    </form-field-wrapper>
</template>

<script>
import CustomQuestion from '@/mixins/CustomQuestion';

export default {
    name: 'FormCashDonationGiftQuestion',

    mixins: [CustomQuestion],

    provide () {
        return {
            isMultipleChoice: false
        };
    },

    data () {
        return {
            chosenDonationOption: {}
        };
    },

    computed: {
        customAmountOptionSelected () {
            return this.chosenDonationOption.customAmountItem === true;
        },

        customAmountSettings () {
            return this.question.settings.customAmount || {};
        },

        declineOptionSelected () {
            return this.chosenDonationOption.declineableItem === true;
        },

        declineSettings () {
            return this.question.settings.declineable || {};
        }
    },

    watch: {
        chosenDonationOption: {
            deep: true,
            handler (option) {
                if (option.customAmountItem) {
                    this.internalValue = { ...option };
                    return;
                }

                if (option.declineableItem) {
                    this.internalValue = null;
                    return;
                }

                this.internalValue = option.id;
            }
        }
    },

    methods: {
        selectCustomAmountOption () {
            this.chosenDonationOption = { customAmountItem: true };
        },

        selectDeclineOption () {
            this.chosenDonationOption = { declineableItem: true };
        }
    }
};
</script>
