import find from 'lodash/find';
import { Settings } from 'luxon';

export default {
    computed: {
        eventTimezone () {
            // If event doesn't have a timezone, default timezone should be
            // browser's timezone. For timeslot events, timezone should always
            // exist on the event, so timeslots will not be messed up with
            // browser's timezone.
            return this.event.timezone_id
                ? find(window.timezones, { id: this.event.timezone_id })
                : find(window.timezones, { name: Settings.defaultZone.name });
        }
    }
};
