/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'family-child-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.5 2.25a3 3 0 100 6 3 3 0 100-6zM17.812 20.25l.18 1.5h3l.75-6h1.5V13.5v0c0-2.08-1.68-3.75-3.75-3.75-.82 0-1.61.26-2.25.75M4.5 2.25a3 3 0 100 6 3 3 0 100-6zM6.188 20.25l-.19 1.5h-3l-.75-6H.74V13.5h0a3.739 3.739 0 013.74-3.76c.81-.01 1.6.26 2.25.74M12 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM12 12.75h-.01c-2.08 0-3.75 1.67-3.75 3.75 0 0 0 0 0 0v.75h1.5l.75 4.5h1.5"/><path pid="1" d="M12 12.75h-.01a3.736 3.736 0 013.75 3.74v.75h-1.5l-.75 4.5h-1.5"/></g>'
  }
})
