<template>
    <div v-if="!isOrderLoaded" class="text-center">
        <app-icon
            class="h-12 w-12 text-gray-500 fill-current"
            name="loader"
        ></app-icon>
    </div>

    <div v-else-if="!showRefundScreen" class="-mt-4">
        <div class="flex items-center justify-between mt-4">
            <div>
                <base-dropdown
                    v-if="order.refunded_amount > 0"
                    placement="bottom-end"
                >
                    <template slot="trigger" slot-scope="{ triggerFunction }">
                        <div
                            class="badge badge-warning"
                            @mouseenter="triggerFunction"
                            @mouseleave="triggerFunction"
                        >
                            <div class="flex items-center space-x-2">
                                <app-icon name="refresh-arrow-stroke" class="h-4 w-4"></app-icon>
                                <div>Refunded <money-field v-model.number="order.refunded_amount" static></money-field></div>
                            </div>
                        </div>
                    </template>

                    <div class="p-4 bg-white rounded shadow text-sm">
                        <div
                            v-for="refund in order.refunds"
                            :key="refund.id"
                            class="border-b last:border-b-0 p-2"
                        >
                            <div class="font-semibold">
                                <money-field v-model.number="refund.amount" static></money-field> <span v-if="refund.user">refunded by {{ refund.user.name }}</span>
                            </div>
                            <div>{{ refund.created_at | dateTimeTz(eventTimezone.name) }}</div>
                            <div v-if="refund.description" class="italic">{{ refund.description }}</div>
                        </div>
                    </div>
                </base-dropdown>
            </div>

            <div class="flex items-center justify-end space-x-4">
                <button
                    v-if="order.refundable_amount > 0"
                    class="button button-empty button-sm"
                    @click="startRefund"
                >
                    <app-icon name="refresh-arrow-stroke" class="h-6 w-6 mr-2"></app-icon>
                    <span>Refund</span>
                </button>
                <a
                    v-if="order.payment && order.payment.receiptUrl"
                    :href="order.payment.receiptUrl"
                    class="button button-empty button-sm"
                    target="_blank"
                >
                    <app-icon name="payment-stripe-stroke" class="h-6 w-6 mr-2"></app-icon>
                    <span>View on Stripe</span>
                </a>
            </div>
        </div>
        <div
            v-for="item in orderItems"
            :key="item.id"
            class="py-6 flex justify-between border-b"
        >
            <div>
                <div class="flex font-semibold">
                    <app-icon
                        v-if="item.orderable.settings.iconName"
                        :name="item.orderable.settings.iconName"
                        class="h-5 w-5 mr-2"
                    ></app-icon>
                    {{ item.orderable.title }}
                </div>

                <div class="flex items-end text-gray-600 text-sm">
                    <div class="h-5 w-5 mr-2 mt-1"></div>
                    {{ item.quantity | number }} &times;&nbsp;<money-field v-model.number="item.price" static></money-field>
                </div>
            </div>

            <div class="flex items-center font-semibold text-lg"><money-field v-model.number="item.totalPrice" static></money-field></div>
        </div>

        <div v-if="order.coupon" class="py-6 border-b-2 flex justify-between">
            <div class="text-left">
                <div class="font-semibold mb-2">Coupon</div>
                <div class="uppercase tracking-wide text-sm text-gray-600">{{ order.coupon.code }}</div>
            </div>

            <div class="flex items-center">
                <div class="font-semibold">(<money-field v-model.number="order.discount" static></money-field>)</div>
            </div>
        </div>

        <div class="py-6 flex justify-between" :class="salesTaxSectionStyles">
            <div class="font-semibold">
                {{ salesTax.label }}
            </div>
            <div class="font-semibold text-lg"><money-field v-model.number="order.tax" static></money-field></div>
        </div>

        <div v-if="order.fee_passthrough_enabled" class="py-6 flex justify-between border-b-2">
            <div class="font-semibold">
                {{ feePassthrough.label }}
            </div>
            <div class="font-semibold text-lg">
                <money-field :value="order.passthroughFee" static></money-field>
            </div>
        </div>

        <div class="py-6 flex justify-between">
            <div class="font-semibold uppercase">
                Total
            </div>
            <div class="text-green-dark">
                <div class="flex items-center space-x-2 font-semibold text-2xl" :class="order.refunded_amount > 0 ? 'text-yellow-dark' : ''">
                    <div v-if="order.refunded_amount > 0" class="flex items-center justify-center bg-yellow-light text-yellow-dark p-2 rounded-md">
                        <app-icon name="refresh-arrow-stroke" class="h-5 w-5"></app-icon>
                    </div>

                    <money-field v-model.number="order.total" static></money-field>
                </div>
            </div>
        </div>

        <div v-if="order.payment" class="text-right">
            <div v-if="order.payment.purchaserName" class="mb-2">
                {{ order.payment.purchaserName }}
            </div>

            <div class="font-mono flex items-center justify-end">
                <app-icon
                    v-if="order.payment.creditCardBrand"
                    :name="getCreditCardBrandIcon(order.payment.creditCardBrand)"
                    class="w-8 h-8 mr-2 text-gray-800"
                ></app-icon>

                <div v-if="order.payment.creditCardLast4">
                    **** **** **** {{ order.payment.creditCardLast4 }}
                </div>
            </div>
        </div>
    </div>

    <div v-else-if="showRefundScreen" :class="{ 'opacity-50': processing }">
        <div v-if="order.refunded_amount > 0" class="text-sm font-semibold text-gray-500 pb-6">
            Payment has already been partially refunded (<money-field v-model.number="order.refundable_amount" static></money-field> remaining).
        </div>

        <form-field-wrapper
            label="Refund amount"
            :error="refundForm.errors.get('amount')"
            :should-show-error="refundForm.errors.has('amount')"
            tooltip="Refunds take 5 to 10 days to appear on customer’s credit card statement. Stripe and RSVPify service fees paid by the event organizer are non-refundable. Your event’s gross sales metrics will be updated to subtract refunded amounts."
        >
            <money-field
                v-model.number="refundForm.amount"
            ></money-field>
        </form-field-wrapper>

        <form-field-wrapper
            label="Refund note"
            :error="refundForm.errors.get('description')"
            :should-show-error="refundForm.errors.has('description')"
            tooltip="Refund reason or details. Visible to you and co-managers only."
        >
            <textarea v-model="refundForm.description" class="form-field"></textarea>

            <template #help>
                <div>optional</div>
            </template>
        </form-field-wrapper>

        <form-field-wrapper
            label="Cancel registration?"
            :error="refundForm.errors.get('cancelRegistration')"
            :should-show-error="refundForm.errors.has('cancelRegistration')"
            :tooltip="`${submission.guestsCount} guest(s) will be updated to 'Not Attending' and will not be able to check-in at your event.`"
        >
            <toggle-switch v-model="refundForm.cancelRegistration"></toggle-switch>
        </form-field-wrapper>

        <portal to="party-details-modal-footer">
            <form-field-wrapper
                class="text-right bg-gray-100"
            >
                <button class="button mr-2" @click="cancelRefund">Cancel</button>

                <stateful-button
                    class="button-primary"
                    :loading="processing"
                    :disabled="cannotRefund"
                    @click="completeRefund"
                >
                    Refund <money-field v-model.number="refundForm.amount" static></money-field>
                </stateful-button>
            </form-field-wrapper>
        </portal>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import axios from '@/util/axios';
import CreditCardBrandIcon from '@/mixins/CreditCardBrandIcon';
import Form from '@/validation/Form';
import EventTimezone from '@/mixins/EventTimezone';

export default {
    name: 'PartyDetailsPaymentsTab',

    mixins: [CreditCardBrandIcon, EventTimezone],

    props: {
        isActive: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        submission: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            isOrderLoaded: false,
            order: null,
            feePassthrough: {
                label: 'Service Fees'
            },
            refundForm: null,
            salesTax: {
                label: 'Tax & Service Fees'
            },
            processing: false,
            showRefundScreen: false
        };
    },

    computed: {
        ...get('Event/*'),

        cannotRefund () {
            return !this.refundForm || this.refundForm.amount <= 0 || this.refundForm.amount > this.order.refundable_amount;
        },

        orderItems () {
            return this.order.items.filter((item) => {
                return !item.addedByHost;
            });
        },

        salesTaxSectionStyles () {
            return {
                'border-b': this.order.fee_passthrough_enabled,
                'border-b-2': !this.order.fee_passthrough_enabled
            };
        }
    },

    watch: {
        isActive (activated) {
            if (activated) {
                this.loadOrder();
            }
        }
    },

    methods: {
        cancelRefund () {
            this.$emit('toggle-edit-mode');
            this.showRefundScreen = false;
        },

        completeRefund () {
            this.processing = true;

            this.refundForm.post(this.route('api.events.submissions.refund-payment', [this.event, this.submission]))
                .then(({ data }) => {
                    Object.assign(this.order, {
                        refunded_amount: data.data.refunded_amount,
                        refundable_amount: data.data.refundable_amount,
                        refunds: data.data.refunds
                    });

                    this.$toasted.global.success('Successfully refunded the order payment.');

                    this.$emit('toggle-edit-mode');
                    this.showRefundScreen = false;
                })
                .catch((error) => {
                    if (!error.response || error.response.status !== 422) {
                        this.$toasted.global.error('There was an error while refunding this payment.');
                    }
                })
                .finally(() => {
                    this.processing = false;
                });
        },

        loadOrder () {
            if (this.isOrderLoaded) {
                return;
            }

            axios.get(this.route('api.submissions.reporting.payment-details', this.submission))
                .then((response) => {
                    this.isOrderLoaded = true;
                    this.order = response.data.data;

                    if (response.data.feePassthrough) {
                        this.feePassthrough = response.data.feePassthrough;
                    }

                    if (response.data.salesTax) {
                        this.salesTax = response.data.salesTax;
                    }
                });
        },

        startRefund () {
            if (!this.isEditing) {
                this.$emit('toggle-edit-mode');
            }

            this.refundForm = new Form({
                amount: this.order.refundable_amount,
                description: '',
                cancelRegistration: false
            });

            this.showRefundScreen = true;
        }
    }
};
</script>
