import { make } from 'vuex-pathify';
import { v4 as uuidv4 } from 'uuid';
import keys from 'lodash/keys';
import styles from '@/themer/styles';

const moduleState = {
    styles: Object.assign(...keys(styles).map((style) => {
        return { [style]: `themed-${uuidv4()}` };
    }))
};

export default {
    namespaced: true,
    state: moduleState,
    mutations: {
        ...make.mutations(moduleState)
    }
};
