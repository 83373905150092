/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'school-man-graduate-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>user person</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 6v0c0 2.89-2.36 5.25-5.25 5.25-2.9 0-5.25-2.36-5.25-5.25V.75h10.5zM2.25 23.25h0c-.01-5.39 4.36-9.76 9.74-9.76 5.38-.01 9.75 4.36 9.75 9.74 0 0 0 0 0 0M.75.75h22.5M6.75 5.25h10.5M2.25.75v7.5"/><path pid="1" d="M7.013 14.871L12 18.741l4.98-3.88"/></g>'
  }
})
