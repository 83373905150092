/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-protection-face-shield-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7 .81c-3.56.54-6 1.645-6 2.92 0 1.81 4.925 3.28 11 3.28 6.07 0 11-1.47 11-3.28 0-1.275-2.45-2.377-6-2.92"/><path pid="1" d="M1 3.73v3.3c0 1.81 4.925 3.28 11 3.28 6.07 0 11-1.47 11-3.277V3.72"/><path pid="2" d="M4 9.31l-.9 9.794v-.01c-.08.71.25 1.41.84 1.82h0c2.39 1.53 5.2 2.32 8.05 2.25l-.01-.01c2.84.06 5.65-.72 8.05-2.26h-.01c.59-.41.91-1.11.84-1.83l-.91-9.8"/><path pid="3" d="M13 20.19v-.01a7.63 7.63 0 005-2.01"/></g>'
  }
})
