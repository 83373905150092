import Vue from 'vue';
import Vuebar from 'vuebar';
import SvgIcon from 'vue-svgicon';
import PortalVue from 'portal-vue';
import vClickOutside from 'v-click-outside';
import * as VueGoogleMaps from 'vue2-google-maps';
import VeeValidate from 'vee-validate';
import VueTippy, { TippyComponent } from 'vue-tippy';
import VueClipboard from 'vue-clipboard2';
import VueToasted from 'vue-toasted';
import VueI18n from 'vue-i18n';
import { VueMasonryPlugin } from 'vue-masonry';
import { asset } from '@codinglabs/laravel-asset';
import Auth from '@/mixins/Auth';
import ListensToWindowResizeEvent from '@/mixins/ListensToWindowResizeEvent';

require('./validation/custom-rules');

Vue.use(Vuebar);
Vue.use(SvgIcon, {
    tagName: 'svg-icon',
    classPrefix: 'svg',
    isStroke: false,
    isOriginalDefault: true
});
Vue.use(PortalVue);
Vue.use(vClickOutside);
Vue.mixin({
    methods: {
        asset,
        route: window.route,

        waitTicks (count) {
            if (count === 0) {
                return Promise.resolve();
            }

            return new Promise((resolve) => {
                this.$nextTick(() => {
                    resolve(this.waitTicks(count - 1));
                });
            });
        }
    }
});
Vue.mixin(ListensToWindowResizeEvent);
Vue.mixin(Auth);

Vue.use(VueGoogleMaps, {
    load: {
        key: window.googlePlacesApiKey,
        libraries: 'places'
    }
});

Vue.use(VeeValidate);

Vue.use(VueTippy, {
    animation: 'shift-toward',
    arrow: true,
    arrowType: 'round',
    distance: 15,
    onShow: (options) => {
        return !!options.props.content;
    }
});
Vue.component('Tippy', TippyComponent);

Vue.use(VueClipboard);

Vue.use(VueToasted, {
    duration: 4000,
    position: 'top-right',
    theme: 'toasted-primary'
});

Vue.use(VueI18n);
Vue.use(VueMasonryPlugin);

require('./custom-toasts');
