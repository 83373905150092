<template>
    <a role="button" @click="deleteItem">
        <slot></slot>
    </a>
</template>

<script>
import axios from '@/util/axios';

export default {
    props: {
        confirmationMessage: {
            type: Object,
            default: () => {
                return {
                    title: 'Are you sure?',
                    text: 'Do you really want to delete this item?'
                };
            }
        },
        confirmationSettings: {
            type: Object,
            default: () => {
                return {};
            }
        },
        endpoint: {
            type: String,
            default: ''
        }
    },

    methods: {
        deleteItem () {
            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'question',
                ...this.confirmationSettings
            };

            const onConfirm = () => {
                if (this.endpoint === '') {
                    return this.$emit('delete-item');
                }

                this.handleDelete();
            };

            App.alert().confirm(
                this.confirmationMessage.title,
                this.confirmationMessage.text,
                attributes,
                onConfirm
            );
        },

        handleDelete () {
            axios.delete(this.endpoint)
                .then((response) => {
                    this.$emit('delete-item', response);
                }).catch((error) => {
                    this.$emit('error', error);
                });
        }
    }
};
</script>
