/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-map-pin-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>marker gps</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25 9V4.64c0-.62-.38-1.17-.95-1.4l-6-2.4-.01-.01a1.45 1.45 0 00-1.12 0L8.78 3.38h-.01c-.36.14-.76.14-1.12-.01L1.73 1h0a.747.747 0 00-.98.41c-.04.08-.06.18-.06.28v14.37H.68c-.01.61.37 1.16.94 1.39l6 2.4h0c.35.14.75.14 1.11-.01l2.88-1.16M8.25 3.52v16.5M15.75.75v7.5M18.75 15.449h-.01c.2-.01.37.16.37.37"/><path pid="1" d="M18.375 15.824h0c-.01-.21.16-.38.37-.38M18.75 16.2h-.01a.386.386 0 01-.38-.38s0 0 0 0M19.125 15.824v-.001c0 .2-.17.375-.375.375"/><path pid="2" d="M18.75 11.324h-.01c2.48-.01 4.5 2.01 4.5 4.49 0 1.92-2.69 5.57-3.91 7.138v-.01a.759.759 0 01-1.19-.01c-1.221-1.561-3.91-5.22-3.91-7.138v-.01c0-2.49 2.01-4.5 4.5-4.5z"/></g>'
  }
})
