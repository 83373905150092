<template>
    <dashboard-data
        :always-loaded-columns="['formalName', 'orderTotal']"
        :custom-column-definitions="customColumnDefinitions"
        :default-columns="['firstName', 'lastName', 'email', 'purchases', 'orderTotal']"
        :event="event"
    >
        <template #row="{ openSubmissionModal, property, row }">
            <template v-if="property === 'purchases'">
                <a
                    role="button"
                    class="text-sm mt-2 text-purple-light hover:text-purple hover:cursor-pointer"
                    @click="openSubmissionModal(row)"
                >View Details</a>
            </template>
        </template>

        <template #summary="{ grouped, openSubmissionModal, row, rowIndex }">
            <div class="flex">
                <div class="flex flex-col flex-auto">
                    <p>{{ row.formalName }}</p>

                    <a
                        role="button"
                        class="text-sm mt-2 text-purple-light hover:text-purple hover:cursor-pointer"
                        @click="openSubmissionModal(row)"
                    >View Details</a>
                </div>

                <div class="flex items-center justify-center">
                    <p v-if="shouldShowTotal(grouped, rowIndex)" class="text-sm">
                        <money-field static :value="row.orderTotal"></money-field>
                    </p>
                </div>
            </div>
        </template>
    </dashboard-data>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    name: 'DashboardSalesTable',

    computed: {
        customColumnDefinitions () {
            return [
                {
                    label: 'Purchases',
                    property: 'purchases',
                    sortable: false,
                    virtual: true
                }
            ];
        },

        event: get('Event/event')
    },

    methods: {
        shouldShowTotal (grouped, rowIndex) {
            if (!grouped) {
                return false;
            }

            return rowIndex === 0;
        }
    }
};
</script>
