<template>
    <div class="container">
        <section class="panel">
            <page-header icon="shopping-pay-dollar-stroke" label="Stripe Fees">
                <a :href="route('admin.dashboard')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <div class="row no-gutters">
                <div class="col-10 md:col-6">
                    <search-field
                        v-model="searchTerm"
                        class="w-full"
                        placeholder="Search by country name..."
                    ></search-field>
                </div>

                <div class="col-2 md:col-6 justify-end">
                    <button class="button-icon button-primary button-lg" @click="isCreateStripeFeesModalVisible = true">
                        <app-icon name="add-circle" stroke></app-icon>
                    </button>
                </div>
            </div>

            <div
                v-if="filteredStripeFees.length === 0"
                role="alert"
                class="alert alert-warning w-full mt-4"
            >
                We couldn't find any countries matching the criteria.
            </div>

            <div class="row">
                <div
                    v-for="stripeFees in filteredStripeFees"
                    :key="stripeFees.id"
                    class="col-12 md:col-6 mt-4"
                >
                    <div class="flex w-full items-center p-4 rounded-md border shadow">
                        <p class="flex-1">{{ countryNamesByCodeMapping[stripeFees.country_code] }}</p>

                        <button class="text-gray-500 hover:text-purple transition duration-150 ease-in-out" @click="customizeStripeFees(stripeFees)">
                            <app-icon
                                name="settings-cog"
                                class="w-6 h-6"
                                stroke
                            ></app-icon>
                        </button>
                    </div>
                </div>
            </div>
        </section>

        <create-stripe-fees-modal
            v-model="isCreateStripeFeesModalVisible"
            @create-stripe-fees="handleStripeFeesCreated"
        ></create-stripe-fees-modal>

        <update-stripe-fees-modal
            v-model="isUpdateStripeFeesModalVisible"
            :initial-stripe-fees="stripeFeesBeingCustomized"
            @update-stripe-fees="handleStripeFeesUpdated"
        ></update-stripe-fees-modal>
    </div>
</template>

<script>
import map from 'lodash/map';
import zipObject from 'lodash/zipObject';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import countries from '@/components/core/pickers/country-picker/countries';

export default {
    props: {
        initialStripeFees: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            isCreateStripeFeesModalVisible: false,
            isUpdateStripeFeesModalVisible: false,
            searchTerm: '',
            stripeFees: [],
            stripeFeesBeingCustomized: {}
        };
    },

    computed: {
        countryNamesByCodeMapping () {
            return zipObject(
                map(countries, 'code'),
                map(countries, 'name')
            );
        },

        filteredCountryCodes () {
            const searchRegex = new RegExp(this.searchTerm, 'i');

            const filteredCountries = countries.filter(({ name }) => {
                return searchRegex.test(name);
            });

            return map(filteredCountries, 'code');
        },

        filteredStripeFees () {
            return this.stripeFees.filter(({ country_code }) => {
                return this.filteredCountryCodes.includes(country_code);
            });
        }
    },

    created () {
        this.stripeFees = cloneDeep(this.initialStripeFees);
    },

    methods: {
        customizeStripeFees (stripeFees) {
            this.stripeFeesBeingCustomized = stripeFees;

            this.isUpdateStripeFeesModalVisible = true;
        },

        handleStripeFeesCreated (newStripeFees) {
            this.stripeFees.push(newStripeFees);
        },

        handleStripeFeesUpdated (updatedStripeFees) {
            const index = findIndex(this.stripeFees, {
                id: updatedStripeFees.id
            });

            this.stripeFeesBeingCustomized = updatedStripeFees;

            this.$set(this.stripeFees, index, updatedStripeFees);
        }
    }
};
</script>
