/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-location-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.736 13.8c2.74.95 4.51 2.48 4.51 4.2 0 2.9-5.04 5.25-11.25 5.25-6.22 0-11.25-2.36-11.25-5.26 0-1.72 1.76-3.237 4.485-4.195M12 .75A2.625 2.625 0 1012 6a2.625 2.625 0 100-5.25z"/><path pid="1" d="M15.75 12.745v-2.25 0c0-2.08-1.68-3.75-3.75-3.75-2.08 0-3.75 1.67-3.75 3.75v2.25h1.5l.75 6h3l.75-6z"/></g>'
  }
})
