<template>
    <div class="mt-8 -mx-8 md:mx-0">
        <div v-if="showDuplicatingEmailDraftCampaigns" class="flex flex-col md:flex-row alert alert-warning items-center">
            <app-icon name="alert-triangle" class="h-6 w-6 mr-0 md:mr-4 mb-4 md:mb-0"></app-icon>
            <span class="text-center md:text-left">Your email drafts are being cloned. For larger events, this may take a few minutes. Please come back or refresh this page in a few minutes.</span>
        </div>

        <infinite-data-table
            class="text-gray-600"
            clickable-rows
            :columns="chosenColumns"
            :filter="filter"
            no-results-placeholder="Sorry, we couldn't find any campaigns."
            remote-sort
            :sort="sort"
            :source="dataSource"
            @change-sort="handleChangedSort"
            @click-row="redirectToEdit"
        >
            <template #header>
                <div class="flex-auto">
                    <search-field
                        v-model="filter.term"
                        class="w-full md:w-72"
                        placeholder="Search by title or subject..."
                    ></search-field>
                </div>

                <choosable-columns
                    v-model="chosenColumns"
                    class="mr-2 hidden md:block"
                    :columns="columns"
                ></choosable-columns>

                <a :href="route('events.campaigns.drafts.create', event)" class="button button-primary p-2">
                    <app-icon
                        class="w-5 h-5 mr-2"
                        name="add-circle"
                        stroke
                    ></app-icon>
                    New Email
                </a>
            </template>

            <template slot="row" slot-scope="{ row, property, value, reload }">
                <!-- Summary column represents mobile representation of table data. -->
                <div v-if="property === 'summary'" class="flex">
                    <div class="flex flex-col flex-auto text-gray-600">
                        <p>{{ row.title }}</p>
                        <p class="text-sm text-purple">{{ row.subject }}</p>
                        <p class="text-sm">Edited by {{ row.updated_by || 'Deleted Account' }} on {{ row.updated_at | dateTimeTz(eventTimezone.name) }}</p>
                    </div>

                    <div class="flex items-center ml-4" @click.stop>
                        <base-dropdown v-cloak placement="bottom-end">
                            <template slot="trigger" slot-scope="{ triggerFunction }">
                                <button class="flex button-icon" @click.stop="triggerFunction">
                                    <app-icon name="navigation-menu-horizontal" stroke></app-icon>
                                </button>
                            </template>

                            <div class="w-40 bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden">
                                <a
                                    class="flex items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out"
                                    :href="getEditRoute(row.id)"
                                >
                                    Edit
                                </a>

                                <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="duplicateCampaign(event, row.id)">Duplicate</button>

                                <delete-item
                                    class="flex items-center px-4 py-2 cursor-pointer font-medium hover:text-red hover:bg-gray-100 transition duration-150 ease-in-out"
                                    :confirmation-message="{ title: 'Are you sure?', text: 'Do you really want to delete this draft campaign?' }"
                                    :endpoint="route('api.events.campaigns.destroy', { event, campaign: row.id })"
                                    @delete-item="reload"
                                >
                                    Delete
                                </delete-item>
                            </div>
                        </base-dropdown>
                    </div>
                </div>

                <div v-else-if="property === 'updated_at'">
                    {{ row.updated_at | dateTimeTz(eventTimezone.name) }}
                </div>

                <!--Note that the Options column is a "fake" column/property name and it does not actually have a label -->
                <div
                    v-else-if="property === 'options'"
                    class="flex items-center mx-4"
                    @click.stop
                >
                    <base-dropdown v-cloak placement="bottom-end">
                        <template slot="trigger" slot-scope="{ triggerFunction }">
                            <button class="flex button-icon" @click.stop="triggerFunction">
                                <app-icon name="navigation-menu-horizontal"></app-icon>
                            </button>
                        </template>

                        <div class="w-40 bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden">
                            <a
                                class="flex items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out"
                                :href="getEditRoute(row.id)"
                            >
                                Edit
                            </a>

                            <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="duplicateCampaign(event, row.id)">Duplicate</button>

                            <delete-item
                                class="flex items-center px-4 py-2 cursor-pointer font-medium hover:text-red hover:bg-gray-100 transition duration-150 ease-in-out"
                                :confirmation-message="{ title: 'Are you sure?', text: 'Do you really want to delete this draft campaign?' }"
                                :endpoint="route('api.events.campaigns.destroy', { event, campaign: row.id })"
                                @delete-item="reload"
                            >
                                Delete
                            </delete-item>
                        </div>
                    </base-dropdown>
                </div>

                <div v-else>
                    {{ value }}
                </div>
            </template>
        </infinite-data-table>
    </div>
</template>

<script>
import { get } from 'lodash';
import EventTimezone from '@/mixins/EventTimezone';
import DuplicateCampaign from '@/mixins/DuplicateCampaign';

export default {
    name: 'DraftCampaigns',

    mixins: [DuplicateCampaign, EventTimezone],

    props: {
        event: {
            required: true,
            type: Object
        }
    },

    data () {
        return {
            chosenColumns: [],
            sort: {},
            filter: {
                term: ''
            }
        };
    },

    computed: {
        dataSource () {
            return this.route('api.events.campaigns.drafts.index', this.event);
        },

        columns () {
            return [
                {
                    label: 'Email',
                    property: 'summary',
                    desktop: false,
                    mobile: true,
                    alwaysActive: true
                },
                {
                    label: 'Title',
                    property: 'title',
                    desktop: true,
                    sortable: true
                },
                {
                    label: 'Subject Line',
                    property: 'subject',
                    desktop: true,
                    sortable: true
                },
                {
                    label: 'Last Edited',
                    property: 'updated_at',
                    desktop: true,
                    sortable: true
                },
                {
                    label: 'Last Edited By',
                    property: 'updated_by',
                    desktop: true,
                    sortable: false
                },
                {
                    label: '',
                    property: 'options',
                    desktop: true,
                    sortable: false
                }
            ];
        },

        showDuplicatingEmailDraftCampaigns () {
            return get(this.event, 'metadata.duplicating.campaigns', false);
        }
    },

    methods: {
        getEditRoute (campaignId) {
            return this.route('events.campaigns.edit', {
                event: this.event,
                campaign: campaignId
            });
        },

        handleChangedSort ([newSort]) {
            this.sort = newSort || {};
        },

        redirectToEdit (row) {
            window.location.href = this.getEditRoute(row.id);
        }
    }
};
</script>
