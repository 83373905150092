/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cards-spade-diamond-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>game casino deck</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.511 1.96l-.01-.01c-.16-.81-.94-1.34-1.75-1.19h-.01l-8.8 1.7h-.01C1.12 2.62.59 3.4.74 4.21l2.56 13.2-.01-.01c.15.8.94 1.33 1.75 1.18l.82-.16"/><path pid="1" d="M8.35 6.836l-1.64 3.36.75.68M3.487 4.472h-.01c.2-.04.39.09.43.29 0 0 0 0 0 0"/><path pid="2" d="M3.19 4.91h0a.37.37 0 01.29-.44v-.01"/><path pid="3" d="M3.629 5.208v-.01c-.21.03-.4-.1-.44-.3l-.01-.01"/><path pid="4" d="M3.92 4.768h0c.03.2-.1.4-.3.43h-.01"/><rect pid="5" width="11.953" height="16.435" x="9.966" y="5.972" rx="1" transform="rotate(11.48 15.957 14.171)"/><path pid="6" d="M15.35 17.12l.3-1.47M16.682 10.52s-2.5 1.02-2.79 2.48h-.01c-.25 1.21.53 2.39 1.75 2.64 1.21.24 2.39-.54 2.64-1.76.29-1.47-1.61-3.38-1.61-3.38zM13.831 7.66h0c.2.04.33.23.29.44M13.38 7.957v-.01a.37.37 0 01.44-.3"/><path pid="7" d="M13.682 8.4l-.01-.01a.384.384 0 01-.3-.45"/><path pid="8" d="M14.12 8.106v-.01c-.05.2-.24.33-.45.29M18.2 19.979h0c.2.04.33.23.29.44M17.76 20.272v-.01a.37.37 0 01.44-.3M18.053 20.71l-.01-.01a.384.384 0 01-.3-.45"/><path pid="9" d="M18.5 20.421v-.01c-.05.2-.24.33-.45.29"/></g>'
  }
})
