/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.593 6.8l1.4 2.95h2.37-.01c.35-.01.65.27.66.63 0 .17-.07.34-.2.47l-2.19 1.94 1.2 2.77h0c.14.33-.02.72-.36.86-.2.07-.41.06-.59-.04l-2.93-1.65-2.923 1.64v-.01a.663.663 0 01-.94-.83l1.2-2.78-2.19-1.95-.01-.01a.661.661 0 01.46-1.13h2.377l1.4-2.95h-.01c.16-.33.56-.46.89-.29.12.06.22.16.28.28z"/><path pid="1" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/></g>'
  }
})
