<template>
    <div
        class="alert alert-warning alert-global w-full flex items-center border-b last:border-b-0 border-yellow-dark"
        role="alert"
    >
        <div>{{ notification.data.message }}</div>

        <a
            v-if="notification.data.cta"
            :href="notification.data.cta.destination"
            class="alert-button"
        >
            {{ notification.data.cta.text }}
        </a>

        <div class="flex-1"></div>

        <button class="button-icon button-sm ml-4" @click="$emit('dismiss')">
            <app-icon name="close"></app-icon>
        </button>
    </div>
</template>

<script>
export default {
    name: 'SystemAlert',

    props: {
        notification: {
            type: Object,
            required: true
        }
    }
};
</script>
