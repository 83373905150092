/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'table-warning-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25 18.75h-4.5l-.01-.001c-1.66-.01-3-1.35-3-3V3.748h0A2.998 2.998 0 013.73.73h12-.01c1.65-.01 3 1.34 3 3v4.5M6.75.75v18M12.75.75v9M18.75 6.75h-18M9.75 12.75h-9M17.25 20.25h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M17.25 18v-3"/><path pid="1" d="M23.063 20.682l-.01-.01c.43.87.08 1.94-.8 2.38-.25.12-.52.18-.8.18h-8.46v-.001c-.98 0-1.78-.8-1.78-1.78-.01-.28.06-.55.18-.8l4.22-8.451h-.01a1.76 1.76 0 012.37-.8c.34.17.62.45.79.79z"/></g>'
  }
})
