/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'target-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>marker</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 9a3 3 0 100 6 3 3 0 100-6z"/><path pid="1" d="M12 4.5a7.5 7.5 0 100 15 7.5 7.5 0 100-15zM12 .75V4.5M.75 12H4.5M12 23.25V19.5M23.25 12H19.5"/></g>'
  }
})
