<template>
    <div :class="containerClass">
        <input
            v-model="password"
            :class="inputClass"
            type="password"
            v-bind="$attrs"
        >

        <password-strength-meter
            style="max-width: none !important;"
            :value="password"
            :strength-meter-only="true"
            strength-meter-fill-class="password-strength-meter-bars"
        ></password-strength-meter>
    </div>
</template>

<script>
import PasswordStrengthMeter from 'vue-password-strength-meter';

export default {
    name: 'PasswordInput',

    components: {
        PasswordStrengthMeter
    },

    props: {
        containerClass: {
            type: String,
            default: null
        },

        inputClass: {
            type: String,
            default: 'form-field'
        },

        value: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            password: this.value
        };
    },

    watch: {
        password (newValue) {
            this.$emit('input', newValue);
        },

        value (newValue) {
            this.password = newValue;
        }
    }
};
</script>
