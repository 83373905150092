/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video-webcam-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>live stream camera monitor computer desktop</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 2.25h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0a.38.38 0 00-.38-.38v0M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM15.75 15h0A4.251 4.251 0 0010 13.24c-.75.39-1.36 1-1.75 1.75"/><path pid="1" d="M15.75 3h6-.01c.82-.01 1.5.67 1.5 1.5v12c0 .82-.68 1.5-1.5 1.5H2.23a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-12h0c-.01-.83.67-1.51 1.49-1.51h6M15.75 22.5h-7.5L9 18h6l.75 4.5zM6 22.5h12"/></g>'
  }
})
