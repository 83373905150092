/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 3.75a8.25 8.25 0 100 16.5 8.25 8.25 0 100-16.5zM13.75.75h-3.5M12 .75v3M5.282 2.808L4.045 4.045 2.808 5.282M4.05 4.05l2.12 2.12M.75 10.25v3.5M.75 12h3M2.808 18.718l1.237 1.23 1.237 1.23M4.05 19.95l2.12-2.12M10.25 23.25h3.5M12 23.25v-3M18.718 21.192l1.23-1.24 1.23-1.24M19.95 19.95l-2.12-2.12M23.25 13.75v-3.5M23.25 12h-3M21.192 5.282l-1.24-1.237-1.24-1.237M19.95 4.05l-2.12 2.12"/><path pid="1" d="M16.75 11.75a.5.5 0 100 1 .5.5 0 100-1zM12.75 16.25a.5.5 0 100 1 .5.5 0 100-1zM9.5 7.75a1.75 1.75 0 100 3.5 1.75 1.75 0 100-3.5z"/></g>'
  }
})
