import Vue from 'vue';
import { make } from 'vuex-pathify';

const moduleState = {
    isEditMode: false,
    isMobileView: false,
    selectedBlock: null,
    wall: {}
};

export default {
    namespaced: true,
    state: moduleState,
    mutations: {
        ...make.mutations(moduleState),

        touch (state) {
            if (state.wall.published_at !== null) {
                state.wall.hasUnpublishedChanges = true;
            }
        }
    }
};
