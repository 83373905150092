<template>
    <div class="relative">
        <div
            v-if="selectedBlock"
            id="blockPositioner"
            class="bg-black fixed top-1/2 -translate-x-1/2 -translate-y-1/2 z-90 opacity-0 max-h-screen-80"
            :style="{ width: `${selectedBlockElementBox.width}px`, height: `${selectedBlockElementBox.height}px`, left: 'calc(50% + 185px)'}"
        ></div>

        <block-settings
            v-if="selectedBlock"
            ref="blockSettings"
            domain="form"
            :event="event"
            :saving="saving"
            :setup-types="setupTypes"
            @close-settings="closeBlockSettings"
            @save-settings="saveBlockSettings"
        >
            <template #overlay="{ clickOutsideOfDrawer }">
                <transition
                    enter-class="opacity-0"
                    enter-active-class="ease-out duration-300"
                    enter-to-class="opacity-100"
                    leave-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-to-class="opacity-0"
                    appear
                >
                    <div
                        class="fixed w-screen h-screen z-90 bg-snow-950"
                        @click="clickOutsideOfDrawer"
                    ></div>
                </transition>
            </template>
        </block-settings>

        <form-theme-drawer
            :is-drawer-open="isThemeDrawerOpen"
            :sections="formSections"
            title="Update Form Theme"
            @update-settings="formThemeUpdated"
            @keep-editing="showModal = true"
        ></form-theme-drawer>

        <div
            v-if="isHeaderBarVisible"
            ref="headerBar"
            class="fixed md:top-18 top-13 right-0 left-0 z-50 h-12 bg-yellow text-yellow-dark"
            :style="{'margin-left': headerBarOffset}"
        >
            <div v-if="!isBlockPlacementAvailable" class="flex w-full h-full justify-center items-center">Custom blocks cannot be placed above primary blocks.</div>

            <stateful-button
                v-else-if="form.hasUnpublishedChanges && isFloatingRepublishButtonVisible"
                class="w-full h-full rounded-none bg-transparent hover:bg-transparent text-yellow-dark hover:text-white"
                @click="scrollToTop"
            >
                Republish?
                <app-icon name="arrow-up-chevron-stroke"></app-icon>
            </stateful-button>
        </div>

        <transition name="slide-fade-vertical">
            <div
                v-if="form.hasUnpublishedChanges"
                ref="unpublishedChangesNotification"
                class="call-to-action call-to-action-warning flex-col max-w-2xl mx-auto"
            >
                <template v-if="publishing_blocker === 'paymentMethod'">
                    <p>
                        Please connect a payment processing provider before republishing your event.
                    </p>

                    <div class="flex flex-col md:flex-row justify-center mt-6">
                        <a
                            :href="route('settings.event.payments-and-coupons', event)"
                            class="w-auto inline-flex button button-soft"
                        >
                            <app-icon name="settings-cog"></app-icon>
                            Payments &amp; Coupons Settings
                        </a>
                    </div>
                </template>
                <template v-else>
                    {{ republishMessage }}

                    <div class="flex flex-col md:flex-row mt-6">
                        <div class="flex flex-col md:flex-row flex-auto">
                            <stateful-button
                                class="button call-to-action-button mb-4 mr-0 md:mb-0 md:mr-4 "
                                :loading="publishing"
                                @click="republish"
                            >
                                Republish
                            </stateful-button>

                            <a
                                class="button button-soft mb-4 md:mb-0"
                                :href="event.previewUrl"
                                target="_blank"
                            >
                                Preview Changes
                            </a>
                        </div>

                        <a
                            :href="route('publish-invite.index', event)"
                            class="w-auto inline-flex button button-soft"
                        >
                            <app-icon name="settings-cog"></app-icon>
                            Publish Settings
                        </a>
                    </div>
                </template>
            </div>
        </transition>

        <div
            v-if="!showMenu"
            id="form-builder-menu"
            class="uppercase bg-purple-100 font-semibold vertical-text fixed text-purple z-10 cursor-pointer p-2 left-0 border-t border-r border-l rounded-md rounded-b-none border-purple-200 flex items-center ml-0 md:ml-60"
            style="top:200px;"
            @click="toggleMenu"
        >
            Menu
            <app-icon
                name="navigation-menu"
                class="h-4 w-4 ml-2 text-purple-200"
            ></app-icon>
        </div>
        <div class="flex">
            <div class="w-64 sm:top-0 p-0 sm:static absolute block">
                <div class="sm:w-full sm:rounded-md fixed sm:sticky z-10 overflow-y-auto bg-white md:bg-gray-100 bottom-0 left-0 flex flex-col justify-between" style="top:118px;">
                    <component
                        :is="activeMenu"
                        :blocks="blocks"
                        :sections="formSections"
                        :active-section="activeSectionClass"
                        :available-sections="availableSections"
                        @toggle-menu="toggleMenu"
                        @update-active-section="updateActiveSection"
                        @open-theme-drawer="openThemeDrawer"
                        @change-placement-availability="newValue => isBlockPlacementAvailable = newValue"
                        @add-block="addBlock"
                    ></component>

                    <div v-if="showSetupTypePicker" class="bg-gray-100 px-4 py-4 md:px-0">
                        <div class="text-gray-700 text-sm">Show blocks as for event type:</div>

                        <simple-picker
                            v-model="showAsSetupType"
                            placeholder-empty-state="Select event type..."
                            :items="setupTypes"
                            item-label="label"
                            item-value="value"
                            class="mt-4 text-sm"
                        ></simple-picker>
                    </div>
                </div>
            </div>
            <div class="ml-0 md:ml-4">
                <div class="w-screen md:w-180 h-full">
                    <div v-if="isConfirmationSection" class="flex justify-center mb-4">
                        <a
                            class="py-2 px-4 border-b-2 font-medium cursor-pointer"
                            :class="attendingTabStyles"
                            role="button"
                            @click="updateActiveSection('ConfirmationSection')"
                        >
                            Attending
                        </a>
                        <a
                            class="py-2 px-4 border-b-2 font-medium cursor-pointer"
                            :class="notAttendingTabStyles"
                            role="button"
                            @click="updateActiveSection('NotAttendingSection')"
                        >
                            Not Attending
                        </a>
                    </div>

                    <rsvp-form
                        id="rsvp-form"
                        :sections="formSections"
                        :active-section="activeSectionClass"
                        @drag-block="dragBlock"
                        @reorder-blocks="reorderBlocks"
                    >
                        <template #header>
                            <img
                                v-if="form.settings.logo_url"
                                v-theme="'form.logo'"
                                :src="form.settings.logo_url"
                                alt="logo"
                                class="block mx-auto cursor-pointer"
                                @click="openThemeDrawer"
                            >

                            <image-manager
                                conversion="none"
                                :max-images="1"
                                :settings="{}"
                                title="Upload Logo"
                                uploader-classes="text-center mb-0"
                                :value="form.settings.logo_url ? [form.settings.logo_url] : []"
                                @input="logoImageUploaded"
                            >
                                <template #placeholder>
                                    <button
                                        v-tippy
                                        class="hover:opacity-60 transition"
                                        content="PNG and JPG files allowed. Max file size: 2 MB. Logo Size Recommendation: 600 px wide."
                                    >
                                        <img :src="asset('/images/svg/your_logo_here.svg')" alt="upload logo">
                                    </button>
                                </template>

                                <template #recommendations>
                                    PNG and JPG files allowed. Max file size: 2 MB.<br>
                                    Logo Size Recommendation: 600 px wide.
                                </template>
                            </image-manager>
                        </template>

                        <template #block-default-slot="{ block }">
                            <div class="absolute top-0 left-0 mt-3 ml-1 transition duration-300 ease-in-out" :class="{ 'opacity-0': selectedBlock !== null }">
                                <template v-if="block.settings">
                                    <form-builder-settings-button
                                        v-if="block.properties.editable || isEventTemplate"
                                        icon-class="h-4 w-4"
                                        :block="block"
                                        :is-event-template="isEventTemplate"
                                        @duplicate-block="duplicateBlock(block)"
                                        @delete-block="deleteBlock(block)"
                                    ></form-builder-settings-button>

                                    <div
                                        v-if="block.properties.movable"
                                        v-tippy
                                        class="h-8 w-8 flex items-center justify-center rounded-full border-2 border-white text-white bg-purple hover:bg-purple cursor-grab mt-2 draggable-handle"
                                        content="Drag to reorder"
                                    >
                                        <app-icon
                                            name="move-vertical-arrows"
                                            class="h-4 w-4"
                                        ></app-icon>
                                    </div>

                                    <a
                                        v-if="block.slug === 'rsvp' || block.slug === 'ticketing'"
                                        v-tippy
                                        role="button"
                                        class="flex items-center justify-center h-8 w-8 p-1 leading-0 rounded-full border-2 border-white text-white bg-purple transition duration-150 ease-in-out cursor-pointer hover:bg-purple-light mt-2"
                                        :content="focusBlockPaidSettingsButtonTooltip(block)"
                                        @click="focusBlockPaidSettings(block)"
                                    >
                                        <app-icon
                                            name="ticket"
                                            class="h-4 w-4"
                                        ></app-icon>
                                    </a>
                                </template>
                            </div>
                        </template>
                    </rsvp-form>
                </div>
            </div>
        </div>

        <form-preview-modal v-model="showModal" :form-sections="formSections"></form-preview-modal>
        <requires-upgrade-modal v-model="showRequiresUpgradeModal" :used-features="usedFeaturesList"></requires-upgrade-modal>
        <comp-requires-action v-model="showCompRequiresActionModal" @addPaidProduct="openPrimaryBlock"></comp-requires-action>
    </div>
</template>

<script>
import {
    cloneDeep, find, flatMap, findIndex, merge, noop, reduce, get as getValue
} from 'lodash';
import { call, get, sync } from 'vuex-pathify';
import VueScrollTo from '@node_modules/vue-scrollto';
import axios from '@/util/axios';
import CSSInjector from '@/mixins/CssInjector';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';
import { compedUpgradeAlert } from '@/custom-alerts';
import AnimatesBlocks from '@/mixins/AnimatesBlocks';
import automate from '@/automate';
import ForceBlockOrder from '@/mixins/ForceBlockOrder';

export default {
    mixins: [AnimatesBlocks, GeneratesUniqueKey, CSSInjector, ForceBlockOrder],

    provide () {
        return {
            questionTypes: this.questionTypes
        };
    },

    props: {
        initialEvent: {
            type: Object,
            required: true
        },

        availableSections: {
            type: Array,
            required: true
        },

        initialFormSections: {
            type: Array,
            required: true
        },

        questionTypes: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            activeSectionClass: this.availableSections[0].class,
            addingBlock: false,
            cssInjectorNamespace: '.rsvp-form',
            headerBarOffset: '0px',
            isBlockPlacementAvailable: true,
            isFloatingRepublishButtonVisible: null,
            isMealDrawerOpen: false,
            isThemeDrawerOpen: false,
            saving: false,
            selectedBlockElement: null,
            selectedBlockElementBox: { width: 100, height: 100 },
            setupTypes: [
                {
                    label: 'All Setup Types',
                    value: 'all'
                },
                {
                    label: 'RSVP Setup Type',
                    value: 'rsvp'
                },
                {
                    label: 'Ticketing Setup Type',
                    value: 'tickets'
                }
            ],
            showModal: false,
            showMenu: true,
            showCompRequiresActionModal: false,
            showRequiresUpgradeModal: false,
            publishing: false,
            publishing_blocker: null,
            usedFeaturesList: [],
            event: cloneDeep(this.initialEvent)
        };
    },

    computed: {
        ...sync('Form/*'),
        ...get('UsedFeatures/*'),

        activeMenu () {
            return this.showMenu ? 'form-builder-main-menu' : '';
        },

        activeSection () {
            return find(this.formSections, {
                class: this.activeSectionClass
            });
        },

        attendingTabStyles () {
            return {
                'border-transparent text-gray-600 hover:text-gray-800': !this.isAttendingSection,
                'border-purple text-purple': this.isAttendingSection
            };
        },

        blocks () {
            return flatMap(this.formSections, 'blocks');
        },

        customCssToInject: get('Form/form@settings.theme.customCss'),

        formSections: sync('Form/form@sections'),

        isAttendingSection () {
            return this.activeSectionClass === 'ConfirmationSection';
        },

        isConfirmationSection () {
            return this.isAttendingSection || this.isNotAttendingSection;
        },

        isHeaderBarVisible () {
            if (!this.isBlockPlacementAvailable) {
                return true;
            }

            if (this.form.hasUnpublishedChanges && this.isFloatingRepublishButtonVisible) {
                return true;
            }

            return false;
        },

        isNotAttendingSection () {
            return this.activeSectionClass === 'NotAttendingSection';
        },

        notAttendingTabStyles () {
            return {
                'border-transparent text-gray-600': !this.isNotAttendingSection,
                'border-purple text-purple': this.isNotAttendingSection
            };
        },

        isComped: sync('Event/event@isComped'),

        hasPayableProducts: sync('Event/event@hasPayableProducts'),

        isEventTemplate () {
            return this.event.user_id === null;
        },

        republishMessage () {
            if (!this.form.isOpened && !this.form.isClosed) {
                return 'You\'ve made changes to your Event Form since the last time you scheduled registrations to open. You\'ll need to re-publish your Event Form in order for your guests to be able to see the newest updates when form registration opens.';
            }

            return 'You\'ve made changes to your Event Form since the last time you published it. You\'ll need to re-publish your Event Form before your guests will be able to see the updates.';
        },

        showSetupTypePicker () {
            return this.isEventTemplate && this.showMenu;
        }
    },

    mounted () {
        App.$on('select-block', this.elementSelected);
        App.$on('modified-block', this.blockModified);

        this.callOpenPrimaryBlockAction();
        this.initializeObserver();

        window.addEventListener('scroll', () => {
            this.isFloatingRepublishButtonVisible = this.$refs.unpublishedChangesNotification?.getBoundingClientRect()?.bottom < 0;
        });
    },

    created () {
        this.collapseMenuOnMobileOnly();

        this.$store.set('Form/form', this.event.form);
        this.$store.set('Form/form@sections', cloneDeep(this.initialFormSections));
        this.$store.set('Wall/wall', this.event.wall);

        this.initializeUsedFeatures(this.event.used_features);

        this.isEditMode = true;
    },

    methods: {
        addBlock ({ block, section }) {
            if (this.addingBlock) {
                return;
            }

            this.addingBlock = true;

            const addedBlockSectionIndex = findIndex(
                this.formSections,
                { class: section.class }
            );

            const addedBlockIndex = this.findLastAvailableNewBlockIndex(this.formSections[addedBlockSectionIndex]);

            this.storeBlock(block.id, addedBlockSectionIndex, addedBlockIndex, 0, true);
        },

        blockModified ({ id, ...settings }) {
            const block = this.blocks.find((formBlock) => {
                return formBlock.pivot.id === id;
            });

            Object.assign(block.pivot.settings, settings);

            this.saveBlockSettings({
                defaultCloseHandler: noop,
                block
            });
        },

        callOpenPrimaryBlockAction () {
            const query = new URLSearchParams(window.location.search);

            if (query.has('open_primary_block_settings')) {
                // Let the page load in full due to the block "focus" animation
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.openPrimaryBlock();
                    }, 500);
                });

                query.delete('open_primary_block_settings');
                window.history.replaceState(null, null, `?${query.toString()}`);
            }
        },

        closeAllDrawers () {
            this.showModal = false;
            this.isThemeDrawerOpen = false;
            this.isMealDrawerOpen = false;
        },

        collapseMenuOnMobileOnly () {
            if (window.screen.width < 576) {
                this.showMenu = false;
            }
        },

        deleteBlock (block) {
            const attributes = {
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel'
            };

            const onDelete = () => {
                const route = this.route('api.forms.blockables.destroy', {
                    event: this.event,
                    blockable: block.pivot,
                    pageLoadTimestamp: window.pageLoadTimestamp
                });

                axios.delete(route).then(({ data }) => {
                    this.updateStores(data);

                    const { section, blockIndex } = this.getBlockAndSectionByBlockableId(block.pivot.id);
                    this.touchForm();

                    section.blocks.splice(blockIndex, 1);

                    this.$toasted.global.success(`The ${block.pivot.settings.title} block has been removed.`);
                }).catch((response) => {
                    this.$toasted.global.error(`There was an error removing the ${block.pivot.settings.title} block.`);
                });
            };

            App.alert().confirm(
                'Are you sure?',
                'This will permanently delete this form block and all associated responses and data.',
                attributes,
                onDelete
            );
        },

        dragBlock ($event) {
            const addedBlockSectionIndex = findIndex(
                this.formSections,
                { class: $event.clone.dataset.sectionClass }
            );

            const addedBlockIndex = findIndex(
                this.formSections[addedBlockSectionIndex].blocks,
                { id: parseInt($event.clone.dataset.blockId, 10) }
            );

            const addedBlock = this.formSections[addedBlockSectionIndex].blocks[addedBlockIndex];

            this.storeBlock(addedBlock.blockId, addedBlockSectionIndex, addedBlockIndex, 1);
        },

        duplicateBlock (block) {
            this.saving = true;
            const { section, blockIndex } = this.getBlockAndSectionByBlockableId(block.pivot.id);

            const route = this.route('api.forms.blockables.duplicate', {
                event: this.event,
                pageLoadTimestamp: window.pageLoadTimestamp
            });

            return axios.post(route, { block }).then(({ data }) => {
                this.updateStores(data);

                section.blocks.splice(blockIndex + 1, 0, data.result);
                this.touchForm();

                if (data.result.properties.openSettingsAfterDrop) {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.elementSelected({ block: data.result });
                        }, 250);
                    });
                }

                this.reorderBlocks();
            }).catch(function () {
                this.$toasted.global.error('There was an error duplicating the block to your form.');
            }).finally(() => {
                this.saving = false;
            });
        },

        elementSelected ({ block }) {
            this.selectedBlock = find(this.blocks, ({ pivot }) => {
                return pivot.id === block.pivot.id;
            });

            this.animateBlockToScreenCenter(`.rsvp-form div[data-pivot-id="${block.pivot.id}"]`);
        },

        closeBlockSettings () {
            this.selectedBlock = null;

            if (this.selectedBlockElement) {
                this.animateBlockBackToOriginalPosition();

                this.selectedBlockElement = null;
            }
        },

        formThemeUpdated () {
            this.closeAllDrawers();

            const route = this.route('api.events.used-features', {
                event: this.event,
                pageLoadTimestamp: window.pageLoadTimestamp
            });

            axios.get(route)
                .then(({ data }) => {
                    this.updateStores(data);
                });
        },

        async focusBlockPaidSettings (block) {
            this.elementSelected({ block });

            if (block.slug === 'rsvp') {
                if (this.blockHasAttendingPaidProduct(block)) {
                    await automate([
                        { selector: 'button[data-descriptor="edit-attending-product"]', method: 'click' },
                        { selector: 'div[data-descriptor="costs-money-panel"] [data-descriptor="money-field"] input', method: 'focus', sleep: 500 }
                    ]);
                } else {
                    await automate([
                        { selector: 'button[data-descriptor="edit-attending-product"]', method: 'click' },
                        { selector: 'div[data-descriptor="costs-money-panel"] [data-descriptor="toggle"]', method: 'click', sleep: 500 },
                        { selector: 'div[data-descriptor="costs-money-panel"] [data-descriptor="money-field"] input', method: 'focus' }
                    ]);
                }
            } else if (block.slug === 'ticketing') {
                if (this.blockHasAnyPaidProduct(block)) {
                    await automate([
                        { selector: 'div[data-descriptor="ticket-list"] a[data-descriptor="edit-item"]:not([data-price="0"])', method: 'click' },
                        { selector: 'div[data-descriptor="costs-money-panel"] [data-descriptor="money-field"] input', method: 'focus', sleep: 500 }
                    ]);
                } else {
                    await automate([
                        { selector: 'div[data-descriptor="ticket-list"] a[data-descriptor="edit-item"]', method: 'click' },
                        { selector: 'div[data-descriptor="costs-money-panel"] [data-descriptor="toggle"]', method: 'click', sleep: 500 },
                        { selector: 'div[data-descriptor="costs-money-panel"] [data-descriptor="money-field"] input', method: 'focus' }
                    ]);
                }
            }
        },

        focusBlockPaidSettingsButtonTooltip (block) {
            if (block.slug === 'rsvp') {
                return this.blockHasAttendingPaidProduct(block) ? `Update or remove ticket price` : `Make this a paid event`;
            }

            return this.blockHasAnyPaidProduct(block) ? `Update or remove ticket price` : `Make this a paid event`;
        },

        blockHasAttendingPaidProduct (block) {
            const attendingProduct = find(block?.products, { short_guest_state: 'Attending' });
            return attendingProduct?.price > 0;
        },

        blockHasAnyPaidProduct (block) {
            return find(block?.products, (product) => { return product.price > 0; }) !== undefined;
        },

        getBlockAndSectionByBlockableId (blockableId) {
            for (let i = 0; i < this.formSections.length; i++) {
                const section = this.formSections[i];

                for (let j = 0; j < section.blocks.length; j++) {
                    const block = section.blocks[j];

                    if (block.pivot.id === blockableId) {
                        return {
                            section,
                            sectionIndex: i,
                            block,
                            blockIndex: j
                        };
                    }
                }
            }

            return null;
        },

        initializeUsedFeatures: call('UsedFeatures/initialize'),

        initializeObserver () {
            const inEventSidebarNav = document.getElementById('in-event-sidebar-nav');
            const observer = new ResizeObserver((mutations) => {
                mutations.forEach(() => {
                    this.headerBarOffset = `${inEventSidebarNav.getBoundingClientRect().width}px`;
                });
            });

            observer.observe(inEventSidebarNav);
        },

        async logoImageUploaded (images) {
            const settingsDelta = {
                logo_url: null,
                email_logo_url: null
            };

            if (images.length > 0) {
                settingsDelta.logo_url = `${images[0].src.original}?id=${images[0].id}`;
                settingsDelta.email_logo_url = `${images[0].src.email}?id=${images[0].id}`;
            }

            this.$set(this.form, 'settings', {
                ...this.form.settings,
                ...settingsDelta
            });

            try {
                await axios.patch(
                    this.route('api.forms.settings.update', {
                        form: this.form
                    }),
                    this.form
                );

                this.$toasted.global.success('Logo saved.');
            } catch (e) {
                this.$toasted.global.error('There was a problem while saving your logo.');
            }
        },

        openPrimaryBlock () {
            const primaryBlockSlug = this.event.setup_type === 'rsvp' ? 'rsvp' : 'ticketing';
            const primaryEventBlock = find(this.blocks, { slug: primaryBlockSlug });

            App.$emit('select-block', { block: primaryEventBlock });
        },

        openThemeDrawer () {
            this.closeAllDrawers();
            this.isThemeDrawerOpen = true;
            this.showModal = true;
        },

        reorderBlocks () {
            this.activeSection.blocks.map((block, index) => {
                block.pivot.sort = index + 1;
                return block;
            });

            const payload = reduce(this.activeSection.blocks, (carry, block) => {
                carry[block.pivot.id] = block.pivot.sort;
                return carry;
            }, {});

            axios.patch(this.route('api.forms.reorder', { event: this.event }), {
                sort: payload
            })
                .then(this.touchForm)
                .catch(() => {
                    this.$toasted.global.error(`There was an error reordering the blocks.`);
                });
        },

        saveBlockSettings ({ defaultCloseHandler, block, form }) {
            this.saving = true;

            const route = this.route('api.forms.blockables.update', {
                event: this.event,
                blockable: block.pivot,
                pageLoadTimestamp: window.pageLoadTimestamp
            });

            // The Axios call is used for self-modifying blocks such as the TextBlcok
            const apiCall = form
                ? form.put(route, null, { setup_type: block.pivot.settings.setup_type })
                : axios.put(route, block.pivot.settings);

            apiCall.then(({ data }) => {
                this.updateStores(data);

                this.updateEventSettings(data);
                this.updateEventTimezone(data);

                this.$toasted.global.success(`${block.title} block settings have been updated.`);
                this.touchForm();

                const blockIndex = findIndex(this.blocks, { pivot: { id: data.result.pivot.id } });
                Object.assign(this.blocks[blockIndex], data.result);

                defaultCloseHandler();
            }).catch(({ response }) => {
                if (response.status !== 422) {
                    this.$toasted.global.error(`There was an error updating the ${block.title} block settings.`);
                }
            }).finally(() => {
                this.saving = false;
            });
        },

        scrollToTop () {
            window.scroll({ top: 0, behavior: 'smooth' });
        },

        showFreeUpgrade () {
            if (this.isComped) {
                compedUpgradeAlert();
            }
        },

        showCompedDowngradeMessage () {
            if (!this.isComped) {
                App.alert().warning(
                    'Your event no longer qualifies for a free upgrade.',
                    '',
                    {
                        customClass: {
                            confirmButton: 'button button-primary'
                        },
                        buttonsStyling: false,
                        html: `You’ve removed paid tickets from your event.<br><br>You can always add paid ticket types to your event to re-activate the free upgrade. No-charge events may require an upgrade to publish.`,
                        confirmButtonText: 'GOT IT'
                    }
                );
            }
        },

        /* This will be an API call that will add a block to this form section */
        storeBlock (availableBlockId, sectionIndex, insertionIndex, howManyToSplice, scrollAfterAdding = false) {
            const section = this.formSections[sectionIndex];

            const route = this.route('api.forms.blockables.store', {
                event: this.event,
                section: section.class,
                pageLoadTimestamp: window.pageLoadTimestamp
            });

            return axios.post(route, {
                block: availableBlockId
            }).then(({ data }) => {
                this.updateStores(data);

                const block = data.result;

                section.blocks.splice(insertionIndex, howManyToSplice, block);
                this.touchForm();

                if (scrollAfterAdding) {
                    const duration = 500;

                    this.$nextTick(() => {
                        setTimeout(() => {
                            const newBlockElement = document.querySelector(`#rsvp-form [data-pivot-id="${data.result.pivot.id}"]`);

                            VueScrollTo.scrollTo(newBlockElement, duration, {
                                duration,
                                easing: [0.25, 0.46, 0.45, 0.94],
                                cancelable: false
                            });
                        }, 500);
                    });
                }

                if (block.properties.openSettingsAfterDrop) {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.elementSelected({ block });
                        }, 1000);
                    });
                }

                this.reorderBlocks();
            }).catch(() => {
                this.$toasted.global.error('There was an error adding the block to your form.');
            }).finally(() => {
                this.addingBlock = false;
            });
        },

        toggleMenu () {
            this.showMenu = !this.showMenu;
        },

        touchForm () {
            this.$store.commit('Form/touch');
        },

        trackFeatures: call('UsedFeatures/trackFeatures'),

        republish () {
            this.publishing = true;

            const route = this.route(`api.events.republish`, this.event);

            axios.post(route, { model: 'form' }).then(({ data }) => {
                merge(this.form, data.form);
                this.$toasted.global.success('Your event has been published.');
            }).catch((error) => {
                const publishingBlocker = error.response.data.publishing_blocker;
                const usedFeaturesList = error.response.data.used_features;

                if (publishingBlocker === 'beyondLimit' && usedFeaturesList && usedFeaturesList.length > 0) {
                    this.$set(this, 'usedFeaturesList', usedFeaturesList);
                    this.showRequiresUpgradeModal = true;

                    return;
                }

                if (publishingBlocker === 'compRequiresAction') {
                    this.showCompRequiresActionModal = true;
                    return;
                }

                this.publishing_blocker = error.response.data.publishing_blocker;
                this.$toasted.global.error('There was a problem publishing your event.');
            }).finally(() => {
                this.publishing = false;
            });
        },

        updateActiveSection (sectionClass) {
            this.activeSectionClass = sectionClass;
        },

        updateEventSettings (data) {
            this.$set(this.event, 'settings', getValue(data, 'event.settings'));
            this.$store.set('Event/event@settings', this.event.settings);
        },

        updateStores (data) {
            window.pageLoadTimestamp = data.timestamp;

            this.trackFeatures(data.event_used_features);
            this.hasPayableProducts = data.event.hasPayableProducts;

            if (!this.isComped && data.event.isComped) {
                this.isComped = true;
                this.showFreeUpgrade();
            }

            if (this.isComped && !data.event.isComped) {
                this.isComped = false;
                this.showCompedDowngradeMessage();
            }
        },

        updateEventTimezone (data) {
            this.event.timezone_id = getValue(data, 'event.timezone_id');
            this.$store.set('Event/event@timezone_id', this.event.timezone_id);
        }
    }
};
</script>
