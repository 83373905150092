import Vue from 'vue';
import { isString, noop } from 'lodash';

Vue.toasted.customToast = (params) => {
    return Vue.toasted.show(params.message, params.options);
};

Vue.toasted.register('error', (message) => {
    return message || 'Oops... something went wrong.';
}, {
    type: 'error',
    duration: 8000,
    action: {
        text: 'OK',
        onClick: (_e, toast) => {
            toast.goAway();
        }
    },
    icon: 'gg-close-o mr-2',
    iconPack: 'custom-class'
});

Vue.toasted.register('success', (options) => {
    let message = '';
    let onComplete = noop;

    if (isString(options)) {
        message = options;
    } else {
        message = options.message;
        onComplete = options.onComplete || noop;
    }

    return {
        message,
        options: {
            icon: 'gg-check-o mr-2',
            iconPack: 'custom-class',
            onComplete
        }
    };
}, 'customToast');

Vue.toasted.usedFeatures = Vue.toasted.group({
    duration: 10000,
    position: 'top-right',
    theme: 'featureNotification',
    action: {
        text: ' ',
        onClick: (_e, toast) => {
            toast.goAway();
        }
    }
});
