/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-correct-5-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.5 12.625a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75zM23.25 23.125h0a6.031 6.031 0 00-8.46-1.05c-.39.3-.74.65-1.05 1.04"/><path pid="1" d="M21.875 16.11v-.01c-2.27.65-4.71.08-6.45-1.52M5.5 12.625a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75zM10.24 23.125h0a6.031 6.031 0 00-8.46-1.05c-.39.3-.74.65-1.05 1.04"/><path pid="2" d="M8.87 16.11v-.01c-2.27.65-4.71.08-6.45-1.52M.75 6.88h3.5M2.75 8.87l-2-2 2-2M23.25 6.88h-3.5M21.25 8.87l2-2-2-2M12 .875a5 5 0 100 10 5 5 0 100-10z"/><path pid="3" d="M10 6.375l1.08 1.08-.01-.01c.19.19.51.19.7-.01.01-.02.03-.05.05-.07l2.15-3.02"/></g>'
  }
})
