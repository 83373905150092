/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'team-chat-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>meeting network group users message team</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.74 21h1.5v2.25l3-2.25h4.5-.01c.82 0 1.5-.68 1.5-1.5V15v0c0-.83-.68-1.5-1.5-1.5h-9.01c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v4.5h0c0 .82.67 1.49 1.5 1.49zM4.97 14.25a2.62 2.62 0 100 5.25 2.62 2.62 0 100-5.25zM9.2 23.25h0a4.482 4.482 0 00-8.46-.01M19.02 1.5a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM23.249 10.5h0a4.482 4.482 0 00-8.46-.01M16.5 15.75h3.75M14.25 18h6M11.24 8.25h-1.5v2.25l-3-2.25h-4.5l-.01-.001a1.51 1.51 0 01-1.5-1.5V2.248h0A1.5 1.5 0 012.22.73h9-.01c.82-.01 1.5.67 1.5 1.5v4.5h0c0 .82-.68 1.49-1.5 1.5zM7.5 3H3.75M9.75 5.25h-6"/></g>'
  }
})
