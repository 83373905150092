/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-champagne-bottle-glass-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>bar alcohol bubbly prosecco celebration mimosa</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.93 6.228l-.01-.01a24.76 24.76 0 01-.44-4.62v-.12 0c0-.42-.34-.75-.75-.75H8.219c-.42 0-.75.33-.75.75 0 0 0 0 0 0v.11-.01c0 2.9-.51 5.78-1.5 8.51h-.02c-1 2.72-1.5 5.61-1.5 8.51v3.11h0c0 .82.67 1.49 1.5 1.49 0 0 0 0 0 0h5.25"/><path pid="1" d="M10.56 12.53h-.01c-.51.14-1.04.21-1.57.21h-.01c-1.27 0-2.49-.43-3.47-1.22M9 16.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM7.41 3.75h3.18M15 23.25h3M16.5 23.25V19.5M18.164 19.01v0V19c-1.02.64-2.32.64-3.33 0v0h0a2.82 2.82 0 01-1.32-2.72l.8-7.6h-.01c.03-.27.26-.47.52-.46h3.3-.01c.26-.01.49.19.52.45l.8 7.59h0c.12 1.08-.39 2.14-1.32 2.71zM13.89 12.75h5.22"/></g>'
  }
})
