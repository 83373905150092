/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'house-apartment-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>rent unit</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25 10.5v12.75H.75V10.5M23.25 10.5H16.5v0a4.5 4.5 0 00-9 0H.75l2.67-8.7v-.01c.19-.63.77-1.06 1.43-1.06h14.28-.01c.65-.01 1.24.42 1.43 1.05z"/><path pid="1" d="M13.5 13.5h-3v-3.375C10.5 9.5 11.17 9 12 9c.82 0 1.5.5 1.5 1.125zM13.5 23.25h-3V18h0a1.496 1.496 0 112.99-.02s0 0 0 0zM3.75 13.5h3M17.25 13.5h3M3.75 16.5h3v3h-3zM17.25 16.5h3v3h-3z"/></g>'
  }
})
