/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>back</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.5 12h9M11.251 15.74L7.5 11.99l3.75-3.75"/><path pid="1" d="M12 1.49a10.5 10.5 0 100 21 10.5 10.5 0 100-21z"/></g>'
  }
})
