/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hotel-bunk-bed-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75.75v22.5M23.25 4.5v18.75M23.25 21H.75M6 13.5A2.25 2.25 0 106 18a2.25 2.25 0 100-4.5zM11.25 18v-3.54l-.01-.01c-.01-.42.33-.76.74-.76.06-.01.12 0 .18.02l5.88 1.46h0a2.88 2.88 0 012.18 2.8zM23.25 10.5H.75M6 3a2.25 2.25 0 100 4.5A2.25 2.25 0 106 3zM11.25 7.5V3.961l-.01-.01c-.01-.42.33-.76.74-.76.06-.01.12 0 .18.02l5.88 1.46h0a2.88 2.88 0 012.18 2.8z"/></g>'
  }
})
