<template>
    <div class="flex flex-col">
        <p class="text-2xl">Map columns from your spreadsheet</p>

        <div class="flex flex-col border-t-2 border-b-2 py-6 mt-4">
            <div class="flex">
                <p
                    v-tippy
                    content="A guest identifier (first name and last name and/or email address) is required for each invitee."
                    class="pb-1 border-b border-dashed border-gray-500 text-gray-600 text-sm font-bold uppercase tracking-wide cursor-help"
                >
                    At least one required
                </p>
            </div>

            <div class="flex flex-col md:flex-row px-4">
                <form-field-wrapper class="mt-8">
                    <template slot="label">
                        <div class="flex items-center">
                            <div class="flex items-center justify-center w-5 h-5 mr-2 -ml-2">
                                <app-icon
                                    v-if="form.mapping.first_name"
                                    name="check-circle"
                                    class="h-5 w-5 text-green"
                                    stroke
                                ></app-icon>
                            </div>
                            First Name
                        </div>
                    </template>

                    <simple-picker
                        v-model="form.mapping.first_name"
                        placeholder-empty-state="Select Column"
                        class="ml-5"
                        :items="columns"
                        item-label="column"
                        item-value="index"
                    ></simple-picker>
                </form-field-wrapper>

                <form-field-wrapper class="mx-0 md:mx-4">
                    <template slot="label">
                        <div class="flex items-center">
                            <div class="flex items-center justify-center w-5 h-5 mr-2 -ml-2">
                                <app-icon
                                    v-if="form.mapping.last_name"
                                    name="check-circle"
                                    class="h-5 w-5 text-green"
                                ></app-icon>
                            </div>
                            Last name
                        </div>
                    </template>

                    <simple-picker
                        v-model="form.mapping.last_name"
                        placeholder-empty-state="Select Column"
                        class="ml-5"
                        :items="columns"
                        item-label="column"
                        item-value="index"
                    ></simple-picker>
                </form-field-wrapper>

                <form-field-wrapper>
                    <template slot="label">
                        <div class="flex items-center">
                            <div class="flex items-center justify-center w-5 h-5 mr-2 -ml-2">
                                <app-icon
                                    v-if="form.mapping.email"
                                    name="check-circle"
                                    class="h-5 w-5 text-green"
                                ></app-icon>
                            </div>
                            Email address
                        </div>
                    </template>

                    <simple-picker
                        v-model="form.mapping.email"
                        placeholder-empty-state="Select Column"
                        class="ml-5"
                        :items="columns"
                        item-label="column"
                        item-value="index"
                    ></simple-picker>
                </form-field-wrapper>
            </div>

            <div v-if="form.errors.hasAny(['mapping.first_name', 'mapping.last_name', 'mapping.email'])" class="alert alert-error w-full mt-4">
                {{ form.errors.getAny(['mapping.first_name', 'mapping.last_name', 'mapping.email']) }}
            </div>

            <p class="mt-8 uppercase text-sm font-bold text-gray-600 tracking-wide">Optional columns</p>

            <div class="flex flex-col md:flex-row px-4">
                <form-field-wrapper class="mt-8">
                    <template slot="label">
                        <div class="flex items-center">
                            <div class="flex items-center justify-center w-5 h-5 mr-2 -ml-2">
                                <app-icon
                                    v-if="form.mapping.title"
                                    name="check-circle"
                                    class="h-5 w-5 text-green"
                                ></app-icon>
                            </div>
                            Invitee title
                        </div>
                    </template>

                    <simple-picker
                        v-model="form.mapping.title"
                        placeholder-empty-state="Select Column"
                        class="ml-5"
                        :items="columns"
                        item-label="column"
                        item-value="index"
                    ></simple-picker>
                </form-field-wrapper>

                <form-field-wrapper class="mx-0 md:mx-4">
                    <template slot="label">
                        <div class="flex items-center">
                            <div class="flex items-center justify-center w-5 h-5 mr-2 -ml-2">
                                <app-icon
                                    v-if="form.mapping.group_id"
                                    name="check-circle"
                                    class="h-5 w-5 text-green"
                                ></app-icon>
                            </div>
                            <p
                                v-tippy
                                content="Use this column to group multiple invitees who will be part of the same invitation or reply (i.e. couples, families, teams, etc.). Use any unique IDs per group you like. Careful not to group together unassociated invitees!"
                                class="border-b border-dashed border-gray-500 cursor-help"
                            >
                                Group Id
                            </p>
                        </div>
                    </template>

                    <simple-picker
                        v-model="form.mapping.group_id"
                        placeholder-empty-state="Select Column"
                        class="ml-5"
                        :items="columns"
                        item-label="column"
                        item-value="index"
                    ></simple-picker>
                </form-field-wrapper>

                <form-field-wrapper>
                    <template slot="label">
                        <div class="flex items-center">
                            <div class="flex items-center justify-center w-5 h-5 mr-2 -ml-2">
                                <app-icon
                                    v-if="form.mapping.additional_guests_limit"
                                    name="check-circle"
                                    class="h-5 w-5 text-green"
                                ></app-icon>
                            </div>
                            Additional guest(s) allowed (+1's)
                        </div>
                    </template>

                    <simple-picker
                        v-model="form.mapping.additional_guests_limit"
                        placeholder-empty-state="Select Column"
                        class="ml-5"
                        :items="columns"
                        item-label="column"
                        item-value="index"
                    ></simple-picker>

                    <div class="flex">
                        <p
                            v-tippy
                            content="If you’re hosting a private invite-only event, invitees will not be able to bring any additional guests. You can add additional guests (+1’s) to select invitees later, after import. If your event is not invite-only, there will be no restriction on bringing additional guests."
                            class="p-1 mt-1 ml-5 border-b border-dashed border-gray-500 text-gray-600 text-sm cursor-help"
                        >
                            What if I leave this blank?
                        </p>
                    </div>
                </form-field-wrapper>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-3 gap-y-12 mt-8 px-4">
                <form-field-wrapper>
                    <template slot="label">
                        <div class="flex items-center">
                            <div class="flex items-center justify-center w-5 h-5 mr-2 -ml-2">
                                <app-icon
                                    v-if="form.mapping.tags"
                                    name="check-circle"
                                    class="h-5 w-5 text-green"
                                ></app-icon>
                            </div>
                            Tags
                        </div>
                    </template>

                    <simple-picker
                        v-model="form.mapping.tags"
                        placeholder-empty-state="Select Column"
                        class="ml-5"
                        :items="columns"
                        item-label="column"
                        item-value="index"
                    ></simple-picker>
                </form-field-wrapper>

                <div
                    v-for="(customDataField, index) in form.customDataFields"
                    :key="`custom-data-field-${index}`"
                >
                    <form-field-wrapper>
                        <template slot="label">
                            <div class="flex items-center">
                                <div class="flex items-center justify-center w-5 h-5 mr-2 -ml-2">
                                    <app-icon
                                        v-if="form.customDataFields[index].column"
                                        name="check-circle"
                                        class="h-5 w-5 text-green"
                                    ></app-icon>
                                </div>
                                {{ customDataField.field }}
                            </div>
                        </template>

                        <simple-picker
                            v-model="form.customDataFields[index].column"
                            placeholder-empty-state="Select Column"
                            class="ml-5"
                            :items="columns"
                            item-label="column"
                            item-value="index"
                        ></simple-picker>
                    </form-field-wrapper>
                </div>

                <div
                    v-for="(customDataField, index) in form.newCustomDataFields"
                    :key="`new-custom-data-field-${index}`"
                    class="px-4"
                >
                    <form-field-wrapper>
                        <template slot="label">
                            <div class="flex items-center -ml-4">
                                <div class="flex items-center justify-center w-5 h-5 mr-2 -ml-2">
                                    <app-icon
                                        v-if="form.newCustomDataFields[index].column && form.newCustomDataFields[index].field"
                                        name="check-circle"
                                        class="h-5 w-5 text-green"
                                    ></app-icon>
                                </div>
                                {{ customDataField.field || 'Custom Field' }}
                                <button class="ml-2" @click="removeNewCustomDataField(index)">
                                    <app-icon name="trash-stroke" class="h-6 w-6"></app-icon>
                                </button>
                            </div>
                        </template>
                        <input
                            v-model="form.newCustomDataFields[index].field"
                            type="text"
                            class="form-field"
                            placeholder="Type field name..."
                        >
                    </form-field-wrapper>

                    <form-field-wrapper
                        :should-show-error="form.errors.has(`newCustomDataFields.${index}.*`)"
                        :error="form.errors.getByPath(`newCustomDataFields.${index}`)"
                    >
                        <simple-picker
                            v-model="form.newCustomDataFields[index].column"
                            placeholder-empty-state="Select Column"
                            :items="columns"
                            item-label="column"
                            item-value="index"
                        ></simple-picker>
                    </form-field-wrapper>
                </div>

                <button
                    v-if="!event.plan.features.CustomDataFieldLimit.hidden"
                    class="flex flex-row text-purple text-lg font-semibold self-center justify-self-center"
                    @click="addCustomDataField"
                >
                    <app-icon name="add-circle-stroke" class="h-5 w-5 mt-1"></app-icon>
                    <span class="ml-2">Add Custom Data Field</span>
                    &nbsp; <app-icon
                        v-tippy
                        class="h-4 w-4"
                        name="info-circle"
                        title="Attach additional data to invitees (e.g. employee ID, industry, account manager, etc.)."
                    ></app-icon>
                </button>
            </div>
        </div>

        <div class="flex flex-col md:flex-row items-center justify-center md:justify-end mt-6">
            <a :href="route('invite-list.import', importRecord.event_id)" class="button w-full md:w-auto">Cancel</a>

            <stateful-button
                :loading="form.processing"
                class="button button-primary mt-2 md:mt-0 ml-0 md:ml-2 w-full md:w-auto"
                @click="updateMapping"
            >
                Next
            </stateful-button>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { forEach, map } from 'lodash';
import Form from '@/validation/Form';

export default {
    name: 'MapColumns',

    props: {
        customDataFields: {
            required: true,
            type: Array
        },

        importRecord: {
            required: true,
            type: Object
        }
    },

    data () {
        return {
            form: new Form({
                mapping: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    title: null,
                    group_id: null,
                    additional_guests_limit: null,
                    tags: null
                },
                customDataFields: [],
                newCustomDataFields: []
            })
        };
    },

    computed: {
        ...get('Event/*'),

        columns () {
            return map(this.importRecord.metadata.columns, (column, index) => {
                return {
                    column,
                    index
                };
            });
        },

        defaultColumnHeadings () {
            return {
                first_name: 'FIRST NAME',
                last_name: 'LAST NAME',
                email: 'EMAIL ADDRESS',
                title: 'TITLE/SALUTATION',
                group_id: 'GROUP ID',
                additional_guests_limit: 'ADDITIONAL GUEST(S) ALLOWED (+1\'s)',
                tags: 'TAGS'
            };
        },

        totalCustomDataFields () {
            return this.customDataFields.length + this.form.newCustomDataFields.length;
        }
    },

    created () {
        this.createDefaultMapping();
        this.addCustomDataFields();
    },

    methods: {
        addCustomDataField () {
            if (this.totalCustomDataFields >= this.event.plan.features.CustomDataFieldLimit.max) {
                const reachedLimitText = this.event.plan.features.CustomDataFieldLimit.max === 0
                    ? `Your ${this.event.plan.name} plan doesn't include custom data fields.  Upgrade to add custom data fields.`
                    : `Your ${this.event.plan.name} plan includes <strong>${this.event.plan.features.CustomDataFieldLimit.max}</strong> custom data fields. Upgrade to add more custom data fields to your event(s).`;

                App.alert().paymentRequired(
                    'Please upgrade.',
                    reachedLimitText
                );
                return;
            }

            this.form.newCustomDataFields.push({
                field: '',
                column: null
            });
        },

        addCustomDataFields () {
            forEach(this.customDataFields, (customDataField) => {
                this.form.customDataFields.push({
                    column: null,
                    id: customDataField.id,
                    field: customDataField.field
                });
            });
        },

        createDefaultMapping () {
            Object.entries(this.defaultColumnHeadings).forEach(([slug, defaultHeading]) => {
                const match = this.columns.find(({ column }) => {
                    return column.toUpperCase() === defaultHeading.toUpperCase();
                });

                if (match) {
                    this.form.mapping[slug] = match.index;
                }
            });
        },

        removeNewCustomDataField (index) {
            this.form.newCustomDataFields.splice(index, 1);
        },

        updateMapping () {
            this.form.post(this.route('api.invite-list.import.update-mapping', {
                event: this.event,
                import: this.importRecord
            }))
                .then(() => {
                    window.location.href = this.route('invite-list.import.process', [this.importRecord.event, this.importRecord]);
                });
        }
    }
};
</script>
