/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-movie-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.5 11.75h0a3.74 3.74 0 013.12-3.71h-.01c.36-.07.62-.38.62-.74V4.98v0c0-.83-.68-1.5-1.5-1.5H2.22c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v2.31H.71c-.01.36.26.67.62.73h0c2.04.34 3.42 2.27 3.08 4.31a3.76 3.76 0 01-3.09 3.08h-.01a.74.74 0 00-.63.73v2.3-.01c-.01.82.67 1.49 1.49 1.5h19.5-.01c.82 0 1.5-.68 1.5-1.5V16.1c0-.37-.27-.68-.63-.74l-.01-.01a3.756 3.756 0 01-3.13-3.7zM4.5 6.5h2.25M10.5 6.5h3M17.25 6.5h2.25M4.5 17h2.25M10.5 17h3M17.25 17h2.25"/><path pid="1" d="M12.62 10.436h0a.756.756 0 00-1.05-.21c-.09.05-.16.12-.21.2l-1.6 2.4v-.01c-.23.34-.14.81.21 1.03.12.08.26.12.41.12h3.2v-.001c.41-.01.74-.34.74-.76a.865.865 0 00-.13-.42z"/></g>'
  }
})
