/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add-circle-thick-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>new create plus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/><path pid="1" d="M17.25 9.75h-3v-3 0c0-.83-.68-1.5-1.5-1.5h-1.51c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v3H6.73c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v1.5h0c0 .82.67 1.49 1.5 1.49h3v3h0c0 .82.67 1.49 1.5 1.49h1.5-.01c.82 0 1.5-.68 1.5-1.5v-3h3l-.01-.001c.82 0 1.5-.68 1.5-1.5v-1.51c0-.83-.68-1.5-1.5-1.5h-.01z"/></g>'
  }
})
