/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add-tab-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>create new plus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.938 18H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V7.49c0-.83.67-1.5 1.5-1.5h16.68v-.001c.51-.01.99.26 1.27.7l2.81 4.5h0c.3.48.3 1.1-.01 1.59l-2.82 4.5h-.01c-.28.43-.76.7-1.28.7zM6.75 9.75v4.5M4.5 12H9"/></g>'
  }
})
