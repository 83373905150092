/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-circle-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>ok yes correct done task</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 9l3.29 4.61-.01-.01c.48.68 1.44.84 2.12.35.1-.08.2-.17.28-.27L23.22.72"/><path pid="1" d="M16.78 3.824h0C11.85.774 5.39 2.29 2.34 7.214c-3.05 4.92-1.53 11.38 3.39 14.43 4.92 3.04 11.38 1.52 14.43-3.4 1.84-2.99 2.07-6.7.61-9.89"/></g>'
  }
})
