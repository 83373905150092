<template>
    <div class="px-4 py-8" :class="{ 'cursor-pointer': !isActive }">
        <slot name="block-header"></slot>

        <div v-theme="'form.title-text'" class="my-2 text-2xl">
            {{ block.pivot.settings.title }}
        </div>

        <form-field-wrapper :error="errorBag.get('payload.note')">
            <multi-line-text-input
                ref="input"
                v-model="note"
                :placeholder="$t('placeholder-type-here')"
            ></multi-line-text-input>
        </form-field-wrapper>

        <slot name="block-footer" :complete-block="completeBlock"></slot>
    </div>
</template>

<script>
import FormBlock from '@/mixins/FormBlock';
import { groupPassesAskIf, wholeGroupPassesAskIf } from '@/util/ask-if';
import { groupPassesAdditionalCriteria } from '@/util/additional-criteria';
import getValue from 'lodash/get';

export default {
    name: 'FormNoteBlock',

    mixins: [FormBlock],

    behaviour: {
        render: {
            when (block, submission) {
                const { askIf, additionalCriteria } = block.pivot.settings;

                /**
                 * If askIf target is Not Attending, the Note Block can be submitted
                 * only if ALL guests in the group are not attending.
                 */
                const passingAskIfCriteria = getValue(askIf, 'target') === 'not-attending'
                    ? wholeGroupPassesAskIf(askIf, submission.guests)
                    : groupPassesAskIf(askIf, submission.guests);

                const passingAdditionalCriteria = groupPassesAdditionalCriteria(additionalCriteria, submission.guests);

                return passingAskIfCriteria && passingAdditionalCriteria;
            },
            unless (block, submission) {
                return false;
            }
        }
    },

    props: {
        block: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            note: ''
        };
    },

    watch: {
        isActive (newValue, oldValue) {
            if (newValue && !oldValue) {
                this.$refs.input.focus();
            }
        }
    },

    methods: {
        async completeBlock () {
            return this.getCompletionObject();
        },

        serializePayload () {
            return {
                note: this.note
            };
        }
    }
};
</script>
