<template>
    <div>
        <button
            class="button-text button-info mb-4 mt-4 ml-4 md:mt-0 md:ml-0"
            @click="$emit('open-theme-drawer')"
        >
            <app-icon
                name="color-brush"
                class="h-5 w-5 mr-2"
                stroke
            ></app-icon>
            Update Form Theme
        </button>

        <div class="bg-white p-4 border-0 md:border rounded-md">
            <div class="flex items-center">
                <div class="flex items-center flex-auto">
                    <app-icon
                        name="app-page-account"
                        class="h-5 w-5 text-gray-600 mr-2"
                        stroke
                    ></app-icon>

                    <div class="text-gray-800 font-bold text-lg">
                        Form Builder
                    </div>
                </div>

                <div class="shrink">
                    <a
                        class="text-gray-600 hover:text-gray-700 leading-none cursor-pointer"
                        role="button"
                        @click="toggleMenu"
                    >
                        <app-icon name="close" class="h-3 w-3"></app-icon>
                    </a>
                </div>
            </div>

            <accordion-list
                :value="activeSection"
                class="pt-4 rounded-md"
                @input="$emit('update-active-section', $event)"
            >
                <accordion-list-item
                    v-for="section in availableSections"
                    :key="section.class"
                    :item-id="section.class"
                    v-bind="accordionItemConfig"
                >
                    <div slot="header" class="py-2 flex grow items-center justify-between">
                        <div>
                            {{ section.label }}
                        </div>
                    </div>
                    <div slot="content">
                        <div class="px-2 py-3 text-sm flex items-center justify-center text-gray-600">
                            <template v-if="section.availableBlocks.length">
                                <app-icon
                                    name="move-hand"
                                    class="h-5 w-5 mr-1 text-gray-500"
                                    stroke
                                ></app-icon>
                                Drag-and-drop to add.
                            </template>
                            <template v-else>
                                This section is not editable.
                            </template>
                        </div>

                        <draggable
                            v-model="section.availableBlocks"
                            v-bind="getBlockDraggableOptions(section)"
                            :clone="handleClone"
                            :move="blockMove"
                            @end="isBlockPlacementAvailable = true"
                        >
                            <div
                                v-for="block in section.availableBlocks"
                                :key="`${block.id}-${blocks.length}`"
                                class="group rounded-md border px-2 py-3 mb-2 text-sm flex items-center justify-between"
                                :class="blockDragItemClass(block)"
                                :data-block-id="nextAvailableBlockId"
                                :data-section-class="section.class"
                                @click="addBlock(block, section)"
                            >
                                <div class="flex grow items-center justify-between text-sm">
                                    <app-icon
                                        v-if="block.settings.icon"
                                        :name="block.settings.icon"
                                        class="h-5 w-5 mr-3 text-purple"
                                    ></app-icon>
                                    <div class="flex-auto">
                                        {{ block.title }}
                                    </div>
                                    <div v-if="block.properties.feature" class="leading-none mr-2">
                                        <premium-feature :feature="block.properties.feature"></premium-feature>
                                    </div>
                                    <div v-if="block.properties.toggleable" class="leading-none mr-2">
                                        <toggle-switch
                                            :value="isBlockVisible(block)"
                                            small
                                            @input="toggleBlockVisibility($event, block)"
                                        ></toggle-switch>
                                    </div>
                                </div>
                                <div
                                    v-tippy
                                    content="Add block to bottom of form."
                                >
                                    <app-icon
                                        v-if="canAddBlock(block)"
                                        name="add-circle"
                                        class="hidden h-5 w-5 text-purple hover:text-purple-light hover:cursor-pointer group-hover:flex"
                                        stroke
                                    ></app-icon>
                                </div>
                                <app-icon
                                    v-if="block.paidFeature"
                                    name="lock"
                                    class="h-4 w-4 text-gray-300"
                                    stroke
                                ></app-icon>
                            </div>
                        </draggable>
                    </div>
                </accordion-list-item>
            </accordion-list>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import find from 'lodash/find';
import ForceBlockOrder from '@/mixins/ForceBlockOrder';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';

export default {
    name: 'FormBuilderMainMenu',

    components: { draggable },

    mixins: [
        ForceBlockOrder,
        GeneratesUniqueKey
    ],

    props: {
        activeSection: {
            type: String,
            default: null
        },

        availableSections: {
            type: Array,
            required: true
        },

        blocks: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            sectionToDelete: null,
            nextAvailableBlockId: this.getUniqueKey()
        };
    },

    computed: {
        accordionItemConfig () {
            return {
                'expand-collapse-icon': false,
                class: 'mb-2',
                activeHeaderClass: 'text-purple'
            };
        }
    },

    watch: {
        isBlockPlacementAvailable (newValue) {
            this.$emit('change-placement-availability', newValue);
        }
    },

    methods: {
        blockDragItemClass (block) {
            if (block.properties.unique && this.isBlockAlreadyAdded(block)) {
                return `ignore-elements ${block.properties.toggleable ? '' : 'opacity-50'}`;
            }

            return 'cursor-grab';
        },

        getBlockDraggableOptions (section) {
            return {
                filter: '.ignore-elements',
                animation: 150,
                sort: false,
                group: {
                    name: section.class,
                    pull: 'clone',
                    put: false
                }
            };
        },

        addBlock (block, section) {
            if (this.canAddBlock(block)) {
                this.$emit('add-block', { block, section });
            }
        },

        canAddBlock (block) {
            if (block.properties.unique && this.isBlockAlreadyAdded(block)) {
                return false;
            }

            return !block.paidFeature && !block.properties.toggleable;
        },

        handleClone (originalObject) {
            return {
                id: this.nextAvailableBlockId,
                blockId: originalObject.id
            };
        },

        isBlockAlreadyAdded (block) {
            return !!find(this.blocks, { type: block.type });
        },

        isBlockVisible (block) {
            const blockable = find(this.blocks, { type: block.type });
            return blockable.pivot.settings.isDisplayed !== false;
        },

        toggleMenu () {
            this.$emit('toggle-menu');
        },

        toggleBlockVisibility (value, block) {
            const blockable = find(this.blocks, { type: block.type });

            App.$emit('modified-block', {
                id: blockable.pivot.id,
                isDisplayed: value
            });
        }
    }
};
</script>
