/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-discount-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>shopping percent coupon</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25 15v3c0 .82-.68 1.5-1.5 1.5H2.24A1.51 1.51 0 01.74 18s0 0 0 0v-3l-.01-.001c1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3v-3h0c-.01-.83.67-1.51 1.49-1.51h19.5v-.001c.82-.01 1.5.67 1.5 1.5 0 0 0 0 0 0v3h-.01c-1.66 0-3 1.34-3 3 0 1.65 1.34 3 3 3z"/><path pid="1" d="M8.25 7.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM16.5 13.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM15.75 8.25l-7.5 7.5"/></g>'
  }
})
