/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-sanitizer-liquid-3-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9.125 20.076v-.01c-3.5.82-7.01-1.34-7.83-4.84-.12-.49-.18-.99-.18-1.5 0-4.88 6.5-13 6.5-13l-.01-.01c2.07 2.63 3.84 5.48 5.3 8.49"/><path pid="1" d="M22.875 15.75h0a7.67 7.67 0 01-6.01 7.49l-.01-.01a7.672 7.672 0 01-6-7.51v-2.26h0c-.01-.83.67-1.51 1.49-1.51h9-.01c.82-.01 1.5.67 1.5 1.5zM16.88 15v4.5M14.62 17.25h4.5M7.62 17.25l-.01-.001a3.506 3.506 0 01-3.5-3.51v-.001"/></g>'
  }
})
