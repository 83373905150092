/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'religion-islam-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.31 6.311h-.01c.12-.25.41-.34.65-.22.09.04.16.12.21.21l1.01 2.04h0c.07.14.2.24.36.26l2.27.32h0c.26.03.44.27.4.53-.02.1-.07.2-.14.27l-1.65 1.6h-.01c-.12.1-.17.26-.14.42l.38 2.25h0c.03.26-.15.51-.41.54-.1.01-.21-.01-.3-.05l-2.03-1.06h0a.5.5 0 00-.46-.01l-2.03 1.06v-.01c-.24.12-.53.02-.66-.21-.05-.09-.07-.2-.05-.3l.38-2.26v-.01a.516.516 0 00-.14-.43l-1.65-1.6-.01-.01a.477.477 0 01-.01-.68.42.42 0 01.27-.14l2.27-.33h-.01c.15-.03.29-.13.36-.27z"/><path pid="1" d="M8.25 12.043c0-3.82 2.94-8.4 7.83-8.96v-.01c.41-.06.7-.43.65-.84a.786.786 0 00-.46-.61l-.01-.01c-5.75-2.38-12.32.36-14.7 6.1-2.38 5.74.36 12.31 6.1 14.69 1.36.56 2.82.85 4.29.85h0c1.47 0 2.92-.29 4.28-.86h-.01a.758.758 0 00-.21-1.45l-.01-.01a9.145 9.145 0 01-7.84-8.97z"/></g>'
  }
})
