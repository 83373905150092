/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'school-woman-graduate-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>user person</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.223 6v0c0 2.89-2.36 5.25-5.25 5.25-2.9 0-5.25-2.36-5.25-5.25V.75h10.5zM.72.75h22.5M6.72 5.25h10.5M2.22.75v7.5M6.9 14.804l5.073 3.94 5.07-3.95"/><path pid="1" d="M22.44 23.25h0c-.42-5.79-5.45-10.14-11.23-9.73-5.21.37-9.35 4.51-9.73 9.72M6.77 6.75S6.71 10.5 3.71 12M17.169 6.75s.05 3.75 3.05 5.25"/></g>'
  }
})
