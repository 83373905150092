/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-disable-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<rect pid="0" x=".75" y="3.75" width="22.5" height="19.5" rx="1.5" ry="1.5" _fill="none" _stroke="currentColor" stroke-linejoin="round" stroke-width="1.5"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linejoin="round" stroke-width="1.5" d="M.75 9.75h22.5"/><path pid="2" stroke-linecap="round" _fill="none" _stroke="currentColor" stroke-linejoin="round" stroke-width="1.5" d="M6.75 6V.75M17.25 6V.75M9 13.5l6 6M9 19.5l6-6"/>'
  }
})
