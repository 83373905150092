/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music-speaker-note-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.875 8.41a1.875 1.875 0 100 3.75 1.875 1.875 0 100-3.75zM21.375 6.16a1.875 1.875 0 100 3.75 1.875 1.875 0 100-3.75z"/><path pid="1" d="M15.75 10.29v-6.8h-.01c0-.65.41-1.22 1.02-1.43l4.5-1.25V.8c.78-.27 1.63.16 1.89.94.05.15.07.31.07.47V8"/><path pid="2" d="M17.25 14.25v7.5c0 .82-.68 1.5-1.5 1.5H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V2.25h0C.73 1.42 1.41.74 2.23.74h10.5"/><path pid="3" d="M14.232 15.436v-.01a5.26 5.26 0 01-5.67 4.79c-2.89-.25-5.04-2.78-4.8-5.67.22-2.73 2.5-4.82 5.23-4.82"/><path pid="4" d="M9 13.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM9 3.75a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/></g>'
  }
})
