/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'baby-care-stroller-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>trolley</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M3 18.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM13.5 18.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM15 15.75H3.42h0c-.69-.01-1.29-.47-1.46-1.14L.74 9.74h16.5"/><path pid="1" d="M13.864 18.779l4.9-13.09v-.01a2.97 2.97 0 012.8-1.95h1.66M5.12 20.25h6.26M.75 9.75h0A8.988 8.988 0 019.74.74v9M9.75 9.75L1.98 5.21M9.75 9.75L5.21 1.98"/></g>'
  }
})
