<template>
    <multi-page-item-settings
        class="h-full"
        :items-array="form.products"
        :settings="settings"
        :item-creator-function="createItem"
        :default-close-handler="defaultCloseHandler"
        @save-specific-settings="saveSpecificItemSettings"
    >
        <template slot-scope="{ edit, save, create, duplicate, restore, selectedItem }">
            <form-ticketing-block-general-settings
                key="general-settings"
                :block="block"
                :form="form"
                @edit-item="edit"
                @create-item="create"
                @duplicate-item="duplicate"
            ></form-ticketing-block-general-settings>

            <form-product-item-settings
                key="item-settings"
                :event="event"
                :block="block"
                :item="selectedItem"
                :recurring-time-slots-active="form.recurringTimeSlots.active"
                @save-settings="save"
                @cancel="restore"
            ></form-product-item-settings>
        </template>
    </multi-page-item-settings>
</template>

<script>
import get from 'lodash/get';
import Form from '@/validation/Form';
import HasDefaultCloseHandler from '@/mixins/HasDefaultCloseHandler';
import BlockableSettingsMixin from '@/mixins/BlockableSettingsMixin';

export default {
    mixins: [HasDefaultCloseHandler, BlockableSettingsMixin],

    data () {
        const recurringTimeSlots = { ...this.event.settings.recurringTimeSlots, timezoneId: this.event.timezone_id };
        const maxCapacity = { ...this.event.settings.maxCapacity };

        this.$emit('original-settings-override-input', { recurringTimeSlots, maxCapacity });

        return {
            form: new Form(
                Object.assign(this.settings, {
                    products: this.block.pivot.products,
                    recurringTimeSlots,
                    maxCapacity
                })
            )
        };
    },

    methods: {
        createItem () {
            return {
                title: '',
                category: 'individual',
                price: 0,
                active: true,
                sort: this.form.products.length + 1,
                settings: {
                    costsMoney: false,
                    icon: 'ticket',
                    description: '',
                    requireName: {
                        enabled: false
                    },
                    requireEmail: {
                        enabled: false,
                        sendConfirmationEmails: false
                    },
                    calendarInvites: {
                        enabled: !!this.event.starts_at,
                        description: get(this.event.settings, 'globalCalendarInvites.description', null),
                        email: get(this.event.settings, 'globalCalendarInvites.organizerEmail', null),
                        end: '',
                        location: '',
                        organizer: get(this.event.settings, 'globalCalendarInvites.eventOrganizer', null),
                        start: '',
                        timezone: this.event.timezone_id,
                        type: this.event.starts_at ? 'event' : 'custom'
                    },
                    maxCapacity: {
                        enabled: false,
                        slots: 50,
                        showMessage: true,
                        message: 'Sorry, all tickets are sold out!'
                    },
                    guestsPerOrder: 2
                }
            };
        },

        saveSpecificItemSettings (item) {
            this.$set(this.form, 'declineable', item);
        }
    }
};
</script>
