<template>
    <div>
        <button class="button-text button-info" @click="exportSpreadsheet">
            Export activity
            <app-icon
                v-tippy
                name="info-circle"
                class="h-4 w-4 ml-2 text-gray-600"
                content="Export timestamped activity logs of guest check-in and out activity."
                stroke
            ></app-icon>
        </button>

        <export-button-modal
            v-model="isModalVisible"
            :state="state"
            :download-url="downloadUrl"
            @closed="isModalVisible = false"
        ></export-button-modal>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import InteractsWithAbly from '@/mixins/InteractsWithAbly';
import axios from '@/util/axios';
import EventTimezone from '@/mixins/EventTimezone';

const STATE_EXPORTING = 'exporting';
const STATE_EXPORT_READY = 'export-ready';
const STATE_FAILED = 'failed';

export default {
    name: 'ExportCheckInActivity',

    mixins: [InteractsWithAbly, EventTimezone],

    data () {
        return {
            downloadUrl: null,
            isModalVisible: false,
            state: null
        };
    },

    computed: {
        event: get('Event/event')
    },

    methods: {
        exportSpreadsheet () {
            this.listenForEvents();

            this.isModalVisible = true;
            this.state = STATE_EXPORTING;

            this.initiateExport();
        },

        /**
         * Starts the download process by submitting an API call.
         */
        initiateExport () {
            axios.get(this.route('check-in.events.check-in-activity.export', {
                event: this.event,
                timezone: this.eventTimezone
            }));
        },

        /**
         * Listens for the export to fail or succeed to present the download link.
         */
        listenForEvents () {
            this.$echo.private(`events.${this.event.id}.check-in-activity`)
                .listen('.Domain\\CheckIn\\Events\\CheckInActivityExportFailed', () => {
                    this.state = STATE_FAILED;
                })
                .listen('.Domain\\CheckIn\\Events\\CheckInActivityExported', ({ downloadUrl }) => {
                    this.downloadUrl = downloadUrl;
                    this.state = STATE_EXPORT_READY;
                    window.location = downloadUrl;
                });
        }
    }
};
</script>
