<template>
    <div>
        <slot :launch="launch"></slot>

        <fancy-image-uploader-modal
            v-if="isUploadModalVisible"
            v-model="isUploadModalVisible"
            @select-image="handleImage"
        >
            <template #recommendations>
                <slot name="recommendations"></slot>
            </template>
        </fancy-image-uploader-modal>
    </div>
</template>

<script>
export default {
    name: 'FancyImageUploader',

    props: {
        conversion: {
            type: String,
            default: 'original'
        }
    },

    data () {
        return {
            isUploadModalVisible: false
        };
    },

    methods: {
        handleImage (urls) {
            this.isUploadModalVisible = false;
            this.$emit('select-image', urls[this.conversion]);
        },

        launch () {
            this.isUploadModalVisible = true;
        }
    }
};
</script>
