const colors = require('tailwindcss/colors');
const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
    content: [
        './resources/**/*.blade.php',
        './resources/**/*.js',
        './resources/**/*.vue'
    ],

    theme: {
        colors: {
            black: '#0d0918',
            white: colors.white,
            transparent: 'transparent',
            gray: colors.gray,

            red: {
                light: '#fceced',
                DEFAULT: '#e3414b',
                dark: '#760d13'
            },

            orange: {
                DEFAULT: '#f5a55a',
                dark: '#f28825'
            },

            yellow: {
                light: '#fef5ed',
                DEFAULT: '#ffb875',
                dark: '#996200'
            },

            green: {
                light: '#e6f6f1',
                DEFAULT: '#09a96f',
                dark: '#055a3b'
            },

            teal: {
                light: '#e6f4f6',
                DEFAULT: '#0994a9',
                dark: '#0f5d69'
            },

            blue: {
                light: '#ecf4ff',
                DEFAULT: '#3c90ff',
                dark: '#2f69b5'
            },

            purple: {
                50: '#eceaf0',
                100: '#cbc3d6',
                200: '#b5a9c4',
                300: '#9e8fb2',
                400: '#8875a0',
                500: '#725c8f',
                600: '#5b427d',
                700: '#45286b',
                800: '#3e2460',
                900: '#372056',
                lighter: '#9e8fb2',
                light: '#725c8f',
                DEFAULT: '#45286b'
            },

            violet: {
                50: '#e8e7eb',
                100: '#c6c4cd',
                200: '#bab8c3',
                300: '#a3a0af',
                400: '#817d90',
                500: '#5e5972',
                600: '#3c3654',
                700: '#191236',
                800: '#120d26',
                900: '#0e0a1e',
                DEFAULT: '#191236'
            },

            smoke: {
                100: 'hsla(0, 0%, 0%, 0.1)',
                200: 'hsla(0, 0%, 0%, 0.2)',
                300: 'hsla(0, 0%, 0%, 0.3)',
                400: 'hsla(0, 0%, 0%, 0.4)',
                500: 'hsla(0, 0%, 0%, 0.5)',
                600: 'hsla(0, 0%, 0%, 0.6)',
                700: 'hsla(0, 0%, 0%, 0.7)',
                800: 'hsla(0, 0%, 0%, 0.8)',
                900: 'hsla(0, 0%, 0%, 0.9)'
            },

            snow: {
                100: 'hsla(0, 0%, 100%, 0.1)',
                200: 'hsla(0, 0%, 100%, 0.2)',
                300: 'hsla(0, 0%, 100%, 0.3)',
                400: 'hsla(0, 0%, 100%, 0.4)',
                500: 'hsla(0, 0%, 100%, 0.5)',
                600: 'hsla(0, 0%, 100%, 0.6)',
                700: 'hsla(0, 0%, 100%, 0.7)',
                800: 'hsla(0, 0%, 100%, 0.8)',
                900: 'hsla(0, 0%, 100%, 0.9)',
                950: 'hsla(0, 0%, 100%, 0.95)'
            }
        },

        container: {
            center: true,
            padding: '1rem'
        },

        fontWeight: {
            hairline: 300,
            thin: 300,
            light: 300,
            normal: 400,
            medium: 600,
            semibold: 600,
            bold: 700,
            extrabold: 800,
            black: 800
        },

        maxHeight: {
            '2xs': '10rem',
            xs: '20rem',
            sm: '30rem',
            md: '40rem',
            lg: '50rem',
            xl: '60rem',
            full: '100%',
            screen: '100vh',
            'screen-64': '64vh',
            'screen-80': '80vh'
        },

        minWidth: (theme) => {
            return {
                ...theme('maxWidth'),
                ...defaultTheme.minWidth,
                ...defaultTheme.spacing
            };
        },

        extend: {
            borderColor: (theme) => {
                return {
                    DEFAULT: theme('colors.gray.200', 'currentColor')
                };
            },

            cursor: {
                help: 'help'
            },

            fontFamily: {
                sans: ['Open Sans', ...defaultTheme.fontFamily.sans]
            },

            lineHeight: ['0'],

            outline: {
                none: '0px solid transparent'
            },

            padding: {
                '5/6': '83.3333333%'
            },

            spacing: {
                '2px': '2px',
                7: '1.75rem',
                9: '2.25rem',
                11: '2.75rem',
                13: '3.25rem',
                14: '3.5rem',
                15: '3.75rem',
                18: '4.5rem',
                28: '7rem',
                30: '7.5rem',
                63: '15.75rem',
                72: '18rem',
                80: '20rem',
                88: '22rem',
                128: '32rem',
                144: '36rem',
                180: '45rem'
            },

            maxWidth: {
                20: '5rem',
                28: '7rem',
                52: '13rem'
            },

            minHeight: {
                xs: '10rem',
                sm: '20rem'
            },

            fontSize: {
                '2xs': '.625rem',
                '3xs': '.5rem'
            }
        },

        scale: {
            0: 0,
            25: '.25',
            50: '.5',
            75: '.75',
            100: '1',
            110: '1.1',
            125: '1.25'
        },

        zIndex: {
            ...defaultTheme.zIndex,
            60: 60,
            70: 70,
            80: 80,
            90: 90,
            100: 100,
            1000: 1000,
            9999: 9999,
            99999: 99999,
            999999: 999999
        }
    },
    plugins: [
        require('@kirschbaum-development/tailwindcss-scale-utilities'),
        require('./resources/js/tailwind-plugins/border-color-utilities')(),
        require('./resources/js/tailwind-plugins/flexbox-grid')()
    ]
};
