/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'color-painting-palette-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>theme art</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.022 15.62c.3 3.85 6.01 1.2 5.56 2.54-2.53 7.48-12.65 5.68-16.97 1.16l-.01-.01C.43 15.06.44 8.25 4.62 4.02 8.84-.21 16.012-.52 19.9 3.99c7.63 8.84-4.17 8.165-3.91 11.6z"/><path pid="1" d="M7.19 11.78a1.52 1.52 0 100 3.04 1.52 1.52 0 100-3.04zM10.95 16.62a1.52 1.52 0 100 3.04 1.52 1.52 0 100-3.04zM14.58 4.39a1.52 1.52 0 100 3.04 1.52 1.52 0 100-3.04zM8.14 6a1.52 1.52 0 100 3.04 1.52 1.52 0 100-3.04z"/></g>'
  }
})
