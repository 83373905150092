<template>
    <input
        ref="autocomplete"
        :value="location"
        type="text"
        :placeholder="placeholder"
        @input="fireInputEvent({ address: $event.target.value })"
    >
</template>

<script>
export default {
    name: 'GoogleAutocompleteInput',

    props: {
        placeholder: {
            type: String,
            default: 'Start typing'
        },

        value: {
            type: Object,
            default: () => {
                return {
                    address: null,
                    venue: null
                };
            },
            required: false
        }
    },

    data () {
        return {
            autocomplete: null
        };
    },

    computed: {
        location () {
            if (!this.value) {
                return '';
            }

            if (!this.value.venue) {
                return this.value.address;
            }

            return `${this.value.venue} - ${this.value.address}`;
        },

        fields () {
            return [
                'address_components',
                'formatted_address',
                'geometry',
                'name',
                'types'
            ];
        }
    },

    mounted () {
        this.autocomplete = new google.maps.places.Autocomplete(
            this.$refs.autocomplete
        );

        this.autocomplete.setFields(this.fields);
        this.autocomplete.addListener('place_changed', this.onPlaceChanged);
    },

    methods: {
        fireInputEvent (location) {
            this.$emit('input', {
                address: null,
                venue: null,
                ...location
            });
        },

        onPlaceChanged () {
            const place = this.autocomplete.getPlace();

            if (!place.geometry || !place.address_components) {
                this.fireInputEvent({ address: place.name });
                return;
            }

            if (!place.types.includes('point_of_interest')) {
                this.fireInputEvent({ address: place.formatted_address });
                return;
            }

            this.fireInputEvent({
                address: place.formatted_address,
                venue: place.name
            });
        }
    }
};
</script>
