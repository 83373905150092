/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'award-ribbon-first-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.8 13.042L.74 18l3.75.75 1.5 4.5 3.94-6.26M19.193 13.042L23.24 18l-3.75.75-1.5 4.5-3.95-6.26"/><path pid="1" d="M12 .75a8.25 8.25 0 100 16.5 8.25 8.25 0 100-16.5z"/><path pid="2" d="M11.25 5.25H12h-.01c.41-.01.75.33.75.75v5.25M11.25 11.25h3"/></g>'
  }
})
