/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'animal-dog-leash-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>puppy</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.25 18.75h-9l-.01-.001c-1.66-.01-3-1.35-3-3v-1.501h0a2.996 2.996 0 012.99-3.01h7.65-.01c1.04-.01 2-.54 2.55-1.43v-.01c.17-.3.33-.6.45-.92h-.01c.34-.86 1.16-1.41 2.08-1.41 1.5 0 2.25 1.75 2.25 3h0c0 .41.33.74.75.74h.75-.01c.82-.01 1.5.67 1.5 1.5 0 .82-.68 1.5-1.5 1.5h-2.25l-2.14 2.98h-.01a3.02 3.02 0 01-2.61 1.51h-.51M.75 9s0 3.24 2.533 2.98M14.25 18.75v4.5M17.25 23.25l-.73-5.07M3.75 18.35v4.9M6.75 18.75v4.5"/><path pid="1" d="M18.48 15.666l-4.39-4.67L8.235.75"/></g>'
  }
})
