<template>
    <tippy
        v-if="component !== null"
        ref="tippy"
        interactive
        placement="right"
        boundary="viewport"
        theme="white"
        :arrow="false"
        :max-width="400"
        trigger="click"
    >
        <template #trigger>
            <slot></slot>
        </template>

        <component
            :is="component"
        ></component>
    </tippy>
    <fragment v-else>
        <slot></slot>
    </fragment>
</template>

<script>
import { Fragment } from 'vue-frag';

export default {
    name: 'AccordionEventNavItemPopover',

    components: { Fragment },

    props: {
        component: {
            type: String,
            default: null
        }
    }

};
</script>
