/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-man-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 1a2.4 2.4 0 100 4.8 2.4 2.4 0 100-4.81zM16.2 11.2v0a4.2 4.2 0 10-8.4 0V13h1.8l.6 6h3.6l.6-6h1.8zM1 6v4M5 8H1M23 6v4M19 8h4"/><path pid="1" d="M5 15.914c-2.443.734-4 1.84-4 3.08 0 2.2 4.925 4 11 4 6.07 0 11-1.8 11-4 0-1.25-1.56-2.36-4-3.09"/></g>'
  }
})
