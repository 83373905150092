/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'award-badge-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>ribbon achievement star</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.7 12.434l1.66 3.31h2.85v-.001c.42-.01.77.34.77.77 0 .2-.09.4-.24.55l-2.44 1.66 1.26 3.39h0c.17.4-.02.86-.42 1.03-.23.09-.49.08-.7-.04l-3.49-1.96-3.5 1.96v-.01c-.39.21-.87.07-1.08-.31a.787.787 0 01-.04-.7l1.6-3.4-2.76-1.66-.01-.01a.779.779 0 01-.01-1.11c.14-.15.34-.24.55-.24h2.83l1.66-3.32h-.01a.784.784 0 011.4-.01z"/><path pid="1" d="M8.25.75h-.01c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v5.22h-.01c-.01.48.23.94.62 1.22l4.62 3.29 4.622-3.3v-.01c.39-.29.62-.74.62-1.23V2.21v0c0-.83-.68-1.5-1.5-1.5zM12 .75V12"/></g>'
  }
})
