<template>
    <base-dropdown
        class="inline-block"
        placement="bottom-start"
        :disabled="!isEditing"
    >
        <template slot="trigger" slot-scope="{ triggerFunction }">
            <button class="flex items-center" @click.stop="triggerFunction">
                <div v-if="selectedMeal" :key="selectedMeal.id">
                    <app-icon
                        v-if="selectedMeal.settings.icon"
                        :name="selectedMeal.settings.icon"
                        class="mr-2 h-6 w-6"
                    ></app-icon>

                    {{ selectedMeal.title }}
                </div>
                <div v-else>
                    No selection
                </div>

                <app-icon
                    v-if="isEditing"
                    name="arrow-down-chevron"
                    class="ml-2 h-2 w-2"
                    stroke
                ></app-icon>
            </button>
        </template>

        <template #default="{ triggerFunction }">
            <div class="bg-white rounded-md border shadow cursor-pointer text-sm mt-1 max-h-sm overflow-y-auto z-90">
                <button
                    v-for="eventMeal in blockableMeals"
                    :key="eventMeal.id"
                    class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                    @click.stop="selectMeal(eventMeal, triggerFunction)"
                >
                    <app-icon
                        v-if="eventMeal.settings.icon"
                        :name="eventMeal.settings.icon"
                        class="mr-2 h-6 w-6"
                    ></app-icon>
                    {{ eventMeal.title }}

                    <app-icon
                        v-if="selectedMeal && eventMeal.id === selectedMeal.id"
                        name="check-circle-filled"
                        class="ml-4 text-purple"
                    ></app-icon>
                </button>

                <button
                    class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                    @click.stop="selectMeal(null, triggerFunction)"
                >
                    No selection

                    <app-icon
                        v-if="!selectedMeal"
                        name="check-circle-filled"
                        class="ml-4 text-purple"
                    ></app-icon>
                </button>
            </div>
        </template>
    </base-dropdown>
</template>

<script>
import { find, get, filter } from 'lodash';

export default {
    name: 'GuestMealSelector',

    props: {
        event: {
            type: Object,
            required: true
        },

        guest: {
            type: Object,
            required: true
        },

        allEventMeals: {
            type: Array,
            required: true
        },

        blockableId: {
            type: Number,
            required: true
        },

        isEditing: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        blockableMeals () {
            return filter(this.allEventMeals, { blockable_id: this.blockableId });
        },

        selectedMeal () {
            if (this.guest.isNewGuest) {
                const selectedMealId = get(this.guest.meals, this.blockableId);

                return find(this.blockableMeals, { id: selectedMealId });
            }

            return find(this.guest.meals, { blockable_id: this.blockableId });
        }
    },

    methods: {
        selectMeal (meal, dropdownTriggerFunction) {
            this.$emit('selected-meal', meal);

            dropdownTriggerFunction();
        }
    }
};
</script>
