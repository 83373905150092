/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-soccer-field-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="22.5" height="16.5" x=".75" y="3.75" rx="1"/><path pid="1" d="M12 3.75v16.5M12 9a3 3 0 100 6 3 3 0 100-6zM.75 8.25h3-.01c.82-.01 1.5.67 1.5 1.5v4.5c0 .82-.68 1.5-1.5 1.5h-3M23.25 8.25h-3.01c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v4.5h0c0 .82.67 1.49 1.5 1.49h3"/></g>'
  }
})
