<template>
    <div class="w-full relative h-full">
        <template v-if="showDashboard">
            <test-mode-limited-capacity-alert></test-mode-limited-capacity-alert>
            <base-dropdown
                v-if="eventHasRecurringTimeslots"
                class="p-2"
                placement="bottom-start"
            >
                <template slot="trigger" slot-scope="{ triggerFunction }">
                    <a
                        role="button"
                        class="button-text button-primary text-lg space-x-2"
                        @click="triggerFunction"
                    >
                        <app-icon
                            name="calendar-clock"
                            class="w-6 h-6"
                            stroke
                        ></app-icon>

                        <span v-if="selectedTimeslotDate">{{ selectedTimeslotDate.toFormat('LLLL d, yyyy') }}</span>
                        <span v-else>All Time Slots</span>

                        <app-icon
                            name="arrow-down-chevron"
                            class="w-6 h-6"
                            stroke
                        ></app-icon>
                    </a>
                </template>

                <template #default="{ triggerFunction }">
                    <div class="p-4 bg-white border rounded-md shadow">
                        <timeslots-calendar
                            :value="selectedTimeslotDate"
                            host-calendar-view
                            @input="selectTimeslotDate($event, triggerFunction)"
                        ></timeslots-calendar>

                        <div class="text-center mt-2">
                            <button class="button-text button-primary" @click="viewAllTimeslots(triggerFunction)">
                                View All Time Slots
                            </button>
                        </div>
                    </div>
                </template>
            </base-dropdown>

            <tab-list
                v-if="showTabbedDashboard"
                class="w-full"
            >
                <tab-list-item
                    id="guests"
                    label="Guests"
                >
                    <portal-target name="guests"></portal-target>
                </tab-list-item>
                <tab-list-item
                    id="day-summary"
                    label="Day Summary"
                >
                    <portal-target name="day-summary"></portal-target>
                </tab-list-item>
            </tab-list>

            <portal to="day-summary" :disabled="!showTabbedDashboard">
                <dashboard-overview-cards
                    :event="event"
                    :selected-timeslot-date="selectedTimeslotDate"
                    :products="products"
                    :timeslots="timeslots"
                >
                    <div v-if="loading" class="absolute flex items-center justify-center inset-0 bg-white opacity-75">
                        <app-icon
                            name="loader"
                            class="w-16 h-16 text-purple opacity-75"
                        ></app-icon>
                    </div>
                </dashboard-overview-cards>
            </portal>

            <portal to="guests" :disabled="!showTabbedDashboard">
                <dashboard-data
                    :always-loaded-columns="['formalName', 'reply']"
                    :default-columns="['firstName', 'lastName', 'email', 'reply']"
                    :empty-placeholder="noResultsPlaceholder"
                    :event="event"
                    :required-predicates="requiredPredicates"
                >
                    <template #summary="{ row }">
                        <p>{{ row.formalName }}</p>

                        <template v-if="row.reply">
                            <div v-if="row.reply.state === 'NotAttending'" class="flex items-center space-x-2">
                                <app-icon
                                    name="close-circle"
                                    class="text-red h-5 w-5"
                                ></app-icon>

                                <span>{{ event.declineTitle }}</span>
                            </div>

                            <div v-else class="flex items-center">
                                <guest-reply-icon
                                    class="mr-2 min-h-5 min-w-5"
                                    :state="row.reply.state"
                                    colored
                                ></guest-reply-icon>

                                <span>{{ row.reply.product }}</span>

                                <span v-if="row.reply.timeslot" class="font-light ml-2">
                                    ({{ row.reply.timeslot | timeslotDate(eventTimezone.name) }})
                                </span>
                            </div>
                        </template>

                        <template v-else-if="property === 'refundedAmount'">
                            <div>
                                <div v-if="row[property] > 0">
                                    <span v-if="row.submission.order.refundable_amount > 0">Partial</span>
                                    <span v-else>Full</span>
                                    (<money-field :value="row[property]" static></money-field>)
                                </div>
                            </div>
                        </template>
                    </template>
                </dashboard-data>
            </portal>
        </template>

        <div v-else-if="loading" class="flex flex-col items-center justify-center h-full">
            <app-icon name="loader" class="w-20 h-20 mb-8"></app-icon>

            <h1 class="text-2xl text-center">
                We're loading your data, it will be ready in just a moment.
            </h1>
        </div>
    </div>
</template>

<script>
import axios from '@/util/axios';
import EventTimezone from '@/mixins/EventTimezone';

export default {
    name: 'DashboardOverview',

    mixins: [EventTimezone],

    props: {
        initialEvent: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            loading: true,
            event: null,
            products: [],
            selectedTimeslotDate: null,
            timeslots: []
        };
    },

    computed: {
        eventHasRecurringTimeslots () {
            return this.event.settings.recurringTimeSlots.active;
        },

        eventHasResponses () {
            return this.event.attending_count > 0
                || this.event.maybe_attending_count > 0
                || this.event.not_attending_count > 0;
        },

        requiredPredicates () {
            if (this.selectedTimeslotDate) {
                return [this.timeslotPredicates];
            }

            return [];
        },

        timeslotPredicates () {
            if (this.selectedTimeslotDate) {
                return {
                    field: 'reply:timeslot',
                    comparison: 'onDate',
                    value: this.selectedTimeslotDate.toFormat('yyyy-MM-dd')
                };
            }

            return {};
        },

        noResultsPlaceholder () {
            if (this.eventHasResponses) {
                return 'No guests match your search criteria.';
            }

            return 'You haven’t received any registrations.';
        },

        showDashboard () {
            if (!this.event) {
                return false;
            }

            if (this.selectedTimeslotDate) {
                return true;
            }

            return !this.loading;
        },

        showTabbedDashboard () {
            return this.selectedTimeslotDate !== null;
        },

        source () {
            return this.route('api.dashboard.overview', this.initialEvent);
        }
    },

    watch: {
        selectedTimeslotDate (newValue) {
            const date = newValue !== null
                ? newValue.toFormat('yyyy-MM-dd')
                : null;

            this.getEventOverviewCounts(date);
        }
    },

    created () {
        this.setLanguage();
    },

    mounted () {
        this.getEventOverviewCounts();

        App.$on('integration-sync-processed', () => {
            this.getEventOverviewCounts();
        });
    },

    methods: {
        getEventOverviewCounts (date = null) {
            this.loading = true;

            axios.get(this.route('api.dashboard.counts', { event: this.initialEvent, date }))
                .then(({ data }) => {
                    this.$set(this, 'event', data.event);
                    this.$set(this, 'products', data.products);
                    this.$set(this, 'timeslots', data.timeslots);
                    this.loading = false;
                });
        },

        selectTimeslotDate (date, dropdownToggleFn) {
            this.selectedTimeslotDate = date;
            dropdownToggleFn();
        },

        viewAllTimeslots (dropdownToggleFn) {
            this.selectedTimeslotDate = null;
            dropdownToggleFn();
        },

        setLanguage () {
            this.initialEvent.enabled_languages.forEach(({ name, translations }) => {
                this.$i18n.setLocaleMessage(name, translations);
            });

            this.$i18n.locale = this.initialEvent.enabled_languages[0].name;
        }
    }
};
</script>
