/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin-globe-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>gps earth planet</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 3.75a6 6 0 100 12 6 6 0 100-12zM6 9.75h12"/><path pid="1" d="M10.706 3.9v-.01c-1.08 3.88-.92 8 .45 11.8M13.3 3.9h0c.46 1.68.7 3.43.69 5.18l-.01-.01c0 2.25-.39 4.49-1.16 6.61"/><path pid="2" d="M21 9.754c0 7.9-6.932 12.33-8.629 13.3v-.01a.77.77 0 01-.75 0c-1.7-.97-8.63-5.41-8.63-13.3h0a8.988 8.988 0 018.99-9.01c4.97-.01 9 4.02 9 8.99 0 0 0 0 0 0z"/></g>'
  }
})
