<template>
    <div :class="containerClass">
        <table class="w-full border-collapse">
            <thead>
                <tr ref="header">
                    <th class="text-center top-0 table-cell md:sticky bg-white font-normal z-20 px-2" :colspan="rowColSpan">
                        <div class="w-full flex flex-wrap items-center my-2">
                            <slot
                                name="header"
                                :selected-all="selectedAll"
                                :selected-ids="selectedIds"
                            ></slot>
                        </div>
                    </th>
                </tr>
                <tr ref="columnHeaders">
                    <th
                        v-if="selectable"
                        class="text-center md:sticky box-sizing bg-white w-6 md:w-auto md:table-cell"
                        :style="columnHeaderOffset"
                    >
                        <div v-if="hasSelectAll" class="m-2">
                            <input
                                v-model="selectedAll"
                                type="checkbox"
                                name="tags"
                                @change="toggleSelectAll($event.target.checked)"
                            >
                            <div class="state">
                                <label></label>
                            </div>
                        </div>
                    </th>
                    <th
                        v-for="column in columns"
                        :key="column.label"
                        class="table-cell md:sticky box-sizing bg-white"
                        :class="columnHeaderClass"
                        :style="columnHeaderOffset"
                    >
                        <div
                            class="flex items-center uppercase tracking-wide text-sm text-left font-semibold text-gray-600 px-4 py-6"
                            :class="getColumnHeaderClass(column)"
                            :style="getColumnHeaderStyle(column)"
                            @click="sortByColumn(column)"
                        >
                            <div class="flex">
                                <div class="flex-auto">
                                    <div
                                        v-tippy="{ placement: 'top-start' }"
                                        :content="shouldTruncateCell(column.label, column) ? column.label : null"
                                    >
                                        <slot
                                            name="column"
                                            :column="column"
                                            :label="column.label"
                                            :property="column.property"
                                        >
                                            {{ getColumnHeaderValue(column.label, column) }}
                                        </slot>
                                    </div>
                                </div>
                                <div
                                    v-if="column.tooltip"
                                    v-tippy="{ placement: 'top-start' }"
                                    :content="column.tooltip"
                                    class="flex items-center justify-center ml-2"
                                >
                                    <app-icon name="info-circle" class="w-3 h-3"></app-icon>
                                </div>

                                <div v-if="column.sortable" class="shrink ml-2">
                                    <app-icon
                                        :name="getColumnSortIcon(column)"
                                        class="h-4 w-4 text-gray-500"
                                    ></app-icon>
                                </div>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr class="h-4"></tr>
                <template v-for="(group, index) in sortedData">
                    <tr
                        v-for="(row, rowIndex) in group"
                        :key="`row-${row[rowPrimaryKey]}`"
                        class="bg-white hover:border-r-purple hover:bg-gray-100"
                        :class="styles.row"
                        @click="handleRowClick(row, $event)"
                    >
                        <td v-if="selectable" class="border-b border-gray-100 text-center w-6 md:w-auto md:table-cell">
                            <div class="pretty p-default p-round p-bigger w-6 md:w-auto m-2">
                                <input
                                    :value="row[rowPrimaryKey]"
                                    :checked="rowIsSelected(row)"
                                    type="checkbox"
                                    @change="toggleSelectRow(row, $event.target.checked)"
                                    @click.stop
                                >
                                <div class="state">
                                    <label></label>
                                </div>
                            </div>
                        </td>
                        <td
                            v-for="column in columns"
                            :key="column.label"
                            class="p-4 border-b border-gray-100"
                        >
                            <div
                                v-tippy="{ placement: 'top-start' }"
                                :content="shouldTruncateCell(getCellValue(column, row, false), column) ? row[column.property] : null"
                                class="focus:outline-none"
                            >
                                <slot
                                    name="row"
                                    :group="group"
                                    :grouped="grouped"
                                    :row="row"
                                    :rowIndex="rowIndex"
                                    :column="column"
                                    :property="column.property"
                                    :value="getCellValue(column, row)"
                                >
                                    {{ getCellValue(column, row) }}
                                </slot>
                            </div>
                        </td>
                    </tr>
                    <tr :key="`group-spacer-${index}`" class="h-4"></tr>
                </template>
            </tbody>
        </table>

        <slot
            name="selectable"
            v-bind="{ selectedAll, selectedIds }"
        ></slot>

        <div
            v-if="rows.length === 0"
            class="alert alert-warning"
            role="alert"
        >
            {{ noResultsPlaceholder }}
        </div>

        <infinite-loading
            v-if="lazyLoadData"
            ref="infiniteLoading"
            :identifier="infiniteId"
            @infinite="$emit('scroll-end', $event)"
        >
            <div slot="spinner" class="w-full text-center my-4">
                <app-icon class="h-6 w-6 text-gray-500" name="loader"></app-icon>
            </div>

            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </div>
</template>

<script>
import includes from 'lodash/includes';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';
import flattenDeep from 'lodash/flatten';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import isArray from 'lodash/isArray';
import InfiniteLoading from 'vue-infinite-loading';

const DefaultOverflowLength = 50;

export default {
    components: { InfiniteLoading },

    props: {
        columns: {
            type: Array,
            required: true
        },

        columnHeaderClass: {
            type: String,
            default: ''
        },

        containerClass: {
            type: String,
            default: 'max-h-screen-80 block overflow-auto w-full my-4'
        },

        clickableRows: {
            type: Boolean,
            default: false
        },

        noRowBorder: {
            type: Boolean,
            default: false
        },

        remoteSort: {
            type: Boolean,
            default: false
        },

        rows: {
            type: [Array, Object],
            required: true
        },

        rowPrimaryKey: {
            type: String,
            default: 'id'
        },

        grouped: {
            type: Boolean,
            default: false
        },

        selectable: {
            type: Boolean,
            default: false
        },

        hasSelectAll: {
            type: Boolean,
            default: false
        },

        /**
         * By providing a non-null isSelectedFunction, the DataTable does not
         * handle any selection logic except emitting events.
         * */
        isSelectedFunction: {
            type: Function,
            default: null
        },

        noResultsPlaceholder: {
            type: String,
            required: true
        },

        sortBySingleColumnOnly: {
            type: Boolean,
            default: true
        },

        breakpoint: {
            type: String,
            default: 'md'
        },

        columnSorts: {
            type: Array,
            default: () => { return []; }
        },

        lazyLoadData: {
            type: Boolean,
            default: false
        },

        total: {
            type: Number,
            default: undefined
        }
    },

    data () {
        return {
            columnHeaderOffset: { top: '0px' },
            infiniteId: +new Date(),
            observer: null,
            selectedAll: false,
            selectedIds: [],
            sorts: this.columnSorts,
            selectAllTextOffset: { top: '0px' }
        };
    },

    computed: {
        styles () {
            return {
                row: {
                    'cursor-pointer': this.clickableRows,
                    border: this.noRowBorder ? '' : 'border-l-2 border-gray-500'
                }
            };
        },

        sortedData () {
            if (this.sorts.length === 0 || this.remoteSort) {
                return this.groups;
            }

            return this.sort(this.groups);
        },

        groups () {
            if (!this.grouped) {
                return [this.rows]; // There are no actual groups; there is only one group containing all the rows
            }

            return this.rows;
        },

        rowColSpan () {
            if (this.selectable) {
                return this.columns.length + 1;
            }

            return this.columns.length;
        }
    },

    watch: {
        columnSorts (newSorts) {
            this.sorts = newSorts;
        },

        rows () {
            if (this.selectedAll) {
                this.selectAllRows();
            }

            this.computeTopOffsets();
        },

        screenWidth () {
            this.computeTopOffsets();
        }
    },

    mounted () {
        if (this.hasSelectAll && this.total === undefined) {
            throw new Error('The total prop is required when hasSelectAll is true.');
        }

        this.observer = new IntersectionObserver((entries) => {
            if (entries[0].intersectionRatio > 0) {
                this.$refs?.infiniteLoading?.attemptLoad();
            }
        });

        this.observer.observe(this.$el);
    },

    beforeDestroy () {
        this.observer.disconnect();
    },

    methods: {
        computeTopOffsets () {
            this.columnHeaderOffset = {
                top: `${this.$refs.header.clientHeight}px`
            };

            const offset = this.$refs.columnHeaders.clientHeight + this.$refs.header.clientHeight;
            this.selectAllTextOffset = { top: `${offset}px` };
        },

        handleRowClick (row, $event) {
            if (!this.clickableRows) {
                return;
            }

            if (['a', 'button', 'input', 'textarea'].includes($event.target.tagName.toLowerCase())) {
                return;
            }

            this.$emit('click-row', row);
        },

        resetLazyLoading () {
            this.infiniteId = +new Date();
            this.selectedAll = false;
            this.selectedIds = [];
        },

        rowIsSelected (row) {
            if (this.isSelectedFunction) {
                return this.isSelectedFunction(row);
            }

            return includes(this.selectedIds, row[this.rowPrimaryKey]);
        },

        selectAllRows () {
            if (!this.grouped) {
                this.selectedIds = map(this.rows, this.rowPrimaryKey);
                return;
            }

            this.selectedIds = flatMap(this.rows, (group) => {
                return map(group, this.rowPrimaryKey);
            });
        },

        toggleSelectAll (isSelected) {
            const emitSelectedAllEvent = () => {
                this.$emit('selected-all', isSelected);
            };

            if (this.isSelectedFunction) {
                emitSelectedAllEvent();

                return;
            }

            this.selectedAll = isSelected;

            if (isSelected) {
                this.selectAllRows();
            } else {
                this.selectedIds = [];
            }

            emitSelectedAllEvent();
        },

        toggleSelectRow (row, isSelected, disableAllSelected = true) {
            const emitRowSelectedEvent = () => {
                this.$emit('row-selected', { row, isSelected });
            };

            if (this.isSelectedFunction) {
                emitRowSelectedEvent();

                return;
            }

            if (disableAllSelected) {
                this.selectedAll = false;
            }

            if (isSelected && !this.rowIsSelected(row)) {
                this.selectedIds.push(row[this.rowPrimaryKey]);
            }

            if (!isSelected) {
                const index = this.selectedIds.indexOf(row[this.rowPrimaryKey]);
                this.$delete(this.selectedIds, index);
            }

            emitRowSelectedEvent();
        },

        sortByColumn (column) {
            if (!column.sortable) {
                return;
            }

            if (!this.sortBySingleColumnOnly) {
                if (column.comparator) {
                    // At the moment, when sorting by custom criteria, there's no ordering by multiple columns, so delete
                    // all other sorts first and just leave this sort in (if it was there at all)
                    const existingSort = find(this.sorts, { property: column.property });

                    if (existingSort) {
                        this.sorts = [existingSort];
                    } else {
                        this.sorts = [];
                    }
                } else {
                    // Normal sorts that do not use custom criteria, can't be used in conjuction with custom sorts, so
                    // let's delete all custom sorts
                    this.sorts = this.sorts.filter((sort) => {
                        return !sort.comparator;
                    });
                }
            }

            this.toggleColumnSort(column);
        },

        getColumnSortDirection (column) {
            const existingColumnSort = find(this.sorts, { property: column.property });

            return existingColumnSort ? existingColumnSort.type : null;
        },

        getCellValue (column, row, truncateResults = true) {
            const value = get(row, column.property);
            const rendered = (typeof column.render === 'function')
                ? column.render(value, column, row)
                : value;

            return truncateResults ? this.truncateCell(rendered, column) : rendered;
        },

        getColumnHeaderValue (value, column) {
            return this.truncateCell(value, column);
        },

        truncateCell (value, column) {
            if (!this.shouldTruncateCell(value, column)) {
                return value;
            }

            const maxLength = column.overflow === true ? DefaultOverflowLength : column.overflow;
            const trimmedValue = value.substr(0, maxLength).trim();

            return `${trimmedValue}...`;
        },

        shouldTruncateCell (string, column) {
            if (typeof string !== 'string' || !column.overflow) {
                return false;
            }

            const maxLength = column.overflow === true ? DefaultOverflowLength : column.overflow;

            return string.length > maxLength;
        },

        toggleColumnSort (column) {
            const existingColumnSortIndex = findIndex(this.sorts, { property: column.property });

            // Toggles between the following sort states in the exact order: asc, desc, no sort
            if (existingColumnSortIndex === -1) {
                if (this.sortBySingleColumnOnly) {
                    this.sorts = [];
                }

                this.sorts.push({
                    property: column.property,
                    type: 'asc',
                    comparator: column.comparator,
                    grouped: !!column.groupedSort
                });
            } else {
                const sort = this.sorts[existingColumnSortIndex];

                if (sort.type === 'asc') {
                    sort.type = 'desc';
                } else {
                    this.sorts.splice(existingColumnSortIndex, 1);
                }
            }

            this.$emit('change-sort', this.sorts);
        },

        sort (data) {
            const rows = flattenDeep(data);

            // Custom sorting comparator by only one column
            if (this.sorts.length === 1 && this.sorts[0].comparator) {
                return this.sortWithCustomComparator(this.sorts[0].grouped ? data : rows);
            }

            // Regular sort with string comparison, that can sort by multiple column
            const properties = map(this.sorts, 'property');
            const directions = map(this.sorts, 'type');

            return [orderBy(rows, properties, directions)];
        },

        sortWithCustomComparator (rows) {
            const criteria = this.sorts[0];
            const { comparator } = criteria;

            const sortedRows = [];

            // The default comparator function logic is ascending, so in case the criteria chosen
            // by the user is descending, we have to "invert" the comparator function result
            const criteriaType = criteria.type === 'desc' ? -1 : 1;

            if (isFunction(comparator)) {
                sortedRows.push(
                    [...rows].sort((a, b) => {
                        return comparator(a, b, criteriaType) * criteriaType;
                    })
                );
            }

            if (isArray(comparator)) {
                sortedRows.push(
                    [...rows].sort((a, b) => {
                        let comparisonResult = 0;

                        const aIndex = comparator.indexOf(a[criteria.property]);
                        const bIndex = comparator.indexOf(b[criteria.property]);

                        if (aIndex < bIndex) {
                            comparisonResult = -1;
                        }

                        if (aIndex > bIndex) {
                            comparisonResult = 1;
                        }

                        return comparisonResult * criteriaType;
                    })
                );
            }

            return criteria.grouped ? sortedRows[0] : sortedRows;
        },

        getColumnSortIcon (column) {
            const direction = this.getColumnSortDirection(column);

            if (direction === 'asc') {
                return 'arrow-down-chevron-stroke';
            }

            if (direction === 'desc') {
                return 'arrow-up-chevron-stroke';
            }

            return 'arrow-up-down-chevron';
        },

        getColumnHeaderClass (column) {
            return [{ 'cursor-pointer': column.sortable }, column.headerClass];
        },

        getColumnHeaderStyle (column) {
            const columnStyles = {};

            if (column.minWidth) {
                columnStyles['min-width'] = column.minWidth;
            }

            if (column.maxWidth) {
                columnStyles['max-width'] = column.maxWidth;
            }

            return columnStyles;
        }
    }
};
</script>
