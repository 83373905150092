/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shoes-sneakers-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>clothing accessory footwear</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.5 12.75v-.01a3.758 3.758 0 01-3.76 3.74 3.765 3.765 0 01-3.64-2.85h0c-.14-.53-.61-.9-1.15-.9h0a1.2 1.2 0 00-1.21 1.2v7.79-.01c-.01.82.67 1.5 1.49 1.5h19.5-.01c.82 0 1.5-.68 1.5-1.5v-1.3c0-1.07-.57-2.05-1.48-2.59zM.75 20.25h22.5M12.75 16.5l1.87-1.87M15.75 18l1.97-1.97M13.5 11.25h-.01a3.745 3.745 0 017.38-.91l-.01-.01c.13.52.6.89 1.15.9H22c.66 0 1.2-.55 1.2-1.21v-7.8 0c0-.83-.68-1.5-1.5-1.5H2.19c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0V3.5H.68a2.98 2.98 0 001.47 2.58zM23.25 3.75H.75M11.25 7.5L9.38 9.37M8.25 6L6.28 7.97"/></g>'
  }
})
