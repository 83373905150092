<template>
    <div
        v-clipboard:copy="copy"
        v-clipboard:success="onCopy"
        v-clipboard:error="e => $emit('error', e)"
    >
        <div v-show="!showSuccess">
            <slot></slot>
        </div>

        <div v-if="showSuccess">
            <slot name="success"></slot>
        </div>
    </div>
</template>

<script>
import SlotHelpers from '@/mixins/SlotHelpers';

export default {
    name: 'CopyToClipboard',

    mixins: [SlotHelpers],

    props: {
        copy: {
            type: String,
            required: true
        },

        toastSuccessMessage: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            showSuccess: false
        };
    },

    methods: {
        onCopy (e) {
            this.$emit('copied', e);

            if (this.toastSuccessMessage) {
                this.$toasted.show(this.toastSuccessMessage);
            }

            if (!this.hasSlot('success')) {
                return;
            }

            this.showSuccess = true;

            setTimeout(() => {
                this.showSuccess = false;
            }, 3000);
        }
    }
};
</script>
