/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wedding-rings-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.13 16.5l-.01-.01c-1.24-3.52.6-7.36 4.11-8.6 3.51-1.24 7.35.6 8.59 4.11a6.742 6.742 0 01-4.12 8.59c-1.2.42-2.5.49-3.74.21"/><path pid="1" d="M13.86 12h0a6.742 6.742 0 01-4.12 8.59 6.749 6.749 0 01-8.6-4.12c-1.24-3.52.6-7.36 4.11-8.6 1.19-.43 2.49-.5 3.73-.22M16.5 7.5l-3.75-3 1.5-2.25h4.5l1.5 2.25-3.75 3z"/></g>'
  }
})
