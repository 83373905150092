<template>
    <div>
        <h3 class="mb-4">Simple example - With timeout function</h3>

        <copy-to-clipboard :copy="copyText">
            <template slot="success">
                <div class="inline-flex p-2 bg-green-light text-green-dark rounded-md uppercase tracking-wide text-sm font-semibold">
                    Copied!
                </div>
            </template>

            <a role="button" class="inline-flex p-2 bg-transparent rounded-md text-sm font-semibold hover:text-purple hover:cursor-pointer">
                Copy text
            </a>
        </copy-to-clipboard>

        <copy-to-clipboard :copy="copyText" @copied="showCopiedBadge">
            <div class="flex">
                <p v-if="!displayCopiedBadge" class="hover:text-purple hover:cursor-pointer">Copy text</p>

                <div v-if="displayCopiedBadge" class="badge badge-success">
                    COPIED!
                </div>
            </div>
        </copy-to-clipboard>

        <form-field-wrapper label="Enter text that you want to copy to clipboard">
            <input v-model="textToBeCopied" class="form-field">
        </form-field-wrapper>

        <div class="row mt-4">
            <div class="col-6">
                <copy-to-clipboard class="w-full" :copy="textToBeCopied">
                    <div class="flex items-center justify-center p-4 bg-purple text-white hover:cursor-pointer hover:bg-purple-lighter rounded-md">
                        Copy text without any action
                    </div>
                </copy-to-clipboard>
            </div>
            <div class="col-6">
                <copy-to-clipboard
                    class="w-full"
                    :copy="textToBeCopied"
                    @copied="showCopiedAlert"
                >
                    <div class="flex items-center justify-center p-4 bg-purple text-white hover:cursor-pointer hover:bg-purple-lighter rounded-md">
                        Copy text with action
                    </div>
                </copy-to-clipboard>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CopyToClipboardExample',

    data () {
        return {
            displayCopiedBadge: false,
            copyText: 'https://www.rsvpify.com',
            textToBeCopied: 'This text will be copied to your clipboard.'
        };
    },

    methods: {
        showCopiedBadge () {
            this.displayCopiedBadge = true;
            setTimeout(() => { this.displayCopiedBadge = false; }, 2000);
        },

        showCopiedAlert () {
            this.$toasted.global.success(`'${this.textToBeCopied}' has been copied to your clipboard.`);
        }
    }
};
</script>
