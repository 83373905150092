/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>url hyperlink</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 17.25l10.5-10.5M7.735 12.02h0c-1.28-.08-2.52.39-3.42 1.29l-2.25 2.25h-.01a4.49 4.49 0 000 6.36 4.5 4.5 0 006.36-.01l2.25-2.25v-.01c.9-.91 1.37-2.15 1.29-3.42M16.26 11.976h0c1.27.07 2.51-.4 3.41-1.31l2.25-2.25v-.01a4.512 4.512 0 00-.01-6.37 4.503 4.503 0 00-6.37 0l-2.25 2.25h-.01c-.9.9-1.37 2.14-1.3 3.41"/></g>'
  }
})
