/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-volleyball-ball-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/><path pid="1" d="M20.61 19.24h0a11.367 11.367 0 00-8.62-7.25M1.36 15.679l-.01-.01c3.94.78 8.01-.63 10.63-3.68"/><path pid="2" d="M13.88.9h-.01a11.13 11.13 0 00-1.89 11.09M4.942 15.8l-.01-.01A11.268 11.268 0 013.71 4.36M8.7 14.61l-.01-.01c-2.9-4.06-2.78-9.54.3-13.47"/><path pid="3" d="M12.07 3.955h-.01c4-.59 8 1.02 10.5 4.2M11.3 7.86v-.01c4.88-.66 9.62 1.92 11.73 6.37M18.83 16.075v-.01c-1.55 3.8-5.05 6.46-9.14 6.94M15.851 13.461v-.01a11.265 11.265 0 01-11.59 6.71"/></g>'
  }
})
