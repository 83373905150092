<template>
    <div class="w-full lg:w-1/3 tracking-wider text-xs rounded-md p-4 ring-2 ring-transparent">
        <div v-if="rsvpCredits.type === 'monthly'">
            <div class="flex tracking-wider mb-1">
                <p class="font-bold">RSVPs</p>
                <p class="ml-auto text-gray-500">{{ rsvpCredits.credits | number }} of {{ rsvpCredits.max | number }}</p>
            </div>

            <progress-bar class="mb-2" :bars="[{color: 'bg-purple-light', current: rsvpCredits.credits, total: rsvpCredits.max}]"></progress-bar>
        </div>

        <div v-else>
            <div
                v-for="(credits, index) in rsvpCredits.credits"
                :key="index"
            >
                <div class="flex tracking-wider mb-1">
                    <p class="font-bold">RSVPs: {{ credits.event }}</p>
                    <p class="ml-auto text-gray-500">
                        {{ credits.used | number }} of
                        <span v-if="credits.unlimited">unlimited</span>
                        <span v-else>{{ rsvpCredits.max | number }}</span>
                    </p>
                </div>

                <progress-bar class="mb-2" :bars="[{color: 'bg-purple-light', current: credits.used, total: rsvpCredits.max}]"></progress-bar>
            </div>
        </div>

        <div class="flex mb-1">
            <p class="font-bold">Events</p>
            <p class="ml-auto text-gray-500">{{ usedEvents | number }} of
                <template v-if="plan.features.EventLimit.unlimited">
                    unlimited
                </template>
                <template v-else>
                    {{ plan.features.EventLimit.max | number }}
                </template>
                used
            </p>
        </div>

        <template v-if="plan.features.EventLimit.unlimited">
            <progress-bar class="mb-2" :bars="[{color: 'bg-purple-light', percentage: 0}]"></progress-bar>
        </template>
        <template v-else>
            <progress-bar class="mb-2" :bars="[{color: 'bg-purple-light', current: usedEvents, total: plan.features.EventLimit.max}]"></progress-bar>
        </template>

        <div class="flex mb-1">
            <p class="font-bold">Email credits</p>
            <p class="ml-auto text-gray-500">{{ usedEmailCredits | number }} of {{ allotedEmailCredits | number }} used</p>
        </div>

        <progress-bar class="mb-2" :bars="[{color: 'bg-purple-light', current: usedEmailCredits, total: allotedEmailCredits}]"></progress-bar>

        <div class="flex mb-1">
            <p class="font-bold">Co-managers</p>
            <p class="ml-auto text-gray-500">{{ usedSharedUsers | number }} of
                <template v-if="plan.features.SharedUserLimit.unlimited">
                    unlimited
                </template>
                <template v-else>
                    {{ plan.features.SharedUserLimit.max | number }}
                </template>
                used
            </p>
        </div>

        <template v-if="plan.features.SharedUserLimit.unlimited">
            <progress-bar class="mb-2" :bars="[{color: 'bg-purple-light', percentage: 0}]"></progress-bar>
        </template>
        <template v-else>
            <progress-bar class="mb-2" :bars="[{color: 'bg-purple-light', current: usedSharedUsers, total: plan.features.SharedUserLimit.max}]"></progress-bar>
        </template>

        <template v-if="allotedCheckInCreditsPlan.type !== 'hide'">
            <div class="flex mb-1">
                <p class="font-bold">Check-in credits</p>
                <p class="ml-auto text-gray-500">{{ usedCheckinCredits | number }} of
                    <template v-if="allotedCheckInCreditsPlan.type === 'unlimited'">
                        unlimited
                    </template>
                    <template v-else>
                        {{ allotedCheckInCreditsPlan.max | number }}
                    </template>
                    used
                </p>
            </div>

            <template v-if="allotedCheckInCreditsPlan.type === 'unlimited'">
                <progress-bar class="mb-2" :bars="[{color: 'bg-purple-light', percentage: 0}]"></progress-bar>
            </template>
            <template v-else>
                <progress-bar class="mb-2" :bars="[{color: 'bg-purple-light', current: usedCheckinCredits, total: allotedCheckInCreditsPlan.max}]"></progress-bar>
            </template>
        </template>

        <p class="text-gray-500 text-center">
            <template v-if="planOverrideEndsAt !== null">
                <p v-if="planOverrideEndsAt !== null" class="text-xs mt-1">
                    <template v-if="planOverrideRenews === true">
                        Renews {{ auth().user().credits_period_end | datetime('DATE_FULL', true) }}
                    </template>

                    <template v-else>
                        Ends {{ planOverrideEndsAt | datetime('DATE_FULL', true) }}
                    </template>
                </p>
            </template>

            <template v-else-if="subscription && subscription.ends_at">
                Ends {{ subscription.ends_at | datetime('DATE_FULL') }}
            </template>

            <template v-else>
                Monthly credits renew {{ auth().user().credits_period_end | datetime('DATE_FULL') }}
            </template>
        </p>
    </div>
</template>

<script>
export default {
    name: 'AccountLimitsUsed',

    props: {
        allotedCheckInCreditsPlan: {
            type: Object,
            required: true
        },

        allotedEmailCredits: {
            type: Number,
            required: true
        },

        plan: {
            type: Object,
            required: true
        },

        planOverrideEndsAt: {
            type: String,
            default: null
        },

        planOverrideRenews: {
            type: Boolean,
            default: null
        },

        rsvpCredits: {
            type: Object,
            required: true
        },

        subscription: {
            type: Object,
            default: null
        },

        usedCheckinCredits: {
            type: Number,
            required: true
        },

        usedEmailCredits: {
            type: Number,
            required: true
        },

        usedEvents: {
            type: Number,
            required: true
        },

        usedSharedUsers: {
            type: Number,
            required: true
        }
    }
};
</script>
