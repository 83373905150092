/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'animal-fish-bowl-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>aquarium tank</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.263 11.545s5.25 6.75 9.75 1.5c-4.5-5.25-9.75 1.5-9.75 1.5M1.4 6.75h21.22"/><path pid="1" d="M19.661 2.25H4.36v-.01C-.2 6.46-.47 13.58 3.75 18.14c4.22 4.55 11.34 4.82 15.9.6 4.55-4.23 4.82-11.35.6-15.9-.2-.21-.4-.41-.6-.61z"/></g>'
  }
})
