/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'seafood-crab-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 12.75v1.5M8.29 13.5l1.05 1.06M15.71 13.5l-1.05 1.06M18.629 11.42l-.01-.01c-4.27-1.65-9-1.65-13.26 0v-.01c-.8.27-1.23 1.14-.95 1.94.01.04.02.07.04.11 1.73 3.907 2.849 5.509 5.61 7.9l-.01-.01c.27.23.61.36.97.36h1.87-.01c.35-.01.7-.14.97-.37 2.76-2.4 3.88-4 5.61-7.9v-.01c.34-.78-.01-1.68-.79-2.02-.04-.02-.08-.04-.12-.05zM18.5 15.669l-.01-.01a7.85 7.85 0 013.71 2.79l.18.27"/><path pid="1" d="M17.166 17.92l-.01-.01c1.44.54 2.69 1.52 3.56 2.8l.18.27M15.381 20.032l-.01-.01c1.5.59 2.8 1.61 3.73 2.94l.18.27M5.5 15.669v-.01c-1.5.53-2.79 1.51-3.72 2.79l-.19.28M6.834 17.92v-.01c-1.45.54-2.7 1.52-3.57 2.79l-.19.28M8.619 20.032v-.01a8.2 8.2 0 00-3.74 2.94l-.19.27M4.52 12.14l-.4-.28-.01-.01a7.052 7.052 0 01-2.53-6.63"/><path pid="2" d="M5.316 4.5l4.6-1.573h0c-.04-.32-.15-.63-.3-.9-.92-1.53-3.38-1.72-5.5-.42-2.12 1.29-3.1 3.58-2.18 5.118.91 1.53 3.38 1.71 5.5.41v-.01c.37-.24.72-.52 1.04-.83zM19.476 12.14l.4-.28v-.01a7.067 7.067 0 002.52-6.63"/><path pid="3" d="M18.684 4.5l-4.6-1.573h-.01c.03-.32.14-.63.29-.9.91-1.53 3.37-1.72 5.49-.42 2.11 1.29 3.09 3.58 2.17 5.118-.92 1.53-3.38 1.71-5.5.41h0a6.5 6.5 0 01-1.05-.83z"/></g>'
  }
})
