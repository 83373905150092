/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help-chat-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>question support bubble message</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.75 18.75h-10.5l-6 4.5v-4.5H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V2.24c0-.83.67-1.5 1.5-1.5h19.5-.01c.82-.01 1.5.67 1.5 1.5 0 0 0 0 0 0v15h0c0 .82-.68 1.5-1.5 1.5l-.01-.001z"/><path pid="1" d="M9 6.75h-.01a2.996 2.996 0 115.99 0c-.01 1.27-.81 2.4-2 2.82v-.01c-.6.21-1.01.77-1 1.41v.25M12 14.25h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.38-.38-.375v0"/></g>'
  }
})
