<template>
    <div class="container">
        <section class="flex flex-col mt-8">
            <page-header
                label="Registration Notifications"
                icon-stroke
                class="py-2 font-semibold text-xl text-black border-b"
            >
                <template #icon>
                    <toggle-switch v-model="allEventsToggle"></toggle-switch>
                </template>
            </page-header>

            <div class="w-full">
                <div class="w-full">
                    <div class="flex items-center justify-between space-x-6">
                        <div class="w-full md:w-2/3 lg:w-1/3">
                            <search-field
                                v-model="searchTerm"
                                class="w-full"
                                placeholder="Search by event name ..."
                            ></search-field>
                        </div>
                    </div>

                    <data-table
                        :columns="columns"
                        :rows="filteredEvents"
                        no-row-border
                        no-results-placeholder="There are currently no events you have access to."
                        column-header-class="z-10"
                    >
                        <template #column="{ property }">
                            <template v-if="property === 'options'">
                                <app-icon name="email-envelope-stroke" class="h-5 w-5"></app-icon>
                            </template>
                        </template>
                        <template #row="{ property, value, row, rowIndex }">
                            <template v-if="property === 'options'">
                                <toggle-switch :value="getEventAllNotificationsToggleValue(row)" @input="toggleAllNotificationsForEvent(row)"></toggle-switch>
                            </template>
                            <template v-else-if="property === 'name'">
                                <a :href="route('events.dashboard.overview', row)" target="_blank">{{ row.name }}</a>
                            </template>
                            <template v-else-if="property === 'date'">
                                {{ getFormattedStartDate(row) }}
                            </template>
                            <template v-else-if="property === 'new'">
                                <toggle-switch :value="row.permission.new" @input="togglePermission(row, 'new')"></toggle-switch>
                            </template>
                            <template v-else-if="property === 'edits'">
                                <toggle-switch :value="row.permission.edits" @input="togglePermission(row, 'edits')"></toggle-switch>
                            </template>
                            <template v-else-if="property === 'cancellations'">
                                <toggle-switch
                                    :value="row.permission.cancellations"
                                    class="ml-12"
                                    @input="togglePermission(row, 'cancellations')"
                                ></toggle-switch>
                            </template>
                        </template>
                    </data-table>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { cloneDeep, map } from 'lodash';
import { DateTime } from 'luxon';
import axios from '@/util/axios';
import SortingComparators from '@/util/sorting-comparators';

export default {
    name: 'RegistrationNotifications',

    props: {
        initialEvents: {
            type: Array,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            events: cloneDeep(this.initialEvents),
            loading: false,
            searchTerm: '',
            allEventsToggle: !this.user.hasAllEventNotificationsDisabled
        };
    },

    computed: {
        columns () {
            return [
                {
                    label: 'Options', property: 'options'
                },
                {
                    label: 'Event Name', property: 'name', sortable: true
                },
                {
                    label: 'Event Date',
                    property: 'date',
                    sortable: true,
                    comparator: SortingComparators.date('starts_at')
                },
                {
                    label: 'New', property: 'new'
                },
                {
                    label: 'Edits', property: 'edits'
                },
                {
                    label: 'Cancelations', property: 'cancellations'
                }
            ];
        },

        filteredEvents () {
            return this.events.filter((event) => {
                const searchRegex = new RegExp(this.searchTerm, 'i');

                return searchRegex.test(event.name);
            });
        }
    },

    watch: {
        allEventsToggle (newValue) {
            this.filteredEvents.forEach((event) => {
                Object.assign(event.permission, { new: newValue, edits: newValue, cancellations: newValue });
            });

            this.sendTogglePermissionRequests(
                map(this.filteredEvents, 'id'),
                ['new', 'edits', 'cancellations'],
                newValue
            );
        }
    },

    methods: {
        getFormattedStartDate (event) {
            if (!event.starts_at) {
                return '';
            }

            return DateTime.fromISO(event.starts_at).setZone('UTC').toFormat('LLLL d, yyyy @ h:mm a');
        },

        getEventAllNotificationsToggleValue (event) {
            const { edits, cancellations } = event.permission;

            return event.permission.new || edits || cancellations;
        },

        toggleAllNotificationsForEvent (event) {
            const newValue = !this.getEventAllNotificationsToggleValue(event);

            Object.assign(event.permission, { new: newValue, edits: newValue, cancellations: newValue });

            this.sendTogglePermissionRequests(
                [event.id],
                ['new', 'edits', 'cancellations'],
                newValue
            );
        },

        togglePermission (event, notification) {
            const newValue = !event.permission[notification];

            event.permission[notification] = newValue;

            this.sendTogglePermissionRequests([event.id], [notification], newValue);
        },

        sendTogglePermissionRequests (event_ids, notifications, enabled) {
            return axios.post(this.route('api.account.registration-notifications.toggle'), {
                event_ids,
                notifications,
                enabled
            });
        }
    }
};
</script>
