<template>
    <div>
        <input
            v-model="answer.streetAddress"
            placeholder="Street Address"
            class="form-field"
        >

        <input
            v-if="question.settings.mailingAddress.includeSecondStreetAddressField"
            v-model="answer.secondStreetAddress"
            placeholder="Street Address 2"
            class="form-field mt-4"
        >

        <div class="flex space-x-2 mt-4">
            <input
                v-if="question.settings.mailingAddress.includeCityField"
                v-model="answer.city"
                placeholder="City"
                class="w-1/2 form-field"
            >

            <div v-if="question.settings.mailingAddress.includeStateField" class="w-1/2">
                <us-states-picker
                    v-if="showStatesPicker"
                    v-model="answer.state"
                    placeholder-empty-state="State"
                    placeholder-search="Search State..."
                    class="w-full"
                    item-value="name"
                ></us-states-picker>

                <input
                    v-else
                    v-model="answer.state"
                    placeholder="State"
                    class="w-full form-field"
                >
            </div>
        </div>

        <div class="flex space-x-2 mt-4">
            <input
                v-if="question.settings.mailingAddress.includeZipCodeField"
                v-model="answer.zipCode"
                placeholder="Zip Code"
                class="form-field w-1/2"
            >

            <country-picker
                v-if="showCountryPicker"
                v-model="answer.country"
                placeholder-empty-state="Country"
                placeholder-search="Search Country..."
                class="w-1/2"
                item-value="name"
            ></country-picker>
        </div>
    </div>
</template>

<script>
import CustomQuestionEditComponent from '@/mixins/CustomQuestionEditComponent';

export default {
    name: 'CustomQuestionAnswerMailingAddress',

    mixins: [CustomQuestionEditComponent],

    data () {
        let answer = { };

        if (this.answers.length > 0 && this.answers[0].raw_value) {
            answer = JSON.parse(this.answers[0].raw_value);
        }

        return { answer };
    },

    computed: {
        showCountryPicker () {
            return this.question.settings.mailingAddress.includeCountryField && this.question.settings.mailingAddress.stateField !== 'usStates';
        },

        showStatesPicker () {
            return this.question.settings.mailingAddress.stateField === 'usStates';
        }
    },

    watch: {
        answer: {
            deep: true,
            handler (newValue) {
                this.$emit('input', newValue);
            }
        }
    }
};
</script>
