<template>
    <div
        class="inline-flex items-center w-auto bg-gray-800 rounded-md text-white py-1 px-2 group text-xs mr-1 cursor-pointer"
        @click="$emit('click')"
    >
        <div>{{ name }}</div>

        <app-icon
            v-if="deleteable"
            name="close"
            class="mx-2 hidden group-hover:block rounded-full bg-gray-900 p-1"
        ></app-icon>
    </div>
</template>

<script>
export default {
    name: 'Tag',

    props: {
        name: {
            type: String,
            default: ''
        },

        deleteable: {
            type: Boolean,
            default: true
        }
    }
};
</script>
