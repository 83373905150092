import InteractsWithAbly from '@/mixins/InteractsWithAbly';
import axios from '@/util/axios';

export default {
    mixins: [InteractsWithAbly],

    created () {
        if (this.event.classyIntegration) {
            this.$echo.private(`events.${this.event.id}`)
                .listen('.Domain\\Integrations\\Events\\SyncProcessed', (data) => {
                    this.$set(this.event.classyIntegration, 'syncing', false);
                    this.$set(this.event.classyIntegration, 'last_sync_at', data.last_sync_at);
                    this.$set(this.event.classyIntegration, 'last_sync_diff_for_humans', data.last_sync_diff_for_humans);
                    this.$set(this.event.classyIntegration, 'last_sync_status', data.last_sync_status);

                    if (App) {
                        App.$emit('integration-sync-processed');
                    }
                });
        }
    },

    methods: {
        syncWithClassy () {
            if (this.event.classyIntegration.syncing) {
                return;
            }

            this.event.classyIntegration.syncing = true;

            axios.post(this.route('api.events.classy.sync', this.event));
        }
    }
};
