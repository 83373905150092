<template>
    <div>
        <slot name="trigger" :show-modal="openModal">
            <a
                role="button"
                class="no-underline flex flex-col md:flex-row items-center p-6 md:py-4 bg-white shadow ring-2 ring-transparent hover:ring-purple-light hover:shadow-lg cursor-pointer rounded-md text-gray-800 transition ease-in-out duration-150"
                @click="openModal"
            >
                <app-icon name="monitor-code-send-stroke" class="h-12 w-12 md:h-8 md:w-8 text-gray-600"></app-icon>

                <div class="mt-2 md:mt-0 md:ml-6 flex-auto leading-normal text-center md:text-left">
                    <p class="text-lg font-semibold">
                        Embed Your Form
                        <img
                            v-if="!event.plan.features.FormEmbedding.allowed"
                            v-tippy
                            class="h-5 w-5 inline"
                            :src="asset('images/account/rsvpify-premium.svg')"
                            content="Upgrade to access this feature."
                        >
                    </p>
                    <p class="mt-4 md:m-0 md:text-sm text-gray-600">Add your registration form to another website.</p>
                </div>

                <div class="mt-4 md:mt-0 md:mr-4">
                    Get Code
                </div>

                <app-icon
                    name="arrow-right-chevron"
                    class="h-6 w-6 text-gray-500 hidden md:flex"
                    stroke
                ></app-icon>
            </a>
        </slot>

        <app-modal v-model="showModal" title="Publish event">
            <template #header>
                <div class="flex items-center">
                    <app-icon name="monitor-code-send-stroke" class="h-12 w-12 md:h-8 md:w-8 text-gray-600"></app-icon>

                    <div class="mt-2 md:mt-0 md:ml-6 flex-auto leading-normal text-center md:text-left">
                        <p class="text-lg font-semibold">Embed Your Form</p>
                        <p class="mt-4 md:m-0 md:text-sm text-gray-600 font-normal">Copy and paste this code where you want your RSVPify registration<br>or RSVP form to display.</p>
                    </div>
                </div>
            </template>

            <copy-to-clipboard
                :copy="embedCode"
                class="my-4"
                @copied="showCopiedBadge"
            >
                <div class="text-xs cursor-pointer text-gray-600 bg-gray-200 p-4 rounded-md font-mono">
                    {{ embedCode }}
                </div>

                <div v-if="displayCopiedBadge" class="badge badge-success">
                    COPIED!
                </div>
            </copy-to-clipboard>

            <template #footer="{ close }">
                <copy-to-clipboard :copy="embedCode" @copied="showCopiedBadge(close)">
                    <button class="button button-primary">
                        <app-icon name="file-copy" class="w-3 h-3"></app-icon>

                        <span class="ml-4">Copy and Close</span>
                    </button>
                </copy-to-clipboard>
            </template>
        </app-modal>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import IsEventPublished from '@/mixins/IsEventPublished';

export default {
    name: 'EmbedForm',

    mixins: [IsEventPublished],

    data () {
        return {
            displayCopiedBadge: false,
            showModal: false
        };
    },

    computed: {
        ...get('Event/*'),

        isEventTemplate () {
            return this.event.user_id === null;
        },

        embedCode () {
            const eventLink = this.route('event.show', this.event.subdomain);

            return `<script type="text/javascript" src="${eventLink}/embed"><\/script>`;
        }
    },

    methods: {
        showCopiedBadge (close) {
            this.displayCopiedBadge = true;
            setTimeout(() => { this.displayCopiedBadge = false; }, 1000);
            this.$toasted.global.success('Embed code copied to clipboard!');
            close();
        },

        openModal () {
            if (!this.event.plan.features.FormEmbedding.allowed) {
                App.alert().paymentRequired(
                    'Please upgrade.',
                    `Your ${this.event.plan.name} plan doesn't include form embedding. Please upgrade to access this feature.`
                );
                return;
            }

            this.showModal = true;
        }
    }
};
</script>
