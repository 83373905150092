/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'money-basket-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.718 5.73L2.05 7.79v-.01C1.29 8.11.94 9 1.28 9.75l2.64 5.98M12.02 15.76l10.258-4.54v-.01c.75-.34 1.1-1.22.76-1.98l-.01-.01-3.36-7.61h0c-.34-.76-1.23-1.11-1.98-.77l-5.09 2.24M3.89 18.41l1.54 4.76M9.03 18.41l.79 4.76M21.864 23.172l1.36-3.44v-.01c.12-.6-.26-1.18-.85-1.31a.908.908 0 00-.23-.03H1.83v-.001c-.61 0-1.1.49-1.1 1.1 0 .07 0 .15.02.22l1.362 3.43M20.13 18.41l-1.54 4.76M14.97 18.41l-.77 4.76"/><path pid="1" d="M10.715 11.24h0c.62.36 1.39.39 2.05.09h-.01c.96-.27 1.53-1.26 1.3-2.23h0a1.867 1.867 0 00-2.5-.64v-.01c-.87.49-1.98.21-2.5-.64l-.01-.01c-.24-.98.34-1.96 1.3-2.23v-.01a2.19 2.19 0 012.05.09M12.77 11.33l.39.95M9.99 4.67l.4.95"/></g>'
  }
})
