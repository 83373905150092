/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hospital-bedroom-nurse-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>medical</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.5.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM8.25 14.25a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM12.75 14.25h8.5-.01c1.1-.01 2 .89 2 1.99v2.5h-10.5v-4.5zM2.25 18.75h21v3h-21zM2.25 23.25v-9M23.25 23.25v-1.5M12.75 11.25h0a2.996 2.996 0 012.99-3.01h1.5-.01c1.65-.01 3 1.34 3 3M9 3.75H6.75V1.5v0c0-.42-.34-.75-.75-.75H4.49c-.42 0-.75.33-.75.75 0 0 0 0 0 0v2.25H1.48c-.42 0-.75.33-.75.75 0 0 0 0 0 0V6v-.01c-.01.41.33.75.74.75h2.25v2.25h0c0 .41.33.74.75.74h1.49-.01c.41 0 .75-.34.75-.75V6.73h2.25l-.01-.001c.41 0 .75-.34.75-.75v-1.5 0c0-.42-.34-.75-.75-.75z"/></g>'
  }
})
