<template>
    <div>
        <ul
            v-if="!disabled"
            class="border-b"
            :class="headerClass"
            role="tablist"
        >
            <li
                v-for="tab in tabs"
                :key="tab.label"
                class="hidden sm:inline-flex mr-4 -mb-px"
                role="presentation"
            >
                <a
                    role="button"
                    class="py-2 px-4 border-b-2 font-medium"
                    :class="activeTabStyles(tab)"
                    @click="activateTab(tab)"
                >
                    {{ tab.label }}
                </a>
            </li>

            <nav class="tabs-list mobile-navigation">
                <div>
                    <a
                        v-for="tab in tabs"
                        :key="tab.label"
                        role="button"
                        class="py-2 px-4 border-b-2 font-medium"
                        :class="activeTabStyles(tab)"
                        @click="activateTab(tab)"
                    >
                        {{ tab.label }}
                    </a>
                </div>
            </nav>
        </ul>

        <div :class="contentClass">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import find from 'lodash/find';

export default {
    name: 'TabList',

    provide () {
        return {
            tabListState: this.sharedState
        };
    },

    props: {
        contentClass: {
            type: [String, Array, Object],
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        headerClass: {
            type: [String, Array, Object],
            default: ''
        }
    },

    data () {
        return {
            sharedState: {
                activeId: null
            },
            tabs: []
        };
    },

    computed: {
        firstActiveTab () {
            return find(this.tabs, 'active');
        }
    },

    mounted () {
        this.tabs = this.$children;

        if (this.firstActiveTab) {
            this.activateTab(this.firstActiveTab);
        } else {
            this.activateTab(this.tabs[0]);
        }
    },

    methods: {
        activateTab (tab) {
            this.sharedState.activeId = tab.id;
            this.$emit('selected-tab', tab.id);
        },

        activeTabStyles (tab) {
            const tabIsActive = this.tabIsActive(tab);

            return {
                'border-transparent text-gray-600': !tabIsActive,
                'border-purple text-purple': tabIsActive
            };
        },

        tabIsActive (tab) {
            return tab.id === this.sharedState.activeId;
        }
    }
};
</script>
