<template>
    <portal to="modal-portal-target">
        <div v-if="isActive" class="fixed inset-0 flex justify-center items-center z-50">
            <div class="absolute inset-0 bg-black opacity-50"></div>
            <transition
                enter-class="opacity-0 scale-125"
                enter-active-class="transition-all duration-300 ease-out"
                enter-to-class="opacity-100 scale-100"
                leave-class="opacity-100 scale-100"
                leave-active-class="transition-all duration-200 ease-in"
                leave-to-class="opacity-0 scale-125"
                appear
            >
                <div class="modal-content full-screen-on-mobile full-height">
                    <div class="flex justify-center px-6 py-4 bg-gray-100 border-b font-semibold uppercase text-sm text-gray-600">
                        Form Theme Preview
                    </div>

                    <div class="flex-1 overflow-y-auto overflow-x-hidden">
                        <rsvp-form
                            :sections="formSections"
                            class="preview pointer-events-none"
                            preview
                        >
                            <template #header>
                                <img
                                    v-if="form.settings.logo_url"
                                    v-theme="'form.logo'"
                                    :src="form.settings.logo_url"
                                    alt="logo"
                                    class="block mx-auto cursor-pointer"
                                >
                            </template>
                        </rsvp-form>
                    </div>
                </div>
            </transition>
        </div>
    </portal>
</template>

<script>
import { sync } from 'vuex-pathify';
import Toggleable from '@/mixins/Toggleable';

export default {
    name: 'FormPreviewModal',

    mixins: [Toggleable],

    props: {
        formSections: {
            type: Array,
            required: true
        }
    },

    computed: {
        ...sync('Form/*')
    }
};
</script>
