/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-quarantine-calendar-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 13.78a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM16.68 11.21h1.14M17.25 11.21v2.58M21.09 12.57l.81.81M21.49 12.97l-1.82 1.82M23.25 16.64v1.15M23.25 17.21h-2.57M21.9 21.05l-.81.81M21.49 21.46l-1.82-1.82M17.82 23.21h-1.14M17.25 23.21v-2.57M13.41 21.86l-.81-.81M13.01 21.46l1.82-1.82M11.25 17.79v-1.15M11.25 17.21h2.57M12.6 13.38l.81-.81M13.01 12.97l1.82 1.82M7.965 17.83h-5.67l-.01-.001a1.56 1.56 0 01-1.55-1.56V3.86L.73 3.85c-.01-.86.69-1.56 1.55-1.56h13.97-.01c.85-.01 1.55.69 1.55 1.55V8.2"/></g><g stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="1" d="M.76 6.99h17.08"/><g stroke-linecap="round"><path pid="2" d="M5.41 3.89V.79M13.17 3.89V.79"/></g></g>'
  }
})
