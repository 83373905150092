/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-euro-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>shopping money</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.293 16.5c-1.98-1.98-1.98-4.16 0-6.131 1.97-1.98 4.15-1.98 6.131 0M10.29 8.33l4.09 4.08M8.25 10.37l3.06 3.06M18.24 4a1.44 1.44 0 100 2.89 1.44 1.44 0 100-2.89z"/><path pid="1" d="M21.705.75h-9.38l-.01-.001c-.43-.01-.84.17-1.13.48L1.14 11.89h-.01c-.59.61-.56 1.58.06 2.17l.04.04 10.03 8.749-.01-.01c.62.54 1.57.49 2.14-.11l9.37-10.017h-.01c.26-.29.41-.67.41-1.06V2.25h0c-.01-.86-.7-1.55-1.55-1.55h-.01z"/></g>'
  }
})
