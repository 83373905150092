<template>
    <div class="flex items-center mb-2">
        <button @click="toggleAlert">
            <app-icon
                :name="value ? 'alarm-bell-stroke' : 'alarm-bell-off-stroke'"
                class="h-5 w-5 mr-2"
                :class="value ? 'text-purple' : 'text-gray-400'"
            ></app-icon>
        </button>

        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FeatureLabel',

    props: {
        value: {
            type: Boolean,
            default: null
        }
    },

    methods: {
        toggleAlert () {
            this.$emit('input', !this.value);
        }
    }
};
</script>
