/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>event date time appointment</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="22.5" height="19.5" x=".75" y="3.75" rx="1.5"/><path pid="1" d="M.75 9.75h22.5M6.75 6V.75M17.25 6V.75M5.62 13.5h0M5.62 13.5h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375M5.62 18.75h0M5.62 18.75h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375M12 13.5h0M12 13.5h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.38-.38-.375M12 18.75h0M12 18.75h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.38-.38-.375M18.38 13.5h0M18.375 13.5h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375M18.38 18.75h0M18.375 18.75h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375"/></g>'
  }
})
