/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-carrier-blood-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.88 3.81a3.51 3.51 0 100 7.03 3.51 3.51 0 100-7.04zM16.3 1.18h1.17M16.88 1.18v2.64M20.82 2.57l.83.83M21.23 2.98l-1.86 1.87M23.04 6.75v1.17M23.04 7.33H20.4M21.65 11.27l-.83.83M21.23 11.69l-1.86-1.87M17.47 13.49H16.3M16.88 13.49v-2.64M12.94 12.1l-.83-.83M12.53 11.69l1.86-1.87M10.73 7.92V6.75M10.73 7.33h2.63M12.11 3.4l.83-.83M12.53 2.98l1.86 1.87M14.836 16.31v0a6.94 6.94 0 01-13.88 0c0-5 4.57-12.55 6.292-15.22v-.01c.22-.36.7-.46 1.05-.24.09.05.17.13.23.23l.76 1.288"/><path pid="1" d="M7.9 20.167l-.01-.001c-2.13-.01-3.86-1.73-3.86-3.86v-.001"/></g>'
  }
})
