/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down-thick-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sort descending</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.85.75h-.01c-1.25 0-2.25 1-2.25 2.25 0 0 0 0 0 0v12.56L6.53 12.5l-.01-.01c-.88-.88-2.31-.88-3.19 0-.88.87-.88 2.3 0 3.18l6.9 6.894-.01-.01c.87.87 2.3.87 3.18 0v-.01l6.88-6.9v-.01c.87-.88.87-2.31-.01-3.19-.88-.88-2.31-.88-3.19 0l-3.06 3.05V2.91v0c0-1.25-1.01-2.25-2.25-2.25z"/>'
  }
})
