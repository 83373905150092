/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alarm-bell-off-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>alarm bell notification</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M22.501 2.25l-21 21M10 21.75a2.087 2.087 0 004.005 0M12.001 3V.75M10.5 18.75H21s-1.5-1.2-1.5-8.25a7.271 7.271 0 00-.093-.915M16.212 4.3A7.5 7.5 0 004.5 10.5v5.25"/>'
  }
})
