import filter from 'lodash/filter';
import find from 'lodash/find';
import { guestPassesAskIf } from '@/util/ask-if';

function guestPassesAdditionalCriteria (additionalCriteria, guest, parentGuest = null) {
    if (!additionalCriteria) {
        return true;
    }

    const { target } = additionalCriteria;

    if (target === 'none') {
        return true;
    }

    if (target === 'tagged') {
        const { value: tagId } = additionalCriteria.query.tagIds;
        let { invitee } = guest;

        if (invitee === null && additionalCriteria.includeAdditionalGuests && parentGuest) {
            ({ invitee } = parentGuest);
        }

        if (!invitee) {
            return false;
        }

        return find(invitee.tags, { id: tagId }) !== undefined;
    }

    return false;
}

function groupPassesAdditionalCriteria (additionalCriteria, guests) {
    if (!additionalCriteria) {
        return true;
    }

    const filteredGuests = filter(guests, (guest) => {
        return guestPassesAdditionalCriteria(additionalCriteria, guest);
    });

    return filteredGuests.length > 0;
}

function getGuestsPassingCriteria (askIf, additionalCriteria, submission) {
    const guests = submission.guests || [];

    return filter(guests, (guest) => {
        const parentGuest = guest.parent_invitee_id ? find(guests, { invitee_id: guest.parent_invitee_id }) : null;
        return guestPassesAskIf(askIf, guest) && guestPassesAdditionalCriteria(additionalCriteria, guest, parentGuest);
    });
}

export {
    guestPassesAdditionalCriteria,
    groupPassesAdditionalCriteria,
    getGuestsPassingCriteria
};
