/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'house-nature-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>home yard</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.25 15.75v7.5M2.25 23.25v-7.5M.75 16.5l6-4.5 6 4.5M21.75 17.25v0c0 1.65-1.35 3-3 3-1.66 0-3-1.35-3-3 0-4.5 3-10.5 3-10.5s3 6 3 10.5zM18.75 20.25v3M.75 23.25h22.5M5.25 23.25v-3h0a1.496 1.496 0 112.99-.02s0 0 0 0v3M8.25 6.75h0c1.24 0 2.25-1 2.25-2.25s-1-2.26-2.25-2.26c-.62-.01-1.22.25-1.64.69l-.01-.01A2.99 2.99 0 002.91.84C1.31 1.28.38 2.93.83 4.52A2.98 2.98 0 003.72 6.7z"/></g>'
  }
})
