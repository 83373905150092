/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-quarantine-house-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.017.768h-3.04M10.5.77v2.6M4.678 2.55L3.606 3.62 2.533 4.69M3.61 3.62l1.83 1.84M.75 9.001v3.026M.75 10.52h2.6M2.533 16.33l1.073 1.07 1.072 1.07M3.61 17.41l1.83-1.84M18.467 4.696l-1.08-1.08-1.08-1.08M17.39 3.62l-1.83 1.84M9.41 12.61a.43.43 0 100 .86.43.43 0 100-.87zM8.98 6.83a1.51 1.51 0 100 3.03 1.51 1.51 0 100-3.04z"/><path pid="1" d="M16.229 6.24l-.01-.01A7.164 7.164 0 006.2 4.77a7.14 7.14 0 00-1.46 10 7.186 7.186 0 003.7 2.58"/><path pid="2" d="M17.608 9.757h0c-.05-.44-.14-.86-.26-1.28v0h0c-.24-.81-.63-1.57-1.13-2.25M12.75 15.93v5.8h0c0 .82.67 1.49 1.5 1.49h6-.01c.82 0 1.5-.68 1.5-1.5v-5.8"/><path pid="3" d="M11.25 17.243l4.51-3.96v-.01c.84-.75 2.11-.75 2.96 0l4.51 3.95M18.75 23.232h-3v-3h0a1.496 1.496 0 112.99-.02s0 0 0 0z"/></g>'
  }
})
