/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'religion-mitzvah-star-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.633 1.1h0a.75.75 0 00-1.04-.23c-.1.05-.18.13-.23.22l-9.75 15h-.01a.736.736 0 00.62 1.14h19.5-.01a.75.75 0 00.74-.76.934.934 0 00-.12-.41z"/><path pid="1" d="M12.633 22.9v-.01c-.23.34-.69.45-1.04.22a.633.633 0 01-.23-.23l-9.75-15-.01-.01a.746.746 0 01.23-1.04c.11-.08.25-.12.39-.12h19.5l-.01-.001c.41 0 .74.33.74.75a.89.89 0 01-.12.39z"/></g>'
  }
})
