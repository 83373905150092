/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-basket-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>cart</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.936 20.625H5.064h0c-.67-.02-1.25-.49-1.39-1.14l-2.14-9h0c-.22-.8.26-1.61 1.05-1.82.1-.03.21-.05.32-.05h18.14c.81.02 1.46.71 1.43 1.53-.01.11-.03.22-.05.32l-2.14 9h-.01c-.15.65-.72 1.12-1.39 1.13zM4.5 8.62L9 3.38M19.5 8.62L15 3.38"/></g>'
  }
})
