<script>
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import ResendsConfirmationEmails from '@/mixins/ResendsConfirmationEmails';

export default {
    mixins: [
        ResendsConfirmationEmails
    ],

    methods: {
        mapGuestsToBulkActionData (guests) {
            const recipients = guests.filter(({ email }) => {
                return !isEmpty(email);
            });

            return {
                all: false,
                ids: map(recipients, 'id')
            };
        },

        resend (guests) {
            this.confirmResend().then(() => {
                return this.makeResendRequest(
                    this.mapGuestsToBulkActionData(guests)
                );
            });
        }
    },

    render () {
        return this.$scopedSlots.default({
            resend: this.resend
        });
    }
};
</script>
