<template>
    <form-field-wrapper label="Format">
        <select-list :value="settings.format" @input="newFormat => updateSetting('format', newFormat)">
            <select-option
                id="twelveHour"
                class="my-2"
                label="Date & Time"
            ></select-option>

            <select-option
                id="twentyFourHour"
                class="my-2"
                label="Date & Time (24 Hr)"
            ></select-option>

            <select-option
                id="dateOnly"
                class="my-2"
                label="Date Only"
            ></select-option>
        </select-list>
    </form-field-wrapper>
</template>

<script>
import CustomQuestionSettings from '@/mixins/CustomQuestionSettings';

export default {
    mixins: [CustomQuestionSettings],

    computed: {
        defaultSettings () {
            return {
                format: 'twelveHour'
            };
        },

        namespace () {
            return 'dateTime';
        }
    }
};
</script>
