<template>
    <div class="hidden lg:flex">
        <a
            v-tippy="{theme: 'light bold', content: 'Refer a friend, get a free month!'}"
            class="flex mt-5"
            :href="route('account.refer-a-friend')"
        >
            <app-icon name="gift-stroke" class="h-5 w-5"></app-icon>
        </a>

        <nav-events :events="events"></nav-events>
        <nav-user :user="user"></nav-user>
    </div>
</template>

<script>
export default {
    props: {
        user: {
            type: Object,
            default: () => { return ([]); }
        },
        events: {
            type: Array,
            default: () => { return ([]); }
        }
    }
};
</script>
