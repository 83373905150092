/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-beer-glass-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>brew pub bar alcohol</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15 23.25H9L5.27 2.5l-.01-.01c-.14-.82.41-1.6 1.22-1.73.08-.02.16-.03.25-.03h10.5-.01c.82-.01 1.5.67 1.5 1.49 0 .08-.01.16-.03.25zM6 23.25h12M5.76 5.25h12.48"/><path pid="1" d="M12 9.75a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/></g>'
  }
})
