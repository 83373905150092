<template>
    <div>
        <party-details-modal
            v-if="shouldShowGuestModal"
            v-model="isActive"
            :initial-submission="submission"
            @saved="updateSeatables"
        ></party-details-modal>

        <invitee-group-modal
            v-if="shouldShowInviteeModal"
            v-model="isActive"
            :initial-group="inviteeGroup"
            :default-invitee="defaultInvitee"
            :is-invite-list-empty="false"
            :should-refresh="false"
            @saved="updateSeatables"
        ></invitee-group-modal>

        <div v-if="displayLoading" class="flex items-center justify-center absolute inset-0 bg-black opacity-50 z-50">
            <app-icon name="loader" class="w-20 h-20 mb-8 text-gray-300"></app-icon>
        </div>
    </div>
</template>

<script>
import { call, get } from 'vuex-pathify';
import axios from '@/util/axios';
import Toggleable from '@/mixins/Toggleable';

export default {
    name: 'SeatableDetailsModal',

    mixins: [Toggleable],

    props: {
        seatable: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            defaultInvitee: {
                title_id: null, firstName: '', lastName: '', email: '', additionalGuestsLimit: null, additionalGuestsType: 'unlimited', tags: []
            },
            submission: null,
            inviteeGroup: null,
            modalToShow: null
        };
    },

    computed: {
        ...get('Event/*'),

        displayLoading () {
            return this.isActive && !this.shouldShowInviteeModal && !this.shouldShowGuestModal;
        },

        isSeatableGuest () {
            return this.seatable.type && this.seatable.type.includes('Guest');
        },

        isSeatableInvitee () {
            return this.seatable.type && this.seatable.type.includes('Invitee');
        },

        shouldShowGuestModal () {
            return this.isSeatableGuest && this.submission;
        },

        shouldShowInviteeModal () {
            return this.isSeatableInvitee && this.inviteeGroup;
        }
    },

    watch: {
        value (val) {
            if (!val) {
                this.restoreModalDetails();
            }

            if (val) {
                this.retrieveModalDetails();
            }

            this.isActive = !!val;
        }
    },

    methods: {
        refreshSeatables: call('Seating/loadSeatables'),

        restoreModalDetails () {
            this.submission = null;
            this.inviteeGroup = null;
        },

        retrieveGuestModalDetails () {
            axios.get(this.route('api.events.submissions.show', [this.event, this.seatable.group_id]))
                .then(({ data }) => {
                    this.submission = data.data;
                }).catch(() => {
                    this.$toasted.global.error(`There was an error retrieving this submission.`);
                    this.isActive = false;
                });
        },

        retrieveInviteeModalDetails () {
            axios.get(this.route('api.events.invitee-groups.show', [this.event, this.seatable.group_id]))
                .then(({ data }) => {
                    this.inviteeGroup = data.data;
                }).catch(() => {
                    this.$toasted.global.error(`There was an error retrieving this invitee group.`);
                    this.isActive = false;
                });
        },

        retrieveModalDetails () {
            if (this.isSeatableInvitee) {
                this.retrieveInviteeModalDetails();
            }

            if (this.isSeatableGuest) {
                this.retrieveGuestModalDetails();
            }
        },

        updateSeatables () {
            this.refreshSeatables(this.$store.get('Seating/seatingChart.id'));
            this.isActive = false;
        }
    }

};
</script>
