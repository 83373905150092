import { get } from 'vuex-pathify';

export default {
    computed: {
        ...get('Wall/wall@', {
            isWallPublished: 'isPublished'
        }),

        ...get('Form/form@', {
            isFormPublished: 'isPublished'
        }),

        isEventPublished () {
            return this.isWallPublished || this.isFormPublished;
        }
    }
};
