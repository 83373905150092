/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'like-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>thumb up</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/><path pid="1" d="M7.5 16.4l-.01-.01c.3 0 .61.04.9.12l2.12.58-.01-.01c.31.08.63.13.96.13h2.84l-.01-.01c1.77.02 3.27-1.31 3.48-3.07l.17-2.89v-.01c.05-1.24-.79-2.33-1.99-2.6l-.85-.19-.01-.01a.865.865 0 01-.67-.83V5.67h0c-.03-.73-.63-1.3-1.35-1.28a1.3 1.3 0 00-1.28 1.27v.88h-.01c-.04 2.38-2 4.28-4.38 4.24z"/><path pid="2" d="M5.8 9.75h1.7v7.5H5.8"/></g>'
  }
})
