<template>
    <div class="w-full mx-auto p-4 md:max-w-xl text-center tracking-wider space-y-8">
        <img class="max-w-xs mx-auto" :src="asset('images/account/cancelled.png')">
        <h1 class="text-2xl font-bold">Your subscription has been canceled</h1>
        <p class="text-gray-600 text-sm">You'll still have access to your events and data until {{ expiryDate }} </p>
        <a :href="route('account.show')" class="button button-primary">Go to Dashboard</a>
        <resume-plan :plan="plan" :resume-date="expiryDate">
            <template #default="{ confirm }">
                <button
                    class="m-auto text-sm font-semibold transition duration-150 ease-in-out hover:text-purple cursor-pointer"
                    @click="confirm"
                >Undo cancellation and resume subscription</button>
            </template>
        </resume-plan>
    </div>
</template>

<script>
export default {
    name: 'CancelPlanConfirmation',

    props: {
        plan: {
            type: Object,
            required: true
        },

        expiryDate: {
            type: String,
            required: true
        }
    }
};
</script>
