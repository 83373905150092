/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-4-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.25 12.22a3.71 3.71 0 100 7.42 3.71 3.71 0 100-7.43zM6.63 9.44h1.24M7.25 9.44v2.78M11.41 10.9l.87.88M11.85 11.34l-1.97 1.97M13.75 15.32v1.24M13.75 15.94h-2.79M12.28 20.09l-.87.88M11.85 20.53l-1.97-1.97M7.87 22.44H6.63M7.25 22.44v-2.79M3.09 20.97l-.87-.88M2.65 20.53l1.97-1.97M.75 16.56v-1.24M.75 15.94h2.79M2.22 11.78l.87-.88M2.65 11.34l1.97 1.97M18.5 3.59a2.71 2.71 0 100 5.42 2.71 2.71 0 100-5.43zM18.05 1.56h.9M18.5 1.56V3.6M21.54 2.63l.64.64M21.86 2.95l-1.44 1.44M23.25 5.86v.91M23.25 6.31h-2.04M22.18 9.35l-.64.64M21.86 9.67l-1.44-1.44M18.95 11.06h-.9M18.5 11.06V9.03M15.46 9.99l-.64-.64M15.14 9.67l1.44-1.44M13.75 6.77v-.91M13.75 6.31h2.04M14.82 3.27l.64-.64M15.14 2.95l1.44 1.44"/></g>'
  }
})
