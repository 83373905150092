/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wedding-arch-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25 23.25v-8.26c0-3.35-1.49-6.51-4.06-8.65M4.432 6.676v-.01a11.196 11.196 0 00-3.692 8.32v8.25M16.883 1.48h0c-.9-.95-2.4-.98-3.34-.09-.03.02-.06.05-.09.08l-1.47 1.51-1.47-1.52h0c-.9-.95-2.4-.98-3.34-.09-.03.02-.06.05-.09.08h-.01c-.95.98-.95 2.54 0 3.53l4.36 4.51h0c.27.28.72.29 1.01.02 0-.01.01-.02.02-.03l4.36-4.511h-.01c.94-.99.94-2.55 0-3.54zM15.75 16.5h3M17.25 15v3M20.25 2.25h3M21.75.75v3M4.5 13.5h3M6 12v3"/></g>'
  }
})
