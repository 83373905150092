/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-halloween-calendar-ghost-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>spooky scary haunted</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.125 16.48h-.01c.2-.01.37.16.37.37"/><path pid="1" d="M9.75 16.86h0c-.01-.21.16-.38.37-.38M10.125 17.235h-.01a.386.386 0 01-.38-.38s0 0 0 0M10.5 16.86v0c0 .2-.17.375-.375.375M13.87 16.48h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M13.5 16.86h0c-.01-.21.16-.38.37-.38M13.87 17.235h-.01a.386.386 0 01-.38-.38s0 0 0 0M14.25 16.86v0c0 .2-.17.375-.375.375"/><path pid="3" d="M3.75 23.25h-.76a1.51 1.51 0 01-1.5-1.5s0 0 0 0V5.25h0c-.01-.83.67-1.51 1.49-1.51h18-.01c.82-.01 1.5.67 1.5 1.5v16.5c0 .82-.68 1.5-1.5 1.5h-.75M1.5 9.75h21M7.5 6V.75M16.5 6V.75"/><path pid="4" d="M16.5 21.864v-.01c-.01.41-.34.74-.76.74a.794.794 0 01-.53-.22l-.44-.44-.01-.01c-.3-.3-.77-.3-1.06-.01h-.01l-1.19.96h-.01c-.3.29-.77.29-1.06 0l-.01-.01-1.19-.97-.01-.01c-.3-.3-.77-.3-1.06-.01h-.01l-.44.43v-.01c-.3.29-.77.29-1.07-.01a.742.742 0 01-.22-.53v-4.63h0a4.494 4.494 0 014.49-4.51v0h-.01c2.48-.01 4.5 2.01 4.5 4.49z"/></g>'
  }
})
