/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'app-link-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>window browser hyper chain</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.76 8.253v-6 0a1.5 1.5 0 00-1.5-1.5h-18 0a1.5 1.5 0 00-1.5 1.5s0 0 0 0v16.5h0a1.5 1.5 0 001.5 1.5h6M.76 5.25h21M17.265 19.382h0a2.282 2.282 0 01-.639 1.92l-1.292 1.292h0a2.416 2.416 0 01-3.415-3.414l1.292-1.292h0a2.276 2.276 0 011.878-.644M19.388 17.258h0a2.282 2.282 0 001.921-.639l1.291-1.292h0a2.416 2.416 0 00-3.414-3.414L17.9 13.205h0a2.272 2.272 0 00-.644 1.878M15.01 19.5l4.5-4.5"/></g>'
  }
})
