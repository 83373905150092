/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>event date time appointment</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="22.5" height="19.5" x=".752" y="3.75" rx="1.5"/><path pid="1" d="M.75 9.75h22.5M6.75 6V.75M17.25 6V.75"/></g>'
  }
})
