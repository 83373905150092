/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-beer-half-glass': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>brew pub bar alcohol</desc><path pid="0" _fill="currentColor" d="M12 0C9.413 0 3.5.42 3.5 3c0 .343 1.667 5.65 3.628 12.154a6.609 6.609 0 01-.864 5.513 1.942 1.942 0 00-.143 1.807 1.986 1.986 0 001.4 1.142A22.587 22.587 0 0012 24a22.575 22.575 0 004.478-.385 2.041 2.041 0 001.406-1.152 1.938 1.938 0 00-.148-1.8l-.009-.015a6.587 6.587 0 01-.859-5.483C18.838 8.633 20.5 3.344 20.5 3c0-2.58-5.913-3-8.5-3zm-1.5 21.5a1 1 0 111-1 1 1 0 01-1 1zM11 15a1 1 0 111-1 1 1 0 01-1 1zm2.5 3.5a1 1 0 111-1 1 1 0 01-1 1zm2.787-8.334a.5.5 0 01-.318.329A13.25 13.25 0 0112 11a13.25 13.25 0 01-3.969-.5.5.5 0 01-.318-.329.034.034 0 01.02-.043A11.675 11.675 0 0112 9.5a11.737 11.737 0 014.266.621.037.037 0 01.021.045zm.739-2.44a.5.5 0 01-.628.332A15.726 15.726 0 0012 7.5a15.726 15.726 0 00-4.4.558.5.5 0 01-.628-.332L6.38 5.76a.25.25 0 01.3-.315A24.934 24.934 0 0012 6a24.934 24.934 0 005.322-.555.25.25 0 01.3.315zM12 4a17.991 17.991 0 01-5.74-.765.249.249 0 010-.47A17.991 17.991 0 0112 2a17.991 17.991 0 015.74.765.249.249 0 010 .47A17.991 17.991 0 0112 4z"/>'
  }
})
