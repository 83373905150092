<template>
    <div class="w-full space-y-2">
        <div
            v-tippy
            class="w-full"
            :content="buttonTooltip"
        >
            <stateful-button
                class="button button-primary w-full"
                :disabled="hasRetryFailed"
                :loading="processing"
                @click="retryCharge"
            >Retry charge with card ending in {{ cardLastFour }}</stateful-button>
        </div>

        <update-credit-card
            ref="updateCreditCardButton"
            button-class="button button-outline w-full"
            :card-brand="cardBrand"
            :card-last-four="cardLastFour"
            :setup-intent="setupIntent"
        ></update-credit-card>
    </div>
</template>

<script>
import axios from '@/util/axios';

export default {
    name: 'RetryLatestInvoice',

    props: {
        cardBrand: {
            type: String,
            required: true
        },

        cardLastFour: {
            type: String,
            required: true
        },

        setupIntent: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            processing: false,
            hasRetryFailed: false
        };
    },

    computed: {
        buttonTooltip () {
            if (!this.hasRetryFailed) {
                return null;
            }

            return 'You\'ve already retried the charge and the attempt failed. Please update your credit card instead.';
        }
    },

    methods: {
        async retryCharge () {
            this.processing = true;

            try {
                await axios.post(this.route('api.account.retry-latest-invoice'));

                this.showSuccessAlert();
            } catch (error) {
                this.hasRetryFailed = true;

                if (error.response.data.type === 'card') {
                    this.showFailureAlert(error.response.data.declineCode);
                    return;
                }

                this.$toasted.global.error('There was a problem retrying the latest invoice.');
            } finally {
                this.processing = false;
            }
        },

        showFailureAlert (declineCode) {
            App.alert().error(
                '',
                '',
                {
                    confirmButtonText: 'Update Credit Card',
                    customClass: {
                        cancelButton: 'button',
                        confirmButton: 'button button-primary mr-2'
                    },
                    html: `Sorry, we still weren't able to successfully process this transaction using the card ending in ${this.cardLastFour}. Your bank returned the error code: ${declineCode}.<br><br>Please update your credit card information.`,
                    showCancelButton: true
                },
                () => {
                    this.$refs.updateCreditCardButton.launchModal();
                }
            );
        },

        showSuccessAlert () {
            App.alert().success(
                '',
                'We\'ve successfully renewed your RSVPify subscription. You\'re all set!',
                {
                    confirmButtonText: 'Continue',
                    onClose () {
                        window.location.reload();
                    }
                }
            );
        }
    }
};
</script>
