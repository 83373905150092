<template>
    <predicate-list
        :filterable-fields="filterableFields"
        v-bind="$attrs"
        v-on="$listeners"
    ></predicate-list>
</template>

<script>
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import { call, get } from 'vuex-pathify';

export default {
    props: {
        blockableId: {
            type: Number,
            default: null
        }
    },

    computed: {
        addons () {
            return filter(this.event.products, { category: 'add-on' });
        },

        loadedTags: get('Tags/loadedTags'),
        event: get('Event/event'),

        filterableFields () {
            const fields = [];

            if (this.primaryEvent && this.event.setup_type === 'rsvp') {
                fields.push(this.replyField());
            } else if (this.primaryEvent && this.event.setup_type === 'tickets') {
                fields.push(this.ticketField());
            } else if (!this.primaryEvent) {
                fields.push(this.secondaryReplyField());
            }

            return [
                ...fields,
                this.addonField(),
                this.tagField(),
                this.emailField(),
                this.nameField(),
                this.checkInStateField()
            ];
        },

        primaryEvent () {
            return this.blockableId === null;
        },

        products () {
            return reject(this.event.products, { category: 'add-on' });
        }
    },

    created () {
        this.loadTags();
    },

    methods: {
        addonField () {
            const addonPickerItems = this.addons.map(({ id, title }) => {
                return { label: title, value: id };
            });

            return {
                slug: 'addons',
                name: 'Add-on',
                icon: 'add-circle-stroke',
                comparisons: [
                    {
                        slug: 'has',
                        label: 'is',
                        summaryGenerator: (value) => { return `is ${this.findLabelByValue(addonPickerItems, value)}`; },
                        input: 'picker',
                        items: addonPickerItems
                    },
                    {
                        slug: 'doesnt-have',
                        label: 'is not',
                        summaryGenerator: (value) => { return `isn't ${this.findLabelByValue(addonPickerItems, value)}`; },
                        input: 'picker',
                        items: addonPickerItems
                    },
                    {
                        slug: 'empty-relation',
                        label: 'has no value',
                        summaryGenerator: () => { return 'has no value'; }
                    }
                ]
            };
        },

        emailField () {
            return {
                slug: 'email',
                name: 'Email address',
                icon: 'email-envelope-stroke',
                comparisons: [
                    {
                        slug: 'contains',
                        label: 'contains',
                        summaryGenerator: (value) => { return `contains ${value}`; },
                        input: 'text'
                    },
                    {
                        slug: 'not-contains',
                        label: 'does not contain',
                        summaryGenerator: (value) => { return `doesn't contain ${value}`; },
                        input: 'text'
                    },
                    {
                        slug: 'empty',
                        label: 'has no value',
                        summaryGenerator: (value) => { return 'has no value'; }
                    }
                ]
            };
        },

        findLabelByValue (items, value) {
            return items.find((item) => { return item.value === value; }).label;
        },

        loadTags: call('Tags/loadTags'),

        nameField () {
            return {
                slug: 'name',
                name: 'Name',
                icon: 'user-alt-search-stroke',
                comparisons: [
                    {
                        slug: 'contains',
                        label: 'contains',
                        summaryGenerator: (value) => { return `contains ${value}`; },
                        input: 'text'
                    },
                    {
                        slug: 'not-contains',
                        label: 'does not contain',
                        summaryGenerator: (value) => { return `doesn't contain ${value}`; },
                        input: 'text'
                    }
                ]
            };
        },

        replyField () {
            const replyPickerItems = [
                { value: 'attending', label: 'Attending' },
                { value: 'not-attending', label: 'Not attending' },
                { value: 'maybe', label: 'Maybe' }
            ];

            return {
                slug: 'reply',
                name: 'Reply',
                icon: 'messages-user-check-stroke',
                comparisons: [
                    {
                        slug: 'state-eq',
                        label: 'is',
                        summaryGenerator: (value) => { return `is ${this.findLabelByValue(replyPickerItems, value)}`; },
                        input: 'picker',
                        items: replyPickerItems
                    },
                    {
                        slug: 'state-neq',
                        label: 'is not',
                        summaryGenerator: (value) => { return `isn't ${this.findLabelByValue(replyPickerItems, value)}`; },
                        input: 'picker',
                        items: replyPickerItems
                    }
                ]
            };
        },

        tagField () {
            const fetchFunction = async (searchTerm = '') => {
                await this.loadTags({ name: searchTerm });

                return this.loadedTags.map(({ id, name }) => {
                    return { label: name, value: id };
                });
            };

            return {
                slug: 'tags',
                name: 'Tag',
                icon: 'tag-stroke',
                comparisons: [
                    {
                        slug: 'has',
                        label: 'is',
                        summaryGenerator: (value, label) => { return `is ${label}`; },
                        input: 'picker',
                        isFilterable: true,
                        fetchFunction
                    },
                    {
                        slug: 'doesnt-have',
                        label: 'is not',
                        summaryGenerator: (value, label) => { return `isn't ${label}`; },
                        input: 'picker',
                        isFilterable: true,
                        fetchFunction
                    },
                    {
                        slug: 'empty-relation',
                        label: 'has no tags',
                        summaryGenerator: () => { return 'is empty'; }
                    }
                ]
            };
        },

        ticketField () {
            const tickets = filter(this.products, { type: 'primary' });
            const ticketPickerItems = tickets.map(({ id, title }) => {
                return { label: title, value: id };
            });

            return {
                slug: 'tickets',
                name: 'Ticket/Selection',
                icon: 'ticket-stroke',
                comparisons: [
                    {
                        slug: 'has',
                        label: 'is',
                        summaryGenerator: (value) => { return `is ${this.findLabelByValue(ticketPickerItems, value)}`; },
                        input: 'picker',
                        items: ticketPickerItems
                    },
                    {
                        slug: 'doesnt-have',
                        label: 'is not',
                        summaryGenerator: (value) => { return `isn't ${this.findLabelByValue(ticketPickerItems, value)}`; },
                        input: 'picker',
                        items: ticketPickerItems
                    },
                    {
                        slug: 'empty-relation',
                        label: 'is not attending',
                        summaryGenerator: () => { return 'is not attending'; }
                    }
                ]
            };
        },

        secondaryReplyField () {
            const secondaryReplies = filter(this.products, { blockable_id: this.blockableId });
            const secondaryReplPickerItems = secondaryReplies.map(({ id, title }) => {
                return { label: title, value: id };
            });

            return {
                slug: 'secondary-reply',
                name: 'Secondary Event Reply',
                icon: 'messages-user-check-stroke',
                comparisons: [
                    {
                        slug: 'has',
                        label: 'is',
                        summaryGenerator: (value) => { return `is ${this.findLabelByValue(secondaryReplPickerItems, value)}`; },
                        input: 'picker',
                        items: secondaryReplPickerItems
                    },
                    {
                        slug: 'doesnt-have',
                        label: 'is not',
                        summaryGenerator: (value) => { return `isn't ${this.findLabelByValue(secondaryReplPickerItems, value)}`; },
                        input: 'picker',
                        items: secondaryReplPickerItems
                    }
                ]
            };
        },

        checkInStateField () {
            const checkInStateItems = [
                { value: 'Unseen', label: 'Unseen' },
                { value: 'CheckedIn', label: 'Checked In' },
                { value: 'CheckedOut', label: 'Checked Out' }
            ];

            return {
                slug: 'check_in_state',
                name: 'Check In State',
                icon: 'messages-user-check-stroke',
                comparisons: [
                    {
                        slug: 'state-eq',
                        label: 'is',
                        summaryGenerator: (value) => { return `is ${this.findLabelByValue(checkInStateItems, value)}`; },
                        input: 'picker',
                        items: checkInStateItems
                    },
                    {
                        slug: 'state-neq',
                        label: 'is not',
                        summaryGenerator: (value) => { return `isn't ${this.findLabelByValue(checkInStateItems, value)}`; },
                        input: 'picker',
                        items: checkInStateItems
                    }
                ]
            };
        }
    }
};
</script>
