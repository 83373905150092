/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bread-wheat-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6 23.24V.74M1.5 17.98l-.01-.01a4.786 4.786 0 004.5 3h0c1.97.02 3.76-1.17 4.5-3M1.5 12.73l-.01-.01a4.786 4.786 0 004.5 3h0c1.97.02 3.76-1.17 4.5-3M1.5 7.48l-.01-.01a4.786 4.786 0 004.5 3h0c1.97.02 3.76-1.17 4.5-3M1.5 2.23l-.01-.01a4.786 4.786 0 004.5 3h0c1.97.02 3.76-1.17 4.5-3M18 23.24V.74M13.5 17.98l-.01-.01a4.864 4.864 0 008.99-.01M13.5 12.73l-.01-.01a4.864 4.864 0 008.99-.01M13.5 7.48l-.01-.01a4.864 4.864 0 008.99-.01M13.5 2.23l-.01-.01a4.864 4.864 0 008.99-.01"/></g>'
  }
})
