<template>
    <base-dropdown
        ref="dropdown"
        class="inline-block"
        placement="bottom-start"
        trigger-class="flex items-center"
    >
        <template slot="trigger" slot-scope="{ triggerFunction }">
            <button
                class="menu-button relative"
                @click="triggerFunction"
            >
                <app-icon name="cursor-double-click-stroke" class="h-5 w-5"></app-icon>
            </button>
        </template>

        <template #default="{ triggerFunction }">
            <div v-bar="{ useScrollbarPseudo: true }" class="bg-black border-b-2 border-purple-light rounded-xl py-2 px-1 w-48 -ml-2 mt-2">
                <div>
                    <button
                        class="w-full px-2 py-1 text-white hover:bg-smoke-300 rounded-xl text-cursor text-sm"
                        @click="insertButton('event_link', triggerFunction)"
                    >
                        Link to Event
                    </button>
                    <button
                        class="w-full px-2 py-1 text-white hover:bg-smoke-300 rounded-xl text-cursor text-sm"
                        @click="insertButton('preselection', triggerFunction)"
                    >
                        Attending / Not Attending
                    </button>
                    <button
                        v-if="allowCustomButton"
                        class="w-full px-2 py-1 text-white hover:bg-smoke-300 rounded-xl text-cursor text-sm"
                        @click="insertButton('custom', triggerFunction)"
                    >
                        Custom Button
                    </button>
                </div>
            </div>
        </template>
    </base-dropdown>
</template>

<script>
import tinycolor from 'tinycolor2';
import get from 'lodash/get';

export default {
    name: 'EditorInsertEmailButton',

    props: {
        defaultButtonOptions: {
            type: Object,
            default: () => { return {}; }
        },

        editor: {
            type: Object,
            required: true
        },

        themeSettings: {
            type: Object,
            required: true
        },

        allowCustomButton: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        insertButton (type, toggleDropdown) {
            const buttonColor = tinycolor(this.themeSettings.colors.button).toHexString();
            const textColor = tinycolor(this.themeSettings.colors.buttonText).toHexString();
            const borderColor = buttonColor;

            this.editor.commands.emailButton({
                buttonData: encodeURIComponent(JSON.stringify({
                    align: 'justify-center',
                    text: get(this.defaultButtonOptions, 'text', 'Click Here'),
                    attendingText: get(this.defaultButtonOptions, 'attendingText', `I'll be there!`),
                    notAttendingText: get(this.defaultButtonOptions, 'notAttendingText', `Can't Make It`),
                    link: '',
                    textColor,
                    buttonColor,
                    borderColor,
                    borderRadius: 3,
                    showFirstOnMobile: 'Wall'
                })),
                type
            });

            this.editor.blur();

            this.$emit('insert-button');

            toggleDropdown();
        }
    }
};
</script>
