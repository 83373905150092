/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-vaccine-shield-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.125 6.91a3.71 3.71 0 100 7.42 3.71 3.71 0 100-7.43zM11.51 4.12h1.23M12.12 4.12v2.79M16.28 5.59l.88.88M16.72 6.03L14.75 8M18.62 10.01v1.23M18.62 10.62h-2.78M17.16 14.78l-.88.88M16.72 15.22l-1.97-1.97M12.74 17.12h-1.23M12.12 17.12v-2.78M7.97 15.66l-.88-.88M7.53 15.22l1.97-1.97M5.62 11.24v-1.23M5.62 10.62h2.79M7.09 6.47l.88-.88M7.53 6.03L9.5 8"/><path pid="1" d="M2.25 3.92v7.61c0 3.72 1.62 8.8 8.673 11.51h0c.69.26 1.46.26 2.15-.01 7.041-2.71 8.67-7.83 8.67-11.513V3.88c0-.59-.34-1.12-.87-1.37h0a21.791 21.791 0 00-8.89-1.82h0c-3.06-.03-6.09.58-8.89 1.81h-.01c-.54.24-.88.77-.87 1.36z"/></g>'
  }
})
