<template>
    <div class="flex flex-col items-center justify-center -mx-6">
        <div v-if="loadingSeatingCharts" class="flex flex-col items-center justify-center space-y-2 min-h-xs">
            <app-icon name="loader" class="w-8 h-8 fill-current"></app-icon>

            <p class="text-center">
                We're preparing your seating assignment merge tags, it will be ready in just a moment.
            </p>
        </div>

        <div v-else-if="!hasSeatingCharts" class="flex flex-col items-center justify-center space-y-2 min-h-xs">
            <p>You haven't created any seating charts yet.</p>
            <a class="button bg-white border-purple text-purple hover:bg-purple hover:text-white" :href="route('events.seating-charts.index', event)">
                <app-icon name="add-circle" stroke></app-icon>
                Create Seating Chart
            </a>
        </div>

        <div v-else class="w-full">
            <div class="flex px-6 py-2">
                <div class="w-1/2 font-semibold">Title:</div>
                <div class="w-1/2 font-semibold">Show seat number:</div>
            </div>

            <div class="flex flex-col">
                <div
                    v-for="seatingChart in seatingCharts"
                    :key="seatingChart.id"
                    class="flex flex px-6 py-2 hover:bg-gray-100"
                >
                    <div class="flex items-center w-1/2">
                        <label class="checkbox-container">
                            <input v-model="seatingChart.include" type="checkbox">
                            <span class="checkmark"></span>
                            {{ seatingChart.title }}
                        </label>
                    </div>
                    <div class="flex items-center w-1/2">
                        <toggle-switch v-model="seatingChart.showSeatNumber" small>
                            <span v-if="seatingChart.showSeatNumber">Yes</span>
                            <span v-else>No</span>
                        </toggle-switch>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="hasSeatingCharts && hasSelectedSeatingCharts" class="flex items-center justify-end w-full mt-2 mr-10">
            <stateful-button class="button-primary" @click="insertMergeTag">Insert Tag(s)</stateful-button>
        </div>

    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import DynamicMergeTags from '@/mixins/DynamicMergeTags';
import axios from '@/util/axios';

export default {
    name: 'SeatingAssignmentMergeTags',

    mixins: [DynamicMergeTags],

    data () {
        return {
            seatingCharts: [],
            loadingSeatingCharts: false
        };
    },

    mounted () {
        this.retrieveSeatingCharts();
    },

    computed: {
        ...get('Event/*'),

        hasSeatingCharts () {
            return this.seatingCharts.length > 0;
        },

        hasSelectedSeatingCharts () {
            return this.seatingCharts.some((seatingChart) => { return seatingChart.include; });
        }
    },

    methods: {
        generateMergeTags () {
            let mergeTags = '';

            this.seatingCharts.filter((seatingChart) => { return seatingChart.include; })
                .forEach((seatingChart) => {
                    const showSeatNumber = seatingChart.showSeatNumber ? 'yes' : 'no';
                    mergeTags = `${mergeTags}{table_assignment|id=${seatingChart.id}|showSeatNumber=${showSeatNumber}|title=${seatingChart.title}}\n`;
                });

            return mergeTags;
        },

        retrieveSeatingCharts () {
            this.loadingSeatingCharts = true;

            axios.get(this.route('api.events.campaigns.merge-tags-data.seating-charts', this.event))
                .then(({ data }) => {
                    this.seatingCharts = data.data.map((seatingChart) => {
                        return {
                            id: seatingChart.id,
                            title: seatingChart.title,
                            showSeatNumber: false,
                            include: false
                        };
                    });
                }).finally(() => {
                    this.loadingSeatingCharts = false;
                });
        }
    }
};
</script>
