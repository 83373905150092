/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-sanitizer-spray-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="10.291" height="14.407" x="2.223" y="8.843" rx="2.058"/><path pid="1" d="M5.07 1.871h4.58-.01c.44-.01.8.35.8.8 0 0 0 0 0 0v6.17H4.26V2.65c0-.45.35-.8.8-.8zM7.37 13.99v4.11M5.31 16.05h4.12M16.2 3.27a.42.42 0 100 .84.42.42 0 100-.85zM20.13.75a.42.42 0 100 .84.42.42 0 100-.85zM16.2 6.64a.42.42 0 100 .84.42.42 0 100-.85zM21.35 4.96a.42.42 0 100 .84.42.42 0 100-.85zM20.13 9.16a.42.42 0 100 .84.42.42 0 100-.85z"/></g>'
  }
})
