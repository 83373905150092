/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-symptoms-virus-lung-damage-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.05 2.97a2.96 2.96 0 100 5.93 2.96 2.96 0 100-5.94zM17.56.75h.99M18.06.75v2.22M21.38 1.92l.7.7M21.73 2.27l-1.57 1.57M23.25 5.45v.99M23.25 5.94h-2.23M22.08 9.26l-.7.7M21.73 9.61l-1.57-1.57M18.55 11.14h-.99M18.06 11.14V8.91M14.74 9.96l-.7-.7M14.39 9.61l1.57-1.57M12.86 6.44v-.99M12.86 5.94h2.23M14.04 2.62l.7-.7M14.39 2.27l1.57 1.57M9.9 4.95v7.93M6.849 9.328v-.01c0-.68-.55-1.22-1.22-1.23-.35-.01-.68.14-.91.4l-.81.88h-.01a12.148 12.148 0 00-3.18 8.2v3.2-.01a2.435 2.435 0 002.43 2.44c.31 0 .62-.06.91-.18l1.98-.8h-.01c.46-.19.76-.64.76-1.14zM4.41 17.15l5.49-4.27M18.732 14.843h0c.2.9.31 1.82.31 2.75v3.21l-.01-.01c0 1.34-1.1 2.44-2.44 2.44-.32 0-.62-.06-.91-.18l-1.99-.8h0c-.47-.19-.77-.64-.77-1.14v-9.27M15.39 17.15L9.9 12.88"/></g>'
  }
})
