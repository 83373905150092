<template>
    <div class="flex flex-col grow">
        <div class="flex flex-wrap">
            <form-field-wrapper label="Section Icon">
                <icon-picker
                    v-model="form.icon"
                    class="text-gray-800 w-11 h-11"
                ></icon-picker>
            </form-field-wrapper>

            <form-field-wrapper
                label="Section Title"
                :error="form.errors.get('title')"
                :should-show-error="form.errors.has('title')"
            >
                <input
                    v-model="form.title"
                    class="form-field"
                >
            </form-field-wrapper>

            <form-field-wrapper>
                <toggle-switch v-model="form.displayTitle">
                    Display Section Icon &amp; Title
                </toggle-switch>

                <toggle-switch
                    v-model="form.displayInMenu"
                    class="mt-4"
                >
                    Show Section In Menu
                </toggle-switch>
            </form-field-wrapper>

            <form-field-wrapper
                :error="form.errors.get('locationToUse')"
                :should-show-error="form.errors.has('locationToUse')"
            >
                <select-list v-model="form.locationToUse">
                    <select-option
                        id="event"
                        class="my-2"
                        tooltip="Display a map of your event’s location as set in Event Settings > Event Details."
                    >
                        <span class="border-b border-dashed border-gray-500">Set As Primary Event Location</span>
                    </select-option>

                    <select-option
                        id="custom"
                        class="my-2"
                        tooltip="Display a map of any location."
                    >
                        <span class="border-b border-dashed border-gray-500">Other Location</span>
                    </select-option>
                </select-list>
            </form-field-wrapper>

            <form-field-wrapper
                v-if="isCustomLocation"
                label="Address"
                :error="form.errors.get('location')"
                :should-show-error="form.errors.has('location')"
            >
                <google-autocomplete-input
                    v-model="venueWithAddress"
                    placeholder="Start typing a location..."
                    class="form-field"
                    name="event-location"
                >
                </google-autocomplete-input>
            </form-field-wrapper>

            <form-field-wrapper v-if="locationToDisplay">
                <location-map :address="locationToDisplay" class="h-80"></location-map>
            </form-field-wrapper>

            <portal to="app-drawer-footer">
                <footer-save-cancel
                    @cancel="$emit('cancel')"
                    @save="$emit('save-settings')"
                ></footer-save-cancel>
            </portal>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import HasDefaultCloseHandler from '@/mixins/HasDefaultCloseHandler';
import BlockableSettingsMixin from '@/mixins/BlockableSettingsMixin';

export default {
    name: 'WallMapBlockSettings',

    mixins: [HasDefaultCloseHandler, BlockableSettingsMixin],

    computed: {
        eventLocation: get('Event/event@location'),

        locationToDisplay () {
            return this.isCustomLocation ? this.venueWithAddress : {
                address: this.eventLocation?.address || '',
                venue: this.eventLocation?.venue_name || ''
            };
        },

        isCustomLocation () {
            return this.form.locationToUse === 'custom';
        },

        venueWithAddress: {
            get () {
                return {
                    address: this.form.location,
                    venue: this.form.venue_name
                };
            },

            set ({ address, venue }) {
                this.form.location = address;
                this.form.venue_name = venue;
            }
        }
    }
};
</script>
