<template>
    <div v-if="!isDataLoaded" class="text-center">
        <app-icon
            class="h-12 w-12 text-gray-500 fill-current"
            name="loader"
        ></app-icon>
    </div>

    <div
        v-else-if="!areFieldsVisible"
        class="text-center"
    >
        There are no group responses for this submission.
    </div>

    <div v-else :key="loadedDataCount">
        <div class="text-sm">
            <button
                v-if="userCanEditReplies"
                class="button button-sm button-soft"
                @click="toggleEditMode"
            >
                <app-icon name="edit"></app-icon>
                Edit Replies
            </button>
        </div>

        <div
            v-for="(addon) in data.addons"
            :key="addon.id"
            class="p-4 border-b"
        >
            <div
                class="uppercase tracking-wide text-gray-500 flex text-sm"
            >
                <app-icon
                    v-if="addon.orderable.settings.icon"
                    :name="addon.orderable.settings.icon"
                    class="w-4 h-4 mr-3 mt-1"
                ></app-icon>

                <div>
                    {{ addon.orderable.title }}

                    <div
                        v-if="isEditing"
                        v-tippy
                        class="items-center flex cursor-not-allowed"
                        content="Add-ons and groups packages aren't editable. Please delete and resubmit this group's reply if you'd like to update these selections."
                    >
                        <span class="mr-2">x</span>
                        <input
                            :value="getProductQuantity(addon)"
                            class="form-field w-18"
                            disabled
                        >
                    </div>
                    <div v-else class="text-left text-xs">x {{ getProductQuantity(addon) }}</div>
                </div>

                <div class="flex grow items-center justify-end">
                    <app-icon
                        v-if="isEditing"
                        v-tippy
                        name="trash"
                        class="h-5 w-5 cursor-not-allowed"
                        content="Add-ons and groups packages aren’t editable at this time. Please delete and resubmit this group’s reply if you’d like to update these selections."
                    >
                    </app-icon>
                </div>
            </div>

            <party-details-form-field-error :name="`addonProducts.${getProductPayloadKey(addon)}`" :form-errors="formErrors"></party-details-form-field-error>
        </div>

        <div class="w-full mb-4">
            <div
                v-for="question in groupQuestions"
                :key="question.id"
                class="p-4 border-b last:border-b-0"
            >
                <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">{{ question.title }}</div>

                <div class="flex items-center">
                    <custom-question-answer
                        :is-editing="isEditing"
                        :question="getEventQuestionWithAnswer(question)"
                        @input="modifyCustomQuestion(question, $event)"
                    ></custom-question-answer>
                </div>

                <party-details-form-field-error
                    :name="`submissionAnswers.${question.id}`"
                    :form-errors="formErrors"
                ></party-details-form-field-error>
            </div>
        </div>

        <portal to="party-details-modal-footer">
            <template #default="{ close }">
                <edit-party-details-footer
                    :loading="saving"
                    :disabled="!hasChanges"
                    @cancel="cancel"
                    @save="save"
                ></edit-party-details-footer>
            </template>
        </portal>
    </div>
</template>

<script>
import {
    differenceBy, find, get, isEmpty, map, size
} from 'lodash';
import axios from '@/util/axios';

export default {
    name: 'PartyDetailsResponsesTab',

    props: {
        event: {
            type: Object,
            required: true
        },
        isActive: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        initialSubmission: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            addonProducts: [],
            data: null,
            editPayload: {
                addonProducts: {},
                submissionAnswers: {}
            },
            eventQuestions: [],
            formErrors: {},
            isEditMode: false,
            isDataLoaded: false,
            loadedDataCount: 0,
            saving: false
        };
    },

    computed: {
        areFieldsVisible () {
            return this.submissionHasGroupResponses || this.eventQuestions.length > 0;
        },

        availableAddonProducts () {
            if (!this.data) {
                return [];
            }

            return differenceBy(
                this.addonProducts,
                map(this.data.addons, 'orderable'),
                'id'
            );
        },

        groupQuestions () {
            return this.eventQuestions.filter(({ settings }) => {
                return settings.target === 'group';
            });
        },

        hasChanges () {
            return !isEmpty(this.editPayload.addonProducts)
                || !isEmpty(this.editPayload.submissionAnswers);
        },

        submissionHasGroupResponses () {
            return size(this.data.addons) > 0 || size(this.data.questionAnswers) > 0;
        },

        userCanEditReplies () {
            return this.auth().user().can('update.event') || false;
        }
    },

    watch: {
        isActive (activated) {
            if (activated) {
                this.loadData();
            }
        }
    },

    methods: {
        cancel () {
            this.toggleEditMode();
            this.clearChanges();
        },

        clearChanges () {
            this.$set(this.editPayload, 'addonProducts', {});
            this.$set(this.editPayload, 'submissionAnswers', {});
        },

        loadData (force = false) {
            if (this.isDataLoaded && !force) {
                return null;
            }

            return axios.get(this.route('api.submissions.reporting.submission-details', this.initialSubmission))
                .then(({ data }) => {
                    this.isDataLoaded = true;
                    this.data = data.data;
                    this.addonProducts = data.addonProducts;
                    this.eventQuestions = data.eventQuestions;
                    this.loadedDataCount += 1;
                });
        },

        getEventQuestionWithAnswer (question) {
            const answer = find(this.data.questionAnswers, {
                id: question.id
            });

            if (answer == null) {
                return question;
            }

            return {
                ...question,
                ...answer
            };
        },

        getProductPayloadKey (product) {
            return product.isNew ? `new-${product.id}` : product.id;
        },

        getProductQuantity (product) {
            const key = this.getProductPayloadKey(product);

            const modifiedQuantity = get(this.editPayload.addonProducts, `${key}.quantity`, null);

            return modifiedQuantity !== null ? modifiedQuantity : product.quantity;
        },

        modifyCustomQuestion (question, answers) {
            this.$set(this.editPayload.submissionAnswers, question.id, answers);
        },

        toggleEditMode () {
            this.$emit('toggle-edit-mode');
        },

        save () {
            this.saving = true;
            this.$set(this, 'formErrors', {});

            axios.post(
                this.route('api.events.submissions.update', [this.event, this.initialSubmission]),
                this.editPayload
            )
                .then(() => {
                    this.$emit('saved');
                })
                .catch((error) => {
                    this.$set(this, 'formErrors', error.response.data.errors);
                    this.$toasted.global.error('There was an error updating the submission.');
                })
                .finally(() => {
                    this.saving = false;
                });
        }
    }
};
</script>
