<template>
    <div>
        <button
            class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
            @click="bulkResend"
        >
            <app-icon
                name="email-sync"
                class="h-4 w-4 mr-3"
                stroke
            ></app-icon>

            Confirmation ({{ bulkActionData.totalSelected | number }})
        </button>
    </div>
</template>

<script>
import ResendsConfirmationEmails from '@/mixins/ResendsConfirmationEmails';

export default {
    mixins: [
        ResendsConfirmationEmails
    ],

    props: {
        bulkActionData: {
            type: Object,
            required: true
        }
    },

    methods: {
        bulkResend () {
            this.confirmResend().then(() => {
                return this.makeResendRequest(this.bulkActionData);
            });
        }
    }
};
</script>
