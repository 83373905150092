/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transportation-taxi-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 17.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM18.75 17.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/><path pid="1" d="M20.25 19h1.5-.01c.82 0 1.5-.68 1.5-1.5v-3 0c0-.83-.68-1.5-1.5-1.5h-3v0c0-3.32-2.69-6-6-6h-3l-.01-.001c-.89-.01-1.66.59-1.87 1.45l-1.14 4.54H3.71c-1.66 0-3 1.34-3 3 0 0 0 0 0 0v1.5h0c0 .82.67 1.49 1.5 1.49h1.5M6.75 19h10.5M6.75 13h12M8.25 16H9M12 16h.75M15.75 16h.75"/><path pid="2" d="M9.75 7V5.5h0c-.01-.83.67-1.51 1.49-1.51h.75-.01c.82-.01 1.5.67 1.5 1.5v1.54"/></g>'
  }
})
