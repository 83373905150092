/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'family-couple-woman-woman-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>pride equality</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M22.5 23.25v0a4.5 4.5 0 00-9 0M18 11.25a3 3 0 100 6 3 3 0 100-6zM15 14.25S15 18 12.75 18M21 14.25S21 18 23.25 18M10.5 23.25v0a4.5 4.5 0 00-9 0M6 11.25a3 3 0 100 6 3 3 0 100-6zM3 14.25S3 18 .75 18M9 14.25S9 18 11.25 18M16.272 1.5h0a2.433 2.433 0 00-3.44-.09c-.03.02-.06.05-.09.08l-1.51 1.56-1.51-1.56h0a2.433 2.433 0 00-3.44-.09c-.03.02-.06.05-.09.08h-.01a2.608 2.608 0 000 3.63l4.48 4.639h0c.28.29.75.3 1.04.02 0-.01.01-.02.02-.03l4.48-4.639h-.01a2.63 2.63 0 00-.01-3.64z"/></g>'
  }
})
