/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-graph-cured-stable-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.787 22.5h-21v-21"/><path pid="1" d="M16.53 6v0c0-.56-.45-1-1-1h-2.5V2.5v0c0-.56-.45-1-1-1h-1.51c-.56 0-1 .44-1 1 0 0 0 0 0 0V5H7.01c-.56 0-1 .44-1 1 0 0 0 0 0 0v1.5h0c0 .55.44.99 1 .99h2.5v2.5h0c0 .55.44.99 1 .99h1.5l-.01-.01c.55 0 1-.45 1-1v-2.5h2.5l-.01-.001c.55 0 1-.45 1-1v-.001zM20.822 15.23l2.39 2.39-2.4 2.39"/><path pid="2" d="M.78 17.625h.5-.01c1.64-.01 3.26-.39 4.73-1.12l2.42-1.21h-.01c1.78-.9 3.89-.9 5.68-.01l2.42 1.21h0c1.47.73 3.09 1.11 4.73 1.11h1.92"/></g>'
  }
})
