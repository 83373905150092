<template>
    <input
        type="text"
        class="form-field"
        v-bind="$attrs"
        @input="$emit('input', $event.target.value)"
    >
</template>

<script>
export default {
};
</script>
