/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'messages-user-alt-bubble-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>chat</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18 .75h0a5.226 5.226 0 00-5.26 5.19c-.01 1.28.46 2.52 1.31 3.48l-.657 3.78 3.423-2.12-.01-.01c2.82.65 5.63-1.12 6.29-3.94.65-2.83-1.12-5.64-3.94-6.3-.39-.09-.79-.14-1.19-.14zM6.75 9.75a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75zM12.75 23.25h0a6.066 6.066 0 00-6.81-5.2 6.047 6.047 0 00-5.2 5.19"/></g>'
  }
})
