/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-twitter': {
    width: 16,
    height: 16,
    viewBox: '0 0 52 42',
    data: '<path pid="0" d="M51.578 5.265a20.835 20.835 0 01-5.984 1.642 10.455 10.455 0 004.58-5.765 20.834 20.834 0 01-6.616 2.528A10.405 10.405 0 0035.953.38c-5.755 0-10.42 4.665-10.42 10.42 0 .816.092 1.612.27 2.374-8.66-.434-16.338-4.583-21.477-10.887a10.365 10.365 0 00-1.41 5.238c0 3.615 1.838 6.804 4.635 8.673a10.364 10.364 0 01-4.72-1.303c-.002.044-.002.087-.002.13 0 5.05 3.592 9.26 8.358 10.219a10.42 10.42 0 01-2.744.364 10.39 10.39 0 01-1.961-.186 10.433 10.433 0 009.735 7.237 20.916 20.916 0 01-12.944 4.46c-.84 0-1.67-.05-2.486-.145a29.502 29.502 0 0015.974 4.68c19.166 0 29.645-15.876 29.645-29.647 0-.452-.01-.9-.03-1.347a21.219 21.219 0 005.2-5.395" _fill="currentColor"/>'
  }
})
