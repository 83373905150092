/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-card-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>person card id avatar account</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25 8.25a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM3.75 18.75h0a4.494 4.494 0 014.49-4.51c2.48-.01 4.5 2.01 4.5 4.49 0 0 0 0 0 0zM14.25 11.25h4.5M14.25 14.25h6"/><path pid="1" d="M21.75 5.25H2.24c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v15-.01c-.01.82.67 1.5 1.49 1.5h3a1.496 1.496 0 112.99-.02s0 0 0 0h7.5a1.496 1.496 0 112.99-.02s0 0 0 0h3-.01c.82 0 1.5-.68 1.5-1.5v-15h0c0-.83-.68-1.5-1.5-1.5zM23.25 2.25v0c0-.83-.68-1.5-1.5-1.5H2.24c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0"/></g>'
  }
})
