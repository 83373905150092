<template>
    <div class="flex grow justify-center">
        <fancy-image-uploader
            class="inline-block bg-gray-500 hover:bg-purple text-white py-2 px-4 rounded-md focus:outline-none focus:ring"
            @select-image="uploadImage"
        >
            Select Image
        </fancy-image-uploader>
    </div>
</template>

<script>
export default {
    props: {
        settings: {
            type: Object,
            default: () => {
                return {
                    pattern: '',
                    image: null
                };
            }
        }
    },

    methods: {
        uploadImage (url) {
            App.$emit('image-pattern-picker-settings/uploadedImage', url);
        }
    }
};
</script>
