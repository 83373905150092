export default {
    props: {
        error: {
            type: String,
            default: ''
        },

        shouldShowError: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        showError () {
            return this.error && this.shouldShowError;
        }
    }
};
