<template>
    <div>
        <div v-if="form.question">
            <form-field-wrapper
                label="Question"
                :error="form.errors.get('question.title')"
                :should-show-error="form.errors.has('question.title')"
            >
                <input
                    v-model="form.question.title"
                    class="form-field"
                    type="text"
                    name="title"
                    :placeholder="questionPlaceholder"
                >
            </form-field-wrapper>

            <form-field-wrapper label="Description (optional)">
                <textarea
                    v-model="form.question.settings.description"
                    class="form-field"
                    rows="5"
                ></textarea>
            </form-field-wrapper>

            <component
                :is="settingsComponent"
                v-model="form.question.settings"
                :form="form"
            ></component>

            <template v-if="form.question.type.settings.optionBased">
                <form-field-wrapper class="-mb-4">
                    <button class="button-text button-info" @click="createItem">
                        <app-icon
                            name="add-circle"
                            class="h-5 w-5 mr-2"
                            stroke
                        ></app-icon>
                        Add Selection
                    </button>
                </form-field-wrapper>

                <form-field-wrapper
                    :error="form.errors.get('question.options')"
                    :should-show-error="form.errors.has('question.options')"
                >
                    <draggable-list
                        v-model="primaryOptions"
                        confirm-delete
                        editable
                        deletable
                        unique-property="id"
                        label-property="title"
                        name="options"
                        :set-sort-property="true"
                        @edit-item="editItem"
                        @delete-item="deleteItem"
                    ></draggable-list>

                    <!-- Other Option -->
                    <div v-if="supportsOtherOption" class="flex items-center">
                        <div class="flex grow items-center font-semibold p-2 shadow rounded-md text-sm bg-white border my-2 draggable-block text-gray-500">
                            <div class="flex-auto text-gray-600">
                                Other (Fill in)
                            </div>

                            <div class="shrink mr-2 leading-none">
                                <toggle-switch v-model="form.question.settings.hasOtherOption" small></toggle-switch>
                            </div>

                            <button class="shrink cursor-pointer hover:text-purple leading-none" @click="editItem(otherOption)">
                                <app-icon
                                    name="settings-cog"
                                    class="h-5 w-5"
                                    stroke
                                ></app-icon>
                            </button>
                        </div>
                    </div>

                    <!-- Amount of Your Choice Settings -->
                    <div v-if="customAmountSettings" class="flex items-center">
                        <div class="flex grow items-center font-semibold p-2 shadow rounded-md text-sm bg-white border my-2 draggable-block text-gray-500">
                            <div class="flex-auto text-gray-600">
                                {{ customAmountSettings.title }}
                            </div>

                            <div class="shrink mr-2 leading-none">
                                <toggle-switch
                                    v-model="customAmountSettings.active"
                                    small
                                ></toggle-switch>
                            </div>

                            <button
                                class="shrink cursor-pointer hover:text-purple leading-none"
                                @click="editItem(customAmountSettings)"
                            >
                                <app-icon
                                    name="settings-cog"
                                    class="h-5 w-5"
                                    stroke
                                ></app-icon>
                            </button>
                        </div>
                    </div>

                    <!-- Decline Settings -->
                    <div v-if="declineSettings" class="flex items-center">
                        <div class="flex grow items-center font-semibold p-2 shadow rounded-md text-sm bg-white border my-2 draggable-block text-gray-500">
                            <div class="flex-auto text-gray-600">
                                {{ declineSettings.title }}
                            </div>

                            <div class="shrink mr-2 leading-none">
                                <toggle-switch
                                    v-model="declineSettings.active"
                                    small
                                ></toggle-switch>
                            </div>

                            <button
                                class="shrink cursor-pointer hover:text-purple leading-none"
                                @click="editItem(declineSettings)"
                            >
                                <app-icon
                                    name="settings-cog"
                                    class="h-5 w-5"
                                    stroke
                                ></app-icon>
                            </button>
                        </div>
                    </div>
                </form-field-wrapper>
            </template>

            <form-field-wrapper
                label="Ask Question"
                :error="form.errors.get('question.settings.target')"
                :should-show-error="form.errors.has('question.settings.target')"
            >
                <select-list
                    v-model="form.question.settings.target"
                    name="target"
                >
                    <select-option
                        id="group"
                        class="my-2"
                        label="Once Per Group"
                        tooltip="Ask once per group if anyone in that group indicates they are attending."
                    ></select-option>

                    <select-option
                        id="individual"
                        class="my-2"
                        label="To Each Attendee"
                        tooltip="Ask each guest separately who indicates they are attending."
                    ></select-option>

                </select-list>
            </form-field-wrapper>

            <form-field-wrapper
                v-if="form.question.settings.askIf"
                label="Ask If"
                :error="form.errors.get('question.settings.askIf.target')"
                :should-show-error="form.errors.has('question.settings.askIf.target')"
            >
                <select-list
                    v-model="form.question.settings.askIf.target"
                    name="askIf"
                >
                    <select-option
                        id="attending"
                        class="my-2"
                        label="Attending"
                        tooltip="Ask the guest(s) if they are attending the selected event."
                    ></select-option>

                    <form-field-wrapper
                        v-if="form.question.settings.askIf.target === 'attending'"
                        :error="form.errors.get('question.settings.askIf.targetId')"
                        :should-show-error="form.errors.has('question.settings.askIf.targetId')"
                    >
                        <simple-picker
                            v-model="form.question.settings.askIf.targetId"
                            :items="events"
                            item-label="label"
                            item-value="value"
                            class="w-full pl-8"
                        ></simple-picker>
                    </form-field-wrapper>

                    <select-option
                        v-if="event.hasMaybeOption"
                        id="maybe"
                        class="my-2"
                        label="Maybe"
                        tooltip="Ask the guest(s) if they might attend the selected event."
                    ></select-option>

                    <form-field-wrapper
                        v-if="form.question.settings.askIf.target === 'maybe'"
                        :error="form.errors.get('question.settings.askIf.targetId')"
                        :should-show-error="form.errors.has('question.settings.askIf.targetId')"
                    >
                        <simple-picker
                            v-model="form.question.settings.askIf.targetId"
                            :items="events"
                            item-label="label"
                            item-value="value"
                            class="w-full pl-8"
                        ></simple-picker>
                    </form-field-wrapper>

                    <select-option
                        id="not-attending"
                        class="my-2"
                        label="Not Attending"
                        tooltip="Ask the guest(s) if they are not attending the selected event."
                    ></select-option>

                    <form-field-wrapper
                        v-if="form.question.settings.askIf.target === 'not-attending'"
                        :error="form.errors.get('question.settings.askIf.targetId')"
                        :should-show-error="form.errors.has('question.settings.askIf.targetId')"
                    >
                        <simple-picker
                            v-model="form.question.settings.askIf.targetId"
                            :items="events"
                            item-label="label"
                            item-value="value"
                            class="w-full pl-8"
                        ></simple-picker>
                    </form-field-wrapper>

                    <select-option
                        id="always"
                        class="my-2"
                        label="Always"
                        tooltip="Always ask the custom question."
                    ></select-option>
                </select-list>
            </form-field-wrapper>

            <form-field-wrapper
                v-if="form.question.settings.additionalCriteria"
                label="Additional Criteria"
                :error="form.errors.get('question.settings.askIf.target')"
                :should-show-error="form.errors.has('question.settings.askIf.target')"
            >
                <select-list
                    v-model="form.question.settings.additionalCriteria.target"
                    name="additionalCriteria.target"
                >
                    <select-option
                        id="none"
                        class="my-2"
                        label="None"
                    ></select-option>

                    <select-option
                        id="tagged"
                        class="my-2"
                        :disabled="tags.length === 0"
                    >
                        <div class="flex items-center">
                            <span v-tippy content="Ask the guest(s) if they are tagged with the selected tag.">Is tagged with...</span>
                            <premium-feature feature="AskQuestionByTag" class="ml-2"></premium-feature>
                        </div>
                    </select-option>
                    <form-field-wrapper
                        v-if="showIncludeAdditionalGuestsToggle"
                        :error="form.errors.get('question.settings.additionalCriteria.query.tagIds.value')"
                        :should-show-error="form.errors.has('question.settings.additionalCriteria.query.tagIds.value')"
                    >
                        <simple-picker
                            v-model="form.question.settings.additionalCriteria.query.tagIds.value"
                            placeholder-empty-state="Select tag..."
                            :items="tags"
                            item-label="name"
                            item-value="id"
                            class="w-full pl-8"
                            @input="form.question.settings.additionalCriteria.query.tagIds.comparison = 'equals'"
                        ></simple-picker>
                    </form-field-wrapper>
                    <toggle-switch
                        v-if="showIncludeAdditionalGuestsToggle"
                        v-model="form.question.settings.additionalCriteria.includeAdditionalGuests"
                        class="mt-4 pl-8"
                    >
                        Include additional guests
                    </toggle-switch>
                </select-list>
            </form-field-wrapper>

            <form-field-wrapper v-if="isSelectMultipleQuestion">
                <form-panel
                    v-model="hasMinimum"
                    label="Minimum Number of Selections"
                    tooltip="Require guest to select a minimum number of selections."
                    :error="form.errors.get('question.settings.minSelectable')"
                >
                    <div class="flex items-end">
                        <input
                            v-model="form.question.settings.minSelectable"
                            class="form-field w-32 mr-4"
                            type="number"
                            name="minimum"
                            step="1"
                            min="1"
                        >
                        <div class="py-2 bold uppercase tracking-wide">
                            Minimum
                        </div>
                    </div>
                </form-panel>
            </form-field-wrapper>

            <form-field-wrapper v-if="isSelectMultipleQuestion">
                <form-panel
                    v-model="hasMaximum"
                    label="Maximum Number of Selections"
                    tooltip="Limit the number of selections a guest can make."
                    :error="form.errors.get('question.settings.maxSelectable')"
                >
                    <div class="flex items-end">
                        <input
                            v-model="form.question.settings.maxSelectable"
                            class="form-field w-32 mr-4"
                            type="number"
                            name="maximum"
                            step="1"
                            min="1"
                        >
                        <div class="py-2 bold uppercase tracking-wide">
                            Maximum
                        </div>
                    </div>
                </form-panel>
            </form-field-wrapper>

            <form-field-wrapper v-if="!isDonationQuestion">
                <toggle-switch v-model="form.question.settings.required" :disabled="isAlwaysRequired">
                    <div
                        v-tippy
                        content="Require guest(s) to submit a reply to this question."
                        class="tooltip-text"
                    >
                        Required
                    </div>
                </toggle-switch>
            </form-field-wrapper>

            <form-field-wrapper v-if="isPhoneNumberQuestion">
                <template #help>
                    <div>Guests will only be able to type numerical digits, + () . and - in this field.</div>
                </template>
            </form-field-wrapper>

            <feature-enabled v-if="isSocialProfileQuestion" feature="social-profile">
                <div>
                    <form-field-wrapper v-if="isSocialProfileQuestion">
                        <toggle-switch v-model="form.question.settings.linkToWhoIsGoing">
                            <div
                                v-tippy
                                content="If you've added a 'Who's Going' block to your Event Wall, choose to link guest's names to their profiles automatically."
                                class="tooltip-text"
                            >
                                Link to Profile from Who's Going Link
                            </div>
                        </toggle-switch>
                    </form-field-wrapper>
                </div>
            </feature-enabled>
        </div>

        <div v-else class="flex flex-wrap">
            <div
                v-for="questionType in questionTypes"
                :key="questionType.id"
                class="w-1/2 relative"
            >
                <icon-text-box
                    class="m-1"
                    :title="questionType.title"
                    :icon="questionType.icon"
                    @click.native="selectQuestionType(questionType)"
                ></icon-text-box>

                <div v-if="questionType.slug === 'terms-conditions'" class="absolute top-0 right-0 m-4">
                    <premium-feature feature="TermsAndConditionsQuestion"></premium-feature>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import Vue from 'vue';
import camelCase from 'lodash/camelCase';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import upperFirst from 'lodash/upperFirst';
import { find, findIndex } from 'lodash';
import InteractsWithFeatureFlags from '@/mixins/InteractsWithFeatureFlags';
import BlockableGeneralSettingsMixin from '@/mixins/BlockableGeneralSettingsMixin';
import HasSecondaryEvents from '@/mixins/HasSecondaryEvents';

export default {
    name: 'FormQuestionBlockGeneralSettings',

    mixins: [BlockableGeneralSettingsMixin, InteractsWithFeatureFlags, HasSecondaryEvents],

    inject: ['questionTypes'],

    props: {
        event: {
            type: Object,
            default: () => { return {}; }
        }
    },

    data () {
        return {
            hasMinimum: false,
            hasMaximum: false
        };
    },

    computed: {
        ...get('Event/event@', {
            tags: 'tags'
        }),

        customAmountSettings () {
            return this.form.question.settings.customAmount;
        },

        declineSettings () {
            return this.form.question.settings.declineable;
        },

        defaultQuestionSettings () {
            return {
                additionalCriteria: {
                    includeAdditionalGuests: false,
                    target: 'none',
                    query: {
                        tagIds: {
                            value: null,
                            comparison: null
                        }
                    }
                },
                askIf: {
                    target: 'attending',
                    targetId: null
                },
                description: '',
                hasOtherOption: false,
                required: false
            };
        },

        isAlwaysRequired () {
            return this.form.question.type.slug === 'terms-conditions';
        },

        isDropdownQuestion () {
            return this.form.question.type.slug === 'dropdown';
        },

        isDonationQuestion () {
            return this.form.question.type.slug === 'cash-donation-gift';
        },

        isMailingAddressQuestion () {
            return this.form.question.type.slug === 'mailing-address';
        },

        isQuantityQuestion () {
            return this.form.question.type.slug === 'quantity';
        },

        isSocialProfileQuestion () {
            return this.form.question.type.slug === 'social-profile';
        },

        isWebUrlQuestion () {
            return this.form.question.type.slug === 'web-url';
        },

        isSelectOneQuestion () {
            return this.form.question.type.slug === 'select-one';
        },

        isSelectMultipleQuestion () {
            return this.form.question.type.slug === 'select-multiple';
        },

        isPhoneNumberQuestion () {
            return this.form.question.type.slug === 'phone-number';
        },

        primaryOptions: {
            get () {
                return this.form.question.options.filter(({ type }) => {
                    return type === 'primary';
                });
            },
            set (newPrimaryOptions) {
                const existingIds = map(this.primaryOptions, 'id');
                const newIds = map(newPrimaryOptions, 'id');

                // Prevents an infinite loop caused by this setter triggering the getter, which triggers the
                // `DraggableList` and so on
                if (isEqual(existingIds, newIds)) {
                    return;
                }

                this.form.question.options = [...newPrimaryOptions, this.otherOption].filter();
            }
        },

        questionPlaceholder () {
            if (this.form.question.type.slug === 'social-profile') {
                return 'Paste a link';
            }

            return 'Type a question...';
        },

        settingsComponent () {
            const componentName = `form-${this.form.question.type.slug}-question-settings`;

            if (upperFirst(camelCase(componentName)) in Vue.options.components) {
                return componentName;
            }

            return null;
        },

        showIncludeAdditionalGuestsToggle () {
            return this.form.question.settings.additionalCriteria.target === 'tagged' && this.tags.length !== 0;
        },

        supportsOtherOption () {
            return this.isSelectOneQuestion || this.isSelectMultipleQuestion;
        },

        otherOption () {
            return this.form.question.options.find(({ type }) => {
                return type === 'other';
            });
        }
    },

    watch: {
        'form.question': {
            deep: true,
            handler (newQuestion) {
                this.$emit('update-question', newQuestion);

                // Wait until user chooses a question type and create the "Other" option if the chosen type supports it
                // and if it wasn't created already. There should be at most one "Other" option per question.
                if (this.supportsOtherOption && !this.otherOption) {
                    this.createOtherOption();
                }
            }
        },

        hasMinimum (newValue) {
            if (!newValue) {
                this.form.question.settings.minSelectable = null;
            }
        },

        hasMaximum (newValue) {
            if (!newValue) {
                this.form.question.settings.maxSelectable = null;
            }
        }
    },

    created () {
        if (this.form.question && this.supportsOtherOption && !this.otherOption) {
            this.createOtherOption();
        }
    },

    mounted () {
        this.hasMinimum = this.form.question?.settings.minSelectable > 0;
        this.hasMaximum = this.form.question?.settings.maxSelectable > 0;

        if (this.form.question === null && this.form.preselectedQuestionType) {
            const type = find(this.questionTypes, { slug: this.form.preselectedQuestionType });

            this.selectQuestionType(type);
        }
    },

    methods: {
        createOtherOption () {
            this.form.question.options.push({
                id: Math.ceil(Math.random() * 1000000000000),
                title: 'Other',
                type: 'other',
                price: 0,
                settings: {
                    costsMoney: false,
                    description: '',
                    maxCapacity: {
                        enabled: false,
                        slots: 50,
                        showMessage: true,
                        message: 'Sorry, this item is no longer available.'
                    }
                }
            });
        },

        deleteItem (index, option) {
            this.form.question.options.splice(findIndex(this.form.question.options, { id: option.id }), 1);
        },

        selectQuestionType (type) {
            this.form.question = {
                ...this.form.question,
                options: [],
                settings: {
                    ...this.defaultQuestionSettings
                },
                title: '',
                type
            };

            if (this.isAlwaysRequired) {
                this.form.question.settings.required = true;
            }

            if (this.isSelectMultipleQuestion) {
                this.form.isMultipleChoice = true;
            } else if (this.isQuantityQuestion) {
                this.form.requiresAnswers = false;
                this.form.items.forEach((item) => {
                    item.minimumPerGroup.hasInput = true;
                    item.maximumPerGroup.hasInput = true;
                    item.type = 'number-picker';
                });
            } else if (this.isSocialProfileQuestion) {
                this.$set(this.form, 'acceptedWebsites', 'linkedin.com, twitter.com');
                this.$set(this.form, 'linkToWhoIsGoing', true);
                this.form.text = 'Paste a link.';
                this.form.required = true;
            } else if (this.isPhoneNumberQuestion) {
                this.form.text = 'Phone Number';
            } else if (this.isWebUrlQuestion) {
                this.form.text = 'Paste a link.';
                this.$set(this.form, 'acceptedWebsites', 'linkedin.com, twitter.com');
                this.form.required = true;
            } else if (this.isMailingAddressQuestion) {
                this.$set(this.form.question.settings, 'mailingAddress', {
                    includeStreetAddressField: true,
                    includeSecondStreetAddressField: true,
                    includeCityField: true,
                    includeStateField: true,
                    includeZipCodeField: true,
                    includeCountryField: true,
                    stateField: 'textBox',
                    defaultCountry: null
                });
            }
        }
    }
};
</script>
