/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-tennis-ball-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/><path pid="1" d="M6 6.007h0c.32.58.61 1.2.84 1.83M7.52 10.62h0c.1.86.08 1.73-.04 2.59M6.73 15.983v-.01c-.3.7-.66 1.37-1.08 2.01M18 6.007v-.01c-.33.58-.61 1.2-.85 1.83M16.47 10.62h-.01c-.11.86-.09 1.73.03 2.59M17.27 15.983h0c.29.7.65 1.37 1.07 2.01"/></g>'
  }
})
