/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-quarantine-bed-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.31 2.89a2.85 2.85 0 100 5.71 2.85 2.85 0 100-5.72zM9.84.75h.95M10.31.75v2.14M13.51 1.88l.68.67M13.85 2.21l-1.52 1.52M15.31 5.27v.96M15.31 5.75h-2.14M14.19 8.95l-.68.67M13.85 9.29l-1.52-1.52M10.79 10.75h-.95M10.31 10.75V8.61M7.11 9.62l-.67-.67M6.78 9.29l1.51-1.52M5.31 6.23v-.96M5.31 5.75h2.14M6.44 2.55l.67-.67M6.78 2.21l1.51 1.52M6.81 14.25a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM11.31 18.75v-4.5h9.87-.01c1.1-.01 2 .89 2 1.99v2.5M.81 18.75h22.375v3H.81zM.81 23.25v-9M23.19 23.25v-1.5"/></g>'
  }
})
