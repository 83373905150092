/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-carrier-human-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.85 14.55a3.16 3.16 0 100 6.32 3.16 3.16 0 100-6.33zM11.33 12.18h1.05M11.86 12.18v2.37M15.4 13.43l.74.74M15.77 13.8l-1.68 1.68M17.39 17.19v1.05M17.39 17.72h-2.37M16.14 21.26l-.74.74M15.77 21.63l-1.68-1.68M12.38 23.25h-1.05M11.86 23.25v-2.37M8.32 22l-.75-.74M7.94 21.63l1.68-1.68M6.32 18.24v-1.05M6.32 17.72h2.37M7.57 14.17l.75-.74M7.94 13.8l1.68 1.68M9.35 8.943l-.01-.01A4.535 4.535 0 018.3 2.6C9.75.56 12.58.1 14.62 1.56c2.03 1.45 2.49 4.28 1.03 6.32-.29.39-.64.74-1.04 1.03h0a8.39 8.39 0 015.76 7.98v6.218"/><path pid="1" d="M3.58 23.142v-6.218h-.01c0-3.63 2.32-6.85 5.76-7.99"/></g>'
  }
})
