/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'team-meeting-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>group users people network</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.49 15a2.62 2.62 0 100 5.25 2.62 2.62 0 100-5.25zM8.24 23.25h0a4.251 4.251 0 00-5.75-1.76c-.75.39-1.36 1-1.75 1.75M19.49 15a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM23.249 23.25h0a4.251 4.251 0 00-5.75-1.76c-.75.39-1.36 1-1.75 1.75M11.99.75a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM15.24 8.25h0a4.267 4.267 0 00-6.02-.49c-.18.14-.34.3-.49.48M9.05 19.707h0c1.91.73 4.03.72 5.94-.02M6.348 6h-.01a8.162 8.162 0 00-2.6 6c0 .25.01.5.03.75M20.21 12.75c.02-.25.03-.5.03-.75h-.01c0-2.28-.95-4.45-2.6-6.01"/></g>'
  }
})
