/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-clean-bottle-virus-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.61 1.27h1.33M16.27 1.27v2.96M20.74 2.85l.94.94M21.21 3.32l-2.12 2.11M23.25 7.59v1.32M23.25 8.25h-2.99M21.68 12.71l-.94.94M21.21 13.18l-2.12-2.11M16.94 15.23h-1.33M16.27 15.23v-2.99"/><rect pid="1" width="11.999" height="13.499" x=".75" y="9.229" rx="3"/><path pid="2" d="M.75 4.729l.621-.63h-.01c.56-.57 1.32-.88 2.12-.88h7M9.74 9.229h-6v-1.5h0c-.01-.83.67-1.51 1.49-1.51h3-.01c.82-.01 1.5.67 1.5 1.5zM6.75 6.23v-3M6.75 13.23v6M3.75 16.23h6M13.605 5.228v-.01a3.982 3.982 0 015.64.35 3.992 3.992 0 01-.36 5.64c-.74.64-1.68.99-2.65.99"/></g>'
  }
})
