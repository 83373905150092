<template>
    <div class="pt-2 pl-2">
        <decision-input
            v-for="choice in visibleChoices"
            :key="choice.id"
            class="mb-4 last:mb-0"
            :class="isOutOfAvailability(choice) ? 'opacity-25' : ''"
            :title="choice.title"
            :price="getChoicePrice(choice)"
            :icon="choice.settings.icon"
            :description="choice.settings.description"
            :availability-message="getMealAvailabilityMessage(choice)"
            :value="value === choice.id"
            @input="handleItemSelected(choice)"
        >
            <button
                v-if="!editing"
                v-theme="'form.accented-text'"
                class="button-text text-sm font-normal"
                @click.stop="editing = true"
            >
                {{ $t('button-edit-selection') }}
            </button>
        </decision-input>
    </div>
</template>

<script>
import filter from 'lodash/filter';

export default {
    name: 'MealSelection',

    provide: {
        isMultipleChoice: false
    },

    props: {
        choices: {
            type: Array,
            required: true
        },
        value: {
            type: Number,
            default: null
        }
    },

    data () {
        return {
            editing: true
        };
    },

    computed: {
        visibleChoices () {
            if (this.editing) {
                return filter(this.choices, { active: true });
            }

            return this.choices.filter(({ id }) => {
                return this.value === id;
            });
        }
    },

    methods: {
        getChoicePrice (choice) {
            return choice.settings.costsMoney ? choice.price : null;
        },

        getMealAvailabilityMessage (meal) {
            const availabilitySettings = meal.settings.maxCapacity || {};

            if (availabilitySettings.enabled && meal.quantity_remaining === 0) {
                return availabilitySettings.showMessage ? availabilitySettings.message : '';
            }

            return '';
        },

        isOutOfAvailability (meal) {
            const availabilitySettings = meal.settings.maxCapacity || {};

            return availabilitySettings.enabled && meal.quantity_remaining === 0;
        },

        handleItemSelected (meal) {
            if (!this.editing || this.isOutOfAvailability(meal)) {
                return;
            }

            this.editing = false;

            this.$emit('input', Number(meal.id));
        }
    }
};
</script>
