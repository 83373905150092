import Vue from 'vue';
import { make } from 'vuex-pathify';
import findIndex from 'lodash/findIndex';
import axios from '@/util/axios';
import Errors from '@/validation/Errors';

const moduleState = {
    errorBag: new Errors(),
    payloads: [],
    submission: {
        id: null,
        uuid: null,
        state: null,
        order: {
            items: [],
            payment: {
                isSuccessful: false
            }
        },
        invitee_group: null,
        language_id: null,
        selectedTimeSlot: null,
        lookedUpGuest: null,
        allQuestionAnswers: []
    },
    paymentSectionCompleted: false,
    inviteeLookUpDetails: {},
    preselectedReply: null,
    enteredPasscode: null,
    securityToken: (new URLSearchParams(window.location.search)).get('securityToken')
};

const mutations = {
    ...make.mutations(moduleState),

    setPayload (state, { block, payload }) {
        const payloadIndex = findIndex(
            state.payloads,
            {
                block: {
                    pivot: {
                        id: block.pivot.id
                    }
                }
            }
        );

        if (payloadIndex !== -1) {
            Vue.set(state.payloads, payloadIndex, { block, payload });
        } else {
            state.payloads.push({ block, payload });
        }
    }
};

const actions = {
    /**
     * Creates a new Submission and stores the created Submission in the state.
     */
    async start ({ state, commit, rootState }, { test_mode }) {
        const route = window.route('api.submissions.start', rootState.Event.event.id);

        const { data } = await axios.post(route, {
            test_mode,
            language_id: state.submission.language_id,
            securityToken: state.securityToken,
            form_publish_date: rootState.Form.form.published_at
        });

        commit('submission', { ...state.submission, ...data });

        App.trackers.event('RegistrationStarted', 'facebook');
        App.trackers.event('Registration Started', 'google', {
            event_category: 'RSVPify',
            event_label: `${rootState.Event.event.name} - ${window.location.hostname}`,
            value: 0
        });
    },

    /**
     * Completes a FormBlock as part of this Submission, updates the Submission
     * state object with the updated Submission object return from the backend,
     * and locally stores the sent block payload to the server.
     *
     * If a submission hasn't been started yet, it first starts the submission.
     */
    async completeBlock ({
        state, commit, rootState, getters, dispatch
    }, { block, payload }) {
        if (!getters.submissionStarted) {
            await dispatch('start', { test_mode: rootState.Form.isPreview });
        }

        const route = window.route(
            'api.submissions.complete-block',
            {
                submission: state.submission.id,
                uuid: state.submission.uuid
            }
        );

        try {
            const { data: { submission, result } } = await axios.post(route, {
                block: block.pivot.id,
                securityToken: state.securityToken,
                payload
            });

            commit('errorBag', new Errors());
            // Selected timeslot is not being returned from the backend,
            // but it is needed on the submission object, so it is being
            // added here so it does not get overridden.
            commit('submission', {
                ...submission,
                selectedTimeSlot: state.submission.selectedTimeSlot
            });
            commit('setPayload', { block, payload });

            return {
                result,
                successful: true
            };
        } catch (error) {
            if (error.response && error.response.status === 422) {
                commit('errorBag', new Errors(error.response.data.errors));
            }

            throw error;
        }
    },

    /**
     * Completes the Submission currently in progress.
     */
    async complete ({ state, commit, rootState }) {
        const route = window.route('api.submissions.complete', [
            state.submission.id,
            state.submission.uuid
        ]);
        const { data: submission } = await axios.post(route, { securityToken: state.securityToken });
        commit('submission', submission);

        const { currencyDescriptor } = rootState.Event.event;
        const eventLabel = `${rootState.Event.event.name} - ${window.location.hostname}`;
        App.trackers.event('CompleteRegistration', 'facebook');
        App.trackers.event('Registration Submitted', 'google', {
            event_category: 'RSVPify',
            event_label: eventLabel,
            value: 0
        });

        if (submission.order.total > 0) {
            App.trackers.event('Ticket(s) Purchased', 'google', {
                event_category: 'RSVPify',
                event_label: eventLabel,
                value: Math.round(submission.order.total / currencyDescriptor.subunit)
            });

            App.trackers.event('Purchase', 'facebook', {
                value: (submission.order.total / currencyDescriptor.subunit).toFixed(currencyDescriptor.precision),
                currency: currencyDescriptor.short_code
            });
        }

        if (submission.order.donation > 0) {
            App.trackers.event('Donation', 'google', {
                event_category: 'RSVPify',
                event_label: eventLabel,
                value: Math.round(submission.order.donation / currencyDescriptor.subunit)
            });

            App.trackers.event('Donate', 'facebook', {
                value: (submission.order.donation / currencyDescriptor.subunit).toFixed(currencyDescriptor.precision),
                currency: currencyDescriptor.short_code
            });
        }
    }
};

const getters = {
    submissionStarted (state) {
        return !!state.submission.uuid;
    },

    submissionCompleted (state) {
        return state.submission.status === 'Completed';
    }
};

export default {
    namespaced: true,
    state: moduleState,
    mutations,
    actions,
    getters
};
