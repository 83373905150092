/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download-cloud-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 14.25v9M15.75 19.5L12 23.25 8.25 19.5M16.5 15.75h1.875c2.69 0 4.87-2.18 4.88-4.87 0-2.7-2.18-4.88-4.87-4.89-1-.01-1.97.3-2.79.86h0C14.825 2.77 10.9.09 6.83.86A7.484 7.484 0 00.84 9.61a7.484 7.484 0 006.59 6.07"/></g>'
  }
})
