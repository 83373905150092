<template>
    <div class="w-full h-full passcode-check" :style="formBackgroundStyle">
        <div
            class="form-background"
            :class="{ 'wall-inactive': true }"
        ></div>

        <rsvp-page-language-selector
            v-if="isLanguageSelectorVisible"
            @disable-language-selector="isLanguageSelectorVisible = false"
        ></rsvp-page-language-selector>

        <div class="relative w-full h-full flex items-center justify-center">
            <div class="w-full max-w-4xl mx-2">
                <img
                    v-if="logo"
                    v-theme="'form.logo'"
                    :src="logo"
                    alt="logo"
                    class="block mx-auto mb-6"
                >

                <div v-theme="'form.background'" class="relative p-6 rounded-md">
                    <div class="mb-2 text-2xl break-words">
                        <span v-theme="'form.title-text'">{{ $t('title-enter-passcode') }}</span>
                    </div>

                    <form-field-wrapper
                        :error="errorToDisplay"
                        :should-show-error="shouldShowError"
                        class="my-4"
                    >
                        <single-line-text-input
                            v-model="form.passcode"
                            :placeholder="$t('placeholder-passcode')"
                            type="password"
                            @complete="checkPasscode"
                        ></single-line-text-input>
                    </form-field-wrapper>

                    <div class="absolute left-0 w-full">
                        <rsvp-next-button
                            class="mx-auto"
                            :loading="form.processing"
                            @click="checkPasscode"
                        ></rsvp-next-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import Form from '@/validation/Form';

export default {
    props: {
        event: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            form: new Form({
                passcode: ''
            }),
            invalidPassword: false,
            isLanguageSelectorVisible: this.event.enabled_languages.length > 1
        };
    },

    computed: {
        logo: get('Form/form@settings.logo_url'),
        formBackgroundStyle: get('Form/formBackgroundStyle'),

        errorToDisplay () {
            return this.form.errors.has('passcode') ? this.form.errors.get('passcode') : this.$t('text-incorrect-passcode');
        },

        shouldShowError () {
            return this.invalidPassword || this.form.errors.has('passcode');
        }
    },

    created () {
        this.event.enabled_languages.forEach(({ name, translations }) => {
            this.$i18n.setLocaleMessage(name, translations);
        });

        this.$i18n.locale = this.event.enabled_languages[0].name;
    },

    methods: {
        checkPasscode () {
            this.invalidPassword = false;

            const getParams = new URLSearchParams(window.location.search);

            const queryParams = {};

            getParams.forEach((value, key) => {
                queryParams[key] = value;
            });

            const additionalData = {
                language: this.$i18n.locale,
                groupUuid: getParams.get('group')
            };

            const route = this.route('api.rsvp.check-passcode', { subdomain: this.event.subdomain, ...queryParams });

            this.form.post(route, null, additionalData).then(({ data }) => {
                if (data.valid) {
                    window.location.href = data.redirectUrl;
                } else {
                    this.invalidPassword = true;
                }
            });
        }
    }
};
</script>
