/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shoes-heels-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>clothing accessory footwear</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.74 15h-.01c-.63 0-1.22-.29-1.6-.79L5.98 2.24h-.01A5.25 5.25 0 00.72 7.49c0 1.37.53 2.69 1.49 3.67v9.07h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51v-7.55l-.01-.01c.24.03.49.05.74.05h-.01c3.72-.01 6.75 3.02 6.75 6.75v.75h0c0 .82.67 1.49 1.5 1.49h7.5-.01c.82 0 1.5-.68 1.5-1.5v-3c0-3.93-2.96-4.93-3.99-4.266-.81.51-1.42 2.01-2.53 2.01z"/>'
  }
})
