/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin-church-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>religion holy</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.75 11.754v4h-6v-4"/><path pid="1" d="M7.25 12.754l4.5-3 4.5 3M11.75 5.25v4.5M10.25 6.75h3"/><path pid="2" d="M20.75 9.754c0 7.9-6.932 12.33-8.629 13.3v-.01a.77.77 0 01-.75 0c-1.7-.97-8.63-5.4-8.63-13.3h0a8.988 8.988 0 018.99-9.01c4.97-.01 9 4.02 9 8.99 0 0 0 0 0 0z"/></g>'
  }
})
