/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-carrier-blood-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M20.182 15.068h0c.09 4.42-3.43 8.09-7.86 8.18-.11 0-.22 0-.33-.01v-.01c-4.43.09-8.1-3.43-8.19-7.86-.01-.11-.01-.22-.01-.33 0-6.93 8.18-14.32 8.18-14.32s8.18 7.395 8.18 14.318z"/><path pid="1" d="M12 12.07a2.79 2.79 0 100 5.59 2.79 2.79 0 100-5.59zM11.53 9.97h.94M12 9.97v2.1M15.13 11.08l.66.66M15.46 11.41l-1.48 1.48M16.89 14.4v.93M16.89 14.86H14.8M15.79 17.99l-.66.66M15.46 18.32l-1.48-1.48M12.47 19.76h-.94M12 19.76v-2.1M8.87 18.65l-.66-.66M8.54 18.32l1.48-1.48M7.11 15.33v-.93M7.11 14.86H9.2M8.21 11.74l.66-.66M8.54 11.41l1.48 1.48"/></g>'
  }
})
