import { Node } from 'tiptap';
import EmailButton from './EmailButton';

export default class EmailButtonNode extends Node {
    get name () {
        return 'emailButton';
    }

    commands ({ type }) {
        return (attrs) => {
            return (state, dispatch) => {
                const { selection } = state;

                const position = selection.$cursor
                    ? selection.$cursor.pos
                    : selection.$to.pos;

                const node = type.create(attrs);
                const transaction = state.tr.insert(position, node);

                dispatch(transaction);
            };
        };
    }

    get schema () {
        return {
            content: 'inline*',
            attrs: {
                buttonData: {
                    default: encodeURIComponent(JSON.stringify({
                        align: 'justify-center',
                        text: 'Click Here',
                        attendingText: `I'll be there!`,
                        notAttendingText: `Can't Make It`,
                        link: '',
                        textColor: '#FFFFFF',
                        buttonColor: '#A04697',
                        borderColor: '#A04697',
                        borderRadius: 3,
                        showFirstOnMobile: 'Wall'
                    }))
                },
                type: {
                    default: 'custom'
                }
            },
            group: 'block',
            draggable: true,
            selectable: true,
            parseDOM: [{
                tag: 'div[data-email-button]',
                getAttrs: (dom) => {
                    return {
                        buttonData: dom.getAttribute('data-email-button'),
                        type: dom.getAttribute('data-email-button-type')
                    };
                }
            }],
            toDOM: (node) => {
                return ['div', {
                    'data-email-button': node.attrs.buttonData,
                    'data-email-button-type': node.attrs.type
                }];
            }
        };
    }

    get view () {
        return EmailButton;
    }
}
