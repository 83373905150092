<template>
    <div>
        <form-field-wrapper label="Text, Image, Video">
            <p>Customize text by clicking directly into the preview (at-right). Click the (+) button to add images or video.</p>

            <img
                :src="asset('images/gifs/editing-text-block.gif')"
                class="mt-4 border rounded-md"
                alt="How to edit the text block"
            >
        </form-field-wrapper>

        <form-field-wrapper
            v-if="showAskIfOption"
            label="Show If"
            :error="form.errors.get('askIf.target')"
            :should-show-error="form.errors.has('askIf.target')"
        >

            <select-list
                v-model="form.askIf.target"
                name="askIf"
            >
                <select-option
                    id="always"
                    class="my-2"
                    label="Always"
                    tooltip="Always show the image & text."
                ></select-option>

                <select-option
                    id="attending"
                    class="my-2"
                    label="Attending"
                    tooltip="Only show the image & text to guest(s) who are attending."
                ></select-option>

                <form-field-wrapper
                    v-if="form.askIf.target === 'attending'"
                    :error="form.errors.get('askIf.targetId')"
                    :should-show-error="form.errors.has('askIf.targetId')"
                >
                    <simple-picker
                        v-model="form.askIf.targetId"
                        :items="events"
                        item-label="label"
                        item-value="value"
                        class="w-full pl-8"
                    ></simple-picker>
                </form-field-wrapper>

                <select-option
                    v-if="event.hasMaybeOption"
                    id="maybe"
                    class="my-2"
                    label="Maybe"
                    tooltip="Only show the image & text to guest(s) who might attend."
                ></select-option>

                <form-field-wrapper
                    v-if="form.askIf.target === 'maybe'"
                    :error="form.errors.get('askIf.targetId')"
                    :should-show-error="form.errors.has('askIf.targetId')"
                >
                    <simple-picker
                        v-model="form.askIf.targetId"
                        :items="events"
                        item-label="label"
                        item-value="value"
                        class="w-full pl-8"
                    ></simple-picker>
                </form-field-wrapper>

                <select-option
                    id="not-attending"
                    class="my-2"
                    label="Not Attending"
                    tooltip="Only show the image & text to guest(s) who are not attending."
                ></select-option>

                <form-field-wrapper
                    v-if="form.askIf.target === 'not-attending'"
                    :error="form.errors.get('askIf.targetId')"
                    :should-show-error="form.errors.has('askIf.targetId')"
                >
                    <simple-picker
                        v-model="form.askIf.targetId"
                        :items="events"
                        item-label="label"
                        item-value="value"
                        class="w-full pl-8"
                    ></simple-picker>
                </form-field-wrapper>
            </select-list>
        </form-field-wrapper>

        <form-field-wrapper
            v-if="form.additionalCriteria"
            label="Additional Criteria"
            :error="form.errors.get('askIf.target')"
            :should-show-error="form.errors.has('askIf.target')"
        >
            <select-list
                v-model="form.additionalCriteria.target"
                name="additionalCriteria.target"
            >
                <select-option
                    id="none"
                    class="my-2"
                    label="None"
                ></select-option>

                <select-option
                    id="tagged"
                    class="my-2"
                    label="Show only if tagged with..."
                    :disabled="tags.length === 0"
                ></select-option>
                <form-field-wrapper
                    v-if="form.additionalCriteria.target === 'tagged' && tags.length !== 0"
                    :error="form.errors.get('additionalCriteria.query.tagIds.value')"
                    :should-show-error="form.errors.has('additionalCriteria.query.tagIds.value')"
                >
                    <simple-picker
                        v-model="form.additionalCriteria.query.tagIds.value"
                        placeholder-empty-state="Select tag..."
                        :items="tags"
                        item-label="name"
                        item-value="id"
                        class="w-full pl-8"
                        @input="form.additionalCriteria.query.tagIds.comparison = 'equals'"
                    ></simple-picker>
                </form-field-wrapper>
            </select-list>
        </form-field-wrapper>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import BlockableSettingsMixin from '@/mixins/BlockableSettingsMixin';
import HasSecondaryEvents from '@/mixins/HasSecondaryEvents';
import BaseBlockIsSelfEditable from '@/mixins/BaseBlockIsSelfEditable';

export default {
    name: 'FormTextBlockSettings',

    mixins: [BaseBlockIsSelfEditable, BlockableSettingsMixin, HasSecondaryEvents],

    computed: {
        ...get('Event/event@', {
            tags: 'tags'
        }),

        showAskIfOption () {
            return this.form.askIf && this.form.section === 'PrimarySection';
        }
    }
};
</script>
