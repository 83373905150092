<template>
    <div>
        <slot v-bind="{ publishNow }"></slot>

        <published-event-info-modal v-model="showPublishedEventInfoModal"></published-event-info-modal>

        <requires-upgrade-modal v-model="isUpgradeModalVisible" :used-features="usedFeaturesList"></requires-upgrade-modal>
        <comp-requires-action v-model="isCompRequiresActionModal" @addPaidProduct="openPrimaryBlock"></comp-requires-action>
        <event-requires-stripe-action v-model="isEventRequiresStripeActionModal" @removePaidProduct="openPrimaryBlock"></event-requires-stripe-action>
    </div>
</template>

<script>
import { merge } from 'lodash';
import { get } from 'vuex-pathify';
import axios from '@/util/axios';
import CheckEventPublishabilityInfo from '@/mixins/CheckEventPublishabilityInfo';

export default {
    name: 'PublishNowAction',

    mixins: [CheckEventPublishabilityInfo],

    data () {
        return {
            showPublishedEventInfoModal: false,
            publishing: false
        };
    },

    mounted () {
        App.$on('publish-event', () => {
            this.publishNow();
        });
    },

    computed: {
        event: get('Event/event'),
        form: get('Form/form'),
        wall: get('Wall/wall')
    },

    watch: {
        publishing (newVal) {
            this.$emit('action-in-progress', newVal);
        }
    },

    methods: {
        async publishNow () {
            this.publishing = true;
            const result = await this.getPublishabilityInfo();

            if (!result.publishable) {
                this.showBlockerModal(result);
                this.publishing = false;

                App.$emit('publish-flow-errored', this.event);

                return;
            }

            this.publish();
        },

        publish () {
            axios.post(this.route('api.events.publish', this.event)).then(({ data }) => {
                merge(this.event, data);
                merge(this.form, data.form);
                merge(this.wall, data.wall);

                this.showPublishedEventInfoModal = true;

                this.$toasted.global.success('Your event has been published.');

                if (this.event.number_of_publishes === 1 && window.gtag) {
                    window.gtag('event', 'Published Event', {
                        event_category: 'Conversion',
                        event_label: this.name
                    });
                }
            }).catch(() => {
                this.$toasted.global.error('There was a problem publishing your event.');
            }).finally(() => {
                this.publishing = false;
                App.$emit('publish-flow-completed', this.event);
            });
        }
    }
};
</script>
