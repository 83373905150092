<template>
    <multi-page-item-settings
        items-array-name="events"
        :settings="form"
        :item-creator-function="createEmptyItem"
        :default-close-handler="defaultCloseHandler"
    >
        <template slot-scope="{ edit, save, close, create, restore, selectedItem }">
            <wall-schedule-block-general-settings
                key="general-settings"
                :form="form"
                @edit-item="edit"
                @create-item="create"
            ></wall-schedule-block-general-settings>

            <wall-schedule-block-item-settings
                key="item-settings"
                :event="event"
                :block="block"
                :item="selectedItem"
                @save-settings="save"
                @cancel="restore"
            ></wall-schedule-block-item-settings>
        </template>
    </multi-page-item-settings>
</template>

<script>
import BlockableSettingsMixin from '@/mixins/BlockableSettingsMixin';
import HasDefaultCloseHandler from '@/mixins/HasDefaultCloseHandler';

export default {
    name: 'WallScheduleBlockSettings',

    mixins: [HasDefaultCloseHandler, BlockableSettingsMixin],

    methods: {
        createEmptyItem () {
            return {
                name: '',
                icon: '',
                timeAndDate: '',
                description: '',
                showLink: false,
                linkText: '',
                linkUrl: '',
                active: true,
                new: true,
                deletable: true
            };
        }
    }
};
</script>
