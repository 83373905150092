/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-tea-kettle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.9 12h14.7M6.136 14.25H3.621l-.01-.001a2.11 2.11 0 00-2.13 2.12c-.01.56.22 1.1.62 1.5l3.87 3.87"/><path pid="1" d="M22.5 21.75v0c0 .82-.68 1.5-1.5 1.5H7.49a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-6h0c-.01-4.56 3.69-8.26 8.24-8.26 4.55-.01 8.25 3.69 8.25 8.24 0 0 0 0 0 0z"/><path pid="2" d="M14.25 4.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/><path pid="3" d="M21 11.005V7.86A6.98 6.98 0 0014.24.73v0h-.01a6.95 6.95 0 00-6.75 7.12v3.13"/></g>'
  }
})
