/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-room-service-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M3.75 11.579h0a8.988 8.988 0 018.99-9.01c4.97-.01 9 4.02 9 8.99 0 0 0 0 0 0zM2.25 11.58h21M12.75 1.08v1.5"/><path pid="1" d="M.75 22.079l3.75-3.65 5.25 4.5 12.06-8.72v-.01c.52-.38.64-1.11.26-1.64-.1-.13-.22-.24-.35-.32v0l-.01-.01c-1.38-.83-3.1-.86-4.5-.08l-2.93 1.62v-.01c-1.79.97-4 .65-5.44-.77l-.59-.59h0a2.996 2.996 0 00-3.47-.56v0-.01c-.43.21-.6.72-.39 1.14.04.08.09.15.15.22l.45.45h0a4.476 4.476 0 011.22 4.06l-.38 1.843"/></g>'
  }
})
