<template>
    <div>
        <div
            :id="blockId"
            v-theme="'wall.background'"
            class="relative py-6"
        >
            <div
                v-if="displayTitle"
                v-theme="['wall.title-color', 'wall.title-font', 'wall.title-font-size']"
                class="flex justify-center items-center flex-auto mb-6 font-bold leading-normal"
            >
                <app-icon
                    v-if="icon"
                    :name="icon"
                    class="h-8 w-8 mr-4"
                ></app-icon>

                {{ title }}
            </div>

            <div v-if="retrievingGuests" class="flex items-center justify-center">
                <app-icon
                    v-theme="['wall.text']"
                    name="loader"
                    class="w-10 h-10"
                ></app-icon>
            </div>

            <div v-else-if="guests.length === 0" class="flex items-center justify-center">
                <p v-theme="['wall.text']">{{ $t('label-no-attendees-yet') }}</p>
            </div>

            <div v-else class="flex flex-col items-center justify-center px-4">
                <div v-if="!event.isPublished" class="badge badge-success mb-4">Displaying test submissions.</div>

                <div class="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-y-2 gap-x-8">
                    <p
                        v-for="guest in guests"
                        :key="guest.id"
                        v-theme="['wall.text']"
                    >
                        {{ guest.formalName }}
                    </p>
                </div>

                <div class="mt-6 grid grid-cols-2 grid-flow-row gap-y-2 gap-x-8">
                    <button
                        v-if="previousPage"
                        v-theme="['wall.text']"
                        class="flex items-center justify-center"
                        @click="retrieveGuests(previousPage)"
                    >
                        <app-icon
                            class="h-6 w-6 mr-2"
                            name="arrow-left-chevron"
                            stroke
                        ></app-icon>

                        {{ $t('label-previous-page') }}
                    </button>
                    <div v-else></div>
                    <button
                        v-if="nextPage"
                        v-theme="['wall.text']"
                        class="flex items-center"
                        @click="retrieveGuests(nextPage)"
                    >
                        {{ $t('label-next-page') }}

                        <app-icon
                            class="h-6 w-6 ml-2"
                            name="arrow-right-chevron"
                            stroke
                        ></app-icon>
                    </button>
                </div>
            </div>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import WallBlock from '@/mixins/WallBlock';
import axios from '@/util/axios';

export default {
    name: 'WallGuestListBlock',

    mixins: [WallBlock],

    props: {
        displayTitle: {
            type: Boolean,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        sort: {
            type: String,
            default: '-submissionTimestamp'
        }
    },

    data () {
        return {
            guests: [],
            retrievingGuests: false,
            nextPage: null,
            previousPage: null
        };
    },

    computed: {
        ...get('Event/*')
    },

    watch: {
        sort () {
            this.retrieveGuests(null);
        },

        'event.isPublished': function () {
            this.retrieveGuests(null);
        }
    },

    mounted () {
        this.retrieveGuests(this.nextPage);
    },

    methods: {
        retrieveGuests (page) {
            this.retrievingGuests = true;

            return axios.post(this.route('api.walls.get-guest-list', { event: this.event, page }), {
                sort: this.sort
            }).then(({ data }) => {
                this.guests = data.data;
                this.nextPage = data.meta.current_page !== data.meta.last_page ? data.meta.current_page + 1 : null;
                this.previousPage = data.meta.current_page !== 1 ? data.meta.current_page - 1 : null;
            }).catch(() => {
                this.$toasted.global.error('There was an error retrieving attendees for this event.');
            }).finally(() => {
                this.retrievingGuests = false;
            });
        }
    }
};
</script>
