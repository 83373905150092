<template>
    <base-dropdown
        class="form-field w-full"
        placement="bottom-start"
    >
        <template slot="trigger" slot-scope="{ triggerFunction }">
            <button
                class="uppercase tracking-wide text-gray-500 flex items-center text-sm"
                @click.stop="triggerFunction"
            >
                <template v-if="selectedAnswer">
                    <app-icon
                        v-if="selectedAnswer.settings.icon"
                        :name="selectedAnswer.settings.icon"
                        class="w-4 h-4 mr-3"
                    ></app-icon>

                    {{ selectedAnswer.title }}
                </template>

                <template v-else>No response</template>

                <app-icon
                    name="arrow-down-chevron"
                    class="ml-2 h-2 w-2"
                    stroke
                ></app-icon>
            </button>
        </template>

        <template #default="{ triggerFunction }">
            <div class="bg-white rounded-md border shadow cursor-pointer text-sm mt-1 max-h-sm overflow-y-auto z-90">
                <button
                    v-for="option in question.options"
                    :key="option.id"
                    class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                    @click.stop="selectOption(option, triggerFunction)"
                >
                    <app-icon
                        v-if="option.settings.icon"
                        :name="option.settings.icon"
                        class="w-4 h-4 mr-3"
                    ></app-icon>

                    {{ option.title }}

                    <app-icon
                        v-if="selectedAnswer && selectedAnswer.id === option.id"
                        name="check-circle-filled"
                        class="ml-4 text-purple"
                    ></app-icon>
                </button>

                <button
                    class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                    @click="clearResponse(triggerFunction)"
                >
                    No response

                    <app-icon
                        v-if="!selectedAnswer"
                        name="check-circle-filled"
                        class="ml-4 text-purple"
                    ></app-icon>
                </button>
            </div>
        </template>
    </base-dropdown>
</template>

<script>
import find from 'lodash/find';
import CustomQuestionEditComponent from '@/mixins/CustomQuestionEditComponent';

export default {
    name: 'CustomQuestionAnswerDropdown',

    mixins: [CustomQuestionEditComponent],

    data () {
        return {
            answer: this.answers.length > 0 ? this.answers[0].id : null
        };
    },

    computed: {
        selectedAnswer () {
            return find(this.question.options, { id: this.answer });
        }
    },

    methods: {
        clearResponse (triggerFunction) {
            this.answer = null;

            triggerFunction();
        },

        selectOption (option, triggerFunction) {
            this.answer = option.id;

            triggerFunction();
        }
    }
};
</script>
