/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'messages-user-bubble-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>chat</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.5 3.75a3 3 0 100 6 3 3 0 100-6zM8.25 17.25V15v0c0-2.08-1.68-3.75-3.75-3.75C2.42 11.25.75 12.92.75 15v2.25h1.5l.75 6h3l.75-6zM17.25.75h0a5.96 5.96 0 00-6 5.94c-.01 1.46.52 2.88 1.49 3.98l-.76 4.31 3.91-2.42-.01-.01A6.005 6.005 0 0018.64.86 5.79 5.79 0 0017.2.69zM15.75 5.25h3M15.75 8.25h3"/></g>'
  }
})
