<template>
    <a
        role="button"
        class="block p-1 leading-0 rounded-full text-white bg-purple transition duration-150 ease-in-out cursor-pointer hover:bg-purple-light"
        @click="click"
    >
        <app-icon
            name="settings-cog"
            :class="iconClasses"
        ></app-icon>
    </a>
</template>

<script>
export default {
    props: {
        block: {
            type: Object,
            default: () => { return {}; }
        },
        iconClasses: {
            type: [Array, String, Object],
            default: 'h-4 w-4'
        },
        selectionData: {
            type: Object,
            default: () => { return {}; }
        }
    },

    methods: {
        click () {
            this.$emit('click');
            App.$emit('select-block', { block: this.block, selectionData: this.selectionData });
        }
    }
};
</script>
