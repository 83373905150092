/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'breakfast-croissant': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food pastry bakery</desc><g _fill="currentColor"><path pid="0" d="M1.355 11.491a.253.253 0 00-.413.114A11.3 11.3 0 00.5 14.7c0 4.218 3.383 7.333 4.378 6.926A1 1 0 005.5 20.7v-2a4.968 4.968 0 01.688-2.526.24.24 0 00-.043-.3zM23.058 11.605a.253.253 0 00-.413-.114l-4.79 4.375a.24.24 0 00-.043.3A4.968 4.968 0 0118.5 18.7v2a1 1 0 00.622.926c1.057.432 4.378-2.774 4.378-6.926a11.3 11.3 0 00-.442-3.095zM17.94 3.675a13.882 13.882 0 00-11.88 0 .343.343 0 00-.177.452l4.884 10.59a.329.329 0 00.3.193h1.862a.329.329 0 00.3-.194l4.888-10.589a.343.343 0 00-.177-.452zM5.072 5.95a.257.257 0 00-.394-.092 11.592 11.592 0 00-2.942 3.7.259.259 0 00.057.3l5.347 4.885a.242.242 0 00.313.009 5.058 5.058 0 011.1-.642.242.242 0 00.13-.327zM22.264 9.556a11.593 11.593 0 00-2.942-3.7.257.257 0 00-.394.092l-3.613 7.833a.244.244 0 00.131.327 5.058 5.058 0 011.1.642.242.242 0 00.313-.009l5.348-4.881a.256.256 0 00.057-.304z"/></g>'
  }
})
