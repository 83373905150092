/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-plane-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>event date time appointment travel</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 17.12L8 18.25M15.949 15.16l-1.73.82-3.75-3.37-2.093.82 3.04 3.63-3.44 1.15 1.8 1.62h0c.42.37 1.02.48 1.55.28l5.69-2.2h-.01a1.51 1.51 0 00-1.11-2.81z"/></g><g stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="1" width="22.5" height="19.5" x=".75" y="3.75" rx="1.5"/><path pid="2" d="M.75 9.75h22.5"/><g stroke-linecap="round"><path pid="3" d="M6.75 6V.75M17.25 6V.75"/></g></g>'
  }
})
