<template>
    <button
        v-theme="['form.button']"
        class="border border-smoke-200 shadow-md rounded-md p-2 flex items-center mx-auto relative disabled:cursor-not-allowed"
        :disabled="disabled"
        @click.stop="$emit('click')"
    >
        <div v-if="loading" class="absolute inset-0 flex items-center justify-center">
            <app-icon name="loader" class="w-5 h-5 fill-current"></app-icon>
        </div>

        <slot>
            <app-icon
                class="mx-2 h-6 w-6"
                :class="{ invisible: loading }"
                name="check-circle"
            ></app-icon>

            <div class="mx-2 text-left text-sm" :class="{ invisible: loading }">
                <div v-if="showCompleteButtonMessage" class="capitalize">
                    {{ $t('button-submit') }}
                </div>
                <template v-else>
                    <span class="hidden sm:inline-block">{{ $t('button-press-enter') }}</span>
                    <span class="sm:hidden">{{ $t('button-continue') }}</span>
                </template>
            </div>
        </slot>
    </button>
</template>

<script>
export default {
    name: 'RsvpNextButton',

    props: {
        disabled: {
            type: Boolean,
            default: false
        },

        loading: {
            type: Boolean,
            default: false
        },

        showCompleteButtonMessage: {
            type: Boolean,
            default: false
        }
    }
};
</script>
