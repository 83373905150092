/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music-note-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9 15a3.75 3.75 0 100 7.5A3.75 3.75 0 109 15zM12.75 18.75V1.5M12.75 2.867l3.57 2.184c2.439 1.88 3.1 4.51 1.7 6.81v0"/></g>'
  }
})
