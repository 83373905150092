/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tablet-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.744 18.75h16.5"/><rect pid="1" x="3.747" y=".75" width="16.5" height="22.5" rx="1.5" ry="1.5" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="2" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7.497 5.25h3v3h-3zM13.497 5.25h3v3h-3zM7.497 11.25h3v3h-3z"/>'
  }
})
