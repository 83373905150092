/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-woman-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .875a2.56 2.56 0 100 5.13 2.56 2.56 0 100-5.14zM13.925 20.125l.64-6.417h1.92v-1.93h-.01c0-1.52-.76-2.93-2.03-3.76l-2.47 3.75L9.5 8v-.01a4.478 4.478 0 00-2.03 3.75v1.92h1.92l.64 6.41zM9.433 3.44s0 3.2-1.93 3.2M14.567 3.44s0 3.2 1.92 3.2M1 6.12v4M5 8.12H1M23 6.12v4M19 8.12h4"/><path pid="1" d="M5 16.039c-2.443.73-4 1.84-4 3.08 0 2.2 4.925 4 11 4 6.07 0 11-1.8 11-4 0-1.25-1.56-2.36-4-3.09"/></g>'
  }
})
