/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'like-shine-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>thumb up</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.5 6.75v-3M18 5.25h3M6.75 3.75v-3M5.25 2.25h3M2.25 9V6M.75 7.5h3M.75 12h2.14l-.01-.001c4.37 0 7.94-3.5 8.03-7.87 0-1.6 3.215-1.8 3.215 1.22v-.01c-.17 1.96-.53 3.91-1.08 5.81l-.03.07h8.7-.01c.82-.01 1.5.67 1.5 1.5 0 .82-.68 1.5-1.5 1.5h-1.51c.82-.01 1.5.67 1.5 1.5 0 .82-.68 1.5-1.5 1.5h-.77c.82-.01 1.5.67 1.5 1.5 0 .82-.68 1.5-1.5 1.5h-.76c.82-.01 1.5.67 1.5 1.5 0 .82-.68 1.5-1.5 1.5h-7.29c-4.285 0-2.15-2.25-10.714-2.25"/></g>'
  }
})
