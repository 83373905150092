<template>
    <div v-show="isActive" class="p-4">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'TabListItem',

    inject: ['tabListState'],

    props: {
        active: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        tooltip: {
            type: String,
            default: null
        }
    },

    computed: {
        isActive () {
            return this.id === this.tabListState.activeId;
        }
    }
};
</script>
