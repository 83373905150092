/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'team-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>group users people meeting</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 14.25a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM16.224 23.25h0a4.469 4.469 0 00-5.7-2.75c-1.29.44-2.3 1.46-2.75 2.74M4.97.75a2.62 2.62 0 100 5.25 2.62 2.62 0 100-5.25zM9.2 9.75h0a4.482 4.482 0 00-8.46-.01zM19.02.75a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM23.25 9.75h0a4.482 4.482 0 00-8.46-.01zM3.75 12.75v3.75h0c0 1.24 1 2.24 2.25 2.24h.75M20.25 12.75v3.75c0 1.24-1.01 2.25-2.25 2.25h-.75"/></g>'
  }
})
