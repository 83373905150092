/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-cheese-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>dairy</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 11.351L12.26 1.23h-.01c.3-.28.71-.41 1.12-.38 4.4.405 7.9 2.519 9.857 7.49"/><path pid="1" d="M.75 11.351v4.06H.74c-.01.59.35 1.13.9 1.37l-.01-.01c1.13.49 1.65 1.82 1.16 2.96-.29.66-.88 1.14-1.59 1.29h-.01a.59.59 0 00-.48.58H.7c-.01.82.67 1.5 1.49 1.5.06 0 .13-.01.2-.02l19.5-2.6v-.01c.74-.11 1.3-.74 1.3-1.49V8.29z"/><path pid="2" d="M10.875 15.85a1.875 1.875 0 100 3.75 1.875 1.875 0 100-3.75zM18.375 11.35a1.875 1.875 0 100 3.75 1.875 1.875 0 100-3.75zM6.375 13.6l-.01-.001c.2 0 .37.16.37.37-.01.2-.17.37-.38.37a.386.386 0 01-.38-.38h0c-.01-.21.16-.38.37-.38M16.875 18.1h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M13.125 12.851h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38"/></g>'
  }
})
