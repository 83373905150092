<template>
    <div class="w-full mx-auto p-4 md:max-w-xl text-center tracking-wider space-y-8">
        <div>
            <h1 class="text-2xl font-bold mb-4">Confirm subscription cancellation</h1>
            <p class="text-gray-600 text-sm">We're sorry to see you go, but understand that all great events must come to an end. We'd truly appreciate your feedback...</p>
        </div>

        <form-field-wrapper
            :error="form.errors.get('cancel_reason_id')"
            class="mb-8"
        >
            <p class="font-bold mb-4 uppercase">Why are you canceling your subscription?</p>
            <select-list v-model="form.cancel_reason_id" class="text-gray-600 text-sm space-y-4">
                <select-option
                    v-for="cancelReason in cancelReasons"
                    :id="cancelReason.id"
                    :key="cancelReason.id"
                    :label="cancelReason.reason"
                ></select-option>
            </select-list>
        </form-field-wrapper>

        <form-field-wrapper
            :error="form.errors.get('rating')"
        >
            <p class="font-bold mb-4 uppercase">How likely are you to recommend RSVPify to a friend or colleague?</p>
            <div class="">
                <select-list
                    v-model="form.rating"
                    class="text-gray-600 space-x-4 justify-center text-sm"
                    :vertical="false"
                >
                    <p class="text-xs">Would NOT recommend</p>
                    <select-option
                        v-for="rating in 10"
                        :id="rating"
                        :key="rating"
                        :label="rating.toString()"
                    ></select-option>
                    <p class="text-xs">Would HIGHLY recommend</p>
                </select-list>
            </div>
        </form-field-wrapper>

        <div>
            <p class="font-bold uppercase">Any additional feedback you'd be willing to share? (It'd mean the world to us!)</p>
            <p class="text-xs italic">Optional</p>
            <multi-line-text-input v-model="form.note"></multi-line-text-input>
        </div>

        <div>
            <stateful-button
                class="w-full button button-primary mb-4"
                :loading="form.processing"
                :disabled="!formIsComplete"
                @click="cancel"
            >
                Confirm &amp; complete cancellation
            </stateful-button>

            <a :href="route('account.show')" class="button-text button-primary">Actually, I'd like to keep my current subscription</a>

        </div>
    </div>
</template>

<script>
import Form from '@/validation/Form';

export default {
    name: 'CancelPlanConfirm',

    props: {
        cancelReasons: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            form: new Form({
                cancel_reason_id: null,
                rating: null,
                note: ''
            })
        };
    },

    computed: {
        formIsComplete () {
            return this.form.cancel_reason_id && this.form.rating;
        }
    },

    methods: {
        cancel () {
            this.form.delete(this.route('api.account.cancel-plan'))
                .then(() => {
                    this.$emit('submit');
                });
        }
    }
};
</script>
