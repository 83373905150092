/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>arrow right</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M.75 12h22.5M12.75 22.5L23.25 12 12.75 1.5"/>'
  }
})
