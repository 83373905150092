/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-basket-edit-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>cart</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M22.63 14.86L15 22.49l-3.75.75.75-3.75 7.63-7.631h-.01c.82-.83 2.16-.83 2.99-.01h0c.82.82.82 2.16 0 2.99zM7.875 17.25H4.314h0c-.67-.02-1.25-.49-1.39-1.14l-2.14-9h0c-.22-.8.26-1.61 1.05-1.82.1-.03.21-.05.32-.05h18.14c.81.02 1.46.71 1.43 1.53-.01.11-.03.22-.05.32l-.3 1.25M3.75 5.25l4.5-4.5M18.75 5.25l-4.5-4.5"/></g>'
  }
})
