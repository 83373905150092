export default {
    methods: {
        toggleBodyElementClasses (classes, isActive) {
            const bodyElement = window.document.querySelector('body');

            if (isActive) {
                bodyElement.classList.add(...classes);
            } else {
                bodyElement.classList.remove(...classes);
            }
        }
    }
};
