<template>
    <div :class="classWrapper">
        <label v-if="showLabel" class="flex items-center justify-between relative mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">
            <slot name="label">
                <template v-if="hasTooltip">
                    <span v-if="withTooltipIcon">
                        <span>{{ label }}</span>

                        <app-icon
                            v-tippy
                            name="info-circle"
                            class="h-4 w-4 ml-2"
                            :content="tooltip"
                            stroke
                        ></app-icon>
                    </span>
                    <span
                        v-else
                        v-tippy
                        :content="tooltip"
                        class="tooltip-text"
                    >
                        {{ label }}
                    </span>
                </template>
                <template v-else>{{ label }}</template>
            </slot>
        </label>

        <div class="block w-full">
            <slot></slot>
        </div>

        <div
            v-if="hasSlot('help')"
            class="flex items-center relative mt-3 ml-2px text-gray-600 leading-normal italic"
        >
            <slot name="help"></slot>
        </div>

        <div v-if="showError" class="alert alert-error alert-sm mt-2">
            <tiptap-editor
                v-if="rawErrorOutput"
                class="w-full font-normal text-center"
                :value="error"
                read-only
            ></tiptap-editor>
            <p v-else class="w-full font-normal text-center">{{ error }}</p>
        </div>
    </div>
</template>

<script>
import HandleErrorMessages from '@/mixins/HandleErrorMessages';
import SlotHelpers from '@/mixins/SlotHelpers';

export default {
    name: 'FormFieldWrapper',

    mixins: [SlotHelpers, HandleErrorMessages],

    props: {
        classWrapper: {
            type: String,
            default: 'form-field-wrapper'
        },

        label: {
            type: String,
            default: ''
        },

        rawErrorOutput: {
            type: Boolean,
            default: false
        },

        tooltip: {
            type: String,
            default: ''
        },

        withTooltipIcon: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        hasLabel () {
            return this.label !== '';
        },

        hasTooltip () {
            return this.tooltip !== '';
        },

        showLabel () {
            return this.hasLabel || (!this.hasLabel && this.hasSlot('label'));
        },

        labelStyle () {
            if (!this.withTooltipIcon) {
                return `tooltip-text`;
            }
        }
    }
};
</script>
