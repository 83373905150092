<template>
    <div class="container">
        <section class="panel">
            <page-header icon="money-bag-dollar-stroke" label="Payment Currencies">
                <a :href="route('admin.dashboard')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <div class="row mx-0">
                <div class="col-12 md:col-4 px-0 mb-4">
                    <search-field
                        v-model="searchTerm"
                        class="w-full"
                        placeholder="Find a currency..."
                    ></search-field>
                </div>

                <div class="col-12 mb-4 md:col-8">
                    <div class="flex w-full justify-end">
                        <toggle-switch
                            v-model="displayOnlyActiveCurrencies"
                            label-position="left"
                        >
                            Display Only Active Currencies
                        </toggle-switch>
                    </div>
                </div>
            </div>

            <div class="row">
                <div
                    v-show="filteredCurrencies.length === 0"
                    class="col-12 items-center text-yellow-dark text-lg my-8 font-semibold"
                >
                    <app-icon
                        name="alert-triangle"
                        class="h-8 w-8 mr-2"
                    ></app-icon>
                    <span>No currencies found</span>
                </div>

                <div
                    v-for="currency in filteredCurrencies"
                    :key="currency.id"
                    class="col-12 md:col-6 mt-4"
                >
                    <div class="flex w-full items-center p-4 rounded-md border shadow">
                        <p class="flex-1">{{ currency.name }} ({{ currency.currency_code }})</p>

                        <toggle-switch
                            v-if="displayCurrency(currency)"
                            v-model="currency.active"
                            @input="updatePaymentCurrency(currency)"
                        ></toggle-switch>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from '@/util/axios';

export default {
    name: 'PaymentCurrenciesList',

    props: {
        initialCurrencies: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            searchTerm: '',
            currencies: this.initialCurrencies,
            displayOnlyActiveCurrencies: false
        };
    },

    computed: {
        filteredCurrencies () {
            const currencies = (!this.displayOnlyActiveCurrencies)
                ? this.currencies
                : this.currencies.filter((currency) => { return currency.active; });

            return currencies.filter((currency) => {
                const searchRegex = new RegExp(this.searchTerm, 'i');

                return searchRegex.test(currency.name) || searchRegex.test(currency.currency_code);
            });
        }
    },

    methods: {
        displayCurrency (currency) {
            return currency.currency_code !== 'USD';
        },

        updatePaymentCurrency (paymentCurrency) {
            axios
                .put(this.route('admin.payment-currencies.update', paymentCurrency), paymentCurrency)
                .catch(() => {
                    this.$toasted.global.error('There was an error updating the currency.');
                });
        }
    }
};
</script>
