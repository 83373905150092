/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button-stop-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>button stop stroke</desc><circle pid="0" cx="12" cy="11.998" r="11.25" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><rect pid="1" x="8.25" y="8.248" width="7.5" height="7.5" rx=".75" ry=".75" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>'
  }
})
