/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'technology-mobile-phone-qr-code': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>check-in scan</desc><g _fill="currentColor"><path pid="0" d="M7.5 9.375h2a.625.625 0 00.625-.625v-2a.625.625 0 00-.625-.625h-2a.625.625 0 00-.625.625v2a.625.625 0 00.625.625zm.625-2h.75v.75h-.75zM9.5 13.125h-2a.625.625 0 00-.625.625v2a.625.625 0 00.625.625h2a.625.625 0 00.625-.625v-2a.625.625 0 00-.625-.625zm-.625 2h-.75v-.75h.75zM16.5 6.125h-2a.625.625 0 00-.625.625v2a.625.625 0 00.625.625h2a.625.625 0 00.625-.625v-2a.625.625 0 00-.625-.625zm-.625 2h-.75v-.75h.75zM11.5 11.125h-4a.625.625 0 000 1.25h3.375v.375a.625.625 0 001.25 0v-1a.625.625 0 00-.625-.625zM16.5 12.125h-1a.625.625 0 000 1.25h.375v1.75h-1.75V12.75a.625.625 0 00-1.25 0v3a.625.625 0 00.625.625h3a.625.625 0 00.625-.625v-3a.625.625 0 00-.625-.625zM11.5 14.125a.625.625 0 00-.625.625v1a.625.625 0 001.25 0v-1a.625.625 0 00-.625-.625zM13.125 9.75a.625.625 0 00-.625-.625h-.375V6.75a.625.625 0 00-1.25 0v3a.625.625 0 00.625.625h1a.625.625 0 00.625-.625zM16.5 10.125h-2a.625.625 0 000 1.25h2a.625.625 0 000-1.25z"/><path pid="1" d="M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z"/></g>'
  }
})
