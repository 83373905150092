/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-quarantine-calendar-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 12.94a2.71 2.71 0 100 5.42 2.71 2.71 0 100-5.43zM11.55 10.91h.9M12 10.91v2.03M15.04 11.98l.64.64M15.36 12.3l-1.44 1.44M16.75 15.21v.9M16.75 15.66h-2.04M15.68 18.7l-.64.64M15.36 19.02l-1.44-1.44M12.45 20.41h-.9M12 20.41v-2.04M8.96 19.34l-.64-.64M8.64 19.02l1.44-1.44M7.25 16.11v-.9M7.25 15.66h2.04M8.32 12.62l.64-.64M8.64 12.3l1.44 1.44"/><rect pid="1" width="22.5" height="20.375" x=".75" y="2.82" rx="1.5"/></g><g stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="2" d="M.75 8.32h22.5"/><g stroke-linecap="round"><path pid="3" d="M7.25 4.8V.81M16.25 4.8V.81"/></g></g>'
  }
})
