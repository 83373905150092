<template>
    <div class="my-8">
        <form-field-wrapper
            label="Legalese"
            :error="form.errors.get('question.settings.termsConditions.legalese')"
            :should-show-error="form.errors.has('question.settings.termsConditions.legalese')"
        >
            <textarea
                :value="settings.legalese"
                class="form-field"
                name="legalese"
                placeholder="Enter the legalese text..."
                rows="5"
                @input="newLegalese => updateSetting('legalese', newLegalese.target.value)"
            ></textarea>
        </form-field-wrapper>

        <form-field-wrapper
            label="Confirmation"
            :error="form.errors.get('question.settings.termsConditions.confirmation')"
            :should-show-error="form.errors.has('question.settings.termsConditions.confirmation')"
        >
            <input
                :value="settings.confirmation"
                class="form-field"
                name="confirmation"
                placeholder="Enter the confirmation text..."
                type="text"
                @input="newConfirmation => updateSetting('confirmation', newConfirmation.target.value)"
            >
        </form-field-wrapper>
    </div>
</template>

<script>
import CustomQuestionSettings from '@/mixins/CustomQuestionSettings';

export default {
    name: 'FormTermsConditionsQuestionSettings',

    mixins: [CustomQuestionSettings],

    computed: {
        namespace () {
            return 'termsConditions';
        }
    }
};
</script>
