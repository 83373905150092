/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'award-medal-first-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 9.75a6.75 6.75 0 100 13.5 6.75 6.75 0 100-13.5z"/><path pid="1" d="M5.851 13.71L.75 5.24l3-4.5 5.58 9.549M10.5 13.5h.75-.01c.41-.01.75.33.75.75v5.25M10.5 19.5h3M18.149 13.71l5.1-8.47-3-4.5-5.59 9.549M20.25.75H3.75"/></g>'
  }
})
