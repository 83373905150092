<template>
    <div class="w-full">
        <template v-if="isEditing && isEditable">
            <component
                :is="editComponent"
                :question="question"
                :answers="answers"
                @input="input"
            ></component>
        </template>

        <template v-else-if="answers == null">
            No response
        </template>

        <template v-else>
            <div
                v-for="answer in answers"
                :key="answer.id"
                class="relative"
            >
                <template v-if="question.slug === 'file-uploads' && answer.meta.displayable">
                    <a
                        class="absolute top-1 right-1 rounded px-2 py-1 bg-white text-gray-800"
                        :href="answer.value"
                        target="_blank"
                    >
                        <app-icon class="w-4 h-4" name="download-cloud"></app-icon>
                    </a>

                    <img :src="answer.value" class="max-w-full">
                </template>

                <template v-else-if="question.slug === 'file-uploads'">
                    <a
                        class="text-purple underline"
                        :href="answer.value"
                        target="_blank"
                    >{{ answer.meta.filename }}</a>
                </template>

                <template v-else-if="answer.value !== null">
                    {{ getAnswerValue(question, answer) }}
                </template>

                <span v-else class="text-gray-300">No response</span>
            </div>
        </template>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'CustomQuestionAnswer',

    props: {
        isEditing: {
            type: Boolean,
            default: false
        },
        question: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            answers: cloneDeep(this.question.answers)
        };
    },

    computed: {
        editComponent () {
            return `custom-question-answer-${this.editComponentSuffix}`;
        },

        isEditable () {
            return this.question.type.slug !== 'cash-donation-gift';
        },

        editComponentSuffix () {
            switch (this.question.type.slug) {
                case 'select-one':
                    return 'radio';

                case 'dropdown':
                    return 'dropdown';

                case 'file-uploads':
                    return 'file-uploads';

                case 'select-multiple':
                    return 'checkbox';

                case 'date-time':
                    return 'datetime';

                case 'textarea':
                    return 'textarea';

                case 'quantity':
                    return 'quantity';

                case 'mailing-address':
                    return 'mailing-address';

                case 'donor-information':
                    return 'donor-information';

                default:
                    return 'text';
            }
        }
    },

    methods: {
        input (answers) {
            this.$emit('input', answers);
        },

        getAnswerValue (question, answer) {
            if (question.slug === 'quantity' && parseInt(answer.raw_value) === 0) {
                return;
            }

            return answer.value;
        }
    }
};
</script>
