/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-symptoms-virus-loss-smell-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.409 17.5v-.01c.4-.47.74-.98 1-1.54M11.3 20.058v-.01c.71-.19 1.4-.48 2.04-.85M21.11 21.08h-.01c-.05.7.09 1.41.41 2.04M22.552 16.98c-.35.68-.69 1.36-.95 2.04M16.416 22.1v-.01c-.55.56-1.27.92-2.05 1.02M18.461 19.035h-.01c-.2.53-.46 1.04-.76 1.53M22.46.87s-2.82 5.82-5.48 7.82c-3.128 2.346-1.564 5.47.782 5.47h5.47M19.578 11.1c.76-1.03 1.53-1.03 3.06-1.03M6.375.87a5.625 5.625 0 100 11.25 5.625 5.625 0 100-11.25zM2.4 10.48l7.95-7.96"/></g>'
  }
})
