<template>
    <div
        class="flex flex-col border-4 rounded-md mb-4 mx-2 w-[236px]"
        :class="cardStyle"
        :data-plan-id="plan.id"
    >
        <div class="flex flex-col grow">
            <div class="pb-1 uppercase tracking-wide text-sm text-center text-white plan-highlight-label" :class="cardHeaderStyle">
                {{ spot.highlighted_as }}&nbsp;
            </div>

            <div class="py-4 font-semibold text-3xl text-center plan-name">
                {{ plan.name }}
            </div>

            <p v-if="showDescriptionContainer" class="block sm:h-12 text-sm text-center mb-4 px-4">{{ plan.description }}</p>

            <div
                class="flex px-4"
                :class="{
                    'sm:h-20': expandedPricingSection,
                    'sm:h-15': !expandedPricingSection,
                    'flex-col': plan.billing_period === 'custom'
                }"
            >
                <div class="flex-1 text-4xl text-center pr-2 plan-price">
                    <template v-if="plan.billing_period === 'custom'">{{ plan.price }}</template>
                    <template v-else-if="plan.billing_period === 'free'">$0</template>
                    <template v-else>{{ adjustedPrice | currency(true) }}</template>
                </div>

                <div>
                    <div
                        v-if="plan.billing_info_line_1 || plan.billing_info_line_2 || plan.billing_info_line_3"
                        class="flex-1 flex flex-col pl-2"
                        :class="{
                            'text-center': plan.billing_period === 'custom',
                            'border-l': plan.billing_period !== 'custom',
                        }"
                    >
                        <div
                            v-if="plan.billing_info_line_1"
                            class="text-sm"
                            v-html="plan.billing_info_line_1"
                        ></div>

                        <div
                            v-if="plan.billing_info_line_2"
                            class="text-gray-500 text-xs"
                            v-html="plan.billing_info_line_2"
                        ></div>

                        <div
                            v-if="plan.billing_info_line_3"
                            class="text-gray-500 text-xs"
                            v-html="plan.billing_info_line_3"
                        ></div>
                    </div>
                </div>
            </div>

            <div class="p-4 plan-details-description" v-html="plan.details_html"></div>
        </div>

        <div class="flex-col shrink">
            <div class="flex items-center justify-center w-full">
                <div v-if="isCurrentPlan" class="flex flex-col w-full p-2">
                    <a
                        v-if="plan.hasAnnualPlan"
                        :href="route('account.upgrade.preview',{ plan_segment: spot.plan_segment_id, plan: plan.linked_plan.id })"
                        class="text-xs text-blue hover:text-blue-dark transition flex items-center space-x-1 justify-center"
                    >
                        <app-icon
                            name="discount-bubble"
                            stroke
                            class="h-5 w-5"
                        ></app-icon>
                        <span>Switch to annual <strong>and save {{ plan.savingsOverMonthly }}%</strong></span>
                    </a>
                    <button class="mt-2 button normal-case text-xl p-4 w-full cursor-default hover:bg-gray-200" disabled>Current Plan</button>
                </div>

                <template v-else-if="!spot.hide_cta">
                    <contact-support v-if="spot.requires_contact" class="m-2 w-full">
                        <template #default="{ openChatWindow }">
                            <button
                                v-if="spot.requires_contact"
                                class="button button-blue normal-case text-xl p-4 w-full"
                                @click="openChatWindow"
                            >
                                Chat Now
                            </button>
                        </template>
                    </contact-support>

                    <a
                        v-else-if="spot.non_profit_cta"
                        class="button button-blue normal-case text-xl p-4 w-full m-2"
                        target="_blank"
                        href="https://help.rsvpify.com/en/articles/5481764-does-rsvpify-offer-a-nonprofit-discount"
                    >
                        Learn More
                    </a>

                    <a
                        v-else-if="!plan.isFree"
                        :href="route('account.upgrade.preview', [spot.plan_segment_id, plan.id])"
                        class="button button-blue normal-case text-xl p-4 m-2 w-full"
                    >
                        {{ buttonText }}
                    </a>
                </template>
            </div>
            <button
                class="w-full text-center text-sm pb-2 flex items-center justify-center space-x-1.5"
                :class="{ invisible: !spot.show_anchor_link }"
                @click="$emit('scroll-to-features')"
            >
                <span>{{ plan.name }} Features</span>

                <app-icon
                    name="arrow-down-chevron"
                    class="h-4 w-4 text-gray-500"
                    stroke
                ></app-icon>
            </button>
        </div>
    </div>
</template>

<script>
import { tippy } from '@node_modules/vue-tippy';

export default {
    name: 'PlanCard',

    props: {
        annual: {
            type: Boolean,
            required: true
        },
        expandedPricingSection: {
            type: Boolean,
            default: false
        },
        spot: {
            type: Object,
            required: true
        },
        plan: {
            type: Object,
            required: true
        },
        showDescriptionContainer: {
            type: Boolean,
            default: false
        },
        userPlan: {
            type: Object,
            required: true
        }
    },

    computed: {
        adjustedPrice () {
            return this.annual ? this.plan.price / 12 : this.plan.price;
        },

        buttonText () {
            // If the user plan is linked to the this plan, the price no longer matters
            // when determining if this is an upgrade or downgrade.  If the linked plan
            // is an annual plan, it's a upgrade else it's a downgrade.
            if (this.userPlan && this.userPlan.linked_plan_id === this.plan.id) {
                return this.plan.isMonthly ? 'Downgrade' : 'Upgrade';
            }

            if (!this.userPlan || !this.userPlan.id) {
                return `Upgrade`;
            }

            const currentPrice = this.resolvePlanPrice(this.userPlan);
            const newPrice = this.resolvePlanPrice(this.plan);

            if (currentPrice <= newPrice) {
                return 'Upgrade';
            }

            return 'Downgrade';
        },

        cardStyle () {
            return this.spot.highlighted_as !== null
                ? 'border-blue'
                : 'border-gray-200';
        },

        cardHeaderStyle () {
            return this.spot.highlighted_as !== null
                ? 'bg-blue'
                : 'bg-transparent';
        },

        isCurrentPlan () {
            return this.userPlan.id === this.plan.id;
        }
    },

    mounted () {
        document.querySelectorAll('[data-tooltip]').forEach((el) => {
            tippy(el, {
                content: el.dataset.tooltip
            });
        });
    },

    methods: {
        resolvePlanPrice (plan) {
            return plan.billing_period === 'free' ? 0 : parseInt(plan.price);
        }
    }
};
</script>
