<template>
    <div class="relative">
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <app-icon
                v-if="loading"
                name="loader"
                class="w-6 h-6 text-purple"
            ></app-icon>
        </div>

        <input
            v-model="form.mfaCode"
            class="form-field text-center appearance-no-spinners"
            :disabled="disableInput"
            type="number"
        >

        <p v-if="showError" class="w-full font-normal text-center text-red mt-1 text-sm">
            Sorry, this code is either invalid or expired. Please try again.
        </p>
    </div>
</template>

<script>
import Form from '@/validation/Form';

export default {
    name: 'MfaCodeField',

    data () {
        return {
            form: new Form({
                mfaCode: ''
            }),
            disableInput: false,
            loading: false,
            showError: false
        };
    },

    watch: {
        'form.mfaCode': function (newValue) {
            if (newValue.length === 6) {
                this.checkMfaCode();
            }
        }
    },

    methods: {
        async checkMfaCode () {
            try {
                this.disableInput = true;
                this.loading = true;
                this.showError = false;

                await this.form.post(this.route('auth.mfa-verify-login'));

                window.location.href = this.route('auth.finalize');
            } catch (error) {
                this.disableInput = false;
                this.loading = false;
                this.showError = true;
                this.form.mfaCode = '';
            }
        }
    }
};
</script>
