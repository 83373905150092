/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-confetti-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.53 7.21a2.625 5.25 0 100 10.5 2.625 5.25 0 100-10.5z" transform="matrix(.7071 -.70711 .7071 .7071 -5.44 11.806)"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="1" d="M7.4 9.7L.88 21.12H.87c-.35.75-.02 1.64.73 1.99.39.18.85.18 1.25-.01l11.42-6.51M15.773 7.7h0c.14.14.14.38 0 .53h-.01M15.24 7.7v-.01c.14-.15.38-.15.53 0M15.24 8.22l-.01-.01a.38.38 0 010-.54"/><path pid="2" d="M15.773 8.22h-.01c-.15.14-.39.14-.53-.01M20.01 3.45h0c.14.14.14.38 0 .52H20M19.48 3.45h-.01c.14-.15.38-.15.52-.01 0 0 0 0 0 0"/><path pid="3" d="M19.48 3.984h0a.377.377 0 01-.01-.53v-.01"/><path pid="4" d="M20.01 3.984H20c-.15.14-.39.14-.53-.01M20.01 14.06h0c.14.14.14.38 0 .53H20M19.48 14.06v-.01c.14-.15.38-.15.53 0M19.48 14.591l-.01-.01a.39.39 0 01-.01-.54v-.01"/><path pid="5" d="M20.01 14.591H20c-.15.14-.39.14-.53-.01M8.349 4.51h0c.14.14.14.38-.01.53M7.819 4.51V4.5c.14-.15.38-.15.53 0M7.819 5.045l-.01-.01a.39.39 0 01-.01-.54v-.01"/><path pid="6" d="M8.349 5.045h-.01c-.15.14-.39.14-.53-.01M12.85.75h0c.21 1.88.03 3.8-.54 5.61M16.57 2.13l-.53 2.65M23.25 11.14h0c-1.89-.22-3.81-.04-5.62.53M21.87 7.43l-2.65.53"/></g>'
  }
})
