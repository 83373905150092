<template>
    <div class="flex flex-col md:flex-row w-full rounded-md border bg-white">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'BlockSelect',

    provide () {
        return {
            blockSelectState: this.sharedState
        };
    },

    data () {
        return {
            sharedState: {
                selectedOptionId: null
            }
        };
    },

    watch: {
        'sharedState.selectedOptionId': function (newSelectedOptionId) {
            this.$emit('input', newSelectedOptionId);
        }
    }
};
</script>
