/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'animal-dog-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>puppy</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.96 21.53H4.02l-.01-.01c-.68-2.9-1.17-5.84-1.47-8.8l-.01-.01a3.66 3.66 0 013.66-3.68v-.01h6.6l-.01-.01c.71.05 1.39-.3 1.75-.92"/><path pid="1" d="M17.23 5.76c0 1.41.36 2.56-1.468 2.56-1.84 0-2.21-.74-1.47-2.94.44-1.34.87-2.94 3.66-2.94l-.01-.01c1.33.06 2.49.93 2.93 2.19l2.2.73s.82 4.081-2.94 3.668c-.74 2.93 0 5.13-2.2 6.6v5.86H19.4M10.63 13.467s0 2.93-3.668 2.93l-.86 5.13"/><path pid="2" d="M17.97 21.53h-2.94l-.74-5.87h-1.47M3.3 10.533s-3.671-.74-2.2-5.14"/></g>'
  }
})
