<template>
    <div>
        <infinite-data-table
            ref="table"
            class="text-gray-600"
            :columns="columns"
            has-select-all
            no-results-placeholder="Sorry, we couldn't find any tags."
            remote-sort
            selectable
            :sort="sort"
            :source="dataSource"
            @change-sort="handleChangedSort"
            @received-response="response => $emit('total', response.data)"
        >
            <template #header>
                <div class="w-full flex items-center space-x-2">
                    <portal-target
                        name="bulk-action-tags"
                        class="hidden md:block"
                    ></portal-target>

                    <div class="flex-auto"></div>

                    <button
                        class="button-icon button-lg button-info"
                        @click="tagModalOpen = true"
                    >
                        <app-icon name="add-circle" stroke></app-icon>
                    </button>
                </div>
            </template>

            <template #selectable="{ selectedAll, selectedIds, reload, total }">
                <portal to="bulk-action-tags">
                    <bulk-actions
                        :ids="selectedIds"
                        :all="selectedAll"
                        :total="total"
                        class="hidden md:block"
                        :disabled="actionsButton.disabled"
                        :disabled-tooltip="actionsButton.disabledTooltip"
                    >
                        <template #default="bulkActionData">
                            <bulk-delete
                                :bulk-action-data="bulkActionData"
                                :endpoint="route('api.events.tags.bulk.destroy', event)"
                                :delete-confirm-button-text="`DELETE ${bulkActionData.formatted.totalSelected} TAG(S).`"
                                :delete-message="`You are deleting ${bulkActionData.formatted.totalSelected} tag(s). These tags will be removed from invitees and confirmed guests. This cannot be undone.`"
                                :confirmation-message="`Deleting ${bulkActionData.formatted.totalSelected} tags(s).  This may take a few minutes.`"
                                @rows-deleted="reload"
                            ></bulk-delete>
                        </template>
                    </bulk-actions>
                </portal>
            </template>

            <template slot="row" slot-scope="{ row, property, value, reload }">
                <div v-if="property === 'options'">
                    <delete-item
                        v-if="auth().user().can('update.event')"
                        :confirmation-message="generateConfirmationMessage(row)"
                        :confirmation-settings="confirmationSettings"
                        :endpoint="route('api.events.tags.destroy', { event, tag: row })"
                        @delete-item="reload"
                    >
                        <app-icon
                            class="mr-2 w-6 h-6"
                            name="trash"
                            stroke
                        ></app-icon>
                    </delete-item>
                </div>

                <div v-else>
                    {{ value }}
                </div>
            </template>
        </infinite-data-table>

        <app-modal v-model="tagModalOpen" title="Create Tag">
            <form id="tag-form" @submit.prevent="addTag">
                <form-field-wrapper label="Tag" :error="form.errors.get('name')">
                    <input
                        v-model="form.name"
                        class="form-field"
                    >
                </form-field-wrapper>
            </form>

            <template #footer="{ close }">
                <stateful-button
                    class="button button-primary"
                    form="tag-form"
                    :loading="form.processing"
                    type="submit"
                >
                    Create Tag
                </stateful-button>

                <button class="button" @click="close">
                    Cancel
                </button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import Form from '@/validation/Form';
import BulkActionsButtonAuthorization from '@/mixins/BulkActionsButtonAuthorization';

export default {
    mixins: [BulkActionsButtonAuthorization],

    data () {
        return {
            columns: [
                {
                    label: 'Tag',
                    property: 'name',
                    desktop: true,
                    mobile: true,
                    sortable: true
                },
                {
                    label: '',
                    property: 'options',
                    desktop: true,
                    mobile: true,
                    sortable: false
                }
            ],
            form: new Form({
                name: ''
            }),
            sort: {},
            tagModalOpen: false
        };
    },

    computed: {
        ...get('Event/*'),

        dataSource () {
            return this.route('api.events.tags.index', this.event.id);
        },

        confirmationSettings () {
            return {
                confirmButtonText: 'Delete Tag',
                cancelButtonText: 'Cancel'
            };
        }
    },

    watch: {
        tagModalOpen () {
            this.form.restore();
        }
    },

    methods: {
        addTag () {
            this.form.post(this.route('api.events.tags.store', this.event))
                .then(() => {
                    this.$toasted.global.success(`${this.form.name} was created.`);
                    this.tagModalOpen = false;
                    this.form.restore();
                    this.$refs.table.reload();
                });
        },

        handleChangedSort ([newSort]) {
            this.sort = newSort || {};
        },

        generateConfirmationMessage (tag) {
            return {
                title: 'Are you sure?',
                text: `All invitees and guests tagged with "${tag.name}" will be untagged and this tag will no longer be available. If you've set a form question to be asked to only invitees tagged with "${tag.name}", the question will be asked to ALL invitees.`
            };
        }
    }
};
</script>
