<template>
    <app-modal
        :value="isActive"
        @input="$emit('input', $event)"
        @closed="form.restore()"
    >
        <template slot="header">
            Duplicate Event
            <div class="font-normal text-sm uppercase tracking-wider text-gray-600">{{ `${originalEvent.name}` }}</div>
        </template>

        <form-field-wrapper
            label="Name Your Event"
            :error="form.errors.get('name')"
        >
            <input
                v-model="form.name"
                class="form-field"
                placeholder="Event Name"
            >
        </form-field-wrapper>

        <form-field-wrapper label="RSVPify Event URL" :error="form.errors.get('subdomain')">
            <event-subdomain v-model="form.subdomain" validate-on-init></event-subdomain>
        </form-field-wrapper>

        <form-field-wrapper v-if="originalEventHasInvitees" :error="form.errors.get('duplicateInviteList')">
            <toggle-switch v-model="form.duplicateInviteList">
                <span
                    v-tippy
                    content="Invited guests' responses will be reset to ”Haven’t heard”"
                    class="tooltip-text"
                >
                    Also copy this event’s Invite List
                </span>
            </toggle-switch>
        </form-field-wrapper>

        <form-field-wrapper :error="form.errors.getAny(['starts_at', 'ends_at'])">
            <template slot="label">
                Time

                <toggle-switch
                    v-model="form.time_tbd"
                    label-position="left"
                    class="font-normal normal-case"
                    small
                >
                    Time TBD
                </toggle-switch>
            </template>

            <div v-if="showDateTimePickers">
                <div class="row">
                    <div class="col-12">
                        <date-time-picker
                            v-model="form.starts_at"
                            label="Start"
                            has-time
                            class="w-full"
                        ></date-time-picker>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <date-time-picker
                            v-model="form.ends_at"
                            label="End"
                            has-time
                            class="w-full"
                        ></date-time-picker>
                    </div>
                </div>
            </div>
        </form-field-wrapper>

        <form-field-wrapper
            v-if="showEventTimezonePicker"
            label="Event's Timezone"
            :error="form.errors.get('timezone_id')"
        >
            <div v-if="originalEventHasTimeslots" class="alert alert-warning w-full">
                <p>A timezone for this event is required.</p>
            </div>

            <timezone-picker v-model="form.timezone_id" guess-timezone></timezone-picker>
        </form-field-wrapper>

        <form-field-wrapper :error="form.errors.get('location.type')">
            <template #label>
                <span>Event Location</span>
                <toggle-switch
                    :value="!isLocationEnabled"
                    label-position="left"
                    class="font-normal normal-case"
                    small
                    @input="toggleLocation"
                >
                    Undecided
                </toggle-switch>
            </template>

            <inline-button-picker v-if="isLocationEnabled" v-model="form.location.type"></inline-button-picker>
        </form-field-wrapper>

        <form-field-wrapper v-if="isLocationEnabled && form.location.type !== 'virtual'" :error="form.errors.getAny(['location.address', 'location.venue_name'])">
            <google-autocomplete-input
                v-model="venueWithAddress"
                placeholder="Start typing a location..."
                class="form-field"
            >
            </google-autocomplete-input>
        </form-field-wrapper>

        <form-field-wrapper v-if="isLocationEnabled && form.location.type !== 'in-person'" :error="form.errors.get('location.virtual_url')">
            <input
                v-model="form.location.virtual_url"
                class="form-field"
                placeholder="Paste virtual meeting link"
                type="text"
            >
        </form-field-wrapper>

        <form-field-wrapper v-if="isLocationEnabled && form.location.type !== 'virtual' && form.location.address">
            <location-map :address="form.location.address" class="h-80"></location-map>
        </form-field-wrapper>

        <template #footer="{ close }">
            <stateful-button
                class="button-primary"
                :loading="form.processing"
                @click="duplicateEvent"
            >
                Duplicate
            </stateful-button>

            <button class="button" @click="close">
                Cancel
            </button>
        </template>
    </app-modal>
</template>

<script>
import get from 'lodash/get';
import axios from '@/util/axios';
import Form from '@/validation/Form';
import Toggleable from '@/mixins/Toggleable';

export default {
    name: 'DuplicateEventModal',

    mixins: [Toggleable],

    props: {
        originalEvent: {
            type: Object,
            required: true
        },

        numberOfUsedEvents: {
            type: Number,
            required: true
        }
    },

    data () {
        return {
            form: this.createForm(),
            originalEventHasInvitees: null,
            timeslotsCount: 0
        };
    },

    computed: {
        canDuplicateEvents () {
            if (this.originalEvent.isComped) {
                return true;
            }

            return window.user.plan.features.EventDuplication.allowed;
        },

        hasReachedEventLimit () {
            if (window.user.plan.features.EventLimit.unlimited) {
                return false;
            }

            return this.numberOfUsedEvents >= window.user.plan.features.EventLimit.max;
        },

        isLocationEnabled () {
            return this.form.location !== null;
        },

        showDateTimePickers () {
            return !this.form.time_tbd;
        },

        showEventTimezonePicker () {
            return this.showDateTimePickers || this.originalEventHasTimeslots;
        },

        originalEventHasTimeslots () {
            return this.timeslots_count > 0 || get(this.originalEvent, 'settings.recurringTimeSlots.active', false);
        },

        venueWithAddress: {
            get () {
                return {
                    address: this.form.location.address,
                    venue: this.form.location.venue_name
                };
            },

            set ({ address, venue }) {
                this.form.location.address = address;
                this.form.location.venue_name = venue;
            }
        }
    },

    watch: {
        originalEvent () {
            this.form = this.createForm();
        },

        isActive (newValue) {
            if (!newValue) {
                this.originalEventHasInvitees = null;
                return;
            }

            if (!this.canDuplicateEvents) {
                App.alert().paymentRequired(
                    'Please upgrade.',
                    `Please upgrade to a premium plan to duplicate events.`
                );

                this.isActive = false;
                return;
            }

            if (this.hasReachedEventLimit) {
                App.alert().paymentRequired(
                    'Please upgrade.',
                    `Your ${window.user.plan.name} plan includes <strong>${window.user.plan.features.EventLimit.max}</strong> event(s). Please upgrade to create more events.`
                );

                this.isActive = false;
                return;
            }

            axios.get(this.route('api.events.has-invitees', this.originalEvent))
                .then(({ data }) => {
                    this.originalEventHasInvitees = data.hasInvitees;
                });

            axios.get(window.route('api.events.get-timeslots-count', this.originalEvent.id))
                .then(({ data }) => {
                    this.$set(this, 'timeslotsCount', data.timeslots_count);
                });
        },

        'form.time_tbd': function () {
            this.form.starts_at = null;
            this.form.ends_at = null;

            if (!this.originalEventHasTimeslots) {
                this.form.timezone_id = null;
            }

            this.form.errors.clear(['starts_at', 'ends_at', 'timezone_id']);
        }
    },

    methods: {
        createForm () {
            return new Form({
                name: `${this.originalEvent.name} NEW`,
                subdomain: `${this.originalEvent.subdomain}duplicated`,
                time_tbd: false,
                starts_at: null,
                ends_at: null,
                timezone_id: this.originalEventHasTimeslots ? this.originalEvent.timezone_id : null,
                location: this.originalEvent.location,
                duplicateInviteList: false
            });
        },

        duplicateEvent () {
            this.form.post(this.route('events.duplicate', this.originalEvent))
                .then(({ data }) => {
                    this.isActive = false;

                    this.$toasted.global.success({
                        message: 'Your event has been duplicated.',
                        onComplete: () => {
                            window.location.href = this.route('events.dashboard.overview', { event: data });
                        }
                    });
                });
        },

        toggleLocation (disabled) {
            this.form.location = disabled ? null : {
                type: 'in-person',
                address: '',
                venue_name: '',
                virtual_url: null
            };
        }
    }
};
</script>
