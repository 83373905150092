<template>
    <div>
        <slidable-list v-model="currentPage" class="w-full h-64 border rounded-md overflow-hidden">
            <div :key="1" class="w-full h-full flex items-center justify-center text-center">
                <h1>One</h1>
            </div>
            <div :key="2" class="w-full h-full flex items-center justify-center text-center">
                <h2>Two</h2>
            </div>
            <div :key="3" class="w-full h-full flex items-center justify-center text-center">
                <h3>Three</h3>
            </div>
        </slidable-list>

        <div class="text-center m-2">
            <button class="p-2 mx-2 bg-gray-500 text-white" @click="currentPage = 1">One</button>&nbsp;
            <button class="p-2 mx-2 bg-gray-500 text-white" @click="currentPage = 2">Two</button>&nbsp;
            <button class="p-2 mx-2 bg-gray-500 text-white" @click="currentPage = 3">Three</button>&nbsp;
        </div>
    </div>
</template>

<script>

export default {
    name: 'PagesExample',

    data () {
        return {
            currentPage: 1
        };
    }
};
</script>
