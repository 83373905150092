<template>
    <transition-group
        class="row items-start no-gutters h-full"
        tag="div"
        :name="transitionName"
    >
        <rsvp-page-language-selector
            v-if="shouldShowLanguageSelector"
            key="language-selector"
            @disable-language-selector="disableLanguageSelector"
        ></rsvp-page-language-selector>

        <wall-menu
            v-show="showGlobalWallMenu && isWallActive"
            key="wall-menu"
            :event="event"
            global
            :preview="preview"
        ></wall-menu>

        <div
            v-show="showEventWall && isWallActive"
            id="event-wall"
            key="event-wall"
            class="col-12 md:col-7 h-full absolute inset-0 md:relative z-10"
            :class="styles.eventWall"
        >
            <div class="flex flex-col w-full h-full">
                <div v-bar="{ useScrollbarPseudo: true }" class="flex-1">
                    <div>
                        <event-wall :event="event" :preview="preview"></event-wall>
                    </div>
                </div>

                <portal-target name="event-wall-footer" slim></portal-target>
            </div>
        </div>

        <div
            v-show="showEventForm"
            id="rsvp-form"
            key="rsvp-form"
            :class="styles.eventForm"
            class="h-full absolute inset-x-0 inset-y-0 md:relative z-10"
        >
            <div class="w-full h-full" :class="styles.formParent">
                <portal-target name="rsvp-form-target"></portal-target>

                <rsvp-form
                    ref="rsvpForm"
                    :is-embed="isEmbed"
                    :is-o-embed="isOEmbed"
                    :sections="sections"
                    :editable="false"
                    :preview="preview"
                    scroll-container-id="formScrollContainer"
                    :inaccessible="formInaccessible"
                ></rsvp-form>
            </div>
        </div>
    </transition-group>
</template>

<script>
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import { get, sync } from 'vuex-pathify';
import cloneDeep from 'lodash/cloneDeep';

export default {
    components: { simplebar },

    props: {
        event: {
            type: Object,
            required: true
        },
        formSections: {
            type: Array,
            required: true
        },
        formInaccessible: {
            type: Boolean,
            default: false
        },
        initialSubmission: {
            type: Object,
            default: null
        },
        language: {
            type: String,
            default: null
        },
        preview: {
            type: Boolean,
            default: false
        }
    },

    data () {
        let showWall = true;
        let isEmbed = false;
        let isOEmbed = false;
        let hadGroupPreselected = false;

        const getParams = new URLSearchParams(window.location.search);

        if ((getParams.has('view') && getParams.get('view') === 'Form') || this.initialSubmission) {
            showWall = false;
        }

        if (getParams.has('embed') && getParams.get('embed') === '1') {
            isEmbed = true;
        }

        if (getParams.has('oEmbed') && getParams.get('oEmbed') === '1') {
            isOEmbed = true;
        }

        if (getParams.has('group')) {
            hadGroupPreselected = true;
        }

        return {
            shouldShowLanguageSelector: this.event.enabled_languages.length > 1,
            showWall,
            isEmbed,
            isOEmbed,
            hadGroupPreselected
        };
    },

    computed: {
        activeBlock: sync('Form/activeBlock'),
        paymentSectionCompleted: get('Submission/paymentSectionCompleted'),
        sections: get('Form/form@sections'),
        submissionCompleted: get('Submission/submissionCompleted'),
        submission: sync('Submission/submission'),
        ...get('Wall/wall@', {
            isWallActive: 'active'
        }),

        backgroundImageStyle () {
            return `--image: url(${this.$store.get('Form/form@settings.background_url')});`;
        },

        showEventWall () {
            if (this.isEmbed) {
                return false;
            }

            if (this.screenWidthIs('gte', 'md')) {
                return true;
            }

            return this.showWall;
        },

        showEventForm () {
            if (this.isEmbed) {
                return true;
            }

            if (this.screenWidthIs('gte', 'md')) {
                return true;
            }

            return !this.showWall;
        },

        showGlobalWallMenu () {
            return !this.isEmbed && !this.showEventWall && !this.$store.get('Form/showPaymentScreen');
        },

        transitionName () {
            return this.showWall ? 'slide-right' : 'slide-left';
        },

        styles () {
            return {
                eventWall: {
                    'pt-14': this.preview
                },
                eventForm: {
                    'pt-14': this.preview,
                    'col-12': true,
                    'md:col-5': !this.isEmbed && this.isWallActive
                },
                formParent: {
                    'overflow-y-auto': this.paymentSectionCompleted || this.submissionCompleted,
                    'overflow-y-hidden': !this.paymentSectionCompleted && !this.submissionCompleted
                }
            };
        }
    },

    created () {
        this.$store.set('Form/form', this.event.form);
        this.$store.set('Form/form@sections', cloneDeep(this.formSections));
        this.$store.set('Wall/wall', this.event.wall);

        if (this.event.requiresPassword) {
            this.extractEnteredPasscode();
            this.clearUrl();
        }

        if (this.initialSubmission) {
            this.submission = this.initialSubmission;
        }

        if (this.language) {
            this.setLanguage();
        }
    },

    mounted () {
        if (!this.isWallActive) {
            this.showWall = false;
        }

        App.$on('toggle-event-wall-display', (showWall) => {
            /**
             * This is a fix for scrolling the active block into the viewport
             * if the group was preselected from the email and Wall is shown
             * as default instead of the form.
             */
            if (this.showWall && this.hadGroupPreselected) {
                setTimeout(() => {
                    this.$refs.rsvpForm.scrollToBlock(this.activeBlock.index);
                }, 450);
            }

            this.showWall = showWall;
        });

        this.setDocumentHeight();

        window.addEventListener('orientationchange', function () {
            this.setDocumentHeight();
        });

        if (this.isEmbed) {
            this.setEmbedStyles();
        }
    },

    methods: {
        clearUrl () {
            // When using event or invitee passcode feature, some data is passed
            // through the query parameters from `CheckPasscodeController.php` due
            // the issue with browsers that are blocking 3rd party cookies.
            // Guest doesn't need to see that data in the URL, so in this place
            // all not needed data from the query is cleared out so it doesn't produce
            // unnecessary clutter in the guest's browser search bar.
            const urlParams = new URLSearchParams(window.location.search);

            urlParams.delete('hashedInviteePasscode');
            urlParams.delete('hashedPasscode');
            urlParams.delete('language');
            urlParams.delete('signature');

            const url = urlParams.toString() !== '' ? `?${urlParams.toString()}` : '/';

            window.history.pushState('', '', url);
        },

        disableLanguageSelector () {
            this.shouldShowLanguageSelector = false;
        },

        extractEnteredPasscode () {
            const urlParams = new URLSearchParams(window.location.search);

            if (urlParams.has('hashedInviteePasscode')) {
                this.$store.set('Submission/enteredPasscode', urlParams.get('hashedInviteePasscode'));
            }
        },

        setDocumentHeight () {
            document.documentElement.style.setProperty('--fvh', `${window.innerHeight}px`);
        },

        setEmbedStyles () {
            document.documentElement.style.overflowY = 'auto';
            document.body.style.backgroundColor = 'transparent';
        },

        setLanguage () {
            const index = this.event.enabled_languages.findIndex(({ name }) => { return name === this.language; });

            if (index !== -1) {
                this.shouldShowLanguageSelector = false;
                this.$nextTick(() => { this.$i18n.locale = this.language; });
            }
        }
    }
};
</script>
