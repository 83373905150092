<template>
    <app-modal
        :value="value"
        title="Select Registration Type"
        @input="newValue => $emit('input', newValue)"
    >
        <form-field-wrapper label="Registration Type" tooltip="Submit a test, manually register on behalf of a guest, or charge a guest for this order.">
            <select-list v-model="registrationType">
                <select-option
                    id="test"
                    class="mb-2"
                    label="Test Registration"
                    tooltip="Test your form and preview the entire experience as guests will see it."
                ></select-option>

                <select-option
                    id="courtesy"
                    class="mb-2"
                    label="Courtesy Registration"
                    tooltip="Submit a registration on behalf of a guest who will be &quot;comped&quot; and not require payment."
                ></select-option>

                <select-option
                    id="charge"
                    :disabled="!event.isStripeConnected"
                    label="Charge a Credit Card for this Order"
                    :tooltip="chargeCreditCardTooltip"
                ></select-option>
            </select-list>
        </form-field-wrapper>

        <p class="mt-4 italic text-sm text-gray-600 text-center">This window is only visible to event administrators. Guests will be immediately directed to your checkout screen.</p>

        <template #footer="{ close }">
            <button
                class="button button-primary ml-2 disabled:opacity-50 disabled:cursor-not-allowed"
                :disabled="isContinueButtonDisabled"
                @click="$emit('confirm', registrationType)"
            >
                Continue
            </button>

            <button class="button" @click="close">Cancel</button>
        </template>
    </app-modal>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            registrationType: null
        };
    },

    computed: {
        ...get('Event/*'),

        chargeCreditCardTooltip () {
            return this.event.isStripeConnected
                ? `Submit a registration on behalf of a guest. Charge the guest's credit card.`
                : `Connect a Stripe account to process live credit card transactions.`;
        },

        isContinueButtonDisabled () {
            return !this.registrationType;
        }
    }
};
</script>
