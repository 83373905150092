import { render, staticRenderFns } from "./UnshareItem.vue?vue&type=template&id=7f87586c"
import script from "./UnshareItem.vue?vue&type=script&lang=js"
export * from "./UnshareItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports