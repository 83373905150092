import debounce from 'lodash/debounce';
import bind from 'lodash/bind';
import keys from 'lodash/keys';
import store from '@/store/index';
import styles from '@/themer/styles';

const themedElementSelectorRegex = /themedElementSelector/g;

const themedRoutes = [
    'walls.edit',
    'forms.edit',
    'event.show',
    'publish-invite.index',
    'publish-invite.cancel',
    'rsvp-page-preview',
    'rsvp-page-template-preview',
    'submissions.show',
    'submissions.edit',
    'event.security.passcode'
];

export default class Themer {
    constructor () {
        this.styleElement = this.injectStyleElement();
    }

    setup () {
        if (!themedRoutes.includes(window.route().current()) && !window.allowTheming) {
            // The current route does not need the themer
            return;
        }

        const update = bind(debounce(this.update, 100), this);

        store.subscribe((mutation, state) => {
            if (mutation.type === 'Wall/wall' || mutation.type === 'Form/form') {
                update({
                    wall: state.Wall.wall.settings.theme,
                    form: state.Form.form.settings.theme
                });
            }
        });

        update({
            wall: store.state.Wall.wall.settings.theme,
            form: store.state.Form.form.settings.theme
        });
    }

    update (theme) {
        const css = keys(styles).map((key) => {
            return styles[key](theme).replace(themedElementSelectorRegex, `.${store.state.Theme.styles[key]}`);
        }).join('\n');

        this.styleElement.innerHTML = css;
    }

    injectStyleElement () {
        const style = document.createElement('style');
        style.classList.add('themer-injected-style');
        style.type = 'text/css';
        style.appendChild(document.createTextNode(''));
        document.head.prepend(style);

        return style;
    }
}
