<template>
    <div v-theme="['form.text']">
        <confirmation-widget
            v-model="textHtml"
            :editing="isEditMode"
            :guests="guests"
        ></confirmation-widget>

        <div v-theme="'form.background'" class="mt-16 px-4 py-8 w-full rounded-md relative transition-all">
            <div
                v-if="isEditMode"
                v-theme="'form.accent'"
                class="p-6 flex flex-col items-center"
            >
                <app-icon
                    name="list-check-stroke"
                    class="h-10 w-10"
                    stroke
                ></app-icon>
                <div v-theme="'form.title-text'" class="text-xl text-center mt-4">
                    {{ $t('text-submission-summary') }}
                </div>
            </div>
            <template v-else>
                <div v-if="showConfirmationCode" class="text-center">
                    <img
                        v-if="event.settings.showQrcodeOnSummary"
                        class="mx-auto w-40 h-40 mb-4 rounded"
                        :src="`/submissions/${submission.uuid}/qrcode`"
                    >
                    <div class="text-sm uppercase tracking-wide">{{ $t('text-confirmation-code') }}</div>
                    <div class="text-lg font-bold">{{ submission.confirmation_code }}</div>
                </div>

                <div v-if="submission.timeslot_from" class="p-6">
                    <div class="flex items-center justify-center">
                        <app-icon name="calendar-clock" class="h-6 w-6"></app-icon>
                        <div class="ml-2 text-lg font-bold">{{ submission.timeslot_from | timeslotDate(eventTimezone.name) }}</div>
                    </div>
                    <div class="text-center">
                        {{ eventTimezone.name.replace(/_/g, ' ') }}
                    </div>
                </div>

                <div v-if="showRtsCancelledText" class="p-6">
                    <div class="flex items-center justify-center">
                        <app-icon name="calendar-clock" class="h-6 w-6 mr-2"></app-icon>{{ $t('text-canceled') }}
                    </div>
                </div>

                <template v-if="order">
                    <div v-for="(orderItem, index) in order.items" :key="index">
                        <div
                            v-theme="'form.accent'"
                            class="p-6 flex justify-between"
                            :class="orderItemsListItemStyle(index)"
                        >
                            <div>
                                <div class="flex font-semibold">
                                    <app-icon
                                        v-if="orderItem.orderable.settings.icon"
                                        :name="orderItem.orderable.settings.icon"
                                        class="h-5 w-5 mr-2"
                                    ></app-icon>
                                    {{ orderItem.orderable.title }}
                                </div>
                                <div v-if="orderItem.isDonation || showOrderItemQuantity(orderItem) || showOrderItemPrice(orderItem)" class="flex items-end">
                                    <div class="h-5 w-5 mr-2 mt-1"></div>
                                    <span v-if="orderItem.isDonation">
                                        {{ orderItem.orderable.settings.description }}
                                    </span>
                                    <span v-else>
                                        <span v-if="showOrderItemQuantity(orderItem)">
                                            {{ orderItem.quantity | number }} x
                                        </span>
                                        <money-field
                                            v-if="showOrderItemPrice(orderItem)"
                                            :value="orderItem.price"
                                            static
                                        ></money-field> {{ block.pivot.settings.currency }}
                                    </span>
                                </div>
                            </div>
                            <div v-if="showOrderItemTotalPrice(orderItem)" class="flex items-center font-semibold">
                                <money-field :value="orderItem.totalPrice" static></money-field>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="orderHasACoupon"
                        v-theme="'form.accent'"
                        class="p-6 flex justify-between border-b-2"
                    >
                        <div class="font-semibold">
                            <span class="uppercase mr-2">
                                "{{ order.coupon.code }}"
                            </span>
                        </div>
                        <div class="font-semibold">(-<money-field :value="order.discount" static></money-field>)</div>
                    </div>
                    <div
                        v-if="order.tax"
                        v-theme="'form.accent'"
                        class="p-6 flex justify-between"
                        :class="serviceFeeSectionStyles.tax"
                    >
                        <div class="font-semibold">
                            {{ event.sales_tax.label }}
                        </div>
                        <div class="font-semibold">
                            <money-field :value="order.tax" static></money-field></div>
                    </div>
                    <div
                        v-if="order.fee_passthrough_enabled"
                        v-theme="'form.accent'"
                        class="p-6 flex justify-between"
                        :class="serviceFeeSectionStyles.fee_passthrough"
                    >
                        <div class="font-semibold">
                            {{ event.settings.feePassthrough.label }}
                        </div>
                        <div class="font-semibold">
                            <money-field static :value="order.passthroughFee"></money-field>
                        </div>
                    </div>
                    <div
                        v-if="showOrderTotal"
                        v-theme="'form.accent'"
                        class="px-6 pt-6 flex justify-between"
                    >
                        <div class="font-semibold uppercase tracking-wide">
                            {{ $t('text-order-total') }}
                        </div>
                        <div class="font-semibold">
                            <money-field :value="order.total" static></money-field>
                            {{ block.pivot.settings.currency }}
                        </div>
                    </div>

                    <div v-if="paymentSuccessful" class="text-right p-6 opacity-50">
                        <div class="mb-2">
                            {{ order.payment.purchaserName }}
                        </div>

                        <div class="font-mono flex items-center justify-end">
                            <app-icon
                                v-if="order.payment.creditCardBrand"
                                :name="getCreditCardBrandIcon(order.payment.creditCardBrand)"
                                class="w-8 h-8 mr-2 text-gray-800"
                            ></app-icon>

                            <div v-if="order.payment.creditCardLast4">
                                **** **** **** {{ order.payment.creditCardLast4 }}
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </div>

        <div class="flex w-full mt-6">
            <edit-reply-button
                v-if="canEditSubmission"
                :event="event"
                :submission="submission"
            ></edit-reply-button>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import getValue from 'lodash/get';
import FormBlock from '@/mixins/FormBlock';
import EventTimezone from '@/mixins/EventTimezone';
import SelfEditableBlockMixin from '@/mixins/SelfEditableBlockMixin';

export default {
    name: 'TicketsSummaryBlock',

    mixins: [FormBlock, SelfEditableBlockMixin, EventTimezone],

    data () {
        return {
            blockId: this.block.pivot.id,
            textHtml: this.block.pivot.settings.html,
            syncSelfEditableSettings: {
                textHtml: 'html'
            }
        };
    },

    computed: {
        ...get('Event/*'),

        event: get('Event/event'),
        guests: get('Submission/submission@guests'),
        order: get('Submission/submission@order'),
        paymentSectionCompleted: get('Submission/paymentSectionCompleted'),
        submission: get('Submission/submission'),

        canEditSubmission () {
            return (this.submission.editable || this.submission.hasEditPreview) && getValue(this.event, 'settings.security.allowGuestSubmissionEditing');
        },

        isOrderEmpty () {
            return !this.order || this.order.items.length === 0;
        },

        orderTotalNotFree () {
            return this.order.total > 0;
        },

        orderHasACoupon () {
            return !!this.order.coupon;
        },

        paymentSuccessful () {
            return this.order.payment && this.order.payment.isSuccessful;
        },

        serviceFeeSectionStyles () {
            return {
                tax: {
                    'border-b': this.order.fee_passthrough_enabled,
                    'border-b-2': !this.order.fee_passthrough_enabled
                },
                fee_passthrough: {
                    'border-b-2': this.order.fee_passthrough_enabled
                }
            };
        },

        showConfirmationCode () {
            return getValue(this.event, 'settings.confirmationEmails.includeConfirmationCode');
        },

        showRtsCancelledText () {
            return getValue(this.event, 'settings.recurringTimeSlots.active', false) && this.submission.declined;
        },

        showOrderTotal () {
            if (this.orderHasACoupon) {
                return true;
            }

            return this.orderTotalNotFree;
        }
    },

    methods: {
        orderItemsListItemStyle (index) {
            return {
                'border-b': index < this.order.items.length - 1 || this.showOrderTotal
            };
        },

        showOrderItemPrice (orderItem) {
            if (this.orderHasACoupon || this.orderTotalNotFree) {
                return true;
            }

            return orderItem.price > 0;
        },

        showOrderItemTotalPrice (orderItem) {
            if (this.orderHasACoupon || this.orderTotalNotFree) {
                return true;
            }

            return orderItem.totalPrice > 0;
        },

        showOrderItemQuantity (orderItem) {
            if (this.orderHasACoupon || this.orderTotalNotFree) {
                return true;
            }

            return orderItem.quantity > 1;
        }
    }
};
</script>
