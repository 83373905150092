/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'medical-instrument-syringe-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>needle</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7.89 19.038l-3.19-3.19-.01-.01c-.59-.59-.59-1.54-.01-2.13v-.01l9.016-9.015 5.3 5.3-9.015 9.01v-.01c-.59.58-1.54.58-2.12 0z"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.1 17.27h3.75v3H3.1z" transform="matrix(.7071 -.70711 .7071 .7071 -11.816 9.018)"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="2" d="M1 22.75l2.65-2.65M12.14 3.13l8.48 8.48"/></g><path pid="3" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.61 4.01h5.25v3h-5.25z" transform="matrix(.7071 -.70711 .7071 .7071 1.442 14.51)"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="4" d="M17.44 1.01l5.3 5.3M13.73 11.08l2.12 2.12M10.55 14.27l2.12 2.12"/></g>'
  }
})
