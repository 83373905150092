/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'power-button-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 1.5v9.75M9 5.01V5a8.987 8.987 0 00-5.49 11.48c1.65 4.68 6.79 7.14 11.48 5.48 4.68-1.66 7.14-6.8 5.48-11.49a9.024 9.024 0 00-5.49-5.49"/></g>'
  }
})
