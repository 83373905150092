<template>
    <div class="flex w-auto items-center">
        <app-icon :name="icon" :class="styles"></app-icon>
        <span v-if="withLabel">{{ label }}</span>
    </div>
</template>

<script>
export default {
    props: {
        colored: {
            type: Boolean,
            default: true
        },
        state: {
            type: String,
            required: true
        },
        withLabel: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        iconMapping () {
            return {
                CheckedIn: 'check-circle-stroke',
                CheckedOut: 'logout-stroke',
                Unseen: 'question-circle-stroke'
            };
        },

        icon () {
            return this.iconMapping[this.state];
        },

        label () {
            const labels = {
                CheckedIn: 'Checked In',
                CheckedOut: 'Checked Out',
                Unseen: 'Unseen'
            };

            return labels[this.state];
        },

        styles () {
            return {
                'mr-2 h-5 w-5': true,
                'text-green': this.colored && this.state === 'CheckedIn',
                'text-yellow': this.colored && this.state === 'CheckedOut',
                'text-gray-500': this.colored && this.state === 'Unseen'
            };
        }
    }
};
</script>
