/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-expand-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>grow show view all larger bigger out</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9.75 14.25l-9 9M23.25 7.498V.748L16.5.74M.75 16.49v6.75H7.5M23.25.75l-9 9"/></g>'
  }
})
