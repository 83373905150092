<template>
    <div v-if="isCustomAmountOption" class="mt-4">
        <form-panel
            v-model="settings.minimumPrice.active"
            label="Require Minimum Donation"
            tooltip="Require guests to include a donation or gift of a minimum amount before they can complete your form."
            :error="form.errors.get('settings.minimumPrice.amount')"
            :should-show-error="form.errors.has('settings.minimumPrice.amount')"
        >
            <form-field-wrapper>
                <money-field v-model="settings.minimumPrice.amount"></money-field>
            </form-field-wrapper>

            <form-field-wrapper
                :error="errors.first('minimum-price-message')"
                :should-show-error="shouldShowErrorMessages"
            >
                <div
                    v-tippy
                    class="tooltip-text uppercase inline-flex"
                    content="Show guests a message if they don't enter a value that meets or exceeds the minimum donation amount."
                >
                    Message to display
                </div>
                <textarea
                    v-model="settings.minimumPrice.message"
                    class="form-field"
                    placeholder="Enter a description and details about this selection."
                    rows="3"
                    name="minimum-price-message"
                ></textarea>
            </form-field-wrapper>
        </form-panel>
    </div>
</template>

<script>
import ConditionalValidation from '@/mixins/ConditionalValidation';

export default {
    name: 'FormCashDonationGiftQuestionItemSettings',

    mixins: [ConditionalValidation],

    props: {
        value: {
            type: Object,
            required: true
        },

        form: {
            type: Object,
            required: true
        },

        item: {
            type: Object,
            default: () => { return {}; }
        }
    },

    data () {
        return {
            settings: this.value
        };
    },

    computed: {
        isCustomAmountOption () {
            return this.item.customAmountItem === true;
        }
    }
};
</script>
