/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 5.251c-4.04-.07-8.2 2.74-10.83 5.63h-.01c-.57.63-.57 1.59 0 2.22 2.56 2.82 6.72 5.7 10.82 5.63 4.1.06 8.258-2.82 10.82-5.64v-.01c.56-.64.56-1.6-.01-2.23-2.63-2.89-6.8-5.71-10.83-5.64z"/><path pid="1" d="M15.75 12v-.01c0 2.07-1.68 3.75-3.75 3.75-2.08 0-3.75-1.68-3.75-3.75 0-2.08 1.67-3.75 3.74-3.75h-.01c2.07-.01 3.74 1.67 3.75 3.74 0 0 0 0 0 0z"/></g>'
  }
})
