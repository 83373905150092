/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>security key secure</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="16.5" height="13.5" x="3.75" y="9.75" rx="1.5"/><path pid="1" d="M6.75 9.75V6h0A5.25 5.25 0 0111.99.74c2.89-.01 5.25 2.35 5.25 5.24 0 0 0 0 0 0v3.75M12 15v3"/></g>'
  }
})
