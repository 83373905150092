/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-baseball-glove-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.25 9V2.625v0c0-1.04-.84-1.875-1.875-1.875-1.04 0-1.875.83-1.875 1.875v8.625c0 1.65-1.35 3-3 3-1.66 0-3-1.35-3-3V7.5v0c0-1.25-1.01-2.25-2.25-2.25C2 5.25 1 6.25 1 7.5v4.875h0c0 6 4.86 10.87 10.87 10.87 6-.01 10.87-4.87 10.87-10.88V7.11v0c0-1.04-.84-1.875-1.875-1.875-1.04 0-1.875.83-1.875 1.875M5.5 9.75h6M8.5 2.14v7.61"/><path pid="1" d="M16.1 18.19h-.01c-.8.54-1.7.93-2.65 1.13M10.293 19.33l-.01-.01c-.95-.21-1.85-.59-2.65-1.14M3.8 5.319h-.01c1.96-2.24 4.73-3.6 7.69-3.8l.375-.03M15.25 4.875h0c-.01-1.04.83-1.88 1.87-1.88a1.86 1.86 0 011.87 1.87s0 0 0 0v5.62"/></g>'
  }
})
