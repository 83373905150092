<template>
    <div class="flex-1 p-8">
        <section class="panel flex flex-col">
            <page-header icon="email-close-stroke" label="Manage Campaign Subject Line Blacklist">
                <a :href="route('admin.dashboard')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <infinite-data-table
                ref="table"
                class="-mx-8 md:mx-0 text-gray-600"
                column-header-class="z-10"
                :columns="columns"
                :filter="filter"
                no-results-placeholder="We couldn't find any blacklisted campaign subject lines matching the filtering criteria."
                :source="source"
            >
                <template #header>
                    <div class="flex-auto">
                        <search-field
                            v-model="filter.term"
                            class="w-full md:w-80"
                            placeholder="Search by term..."
                        ></search-field>
                    </div>

                    <div class="flex justify-end flex-auto ml-4">
                        <button
                            class="button-icon button-primary button-lg"
                            @click="isModalVisible = true"
                        >
                            <app-icon name="add-circle" stroke></app-icon>
                        </button>
                    </div>
                </template>

                <template #row="{ property, row, value }">
                    <template v-if="property === 'enabled'">
                        <toggle-switch
                            :value="value"
                            small
                            @input="newValue => update(row, { enabled: newValue })"
                        ></toggle-switch>
                    </template>
                    <template v-else-if="property === 'delete'">
                        <button class="ml-2 button-icon hover:text-red" @click="removeBlacklistedItem(row)">
                            <app-icon
                                name="trash"
                                class="h-6 w-6"
                                stroke
                            ></app-icon>
                        </button>
                    </template>
                    <template v-else><span class="whitespace-pre">{{ value }}</span></template>
                </template>
            </infinite-data-table>
        </section>

        <app-modal v-model="isModalVisible">
            <template #header>Add Campaign Subject Line to Blacklist</template>

            <template #default="{ close }">
                <form id="campaign-subject-line-form" @submit.prevent="store(close)">
                    <form-field-wrapper label="Campaign Subject Line" :error="form.errors.get('campaign_subject_line_blacklist')">
                        <input
                            v-model="form.campaign_subject_line_blacklist"
                            class="form-field"
                            placeholder="Campaign subject line or just prohibited term"
                        >
                    </form-field-wrapper>
                </form>
            </template>

            <template #footer="{ close }">
                <stateful-button
                    class="button-primary"
                    form="campaign-subject-line-form"
                    :loading="form.processing"
                    type="submit"
                >
                    Add
                </stateful-button>

                <button class="button" @click="close">Cancel</button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import axios from '@/util/axios';
import Form from '@/validation/Form';

export default {
    name: 'ManageCampaignSubjectLineBlacklist',

    data () {
        return {
            filter: {
                term: ''
            },
            form: new Form({
                campaign_subject_line_blacklist: ''
            }),
            isModalVisible: false
        };
    },

    computed: {
        columns () {
            return [
                {
                    label: 'Campaign Subject Line',
                    property: 'value',
                    desktop: true
                },
                {
                    label: 'Enabled',
                    property: 'enabled',
                    desktop: true
                },
                {
                    label: '',
                    property: 'delete',
                    desktop: true
                }
            ];
        },

        source () {
            return this.route('api.admin.blacklist.campaign-subject-line.index');
        }
    },

    methods: {
        store (closeFunction) {
            this.form.post(this.route('api.admin.blacklist.campaign-subject-line.store'))
                .then(() => {
                    closeFunction();

                    this.$refs.table.reload();
                    this.form.campaign_subject_line_blacklist = '';
                })
                .catch(() => {
                    this.$toasted.global.error('There was an error adding a campaign subject line to the blacklist.');
                });
        },

        update (campaignSubjectLine, data) {
            axios.patch(this.route('api.admin.blacklist.campaign-subject-line.update', campaignSubjectLine), data)
                .then(() => {
                    this.$refs.table.reload();
                })
                .catch(() => {
                    this.$toasted.global.error('There was an error updating the blacklist.');
                });
        },

        removeBlacklistedItem (campaignSubjectLine) {
            const onConfirm = () => {
                axios.delete(this.route('api.admin.blacklist.campaign-subject-line.destroy', campaignSubjectLine))
                    .then(() => {
                        this.$refs.table.reload();
                    })
                    .catch(() => {
                        this.$toasted.global.error('There was an error deleting the blacklist.');
                    });
            };

            App.alert().confirm(
                'Are you sure?',
                'Are you sure you want to delete this blacklisted item?',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                },
                onConfirm
            );
        }
    }
};
</script>
