<template>
    <div class="flex flex-col items-center justify-center -mx-6">
        <div v-if="loadingCustomDataFields" class="flex flex-col items-center justify-center space-y-2 min-h-xs">
            <app-icon name="loader" class="w-8 h-8 fill-current"></app-icon>

            <p class="text-center">
                We're preparing your custom data fields, it will be ready in just a moment.
            </p>
        </div>

        <div v-else-if="!hasCustomDataFields" class="flex flex-col items-center justify-center space-y-2 min-h-xs">
            <p>You haven't added any custom data fields.</p>
            <a
                class="text-purple"
                href="https://help.rsvpify.com/en/articles/5269224-custom-data-fields"
                target="_blank"
            >What are custom data fields?</a>
        </div>

        <div v-else class="flex flex-col w-full">
            <a
                v-for="customDataField in customDataFields"
                :key="customDataField.id"
                role="button"
                class="group flex items-center px-6 py-2 hover:bg-gray-100"
                @click="insertMergeTag(customDataField)"
            >
                <span class="flex-1">{{ customDataField.field }}</span>
                <span class="button-icon button-primary hidden group-hover:flex">
                    <app-icon name="add-circle" stroke></app-icon>
                </span>
            </a>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import DynamicMergeTags from '@/mixins/DynamicMergeTags';
import axios from '@/util/axios';

export default {
    name: 'CustomDataFieldMergeTags',

    mixins: [DynamicMergeTags],

    data () {
        return {
            customDataFields: [],
            loadingCustomDataFields: false
        };
    },

    mounted () {
        this.retrieveCustomDataFields();
    },

    computed: {
        ...get('Event/*'),

        hasCustomDataFields () {
            return this.customDataFields.length > 0;
        }
    },

    methods: {
        generateMergeTags (value) {
            return `{custom_data_field|id=${value.id}|field=${value.field}}`;
        },

        retrieveCustomDataFields () {
            this.loadingCustomDataFields = true;

            axios.get(this.route('api.events.campaigns.merge-tags-data.custom-data-fields', this.event))
                .then(({ data }) => {
                    this.customDataFields = data.data;
                }).finally(() => {
                    this.loadingCustomDataFields = false;
                });
        }
    }
};
</script>
