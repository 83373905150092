/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wedding-gift-fund-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.1 9.75l-4.39-4.572-.01-.01c-.79-.8-.99-2-.49-3v0h-.01A2.564 2.564 0 0116.67 1c.24.12.47.28.67.48l.71.716.71-.716v-.01a2.58 2.58 0 014.14.66v0l-.01-.01c.49.99.3 2.2-.49 2.99zM7.5 10.125h-.01c.2-.01.37.16.37.37M7.12 10.5h0c-.01-.21.16-.38.37-.38"/><path pid="1" d="M7.5 10.87h-.01a.386.386 0 01-.38-.38s0 0 0 0M7.875 10.5v0c0 .2-.17.375-.375.375M6.01 20.2l-.76 3.05M16.49 20.21l.76 3.04"/><path pid="2" d="M9.019 5.516l-1.97-1.64h0a.748.748 0 00-1.06.09.63.63 0 00-.17.38l-.36 2.81v-.01a8.16 8.16 0 00-2.88 4.07H.71v6H3.2a9.093 9.093 0 008.01 4.49c4.971 0 9-3.7 9-8.25v-.01c0-.26-.02-.51-.04-.75"/></g>'
  }
})
