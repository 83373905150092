import { Node } from 'tiptap';
import EditorIFrame from './EditorIFrame';

export default class Iframe extends Node {
    get name () {
        return 'iframe';
    }

    get schema () {
        return {
            attrs: {
                src: {
                    default: null
                }
            },
            group: 'block',
            selectable: false,
            parseDOM: [{
                tag: 'iframe',
                getAttrs: (dom) => {
                    return {
                        src: dom.getAttribute('src')
                    };
                }
            }],
            toDOM: (node) => {
                return ['iframe', {
                    src: node.attrs.src,
                    frameborder: 0,
                    allowfullscreen: 'true',
                    width: '100%',
                    height: '300px'
                }];
            }
        };
    }

    commands ({ type }) {
        return (attrs) => {
            return (state, dispatch) => {
                const { selection } = state;
                const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
                const node = type.create(attrs);
                const transaction = state.tr.insert(position, node);
                dispatch(transaction);
            };
        };
    }

    get view () {
        return EditorIFrame;
    }
}
