<template>
    <div>
        <div class="flex flex-col md:flex md:flex-row items-center p-8 border-b">
            <div class="flex flex-col md:flex md:flex-row md:flex-1 items-center md:items-start space-x-4">
                <a class="w-20 h-20 ml-4 md:ml-0 flex-none">
                    <img :src="asset('images/account/integrations/zapier.png')" alt="Zapier">
                </a>
                <img
                    v-if="!canUsePublicApi"
                    v-tippy
                    class="w-5 h-5 mt-3 md:mt-0 pt-0 md:pt-1"
                    :src="asset('images/account/rsvpify-premium.svg')"
                    content="This feature is not included in your current plan. Please consider upgrading."
                >
                <div>
                    <p class="mt-3 md:mt-0 text-center md:text-left text-gray-600 uppercase tracking-wide font-semibold">Zapier</p>
                    <p class="mt-3 md:mt-0 mr-0 md:mr-20 text-center md:text-left text-gray-600">
                        Seamlessly integrate RSVPify with other apps to automate tracks and sync data. Connect to Salesforce, MailChimp, HubSpot, Google Sheets and thousands more.
                    </p>
                    <feature-enabled feature="zapier-integration-embed">
                        <p class="mt-3 md:mt-1 mr-0 md:mr-20 text-center md:text-left text-gray-600 text-xs">
                            Connect RSVPify with 5,000+ other apps <a
                                :href="route('account.integrations.zapier')"
                                class="text-blue"
                            >Explore now <app-icon
                                name="video-game-magic-wand"
                                class="h-4 w-4"
                                stroke
                            ></app-icon></a>
                        </p>
                    </feature-enabled>
                </div>
            </div>

            <div
                v-if="isRegeneratingToken"
                class="button w-60 mt-3 md:mt-0 bg-white border-purple text-purple hover:bg-purple hover:text-white"
                :loading="refreshingToken"
                @click="launchModal"
            >
                Re-generate Api Token
            </div>

            <div
                v-else
                class="button w-60 mt-3 md:mt-0 bg-white border-purple text-purple hover:bg-purple hover:text-white"
                :loading="refreshingToken"
                @click="refreshToken"
            >
                Generate Api Token
            </div>
        </div>

        <app-modal v-model="isTokenGeneratedModalVisible" title="Token generated">
            <p class="mb-4 text-justify text-gray-600">Keep this token in a safe place. You will <strong>not</strong> be able to access it again. You can generate a new API token any time.</p>

            <div class="flex items-center p-1 border rounded">
                <div class="flex-1 text-center text-xs">
                    {{ freshToken }}
                </div>

                <copy-to-clipboard
                    class="-mb-1"
                    :copy="freshToken"
                    @copied="onTokenCopied"
                >
                    <button class="button-icon bg-gray-100">
                        <app-icon class="w-4 h-4" name="file-copy"></app-icon>
                    </button>
                </copy-to-clipboard>
            </div>
        </app-modal>

        <app-modal
            v-slot="{ close }"
            v-model="isRegenerateTokenModalVisible"
            title="Regenerate API token?"
        >
            <p class="mb-4 text-justify text-gray-600">You've already generated an API token. You can generate a new token any time. Once regenerated, your <strong>existing API token will become inactive</strong> and your existing Zapier integrations will no longer be functional.</p>

            <div class="flex max-w-sm mx-auto">
                <button class="button flex-1 mr-1" @click="close">Cancel</button>

                <stateful-button
                    class="button button-primary flex-1 ml-1"
                    :loading="refreshingToken"
                    @click="refreshToken"
                >Regenerate Token</stateful-button>
            </div>
        </app-modal>
    </div>
</template>

<script>
import axios from '@/util/axios';

export default {
    props: {
        hasApiToken: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            freshToken: null,
            isNewTokenModalVisible: false,
            isRegenerateTokenModalVisible: false,
            isTokenGeneratedModalVisible: false,
            refreshingToken: false
        };
    },

    computed: {
        canUsePublicApi () {
            return this.auth().user().plan.features.PublicApi.allowed;
        },

        isRegeneratingToken () {
            return this.hasApiToken || this.freshToken != null;
        }
    },

    methods: {
        launchModal () {
            if (this.isRegeneratingToken) {
                this.isRegenerateTokenModalVisible = true;
            } else {
                this.isNewTokenModalVisible = true;
            }
        },

        onTokenCopied () {
            this.$toasted.global.success('Your new API token has been copied to your clipboard.');
        },

        async refreshToken () {
            if (!this.canUsePublicApi) {
                this.showPaywall();
            } else {
                this.refreshingToken = true;

                const { data } = await axios.post(this.route('api.public-api.token.refresh'));

                this.isNewTokenModalVisible = false;
                this.isRegenerateTokenModalVisible = false;
                this.refreshingToken = false;

                this.freshToken = data.token;
                this.isTokenGeneratedModalVisible = true;
            }
        },

        showPaywall () {
            App.alert().paymentRequired(
                'Please upgrade.',
                `Your ${this.auth().user().plan.name} plan doesn't include API access. Please upgrade to access this feature.`
            );
        }
    }
};
</script>
