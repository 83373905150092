/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-decoration-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 8.091c5.25 3.75 13.37 5.39 22.5 4.5M2.965 9.46l-.09 4.19-.01-.01c.03.58.53 1.03 1.12 1 .22-.02.43-.1.6-.24l3.19-3.02"/><path pid="1" d="M9.96 11.96l.92 4.36-.01-.01c.16.56.75.89 1.32.72.21-.07.4-.19.53-.37l2.58-3.97M16.97 12.8l1.77 4.13-.01-.01c.27.52.92.71 1.44.44.19-.11.35-.27.45-.48l1.73-4.24M17.625 9.25h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M17.25 9.625h0c-.01-.21.16-.38.37-.38M17.625 10h-.01a.386.386 0 01-.38-.38s0 0 0 0M18 9.625v0c0 .2-.17.375-.375.375M4.12 19h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M3.75 19.375h0c-.01-.21.16-.38.37-.38M4.12 19.75h-.01a.386.386 0 01-.38-.38s0 0 0 0M4.5 19.375v0c0 .2-.17.375-.375.375M4.875 2.5h-.01c.2-.01.37.16.37.37M4.5 2.87v-.01c0-.21.16-.38.37-.38M4.875 3.25l-.01-.001a.386.386 0 01-.38-.38v-.001M5.25 2.87v0c0 .2-.17.375-.375.375M19.5 1v3M21 2.5h-3M9 4.75v3M10.5 6.25h-3M17.25 19.75v3M18.75 21.25h-3"/></g>'
  }
})
