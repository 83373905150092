/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-check-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 11.25a6 6 0 100 12 6 6 0 100-12z"/><path pid="1" d="M19.924 15.506l-2.91 3.87v-.01a.76.76 0 01-1.06.14c-.03-.03-.06-.05-.08-.07l-1.5-1.5M19.575 8.25h-.01c.31-1.52 1.52-2.7 3.04-2.95v-.01c.36-.07.62-.38.62-.74V2.23v0c0-.83-.68-1.5-1.5-1.5H2.21c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v2.31H.7c-.01.36.26.67.62.73h0c2.04.34 3.42 2.27 3.08 4.31a3.76 3.76 0 01-3.09 3.08H1.3a.74.74 0 00-.63.73v2.3h0c0 .82.67 1.49 1.5 1.49h6M8.25 14.25v-1.5M8.25 9.75v-1.5M8.25 5.25v-1.5"/></g>'
  }
})
