/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text-italic': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="currentColor" d="M.75 23.998a.75.75 0 010-1.5h4.8l11.2-21h-4a.75.75 0 010-1.5h10.5a.75.75 0 010 1.5h-4.8l-11.2 21h4a.75.75 0 010 1.5H.75z"/>'
  }
})
