/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-champagne-cheers-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>bar alcohol bubbly prosecco celebration mimosa</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M1.48 21.55L7 23.24M4.24 22.4l1.68-5.54M8.324 16.864v0-.01c-1.58.49-3.29-.04-4.32-1.32v0l-.01-.01a4.157 4.157 0 01-.56-4.28l4.21-10.1h-.01a.71.71 0 01.87-.42l4.275 1.3h0c.35.11.57.47.49.84l-2.14 10.73h-.01a4.175 4.175 0 01-2.86 3.23zM5.68 5.93l6.63 2.02"/><path pid="1" d="M7.211 9.04l-.01-.01c.19.05.3.26.24.46h-.02c-.07.19-.28.31-.47.25h0a.375.375 0 01-.25-.47v-.02c.06-.2.27-.32.46-.26M15.461 9.04l-.01-.01c.19.05.3.26.24.46h-.02c-.07.19-.28.31-.47.25h0a.375.375 0 01-.25-.47v-.02c.06-.2.27-.32.46-.26M17.71 11.3l-.01-.01c.19.05.3.26.24.46h-.02c-.07.19-.28.31-.47.25h0a.375.375 0 01-.25-.47v-.02c.06-.2.27-.32.46-.26M16.21 13.54l-.01-.01c.19.05.3.26.24.46h-.02c-.07.19-.28.31-.47.25h0a.375.375 0 01-.25-.47v-.02c.06-.2.27-.32.46-.26M8.71 11.3l-.01-.01c.19.05.3.26.24.46h-.02c-.07.19-.28.31-.47.25h0a.375.375 0 01-.25-.47v-.02c.06-.2.27-.32.46-.26M6.461 13.54l-.01-.01c.19.05.3.26.24.46h-.02c-.07.19-.28.31-.47.25h0a.375.375 0 01-.25-.47v-.02c.06-.2.27-.32.46-.26M17 23.28l5.52-1.66M19.76 22.45l-1.66-5.54"/><path pid="2" d="M15.06.93l.57-.18V.74c.35-.11.73.08.87.42l4.16 10.11h0c.61 1.42.39 3.06-.58 4.27v0-.01a4.034 4.034 0 01-4.33 1.3v0h0c-.98-.31-1.8-.97-2.32-1.85M15.81 6.75l2.67-.8"/></g>'
  }
})
