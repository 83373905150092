/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'globe-model-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>earth planet</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11 1.98a7.5 7.5 0 100 15 7.5 7.5 0 100-15z"/><path pid="1" d="M18.42 2.061h0c4.1 4.1 4.1 10.74-.01 14.84C14.3 21 7.66 21 3.56 16.891M19.49 1l-1.07 1.06M3.58 16.91l-1.06 1.06M11 19.99v3M5 22.99h12"/><path pid="2" d="M4.12 6.485h1.62-.01c.82-.01 1.5.67 1.5 1.5v1.62h-.01c-.01.39.15.77.43 1.06l1.15 1.15-.01-.01c.4.4.54.99.36 1.53l-.31.92v-.01c-.1.28-.28.54-.53.72l-1.22.91M13.4 2.37c-.41.32-.85.66-1.23.95h-.01c-.66.5-.78 1.43-.29 2.08l.91 1.2h0c.28.37.72.59 1.2.59h.87l-.01-.001c.39 0 .77.15 1.05.43l1.15 1.15-.01-.01c.36.36.89.51 1.4.4"/></g>'
  }
})
