// https://stackoverflow.com/questions/20600800/js-client-side-exif-orientation-rotate-and-mirror-jpeg-images

export default {
    methods: {
        /* eslint-disable */
        resetImageOrientation (srcBase64, srcOrientation, callback) {
            const img = new Image();

            img.onload = () => {
                const { width, height } = img;
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set proper canvas dimensions before transform & export
                if (srcOrientation > 4 && srcOrientation < 9) {
                    canvas.width = height;
                    canvas.height = width;
                } else {
                    canvas.width = width;
                    canvas.height = height;
                }

                // Transform context before drawing image
                switch (srcOrientation) {
                    case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                    case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
                    case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
                    case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                    case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
                    case 7: ctx.transform(0, -1, -1, 0, height, width); break;
                    case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                    default: break;
                }

                // Draw image
                ctx.drawImage(img, 0, 0);

                // Export base64 encoded data, the actual File blob and some extra metadata
                canvas.toBlob(
                    (blob) => {
                        callback(canvas.toDataURL(), blob, width, height);
                    },
                    this.getMimeTypeFromBase64(srcBase64),
                    0.8
                );
            };

            img.src = srcBase64;
        },

        getImageExifOrientation (file, callback) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const view = new DataView(e.target.result);

                if (view.getUint16(0, false) !== 0xFFD8) {
                    return callback(-2);
                }

                const length = view.byteLength;
                let offset = 2;

                while (offset < length) {
                    if (view.getUint16(offset + 2, false) <= 8) {
                        return callback(-1);
                    }

                    const marker = view.getUint16(offset, false);
                    offset += 2;

                    if (marker === 0xFFE1) {
                        if (view.getUint32(offset += 2, false) !== 0x45786966) {
                            return callback(-1);
                        }

                        const little = view.getUint16(offset += 6, false) === 0x4949;
                        offset += view.getUint32(offset + 4, little);

                        const tags = view.getUint16(offset, little);
                        offset += 2;

                        for (let i = 0; i < tags; i++) {
                            if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                                return callback(view.getUint16(offset + (i * 12) + 8, little));
                            }
                        }
                    } else if ((marker & 0xFF00) !== 0xFF00) {
                        break;
                    } else {
                        offset += view.getUint16(offset, false);
                    }
                }

                return callback(-1);
            };

            reader.readAsArrayBuffer(file);
        },

        getMimeTypeFromBase64(srcBase64) {
            return srcBase64.split(',')[0].match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);/)[1];
        }
        /* eslint-enable */
    }
};
