/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'upload-button': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>file, upload, share</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 23.3v-15M16.5 12.8L12 8.3l-4.5 4.5"/><path pid="1" d="M8.3 19.5H6a3 3 0 01-3-3V3.8a3 3 0 013-3h12a3 3 0 013 3v12.7a3 3 0 01-3 3h-2.2" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>'
  }
})
