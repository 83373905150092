/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heart-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>love favorite</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 21.844l-9.588-10h0A5.693 5.693 0 011.34 5.28v0h-.01a5.665 5.665 0 019.08-1.48l1.56 1.56 1.56-1.57v-.01a5.661 5.661 0 018.02 0c.43.43.78.92 1.06 1.47v0h0a5.66 5.66 0 01-1.07 6.54z"/>'
  }
})
