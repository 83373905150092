/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shield-bolt': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>protection lightning power flash</desc><path pid="0" _fill="currentColor" d="M24 1.953A1.959 1.959 0 0022.044.006H1.959A1.958 1.958 0 00.013 1.965L0 9.306a15.147 15.147 0 0011.862 14.669.974.974 0 00.194.019 1 1 0 00.2-.021A15.145 15.145 0 0023.988 9.2zm-7.895 8.511L8.9 18.4a.288.288 0 01-.478-.3l2.249-5.765a.251.251 0 00-.233-.341H8.315a.576.576 0 01-.5-.859l3.915-6.849a.574.574 0 01.5-.292h2.886a.575.575 0 01.493.873l-2.4 4.254a.249.249 0 00.217.373h2.257a.576.576 0 01.42.97z"/>'
  }
})
