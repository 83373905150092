/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'religion-cross-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 11.25v-1.5 0c0-.42-.34-.75-.75-.75h-2.26a.755.755 0 01-.75-.75s0 0 0 0V6v0c0-.42-.34-.75-.75-.75h-1.51c-.42 0-.75.33-.75.75 0 0 0 0 0 0v2.25c0 .41-.34.75-.75.75H7.47c-.42 0-.75.33-.75.75 0 0 0 0 0 0v1.5h0c0 .41.33.74.75.74h2.24-.01c.41-.01.75.33.75.75v6h0c0 .41.33.74.75.74h1.5-.01c.41 0 .75-.34.75-.75v-6h0c-.01-.42.33-.76.74-.76h2.25-.01c.41 0 .75-.34.75-.75zM7.5 4.5L4.5.75M.75 7.5L4.5 9M.75 17.25L4.5 15M7.5 19.5l-3 3.75M16.5 4.5l3-3.75M23.25 7.5L19.5 9M23.25 17.25L19.5 15M16.5 19.5l3 3.75"/></g>'
  }
})
