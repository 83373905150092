/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-protect-shield-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 9.25v4M23.25 9.25v4M.75 11.25h5.5M6.25 7.237v4.49h-.01a6.992 6.992 0 004.5 6.55l.61.23h0c.4.15.86.15 1.27-.01l.61-.24h-.01a7.034 7.034 0 004.5-6.56v-4.51c0-.35-.2-.66-.52-.8h0c-1.66-.73-3.44-1.09-5.24-1.08l-.01-.01c-1.81-.02-3.59.34-5.24 1.06v-.01c-.32.14-.52.45-.52.8zM17.75 11.25h5.5"/></g>'
  }
})
