/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-forbidden-close-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.083 7.811h0c1.49.23 2.79 1.13 3.55 2.43M6.22.75a2.82 2.82 0 100 5.65 2.82 2.82 0 100-5.65zM11.053 14.5l-.01-.001a1.51 1.51 0 01-1.5-1.5c0-.83.67-1.5 1.5-1.5h2.5M6.3 21.75v-5.19M9.3 16.99v4.75c0 .82-.68 1.5-1.5 1.5-.83 0-1.5-.68-1.5-1.5M10.8.871h-.01c1.49-.46 3.07.39 3.52 1.88a2.83 2.83 0 01-2.71 3.64h-.01c-.3 0-.59-.05-.87-.14"/><path pid="1" d="M.8 12.75h0a4.99 4.99 0 014.99-5.01h1v-.001a4.985 4.985 0 014.84 3.75"/><path pid="2" d="M6.3 21.75v0c0 .82-.68 1.5-1.5 1.5-.83 0-1.5-.68-1.5-1.5v-7.07h-1l-.01-.001a1.51 1.51 0 01-1.5-1.5v-.441M18.19 13.23a5 5 0 100 10 5 5 0 100-10zM21.73 14.7l-7.07 7.07"/></g>'
  }
})
