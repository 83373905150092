/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-coffee-travel-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="18" height="4.5" x="3" y="3.75" rx="1.5"/><path pid="1" d="M18.148 21.916v-.01c-.09.75-.73 1.33-1.5 1.33H7.31c-.77 0-1.41-.58-1.5-1.34L4.45 8.22h15zM18.415 1.579l-.01-.01c-.26-.51-.78-.83-1.35-.83H6.9L6.89.738c-.57-.01-1.09.32-1.35.82L4.45 3.72h15z"/><path pid="2" d="M12 12.75a3 3 0 100 6 3 3 0 100-6z"/></g>'
  }
})
