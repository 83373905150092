<template>
    <div>
        <div
            v-for="option in availableOptions"
            :key="option.id"
            class="mb-6"
        >
            <decision-input
                v-if="!isOptionHidden(option)"
                :title="option.title"
                :icon="option.settings.icon"
                :price="getOptionPrice(option)"
                :description="option.settings.description"
                :availability-message="getOptionAvailabilityMessage(option)"
                :value="isOptionSelected(option)"
                :class="{ 'opacity-25': isOutOfAvailability(option) }"
                @input="clickOption(option)"
            >
                <template v-if="option.type === 'other'" #active-view>
                    <input
                        v-theme="['form.accent', 'form.text']"
                        class="block w-full -mt-1 appearance-none outline-none py-2 px-4 border rounded-lg bg-transparent leading-normal transition duration-150 ease-in-out"
                        placeholder="Type..."
                        type="text"
                        :value="customValue"
                        @click.stop
                        @input="$event => setCustomValue($event.target.value)"
                    >
                </template>
            </decision-input>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OptionSelection',

    provide () {
        return {
            isMultipleChoice: this.isMultipleChoice
        };
    },

    props: {
        allowCustomChoice: {
            type: Boolean,
            default: false
        },

        isMultipleChoice: {
            type: Boolean,
            default: false
        },

        options: {
            type: Array,
            required: true
        },

        value: {
            type: Array,
            default: null
        }
    },

    computed: {
        availableOptions () {
            if (this.allowCustomChoice) {
                return this.sortedOptions;
            }

            return this.options.filter(({ type }) => {
                return type === 'primary';
            });
        },

        customValue () {
            if (!this.isOptionSelected(this.otherOption)) {
                return '';
            }

            const otherSelection = this.value.find(({ option }) => {
                return option === this.otherOption.id;
            });

            return otherSelection.custom || '';
        },

        otherOption () {
            return this.availableOptions.find(({ type }) => {
                return type === 'other';
            });
        },

        sortedOptions () {
            const optionsCopy = this.options.slice();

            optionsCopy.sort((optionA, optionB) => {
                if (optionA.type === 'primary' && optionB.type === 'other') {
                    return -1;
                }

                if (optionA.type === 'other' && optionB.type === 'primary') {
                    return 1;
                }

                return 0;
            });

            return optionsCopy;
        }
    },

    methods: {
        deselectOption (option) {
            this.$emit('input', this.value.filter((selectedOption) => {
                return selectedOption.option !== option.id;
            }));
        },

        getOptionAvailabilityMessage (option) {
            if (this.isOutOfAvailability(option)) {
                return option.settings.maxCapacity.showMessage ? option.settings.maxCapacity.message : 'This option is no longer available.';
            }

            return '';
        },

        getOptionPrice (option) {
            return option.settings.costsMoney ? option.price : null;
        },

        isOptionHidden (option) {
            if (this.isOutOfAvailability(option)) {
                return option.settings.maxCapacity.showMessage === false;
            }

            return false;
        },

        isOptionSelected (option) {
            return this.value.some((selectedOption) => {
                return selectedOption.option === option.id;
            });
        },

        isOutOfAvailability (option) {
            const availabilitySettings = option.settings.maxCapacity || {};

            return availabilitySettings.enabled && option.quantity_remaining === 0;
        },

        clickOption (option) {
            // Is the user deselecting a selected option?
            if (this.isOptionSelected(option) && this.isMultipleChoice) {
                this.deselectOption(option);
                return;
            }

            if (this.isOutOfAvailability(option)) {
                return;
            }

            this.selectOption(option);
        },

        selectOption (option) {
            this.$emit('input', [
                ...this.value,
                { option: option.id, custom: null }
            ]);
        },

        setCustomValue (value) {
            if (!this.isOptionSelected(this.otherOption)) {
                return;
            }

            const primarySelections = this.value.filter(({ option }) => {
                return option !== this.otherOption.id;
            });

            this.$emit('input', [
                ...primarySelections,
                {
                    option: this.otherOption.id,
                    custom: value
                }
            ]);
        }
    }
};
</script>
