/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dessert-ice-cream-popsicle-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.5 17.25H7.49a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-9h0A5.992 5.992 0 0111.98.74v0h-.01c3.31-.01 6 2.68 6 6v9h0c0 .82-.68 1.49-1.5 1.5zM12 23.25v-6M10.5 6.75v6M13.5 6.75v6"/></g>'
  }
})
