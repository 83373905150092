/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-july4-fireworks-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.375 2.25h-.01c.2-.01.37.16.37.37"/><path pid="1" d="M18 2.625h0c-.01-.21.16-.38.37-.38M18.375 3l-.01-.001a.386.386 0 01-.38-.38v-.001M18.75 2.625v0c0 .2-.17.375-.375.375M1.875 22.5h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M1.5 22.875h0c-.01-.21.16-.38.37-.38M1.875 23.25h-.01a.386.386 0 01-.38-.38s0 0 0 0M2.25 22.875v0c0 .2-.17.375-.375.375M22.125 17.25h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M21.75 17.625h0c-.01-.21.16-.38.37-.38M22.125 18h-.01a.386.386 0 01-.38-.38s0 0 0 0M22.5 17.625v0c0 .2-.17.375-.375.375M12 2.25V13.5M12 13.47s2.02-5.891 7.183-7.37M12 13.47h0c-1.28-3.52-4-6.33-7.47-7.7M.75 12.752H.74c3.7-1.21 7.72-.96 11.24.71M4.912 19.03S7.5 13.17 12 13.46"/><path pid="4" d="M23.25 12.752l-.01-.01c-3.71-1.21-7.73-.96-11.25.71M19.08 19.03s-2.59-5.86-7.09-5.57M3 .75v3M4.5 2.25h-3M12 19.5v3M13.5 21h-3"/></g>'
  }
})
