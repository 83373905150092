/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'season-autumn-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>fall tree leaf</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.582 20.754s1.22 2.49 3.27 2.308c4.15-.38 3.88-5.907 5.4-7.859h0a9.1 9.1 0 00-8.27.07c-2.16 1.22-2.31 3.27-.41 5.47zM17.523 18.219s-2.7.33-4.63 5.03M20.026 11.491v-.01c.67-1.53.99-3.19.91-4.85l-.01-.01a.494.494 0 00-.45-.46h0c-2.51-.26-5 .63-6.77 2.42l-.01-.01a15.08 15.08 0 00-2.49-7.67h0a.482.482 0 00-.67-.11c-.04.02-.08.06-.11.1h-.01a14.889 14.889 0 00-2.49 7.66l-.01-.01a8.3 8.3 0 00-6.77-2.43h-.01c-.25.01-.44.21-.45.45-.08 1.62-.18 6.72 5.98 8.36l-2.372 1.35h-.01a.476.476 0 00.13.88c1.38.28 3.3 1.24 5.98-.3M10.5 21v-4.04"/></g>'
  }
})
