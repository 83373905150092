/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-copy-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>duplicate clone</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.625 23.25H4.115a1.51 1.51 0 01-1.5-1.5s0 0 0 0V5.62"/><path pid="1" d="M21.375 18.15h0c.02.99-.76 1.81-1.76 1.84H7.36h0a1.8 1.8 0 01-1.75-1.85V2.57l-.01-.01c-.03-1 .75-1.82 1.75-1.85h8.9c.46 0 .91.19 1.23.53l3.349 3.524h0c.33.35.51.81.51 1.3z"/></g>'
  }
})
