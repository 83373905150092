/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-add-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>document create new plus text</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 11.25a6 6 0 100 12 6 6 0 100-12zM17.25 14.25v6M14.25 17.25h6"/><path pid="1" d="M8.25 20.25H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V2.25h0C.73 1.42 1.41.74 2.23.74h10.62l-.01-.001c.39 0 .77.15 1.06.43l2.872 2.87h0c.28.28.43.66.43 1.06v3.12"/></g>'
  }
})
