<template>
    <app-modal
        :value="value"
        header-classes="items-center"
        @input="$emit('input', $event)"
    >
        <template #header>
            <div class="flex items-center">
                <div class="shrink pr-4">
                    <img
                        class="w-10"
                        :src="asset('/images/account/rsvpify-premium.svg')"
                        alt="RSVPify Premium"
                    >
                </div>
                <div class="text-lg text-teal font-bold grow">
                    Keep your free upgrade?
                </div>
            </div>
        </template>

        <div class="text-gray-600 text-lg">To keep your free upgrade and publish your event, please...</div>

        <div class="mt-2 space-y-2">
            <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 w-full">
                <div v-if="! event.isStripeConnected" class="flex-auto">
                    <a
                        :href="route('stripe.connect.start-authorization', event)"
                        class="button button-primary w-full"
                    >
                        Connect payment processor
                    </a>
                </div>

                <div v-if="! event.hasPayableProducts" class="flex-auto">
                    <button
                        class="button button-primary w-full"
                        @click="addPaidProduct"
                    >
                        Add a paid product
                    </button>
                </div>
            </div>

            <stateful-button
                class="button button-secondary w-full"
                :loading="saving"
                @click="removeComp"
            >
                No longer selling tickets for this event
            </stateful-button>
        </div>
    </app-modal>
</template>

<script>
import { get } from 'vuex-pathify';
import { merge } from 'lodash';
import axios from '@/util/axios';

export default {
    name: 'CompRequiresAction',

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            saving: false
        };
    },

    computed: {
        event: get('Event/event')
    },

    methods: {
        addPaidProduct () {
            this.$emit('addPaidProduct');
            this.$emit('input', false);
        },

        removeComp () {
            const onConfirm = () => {
                this.saving = true;

                axios.post(this.route('api.events.remove-comp', { event: this.event }))
                    .then(({ data }) => {
                        merge(this.event, data);
                        this.$emit('input', false);
                    }).catch(() => {
                        this.$toasted.global.error('There was a problem removing the free upgrade. Please try again.');
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            };

            App.alert().confirm(
                'Are you sure?',
                'If you’re no longer processing payments through RSVPify, your free upgrade to RSVPify Professional will be removed.',
                {
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel'
                },
                onConfirm
            );
        }
    }
};
</script>
