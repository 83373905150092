<template>
    <div>
        <form-field-wrapper
            label="Course Name"
            :should-show-error="form.errors.has('title')"
            :error="form.errors.get('title')"
        >
            <input
                v-model="form.title"
                class="form-field"
                type="text"
                name="course-name"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Description (optional)">
            <textarea
                v-model="form.description"
                class="form-field"
                placeholder="Enter a description and details about this course."
                rows="5"
            ></textarea>
        </form-field-wrapper>

        <form-field-wrapper class="-mb-4">
            <button class="button-text button-info" @click="createItem">
                <app-icon
                    name="add-circle"
                    class="h-5 w-5 mr-2"
                    stroke
                ></app-icon>
                <span>Add Selection</span>
            </button>
        </form-field-wrapper>

        <form-field-wrapper
            :should-show-error="form.errors.has('meals')"
            :error="form.errors.get('meals')"
        >
            <draggable-list
                v-model="form.meals"
                confirm-delete
                editable
                deletable
                unique-property="id"
                label-property="title"
                switchable-property="active"
                :set-sort-property="true"
                @edit-item="editItem"
                @delete-item="deleteItem"
                @input="handleMealsUpdate"
            ></draggable-list>
        </form-field-wrapper>

        <form-field-wrapper
            v-if="form.askIf"
            label="Ask if attending"
            :error="form.errors.get('askIf.target')"
            :should-show-error="form.errors.has('askIf.target')"
        >

            <form-field-wrapper
                :error="form.errors.get('askIf.targetId')"
                :should-show-error="form.errors.has('askIf.targetId')"
            >
                <simple-picker
                    v-model="form.askIf.targetId"
                    :items="events"
                    item-label="label"
                    item-value="value"
                    class="w-full"
                ></simple-picker>
            </form-field-wrapper>
        </form-field-wrapper>

        <form-field-wrapper
            v-if="form.additionalCriteria"
            label="Additional Criteria"
            :error="form.errors.get('askIf.target')"
            :should-show-error="form.errors.has('askIf.target')"
        >
            <select-list
                v-model="form.additionalCriteria.target"
                name="additionalCriteria.target"
            >
                <select-option
                    id="none"
                    class="my-2"
                    label="None"
                ></select-option>

                <select-option
                    id="tagged"
                    class="my-2"
                    label="Show only if tagged with..."
                    :disabled="tags.length === 0"
                ></select-option>
                <form-field-wrapper
                    v-if="showIncludeAdditionalGuestsToggle"
                    :error="form.errors.get('additionalCriteria.query.tagIds.value')"
                    :should-show-error="form.errors.has('additionalCriteria.query.tagIds.value')"
                >
                    <simple-picker
                        v-model="form.additionalCriteria.query.tagIds.value"
                        placeholder-empty-state="Select tag..."
                        :items="tags"
                        item-label="name"
                        item-value="id"
                        class="w-full pl-8"
                        @input="form.additionalCriteria.query.tagIds.comparison = 'equals'"
                    ></simple-picker>
                </form-field-wrapper>
                <toggle-switch
                    v-if="showIncludeAdditionalGuestsToggle"
                    v-model="form.additionalCriteria.includeAdditionalGuests"
                    class="mt-4 pl-8"
                >
                    Include additional guests
                </toggle-switch>
            </select-list>
        </form-field-wrapper>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import BlockableGeneralSettingsMixin from '@/mixins/BlockableGeneralSettingsMixin';
import HasSecondaryEvents from '@/mixins/HasSecondaryEvents';

export default {
    mixins: [BlockableGeneralSettingsMixin, HasSecondaryEvents],

    props: {
        event: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            itemsProperty: 'meals'
        };
    },

    computed: {
        ...get('Event/event@', {
            tags: 'tags'
        }),

        showIncludeAdditionalGuestsToggle () {
            return this.form.additionalCriteria.target === 'tagged' && this.tags.length !== 0;
        }
    },

    methods: {
        handleMealsUpdate (updatedMeals) {
            this.form.meals = updatedMeals;

            this.$emit('update-meals', updatedMeals);
        }
    }
};
</script>
