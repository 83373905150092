<template>
    <div
        v-if="comped"
        v-tippy
        class="flex items-center mr-4 text-teal font-semibold"
        title="You’re selling tickets to this event and received a free upgrade to RSVPify’s professional-grade features!"
    >
        <img class="w-6 h-6 mr-1" :src="asset('images/account/rsvpify-premium.svg')">
        Upgraded
    </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    name: 'CompedTicketingEventBadge',

    computed: {
        comped: get('Event/event@isComped')
    }
};
</script>
