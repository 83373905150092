<template>
    <div class="container">
        <div class="py-2 font-semibold text-xl text-black border-b">About You</div>

        <div class="flex flex-col md:flex-row mt-8">
            <div class="w-full md:w-1/4">
                <form-field-wrapper label="Full Name" :error="form.errors.get('name')">
                    <input
                        v-model="form.name"
                        class="form-field"
                        placeholder="Full Name"
                    >
                </form-field-wrapper>

                <form-field-wrapper label="Email" :error="form.errors.get('email')">
                    <input
                        v-if="isUsersEmailConfirmed"
                        v-model="form.email"
                        class="form-field"
                        placeholder="Email"
                    >

                    <div v-else class="flex">
                        <p class="flex-auto">{{ form.email }}</p>
                        <div class="badge badge-warning">Confirmation Pending</div>
                    </div>
                </form-field-wrapper>
            </div>

            <div class="w-full md:w-1/4 ml-0 mt-4 md:mt-0 md:ml-14">
                <form-field-wrapper label="Avatar">
                    <div class="group relative overflow-hidden rounded-full left-0 right-0 w-1/4">
                        <img
                            v-if="initialUser.avatar_url"
                            :alt="`${initialUser.name}`"
                            :src="initialUser.avatar_url"
                            class="w-full h-full"
                        >

                        <app-icon
                            v-else
                            name="user-circle"
                            class="w-full h-full text-gray-500"
                            stroke
                        ></app-icon>

                        <image-uploader
                            :url="route('account.store-avatar')"
                            crop-on-upload
                            remote-upload
                            class="absolute w-full h-1/3 bottom-0 left-0 right-0 p-1 visible md:hidden md:group-hover:block cursor-pointer bg-purple-900 text-center text-xs text-white"
                            @upload-image="avatarUploaded"
                        >
                            Edit
                        </image-uploader>
                    </div>
                </form-field-wrapper>
            </div>
        </div>

        <div v-if="!isUsersEmailConfirmed" class="pt-3">
            <div class="italic">Please follow the confirmation link sent to {{ form.email }} to complete the email address confirmation. </div>
            <button class="button-text button-primary pt-3" @click="cancelEmailChange">Cancel Confirmation</button>
        </div>

        <stateful-button
            class="button-primary w-24 h-9 mt-4"
            :loading="form.processing"
            @click="saveUser"
        >Save</stateful-button>
    </div>
</template>

<script>
import Form from '@/validation/Form';

export default {
    name: 'MyAccount',

    props: {
        initialUser: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            email_verified_at: this.initialUser.email_verified_at,
            form: new Form({
                name: this.initialUser.name,
                email: this.initialUser.email
            })
        };
    },

    computed: {
        isUsersEmailConfirmed () {
            return this.email_verified_at;
        }
    },

    methods: {
        avatarUploaded () {
            window.location.reload();
        },

        cancelEmailChange () {
            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            const onConfirm = () => {
                this.form.email = '';
                this.email_verified_at = true;
                this.$toasted.global.success('Email change has been cancelled.');
            };

            App.alert().confirm(
                'Are you sure?',
                'Are you sure you want to cancel the email change?',
                attributes,
                onConfirm
            );
        },

        saveUser () {
            this.form.patch(this.route('api.account.update'))
                .then(() => {
                    this.$toasted.show('Your profile has been saved.', {
                        onComplete: () => {
                            window.location.reload();
                        }
                    });
                });
        }
    }
};
</script>
