/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'school-building-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>college education</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.5 23.25H.75V15h0c-.01-.83.67-1.51 1.49-1.51h5.25M16.5 13.5h5.25-.01c.82-.01 1.5.67 1.5 1.5v8.25h-6.75M13.5 19.31h0c-.06-.78-.73-1.37-1.5-1.32h0c-.78-.05-1.45.53-1.5 1.31v3.93h3zM12 6.75v-6M12 .75h4-.01c.27-.01.5.22.5.49v2c0 .27-.23.5-.5.5h-4v-3zM12 9.81a1.46 1.46 0 100 2.93 1.46 1.46 0 100-2.94z"/><path pid="1" d="M16.5 11.11h0c-.04-2.45-2.06-4.41-4.5-4.37l-.01-.001a4.42 4.42 0 00-4.5 4.36v12.13h9zM3.75 16.5h.75M3.75 20.25h.75M19.5 16.5h.75M19.5 20.25h.75"/></g>'
  }
})
