/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>delete remove</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 23.25L23.25.75M23.25 23.25L.75.75"/></g>'
  }
})
