<template>
    <div class="md:mx-6 text-gray-600 invite-list">
        <large-invitee-groups-notification
            v-for="notification in largeInviteeGroupsNotifications"
            :key="notification.id"
            :notification="notification"
        ></large-invitee-groups-notification>

        <div v-if="showDuplicatingInviteListAlert" class="flex flex-col md:flex-row alert alert-warning items-center">
            <app-icon name="alert-triangle" class="h-6 w-6 mr-0 md:mr-4 mb-4 md:mb-0"></app-icon>
            <span class="text-center md:text-left">Your invite list is being cloned. For larger events, this may take a few minutes. Please come back or refresh this page in a few minutes.</span>
        </div>

        <div v-if="totalInviteeCount > 0" class="flex space-x-16">
            <div class="w-1/2 text-center md:text-left md:w-auto md:shrink">
                <div class="block text-4xl tracking-wide font-bold">
                    {{ totalInviteeCount + additionalGuestsCount | number }}<span v-if="unlimitedAdditionalGuests">+</span>
                </div>
                <span
                    v-tippy
                    :content="guestsOnListTooltip"
                    class="block uppercase text-sm tracking-wide tooltip-text hover:cursor-help"
                >Guests on list</span>
            </div>
            <div class="w-1/2 text-center md:text-left md:w-auto">
                <span class="block text-4xl tracking-wide font-bold">{{ replyPercentage }}%</span>
                <span class="block uppercase text-sm tracking-wide">Replied</span>
            </div>
        </div>

        <data-columns
            v-show="totalInviteeCount > 0"
            ref="dataColumns"
            :custom-column-definitions="customColumnDefinitions"
            :default-columns="['firstName', 'lastName', 'email', 'reply', 'additionalGuests', 'options']"
            :event="event"
            :mapper="mapper"
            :required-columns="['id', 'inviteeGroupId', 'submissionId', 'formalName', 'reply']"
            :route-resolver="routeResolver"
            :total-item-count="filteredInviteesCount"
            with-preflight
            @click-row="openGroupModal"
            @received-response="onReceivedResponse"
        >
            <template #header="{ allPredicates, availableColumns, chosenColumns, grouped, predicates, reload, reset, searchQuery, selectedAll, selectedIds, setChosenColumns, setPredicates, setSearchQuery }">
                <div class="w-full flex flex-wrap items-center space-x-2">
                    <bulk-actions
                        :ids="selectedIds"
                        :all="selectedAll"
                        :predicates="allPredicates"
                        :total="filteredInviteesCount"
                        :disabled="actionsButton.disabled"
                        :disabled-tooltip="actionsButton.disabledTooltip"
                    >
                        <template #default="bulkActionData">
                            <bulk-tag
                                :bulk-action-data="bulkActionData"
                                :confirmation-message="`Tagging ${bulkActionData.formatted.totalSelected} invitee(s).  This may take a few minutes.`"
                                :modal-title="`Tagging ${bulkActionData.formatted.totalSelected} invitee(s)`"
                                :confirm-button-text="`Tag ${bulkActionData.formatted.totalSelected} Invitee(s)`"
                                :endpoint="route('api.events.invitees.bulk.tag', event)"
                                @rows-tagged="reload"
                            ></bulk-tag>

                            <bulk-untag
                                :bulk-action-data="bulkActionData"
                                :confirmation-message="`Untagging invitee(s).  This may take a few minutes.`"
                                :modal-title="`Untagging invitee(s)`"
                                :confirm-button-text="`Untag Invitee(s)`"
                                :counts-endpoint="route('api.events.invitees.tags.counts', event)"
                                :endpoint="route('api.events.invitees.bulk.untag', event)"
                                @rows-untagged="reload"
                            >
                            </bulk-untag>

                            <bulk-delete
                                :bulk-action-data="bulkActionData"
                                :endpoint="route('api.events.invitees.bulk.destroy', event)"
                                :delete-confirm-button-text="`DELETE ${bulkActionData.formatted.totalSelected} INVITEE(S).`"
                                :delete-message="`You are permanently deleting ${bulkActionData.formatted.totalSelected} invitee(s) from your Invite List. This cannot be undone. If any of these invitees have submitted a reply, their reply will NOT be deleted. Type delete below to confirm.`"
                                :confirmation-message="`Deleting ${bulkActionData.formatted.totalSelected} invitee(s).  This may take a few minutes.`"
                                require-type-delete-confirmation
                                @rows-deleted="reload"
                            ></bulk-delete>

                            <bulk-mark-invitee-state
                                :event="event"
                                :bulk-action-data="bulkActionData"
                                state="attending"
                                label="Mark Attending"
                                @rows-updated="reload"
                            ></bulk-mark-invitee-state>

                            <bulk-mark-invitee-state
                                v-if="event.setup_type === 'rsvp'"
                                :event="event"
                                :bulk-action-data="bulkActionData"
                                state="maybe"
                                label="Mark Maybe Attending"
                                @rows-updated="reload"
                            ></bulk-mark-invitee-state>

                            <bulk-mark-invitee-state
                                :event="event"
                                :bulk-action-data="bulkActionData"
                                state="not-attending"
                                label="Mark Not Attending"
                                @rows-updated="reload"
                            ></bulk-mark-invitee-state>

                            <bulk-edit-guest-limit :bulk-action-data="bulkActionData" @rows-updated="reload"></bulk-edit-guest-limit>
                        </template>
                    </bulk-actions>

                    <div class="flex-auto">
                        <search-field
                            class="w-full md:w-80 pr-6"
                            placeholder="Name, email, confirmation..."
                            :value="searchQuery"
                            @input="setSearchQuery"
                        ></search-field>
                    </div>

                    <choosable-columns
                        :value="chosenColumns"
                        :columns="availableColumns"
                        @input="setChosenColumns"
                    ></choosable-columns>

                    <button
                        class="bg-white rounded-md border p-2 disabled:opacity-50 hidden md:block"
                        :class="resetButtonStyles(grouped)"
                        :disabled="grouped"
                        @click="reset"
                    >
                        <app-icon
                            name="team"
                            class="w-6 h-6"
                            stroke
                        ></app-icon>
                    </button>

                    <a
                        :href="route('invite-list.import', event)"
                        class="items-center bg-white rounded-md border p-2 hover:border-purple hover:cursor-pointer text-gray-600 hover:text-purple hidden md:flex"
                    >
                        <app-icon
                            name="upload-circle"
                            class="w-5 h-5 mr-2"
                            stroke
                        ></app-icon>
                        Import
                    </a>

                    <invite-list-export-button
                        class="hidden md:block"
                        :chosen-columns="chosenColumns"
                        :predicates="allPredicates"
                    ></invite-list-export-button>

                    <button class="button button-primary p-2" @click="showAddNewGroupModal">
                        <app-icon
                            class="w-5 h-5 mr-2"
                            name="add-circle"
                            stroke
                        ></app-icon>
                        Add Guest
                    </button>
                </div>

                <data-columns-predicate-list
                    class="mt-2 pl-2 hidden md:block"
                    :include-reply-field="event.setup_type === 'tickets'"
                    :value="predicates"
                    @input="setPredicates"
                ></data-columns-predicate-list>
            </template>

            <template #row="{ property, row }">
                <template v-if="property === 'options'">
                    <button
                        v-if="!row.submissionId"
                        class="button-text hover:text-red ml-2"
                        @click.stop="deleteInvitee(row)"
                    >
                        <app-icon
                            name="trash"
                            class="w-5 h-5"
                            stroke
                        ></app-icon>
                    </button>
                </template>
            </template>

            <template #summary="{ row }">
                <p>{{ row.formalName }}</p>

                <template v-if="row.reply && (row.reply.state || row.reply.product)">
                    <div v-if="row.reply.state === 'NotAttending'" class="flex items-center space-x-2">
                        <app-icon
                            name="close-circle"
                            class="text-red h-5 w-5"
                        ></app-icon>

                        <span>{{ event.declineTitle }}</span>
                    </div>

                    <div v-else class="flex items-center">
                        <guest-reply-icon
                            class="mr-2 min-h-5 min-w-5"
                            :state="row.reply.state"
                            colored
                        ></guest-reply-icon>

                        <span>{{ row.reply.product }}</span>

                        <span v-if="row.reply.timeslot" class="font-light ml-2">
                            ({{ row.reply.timeslot | timeslotDate(eventTimezone.name) }})
                        </span>
                    </div>
                </template>
            </template>
        </data-columns>

        <div v-if="showPlaceholderActions" class="flex flex-col md:flex-row justify-center mb-10">
            <a class="button button-primary" @click="importInviteList">
                <app-icon name="arrow-up-circle" stroke></app-icon>
                Import List
            </a>

            <a class="button button-primary mt-2 ml-0 md:mt-0 md:ml-4" @click="showAddNewGroupModal">
                <app-icon name="add-circle" stroke></app-icon>
                Manual Addition
            </a>
        </div>

        <div
            v-if="totalInviteeCount === 0"
            class="flex flex-col items-center justify-center"
            :class="emptyStateStyles"
            @click="handleEmptyStateClick"
        >
            <p class="text-2xl font-semibold mb-6">No invitees added... yet.</p>
            <p class="mb-6 text-center">Creating an invite list is optional. Add invitees if you plan to send email invitations or are hosting a private event with an exclusive guest list.</p>

            <img
                class="w-128"
                :src="asset('images/empty-states/invite-list.svg')"
                alt="Invite List empty state"
            >
        </div>

        <invitee-group-modal
            v-model="showGroupDetailsModal"
            :initial-group="selectedGroup"
            :default-invitee="defaultInvitee"
            :is-invite-list-empty="totalInviteeCount === 0"
            @saved="updateDataTable"
            @deleted-group="reloadData"
            @view-reply="viewGroupReply"
        ></invitee-group-modal>

        <party-details-modal
            v-model="showPartyDetailsModal"
            :initial-submission="selectedSubmission"
            @delete-submission="deletedSubmission"
            @saved="updateDataTable"
        ></party-details-modal>

        <invite-list-filter-drawer
            :value="false"
            :filter-settings="{}"
        ></invite-list-filter-drawer>

        <email-agreement v-model="showEmailAgreementModal" @accepted="acceptedEmailTerms"></email-agreement>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import getValue from 'lodash/get';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { sync } from 'vuex-pathify';
import axios from '@/util/axios';
import BulkActionsButtonAuthorization from '@/mixins/BulkActionsButtonAuthorization';
import EmailAgreement from '@/components/campaigns/EmailAgreement.vue';

export default {
    components: { EmailAgreement },
    mixins: [
        BulkActionsButtonAuthorization
    ],

    props: {
        canCreateInvitees: {
            type: Boolean,
            default: false
        },

        defaultInvitee: {
            type: Object,
            required: true
        },

        event: {
            type: Object,
            required: true
        },

        largeInviteeGroupsNotifications: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            accepted: this.auth().user().accepted_email_terms,
            acceptedEmailTermsAction: null,
            additionalGuestsCount: 0,
            filteredInviteesCount: 0,
            replyPercentage: 0,
            selectedGroup: {},
            selectedSubmission: {},
            showGroupDetailsModal: false,
            showPartyDetailsModal: false,
            showEmailAgreementModal: false,
            totalInviteeCount: 0,
            unlimitedAdditionalGuests: false
        };
    },

    computed: {
        ...sync('InviteList/*'),

        customColumnDefinitions () {
            return [
                {
                    label: 'Password',
                    property: 'password',
                    sortable: false
                },
                {
                    label: 'Added',
                    property: 'timestamp',
                    sortable: true
                },
                {
                    label: '',
                    property: 'options',
                    sortable: false,
                    virtual: true
                }
            ];
        },

        emptyStateStyles () {
            return {
                'cursor-pointer': this.canCreateInvitees
            };
        },

        guestsOnListTooltip () {
            return this.unlimitedAdditionalGuests
                ? 'Count includes any additional +1 guests allowed. You’ve offered at least one invitee on your list UNLIMITED additional guests.'
                : 'Count includes any additional +1 guests allowed.';
        },

        showDuplicatingInviteListAlert () {
            return getValue(this.event, 'metadata.duplicating.inviteList', false);
        },

        showPlaceholderActions () {
            return this.totalInviteeCount === 0 && this.canCreateInvitees;
        }
    },

    methods: {
        acceptedEmailTerms () {
            this.accepted = true;

            if (this.acceptedEmailTermsAction === 'import') {
                this.goToInviteList();
                return;
            }

            this.showAddNewGroupModal();
        },

        importInviteList () {
            if (this.accepted) {
                this.goToInviteList();
                return;
            }

            this.acceptedEmailTermsAction = 'import';
            this.showEmailAgreementModal = true;
        },

        goToInviteList () {
            window.location = this.route('invite-list.import', this.event);
        },

        deleteInvitee (invitee) {
            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            const onConfirm = async () => {
                const route = this.route('api.events.invitees.destroy', {
                    event: this.event,
                    invitee
                });

                try {
                    await axios.delete(route);
                    this.reloadData();
                } catch (e) {
                    this.$toasted.global.error('There was an error deleting the invitee.');
                }
            };

            App.alert().confirm(
                'Are you sure?',
                'Are you sure you want to delete this invitee?',
                attributes,
                onConfirm
            );
        },

        deletedSubmission () {
            this.reloadData();

            this.$toasted.global.success('The group has been deleted.');
        },

        handleEmptyStateClick () {
            if (this.canCreateInvitees) {
                this.showAddNewGroupModal();
            }
        },

        mapper (items, grouped) {
            if (!grouped) {
                return items;
            }

            const groups = Object.values(
                groupBy(items, 'inviteeGroupId')
            );

            return sortBy(groups, (group) => {
                return items.indexOf(group[0]);
            });
        },

        onReceivedResponse (response) {
            this.additionalGuestsCount = response.data.additionalGuestsCount;
            this.filteredInviteesCount = response.data.filteredInviteesCount;
            this.replyPercentage = response.data.replyPercentage;
            this.totalInviteeCount = response.data.totalInviteeCount;
            this.unlimitedAdditionalGuests = response.data.unlimitedAdditionalGuests;
        },

        async openGroupModal ({ inviteeGroupId }) {
            const { data } = await axios.get(this.route('api.events.invitee-groups.show', {
                event: this.event,
                invitee_group: inviteeGroupId
            }));

            this.selectedGroup = data.data;
            this.showGroupDetailsModal = true;
        },

        resetButtonStyles (grouped) {
            return {
                'hover:border-purple hover:cursor-pointer text-gray-600 hover:text-purple': !grouped
            };
        },

        routeResolver (grouped) {
            return grouped
                ? 'api.events.invitee-groups.index'
                : 'api.events.invitees.index';
        },

        viewGroupReply (submission) {
            this.$set(this, 'selectedSubmission', submission);

            this.showGroupDetailsModal = false;
            this.showPartyDetailsModal = true;
        },

        reloadData () {
            this.$refs.dataColumns.reload();
        },

        showAddNewGroupModal () {
            if (!this.accepted) {
                this.showEmailAgreementModal = true;
                this.acceptedEmailTermsAction = 'add';
                return;
            }

            this.$set(this, 'selectedGroup', {
                invitees: [
                    cloneDeep(this.defaultInvitee)
                ]
            });

            this.showGroupDetailsModal = true;
        },

        updateDataTable (groupSize) {
            if (groupSize && this.totalInviteeCount === 0) {
                this.totalInviteeCount = groupSize;
            }

            this.showPartyDetailsModal = false;
            this.showGroupDetailsModal = false;

            this.reloadData();
        }
    }
};
</script>
