/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'time-clock-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>watch</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 1.5a10.5 10.5 0 100 21 10.5 10.5 0 100-21z"/><path pid="1" d="M12 10.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM12 10.5V5.25M13.06 13.06l2.69 2.69"/></g>'
  }
})
