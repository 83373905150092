/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fruit-strawberry-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 23.25c5.273 0 12.18-14.057 3.75-15h-7.5c-8.438.94-1.524 15 3.75 15z"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.25-.19a1.59 3.18 0 100 6.36 1.59 3.18 0 100-6.37z" transform="matrix(.7071 -.70711 .7071 .7071 .295 6.712)"/><path pid="2" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.75 1.4a3.18 1.59 0 100 3.18 3.18 1.59 0 100-3.19z" transform="matrix(.7071 -.70711 .7071 .7071 2.492 12.015)"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="3" d="M7.5 11.25l.75.75M9.75 15l.75.75M13.5 15l.75.75M11.62 18.75l.76.75M11.62 11.25l.76.75M15.75 11.25l.75.75"/></g>'
  }
})
