/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-fishing-line-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.916 16.451l-2.173-.82-.01-.01c-.4-.16-.85.04-1 .44h-.01l-1.9 5.06v-.01c-.3.8.1 1.69.9 1.99.8.29 1.69-.11 1.99-.91z"/><path pid="1" d="M4.46 15.9l1.63-4.35C8.25 5.75 12.14-.22 15.04.86c2.89 1.08-.46 5.37-1.22 6.56-3.46 5.38.4 7.03 3.32 4.63.4-.33 1.13-.97 1.13-.97 2.92-2.5 5.058.4 2.48 2.526-1.468 1.21-1.51 2.73-.31 3.35h0c1 .42 2.16.07 2.78-.83"/><path pid="2" d="M8.08 14.94a2.32 2.32 0 100 4.64 2.32 2.32 0 100-4.64z"/></g>'
  }
})
