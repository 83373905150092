/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-instagram-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" _stroke="currentColor" _fill="none" stroke-linejoin="round" stroke-width="1.5"><rect pid="0" width="23" height="23" x=".5" y=".5" rx="6"/><path pid="1" d="M12 6a6 6 0 100 12 6 6 0 100-12zM19 3.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/></g>'
  }
})
