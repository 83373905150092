/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fruit-apple': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g _fill="currentColor"><path pid="0" d="M16 6a6.888 6.888 0 00-2.656.409.25.25 0 01-.344-.232V5.5a3 3 0 00-3-3H8a1 1 0 000 2h2a1 1 0 011 1v.677a.248.248 0 01-.111.208.251.251 0 01-.233.024A6.888 6.888 0 008 6c-3.364 0-6 3.294-6 7.5C2 17.456 5.038 24 8.5 24c1.8 0 2.331-.342 2.793-.754A.806.806 0 0112 23a.806.806 0 01.707.246c.462.412.992.754 2.793.754 3.462 0 6.5-6.544 6.5-10.5C22 9.294 19.364 6 16 6z"/><path pid="1" d="M14.7 5.414a5.013 5.013 0 004.252-4.254A1.016 1.016 0 0017.8.01a5.015 5.015 0 00-4.254 4.255A1.015 1.015 0 0014.7 5.414zm-.069-.5z"/></g>'
  }
})
