/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>photography slr picture</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.25 7.5a5.25 5.25 0 100 10.5 5.25 5.25 0 100-10.5z"/><path pid="1" d="M19.5 6l-1.09-2.18-.01-.01c-.26-.51-.78-.83-1.35-.83H11.4l-.01-.001c-.57-.01-1.09.32-1.35.82L8.95 5.96H2.19c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v12h0c0 .82.67 1.49 1.5 1.49h19.5-.01c.82 0 1.5-.68 1.5-1.5V7.449c0-.83-.68-1.5-1.5-1.5h-.01z"/><path pid="2" d="M4.12 9h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38v-.001M5.25 6V4.5"/></g>'
  }
})
