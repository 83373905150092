<template>
    <div class="flex flex-col w-full items-center justify-center">
        <div v-theme="['form.text']" class="flex flex-col items-center justify-center">
            <button class="flex md:hidden items-center justify-center text-xl" @click="$emit('select-another-date')">
                <app-icon
                    name="arrow-left-chevron"
                    class="h-4 w-4 mr-2"
                ></app-icon>
                {{ $t('button-select-another-date') }}
            </button>

            <button class="text-lg mt-4 md:mt-0" @click="$emit('select-another-date')">{{ selectedDateFormatted }}</button>

            <div v-if="event.timezone" class="flex items-center justify-center">
                <app-icon
                    name="time-clock"
                    class="h-4 w-4 mr-2"
                    stroke
                ></app-icon>
                <p class="text-sm">{{ event.timezone.name.replace(/_/g, ' ') }}</p>
            </div>
        </div>

        <div v-if="loading" class="flex flex-col items-center justify-center h-72">
            <app-icon
                v-theme="['form.text']"
                name="loader"
                class="w-12 h-12"
            ></app-icon>
        </div>

        <div v-else class="flex flex-col w-full mt-4 h-72">
            <div v-bar="{ useScrollbarPseudo: true }" class="flex-1">
                <div class="space-y-2">
                    <div
                        v-if="noAvailableTimeslots"
                        v-theme="['form.accent']"
                        class="text-center"
                    >
                        No available slots for the selected date.
                    </div>

                    <button
                        v-for="timeslot in timeslots"
                        v-theme="['form.accent']"
                        :class="timeslotStyle(timeslot)"
                        class="flex items-center justify-center w-full py-2 rounded-md border-2"
                        :disabled="!timeslot.available"
                        :style="timeslotTheming(timeslot)"
                        @click="toggleTimeslot(timeslot)"
                    >
                        <p v-theme="['form.text']">
                            {{ formatTimeRepresentation(timeslot.from) }}
                            <span v-if="showDuration"> to {{ formatTimeRepresentation(timeslot.to) }}</span>
                        </p>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/util/axios';
import { DateTime } from 'luxon';
import { get, sync } from 'vuex-pathify';
import { get as getValue } from 'lodash';
import TranslateMonthName from '@/mixins/TranslateMonthName';

export default {
    name: 'RecurringTimeSlotsDaySlotPicker',

    mixins: [TranslateMonthName],

    props: {
        selectedDate: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...get('Event/*'),

        submission: sync('Submission/submission'),

        errorBag: get('Submission/errorBag'),

        formTheme: get('Form/form@settings.theme'),

        noAvailableTimeslots () {
            return !this.loading && this.timeslots.length === 0;
        },

        showDuration () {
            return getValue(this.event.settings, 'recurringTimeSlots.displayDuration', false);
        },

        selectedDateFormatted () {
            return `${this.translatedMonthName(this.selectedDate.month)} ${this.selectedDate.toFormat('d, y')}`;
        }

    },

    data () {
        return {
            loading: false,
            selectedTimeSlot: null,
            timeslots: []
        };
    },

    watch: {
        selectedDate () {
            this.retrieveAvailableSlots();
        }
    },

    mounted () {
        this.retrieveAvailableSlots();
    },

    methods: {
        formatTimeRepresentation (time) {
            const timeFormat = getValue(this.event.settings, 'recurringTimeSlots.timeFormat');

            return timeFormat === '24-hours' ? time.toFormat('H:mm') : time.toFormat('h:mm a');
        },

        isTimeslotSelected (timeslot) {
            return this.selectedTimeSlot && timeslot.index === this.selectedTimeSlot.index;
        },

        retrieveAvailableSlots () {
            this.loading = true;

            axios.post(this.route('api.events.timeslots.on-date', this.event), {
                date: this.selectedDate
            }).then(({ data }) => {
                const dateWithoutTime = {
                    year: this.selectedDate.year,
                    month: this.selectedDate.month,
                    day: this.selectedDate.day
                };

                this.timeslots = data.map((day) => {
                    return {
                        ...day,
                        from: DateTime.fromISO(day.from).set(dateWithoutTime),
                        to: DateTime.fromISO(day.to).set(dateWithoutTime)
                    };
                });
            }).catch(() => {
                this.$toasted.global.error('There was an error retrieving available time slots for this date.');
            }).finally(() => {
                this.loading = false;
            });
        },

        toggleTimeslot (timeslot) {
            if (!timeslot.available) {
                return;
            }

            if (this.isTimeslotSelected(timeslot)) {
                this.selectedTimeSlot = null;
                this.$set(this.submission, 'selectedTimeSlot', null);
                return;
            }

            this.selectedTimeSlot = timeslot;
            this.$set(this.submission, 'selectedTimeSlot', timeslot);
            this.errorBag.clear();
        },

        timeslotTheming (timeslot) {
            return this.isTimeslotSelected(timeslot) ? { 'background-color': this.formTheme.colors.button } : {};
        },

        timeslotStyle (timeslot) {
            return {
                'opacity-50 cursor-not-allowed': !timeslot.available,
                'hover:opacity-70': timeslot.available
            };
        }
    }
};
</script>
