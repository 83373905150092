<template>
    <multi-page-item-settings
        items-array-name="stores"
        :settings="form"
        :item-creator-function="createEmptyItem"
        :default-close-handler="defaultCloseHandler"
    >
        <template slot-scope="{ edit, save, create, restore, selectedItem }">
            <wall-gift-registry-block-general-settings
                key="general-settings"
                :form="form"
                @edit-item="edit"
                @create-item="create"
            ></wall-gift-registry-block-general-settings>

            <wall-gift-registry-block-item-settings
                key="item-settings"
                :event="event"
                :block="block"
                :item="selectedItem"
                @save-settings="save"
                @cancel="restore"
            ></wall-gift-registry-block-item-settings>
        </template>
    </multi-page-item-settings>
</template>

<script>
import BlockableSettingsMixin from '@/mixins/BlockableSettingsMixin';
import HasDefaultCloseHandler from '@/mixins/HasDefaultCloseHandler';
import BaseBlockIsSelfEditable from '@/mixins/BaseBlockIsSelfEditable';

export default {
    name: 'WallGiftRegistryBlockSettings',

    mixins: [BaseBlockIsSelfEditable, HasDefaultCloseHandler, BlockableSettingsMixin],

    methods: {
        createEmptyItem () {
            return {
                name: '',
                url: '',
                images: [],
                deletable: true,
                active: true,
                new: true
            };
        }
    }
};
</script>
