<template>
    <div class="flex flex-col grow">
        <div class="flex flex-wrap">
            <form-field-wrapper
                label="Event name"
                :error="form.errors.get('name')"
                :should-show-error="form.errors.has('name')"
            >
                <input
                    v-model="form.name"
                    class="form-field"
                    placeholder="Enter a name for your event"
                >
            </form-field-wrapper>

            <form-field-wrapper label="Icon">
                <icon-picker
                    v-model="form.icon"
                    class="text-gray-800 w-11 h-11"
                ></icon-picker>
            </form-field-wrapper>

            <form-field-wrapper
                label="Time &amp; Date"
                :error="form.errors.get('timeAndDate')"
                :should-show-error="form.errors.has('timeAndDate')"
            >
                <input
                    v-model="form.timeAndDate"
                    class="form-field"
                    placeholder="Date &amp; Time Details"
                >
            </form-field-wrapper>

            <form-field-wrapper
                label="Event details"
                :error="form.errors.get('description')"
                :should-show-error="form.errors.has('description')"
            >
                <textarea
                    v-model="form.description"
                    class="form-field"
                    placeholder="Type anything you'd like here. Include event description and details."
                    rows="5"
                ></textarea>
            </form-field-wrapper>

            <form-panel
                v-model="form.showLink"
                label="Include a link"
                :error="form.errors.get('showLink')"
                :should-show-error="form.errors.has('showLink')"
            >
                <form-field-wrapper
                    label="Button Text"
                    :error="form.errors.get('linkText')"
                    :should-show-error="form.errors.has('linkText')"
                >
                    <input
                        v-model="form.linkText"
                        class="form-field"
                        placeholder="Learn More"
                    >
                </form-field-wrapper>

                <form-field-wrapper
                    label="Link To"
                    :error="form.errors.get('linkUrl')"
                    :should-show-error="form.errors.has('linkUrl')"
                >
                    <input
                        v-model="form.linkUrl"
                        class="form-field"
                        placeholder="https://google.com"
                    >
                </form-field-wrapper>
            </form-panel>

            <portal to="app-drawer-footer">
                <footer-save-cancel
                    :processing="loading"
                    @cancel="$emit('cancel')"
                    @save="save"
                ></footer-save-cancel>
            </portal>
        </div>
    </div>
</template>

<script>
import WallItemSettingsMixin from '@/mixins/WallItemSettingsMixin';

export default {
    name: 'WallScheduleBlockItemSettings',

    mixins: [WallItemSettingsMixin]
};
</script>
