<template>
    <tabbed-modal
        :value="isActive"
        :confirm-close="askForConfirmation"
        header-classes="items-baseline"
        :hide-tab-controls="isEditing"
        @input="$emit('input', $event)"
        @closed="restoreParty"
    >
        <template #header>
            <div class="flex font-normal text-xl items-start">
                <base-dropdown class="inline-block" placement="bottom-start">
                    <template #trigger="{ triggerFunction }">
                        <button
                            class="focus:outline-none"
                            @click="triggerFunction"
                        >
                            <div class="shrink mr-2">
                                <a class="text-gray-400 hover:text-gray-500 transition duration-150 ease-in-out" role="button">
                                    <app-icon
                                        name="navigation-menu-vertical"
                                        class="w-5 h-5"
                                        stroke
                                    ></app-icon>
                                </a>
                            </div>
                        </button>
                    </template>

                    <div class="bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden mt-1">
                        <button
                            class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-red hover:bg-gray-100"
                            @click="deleteSubmission"
                        >
                            <app-icon name="trash" class="h-4 w-4 mr-3"></app-icon>
                            Delete Group
                        </button>

                        <resend-confirmation-email v-slot="{ resend }" :event="event">
                            <button class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-red hover:bg-gray-100" @click="resend(submission.guests)">
                                <app-icon
                                    name="email-sync"
                                    class="h-4 w-4 mr-3"
                                    stroke
                                ></app-icon>
                                Confirmation
                            </button>
                        </resend-confirmation-email>
                    </div>
                </base-dropdown>

                <div class="grow">
                    <div>
                        <span class="font-semibold">{{ submission.creator.formalName }}</span>
                        <span class="mx-2 text-base">Group of {{ guestsCount }}</span>
                        <span v-if="isEditing" class="badge badge-warning">Editing</span>
                    </div>

                    <div class="text-sm text-gray-500 mt-2 flex font-semibold">
                        <div
                            v-tippy
                            class="mr-8 flex items-center"
                            :class="originallySubmittedAtTooltip ? 'tooltip-text' : ''"
                            :content="originallySubmittedAtTooltip"
                        >
                            <app-icon
                                name="calendar"
                                class="h-5 w-5 mr-2"
                                stroke
                            ></app-icon>
                            <p>
                                <span v-if="submission.original_submission_id">Last edited:</span>
                                {{ submission.submittedAt | dateTimeTz(eventTimezone.name) }}
                            </p>
                        </div>

                        <div class="flex items-center uppercase tracking-wide">
                            <app-icon
                                name="user-alt-search"
                                class="h-5 w-5 mr-2"
                                stroke
                            ></app-icon>
                            {{ submission.confirmationCode }}
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #content="{ initialize }">
            <tabbed-modal-item
                id="guest-details"
                v-slot="{ isActive }"
                label="Guest Details"
                @hook:mounted="initialize"
            >
                <keep-alive>
                    <party-details-guests-tab
                        :event="event"
                        :is-active="isActive"
                        :is-editing="isEditing"
                        :initial-submission="initialSubmission"
                        @toggle-edit-mode="toggleEditMode"
                        @fetched-guests="fetchedGuests"
                        v-on="$listeners"
                    ></party-details-guests-tab>
                </keep-alive>
            </tabbed-modal-item>

            <tabbed-modal-item
                id="group-responses"
                v-slot="{ isActive }"
                label="Group Responses"
            >
                <party-details-responses-tab
                    :event="event"
                    :is-active="isActive"
                    :is-editing="isEditing"
                    :initial-submission="initialSubmission"
                    @toggle-edit-mode="toggleEditMode"
                    v-on="$listeners"
                ></party-details-responses-tab>
            </tabbed-modal-item>

            <tabbed-modal-item id="my-notes" label="Notes">
                <comment-list :submission-id="submission.id"></comment-list>
            </tabbed-modal-item>

            <tabbed-modal-item
                v-if="event.hasEverHadPayableItems"
                id="payment"
                v-slot="{ isActive }"
                label="Payment"
            >
                <keep-alive>
                    <party-details-payments-tab
                        :is-active="isActive"
                        :submission="initialSubmission"
                        @toggle-edit-mode="toggleEditMode"
                    ></party-details-payments-tab>
                </keep-alive>
            </tabbed-modal-item>
        </template>

        <template v-if="isEditing" #footer="{ close }">
            <portal-target
                name="party-details-modal-footer"
                :slot-props="{ close }"
                slim
            ></portal-target>
        </template>
    </tabbed-modal>
</template>

<script>
import isEqual from 'lodash/isEqual';
import defaultTo from 'lodash/defaultTo';
import { get } from 'vuex-pathify';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';
import Toggleable from '@/mixins/Toggleable';
import axios from '@/util/axios';
import EventTimezone from '@/mixins/EventTimezone';
import dateTimeTzFilter from '@/filters/DateTimeTzFilter';

export default {
    name: 'PartyDetailsModal',

    mixins: [GeneratesUniqueKey, Toggleable, EventTimezone],

    props: {
        initialSubmission: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            isEditing: false,
            originalSelectedItem: null,
            submission: this.initialSubmission
        };
    },

    computed: {
        ...get('Event/*'),

        guestsCount () {
            if (this.submission.guestsCount) {
                return this.submission.guestsCount;
            }

            return defaultTo(this.submission.guests, []).length;
        },

        askForConfirmation () {
            return !isEqual(this.initialSubmission, this.submission);
        },

        originallySubmittedAtTooltip () {
            if (this.submission.original_submission_id) {
                return `Originally submitted: ${dateTimeTzFilter(this.submission.originallySubmittedAt, this.eventTimezone.name)}`;
            }

            return null;
        }
    },

    watch: {
        initialSubmission (newSubmission) {
            this.submission = newSubmission;
        }
    },

    methods: {
        restoreParty () {
            if (this.isEditing) {
                this.disableEditMode();
            }
        },

        disableEditMode () {
            this.isEditing = false;
        },

        toggleEditMode () {
            this.isEditing = !this.isEditing;
        },

        fetchedGuests (guests) {
            this.$set(this.submission, 'guests', guests);
        },

        deleteSubmission () {
            const attributes = {
                confirmButtonText: 'Delete Group',
                cancelButtonText: 'Cancel',
                html: `<p>You are deleting replies from <span class="font-bold">${this.guestsCount} guest(s)</span>. All associated data will be permanently deleted. This cannot be undone.</p>`
            };

            if (this.submission.invitee_group_id) {
                Object.assign(attributes, {
                    input: 'checkbox',
                    inputPlaceholder: `Also delete this group from my Invite List`
                });
            }

            // `Are you sure you want to delete this entire group of ${this.guestsCount}? This cannot be undone.`,
            App.alert().confirm(
                'Are you sure?',
                '',
                attributes,
                ({ value }) => {
                    const route = this.route('api.events.submissions.destroy', {
                        event: this.event,
                        submission: this.submission,
                        deleteInviteeGroup: this.submission.invitee_group_id ? value : 0
                    });

                    axios.delete(route)
                        .then(({ data }) => {
                            this.isActive = false;
                            this.$emit('delete-submission', data.id);
                        }).catch(() => {
                            this.$toasted.global.error(`There was an error deleting this submission.`);
                        });
                }
            );
        }
    }
};
</script>
