/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings-toggle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>switch option</desc><g _fill="currentColor"><path pid="0" d="M22.044 0H1.956A1.959 1.959 0 000 1.958v20.086A1.959 1.959 0 001.956 24h20.088A1.959 1.959 0 0024 22.044V1.958A1.959 1.959 0 0022.044 0zM21.5 21.959L2.544 22a.5.5 0 01-.5-.5L2 2.5a.5.5 0 01.5-.5l19-.041a.5.5 0 01.5.5v19a.5.5 0 01-.5.5z"/><path pid="1" d="M7.967 10.779h8.066a3.422 3.422 0 100-6.844H7.967a3.422 3.422 0 100 6.844zm0-5.162a1.711 1.711 0 11-1.711 1.711 1.712 1.712 0 011.711-1.711zM7.967 20.067h8.066a3.422 3.422 0 100-6.844H7.967a3.422 3.422 0 100 6.844zm8.066-5.1a1.711 1.711 0 11-1.711 1.711 1.712 1.712 0 011.711-1.715z"/></g>'
  }
})
