/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buildings-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>office business city skyline</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M1.5 23.25v-15h9v15M4.5 23.25v-12M7.5 23.25v-12M1.5 8.25L6 3l4.5 5.25M6 .75V3M22.5 23.25V11.04h0a.777.777 0 00-.52-.72l-7.5-2.5-.01-.01c-.4-.14-.82.07-.95.47-.03.07-.04.15-.04.23v14.7M19.5 21v.75M19.5 17.25V18M19.5 13.5v.75M16.5 21v.75M16.5 17.25V18M16.5 13.5v.75"/></g>'
  }
})
