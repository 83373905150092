export default {
    computed: {
        actionsButton () {
            const canUserTakeActions = this.auth().user().can('update.event');
            return {
                disabled: !canUserTakeActions,
                disabledTooltip: !canUserTakeActions ? 'Please request event administrator privileges from your event\'s creator.' : null
            };
        }
    }
};
