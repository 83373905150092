<template>
    <simple-picker
        item-label="label"
        item-value="value"
        v-bind="$attrs"
        v-on="$listeners"
    ></simple-picker>
</template>

<script>
export default {
};
</script>
