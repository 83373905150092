/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refresh-arrow-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 14.248v4.5H1.5M5.717 18.751A9.753 9.753 0 0116.5 3M5.712 18.746A9.72 9.72 0 119 21"/>'
  }
})
