/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alarm-bell-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>alarm bell notification</desc><path pid="0" d="M10 21.75a2.087 2.087 0 004.005 0M12 3V.75M12 3a7.5 7.5 0 017.5 7.5c0 7.046 1.5 8.25 1.5 8.25H3s1.5-1.916 1.5-8.25A7.5 7.5 0 0112 3z" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>'
  }
})
