/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-forbidden-close-4-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.524 22.009l.22 1.24h3.5l1-5.5h1.75v-3.01c0-1.71-.97-3.27-2.49-4.03M8.5 3.75a2.75 2.75 0 100 5.5 2.75 2.75 0 100-5.5zM18.168 5.832h0a2.742 2.742 0 00-3.31-2.01c-.38.09-.74.26-1.04.5M.75 23.25L23.25.75M12.035 11.96h0a4.503 4.503 0 00-6.32-.76 4.468 4.468 0 00-1.72 3.53v3h2.25M6.47 21.76l.27 1.48h3.5l1-5.5h1.75v-2.51"/></g>'
  }
})
