<template>
    <span v-if="static">{{ formatted }}</span>

    <div v-else class="flex items-end">
        <div
            v-if="currency.prefix"
            v-tippy
            :content="tooltip"
            :class="currencyPrefixClass"
        >
            {{ currency.prefix }}
        </div>

        <input
            v-model.number="adjustedValue"
            type="number"
            :step="step"
            :class="inputClass"
        >

        <div
            v-if="currency.suffix"
            v-tippy
            :content="tooltip"
            :class="currencySuffixClass"
        >
            {{ currency.suffix }}
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import formatNumber from '@/util/format-number';

export default {
    name: 'MoneyField',

    props: {
        currencyPrefixClass: {
            type: String,
            default: 'mr-2 py-2 bold uppercase tracking-wide border-b border-dashed border-gray-500'
        },
        currencySuffixClass: {
            type: String,
            default: 'ml-2 py-2 bold uppercase tracking-wide border-b border-dashed border-gray-500'
        },
        inputClass: {
            type: String,
            default: 'form-field w-32'
        },
        noTooltip: {
            type: Boolean,
            default: false
        },
        static: {
            type: Boolean,
            default: false
        },
        value: {
            type: Number,
            required: true
        }
    },

    computed: {
        adjustedValue: {
            get () {
                const { subunit, precision } = this.currency;

                return (this.value / subunit).toFixed(precision);
            },
            set (newAdjustedValue) {
                if (newAdjustedValue === '') {
                    this.$emit('input', 0);
                    return;
                }

                const { subunit, precision } = this.currency;

                this.$emit('input', Math.round(newAdjustedValue.toFixed(precision) * subunit));
            }
        },

        currency: get('Event/event@currencyDescriptor'),

        formatted () {
            const {
                precision,
                decimal_mark,
                thousands_separator,
                prefix,
                suffix
            } = this.currency;

            const formattedValue = formatNumber(
                Math.abs(this.adjustedValue),
                precision,
                decimal_mark,
                thousands_separator
            );

            return `${this.value < 0 ? '-' : ''}${prefix}${formattedValue}${suffix}`;
        },

        step () {
            return 1 / this.currency.subunit;
        },

        tooltip () {
            if (this.noTooltip) {
                return null;
            }

            return 'Change currencies and configure payment and bank options in Setup > Event Settings > Payments & Coupons.';
        }
    }
};
</script>
