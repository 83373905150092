/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'color-brush-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>painting art theme</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M1.38 22.522l-.01-.01a.562.562 0 01-.27-.74.5.5 0 01.29-.28c2.62-1.07 1.78-4.07 2.56-5.67h-.01a3.938 3.938 0 015.22-1.81c5.86 2.86-1.12 11.66-7.82 8.47zM13.88 14.1l8.778-11.116v-.01c.47-.58.39-1.43-.18-1.91-.55-.46-1.35-.41-1.84.1l-9.9 9.86"/></g>'
  }
})
