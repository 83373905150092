<template>
    <base-dropdown :initial-state="initialVisibility ? 'open' : 'closed'">
        <template #trigger="{ triggerFunction }">
            <div
                class="flex items-center px-2 py-1 hover:bg-gray-100 border rounded-md cursor-pointer"
                :class="pillStyles"
                @click="triggerFunction"
                @mouseenter="isRemoveButtonVisible = true"
                @mouseleave="isRemoveButtonVisible = false"
            >
                <app-icon :name="field.icon" class="w-4 h-4 mr-2"></app-icon>

                <strong class="mr-1">{{ field.name }}</strong>

                <template v-if="isMissingValue">
                    is missing value
                </template>

                <template v-else>
                    {{ summarizedPredicate }}
                </template>

                <button
                    v-if="isRemoveButtonVisible"
                    class="ml-2 w-6 h-6 rounded-full"
                    :class="removeButtonStyles"
                    @click.stop="$emit('remove')"
                >
                    &times;
                </button>
            </div>
        </template>

        <div class="absolute pt-2">
            <div class="w-72 p-2 bg-white rounded-md shadow">
                <select-list
                    :value="value.comparison"
                    @input="selectComparison"
                >
                    <div
                        v-for="{ label, slug, ...rest } in field.comparisons"
                        :key="slug"
                        class="mb-2"
                    >
                        <select-option :id="slug" class="mb-2">{{ label }}</select-option>

                        <div v-if="value.comparison === slug" class="pl-9">
                            <component
                                :is="inputComponent"
                                :value="value.value"
                                v-bind="rest"
                                @input="newValue => debouncedUpdate({ value: newValue })"
                                @selected-label-change="lastSelectedItemLabel = $event"
                            ></component>
                        </div>
                    </div>
                </select-list>
            </div>
        </div>
    </base-dropdown>
</template>

<script>
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import PickerValueInput from './PickerValueInput.vue';
import TextValueInput from './TextValueInput.vue';

export default {
    props: {
        choosableFields: {
            type: Array,
            required: true
        },

        initialVisibility: {
            type: Boolean,
            default: false
        },

        value: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            isRemoveButtonVisible: false,
            lastSelectedItemLabel: null
        };
    },

    computed: {
        field () {
            return this.findBySlug(this.choosableFields, this.value.field);
        },

        inputComponent () {
            switch (this.selectedComparison.input) {
                case 'text': return TextValueInput;
                case 'picker': return PickerValueInput;
                default: return null;
            }
        },

        isMissingValue () {
            return this.selectedComparison.input != null && this.value.value == null;
        },

        pillStyles () {
            return {
                'bg-white text-gray-600': !this.isMissingValue,
                'bg-red-light hover:bg-red-light hover:bg-opacity-75 border-red text-red': this.isMissingValue
            };
        },

        removeButtonStyles () {
            return {
                'bg-gray-300 hover:bg-gray-200': !this.isMissingValue,
                'bg-red hover:bg-red hover:bg-opacity-75 text-white': this.isMissingValue
            };
        },

        selectedComparison () {
            return this.findBySlug(this.field.comparisons, this.value.comparison);
        },

        summarizedPredicate () {
            return this.selectedComparison.summaryGenerator(this.value.value, this.lastSelectedItemLabel);
        }
    },

    methods: {
        debouncedUpdate: debounce(function (changes) {
            this.update(changes);
        }, 250),

        findBySlug (items, slug) {
            return items.find((item) => { return item.slug === slug; });
        },

        selectComparison (newComparison) {
            const newComparisonObject = this.findBySlug(this.field.comparisons, newComparison);

            this.debouncedUpdate({
                comparison: newComparison,
                value: get(newComparisonObject, 'defaultValue', this.value.value)
            });
        },

        update (changes) {
            const newValue = { ...this.value, ...changes };

            if (!isEqual(this.value, newValue)) {
                this.$emit('input', newValue);
            }
        }
    }
};
</script>
