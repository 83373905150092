import { map } from 'lodash';
import { Mark } from 'tiptap';
import { updateMark, toggleMark } from 'tiptap-commands';

const quillFonts = map(window.fonts, (font) => {
    return `span.ql-font-${font.label.replace(/ /g, '-')}`;
}).join(',');

export default class FontFamily extends Mark {
    get name () {
        return 'fontFamily';
    }

    get schema () {
        return {
            attrs: {
                fontFamily: {
                    default: `Arial`
                }
            },
            content: 'inline*',
            group: 'block',
            draggable: false,
            parseDOM: [
                {
                    tag: quillFonts,
                    getAttrs: (mark) => {
                        let fontFamily = 'Arial';

                        mark.classList.forEach((className) => {
                            if (this.isQuillFontClass(className)) {
                                fontFamily = this.parseQuillClassName(className);
                            }
                        });

                        return {
                            fontFamily
                        };
                    }
                },
                {
                    style: 'font-family',
                    getAttrs: (mark) => {
                        return {
                            fontFamily: mark
                        };
                    }
                }
            ],
            toDOM: (mark) => {
                return [
                    'span',
                    { style: `font-family: ${mark.attrs.fontFamily}` },
                    0
                ];
            }
        };
    }

    commands ({ type }) {
        return (attrs) => {
            if (attrs.fontFamily) {
                return updateMark(type, attrs);
            }

            return toggleMark(type, attrs);
        };
    }

    isQuillFontClass (className) {
        return className.includes('ql-font');
    }

    parseQuillClassName (className) {
        return className.replace('ql-font-', '').replace(/-/g, ' ');
    }
}
