/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'badge-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 23.25h-.01c.36-.01.73-.07 1.07-.2l1.04-.4h-.01c2.24-.78 4.34-1.91 6.22-3.34v-.01a5.981 5.981 0 00.75-8.4l-.01-.01a3.005 3.005 0 010-3.85l2.15-2.58L20.83.71l-3.92 3.61h-.01c-.28.18-.65.16-.9-.05L12.41.86 12.4.85a.736.736 0 00-.48-.18v0l-.01-.01a.69.69 0 00-.48.17l-3.59 3.4h-.01c-.26.21-.63.23-.9.04L3.01.65.64 4.4l2.15 2.57h0c.92 1.11.92 2.72-.01 3.84v-.01c-2.11 2.52-1.77 6.28.75 8.39l-.01-.01c1.88 1.43 3.98 2.56 6.22 3.33l1.04.4h0c.34.13.7.19 1.07.19z"/>'
  }
})
