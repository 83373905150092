/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin-airport-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>travel airplane</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.61 12.43l1.38.95M15.524 8.66l-1.97 1.08L9.4 6.89 7.43 7.97l3.49 3.21-3.94 2.17 2 1.375h0c.46.32 1.07.35 1.57.07l6.4-3.55v-.01c.72-.41.98-1.32.58-2.04-.41-.73-1.32-.99-2.04-.59z"/><path pid="1" d="M20.75 9.754c0 7.9-6.932 12.33-8.629 13.3v-.01a.77.77 0 01-.75 0c-1.7-.97-8.63-5.4-8.63-13.3h0a8.988 8.988 0 018.99-9.01c4.97-.01 9 4.02 9 8.99 0 0 0 0 0 0z"/></g>'
  }
})
