/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'religion-peace-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5zM12 .75v22.5"/><path pid="1" d="M3.804 19.707L12 11.5l8.36 8.012"/></g>'
  }
})
