/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-accessory-beanie-winter-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>hat cap</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25 19.125v0c0 1.65-1.35 3-3 3H3.75l-.01-.001c-1.66-.01-3-1.35-3-3 0-1.66 1.34-3 3-3h16.5c1.65 0 2.99 1.34 2.99 3z"/><path pid="1" d="M20.25 16.125H3.75v-6h0c-.01-4.56 3.69-8.26 8.24-8.26 4.55-.01 8.25 3.69 8.25 8.24 0 0 0 0 0 0zM6.75 16.12v6M17.25 16.12v6M12 16.12v6"/><path pid="2" d="M8.25 16.125v-4.808l-.001-.01c-.01-2.25.63-4.46 1.82-6.36l1.92-3.09M15.75 16.125v-4.818c0-2.25-.64-4.46-1.83-6.36l-1.93-3.09"/></g>'
  }
})
