/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'animal-dog-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>puppy</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/><path pid="1" d="M4.87 20.706C9.74 6 10.31 5.25 12.74 5.25v3l5.17 2.58-.01-.01c.5.25.82.77.82 1.34v.57c0 1.65-1.35 3-3 3h-1.5V23M14.25 19.5l-7.5-4.37"/></g>'
  }
})
