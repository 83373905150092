/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-rice-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>asian chinese japanese thai</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25 10.5c0 6.21-5.04 9.75-11.25 9.75C5.78 20.25.75 16.71.75 10.5z"/><path pid="1" d="M7.8 19.655l-1.26 2.51h-.01c-.19.37-.04.82.33 1 .1.05.21.07.33.07h9.573v-.001c.41-.01.74-.34.74-.76a.942.942 0 00-.08-.34l-1.26-2.51M21.75 10.5v0c0-1.66-1.35-3-3-3h-2.5 0a5.233 5.233 0 00-9.77-.71h0c-.17-.03-.34-.05-.51-.05h0a3.745 3.745 0 00-3.76 3.74h-.001M18 7.5l5.25-3M15.63 6.14L20.25.75"/></g>'
  }
})
