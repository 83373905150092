/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transportation-airplane-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.424 20.571l.68-.69v-.01c.37-.38.52-.93.38-1.45l-2.44-9.25 3.21-3.22h-.01a3.117 3.117 0 00.44-3.97l-.01-.01a3.002 3.002 0 00-4.19-.72c-.14.09-.27.2-.39.32L14.76 4.9 5.51 2.46h0c-.52-.14-1.07.01-1.45.39l-.69.68v-.01a1.5 1.5 0 00.27 2.34l6.3 3.78-4.8 5.44-1.5.02h0c-.47-.08-.94.06-1.29.38l-1.5 1.37v-.01c-.3.29-.3.76-.01 1.05.06.06.14.12.23.15l2.89 1.63 1.9 2.99-.01-.01c.16.38.6.55.98.39.08-.04.16-.1.23-.16l1.3-1.25v-.01c.36-.35.53-.87.44-1.36l.02-1.51 5.29-4.95 3.78 6.308-.01-.01c.42.71 1.34.94 2.05.51.1-.07.2-.14.28-.23z"/>'
  }
})
