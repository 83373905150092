/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-transportation-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 3.65a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM16.68 1.09h1.14M17.25 1.09v2.57M21.09 2.44l.81.81M21.49 2.84l-1.82 1.82M23.25 6.51v1.15M23.25 7.09h-2.57M21.9 10.92l-.81.81M21.49 11.33l-1.82-1.82M17.82 13.09h-1.14M17.25 13.09v-2.58M13.41 11.73l-.81-.81M13.01 11.33l1.82-1.82M11.25 7.66V6.51M11.25 7.09h2.57M12.6 3.25l.81-.81M13.01 2.84l1.82 1.82M9.75 10.91H8.24c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v6.78M17.96 21.415h.78-.01c.82 0 1.5-.68 1.5-1.5v-4.83"/><path pid="1" d="M6.75 12.41H3.74c-1.66 0-3 1.34-3 3 0 0 0 0 0 0v4.5h0c0 .82.67 1.49 1.5 1.49h2.28"/><path pid="2" d="M6.375 19.16a1.875 1.875 0 100 3.75 1.875 1.875 0 100-3.75zM16.125 19.16a1.875 1.875 0 100 3.75 1.875 1.875 0 100-3.75zM8.21 21.42h6.08M.75 17.02h6"/></g>'
  }
})
