/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'day-sunrise-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 20.21h22.5M12 5.21v-1.5M18.89 8.06L19.95 7M21.75 14.96h1.5M2.25 14.96H.75M5.11 8.06L4.05 7M18.33 17.207v-.01c1.28-3.5-.52-7.38-4.02-8.67-3.5-1.29-7.38.51-8.67 4.01-.56 1.5-.56 3.14 0 4.64"/></g>'
  }
})
