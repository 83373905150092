import tinycolor from 'tinycolor2';
import hexToRGBA from './hexToRGBA';

export default {
    'form.title-font': (theme) => {
        return `
            themedElementSelector {
                font-family: ${theme.form.fonts.title};
            }
        `;
    },

    'form.title-text': (theme) => {
        return `
            themedElementSelector {
               color: ${hexToRGBA(theme.form.colors.text)} !important;
               font-family: ${theme.form.fonts.title};
            }
        `;
    },

    'form.text': (theme) => {
        return `
            themedElementSelector {
                font-family: ${theme.form.fonts.body} !important;
                color: ${hexToRGBA(theme.form.colors.text)} !important;
            }
            themedElementSelector::placeholder {
                color: ${hexToRGBA(theme.form.colors.text)} !important;
                opacity: 0.5 !important;
            }
        `;
    },

    'form.text-color': (theme) => {
        return `
            themedElementSelector {
                color: ${hexToRGBA(theme.form.colors.text)} !important;
            }
        `;
    },

    'form.input-border-accent': (theme) => {
        return `
            themedElementSelector input[type="number"]:focus {
               border-color: ${hexToRGBA(theme.form.colors.accent)} !important;
            }
        `;
    },

    'form.border-accent': (theme) => {
        return `
            themedElementSelector {
               border-color: ${hexToRGBA(theme.form.colors.accent)} !important;
            }
        `;
    },

    'form.dropdown': (theme) => {
        return `
            themedElementSelector .form-field {
                background-color: transparent !important;
            }
            themedElementSelector .dropdown-input,
            themedElementSelector .dropdown-placeholder {
                color: ${hexToRGBA(theme.form.colors.text)} !important;
                font-family: ${theme.form.fonts.body} !important;
                padding-left: 0.5rem;
                opacity: 0.5;
            }
            themedElementSelector input {
                color: ${hexToRGBA(theme.form.colors.text)} !important;
                font-family: ${theme.form.fonts.body} !important;
                border-color: ${hexToRGBA(theme.form.colors.accent)} !important;
            }
            themedElementSelector input::placeholder {
                color: ${hexToRGBA(theme.form.colors.text)} !important;
                opacity: 0.5 !important;
            }
            themedElementSelector .dropdown-background {
                background-color: ${hexToRGBA(theme.form.colors.dropdownBackground)};
                border-color: ${hexToRGBA(theme.form.colors.dropdownBackground)};
            }
            themedElementSelector li:hover, themedElementSelector .dropdown-selected {
                background-color: ${hexToRGBA(theme.form.colors.button)};
                color: ${hexToRGBA(theme.form.colors.text)} !important;
            }
            themedElementSelector li:hover svg,
            themedElementSelector li.dropdown-selected svg {
                color: ${hexToRGBA(theme.form.colors.text)} !important;
            }
            themedElementSelector .dropdown-placeholder {
                opacity: 0.5;
            }
            themedElementSelector button.form-field {
                border-color: ${hexToRGBA(theme.form.colors.accent)};
                padding: 0.5rem 0;
            }
            themedElementSelector li svg,
            themedElementSelector button.form-field svg {
                color: ${hexToRGBA(theme.form.colors.accent)} !important;
            }
        `;
    },

    'form.datetime-picker': (theme) => {
        const backgroundColor = tinycolor(theme.form.colors.background).setAlpha(1);

        return `
            themedElementSelector .form-field {
                border-color: ${hexToRGBA(theme.form.colors.accent)} !important;
                background-color: transparent !important;
                color: ${hexToRGBA(theme.form.colors.text)} !important;
            }
            themedElementSelector .vdp-datepicker__calendar {
                background-color: ${backgroundColor.toRgbString()};
                color: ${hexToRGBA(theme.form.colors.text)};
                border: 2px solid ${hexToRGBA(theme.form.colors.accent)};
            }
            themedElementSelector .vdp-datepicker__calendar .today {
                background-color: ${backgroundColor.toRgbString()};
                color: ${hexToRGBA(theme.form.colors.text)};
            }
            themedElementSelector .vdp-datepicker__calendar .disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
            themedElementSelector .vdp-datepicker__calendar .cell.muted{
                color: ${hexToRGBA(theme.form.colors.accent)};
            }
            themedElementSelector .vdp-datepicker__calendar .cell.selected{
                background: ${hexToRGBA(theme.form.colors.accent)};
                color: ${hexToRGBA(theme.form.colors.text)};
            }
            themedElementSelector .vdp-datepicker__calendar .cell:hover{
                background: ${hexToRGBA(theme.form.colors.accent)} !important;
                color: ${hexToRGBA(theme.form.colors.text)} !important;
            }
            themedElementSelector .vdp-datepicker__calendar .day__month_btn:hover, themedElementSelector .vdp-datepicker__calendar .month__year_btn:hover{
                background: ${hexToRGBA(theme.form.colors.accent)} !important;
                color: ${hexToRGBA(theme.form.colors.text)} !important;
            }
        `;
    },

    'form.time-picker': (theme) => {
        const backgroundColor = tinycolor(theme.form.colors.background).setAlpha(1);

        return `
            themedElementSelector .time-picker {
                border-color: ${hexToRGBA(theme.form.colors.accent)} !important;
                background-color: ${backgroundColor.toRgbString()} !important;
                color: ${hexToRGBA(theme.form.colors.text)} !important;
            }

            themedElementSelector .time-picker li:hover {
                background-color: ${hexToRGBA(theme.form.colors.accent)} !important;
                color: ${hexToRGBA(theme.form.colors.text)} !important;
            }
        `;
    },

    'form.button': (theme) => {
        return `
            themedElementSelector {
                color: ${theme.form.colors.buttonText ? hexToRGBA(theme.form.colors.buttonText) : hexToRGBA(theme.form.colors.text)};
                background-color: ${hexToRGBA(theme.form.colors.button)};
            }
            themedElementSelector svg {
                color: ${theme.form.colors.buttonText ? hexToRGBA(theme.form.colors.buttonText) : hexToRGBA(theme.form.colors.text)};
            }
        `;
    },

    'form.accent': (theme) => {
        return `
            themedElementSelector {
                border-color: ${hexToRGBA(theme.form.colors.accent)} !important;
            }
            themedElementSelector .option-selected {
                background-color: ${hexToRGBA(theme.form.colors.accent)};
            }
            themedElementSelector input, themedElementSelector button {
                border-color: ${hexToRGBA(theme.form.colors.accent)};
            }
            themedElementSelector svg,
            themedElementSelector .accent-text {
                color: ${hexToRGBA(theme.form.colors.accent)};
            }
            themedElementSelector .number-input {
                color: ${hexToRGBA(theme.form.colors.text)};
                background-color: transparent;
            }
        `;
    },

    'form.accented-text': (theme) => {
        return `
            themedElementSelector {
                color: ${hexToRGBA(theme.form.colors.accent)} !important;
            }
        `;
    },

    'form.secondary-button': (theme) => {
        return `
            themedElementSelector {
                color: ${hexToRGBA(theme.form.colors.text)} !important;
                border-color: ${hexToRGBA(theme.form.colors.button)};
            }
        `;
    },

    'form.background': (theme) => {
        const color = tinycolor(theme.form.colors.background);

        return `
            themedElementSelector {
                background-color: ${color.toRgbString()} !important;
            }
        `;
    },

    'form.background-no-opacity': (theme) => {
        const color = tinycolor(theme.form.colors.background).setAlpha(1);

        return `
            themedElementSelector {
                background-color: ${color.toRgbString()} !important;
            }
        `;
    },

    'form.logo': (theme) => {
        if (!theme.form.logo) {
            return '';
        }

        return `
            themedElementSelector {
                width: ${theme.form.logo.size};
            }
        `;
    },

    'form.file-upload': (theme) => {
        return `
            themedElementSelector {
                border: 4px dashed ${hexToRGBA(theme.form.colors.accent)};
            }
        `;
    }
};
