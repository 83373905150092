/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'record': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.992 5.376 18.624.008 12 0zm0 21.421A9.421 9.421 0 1121.421 12a9.432 9.432 0 01-9.42 9.417z"/><path pid="1" d="M15.098 8.898a4.381 4.381 0 11-6.196 6.196 4.381 4.381 0 016.196-6.196"/></g>'
  }
})
