<template>
    <div
        v-show="isScreenVisible"
        v-theme="['form.background-no-opacity', 'form.text']"
        class="absolute inset-0 z-50 flex flex-col"
        :class="styles"
    >
        <div v-show="isCloseButtonVisible" class="mr-4 mb-4 ml-auto">
            <button
                v-theme="'form.text-color'"
                class="button-icon button-sm hover:opacity-75"
                @click="$emit('close')"
            >
                <app-icon class="fill-current" name="close"></app-icon>
            </button>
        </div>

        <div v-if="existingSubmission" class="max-w-xl mx-auto space-y-8 text-center">
            <template v-if="existingSubmission.editable">
                <template v-if="state === STATE_INITIAL">
                    <template v-if="showAlreadyRegisteredText">
                        <p class="my-4"> {{ $t('text-already-registered') }}</p>

                        <div class="flex flex-col justify-center max-w-sm mx-auto space-y-2 px-2">
                            <button
                                v-theme="['form.accent']"
                                class="w-full py-2 px-4 border-2 rounded-md hover:opacity-75 disabled:opacity-50 disabled:cursor-not-allowed"
                                :disabled="processing"
                                @click="$emit('close')"
                            >
                                <span class="text-lg">{{ $t('button-back') }}</span><br>
                            </button>
                        </div>
                    </template>
                    <template v-else>
                        <p class="my-4">{{ submissionExistsLabel }}</p>

                        <div class="flex flex-col justify-center max-w-sm mx-auto space-y-2 px-2">
                            <button
                                v-if="allowGuestSubmissionEditing"
                                v-theme="['form.accent']"
                                class="w-full py-2 px-4 border-2 rounded-md hover:opacity-75 disabled:opacity-50 disabled:cursor-not-allowed"
                                :disabled="processing"
                                @click="editExistingSubmission"
                            >
                                <span class="text-lg">{{ $t('button-edit-existing') }}</span><br>
                                <span class="text-xs opacity-50">{{ $t('button-submitted-at').replace('#DATE#', $options.filters.dateTimeTz(existingSubmission.submittedAt, eventTimezone.name)) }}</span>
                            </button>

                            <button
                                v-if="closable"
                                v-theme="['form.accent']"
                                class="w-full py-2 px-4 border-2 rounded-md hover:opacity-75 disabled:opacity-50 disabled:cursor-not-allowed"
                                :disabled="processing"
                                @click="$emit('submit-block')"
                            >
                                {{ $t('button-create-new-submission') }}
                            </button>
                        </div>
                    </template>
                </template>

                <template v-else-if="state === STATE_SENT">
                    <p class="font-semibold">{{ $t('title-check-your-inbox') }}</p>
                    <p>{{ $t('text-edit-link-sent').replace('#EMAIL#', existingSubmission.creator.email) }}</p>

                    <div class="max-w-sm mx-auto px-2">
                        <button
                            v-theme="['form.accent']"
                            class="w-full py-2 px-4 border-2 rounded-md hover:opacity-75 disabled:opacity-50 disabled:cursor-not-allowed"
                            :disabled="processing"
                            @click="() => sendEditLink()"
                        >
                            {{ $t('button-resend-link') }}
                        </button>
                    </div>
                </template>
            </template>

            <template v-else>
                <p class="mb-4">{{ $t('text-non-editable-submission-exists') }}</p>

                <div class="max-w-sm mx-auto space-y-2 px-2">
                    <button
                        v-theme="['form.accent']"
                        class="w-full py-2 px-4 border-2 rounded-md hover:opacity-75 disabled:opacity-50 disabled:cursor-not-allowed"
                        :disabled="processing"
                        @click="sendConfirmationEmail"
                    >
                        {{ $t('button-resend-confirmation') }}
                    </button>

                    <button
                        v-if="closable"
                        v-theme="['form.accent']"
                        class="w-full py-2 px-4 border-2 rounded-md hover:opacity-75 disabled:opacity-50 disabled:cursor-not-allowed"
                        :disabled="processing"
                        @click="$emit('submit-block')"
                    >
                        {{ $t('button-create-new-submission') }}
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import getValue from 'lodash/get';
import { get } from 'vuex-pathify';
import axios from '@/util/axios';
import EventTimezone from '@/mixins/EventTimezone';

const STATE_INITIAL = 'initial';
const STATE_SENT = 'sent';

export default {
    mixins: [EventTimezone],

    props: {
        closable: {
            type: Boolean,
            default: true
        },
        existingSubmission: {
            type: Object,
            default: null
        }
    },

    data () {
        return {
            STATE_INITIAL,
            STATE_SENT,

            processing: false,
            state: STATE_INITIAL
        };
    },

    computed: {
        allowGuestSubmissionEditing () {
            return getValue(this.event, 'settings.security.allowGuestSubmissionEditing');
        },

        event: get('Event/event'),

        submissionExistsLabel () {
            const replacementText = this.existingSubmission.creator.email
                ? this.existingSubmission.creator.email
                : this.existingSubmission.creator.formalName;

            return this.$t('text-submission-exists').replace('#EMAIL#', replacementText);
        },

        isCloseButtonVisible () {
            return this.closable && this.state === STATE_INITIAL;
        },

        isPreview: get('Form/isPreview'),

        isScreenVisible () {
            return this.existingSubmission != null;
        },

        securityToken: get('Submission/securityToken'),

        showAlreadyRegisteredText () {
            return this.event.invite_list.is_enabled && !this.allowGuestSubmissionEditing;
        },

        styles () {
            return {
                'pt-16': this.isPreview,
                'pt-2': !this.isPreview
            };
        }
    },

    methods: {
        editExistingSubmission () {
            if (getValue(this.event.settings, 'security.submissionEditingMfaEnabled', false)) {
                this.sendEditLink(STATE_SENT);
                return;
            }

            window.location = this.route('submissions.edit', [this.existingSubmission.uuid], false);
        },

        async sendEditLink (newState = null) {
            this.processing = true;

            const route = this.route('api.events.submissions.send-edit-link', {
                event: this.event,
                submission: this.existingSubmission.uuid,
                securityToken: this.securityToken
            });

            try {
                await axios.post(route);

                if (newState != null) {
                    this.state = newState;
                }
            } catch (error) {
                this.$toasted.global.error(`There was an error sending the edit link to ${this.existingSubmission.creator.email}.`);
            } finally {
                this.processing = false;
            }
        },

        async sendConfirmationEmail () {
            this.processing = true;

            const route = this.route('api.events.submissions.send-confirmation', {
                event: this.event,
                submission: this.existingSubmission.uuid
            });

            try {
                await axios.post(route);

                this.$toasted.global.success(`The confirmation email has been re-sent to ${this.existingSubmission.creator.email}. Please note it may take a few minutes to arrive.`);
            } catch (error) {
                this.$toasted.global.error(`There was an error re-sending the confirmation email to ${this.existingSubmission.creator.email}.`);
            } finally {
                this.processing = false;
            }
        }
    }
};
</script>
