<template>
    <div>
        <bounced-email-alert
            v-for="notification in bounceNotifications"
            :key="notification.id"
            :campaign="findBouncedCampaign(notification.data.campaign)"
            dismissable
            :notification="notification"
            @fix-bounced="handleFixBounced"
        ></bounced-email-alert>

        <div class="mt-8 -mx-8 md:mx-0">
            <infinite-data-table
                class="text-gray-600"
                clickable-rows
                :columns="chosenColumns"
                :filter="filter"
                no-results-placeholder="Sorry, we couldn't find any campaigns."
                remote-sort
                :sort="sort"
                :source="dataSource"
                @change-sort="handleChangedSort"
                @click-row="launchCampaignReport"
            >
                <template #header>
                    <div class="flex-auto">
                        <search-field
                            v-model="filter.term"
                            class="w-full md:w-72"
                            placeholder="Search by title or subject..."
                        ></search-field>
                    </div>

                    <choosable-columns
                        v-model="chosenColumns"
                        class="mr-2 hidden md:block"
                        :columns="columns"
                    ></choosable-columns>

                    <a :href="route('events.campaigns.drafts.create', event)" class="button button-primary p-2">
                        <app-icon
                            class="w-5 h-5 mr-2"
                            name="add-circle"
                            stroke
                        ></app-icon>
                        New Email
                    </a>
                </template>

                <template slot="row" slot-scope="{ row, property, value, reload }">
                    <!-- Summary column represents mobile representation of table data. -->
                    <div v-if="property === 'summary'" class="flex">
                        <div class="flex flex-col flex-auto text-gray-600">
                            <p>{{ row.title }}</p>
                            <p class="text-sm text-purple">{{ row.subject }}</p>
                            <p class="text-sm">Sent by {{ row.updated_by }} on {{ row.sent_at | dateTimeTz(eventTimezone.name) }}</p>

                            <p v-if="row.shortState === 'Aborted'" class="text-sm">Incomplete Delivery | Campaign Aborted</p>

                            <p v-else-if="finishedSending(row.recipients_count)" class="text-sm">
                                <span class="font-semibold text-green ">{{ row.recipients_count.sent }}</span> sent |
                                <span class="font-semibold text-green">{{ row.recipients_count.opened }}</span> opens |
                                <span class="font-semibold text-yellow">{{ row.recipients_count.bounced }}</span> bounced
                            </p>

                            <div v-else class="badge badge-warning items-center">
                                <app-icon
                                    class="mr-2 w-4 h-4"
                                    name="time-clock"
                                    stroke
                                ></app-icon>
                                Now Sending to {{ row.recipients_count.total }}
                            </div>
                        </div>

                        <div class="flex items-center ml-4" @click.stop>
                            <base-dropdown v-cloak placement="bottom-end">
                                <template slot="trigger" slot-scope="{ triggerFunction }">
                                    <button class="flex button-icon" @click="triggerFunction">
                                        <app-icon name="navigation-menu-horizontal" stroke></app-icon>
                                    </button>
                                </template>

                                <div class="w-64 bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden">
                                    <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="launchCampaignReport(row)">View Report</button>
                                    <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="duplicateCampaign(event, row.id)">Duplicate to draft</button>
                                    <delete-item
                                        class="flex items-center px-4 py-2 cursor-pointer font-medium hover:text-red hover:bg-gray-100 transition duration-150 ease-in-out"
                                        :confirmation-message="{ title: 'Are you sure?', text: 'Do you really want to delete this campaign?' }"
                                        :endpoint="route('api.events.campaigns.destroy', { event, campaign: row.id })"
                                        @delete-item="reload"
                                    >
                                        Delete sent email and reports
                                    </delete-item>
                                </div>
                            </base-dropdown>
                        </div>
                    </div>

                    <div v-else-if="property === 'recipients_count'">
                        <template v-if="row.shortState === 'Aborted'">Incomplete Delivery | Campaign Aborted</template>

                        <template v-else-if="awaitingVerificationInQueue(row)">
                            <span class="font-semibold text-yellow">Pending send</span>
                        </template>

                        <template v-else-if="finishedSending(value)">
                            <span class="font-semibold text-green">{{ value.sent }}</span> sent |
                            <span class="font-semibold text-green">{{ value.opened }}</span> opens |
                            <span class="font-semibold text-yellow">{{ value.bounced }}</span> bounced
                        </template>

                        <div v-else class="badge badge-warning items-center">
                            <app-icon
                                class="mr-2 w-4 h-4"
                                name="time-clock"
                                stroke
                            ></app-icon>
                            Now Sending to {{ value.estimated_total }}
                        </div>
                    </div>

                    <div v-else-if="property === 'sent_at'">
                        {{ row.sent_at | dateTimeTz(eventTimezone.name) }}
                    </div>

                    <!-- Note that the Options column is a "fake" column/property name and it does not actually have a label. -->
                    <div
                        v-else-if="property === 'options'"
                        class="flex items-center mx-4"
                        @click.stop
                    >
                        <base-dropdown v-cloak placement="bottom-end">
                            <template slot="trigger" slot-scope="{ triggerFunction }">
                                <button class="flex button-icon" @click="triggerFunction">
                                    <app-icon name="navigation-menu-horizontal"></app-icon>
                                </button>
                            </template>

                            <div class="w-64 bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden">
                                <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="launchCampaignReport(row)">View Report</button>
                                <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="launchCampaignReport(row, 'add-recipients')">Add Recipients</button>
                                <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="duplicateCampaign(event, row.id)">Duplicate to draft</button>
                                <delete-item
                                    class="flex items-center px-4 py-2 cursor-pointer font-medium hover:text-red hover:bg-gray-100 transition duration-150 ease-in-out"
                                    :confirmation-message="{ title: 'Are you sure?', text: 'Do you really want to delete this campaign?' }"
                                    :endpoint="route('api.events.campaigns.destroy', { event, campaign: row.id })"
                                    @delete-item="reload"
                                >
                                    Delete sent email and reports
                                </delete-item>
                            </div>
                        </base-dropdown>
                    </div>

                    <div v-else>
                        {{ value }}
                    </div>
                </template>
            </infinite-data-table>
        </div>

        <campaign-report-modal
            ref="campaignReportModal"
            v-model="showCampaignReport"
            :event="event"
            :campaign="selectedCampaign"
            :bounce-notification="findBounceNotification(selectedCampaign)"
            @fix-bounced="handleFixBounced"
        ></campaign-report-modal>

        <bounced-report-modal
            v-if="showBouncedReport"
            v-model="showBouncedReport"
            :event="event"
            :campaign="selectedCampaign"
            @resolved-bounces="shouldRefreshOnBounceReportClose = true"
        ></bounced-report-modal>
    </div>
</template>

<script>
import EventTimezone from '@/mixins/EventTimezone';
import DuplicateCampaign from '@/mixins/DuplicateCampaign';

export default {
    name: 'SentCampaigns',

    mixins: [DuplicateCampaign, EventTimezone],

    props: {
        bouncedCampaigns: {
            type: Array,
            required: true
        },
        bounceNotifications: {
            type: Array,
            required: true
        },
        event: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            chosenColumns: [],
            dataSource: this.route('api.events.campaigns.sent.index', this.event),
            filter: {
                term: ''
            },
            selectedCampaign: {},
            shouldRefreshOnBounceReportClose: false,
            showCampaignReport: false,
            showBouncedReport: false,
            sort: {}
        };
    },

    computed: {
        columns () {
            return [
                {
                    label: 'Email',
                    property: 'summary',
                    desktop: false,
                    mobile: true,
                    alwaysActive: true
                },
                {
                    label: 'Title',
                    property: 'title',
                    desktop: true,
                    sortable: true
                },
                {
                    label: 'Subject Line',
                    property: 'subject',
                    desktop: true,
                    sortable: true
                },
                {
                    label: 'Sent',
                    property: 'sent_at',
                    desktop: true,
                    sortable: true
                },
                {
                    label: 'Sent By',
                    property: 'updated_by',
                    desktop: true,
                    sortable: false
                },
                {
                    label: 'Unique Recipients',
                    property: 'recipients_count',
                    desktop: true,
                    sortable: false
                },
                {
                    label: '',
                    property: 'options',
                    desktop: true,
                    sortable: false
                }
            ];
        }
    },

    watch: {
        showBouncedReport (newValue) {
            if (!newValue && this.shouldRefreshOnBounceReportClose) {
                window.location.reload();
            }
        }
    },

    methods: {
        findBouncedCampaign (id) {
            return this.bouncedCampaigns
                .find((campaign) => { return campaign.id === id; });
        },

        findBounceNotification (campaign) {
            return this.bounceNotifications
                .find((notification) => { return notification.data.campaign === campaign.id; });
        },

        awaitingVerificationInQueue (campaign) {
            return !campaign.approved;
        },

        finishedSending ({ total, sent }) {
            return total === sent;
        },

        handleChangedSort ([newSort]) {
            this.sort = newSort || {};
        },

        handleFixBounced (campaign) {
            this.selectedCampaign = campaign;
            this.showBouncedReport = true;
            this.showCampaignReport = false;
        },

        launchCampaignReport (campaign, tab = null) {
            this.selectedCampaign = campaign;

            this.$refs.campaignReportModal.open(tab);
        }
    }
};
</script>
