/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-pay-dollar-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.875 17.25h-5.26a1.51 1.51 0 01-1.5-1.5V7.49h0c-.01-.83.67-1.51 1.49-1.51h18.75-.01c.82-.01 1.5.67 1.5 1.5v8.75M6.26 19.544l.86 1.2v2.5"/><path pid="1" d="M7.875 4.712v0c0-.83-.68-1.5-1.5-1.5-.83 0-1.5.67-1.5 1.5v1.28"/><path pid="2" d="M10.87 6V3.71v0c0-.83-.68-1.5-1.5-1.5-.83 0-1.5.67-1.5 1.5v2.28M13.87 6V2.25v0c0-.83-.68-1.5-1.5-1.5-.83 0-1.5.67-1.5 1.5V6M16.875 6V3.71v0c0-.83-.68-1.5-1.5-1.5-.83 0-1.5.67-1.5 1.5v1.03M16.1 23.25v-1l-.01-.01c-.01-.63.28-1.22.77-1.6l2.22-1.8h-.01c.48-.39.77-.98.77-1.6V12.7l-.01-.01c-.01-.81-.65-1.46-1.45-1.47-1.26 0-1.5 1.41-1.52 1.46l-.29 2.067v-.01c-.08.6-.43 1.13-.96 1.45l-1.59.93v-.01c-.61.36-.98 1.02-.98 1.73v1.33M9.579 13.844h0c.43.57 1.13.9 1.85.87 1.13 0 2.06-.7 2.06-1.55 0-.86-.93-1.55-2.07-1.55-1.14 0-2.07-.7-2.07-1.548 0-.86.92-1.55 2.06-1.55v-.001c.72-.03 1.41.29 1.85.87M11.44 14.72v1.03M11.44 7.5v1.03"/></g>'
  }
})
