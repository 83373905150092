/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buildings-modern-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>office business city skyline</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M2.25 23.25V6l-.01-.01c-.01-.28.15-.53.39-.66L10.87.89h-.01c.36-.2.81-.07 1.01.3.05.1.08.23.08.35v21.69M21.75 23.25V13.083h0a.79.79 0 00-.29-.6l-5.26-4.09h0c-.33-.26-.8-.2-1.06.12-.11.13-.16.29-.16.46v14.25M23.25 23.25H.75M12 8.25H7.5M12 11.25H6"/><path pid="1" d="M6 23.25h3v-3 0c0-.83-.68-1.5-1.5-1.5-.83 0-1.5.67-1.5 1.5zM4.5 4.34V.75M15 13.5h2.25M15 16.5h3.75M20.25 11.55v-3.3"/></g>'
  }
})
