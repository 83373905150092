<template>
    <div class="flex flex-col items-center justify-center space-y-2">
        <app-icon
            name="lock"
            class="h-10 w-10"
            stroke
        ></app-icon>

        <p>This group can access your event using this password:</p>

        <div v-if="editing" class="flex flex-col items-center justify-center w-full space-y-2">
            <form-field-wrapper :error="form.errors.get('password')">
                <input v-model="form.password" class="form-field text-2xl text-center">
            </form-field-wrapper>

            <stateful-button
                :loading="form.processing"
                :disabled="!form.isModified"
                class="button button-primary"
                @click="updatePassword"
            >
                Save
            </stateful-button>
        </div>

        <div v-else class="flex flex-col items-center justify-center w-full space-y-2">
            <p class="text-2xl font-semibold">{{ form.password }}</p>

            <button class="button-text button-primary text-sm" @click="editPassword">
                Change Password
            </button>
        </div>
    </div>
</template>

<script>
import axios from '@/util/axios';
import { get } from 'vuex-pathify';
import Form from '@/validation/Form';

export default {
    name: 'InviteeGroupSecurity',

    props: {
        inviteeGroup: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...get('Event/*')
    },

    data () {
        return {
            editing: false,
            form: new Form({
                password: null
            })
        };
    },

    mounted () {
        this.retrieveInviteeGroupRandomPasswordSecurity();
    },

    methods: {
        retrieveInviteeGroupRandomPasswordSecurity () {
            axios.get(this.route('api.events.invitee-groups.random-password-security', [this.event, this.inviteeGroup]))
                .then(({ data }) => {
                    this.form.password = data.data.securityPassword;
                });
        },

        editPassword () {
            this.editing = true;
        },

        updatePassword () {
            this.form.put(this.route('api.events.invitee-groups.random-password-security', [this.event, this.inviteeGroup]))
                .then(() => {
                    this.editing = false;
                });
        }
    }
};
</script>
