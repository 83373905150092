/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-star-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>shopping favorite like</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9.012 19.188v-.01c-.59.58-1.54.58-2.13 0L1.5 13.79l-.01-.01c-.59-.59-.59-1.54-.01-2.13v-.01l10.5-10.5h-.01c.28-.29.66-.44 1.06-.44h5.378-.01c.82-.01 1.5.67 1.5 1.5v5.37-.01c0 .39-.16.77-.44 1.06"/><path pid="1" d="M16.2 4.12h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375v0M17.62 11.679l1.54 4.07h3-.01c.4-.02.74.29.76.7 0 .21-.09.43-.25.57l-2.6 1.81 1.44 3.314h0c.16.4-.03.86-.43 1.02-.23.09-.49.07-.7-.05l-3.49-1.97-3.5 1.96v-.01c-.38.21-.86.08-1.08-.29a.787.787 0 01-.05-.7l1.44-3.314-2.6-1.82-.01-.01a.733.733 0 01-.07-1.04c.14-.17.35-.26.57-.25h3l1.545-4.07h-.01a.793.793 0 011.4-.01z"/></g>'
  }
})
