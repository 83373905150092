<template>
    <multi-page-item-settings
        items-array-name="tiers"
        :settings="settings"
        :item-creator-function="createEmptyItem"
        :default-close-handler="defaultCloseHandler"
    >
        <template slot-scope="{ edit, save, close, create, restore, selectedItem }">
            <wall-donation-block-general-settings
                key="general-settings"
                :settings="settings"
                @edit-tier="edit"
                @create-tier="create"
            ></wall-donation-block-general-settings>

            <wall-donation-block-item-settings
                key="item-settings"
                :tier="selectedItem"
                @save-settings="save"
                @cancel="restore"
            ></wall-donation-block-item-settings>
        </template>
    </multi-page-item-settings>
</template>

<script>
import HasDefaultCloseHandler from '@/mixins/HasDefaultCloseHandler';
import BaseBlockIsSelfEditable from '@/mixins/BaseBlockIsSelfEditable';

export default {
    name: 'WallDonationBlockSettings',

    mixins: [BaseBlockIsSelfEditable, HasDefaultCloseHandler],

    props: {
        block: {
            type: Object,
            required: true
        },
        event: {
            type: Object,
            required: true
        },
        settings: {
            type: Object,
            required: true
        }
    },

    methods: {
        createEmptyItem () {
            return {
                title: '',
                description: '',
                price: 10,
                canModifyPrice: false,
                active: true,
                deletable: true,
                new: true
            };
        }
    }
};
</script>
