/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-alt-search-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 17.25v-.01c0-3.74 3.03-6.76 6.76-6.75.63 0 1.26.09 1.87.26M7.5.75A4.125 4.125 0 107.5 9a4.125 4.125 0 100-8.25zM15.75 10.5a5.25 5.25 0 100 10.5 5.25 5.25 0 100-10.5zM23.25 23.25l-3.79-3.79"/></g>'
  }
})
