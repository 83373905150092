/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camping-trees-fire-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.166 18.4h0c.41 0 .75-.34.75-.75 0-.14-.04-.26-.1-.38l-2.36-4.88h2.25l-3-5.25h2.25L7.07 1.32h0a.756.756 0 00-1.05-.21c-.09.05-.16.12-.21.2L1.93 7.12h2.25l-3 5.25h2.25l-2.36 4.878h-.01c-.21.35-.09.81.27 1.02.11.06.24.1.37.09zM17.923 10.149l-1.72-3h2.25l-3.88-5.82h0a.756.756 0 00-1.05-.21c-.09.05-.16.12-.21.2l-1.38 2.06M6.46 18.4v4.5M19.454 13.156h0a.372.372 0 00-.51.17c-.05.09-.06.21-.01.31l-.01-.01c.5 1.19.42 2.55-.21 3.69v-.01c-.77.06-1.45-.48-1.57-1.24h-.01c-1.13.6-1.8 1.81-1.72 3.09l-.01-.01c0 2.05 1.68 3.71 3.74 3.71v-.011c2.04-.02 3.7-1.67 3.74-3.71v-.01a6.031 6.031 0 00-3.5-6.04z"/></g>'
  }
})
