/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stop-sign-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.2 6.407l-3-1-.01-.01c-1.58-.53-3.28.32-3.8 1.89-.11.3-.16.62-.16.95v4.5l-1.8-1.44h0c-.49-.39-1.2-.32-1.59.17-.03.02-.05.05-.06.08v0h-.01a1.1 1.1 0 000 1.25l2.56 3.85-.01-.01a4.67 4.67 0 003.86 2.07h2.23c.99 0 1.95-.4 2.66-1.1v0-.01c.7-.71 1.1-1.67 1.09-2.66V9.18c0-1.3-.83-2.44-2.05-2.85z"/><path pid="1" d="M6.75 23.25h10.5l6-6V6.75l-6-6H6.75l-6 6v10.5l6 6z"/></g>'
  }
})
