/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-clean-bottle-shield-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23 15.74l-.01-.01a7.69 7.69 0 01-6 7.5l-.01-.01a7.67 7.67 0 01-6-7.5v-2.25h0c-.01-.83.67-1.5 1.49-1.51h9-.01c.82-.01 1.5.67 1.5 1.49 0 0 0 0 0 0zM17 15v4.5M14.75 17.25h4.5"/><path pid="1" d="M8.5 20.253H4l-.01-.001c-1.66-.01-3-1.35-3-3V9.751h0A2.996 2.996 0 013.98 6.74h6-.01c1.39-.01 2.61.96 2.92 2.33M1 2.25l.62-.621h-.01a2.95 2.95 0 012.11-.88h7"/><path pid="2" d="M10 6.751H4v-1.5h0c-.01-.83.67-1.51 1.49-1.51h3-.01c.82-.01 1.5.67 1.5 1.5zM7 3.75v-3"/></g>'
  }
})
