/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-road-trip-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>marker gps</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none"><path pid="0" d="M23.25 12v0c0 6.21-5.04 11.25-11.25 11.25C5.78 23.25.75 18.21.75 12 .75 5.78 5.78.75 12 .75"/><path pid="1" d="M3.98 13.272v-.01c.27-.19.56-.36.86-.51"/><path pid="2" stroke-dasharray="2.025 2.025" d="M6.758 12.107c4.08-.79 6.5 2.94 10.323 3.56"/><path pid="3" d="M18.091 15.76h0c.33 0 .66-.02.99-.07"/><g stroke-linejoin="round"><path pid="4" d="M18.75 4.875h-.01c.2-.01.37.16.37.37"/><path pid="5" d="M18.375 5.25h0c-.01-.21.16-.38.37-.38M18.75 5.62h-.01a.386.386 0 01-.38-.38s0 0 0 0M19.125 5.25v0c0 .2-.17.375-.375.375"/><path pid="6" d="M18.75.75h-.01c2.48-.01 4.5 2.01 4.5 4.49 0 1.92-2.69 5.57-3.91 7.13v-.01a.759.759 0 01-1.19-.01c-1.23-1.57-3.91-5.22-3.91-7.14h0A4.494 4.494 0 0118.72.7z"/></g></g>'
  }
})
