import swal from 'sweetalert2';
import { asset } from '@codinglabs/laravel-asset';

require('sweetalert2/dist/sweetalert2.css');

export default class Alert {
    static createAlert (icon, title, message, attributes) {
        const alertAttributes = {

            icon,
            title,
            text: message,
            ...attributes
        };

        return swal.fire(alertAttributes);
    }

    confirm (title, message, attributes = {}, onConfirmCallback, onCancelCallback) {
        const icon = attributes.type !== null ? 'warning' : null;

        const confirmAttributes = {
            customClass: {
                confirmButton: 'button button-primary mx-2',
                cancelButton: 'button mx-2'
            },
            buttonsStyling: false,
            showCancelButton: true,
            ...attributes
        };

        Alert.createAlert(icon, title, message, confirmAttributes).then(
            (result) => {
                if (result.dismiss === undefined && onConfirmCallback) {
                    onConfirmCallback(result);
                } else if (result.dismiss !== undefined && onCancelCallback) {
                    onCancelCallback(result);
                }
            }
        );
    }

    confirmType (title, message, attributes = {}, onConfirmCallback, onCancelCallback) {
        const icon = attributes.type !== null ? 'warning' : null;

        const confirmAttributes = {
            customClass: {
                confirmButton: 'button button-primary mx-2',
                cancelButton: 'button mx-2'
            },
            input: 'text',
            inputValidator: (value) => {
                if (value.toLowerCase() !== 'delete') {
                    return 'You must type "delete" to confirm.';
                }
            },
            buttonsStyling: false,
            showCancelButton: true,
            ...attributes
        };

        Alert.createAlert(icon, title, message, confirmAttributes).then(
            (result) => {
                if (result.dismiss === undefined && onConfirmCallback) {
                    onConfirmCallback(result);
                } else if (result.dismiss !== undefined && onCancelCallback) {
                    onCancelCallback(result);
                }
            }
        );
    }

    async confirmAsync (title, message, attributes = {}) {
        return new Promise((resolve) => {
            this.confirm(
                title,
                message,
                attributes,
                () => { resolve(true); },
                () => { resolve(false); }
            );
        });
    }

    async success (title, message, attributes = {}) {
        const successAttributes = {
            customClass: {
                confirmButton: 'button button-primary'
            },
            buttonsStyling: false,
            ...attributes
        };

        return Alert.createAlert('success', title, message, successAttributes);
    }

    paymentRequired (title, message, attributes = {}, onConfirmCallback) {
        const paymentAttributes = {
            customClass: {
                confirmButton: 'button button-info',
                cancelButton: 'button mx-2'
            },
            showCancelButton: true,
            buttonsStyling: false,
            reverseButtons: true,
            confirmButtonText: attributes.chatWithUs ? 'Chat With Us' : 'Upgrade Options',
            cancelButtonText: 'Not now',
            icon: false,
            imageUrl: asset('/images/account/rsvpify-premium.svg'),
            imageHeight: 90,
            html: message,
            ...attributes
        };

        Alert.createAlert('info', title, null, paymentAttributes).then(
            (result) => {
                if (result.isConfirmed && !onConfirmCallback) {
                    if (attributes.chatWithUs && !!window.Intercom) {
                        window.Intercom('showNewMessage', attributes.supportMessage ? attributes.supportMessage : null);
                    } else {
                        window.location.href = window.route('account.upgrade.index');
                    }
                } else if (result.isConfirmed && onConfirmCallback) {
                    onConfirmCallback(result);
                }
            }
        );
    }

    error (title, message, attributes = {}, onConfirmCallback, onCancelCallback) {
        const errorAttributes = {
            customClass: {
                confirmButton: 'bg-red text-white font-sans rounded-md p-3 hover:bg-red hover:bg-opacity-75'
            },
            buttonsStyling: false,
            ...attributes
        };

        Alert.createAlert('error', title, message, errorAttributes).then(
            (result) => {
                if (result.dismiss !== 'cancel' && onConfirmCallback) {
                    onConfirmCallback(result);
                } else if (result.dismiss === 'cancel' && onCancelCallback) {
                    onCancelCallback(result);
                }
            }
        );
    }

    warning (title, message, attributes = {}) {
        Alert.createAlert('warning', title, message, attributes);
    }

    inputAlert (title, message, attributes = {}, onConfirmCallback, onCancelCallback) {
        const inputAlertAttributes = {
            customClass: {
                confirmButton: 'button button-primary mx-2',
                cancelButton: 'button mx-2'
            },
            input: 'text',
            buttonsStyling: false,
            showCancelButton: true,
            ...attributes
        };

        Alert.createAlert('warning', title, message, inputAlertAttributes).then(
            (result) => {
                if ((result.value || result.value === '') && onConfirmCallback) {
                    onConfirmCallback(result);
                } else if (!result.value && onCancelCallback) {
                    onCancelCallback();
                }
            }
        );
    }

    dropdownAlert (title, message, attributes = {}, onConfirmCallback, onCancelCallback) {
        return (new Alert()).inputAlert(
            title,
            message,
            {
                input: 'select',
                ...attributes
            },
            onConfirmCallback,
            onCancelCallback
        );
    }
}
