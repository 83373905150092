/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'technology-mobile-qr-code-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>check-in scan</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="13.5" height="22.5" x="5.25" y=".75" rx="3"/><path pid="1" d="M5.25 19.12h13.5M8.25 6.375v-1.5h1.5M12.75 4.875h3v3h-3zM8.25 12.375h3v3h-3zM8.25 9.38h3M13.5 10.88h2.25M15.75 13.87v1.5H13.5"/></g>'
  }
})
