<template>
    <app-modal
        v-model="showModal"
        title="Send Email to Invitees"
        @closed="close"
    >
        <div
            v-if="loading"
            class="text-center m-2"
        >
            <app-icon class="h-12 w-12 text-gray-500" name="loader"></app-icon>
        </div>
        <div v-else class="w-10/12 m-auto mt-4">
            <div class="flex flex-col">
                <div>
                    <img class="h-24 m-auto" :src="asset('images/campaigns/send-email.svg')">
                </div>

                <div class="text-center text-2xl mt-4">
                    <h1>Do you want to send an email invitation to these new invitees?</h1>
                </div>

                <div class="py-1 px-6 mt-4 bg-white text-center border-gray-100 border-2 rounded-md m-auto">
                    <span><strong>{{ recipientCount }} </strong> recipient(s)</span>
                    <span class="px-6"><strong>{{ uniqueEmailCount }}</strong> unique email(s)</span>
                    <span><strong>{{ groupCount }}</strong> group(s)</span>
                </div>

                <div class="mt-4">
                    <p class="bg-white text-sm p-2 text-gray-500 uppercase tracking-wide">Select a recently sent email</p>
                    <simple-picker
                        v-model="campaignId"
                        :items="campaigns"
                        placeholder-empty-state="Select an email"
                        placeholder-search="Type to search ..."
                        item-label="title"
                        item-value="id"
                    ></simple-picker>
                    <p
                        v-if="campaignId"
                        class="bg-white text-sm p-2 text-gray-500 italic"
                    >You first sent this email {{ campaignLastSentAt | dateTimeTz(eventTimezone.name) }}</p>
                </div>
            </div>
        </div>

        <template #footer>
            <stateful-button
                :disabled="! campaignId"
                :loading="sending"
                class="button button-primary uppercase w-full disabled:opacity-50 disabled:cursor-not-allowed"
                @click="send"
            >
                Send email
            </stateful-button>

            <button class="button uppercase" @click="close">
                Not right now
            </button>
        </template>
    </app-modal>
</template>

<script>
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import axios from '@/util/axios';
import EventTimezone from '@/mixins/EventTimezone';

export default {
    mixins: [EventTimezone],

    props: {
        event: {
            type: Object,
            required: true
        },

        importId: {
            type: Number,
            default: null
        },

        importInviteeCount: {
            type: Number,
            default: null
        },

        importGroupCount: {
            type: Number,
            default: null
        },

        importUniqueEmailCount: {
            type: Number,
            default: null
        }
    },

    data () {
        return {
            campaigns: [],
            campaignId: null,
            completeCallback: null,
            invitees: [],
            loading: true,
            showModal: false,
            sending: false
        };
    },

    computed: {
        campaignLastSentAt () {
            if (!this.campaignId) {
                return null;
            }

            const campaign = find(this.campaigns, { id: this.campaignId });

            return campaign.sent_at;
        },

        recipients () {
            const recipients = [];

            forEach(this.invitees, (invitee) => {
                recipients.push({
                    type: 'Invitee',
                    id: invitee.id
                });
            });

            return recipients;
        },

        uniqueEmailCount () {
            if (this.importUniqueEmailCount) {
                return this.importUniqueEmailCount;
            }

            return filter(this.invitees, (invitee) => {
                return invitee.email !== null;
            }).length;
        },

        groupCount () {
            if (this.importGroupCount) {
                return this.importGroupCount;
            }

            return 1;
        },

        recipientCount () {
            if (this.importInviteeCount) {
                return this.importInviteeCount;
            }

            return uniqBy(filter(this.invitees, (invitee) => {
                return invitee.email !== null;
            }), 'email').length;
        }
    },

    watch: {
        loading () {
            if (this.showModal && this.campaigns.length === 0) {
                this.close();
            }
        }
    },

    mounted () {
        this.resetCompleteCallback();
        this.fetchCampaigns();
    },

    methods: {
        close () {
            const callback = this.completeCallback;
            this.showModal = false;

            this.resetCompleteCallback();

            if (callback) {
                return callback();
            }

            return new Promise((resolve) => {
                resolve();
            });
        },

        open (invitees = []) {
            if (!this.loading && this.campaigns.length === 0) {
                return this.close();
            }

            this.invitees = invitees;
            this.showModal = true;

            if (this.uniqueEmailCount === 0) {
                return this.close();
            }

            return new Promise((resolve) => {
                this.completeCallback = resolve;
            });
        },

        fetchCampaigns () {
            axios.get(this.route('api.events.campaigns.sent.index', this.event))
                .then(({ data: { data: campaigns } }) => {
                    this.campaigns = campaigns;
                    this.loading = false;
                });
        },

        resetCompleteCallback () {
            this.completeCallback = null;
        },

        send () {
            this.sending = true;

            const params = this.invitees.length > 0 ? { recipients: this.recipients } : { import_id: this.importId };

            axios.post(this.route('api.events.campaigns.resend', {
                event: this.event,
                campaign: this.campaignId
            }), params)
                .then(() => {
                    this.$toasted.global.success(`Sending selected email to the invitees.`);
                    this.close();
                })
                .finally(() => {
                    this.sending = false;
                });
        }
    }
};
</script>
