<template>
    <div class="top-0 right-0 mt-15 absolute">
        <a
            v-for="link in activeLinks"
            :key="link.title"
            :href="link.action ? '#' : link.url"
            :target="link.target"
            class="group flex items-center w-63 no-underline text-violet-200 hover:text-white bg-black hover:bg-violet-700 p-3 cursor-pointer space-x-3"
            @click="linkClicked(link)"
        >
            <app-icon
                v-if="link.icon"
                :name="link.icon"
                class="h-5 w-5 text-violet-300 group-hover:text-violet-50"
            ></app-icon>
            <span>{{ link.title }}</span>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Array,
            required: true
        }
    },

    computed: {
        activeLinks () {
            return this.links.filter((link) => {
                return !link.hidden;
            });
        }
    },

    methods: {
        linkClicked (link) {
            if (link.action) {
                link.action();
            }
        }
    }
};
</script>
