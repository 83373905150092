/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sort ascending</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 7.5v9M8.251 11.24l3.75-3.75 3.75 3.75"/><path pid="1" d="M12 1.49a10.5 10.5 0 100 21 10.5 10.5 0 100-21z"/></g>'
  }
})
