/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-sanitizer-virus-shield-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.319 21.66H2.9v-.001c-1.2 0-2.17-.97-2.17-2.16V8.67h0c-.01-1.2.96-2.17 2.16-2.17h7.9-.01c1.19-.01 2.16.96 2.16 2.16v.74"/><path pid="1" d="M3.99 1.117h5.741-.01c.59-.01 1.08.48 1.08 1.08h-.01v4.32h-7.9V2.18h0c-.01-.6.48-1.09 1.07-1.09zM.75 10.85h8.31M.75 17.33h6.49M23.25 15.546h0a7.5 7.5 0 01-5.88 7.33l-.01-.01a7.501 7.501 0 01-5.87-7.34v-2.2h0c-.01-.82.65-1.47 1.46-1.47h8.8-.01c.81-.01 1.46.65 1.46 1.46zM17.38 14.81v4.41M15.18 17.01h4.4"/></g>'
  }
})
