<template>
    <div>
        <form-field-wrapper
            label="Event name"
            :error="form.errors.get('title')"
            :should-show-error="form.errors.has('title')"
        >
            <input v-model="form.title" class="form-field">
        </form-field-wrapper>

        <form-field-wrapper
            label="Event description (optional)"
            :error="form.errors.get('description')"
            :should-show-error="form.errors.has('description')"
        >
            <textarea
                v-model="form.description"
                class="form-field"
                rows="4"
            ></textarea>
        </form-field-wrapper>

        <form-field-wrapper>
            <div class="pl-2 text-sm text-gray-600 font-semibold tracking-wide flex items-center">
                <guest-reply-icon
                    class="mr-1"
                    state="Attending"
                    colored
                ></guest-reply-icon> Attending Response
            </div>

            <div class="flex items-center font-semibold p-2 shadow rounded-md text-sm bg-white border mb-4 mt-2">
                <div class="flex-auto text-gray-600">
                    {{ attendingProduct.title }}
                </div>

                <button class="shrink cursor-pointer text-gray-500 hover:text-purple leading-none" @click="editItem(attendingProduct)">
                    <app-icon
                        name="settings-cog"
                        class="h-5 w-5"
                        stroke
                    ></app-icon>
                </button>
            </div>

            <div class="pl-2 mt-2 text-sm text-gray-600 font-semibold tracking-wide flex items-center">
                <guest-reply-icon
                    class="mr-1"
                    state="Maybe"
                    colored
                ></guest-reply-icon> Maybe Attending Response
            </div>

            <div class="flex items-center font-semibold p-2 shadow rounded-md text-sm bg-white border mb-4 mt-2">
                <div class="flex-auto text-gray-600">
                    {{ maybeProduct.title }}
                </div>

                <div class="shrink mr-2 leading-none">
                    <toggle-switch v-model="maybeProduct.active" small></toggle-switch>
                </div>

                <button class="shrink cursor-pointer text-gray-500 hover:text-purple leading-none" @click="editItem(maybeProduct)">
                    <app-icon
                        name="settings-cog"
                        class="h-5 w-5"
                        stroke
                    ></app-icon>
                </button>
            </div>

            <div class="pl-2 mt-2 text-sm text-gray-600 font-semibold tracking-wide flex items-center">
                <guest-reply-icon
                    class="mr-1"
                    state="NotAttending"
                    colored
                ></guest-reply-icon> Not Attending Response
            </div>

            <div class="flex items-center font-semibold p-2 shadow rounded-md text-sm bg-white border mb-4 mt-2">
                <div class="flex-auto text-gray-600">
                    {{ declineProduct.title }}
                </div>

                <div class="shrink mr-2 leading-none">
                    <toggle-switch v-model="declineProduct.active" small></toggle-switch>
                </div>

                <button class="shrink cursor-pointer text-gray-500 hover:text-purple leading-none" @click="editItem(declineProduct)">
                    <app-icon
                        name="settings-cog"
                        class="h-5 w-5"
                        stroke
                    ></app-icon>
                </button>
            </div>
        </form-field-wrapper>

        <form-field-wrapper
            label="Invite"
            :error="form.errors.get('audience.additionalCriteria.target')"
            :should-show-error="form.errors.has('audience.additionalCriteria.target')"
        >
            <select-list v-model="form.additionalCriteria.target">
                <select-option
                    id="none"
                    class="my-2"
                    label="Everyone"
                    tooltip="Everyone will be asked if they are able to attend this secondary event."
                ></select-option>

                <select-option
                    id="tagged"
                    class="my-2"
                    label="Only invitees tagged with…"
                    :disabled="!eventHasTags"
                    tooltip="If you’re using an Invite List, tag invitees to indicate who can both (1) see and (2) be asked to reply to this secondary event."
                ></select-option>
            </select-list>
        </form-field-wrapper>

        <div v-if="isAudienceTaggedInvites" class="ml-9 mb-8">
            <form-field-wrapper :error="form.errors.get('additionalCriteria.query.tagIds.value')" :should-show-error="form.errors.has('additionalCriteria.query.tagIds.value')">
                <simple-picker
                    v-model="form.additionalCriteria.query.tagIds.value"
                    :items="eventTags"
                    item-label="name"
                    item-value="id"
                    placeholder-empty-state="Select tag..."
                    @input="form.additionalCriteria.query.tagIds.comparison = 'equals'"
                ></simple-picker>
            </form-field-wrapper>

            <form-field-wrapper :error="form.errors.get('additionalCriteria.includeAdditionalGuests')" :should-show-error="form.errors.has('additionalCriteria.includeAdditionalGuests')">
                <toggle-switch v-model="form.additionalCriteria.includeAdditionalGuests">
                    <span
                        v-tippy
                        class="tooltip-text hover:cursor-help"
                        content="+1’s / additional guests will also be invited to this secondary event."
                    >
                        Include unnamed guests
                    </span>
                </toggle-switch>
            </form-field-wrapper>
        </div>

        <form-field-wrapper
            label="Ask If"
            :error="form.errors.get('askIf.target')"
            :should-show-error="form.errors.has('askIf.target')"
        >
            <select-list v-model="form.askIf.target">
                <select-option
                    id="always"
                    class="my-2"
                    label="Always"
                    tooltip="Guests will be asked if they can attend this secondary event even if they have indicated that they are not attending your primary event."
                ></select-option>

                <select-option
                    id="attending"
                    class="my-2"
                    label="Only if attending primary event"
                    tooltip="Guests will only see and be asked to reply to this secondary event if they have indicated the they are attending your primary event."
                ></select-option>

                <select-option
                    v-if="displayMaybeAskIfOption"
                    id="maybe"
                    class="my-2"
                    label="Only if maybe attending primary event"
                    tooltip="Guests will only see and be asked to reply to this secondary event if they have indicated that they might be attending your primary event."
                ></select-option>

                <select-option
                    id="not-attending"
                    class="my-2"
                    label="Only if not attending primary event"
                    tooltip="Guests will only see and be asked to reply to this secondary event if they have indicated that the they are not attending your primary event."
                ></select-option>
            </select-list>
        </form-field-wrapper>
    </div>
</template>

<script>
import find from 'lodash/find';
import { get } from 'vuex-pathify';
import BlockableGeneralSettingsMixin from '@/mixins/BlockableGeneralSettingsMixin';

export default {
    name: 'FormSecondaryEventBlockGeneralSettings',

    mixins: [BlockableGeneralSettingsMixin],

    props: {
        block: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...get('Event/event@', {
            eventTags: 'tags',
            eventSetupType: 'setup_type'
        }),

        attendingProduct () {
            return find(this.form.products, {
                short_guest_state: 'Attending'
            });
        },

        declineProduct () {
            return find(this.form.products, {
                short_guest_state: 'NotAttending'
            });
        },

        displayMaybeAskIfOption () {
            return this.eventSetupType !== 'tickets';
        },

        eventHasTags () {
            return !!this.eventTags.length;
        },

        isAudienceTaggedInvites () {
            return this.form.additionalCriteria.target === 'tagged';
        },

        maybeProduct () {
            return find(this.form.products, {
                short_guest_state: 'Maybe'
            });
        }
    }
};
</script>
