/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-sanitizer-liquid-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M1.12 19.78l2.9 2.32-.01-.01a5.21 5.21 0 003.24 1.13h5.967l-.01-.001c.95 0 1.73-.78 1.73-1.74v0h-.001c0-.96-.78-1.74-1.74-1.74h-4.48"/><path pid="1" d="M1.27 14.6h3.46l-.01-.001c1.02 0 2.02.3 2.88.87l1.97 1.31-.01-.01c.76.45 1.01 1.45.55 2.21-.02.02-.03.04-.05.06v0h-.01c-.46.67-1.34.91-2.07.54l-2.6-1.56M22.879 10.64v0a4.95 4.95 0 01-4.945 4.945 4.943 4.943 0 01-4.945-4.945c0-3.71 4.945-9.89 4.945-9.89s4.945 6.18 4.945 9.89zM15.96 9.65h3.95M17.93 7.67v3.96"/></g>'
  }
})
