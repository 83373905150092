import Vue from 'vue';
import find from 'lodash/find';
import { DateTime, Settings } from 'luxon';

/**
 * Formats provided UTC timestamp to a human-readable string such as
 * February 1, 2021 at 9:00 AM and in the event's timezone. If event
 * doesn't have timezone specified, uses browser's default timezone.
 *
 * @param {string} value
 * @param {string,null} zone
 * @param {Function,null} valueParser
 */
const dateTimeTzFilter = (value, zone = null, valueParser = null) => {
    if (!value) {
        return '-';
    }

    valueParser ||= (value) => {
        return DateTime.fromISO(value);
    };

    const browserTimezone = find(window.timezones, { name: Settings.defaultZone.name });
    const timezone = zone || browserTimezone.name;

    return valueParser(value)
        .setZone(timezone)
        .toFormat(`LLLL d, yyyy 'at' h:mm a`);
};

Vue.filter('dateTimeTz', dateTimeTzFilter);

export default dateTimeTzFilter;
