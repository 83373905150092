<template>
    <div v-if="showError" class="alert alert-error alert-sm mt-2">
        <p class="w-full font-normal text-center">{{ error[0] }}</p>
    </div>
</template>

<script>
import get from 'lodash/get';

export default {
    name: 'PartyDetailsFormFieldError',

    props: {
        formErrors: {
            type: Object,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },

    computed: {
        showError () {
            return this.error !== null;
        },

        error () {
            return get(this.formErrors, this.name, null);
        }
    }
};
</script>
