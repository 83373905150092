/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'family-couple-man-man-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>pride equality</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 11.25a3 3 0 100 6 3 3 0 100-6zM9.75 23.25v0a4.5 4.5 0 00-9 0M18.75 11.25a3 3 0 100 6 3 3 0 100-6zM23.25 23.25v0a4.5 4.5 0 00-9 0M16.272 1.5h0a2.433 2.433 0 00-3.44-.09c-.03.02-.06.05-.09.08l-1.51 1.56-1.51-1.56h0a2.433 2.433 0 00-3.44-.09c-.03.02-.06.05-.09.08h-.01a2.608 2.608 0 000 3.63l4.48 4.639h0c.28.29.75.3 1.04.02 0-.01.01-.02.02-.03l4.48-4.639h-.01a2.63 2.63 0 00-.01-3.64z"/></g>'
  }
})
