/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-beer-glass-foam': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>brew pub bar alcohol</desc><path pid="0" _fill="currentColor" d="M20.75 4a4 4 0 00-4-4H6.25a3 3 0 00-.774 5.894.5.5 0 01.366.405A20.177 20.177 0 007.49 11.69a11.92 11.92 0 01.1 9.59 3.793 3.793 0 00-.34 1.22 1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5 4.076 4.076 0 00-.34-1.22 11.835 11.835 0 01-.16-8.89.25.25 0 01.4-.1 2.753 2.753 0 004.6-2.04zM9.25 9a1 1 0 111 1 1 1 0 01-1-1zm2 11a1 1 0 111-1 1 1 0 01-1 1zm1-6a1 1 0 111-1 1 1 0 01-1 1zm6.5-3.75a.75.75 0 01-1.5 0V7.72a1 1 0 00-1.5-.86 1 1 0 01-1.5-.86 1.022 1.022 0 01.14-.5.963.963 0 000-1 .987.987 0 00-.86-.5H6.25a1 1 0 010-2h10.5a2.006 2.006 0 012 2z"/>'
  }
})
