/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-football-helmet-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.73 18.379l2.47 2.82-.01-.01a5.973 5.973 0 004.51 2.04h2.52l-.01-.001c1.65 0 3-1.35 3-3v-1.5 0c0-.83-.68-1.5-1.5-1.5h-10.5"/><path pid="1" d="M9.75 15.75a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM9.75 15.75v-1.5h0a5.992 5.992 0 015.99-6.01h6.88"/><path pid="2" d="M11.187 23.221l-.01-.01C4.97 22.741.32 17.34.78 11.15 1.24 4.95 6.64.3 12.837.76c4.44.33 8.28 3.26 9.77 7.46h0c.41 1.17-.2 2.45-1.37 2.87-.25.08-.5.12-.76.12H15.69c-1.66 0-3 1.34-3 3 0 0 0 0 0 0"/><path pid="3" d="M15.75 17.25v3h0c0 .84-.36 1.65-.99 2.22M20.25 17.25v3h0c0 1.57-1.23 2.88-2.81 2.99"/></g>'
  }
})
