/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'money-bag-share-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>shopping donate</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 14.25v9M.75 21.75h16.5v0c0-1.66-1.35-3-3-3H10.5v0c0-1.66-1.35-3-3-3H.75M6.75 18.75h3.75M20.24 18v-.01c3.31-2.47 3.99-7.15 1.52-10.47a7.46 7.46 0 00-3.56-2.6l1.85-3.17v-.01c.12-.22.12-.48-.01-.69h0a.721.721 0 00-.62-.35H12h0a.665.665 0 00-.61.34h-.01c-.13.21-.13.47 0 .68l1.85 3.17v-.01a7.465 7.465 0 00-5.04 7.05"/><path pid="1" d="M13.7 13.844h0c.43.57 1.13.9 1.85.87 1.13 0 2.06-.7 2.06-1.55 0-.86-.93-1.55-2.07-1.55-1.14 0-2.06-.7-2.06-1.55 0-.86.92-1.55 2.06-1.55v-.001c.72-.03 1.41.29 1.85.87M15.56 14.72v1.03M15.56 7.5v1.03"/></g>'
  }
})
