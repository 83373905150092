<template>
    <div
        v-if="shouldShowIndicator"
        v-tippy
        :title="tooltipText"
        :class="styles"
    >
        <img
            class="w-5 h-5"
            :src="asset('images/account/rsvpify-premium.svg')"
        >
        <span v-if="block" class="uppercase text-sm ml-1">Premium Feature</span>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import InteractsWithFeatureFlags from '@/mixins/InteractsWithFeatureFlags';

export default {
    name: 'PremiumFeature',

    mixins: [InteractsWithFeatureFlags],

    props: {
        block: {
            type: Boolean,
            default: false
        },
        feature: {
            type: String,
            required: true
        },
        featureUsage: {
            type: Object,
            default: null
        },
        requiresUpgrade: {
            type: Boolean,
            default: false
        },
        tooltip: {
            type: String,
            default: null
        }
    },

    computed: {
        ...get('UsedFeatures/*'),

        tooltipText () {
            return this.tooltip
                ? this.tooltip
                : this.usage.message.message;
        },

        usage () {
            return this.featureUsage || this.usedFeatures[this.feature];
        },

        shouldShowIndicator () {
            return this.requiresUpgrade || (
                this.usage
                && this.usage.requires_upgrade
                && this.usage.message.notifyImmediately
            );
        },

        styles () {
            return {
                'flex items-center rounded-md bg-teal text-white pl-1 pr-2': this.block
            };
        }
    }
};
</script>
