/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dessert-pie-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.48 20.2l-1.43 2.33v-.01c-.28.44-.76.71-1.28.71H7.4v-.001c-.53 0-1.01-.28-1.28-.72l-1.41-2.3"/><path pid="1" d="M21.95 18.684v-.01c.36-.28.56-.72.52-1.18-.46-4.32-4.98-7.69-10.48-7.69s-10.02 3.37-10.477 7.69v-.01c-.04.45.16.89.52 1.17l1.189.95h0c1.02.81 2.47.81 3.5-.01v0h-.01a2.81 2.81 0 013.5-.01v0l-.01-.01c1.02.81 2.47.81 3.5 0v0h-.01a2.81 2.81 0 013.5-.01v0h0c1.02.81 2.47.81 3.5-.01zM12.02 14.25L12 12.71M15.73 14.25l-.75-1.5M8.23 14.25l.75-1.5M12.73.75l-.62.823v-.01c-.52.67-.41 1.63.24 2.17v0h0c.64.54.75 1.5.24 2.17l-.62.82M6.21 2.25l-.617.823v-.01c-.52.67-.41 1.63.24 2.17v0h0c.65.54.75 1.5.24 2.17l-.62.82M18.733 2.25l.61.823-.01-.01c.51.67.4 1.63-.25 2.17v0-.01c-.66.54-.76 1.5-.25 2.17l.61.82"/></g>'
  }
})
