/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rotate-forward': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>right clockwise</desc><g _fill="currentColor"><path pid="0" d="M12.081 22a8 8 0 110-16 .243.243 0 01.172.414l-1.879 1.879a1 1 0 101.414 1.414l4-4a1 1 0 000-1.416l-4-4a1 1 0 10-1.414 1.414l1.879 1.879a.243.243 0 01-.172.416 10 10 0 000 20 1 1 0 100-2zM16.9 20.39a8.008 8.008 0 01-1.723.991 1 1 0 00.773 1.845 10.036 10.036 0 002.156-1.24 1 1 0 00-1.206-1.6zM21.11 14.732a1 1 0 00-1.173.791 7.961 7.961 0 01-.676 2.006 1 1 0 101.8.883 10.079 10.079 0 00.844-2.507 1 1 0 00-.795-1.173zM21.089 13.068a1 1 0 00.765-1.189 10 10 0 00-.941-2.574 1 1 0 00-1.766.938A7.978 7.978 0 0119.9 12.3a1 1 0 001.189.768z"/></g>'
  }
})
