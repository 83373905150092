<template>
    <nav
        class="w-full flex justify-between flex-col sm:flex-row sm:relative"
        aria-label="Progress"
    >
        <button
            v-if="showBackButton"
            class="button-text button-primary button-sm mx-auto mb-4 sm:mx-0 sm:mb-0 sm:absolute sm:left-0 sm:top-0"
            @click="$emit('back')"
        >
            &larr; Back
        </button>

        <ol role="list" class="flex justify-center w-full">
            <li
                v-for="({ label }, index) in navSteps"
                :key="index"
                class="flex items-center"
            >
                <span
                    v-if="index > 0"
                    aria-hidden="true"
                    class="h-0.5 w-4 sm:w-20 mx-4 bg-gray-300"
                ></span>

                <component
                    :is="index <= maxNavigatableStepIndex ? 'button' : 'span'"
                    class="flex items-center group"
                    @click="navigate(index)"
                >
                    <span
                        class="h-6 w-6 flex items-center justify-center border rounded-full text-xs font-bold"
                        :class="{
                            'group-hover:bg-blue group-hover:border-blue group-hover:text-white': index <= maxNavigatableStepIndex,
                            'border-gray-300 transition': activeStepIndex !== index,
                            'bg-blue border-blue text-white': activeStepIndex === index,
                        }"
                    >
                        {{ index + 1 }}
                    </span>

                    <span
                        class="ml-2 text-sm hidden lg:inline"
                        :class="{
                            'group-hover:text-blue': index <= maxNavigatableStepIndex,
                            'text-gray-500': activeStepIndex !== index,
                            'text-blue': activeStepIndex === index,
                        }"
                    >
                        {{ label }}
                    </span>
                </component>
            </li>
        </ol>
    </nav>
</template>

<script>
export default {
    name: 'CreateEventNavigation',

    props: {
        steps: {
            type: Array,
            required: true
        },
        activeStep: {
            type: Object,
            default: null
        },
        maxNavigatableStep: {
            type: Object,
            default: 0
        },
        showBackButton: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        activeStepIndex () {
            return this.navSteps.findIndex((step) => {
                return step === this.activeStep;
            });
        },

        maxNavigatableStepIndex () {
            return this.navSteps.findIndex((step) => {
                return step === this.maxNavigatableStep;
            });
        },

        navSteps () {
            return this.steps.filter((step) => {
                return step.hideFromNav !== true;
            });
        }
    },

    methods: {
        navigate (index) {
            if (index <= this.maxNavigatableStepIndex) {
                this.$emit('navigate', index);
            }
        }
    }
};
</script>
