/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-dance-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M20.3 18.94l-.75-1.87h0a4.468 4.468 0 00-4.18-2.83h-2.34l-.48-3h1.86-.01c1.57-.01 3.04-.84 3.86-2.19l1.375-2.3v-.01c.41-.72.18-1.64-.54-2.06-.71-.42-1.62-.19-2.04.5l-1.38 2.29v-.01c-.28.45-.77.72-1.29.72h-5.8 0c-1.2-.01-2.35.47-3.19 1.31l-2.13 2.12h-.01a1.5 1.5 0 00-.01 2.12c.58.58 1.53.58 2.12 0L7.48 11.6h-.01c.28-.28.66-.44 1.06-.44h.9l1.16 7.32h0c.06.37-.02.76-.23 1.09l-.84 1.25h-.01c-.46.69-.28 1.62.41 2.08.69.45 1.62.27 2.08-.42l.83-1.26h-.01c.63-.96.88-2.12.69-3.25l-.14-.84h1.85-.01c.61-.01 1.16.37 1.39.94l.74 1.86-.01-.01c.34.75 1.23 1.08 1.98.74.7-.33 1.04-1.13.79-1.86zM10.5.75a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM2.25 3v3M.75 4.5h3M21.75.75v3M20.25 2.25h3"/><path pid="1" d="M18.1 4.526l-1.48-1.541-.01-.01a.746.746 0 00-1.06-.03h-.01l-1.09 1.03h-.01c-.3.28-.31.76-.03 1.06l1.68 1.75M17.842 9.679l1.78 1.85h0c.57.59 1.52.61 2.12.04v-.01l1.08-1.04v-.01c.59-.58.61-1.53.04-2.13l-.01-.01-1.82-1.9h0a3.13 3.13 0 00-1.2-.77M22.5 15.37h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M22.125 15.75h0c-.01-.21.16-.38.37-.38M22.5 16.125h-.01a.386.386 0 01-.38-.38s0 0 0 0M22.875 15.75v0c0 .2-.17.375-.375.375M5.25 6.375h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M4.875 6.75h0c-.01-.21.16-.38.37-.38M5.25 7.12h-.01a.386.386 0 01-.38-.38s0 0 0 0"/><path pid="4" d="M5.62 6.75v0c0 .2-.17.375-.375.375"/></g>'
  }
})
