/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-heart-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>event date time appointment favorite</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="22.5" height="19.5" x=".75" y="3.75" rx="1.5"/><path pid="1" d="M.75 9.75h22.5M6.75 6V.75M17.25 6V.75M15.93 13.6l-.01-.01c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06l-1.18 1.21-1.18-1.22-.01-.01c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06v-.01c-.76.79-.76 2.04-.01 2.84l3.51 3.63h0c.22.23.58.24.81.01 0-.01.01-.02.01-.02l3.51-3.64v-.01c.75-.8.75-2.05-.01-2.84z"/></g>'
  }
})
