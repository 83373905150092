/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rotate-back': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>left counter clockwise</desc><g _fill="currentColor"><path pid="0" d="M11.919 4a.243.243 0 01-.172-.414l1.879-1.879A1 1 0 1012.212.293l-4 4a1 1 0 000 1.416l4 4a1 1 0 101.414-1.414l-1.879-1.881A.243.243 0 0111.919 6a8 8 0 010 16 1 1 0 100 2 10 10 0 000-20zM8.827 21.381A8.008 8.008 0 017.1 20.39a1 1 0 00-1.206 1.6 10.036 10.036 0 002.156 1.24 1 1 0 00.773-1.845zM4.063 15.523a1 1 0 10-1.963.382 10.079 10.079 0 00.844 2.507 1 1 0 001.8-.883 7.961 7.961 0 01-.681-2.006zM4.438 8.891a1 1 0 00-1.352.415 10.019 10.019 0 00-.939 2.573A1 1 0 004.1 12.3a8.012 8.012 0 01.752-2.062 1 1 0 00-.414-1.347z"/></g>'
  }
})
