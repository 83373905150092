/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'technology-mobile-hashtag-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>trending pound</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="13.5" height="22.5" x="5.25" y=".75" rx="3"/><path pid="1" d="M5.25 18.75h13.5M21.75 10.5h1.5M21.75 7.5l1.5-.75M21.75 13.5l1.5.75M2.25 10.5H.75M2.25 7.5l-1.5-.75M2.25 13.5l-1.5.75M9.75 8.25H15M9 11.25h5.25M11.25 6.75l-1.5 6M14.25 6.75l-1.5 6"/></g>'
  }
})
