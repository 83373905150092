/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email-envelope-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>postal</desc><rect pid="0" x=".75" y="4.5" width="22.5" height="15" rx="1.5" ry="1.5" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.687 9.975L19.5 13.5M8.313 9.975L4.5 13.5M22.88 5.014l-9.513 6.56a2.406 2.406 0 01-2.734 0L1.12 5.014"/>'
  }
})
