<template>
    <div>
        <slick
            ref="carousel"
            :key="sliderKey"
            :options="carouselOptions"
            @beforeChange="onBeforeSlideChange"
        >
            <slot
                v-for="image in images"
                :id="image.id"
                name="image"
                :source="image.src"
            >
                <img
                    :key="image.id"
                    alt="Image"
                    :class="imageClass"
                    :src="image.src"
                >
            </slot>
        </slick>

        <template v-if="showManualNavigation">
            <a
                class="flex items-center text-snow-500 absolute inset-y-0 left-0 mt-16 ml-4 hover:text-snow-900"
                role="button"
                @click="previousSlide"
            >
                <app-icon
                    name="arrow-left-chevron"
                    class="h-8 w-8"
                    stroke
                ></app-icon>
            </a>

            <a
                class="flex items-center text-snow-500 absolute inset-y-0 right-0 mt-16 mr-4 hover:text-snow-900"
                role="button"
                @click="nextSlide"
            >
                <app-icon
                    name="arrow-right-chevron"
                    class="h-8 w-8"
                    stroke
                ></app-icon>
            </a>
        </template>
    </div>
</template>

<script>
import Slick from 'vue-slick';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';

export default {
    name: 'WallImageSliderBlock',

    components: { Slick },

    mixins: [GeneratesUniqueKey],

    props: {
        imageClass: {
            type: String,
            default: 'min-w-full min-h-full'
        },
        images: {
            type: Array,
            required: true
        },
        manualNavigation: {
            type: Boolean,
            required: true
        },
        transition: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            sliderKey: this.getUniqueKey()
        };
    },

    computed: {
        autoplaySpeed () {
            return this.isTransitionManualOnly ? null : this.transition.duration;
        },

        carouselOptions () {
            return {
                slidesToShow: 1,
                arrows: false,
                autoplay: true,
                autoplaySpeed: this.autoplaySpeed,
                pauseOnHover: false,
                fade: this.transition.name === 'fade'
            };
        },

        isTransitionManualOnly () {
            return this.transition.duration === 'manual-only';
        },

        showManualNavigation () {
            return this.manualNavigation && this.images.length > 1;
        }
    },

    watch: {
        images () {
            this.reinitializeSlider();
        },

        manualNavigation (newValue) {
            if (!newValue) {
                this.$refs.carousel.play();
            }
        },

        'transition.duration': function (newValue) {
            this.$refs.carousel.setOption('autoplaySpeed', newValue, true);
        },

        'transition.name': function () {
            this.reinitializeSlider();
        }
    },

    methods: {
        nextSlide () {
            this.$refs.carousel.next();
        },

        onBeforeSlideChange () {
            if (this.isTransitionManualOnly) {
                this.$refs.carousel.pause();
            }
        },

        previousSlide () {
            this.$refs.carousel.prev();
        },

        reinitializeSlider () {
            this.sliderKey = this.getUniqueKey();
        }
    }
};
</script>
