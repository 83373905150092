/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-glass-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.908 2.25h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M16.9 12h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38v-.001M14.658 15h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38v-.001M16.158 18.75h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M19.533.75h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38V.729"/><path pid="1" d="M19.64 21.875v-.01a1.51 1.51 0 01-1.5 1.37H9.63c-.79 0-1.44-.6-1.5-1.38L6.81 6.035h0c-.04-.42.27-.78.68-.81.01-.01.03-.01.05-.01h12.62c.41 0 .74.33.74.75-.01.02-.01.04-.01.06z"/><path pid="2" d="M3.033.75l3.78.47h0c1.31.16 2.36 1.17 2.58 2.48l2.63 15.79M7.16 9.75h13.5"/></g>'
  }
})
