<template>
    <div>
        <portal to="rsvp-form-target">
            <existing-submission-screen
                :existing-submission="existingSubmission"
                @close="existingSubmission = null"
                @submit-block="continueWithNewSubmission"
            ></existing-submission-screen>
        </portal>

        <div v-theme="['form.text']">
            <div
                v-if="!!chosenProducts"
                v-theme="'form.background'"
                class="px-4 py-8 w-full rounded-md relative transition-all"
                :class="{ 'cursor-pointer': !isActive }"
            >
                <slot v-if="!isEventWithInvitees" name="block-header"></slot>

                <div v-if="displayTimeslotOptions" class="flex flex-col items-center justify-center -mb-4">
                    <button class="flex items-center justify-center text-lg" @click="selectAnotherTimeslot">
                        <app-icon
                            name="arrow-left-chevron"
                            class="h-4 w-4 mr-2"
                        ></app-icon>
                        {{ $t('button-select-another-time') }}
                    </button>

                    <p class="text-sm mt-4">{{ formattedSelectedTimeslotDate }}</p>

                    <p>{{ formattedSelectedTimeslot }}</p>

                    <div v-if="event.timezone" class="flex items-center justify-center">
                        <app-icon
                            name="time-clock"
                            class="h-4 w-4 mr-2"
                            stroke
                        ></app-icon>
                        <p class="text-sm">{{ event.timezone.name.replace(/_/g, ' ') }}</p>
                    </div>
                </div>

                <div v-if="hasNoProducts">
                    <div v-theme="['form.accent']" class="p-6 flex flex-col items-center">
                        <app-icon
                            name="ticket"
                            class="h-10 w-10"
                            stroke
                        ></app-icon>
                        <div
                            v-if="isEditMode"
                            v-theme="'form.title-text'"
                            class="text-xl text-center mt-4"
                        >
                            This Ticketing Block is empty.
                        </div>
                        <div v-theme="'form.text'" class="text-center mt-4">
                            <div v-if="isEditMode">You have not added any products to the block yet.</div>
                            <span v-else>This event does not have any products available for sale yet.</span>
                        </div>
                    </div>
                </div>

                <template v-else>
                    <div
                        v-theme="['form.title-text', 'form.accent']"
                        class="text-center text-2xl mt-8 mb-4 border-b pb-4 font-semibold"
                    >
                        {{ block.pivot.settings.title }}
                    </div>

                    <div
                        v-if="block.pivot.settings.description && (event.published_at === null || new Date(event.published_at) > Date.UTC(2024, 0, 12, 19))"
                        v-theme="['form.text']"
                        class="mb-2 break-words"
                    >
                        {{ block.pivot.settings.description }}
                    </div>

                    <div v-if="loadingAvailableProductsForTimeslot" class="flex flex-col items-center justify-center">
                        <app-icon
                            v-theme="['form.text']"
                            name="loader"
                            class="w-12 h-12"
                        ></app-icon>
                    </div>

                    <form-field-wrapper :error="errorBag.get('payload.products')">
                        <div
                            v-if="hasNoAvailableProducts && !loadingAvailableProductsForTimeslot"
                            v-theme="'form.text'"
                            class="text-center mt-4"
                        >
                            There is no availability at this time. Please check back at a later date. <br><br>
                            Thank you for your patience.
                        </div>

                        <div
                            v-for="product in activeProducts"
                            :key="product.id"
                            class="mb-6"
                        >
                            <quantity-input
                                :value="getProductQuantity(product)"
                                :event="event"
                                :item="product"
                                :is-active="isActive"
                                :editable="isEditMode"
                                :disabled="declined"
                                :error="errorBag.get(`payload.products.${product.id}.quantity`)"
                                :disabled-increment="!isProductSelectable(product)"
                                @input="setProductQuantity(product, $event)"
                                @complete-block="completeBlock"
                            ></quantity-input>

                            <product-guests
                                v-model="chosenProducts[product.id].guests"
                                :quantity="getGuestCount(product)"
                                :require-guest-title="block.pivot.settings.guestTitles.enabled"
                                :product="product"
                                :error-bag="errorBag.only(`payload.products.${product.id}`)"
                            ></product-guests>
                        </div>

                        <!-- Show decline option as last, if active -->
                        <div v-if="shouldShowDecline" class="mb-6">
                            <div class="flex items-start">
                                <div v-theme="['form.accent']" class="flex">
                                    <app-icon
                                        v-if="declineable.settings.icon"
                                        :name="declineable.settings.icon"
                                        class="shrink-0 opacity-50 h-6 w-6 mr-4"
                                    ></app-icon>
                                    <div v-else class="h-6 w-6 mr-4"></div>
                                </div>

                                <div v-theme="['form.text']" class="flex-1">
                                    <div class="mb-1">
                                        <div class="font-semibold">
                                            {{ declineable.title }}
                                        </div>
                                    </div>

                                    <div class="opacity-75 text-sm break-word">
                                        {{ declineable.settings.description }}
                                    </div>
                                </div>

                                <div>
                                    <div
                                        role="button"
                                        class="flex items-start leading-normal"
                                        @click="declined = !declined"
                                    >
                                        <div
                                            v-theme="['form.accent']"
                                            class="rounded-md flex shrink-0 justify-center items-center h-8 w-8 mr-4 border-2"
                                        >
                                            <div
                                                v-if="declined"
                                                class="rounded-sm h-5 w-5 option-selected"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="declined" class="mt-4">
                                <guest-details
                                    v-model="decliningGuest"
                                    :error="errorBag.only('payload.decliningGuest').all()[0]"
                                    :require-name="declineable.settings.requireName.enabled"
                                    :require-title="block.pivot.settings.guestTitles.enabled"
                                    :require-email="true"
                                ></guest-details>
                            </div>
                        </div>
                    </form-field-wrapper>

                    <div v-if="errorBag.has('payload.products.quantity')" class="alert alert-error alert-sm mt-2">
                        <p class="w-full font-normal text-center">{{ errorBag.get('payload.products.quantity') }}</p>
                    </div>
                </template>
            </div>

            <div
                v-if="showPrimaryGuestDetailsBlock"
                v-theme="'form.background'"
                class="my-8 px-4 py-8 w-full rounded-md relative transition-all"
            >
                <guest-details
                    v-model="primaryGuest"
                    :error="errorBag.only('payload.primaryGuest').all()[0]"
                    :require-name="!hasGuestWithName"
                    :require-title="block.pivot.settings.guestTitles.enabled"
                    :require-email="!hasGuestWithEmail"
                ></guest-details>
            </div>

            <slot
                v-if="!hasNoAvailableProducts"
                name="block-footer"
                :complete-block="completeBlock"
            ></slot>
        </div>
    </div>
</template>

<script>
import filter from 'lodash/filter';
import find from 'lodash/find';
import reject from 'lodash/reject';
import pick from 'lodash/pick';
import chunk from 'lodash/chunk';
import getValue from 'lodash/get';
import { get } from 'vuex-pathify';
import axios from '@/util/axios';
import FormBlock from '@/mixins/FormBlock';
import BlockWithProducts from '@/mixins/BlockWithProducts';
import TranslateMonthName from '@/mixins/TranslateMonthName';

export default {
    name: 'OpenTicketingBlock',

    mixins: [FormBlock, BlockWithProducts, TranslateMonthName],

    data () {
        return {
            chosenProducts: null,
            declined: false,
            decliningGuest: {
                title_id: null,
                first_name: '',
                last_name: '',
                email: ''
            },
            existingSubmission: null,
            primaryGuest: {
                title_id: null,
                first_name: '',
                last_name: '',
                email: ''
            }
        };
    },

    computed: {
        chosenProductSettings () {
            return this.products.filter((product) => {
                return this.chosenProducts[product.id.toString()].quantity > 0;
            }).map((product) => {
                return product.settings;
            });
        },

        declineable () {
            return this.block.pivot.settings.declineable;
        },

        displayTimeslotOptions () {
            return this.event.settings.recurringTimeSlots.active && !!this.submission.selectedTimeSlot;
        },

        event: get('Event/event'),

        hasAtLeastOneChosenProduct () {
            return !!find(this.chosenProducts, ({ quantity }, id) => {
                const product = find(this.products, (product) => {
                    return product.id == id;
                });

                if (product == null) {
                    return quantity > 0;
                }

                return this.getGuestCount(product) > 0;
            });
        },

        hasNoAvailableProducts () {
            return this.activeProducts.length === 0 && !this.shouldShowDecline;
        },

        hasGuestWithName () {
            return !!this.chosenProductSettings.find((settings) => {
                return settings.requireName.enabled;
            });
        },

        hasGuestWithEmail () {
            return !!this.chosenProductSettings.find((settings) => {
                return settings.requireEmail.enabled;
            });
        },

        hasNoProducts () {
            return this.products.length === 0;
        },

        formattedSelectedTimeslot () {
            const timeFormat = getValue(this.event.settings, 'recurringTimeSlots.timeFormat');

            const format = timeFormat === '24-hours' ? 'H:mm' : 'h:mm a';

            return `${this.submission.selectedTimeSlot.from.toFormat(format)} ${this.$t('label-to')} ${this.submission.selectedTimeSlot.to.toFormat(format)}`;
        },

        formattedSelectedTimeslotDate () {
            return `${this.translatedMonthName(this.submission.selectedTimeSlot.from.month)} ${this.submission.selectedTimeSlot.from.toFormat('d, y')}`;
        },

        isInviteeFlow () {
            return this.event.invite_list.is_enabled;
        },

        isEventWithInvitees () {
            return this.event.has_invitees;
        },

        securityToken: get('Submission/securityToken'),

        shouldShowDecline () {
            if (this.displayTimeslotOptions) {
                return false;
            }

            if (this.submission.declined) {
                return true;
            }

            return this.declineable.active;
        },

        showPrimaryGuestDetailsBlock () {
            return this.hasAtLeastOneChosenProduct
                && (!this.hasGuestWithName || !this.hasGuestWithEmail);
        },

        totalQuantityOfChosenProducts () {
            return reject(this.products, { category: 'add-on' })
                .reduce((sum, product) => {
                    return sum + this.getProductGuestCount(product);
                }, 0);
        },

        totalQuantityOfChosenAttendingProducts () {
            return reject(this.products, (product) => {
                return product.category === 'add-on' || product.short_guest_state !== 'Attending';
            })
                .reduce((sum, product) => {
                    return sum + this.getProductGuestCount(product);
                }, 0);
        }
    },

    watch: {
        declined (newVal) {
            if (newVal) {
                this.clearChosenProducts();
            }
        },

        products () {
            this.clearChosenProducts();
        }
    },

    mounted () {
        this.clearChosenProducts();
        this.retrieveAvailableProductsForTimeslot();

        if (this.submission.original_submission_id && !this.submission.invitee_group) {
            this.initFromSubmission();
        }

        App.$on('prefill-guest-details', (guest) => {
            Object.assign(this.primaryGuest, {
                first_name: guest.first_name,
                last_name: guest.last_name,
                email: guest.email
            });
        });

        this.handlePrefillingLookedUpGuestDetailsForRTS();
    },

    methods: {
        async completeBlock () {
            if (this.hasNoAvailableProducts) {
                return;
            }

            if (this.isPreview || this.submission.original_submission_id) {
                return this.getCompletionObject();
            }

            // If this is a private event, then all lookups are done in the lookup block
            if (this.event.invite_list.is_enabled && this.event.invite_list.settings.security === 'private') {
                return this.getCompletionObject();
            }

            this.existingSubmission = await this.findExistingSubmission();

            if (this.existingSubmission == null) {
                return this.getCompletionObject();
            }

            return false;
        },

        async continueWithNewSubmission () {
            this.existingSubmission = null;

            this.$emit('complete-block', this.getCompletionObject());
        },

        async findExistingSubmission () {
            const email = this.getPrimaryGuestEmail();

            if (email == null) {
                return null;
            }

            try {
                const { data } = await axios.post(this.route('api.events.submissions.lookup', this.event), {
                    email,
                    securityToken: this.securityToken
                });

                return data.data;
            } catch (error) {
                return null;
            }
        },

        getGuestCount (product) {
            const minQuantity = product.settings.minimumRequiredQuantity || 0;
            const { has_limited_availability, quantity_remaining } = product;

            // Guest fields should not show up at all if the minimum quantity is unavailable due to limited capacity
            if (has_limited_availability && quantity_remaining < minQuantity) {
                return 0;
            }

            return this.getProductQuantity(product);
        },

        getPrimaryGuestEmail () {
            if (this.declined) {
                return this.decliningGuest.email || this.primaryGuest.email;
            }

            for (const { category, id } of this.products) {
                const emailPath = category === 'individual'
                    ? `${id}.guests.0.email`
                    : `${id}.guests.0.0.email`;

                const email = getValue(this.chosenProducts, emailPath);

                if (email != null && email !== '') {
                    return email;
                }
            }

            return this.primaryGuest.email;
        },

        getProductQuantity (product) {
            return this.chosenProducts[product.id].quantity;
        },

        getProductGuestCount (product) {
            const guestsPerOrder = product.category === 'group' ? product.settings.guestsPerOrder : 1;
            const quantity = this.chosenProducts[product.id].quantity * guestsPerOrder;

            return quantity;
        },

        handlePrefillingLookedUpGuestDetailsForRTS () {
            if (this.submission.selectedTimeSlot && this.submission.lookedUpGuest) {
                Object.assign(this.primaryGuest, this.submission.lookedUpGuest);
            }
        },

        initAddonsFromSubmission () {
            filter(this.activeProducts, { category: 'add-on' }).forEach(({ id }) => {
                filter(this.submission.addons, { orderable_id: id }).forEach(({ orderable_id, quantity }) => {
                    this.chosenProducts[orderable_id].quantity = quantity;
                });
            });
        },

        initDeclinedStateFromSubmission () {
            // RTS declined are handled differently using the FormRecurringTimeSlotsBlock.
            // Declined should always be false if RTS is using this component.
            if (this.displayTimeslotOptions) {
                this.declined = false;
                return;
            }

            this.declined = this.submission.declined && this.$options.name.includes('Ticketing');

            this.decliningGuest = pick(
                this.submission.guests[0],
                ['title_id', 'first_name', 'last_name', 'email']
            );
        },

        initFromSubmission () {
            this.initAddonsFromSubmission();
            this.initProductChoicesFromSubmission();
            this.initDeclinedStateFromSubmission();
            this.initPrimaryGuestFromSubmission();
        },

        initPrimaryGuestFromSubmission () {
            const firstGuest = this.submission.guests[0];

            if (!this.hasGuestWithName) {
                this.primaryGuest = {
                    ...this.primaryGuest,
                    ...pick(firstGuest, ['title_id', 'first_name', 'last_name'])
                };

                firstGuest.title_id = null;
                firstGuest.first_name = '';
                firstGuest.last_name = '';
            }

            if (!this.hasGuestWithEmail) {
                this.primaryGuest.email = firstGuest.email;
                firstGuest.email = '';
            }
        },

        initProductChoicesFromSubmission () {
            reject(this.activeProducts, { category: 'add-on' }).forEach(({ id, guests_per_order }) => {
                const guests = this.submission.guests.filter(({ products }) => {
                    return find(products, { id }) != null;
                });

                this.chosenProducts[id] = {
                    quantity: guests.length / guests_per_order,
                    guests: guests_per_order > 1 ? chunk(guests, guests_per_order) : guests
                };
            });
        },

        prefillLookedUpGuestDetails (product, quantity) {
            if (this.primaryGuest && product.settings.requireName.enabled && product.category === 'individual') {
                this.$set(this.chosenProducts, product.id, {
                    guests: [this.primaryGuest],
                    quantity
                });
            }
        },

        isProductSelectable (product) {
            if (product.category === 'add-on') {
                return true;
            }

            if (this.event.settings.maxCapacity.enabled && this.totalQuantityOfChosenAttendingProducts >= this.event.maxCapacityAvailableGuests) {
                return false;
            }

            if (this.timeslotTotalRemainingQuantity) {
                return this.totalQuantityOfChosenProducts + product.guests_per_order <= this.timeslotTotalRemainingQuantity;
            }

            if (!this.block.pivot.settings.limitedAttendancePerGroup.enabled) {
                return true;
            }

            return this.totalQuantityOfChosenProducts + product.guests_per_order <= this.block.pivot.settings.limitedAttendancePerGroup.limit;
        },

        retrieveAvailableProductsForTimeslot () {
            if (!this.submission.selectedTimeSlot || !this.event.settings.recurringTimeSlots.active) {
                return;
            }

            this.loadingAvailableProductsForTimeslot = true;

            axios.post(this.route('api.events.timeslots.available-products', this.event), {
                timeslotStartTime: this.submission.selectedTimeSlot.from
            }).then(({ data: { products, totalRemainingQuantity } }) => {
                this.availableProductsForTimeslot = products;
                this.timeslotTotalRemainingQuantity = totalRemainingQuantity;
            }).catch(() => {
                this.$toasted.global.error('There was an error retrieving available products for this timeslot.');
            }).finally(() => {
                this.loadingAvailableProductsForTimeslot = false;
            });
        },

        serializePayload () {
            return {
                declined: this.declined,
                decliningGuest: this.decliningGuest,
                primaryGuest: this.primaryGuest,
                products: this.chosenProducts,
                selectedTimeSlot: this.submission.selectedTimeSlot
            };
        },

        selectAnotherTimeslot () {
            this.submission.selectedTimeSlot = null;
        },

        setProductQuantity (product, quantity) {
            /**
             * We want to prefill the primary guest information that was populated
             * on the lookup field only if no products have been chosen yet on the
             * selected product.
             */
            if (this.totalQuantityOfChosenProducts === 0) {
                this.prefillLookedUpGuestDetails(product, quantity);
            }

            this.chosenProducts[product.id].quantity = quantity;
        },

        clearChosenProducts () {
            this.chosenProducts = {};

            this.products.forEach((product) => {
                this.$set(this.chosenProducts, product.id, {
                    quantity: 0,
                    guests: []
                });
            });
        }
    }
};
</script>
