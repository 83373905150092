/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'microphone-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18 .75a5.25 5.25 0 100 10.5 5.25 5.25 0 100-10.5zM13.23 1.23l9.54 9.54"/><path pid="1" d="M17.941 11.25L7.31 20.07v-.01a1.5 1.5 0 01-2-.11l-1.28-1.28h0a1.49 1.49 0 01-.11-2l8.82-10.64"/><path pid="2" d="M12.6 11.568l-.01-.01c.14.14.14.38 0 .53a.4.4 0 01-.54 0 .39.39 0 01-.01-.54v-.01h-.01c.14-.15.38-.15.53-.01M4.67 19.33L.75 23.25"/></g>'
  }
})
