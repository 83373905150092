/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camping-tent-forest-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25.75a6 6 0 100 12 6 6 0 100-12zM17.25 16.5V5.25M17.25 10.5c0-2.25 1.5-3 2.25-3M17.25 10.5c0-2.25-1.5-3-2.25-3M9 9.75L.87 22.084H.86c-.23.34-.14.81.2 1.04.12.08.26.12.41.12h12v-.001c.41-.01.74-.34.74-.76a.865.865 0 00-.13-.42L5.95 9.72M13.5 16.5h9.75M7.5 12v11.25"/></g>'
  }
})
