<template>
    <form-field-wrapper>
        <div
            slot="label"
            v-tippy
            class="tooltip-text"
            content="Choose to allow links from only certain websites (separate by comma). Leave blank to allow any website. Do not include https:// or www."
        >
            Allowed Websites
        </div>

        <input
            name="allowed websites"
            class="form-field"
            type="text"
            :value="initialValue"
            @input="update"
        >
    </form-field-wrapper>
</template>

<script>
import CustomQuestionSettings from '@/mixins/CustomQuestionSettings';

export default {
    name: 'FormSocialProfileQuestionSettings',

    mixins: [CustomQuestionSettings],

    data () {
        return {
            initialValue: ''
        };
    },

    computed: {
        namespace () {
            return 'socialProfile';
        }
    },

    mounted () {
        this.initialValue = this.settings && this.settings.allowedWebsites
            ? this.settings.allowedWebsites
            : '';
    },

    methods: {
        update ({ target }) {
            this.updateSetting('allowedWebsites', target.value);
        }
    }
};
</script>
