<template>
    <div class="flex-1 p-8">
        <section class="panel flex flex-col">
            <page-header icon="users-stroke" label="Manage Users">
                <a :href="route('admin.dashboard')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <infinite-data-table
                v-show="data.userCount"
                ref="table"
                class="-mx-8 md:mx-0 text-gray-600"
                clickable-rows
                :columns="columns"
                :filter="filter"
                :mapper="mapResource"
                no-results-placeholder="Sorry, we couldn't find any users with that name."
                remote-sort
                :sort="sort"
                :source="dataSource"
                with-preflight
                @change-sort="handleChangedSort"
                @click-row="userClicked"
                @received-response="onReceivedResponse"
            >
                <template #header>
                    <div class="flex-auto">
                        <search-field
                            v-if="data.userCount"
                            v-model="filter.term"
                            class="w-full md:w-80"
                            placeholder="Find a user by email address..."
                        ></search-field>
                    </div>

                    <div class="flex justify-end flex-auto ml-4">
                        <button
                            class="flex items-center justify-center bg-white rounded-md border p-2 mr-2 disabled:opacity-50 text-gray-600 hover:border-purple hover:cursor-pointer hover:text-purple"
                            @click="showDefaultApplicationFeeModal"
                        >
                            <app-icon
                                name="settings-slider"
                                class="w-6 h-6 mr-2"
                                stroke
                            ></app-icon>
                            Edit Defaults
                        </button>

                        <button
                            v-if="data.userCount"
                            v-tippy="{ placement: 'top-start' }"
                            content="Reset filter settings."
                            class="bg-white rounded-md border p-2 mr-2 disabled:opacity-50"
                            :class="resetButtonStyles"
                            :disabled="inDefaultView"
                            @click="reset"
                        >
                            <app-icon
                                name="team"
                                class="w-6 h-6"
                                stroke
                            ></app-icon>
                        </button>
                    </div>
                </template>
            </infinite-data-table>
        </section>

        <manage-user-modal
            v-model="manageUserModalVisible"
            :feature-flags="featureFlags"
            :user="selectedUser"
            :plans="plans"
            :plan-segments="planSegments"
            @updated="reloadData"
        ></manage-user-modal>

        <default-application-settings-modal
            v-model="showDefaultApplicationSettingsModal"
            :settings="defaultFeeSettings"
            @saved-default-fee-settings="reloadData"
        ></default-application-settings-modal>
    </div>
</template>

<script>
import { DateTime } from 'luxon';
import getValue from 'lodash/get';
import FormatNumbers from '@/mixins/FormatNumbers';

export default {
    name: 'ManageUsers',

    mixins: [FormatNumbers],

    props: {
        defaultFeeSettings: {
            type: Object,
            required: true
        },

        featureFlags: {
            type: Array,
            required: true
        },

        plans: {
            type: Array,
            required: true
        },

        planSegments: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            columns: [
                {
                    label: 'User Name',
                    property: 'name',
                    desktop: true,
                    sortable: true
                },
                {
                    label: 'Email',
                    property: 'email',
                    desktop: true,
                    sortable: true
                },
                {
                    label: 'Signed Up',
                    property: 'created_at',
                    desktop: true,
                    sortable: true,
                    render: this.formatDate
                },
                {
                    label: 'Last Seen',
                    property: 'last_login_at',
                    desktop: true,
                    sortable: true,
                    render: this.formatDate
                },
                {
                    label: 'Fees',
                    property: 'fees',
                    desktop: true,
                    sortable: false
                }
            ],
            data: {
                userCount: 0
            },
            filter: {
                term: ''
            },
            sort: {},
            selectedUser: {},
            manageUserModalVisible: false,
            showDefaultApplicationSettingsModal: false
        };
    },

    computed: {
        dataSource () {
            return this.route('api.admin.users.index');
        },

        inDefaultView () {
            return this.filter.term === '' && !this.sorted;
        },

        resetButtonStyles () {
            return {
                'hover:border-purple hover:cursor-pointer text-gray-600 hover:text-purple': !this.inDefaultView
            };
        },

        sorted () {
            return !!this.sort.property;
        }
    },

    methods: {
        formatDate (dateStr) {
            if (!dateStr) {
                return '';
            }

            const now = DateTime.local();
            const date = DateTime.fromISO(dateStr).setZone('UTC').toLocal();

            return date.hasSame(now, 'day')
                ? `${date.toFormat('LLLL d, yyyy')} at ${date.toFormat('h:mm a')}`
                : date.toFormat('LLLL d, yyyy');
        },

        formatFixedFee (value) {
            return this.formatCurrency(value || 0, true);
        },

        formatPercentageFee (value) {
            return value
                ? `${this.formatNumber(value, '0.00')}%`
                : '0.00%';
        },

        handleChangedSort ([newSort]) {
            this.sort = newSort || {};
        },

        getUserFeesSummary (user) {
            return getValue(user, 'fees.hasCustomFees', false)
                ? `$${getValue(user, 'fees.fixedFormatted', 0)} + ${this.formatPercentageFee(getValue(user, 'fees.percentage', 0))}`
                : 'Default';
        },

        mapResource (resource) {
            return {
                id: getValue(resource, 'id'),
                user_id: getValue(resource, 'id'),
                name: getValue(resource, 'name'),
                email: getValue(resource, 'email'),
                additional_email_credits: getValue(resource, 'additional_email_credits'),
                last_login_at: getValue(resource, 'last_login_at') || getValue(resource, 'updated_at'),
                updated_at: getValue(resource, 'updated_at'),
                created_at: getValue(resource, 'created_at'),
                fixed: getValue(resource, 'fees.fixedFormatted'),
                percentage: getValue(resource, 'fees.percentage'),
                fees: this.getUserFeesSummary(resource),
                is_verified: getValue(resource, 'is_verified'),
                email_verified_at: getValue(resource, 'email_verified_at'),
                account_status: getValue(resource, 'account_status'),
                plan_override: getValue(resource, 'plan_override'),
                plan_override_renews: getValue(resource, 'plan_override_renews'),
                plan_override_ends_at: getValue(resource, 'plan_override_ends_at'),
                plan_id: getValue(resource, 'plan_id'),
                feature_flag_overrides: getValue(resource, 'feature_flag_overrides'),
                credits_period_end: getValue(resource, 'credits_period_end'),
                settings: getValue(resource, 'settings'),
                user_segment_id: getValue(resource, 'user_segment_id'),
                deleted_at: getValue(resource, 'deleted_at')
            };
        },

        onReceivedResponse (response) {
            this.data.userCount = response.data.userCount;
        },

        reloadData () {
            this.$refs.table.reload();
        },

        reset () {
            if (this.inDefaultView) {
                return;
            }

            this.filter.term = '';
            this.sort = {};

            this.$refs.table.clearSorts();
        },

        showDefaultApplicationFeeModal () {
            this.showDefaultApplicationSettingsModal = true;
        },

        userClicked (user) {
            this.$set(this, 'selectedUser', user);

            this.manageUserModalVisible = true;
        }
    }
};
</script>
