<template>
    <section class="panel flex flex-col">
        <page-header icon="calendar-clock-stroke" label="Event Details">
            <button class="button-icon button-sm" @click="closePage">
                <app-icon name="close"></app-icon>
            </button>
        </page-header>

        <item-modified-warning v-if="form.isModified()"></item-modified-warning>

        <div class="w-full md:w-1/2">
            <form-field-wrapper
                label="Event Name"
                :error="form.errors.get('name')"
                :should-show-error="form.errors.has('name')"
            >
                <input
                    v-model="form.name"
                    class="form-field"
                    placeholder="Type your event name"
                >
            </form-field-wrapper>

            <form-field-wrapper
                label="RSVPify Event URL"
                :error="form.errors.get('subdomain')"
                :should-show-error="form.errors.has('subdomain')"
            >
                <event-subdomain v-model="form.subdomain"></event-subdomain>
            </form-field-wrapper>

            <div
                v-tippy
                :content="timeOptionsTooltip"
                class="mt-8 outline-none"
            >
                <form-field-wrapper
                    :error="form.errors.getAny(['starts_at', 'ends_at'])"
                    :should-show-error="form.errors.hasAny(['starts_at', 'ends_at'])"
                >
                    <template slot="label">
                        Time

                        <toggle-switch
                            v-model="timeTBD"
                            label-position="left"
                            class="font-normal normal-case"
                            small
                            :disabled="disableDateTimeOptions"
                        >
                            Time TBD
                        </toggle-switch>
                    </template>

                    <div v-if="showDateTimePickers">
                        <div class="row">
                            <div class="col-12">
                                <date-time-picker
                                    v-model="form.starts_at"
                                    label="Start"
                                    placeholder="Start Date"
                                    title="Event Start Date"
                                    has-time
                                    :disabled="disableDateTimeOptions"
                                ></date-time-picker>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <date-time-picker
                                    v-model="form.ends_at"
                                    label="End"
                                    placeholder="End Date"
                                    title="Event End Date"
                                    has-time
                                    :disabled="disableDateTimeOptions"
                                ></date-time-picker>
                            </div>
                        </div>
                    </div>
                </form-field-wrapper>

                <form-field-wrapper
                    v-if="!timeTBD"
                    label="Event's Timezone"
                    class="md:w-3/4"
                    :error="form.errors.get('timezone_id')"
                    :should-show-error="form.errors.has('timezone_id')"
                >
                    <timezone-picker v-model="form.timezone_id" :disabled="disableDateTimeOptions"></timezone-picker>
                </form-field-wrapper>
            </div>
        </div>

        <div class="flex mt-6 justify-end">
            <button
                v-if="form.isModified()"
                class="button flex-1 md:flex-none mr-4"
                @click="restoreEvent"
            >
                Cancel
            </button>

            <stateful-button
                class="button-primary flex-1 md:flex-none"
                :disabled="!form.isModified()"
                :loading="form.processing"
                @click="updateEvent"
            >
                Save
            </stateful-button>
        </div>
    </section>
</template>

<script>
import { sync } from 'vuex-pathify';
import { DateTime } from 'luxon';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import Form from '@/validation/Form';

export default {
    name: 'EventDetails',

    data () {
        return {
            timeTBD: false,
            form: null
        };
    },

    watch: {
        timeTBD (newValue) {
            if (newValue) {
                this.form.starts_at = null;
                this.form.ends_at = null;
            }
        }
    },

    computed: {
        ...sync('Event/*'),

        disableDateTimeOptions () {
            return this.event.settings.recurringTimeSlots.active;
        },

        showDateTimePickers () {
            return !this.timeTBD;
        },

        timeOptionsTooltip () {
            return this.disableDateTimeOptions ? 'You\'ve enabled Recurring Time Slots. Each available time slot will be assigned a date and time automatically.' : '';
        },

        formData () {
            const dateTimeFields = ['starts_at', 'ends_at', 'timezone_id'];

            if (intersection(dateTimeFields, this.form.dirtyFields()).length !== 0) {
                const missingDateTimeFields = difference(dateTimeFields, this.form.dirtyFields());

                return this.form.dirtyFields().concat(missingDateTimeFields);
            }

            return this.form.dirtyFields();
        }
    },

    created () {
        this.timeTBD = !this.event.starts_at;

        this.form = new Form({
            name: this.event.name,
            subdomain: this.event.subdomain,
            starts_at: this.event.starts_at ? this.event.starts_at : null,
            ends_at: this.event.ends_at ? this.event.ends_at : null,
            timezone_id: this.event.timezone_id
        });
    },

    methods: {
        restoreEvent () {
            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            const onConfirm = () => {
                this.form.restore();
            };

            App.alert().confirm(
                'Are you sure?',
                'Are you sure you want to discard all your changes?',
                attributes,
                onConfirm
            );
        },

        updateEvent () {
            this.form
                .patch(this.route('api.events.update', this.event), this.formData)
                .then(() => {
                    this.$toasted.global.success({
                        message: 'Event details have been updated.',
                        onComplete: () => {
                            window.location.reload();
                        }
                    });
                });
        },

        closePage () {
            const backToSettingsIndexPage = () => {
                window.location.href = this.route('settings.event', this.event);
            };

            if (this.form.isModified()) {
                const attributes = {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                };

                App.alert().confirm(
                    'Are you sure?',
                    'Are you sure you want to discard all your changes and go back to settings page?',
                    attributes,
                    backToSettingsIndexPage
                );
            } else {
                backToSettingsIndexPage();
            }
        }
    }
};
</script>
