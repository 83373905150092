<template>
    <div>
        <cancel-plan-overview
            v-if="state === 'overview'"
            :current-plan="currentPlan"
            :current-plan-segment="currentPlanSegment"
            :downgrade-plan="downgradePlan"
            :user-segment="userSegment"
            @submit="state = 'confirm'"
        ></cancel-plan-overview>

        <cancel-plan-confirm
            v-if="state === 'confirm'"
            :cancel-reasons="cancelReasons"
            @submit="state = 'confirmation'"
        ></cancel-plan-confirm>

        <cancel-plan-confirmation
            v-if="state === 'confirmation'"
            :expiry-date="expiryDate"
            :plan="currentPlan"
        ></cancel-plan-confirmation>
    </div>
</template>

<script>
export default {
    name: 'CancelPlan',

    props: {
        cancelReasons: {
            type: Array,
            required: true
        },

        currentPlan: {
            type: Object,
            required: true
        },

        currentPlanSegment: {
            type: Object,
            required: true
        },

        downgradePlan: {
            type: Object,
            default: null
        },

        expiryDate: {
            type: String,
            required: true
        },

        userSegment: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            state: 'overview'
        };
    }
};
</script>
