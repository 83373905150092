/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email-warning-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>envelope postal alert</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 20.25h0M17.25 20.25h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375M17.25 18v-3"/><path pid="1" d="M23.063 20.682l-.01-.01c.43.87.08 1.94-.8 2.38-.25.12-.52.18-.8.18h-8.46v-.001c-.98 0-1.78-.8-1.78-1.78-.01-.28.06-.55.18-.8l4.22-8.451v-.01a1.76 1.76 0 012.37-.8c.34.17.62.45.79.79z"/><path pid="2" d="M9.75 15.75h-7.5l-.01-.001a1.51 1.51 0 01-1.5-1.5V2.238c0-.83.67-1.5 1.5-1.5h18-.01c.82-.01 1.5.67 1.5 1.5 0 0 0 0 0 0v8.25"/><path pid="3" d="M21.411 1.3l-8.15 6.26v-.01a3.33 3.33 0 01-4.04 0L1.07 1.28"/></g>'
  }
})
