/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'users-check-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>multiple tick yes ok people group party</desc><g _fill="currentColor"><circle pid="0" cx="14.5" cy="3.5" r="3"/><path pid="1" d="M11.933 11.261a.25.25 0 00.392.092A7.948 7.948 0 0117.5 9.438h.2a.25.25 0 00.2-.416 4.552 4.552 0 00-7 .235.25.25 0 00.005.311 7.015 7.015 0 011.028 1.693zM9.5 17.438a7.946 7.946 0 011.336-4.418.251.251 0 00.036-.192A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.571a.248.248 0 00.182-.079.246.246 0 00.067-.186c-.005-.099-.01-.198-.01-.297z"/><circle pid="2" cx="5.5" cy="3.5" r="3.5"/><path pid="3" d="M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.706 5.181l-3.148 4.2a1.809 1.809 0 01-1.323.721h-.131a1.808 1.808 0 01-1.281-.534L13.7 18.945a1 1 0 111.414-1.414l1.27 1.269a.25.25 0 00.376-.027l2.846-3.795a1 1 0 011.6 1.2z"/><circle pid="4" cx="14.5" cy="3.5" r="3"/><path pid="5" d="M11.933 11.261a.25.25 0 00.392.092A7.948 7.948 0 0117.5 9.438h.2a.25.25 0 00.2-.416 4.552 4.552 0 00-7 .235.25.25 0 00.005.311 7.015 7.015 0 011.028 1.693zM9.5 17.438a7.946 7.946 0 011.336-4.418.251.251 0 00.036-.192A5.5 5.5 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.571a.248.248 0 00.182-.079.246.246 0 00.067-.186c-.005-.099-.01-.198-.01-.297z"/><circle pid="6" cx="5.5" cy="3.5" r="3.5"/><path pid="7" d="M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.706 5.181l-3.148 4.2a1.809 1.809 0 01-1.323.721h-.131a1.808 1.808 0 01-1.281-.534L13.7 18.945a1 1 0 111.414-1.414l1.27 1.269a.25.25 0 00.376-.027l2.846-3.795a1 1 0 011.6 1.2z"/></g>'
  }
})
