/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'light-bulb-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>idea light led</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.5 22.5h3M18 12v-.01a6.013 6.013 0 00-5.98-6.03c-3.32-.02-6.01 2.66-6.03 5.97a6.004 6.004 0 004.49 5.83v1.71h3v-1.73a5.98 5.98 0 004.49-5.79zM12 3V1.5M21 10.5h1.5M1.5 12H3M18.36 17.61l1.06 1.06M4.57 3.83l1.07 1.06M18.36 4.89l1.06-1.06M4.57 18.67l1.07-1.06"/></g>'
  }
})
