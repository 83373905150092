/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'table-download-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc/><g _fill="currentColor"><path pid="0" d="M8 19a.25.25 0 01-.25-.25V15a.25.25 0 01.25-.25h1.825a.251.251 0 00.233-.159c.13-.333.283-.657.456-.97a.247.247 0 000-.248.251.251 0 00-.215-.123H8a.25.25 0 01-.25-.25V8A.25.25 0 018 7.75h4a.25.25 0 01.25.25v2.95a.25.25 0 00.4.2c.307-.237.632-.451.97-.641a.249.249 0 00.128-.218V8A.25.25 0 0114 7.75h3.75A.25.25 0 0118 8v1.294c0 .13.1.237.228.249.492.043.979.132 1.454.265a.25.25 0 00.318-.24V3a3 3 0 00-3-3H3a3 3 0 00-3 3v15a3 3 0 003 3h6.927a.25.25 0 00.229-.349 7.875 7.875 0 01-.47-1.455.25.25 0 00-.244-.2zm-1.75-6a.25.25 0 01-.25.25H2.25A.25.25 0 012 13V8a.25.25 0 01.25-.25H6a.25.25 0 01.25.25zM8 6.25A.25.25 0 017.75 6V2.25A.25.25 0 018 2h4a.25.25 0 01.25.25V6a.25.25 0 01-.25.25zM18 3v3a.25.25 0 01-.25.25H14a.25.25 0 01-.25-.25V2.25A.25.25 0 0114 2h3a1 1 0 011 1zM3 2h3a.25.25 0 01.25.25V6a.25.25 0 01-.25.25H2.25A.25.25 0 012 6V3a1 1 0 011-1zM2 18v-3a.25.25 0 01.25-.25H6a.25.25 0 01.25.25v3.75A.25.25 0 016 19H3a1 1 0 01-1-1z"/><path pid="1" d="M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-.391 9.812l-2-2.5a.5.5 0 01.391-.812h1a.25.25 0 00.25-.25V14.5a.75.75 0 111.5 0v2.75c0 .138.112.25.25.25h1a.5.5 0 01.391.812l-2 2.5a.5.5 0 01-.782 0z"/></g>'
  }
})
