<template>
    <div id="section_header" class="flex flex-col space-y-4">
        <h1
            v-if="title"
            :id="`text_title_${step}_${stepOrder}`"
            class="text-3xl font-semibold text-center"
        >
            {{ title }}
        </h1>

        <h2
            v-if="subtitle"
            :id="`text_subtitle_${step}_${stepOrder}`"
            class="text-[15px] text-center text-gray-500"
        >
            {{ subtitle }}
        </h2>
    </div>
</template>

<script>
export default {
    name: 'CreateEventHeader',

    props: {
        title: {
            type: String,
            default: null
        },
        step: {
            type: String,
            required: true
        },
        stepOrder: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            default: null
        }
    }
};
</script>
