<template>
    <app-modal
        :value="value"
        title="Update Stripe Fees"
        @input="$emit('input', $event)"
    >
        <form-field-wrapper
            label="Fixed Fee"
            :error="form.errors.get('fee_fixed')"
        >
            <input
                v-model.number="form.fee_fixed"
                type="number"
                class="form-field"
                min="0"
                step="0.01"
            >
        </form-field-wrapper>

        <form-field-wrapper
            label="Percentage Fee"
            :error="form.errors.get('fee_percentage')"
        >
            <input
                v-model.number="form.fee_percentage"
                type="number"
                class="form-field"
                min="0"
                max="100"
                step="0.01"
            >
        </form-field-wrapper>

        <template #footer="{ close }">
            <stateful-button
                class="button-primary"
                :loading="form.processing"
                @click="update(close)"
            >
                Update
            </stateful-button>

            <button class="button" @click="close">Cancel</button>
        </template>
    </app-modal>
</template>

<script>
import Form from '@/validation/Form';

export default {
    name: 'UpdateStripeFeesModal',

    props: {
        initialStripeFees: {
            type: Object,
            default: () => { return {}; }
        },
        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            form: new Form({
                fee_fixed: null,
                fee_percentage: null
            })
        };
    },

    watch: {
        value () {
            this.loadInitialFormValues();
        }
    },

    methods: {
        loadInitialFormValues () {
            this.form.fee_fixed = this.initialStripeFees.fee_fixed;
            this.form.fee_percentage = this.initialStripeFees.fee_percentage;
        },

        update (closeFunction) {
            this.form.put(this.route('api.admin.stripe-fees.update', this.initialStripeFees))
                .then(({ data }) => {
                    this.$toasted.global.success('Stripe fees have been updated.');

                    this.$emit('update-stripe-fees', data);

                    closeFunction();
                });
        }
    }
};
</script>
