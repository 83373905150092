<template>
    <div>
        <portal v-if="isActive" to="rsvp-form-target">
            <existing-submission-screen
                :closable="!isPrivateEvent"
                :existing-submission="existingSubmission"
                @close="existingSubmission = null"
                @submit-block="continueWithNewSubmission"
            ></existing-submission-screen>
        </portal>

        <div v-theme="['form.text']">
            <div
                v-theme="'form.background'"
                class="px-4 py-8 w-full rounded-md relative transition-all"
                :class="{ 'cursor-pointer': !isActive }"
            >
                <slot name="block-header"></slot>

                <div v-if="showLookupForm">
                    <guest-details
                        :value="form"
                        :require-name="showNameInput"
                        :require-email="showEmailInput"
                        :require-title="false"
                        :error="form.errors.getAny(['first_name', 'last_name', 'email'])"
                        @input="updateGuest"
                    >
                    </guest-details>

                    <div v-if="displayNoInviteesFoundError" class="alert alert-error alert-sm mt-2">
                        <p class="w-full font-normal text-center">
                            {{ $t('text-invitation-not-found') }}
                        </p>
                    </div>
                </div>

                <div v-else-if="showLiveModeBlocker">
                    <p class="w-full font-normal text-center">
                        This invitee already has a “live” registration associated with it. If you’d like to re-test your registration experience, please add a new invitee to your Invite List for testing purposes.
                    </p>
                </div>

                <div v-else>
                    <div>
                        <div
                            v-for="group in inviteeGroups"
                            :key="group.uuid"
                            v-theme="'form.accent'"
                            class="flex items-center py-6 border-b"
                        >
                            <div v-theme="'form.text'" class="grow px-2">
                                <div
                                    v-for="invitee in group.invitees"
                                    :key="invitee.id"
                                    class="mb-2"
                                >
                                    <div class="font-semibold">
                                        <span v-if="invitee.firstName && invitee.lastName">
                                            {{ useFormalName ? invitee.formalName : invitee.fullName }}
                                        </span>
                                        <span v-else-if="invitee.email">
                                            {{ invitee.email }}
                                        </span>
                                    </div>
                                    <div v-if="invitee.additionalGuestsLimit !== 0" class="font-thin">
                                        <span v-if="invitee.additionalGuestsLimit === 1">
                                            {{ $t('text-invitee-and-guest') }}
                                        </span>
                                        <span v-else>
                                            {{ $t('text-invitee-and-guests') }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center">
                                <button
                                    v-theme="'form.accented-text'"
                                    class="px-2 py-1 border rounded-md button-text text-sm font-normal"
                                    :disabled="loadingGroupSelection === group.id"
                                    @click="selectInviteeGroup(group)"
                                >
                                    <template v-if="loadingGroupSelection === group.id">
                                        <app-icon name="loader" class="w-4 h-4"></app-icon>
                                    </template>
                                    <template v-else>
                                        <app-icon
                                            v-if="isGroupSelected(group)"
                                            name="check-circle"
                                            class="h-4 w-4 mr-2"
                                        ></app-icon>

                                        <span>{{ $t('button-this-is-me') }}</span>
                                    </template>
                                </button>
                            </div>
                        </div>

                        <div v-theme="'form.accent'" class="flex justify-center mt-6">
                            <button
                                v-if="showNoneOfTheseButton"
                                v-theme="'form.accented-text'"
                                class="px-2 py-1 border rounded-md button-text text-sm font-normal"
                                @click="selectNoneOfThese"
                            >
                                {{ $t('button-none-of-these') }}
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="showLookupForm" class="absolute mx-auto left-0 right-0 bottom-0 mx-auto mb-5">
                    <rsvp-next-button
                        v-if="isActive"
                        class="-mb-10"
                        :loading="loading"
                        @click="lookup"
                    >
                        <template v-if="!loading">
                            <app-icon
                                class="mx-2 h-6 w-6 fill-current"
                                name="search"
                            ></app-icon>

                            <div class="mx-2 text-left text-sm">
                                <div>{{ $t('button-look-up') }}</div>
                            </div>
                        </template>
                    </rsvp-next-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getValue from 'lodash/get';
import { get, sync } from 'vuex-pathify';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import axios from '@/util/axios';
import FormBlock from '@/mixins/FormBlock';
import Form from '@/validation/Form';

export default {
    name: 'FormLookupBlock',

    mixins: [FormBlock],

    behaviour: {
        render: {
            when (block, submission, event) {
                return event.has_invitees;
            },
            unless (block, submission, event) {
                return !!submission.original_submission;
            }
        }
    },

    data () {
        return {
            existingSubmission: null,
            form: new Form({
                first_name: '',
                last_name: '',
                email: '',
                securityToken: (new URLSearchParams(window.location.search)).get('securityToken')
            }),
            inviteeGroups: null,
            loading: false,
            loadingGroupSelection: null,
            selectedGroup: null,
            skipLookupBlock: false,
            showLiveModeBlocker: false,
            preselectedGroup: null
        };
    },

    computed: {
        blocks: get('Form/form@blocks'),

        event: get('Event/event'),

        inviteeLookUpDetails: sync('Submission/inviteeLookUpDetails'),

        preselectedReply: sync('Submission/preselectedReply'),

        enteredPasscode: sync('Submission/enteredPasscode'),

        securityToken: get('Submission/securityToken'),

        displayNoInviteesFoundError () {
            return this.noInviteesFound && this.isPrivateEvent;
        },

        enteredFullName () {
            return `${this.form.first_name} ${this.form.last_name}`;
        },

        isPrivateEvent () {
            return this.event.invite_list.is_enabled;
        },

        namelessInviteeWithExactEmailMatchFound () {
            if (this.inviteeGroups === null || this.inviteeGroups.length !== 1) {
                return false;
            }

            return this.findNamelessInviteeWithEmailIndex(
                this.inviteeGroups[0].invitees,
                this.form.email
            ) !== -1;
        },

        lookupBy () {
            return this.event.invite_list.settings.lookupBy;
        },

        showLookupForm () {
            return this.inviteeGroups === null || this.inviteeGroups.length === 0;
        },

        showNoneOfTheseButton () {
            return getValue(this.event.settings, 'security.level') !== 'inviteePassword';
        },

        noInviteesFound () {
            return this.inviteeGroups !== null && this.inviteeGroups.length === 0;
        },

        showNameInput () {
            return this.lookupBy === 'name-and-email' || this.lookupBy === 'name';
        },

        showEmailInput () {
            return this.lookupBy === 'name-and-email' || this.lookupBy === 'email';
        },

        useFormalName () {
            const primaryBlockType = this.event.setup_type === 'tickets' ? 'ticketing' : 'rsvp';
            const primaryBlock = find(this.blocks, { slug: primaryBlockType });

            return primaryBlock.pivot.settings.guestTitles.enabled;
        }
    },

    watch: {
        isActive (newVal) {
            if (newVal && this.skipLookupBlock) {
                setTimeout(this.skipBlock, 1300);
            }
        }
    },

    mounted () {
        this.form.securityToken = this.securityToken;
        const getParams = new URLSearchParams(window.location.search);

        if (this.enteredPasscode && !getParams.has('group')) {
            this.lookupByPasscode();
        }

        if (getParams.has('reply')) {
            this.preselectedReply = getParams.get('reply');
        }

        if (getParams.has('group')) {
            this.preselectedGroup = getParams.get('group');

            this.preselectInviteeGroup(this.preselectedGroup);
        }
    },

    methods: {
        completeBlock () {
            // You can not call completeBlock without this block being visible on the screen
            // else it causes scrollTo to not be able to calculate the position breaking the form.
            // This IntersectionObserver forces the block to be visible before continuing on.
            new IntersectionObserver((entries) => {
                if (entries[0].intersectionRatio > 0) {
                    this.$emit('complete-block', this.getCompletionObject());
                }
            }).observe(this.$el);
        },

        continueWithNewSubmission () {
            this.existingSubmission = null;

            this.completeBlock();
        },

        enterKeyCompletionFunction () {
            if (this.showLookupForm) {
                this.lookup();
            }
        },

        findNamelessInviteeWithEmailIndex (invitees, email) {
            return findIndex(
                invitees.map((invitee) => { return { ...invitee, email: invitee.email ? invitee.email.toLowerCase() : null }; }),
                {
                    firstName: null,
                    lastName: null,
                    email: email ? email.toLowerCase() : null
                }
            );
        },

        isGroupSelected (group) {
            return this.selectedGroup && this.selectedGroup.id === group.id;
        },

        lookup () {
            this.loading = true;

            this.form.post(this.route('api.events.invitees.lookup', this.event))
                .then(({ data }) => {
                    const inviteeGroups = data.data;

                    this.$set(this, 'inviteeGroups', inviteeGroups);
                    this.loading = false;

                    if (this.noInviteesFound && !this.isPrivateEvent) {
                        /**
                         * No invitees were found and the event security is set to
                         * public, pass through the name from the lookup as the
                         * first guest to the normal block
                         */

                        this.passLookupDetailsToOpenEventFlow();
                    }

                    if (this.namelessInviteeWithExactEmailMatchFound) {
                        this.selectInviteeGroup(this.inviteeGroups[0]);
                    }
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        async lookupByPasscode () {
            this.loading = true;

            axios.post(this.route('api.events.invitees.lookup', this.event), {
                inviteePasscode: this.enteredPasscode,
                securityToken: this.securityToken
            }).then(({ data }) => {
                const inviteeGroups = data.data;

                this.$set(this, 'inviteeGroups', inviteeGroups);
                this.loading = false;

                if (inviteeGroups.length === 1) {
                    this.selectInviteeGroup(this.inviteeGroups[0]);

                    /**
                     * If the block isn't already active and most of the
                     * time it won't be active at this point, we need to
                     * make sure that once it gets active we preselect the group.
                     * This covers the scenario when the welcome message
                     * block is turned on.
                     */
                    if (!this.isActive) {
                        this.skipLookupBlock = true;
                    }
                }
            }).catch(() => {
                this.loading = false;
            });
        },

        async selectInviteeGroup (inviteeGroup) {
            if (this.loadingGroupSelection) {
                return;
            }

            if (inviteeGroup.hasLiveSubmission && this.isPreview) {
                this.showLiveModeBlocker = true;
                return;
            }

            /**
             * Save the looked up details in the store just in case
             * they need to be used, i.e. if an Invitee without an email
             * is selected and we want to prefill the email.
             */
            Object.assign(this.inviteeLookUpDetails, this.form.data(), {
                full_name: `${this.form.first_name} ${this.form.last_name}`,
                invitees: inviteeGroup.invitees
            });

            if (!this.submissionStarted) {
                await this.$store.dispatch('Submission/start', {
                    test_mode: this.isPreview
                });
            }

            this.loadingGroupSelection = inviteeGroup.id;

            const route = this.route('api.submissions.select-invitee-group', [
                this.submission.id,
                this.submission.uuid,
                inviteeGroup.uuid
            ]);

            axios.post(route, { securityToken: this.securityToken })
                .then(({ data }) => {
                    this.submission = data.submission;

                    if (!this.isPreview) {
                        this.existingSubmission = data.existingSubmission;
                    }

                    if (
                        this.namelessInviteeWithExactEmailMatchFound
                        && this.inviteeLookUpDetails.first_name
                        && this.inviteeLookUpDetails.last_name
                    ) {
                        this.passLookupDetailsToNamelessInvitee();
                    }

                    this.selectedGroup = inviteeGroup;

                    if (this.existingSubmission == null) {
                        this.completeBlock();
                    }
                })
                .catch(() => {
                    this.$toasted.global.error('There was an error when selecting an invitee.');
                })
                .finally(() => {
                    this.loadingGroupSelection = null;
                });
        },

        selectNoneOfThese () {
            if (this.preselectedGroup) {
                window.location.href = window.location.href.replace(window.location.search, '');

                return;
            }

            if (!this.isPrivateEvent) {
                this.passLookupDetailsToOpenEventFlow();

                return;
            }

            this.inviteeGroups = [];
        },

        skipBlock () {
            if (this.existingSubmission !== null) {
                return;
            }

            this.skipLookupBlock = false;
            this.completeBlock();
        },

        passLookupDetailsToNamelessInvitee () {
            const index = this.findNamelessInviteeWithEmailIndex(
                this.inviteeGroups[0].invitees,
                this.form.email
            );

            const formalName = `${this.inviteeLookUpDetails.first_name} ${this.inviteeLookUpDetails.last_name}`;

            Object.assign(
                this.submission.invitee_group.invitees[index],
                {
                    first_name: this.inviteeLookUpDetails.first_name,
                    last_name: this.inviteeLookUpDetails.last_name,
                    formalName,
                    fullName: formalName
                }
            );
        },

        passLookupDetailsToOpenEventFlow () {
            this.submission.invitee_group = null;

            this.completeBlock();

            this.$set(this.submission, 'lookedUpGuest', {
                title_id: null,
                first_name: this.form.first_name,
                last_name: this.form.last_name,
                email: this.form.email,
                invitee_id: null,
                parent_invitee_id: null,
                uuid: null
            });

            App.$emit('prefill-guest-details', this.submission.lookedUpGuest);
        },

        async preselectInviteeGroup (groupUuid) {
            if (!this.submissionStarted) {
                await this.$store.dispatch('Submission/start', {
                    test_mode: this.isPreview
                });
            }

            // Do a lookup for the provided UUID so the Lookup block is properly
            // filled even though we can just select the provided group
            axios.post(this.route('api.events.invitees.lookup', this.event), {
                uuid: groupUuid,
                securityToken: this.securityToken
            })
                .then(({ data }) => {
                    const inviteeGroups = data.data;

                    this.$set(this, 'inviteeGroups', inviteeGroups);

                    if (inviteeGroups.length > 0) {
                        this.selectedGroup = this.inviteeGroups[0];
                    }
                });

            const route = this.route('api.submissions.select-invitee-group', [
                this.submission.id,
                this.submission.uuid,
                groupUuid
            ]);

            axios.post(route, { securityToken: this.securityToken })
                .then(({ data }) => {
                    this.submission = data.submission;

                    if (!this.isPreview && data.existingSubmission != null) {
                        this.existingSubmission = data.existingSubmission;
                        return;
                    }

                    /**
                     * If the block is already active even though most of the
                     * time it won't be active at this point, we need to
                     * complete the block immediately. This covers the scenario
                     * when the welcome message block is turned off.
                     */
                    if (this.isActive) {
                        this.skipBlock();
                    } else {
                        this.skipLookupBlock = true;
                    }
                })
                .catch(() => {
                    this.$toasted.global.error('There was an error when preselecting the invitee group.');
                });
        },

        updateGuest (newVal) {
            Object.assign(this.form, newVal);
        }
    }
};
</script>
