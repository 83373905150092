/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shoes-flip-flops-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>clothing accessory footwear</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9.265 19.271v-.01a4.275 4.275 0 01-4.53 3.97 4.274 4.274 0 01-3.99-4.26V5.29A4.57 4.57 0 015.315.71s0 0 0 0h.28V.709c2.52-.01 4.57 2.04 4.57 4.57 0 .1-.01.2-.02.3z"/><path pid="1" d="M.75 10.5l4.5-5.25 4.6 5.25M14.735 19.271l-.01-.01a4.26 4.26 0 004.52 3.97 4.272 4.272 0 003.98-4.26V5.3v0c0-2.53-2.05-4.575-4.58-4.575h-.3 0a4.564 4.564 0 00-4.58 4.57c-.01.1 0 .2.01.3z"/><path pid="2" d="M23.25 10.5l-4.5-5.25-4.6 5.25"/></g>'
  }
})
