<template>
    <div class="container">
        <section class="panel">
            <page-header icon="flags-stroke" label="Feature Flags">
                <a :href="route('admin.dashboard')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <div class="row mx-0">
                <div class="col-12 md:col-4 px-0 mb-4">
                    <search-field
                        v-model="searchTerm"
                        class="w-full"
                        placeholder="Find a feature flag..."
                    ></search-field>
                </div>

                <div class="col-12 mb-4 md:col-8">
                    <div class="flex w-full justify-end">
                        <toggle-switch
                            v-model="filterToEnabledFeatureFlags"
                            label-position="left"
                        >
                            Display Only Enabled Feature Flags
                        </toggle-switch>
                    </div>
                </div>
            </div>

            <div class="row">
                <div
                    v-show="filteredFeatureFlags.length === 0"
                    class="col-12 items-center text-yellow-dark text-lg my-8 font-semibold"
                >
                    <app-icon
                        name="alert-triangle"
                        class="h-8 w-8 mr-2"
                    ></app-icon>
                    <span>No feature flags found</span>
                </div>

                <div
                    v-for="featureFlag in filteredFeatureFlags"
                    :key="featureFlag.id"
                    class="col-12 md:col-6 mt-4"
                >
                    <div class="flex w-full items-center p-4 rounded-md border shadow">
                        <p class="flex-1">{{ featureFlag.title }}</p>
                        <toggle-switch :value="featureFlag.enabled" @input="toggleFeatureFlag(featureFlag)"></toggle-switch>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import axios from '@/util/axios';

export default {
    name: 'ManageFeatureFlags',

    props: {
        initialFeatureFlags: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            featureFlags: cloneDeep(this.initialFeatureFlags),
            filterToEnabledFeatureFlags: false,
            searchTerm: ''
        };
    },

    computed: {
        filteredFeatureFlags () {
            const regex = new RegExp(this.searchTerm, 'i');

            return this.featureFlags.filter(({ title, feature, enabled }) => {
                if (!title.match(regex) && !feature.match(regex)) {
                    return false;
                }

                if (this.filterToEnabledFeatureFlags && !enabled) {
                    return false;
                }

                return true;
            });
        }
    },

    methods: {
        toggleFeatureFlag ({ feature, enabled }) {
            find(this.featureFlags, { feature }).enabled = !enabled;

            const payload = {
                enabled: !enabled
            };

            axios.put(this.route('api.admin.feature-flags.update', feature), payload)
                .catch(() => {
                    this.$toasted.global.error('There was an error updating the feature flag.');
                });
        }
    }
};
</script>
