/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fruit-grapes': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g _fill="currentColor"><circle pid="0" cx="12" cy="21.5" r="2.5"/><circle pid="1" cx="12" cy="10.5" r="2.5"/><circle pid="2" cx="6" cy="11" r="2.5"/><circle pid="3" cx="18" cy="11" r="2.5"/><circle pid="4" cx="15" cy="16.5" r="2.5"/><circle pid="5" cx="9" cy="16.5" r="2.5"/><path pid="6" d="M12 7.5a1 1 0 001-1V1a1 1 0 00-2 0v5.5a1 1 0 001 1zM14.655 6.6a6.4 6.4 0 007.326-4.57.5.5 0 00-.4-.628 6.394 6.394 0 00-7.326 4.57.5.5 0 00.4.628zM9.345 6.6a6.4 6.4 0 01-7.326-4.57.5.5 0 01.4-.628 6.4 6.4 0 017.326 4.57.5.5 0 01-.4.628z"/></g>'
  }
})
