/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dessert-ice-cream-cone-swirl-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.1 21.876v-.01a2.307 2.307 0 01-3.03 1.17 2.34 2.34 0 01-1.18-1.18L5.7 12.27h0c-.17-.38 0-.83.38-.99.09-.05.19-.07.3-.07h11.2v-.001c.41-.01.75.33.75.74 0 .1-.03.2-.07.3z"/><path pid="1" d="M11.25.75h0c0 .82.67 1.49 1.5 1.49v-.001c1.27-.01 2.4.8 2.82 2h0c.13.39-.07.81-.46.95-.09.02-.17.04-.25.04H9.73l-.01-.001c-1.28-.01-2.41.8-2.83 2v-.01c-.14.39.06.81.45.95.08.02.16.04.24.04h6.62-.01c1.65-.01 3 1.34 3 3"/></g>'
  }
})
