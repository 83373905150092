/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'messages-user-warning-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>chat</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.25 15.75h3v4.5l4.5-4.5h3l-.01-.001c.82 0 1.5-.68 1.5-1.5v-12 0c0-.83-.68-1.5-1.5-1.5H9.73c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0M4.5 3.75a3 3 0 100 6 3 3 0 100-6zM8.25 17.25V15v0c0-2.08-1.68-3.75-3.75-3.75C2.42 11.25.75 12.92.75 15v2.25h1.5l.75 6h3l.75-6zM15.75 11.25h0"/><path pid="1" d="M15.75 11.25h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375M15.75 9V5.25"/></g>'
  }
})
