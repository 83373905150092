/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-sweater-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 19.5l3-12.918h-.01a4.495 4.495 0 014.4-3.59h7.68l-.01-.001c2.12 0 3.96 1.49 4.4 3.58l3 12.91"/><path pid="1" d="M20.25 19.5l-3-9.75v9.75c0 .82-.68 1.5-1.5 1.5H8.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V9.75l-3 9.75M6.75 18h10.5M8.159 3s.09 3.75 3.84 3.75S15.839 3 15.839 3"/></g>'
  }
})
