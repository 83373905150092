/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'paragraph-align-center-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>text middle</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M2.24 3h19.5M5.24 7.5h13.5M.74 12h22.5M5.24 16.5h13.5M2.24 21h19.5"/></g>'
  }
})
