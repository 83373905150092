/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alert-finger-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>exclamation warning notification reminder task bandage</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25 23.261l-5.23-5.23h0c-1.02-1.06-1-2.74.06-3.75 1.02-1 2.66-1 3.68-.01l1.47 1.47v-12h0a2.996 2.996 0 115.99-.021s0 0 0 0v7.5h1.5-.01c3.31-.01 6 2.68 6 6v6M14.25 9.76h-6M14.25 5.26h-6"/><path pid="1" d="M3.75 5.261l4.5 2.25-4.5 2.25"/></g>'
  }
})
