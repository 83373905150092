/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings-cog': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>option</desc><path pid="0" _fill="currentColor" d="M22.421 9.763l-1.266-.449a1.374 1.374 0 01-.78-1.886l.576-1.213a2.376 2.376 0 00-3.165-3.165l-1.213.577a1.375 1.375 0 01-1.885-.782l-.45-1.265a2.376 2.376 0 00-4.476 0l-.45 1.266a1.375 1.375 0 01-1.885.781L6.214 3.05a2.376 2.376 0 00-3.165 3.165l.576 1.213a1.375 1.375 0 01-.78 1.886l-1.266.45a2.375 2.375 0 000 4.475l1.266.45a1.374 1.374 0 01.78 1.885l-.576 1.213a2.376 2.376 0 003.165 3.165l1.213-.576a1.373 1.373 0 011.885.781l.45 1.265a2.376 2.376 0 004.476 0l.45-1.266a1.374 1.374 0 011.885-.78l1.213.576a2.376 2.376 0 003.165-3.165l-.576-1.213a1.374 1.374 0 01.78-1.885l1.266-.451a2.375 2.375 0 000-4.475zM12 16.785a4.93 4.93 0 01-4.441-2.944 4.813 4.813 0 012.6-6.281 4.868 4.868 0 016.281 2.6 4.813 4.813 0 01-2.6 6.281 4.589 4.589 0 01-1.84.344z"/>'
  }
})
