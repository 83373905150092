/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'users-alt-add-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 11.25a6 6 0 100 12 6 6 0 100-12zM17.25 14.25v6M14.25 17.25h6M10.782 10.513l-.01-.01a6.025 6.025 0 00-4.04-1.52h0a5.987 5.987 0 00-6 5.24M6.75.75a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75zM16.5.75a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM20.4 9h0c-1.25-2.16-4-2.89-6.15-1.65-.43.24-.82.56-1.14.93"/></g>'
  }
})
