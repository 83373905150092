/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-quarantine-14-days-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.75 14.87h2v8M23.25 19.875h-5.5c0-3 4-5 4-5v8"/><path pid="1" d="M10.125 17.375h0a6.884 6.884 0 01-6.87-6.88c0-3.8 3.08-6.87 6.87-6.87s6.86 3.08 6.86 6.87c-.01.45-.05.91-.14 1.36M11.583 1.125h-2.92M10.12 1.12v2.5M4.52 2.84L3.48 3.87 2.44 4.9M3.5 3.87l1.76 1.77M.75 9.042v2.9M.75 10.5h2.5M2.465 16.098l1.03 1.03 1.03 1.03M3.5 17.13l1.76-1.77M8.667 19.875h2.9M10.12 19.88v-2.5M19.5 11.958v-2.92M19.5 10.5H17M17.78 4.902l-1.04-1.04-1.04-1.04M16.75 3.87l-1.76 1.77"/><path pid="2" d="M9.64 13.45a.41.41 0 100 .83.41.41 0 100-.84zM8.04 6.95a1.45 1.45 0 100 2.91 1.45 1.45 0 100-2.92z"/></g>'
  }
})
