/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'animal-bird-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sparrow cardinal raven</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M1.875 4.875v-.01a12.17 12.17 0 018.24-3c5.25 0 12 16.5 12 16.5"/><path pid="1" d="M4.12 3.32v.8s0 11.439 4.5 14.25c6 3.75 12-2.25 12-2.25l.35-.36"/><path pid="2" d="M10.87 10.125l-.01-.01a4.659 4.659 0 003.74 4.5c3.75.75 4.5-1.5 4.5-1.5l.22-.68M9 4.875h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M8.625 5.25h0c-.01-.21.16-.38.37-.38M9 5.62h-.01a.386.386 0 01-.38-.38s0 0 0 0"/><path pid="4" d="M9.37 5.25h-.001c0 .2-.17.375-.375.375h-.001M8.79 18.47l-.91 3.65M13.88 19.54v2.58"/></g>'
  }
})
