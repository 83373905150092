/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-soccer-ball-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75h-.01C5.77.75.74 5.78.74 12c0 6.21 5.03 11.25 11.25 11.25 6.21-.01 11.25-5.04 11.25-11.25h0C23.24 5.78 18.2.75 11.99.75z"/><path pid="1" d="M8.89 16.754l-1.91-5.9L11.99 7.2 17 10.84l-1.91 5.9H8.88zM16.3 1.601L11.99 4.5 7.68 1.601M14.599 22.94l2.13-4.366 4.884-.75M23.21 12.909l-3.43-3.42.73-4.84M12 4.5v2.71M17.01 10.85l2.78-1.35M.78 12.909l3.427-3.42-.74-4.84M6.99 10.85L4.21 9.5M15.1 16.75l1.64 1.83M9.401 22.94l-2.14-4.366-4.884-.75M8.9 16.75l-1.64 1.83"/></g>'
  }
})
