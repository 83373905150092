/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-wipe-paper-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M1.5.75v5h0c0 1.1.89 1.99 2 1.99h1M22.5.75v5c0 1.1-.9 2-2 2h-1"/><path pid="1" d="M9.786 18.893l1.71.85 2-1 2 1 2-1 2 1v-15.5h-15v10.5M1.5 23.25v-6.83l-.01-.01c-.01-1.68.83-3.24 2.22-4.16l.77-.52"/><path pid="2" d="M7.5 21.75l2.71-3.4v-.01a1.9 1.9 0 00-.44-2.76v0h0c-.75-.5-1.75-.41-2.39.23l-1.91 1.9"/></g>'
  }
})
