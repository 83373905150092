import Vue from 'vue';
import numeral from 'numeral';

const numberFilter = (value) => {
    return numeral(value).format('0,0');
};

Vue.filter('number', numberFilter);

export default numberFilter;
