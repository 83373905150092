<template>
    <div>
        <a
            v-if="!showCssEditor"
            class="button-text button-info"
            role="button"
            @click="showCssEditor = true"
        >
            <app-icon
                name="add-circle"
                class="h-6 w-6 mr-2"
                stroke
            ></app-icon>
            <span>Add</span>
        </a>

        <textarea
            v-else
            v-model="customCss"
            class="form-field"
            placeholder="Enter css text..."
            rows="5"
        ></textarea>

        <button
            v-if="!isCustomCssEmpty"
            class="button-text button-info mt-2"
            @click="removeCustomCss"
        >
            <app-icon
                name="trash"
                class="h-5 w-5 mr-2"
                stroke
            ></app-icon>
            <span>Remove Custom CSS</span>
        </button>
    </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';

export default {
    name: 'ThemeDrawerCssEditor',

    props: {
        value: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            customCss: this.value,
            showCssEditor: !isEmpty(this.value)
        };
    },

    computed: {
        isCustomCssEmpty () {
            return isEmpty(this.customCss);
        }
    },

    watch: {
        value (newCss) {
            this.customCss = newCss;
        },

        customCss (newCss) {
            this.$emit('input', newCss);
        }
    },

    methods: {
        removeCustomCss () {
            this.customCss = '';
            this.showCssEditor = false;
        }
    }
};
</script>
