/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'like-click-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.13 23.25l.1-1.13v-.01c.13-1.51-.87-2.88-2.34-3.2l-3.651-.82v-4.85 0c0-1.04-.84-1.875-1.875-1.875-1.04 0-1.875.83-1.875 1.875v8.3l-1.48-1.11h0c-.68-.51-1.64-.37-2.15.3-.41.54-.41 1.29 0 1.83l.47.63M9 9.75v-6M12 9.75v-6M14.025 8.775l-2.03-2.03 3-3M3.75 3.75v4.8l-.01-.01c.09.74.75 1.27 1.5 1.2h1.5M20.25 9.75h-1.5v-.01c-.75.07-1.41-.46-1.5-1.2v-3.6h-.01c.09-.75.75-1.28 1.5-1.21h1.5M17.25 6.75h3"/><path pid="1" d="M10.5 12.75H2.25l-.01-.001a1.51 1.51 0 01-1.5-1.5V2.238c0-.83.67-1.5 1.5-1.5h19.5-.01c.82-.01 1.5.67 1.5 1.5 0 0 0 0 0 0v9c0 .82-.68 1.5-1.5 1.5h-1.5"/></g>'
  }
})
