/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attachment-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>paperclip</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M23.25 9.9L12.273 20.878v-.01a6.766 6.766 0 01-9.55 0 6.757 6.757 0 01-.01-9.55L11.72 2.3v-.01a4.5 4.5 0 016.36-.12 4.51 4.51 0 01.11 6.36c-.04.03-.08.07-.12.11l-9.02 9.02v-.01c-.9.86-2.32.83-3.19-.06a2.25 2.25 0 01-.01-3.13l9.01-9.02"/>'
  }
})
