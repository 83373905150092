/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-circle-filled': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>ok yes correct done task</desc><path pid="0" _fill="currentColor" d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"/>'
  }
})
