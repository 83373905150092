/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-balloon-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.75 8.625v-.01a7.71 7.71 0 00-7.51-7.88v-.01c-4.25.1-7.6 3.63-7.5 7.87h-.01a7.782 7.782 0 005.74 7.63l-.7 1.4h-.01c-.19.37-.04.82.33 1 .1.05.21.07.33.07h3.57v-.001c.41-.01.74-.34.74-.76a.942.942 0 00-.08-.34l-.7-1.4h-.01a7.805 7.805 0 005.74-7.64zM11.25 18.75v1.5h0c0 .82.67 1.49 1.5 1.49h1.5-.01c.82 0 1.5-.68 1.5-1.5v0h0c-.01-.83.67-1.51 1.49-1.51v0h-.01c.82-.01 1.5.67 1.5 1.5v3"/></g>'
  }
})
