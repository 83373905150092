/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-mask-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M23.25.75v22.5"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.25 3.63a1.68 2.25 0 100 4.5 1.68 2.25 0 100-4.5z" transform="matrix(.36542 -.93084 .93083 .36542 -.241 11.413)"/><path pid="2" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.74 4.19a2.25 1.68 0 100 3.37 2.25 1.68 0 100-3.38z" transform="matrix(.93083 -.36543 .36542 .93083 -1.061 6.162)"/><path pid="3" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.71 1.94h-.01c-.87.36-1.86.24-2.6-.32h0a3.79 3.79 0 00-4.72.17l-.41.34-.41-.35h0a3.791 3.791 0 00-4.72-.18v-.01c-.75.56-1.74.68-2.6.31L.7.7H.69C.66 1.98.72 3.26.87 4.53l-.01-.01c.55 4.02 4.26 6.83 8.28 6.28.98-.14 1.92-.47 2.78-.98h0c3.48 2.08 8 .95 10.08-2.53.51-.86.84-1.8.97-2.79v-.01c.14-1.28.2-2.56.18-3.84z"/>'
  }
})
