/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'discount-bubble-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>coupon percentage</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M22.125 12v-.01c.01-1.51-.94-2.85-2.36-3.34v-.01c.67-1.36.43-2.99-.62-4.09l-.01-.01a3.3 3.3 0 00-3.95-.64h0a3.34 3.34 0 00-4.12-2.32 3.316 3.316 0 00-2.32 2.31h0a3.281 3.281 0 00-3.95.63v-.01a3.564 3.564 0 00-.615 4.08v-.01a3.54 3.54 0 00-2.16 4.52c.35 1 1.14 1.79 2.15 2.15h-.01c-.68 1.35-.44 2.98.6 4.08l-.01-.01a3.295 3.295 0 003.94.63l-.01-.01a3.327 3.327 0 004.11 2.31c1.12-.32 2-1.2 2.31-2.32l-.01-.01c1.3.7 2.92.44 3.94-.64h-.01c1.04-1.1 1.28-2.74.61-4.09v-.01a3.49 3.49 0 002.35-3.34zM8.62 15.75l7.5-7.5"/><path pid="1" d="M9.375 7.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM15.375 13.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/></g>'
  }
})
