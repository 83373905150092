/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-room-service-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 14.25v9M15.75 21.754v0c0-1.66-1.35-3-3-3H9v0c0-1.66-1.35-3-3-3H.75v6zM6 18.75h3M2.25 11.25h21M12.75.75v1.5M21.75 11.254v0c0-4.98-4.03-9-9-9-4.98 0-9 4.02-9 9zM21.75 11.254l-.68 1.346h-.01a3.008 3.008 0 01-2.68 1.65h-7.15"/></g>'
  }
})
