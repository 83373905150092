<template>
    <div class="alert alert-info mb-8 md:items-center">
        <app-icon
            name="info-circle"
            class="w-12 h-12 md:w-6 md:h-6 mr-4"
            stroke
        ></app-icon>
        Changes to these settings will be finalized once you have saved the page.
    </div>
</template>

<script>
export default {
    name: 'ItemModifiedWarning'
};
</script>
