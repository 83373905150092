export default {
    props: {
        answers: {
            type: Array,
            default: () => { return []; }
        },
        question: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            answer: this.answers.length > 0 ? this.answers[0].value : null
        };
    },

    watch: {
        answer (newVal) {
            this.$emit('input', newVal);
        }
    }
};
