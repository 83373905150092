<template>
    <form-field-wrapper :error="error" :should-show-error="shouldShowError">
        <div class="form-panel">
            <div class="inline-flex items-center">
                <slot name="trigger">
                    <toggle-switch
                        :disabled="disabled"
                        :value="value"
                        @input="switchValue"
                    >
                        <div
                            v-tippy="{ arrow: true, arrowType: 'round' }"
                            :content="tooltip"
                            :class="!!tooltip ? 'tooltip-text' : ''"
                        >
                            {{ label }}
                        </div>
                    </toggle-switch>
                </slot>

                <slot name="trigger-appended"></slot>
            </div>

            <div
                v-if="value && hasSlot('default')"
                class="mt-4"
            >
                <slot></slot>
            </div>
        </div>
    </form-field-wrapper>
</template>

<script>
import SlotHelpers from '@/mixins/SlotHelpers';
import HandleErrorMessages from '@/mixins/HandleErrorMessages';

export default {
    name: 'FormPanel',

    mixins: [HandleErrorMessages, SlotHelpers],

    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        tooltip: {
            type: String,
            default: ''
        },
        value: {
            type: Boolean,
            required: true
        }
    },

    methods: {
        switchValue (newValue) {
            this.$emit('input', newValue);

            if (newValue === false) {
                this.$emit('collapse-form-panel');
            } else {
                this.$emit('expand-form-panel');
            }
        }
    }
};
</script>
