<template>
    <simple-picker
        :value="value"
        :placeholder-empty-state="placeholderEmptyState"
        :placeholder-search="placeholderSearch"
        :items="countries"
        item-label="name"
        :item-value="itemValue"
        is-filterable
        filterable-property="name"
        @input="$emit('input', $event)"
    ></simple-picker>
</template>

<script>
import countries from './countries';

export default {
    name: 'CountryPicker',

    props: {
        value: {
            type: String,
            default: null
        },
        placeholderEmptyState: {
            type: String,
            default: 'Select a country...'
        },
        placeholderSearch: {
            type: String,
            default: 'Find a country...'
        },
        itemValue: {
            type: String,
            default: 'code'
        }
    },

    computed: {
        countries () {
            return countries;
        }
    }
};
</script>
