/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-correct-3-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.5.78a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75zM23.25 11.28h0a6.031 6.031 0 00-8.46-1.05c-.39.3-.74.65-1.05 1.04"/><path pid="1" d="M21.875 4.275h-.01c-.61.17-1.25.26-1.88.26v-.001c-1.7 0-3.33-.64-4.57-1.78M5.5 12.78a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75zM10.24 23.28h0a6.031 6.031 0 00-8.46-1.05c-.39.3-.74.65-1.05 1.04"/><path pid="2" d="M8.87 16.274v-.01a6.79 6.79 0 01-6.45-1.52M12.5 19.03a.5.5 0 100 1 .5.5 0 100-1zM15.5 16.03a.5.5 0 100 1 .5.5 0 100-1zM18.5 13.03a.5.5 0 100 1 .5.5 0 100-1zM5.27.71a4.5 4.5 0 100 9 4.5 4.5 0 100-9z"/><path pid="3" d="M3.47 5.66l.97.97-.001-.001c.17.17.46.17.63 0 .01-.02.03-.04.04-.06l1.94-2.72"/></g>'
  }
})
