/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'compass-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>direction location</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/><path pid="1" d="M16.648 7.352h0a.743.743 0 00-.77-.19l-6.54 2.17-2.18 6.53h-.01a.734.734 0 00.94.93l6.532-2.18 2.17-6.54h-.01c.09-.27.02-.57-.19-.77zM12 .75V3M19.95 4.05l-1.59 1.59M23.25 12H21M19.95 19.95l-1.59-1.59M12 23.25V21M4.05 19.95l1.59-1.59M.75 12H3M4.05 4.05l1.59 1.59"/><path pid="2" d="M12 11.625h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M11.625 12h0c-.01-.21.16-.38.37-.38M12 12.37l-.01-.001a.386.386 0 01-.38-.38v-.001"/><path pid="4" d="M12.37 12v0c0 .2-.17.375-.375.375"/></g>'
  }
})
