<template>
    <div class="container">
        <div v-if="!hasEmailWhiteLabeling">
            <div class="py-2 font-semibold text-xl text-black">Email Whitelabeling</div>

            <div class="flex flex-col md:flex md:flex-row md:flex-1 items-center md:items-start space-x-0 md:space-x-3 border-t p-4 mt-2 bg-white md:bg-gray-50">
                <img
                    class="w-8 h-8 mr-0 mt-0 md:mr-2 md:mt-1"
                    :src="asset('images/account/rsvpify-premium.svg')"
                >
                <div class="w-2/3">
                    <p class="mt-3 md:mt-0 text-center md:text-left text-gray-600 tracking-wide font-semibold">Contact your Account Manager to add Email Whitelabeling.</p>
                    <p class="mt-3 md:mt-0 mr-0 md:mr-20 text-center md:text-left text-gray-600">
                        Send emails through RSVPify from your own email address. We'll work with your organization's IT team to digitally sign outbound emails and ensure successful delivery to your guests.
                    </p>
                </div>

                <a
                    class="button button-inverse button-info w-52 h-12 mt-5 md:mt-0"
                    href="https://help.rsvpify.com/en/articles/2766611-can-i-send-emails-as-if-they-came-from-anything-myorganization-com"
                    target="_blank"
                >Learn more</a>

                <button class="button button-info w-52 h-12 mt-5 md:mt-0" @click="chatWithUs">
                    <app-icon name="messages-bubble-double-stroke"></app-icon> Chat with us
                </button>
            </div>

            <div class="flex flex-col items-center text-center w-full space-y-4 mt-20 mb-6 md:mb-0 text-gray-600">
                <app-icon
                    class="w-24 h-24"
                    name="email-action-settings"
                    stroke
                ></app-icon>
                <p>Email whitelabeling is <a class="font-semibold">not enabled</a> on your account.</p>
                <p>Emails you send will come from {{ senderEmail }}.</p>
            </div>
        </div>

        <div v-else>
            <div class="py-2 font-semibold text-xl text-black border-b">Email Whitelabeling</div>

            <div class="flex flex-col items-center text-center w-full mt-20 text-gray-600">
                <app-icon name="check-circle-stroke" class="w-24 h-24 text-green"></app-icon>
                <p class="mt-8">Email whitelabeling is <a class="font-semibold">enabled</a> on your account!</p>
                <p class="mt-8 mb-5">You can send emails from: </p>
                <ul
                    v-for="item in domains"
                    :key="item"
                    class="font-semibold"
                >
                    <li>{{ item }}</li>
                </ul>
                <p class="mt-8 text-gray-400">To make changes to your authenticated domains, please contact your Account Manager.</p>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';

export default {
    name: 'EmailWhitelabeling',

    computed: {
        hasEmailWhiteLabeling () {
            return get(this.auth().user(), 'settings.emailWhitelabeling.enabled');
        },

        domains () {
            const allDomains = this.auth().user().settings.emailWhitelabeling.emails;
            return allDomains.split(',');
        },

        emailCheck () {
            return this.auth().user().settings.emailWhitelabeling.emails;
        },

        senderEmail () {
            return (this.auth().user().settings?.emailWhitelabeling?.emails && this.auth().user().settings.emailWhitelabeling.enabled) || 'invitations@rsvpify.org';
        }
    },

    methods: {
        chatWithUs () {
            if (window.Intercom) {
                window.Intercom('showNewMessage', 'Hello, I would like more information about Email Whitelabeling.');
            }
        }
    }
};
</script>
