<template>
    <toggle-switch
        :value="form.active"
        small
        @input="toggleWallActivity"
    ></toggle-switch>
</template>

<script>
import { sync } from 'vuex-pathify';
import Form from '@/validation/Form';

export default {
    name: 'EventWallActivityToggle',

    props: {
        isWallActive: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            form: new Form({
                active: this.isWallActive
            })
        };
    },

    methods: {
        toggleWallActivity (value) {
            this.form.active = value;

            this.form.patch(this.route('api.walls.toggle-activity', this.wall.event_id))
                .then((response) => {
                    this.$set(this.wall, 'active', response.data.active);

                    const successMessage = response.data.active ? 'Event website is successfully turned on.' : 'Event website is successfully turned off.';

                    this.$toasted.global.success(successMessage);
                }).catch(() => {
                    this.form.isActive = !value;
                    this.$toasted.global.error('Something went wrong with updating event website. Please try again.');
                });
        }
    },

    computed: {
        ...sync('Wall/*')
    },

    watch: {
        'wall.active': function (value) {
            this.form.active = value;
        }
    }
};
</script>
