import find from 'lodash/find';
import map from 'lodash/map';
import filter from 'lodash/filter';

export default {
    computed: {
        events () {
            const section = find(this.event.form.sections, { class: this.form.section });
            const blocks = map(
                filter(section.blocks, { slug: 'secondary-event' }),
                (block) => {
                    return {
                        value: block.pivot.id,
                        label: block.pivot.settings.title
                    };
                }
            );

            return [
                { value: null, label: 'Primary Event' },
                ...blocks
            ];
        }
    }
};
