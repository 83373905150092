<template>
    <div class="flex flex-col w-full">
        <div
            :id="`accordion-header-${itemId}`"
            role="button"
            class="flex items-center justify-between font-semibold accordion-header"
            :class="activeHeaderStyle"
            @click="toggle"
        >
            <slot
                name="header"
                :active="active"
                :click="toggle"
            ></slot>

            <div :class="{ 'hidden': !expandCollapseIcon}">
                <app-icon
                    v-if="!active"
                    name="arrow-right-chevron"
                    class="h-4 w-4 text-gray-500"
                    stroke
                ></app-icon>
                <app-icon
                    v-else
                    name="arrow-down-chevron"
                    class="h-4 w-4 text-gray-500"
                    stroke
                ></app-icon>
            </div>
        </div>

        <div
            :id="`collapse-block-${itemId}`"
            class="accordion-item-collapsable"
            :class="{ 'collapsed': !active }"
        >
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
import includes from 'lodash/includes';
import indexOf from 'lodash/indexOf';

export default {
    inject: ['accordionListState'],

    props: {
        activeHeaderClass: {
            type: String,
            default: ''
        },
        expandCollapseIcon: {
            type: Boolean,
            default: true
        },
        itemId: {
            type: [Number, String],
            required: true
        }
    },

    computed: {
        active () {
            return includes(this.accordionListState.activeItems, this.itemId);
        },

        activeHeaderStyle () {
            return { [this.activeHeaderClass]: this.active };
        }
    },

    methods: {
        async close () {
            const shouldClose = await this.accordionListState.shouldCloseFunction(this.itemId);

            if (!shouldClose) {
                return;
            }

            const itemIndex = indexOf(this.accordionListState.activeItems, this.itemId);
            this.accordionListState.activeItems.splice(itemIndex, 1);

            this.$emit('close-accordion-item', this.itemId);
        },

        open () {
            if (!this.accordionListState.isMultipleItemAccordion) {
                this.$set(this.accordionListState, 'activeItems', []);
            }

            this.accordionListState.activeItems.push(this.itemId);

            this.$emit('open-accordion-item', this.itemId);
        },

        toggle () {
            this.active ? this.close() : this.open();
        }
    }
};
</script>
