<template>
    <div class="w-full">
        <div class="flex flex-col md:flex md:flex-row items-center p-8 border-b">
            <div class="flex flex-col md:flex md:flex-row md:flex-1 items-center md:items-start space-x-4">
                <a class="w-20 h-20 ml-4 md:ml-0 flex-none">
                    <img :src="asset('images/account/integrations/classy.png')" alt="Classy">
                </a>
                <img
                    v-if="!isClassyAllowedOnPlan"
                    v-tippy
                    class="w-4 mt-3 md:mt-0 pt-0 md:pt-1"
                    :src="asset('images/account/rsvpify-premium.svg')"
                    content="This feature is not included in your current plan. Please consider upgrading."
                >
                <div>
                    <p class="mt-3 md:mt-0 text-center md:text-left text-gray-600 uppercase tracking-wide font-semibold">Classy</p>
                    <p class="mt-3 md:mt-0 mr-0 md:mr-20 text-center md:text-left text-gray-600">
                        Automatically sync attendees and data from your Classy campaigns for seamless check-in as guests arrive.
                    </p>
                </div>
            </div>

            <a role="button" @click="manageClassy">
                <div class="button w-60 mt-3 md:mt-0 bg-white border-purple text-purple hover:bg-purple hover:text-white">
                    Manage
                </div>
            </a>
        </div>

        <app-modal v-model="showManageClassyModal">
            <template #header>
                <div class="flex items-center justify-between space-x-4">
                    <div>Classy Integration</div>
                    <div v-if="!user.hasClassyIntegration">
                        <a
                            href="https://help.rsvpify.com/en/articles/5538985-how-to-sync-your-classy-account-to-utilize-rsvpify-s-advanced-event-management-features"
                            target="_blank"
                            class="button button-sm mr-4"
                        >Integration Instructions</a>
                    </div>
                </div>
            </template>

            <template #default="{ close }">
                <template v-if="user.hasClassyIntegration">
                    <div class="text-center flex items-center justify-center space-x-4">
                        <template v-if="user.settings.classy.enabled">
                            <app-icon
                                name="check-circle"
                                class="w-6 h-6 text-green"
                            ></app-icon>

                            <span>You're connected to Classy organization {{ user.settings.classy.external_user_name }}</span>
                        </template>
                        <template v-else>
                            <app-icon
                                name="close-circle"
                                class="w-6 h-6 text-red"
                            ></app-icon>

                            <div class="max-w-xl">It appears RSVPify was unable to connect to Classy API using your previously provided details. Please disconnect and enter your details again.</div>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <form-field-wrapper
                        label="Client ID"
                        :error="form.errors.get('client_id')"
                        :should-show-error="form.errors.has('client_id')"
                        tooltip="The Client ID of the Classy API application"
                    >
                        <input
                            v-model="form.client_id"
                            class="form-field"
                            placeholder="API Client ID"
                        >
                    </form-field-wrapper>

                    <form-field-wrapper
                        label="Client Secret"
                        :error="form.errors.get('client_secret')"
                        :should-show-error="form.errors.has('client_secret')"
                        tooltip="The Client Secret of the Classy API application"
                    >
                        <input
                            v-model="form.client_secret"
                            class="form-field"
                            placeholder="API Client Secret"
                        >
                    </form-field-wrapper>

                    <form-field-wrapper
                        label="Organization ID"
                        :error="form.errors.get('external_user_id')"
                        :should-show-error="form.errors.has('external_user_id')"
                        tooltip="The ID of your organization in Classy - you can find this by looking at the Classy URL after logging in"
                    >
                        <input
                            v-model="form.external_user_id"
                            class="form-field"
                            placeholder="Classy Organization ID"
                        >
                    </form-field-wrapper>

                    <form-field-wrapper
                        label="Organization Name"
                        :error="form.errors.get('external_user_name')"
                        :should-show-error="form.errors.has('external_user_name')"
                        tooltip="How would you like us to name your integration with this organization?"
                    >
                        <input
                            v-model="form.external_user_name"
                            class="form-field"
                            placeholder="Classy Organization Name"
                        >
                    </form-field-wrapper>
                </template>
            </template>

            <template #footer="{ close }">
                <template v-if="user.hasClassyIntegration">
                    <stateful-button
                        v-if="user.settings.classy.enabled"
                        class="button-primary"
                        :loading="resyncing"
                        @click="resyncAllCampaigns"
                    >
                        Re-sync all campaigns
                    </stateful-button>

                    <stateful-button
                        class="button-soft"
                        :loading="disconnecting"
                        @click="disconnect"
                    >Disconnect</stateful-button>
                </template>
                <template v-else>
                    <stateful-button
                        class="button-primary"
                        :loading="form.processing"
                        @click="save"
                    >
                        Save
                    </stateful-button>

                    <button class="button" @click="close">Cancel</button>
                </template>
            </template>
        </app-modal>
    </div>
</template>

<script>
import { get, pick, cloneDeep } from 'lodash';
import Form from '@/validation/Form';
import axios from '@/util/axios';

export default {
    name: 'ClassyIntegration',

    props: {
        initialUser: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            disconnecting: false,
            user: cloneDeep(this.initialUser),
            fetchedExternalEvents: false,
            form: null,
            showManageClassyModal: false,
            resyncing: false,
            wasRedirectedFromDashboard: false
        };
    },

    computed: {
        hasFilledAPIDetails () {
            return this.form.client_id && this.form.client_secret && this.form.external_user_id;
        },

        isClassyAllowedOnPlan () {
            return this.auth().user().plan.features.ClassyIntegration.allowed;
        }
    },

    created () {
        this.form = new Form({
            client_id: '',
            client_secret: '',
            external_user_id: '',
            external_user_name: ''
        });

        if (this.user.hasClassyIntegration) {
            Object.assign(this.form, pick(this.user.settings.classy, [
                'client_id',
                'client_secret',
                'external_user_id',
                'external_user_name'
            ]));
        }
    },

    mounted () {
        const getParams = new URLSearchParams(window.location.search);

        if (getParams.has('classy')) {
            this.showManageClassyModal = true;
            this.wasRedirectedFromDashboard = true;
        }
    },

    methods: {
        resyncAllCampaigns () {
            axios.post(this.route('api.classy.sync-all'));

            App.alert().success(
                '',
                'Re-sync for all your RSVPify events connected to Classy have been started.'
            );

            this.showManageClassyModal = false;
        },

        disconnect () {
            const onConfirm = () => {
                this.disconnecting = true;

                this.form
                    .post(this.route('api.classy.disconnect'))
                    .then(() => {
                        this.user.hasClassyIntegration = false;
                        this.$set(this.user.settings, 'classy', null);
                        this.form.restore();

                        this.showManageClassyModal = false;
                    })
                    .catch((error) => {
                        this.$toasted.global.error(get(error, 'response.data.error'));
                    })
                    .finally(() => {
                        this.disconnecting = false;
                    });
            };

            App.alert().confirm(
                'Are you sure?',
                'Are you sure you want to disconnect RSVPify from your Classy Account? All your events that were previously connected to Classy will stop being synced and updated with data from Classy.',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                },
                onConfirm
            );
        },

        manageClassy () {
            if (!this.isClassyAllowedOnPlan) {
                App.alert().paymentRequired(
                    'Please upgrade.',
                    `This feature is not included in your ${this.auth().user().plan.name} plan. Please consider upgrading.`,
                    {
                        chatWithUs: true,
                        supportMessage: 'Hello, I would like some more information about Classy Integration.'
                    }
                );
                return;
            }
            this.showManageClassyModal = true;
        },

        save () {
            this.form.errors.clear();

            this.form
                .post(this.route('api.classy.connect'))
                .then(({ data }) => {
                    this.user.hasClassyIntegration = true;
                    this.$set(this.user.settings, 'classy', data);

                    if (this.wasRedirectedFromDashboard) {
                        window.location.href = this.route('dashboard', { autoCreateEvent: 1 });
                    }

                    this.showManageClassyModal = false;
                })
                .catch((error) => {
                    this.$toasted.global.error(get(error, 'response.data.message'));
                });
        }
    }
};
</script>
