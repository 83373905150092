<template>
    <div>
        <page-header icon="discount-bubble-stroke" label="Coupons"></page-header>

        <template v-if="coupons.length > 0">
            <data-table
                class="overflow-x-auto"
                :columns="columns"
                :rows="filteredCoupons"
                no-results-placeholder="No coupons found matching your criteria."
                primary-key="id"
            >
                <template #header>
                    <div class="flex-auto">
                        <search-field
                            v-model="searchTerm"
                            class="w-full md:w-80"
                            placeholder="Find coupon by code..."
                        ></search-field>
                    </div>

                    <button
                        class="button-icon button-primary button-lg"
                        @click="showNewCouponModal"
                    >
                        <app-icon name="add-circle" stroke></app-icon>
                    </button>
                </template>
                <template slot="row" slot-scope="{ row, property, value }">
                    <!--Note that the Options column is a "fake" column/property name and it does not actually have a label -->
                    <div
                        v-if="property === 'options'"
                        class="flex justify-center items-center text-center space-x-4"
                    >
                        <button class="button-icon" @click="editCoupon(row)">
                            <app-icon
                                name="edit"
                                class="h-4 w-4"
                                stroke
                            ></app-icon>
                        </button>

                        <button class="button-icon hover:text-red" @click="deleteCoupon(row)">
                            <app-icon
                                name="trash"
                                class="h-4 w-4"
                                stroke
                            ></app-icon>
                        </button>
                    </div>

                    <div v-else-if="property === 'discount'">
                        <span v-if="row.type === 'percentage'">{{ value }}%</span>
                        <money-field
                            v-else
                            v-model.number="value"
                            static
                        ></money-field>
                    </div>

                    <div v-else-if="property === 'code'" class="font-semibold tracking-wide">
                        {{ value }}
                    </div>

                    <div v-else-if="property === 'expires_at'">
                        <span v-if="!!value">Expires {{ value | datetime('DATE_FULL', true) }}</span>
                        <span v-else>Never</span>
                    </div>

                    <div v-else-if="property === 'redemptions'">
                        <span v-if="!!row.max_redemptions">{{ value }} of {{ row.max_redemptions }}</span>
                        <span v-else>{{ value | number }}</span>
                    </div>

                    <div v-else-if="property === 'discountables'">
                        <span>{{ getCouponableLabelsString(value) }}</span>
                    </div>

                    <div v-else>
                        {{ value }}
                    </div>
                </template>
            </data-table>
        </template>

        <div v-else class="flex flex-col items-center justify-center">
            <button
                type="button"
                class="button button-primary my-8"
                @click="showNewCouponModal"
            >
                <app-icon name="add-circle"></app-icon>
                Add a New Coupon
            </button>

            <img
                class="w-128"
                :src="asset('images/empty-states/coupons.svg')"
                alt="Coupons empty state"
            >
        </div>

        <coupon-details
            v-model="showCouponModal"
            :initial-coupon="coupon"
            :available-discountables="event.products"
            :currency="currency"
            @save-coupon="saveCoupon"
            @delete-coupon="deleteCoupon(coupon)"
        ></coupon-details>
    </div>
</template>

<script>
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';
import axios from '@/util/axios';

export default {
    name: 'Coupons',

    mixins: [GeneratesUniqueKey],

    props: {
        event: {
            type: Object,
            required: true
        },
        currency: {
            type: String,
            required: true
        },
        initialCoupons: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            columns: [
                {
                    label: '', property: 'options', desktop: true, mobile: true, minWidth: '100px'
                },
                {
                    label: 'Code', property: 'code', desktop: true, mobile: true, sortable: true, minWidth: '150px'
                },
                {
                    label: 'Discount', property: 'discount', desktop: true, mobile: true, sortable: true, minWidth: '130px'
                },
                {
                    label: 'Expiration', property: 'expires_at', desktop: true, mobile: true, sortable: true, minWidth: '170px'
                },
                {
                    label: 'Redemptions', property: 'redemptions', desktop: true, mobile: true, sortable: true, minWidth: '150px'
                },
                {
                    label: 'Applies to', property: 'discountables', desktop: true, mobile: true, minWidth: '200px'
                }
            ],
            searchTerm: '',
            coupons: cloneDeep(this.initialCoupons),
            coupon: {},
            couponables: [
                { id: 1, label: 'General Admission', type: 'event' },
                { id: 2, label: 'VIP Admission', type: 'ticket' },
                { id: 3, label: 'Official T-Shirt', type: 'item' }
            ],
            showCouponModal: false
        };
    },

    computed: {
        filteredCoupons () {
            return this.coupons.filter((coupon) => {
                const searchRegex = new RegExp(this.searchTerm, 'i');

                return searchRegex.test(coupon.code);
            });
        }
    },

    mounted () {
        this.setEmptyCoupon();
    },

    methods: {
        editCoupon (coupon) {
            this.coupon = {
                ...coupon,
                hasExpiryDate: !!coupon.expires_at,
                hasRedemptions: !!coupon.max_redemptions
            };

            this.showCouponModal = true;
        },

        saveCoupon (coupon) {
            if (coupon.id) {
                const route = this.route('api.events.coupons.update', [this.event, this.coupon.id]);

                axios.put(route, coupon).then(({ data }) => {
                    const couponIndex = findIndex(this.coupons, { id: data.id });
                    this.$set(this.coupons, couponIndex, data);

                    this.$toasted.global.success('Coupon has been updated.');
                }).catch(() => {
                    this.$toasted.global.error('There was an error saving the coupon.');
                });
            } else {
                // Create operation
                const route = this.route('api.events.coupons.store', this.event);

                axios.post(route, coupon).then(({ data }) => {
                    this.coupons.push(data);

                    this.$toasted.global.success('Coupon has been added.');
                }).catch(() => {
                    this.$toasted.global.error('There was an error adding the coupon.');
                });
            }

            this.showCouponModal = false;
            this.setEmptyCoupon();
        },

        deleteCoupon (coupon) {
            App.alert().confirm(
                'Are you sure?',
                'Deleting this code will prevent future redemptions of this code, but will not impact previous purchases.',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                },
                () => {
                    const route = this.route('api.events.coupons.destroy', [this.event, coupon.id]);

                    axios.delete(route, coupon).then(() => {
                        const couponIndex = findIndex(this.coupons, { id: coupon.id });
                        this.coupons.splice(couponIndex, 1);
                        this.showCouponModal = false;

                        this.$toasted.global.success('Coupon has been deleted.');
                    }).catch(() => {
                        this.$toasted.global.error('There was an error deleting the coupon.');
                    });
                }
            );
        },

        getCouponableLabelsString (discountables) {
            if (discountables.length === 0) {
                return 'Total Order';
            }

            return discountables.map((discountable) => {
                return discountable.discountable.discountable_label;
            }).join(', ');
        },

        showNewCouponModal () {
            this.setEmptyCoupon();
            this.showCouponModal = true;
        },

        setEmptyCoupon () {
            this.coupon = {
                code: null,
                discount: null,
                type: null,
                redemptions: 0,
                hasExpiryDate: false,
                hasRedemptions: false,
                hasLimits: false,
                discountables: []
            };
        }
    }
};
</script>
