import { get } from 'vuex-pathify';
import axios from '@/util/axios';

export default {
    computed: {
        event: get('Event/event')
    },

    methods: {
        copyPublishedUrl () {
            this.$copyText(this.route('event.show', this.event.subdomain));
            this.$toasted.global.success('Event URL copied to clipboard and ready to share!');
            axios.post(this.route('api.events.tasks.complete', { event: this.event, task: 'copied-event-link' }));
        },

        previewEvent () {
            if (!this.isEventPublished) {
                axios.post(this.route('api.events.tasks.complete', { event: this.event, task: 'previewed-event' }));
            }

            App.$emit('previewed-event');
            window.open(this.viewEventUrl, '_blank');
        }
    }
};
