<template>
    <div>
        <div v-theme="['form.text']">
            <div
                v-theme="'form.background'"
                class="px-4 py-8 w-full rounded-md relative transition-all"
                :class="{ 'cursor-pointer': !isActive }"
            >
                <div v-if="displayTimeslotOptions" class="flex flex-col items-center justify-center -mb-4">
                    <button class="flex items-center justify-center text-lg" @click="selectAnotherTimeslot">
                        <app-icon
                            name="arrow-left-chevron"
                            class="h-4 w-4 mr-2"
                        ></app-icon>
                        Select another timeslot
                    </button>

                    <p class="text-sm mt-4">{{ formattedSelectedTimeslotDate }}</p>

                    <p>{{ formattedSelectedTimeslot }}</p>

                    <div v-if="event.timezone" class="flex items-center justify-center">
                        <app-icon
                            name="time-clock"
                            class="h-4 w-4 mr-2"
                            stroke
                        ></app-icon>
                        <p class="text-sm">{{ event.timezone.name.replace(/_/g, ' ') }}</p>
                    </div>
                </div>

                <div v-if="hasNoProducts">
                    <div v-theme="['form.accent']" class="p-6 flex flex-col items-center">
                        <app-icon
                            name="ticket"
                            class="h-10 w-10"
                            stroke
                        ></app-icon>
                        <div
                            v-if="isEditMode"
                            v-theme="'form.title-text'"
                            class="text-xl text-center mt-4"
                        >
                            This Ticketing Block is empty.
                        </div>
                        <div v-theme="'form.text'" class="text-center mt-4">
                            <div v-if="isEditMode">You have not added any products to the block yet.</div>
                            <span v-else>This event does not have any products available for sale yet.</span>
                        </div>
                    </div>
                </div>

                <template v-else>
                    <div
                        v-theme="['form.title-text', 'form.accent']"
                        class="text-center text-2xl mt-8 mb-4 border-b pb-4 font-semibold"
                    >
                        {{ block.pivot.settings.title }}
                    </div>

                    <div
                        v-if="block.pivot.settings.description && (event.published_at === null || new Date(event.published_at) > Date.UTC(2024, 0, 12, 19))"
                        v-theme="['form.text']"
                        class="mb-2 break-words"
                    >
                        {{ block.pivot.settings.description }}
                    </div>

                    <div v-if="loadingAvailableProductsForTimeslot" class="flex flex-col items-center justify-center">
                        <app-icon
                            v-theme="['form.text']"
                            name="loader"
                            class="w-12 h-12"
                        ></app-icon>
                    </div>

                    <form-field-wrapper v-if="!!chosenProducts" :error="errorBag.get('payload.products')">
                        <div
                            v-for="product in activeProducts"
                            :key="product.id"
                            class="mb-6"
                        >
                            <quantity-input
                                :event="event"
                                :value="getProductQuantity(product)"
                                :item="product"
                                :is-active="isActive"
                                :editable="isEditMode"
                                :disabled="shouldDisable(product)"
                                :disabled-increment="!canIncrementProductQuantity(product)"
                                :error="errorBag.get(`payload.products.${product.id}.quantity`)"
                                @input="setProductQuantity(product, $event)"
                                @complete-block="completeBlock"
                            ></quantity-input>

                            <product-guests
                                v-model="chosenProducts[product.id].guests"
                                :invitees="invitees"
                                :selected-invitees="selectedInvitees"
                                :max-number-of-invitees="maxNumberOfInvitees"
                                :quantity="getProductQuantity(product)"
                                :product="product"
                                :require-guest-title="block.pivot.settings.guestTitles.enabled"
                                :error-bag="errorBag.only(`payload.products.${product.id}`)"
                                :disable-name-editing="isEditMode"
                                show-invitee-selectors
                                @selected-invitee="refreshInviteeAvailability"
                                @updated-invitee-name="updateInviteeName"
                            ></product-guests>
                        </div>

                        <!-- Show decline option as last, if active -->
                        <div v-if="shouldShowDecline" class="mb-6">
                            <div class="flex items-start">
                                <div v-theme="['form.accent']" class="flex">
                                    <app-icon
                                        v-if="declineable.settings.icon"
                                        :name="declineable.settings.icon"
                                        class="shrink-0 opacity-50 h-6 w-6 mr-4"
                                    ></app-icon>
                                    <div v-else class="h-6 w-6 mr-4"></div>
                                </div>

                                <div v-theme="['form.text']" class="flex-1">
                                    <div class="mb-1">
                                        <div class="font-semibold">
                                            {{ declineable.title }}
                                        </div>
                                    </div>

                                    <div class="opacity-75 text-sm">
                                        {{ declineable.settings.description }}
                                    </div>
                                </div>

                                <div>
                                    <div
                                        role="button"
                                        class="flex items-start leading-normal"
                                        @click="declined = !declined"
                                    >
                                        <div
                                            v-theme="['form.accent']"
                                            class="rounded-md flex shrink-0 justify-center items-center h-8 w-8 mr-4 border-2"
                                        >
                                            <div
                                                v-if="declined"
                                                class="rounded-sm h-5 w-5 option-selected"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form-field-wrapper>
                </template>
            </div>

            <div
                v-if="showPrimaryGuestEmailField"
                v-theme="'form.background'"
                class="my-8 px-4 py-8 w-full rounded-md relative transition-all"
            >
                <guest-details
                    v-model="primaryGuest"
                    :error="errorBag.only('payload.primaryGuest').all()[0]"
                    :require-name="false"
                    :require-title="false"
                    :require-email="true"
                ></guest-details>
            </div>

            <slot name="block-footer" :complete-block="completeBlock"></slot>
        </div>
    </div>
</template>

<script>
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import sumBy from 'lodash/sumBy';
import reject from 'lodash/reject';
import chunk from 'lodash/chunk';
import some from 'lodash/some';
import { get, sync } from 'vuex-pathify';
import values from 'lodash/values';
import OpenTicketingBlock from './OpenTicketingBlock';

export default {
    name: 'InviteeTicketingBlock',

    extends: OpenTicketingBlock,

    data () {
        return {
            invitees: [
                {
                    id: 1,
                    uuid: 'uuid',
                    title_id: null,
                    fullName: 'Guest Name',
                    available: true
                }
            ]
        };
    },

    computed: {
        inviteeLookUpDetails: get('Submission/inviteeLookUpDetails'),
        preselectedReply: sync('Submission/preselectedReply'),

        hasGuestWithEmail () {
            return find(this.selectedInvitees, ({ email }) => {
                return !!email;
            }) !== undefined;
        },

        hasInviteesWithEmail () {
            return some(this.invitees, 'email');
        },

        maxNumberOfInvitees () {
            const hasUnlimitedAdditionalGuests = find(this.invitees, (invitee) => {
                return invitee.additional_guests_limit === null;
            }) !== undefined;

            if (hasUnlimitedAdditionalGuests || !this.invitees.length) {
                return 99;
            }

            return filter(this.invitees, { isAdditionalGuest: false }).length + sumBy(this.invitees, 'additional_guests_limit');
        },

        remainingAttendees () {
            return Math.max(0, this.maxNumberOfInvitees - this.totalQuantityOfChosenProducts);
        },

        selectedInvitees () {
            if (!this.chosenProducts) {
                return [];
            }

            const selectedInvitees = [];

            this.products.forEach(({ id: productId }) => {
                flatMap(this.chosenProducts[productId].guests)
                    .forEach(({ invitee_id, parent_invitee_id, email }) => {
                        if (invitee_id) {
                            selectedInvitees.push({ invitee_id, email, isAdditionalGuest: false });
                        } else if (parent_invitee_id) {
                            selectedInvitees.push({ parent_invitee_id, email, isAdditionalGuest: true });
                        }
                    });
            });

            return selectedInvitees;
        },

        shownAtLeastOneInviteeEmailField () {
            let hasAtLeastOneInviteeEmailField = false;

            this.products.filter((product) => {
                return this.getProductQuantity(product) > 0;
            }).forEach((product) => {
                if (product.settings.requireEmail.enabled) {
                    hasAtLeastOneInviteeEmailField = true;
                }

                let { guests } = this.chosenProducts[product.id];

                if (product.category === 'group') {
                    guests = flatten(guests);
                }

                guests.forEach((guest) => {
                    const invitee = find(this.invitees, { id: guest.invitee_id });

                    if (invitee !== undefined && (!!invitee.prefilledEmail || !!invitee.email)) {
                        hasAtLeastOneInviteeEmailField = true;
                    }
                });
            });

            return hasAtLeastOneInviteeEmailField;
        },

        showPrimaryGuestEmailField () {
            if (this.isEditMode) {
                return false;
            }

            if (this.declined && !this.hasInviteesWithEmail) {
                return true;
            }

            const didNotSelectAnyProducts = values(this.chosenProducts)
                .find((product) => {
                    return product.quantity > 0;
                }) === undefined;

            if (didNotSelectAnyProducts) {
                return false;
            }

            return !this.shownAtLeastOneInviteeEmailField;
        }
    },

    watch: {
        'submission.invitee_group': function (inviteeGroup) {
            if (inviteeGroup) {
                this.setInviteesForSelectedSubmissionGroup();

                if (this.inviteeLookUpDetails.email) {
                    this.primaryGuest.email = this.inviteeLookUpDetails.email;
                }
            }
        }
    },

    mounted () {
        if (this.submission.invitee_group) {
            this.setInviteesForSelectedSubmissionGroup();
        }

        if (this.submission.original_submission_id) {
            this.initFromSubmission();
        }
    },

    methods: {
        canIncrementProductQuantity (product) {
            if (product.category === 'add-on') {
                return true;
            }

            return this.remainingAttendees >= product.guests_per_order;
        },

        clearAdditionalGuestSelections (parentInvitee) {
            this.products.forEach(({ id: productId }) => {
                this.chosenProducts[productId]
                    .guests
                    .forEach((guest) => {
                        if (guest.parent_invitee_id === parentInvitee.id) {
                            guest.parent_invitee_id = null;
                        }
                    });
            });
        },

        async completeBlock () {
            return this.getCompletionObject();
        },

        initProductChoicesFromSubmission () {
            reject(this.activeProducts, { category: 'add-on' }).forEach(({ id, guests_per_order }) => {
                const guests = this.submission.guests
                    .filter(({ products }) => {
                        return find(products, { id }) != null;
                    })
                    .map((guest) => {
                        const invitee = find(this.submission.invitee_group.invitees, { id: guest.invitee_id });
                        const parentInvitee = find(this.submission.invitee_group.invitees, { id: guest.parent_invitee_id });

                        if (invitee == null && parentInvitee == null) {
                            return null;
                        }

                        return { ...guest, uuid: invitee ? invitee.uuid : parentInvitee.uuid };
                    })
                    .filter((guest) => { return guest !== null; });

                this.chosenProducts[id] = {
                    quantity: guests.length / guests_per_order,
                    guests: guests_per_order > 1 ? chunk(guests, guests_per_order) : guests
                };
            });
        },

        /**
         * Prefill the selected invitee email, if an email was passed
         * through from the Lookup Block and the selected invitee does not
         * have an email AND their selected product requires an email.
         * Also pre-fill the primary guest email.
         */
        prefillLookedUpGuestDetails () {
            if (isEmpty(this.inviteeLookUpDetails)) {
                return;
            }

            if (this.selectedInvitees.length === 1 && this.inviteeLookUpDetails.email) {
                this.products.forEach((product) => {
                    if (product.active && product.settings.requireEmail.enabled && this.chosenProducts[product.id].guests.length > 0) {
                        const guest = this.chosenProducts[product.id].guests[0];

                        if (!guest.email) {
                            guest.email = this.inviteeLookUpDetails.email;
                        }
                    }
                });
            }
        },

        preselectReplies () {
            // We're only doing preselection for decline/not-attending answers.
            if (this.declineable.active && this.preselectedReply === 'decline') {
                this.declined = true;
            }
        },

        refreshInviteeAvailability () {
            this.invitees.forEach((invitee) => {
                if (invitee.isAdditionalGuest) {
                    const parentInvitee = find(this.invitees, { id: invitee.parent_invitee_id });
                    const isParentInviteeSelected = find(this.selectedInvitees, { invitee_id: parentInvitee.id }) !== undefined;
                    const allSelectedAdditionalGuests = filter(this.selectedInvitees, {
                        parent_invitee_id: invitee.parent_invitee_id
                    });

                    if (isParentInviteeSelected) {
                        const isUnderAdditionalGuestsLimit = allSelectedAdditionalGuests.length < parentInvitee.additional_guests_limit;

                        invitee.available = parentInvitee.additional_guests_limit === null || isUnderAdditionalGuestsLimit || this.event.invite_list.settings.security === 'public';
                    } else {
                        this.clearAdditionalGuestSelections(parentInvitee);

                        invitee.available = false;
                    }
                } else {
                    invitee.available = find(this.selectedInvitees, { invitee_id: invitee.id }) === undefined;
                }
            });

            this.$nextTick(() => {
                const numberOfAvailableInvitees = filter(this.invitees, { available: true }).length;

                if (numberOfAvailableInvitees === 1) {
                    this.selectLastRemainingInvitee();
                }

                this.prefillLookedUpGuestDetails();
            });
        },

        selectLastRemainingInvitee () {
            const lastRemainingInvitee = find(this.invitees, { available: true });

            forEach(this.products, ({ id: productId, category }) => {
                let selectedInvitee = false;

                let { guests } = this.chosenProducts[productId];

                if (category === 'group') {
                    guests = flatten(guests);
                }

                forEach(guests, (guest) => {
                    if (!guest.invitee_id && !guest.parent_invitee_id) {
                        if (lastRemainingInvitee.isAdditionalGuest) {
                            guest.parent_invitee_id = lastRemainingInvitee.parent_invitee_id;
                        } else {
                            guest.invitee_id = lastRemainingInvitee.id;
                        }

                        selectedInvitee = true;

                        return false;
                    }
                });

                if (selectedInvitee) {
                    /**
                     * Break the forEach loop early and refresh the invitee
                     * availability again because we just selected an invitee
                     */
                    this.refreshInviteeAvailability();

                    return false;
                }
            });
        },

        setInviteesForSelectedSubmissionGroup () {
            this.invitees = cloneDeep(this.submission.invitee_group.invitees)
                .flatMap((invitee) => {
                    const lookedUpInvitee = find(
                        this.inviteeLookUpDetails.invitees,
                        { uuid: invitee.uuid }
                    );

                    if (lookedUpInvitee && lookedUpInvitee.isExactLookupMatch && this.inviteeLookUpDetails.email) {
                        this.$set(invitee, 'email', this.inviteeLookUpDetails.email);
                        this.$set(invitee, 'prefilledEmail', true);
                    }

                    const invitees = [
                        {
                            ...invitee,
                            isAdditionalGuest: false,
                            available: true
                        }
                    ];

                    if (invitee.additional_guests_limit !== 0 || this.event.invite_list.settings.security === 'public') {
                        invitees.push({
                            formalName: `${this.$t('text-guest-of')} ${invitee.formalName}`,
                            parent_invitee_id: invitee.id,
                            available: false,
                            isAdditionalGuest: true
                        });
                    }

                    return invitees;
                });

            this.preselectReplies();
        },

        serializePayload () {
            /**
             * In case the primary guest form field is being shown (no invitee
             * has an email attached to them), we want to use the primaryGuest
             * payload property to also store the looked up first name and
             * last name. This will be used to try and attach the email to the
             * correct guest when storing the guest response later.
             */
            if (this.declined && this.inviteeLookUpDetails.first_name) {
                this.primaryGuest.first_name = this.inviteeLookUpDetails.first_name;
                this.primaryGuest.last_name = this.inviteeLookUpDetails.last_name;
            }

            return {
                declined: this.declined,
                decliningGuest: this.decliningGuest,
                primaryGuest: this.primaryGuest,
                products: this.chosenProducts,
                selectedTimeSlot: this.submission.selectedTimeSlot
            };
        },

        setProductQuantity (product, quantity) {
            this.chosenProducts[product.id].quantity = quantity;

            this.refreshInviteeAvailability();
        },

        shouldDisable (product) {
            if (this.declined) {
                return true;
            }

            return this.maxNumberOfInvitees < product.guests_per_order;
        },

        updateInviteeName (guest) {
            if (guest.parent_invitee_id) {
                return;
            }

            const invitee = find(this.invitees, { id: guest.invitee_id });

            Object.assign(invitee, {
                first_name: guest.first_name,
                last_name: guest.last_name,
                title_id: guest.title_id,
                formalName: `${guest.first_name} ${guest.last_name}`
            });

            filter(this.invitees, { parent_invitee_id: guest.invitee_id })
                .forEach((invitee) => {
                    Object.assign(invitee, {
                        formalName: `${this.$t('text-guest-of')} ${guest.first_name} ${guest.last_name}`
                    });
                });
        }
    }
};
</script>
