import Vue from 'vue';
import numeral from 'numeral';

const currencyFilter = (value, cents) => {
    return numeral(cents ? value / 100 : value).format('$0,0[.]00');
};

Vue.filter('currency', currencyFilter);

export default currencyFilter;
