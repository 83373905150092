/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'qr-code': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M.75 12.75v1.5h1.5M.75.75h6v6h-6zM17.25.75h6v6h-6zM.75 17.25h6v6h-6zM12 .75h2.25M9.75.75v3H12M14.25 3.75v3h-4.5M.75 9.75h6v1.5M5.25 14.25h1.5M23.25 23.25h-3v-3h3v-3h-3M14.25 23.25h-4.5v-6M17.25 17.25v6M12.75 17.25h1.5v3h-1.5M9.75 12.75v1.5h1.5M14.25 14.25h3M15.75 14.25v-3M9.75 9.75h3v1.5M23.25 9.75v1.5M23.25 13.5v.75M18.75 9.75v1.5h1.5v2.25"/>'
  }
})
