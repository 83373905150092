/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-symptoms-fever-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6 9.625a3.5 3.5 0 100 7 3.5 3.5 0 100-7zM11.251 23.25v0a5.25 5.25 0 10-10.501 0M21.749 15.418V3.75v0c0-1.66-1.35-3-3-3-1.66 0-3 1.34-3 3v11.668h-.01a4.486 4.486 0 00-.36 6.35 4.494 4.494 0 006.35.35 4.506 4.506 0 00.35-6.36c-.12-.13-.23-.25-.36-.36zM18.75 5.25v12"/><path pid="1" d="M18.74 17.25a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM2 7.75v0h0c-.97-.97-.97-2.54-.01-3.5v-.01h0-.01c.96-.97.96-2.54 0-3.51h-.01M5.726 7.75v0h0c-.97-.97-.97-2.54-.01-3.5v-.01 0h-.01c.96-.97.96-2.54 0-3.5v0M9.72 7.75v0h0c-.97-.97-.97-2.54-.01-3.5v-.01 0H9.7c.96-.97.96-2.54 0-3.5v0"/></g>'
  }
})
