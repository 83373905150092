<template>
    <div>
        <form-field-wrapper
            label="Question"
            :should-show-error="form.errors.has('title')"
            :error="form.errors.get('title')"
        >
            <input
                v-model="form.title"
                class="form-field"
                type="text"
                name="question"
                placeholder="Would you like to include a note?"
            >
        </form-field-wrapper>

        <form-field-wrapper
            v-if="form.askIf"
            label="Ask If"
            :error="form.errors.get('askIf.target')"
            :should-show-error="form.errors.has('askIf.target')"
        >

            <select-list
                v-model="form.askIf.target"
                name="askIf"
            >
                <select-option
                    id="always"
                    class="my-2"
                    label="Always"
                    tooltip="Always ask for a note from the guest(s)"
                ></select-option>

                <select-option
                    id="attending"
                    class="my-2"
                    label="Attending"
                    tooltip="Only ask a note from the guest(s) who are attending."
                ></select-option>

                <form-field-wrapper
                    v-if="form.askIf.target === 'attending'"
                    :error="form.errors.get('askIf.targetId')"
                    :should-show-error="form.errors.has('askIf.targetId')"
                >
                    <simple-picker
                        v-model="form.askIf.targetId"
                        :items="events"
                        item-label="label"
                        item-value="value"
                        class="w-full pl-8"
                    ></simple-picker>
                </form-field-wrapper>

                <select-option
                    v-if="event.hasMaybeOption"
                    id="maybe"
                    class="my-2"
                    label="Maybe"
                    tooltip="Only ask a note from the guest(s) who might attend."
                ></select-option>

                <form-field-wrapper
                    v-if="form.askIf.target === 'maybe'"
                    :error="form.errors.get('askIf.targetId')"
                    :should-show-error="form.errors.has('askIf.targetId')"
                >
                    <simple-picker
                        v-model="form.askIf.targetId"
                        :items="events"
                        item-label="label"
                        item-value="value"
                        class="w-full pl-8"
                    ></simple-picker>
                </form-field-wrapper>

                <select-option
                    id="not-attending"
                    class="my-2"
                    label="Not Attending"
                    tooltip="Only ask a note from the guest(s) who are not attending."
                ></select-option>

                <form-field-wrapper
                    v-if="form.askIf.target === 'not-attending'"
                    :error="form.errors.get('askIf.targetId')"
                    :should-show-error="form.errors.has('askIf.targetId')"
                >
                    <simple-picker
                        v-model="form.askIf.targetId"
                        :items="events"
                        item-label="label"
                        item-value="value"
                        class="w-full pl-8"
                    ></simple-picker>
                </form-field-wrapper>
            </select-list>
        </form-field-wrapper>

        <form-field-wrapper
            v-if="form.additionalCriteria"
            label="Additional Criteria"
            :error="form.errors.get('askIf.target')"
            :should-show-error="form.errors.has('askIf.target')"
        >
            <select-list
                v-model="form.additionalCriteria.target"
                name="additionalCriteria.target"
            >
                <select-option
                    id="none"
                    class="my-2"
                    label="None"
                ></select-option>

                <select-option
                    id="tagged"
                    class="my-2"
                    label="Show only if tagged with..."
                    :disabled="tags.length === 0"
                ></select-option>
                <form-field-wrapper
                    v-if="form.additionalCriteria.target === 'tagged' && tags.length !== 0"
                    :error="form.errors.get('additionalCriteria.query.tagIds.value')"
                    :should-show-error="form.errors.has('additionalCriteria.query.tagIds.value')"
                >
                    <simple-picker
                        v-model="form.additionalCriteria.query.tagIds.value"
                        placeholder-empty-state="Select tag..."
                        :items="tags"
                        item-label="name"
                        item-value="id"
                        class="w-full pl-8"
                        @input="form.additionalCriteria.query.tagIds.comparison = 'equals'"
                    ></simple-picker>
                </form-field-wrapper>
            </select-list>
        </form-field-wrapper>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import HasSecondaryEvents from '@/mixins/HasSecondaryEvents';
import BlockableSettingsMixin from '@/mixins/BlockableSettingsMixin';

export default {
    name: 'FormNoteBlockSettings',

    mixins: [BlockableSettingsMixin, HasSecondaryEvents],

    computed: {
        ...get('Event/event@', {
            tags: 'tags'
        })
    }
};
</script>
