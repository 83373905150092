/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dessert-cake-cherry-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9.72 3a3.75 3.75 0 100 7.5 3.75 3.75 0 100-7.5z"/><path pid="1" d="M6.1 5.805C2.33 6.915.54 9.735.73 14.25v7.5h0c0 .82.67 1.49 1.5 1.49h19.5-.01c.82 0 1.5-.68 1.5-1.5v-6.73h-.01c0-.49-.24-.95-.63-1.23l-9.17-6.47M23.01 14.25H.73M23.01 18.75H.73M10.437 3.067h-.01a5.004 5.004 0 013.79-2.32"/></g>'
  }
})
