/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-protection-wash-hands-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17 4.5h-3l-.01-.001c.82 0 1.5-.68 1.5-1.5 0-.83-.68-1.5-1.5-1.5H8.14h0c-1.51-.01-2.91.74-3.75 1.99l-1.22 1.83v-.01c-.28.41-.75.66-1.26.66H.71M11.75 4.5H14M.75 21.75h1.9v-.001c.23-.01.46.05.67.15l1.73.86-.01-.01c.62.31 1.31.47 2.01.47h11.68-.01c.82 0 1.5-.68 1.5-1.5 0-.83-.68-1.5-1.5-1.5h2.25-.01c.82 0 1.5-.68 1.5-1.5 0-.83-.68-1.5-1.5-1.5h.75-.01c.82 0 1.5-.68 1.5-1.5 0-.83-.68-1.5-1.5-1.5h-1.5l-.01-.001c.82 0 1.5-.68 1.5-1.5 0-.83-.68-1.5-1.5-1.5h-5.25l-.01-.001c.82 0 1.5-.68 1.5-1.5 0-.83-.68-1.5-1.5-1.5H9.08h0c-1.51-.01-2.91.74-3.75 1.99l-1.22 1.83v-.01c-.28.41-.75.66-1.25.66H.65M12.75 11.25H15M16.5 17.25H21M16.5 14.25h3.75M15.75 20.25h3M21.007.75h0c.18 0 .35.11.42.28.41 1.06 1.82 4.74 1.82 5.78h0c.02 1.22-.95 2.23-2.17 2.25-1.23.02-2.24-.95-2.26-2.17a.17.17 0 010-.1c0-1.07 1.46-4.878 1.84-5.851h-.01c.05-.14.18-.23.33-.23z"/></g>'
  }
})
