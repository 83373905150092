/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings-slider': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>option</desc><g _fill="currentColor"><path pid="0" d="M22.043 0H1.957A1.959 1.959 0 000 1.958v20.086A1.959 1.959 0 001.957 24h20.086A1.959 1.959 0 0024 22.044V1.958A1.959 1.959 0 0022.043 0zM21.5 22l-19 .041a.5.5 0 01-.5-.5L1.958 2.5a.5.5 0 01.5-.5L21.5 1.959a.5.5 0 01.5.5V21.5a.5.5 0 01-.5.5z"/><path pid="1" d="M4.5 17.5h6.646a3.5 3.5 0 006.708 0H19.5a1 1 0 000-2h-1.646a3.5 3.5 0 00-6.708 0H4.5a1 1 0 000 2zm10-2.5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zM4.5 8.5h1.146a3.5 3.5 0 006.708 0H19.5a1 1 0 000-2h-7.146a3.5 3.5 0 00-6.708 0H4.5a1 1 0 100 2zM9 6a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 019 6z"/></g>'
  }
})
