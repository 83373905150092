/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-click-buy-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.356 16.355l2.651 6.895 1.59-2.66 2.65-1.6-6.9-2.66zM23.25 23.25L20.6 20.6M4.5 12V4.5M4.5 4.5H6h-.01c.82-.01 1.5.67 1.5 1.5v.75h0c0 .82-.68 1.5-1.5 1.5l-.01-.001h-1.5"/><path pid="1" d="M4.5 8.25H6h-.01c.82-.01 1.5.67 1.5 1.5v.75h0c0 .82-.68 1.5-1.5 1.5l-.01-.001h-1.5M10.5 4.5v6h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51v-6M16.5 4.5v2.25h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51V4.48M18 8.25V12"/><path pid="2" d="M12.75 15.75h-9l-.01-.001c-1.66-.01-3-1.35-3-3V3.748h0A2.998 2.998 0 013.73.73h16.5-.01c1.65-.01 3 1.34 3 3v9h0c0 1.08-.6 2.09-1.55 2.62"/></g>'
  }
})
