/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-3-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.851 4.958l-1.64-1.64-.01-.01c-.82-.79-2.11-.77-2.9.05-.77.79-.77 2.04 0 2.84l1.6 1.694M16.149 19.04l1.63 1.63-.01-.01c.81.78 2.1.76 2.89-.06.76-.8.76-2.05 0-2.85l-1.64-1.64M4.958 16.149l-1.64 1.63v-.01c-.79.81-.77 2.1.05 2.89.79.76 2.04.76 2.84 0l1.63-1.64M19.075 7.9l1.58-1.694h-.01c.81-.79.83-2.08.05-2.9-.79-.82-2.08-.84-2.9-.06-.02.01-.04.03-.06.05l-1.64 1.63"/><path pid="1" d="M21.205 9.95h-1.3l-.01-.01a8.19 8.19 0 00-5.87-5.87V2.78v0c0-1.13-.92-2.05-2.05-2.05s-2.05.91-2.05 2.04v1.28h-.01a8.161 8.161 0 00-5.87 5.86H2.74c-1.13 0-2.05.91-2.05 2.04 0 1.12.91 2.04 2.04 2.04h1.28a8.165 8.165 0 005.86 5.86v1.29h0c0 1.12.91 2.04 2.04 2.04 1.12-.01 2.04-.92 2.04-2.05v-1.31c2.87-.75 5.12-3 5.86-5.87h1.29l-.01-.001a2.05 2.05 0 00-.01-4.1z"/><path pid="2" d="M13.53 7.9a2.55 2.55 0 100 5.11 2.55 2.55 0 100-5.12zM9.44 15.06a.51.51 0 100 1.02.51.51 0 100-1.03z"/></g>'
  }
})
