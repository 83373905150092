<template>
    <component
        :is="componentName"
        ref="blockComponent"
        v-bind="$props"
        @complete-block="$emit('complete-block', $event)"
    >
        <template #block-header>
            <slot name="block-header"></slot>
        </template>

        <template #block-footer="{ completeBlock }">
            <slot name="block-footer" :complete-block="completeBlock"></slot>
        </template>
    </component>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import FormBlock from '@/mixins/FormBlock';

export default {
    name: 'FormTicketingBlock',

    mixins: [FormBlock],

    computed: {
        event: get('Event/event'),
        submission: sync('Submission/submission'),

        componentName () {
            if (this.isTimeSlotFlow) {
                return 'form-recurring-time-slots-block';
            }

            return this.isInviteeFlow
                ? 'invitee-ticketing-block'
                : 'open-ticketing-block';
        },

        isTimeSlotFlow () {
            return this.event.settings.recurringTimeSlots.active && !this.submission.selectedTimeSlot;
        },

        isInviteeFlow () {
            return (this.event.invite_list.is_enabled && this.event.has_invitees) || !!this.submission.invitee_group;
        }
    },

    methods: {
        enterKeyCompletionFunction () {
            this.$refs.blockComponent.handleEnterKeyPressedEvent();
        }
    }
};
</script>
