/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'family-mother-child-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>mom son daughter heart love</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75.75a3 3 0 100 6 3 3 0 100-6zM10.509 12h0c-.27-.91-.69-1.76-1.23-2.52l-2.53 2.51-2.52-2.52v-.01c-.55.76-.97 1.61-1.24 2.52L.73 18.73h3l.75 4.5h4.5l.75-4.5h3zM15 8.25a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM15.009 14.25h-.01a3.736 3.736 0 013.75 3.74v.75h-1.5l-.75 4.5h-3l-.25-1.5M22.69 1.33h0C21.96.57 20.76.54 20 1.26c-.03.02-.05.04-.07.06l-1.18 1.21-1.18-1.22h0c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06h-.01c-.77.79-.77 2.04 0 2.84l3.51 3.63-.01-.01c.22.23.58.24.81.01 0-.01.01-.02.01-.02l3.51-3.64V4.1c.75-.8.75-2.05-.01-2.85z"/></g>'
  }
})
