/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-pandemic-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 13.82a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM16.68 11.25h1.14M17.25 11.25v2.57M21.09 12.6l.81.81M21.49 13.01l-1.82 1.82M23.25 16.68v1.14M23.25 17.25h-2.57M21.9 21.09l-.81.81M21.49 21.49l-1.82-1.82M17.82 23.25h-1.14M17.25 23.25v-2.57M13.41 21.9l-.81-.81M13.01 21.49l1.82-1.82M11.25 17.82v-1.14M11.25 17.25h2.57M12.6 13.41l.81-.81M13.01 13.01l1.82 1.82"/><path pid="1" d="M9.639 22.19l-.01-.01C3.74 21.12-.16 15.49.9 9.62 1.95 3.74 7.58-.16 13.459.9c4.62.83 8.17 4.56 8.79 9.21"/><path pid="2" d="M4.82 20.01l.97-4.86h1.03l-.01-.001c.79 0 1.44-.65 1.44-1.45 0-.12-.02-.24-.05-.35l-.73-2.89h0c-.17-.65-.74-1.09-1.4-1.09H.92M20.2 5.073h-3.92l-.01-.001c-.67-.01-1.24.44-1.4 1.09l-.72 2.88"/></g>'
  }
})
