import keys from 'lodash/keys';

export default {
    computed: {
        premiumAdditionalGuestsFeatureTooltip () {
            const { plan } = this.event.owner;

            if (!plan) {
                return null;
            }

            const guestTypes = {
                single: `a "+1" Guest`,
                multiple: `"X" Guests`,
                unlimited: `Unlimited Guests`
            };

            const disallowedGuestTypes = keys(guestTypes)
                .filter((type) => {
                    return !plan.features.AdditionalGuests.types.includes(type);
                })
                .map((type) => {
                    return guestTypes[type];
                });

            if (!disallowedGuestTypes.length) {
                return null;
            }

            return `The ability to allow guests to bring ${disallowedGuestTypes.join(', ')} is not included in your current plan (${plan.name}). Try it free!`;
        }
    }
};
