/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'animal-dog-forbidden-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>puppy</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5zM4.88 20.71L8.28 12M14.25 18.75v4.27"/><path pid="1" d="M10.48 6.287h-.01c.56-.66 1.39-1.04 2.26-1.04v3l5.17 2.58-.01-.01c.5.25.82.77.82 1.34v.57h0c0 .53-.14 1.05-.41 1.5M4.05 4.05l15.9 15.9M14.25 19.5L7 15.27"/></g>'
  }
})
