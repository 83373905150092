/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-text-image': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>design document</desc><path pid="0" _fill="currentColor" d="M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.329a2 2 0 00-.586-1.415zM8.6 4.5A1.5 1.5 0 117.1 6a1.5 1.5 0 011.5-1.5zm-1.524 8.237l1.478-2.388a1 1 0 011.273-.206l1.579 1.206a.249.249 0 00.349-.045l1.511-1.944a1 1 0 011.587.067L16.3 12.8a.5.5 0 01-.46.7H7.5a.5.5 0 01-.424-.763zM16.5 20.75h-10a.75.75 0 010-1.5h10a.75.75 0 010 1.5zm0-3.5h-10a.75.75 0 010-1.5h10a.75.75 0 010 1.5z"/>'
  }
})
