/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logout-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M.75 12.004H16.5M12.75 15.754l3.75-3.75-3.75-3.75M3.306 16.6a10.5 10.5 0 10.179-9.542"/>'
  }
})
