/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'season-summer-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>leaf sun</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.579 20.754s1.21 2.49 3.27 2.308c4.15-.38 3.88-5.907 5.4-7.859h0a9.1 9.1 0 00-8.27.07c-2.16 1.21-2.31 3.27-.41 5.47zM17.52 18.219s-2.7.33-4.63 5.03M10.5 18.583l-.01-.01a6.758 6.758 0 01-5.09-8.09 6.743 6.743 0 018.08-5.09c2.8.63 4.88 2.97 5.2 5.83M12 2.25V.75M18.89 5.11l1.06-1.06M5.11 18.9l-1.06 1.05M2.25 12H.75M5.11 5.11L4.05 4.05"/></g>'
  }
})
