<template>
    <div class="row">
        <div :class="chartClass">
            <canvas ref="doughnutChart"></canvas>
        </div>

        <div v-if="showLegend" :class="legendClass">
            <div class="grid grid-cols-3 gap-8">
                <div
                    v-for="(data, index) in dataset.data"
                    :key="index"
                    class="flex-col items-start"
                >
                    <div class="flex items-start">
                        <div class="flex rounded-full p-2 mr-2" :style="getLegendItemStyle(index)"></div>

                        <div v-if="getDataLabel(index)" class="text-sm max-w-full break-words">
                            {{ getDataLabel(index).item }}
                            <tippy
                                v-if="showTooltip"
                                :to="`tippy${index}`"
                                theme="dark"
                                :arrow="true"
                                arrow-type="round"
                                :distance="15"
                                animation="shift-toward"
                            >
                                <template v-if="getDataLabel(index).totalGroups">
                                    {{ getDataLabel(index).totalGroups | number }} {{ totalGroupsTooltip }}
                                </template>
                                {{ getDataLabel(index).totalItems | number }} {{ totalItemsTooltip }}
                            </tippy>

                            <span
                                class="text-gray-600 hover:cursor-pointer tooltip-text"
                                :name="`tippy${index}`"
                            >
                                {{ getDataLabel(index).totalItems | number }}
                            </span>
                        </div>
                    </div>

                    <div v-if="getDataLabel(index).available" class="text-sm text-gray-500 ml-6 mt-2">
                        {{ getDataLabel(index).available | number }} <span class="italic">{{ itemAvailabilityTooltip }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    Chart, CategoryScale, DoughnutController, ArcElement, PointElement, LinearScale, Tooltip
} from 'chart.js';
import merge from 'lodash/merge';
import range from 'lodash/range';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@root/tailwind.config';

Chart.register(CategoryScale, DoughnutController, ArcElement, PointElement, LinearScale, Tooltip);
const tailwind = resolveConfig(tailwindConfig);

const defaultOptions = {
    animation: {
        animateRotate: true,
        animateScale: true
    },
    aspectRatio: 3,
    cutoutPercentage: 90,
    legend: false
};

export default {
    name: 'DoughnutChart',

    props: {
        data: {
            type: Object,
            required: true
        },

        options: {
            type: Object,
            default: () => {
                return {};
            }
        },

        baseColor: {
            type: String,
            default: null
        },

        itemAvailabilityTooltip: {
            type: String,
            default: 'Available'
        },

        tooltipLabelGenerator: {
            type: Function,
            default: null
        },

        displayLegend: {
            type: Boolean,
            default: true
        },

        totalItemsTooltip: {
            type: String,
            default: 'Items'
        },

        totalGroupsTooltip: {
            type: String,
            default: 'Groups'
        },

        chartClass: {
            type: String,
            default: 'col-12 md:col-6'
        },

        legendClass: {
            type: String,
            default: 'hidden md:flex md:col-6'
        },

        showTooltip: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            chart: null
        };
    },

    computed: {
        baseColorExistsInConfiguration () {
            return tailwind.theme.colors[this.baseColor]['500'];
        },

        chartOptions () {
            const pluginOptions = {
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: this.tooltipLabelGenerator ? this.tooltipLabelGenerator : this.defaultTooltipLabelGenerator
                        }
                    }
                }
            };

            return merge({}, defaultOptions, pluginOptions, this.options);
        },

        dataset () {
            return this.chart.data.datasets[0];
        },

        showLegend () {
            return this.chart && this.displayLegend;
        }
    },

    mounted () {
        this.createChart();
    },

    methods: {
        createChart () {
            this.setColors();

            this.chart = new Chart(this.$refs.doughnutChart, {
                type: 'doughnut',
                data: this.data,
                options: this.chartOptions
            });
        },

        setColors () {
            const datasetLength = this.data.datasets[0].data.length;

            if (this.baseColor && this.baseColorExistsInConfiguration) {
                this.data.datasets[0].backgroundColor = this.getSpecifiedColorPalette(datasetLength);
            } else {
                this.data.datasets[0].backgroundColor = this.getDefaultColorPalette(datasetLength);
            }
        },

        getDataLabel (index) {
            return this.chart.data.labels[index];
        },

        getLegendItemStyle (index) {
            return {
                backgroundColor: this.dataset.backgroundColor[index]
            };
        },

        getSpecifiedColorPalette (length) {
            return range(0, length, 1).map((i) => {
                return tailwind.theme.colors[this.baseColor][((i % 9) + 1) * 100];
            });
        },

        getDefaultColorPalette (length) {
            const poolOfColors = ['#45286B', '#8F7EA6', '#DAD4E1', '#0994A9', '#6BBFCB', '#CEEAEE', '#3D91FF', '#8BBDFF', '#D8E9FF', '#FFB875', '#FFC691', '#FFE3C8', '#09A96F', '#6BCBA9', '#CEEEE2', '#E3414B', '#EE8D93', '#F9D9DB', '#FFA400', '#FFC866', '#FFEDCC'];

            return range(0, length, 1).map((i) => {
                return `${poolOfColors[(i % poolOfColors.length)]}`;
            });
        },

        defaultTooltipLabelGenerator: (tooltipItem) => {
            return `${tooltipItem.label.item} - ${tooltipItem.label.totalItems}`;
        }
    }
};
</script>
