/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>find magnifying glass</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.81.74a9.06 9.06 0 100 18.12 9.06 9.06 0 100-18.13z" transform="matrix(.92033 -.39114 .39113 .92033 -3.056 4.62)"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.22 16.22l7.03 7.03"/>'
  }
})
