<template>
    <div>
        <div v-theme="['form.text']">
            <div
                v-theme="'form.background'"
                class="px-4 py-8 w-full rounded-md relative transition-all"
                :class="{ 'cursor-pointer': !isActive }"
            >
                <div
                    v-theme="['form.title-text', 'form.accent']"
                    class="text-center text-2xl mt-8 mb-4 border-b pb-4 font-semibold"
                >
                    {{ block.pivot.settings.title }}
                </div>

                <div
                    v-if="block.pivot.settings.description && (event.published_at === null || new Date(event.published_at) > Date.UTC(2024, 0, 12, 19))"
                    v-theme="['form.text']"
                    class="mb-2 break-words"
                >
                    {{ block.pivot.settings.description }}
                </div>

                <form-field-wrapper
                    class="z-10"
                    :error="errorBag.get('payload.products')"
                    raw-error-output
                >
                    <div
                        v-for="invitee in payload"
                        :key="invitee.uuid"
                        class="mt-6"
                    >
                        <template v-if="editingInviteeName === invitee.id || shouldShowNameEditorForInvitee(invitee)" class="flex items-center">
                            <div v-theme="['form.title-text', 'form.accent']" class="flex w-full py-2">
                                <div v-if="block.pivot.settings.guestTitles.enabled" class="w-24 mr-4">
                                    <title-picker
                                        v-model="invitee.title_id"
                                        v-theme="['form.dropdown']"
                                        :placeholder="$t('placeholder-title')"
                                        class="text-lg"
                                        form-field-class="form-field w-full"
                                    ></title-picker>
                                </div>

                                <div class="flex-1 mr-4">
                                    <single-line-text-input v-model="invitee.first_name" :placeholder="$t('placeholder-first-name')"></single-line-text-input>
                                </div>

                                <div class="flex-1 mr-4">
                                    <single-line-text-input v-model="invitee.last_name" :placeholder="$t('placeholder-last-name')"></single-line-text-input>
                                </div>

                                <div class="h-full">
                                    <button class="h-full button-text accent-text border rounded-md p-2" @click="saveInviteeDetails(invitee)">
                                        <app-icon
                                            class="h-7 w-7"
                                            name="check-circle"
                                        ></app-icon>
                                    </button>
                                </div>
                            </div>

                            <div
                                v-if="shouldShowNameEditorForInvitee(invitee)"
                                v-theme="['form.title-text', 'form.accent']"
                                class="flex w-full py-2"
                            >
                                <div v-if="shouldShowEmailFieldForInvitee(invitee)" class="w-full">
                                    <single-line-text-input
                                        v-model="invitee.email"
                                        :placeholder="$t('placeholder-email-address')"
                                    ></single-line-text-input>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div v-theme="['form.title-text', 'form.accent']" class="flex flex-col w-full py-2 border-b">
                                <div class="grow">
                                    <title-picker
                                        v-if="block.pivot.settings.guestTitles.enabled"
                                        v-model="invitee.title_id"
                                        v-theme="['form.dropdown']"
                                        :placeholder="$t('placeholder-title')"
                                        class="text-xl"
                                        form-field-class="form-field text-base w-32"
                                    ></title-picker>

                                    <div class="text-xl font-semibold my-2">
                                        {{ invitee.fullName }}
                                    </div>

                                    <div v-if="shouldShowEmailFieldForInvitee(invitee)" class="w-full">
                                        <single-line-text-input
                                            v-model="invitee.email"
                                            :placeholder="$t('placeholder-email-address')"
                                        ></single-line-text-input>
                                    </div>
                                </div>
                                <div class="flex mt-2">
                                    <button
                                        v-if="allowNameEditing && !isEditMode"
                                        class="accent-text text-sm"
                                        @click="editingInviteeName = invitee.id"
                                    >
                                        {{ $t('button-edit-name') }}
                                    </button>
                                </div>
                            </div>
                        </template>
                        <div class="mt-4">
                            <rsvp-selection
                                :invitee="payload[invitee.uuid]"
                                :require-guest-title="block.pivot.settings.guestTitles.enabled"
                                :products="activeProducts"
                                :remaining-quantities="remainingQuantities"
                                @change-selection="changeGuestSelection(invitee, $event)"
                                @update-additional-guests="updateAdditionalGuests(invitee, $event)"
                            ></rsvp-selection>
                        </div>
                    </div>

                    <div v-if="firstInviteeError" class="alert alert-error alert-sm mt-2">
                        <p class="w-full font-normal text-center">{{ firstInviteeError }}</p>
                    </div>
                </form-field-wrapper>
            </div>

            <div
                v-if="showPrimaryGuestEmailField"
                v-theme="'form.background'"
                class="my-8 px-4 py-8 w-full rounded-md relative transition-all"
            >
                <guest-details
                    v-model="primaryGuest"
                    :error="errorBag.only('payload.primaryGuest').all()[0]"
                    :require-name="false"
                    :require-title="false"
                    :require-email="true"
                ></guest-details>
            </div>

            <slot name="block-footer" :complete-block="completeBlock"></slot>
        </div>
    </div>
</template>

<script>
import find from 'lodash/find';
import { get, sync } from 'vuex-pathify';
import filter from 'lodash/filter';
import values from 'lodash/values';
import getValue from 'lodash/get';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import BlockWithProducts from '@/mixins/BlockWithProducts';
import FormBlock from '@/mixins/FormBlock';

export default {
    name: 'InviteeRsvpBlock',

    mixins: [BlockWithProducts, FormBlock],

    data () {
        return {
            editing: false,
            editingInviteeName: null,
            payload: {},
            primaryGuest: {
                title_id: null,
                first_name: '',
                last_name: '',
                email: ''
            }
        };
    },

    computed: {
        inviteeLookUpDetails: get('Submission/inviteeLookUpDetails'),
        preselectedReply: sync('Submission/preselectedReply'),

        attendingProduct () {
            return find(this.products, {
                short_guest_state: 'Attending'
            });
        },

        allowNameEditing () {
            return this.event.invite_list.settings.allowNameEditing === true;
        },

        event: get('Event/event'),

        firstInviteeError () {
            return this.errorBag.getByPath('payload.products.*.invitees');
        },

        hasGuestWithEmail () {
            return !!values(this.payload).find((guest) => {
                return guest.email;
            });
        },

        notAttendingProduct () {
            return find(this.products, {
                short_guest_state: 'NotAttending'
            });
        },

        invitees () {
            if (this.isEditMode) {
                return [
                    {
                        uuid: 'uuid',
                        title_id: null,
                        fullName: 'Guest Name',
                        selection: null
                    }
                ];
            }

            if (!this.submission.invitee_group) {
                return [];
            }

            return this.submission.invitee_group.invitees;
        },

        products () {
            return this.block.pivot.products;
        },

        remainingQuantities () {
            const remaining = {};

            this.activeProducts.forEach(({ has_limited_availability, id, quantity_remaining }) => {
                if (!has_limited_availability) {
                    remaining[id] = 99;
                    return;
                }

                const selections = Object.values(this.payload).reduce((accumulator, invitee) => {
                    if (invitee.selection !== id) {
                        return accumulator;
                    }

                    const additionalGuests = invitee.additionalGuests || [];

                    return accumulator + 1 + additionalGuests.length;
                }, 0);

                remaining[id] = quantity_remaining - selections;
            });

            return remaining;
        },

        showPrimaryGuestEmailField () {
            if (this.isEditMode) {
                return false;
            }

            const noSelectionMade = values(this.payload)
                .find((invitee) => {
                    return invitee.selection !== null;
                }) === undefined;

            if (noSelectionMade) {
                return false;
            }

            const atLeastOneInviteeEmailFieldIsShown = values(this.payload)
                .find((invitee) => {
                    return this.shouldShowEmailFieldForInvitee(invitee);
                }) !== undefined;

            if (atLeastOneInviteeEmailFieldIsShown) {
                return false;
            }

            return true;
        }
    },

    watch: {
        'submission.invitee_group': function (newInviteeGroup, oldInviteeGroup) {
            if (!oldInviteeGroup || newInviteeGroup.id !== oldInviteeGroup.id) {
                this.setEmptyPayload(newInviteeGroup.invitees);
            }
        }
    },

    mounted () {
        if (this.isEditMode) {
            this.setEmptyPayload(this.invitees);
        } else if (this.submission.invitee_group) {
            this.setEmptyPayload(this.submission.invitee_group.invitees);

            if (this.submission.original_submission_id) {
                this.initFromSubmission();
            }
        }
    },

    methods: {
        changeGuestSelection (invitee, product) {
            this.payload[invitee.uuid].selection = product;

            const selectedProduct = find(this.activeProducts, { id: this.payload[invitee.uuid].selection });

            /**
             * Email is not required for this product, so reset the invitee
             * email to the original invitee email if the invitee does not already have
             * an email set.
             */
            if (!invitee.email && !getValue(selectedProduct, 'settings.requireEmail.enabled')) {
                this.payload[invitee.uuid].email = invitee.originalEmail;
            }
        },

        initFromSubmission () {
            this.submission.invitee_group.invitees.forEach((invitee) => {
                const guest = find(this.submission.guests, { invitee_id: invitee.id }) || {};
                const additionalGuests = filter(this.submission.guests, { parent_invitee_id: invitee.id });
                const fieldsToPrefill = ['first_name', 'last_name'];
                const selection = getValue(find(guest.products, { type: 'primary' }), 'id');
                const selectedProduct = find(this.activeProducts, { id: selection });

                if (getValue(selectedProduct, 'settings.requireEmail.enabled')) {
                    fieldsToPrefill.push('email');
                }

                Object.assign(
                    this.payload[invitee.uuid],
                    pick(guest, fieldsToPrefill),
                    {
                        selection,
                        additionalGuests: additionalGuests.map((additionalGuest) => {
                            return pick(additionalGuest, ['first_name', 'last_name', 'email', 'title_id']);
                        })
                    }
                );
            });

            // If the primary guest email field is shown, it means no email
            // has been prefilled (even though it might exist in the Submission),
            // possibly due to updated product settings (i.e. Require Email Field
            // was set to False prior to editing this Submission). We will check
            // if there is an email in the original submission and prefill the
            // primary guest field regardless of individual product settings
            if (this.showPrimaryGuestEmailField) {
                const guestWithEmail = this.submission.guests.find((guest) => {
                    return !!guest.email;
                });

                if (guestWithEmail) {
                    this.primaryGuest.email = guestWithEmail.email;
                }
            }
        },

        preselectReplies () {
            let preselectedProductId = null;

            if (this.preselectedReply === 'attending') {
                preselectedProductId = this.attendingProduct.id;
            } else if (this.preselectedReply === 'decline') {
                preselectedProductId = this.notAttendingProduct.id;
            }

            if (preselectedProductId) {
                Object.values(this.payload)
                    .forEach((invitee) => {
                        this.changeGuestSelection(invitee, preselectedProductId);
                    });
            }

            this.preselectReply = null;
        },

        saveInviteeDetails (invitee) {
            invitee.fullName = `${invitee.first_name} ${invitee.last_name}`;
            this.editingInviteeName = null;
        },

        setEmptyPayload (invitees) {
            const payload = invitees.reduce((accumulator, invitee) => {
                const email = invitee.email || '';

                accumulator[invitee.uuid] = {
                    ...invitee,
                    email,
                    originalEmail: email,
                    selection: null
                };

                const lookedUpInvitee = find(
                    this.inviteeLookUpDetails.invitees,
                    { uuid: invitee.uuid }
                );

                if (lookedUpInvitee && lookedUpInvitee.isExactLookupMatch && this.inviteeLookUpDetails.email) {
                    accumulator[invitee.uuid].email = this.inviteeLookUpDetails.email;
                    this.$set(accumulator[invitee.uuid], 'prefilledEmail', true);
                }

                return accumulator;
            }, {});

            this.$set(this, 'payload', payload);

            if (this.preselectedReply) {
                this.preselectReplies();
            }
        },

        serializePayload () {
            // This will automatically remove invitees without a selection from the payload
            const selectionsByProduct = omit(groupBy(this.payload, 'selection'), null);

            return {
                products: mapValues(selectionsByProduct, (invitees) => {
                    return { invitees };
                }),
                primaryGuest: this.primaryGuest
            };
        },

        shouldShowEmailFieldForInvitee (invitee) {
            const selectedProduct = find(this.activeProducts, { id: this.payload[invitee.uuid].selection });

            return !!invitee.originalEmail
                || !!invitee.prefilledEmail
                || getValue(selectedProduct, 'settings.requireEmail.enabled', false);
        },

        shouldShowNameEditorForInvitee (invitee) {
            return this.allowNameEditing && !this.isEditMode && invitee.fullName === ' ';
        },

        updateAdditionalGuests (invitee, additionalGuests) {
            this.$set(this.payload[invitee.uuid], 'additionalGuests', additionalGuests);
        }
    }
};
</script>
