<template>
    <div class="mt-8 -mx-8 md:mx-0">
        <infinite-data-table
            class="text-gray-600"
            clickable-rows
            :columns="chosenColumns"
            :filter="filter"
            no-results-placeholder="Sorry, we couldn't find any campaigns."
            remote-sort
            :sort="sort"
            :source="dataSource"
            @change-sort="handleChangedSort"
            @click-row="redirectToEdit"
        >
            <template #header>
                <div class="flex-auto">
                    <search-field
                        v-model="filter.term"
                        class="w-full md:w-72"
                        placeholder="Search by title or subject..."
                    ></search-field>
                </div>

                <choosable-columns
                    v-model="chosenColumns"
                    class="mr-2 hidden md:block"
                    :columns="columns"
                ></choosable-columns>

                <a :href="route('events.campaigns.drafts.create', event)" class="button button-primary p-2">
                    <app-icon
                        class="w-5 h-5 mr-2"
                        name="add-circle"
                        stroke
                    ></app-icon>
                    New Email
                </a>
            </template>

            <template slot="row" slot-scope="{ row, property, value, reload }">
                <!-- Summary column represents mobile representation of table data. -->
                <div v-if="property === 'summary'" class="flex">
                    <div class="flex flex-col flex-auto text-gray-600">
                        <p>{{ row.title }}</p>
                        <p class="text-sm text-purple">{{ row.subject }}</p>
                        <p class="text-sm">Scheduled by {{ row.updated_by }} on {{ row.scheduled_at.formattedDatetime }}</p>
                        <p class="text-sm text-purple">{{ row.recipients_count.total }} ({{ row.recipients_count.unique }} unique)</p>
                    </div>

                    <div class="flex items-center ml-4" @click.stop>
                        <base-dropdown v-cloak placement="bottom-end">
                            <template slot="trigger" slot-scope="{ triggerFunction }">
                                <button class="flex button-icon" @click.stop="triggerFunction">
                                    <app-icon name="navigation-menu-horizontal" stroke></app-icon>
                                </button>
                            </template>

                            <div class="w-64 bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden">
                                <a
                                    class="flex items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out"
                                    :href="getEditRoute(row.id)"
                                >
                                    Edit
                                </a>

                                <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="unscheduleCampaign(row)">Unschedule & move to Drafts</button>
                                <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="duplicateCampaign(event, row.id)">Duplicate & save in Drafts</button>

                                <delete-item
                                    class="flex items-center px-4 py-2 cursor-pointer font-medium hover:text-red hover:bg-gray-100 transition duration-150 ease-in-out"
                                    :confirmation-message="{ title: 'Are you sure?', text: 'Do you really want to delete this scheduled campaign?' }"
                                    :endpoint="route('api.events.campaigns.destroy', { event, campaign: row.id })"
                                    @delete-item="reload"
                                >
                                    Delete
                                </delete-item>
                            </div>
                        </base-dropdown>
                    </div>
                </div>

                <div v-else-if="property === 'recipients_count'">
                    {{ value.estimated_total }}
                    <span v-if="row.audience !== 'custom'">or more</span>
                </div>

                <div v-else-if="property === 'scheduled_at'">
                    {{ value.formattedDatetime }}
                </div>

                <!-- Note that the Options column is a "fake" column/property name and it does not actually have a label. -->
                <div
                    v-else-if="property === 'options'"
                    class="flex items-center mx-4"
                    @click.stop
                >
                    <base-dropdown v-cloak placement="bottom-end">
                        <template slot="trigger" slot-scope="{ triggerFunction }">
                            <button class="flex button-icon" @click.stop="triggerFunction">
                                <app-icon name="navigation-menu-horizontal"></app-icon>
                            </button>
                        </template>

                        <div class="w-64 bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden">
                            <a
                                class="flex items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out"
                                :href="getEditRoute(row.id)"
                            >
                                Edit
                            </a>

                            <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="unscheduleCampaign(row)">Unschedule & move to Drafts</button>
                            <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="duplicateCampaign(event, row.id)">Duplicate & save in Drafts</button>

                            <delete-item
                                class="flex items-center px-4 py-2 cursor-pointer font-medium hover:text-red hover:bg-gray-100 transition duration-150 ease-in-out"
                                :confirmation-message="{ title: 'Are you sure?', text: 'Do you really want to delete this scheduled campaign?' }"
                                :endpoint="route('api.events.campaigns.destroy', { event, campaign: row.id })"
                                @delete-item="reload"
                            >
                                Delete
                            </delete-item>
                        </div>
                    </base-dropdown>
                </div>

                <div v-else>
                    {{ value }}
                </div>
            </template>
        </infinite-data-table>

    </div>
</template>

<script>
import axios from '@/util/axios';
import DuplicateCampaign from '@/mixins/DuplicateCampaign';

export default {
    name: 'ScheduledCampaigns',

    mixins: [DuplicateCampaign],

    props: {
        event: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            chosenColumns: [],
            dataSource: this.route('api.events.campaigns.scheduled.index', this.event),
            filter: {
                term: ''
            },
            sort: {}
        };
    },

    computed: {
        columns () {
            return [
                {
                    label: 'Email',
                    property: 'summary',
                    desktop: false,
                    mobile: true,
                    alwaysActive: true
                },
                {
                    label: 'Title',
                    property: 'title',
                    desktop: true,
                    sortable: true
                },
                {
                    label: 'Subject Line',
                    property: 'subject',
                    desktop: true,
                    sortable: true
                },
                {
                    label: 'Scheduled To Send',
                    property: 'scheduled_at',
                    desktop: true,
                    sortable: true
                },
                {
                    label: 'Scheduled By',
                    property: 'updated_by',
                    desktop: true,
                    sortable: false
                },
                {
                    label: 'Recipients',
                    property: 'recipients_count',
                    desktop: true,
                    sortable: false
                },
                {
                    label: '',
                    property: 'options',
                    desktop: true,
                    sortable: false
                }
            ];
        }
    },

    methods: {
        getEditRoute (campaignId) {
            return this.route('events.campaigns.edit', {
                event: this.event,
                campaign: campaignId
            });
        },

        handleChangedSort ([newSort]) {
            this.sort = newSort || {};
        },

        redirectToEdit ({ id }) {
            window.location.href = this.getEditRoute(id);
        },

        unscheduleCampaign (campaign) {
            axios.put(this.route('api.events.campaigns.unschedule', [this.event, campaign]))
                .then(() => {
                    this.$toasted.global.success({
                        message: 'This has been unscheduled and moved to Drafts.',
                        onComplete: () => {
                            window.location = this.route('events.campaigns.drafts.index', this.event);
                        }
                    });
                }).catch(() => {
                    this.$toasted.global.error('There was an error unscheduling this campaign.');
                });
        }
    }
};
</script>
