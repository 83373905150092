/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'religion-bible-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.26 23.25H5.51l-.01-.001a2.256 2.256 0 01-2.25-2.26s0 0 0 0"/><path pid="1" d="M6.263.75h-.01c-1.66 0-3 1.34-3 3 0 0 0 0 0 0V21h0a2.24 2.24 0 012.24-2.26h15.75v-18zM19.76 23.25v-4.5M9.26 8.25h6M12.26 5.25v9"/></g>'
  }
})
