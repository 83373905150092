/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-graph-infected-stable-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.787 22.5h-21v-21M20.822 15.23l2.39 2.39-2.4 2.39M11.28 3.75a3 3 0 100 6 3 3 0 100-6zM10.79 1.5h1M11.29 1.5v2.25M14.65 2.68l.7.71M15 3.04l-1.59 1.59M16.54 6.25v1M16.54 6.75h-2.25M15.35 10.11l-.7.71M15 10.46l-1.59-1.59M11.79 12h-1M11.29 12V9.75M7.93 10.82l-.71-.71M7.57 10.46l1.6-1.59M6.04 7.25v-1M6.04 6.75h2.25M7.22 3.39l.71-.71M7.57 3.04l1.6 1.59"/><path pid="1" d="M.78 17.625h.5-.01c1.64-.01 3.26-.39 4.73-1.12l2.42-1.21h-.01c1.78-.9 3.89-.9 5.68-.01l2.42 1.21h0c1.47.73 3.09 1.11 4.73 1.11h1.92"/></g>'
  }
})
