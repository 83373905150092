<script>
import EditorColorPickerButton from './EditorColorPickerButton';

export default {
    name: 'EditorTextBackgroundColorPicker',

    extends: EditorColorPickerButton,

    computed: {
        defaultColorHex () {
            return 'FFFFFF';
        }
    },

    methods: {
        getMarkColor () {
            return this.editor.getMarkAttrs('textBackgroundColor').color;
        },

        setMarkColor (color) {
            this.editor
                .commands
                .textBackgroundColor({ color: color.hex });
        }
    }
};
</script>
