/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up-chevron': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sort ascending</desc><path pid="0" _fill="currentColor" d="M23.75 16.793L12.76 4.32c-.41-.43-1.09-.44-1.51-.04-.02.01-.03.02-.04.03L.23 16.782v-.01c-.37.4-.33 1.03.08 1.39l.02.02 1.85 1.55c.42.36 1.06.31 1.43-.12l8.16-9.46v-.01c.09-.11.24-.12.35-.03l.02.02 8.168 9.45c.17.2.42.33.69.34.26.02.53-.07.74-.24l1.85-1.56v-.01c.41-.36.47-.98.12-1.4-.01-.01-.02-.02-.02-.03z"/>'
  }
})
