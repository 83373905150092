/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>delete remove</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .74a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5zM7.5 16.5l9-9M16.5 16.5l-9-9"/></g>'
  }
})
