<template>
    <div>
        <div v-for="answer in answers" :key="answer.id">
            <template v-if="question.slug === 'file-uploads' && answer.meta.displayable">
                <img
                    v-tippy
                    content="File uploads are not editable by the host."
                    :src="answer.value"
                    class="max-w-full cursor-not-allowed"
                >
            </template>
            <template v-else-if="question.slug === 'file-uploads'">
                <a
                    v-tippy
                    content="File uploads are not editable by the host."
                    class="text-purple underline cursor-not-allowed"
                    :href="answer.value"
                    target="_blank"
                >{{ answer.meta.filename }}</a>
            </template>
        </div>
    </div>
</template>

<script>
import CustomQuestionEditComponent from '@/mixins/CustomQuestionEditComponent';

export default {
    name: 'CustomQuestionAnswerFileUploads',

    mixins: [CustomQuestionEditComponent]
};
</script>
