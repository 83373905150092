/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'move-dots': {
    width: 16,
    height: 16,
    viewBox: '0 0 6 12',
    data: '<defs/><circle pid="0" cx="2" cy="2" r="1" _fill="currentColor"/><circle pid="1" cx="5" cy="2" r="1" _fill="currentColor"/><circle pid="2" cx="2" cy="6" r="1" _fill="currentColor"/><circle pid="3" cx="5" cy="6" r="1" _fill="currentColor"/><circle pid="4" cx="2" cy="10" r="1" _fill="currentColor"/><circle pid="5" cx="5" cy="10" r="1" _fill="currentColor"/>'
  }
})
