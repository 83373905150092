<template>
    <div class="flex flex-col grow">
        <div class="flex flex-wrap">
            <form-field-wrapper label="Section Icon">
                <icon-picker
                    v-model="settings.icon"
                    class="text-gray-800 w-11 h-11"
                ></icon-picker>
            </form-field-wrapper>

            <form-field-wrapper label="Section Title">
                <input
                    v-model="settings.title"
                    class="form-field"
                >
            </form-field-wrapper>

            <form-field-wrapper>
                <toggle-switch v-model="settings.displayTitle">
                    Display Section Icon &amp; Title
                </toggle-switch>

                <toggle-switch
                    v-model="settings.displayInMenu"
                    class="mt-4"
                >
                    Show Section In Menu
                </toggle-switch>
            </form-field-wrapper>

            <form-field-wrapper label="Locations">
                <draggable-list
                    v-model="settings.locations"
                    :editable="true"
                    unique-property="id"
                    label-property="name"
                    switchable-property="active"
                    @edit-item="editLocation"
                    @delete-item="deleteLocation"
                ></draggable-list>

                <a
                    class="button-text button-info mt-4"
                    role="button"
                    @click="createLocation"
                >
                    <app-icon
                        name="add-circle"
                        class="h-5 w-5 mr-2"
                    ></app-icon>
                    <span>Add a Location</span>
                </a>
            </form-field-wrapper>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WallLocationsBlockGeneralSettings',

    props: {
        settings: {
            type: Object,
            required: true
        }
    },

    methods: {
        createLocation () {
            this.$emit('create-location');
        },

        deleteLocation (index) {
            this.settings.locations.splice(index, 1);
        },

        editLocation (location) {
            this.$emit('edit-location', location);
        }
    }
};
</script>
