/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-hoodie-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sweatshirt warm</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.75 11.25v10.5s-1.5 1.5-6.75 1.5-6.75-1.5-6.75-1.5v-10.5"/><path pid="1" d="M6.75 2.25l-1.82.77v-.01a5.976 5.976 0 00-3.64 5.2l-.51 9.73H.77c-.03.41.29.76.7.78h3.74M18.75 18.75h3.7l-.01-.001c.41 0 .74-.34.75-.75 0-.02-.01-.03-.01-.05l-.51-9.74h0a5.995 5.995 0 00-3.63-5.2l-1.83-.78"/><path pid="2" d="M17.25 2.25v0c0 1.65-1.35 3-3 3h-4.5l-.01-.001c-1.66-.01-3-1.35-3-3v-.011c0-.83.67-1.5 1.5-1.5h7.5c.82 0 1.49.67 1.49 1.5zM9.75 5.25v3M14.25 5.25v6"/></g>'
  }
})
