/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-dress-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9.69 3.75h-.01a2.56 2.56 0 00-2.44 3.37l.83 2.49v0l-.01-.01c2.56.65 5.25.65 7.82 0v0l.83-2.5v-.01c.44-1.35-.28-2.8-1.63-3.25-.27-.09-.54-.14-.82-.14h0c-.51-.01-.98.25-1.26.67l-.43.64V5a.763.763 0 01-1.26-.01l-.43-.64h0c-.29-.43-.76-.68-1.26-.68z"/><path pid="1" d="M8.087 9.554L4.69 20.971h-.01c-.14.48.11.98.58 1.17h0c4.35 1.47 9.08 1.47 13.43-.01h-.01a1 1 0 00.58-1.18L15.87 9.53"/><path pid="2" d="M5.683 17.65h0a17.42 17.42 0 0012.63 0M9 3.76V.75M15 3.76V.75"/></g>'
  }
})
