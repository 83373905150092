<template>
    <div>
        <div
            v-if="!loading"
            v-tippy
            class="relative overflow-hidden"
            :content="tooltip"
        >
            <slot></slot>

            <input
                type="file"
                accept="image/*"
                class="absolute inset-0 z-10 w-full h-full opacity-0 cursor-pointer"
                style="font-size: 0"
                @change="selectedImage"
            >
        </div>

        <slot v-else name="loading">
            <app-icon
                class="m-4 h-4 w-4 text-gray-500 fill-current"
                name="loader"
            ></app-icon>
        </slot>

        <image-cropper
            v-if="cropping"
            :image="uploadedImage"
            @input="croppedImage"
            @finish-crop="finishCroppingImage"
            @cancel="cancelCropping"
        ></image-cropper>
    </div>
</template>

<script>
import { get, round } from 'lodash';
import { serialize } from 'object-to-formdata';
import axios from '@/util/axios';
import ImageOrientationUtilities from '@/mixins/ImageOrientationUtilities';

export default {
    name: 'ImageUploader',

    mixins: [ImageOrientationUtilities],

    props: {
        additionalData: {
            type: Object,
            default: () => {
                return {};
            }
        },

        cropOnUpload: {
            type: Boolean,
            default: false
        },

        remoteUpload: {
            type: Boolean,
            default: false
        },
        tooltip: {
            type: String,
            default: ''
        },
        url: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            uploadedImage: {
                src: null,
                url: null,
                width: null,
                height: null
            },
            cropping: false,
            loading: false
        };
    },

    methods: {
        cancelCropping () {
            this.cropping = false;
            this.loading = false;
            this.$emit('cancel');
        },

        selectedImage (event) {
            if (event.target.files && event.target.files[0]) {
                const selectedImage = event.target.files[0];

                if (selectedImage.size > window.maxFileSize) {
                    const selectedImageSizeInMb = round(selectedImage.size / 1024 / 1024, 2);
                    const maxImageSizeInMb = round(window.maxFileSize / 1024 / 1024, 2);

                    App.alert().error(
                        'Image too large',
                        `You've tried to upload an image with a size of ${selectedImageSizeInMb}MB. Please choose an image under ${maxImageSizeInMb}MB.`
                    );

                    return;
                }

                this.loadImageBase64Data(selectedImage);
            }
        },

        loadImageBase64Data (image) {
            this.loading = true;

            this.getImageExifOrientation(image, (sourceOrientation) => {
                const reader = new FileReader();

                reader.onload = ({ target }) => {
                    this.resetImageOrientation(target.result, sourceOrientation, (base64Image, imageBlob, width, height) => {
                        this.uploadedImage.src = base64Image;
                        this.uploadedImage.fileName = image.name;
                        this.uploadedImage.file = imageBlob;
                        this.uploadedImage.width = width;
                        this.uploadedImage.height = height;

                        if (this.cropOnUpload) {
                            this.startCroppingImage();
                        } else {
                            this.uploadImage();
                        }
                    });
                };

                reader.readAsDataURL(image);
            });
        },

        uploadImage (image = null) {
            if (image) {
                this.uploadedImage.src = image.src;
                this.uploadedImage.file = image.file;
            }

            if (this.remoteUpload) {
                axios.post(this.url, serialize({
                    image: this.uploadedImage.file,
                    ...this.additionalData
                })).then(({ data }) => {
                    this.$emit('upload-image', data);
                    this.loading = false;
                })
                    .catch((error) => {
                        if (get(error, 'response.data.errors.image.0')) {
                            this.$toasted.global.error(get(error, 'response.data.errors.image.0'));
                        } else if (get(error, 'response.status') === 413) {
                            this.$toasted.global.error('Uploaded file size is too large.');
                        } else {
                            this.$toasted.global.error('There was an error uploading the image.');
                        }

                        this.loading = false;
                    });
            } else {
                this.$emit('upload-image', this.uploadedImage);
                this.loading = false;
            }
        },

        croppedImage ({ src, file }) {
            this.uploadedImage.src = src;
            this.uploadedImage.file = file;
        },

        startCroppingImage () {
            this.$nextTick(() => {
                this.cropping = true;
            });
        },

        finishCroppingImage () {
            this.cropping = false;
            this.uploadImage();
        }
    }
};
</script>
