export default {
    data () {
        return {
            cardElementError: null,
            stripeObject: null
        };
    },

    computed: {
        cardElementOptions () {
            return {
                style: {
                    base: {
                        color: '#525252',
                        fontFamily: 'Open Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                        fontSize: '16px',
                        lineHeight: '1.5',
                        letterSpacing: 'normal',

                        '::placeholder': {
                            color: '#B0B0B0'
                        }
                    },
                    invalid: {
                        color: '#D64345'
                    }
                }
            };
        }
    },

    methods: {
        async confirmCardSetup (clientSecret, billingDetails = {}) {
            const { setupIntent, error } = await this.stripeObject.instance.confirmCardSetup(
                clientSecret, {
                    payment_method: {
                        card: this.stripeObject.card,
                        billing_details: {
                            name: this.auth().user().name,
                            email: this.auth().user().email,
                            ...billingDetails
                        }
                    }
                }
            );

            if (error) {
                this.cardElementError = error.message;

                return false;
            }

            return setupIntent.payment_method;
        },

        initializeStripeCard (cardElementRef) {
            const instance = Stripe(window.stripeKey);

            const card = instance.elements().create('card', this.cardElementOptions);

            card.on('change', ({ complete, error }) => {
                this.isCardIncomplete = !complete;
                this.cardElementError = error == null ? null : error.message;
            })
                .mount(cardElementRef);

            this.$set(this, 'stripeObject', {
                instance,
                card
            });
        }
    }
};
