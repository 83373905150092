<template>
    <div>
        <slot v-bind="{ scheduleToOpen }"></slot>

        <app-modal v-model="isModalVisible" :title="`Schedule ${registrationRsvpLabels.registrationLabel} to open`">
            <p class="mb-4">Publish event website now and schedule {{ registrationRsvpLabels.registrationLabel }} to open at:</p>

            <form-field-wrapper :error="scheduleToOpenDataForm.errors.get('opened_at')" class="-mb-6">
                <date-time-picker v-model="scheduleToOpenDataForm.opened_at"></date-time-picker>
            </form-field-wrapper>
            <form-field-wrapper :error="scheduleToOpenDataForm.errors.get('opened_at_timezone_id')">
                <timezone-picker v-model="scheduleToOpenDataForm.opened_at_timezone_id" guess-timezone></timezone-picker>
            </form-field-wrapper>

            <p class="my-4">Customize message to display before open:</p>
            <div v-theme="['form.text', 'form.background']" class="p-4 rounded-md advanced-editor group">
                <tiptap-editor
                    v-model="scheduleToOpenDataForm.unavailableMessage"
                    hint-classes="-m-2"
                    with-hint
                ></tiptap-editor>
            </div>

            <template #footer="{ close }">
                <stateful-button
                    class="button-primary ml-4"
                    :loading="scheduleToOpenDataForm.processing"
                    @click="scheduleOpeningTime"
                >
                    {{ scheduleButtonText }}
                </stateful-button>

                <button class="button" @click="close">Cancel</button>
            </template>

            <template v-if="showCancelScheduledOpenOption" #footer-split-options>
                <button class="text-purple hover:text-purple-light" @click="closeFormRegistrations">Cancel scheduled open</button>
            </template>
        </app-modal>

        <published-event-info-modal v-model="showPublishedEventInfoModal"></published-event-info-modal>

        <requires-upgrade-modal v-model="isUpgradeModalVisible" :used-features="usedFeaturesList"></requires-upgrade-modal>
        <comp-requires-action v-model="isCompRequiresActionModal" @addPaidProduct="openPrimaryBlock"></comp-requires-action>
        <event-requires-stripe-action v-model="isEventRequiresStripeActionModal" @removePaidProduct="openPrimaryBlock"></event-requires-stripe-action>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { get as getValue, merge } from 'lodash';
import Form from '@/validation/Form';
import RegistrationRSVPLabels from '@/mixins/RegistrationRSVPLabels';
import CheckEventPublishabilityInfo from '@/mixins/CheckEventPublishabilityInfo';
import FormOpensAtClosesAtFormattedDateTime from '@/mixins/FormOpensAtClosesAtFormattedDateTime';

export default {
    name: 'ScheduleToOpenAction',

    mixins: [CheckEventPublishabilityInfo, FormOpensAtClosesAtFormattedDateTime, RegistrationRSVPLabels],

    data () {
        return {
            isModalVisible: false,
            showPublishedEventInfoModal: false,
            scheduleToOpenDataForm: new Form({
                opened_at: null,
                opened_at_timezone_id: null,
                unavailableMessage: null
            })
        };
    },

    watch: {
        'scheduleToOpenDataForm.processing': function (newVal) {
            this.$emit('action-in-progress', newVal);
        },

        isModalVisible () {
            this.loadDataToForm();
        }
    },

    mounted () {
        this.loadDataToForm();
    },

    computed: {
        event: get('Event/event'),
        form: get('Form/form'),
        wall: get('Wall/wall'),

        scheduleButtonText () {
            return this.form.opened_at ? 'Update' : 'Publish & Schedule Open';
        },

        showCancelScheduledOpenOption () {
            return this.form.opened_at && !this.form.isOpened && !this.form.isClosed;
        }
    },

    methods: {
        loadDataToForm () {
            this.scheduleToOpenDataForm = new Form({
                opened_at: this.form.opened_at,
                opened_at_timezone_id: this.form.opened_at_timezone_id ? this.form.opened_at_timezone_id : this.event.timezone_id,
                unavailableMessage: getValue(this.form.settings, 'unavailableMessage', 'We\'re not accepting registrations just yet!')
            });
        },

        closeFormRegistrations () {
            const closeFormData = new Form({
                closedMessage: getValue(this.form.settings, 'closedMessage', 'Sorry, we\'re no longer accepting registrations or RSVPs!')
            });

            closeFormData.post(this.route('api.events.schedule-form-registrations-to-close', this.event)).then(({ data }) => {
                this.isModalVisible = false;

                this.$nextTick(() => {
                    merge(this.event, data);
                    merge(this.form, data.form);
                    merge(this.wall, data.wall);
                });

                this.$toasted.global.success(`Your ${this.registrationRsvpLabels.registrationLabel} is currently closed. It will no longer open automatically. You can open it any time.`);
            }).catch(() => {
                this.$toasted.global.error(`There was a problem closing this event form for ${this.registrationRsvpLabels.registrationLabel}s.`);
            });
        },

        async scheduleToOpen () {
            const result = await this.getPublishabilityInfo();

            if (!result.publishable) {
                this.showBlockerModal(result);
                return;
            }

            this.isModalVisible = true;
        },

        scheduleOpeningTime () {
            this.scheduleToOpenDataForm.post(this.route('api.events.schedule-form-registrations-to-open', this.event)).then(({ data }) => {
                this.isModalVisible = false;

                this.$nextTick(() => {
                    merge(this.event, data);
                    merge(this.form, data.form);
                    merge(this.wall, data.wall);

                    this.showPublishedEventInfoModal = true;

                    const successMessage = this.registrationOpensIn
                        ? `Your ${this.registrationRsvpLabels.registrationLabel} is scheduled to open in ${this.registrationOpensIn}.`
                        : `Your ${this.registrationRsvpLabels.registrationLabel} will open immediately.`;

                    this.$toasted.global.success(successMessage);
                });
            }).catch(() => {
                this.$toasted.global.error('There was a problem with scheduling this event to open.');
            });
        }
    }
};
</script>
