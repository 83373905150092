/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-decorations-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 1.75l22.5 3.59M2.293 2l1.01 4.153-.01-.01c.18.55.78.86 1.34.68.21-.07.39-.21.52-.39L7.4 2.79M9.494 3.144l1.01 4.156-.01-.01c.18.55.78.86 1.34.68.21-.07.39-.21.52-.39l2.25-3.64M16.69 4.292l1.01 4.15-.01-.01c.18.55.78.86 1.34.68.21-.07.39-.21.52-.39l2.25-3.64M.76 19.79l22.49-8.69M2.3 19.2l3.143 3.26h0c.46.41 1.18.37 1.59-.1.15-.18.25-.4.27-.64l.12-4.54M9.507 16.411l3.14 3.26-.01-.01c.46.41 1.18.37 1.59-.1.15-.18.25-.4.27-.63l.12-4.54M16.71 13.626l3.14 3.266-.01-.01c.46.41 1.17.37 1.59-.1.15-.18.25-.4.27-.64l.12-4.54"/></g>'
  }
})
