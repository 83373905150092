/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flags-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.32 3.03L9.03 20.97M15.756 4.417l7.26 2.94-.01-.01c.19.07.28.29.2.48a.49.49 0 01-.17.18l-2.46 1.37h-.01c-.62.34-.91 1.06-.71 1.73l.8 2.705h0c.05.19-.06.4-.26.46-.09.02-.17.01-.25-.02l-7.26-2.95M7.68 3.03l7.29 17.94M8.24 4.417L.98 7.35v-.01c-.2.07-.29.29-.21.48.03.07.09.14.16.18l2.45 1.37h0c.61.34.9 1.06.7 1.73l-.8 2.705h-.01c-.06.19.05.4.25.46.08.02.16.01.24-.02l7.26-2.95"/></g>'
  }
})
