<template>
    <div class="flex flex-col items-center justify-center -mx-6">
        <div v-if="loadingMealPreferences" class="flex flex-col items-center justify-center space-y-2 min-h-xs">
            <app-icon name="loader" class="w-8 h-8 fill-current"></app-icon>

            <p class="text-center">
                We're preparing your meal preferences, it will be ready in just a moment.
            </p>
        </div>

        <div v-else-if="!hasMealPreferences" class="flex flex-col items-center justify-center space-y-2 min-h-xs">
            <p>You haven't added any meal preferences.</p>
            <a
                class="text-purple"
                href="https://help.rsvpify.com/en/articles/4367616-meal-preferences"
                target="_blank"
            >What are meal preferences?</a>
        </div>

        <div v-else class="flex flex-col w-full">
            <a
                v-for="mealPreference in mealPreferences"
                :key="mealPreference.id"
                role="button"
                class="group flex items-center px-6 py-2 hover:bg-gray-100"
                @click="insertMergeTag(mealPreference)"
            >
                <span class="flex-1">{{ mealPreference.title }}</span>
                <span class="button-icon button-primary hidden group-hover:flex">
                    <app-icon name="add-circle" stroke></app-icon>
                </span>
            </a>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import getValue from 'lodash/get';
import DynamicMergeTags from '@/mixins/DynamicMergeTags';
import axios from '@/util/axios';

export default {
    name: 'MealPreferenceMergeTags',

    mixins: [DynamicMergeTags],

    data () {
        return {
            mealPreferences: [],
            loadingMealPreferences: false
        };
    },

    mounted () {
        this.retrieveMealPreferences();
    },

    computed: {
        ...get('Event/*'),

        hasMealPreferences () {
            return this.mealPreferences.length > 0;
        }
    },

    methods: {
        generateMergeTags (value) {
            return `{meal_preference|id=${value.id}|title=${value.title}}`;
        },

        retrieveMealPreferences () {
            this.loadingMealPreferences = true;

            axios.get(this.route('api.events.campaigns.merge-tags-data.meal-preferences', this.event))
                .then(({ data }) => {
                    this.mealPreferences = data.data.map((mealPreference) => {
                        return {
                            id: mealPreference.id,
                            title: getValue(mealPreference, 'settings.title')
                        };
                    });
                }).finally(() => {
                    this.loadingMealPreferences = false;
                });
        }
    }
};
</script>
