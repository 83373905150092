import WebFont from 'webfontloader';

(function () {
    WebFont.load({
        google: {
            families: [
                'Abhaya Libre:400,700',
                'Anton',
                'Archivo Black',
                'Comfortaa:400,700',
                'Dancing Script',
                'Josefin Sans:400,400i,700,700i',
                'Josefin Slab:400,700,700i',
                'Lato:400,400i,700,700i',
                'Lobster Two:400,700,700i',
                'Montserrat:400,400i,700,700i',
                'Old Standard TT:400,400i,700',
                'Open Sans:300,400,400i,600,700,700i,800',
                'Overlock:400,400i,700,700i',
                'PT Serif:400,400i,700,700i',
                'Pacifico',
                'Parisienne',
                'Playfair Display:400,400i,700,700i',
                'Raleway:400,400i,700,700i',
                'Roboto:400,400i,700,700i',
                'Rochester',
                'Rokkitt:400,700',
                'Sacramento',
                'Satisfy',
                'Source Sans Pro:400,400i,700,700i',
                'Source Serif Pro:400,700',
                'Ubuntu:400,400i,700,700i',
                'Vollkorn:400,400i,700,700i'
            ]
        }
    });
}(document));
