<template>
    <div class="group relative">
        <slot></slot>

        <input
            ref="file"
            type="file"
            name="spreadsheet"
            accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            class="absolute inset-0 z-10 w-full h-full opacity-0 cursor-pointer"
            style="font-size: 0"
            @change="uploadFile"
        >
    </div>
</template>

<script>
import vapor from 'laravel-vapor';
import axios from '@/util/axios';

export default {
    name: 'ImportInviteesFile',

    props: {
        event: {
            type: Object,
            required: true
        }
    },

    methods: {
        uploadFile () {
            this.$emit('has-errors', false);
            this.$toasted.global.success('Uploading your invitee list.');

            vapor.store(this.$refs.file.files[0], {
                baseURL: ''
            }).then((file) => {
                axios.post(this.route('api.invite-list.import.upload-spreadsheet', this.event), {
                    key: file.key,
                    filename: this.$refs.file.files[0].name,
                    content_type: this.$refs.file.files[0].type
                }).then(({ data }) => {
                    window.location.href = this.route('invite-list.import.update-mapping', [this.event, data.id]);
                }).catch(({ response }) => {
                    if (response.status === 422) {
                        this.$emit('has-errors', true);
                    }
                });
            }).catch(() => {
                this.$toasted.global.error('There was an error uploading your invitee list. Please try again.');
            });
        }
    }
};
</script>
