/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'move-hand-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>drag drop grab</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.5 6.747v-1.5 0c0-.83-.68-1.5-1.5-1.5-.83 0-1.5.67-1.5 1.5v-.75 0c0-.83-.68-1.5-1.5-1.5-.83 0-1.5.67-1.5 1.5v.75c0-.83-.68-1.5-1.5-1.5-.83 0-1.5.67-1.5 1.5v5.25h-.01c-1.66 0-3 1.34-3 3 0 0 0 0 0 0h-.01a6.95 6.95 0 001.3 4.08l1.1 1.53h0a4.48 4.48 0 003.65 1.88h3.81c2.82 0 5.12-2.3 5.12-5.13 0-.01-.01-.01-.01-.01V6.71v0c0-.83-.68-1.5-1.5-1.5-.83 0-1.5.67-1.5 1.5zM7.5 10.5v2.25M10.5 9V5.25M13.5 9V5.25M16.5 9V6.75"/></g>'
  }
})
