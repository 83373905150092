<script>
import tinycolor from 'tinycolor2';
import EditorColorPickerButton from './EditorColorPickerButton';

export default {
    name: 'EditorTextColorPicker',

    extends: EditorColorPickerButton,

    data () {
        return {
            hasAlpha: false
        };
    },

    methods: {
        getMarkColor () {
            const markColor = this.editor.getMarkAttrs('textColor').color;

            if (markColor) {
                /**
                 * If the selected text has a textColor mark, return it's color
                 * value
                 */
                return markColor;
            }

            /**
             * If the textColor mark has not been applied to the selected,
             * attempt to calculate the current color from the DOM computed
             * style
             */
            let selectedDomNode = this.editor
                .view
                .domAtPos(this.editor.view.state.selection.anchor)
                .node;

            if (selectedDomNode.nodeType === Node.TEXT_NODE) {
                selectedDomNode = selectedDomNode.parentNode;
            }

            if (selectedDomNode.nodeType === Node.ELEMENT_NODE) {
                return tinycolor(window.getComputedStyle(selectedDomNode).color).toHexString();
            }

            /**
             * If calculating the color from the DOM was unsuccessful, return
             * grey as the default pre-selected color for the picker
             */
            return '#525252';
        },

        setMarkColor (color) {
            this.editor
                .commands
                .textColor({ color: color.hex });
        }
    }
};
</script>
