/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trash-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>delete bin recycle remove</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 21H6.74a1.51 1.51 0 01-1.5-1.5s0 0 0 0V6h13.5v13.5c0 .82-.68 1.5-1.5 1.5zM9.75 16.5v-6M14.25 16.5v-6M2.25 6h19.5M14.25 3H9.74c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0V6h7.5V4.5h-.001c0-.83-.68-1.5-1.5-1.5h-.001z"/></g>'
  }
})
