/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vip-diamond-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.5 3L4.25 7.5v9.75l8.25 4.5 8.25-4.5V7.5L12.5 3z"/><path pid="1" d="M12.5 7.5L8 9.75V15l4.5 2.25L17 15V9.75L12.5 7.5zM12.5 7.5V3M12.5 17.25v4.5M17 15l3.75 2.25M17 9.75l3.75-2.25M8 9.75L4.25 7.5M8 15l-3.75 2.25M3.5.75v4.5M1.25 3h4.5M21.5 20.25v3M20 21.75h3"/></g>'
  }
})
