/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-christmas-tree-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>winter</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21 18.75l-4.5-6h2.25l-4.5-6h3L12 .75l-5.25 6h3l-4.5 6H7.5l-4.5 6h18zM12 18.75v4.5"/><path pid="1" d="M13.125 9.75h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M12.75 10.125h0c-.01-.21.16-.38.37-.38M13.125 10.5h-.01a.386.386 0 01-.38-.38s0 0 0 0M13.5 10.125v0c0 .2-.17.375-.375.375M12 4.5h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M11.625 4.875h0c-.01-.21.16-.38.37-.38M12 5.25h-.01a.386.386 0 01-.38-.38s0 0 0 0"/><path pid="4" d="M12.37 4.875h-.001c0 .2-.17.375-.375.375h-.001M11.25 12.75a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/></g>'
  }
})
