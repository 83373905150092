<template>
    <section
        v-tippy
        class="panel flex flex-col focus:outline-none"
        :content="tooltips.section"
        :class="styles.section"
    >
        <page-header icon="list-check-stroke" label="Invite List Settings"></page-header>

        <item-modified-warning v-if="form.isModified()"></item-modified-warning>

        <form-field-wrapper
            :should-show-error="form.errors.has('security')"
            :error="form.errors.get('security')"
            class="w-full md:w-2/3"
        >
            <select-list v-model="form.security">
                <select-option
                    id="public"
                    class="my-2"
                    :disabled="disabled"
                >
                    Public event
                    <p class="text-sm text-gray-600">Anyone with your event's link may register or RSVP</p>
                </select-option>

                <select-option
                    id="private"
                    class="my-2"
                    :disabled="disabled"
                >
                    Invite-only private event
                    <p class="text-sm text-gray-600">Attendees must be on your Invite List to RSVP or register</p>
                </select-option>
            </select-list>
        </form-field-wrapper>

        <form-field-wrapper
            :should-show-error="form.errors.has('allowNameEditing')"
            :error="form.errors.get('allowNameEditing')"
            class="w-full md:w-2/3"
        >
            <toggle-switch v-model="form.allowNameEditing" :disabled="disableAllowNameEditingOption">
                <div class="flex items-center space-x-4">
                    <span
                        v-tippy
                        :content="allowNameEditingTooltip"
                        class="border-b border-dashed border-gray-500 cursor-help"
                    >
                        Allow invitees to edit pre-set names
                    </span>

                    <img
                        v-if="!form.allowNameEditing && !canPreventInviteeNameEditing"
                        v-tippy
                        class="h-5 w-5 inline"
                        :src="asset('images/account/rsvpify-premium.svg')"
                        :content="`Preventing guests from editing their pre-set names in not included in your current plan (${auth().user().plan.name}). Try it free!`"
                    >
                </div>
            </toggle-switch>
        </form-field-wrapper>

        <p class="my-8 leading-normal italic text-gray-600">
            Require a password to access your event in Event Settings > <a :href="route('settings.event.security-and-passwords', event)" class="underline text-teal hover:text-teal-dark hover:cursor-pointer">Security & Passwords</a>
        </p>

        <form-field-wrapper
            label="Invitee lookup by"
            :should-show-error="form.errors.has('lookupBy')"
            :error="form.errors.get('lookupBy')"
            class="w-full md:w-2/3"
        >
            <select-list v-model="form.lookupBy">
                <select-option
                    id="name-and-email"
                    tooltip="Invitees will locate their invitation by entering their name and email address as listed on your Invite List. Recommended."
                    class="my-2"
                    :disabled="disabled"
                >
                    <span class="border-b border-dashed border-gray-500 cursor-help">
                        Invitee name and email
                    </span>
                </select-option>

                <select-option
                    id="name"
                    tooltip="Invitees can locate their invitation by entering their name as listed on your Invite List. Best if you don’t know most invitee email addresses in advance."
                    class="my-2"
                    :disabled="disabled"
                >
                    <span class="border-b border-dashed border-gray-500 cursor-help">
                        Invitee name only
                    </span>
                </select-option>

                <select-option
                    id="email"
                    tooltip="Invitees can locate their invitation by entering their email address as listed on your Invite List. Most secure. Requires you to know the email address of all invitees."
                    class="my-2"
                    :disabled="disabled"
                >
                    <span class="border-b border-dashed border-gray-500 cursor-help">
                        Invitee email only
                    </span>
                </select-option>
            </select-list>
        </form-field-wrapper>

        <form-field-wrapper
            :should-show-error="form.errors.has('partialLookup')"
            :error="form.errors.get('partialLookup')"
            class="w-full md:w-2/3"
        >
            <toggle-switch v-model="form.partialLookup" :disabled="disablePartialNameMatchToggle">
                <span
                    v-tippy
                    content="RSVPify will look for similar names on your Invite List (e.g. “Mike” v.s. “Michael”) if no exact matches are found. If disabled, invitees will need to enter their name exactly as you have it listed on your Invite List in order to locate their invitation. Disabling this option provides additional security, but may make it harder for guests to find their invitation on your list."
                    class="border-b border-dashed border-gray-500 cursor-help"
                >
                    Allow partial name match
                </span>
            </toggle-switch>
        </form-field-wrapper>

        <div class="flex flex-col md:flex-row justify-end mt-6 md:mt-0">
            <button
                v-if="form.isModified()"
                class="button w-full md:w-auto disabled:opacity-50 disabled:cursor-not-allowed"
                :disabled="disabled"
                @click="form.restore()"
            >
                Cancel
            </button>

            <stateful-button
                class="button-primary mt-2 md:mt-0 ml-0 md:ml-2 w-full md:w-auto"
                :disabled="disableSaveButton"
                :loading="form.processing"
                @click="saveSettings"
            >
                Save
            </stateful-button>
        </div>
    </section>
</template>

<script>
import Form from '@/validation/Form';

export default {
    name: 'InviteListSettings',

    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        event: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            form: new Form({
                ...this.event.invite_list.settings
            })
        };
    },

    computed: {
        allowNameEditingTooltip () {
            return this.event.hasNamelessInvitees
                ? 'Your invite list includes at least one invitee with JUST a first or last name listed.'
                : 'If disabled, guests won’t be able to change their name from that listed on your Invite List. Adds additional security, but prevents invitees from fixing possible errors in the spelling of their name. Invitees will still be edit their title/salutation, even if you’ve pre-added them.';
        },

        canPreventInviteeNameEditing () {
            if (!this.auth().plan()) {
                return false;
            }

            return this.auth().plan().features.InviteeNameEditing.allowed;
        },

        disableAllowNameEditingOption () {
            return this.disabled || this.event.hasNamelessInvitees || this.form.security === 'public';
        },

        disablePartialNameMatchToggle () {
            return this.disabled || this.form.lookupBy === 'email';
        },

        disableSaveButton () {
            return this.disabled || !this.form.isModified();
        },

        styles () {
            return {
                section: {
                    'cursor-not-allowed': this.disabled
                }
            };
        },

        tooltips () {
            return {
                section: this.disabled ? 'These settings are unavailable because you are not currently using an Invite List.' : null
            };
        }
    },

    watch: {
        'form.lookupBy': function (selectedOption) {
            if (selectedOption === 'email') {
                this.form.partialLookup = false;
            }
        },

        'form.security': function (selectedOption) {
            if (selectedOption === 'public') {
                this.form.allowNameEditing = true;
            }
        }
    },

    methods: {
        saveSettings () {
            this.form.put(this.route('api.invite-list.settings.update', this.event))
                .then(() => {
                    this.$toasted.global.success('Invite list settings saved.');
                    window.location.reload();
                });
        }
    }
};
</script>
