/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star-add-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>favorite like create plus new</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 11.25a6 6 0 100 12 6 6 0 100-12zM17.25 14.25v6M14.25 17.25h6"/><path pid="1" d="M22.732 10.112l.27-.28v-.01c.31-.32.31-.83 0-1.14a.84.84 0 00-.51-.24l-6.44-.64-3.35-6.63-.01-.01a.819.819 0 00-1.11-.36c-.16.07-.28.2-.36.35l-3.35 6.62-6.44.63h-.01c-.45.03-.78.42-.74.86.01.19.09.37.23.5l5.3 5.253-1.96 7.13h-.01c-.12.43.14.87.57.99.19.05.39.02.57-.06l3.73-1.86"/></g>'
  }
})
