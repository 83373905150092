/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-warning-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.017.75h-3.04M10.5.75v2.6M4.678 2.533L3.606 3.606 2.533 4.678M3.61 3.61l1.83 1.83M.75 8.983v3.02M.75 10.5h2.6M2.533 16.322l1.073 1.07 1.072 1.07M3.61 17.39l1.83-1.83M18.467 4.678l-1.08-1.072-1.08-1.073M17.39 3.61l-1.83 1.83M10.14 13.68a.43.43 0 100 .86.43.43 0 100-.87zM8.98 6.81a1.51 1.51 0 100 3.03 1.51 1.51 0 100-3.04z"/><path pid="1" d="M17.284 8.252l-.01-.01a7.16 7.16 0 00-9.04-4.55c-3.75 1.23-5.79 5.28-4.55 9.03a7.12 7.12 0 005.91 4.84M17.25 20.25h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M17.25 18v-3"/><path pid="2" d="M23.063 20.682l-.01-.01c.43.87.08 1.94-.8 2.38-.25.12-.52.18-.8.18h-8.46v-.001c-.98 0-1.78-.8-1.78-1.78-.01-.28.06-.55.18-.8l4.22-8.451v-.01a1.76 1.76 0 012.37-.8c.34.17.62.45.79.79z"/></g>'
  }
})
