/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'animal-paw-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.26 15v0c0-2.08-1.68-3.75-3.75-3.75-2.08 0-3.75 1.67-3.75 3.75h-.01c-1.66 0-3 1.34-3 3 0 1.65 1.34 3 3 3h-.01c.66-.02 1.3-.19 1.88-.5h-.01c1.16-.61 2.55-.61 3.71-.01l-.01-.01c.58.31 1.23.48 1.89.5l-.01-.001c1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3zM3.26 9a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM7.76 3a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM15.26 3a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM20.51 9a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5z"/></g>'
  }
})
