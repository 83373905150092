<template>
    <div>
        <button
            class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
            @click="showModal = true"
        >
            <app-icon name="list-numbers" class="h-4 w-4 mr-3"></app-icon>
            <p>Edit Additional Guests Allowed ({{ bulkActionData.totalSelected | number }})</p>
        </button>

        <app-modal
            v-model="showModal"
            title="Edit Additional Guests Allowed"
            :z-index="110"
        >
            <div class="h-40 overflow-y-auto">
                <div class="flex items-center text-sm text-gray-600">
                    <div class="ml-4 mr-2 uppercase">Additional guests allowed:</div>

                    <select-list v-model="additionalGuestsType" class="flex">
                        <select-option
                            id="unlimited"
                            label="Unlimited"
                            class="mx-2"
                            small
                        ></select-option>

                        <select-option
                            id="none"
                            label="None"
                            class="mx-2"
                            small
                        ></select-option>

                        <select-option
                            id="up-to"
                            label="Up to:"
                            class="mx-2"
                            small
                        ></select-option>

                        <input
                            v-model.number="additionalGuestsLimit"
                            type="number"
                            min="0"
                            class="form-field w-16 no-spinner"
                            :disabled="isAdditionalGuestsInputDisabled"
                            :class="{ 'opacity-50 cursor-not-allowed': isAdditionalGuestsInputDisabled }"
                        >

                        <div class="flex shrink items-center ml-2">
                            <img
                                v-if="premiumAdditionalGuestsFeatureTooltip"
                                v-tippy
                                class="h-5 w-5 inline"
                                :src="asset('images/account/rsvpify-premium.svg')"
                                :content="premiumAdditionalGuestsFeatureTooltip"
                            >
                        </div>
                    </select-list>
                </div>
            </div>

            <template #footer="{ close }">
                <stateful-button
                    :loading="saving"
                    class="button-primary"
                    @click="save"
                >
                    Save
                </stateful-button>

                <button class="button" @click="close">
                    Cancel
                </button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import axios from '@/util/axios';
import { get } from '@node_modules/vuex-pathify';
import HasAdditionalGuests from './HasAdditionalGuests';

export default {
    name: 'BulkEditGuestLimit',

    mixins: [
        HasAdditionalGuests
    ],

    props: {
        bulkActionData: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            saving: false,
            showModal: false,
            additionalGuestsType: 'unlimited',
            additionalGuestsLimit: null
        };
    },

    computed: {
        ...get('Event/*'),

        endpoint () {
            return this.route('api.events.invitees.bulk.edit-guests', this.event);
        },

        isAdditionalGuestsInputDisabled () {
            return this.additionalGuestsType !== 'up-to';
        }
    },

    methods: {
        save () {
            this.saving = true;

            axios.post(this.endpoint, {
                all: this.bulkActionData.all,
                ids: this.bulkActionData.ids,
                filters: this.bulkActionData.filters,
                predicates: this.bulkActionData.predicates,
                data: {
                    additional_guests_type: this.additionalGuestsType,
                    additional_guests_limit: this.additionalGuestsLimit
                }
            })
                .then(() => {
                    this.$toasted.global.success('Additional guests limits updated.');
                    this.$emit('rows-updated');
                    this.showModal = false;
                }, () => {
                    this.$toasted.global.error('An error occurred while updating the additional guest limits.');
                })
                .finally(() => {
                    this.saving = false;
                });
        }
    }
};
</script>
