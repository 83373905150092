import startsWith from 'lodash/startsWith';

export default {
    methods: {
        normalizeUrl (url) {
            if (!url) return url;

            const checkableUrl = url.toLowerCase();

            // Protocols are not case-sensitive, which is why a lower-case URL must be used
            if (startsWith(checkableUrl, 'http://') || startsWith(checkableUrl, 'https://') || startsWith(checkableUrl, 'mailto:')) {
                return url;
            }

            return `https://${url}`;
        }
    }
};
