<template>
    <section class="panel">
        <page-header icon="chart-analytics-stroke" label="Analytics &amp; Custom Scripts">
            <a :href="route('settings.event', event)" class="button-icon button-sm">
                <app-icon name="close"></app-icon>
            </a>
        </page-header>

        <div v-if="! hasAccessToCustomScripts" class="flex p-4 mb-4 bg-gray-50 items-center space-x-4">
            <img
                class="w-12 h-12"
                :src="asset('images/account/rsvpify-premium.svg')"
                alt="RSVPify Premium"
            >
            <div class="grow">
                <p class="font-xl text-gray-800 font-semibold">Please upgrade.</p>
                <p>Your {{ event.plan.name }} plan doesn't include Analytics &amp; Custom Scripts.  Please upgrade to access these features.</p>
            </div>
            <a
                :href="route('account.upgrade.index')"
                class="button button-info"
                @click.stop
            >Upgrade Now</a>
        </div>

        <div class="space-y-8" :class="disabledClasses">
            <div class="flex space-x-4">
                <a
                    class="w-16 h-16 flex-none"
                    href="https://www.google.com/analytics"
                    target="_blank"
                >
                    <img
                        :src="asset('images/vendor/google/analytics-square.png')"
                        alt="Google Analytics Logo"
                    >
                </a>

                <div>
                    <h2 class="text-gray-600 uppercase tracking-wide font-semibold">Google Analytics</h2>
                    <p class="text-gray-600">Enter a Universal Analytics Tracking ID, Google Analytics 4 measurement ID, or Google Tag Manager ID.  RSVPify will automatically send analytics data to Google and include <a
                        target="_blank"
                        href="http://help.rsvpify.com/en/articles/5604378-what-conversion-event-tracking-is-configured-with-rsvpify-s-google-analytics-integration"
                        class="text-teal hover:text-teal-dark underline"
                    >conversion event tracking.</a> If you include multiple ID’s, we’ll send data to all analytics properties.</p>
                </div>
            </div>

            <div class="space-y-4">
                <form-field-wrapper
                    label="Universal Analytics Tracking ID"
                    :error="form.errors.get('universal_tracking_id')"
                    :should-show-error="form.errors.has('universal_tracking_id')"
                >
                    <input
                        v-model="form.universal_tracking_id"
                        class="form-field w-80"
                        placeholder="UA-12345678-9"
                    >
                </form-field-wrapper>

                <form-field-wrapper
                    label="Google Analytics 4 Measurement ID"
                    :error="form.errors.get('ga4_analytics_tracking_id')"
                    :should-show-error="form.errors.has('ga4_analytics_tracking_id')"
                >
                    <input
                        v-model="form.ga4_analytics_tracking_id"
                        class="form-field w-80"
                        placeholder="G-1234567890"
                    >
                </form-field-wrapper>

                <form-field-wrapper
                    label="Google Tag Manager Container ID"
                    :error="form.errors.get('gtm_container_id')"
                    :should-show-error="form.errors.has('gtm_container_id')"
                >
                    <input
                        v-model="form.gtm_container_id"
                        class="form-field w-80"
                        placeholder="GTM-1234567"
                    >
                </form-field-wrapper>
            </div>

            <div class="flex space-x-4">
                <a
                    class="w-16 h-16 flex-none"
                    href="https://www.facebook.com/business/ads"
                    target="_blank"
                >
                    <img
                        :src="asset('images/vendor/facebook/square.png')"
                        alt="Facebook Logo"
                    >
                </a>

                <div>
                    <h2 class="text-gray-600 uppercase tracking-wide font-semibold">Facebook Pixel</h2>
                    <p class="text-gray-600">Track traffic from Facebook Ads campaigns by entering a Facebook Pixel ID. You can find your Facebook pixel ID <a
                        target="_blank"
                        href="https://www.facebook.com/business/help/402791146561655?id=1205376682832142"
                        class="text-teal hover:text-teal-dark underline"
                    >here.</a></p>
                </div>
            </div>

            <form-field-wrapper
                label="Pixel ID"
                :error="form.errors.get('fb_pixel_id')"
                :should-show-error="form.errors.has('fb_pixel_id')"
            >
                <input
                    v-model="form.fb_pixel_id"
                    class="form-field w-80"
                    placeholder="123456789012345"
                >
            </form-field-wrapper>

            <div>
                <h2 class="text-gray-600 uppercase tracking-wide font-semibold">Custom Scripts</h2>
                <p class="text-gray-600">Add 3rd-party analytics code or custom scripts here.  Scripts will be placed within the <code>&lt;head&gt;</code> tag.</p>
            </div>

            <form-field-wrapper>
                <textarea
                    v-model="form.custom_scripts"
                    class="form-field"
                    rows="5"
                ></textarea>
            </form-field-wrapper>

            <div class="flex mt-6 justify-end">
                <button
                    class="button flex-1 md:flex-none mr-4"
                    :disabled="!form.isModified()"
                    @click="restore"
                >
                    Cancel
                </button>

                <stateful-button
                    class="button-primary flex-1 md:flex-none"
                    :disabled="!form.isModified()"
                    :loading="form.processing"
                    @click="save"
                >
                    Save
                </stateful-button>
            </div>
        </div>
    </section>
</template>

<script>
import { get } from 'lodash';
import Form from '@/validation/Form';

export default {
    props: {
        event: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            form: new Form({
                custom_scripts: get(this.event, 'settings.customScripts.customScripts'),
                ga4_analytics_tracking_id: get(this.event, 'settings.customScripts.ga4AnalyticsTrackingId'),
                gtm_container_id: get(this.event, 'settings.customScripts.gtmContainerId'),
                fb_pixel_id: get(this.event, 'settings.customScripts.fbPixelId'),
                universal_tracking_id: get(this.event, 'settings.customScripts.universalTrackingId')
            })
        };
    },

    computed: {
        disabledClasses () {
            return {
                'opacity-50': !this.hasAccessToCustomScripts,
                'pointer-events-none': !this.hasAccessToCustomScripts
            };
        },

        hasAccessToCustomScripts () {
            return this.event.plan.features.CustomScripts.type === 'enabled';
        }
    },

    methods: {
        restore () {
            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            const onConfirm = () => {
                this.form.restore();
            };

            App.alert().confirm(
                'Are you sure?',
                'Are you sure you want to discard all your changes?',
                attributes,
                onConfirm
            );
        },

        save () {
            this.form.post(this.route('api.events.update-custom-scripts', this.event), this.formData)
                .then(() => {
                    this.$toasted.global.success('Your analytics and scripts settings have been applied!');
                });
        }
    }
};
</script>
