<template>
    <div>
        <button
            v-tippy
            class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium"
            :class="{ 'opacity-25': !event.isPublished, 'hover:text-purple hover:bg-gray-100': event.isPublished }"
            :content="!event.isPublished ? 'Your event has not been published yet.' : null"
            @click="confirmMark"
        >
            <app-icon
                :name="iconName"
                class="h-4 w-4 mr-3"
                stroke
            ></app-icon>
            <p>{{ label }} ({{ bulkActionData.totalSelected | number }})</p>
        </button>
    </div>
</template>

<script>
import axios from '@/util/axios';

export default {
    props: {
        bulkActionData: {
            type: Object,
            required: true
        },

        event: {
            type: Object,
            required: true
        },

        label: {
            type: String,
            required: true
        },

        state: {
            type: String,
            required: true
        }
    },

    computed: {
        availableProducts () {
            return this.event.products.filter((product) => {
                return product.type === 'primary' && product.category !== 'add-on';
            });
        },

        confirmButtonText () {
            return `Mark ${this.bulkActionData.formatted.totalSelected} as ${this.formattedState.toLowerCase()}`;
        },

        confirmationMessage () {
            return `Marking ${this.bulkActionData.formatted.totalSelected} invitee(s) as ${this.formattedState.toLowerCase()}. This may take a few minutes.`;
        },

        endpoint () {
            return this.route('api.events.invitees.bulk.mark-state', this.event);
        },

        hasToSelectProduct () {
            return !this.isRsvpEvent && this.state !== 'not-attending';
        },

        formattedState () {
            const states = {
                'not-attending': 'Not Attending',
                attending: 'Attending',
                maybe: 'Maybe Attending'
            };

            return states[this.state];
        },

        iconName () {
            const icons = {
                attending: 'check-circle',
                'not-attending': 'close-circle',
                maybe: 'help-circle'
            };

            return icons[this.state];
        },

        isRsvpEvent () {
            return this.event.setup_type === 'rsvp';
        },

        markMessage () {
            return this.isRsvpEvent || this.state === 'not-attending'
                ? `Do you want to mark ${this.bulkActionData.formatted.totalSelected} invitee(s) as ${this.formattedState.toLowerCase()}? `
                : `Assign ticket type to ${this.bulkActionData.formatted.totalSelected} invitee(s):`;
        }
    },

    methods: {
        async confirmMark () {
            if (!this.event.isPublished) {
                window.open(this.route('publish-invite.index', this.event), '_blank');

                return;
            }

            let html = `<div>${this.markMessage}</div>`;

            if (this.hasToSelectProduct) {
                const selectOptions = this.availableProducts.map((product) => {
                    return `<option value="${product.id}">${product.title}</option>`;
                });

                html += `<div class="my-2"><select class="form-field" id="bulk-mark-selected-product">${selectOptions}</select></div>`;
            }

            const result = await this.checkInviteesForEmailExistance();
            html += result.emailExists
                ? `<div class="mt-6"><label><input type="checkbox" class="mr-2" id="bulk-mark-send-confirmation-emails">Send confirmation emails</label></div>`
                : `<div class="mt-6"><label title="There is no email address associated with this guest. You can add one manually." class="cursor-not-allowed"><input type="checkbox" class="mr-2" id="bulk-mark-send-confirmation-emails" disabled>Send confirmation emails</label></div>`;

            App.alert().confirm(
                `Are you sure?`,
                ``,
                {
                    html,
                    confirmButtonText: this.confirmButtonText,
                    cancelButtonText: 'CANCEL',
                    preConfirm () {
                        return new Promise((resolve) => {
                            resolve({
                                selectedProductId: document.querySelector('#bulk-mark-selected-product')?.value,
                                sendConfirmationEmails: document.querySelector('#bulk-mark-send-confirmation-emails')?.checked
                            });
                        });
                    }
                },
                this.bulkMark
            );
        },

        bulkMark ({ value }, overCapacityPolicy = undefined) {
            const { selectedProductId, sendConfirmationEmails } = value;

            const data = {
                state: this.state,
                sendConfirmationEmails
            };

            if (overCapacityPolicy) {
                data.overCapacityPolicy = overCapacityPolicy;
            }

            if (this.hasToSelectProduct && selectedProductId) {
                data.product_id = selectedProductId;
            }

            axios.post(this.endpoint, {
                all: this.bulkActionData.all,
                ids: this.bulkActionData.ids,
                filters: this.bulkActionData.filters,
                predicates: this.bulkActionData.predicates,
                data
            })
                .then(() => {
                    this.$toasted.global.success(this.confirmationMessage);

                    this.$emit('rows-updated');
                }, (error) => {
                    if (error.response.status === 403) {
                        App.alert().paymentRequired(
                            'Please upgrade.',
                            `Your ${window.user.plan.name} plan includes <strong>${window.user.plan.features.RsvpLimit.max}</strong> RSVPs. Please upgrade for more RSVPs.`
                        );

                        return;
                    }

                    if (error.response.status === 400) {
                        this.overCapacityBulkMark(selectedProductId, sendConfirmationEmails, error.response.data);

                        return;
                    }

                    this.$toasted.global.error('An error occured while setting the reply for the selected invitees.');
                });
        },

        overCapacityBulkMark (selectedProductId, sendConfirmationEmails, overCapacityData) {
            const selectedOptions = { value: { selectedProductId, sendConfirmationEmails } };

            App.alert().confirm(
                null,
                `Continuing will exceed your capacity limits as currently set (${overCapacityData.quantityRemaining} remaining). How do you want to proceed?`,
                {
                    confirmButtonText: `Increase capacity limit to accommodate ${overCapacityData.overBy} additional guests`,
                    cancelButtonText: overCapacityData.quantityRemaining > 0
                        ? `Only mark ${overCapacityData.quantityRemaining} of ${this.bulkActionData.formatted.totalSelected} as attending`
                        : `Cancel`,
                    customClass: {
                        confirmButton: 'button button-primary mx-2 my-4',
                        cancelButton: 'button mx-2 my-4 w-full'
                    },
                    showCloseButton: true
                },
                () => {
                    this.bulkMark(selectedOptions, 'increase-capacity');
                },
                (result) => {
                    if (result.dismiss === 'close') {
                        return;
                    }

                    if (overCapacityData.quantityRemaining > 0) {
                        this.bulkMark(selectedOptions, 'remain-under-limit');
                    }
                }
            );
        },

        async checkInviteesForEmailExistance () {
            const response = await axios.post(this.route('api.events.invitees.check-for-email-existance', this.event), {
                all: this.bulkActionData.all,
                ids: this.bulkActionData.ids,
                predicates: this.bulkActionData.predicates
            });

            return response.data;
        }
    }
};
</script>
