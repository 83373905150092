<template>
    <portal to="modal-portal-target">
        <tabbed-modal
            v-bind="{ value, ...$attrs }"
            :z-index="1050"
            v-on="$listeners"
        >
            <template #content="{ initialize }">
                <tabbed-modal-item
                    id="upload"
                    v-slot="{ isActive }"
                    label="Upload Image"
                    @hook:mounted="initialize"
                >
                    <upload-image-tab v-if="isActive" @upload-image="selectImage">
                        <template #recommendations>
                            <slot name="recommendations"></slot>
                        </template>
                    </upload-image-tab>
                </tabbed-modal-item>

                <tabbed-modal-item
                    id="my-images"
                    v-slot="{ isActive }"
                    label="My Images"
                >
                    <my-images-tab v-if="isActive" @select-image="selectImage"></my-images-tab>
                </tabbed-modal-item>

                <tabbed-modal-item
                    id="image-library"
                    v-slot="{ isActive }"
                    label="Stock Image Library"
                >
                    <stock-image-library-tab v-if="isActive" @select-image="selectImage"></stock-image-library-tab>
                </tabbed-modal-item>
            </template>

            <template #footer>
                <portal-target name="upload-modal-footer" slim></portal-target>
            </template>
        </tabbed-modal>
    </portal>
</template>

<script>

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        selectImage (urls) {
            this.$emit('select-image', urls);
        }
    }
};
</script>
