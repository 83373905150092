/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings-vertical-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<rect pid="0" x=".75" y=".75" width="22.5" height="22.5" rx="1.5" ry="1.5" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.75 11.265v-6M15.75 18.765v-3"/><path pid="2" d="M18 13.515a2.25 2.25 0 11-2.25-2.25 2.25 2.25 0 012.25 2.25z" fill-rule="evenodd" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="3" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.25 9.765v9"/><path pid="4" d="M10.5 7.515a2.25 2.25 0 10-2.25 2.25 2.25 2.25 0 002.25-2.25z" fill-rule="evenodd" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>'
  }
})
