/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'layout-three-columns-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="21" height="21" x="1.5" y="1.497" rx="1.5"/><path pid="1" d="M7.5 1.5v21M16.5 1.5v21"/></g>'
  }
})
