<template>
    <div>
        <div class="fixed flex justify-center items-center h-14 bg-white top-0 inset-x-0 z-40 shadow-lg border-b-4 border-yellow">
            <span class="badge badge-warning mr-4">
                <div
                    v-tippy
                    content="Preview your event's experience, submit test RSVPs or registrations, and manually submit replies on behalf of your guest(s). Let co-hosts and stakeholders preview your event prior to publication."
                    class="border-b border-dashed border-yellow pb-1"
                >
                    Preview Mode
                </div>
            </span>
            <button
                class="button-text button-primary"
                @click="openSharePreviewModal"
            >
                Share Preview
            </button>
        </div>

        <app-modal
            v-model="showSharePreviewModal"
            title="Share Preview Link"
        >
            <div class="text-center">
                <div class="mb-4">Co-hosts can preview <span class="font-semibold">{{ event.name }}</span> at:</div>
                <div class="mb-4">{{ previewUrl }}</div>
                <div class="mb-4 text-sm text-gray-500">Preview mode will be available to only event administrators after this event is published.</div>
            </div>

            <template #footer="{ close }">
                <copy-to-clipboard
                    :copy="event.previewUrl"
                    @copied="copyLinkAndCloseModal(close)"
                >
                    <button class="button button-primary">
                        Copy Link &amp; Close
                    </button>
                </copy-to-clipboard>

                <button class="button mr-2" @click="close">Cancel</button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    name: 'RsvpPagePreviewBanner',

    data () {
        return {
            showSharePreviewModal: false
        };
    },

    computed: {
        ...get('Event/*'),

        previewUrl () {
            return this.event && this.event.previewUrl
                ? `${this.event.previewUrl.substr(0, 70)}...`
                : 'loading';
        }
    },

    methods: {
        copyLinkAndCloseModal (closeHandler) {
            closeHandler();
            this.showCopiedAlert();
        },

        openSharePreviewModal () {
            this.showSharePreviewModal = true;
        },

        showCopiedAlert () {
            this.$toasted.global.success('Preview link has been copied to clipboard.');
        }
    }
};
</script>
