/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12.729 1.2l3.346 6.62 6.44.63h0a.79.79 0 01.49 1.36l-5.3 5.253 1.96 7.138h0c.11.43-.15.87-.58.99-.2.05-.4.02-.58-.06l-6.53-3.24-6.52 3.22v-.01a.82.82 0 01-1.1-.37.783.783 0 01-.06-.58l1.96-7.14L.94 9.75l-.01-.01A.806.806 0 01.92 8.6a.79.79 0 01.5-.24l6.44-.64 3.34-6.63h-.01c.2-.41.69-.57 1.1-.36.15.07.27.2.35.35z"/>'
  }
})
