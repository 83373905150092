<template>
    <div>
        <slot v-bind="{ confirm }"></slot>

        <div ref="confirmHtml" class="hidden text-left">
            <p>
                Are you sure you want to resume your subscription? We'd love to have you back! By confirming, you will continue with your previous billing cycle and retain access to premium features.
            </p>

            <div class="mt-4">
                <strong>Subscription Details:</strong>
                <ul class="list-disc space-y-1 mt-4">
                    <li>Plan: <strong>{{ plan.name }} ({{ plan.price | currency(true) }})</strong></li>
                    <li>Billing Cycle: <strong>{{ plan.isMonthly ? 'Monthly' : 'Annual' }}</strong></li>
                    <li>Next Billing Date: <strong>{{ resumeDate }}</strong></li>
                </ul>
            </div>

            <p class="mt-4">If you proceed, your account will be automatically charged based on your chosen billing cycle.</p>
        </div>
    </div>
</template>

<script>
import axios from '@/util/axios';

export default {
    props: {
        plan: {
            type: Object,
            required: true
        },

        resumeDate: {
            type: String,
            required: true
        }
    },

    methods: {
        confirm () {
            const confirmHtml = this.$refs.confirmHtml.cloneNode(true);
            confirmHtml.classList.remove('hidden');

            App.alert().confirm(
                'Resume your plan?',
                null,
                {
                    confirmButtonText: 'Confirm (resumes subscription)',
                    html: confirmHtml.outerHTML
                },
                () => {
                    axios.post(this.route('api.account.resume-plan'))
                        .then(() => {
                            this.$toasted.global.success({
                                message: 'Your subscription was resumed successfully.',
                                onComplete: () => {
                                    window.location.href = this.route('account.show');
                                }
                            });
                        })
                        .catch(() => {
                            this.$toasted.global.error('Something went wrong resuming your subscription.  Please try again.');
                        });
                }
            );
        }
    }
};
</script>
