<template>
    <div class="p-2 bg-white">
        <comment-field
            v-model="newCommentForm.content"
            :disabled="newCommentForm.processing"
            @keypress.enter.prevent="leaveComment"
        >
            <template #button>
                <button v-if="isSendButtonVisible" @click="leaveComment">
                    <img class="w-6 h-6" :src="asset('images/svg/send.svg')">
                </button>
            </template>
        </comment-field>

        <transition-group name="slide-vertical" tag="div">
            <div
                v-for="comment in comments"
                :key="comment.id"
                class="flex flex-col lg:flex-row mb-4"
            >
                <div class="flex lg:flex-1">
                    <logo-rsvpify-full
                        v-if="comment.is_system"
                        :show-text="false"
                        class="w-9 h-9 rounded-full"
                    ></logo-rsvpify-full>

                    <img
                        v-else
                        class="w-9 h-9 rounded-full"
                        :src="getAvatarSource(comment)"
                    >

                    <div class="ml-2">
                        <div class="flex">
                            <div class="text-gray-700 font-semibold">{{ getCommentedAccount(comment) }}</div>

                            <button
                                v-if="isDeletable(comment)"
                                class="button-icon button-sm ml-2"
                                @click="deleteComment(comment)"
                            >
                                <app-icon
                                    class="w-5 h-5"
                                    name="trash"
                                    stroke
                                ></app-icon>
                            </button>
                        </div>

                        <div class="text-gray-500">{{ comment.content }}</div>
                    </div>
                </div>

                <div
                    v-tippy
                    class="ml-11 lg:ml-2 text-gray-300"
                    :title="getCommentTooltip(comment)"
                >
                    {{ comment.relativeTime }}
                </div>
            </div>
        </transition-group>

        <div v-if="isNoCommentsTextVisible" class="text-center text-gray-500">
            No comments yet.
        </div>

        <infinite-loading ref="infiniteLoading" @infinite="loadNextPage">
            <div slot="spinner" class="text-center m-2">
                <app-icon class="h-6 w-6 text-gray-500" name="loader"></app-icon>
            </div>

            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </div>
</template>

<script>
import uniqBy from 'lodash/uniqBy';
import Swal from 'sweetalert2';
import InfiniteLoading from 'vue-infinite-loading';
import axios from '@/util/axios';
import Form from '@/validation/Form';
import dateTimeTzFilter from '@/filters/DateTimeTzFilter';
import CommentField from './CommentField';

export default {
    components: {
        CommentField,
        InfiniteLoading
    },

    props: {
        inviteeGroupId: {
            type: Number,
            default: null
        },
        submissionId: {
            type: Number,
            default: null
        }
    },

    data () {
        return {
            comments: [],
            hasLazyLoadingCompleted: false,
            isNoCommentsTextVisible: false,
            newCommentForm: new Form({
                content: ''
            }),
            page: 1,
            state: 'loading',
            timezone: null
        };
    },

    computed: {
        isSendButtonVisible () {
            if (this.newCommentForm.processing) {
                return false;
            }

            return this.newCommentForm.content !== '';
        }
    },

    mounted () {
        // If this component is used within a tab, it will be mounted but it won't necessarily be visible on the screen
        // if a different tab is selected by default. This causes the `InfiniteLoading` component to mount as well and
        // to attempt a load of data. Because it might not be visible at the time, it's going to decide that a load is
        // not necessary, which causes it not to attempt any future loads. By detecting when the component becomes
        // visible for real, we can force it to attempt a reload and fix the infinite loading.
        new IntersectionObserver((entries) => {
            if (entries[0].intersectionRatio > 0) {
                this.$refs.infiniteLoading.attemptLoad();
            }
        }).observe(this.$el);
    },

    methods: {
        async confirmDelete () {
            const result = await Swal.fire({
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'button button-primary mx-2',
                    cancelButton: 'button mx-2'
                },
                icon: 'warning',
                showCancelButton: true,
                title: 'Are you sure?',
                text: 'Deleted notes cannot be recovered.'
            });

            return result.isConfirmed;
        },

        controlNoCommentsText () {
            this.isNoCommentsTextVisible = this.hasLazyLoadingCompleted && this.comments.length === 0;
        },

        async deleteComment (comment) {
            if (!await this.confirmDelete()) {
                return;
            }

            try {
                await axios.delete(this.route('api.comments.destroy', comment));

                this.comments = this.comments.filter(({ id }) => {
                    return id !== comment.id;
                });

                setTimeout(() => {
                    this.controlNoCommentsText();
                }, 450);
            } catch (error) {
                this.$toasted.global.error('An error ocurred while deleting the comment.');
            }
        },

        getAvatarSource (comment) {
            if (comment.user?.avatar_url) {
                return comment.user.avatar_url;
            }

            return this.asset('images/svg/avatar.svg');
        },

        getCommentedAccount (comment) {
            if (comment.is_system) {
                return `RSVPify`;
            }

            if (comment.user) {
                return comment.user.name;
            }

            return 'Deleted Account';
        },

        getCommentTooltip (comment) {
            return dateTimeTzFilter(comment.createdAt, this.timezone?.name);
        },

        isDeletable (comment) {
            return !comment.is_system && this.auth().user().id === comment.user?.id;
        },

        async leaveComment (event) {
            if (this.newCommentForm.content === '') {
                return;
            }

            try {
                const { data } = await this.newCommentForm.post(this.route('api.comments.store'), ['content'], {
                    invitee_group_id: this.inviteeGroupId,
                    submission_id: this.submissionId
                });

                this.newCommentForm.restore();

                this.comments.unshift(data.data);

                this.controlNoCommentsText();

                event.target.focus();
            } catch (error) {
                this.$toasted.global.error('An error occured while posting your comment.');
            }
        },

        async loadNextPage ($state) {
            const { data } = await axios.get(this.route('api.comments.index', {
                inviteeGroupId: this.inviteeGroupId,
                submissionId: this.submissionId,
                page: this.page
            }));

            this.controlNoCommentsText();

            if (data.data.length === 0) {
                this.hasLazyLoadingCompleted = true;
                this.controlNoCommentsText();
                $state.complete();
                return;
            }

            this.comments = uniqBy(this.comments.concat(data.data), 'id');
            this.page += 1;
            this.timezone = data.timezone;

            $state.loaded();
        }
    }
};
</script>
