/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transportation-airplane-boarding-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="16.5" height="22.5" x="3.75" y=".75" rx="1"/><path pid="1" d="M6.75 20.25h1.5M11.25 20.25h6M6.75 17.25h1.5M11.25 17.25h6M15.3 4.35l-4.95 6.6M9.6 5.7l3.9 1.05 2.1 3.45M8.7 10.65l1.65.3.75 1.5"/></g>'
  }
})
