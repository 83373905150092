/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-christmas-tree-ornaments-gift-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>winter</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.251 16.61l-2.15-3.19h0a.51.51 0 01.09-.72c.09-.08.21-.11.33-.11h1.14c.28.01.52-.21.53-.49 0-.11-.03-.22-.09-.31l-3.63-5.07h2.25L8.671.94l-.01-.01a.55.55 0 00-.77-.13c-.05.03-.1.07-.13.12L3.7 6.69h2.24l-4.5 6h3"/><path pid="1" d="M4.5 12.75L.85 18.68H.84a.5.5 0 00.09.71c.09.07.21.1.33.1h12.21M8.25 19.5v3.75M4.91 8.2l6.14-.68M3.57 14.27l8.54-.84M6.376 10.5h-.01c.2-.01.37.16.37.37M6 10.87h0c-.01-.21.16-.38.37-.38M6.376 11.25h-.01a.38.38 0 01-.38-.38l-.01-.01M6.751 10.87v0c0 .2-.17.375-.375.375M10.126 10.125h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M9.751 10.5h0c-.01-.21.16-.38.37-.38M10.126 10.87h-.01a.386.386 0 01-.38-.38s0 0 0 0M10.5 10.5v0c0 .2-.17.375-.375.375M6.376 16.5h-.01c.2-.01.37.16.37.37M6 16.875h0c-.01-.21.16-.38.37-.38M6.376 17.25h-.01a.38.38 0 01-.38-.38l-.01-.01M6.751 16.875v0c0 .2-.17.375-.375.375M10.126 16.5h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M9.751 16.875h0c-.01-.21.16-.38.37-.38M10.126 17.25h-.01a.386.386 0 01-.38-.38s0 0 0 0"/><path pid="4" d="M10.5 16.875v0c0 .2-.17.375-.375.375"/><rect pid="5" width="6" height="6" x="16.501" y="17.25" rx=".5"/><path pid="6" d="M19.5 17.25v6M16.5 20.25h6M19.5 17.25L18 15M21 15l-1.5 2.25M12 .75c3.71 1.28 8.17 1.98 11.24 1.5M21.75 2.4v2.85M21.75 5.25a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM16.5 1.94v1.81M16.5 3.75a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/></g>'
  }
})
