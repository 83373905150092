export default {
    methods: {
        itemDeleted (array, index) {
            array.splice(index, 1);
        },

        deleteError (message) {
            this.$toasted.global.error(message);
        }
    }
};
