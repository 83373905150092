import Echo from 'laravel-echo';

/**
 * Pusher is technically not used anywhere in this file, but it's import that
 * we import it.
 *
 * @see https://github.com/laravel/echo/pull/110
 */

// eslint-disable-next-line
import Pusher from 'pusher-js';

export default {
    computed: {
        $echo () {
            return new Echo({
                broadcaster: 'pusher',
                key: window.ably.key,
                wsHost: 'realtime-pusher.ably.io',
                wsPort: 443,
                disableStats: true,
                encrypted: true
            });
        }
    }
};
