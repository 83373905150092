export default {
    methods: {
        getMenuTitle (block) {
            if (block.properties.staticMenuTitle) {
                return `${block.title} Block`;
            }

            return block.pivot.settings.title;
        }
    }
};
