/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bread-slice': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>loaf food wheat gluten</desc><g _fill="currentColor"><path pid="0" d="M21.5 22a2 2 0 01-2 2h-15a2 2 0 01-2-2V9.408a3.989 3.989 0 01.23-1.339.5.5 0 00-.221-.6A4 4 0 014.5 0h15a4 4 0 011.991 7.468.5.5 0 00-.221.6 3.989 3.989 0 01.23 1.339zM4.5 2a2 2 0 000 4 1 1 0 01.832 1.555l-.5.744A1.989 1.989 0 004.5 9.408V21.5a.5.5 0 00.5.5h14a.5.5 0 00.5-.5V9.408a1.989 1.989 0 00-.336-1.108l-.5-.744A1 1 0 0119.5 6a2 2 0 000-4z"/><path pid="1" d="M6.9 13.9a1 1 0 01-.707-1.707l4-4a1 1 0 011.414 1.414l-4 4a.993.993 0 01-.707.293zM8.288 18.672a1 1 0 01-.707-1.707l7.424-7.425a1 1 0 011.414 1.414l-7.424 7.425a1 1 0 01-.707.293zM13.059 20.059a1 1 0 01-.707-1.707l4-4a1 1 0 011.414 1.414l-4 4a1 1 0 01-.707.293z"/></g>'
  }
})
