/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wedding-certificate-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M3.75 6.75h9.75M3.75 11.25h6M3.75 15.75h6M15.154 15.94l-.71 7.31 3.65-1.5 3.65 1.5-.7-7.22M11.25 20.25H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V2.25h0C.73 1.42 1.41.74 2.23.74h10.55-.01c.44-.01.86.19 1.15.53l2.94 3.54-.01-.01c.22.26.34.6.34.95V8.2"/><path pid="1" d="M22.6 11.2h0c-.83-.87-2.19-.9-3.05-.08a.5.5 0 00-.08.07l-1.34 1.38-1.34-1.39h0c-.83-.87-2.19-.9-3.05-.08a.5.5 0 00-.08.07v-.01c-.87.9-.87 2.32-.01 3.224l3.98 4.115-.01-.01c.24.26.66.27.92.02 0-.01.01-.02.02-.03l3.98-4.12h-.01c.85-.91.85-2.33-.01-3.23z"/></g>'
  }
})
