<template>
    <div>
        <div
            :id="blockId"
            v-theme="'wall.background'"
            class="relative image-block"
        >
            <div
                v-if="displayTitle"
                v-theme="['wall.title-color', 'wall.title-font', 'wall.title-font-size']"
                class="flex justify-center items-center flex-auto mb-6 pt-6 font-bold leading-normal"
            >
                <app-icon
                    v-if="icon"
                    :name="icon"
                    class="h-8 w-8 mr-4"
                ></app-icon>

                {{ title }}
            </div>

            <div
                class="image-block relative bg-cover bg-center bg-no-repeat"
                :style="style"
            >
                <img
                    v-if="showPlaceholderImage"
                    :src="asset('images/defaults/event-wall/image-block.png')"
                    alt="Placeholder image"
                >
                <wall-image-slider-block
                    v-else
                    v-bind="slider"
                    class="h-full"
                ></wall-image-slider-block>
            </div>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import WallBlock from '@/mixins/WallBlock';

export default {
    name: 'WallImageBlock',

    mixins: [WallBlock],

    props: {
        displayTitle: {
            type: Boolean,
            required: true
        },
        height: {
            type: [Number, String],
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        slider: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },

    computed: {
        style () {
            if (this.slider.images.length <= 1) {
                return {};
            }

            return {
                height: `${this.height}px`
            };
        },

        showPlaceholderImage () {
            return this.slider.images.length === 0;
        }
    }
};
</script>
