/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tic-tac-toe-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>game fun x o connect</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25.75v22.5M15.75.75v22.5M23.25 8.25H.75M23.25 15.75H.75M3.75 2.25a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM20.25 18.75a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM10.5 10.5l3 3M13.5 10.5l-3 3"/></g>'
  }
})
