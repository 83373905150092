<template>
    <div class="flex flex-col items-center justify-center">
        <div v-theme="themeClasses" class="flex items-center justify-center space-x-2">
            <button :class="styles.buttons.previousMonth" @click="switchMonth('back')">
                <app-icon
                    class="w-8 h-8"
                    name="arrow-left-chevron"
                    stroke
                ></app-icon>
            </button>

            <div class="flex items-center justify-center w-40">
                <p class="text-xl">{{ monthAndYearFormatted }}</p>
            </div>

            <button :class="styles.buttons.nextMonth" @click="switchMonth('forward')">
                <app-icon
                    class="w-8 h-8"
                    name="arrow-right-chevron"
                    stroke
                ></app-icon>
            </button>
        </div>

        <div class="grid grid-cols-7 gap-x-6 mt-4">
            <p
                v-for="(dayName, index) in weekDays"
                :key="index"
                class="flex p-4 items-center justify-center w-4 uppercase"
                :class="styles.textColor"
            >
                {{ dayName.substring(0, 3) }}
            </p>
        </div>

        <div v-if="loading" class="flex flex-col items-center justify-center py-16">
            <app-icon
                v-theme="themeClasses"
                name="loader"
                class="w-16 h-16"
                :class="styles.textColor"
            ></app-icon>
        </div>

        <div v-else class="relative grid grid-cols-7 gap-x-6 gap-y-4 mt-4">
            <div v-if="displayNoAvailabilityMessage" class="absolute flex items-center justify-center h-full w-full">
                <div v-theme="['form.accent', 'form.background']" class="px-4 py-2 rounded-md border-2 z-20">
                    {{ $t('label-no-availability') }}
                </div>
            </div>

            <button
                v-for="(day, index) in monthDays"
                :key="index"
                :class="styles.textColor"
                @click="selectDate(day)"
            >
                <div
                    :style="dateTheming(day)"
                    class="flex items-center justify-center w-4 h-4 p-4 rounded-full"
                    :class="dateCellStyle(day)"
                >
                    {{ getDayNumber(day) }}
                </div>
            </button>
        </div>
    </div>
</template>

<script>
import { DateTime } from 'luxon';
import { get } from 'vuex-pathify';
import axios from '@/util/axios';
import TranslateMonthName from '@/mixins/TranslateMonthName';

export default {
    name: 'TimeslotsCalendar',

    mixins: [TranslateMonthName],

    props: {
        themed: {
            type: Boolean,
            default: false
        },

        value: {
            type: Object,
            default: () => { return null; }
        },

        hostCalendarView: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            monthPickerDate: DateTime.local().toUTC(),
            selectedDate: null,
            loading: true,
            monthSlots: []
        };
    },

    computed: {
        ...get('Event/*'),

        formTheme: get('Form/form@settings.theme'),

        displayNoAvailabilityMessage () {
            return !this.isAnyDateAvailable && !this.hostCalendarView;
        },

        monthAndYearFormatted () {
            return `${this.translatedMonthName(this.monthPickerDate.month)} ${this.monthPickerDate.toFormat('y')}`;
        },

        monthDays () {
            const firstWeekDayInMonth = this.monthPickerDate.startOf('month').weekday;

            if (firstWeekDayInMonth === 7) {
                return this.monthSlots;
            }

            const blankDates = Array(firstWeekDayInMonth).fill({
                date: null,
                available: false
            });

            return blankDates.concat(this.monthSlots);
        },

        isAnyDateAvailable () {
            return this.monthDays.some((date) => {
                return this.isDateAvailable(date);
            });
        },

        isSelectedMonthCurrentMonth () {
            const localTime = DateTime.local();
            return this.monthPickerDate.year === localTime.year && this.monthPickerDate.month === localTime.month;
        },

        styles () {
            return {
                buttons: {
                    nextMonth: {
                        'hover:opacity-70': !this.loading,
                        'opacity-50 hover:cursor-not-allowed': this.loading,
                        'text-purple': !this.themed
                    },
                    previousMonth: {
                        'hover:opacity-70': !this.isSelectedMonthCurrentMonth,
                        'opacity-50 hover:cursor-not-allowed': this.loading || (!this.hostCalendarView && this.isSelectedMonthCurrentMonth),
                        'text-purple': !this.themed
                    }
                },
                textColor: {
                    'text-purple': !this.themed
                }
            };
        },

        themeClasses () {
            if (!this.themed) {
                return [];
            }

            return ['form.text'];
        },

        weekDays () {
            return [
                this.$t('label-sunday'),
                this.$t('label-monday'),
                this.$t('label-tuesday'),
                this.$t('label-wednesday'),
                this.$t('label-thursday'),
                this.$t('label-friday'),
                this.$t('label-saturday')
            ];
        }
    },

    watch: {
        value (newValue) {
            this.selectedDate = newValue;
        }
    },

    mounted () {
        if (this.event.settings.recurringTimeSlots.starting) {
            this.monthPickerDate = DateTime.local(
                this.event.settings.recurringTimeSlots.starting.year,
                this.event.settings.recurringTimeSlots.starting.month
            );
        }

        this.retrieveMonthSlots();
    },

    methods: {
        dateCellStyle (date) {
            return {
                'bg-opacity-0 opacity-50 hover:cursor-not-allowed': !this.isDateAvailable(date),
                'hover:cursor-pointer hover:opacity-70': this.isDateAvailable(date) && !this.isDateSelected(date),
                'bg-purple text-white': this.isDateSelected(date) && !this.themed
            };
        },

        dateTheming (date) {
            if (!this.themed) {
                return {};
            }

            return this.isDateSelected(date) ? { 'background-color': this.formTheme.colors.button } : {};
        },

        getDayNumber (day) {
            if (!day.date) {
                return;
            }

            return day.date.day;
        },

        isDateAvailable (date) {
            return date.available;
        },

        isDateSelected (date) {
            if (!this.selectedDate || !date.date) {
                return false;
            }

            return this.selectedDate.equals(date.date);
        },

        switchMonth (direction = 'forward') {
            if (this.loading) {
                return;
            }

            const goToPreviousMonth = direction === 'back';

            if (goToPreviousMonth && this.isSelectedMonthCurrentMonth && !this.hostCalendarView) {
                return;
            }

            this.monthPickerDate = this.monthPickerDate.plus({ months: goToPreviousMonth ? -1 : 1 });
            this.retrieveMonthSlots();
        },

        selectDate (date) {
            if (!this.isDateAvailable(date)) {
                return;
            }

            this.selectedDate = date.date;
            this.$emit('input', this.selectedDate);
        },

        retrieveMonthSlots () {
            this.loading = true;

            axios.post(this.route('api.events.timeslots.date-range-availability', [this.event]), {
                startDate: this.monthPickerDate.startOf('month').toISODate(),
                endDate: this.monthPickerDate.endOf('month').toISODate(),
                hostCalendarView: this.hostCalendarView
            }).then(({ data }) => {
                this.monthSlots = data.map((day) => {
                    return {
                        date: DateTime.fromFormat(day.date, 'yyyy-MM-dd'),
                        available: day.available
                    };
                });
            }).catch(() => {
                this.$toasted.global.error('There was an error retrieving available dates.');
            }).finally(() => {
                this.loading = false;
            });
        }
    }
};
</script>
