<template>
    <div class="flex mx-auto max-w-6xl w-full">
        <div id="section_form" class="w-full flex flex-col items-start md:flex-1 md:border-r md:pr-8 lg:pr-16">
            <div class="w-full flex flex-col space-y-4">
                <h1 id="text_title" class="text-3xl font-semibold">Sign up for RSVPify</h1>
                <h2 id="text_subtitle" class="text-[15px] text-gray-500">Please tell us a bit about yourself so we can finish customizing your experience.</h2>

                <form @submit.prevent>
                    <form-field-wrapper
                        id="field_name"
                        class="mt-4"
                        :error="form.errors.get('name')"
                        label="Your full name"
                    >
                        <input
                            id="input_name"
                            v-model="userName"
                            class="form-field"
                        >
                    </form-field-wrapper>

                    <form-field-wrapper
                        id="field_email"
                        :error="form.errors.getAny(['g-recaptcha-response', 'email'])"
                        label="Email address"
                    >
                        <input
                            id="input_email"
                            v-model="form.email"
                            autocomplete="username"
                            class="form-field"
                            type="email"
                        >
                    </form-field-wrapper>

                    <form-field-wrapper
                        id="field_password"
                        :error="form.errors.get('password')"
                        label="Create a password"
                    >
                        <input
                            id="input_password"
                            v-model="form.password"
                            autocomplete="new-password"
                            class="form-field"
                            type="password"
                        >
                    </form-field-wrapper>

                    <p id="text_tos" class="text-xs text-gray-600 mt-8">
                        By completing sign up, you agree to RSVPify’s <a
                            id="link_tos"
                            class="text-purple font-semibold hover:underline"
                            href="https://rsvpify.com/tos/"
                            target="_blank"
                        >Terms of Service</a>.
                    </p>

                    <vue-recaptcha
                        ref="recaptcha"
                        :sitekey="recaptchaKey"
                        @verify="register"
                    >
                        <stateful-button
                            id="button_sign_up"
                            class="button button-primary mt-8 w-full"
                            :loading="form.processing"
                            type="submit"
                        >
                            Sign up
                        </stateful-button>
                    </vue-recaptcha>
                </form>

                <feature-enabled feature="google-sign-in">
                    <div class="w-full flex flex-col space-y-4">
                        <div class="flex items-center space-x-4">
                            <div class="mx-auto py-3 w-full">
                                <div class="border-t border-ghost-darker"></div>
                            </div>
                            <span class="m-2 text-sm">or</span>
                            <div class="mx-auto py-3 w-full">
                                <div class="border-t border-ghost-darker"></div>
                            </div>
                        </div>

                        <button
                            id="button_sign_up_with_google"
                            class="button button-secondary"
                            type="button"
                            @click="initGoogleAuth"
                        >
                            <img
                                alt=""
                                class="h-5 w-5 mr-3"
                                :src="asset('/images/vendor/google/logo.svg')"
                            >
                            <span>Sign up with Google and create event</span>
                        </button>
                    </div>
                </feature-enabled>

                <p id="text_privacy" class="text-gray-500 text-center text-sm">
                    Your privacy is important to us. <a
                        id="link_privacy"
                        class="font-semibold hover:underline"
                        href="https://rsvpify.com/privacy/"
                        target="_blank"
                    >We never sell your data</a>.
                </p>

                <p
                    v-if="allowLogin"
                    id="text_sign_in"
                    class="text-gray-500 text-center text-sm"
                >
                    Already have an account? <button
                        id="button_sign_in"
                        class="text-sm text-purple font-medium hover:text-purple-light"
                        type="button"
                        @click="initNormalAuth"
                    >Log in</button>
                </p>
            </div>
        </div>

        <div id="section_art" class="hidden md:block md:flex-1 md:pl-8 lg:pl-16">
            <img alt="" :src="urlImage1 ?? asset('/images/empty-states/register.svg')">

            <img
                v-if="urlImage2"
                alt="awards"
                :src="urlImage2"
            >
        </div>
    </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import VueRecaptcha from '@node_modules/vue-recaptcha';
import Form from '@/validation/Form';

export default {
    name: 'EventAccountInfoStep',

    components: {
        VueRecaptcha
    },

    props: {
        allowLogin: {
            type: Boolean,
            default: true
        },
        disableEventSerialization: {
            type: Boolean,
            default: false
        },
        invitationToken: {
            type: String,
            default: null
        },
        urlImage1: {
            type: String,
            default: null
        },
        urlImage2: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            form: new Form({
                email: '',
                password: ''
            })
        };
    },

    computed: {
        userName: sync('CreateEvent/user@name'),

        recaptchaKey () {
            return window.recaptchaKey;
        }
    },

    methods: {
        serializeEvent () {
            localStorage.setItem('onboarding.event', JSON.stringify(this.$store.get('CreateEvent')));
        },

        initGoogleAuth () {
            if (!this.disableEventSerialization) {
                this.serializeEvent();
            }

            window.location.href = this.route('auth.google', {
                invitation_token: this.invitationToken
            });
        },

        initNormalAuth () {
            if (!this.disableEventSerialization) {
                this.serializeEvent();
            }

            window.location.href = this.route('login');
        },

        async register (recaptchaToken) {
            try {
                await this.form.post(this.route('register'), null, {
                    name: this.userName,
                    invitation_token: this.invitationToken,
                    'g-recaptcha-response': recaptchaToken
                });

                this.$emit('completed');
            } catch (error) {
                if (error.response?.status === 422) {
                    return;
                }

                this.$toasted.global.error('Something went wrong. Please try again.');

                throw error;
            } finally {
                this.$refs.recaptcha.reset();
            }
        }
    }
};
</script>
