/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-google-plus': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" class="a" d="M11.366 12.928a4.074 4.074 0 01-1.4-1.505c0-.425.038-.628.988-1.368a4.545 4.545 0 001.906-3.564 4.739 4.739 0 00-1-3.044h.489a.484.484 0 00.282-.091l1.364-.988a.479.479 0 00-.281-.868h-6.1a6.182 6.182 0 00-2.01.347 5.24 5.24 0 00-3.785 4.605 4.9 4.9 0 004.988 4.91 2.6 2.6 0 00-.084.645 2.407 2.407 0 00.33 1.216h-.079c-2.72 0-5.175 1.334-6.108 3.32A3.642 3.642 0 00.5 18.1a2.917 2.917 0 00.382 1.437 5.427 5.427 0 003.544 2.289 11.384 11.384 0 002.8.336 8.962 8.962 0 002.494-.339c2.419-.7 3.981-2.482 3.981-4.538a4.718 4.718 0 00-2.335-4.357zM3.66 17.443c0-1.435 1.823-2.693 3.9-2.693h.056a4.671 4.671 0 011.309.2c.141.1.28.193.412.282.962.656 1.6 1.088 1.774 1.783a2.277 2.277 0 01.063.518c0 1.787-1.333 2.693-3.961 2.693C5.221 20.225 3.66 19 3.66 17.443zM5.551 3.89a1.593 1.593 0 011.226-.567h.055c1.349.041 2.639 1.543 2.876 3.349a3.263 3.263 0 01-.6 2.544 1.59 1.59 0 01-1.241.566h-.023c-1.322-.039-2.639-1.6-2.875-3.4a3.148 3.148 0 01.582-2.492zM23.5 9.5h-3v-3h-2v3h-3v2h3v3h2v-3h3v-2z"/></g>'
  }
})
