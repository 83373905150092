/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-taco-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>mexican</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M22.269 9.613v-.01c.56-.54.59-1.43.06-2l-.07-.07-.81-.78c-3.7-3.54-9.13-3.42-12.971.25v0-.01c-3.69 3.33-3.98 9.03-.64 12.72.11.13.24.25.36.38l1.88 1.8z"/><path pid="1" d="M18.064 4.68v-.01c.47-1.08.22-2.34-.63-3.15l-.01-.01a3.235 3.235 0 00-4.43.12h-.01c-.41.38-.7.87-.85 1.4h0c-1.29-.93-3.05-.8-4.18.31v-.01c-.53.5-.87 1.17-.95 1.89h0c-1.08-.23-2.21.09-3 .85v-.01a2.878 2.878 0 00-.32 3.98h-.01c-.56.14-1.06.42-1.47.81h-.01a2.896 2.896 0 00-.25 4.09c.04.04.08.08.12.13.02.02.05.04.08.06h-.01c-.77 1.13-.62 2.66.37 3.62l-.01-.01c.52.49 1.21.77 1.93.8h-.01c-.31 1-.03 2.09.73 2.82l-.01-.01c.95.82 2.28 1.05 3.45.58h-.01c.23-.08.44-.21.62-.38l.75-.72"/><path pid="2" d="M10.66 15.029h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M13.66 12.029h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M9.91 11.279H9.9c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M12.91 8.279h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M16.66 9.029h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38"/></g>'
  }
})
