/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vegetables-pumpkin-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan fall halloween</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.166 23.25v0-.01c-1.6.08-3-1.05-3.25-2.62L7.836 9.34c-.21-2.22 1.78-4.12 4.32-4.12v0c2.53 0 4.53 1.9 4.32 4.11L15.39 20.6h-.01a3.122 3.122 0 01-3.25 2.61zM12.17 5.25V.75"/><path pid="1" d="M8.41 7.14h0c-.49-.27-1.04-.4-1.6-.41v0H6.8a3.78 3.78 0 00-3.62 3.35v-.01c-.47 3.83.1 7.71 1.63 11.26l-.01-.01c.38 1.1 1.42 1.86 2.59 1.88v0h-.01c.85-.02 1.64-.42 2.15-1.11M15.83 7.029v-.01c.42-.19.88-.28 1.34-.28v0h0c1.86.07 3.4 1.49 3.61 3.35l-.01-.01c.46 3.83-.11 7.71-1.64 11.26v-.01a2.826 2.826 0 01-2.6 1.88v0h0c-.77-.01-1.5-.35-2-.92"/></g>'
  }
})
