/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-gingerbread-man': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M5.2 9.391a.5.5 0 00-.5.5v3.174a.5.5 0 00.5.5h1.919a.5.5 0 01.489.607l-.967 4.422a.5.5 0 00.365.591l3.385.862a.5.5 0 00.609-.379l.039-.18S11.25 18 12 18s.958 1.485.958 1.485l.039.18a.5.5 0 00.612.379l3.385-.862a.5.5 0 00.365-.591l-.967-4.422a.5.5 0 01.489-.607H18.8a.5.5 0 00.5-.5V9.891a.5.5 0 00-.5-.5h-2.611a.249.249 0 01-.2-.4 5.626 5.626 0 001.224-3.769 5.217 5.217 0 10-10.434 0 5.626 5.626 0 001.228 3.765.253.253 0 01.029.264.25.25 0 01-.225.14zm8.891-6.26a1.044 1.044 0 11-1.044 1.043 1.043 1.043 0 011.04-1.043zM12 10.782a.783.783 0 11-.783.783.784.784 0 01.783-.783zm0 3.131a.783.783 0 11-.783.782.783.783 0 01.783-.782zM9.913 3.131A1.044 1.044 0 118.87 4.174a1.044 1.044 0 011.043-1.043zM3.13 9.891a.5.5 0 00-.5-.5h-.38a2.093 2.093 0 100 4.174h.38a.5.5 0 00.5-.5zM17.907 21.1a.5.5 0 00-.612-.377l-3.388.862a.5.5 0 00-.365.591l.009.043a2.247 2.247 0 004.4-.931zM6.705 20.724a.5.5 0 00-.612.377l-.041.188a2.247 2.247 0 004.4.931l.009-.043a.5.5 0 00-.365-.591zM21.75 13.565a2.093 2.093 0 100-4.174h-.38a.5.5 0 00-.5.5v3.174a.5.5 0 00.5.5z"/></g>'
  }
})
