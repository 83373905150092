/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-cocktail-glass-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>bar alcohol manhattan old fashioned liquor</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.71 23.25H9.29l-.01-.001a3.015 3.015 0 01-2.96-2.51L4.63 10.61l-.01-.01c-.07-.41.2-.8.61-.87.03-.01.08-.01.12-.01h13.22v-.001c.41-.01.75.33.75.74 0 .04-.01.08-.02.12l-1.69 10.12v-.01c-.25 1.44-1.5 2.5-2.96 2.5zM15.37 3a1.875 1.875 0 100 3.75 1.875 1.875 0 100-3.75zM14.96 6.7l-2.21 16.55M15.75.75L15.45 3M5.5 15.75h13"/></g>'
  }
})
