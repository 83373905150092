import { uniq } from 'lodash';

export default class Trackers {
    constructor () {
        this.googleTrackers = [];
        this.facebookTrackers = [];
    }

    event (event, type, parameters = {}) {
        if (type === 'google' && window.gtag) {
            uniq(this.googleTrackers).forEach((tracker) => {
                // eslint-disable-next-line no-undef
                gtag('event', event, { send_to: tracker, ...parameters });
            });
        }

        if (type === 'facebook' && window.fbq) {
            uniq(this.facebookTrackers).forEach((tracker) => {
                // eslint-disable-next-line no-undef
                fbq('trackSingle', tracker, event, parameters);
            });
        }
    }

    register (type, trackerId) {
        if (type !== 'google' && type !== 'facebook') {
            throw new Error('Type must be google or facebook.');
        }

        const trackerKey = `${type}Trackers`;
        this[trackerKey].push(trackerId);
    }
}
