/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'duplicate-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>clone copy</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6 3.81h-.01C1.47 5.87-.52 11.22 1.55 15.73a8.969 8.969 0 004.43 4.43"/><path pid="1" d="M14.25 3a9 9 0 100 18 9 9 0 100-18zM14.25 7.5v9M9.75 12h9"/></g>'
  }
})
