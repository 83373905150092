/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'seafood-fish-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.8 3.55c-3.173.01-6.3 1.61-7.37 3.8h-.01c-.26.56-.13 1.21.31 1.64l2.54 2.54"/><path pid="1" d="M9.88 17.267c8.571-1.52 14.23-5.933 13.26-15.125h0c-.08-.68-.61-1.21-1.29-1.29-9.2-.98-13.62 4.69-15.13 13.26l-5.573 2.48h-.01c-.37.18-.52.63-.33.99.08.17.24.3.42.37l3.9.87.87 3.9-.01-.01c.12.38.54.59.93.47.18-.07.34-.2.42-.38z"/><path pid="2" d="M20.44 12.2c-.02 3.173-1.62 6.3-3.8 7.36h-.01c-.57.25-1.22.12-1.65-.32l-2.54-2.55M6.73 14.12l3.15 3.15M16.875 4.5h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38"/></g>'
  }
})
