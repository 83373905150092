<template>
    <dashboard-data
        :always-loaded-columns="['formalName', 'orderDonation']"
        :default-columns="['firstName', 'lastName', 'email', 'orderDonation']"
        :event="event"
    >
        <template #summary="{ grouped, row, rowIndex }">
            <p>{{ row.formalName }}</p>

            <div v-if="shouldShowDonations(grouped, rowIndex)" class="flex mt-2">
                <p class="text-sm rounded-xl border px-1">
                    <money-field static :value="row.orderDonation"></money-field>
                </p>
            </div>
        </template>
    </dashboard-data>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    name: 'DashboardDonationsTable',

    computed: {
        event: get('Event/event')
    },

    methods: {
        shouldShowDonations (grouped, rowIndex) {
            if (!grouped) {
                return false;
            }

            return rowIndex === 0;
        }
    }
};
</script>
