/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-ping-pong-paddle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.29 7.29l12.42 12.42M10.5.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5z"/><path pid="1" d="M5.574 4.821h-.01a9.733 9.733 0 00-.49 10.61l-3.63 3.62h-.01c-.95.97-.92 2.52.06 3.46.95.91 2.45.91 3.4 0l3.62-3.63-.01-.01c4.64 2.72 10.61 1.17 13.34-3.47 2.72-4.65 1.17-10.62-3.47-13.35-.97-.57-2.02-.97-3.12-1.18"/></g>'
  }
})
