/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'family-add-member-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>baby new plus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 6a3 3 0 100 6 3 3 0 100-6zM7.747 14.25l-.01-.01a4.496 4.496 0 00-6.24 1.26c-.49.73-.76 1.6-.76 2.48M22.509 18v-.01a4.5 4.5 0 00-4.5-4.5c-.89 0-1.76.26-2.49.75M18 6a3 3 0 100 6 3 3 0 100-6zM15.009 9s0 3.75-2.25 3.75M21.009 9s0 3.75 2.25 3.75M11.25 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM15.156 23.25h0c-1.25-2.16-4-2.89-6.15-1.65-.69.39-1.26.96-1.65 1.64M8.26 3.75h6M11.26.75v6"/></g>'
  }
})
