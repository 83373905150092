/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>marker gps</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.94 8.783h-.01a1.49 1.49 0 00-.54 2.05c.06.11.14.21.23.3l8.213 8.213-.01-.01c.58.58 1.53.58 2.12 0 .09-.1.16-.2.23-.31h-.01c.97-1.68 1.26-3.66.81-5.54L22.5 9.32v-.01c.69-.45.9-1.38.46-2.08-.06-.1-.13-.18-.21-.26L16.9 1.12h0c-.59-.59-1.54-.59-2.13-.01-.08.07-.15.16-.21.25L10.4 7.89h0a7.49 7.49 0 00-5.54.8zM8.75 15.25l-8 8"/></g>'
  }
})
