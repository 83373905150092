/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-infrared-thermometer-gun-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M20.39 20.75h0c1.29 0 2.34-1.05 2.34-2.34 0-.16-.02-.32-.05-.47l-2.3-11.49-.01-.01a4.004 4.004 0 00-3.93-3.22H4.58v-.001c-.83 0-1.5.67-1.5 1.5 0 .1.01.21.03.32l1.24 5.6h0c.2.91 1.01 1.56 1.95 1.56h4.56a4 4 0 013.77 2.66l1.29 3.66-.01-.01c.45 1.3 1.68 2.17 3.06 2.17z"/><path pid="1" d="M11.769 12.341h0c.16 1.21-.04 2.45-.57 3.55h-.01c-.15.3-.02.65.28.79.08.03.17.05.26.05h3.58M19.594 4.75h2.15-.01c.82-.01 1.5.67 1.5 1.5v1.5-.001c0 .82-.68 1.5-1.5 1.5h-.8M4.418 10.75H2.25l-.01-.001a1.51 1.51 0 01-1.5-1.5V6.248h0c-.01-.83.67-1.51 1.49-1.51h.87M14.25 7.75v0c0 .82-.68 1.5-1.5 1.5h-3.5l-.01-.001a1.51 1.51 0 01-1.5-1.5c0-.83.67-1.5 1.5-1.5h3.5c.82 0 1.49.67 1.49 1.5z"/></g>'
  }
})
