<template>
    <div>
        <form-field-wrapper label="Icon">
            <icon-picker
                v-model="form.settings.icon"
                class="text-gray-800 w-11 h-11"
            ></icon-picker>
        </form-field-wrapper>

        <form-field-wrapper
            :label="productTitle"
            :error="form.errors.get('title')"
            :should-show-error="form.errors.has('title')"
        >
            <input
                v-model="form.title"
                class="form-field"
                type="text"
                name="title"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Description (optional)">
            <textarea
                v-model="form.settings.description"
                class="form-field"
                placeholder="Enter a description and details about this ticket or selection."
                rows="5"
            ></textarea>
        </form-field-wrapper>

        <form-field-wrapper
            v-if="displayLocationField"
            label="Location"
            :error="form.errors.get('settings.location')"
            :should-show-error="form.errors.has('settings.location')"
            tooltip="Calendar invitation(s) that guests receive for this selection will have this location. When this field is empty, the location that will be provided is configured under Setup > Event Settings > Event Details."
        >
            <google-autocomplete-input
                v-model="form.settings.location"
                placeholder="Venue, address or virtual URL ..."
                class="form-field"
                name="location"
            >
            </google-autocomplete-input>
        </form-field-wrapper>

        <form-field-wrapper v-if="form.settings.location && recurringTimeSlotsEnabledOnTicketingBlock">
            <location-map
                :address="form.settings.location"
                class="h-80"
            ></location-map>
        </form-field-wrapper>

        <div v-if="!isDeclineItem" class="mt-8">
            <form-field-wrapper
                v-if="displayCategoryOptions"
                label="Item Type"
                :error="form.errors.get('category')"
                :should-show-error="form.errors.has('category')"
            >
                <select-list v-model="form.category">
                    <select-option
                        id="individual"
                        class="my-2"
                        label="Admit One"
                        tooltip="Admission for single individual. Each selection of this option will count as 1 attendee."
                    ></select-option>
                    <select-option
                        id="group"
                        class="my-2"
                        label="Group Package or Table"
                        tooltip="Allow guests to purchase a multi-guest package or table for one total price. Set the number of attendees per package or table."
                    ></select-option>
                    <select-option
                        id="add-on"
                        class="my-2"
                        label="Add-on or Product"
                        tooltip="Sell event add-ons or products (e.g. raffle tickets, wrist bands, t-shirts, etc.). Selections do not impact the count of guests attending your event."
                    ></select-option>
                </select-list>
            </form-field-wrapper>

            <form-field-wrapper
                v-if="isGroup"
                :error="form.errors.get('settings.guestsPerOrder')"
                :should-show-error="form.errors.has('settings.guestsPerOrder')"
            >
                <div
                    slot="label"
                    v-tippy
                    class="tooltip-text"
                    content="The number of persons or seats available in this package or at this table tier."
                >
                    Guests Per Order
                </div>
                <input
                    v-model.number="form.settings.guestsPerOrder"
                    class="form-field w-full"
                    type="number"
                    name="guests-per-order"
                    step="1"
                    min="2"
                >
            </form-field-wrapper>

            <form-panel
                v-if="!isDeclineItem"
                v-model="form.settings.costsMoney"
                label="Selection Requires Payment"
                tooltip="Choose to require a payment for this selection or option."
                :error="form.errors.get('price')"
                :should-show-error="form.errors.has('price')"
                data-descriptor="costs-money-panel"
                @collapse-form-panel="resetPrice"
                @expand-form-panel="setDefaultPrice"
            >
                <money-field v-model="form.price" data-descriptor="money-field"></money-field>
            </form-panel>

            <form-panel
                v-if="displayAttendingIdentificationOptions"
                v-model="form.settings.requireName.enabled"
                :label="requireNameLabel"
                :tooltip="requireNameTooltip"
            >
            </form-panel>

            <form-panel
                v-if="displayAttendingIdentificationOptions"
                v-model="form.settings.requireEmail.enabled"
                label="Require Email for Each Guest"
                tooltip="Also require an email address for each guest."
            >
                <toggle-switch
                    v-if="form.settings.requireEmail.enabled"
                    v-model="form.settings.requireEmail.sendConfirmationEmails"
                    class="mt-4 w-full"
                    :disabled="!event.settings.emails.enabled"
                >
                    <div
                        v-tippy
                        :content="confirmationEmailsTooltip"
                        class="tooltip-text"
                    >
                        Send Confirmation Email to All
                    </div>
                </toggle-switch>
            </form-panel>

            <form-panel
                :value="calendarInvitesEnabled"
                :disabled="recurringTimeSlotsEnabledOnTicketingBlock"
                label="Include Calendar Invitation"
                :tooltip="includeCalendarInvitationTooltip"
                @input="toggleCalendarInvitesEnabled"
            >
                <template v-if="!recurringTimeSlotsEnabledOnTicketingBlock">
                    <form-field-wrapper
                        :error="form.errors.get('settings.calendarInvites.type')"
                        :should-show-error="form.errors.has('settings.calendarInvites.type')"
                    >
                        <select-list v-model="form.settings.calendarInvites.type">
                            <select-option
                                id="event"
                                class="my-2"
                                :disabled="disableDefaultSettingsCalendarInvitesOption || recurringTimeSlotsEnabledOnTicketingBlock"
                                label="Set As Primary Event Date/Time"
                                tooltip="This calendar invitation will use your primary event’s date and time settings (Setup > Event Settings > Event Details)."
                            ></select-option>
                            <select-option
                                id="custom"
                                class="my-2"
                                :disabled="recurringTimeSlotsEnabledOnTicketingBlock"
                            >
                                <div class="flex items-center">
                                    <span v-tippy content="Use any date or time and customize all details included in the calendar invitation(s) guests receive.">Other Date&#47;Time</span>
                                    <premium-feature feature="CalendarInvitations" class="ml-2"></premium-feature>
                                </div>
                            </select-option>
                        </select-list>
                    </form-field-wrapper>
                </template>

                <div v-if="displayCustomCalendarInvitesOptions" class="mt-8">
                    <form-field-wrapper
                        :error="form.errors.get('settings.calendarInvites.start')"
                        :should-show-error="form.errors.has('settings.calendarInvites.start')"
                        label="Start Date"
                    >
                        <date-time-picker
                            v-model="form.settings.calendarInvites.start"
                            :disabled="recurringTimeSlotsEnabledOnTicketingBlock"
                            has-time
                            class="w-full"
                            name="start-date"
                        ></date-time-picker>
                    </form-field-wrapper>

                    <form-field-wrapper
                        class-wrapper="mt-2 mb-2"
                        :error="form.errors.get('settings.calendarInvites.end')"
                        :should-show-error="form.errors.has('settings.calendarInvites.end')"
                        label="End Date"
                    >
                        <date-time-picker
                            v-model="form.settings.calendarInvites.end"
                            :disabled="recurringTimeSlotsEnabledOnTicketingBlock"
                            has-time
                            class="w-full"
                            name="end-date"
                        ></date-time-picker>
                    </form-field-wrapper>

                    <form-field-wrapper
                        :error="form.errors.get('settings.calendarInvites.timezone')"
                        :should-show-error="form.errors.has('settings.calendarInvites.timezone')"
                        label="Timezone"
                    >
                        <timezone-picker
                            v-model="form.settings.calendarInvites.timezone"
                            :disabled="recurringTimeSlotsEnabledOnTicketingBlock"
                            name="timezone"
                            guess-timezone
                        ></timezone-picker>
                    </form-field-wrapper>

                    <form-field-wrapper
                        label="Location"
                        :error="form.errors.get('settings.calendarInvites.location')"
                        :should-show-error="form.errors.has('settings.calendarInvites.location')"
                    >
                        <google-autocomplete-input
                            v-model="form.settings.calendarInvites.location"
                            :disabled="recurringTimeSlotsEnabledOnTicketingBlock"
                            placeholder="Venue, address or virtual URL ..."
                            class="form-field"
                            name="location"
                        >
                        </google-autocomplete-input>
                    </form-field-wrapper>

                    <form-field-wrapper v-if="form.settings.calendarInvites.location">
                        <location-map
                            :address="form.settings.calendarInvites.location"
                            class="h-80"
                        ></location-map>
                    </form-field-wrapper>

                    <form-field-wrapper
                        label="Description"
                        :error="form.errors.get('settings.calendarInvites.description')"
                        :should-show-error="form.errors.has('settings.calendarInvites.description')"
                    >
                        <textarea
                            v-model="form.settings.calendarInvites.description"
                            :disabled="recurringTimeSlotsEnabledOnTicketingBlock"
                            class="form-field"
                            rows="5"
                            placeholder="Enter a description and details about this calendar invitation."
                            name="description"
                        ></textarea>
                    </form-field-wrapper>

                    <form-field-wrapper
                        label="Event Organizer"
                        :error="form.errors.get('settings.calendarInvites.organizer')"
                        :should-show-error="form.errors.has('settings.calendarInvites.organizer')"
                    >
                        <input
                            v-model="form.settings.calendarInvites.organizer"
                            :disabled="recurringTimeSlotsEnabledOnTicketingBlock"
                            class="form-field"
                            type="text"
                            name="event-organizer"
                        >
                    </form-field-wrapper>

                    <form-field-wrapper
                        label="Organizer's Email Address"
                        :error="form.errors.get('settings.calendarInvites.email')"
                        :should-show-error="form.errors.has('settings.calendarInvites.email')"
                    >
                        <input
                            v-model="form.settings.calendarInvites.email"
                            :disabled="recurringTimeSlotsEnabledOnTicketingBlock"
                            class="form-field"
                            type="email"
                            name="organizer-email"
                        >
                    </form-field-wrapper>
                </div>
            </form-panel>

            <div v-if="!isSecondaryEventBlock" class="mt-8">
                <form-panel
                    v-model="hasMinimum"
                    label="Minimum Required"
                    tooltip="Require a minimum number of this selection to be included per registration."
                    :error="form.errors.get('settings.minimumRequiredQuantity')"
                    :should-show-error="form.errors.has('settings.minimumRequiredQuantity')"
                >
                    <div class="flex items-end">
                        <input
                            :value="form.settings.minimumRequiredQuantity"
                            class="form-field w-32 mr-4"
                            type="number"
                            name="minimum"
                            step="1"
                            min="1"
                            @input="form.settings.minimumRequiredQuantity = Number($event.target.value)"
                        >
                        <div class="py-2 bold uppercase tracking-wide">
                            Minimum
                        </div>
                    </div>
                </form-panel>

                <form-panel
                    v-model="hasMaximum"
                    label="Maximum Selectable"
                    tooltip="Set the maximum number of this selection that a guest can select in a single registration."
                    :error="form.errors.get('settings.maximumSelectable')"
                    :should-show-error="form.errors.has('settings.maximumSelectable')"
                >
                    <div class="flex items-end">
                        <input
                            :value="form.settings.maximumSelectable"
                            class="form-field w-32 mr-4"
                            type="number"
                            name="maximum"
                            step="1"
                            min="1"
                            @input="form.settings.maximumSelectable = Number($event.target.value)"
                        >
                        <div class="py-2 bold uppercase tracking-wide">
                            Maximum
                        </div>
                    </div>
                </form-panel>
            </div>

            <form-panel
                v-if="!isRsvpBlock"
                v-model="form.settings.maxCapacity.enabled"
                class="mt-8"
                label="Limited Number Available"
                :disabled="recurringTimeSlotsEnabledOnTicketingBlock"
                :tooltip="limitedNumberAvailableTooltip"
            >
                <template #trigger-appended>
                    <premium-feature feature="LimitedCapacity" class="ml-2"></premium-feature>
                </template>

                <form-field-wrapper
                    :error="form.errors.get('settings.maxCapacity.slots')"
                    :should-show-error="form.errors.has('settings.maxCapacity.slots')"
                >
                    <div class="flex items-end">
                        <input
                            v-model.number="form.settings.maxCapacity.slots"
                            class="form-field w-32 mr-4"
                            type="number"
                            name="number-available"
                            step="1"
                            min="1"
                            :disabled="recurringTimeSlotsEnabledOnTicketingBlock"
                        >
                        <div class="py-2 bold uppercase tracking-wide">
                            Available
                        </div>
                    </div>
                </form-field-wrapper>

                <form-field-wrapper label="When no longer available...">
                    <select-list v-model="form.settings.maxCapacity.showMessage">
                        <select-option
                            :id="true"
                            :disabled="recurringTimeSlotsEnabledOnTicketingBlock"
                            label="Disable and display a message"
                            class="my-2"
                        ></select-option>
                        <select-option
                            :id="false"
                            :disabled="recurringTimeSlotsEnabledOnTicketingBlock"
                            label="Hide completely"
                            class="my-2"
                        ></select-option>
                    </select-list>
                </form-field-wrapper>

                <form-field-wrapper
                    v-if="form.settings.maxCapacity.showMessage"
                    label="Message to display"
                    :error="form.errors.get('settings.maxCapacity.message')"
                    :should-show-error="form.errors.has('settings.maxCapacity.message')"
                >
                    <tiptap-editor
                        v-model="form.settings.maxCapacity.message"
                        class="form-field"
                        simple
                    ></tiptap-editor>
                </form-field-wrapper>
            </form-panel>
        </div>
        <div v-if="displayDeclinedIdentificationOptions" class="mt-8">
            <form-panel
                :value="true"
                label="Require name"
                tooltip="Collect the name of guest(s) who have indicated that they won’t be able to attend your event."
            >
            </form-panel>

            <form-panel
                :value="isRsvpBlock ? form.settings.requireEmail.enabled : true"
                label="Require Email"
                tooltip="Also require an email address for the guest who declined"
                @input="form.settings.requireEmail.enabled = $event"
            ></form-panel>
        </div>

        <portal to="app-drawer-footer">
            <footer-save-cancel
                :processing="loading"
                @cancel="$emit('cancel')"
                @save="save"
            ></footer-save-cancel>
        </portal>
    </div>
</template>

<script>
import FormItemSettingsMixin from '@/mixins/FormItemSettingsMixin';
import InteractsWithFeatureFlags from '@/mixins/InteractsWithFeatureFlags';

export default {
    name: 'FormProductItemSettings',

    mixins: [InteractsWithFeatureFlags, FormItemSettingsMixin],

    inject: ['$validator'],

    props: {
        settings: {
            type: Object,
            default: () => { return {}; }
        },

        item: {
            type: Object,
            default: () => { return {}; }
        },

        recurringTimeSlotsActive: {
            type: Boolean,
            default: false
        },

        useGuestAttendingTitles: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            hasMinimum: false,
            hasMaximum: false
        };
    },

    computed: {
        calendarInvitesEnabled () {
            if (this.recurringTimeSlotsEnabledOnTicketingBlock) {
                return true;
            }

            return this.form.settings.calendarInvites.enabled;
        },

        confirmationEmailsTooltip () {
            if (this.event.settings.emails.enabled) {
                return 'If enabled, RSVPify will send an email confirmation to all listed guests after a successful registration or purchase.';
            }

            return 'Email confirmations have been disabled on this event.';
        },

        disableDefaultSettingsCalendarInvitesOption () {
            return !this.event.starts_at;
        },

        displayAttendingIdentificationOptions () {
            return !this.isAddOn && !this.isSecondaryEventBlock;
        },

        displayCategoryOptions () {
            return !this.isDeclineItem && this.hasItemType && !this.isSecondaryEventBlock;
        },

        displayCustomCalendarInvitesOptions () {
            if (this.recurringTimeSlotsEnabledOnTicketingBlock) {
                return false;
            }

            return this.form.settings.calendarInvites.type === 'custom';
        },

        displayDeclinedIdentificationOptions () {
            return this.isDeclineItem && !this.isSecondaryEventBlock;
        },

        displayLocationField () {
            return this.recurringTimeSlotsEnabledOnTicketingBlock && !this.isDeclineItem;
        },

        includeCalendarInvitationTooltip () {
            return this.recurringTimeSlotsEnabledOnTicketingBlock
                ? 'You’ve enabled Recurring Time Slots. A calendar invitation will be automatically sent to registrants.'
                : 'Set a time and date to associate with this selection, and automatically send guests calendar invitations with their confirmation emails.';
        },

        isAddOn () {
            return this.form.category === 'add-on';
        },

        isGroup () {
            return this.form.category === 'group';
        },

        isDeclineItem () {
            if (this.block.slug === 'rsvp' || this.isSecondaryEventBlock) {
                return this.form.short_guest_state === 'NotAttending';
            }

            // TicketingBlock decline items have a decline_item property
            return this.form.decline_item === true;
        },

        isRsvpBlock () {
            return this.block.slug === 'rsvp';
        },

        isSecondaryEventBlock () {
            return this.block.slug === 'secondary-event';
        },

        limitedNumberAvailableTooltip () {
            return this.recurringTimeSlotsEnabledOnTicketingBlock
                ? 'You\'ve enabled Recurring Time Slots. Limited availability can be set for each time slot.'
                : 'Choose to hide or disable this option or selection based on availability or capacity.';
        },

        hasItemType () {
            return this.block.slug !== 'rsvp';
        },

        recurringTimeSlotsEnabledOnTicketingBlock () {
            return this.recurringTimeSlotsActive && !this.isSecondaryEventBlock && !this.isRsvpBlock;
        },

        requireNameLabel () {
            switch (this.form.category) {
                case 'group':
                    return 'Require Name of Each Guest';

                default:
                    return 'Collect Name(s) for Each Selection';
            }
        },

        productTitle () {
            if (!this.useGuestAttendingTitles) {
                return 'Title';
            }

            if (this.item.short_guest_state === 'Attending') {
                return 'Attending Title';
            }

            if (this.item.short_guest_state === 'Maybe') {
                return 'Maybe Attending Title';
            }

            return 'Not Attending Title';
        },

        requireNameTooltip () {
            switch (this.form.category) {
                case 'group':
                    return 'Require the names of all guests equivalent to the number of guests per each order of this package or table tier. Choose to require email address and title and send confirmation emails to ALL guests.';

                default:
                    return 'Require the names of all guests equivalent to the number of selections of this ticket tier. Choose to require email address and title and send confirmation emails to ALL guests.';
            }
        }
    },

    watch: {
        'form.category': function () {
            if (this.isAddOn) {
                this.form.settings.requireName.enabled = false;
                this.form.settings.requireEmail.enabled = false;
            }
        },

        hasMinimum (newValue) {
            if (!newValue) {
                this.form.settings.minimumRequiredQuantity = null;
            }
        },

        hasMaximum (newValue) {
            if (!newValue) {
                this.form.settings.maximumSelectable = null;
            }
        },

        'form.settings.minimumRequiredQuantity': function (newValue) {
            if (!this.hasMaximum) {
                return;
            }

            if (newValue > this.form.settings.maximumSelectable) {
                this.form.settings.maximumSelectable = newValue;
            }
        },

        'form.settings.maximumSelectable': function (newValue) {
            if (!this.hasMinimum) {
                return;
            }

            if (newValue < this.form.settings.minimumRequiredQuantity) {
                this.form.settings.minimumRequiredQuantity = newValue;
            }
        }
    },

    mounted () {
        this.hasMinimum = this.form.settings.minimumRequiredQuantity > 0;
        this.hasMaximum = this.form.settings.maximumSelectable > 0;
    },

    methods: {
        resetPrice () {
            this.form.price = 0;
        },

        setDefaultPrice () {
            this.form.price = 10000;
        },

        toggleCalendarInvitesEnabled () {
            if (this.recurringTimeSlotsEnabledOnTicketingBlock) {
                return;
            }

            this.form.settings.calendarInvites.enabled = !this.form.settings.calendarInvites.enabled;
        }
    }

};
</script>
