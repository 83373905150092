<template>
    <div>
        <div
            v-for="option in activeOptions"
            :key="option.id"
            class="my-6"
        >
            <quantity-input
                :value="internalValue[option.id]"
                :item="option"
                :is-active="true"
                @input="setQuantity(option.id, $event)"
            ></quantity-input>

            <div v-if="getError(option)" class="alert alert-error alert-sm mt-2">
                <p class="w-full font-normal text-center">{{ getError(option) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import filter from 'lodash/filter';
import { get } from 'vuex-pathify';
import CustomQuestion from '@/mixins/CustomQuestion';

export default {
    name: 'FormQuantityQuestion',

    mixins: [CustomQuestion],

    computed: {
        errorBag: get('Submission/errorBag'),

        activeOptions () {
            if (this.isEditMode) {
                return this.question.options;
            }

            return filter(this.question.options, (option) => {
                const availabilitySettings = option.settings.maxCapacity || {};

                if (availabilitySettings.enabled && option.quantity_remaining === 0) {
                    return availabilitySettings.showMessage === true;
                }

                return true;
            });
        },

        defaultValue () {
            return this.question.options.reduce((carry, option) => {
                return { ...carry, [option.id]: 0 };
            }, {});
        }
    },

    methods: {
        getError (option) {
            return this.errorBag.get(`answers.${this.answerableId}.${option.id}`);
        },

        setQuantity (optionId, quantity) {
            this.$set(this.internalValue, optionId, quantity);
            this.$emit('input', this.internalValue);
        }
    }
};
</script>
