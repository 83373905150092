<template>
    <textarea v-model="answer" class="form-field w-full"></textarea>
</template>

<script>
import CustomQuestionEditComponent from '@/mixins/CustomQuestionEditComponent';

export default {
    name: 'CustomQuestionAnswerTextarea',

    mixins: [CustomQuestionEditComponent]
};
</script>
