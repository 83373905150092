<template>
    <multi-line-text-input
        ref="input"
        v-model="internalValue"
        :placeholder="$t('placeholder-type-here')"
    ></multi-line-text-input>
</template>

<script>
import CustomQuestion from '@/mixins/CustomQuestion';

export default {
    name: 'FormTextareaQuestion',

    mixins: [CustomQuestion],

    methods: {
        focus () {
            this.$refs.input.focus();
        }
    }
};
</script>
