<template>
    <div>
        <infinite-data-table
            ref="table"
            class="text-gray-600"
            :columns="columns"
            has-select-all
            no-results-placeholder="Sorry, we couldn't find any custom data fields."
            remote-sort
            selectable
            :sort="sort"
            :source="dataSource"
            with-preflight
            @change-sort="handleChangedSort"
            @received-response="response => $emit('total', response.data)"
        >
            <template #header>
                <div class="w-full flex items-center space-x-2">
                    <portal-target
                        name="bulk-action-custom-data-fields"
                        class="hidden md:block"
                    ></portal-target>

                    <div class="flex-auto"></div>

                    <button
                        class="button-icon button-lg button-info"
                        @click="openAddModal"
                    >
                        <app-icon name="add-circle" stroke></app-icon>
                    </button>
                </div>
            </template>

            <template #selectable="{ selectedAll, selectedIds, reload, total }">
                <portal to="bulk-action-custom-data-fields">
                    <bulk-actions
                        :ids="selectedIds"
                        :all="selectedAll"
                        :total="total"
                        class="hidden md:block"
                        :disabled="actionsButton.disabled"
                        :disabled-tooltip="actionsButton.disabledTooltip"
                    >
                        <template #default="bulkActionData">
                            <bulk-delete
                                :bulk-action-data="bulkActionData"
                                :endpoint="route('api.events.custom-data-fields.bulk.destroy', event)"
                                :delete-confirm-button-text="`DELETE ${bulkActionData.formatted.totalSelected} CUSTOM DATA FIELD(S).`"
                                :delete-message="getBulkDeleteMessage(bulkActionData)"
                                :confirmation-message="`Deleting ${bulkActionData.formatted.totalSelected} custom data fields(s).  This may take a few minutes.`"
                                @rows-deleted="reload"
                            ></bulk-delete>
                        </template>
                    </bulk-actions>
                </portal>
            </template>

            <template slot="row" slot-scope="{ row, property, value, reload }">
                <div v-if="property === 'options'">
                    <button
                        v-if="auth().user().can('update.event')"
                        class="mx-2"
                        @click="openEditModal(row)"
                    >
                        <app-icon name="edit-stroke"></app-icon>
                    </button>

                    <delete-item
                        v-if="auth().user().can('update.event')"
                        :confirmation-message="generateConfirmationMessage(row)"
                        :confirmation-settings="confirmationSettings"
                        :endpoint="route('api.events.custom-data-fields.destroy', { event, customDataField: row })"
                        @delete-item="reload"
                    >
                        <app-icon
                            class="mr-2 w-6 h-6"
                            name="trash"
                            stroke
                        ></app-icon>
                    </delete-item>
                </div>

                <div v-else>
                    {{ value }}
                </div>
            </template>
        </infinite-data-table>

        <app-modal v-model="fieldModalOpen" :title="modalTitle">
            <form id="custom-data-field-form" @submit.prevent="submitField">
                <form-field-wrapper label="Data Field Name" :error="form.errors.get('field')">
                    <input
                        v-model="form.field"
                        class="form-field"
                    >
                </form-field-wrapper>
            </form>

            <template #footer>
                <stateful-button
                    class="button button-primary"
                    form="custom-data-field-form"
                    :loading="form.processing"
                    type="submit"
                >
                    <template v-if="editing">
                        Update Data Field
                    </template>

                    <template v-else>
                        Create Data Field
                    </template>
                </stateful-button>

                <button class="button" @click="closeModal">
                    Cancel
                </button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import getValue from 'lodash/get';
import Form from '@/validation/Form';
import BulkActionsButtonAuthorization from '@/mixins/BulkActionsButtonAuthorization';

export default {
    mixins: [BulkActionsButtonAuthorization],

    data () {
        return {
            columns: [
                {
                    label: 'Field',
                    property: 'field',
                    desktop: true,
                    mobile: true,
                    sortable: true
                },
                {
                    label: '',
                    property: 'options',
                    desktop: true,
                    mobile: true,
                    sortable: false
                }
            ],
            editing: false,
            form: new Form({
                id: null,
                field: ''
            }),
            sort: {},
            fieldModalOpen: false
        };
    },

    computed: {
        ...get('Event/*'),

        dataSource () {
            return this.route('api.events.custom-data-fields.index', this.event.id);
        },

        confirmationSettings () {
            return {
                confirmButtonText: 'Delete Custom Data Field',
                cancelButtonText: 'Cancel'
            };
        },

        modalTitle () {
            return this.editing ? 'Update Data Field' : 'Create Data Field';
        }
    },

    watch: {
        fieldModalOpen (newVal) {
            if (!newVal) {
                this.form.restore();
            }
        }
    },

    methods: {
        addField () {
            this.form.post(this.route('api.events.custom-data-fields.store', this.event))
                .then(() => {
                    this.$toasted.show(`${this.form.field} was created.`);
                    this.closeModal();
                    this.$refs.table.reload();
                });
        },

        editField () {
            this.form.put(this.route('api.events.custom-data-fields.update', {
                event: this.event,
                customDataField: this.form.id
            }))
                .then(() => {
                    this.$toasted.show(`${this.form.field} was updated.`);
                    this.closeModal();
                    this.$refs.table.reload();
                });
        },

        closeModal () {
            this.editing = false;
            this.form.restore();
            this.fieldModalOpen = false;
        },

        getBulkDeleteMessage (bulkActionData) {
            if (getValue(this.event.settings, 'security.inviteePassword.type') === 'customDataField' && bulkActionData.ids.includes(getValue(this.event.settings, 'security.inviteePassword.dataFieldId'))) {
                return `You're deleting ALL ${bulkActionData.formatted.totalSelected} custom data fields.
                One of the fields you are deleting is set as a security event field.
                Once deleted, your event won't be password protected anymore.
                All associated data with these custom data fields will also be deleted.
                This cannot be undone.`;
            }

            return `You're deleting ALL ${bulkActionData.formatted.totalSelected} custom data fields. All associated data will also be deleted. This cannot be undone.`;
        },

        openAddModal () {
            if (this.event.plan.features.CustomDataFieldLimit.hidden || this.$refs.table.total >= this.event.plan.features.CustomDataFieldLimit.max) {
                const reachedLimitText = this.event.plan.features.CustomDataFieldLimit.hidden || this.event.plan.features.CustomDataFieldLimit.max === 0
                    ? `Your ${this.event.plan.name} plan doesn't include custom data fields.  Upgrade to add custom data fields.`
                    : `Your ${this.event.plan.name} plan includes <strong>${this.event.plan.features.CustomDataFieldLimit.max}</strong> custom data fields. Upgrade to add more custom data fields to your event(s).`;

                App.alert().paymentRequired(
                    'Please upgrade.',
                    reachedLimitText
                );
                return;
            }

            this.fieldModalOpen = true;
        },

        openEditModal (row) {
            this.editing = true;
            this.form.id = row.id;
            this.form.field = row.field;
            this.fieldModalOpen = true;
        },

        submitField () {
            if (this.editing) {
                this.editField();
                return;
            }

            this.addField();
        },

        handleChangedSort ([newSort]) {
            this.sort = newSort || {};
        },

        generateConfirmationMessage (customDataField) {
            return {
                title: 'Are you sure?',
                text: getValue(this.event.settings, 'security.inviteePassword.type') === 'customDataField' && customDataField.id === getValue(this.event.settings, 'security.inviteePassword.dataFieldId')
                    ? `You’re deleting “${customDataField.field}.” This field is set as a security event field. Once deleted, your event won't be password protected anymore and all data associated with it will be deleted. This cannot be undone.`
                    : `You’re deleting “${customDataField.field}.” All data associated with this field will be deleted. This cannot be undone.`
            };
        }
    }
};
</script>
