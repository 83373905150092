/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-july4-fireworks-rocket-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21.03 2.183v0c0-.4-.33-.72-.72-.72L13.93.74h-.01c-.4-.01-.72.32-.72.71-.01.19.07.37.21.5l7.09 7.092-.01-.01c.27.28.73.28 1.01 0 .13-.14.21-.32.21-.51z"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.6 3.94h7.164v9.74c0 .55-.45 1-1 1H10.6l-.01-.001c-.56-.01-1-.45-1-1.01 0 0 0 0 0 0V3.91z" transform="matrix(.7071 .7071 -.70711 .7071 10.45 -6.591)"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="2" d="M9.38 13.12l-7.8 7.8M10.9 14.63v0-.01a3.582 3.582 0 00-.01 5.06s0 0 0 0v0M18.73 18.18l-1.52 1.52M15.18 21.73l-1.52 1.52M18.73 23.25l-1.52-1.52M15.18 19.7l-1.52-1.52"/></g>'
  }
})
