import map from 'lodash/map';
import { get } from 'vuex-pathify';
import InteractsWithAbly from '@/mixins/InteractsWithAbly';
import EventTimezone from '@/mixins/EventTimezone';

const STATE_EXPORTING = 'exporting';

export default {
    mixins: [InteractsWithAbly, EventTimezone],

    props: {
        chosenColumns: {
            type: Array,
            default: () => {
                return [];
            }
        },

        predicates: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    data () {
        return {
            columnExportType: 'all',
            rowExportType: 'all',
            downloadUrl: null,
            isModalVisible: false,
            state: null
        };
    },

    computed: {
        columnsByName () {
            return map(this.chosenColumns, 'property');
        },

        event: get('Event/event'),

        hasAdvancedExportOptions () {
            return this.chosenColumns.length > 0 || this.predicates.length > 0;
        }
    },

    methods: {
        exportSpreadsheet () {
            this.listenForEvents();

            this.isModalVisible = true;
            this.state = STATE_EXPORTING;

            this.initiateExport();
        },

        /**
        * Starts the download process by submitting an API call.
        */
        initiateExport () {
            throw new TypeError('You must define an initiateExport when extending the base export button component.');
        },

        /**
         * Listens for the export to fail or succeed to present the download link.
         */
        listenForEvents () {
            throw new TypeError('You must define an listenForEvents when extending the base export button component.');
        }
    }
};
