<template>
    <div>
        <div
            v-for="alert in displayableAlerts"
            :key="alert.id"
            class="alert alert-global"
            :class="`alert-${alert.type}`"
            role="alert"
        >
            <p>{{ alert.message }}</p>

            <a
                v-if="alert.link_text && alert.link"
                target="_blank"
                class="alert-button"
                :href="alert.link"
            >
                {{ alert.link_text }}
            </a>

            <a
                class="alert-close-button ml-0 md:ml-4"
                role="button"
                @click="dismiss(alert)"
            >
                <app-icon name="close" class="h-4 w-4"></app-icon>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SiteAlerts',

    data () {
        return {
            hiddenAlerts: []
        };
    },

    props: {
        alerts: {
            type: Array,
            required: true
        }
    },

    computed: {
        displayableAlerts () {
            return this.alerts.filter((alert) => { return this.displayAlert(alert); });
        }
    },

    methods: {
        dismiss (siteAlert) {
            localStorage.setItem(`site-alert-id=${siteAlert.id}`, 'dismissed');
            this.hiddenAlerts.push(siteAlert.id);
        },

        displayAlert (siteAlert) {
            return localStorage.getItem(`site-alert-id=${siteAlert.id}`) !== 'dismissed' && !this.hiddenAlerts.includes(siteAlert.id);
        }
    }
};
</script>
