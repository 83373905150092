/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-parking-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/><path pid="1" d="M12 3.75a8.25 8.25 0 100 16.5 8.25 8.25 0 100-16.5zM11.25 15.75v-7.5"/><path pid="2" d="M11.25 8.25H12h-.01c1.24-.01 2.25 1 2.25 2.24 0 1.24-1.01 2.25-2.25 2.25h-.75"/></g>'
  }
})
