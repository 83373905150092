<template>
    <div v-if="unreadNotifications.length > 0">
        <system-alert
            v-for="notification in unreadNotifications"
            :key="notification.id"
            :notification="notification"
            @dismiss="dismiss(notification)"
        ></system-alert>
    </div>
</template>

<script>
import axios from '@/util/axios';
import cloneDeep from 'lodash/cloneDeep';
import reject from 'lodash/reject';

export default {
    name: 'SystemAlertList',

    props: {
        initialNotifications: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            dismissed: [],
            notifications: cloneDeep(this.initialNotifications)
        };
    },

    computed: {
        unreadNotifications () {
            return reject(this.notifications, (notification) => {
                return this.dismissed.includes(notification);
            });
        }
    },

    methods: {
        dismiss (notification) {
            axios.post(this.route('api.notifications.dismiss', notification))
                .then(() => {
                    this.dismissed.push(notification);
                });
        }
    }
};
</script>
