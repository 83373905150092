<template>
    <div class="flex flex-1 flex-col p-4 items-center block-select-item">
        <div class="flex flex-col items-center flex-auto hover:cursor-pointer group" @click="select">
            <div
                class="flex justify-center items-center rounded-full h-8 w-8 border-2 border-purple"
                :class="selectCircleStyle"
            >
                <div v-if="isSelected" class="rounded-full h-5 w-5 bg-purple"></div>
            </div>

            <p class="font-semibold text-center my-2">{{ title }}</p>

            <div class="text-sm leading-normal text-gray-500 text-center">
                <slot></slot>
                <app-icon
                    v-if="tooltip"
                    name="info-circle"
                    class="h-3 w-3"
                ></app-icon>
            </div>
        </div>
        <div v-if="sampleLinkText" class="flex items-center mt-2">
            <app-icon
                name="search"
                class="h-3 w-3 mr-2"
            ></app-icon>
            <a
                :href="sampleLink"
                class="no-underline text-gray-700 text-sm leading-normal hover:text-gray-500"
                target="_blank"
            >
                {{ sampleLinkText }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlockSelectItem',

    inject: ['blockSelectState'],

    props: {
        id: {
            type: [String, Number],
            required: true
        },
        title: {
            type: String,
            required: true
        },
        tooltip: {
            type: String,
            default: null
        },
        sampleLinkText: {
            type: String,
            default: null
        },
        sampleLink: {
            type: String,
            default: null
        }
    },

    computed: {
        isSelected () {
            return this.blockSelectState.selectedOptionId === this.id;
        },

        selectCircleStyle () {
            return { 'group-hover:border-purple-light': !this.isSelected };
        }
    },

    methods: {
        select () {
            this.blockSelectState.selectedOptionId = this.id;
        }
    }
};
</script>
