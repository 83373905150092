<template>
    <option-selection
        v-model="internalValue"
        :allow-custom-choice="question.settings.hasOtherOption"
        class="mt-4"
        is-multiple-choice
        :options="question.options"
    ></option-selection>
</template>

<script>
import CustomQuestion from '@/mixins/CustomQuestion';

export default {
    name: 'FormSelectMultipleQuestion',

    mixins: [CustomQuestion],

    computed: {
        defaultValue () {
            return [];
        }
    }
};
</script>
