import { DateTime } from 'luxon';
import find from 'lodash/find';

export default {
    methods: {
        getFormattedDateDifference (startDate, endDate) {
            const dateDifference = endDate.diff(startDate, ['years', 'months', 'days', 'hours', 'minutes', 'seconds']);

            if (dateDifference.years > 0) {
                return `${Math.round(dateDifference.years)} years`;
            }
            if (dateDifference.months > 0) {
                return `${Math.round(dateDifference.months)} months`;
            }
            if (dateDifference.days > 0) {
                return `${Math.round(dateDifference.days)} days`;
            }
            if (dateDifference.hours > 0) {
                return `${Math.round(dateDifference.hours)} hours`;
            }
            if (dateDifference.minutes > 0) {
                return `${Math.round(dateDifference.minutes)} minutes`;
            }
            if (dateDifference.seconds > 0) {
                return `${Math.round(dateDifference.seconds)} seconds`;
            }

            return null;
        }
    },

    computed: {
        registrationOpensIn () {
            const opensAt = DateTime.fromISO(this.form.opened_at).setZone('UTC');
            const opensAtTimezone = find(window.timezones, { id: this.form.opened_at_timezone_id });

            const opensAtInTimezone = DateTime.fromObject({ ...opensAt.toObject() }, { zone: opensAtTimezone.name });

            const currentTimeInTimezone = DateTime.fromObject({}, { zone: opensAtTimezone.name });

            return this.getFormattedDateDifference(currentTimeInTimezone, opensAtInTimezone);
        },

        registrationOpensAtFormattedDateTime () {
            const opensAt = DateTime.fromISO(this.form.opened_at).setZone('UTC');
            const opensAtTimezone = find(window.timezones, { id: this.form.opened_at_timezone_id });

            return `${opensAt.toFormat(`LLLL d, yyyy 'at' h:mm a`)} ${opensAtTimezone.name.replace(/_/g, ' ')}`;
        },

        registrationClosesIn () {
            const closesAt = DateTime.fromISO(this.form.closed_at).setZone('UTC');
            const closesAtTimezone = find(window.timezones, { id: this.form.closed_at_timezone_id });

            const closesAtInTimezone = DateTime.fromObject({ ...closesAt.toObject() }, { zone: closesAtTimezone.name });

            const currentTimeInTimezone = DateTime.fromObject({}, { zone: closesAtTimezone.name });

            return this.getFormattedDateDifference(currentTimeInTimezone, closesAtInTimezone);
        },

        registrationClosesAtFormattedDateTime () {
            const closesAt = DateTime.fromISO(this.form.closed_at).setZone('UTC');
            const closesAtTimezone = find(window.timezones, { id: this.form.closed_at_timezone_id });

            return `${closesAt.toFormat(`LLLL d, yyyy 'at' h:mm a`)} ${closesAtTimezone.name.replace(/_/g, ' ')}`;
        }
    }
};
