<template>
    <div class="max-w-2xl w-full mx-auto pt-16">
        <img
            alt="creating event"
            class="w-full"
            :src="asset('images/events/creating-event-animation.svg')"
        >
    </div>
</template>

<script>
import axios from '@/util/axios';

export default {
    name: 'EventFinalizeStep',

    props: {
        skipCreate: {
            type: Boolean,
            default: false
        }
    },

    async created () {
        if (this.skipCreate) {
            this.handleSignupOnly();
        } else {
            await this.handleCreateEvent();
        }
    },

    methods: {
        async handleCreateEvent () {
            try {
                const event = await this.submitCreateRequest();

                setTimeout(() => {
                    this.$emit('finalized', {
                        destination: this.route('events.tasks.index', {
                            event: event.id
                        })
                    });
                }, 4000);

                if (window.gtag) {
                    this.trackConversion(event.type.name);
                }
            } catch (error) {
                this.$toasted.global.error('There was an error creating your event.');
            }
        },

        handleSignupOnly () {
            setTimeout(() => {
                this.$emit('finalized', {
                    destination: this.route('dashboard')
                });
            }, 4000);
        },

        async submitCreateRequest () {
            const { data } = await axios.post(this.route('api.events.store'), {
                name: this.$store.get('CreateEvent/name'),
                event_type_id: this.$store.get('CreateEvent/primary_event_type_id'),
                event_template_id: this.$store.get('CreateEvent/event_template_id'),
                time_tbd: this.$store.get('CreateEvent/starts_at') === null,
                starts_at: this.$store.get('CreateEvent/starts_at'),
                ends_at: this.$store.get('CreateEvent/ends_at'),
                timezone_id: this.$store.get('CreateEvent/timezone_id'),
                browser_timezone_id: this.$store.get('CreateEvent/browser_timezone_id'),
                location: {
                    type: this.$store.get('CreateEvent/location@type'),
                    address: this.$store.get('CreateEvent/location@address'),
                    venue_name: this.$store.get('CreateEvent/location@venue_name'),
                    virtual_url: this.$store.get('CreateEvent/location@virtual_url')
                },
                subdomain: this.$store.get('CreateEvent/subdomain'),
                setup_type: this.$store.get('CreateEvent/setup_type')
            });

            return data;
        },

        trackConversion (eventTypeName) {
            window.gtag('event', 'New v3 Event Created', {
                event_category: 'Conversion',
                event_label: eventTypeName,
                value: 0
            });
        }
    }
};
</script>
