/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-edit': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<rect pid="0" x=".75" y="3.75" width="22.5" height="19.5" rx="1.5" ry="1.5" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M.75 9.75h22.5M6.75 6V.75M17.25 6V.75M10.779 19.745l-2.529.5.506-2.529 4.552-4.552a1.432 1.432 0 012.023 0h0a1.43 1.43 0 010 2.023z"/>'
  }
})
