<script>
import merge from 'lodash/merge';
import { get } from 'vuex-pathify';
import axios from '@/util/axios';

export default {
    name: 'UnpublishAction',

    data () {
        return {
            unpublishing: false
        };
    },

    computed: {
        event: get('Event/event'),
        form: get('Form/form'),
        wall: get('Wall/wall')
    },

    watch: {
        unpublishing (newVal) {
            this.$emit('action-in-progress', newVal);
        }
    },

    methods: {
        unpublish () {
            const onConfirm = () => {
                this.unpublishing = true;

                axios.post(this.route('api.events.unpublish', this.event))
                    .then(({ data }) => {
                        merge(this.event, data);
                        merge(this.form, data.form);
                        merge(this.wall, data.wall);

                        this.$toasted.global.success('Your event has been unpublished.');
                        window.location.href = this.route('publish-invite.index', this.event);
                    }).catch((error) => {
                        this.$toasted.global.error('There was a problem unpublishing your event.');
                    }).finally(() => {
                        this.unpublishing = false;
                    });
            };

            App.alert().confirm(
                'Are you sure you want to unpublish your event?',
                'Your event will return to DRAFT and your event\'s link will no longer be publicly accessible.',
                {
                    confirmButtonText: 'Unpublish Now',
                    cancelButtonText: 'Cancel'
                },
                onConfirm
            );
        }
    },

    render () {
        return this.$scopedSlots.default({
            unpublish: this.unpublish,
            unpublishing: this.unpublishing
        });
    }
};
</script>
