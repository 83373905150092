<template>
    <app-modal
        :value="value"
        header-classes="hidden"
        @input="$emit('input', $event)"
        @closed="$emit('closed')"
    >
        <div class="flex flex-col items-center justify-center space-y-6 w-full pb-8">
            <div class="swal2-icon swal2-warning swal2-icon-show flex"><div class="swal2-icon-content">!</div></div>

            <div class="flex flex-col items-center justify-center space-y-1">
                <p class="text-2xl font-semibold text-center">You've reached your event limit.</p>
                <p class="text-center font-light text-lg">No worries! You have a few options</p>
            </div>

            <div class="flex flex-col space-y-2">
                <button class="flex items-center space-x-4 p-4 rounded-md border shadow-md w-full hover:cursor-pointer hover:border-purple-600 transition" @click="deleteAnEventAction">
                    <app-icon
                        name="trash"
                        class="h-6 w-6"
                        stroke
                    ></app-icon>
                    <p>Delete an event (see instructions)</p>
                </button>
                <a class="flex items-center space-x-4 p-4 rounded-md border shadow-md w-full hover:cursor-pointer hover:border-purple-600 transition" :href="route('account.upgrade.index')">
                    <app-icon
                        name="star-circle-stroke"
                        class="h-6 w-6"
                        stroke
                    ></app-icon>
                    <p>Upgrade your account</p>
                </a>
                <button class="flex items-center space-x-4 p-4 rounded-md border shadow-md w-full hover:cursor-pointer hover:border-purple-600 transition" @click="$emit('create-ticketed-event')">
                    <app-icon
                        name="ticket-stroke"
                        class="h-6 w-6"
                        stroke
                    ></app-icon>
                    <p>Create a ticketed (paid) event</p>
                </button>
            </div>

            <div class="flex items-center justify-center underline">
                <button class="text-sm underline" @click="$emit('closed')">cancel</button>
            </div>
        </div>
    </app-modal>
</template>

<script>
export default {
    name: 'EventLimitWarningModal',

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        deleteAnEventAction () {
            window.open('https://help.rsvpify.com/en/articles/3980429-how-do-i-delete-an-event', '_blank').focus();
            this.$emit('closed');
        }
    }

};
</script>
