/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search-house': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>real estate home rent</desc><g _fill="currentColor"><path pid="0" d="M23.561 21.439L19.2 17.077a.5.5 0 01-.058-.637 10.544 10.544 0 10-2.7 2.7.5.5 0 01.637.058l4.363 4.363a1.5 1.5 0 002.122-2.122zM2 10.5a8.5 8.5 0 118.5 8.5A8.51 8.51 0 012 10.5z"/><path pid="1" d="M11.185 5.271a1 1 0 00-1.37 0L5.158 9.635a.5.5 0 00.342.865H6a.5.5 0 01.5.5v3.5a1 1 0 001 1h6a1 1 0 001-1V11a.5.5 0 01.5-.5h.5a.5.5 0 00.342-.865z"/></g>'
  }
})
