/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-pandemic-3-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.85 2.99a2.94 2.94 0 100 5.88 2.94 2.94 0 100-5.88zM17.36.79h.98M17.85.79v2.2M21.14 1.95l.7.69M21.49 2.3l-1.56 1.56M23 5.45v.98M23 5.93h-2.21M21.84 9.23l-.7.69M21.49 9.57l-1.56-1.56M18.34 11.08h-.98M17.85 11.08v-2.2M14.56 9.92l-.69-.69M14.21 9.57l1.56-1.56M12.71 6.43v-.98M12.71 5.93h2.2M13.87 2.64l.69-.69M14.21 2.3l1.56 1.56M20.287 13.662v-.01a9.855 9.855 0 01-.61 3.26M17.872 19.95h-.01c-.74.83-1.62 1.54-2.59 2.08M11.933 23.12v-.01c-1.11.14-2.23.09-3.32-.13M5.361 21.642l-.01-.01a9.902 9.902 0 01-2.42-2.28M1.37 16.213l-.01-.01a9.23 9.23 0 01-.35-3.3M1.91 9.5v-.01a9.432 9.432 0 011.9-2.73M6.712 4.772h-.01a9.37 9.37 0 013.22-.81M16.431 13.77v-.01a5.801 5.801 0 01-5.99 5.58c-3.2-.12-5.7-2.8-5.59-5.99a5.78 5.78 0 015.13-5.55"/><path pid="1" d="M8.772 8.1l.35 1.44h0c.09.4-.01.83-.29 1.15l-.66.73h-.01c-.17.18-.27.4-.31.65l-.21 1.31h-.01c-.09.55-.54.99-1.1 1.07l-1.612.22M16.188 15.24l-2.08-.89-.01-.01c-.66-.28-1.41.02-1.69.67-.04.07-.06.15-.08.24l-.27 1.29h-.01c-.06.24-.03.5.06.74l.657 1.63"/></g>'
  }
})
