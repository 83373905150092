<template>
    <a
        v-theme="'form.button'"
        class="flex justify-center items-center w-full px-2 py-2 rounded-md text-center"
        :href="editUrl"
    >
        <app-icon class="mr-2 h-5 w-5" name="edit"></app-icon>
        {{ $t('button-edit-reply') }}
    </a>
</template>

<script>
export default {
    props: {
        event: {
            type: Object,
            required: true
        },
        submission: {
            type: Object,
            required: true
        }
    },

    data () {
        const getParams = new URLSearchParams(window.location.search);

        return {
            isEmbed: getParams.has('embed') && getParams.get('embed') === '1'
        };
    },

    computed: {
        editUrl () {
            const routeParameters = {
                subdomain: this.event.subdomain,
                submission: this.submission.uuid
            };

            if (this.isEmbed) {
                routeParameters.embed = '1';
            }

            return this.route('submissions.edit', routeParameters, false);
        }
    }
};
</script>
