/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-touch-hand-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.21 11.94a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM6.64 9.38h1.15M7.21 9.38v2.57M11.05 10.73l.81.81M11.46 11.13l-1.82 1.82M13.21 14.8v1.15M13.21 15.38h-2.57M11.86 19.21l-.81.81M11.46 19.62L9.64 17.8M7.79 21.38H6.64M7.21 21.38V18.8M3.38 20.02l-.81-.81M2.97 19.62l1.82-1.82M1.21 15.95V14.8M1.21 15.38h2.58M2.57 11.54l.81-.81M2.97 11.13l1.82 1.82M22.78 6.12l-2.94-2.35h0a5.312 5.312 0 00-3.28-1.16h-6.04 0c-.97-.01-1.75.78-1.75 1.74h-.001v0h0c0 .96.78 1.74 1.75 1.74h4.52"/><path pid="1" d="M22.626 11.37h-3.5v-.001c-1.04 0-2.06-.31-2.92-.89l-2-1.34h0a1.644 1.644 0 01-.56-2.25c.01-.03.02-.05.04-.07v0h-.01c.45-.69 1.35-.93 2.09-.56l2.62 1.577"/></g>'
  }
})
