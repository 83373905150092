/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camping-fire-marshmallows-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.847 8.826l-.01-.01a.74.74 0 00-1.03.26c-.11.18-.14.39-.08.59l-.01-.01c.39 1.39.22 2.89-.49 4.16v-.01a1.9 1.9 0 01-2.03-1.59h-.01a4.177 4.177 0 00-2.23 3.97l-.01-.01c.02 2.66 2.21 4.79 4.87 4.76v-.001c2.64-.01 4.8-2.13 4.87-4.77v-.01a7.69 7.69 0 00-3.9-7.41zM9.43 20.75l5.57 2.5M6.32 20.75l-5.57 2.5M20.32 14.26l2.93 6.08M16.74 6.83l1.62 3.38"/></g><rect pid="1" width="4.5" height="4.5" x="17.091" y="9.985" rx=".5" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" transform="matrix(.90075 -.43432 .43431 .90075 -3.394 9.615)"/><rect pid="2" width="4.5" height="4.5" x="13.508" y="2.553" rx=".5" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" transform="matrix(.90075 -.43432 .43431 .90075 -.522 7.321)"/><path pid="3" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.78 2.78L13.8.75"/>'
  }
})
