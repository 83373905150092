import { Mark } from 'tiptap';
import { updateMark, toggleMark } from 'tiptap-commands';

export default class TextColor extends Mark {
    get name () {
        return 'textColor';
    }

    get schema () {
        return {
            attrs: {
                color: {
                    default: '#000'
                }
            },
            content: 'inline*',
            group: 'block',
            draggable: false,
            parseDOM: [
                {
                    style: 'color',
                    getAttrs: (mark) => {
                        return {
                            color: mark
                        };
                    }
                }
            ],
            toDOM: (mark) => {
                return [
                    'span',
                    { style: `color: ${mark.attrs.color}` },
                    0
                ];
            }
        };
    }

    commands ({ type }) {
        return (attrs) => {
            if (attrs.color) {
                return updateMark(type, attrs);
            }

            return toggleMark(type, attrs);
        };
    }
}
