/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-human-transmit-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 12.63a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM16.68 10.06h1.14M17.25 10.06v2.57M21.09 11.42l.81.8M21.49 11.82l-1.82 1.82M23.25 15.49v1.14M23.25 16.06h-2.57M21.9 19.9l-.81.81M21.49 20.31l-1.82-1.82M17.82 22.06h-1.14M17.25 22.06v-2.57M13.41 20.71l-.81-.81M13.01 20.31l1.82-1.82M11.25 16.63v-1.14M11.25 16.06h2.57M12.6 12.22l.81-.8M13.01 11.82l1.82 1.82M4.5.93a2.62 2.62 0 100 5.25 2.62 2.62 0 100-5.25zM2.25 20.812h0c0 1.24 1 2.24 2.25 2.24a2.253 2.253 0 002.24-2.26v-3.75h-.01c.82 0 1.5-.68 1.5-1.5 0 0 0 0 0 0v-4.5 0c0-1.66-1.35-3-3-3H3.72c-1.66 0-3 1.34-3 3 0 0 0 0 0 0v4.5h0c0 .82.67 1.49 1.5 1.49zM18.25 1.313l2.25 2.25-2.25 2.25M20.5 3.56h-6"/></g>'
  }
})
