<template>
    <div class="min-h-min -my-12 py-12">
        <component :is="summaryBlockComponent" v-bind="$props">
            <template #block-header>
                <slot name="block-header"></slot>
            </template>

            <template #block-footer="{ completeBlock }">
                <slot name="block-footer" :complete-block="completeBlock"></slot>
            </template>
        </component>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import FormBlock from '@/mixins/FormBlock';

export default {
    name: 'FormSummaryBlock',

    mixins: [FormBlock],

    computed: {
        event: get('Event/event'),

        summaryBlockComponent () {
            return this.event.setup_type === 'rsvp' ? 'RsvpSummaryBlock' : 'TicketsSummaryBlock';
        }
    }
};
</script>
