<template>
    <div class="number-picker select-none">
        <div class="flex items-center justify-center">
            <a
                role="button"
                :class="decrementButtonStyle"
                @click="decrement"
            >
                <slot name="decrement-icon">
                    <app-icon
                        name="remove-circle"
                        class="w-6 h-6"
                        stroke
                    ></app-icon>
                </slot>
            </a>

            <div
                v-if="showAdditionalIndicator"
                class="absolute -ml-4"
            >+</div>

            <input
                v-validate
                :value="value"
                :disabled="isPickerDisabled"
                data-vv-as="number"
                type="number"
                class="form-field text-center mx-2 min-w-20 number-input"
                :class="additionalClasses"
                name="number-input"
                :min="minValue"
                :max="maxValue"
                step="1"
                @input="handleInput"
            >

            <a
                role="button"
                :class="incrementButtonStyle"
                @click="increment"
            >
                <slot name="increment-icon">
                    <app-icon
                        name="add-circle"
                        class="w-6 h-6"
                        stroke
                    ></app-icon>
                </slot>
            </a>
        </div>

        <div v-if="errors.has('number-input')">
            <p class="text-center text-red text-sm mt-2">{{ validationMessage }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            required: false
        },

        disabledDecrement: {
            type: Boolean,
            required: false
        },

        disabledIncrement: {
            type: Boolean,
            required: false
        },

        inputClasses: {
            type: String,
            default: ''
        },

        validationErrorMessage: {
            type: String,
            default: ''
        },

        showAdditionalIndicator: {
            type: Boolean,
            default: false
        },

        value: {
            type: Number,
            required: true
        },

        maxValue: {
            type: Number,
            default: 99
        },

        minValue: {
            type: Number,
            default: 0
        }
    },

    computed: {
        additionalClasses () {
            return {
                [this.inputClasses]: true,
                'form-field-error': this.errors.has('number-input')
            };
        },

        canDecrement () {
            if (this.isPickerDisabled || this.disabledDecrement) {
                return false;
            }

            return this.value > this.min;
        },

        canIncrement () {
            if (this.isPickerDisabled || this.disabledIncrement) {
                return false;
            }

            return this.value < this.max;
        },

        decrementButtonStyle () {
            return {
                'text-purple hover:text-purple-lighter': this.canDecrement,
                'text-gray-300 cursor-not-allowed': !this.canDecrement
            };
        },

        incrementButtonStyle () {
            return {
                'text-purple hover:text-purple-lighter': this.canIncrement,
                'text-gray-300 cursor-not-allowed': !this.canIncrement
            };
        },

        isPickerDisabled () {
            if (!this.disabled && this.value < this.minValue) {
                return false;
            }

            return this.disabled || this.min >= this.max;
        },

        max () {
            return this.maxValue !== null ? this.maxValue : 99;
        },

        min () {
            return this.minValue !== null ? this.minValue : 0;
        },

        validationMessage () {
            return this.validationErrorMessage ? this.validationErrorMessage : this.errors.first('number-input');
        }
    },

    mounted () {
        if (this.value < this.min) {
            this.$emit('input', this.min);
        } else if (this.value > this.max) {
            this.$emit('input', this.max);
        }
    },

    methods: {
        decrement () {
            if (this.canDecrement) {
                this.$emit('input', this.value - 1);
            }
        },

        handleInput (event) {
            let newValue = Number(event.target.value);

            if (newValue > this.max) {
                newValue = this.max;
            } else if (newValue < this.min) {
                newValue = this.min;
            }

            this.$emit('input', newValue);

            // When the user overrides the value in the field directly, it takes multiple ticks until the new value is
            // propagated to the parent component and then back to this component through the `value` prop. This can
            // confuse vee-validate and cause it to detect the old value instead of the new one. Running the validation
            // in a watcher would not work either, because the `input` event can be emitted at times without the value
            // changing at all (e.g. if the user repeatedly types in a value greater than the maximum allowed value).
            // This issue can be worked around by waiting a number of ticks for the value to properly propagate through
            // the hierarchy and then instructing vee-validate to re-validate the new value.
            //
            // The reason that 3 ticks are sufficient is because there are three parts to the propagation:
            //  - propagating from this component to the parent component via the `input` event
            //  - the parent component propagating the value back to this component via the `value` prop
            //  - updating the input field in the component with the new value in the `value` prop
            this.waitTicks(3).then(() => {
                this.$validator.validate();
            });
        },

        increment () {
            if (this.canIncrement) {
                // If minValue is set and greater than 1, then first increment
                // from 0 should be to the number value of minValue prop.
                if (this.value === 0 && this.minValue > 1) {
                    this.$emit('input', this.minValue);
                    return;
                }

                this.$emit('input', this.value + 1);
            }
        }
    }
};
</script>
