/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vegetables-broccoli': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><path pid="0" _fill="currentColor" d="M13.081 19.317a.5.5 0 00-.353.853l.746.746a6.109 6.109 0 004.355 1.807A6.18 6.18 0 0024 16.557a6.117 6.117 0 00-1.878-4.432.5.5 0 01-.142-.467 8.081 8.081 0 00-9.637-9.637.5.5 0 01-.467-.142A6.117 6.117 0 007.448 0h-.005a6.178 6.178 0 00-6.165 6.166 6.112 6.112 0 001.786 4.34l.766.766a.5.5 0 00.854-.353V8.164a.75.75 0 01.75-.749.749.749 0 01.749.75v3.26A6.375 6.375 0 014.3 15.957l-3.9 3.9a1.375 1.375 0 000 1.943l1.8 1.8a1.376 1.376 0 001.943 0l3.9-3.9a6.389 6.389 0 014.537-1.881h3.257a.75.75 0 010 1.5zm.606-5.123l-3.712.706a.749.749 0 01-.877-.877l.707-3.712a.75.75 0 011.474.281l-.363 1.908a.5.5 0 00.585.585l1.905-.364a.75.75 0 01.281 1.473z"/>'
  }
})
