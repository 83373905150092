/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-accessory-hat-cap-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>baseball hat</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M2.25 15.908v-3.75h0c-.01-5.39 4.36-9.76 9.74-9.76 5.38-.01 9.75 4.36 9.75 9.74 0 0 0 0 0 0v3.75"/><path pid="1" d="M23.193 21.2l-.01-.01a1.498 1.498 0 01-1.97 1.79h0a28.142 28.142 0 00-9.23-1.85v-.01c-3.16.09-6.28.71-9.24 1.84h-.01c-.78.28-1.64-.12-1.93-.9-.11-.3-.12-.61-.04-.91l1.44-5.29v-.01a41.77 41.77 0 019.75-1.5l-.01-.01c3.29.11 6.56.62 9.75 1.49zM12 2.41V.91M12 5.4a3 3 0 100 6 3 3 0 100-6z"/></g>'
  }
})
