/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-allergy-wheat-free-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>gluten bread</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .73a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5zM19.95 4.03L4.05 19.94"/><path pid="1" d="M12.75 6.73H7.49c-1.25 0-2.25 1-2.25 2.25 0 1.24 1 2.25 2.25 2.24v1.5M18.75 8.986v0c0 1.24-1.01 2.25-2.25 2.25v6h-5.25"/></g>'
  }
})
