import Vue from 'vue';
import axios from '@/util/axios';
import filter from 'lodash/filter';
import { make } from 'vuex-pathify';
import {
    findIndex, omit, map, intersectionWith
} from 'lodash';

const moduleState = {
    loading: true,
    saving: false,
    unseatingViaDragAndDrop: false,
    seatingChart: null,
    seatingAreas: null,
    seatables: null,
    filteredSeatables: null,
    seatActionInProgress: null,
    loadingSeatables: false,
    seatablesQuery: {
        searchTerm: '',
        predicates: [],
        sort: null
    }
};

const getters = {
    seatedSeatables (moduleState) {
        return filter(moduleState.seatables, (seatable) => { return !!seatable.seating_area_id; });
    }
};

const mutations = {
    ...make.mutations(moduleState),

    addSeatingArea (state, newSeatingArea) {
        if (state.seatingAreas.find((seatingArea) => { return seatingArea.id === newSeatingArea.id; })) {
            return;
        }

        state.seatingAreas.push(newSeatingArea);
    },

    updateSeatingArea (state, updatedSeatingArea) {
        const index = findIndex(state.seatingAreas, { id: updatedSeatingArea.id });

        Vue.set(state.seatingAreas, index, updatedSeatingArea);
    },

    removeSeatingArea (state, { id }) {
        const index = findIndex(state.seatingAreas, { id });

        Vue.delete(state.seatingAreas, index);
    },

    updateSeatsInSeatingArea (state, { seatingAreaId, updatedSeats }) {
        const index = findIndex(state.seatingAreas, { id: seatingAreaId });

        Vue.set(state.seatingAreas[index], 'seats', updatedSeats);
    },

    updateSeatOnSeatable (state, { seatableId, seatableType, seatingAreaId }) {
        // It is important to update seats in both arrays, as `filteredSeatables` is used for displaying in datatable
        // and `seatables` is a single source of truth for both, seating areas and datatable. That way all UI
        // necessities will be satisfied.
        const nonFilteredSeatablesIndex = findIndex(state.seatables, { id: seatableId, type: seatableType });
        Vue.set(state.seatables[nonFilteredSeatablesIndex], 'seating_area_id', seatingAreaId);

        const filteredSeatablesIndex = findIndex(state.filteredSeatables, { id: seatableId, type: seatableType });

        if (state.filteredSeatables[filteredSeatablesIndex]) {
            Vue.set(state.filteredSeatables[filteredSeatablesIndex], 'seating_area_id', seatingAreaId);
        }
    }
};

const actions = {
    loadFilteredSeatables ({ commit, rootState, state: moduleState }, seatingChart) {
        commit('loadingSeatables', true);

        return axios.post(window.route('api.events.seating-charts.seatables.index', [rootState.Event.event, seatingChart]), {
            predicates: moduleState.seatablesQuery.predicates,
            sort: moduleState.seatablesQuery.sort,
            searchTerm: moduleState.seatablesQuery.searchTerm
        }).then(({ data }) => {
            commit('filteredSeatables', data.data);
        }).finally(() => {
            commit('loadingSeatables', false);
        });
    },

    loadSeatables ({ commit, rootState, state: moduleState }, seatingChart) {
        commit('loadingSeatables', true);

        return axios.post(window.route('api.events.seating-charts.seatables.index', [rootState.Event.event, seatingChart])).then(({ data }) => {
            commit('seatables', data.data);
        }).finally(() => {
            commit('loadingSeatables', false);
        });
    },

    loadSeatingChartWithAreas ({ commit, rootState, state: moduleState }, seatingChart) {
        return axios.get(window.route('api.events.seating-charts.show', [rootState.Event.event, seatingChart]))
            .then(({ data }) => {
                commit('seatingChart', omit(data, 'seatingAreas'));
                commit('seatingAreas', data.seatingAreas);
            });
    },

    loadSeatingChart ({ commit, dispatch }, seatingChart) {
        commit('loading', true);

        Promise.all([
            dispatch('loadSeatingChartWithAreas', seatingChart),
            dispatch('loadFilteredSeatables', seatingChart),
            dispatch('loadSeatables', seatingChart)
        ]).then(() => {
            commit('loading', false);
        });
    },

    updateSeatingChart ({ commit, rootState, state: moduleState }, seatingChartUpdates) {
        commit('saving', true);

        return seatingChartUpdates.patch(window.route('api.events.seating-charts.update', [rootState.Event.event, moduleState.seatingChart]))
            .then(({ data }) => {
                commit('seatingChart', data);
            }).finally(() => {
                commit('saving', false);
            });
    },

    createArea ({ commit, rootState, state: moduleState }, newSeatingAreaForm) {
        commit('saving', true);

        return newSeatingAreaForm.post(window.route('api.events.seating-areas.store', [rootState.Event.event, moduleState.seatingChart]))
            .then(({ data }) => {
                commit('addSeatingArea', data.seatingArea);
                commit('seatingChart', data.seatingChart);
            }).finally(() => {
                commit('saving', false);
            });
    },

    editArea ({ commit, rootState, state: moduleState }, { seatingAreaEditsForm, seatingArea }) {
        commit('saving', true);

        return seatingAreaEditsForm.patch(window.route('api.events.seating-areas.update', [rootState.Event.event, moduleState.seatingChart, seatingArea]))
            .then(({ data }) => {
                commit('updateSeatingArea', data);
            }).finally(() => {
                commit('saving', false);
            });
    },

    removeSeatingArea ({
        commit, rootState, state: moduleState, dispatch
    }, seatingArea) {
        commit('saving', true);

        return axios.delete(window.route('api.events.seating-areas.destroy', [rootState.Event.event, moduleState.seatingChart, seatingArea]))
            .then(({ data }) => {
                commit('removeSeatingArea', data.seatingArea);
                dispatch('updateUnseatedSeatables', data.removedSeats);
                commit('seatingChart', data.seatingChart);
            }).finally(() => {
                commit('saving', false);
            });
    },

    reorderSeatingAreas ({ commit, rootState, state: moduleState }, seatingAreas) {
        commit('saving', true);
        commit('seatingAreas', seatingAreas);

        const reorderedSeatingAreas = map(seatingAreas, (seatingArea, index) => {
            return {
                id: seatingArea.id,
                sort: index
            };
        });

        return axios.post(window.route('api.events.seating-areas.reorder', [rootState.Event.event, moduleState.seatingChart]), {
            reorderedSeatingAreas
        }).then(({ data }) => {
            commit('seatingAreas', data);
        }).finally(() => {
            commit('saving', false);
        });
    },

    seat ({
        commit, rootState, state: moduleState, dispatch
    }, { seatable, seatingArea, position }) {
        commit('saving', true);
        commit('seatActionInProgress', {
            seatingAreaId: seatingArea.id,
            seatPosition: position,
            seatable
        });

        axios.post(window.route('api.events.seating-areas.seat', [rootState.Event.event, moduleState.seatingChart, seatingArea]), {
            seatable_id: seatable.id,
            seatable_type: seatable.type,
            seat_position: position
        }).then(({ data }) => {
            dispatch('seatSeatable', {
                seatable,
                newSeatingArea: data.newSeatingArea,
                previousSeatingArea: data.previousSeatingArea,
                seatingChart: data.seatingChart
            });
        }).catch((error) => {
            if (error.response.data.reason === 'exceededLimit') {
                App.alert().paymentRequired(
                    'Try it free',
                    'Seating Charts are free to try. Upgrade to seat additional guests.'
                );
                return;
            }

            this.$toasted.global.error('There was a problem with this seating.');
        }).finally(() => {
            commit('saving', false);
            commit('seatActionInProgress', null);
        });
    },

    seatSeatable ({ commit, rootState, state: moduleState }, {
        seatable, newSeatingArea, previousSeatingArea, seatingChart
    }) {
        commit('updateSeatsInSeatingArea', {
            seatingAreaId: newSeatingArea.id,
            updatedSeats: newSeatingArea.seats
        });

        commit('updateSeatOnSeatable', {
            seatableId: seatable.id,
            seatableType: seatable.type,
            seatingAreaId: newSeatingArea.id
        });

        commit('seatingChart', seatingChart);

        if (previousSeatingArea) {
            commit('updateSeatsInSeatingArea', {
                seatingAreaId: previousSeatingArea.id,
                updatedSeats: previousSeatingArea.seats
            });
        }
    },

    toggleUnseatingViaDragAndDrop ({ commit }, unseating) {
        commit('unseatingViaDragAndDrop', unseating);
    },

    unseat ({
        commit, rootState, state: moduleState, dispatch
    }, { seatable, seatingArea }) {
        commit('saving', true);
        commit('seatActionInProgress', {
            seatingAreaId: seatingArea.id,
            seatPosition: null,
            seatable
        });

        axios.post(window.route('api.events.seating-areas.unseat', [rootState.Event.event, moduleState.seatingChart, seatingArea]), {
            seatable_id: seatable.id,
            seatable_type: seatable.type
        }).then(({ data }) => {
            dispatch('unseatSeatable', {
                seatable,
                seatingChart: data.seatingChart,
                seatingArea: data.seatingArea
            });
        }).finally(() => {
            commit('saving', false);
            commit('seatActionInProgress', null);
        });
    },

    unseatSeatable ({ commit, rootState, state: moduleState }, { seatable, seatingArea, seatingChart }) {
        commit('updateSeatsInSeatingArea', {
            seatingAreaId: seatingArea.id,
            updatedSeats: seatingArea.seats
        });

        commit('updateSeatOnSeatable', {
            seatableId: seatable.id,
            seatableType: seatable.type,
            seatingAreaId: null
        });

        commit('seatingChart', seatingChart);
    },

    updateUnseatedSeatables ({ commit }, removedSeats) {
        if (!removedSeats) {
            return;
        }

        removedSeats.forEach((seat) => {
            commit('updateSeatOnSeatable', {
                seatableId: seat.seatable_id,
                seatableType: seat.seatable_type,
                seatingAreaId: null
            });
        });
    }
};

export default {
    namespaced: true,
    state: moduleState,
    mutations,
    actions,
    getters
};
