/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'beach-palm-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>location travel</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25 21.75c0-2.49-5.04-4.5-11.25-4.5-6.22 0-11.25 2.01-11.25 4.5v1.5h22.5zM15.63 8.48l-.81 8.91M8.49 17.47l5.24-9.61M13.73 7.85l8.276 2.7-.01-.01c.39.13.81-.08.95-.48a.74.74 0 00.02-.39h0a4.745 4.745 0 00-3.6-4.02h0c-.79-.17-1.61-.2-2.4-.09M13.73 7.85L6.19 9.89v-.01a.77.77 0 01-.93-.53.701.701 0 01-.02-.35h-.01a4.764 4.764 0 013.61-4.1h-.01c1.02-.24 2.09-.21 3.11.07"/><path pid="1" d="M18.578 5.56l3.1-3.12v-.01c.29-.29.29-.77 0-1.06a.823.823 0 00-.22-.16h0a4.879 4.879 0 00-5.09.5h-.01c-.64.51-1.09 1.21-1.28 2.01M10.179 4.78L8.22 2.66h0a.745.745 0 01.03-1.06c.09-.1.22-.16.35-.19h-.01c.82-.16 1.66-.24 2.51-.24l-.01-.01c1.66.15 3.14 1.11 3.96 2.57"/></g>'
  }
})
