<template>
    <div class="h-full">
        <div :id="blockId" class="cover-block h-full relative group">
            <wall-image-slider-block
                ref="slider"
                v-theme="'wall.accent-background'"
                v-bind="slider"
                class="h-full"
                :image-class="imageClass"
            >
                <template v-if="imageFormat === 'fit-and-scale'" #image="{ id, source }">
                    <div :key="id" class="relative h-full">
                        <img class="relative h-full mx-auto z-10" :src="source">
                        <div v-theme="'wall.background'" class="absolute top-0 overflow-hidden w-full h-full"></div>
                    </div>
                </template>
            </wall-image-slider-block>

            <div class="event-details absolute w-2/3 bottom-0 left-0 ml-10 mb-20">
                <tiptap-editor
                    ref="titleEditor"
                    v-model="titleHtml"
                    :read-only="!isEditMode"
                    simple
                    class="title font-bold leading-normal"
                    :text-styles="{ fontFamily : titleFont , fontSize: titleFontSize, color: textColor }"
                    hint-classes="-mx-2 -mt-2"
                    with-hint
                ></tiptap-editor>

                <tiptap-editor
                    ref="subtitleEditor"
                    v-model="subtitleHtml"
                    :read-only="!isEditMode"
                    simple
                    class="subtitle font-medium leading-normal"
                    :text-styles="{ fontFamily : subtitleFont , fontSize: subtitleFontSize, color: textColor }"
                    hint-classes="-mx-2 -mb-2"
                    with-hint
                ></tiptap-editor>
            </div>

            <div
                v-if="isScrollDownTextVisible"
                class="absolute bottom-0 inset-x-0 mb-2 text-white text-center animate-bounce"
                :class="scrollDownHintClass"
                :style="scrollDownHintStyle"
            >
                <p>{{ $t('text-scroll-down') }}</p>
            </div>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import SelfEditableBlockMixin from '@/mixins/SelfEditableBlockMixin';
import WallBlock from '@/mixins/WallBlock';

export default {
    name: 'WallCoverBlock',

    mixins: [SelfEditableBlockMixin, WallBlock],

    props: {
        imageFormat: {
            type: String,
            required: true
        },

        slider: {
            type: Object,
            required: true
        },

        subtitle: {
            type: String,
            required: true
        },

        textColor: {
            type: String,
            default: '#FFFFFF'
        },

        title: {
            type: String,
            required: true
        },

        titleFont: {
            type: String,
            required: true
        },

        titleFontSize: {
            type: String,
            required: true
        },

        subtitleFont: {
            type: String,
            required: true
        },

        subtitleFontSize: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            subtitleHtml: this.subtitle,
            titleHtml: this.title,
            syncSelfEditableSettings: {
                titleHtml: 'title',
                subtitleHtml: 'subtitle'
            }
        };
    },

    computed: {
        ...get('Wall/*'),

        imageClass () {
            const baseClass = 'min-w-full min-h-full';

            return this.imageFormat === 'scale-down'
                ? `${baseClass} object-scale-down`
                : `${baseClass} object-cover`;
        },

        isScrollDownTextVisible () {
            return this.wall.blocks.length > 2;
        },

        scrollDownHintClass () {
            return this.isEditMode
                ? { hidden: this.isMobileView }
                : 'hidden md:block';
        },

        scrollDownHintStyle () {
            return {
                color: this.textColor
            };
        }
    },

    watch: {
        imageFormat () {
            this.$nextTick(() => {
                this.$refs.slider.reinitializeSlider();
            });
        },

        title (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.titleHtml = newValue;
                this.$refs.titleEditor.editor.setContent(newValue);
            }
        },

        subtitle (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.subtitleHtml = newValue;
                this.$refs.subtitleEditor.editor.setContent(newValue);
            }
        }
    },

    created () {
        App.$on('modified-block', this.onVisibilityToggle);
    },

    beforeDestroy () {
        App.$off('modified-block', this.onVisibilityToggle);
    },

    methods: {
        onVisibilityToggle ({ id, isDisplayed }) {
            if (id === this.blockId && isDisplayed) {
                this.$refs.slider.reinitializeSlider();
            }
        }
    }
};
</script>
