<template>
    <div>
        <image-manager
            v-model="settings.slider.images"
            :settings="settings.slider"
            title="Upload Cover Images"
        >
            <template #recommendations>
                PNG and JPG files allowed. Max file size: 2 MB.<br>
                Cover Block Size Recommendation: 2,000 px wide x 1,830 px tall.
            </template>
        </image-manager>

        <form-field-wrapper label="Image Format" class="mt-8">
            <simple-picker
                v-model="settings.imageFormat"
                :items="imageFormatOptions"
                item-label="label"
                item-value="value"
            ></simple-picker>
        </form-field-wrapper>

        <form-field-wrapper label="Title" class="mt-8">
            <input
                :value="getTextStrippedOfHtml(settings.title)"
                type="text"
                class="form-field w-full"
                @input="settings.title = $event.target.value"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Sub-title" class="mt-8">
            <input
                :value="getTextStrippedOfHtml(settings.subtitle)"
                type="text"
                class="form-field w-full"
                @input="settings.subtitle = $event.target.value"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Text Color" class="mt-8">
            <color-picker v-model="settings.textColor" :has-alpha="false"></color-picker>
        </form-field-wrapper>

        <form-field-wrapper label="Title Font">
            <font-picker v-model="settings.titleFont"></font-picker>
        </form-field-wrapper>

        <form-field-wrapper label="Title Size">
            <font-size-picker v-model="settings.titleFontSize"></font-size-picker>
        </form-field-wrapper>

        <form-field-wrapper label="Sub-title Font">
            <font-picker v-model="settings.subtitleFont"></font-picker>
        </form-field-wrapper>

        <form-field-wrapper label="Sub-title Font">
            <font-size-picker v-model="settings.subtitleFontSize"></font-size-picker>
        </form-field-wrapper>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import BaseBlockIsSelfEditable from '@/mixins/BaseBlockIsSelfEditable';
import CanCloseElementSettings from '@/mixins/CanCloseElementSettings';

export default {
    name: 'WallCoverBlockSettings',

    mixins: [BaseBlockIsSelfEditable, CanCloseElementSettings],

    props: {
        block: {
            type: Object,
            required: true
        },

        event: {
            type: Object,
            required: true
        },

        settings: {
            type: Object,
            required: true
        }
    },

    computed: {
        imageFormatOptions () {
            return [
                { label: 'Cover and zoom', value: 'cover-and-zoom' },
                { label: 'Fit and scale', value: 'fit-and-scale' },
                { label: 'Scale down', value: 'scale-down' }
            ];
        },

        selectedBlock: get('Wall/selectedBlock')
    },

    methods: {
        getTextStrippedOfHtml (text) {
            if (!text) {
                return '';
            }

            return text.replace(/<[^>]*>?/gm, '');
        }
    }
};
</script>
