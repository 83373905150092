<template>
    <div class="bg-white -mt-12 pt-4" :data-user-segment-id="auth().user().userSegmentId">
        <tab-list
            class="w-full mt-4"
            :disabled="segments.length === 1"
            header-class="text-center text-lg uppercase tracking-wide border-none"
            @selected-tab="selectedTab"
        >
            <tab-list-item
                v-for="segment in segments"
                :id="segment.slug"
                :key="segment.id"
                :label="segment.label"
                class="pb-0 px-0"
            >
                <div class="flex flex-wrap justify-center mt-4 pb-8">
                    <plan-card
                        v-for="(spot, index) in getSegmentSpots(segment)"
                        :id="`plan-column-${index}-segment-${segment.id}`"
                        :key="spot.id"
                        :data-segment-id="segment.id"
                        :spot="spot"
                        :annual="showAnnualPlans"
                        :expanded-pricing-section="shouldExpandPricingSections(segment)"
                        :plan="getSpotPlan(spot)"
                        :show-description-container="shouldShowDescriptions(segment)"
                        :user-plan="userPlan"
                        @scroll-to-features="scrollToFeatures(segment)"
                    ></plan-card>
                </div>

                <div v-if="segment.settings.trusted_by_label && segment.trusted_by_image_url" class="flex mx-auto space-x-16 items-center mb-8 w-fit">
                    <span>{{ segment.settings.trusted_by_label }}</span>
                    <img :src="segment.trusted_by_image_url" alt="customers">
                </div>

                <div class="bg-gray-100 py-8">
                    <template v-if="segment.chart_html">
                        <div
                            v-if="segment.chart_title"
                            :id="`compare-features-${segment.id}`"
                            class="text-center font-semibold tracking-wide text-4xl my-8"
                        >
                            {{ segment.chart_title }}
                        </div>
                        <div
                            class="p-4"
                            v-html="segment.chart_html"
                        ></div>
                    </template>

                    <template v-if="segment.slider_html">
                        <div v-if="segment.slider_title" class="text-center font-semibold tracking-wide text-4xl my-8">
                            {{ segment.slider_title }}
                        </div>
                        <div class="p-4">
                            <div class="py-4 max-w-4xl mx-auto">
                                <div class="logo-slider" v-html="segment.slider_html"></div>
                            </div>
                        </div>
                    </template>

                    <template v-if="segment.faqs_html">
                        <div v-if="segment.faqs_title" class="text-center font-semibold tracking-wide text-4xl my-8">
                            {{ segment.faqs_title }}
                        </div>
                        <div
                            class="p-4"
                            v-html="segment.faqs_html"
                        ></div>
                    </template>
                </div>
            </tab-list-item>
        </tab-list>
    </div>
</template>

<script>
import find from 'lodash/find';
import VueScrollTo from 'vue-scrollto';
import Slick from 'vue-slick';
import $ from 'jquery';

export default {
    name: 'UpgradePage',

    components: { Slick },

    props: {
        segments: {
            type: Array,
            required: true
        },

        userPlan: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            showAnnualPlans: false
        };
    },

    computed: {
        carouselOptions () {
            return {
                slidesToShow: 5,
                slidesToScroll: 1,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 1500,
                pauseOnHover: false,
                dots: false,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2
                        }
                    }
                ]
            };
        }
    },

    mounted () {
        this.initializeSlider();

        document.querySelectorAll('table.feature-table tbody tr.feature').forEach((row) => {
            if (row.nextElementSibling?.className !== 'category') {
                return;
            }

            for (let i = 0; i < row.children.length; i++) {
                row.children.item(i).classList.add('pb-4');
            }
        });
    },

    destroyed () {
        this.destroySlider();
    },

    methods: {
        initializeSlider () {
            const sliderElement = $('.logo-slider');
            const hasSlides = sliderElement && sliderElement.html().includes('img class="h-8');

            if (hasSlides) {
                sliderElement.slick(this.carouselOptions);
            }
        },

        destroySlider () {
            const sliderElement = $('.logo-slider');
            const hasSlides = sliderElement && sliderElement.html().includes('img class="h-8');

            if (hasSlides) {
                sliderElement.slick('unslick');
            }
        },

        getSegmentSpots (segment) {
            if (this.showAnnualPlans && this.segmentHasAnnualPlans(segment)) {
                return segment.spots.filter((spot) => { return !!spot.annual_plan; });
            }

            return segment.spots;
        },

        getSpotPlan (spot) {
            if (this.showAnnualPlans) {
                return spot.annual_plan || spot.plan;
            }

            return spot.plan;
        },

        selectedTab (slug) {
            const segment = find(this.segments, { slug });

            if (this.segmentHasAnnualPlans(segment) && segment.settings.default_pricing === 'yearly') {
                this.showAnnualPlans = true;
            }
        },

        segmentHasAnnualPlans (segment) {
            return segment.spots.filter((spot) => { return !!spot.annual_plan; }).length > 0;
        },

        scrollToFeatures (segment) {
            VueScrollTo.scrollTo(`#compare-features-${segment.id}`, 500, {
                easing: 'ease-in-out',
                cancelable: false
            });
        },

        shouldShowDescriptions (segment) {
            return this.getSegmentSpots(segment).some((spot) => {
                return !!this.getSpotPlan(spot).description;
            });
        },

        shouldExpandPricingSections (segment) {
            return this.getSegmentSpots(segment).some((spot) => {
                return this.getSpotPlan(spot).billing_period === 'custom';
            });
        }
    }
};
</script>
