/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dessert-ice-cream-bowl-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M20.918 11.25v-.01c.77-.05 1.44.54 1.49 1.31 0 .08 0 .17-.01.26v-.01a8.848 8.848 0 01-8.83 6.66v-.01a8.85 8.85 0 01-8.84-6.67h0c-.1-.78.44-1.48 1.21-1.58.08-.02.18-.02.27-.01zM13.57 19.5v3.75M10.57 23.25h6"/><path pid="1" d="M6.143 11.252l-.01-.01a3.74 3.74 0 012.92-4.43c2.03-.42 4.01.89 4.42 2.92.04.24.07.49.07.74v.75M21 11.252v-.01c.4-2.04-.92-4.01-2.95-4.42-2.04-.41-4.01.91-4.42 2.94-.05.23-.08.47-.08.71v.75"/><path pid="2" d="M17.32 6.75v0c0-2.08-1.68-3.75-3.75-3.75-2.08 0-3.75 1.67-3.75 3.75M7.12 7.89L1.57.75"/></g>'
  }
})
