/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close-square-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>delete remove</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="22.5" height="22.5" x=".75" y=".749" rx="1.5"/><path pid="1" d="M7.5 16.5l9-9M16.5 16.5l-9-9"/></g>'
  }
})
