/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin-secret-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>help question</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.125 8.629l-.01-.01c-.01-1.04.83-1.88 1.87-1.88a1.86 1.86 0 011.87 1.87c0 1.03-.84 1.87-1.88 1.87h-.01M12 13.5h-.01c.2-.01.37.16.37.37M11.625 13.87l-.01-.01c-.01-.21.16-.38.37-.38v-.01M12 14.254h-.01a.386.386 0 01-.38-.38s0 0 0 0M12.37 13.87v0c0 .2-.17.375-.375.375"/><path pid="1" d="M21 9.754c0 7.9-6.932 12.33-8.629 13.3v-.01a.77.77 0 01-.75 0c-1.7-.97-8.63-5.41-8.63-13.3h0a8.988 8.988 0 018.99-9.01c4.97-.01 9 4.02 9 8.99 0 0 0 0 0 0z"/></g>'
  }
})
