/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-twitter-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M23 6.62l-2-.5 1-2-2.47.7h0a4.496 4.496 0 00-6.34.21c-.78.83-1.22 1.93-1.21 3.08v1c-3.54.73-6.64-1.2-9.5-4.5-.5 2.66 0 4.66 1.5 6l-3-.5c.405 2.06 1.362 3.7 4 4l-2.5 1c1 2 2.566 2.31 5 2.5h-.01c-1.9 1.35-4.18 2.05-6.5 1.99 12.75 5.66 20-2.67 20-10V8.27z"/>'
  }
})
