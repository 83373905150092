<template>
    <div class="my-3 flex items-center space-x-2">
        <span class="text-gray-800">{{ usage.current }}</span>
        <span v-if="usage.limit" class="text-gray-400 italic">- {{ usage.limit }}</span>
        <a
            v-if="usedFeature.message.tooltip"
            class="block"
            :href="usedFeature.message.tooltip"
            target="_blank"
        >
            <app-icon
                class="w-5 h-5 text-gray-400"
                name="info-circle-stroke"
            ></app-icon>
        </a>
    </div>
</template>

<script>
export default {
    name: 'UsedFeatureShortMessage',

    props: {
        usedFeature: {
            type: Object,
            required: true
        }
    },

    computed: {
        usage () {
            const { shortMessage } = this.usedFeature.message;

            if (shortMessage.indexOf(' - ') === -1) {
                return {
                    current: shortMessage,
                    limit: null
                };
            }

            const [current, limit] = shortMessage.split(' - ');

            return { current, limit };
        }
    }
};
</script>
