/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-accessory-bowtie-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>fancy suit tux</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M22.022 6.884h0a1.512 1.512 0 00-2.02-.66c-.01 0-.02 0-.03.01l-6.23 3.32h0c1.34.96 1.64 2.84.68 4.18-.19.26-.42.49-.69.68l6.22 3.32-.01-.01c.73.39 1.63.11 2.02-.62 0-.01 0-.02.01-.03v-.01c1.63-3.22 1.63-7.02-.01-10.232zM1.97 6.884v-.01A1.497 1.497 0 014 6.224l6.228 3.32v-.01a2.995 2.995 0 00-.69 4.18c.18.26.41.49.68.68l-6.23 3.32v-.01a1.5 1.5 0 01-2.03-.62c-.01-.01-.01-.02-.02-.03l-.01-.01A11.255 11.255 0 011.92 6.8z"/><path pid="1" d="M12 9a3 3 0 100 6 3 3 0 100-6zM15 12h2.25M6.75 12H9"/></g>'
  }
})
