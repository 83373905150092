<template>
    <div
        v-if="isActive"
        class="fixed inset-0 flex justify-center pt-16"
        :style="{'z-index': zIndex}"
    >
        <transition
            ref="modal"
            enter-class="opacity-0"
            enter-active-class="transition-all duration-75 ease-out"
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-active-class="transition-all duration-200 ease-in"
            leave-to-class="opacity-0"
            appear
            @before-leave="backdropLeaving = true"
            @after-leave="backdropLeaving = false"
        >
            <div v-if="isBackdropActive">
                <div class="absolute inset-0 bg-black opacity-50" @click="close"></div>
            </div>
        </transition>

        <transition
            enter-class="opacity-0 scale-125"
            enter-active-class="transition-all duration-300 ease-out"
            enter-to-class="opacity-100 scale-100"
            leave-class="opacity-100 scale-100"
            leave-active-class="transition-all duration-200 ease-in"
            leave-to-class="opacity-0 scale-125"
            appear
            @before-leave="cardLeaving = true"
            @after-leave="cardLeaving = false"
        >
            <div
                v-if="isCardActive"
                class="modal-content"
                :class="contentClasses"
            >
                <div class="flex flex-col justify-between shrink-0 bg-gray-100 border-b">
                    <div class="flex px-6 py-4" :class="headerClasses">
                        <div class="flex-1 font-semibold text-xl text-gray-800">
                            <slot name="header" :close="close">
                                {{ title }}
                            </slot>
                        </div>

                        <div class="flex grow-0">
                            <slot name="close-button" :close="close">
                                <button class="button-icon button-sm shrink" @click="close">
                                    <app-icon name="close"></app-icon>
                                </button>
                            </slot>
                        </div>
                    </div>

                    <slot
                        name="tab-controls"
                        :active-tab-styles="activeTabStyles"
                        :activate-tab="activateTab"
                    >
                        <ul
                            v-if="!hideTabControls"
                            class="flex justify-center w-full"
                            role="tablist"
                        >
                            <li
                                v-for="tab in tabs"
                                :key="tab.id"
                                class="inline-flex mx-2 -mb-px"
                                role="presentation"
                            >
                                <a
                                    role="button"
                                    class="py-2 px-4 border-b-2 font-medium transition duration-150 ease-in-out"
                                    :class="activeTabStyles(tab)"
                                    @click="activateTab(tab)"
                                >
                                    {{ tab.label }}
                                </a>
                            </li>
                        </ul>
                    </slot>
                </div>

                <div class="flex-1 overflow-y-auto overflow-x-hidden px-6 py-4">
                    <slot name="content" v-bind="contentProps"></slot>
                </div>

                <template v-for="footerSlot in footerSlots">
                    <div
                        v-if="hasSlot(footerSlot)"
                        :key="footerSlot"
                        class="flex flex-col items-center md:flex-row-reverse md:justify-start shrink-0 px-6 py-4 bg-gray-100 border-t modal-footer"
                    >
                        <div class="md:ml-auto flex flex-col items-center md:flex-row-reverse md:space-x-2 md:space-x-reverse w-full md:w-auto" :class="{ 'mb-4 md:mb-0': hasSlot('footer-split-options') }">
                            <slot :name="footerSlot" :close="close"></slot>
                        </div>

                        <slot name="footer-split-options"></slot>
                    </div>
                </template>
            </div>
        </transition>
    </div>
</template>

<script>
import Mousetrap from 'mousetrap';
import SlotHelpers from '@/mixins/SlotHelpers';
import BodyElementClassesHelper from '@/mixins/BodyElementClassesHelper';
import Toggleable from '@/mixins/Toggleable';

export default {
    name: 'TabbedModal',

    mixins: [Toggleable, BodyElementClassesHelper, SlotHelpers],

    provide () {
        return {
            tabbedModalState: this.sharedState
        };
    },

    props: {
        closeOnEsc: {
            type: Boolean,
            default: true
        },
        confirmClose: {
            type: Boolean,
            default: false
        },
        fullScreen: {
            type: Boolean,
            default: false
        },
        headerClasses: {
            type: String,
            default: 'items-baseline'
        },
        hideTabControls: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        zIndex: {
            type: Number,
            default: 60
        }
    },

    data () {
        return {
            backdropLeaving: false,
            cardLeaving: false,
            initialized: false,
            isBackdropActive: false,
            isCardActive: false,
            sharedState: {
                activeId: null
            },
            tabs: []
        };
    },

    computed: {
        contentClasses () {
            return [
                this.fullScreenClasses
            ];
        },

        contentProps () {
            return {
                close: this.close,
                initialize: this.initialize
            };
        },

        footerSlots () {
            return [
                `footer-${this.sharedState.activeId}`,
                'footer'
            ];
        },

        fullScreenClasses () {
            return {
                'full-screen': this.fullScreen,
                'full-screen-on-mobile': true
            };
        },

        isLeaving () {
            return this.backdropLeaving || this.cardLeaving;
        }
    },

    watch: {
        isActive (val) {
            this.toggleBodyElementClasses(['overflow-hidden'], val);

            if (val) {
                this.show();
            } else {
                this.close();
            }
        },

        isLeaving (val) {
            if (val === false) {
                this.isActive = false;
            }
        }
    },

    mounted () {
        if (this.closeOnEsc) {
            const mouseTrap = new Mousetrap(this.$refs.modal);

            mouseTrap.bind('esc', () => {
                this.close();
            });
        }

        if (this.isActive) {
            this.show();
        }
    },

    methods: {
        activateTab (tab) {
            this.sharedState.activeId = tab.id;

            this.$emit('activate-tab', tab.id);
        },

        activeTabStyles (tab) {
            return {
                'border-transparent text-gray-500 hover:text-gray-600': !this.tabIsActive(tab),
                'border-purple text-purple': this.tabIsActive(tab)
            };
        },

        close () {
            const closeModal = () => {
                this.isBackdropActive = false;
                this.isCardActive = false;

                this.sharedState.activeId = null;

                this.$emit('closed');
            };

            if (!this.confirmClose) {
                return closeModal();
            }

            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            App.alert().confirm(
                'Are you sure?',
                `The changes you've made haven't been saved. Are you sure you want to leave without saving your changes?`,
                attributes,
                closeModal
            );

            return true;
        },

        initialize () {
            this.tabs = this.$children.filter((component) => {
                return component.$vnode.tag.includes('TabbedModalItem');
            });

            this.activateTab(this.tabs[0]);

            this.initialized = true;
        },

        open (tabId = null) {
            this.isActive = true;

            if (!tabId) {
                return;
            }

            // The list of tabs is not available until the "TabbedModal"
            // component is initialized. It's initialized by its children,
            // usually when one of the children "TabbedModalItem" components
            // is mounted. We can't know when exactly that's going to happen,
            // which is why we have to periodically check and wait until the
            // initialization is complete.
            this.waitUntilInitialized().then(() => {
                const tab = this.tabs.find((tab) => {
                    return tab.id === tabId;
                });

                this.activateTab(tab);
            });
        },

        show () {
            this.isBackdropActive = true;
            this.isCardActive = true;
        },

        tabIsActive (tab) {
            return tab.id === this.sharedState.activeId;
        },

        waitUntilInitialized () {
            if (this.initialized) {
                return Promise.resolve();
            }

            return new Promise((resolve) => {
                const timer = setInterval(() => {
                    if (!this.initialized) {
                        return;
                    }

                    resolve();
                    clearInterval(timer);
                }, 100);
            });
        }
    }
};
</script>
