<template>
    <div class="table">
        <div class="relative flex">
            <div class="absolute w-full h-2 mt-3 bg-gray-400 rounded-md"></div>

            <div
                v-for="(step, index) in steps"
                :key="index"
                class="w-8 h-8 ring-4 ring-white rounded-full z-10"
                :class="circleStyle(index)"
            ></div>
        </div>

        <div class="flex">
            <div
                v-for="({ title }, index) in steps"
                :key="index"
                class="w-28 ml-10 text-center font-semibold"
                :class="textStyle(index)"
            >
                {{ title }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StepProgress',

    props: {
        currentStep: {
            type: Number,
            required: true
        },
        steps: {
            type: Array,
            required: true
        }
    },

    computed: {
        circleStyle () {
            return (index) => {
                return {
                    'ml-20': this.isFirst(index),
                    'ml-16': !this.isFirst(index),
                    'mr-20': this.isLast(index),
                    'mr-16': !this.isLast(index),
                    'bg-purple': this.isCurrent(index),
                    'bg-gray-400': !this.isCurrent(index)
                };
            };
        },

        textStyle () {
            return (index) => {
                return {
                    'ml-10': this.isFirst(index),
                    'ml-12': !this.isFirst(index),
                    'text-purple': this.isCurrent(index),
                    'text-gray-400': !this.isCurrent(index)
                };
            };
        }
    },

    methods: {
        isCurrent (index) {
            return this.currentStep === index;
        },

        isFirst (index) {
            return index === 0;
        },

        isLast (index) {
            return index === this.steps.length - 1;
        }
    }
};
</script>
