<template>
    <simple-picker
        :value="value"
        :placeholder-empty-state="placeholderEmptyState"
        :placeholder-search="placeholderSearch"
        :items="states"
        item-label="name"
        :item-value="itemValue"
        is-filterable
        filterable-property="name"
        @input="$emit('input', $event)"
    ></simple-picker>
</template>

<script>
import usStates from './us-states';

export default {
    name: 'UsStatesPicker',

    props: {
        value: {
            type: String,
            default: null
        },
        placeholderEmptyState: {
            type: String,
            default: 'Select a state...'
        },
        placeholderSearch: {
            type: String,
            default: 'Find a state...'
        },
        itemValue: {
            type: String,
            default: 'code'
        }
    },

    computed: {
        states () {
            return usStates;
        }
    }
};
</script>
