/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vegetables-broccoli-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.68 13.774h0a13.067 13.067 0 00-5.46-5.46M7.87 8.319c-.58 3.734-2.97 6.65-6.54 9.36v-.01a1.502 1.502 0 00-.16 2.26l2.854 2.85h0c.58.59 1.54.59 2.13 0 .04-.05.09-.1.13-.16 2.715-3.58 5.631-5.96 9.364-6.54"/><path pid="1" d="M11.6 17.61l-.01-.01c.56 2.43 3 3.95 5.44 3.38a4.545 4.545 0 003.48-4.05h-.01c.51-.23.98-.55 1.38-.94h-.01a4.542 4.542 0 00-1.42-7.39h-.01a4.526 4.526 0 00-3.78-5.15 4.84 4.84 0 00-1.37-.01h0a4.524 4.524 0 00-5.94-2.4c-1.07.45-1.93 1.3-2.39 2.36h-.01c-2.5.22-4.34 2.42-4.12 4.92a4.535 4.535 0 003.45 4"/><path pid="2" d="M11.31 6.649h-.01a2.28 2.28 0 01-2.54 1.97c-.89-.11-1.63-.73-1.89-1.58M10.12 11.73l2.11-2.11M12.27 13.88l2.1-2.11M17.35 12.69h-.01a2.265 2.265 0 00-1.98 2.53c.1.88.72 1.62 1.57 1.88"/></g>'
  }
})
