<template>
    <div>
        <div class="block relative mb-2 w-1/2">
            <div class="flex items-center p-3 border-2 border-transparent bg-gray-100 rounded-md focus-within:border-purple-light focus-within:bg-white">
                <app-icon class="h-5 w-5 mr-3 text-gray-400 shrink-0 grow-0 leading-0" name="search"></app-icon>

                <input
                    ref="searchInput"
                    v-model="searchTerm"
                    class="block w-full appearance-none bg-transparent focus:outline-none"
                    type="text"
                    placeholder="Find an icon (Press '/' to focus)"
                >

                <a
                    v-show="!!searchTerm"
                    class="ml-4 text-gray-500 hover:text-gray-600 transition duration-150 ease-in-out leading-0"
                    role="button"
                    @click="clearSearchTerm"
                >
                    <app-icon class="h-4 w-4 shrink-0 grow-0 leading-0" name="close"></app-icon>
                </a>
            </div>
        </div>

        <div class="row">
            <div
                v-for="(meta, iconName) in icons"
                :key="iconName"
                class="col-2 my-4 leading-0"
            >
                <copy-to-clipboard :copy="copyIconHtml(iconName)">
                    <button
                        v-tippy
                        :content="iconName"
                        class="p-2 rounded-md border leading-0 hover:bg-gray-100 transition duration-150 ease-in-out"
                    >
                        <app-icon :name="iconName" class="h-10 w-10 leading-0"></app-icon>
                    </button>

                    <template slot="success">
                        <div class="p-2 rounded-md border border-purple bg-purple-100 text-purple leading-0">
                            <app-icon :name="iconName" class="h-10 w-10 leading-0"></app-icon>
                        </div>
                    </template>
                </copy-to-clipboard>
            </div>
        </div>
    </div>
</template>

<script>
import VueSvgIcon from 'vue-svgicon';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import indexOf from 'lodash/indexOf';
import mapValues from 'lodash/mapValues';
import Mousetrap from 'mousetrap';

export default {
    data () {
        return {
            allIcons: {},
            searchTerm: ''
        };
    },

    computed: {
        icons () {
            return pickBy(this.allIcons, (meta) => {
                return meta.indexOf(this.searchTerm) !== -1;
            });
        },

        iconsList () {
            return keys(this.icons);
        }
    },

    mounted () {
        this.allIcons = this.getAllIconsWithMetadata();
    },

    created () {
        Mousetrap.bind('/', (e) => {
            e.preventDefault();
            this.$refs.searchInput.focus();
        });
    },

    methods: {
        clearSearchTerm () {
            this.searchTerm = '';
        },

        copyIconHtml (name) {
            return `<app-icon name="${name}"></app-icon>`;
        },

        getAllIconsWithMetadata () {
            return mapValues(VueSvgIcon.icons, (value, iconName) => {
                return `${iconName} ${this.getMetadataFromIconSvg(value.data)}`;
            });
        },

        getMetadataFromIconSvg (svgSource) {
            const match = svgSource.match(/<desc>(.*?)<\/desc>/);

            return match ? match[1] : '';
        },

        setupKeyListener () {
            return (e) => {
                const evt = window.event ? event : e;

                if (evt.keyCode === 191) {
                    this.$refs.searchInput.focus();
                }
            };
        }
    }
};
</script>
