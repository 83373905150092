<template>
    <app-drawer
        :value="value"
        :default-close-handler="closeWithoutSaving"
        @input="$emit('input', $event)"
    >
        <template #title-bar>
            <app-icon
                name="settings-cogs"
                class="h-6 w-6 text-gray-700"
                stroke
            ></app-icon>
            <p class="text-gray-800 font-bold text-lg">Table</p>
        </template>

        <form-field-wrapper label="Table name" :error="form.errors.get('name')">
            <input
                v-model="form.name"
                class="form-field"
                placeholder="Enter a name of this table"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Number of seats" :error="form.errors.get('number_of_seats')">
            <input
                v-model.number="form.number_of_seats"
                type="number"
                placeholder="Enter a number of seats"
                class="form-field"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Description" :error="form.errors.get('description')">
            <textarea
                v-model="form.description"
                class="form-field"
                rows="5"
                placeholder="Enter a description and details about this table."
            ></textarea>
        </form-field-wrapper>

        <form-field-wrapper label="Table Color" :error="form.errors.get('color')">
            <color-picker v-model="form.color"></color-picker>
        </form-field-wrapper>

        <portal to="app-drawer-footer">
            <footer-save-cancel
                confirm-button-text="Save"
                :processing="form.processing"
                @cancel="closeWithoutSaving"
                @save="saveArea"
            ></footer-save-cancel>
        </portal>
    </app-drawer>
</template>

<script>
import { call } from 'vuex-pathify';
import Form from '@/validation/Form';

export default {
    name: 'SeatingAreaSettings',

    props: {
        initialNumberOfSeats: {
            type: Number,
            default: 5
        },
        originalSeatingArea: {
            type: Object,
            default: null
        },
        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            form: new Form({
                id: null,
                name: '',
                number_of_seats: this.initialNumberOfSeats,
                description: '',
                color: '#ffffff'
            }),
            saving: false
        };
    },

    watch: {
        originalSeatingArea () {
            this.form = new Form({
                id: this.originalSeatingArea ? this.originalSeatingArea.id : null,
                name: this.originalSeatingArea ? this.originalSeatingArea.name : '',
                number_of_seats: this.originalSeatingArea ? this.originalSeatingArea.number_of_seats : this.initialNumberOfSeats,
                description: this.originalSeatingArea ? this.originalSeatingArea.description : '',
                color: this.originalSeatingArea ? this.originalSeatingArea.color : '#ffffff'
            });
        }
    },

    methods: {
        closeSettings () {
            this.form.restore();
            this.$emit('input', false);
        },

        closeWithoutSaving () {
            if (!this.form.isModified()) {
                this.closeSettings();
                return;
            }

            App.alert().confirm(
                'Are you sure?',
                `The changes you've made haven't been saved. Are you sure you want to leave without saving your changes?`,
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                },
                this.closeSettings
            );
        },

        createArea: call('Seating/createArea'),

        editArea: call('Seating/editArea'),

        saveArea () {
            if (this.originalSeatingArea) {
                this.editArea({
                    seatingAreaEditsForm: this.form,
                    seatingArea: this.originalSeatingArea
                }).then(() => {
                    this.closeSettings();
                });
            } else {
                this.createArea(this.form).then(() => {
                    this.closeSettings();
                });
            }
        }
    }
};
</script>
