import cloneDeep from 'lodash/cloneDeep';

export default {
    props: {
        value: {
            type: Object,
            required: true
        },

        form: {
            type: Object,
            required: true
        }
    },

    created () {
        if (!this.value[this.namespace]) {
            this.updateSettings(this.defaultSettings);
        }
    },

    computed: {
        /**
         * The default settings, specific to the question type.
         */
        defaultSettings () {
            return {};
        },

        /**
         * Configuration can sometimes be specific to a question type. For
         * items like that, we'd want them to live in a separate object inside
         * of the question's settings. Override this method to specify the
         * namespace where the type-specific settings will live.
         */
        namespace () {
            return null;
        },

        /**
         * An object of configuration items in the specified namespace.
         */
        settings () {
            if (!this.namespace) {
                return this.value;
            }

            return this.value[this.namespace] || {};
        }
    },

    methods: {
        /**
         * This method should be used to update a setting in the specified
         * namespace. It ensures that the settings are cloned before notifying
         * the parent component of changes.
         */
        updateSetting (key, value) {
            this.updateSettings({ ...this.settings, [key]: value });
        },

        /**
         * Persists the namespaced settings in the question settings object.
         */
        updateSettings (namespacedSettings) {
            const payload = cloneDeep(this.value);

            payload[this.namespace] = namespacedSettings;

            this.$emit('input', payload);
        }
    }
};
