/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-hot-dog-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>restaurant fast</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.359 6.14l.27.27h0a4.294 4.294 0 01-.02 6.07l-9.14 9.13v-.01a4.306 4.306 0 01-6.08.01l-.01-.01-.27-.27M2.641 17.861l-.27-.27-.01-.01a4.297 4.297 0 01.01-6.08L11.5 2.36h-.01a4.285 4.285 0 016.07-.02s0 0 0 0l.27.27"/></g><rect pid="1" width="24.393" height="5.71" x="-.197" y="9.145" rx="2.855" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" transform="matrix(.7071 -.70711 .7071 .7071 -4.971 12)"/><path pid="2" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6.417 18.593L6.737 17v-.01c.11-.57.55-1.02 1.12-1.13l.65-.14v-.01c.56-.12 1.01-.56 1.12-1.13l.13-.66h-.01c.11-.57.55-1.02 1.12-1.13l.66-.14v-.01c.56-.12 1-.56 1.12-1.13l.13-.66v-.01c.11-.57.55-1.02 1.12-1.13l.65-.14v-.01c.56-.12 1.01-.56 1.12-1.13l.13-.66h-.01c.11-.57.55-1.02 1.12-1.13l1.59-.33"/>'
  }
})
