/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>marker gps</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25.74a6 6 0 100 12 6 6 0 100-12z"/><path pid="1" d="M14.25 7.5l-.01-.01a3.739 3.739 0 013.74-3.76c0-.001 0 0 0 0M.75 23.25l12.26-12.26"/></g>'
  }
})
