/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buildings-cloudy-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>office city skyline weather business</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75.75L6.74.749a2.23 2.23 0 00-2.2 1.76l-.01-.01c-.24-.17-.52-.26-.8-.27h-.01c-.83 0-1.5.67-1.5 1.5 0 .82.67 1.5 1.5 1.5h3l-.01-.001c1.24 0 2.25-1.01 2.25-2.25 0-1.25-1.01-2.25-2.25-2.26zM13.75.75h8.5-.01c.55-.01 1 .44 1 .99v21c0 .27-.23.5-.5.5h-9.51a.51.51 0 01-.5-.51s0 0 0 0v-21h0a.99.99 0 01.99-1.01zM15.75 3.75v.75M20.25 3.75v.75M15.75 7.5v.75M20.25 7.5v.75M15.75 11.25V12M20.25 11.25V12M15.75 15v.75M20.25 15v.75M15.75 18.75v.75M20.25 18.75v.75M.75 12.75h9v10c0 .27-.23.5-.5.5H1.24a.51.51 0 01-.5-.51s0 0 0 0v-10z"/><path pid="1" d="M1.75 9.75h7-.01c.55-.01 1 .44 1 .99v2h-9v-2.01c0-.56.44-1 1-1zM3.75 15.75v.75M6.75 15.75v.75M3.75 19.5v.75M6.75 19.5v.75"/></g>'
  }
})
