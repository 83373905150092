<template>
    <base-dropdown
        class="inline-block"
        placement="auto-end"
        :position-fixed="false"
    >
        <template slot="trigger" slot-scope="{ triggerFunction }">
            <div
                class="rounded-md shadow-md w-8 h-8 relative border border-smoke-100"
                :style="{ backgroundColor: colors[colorType] }"
                @click="toggle(triggerFunction)"
            >
            </div>
        </template>

        <sketch-color-picker
            ref="colorPicker"
            :value="colors"
            class="mt-4"
            :preset-colors="presetColors"
            :disable-alpha="!hasAlpha"
            @input="updateColor"
        ></sketch-color-picker>
    </base-dropdown>
</template>

<script>
import { Sketch as SketchColorPicker } from 'vue-color';
import tinycolor from 'tinycolor2';

export default {
    components: { SketchColorPicker },

    props: {
        disabled: {
            type: Boolean,
            default: false
        },

        hasAlpha: {
            type: Boolean,
            default: true
        },

        value: {
            type: String,
            default: '#ffffffff'
        }
    },

    data () {
        const colorType = this.hasAlpha ? 'hex8' : 'hex';

        return {
            colors: {
                [colorType]: this.value
            },
            opened: false
        };
    },

    computed: {
        colorType () {
            if (!this.hasAlpha) {
                return 'hex';
            }

            return 'hex8';
        },

        currentOpacity () {
            if (!this.hasAlpha) {
                return 1;
            }

            return tinycolor(this.colors[this.colorType]).getAlpha();
        },

        presetColors () {
            const colors = [
                this.buildColor(208, 2, 27, this.currentOpacity),
                this.buildColor(245, 166, 35, this.currentOpacity),
                this.buildColor(248, 231, 28, this.currentOpacity),
                this.buildColor(139, 87, 42, this.currentOpacity),
                this.buildColor(126, 211, 33, this.currentOpacity),
                this.buildColor(65, 117, 5, this.currentOpacity),
                this.buildColor(189, 16, 224, this.currentOpacity),
                this.buildColor(144, 19, 254, this.currentOpacity),
                this.buildColor(74, 144, 226, this.currentOpacity),
                this.buildColor(80, 227, 194, this.currentOpacity),
                this.buildColor(184, 233, 134, this.currentOpacity),
                this.buildColor(0, 0, 0, this.currentOpacity),
                this.buildColor(74, 74, 74, this.currentOpacity),
                this.buildColor(155, 155, 155, this.currentOpacity),
                this.buildColor(255, 255, 255, this.currentOpacity)
            ];

            if (this.hasAlpha) {
                colors.push(this.buildColor(0, 0, 0, 0));
            }

            return colors;
        }
    },

    watch: {
        value (newValue) {
            this.colors[this.colorType] = newValue;
        }
    },

    methods: {
        buildColor (red, green, blue, alpha = 1) {
            return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
        },

        toggle (triggerFunction) {
            if (this.disabled) {
                return;
            }

            this.$refs.colorPicker.colorChange({
                hex: this.colors[this.colorType],
                source: 'hex'
            });

            triggerFunction();
        },

        updateColor (value) {
            this.colors = value;
            this.$emit('input', this.colors[this.colorType]);
        }
    }
};
</script>
