<template>
    <div>
        <button
            v-if="! hasAdvancedExportOptions"
            class="flex items-center bg-white rounded-md border p-2 hover:border-purple hover:cursor-pointer text-gray-600 hover:text-purple"
            @click="exportSpreadsheet"
        >
            <app-icon
                name="download-circle"
                class="w-5 h-5 mr-2"
                stroke
            ></app-icon>
            Export
        </button>

        <base-dropdown
            v-else
            placement="bottom-end"
        >
            <template slot="trigger" slot-scope="{ triggerFunction }">
                <button
                    class="flex items-center bg-white rounded-md border p-2 hover:border-purple hover:cursor-pointer text-gray-600 hover:text-purple"
                    @click="triggerFunction"
                >
                    <app-icon
                        name="download-circle"
                        class="w-5 h-5 mr-2"
                        stroke
                    ></app-icon>
                    Export
                </button>
            </template>

            <div class="p-2 bg-white border shadow flex flex-col w-72 tracking-wider">
                <form-field-wrapper label="Columns">
                    <select-list
                        v-model="columnExportType"
                        class="space-y-2"
                    >
                        <select-option
                            id="all"
                            label="All columns"
                        >
                        </select-option>
                        <select-option
                            id="selected"
                            label="Just visible columns"
                        >
                        </select-option>
                    </select-list>
                </form-field-wrapper>

                <div class="pt-2">
                    <form-field-wrapper
                        label="Rows"
                    >
                        <select-list
                            v-model="rowExportType"
                            class="space-y-2"
                        >
                            <select-option
                                id="all"
                                label="All rows"
                            >
                            </select-option>
                            <select-option
                                id="filtered"
                                label="Filtered only"
                            >
                            </select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="space-y-2 mt-4">
                    <button
                        class="button button-primary w-full text-sm h-4"
                        @click="exportSpreadsheet"
                    >
                        Download Spreadsheet
                    </button>

                    <button
                        v-if="showFileExport"
                        class="button button-secondary w-full text-sm h-4"
                        @click="exportAllFiles"
                    >
                        Download All Files (.zip)
                    </button>
                </div>
            </div>
        </base-dropdown>

        <export-button-modal
            v-model="isModalVisible"
            :state="state"
            :download-url="downloadUrl"
            @closed="isModalVisible = false"
        ></export-button-modal>
    </div>
</template>

<script>
import axios from '@/util/axios';
import Export from '@/mixins/Export';

const STATE_EXPORTING = 'exporting';
const STATE_EXPORT_READY = 'export-ready';
const STATE_FAILED = 'failed';

export default {
    mixins: [Export],

    props: {
        customQuestionId: {
            type: Number,
            required: true
        },

        showFileExport: {
            type: Boolean,
            required: true
        }
    },

    methods: {
        exportAllFiles () {
            this.listenForEvents();

            this.isModalVisible = true;
            this.state = STATE_EXPORTING;

            axios.get(
                this.route('submissions.custom-question.export', {
                    event: this.event,
                    question: this.customQuestionId
                })
            );
        },

        initiateExport () {
            axios.get(
                this.route('submissions.export', {
                    event: this.event,
                    columns: this.columnsByName,
                    predicates: this.predicates,
                    columnExportType: this.columnExportType,
                    rowExportType: this.rowExportType,
                    timezone: this.eventTimezone
                })
            );
        },

        listenForEvents () {
            this.$echo.private(`events.${this.event.id}`)
                .listen('.Domain\\Submissions\\Events\\ExportFailed', () => {
                    this.state = STATE_FAILED;
                })
                .listen('.Domain\\Submissions\\Events\\SubmissionsExported', ({ downloadUrl }) => {
                    this.downloadUrl = downloadUrl;
                    this.state = STATE_EXPORT_READY;
                    window.location = downloadUrl;
                });
        }
    }
};
</script>
