<template>
    <app-drawer :value="value" @input="$emit('input', $event)">
        <template slot="title-bar">
            <app-icon
                name="filter"
                class="h-6 w-6 text-gray-600"
                stroke
            ></app-icon>

            <div class="text-gray-800 font-bold text-lg">
                Filter &amp; Sort
            </div>
        </template>

        <div class="flex flex-col grow">
            <div class="flex flex-wrap">
                <form-field-wrapper label="Sort By">
                    <simple-picker
                        v-model="filterSettings.sortBy"
                        :items="sortByOptions"
                        placeholder-empty-state="Please select a field to sort by ..."
                    ></simple-picker>
                </form-field-wrapper>

                <form-field-wrapper label="Reply">
                    <simple-picker
                        v-model="filterSettings.reply"
                        :items="replyOptions"
                        placeholder-empty-state="Filter by guest reply"
                    ></simple-picker>
                </form-field-wrapper>

                <form-field-wrapper label="Email Invitation Status">
                    <simple-picker
                        v-model="filterSettings.invitationStatus"
                        :items="invitationStatusOptions"
                        placeholder-empty-state="Filter by whether an invite has been sent to the guest"
                    ></simple-picker>
                </form-field-wrapper>

                <form-field-wrapper label="Tags">
                    <select-list v-model="filterSettings.tags" multi-select>
                        <select-option
                            :id="1"
                            class="my-2"
                            label="Ipsum Sit Amet"
                        ></select-option>
                        <select-option
                            :id="2"
                            class="my-2"
                            label="Amor Ireculum"
                        ></select-option>
                        <select-option
                            :id="3"
                            class="my-2"
                            label="Sig Amor"
                        ></select-option>
                    </select-list>

                    <div class="mt-4 ml-8">
                        <a class="text-purple no-underline" href="#">Show 8 More Tags...</a>
                    </div>
                </form-field-wrapper>

                <form-field-wrapper label="Invited To">
                    <select-list v-model="filterSettings.invitedTo" multi-select>
                        <select-option
                            :id="1"
                            label="Primary Event"
                            class="my-2"
                        ></select-option>
                        <select-option
                            :id="2"
                            label="Secondary Event"
                            class="my-2"
                        ></select-option>
                        <select-option
                            :id="3"
                            label="Tertiary Event"
                            class="my-2"
                        ></select-option>
                    </select-list>
                </form-field-wrapper>
            </div>
        </div>

        <portal to="app-drawer-footer">
            <template slot-scope="{ defaultCloseHandler }">
                <footer-save-cancel
                    confirm-button-text="Apply"
                    @cancel="cancel"
                    @save="defaultCloseHandler"
                ></footer-save-cancel>
            </template>
        </portal>

    </app-drawer>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },

        filterSettings: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            originalFilterSettings: null,

            sortByOptions: [
                'Date Added',
                'Date of Response',
                'First Name',
                'Last Name'
            ],

            replyOptions: [
                'All',
                'Coming',
                'Not Coming',
                'Maybe'
            ],

            invitationStatusOptions: [
                'All',
                'Invite Sent',
                'Invite Not Sent',
                'Invite Opened',
                'Invite Not Opened'
            ]
        };
    },

    watch: {
        value (isOpened) {
            if (isOpened) {
                this.originalFilterSettings = cloneDeep(this.filterSettings);
            }
        }
    },

    methods: {
        cancel () {
            Object.assign(this.filterSettings, this.originalFilterSettings);
            this.$emit('input', false);
        }
    }
};
</script>
