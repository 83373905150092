/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-liquid-soap-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M22.012 14.74h0c0 1.93-1.57 3.5-3.51 3.5-1.94 0-3.51-1.57-3.51-3.51v-.01c0-2.628 3.5-7.009 3.5-7.009s3.5 4.38 3.5 7z"/><rect pid="1" width="10.012" height="14.017" x="1.988" y="9.233" rx="2.002"/><path pid="2" d="M4.766 6.23h4.45-.01c.42-.01.77.34.77.77 0 0 0 0 0 0v2.22H3.96V6.98c0-.43.34-.78.77-.78zM14 2.752l-.45-.895h0a2.011 2.011 0 00-1.79-1.11H2.98M6.99 14.24v4M4.99 16.24H9M6.99.75v5.48"/></g>'
  }
})
