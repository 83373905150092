/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>todo</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.25 14.26h5.25M11.25 18.76h5.25M8.25 13.88h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M11.25 9.76h5.25M8.25 9.38h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M8.25 18.386h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38"/><path pid="1" d="M15.75 4.51h3.75-.01c.82-.01 1.5.67 1.5 1.5v15.75c0 .82-.68 1.5-1.5 1.5H4.48a1.51 1.51 0 01-1.5-1.5s0 0 0 0V6.01h0c-.01-.83.67-1.51 1.49-1.51h3.75A3.739 3.739 0 0111.96.74a3.736 3.736 0 013.75 3.74s0 0 0 0z"/><path pid="2" d="M12 3.761h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38V3.74"/></g>'
  }
})
