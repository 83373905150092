/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rocket-flying-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M6.06 13.765l-3.622-1.21-.01-.01a.754.754 0 01-.48-.95.58.58 0 01.18-.3l.23-.24v-.01a5.986 5.986 0 016.14-1.45l1.25.41z" id="svgicon_rocket-flying-stroke_a"/><path pid="1" d="M10.3 18.007l1.2 3.622-.01-.01c.13.39.55.6.94.47.11-.04.21-.1.29-.19l.23-.24h-.01a6.012 6.012 0 001.44-6.14l-.42-1.26z" id="svgicon_rocket-flying-stroke_b"/></defs><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="2" d="M22.5 1.56l-2.16.24v-.01c-1.7.18-3.28.94-4.48 2.15l-9.81 9.8 4.24 4.24 9.8-9.81v-.01a7.57 7.57 0 002.15-4.48z"/><use xlink:href="#svgicon_rocket-flying-stroke_a"/><use xlink:href="#svgicon_rocket-flying-stroke_a"/><use xlink:href="#svgicon_rocket-flying-stroke_b"/><use xlink:href="#svgicon_rocket-flying-stroke_b"/><path pid="3" d="M7.77 18.538h0c0 .59-.24 1.16-.66 1.59-.88.87-5.3 2.12-5.3 2.12s1.24-4.43 2.121-5.3v-.01c.38-.39.89-.62 1.44-.66"/></g>'
  }
})
