/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-add-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 11.25a6 6 0 100 12 6 6 0 100-12zM17.25 14.25v6M14.25 17.25h6M6 .75a3 3 0 100 6 3 3 0 100-6zM10.55 10.888h0c-1.45-2.52-4.66-3.39-7.17-1.94a5.222 5.222 0 00-2.64 4.55v2.25h2.25l.75 7.5h4.5L8.56 20"/></g>'
  }
})
