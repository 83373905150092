import { make } from 'vuex-pathify';
import { get } from 'lodash';

const moduleState = {
    isEditMode: false,
    showAsSetupType: 'all',
    isMobileView: false,
    isPreview: false,
    form: {
        sections: []
    },
    activeBlock: {
        index: null,
        id: null
    },
    showPaymentScreen: false,
    selectedBlock: null
};

const getters = {
    formBackgroundStyle (state) {
        const backgroundType = get(state, 'form.settings.backgroundType');

        let styles = ``;

        if (backgroundType === 'image') {
            styles += `--image: url("${get(state, 'form.settings.background_url')}");`;
        } else if (backgroundType === 'color' && (!state.isEditMode || state.isPreview)) {
            styles += `background-color: ${get(state, 'form.settings.theme.colors.pageBackground')}`;
        }

        return styles;
    }
};

export default {
    namespaced: true,
    state: moduleState,
    mutations: {
        ...make.mutations(moduleState),

        touch (state) {
            if (state.form.published_at !== null) {
                state.form.hasUnpublishedChanges = true;
            }
        }
    },
    getters
};
