<template>
    <div class="w-full max-w-2xl mx-auto flex flex-col">
        <form-field-wrapper id="field_subdomain" :label="eventUrlText">
            <event-subdomain v-model="subdomain" validate-on-init></event-subdomain>
        </form-field-wrapper>

        <stateful-button
            id="button_continue"
            class="button-primary mt-8"
            :loading="validationForm?.processing"
            type="button"
            @click="validateAndComplete"
        >
            <template v-if="hasPickedTemplate">Next: Create {{ authenticated ? 'Event' : 'Account' }}</template>
            <template v-else>Next: Pick a theme</template>
        </stateful-button>
    </div>
</template>

<script>
import slug from '@node_modules/slug';
import { get, sync } from 'vuex-pathify';
import Form from '@/validation/Form';

export default {
    name: 'EventDetailsUrlStep',

    props: {
        authenticated: {
            type: Boolean,
            required: true
        },
        eventUrlText: {
            type: String,
            required: true
        },
        hasPickedTemplate: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            validationForm: null
        };
    },

    computed: {
        name: get('CreateEvent/name'),
        subdomain: sync('CreateEvent/subdomain')
    },

    created () {
        this.subdomain = slug(this.name, { lower: true, replacement: '' }).substring(0, 25);
    },

    methods: {
        async validateAndComplete () {
            try {
                this.validationForm = new Form({
                    subdomain: this.subdomain
                });

                await this.validationForm.validate(this.route('api.events.validate-store'));

                this.$emit('completed');
            } catch (error) {
                if (error.response?.status !== 422) {
                    throw error;
                }
            }
        }
    }
};
</script>
