/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'team-remote-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>meeting network group users message team</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15 11.261H9h0a2.996 2.996 0 012.99-3.01v0h-.01c1.65-.01 3 1.34 3 3z"/><path pid="1" d="M12 3.76a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5z"/><rect pid="2" width="16.5" height="10.5" x="3.75" y=".761" rx="3"/><path pid="3" d="M4.5 15.76a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM8.25 23.261h0a4.251 4.251 0 00-5.75-1.76c-.75.39-1.36 1-1.75 1.75M19.5 15.76a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM23.25 23.261h0a4.251 4.251 0 00-5.75-1.76c-.75.39-1.36 1-1.75 1.75M12 15.76a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM15.75 23.261h0a4.251 4.251 0 00-5.75-1.76c-.75.39-1.36 1-1.75 1.75"/></g>'
  }
})
