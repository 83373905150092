/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'makeup-mirror-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>beauty</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.751 18.75l-.01-.01C2.2 15.84.87 9.81 3.77 5.27 6.66.73 12.691-.6 17.231 2.3c4.53 2.89 5.86 8.92 2.96 13.46a9.864 9.864 0 01-2.97 2.96"/><path pid="1" d="M22.5 18.75h-6.314l-.01-.001c-.26-.01-.5.12-.64.34-.28.44-.79 1.154-1.3 1.154H9.72c-.516 0-1.04-.72-1.3-1.154h0a.755.755 0 00-.64-.35H1.45c-.42 0-.75.33-.75.75 0 0 0 0 0 0v.75h0c0 1.65 1.34 2.99 3 2.99h16.5l-.01-.001c1.65 0 3-1.35 3-3v-.761c0-.42-.34-.75-.75-.75h-.01zM12 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 100-13.5z"/></g>'
  }
})
