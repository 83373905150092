/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.934 7.263l-1.665-1.67-.01-.01c-.65-.65-.65-1.69 0-2.34.64-.65 1.68-.65 2.33 0L7.24 4.9M7.263 19.066l-1.67 1.66v-.01c-.65.64-1.69.64-2.34 0-.65-.65-.65-1.69-.01-2.34l1.665-1.67M19.066 16.73l1.66 1.66h0c.64.64.64 1.68-.01 2.33-.65.64-1.69.64-2.34-.01l-1.67-1.67M16.73 4.934l1.66-1.665h-.01c.64-.65 1.68-.65 2.33-.01.64.64.64 1.68 0 2.33l-1.67 1.66"/><path pid="1" d="M20.347 10.35h0a8.526 8.526 0 00-6.7-6.71M13.64 20.347v-.01a8.501 8.501 0 006.69-6.71M3.65 13.64l-.01-.01a8.503 8.503 0 006.69 6.7M10.35 3.65h-.01a8.475 8.475 0 00-6.7 6.7"/><path pid="2" d="M9.8 6.787v-.01c.35-.36.54-.83.54-1.33V2.36c0-.91.73-1.65 1.64-1.65.9 0 1.64.73 1.64 1.64v3.06h0c0 .49.2.97.55 1.32M6.787 14.2h0a1.86 1.86 0 00-1.33-.55h-3.07l-.01-.001c-.91-.01-1.65-.74-1.65-1.65 0-.91.73-1.65 1.64-1.65 0 0 0 0 0 0h3.06-.01c.49-.01.97-.2 1.32-.55M14.2 17.213h-.01c-.36.35-.55.82-.55 1.32v3.06-.01a1.65 1.65 0 01-3.3-.01v-3.07h0c-.01-.5-.2-.98-.55-1.33M17.213 9.8l-.01-.01c.35.35.82.54 1.32.54h3.06-.01c.9-.01 1.64.73 1.64 1.64 0 .9-.74 1.64-1.65 1.64H18.483c-.5 0-.98.19-1.33.54"/></g>'
  }
})
