/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'money-bag-dollar-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.574 12.75h-2.05c-.75 0-1.35.6-1.35 1.34 0 .54.33 1.03.84 1.24l2.064.82-.01-.01c.68.27 1.02 1.05.74 1.74-.21.5-.7.84-1.25.84h-2.04M12.07 12.75V12M12.07 19.5v-.75M6.82 8.25h10.5M15.63 5.03l1.43-2.88v-.01c.18-.38.03-.83-.34-1.01a.721.721 0 00-.67 0l-1.9.948h-.01c-.34.16-.75.05-.96-.26l-.51-.77h0a.756.756 0 00-1.05-.21c-.09.05-.16.12-.21.2l-.51.76h-.01c-.21.31-.63.42-.96.25l-1.9-.95h0a.745.745 0 00-1.01.33c-.11.21-.11.45-.01.66L8.42 4.9"/><path pid="1" d="M7.237 10.5l-2.76 3.2h-.01c-2 2.59-1.52 6.32 1.08 8.31 1.03.79 2.3 1.22 3.61 1.23h5.79v-.001c3.27-.01 5.93-2.66 5.93-5.94a6.003 6.003 0 00-1.24-3.62l-2.76-3.2"/></g>'
  }
})
