/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-soccer-goalkeeper-glove': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>player goalie game</desc><g _fill="currentColor"><path pid="0" d="M3.088 13.776a.534.534 0 00-.751 0l-2.025 2a1.067 1.067 0 000 1.508l6.032 6.033a1.067 1.067 0 001.506 0l2.022-1.997a.534.534 0 000-.757zM21.38 13.553l-2.765.385a.25.25 0 01-.212-.424l5.129-5.128a1.6 1.6 0 00-1.2-2.729.25.25 0 01-.2-.408 1.6 1.6 0 00-2.263-2.235.25.25 0 01-.289.022.247.247 0 01-.116-.265 1.507 1.507 0 00-.457-1.171 1.6 1.6 0 00-2.262 0l-.666.667a.251.251 0 01-.418-.245 1.353 1.353 0 00-.424-1.177 1.6 1.6 0 00-2.262 0L6.188 7.632c-1.794 1.794-2.281 3.124-1.96 4.6a1 1 0 00.27.494l6.214 6.215c1.509 1.508 4.442.5 6.787 0a42.126 42.126 0 004.15-1.129c1.131-.376 2.076-1.1 2-2.272a2.134 2.134 0 00-2.269-1.987zm-5.524-.753a.8.8 0 01-1.131 0L10.46 8.531A.8.8 0 0111.591 7.4l4.265 4.266a.8.8 0 010 1.134z"/></g>'
  }
})
