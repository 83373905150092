/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'navigation-menu-vertical-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" _fill="none" _stroke="currentColor" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>'
  }
})
