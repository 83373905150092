<template>
    <div v-show="isActive">
        <slot :is-active="isActive"></slot>
    </div>
</template>

<script>
export default {
    name: 'TabbedModalItem',

    parent: 'TabbedModal',

    inject: ['tabbedModalState'],

    props: {
        active: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        }
    },

    computed: {
        isActive () {
            return this.id === this.tabbedModalState.activeId;
        }
    }
};
</script>
