export default {
    methods: {
        getCreditCardBrandIcon (brand) {
            const creditCards = {
                american: 'credit-card-amex',
                amex: 'credit-card-amex',
                discover: 'credit-card-discover',
                mastercard: 'credit-card-mastercard',
                visa: 'credit-card-visa',
                default: 'credit-card-default'
            };

            const brandKey = Object.keys(creditCards).find((creditCard) => {
                return brand.toLowerCase().includes(creditCard);
            });

            return brandKey ? creditCards[brandKey] : creditCards.default;
        }
    }
};
