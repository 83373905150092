<template>
    <div class="container">
        <div class="py-2 font-semibold text-xl text-black">Single Sign-on (SSO)</div>

        <div v-if="isSsoDisabledOnPlan">
            <div class="flex flex-col md:flex md:flex-row md:flex-1 items-center space-x-0 md:space-x-3 border-t p-4 mt-2 bg-white md:bg-gray-50">
                <img class="w-8 h-8 mr-0 mt-0 md:mr-2 md:mt-1" :src="asset('images/account/rsvpify-premium.svg')">
                <div>
                    <p class="mt-3 md:mt-0 text-center md:text-left text-gray-600 tracking-wide font-semibold">Contact your Account Manager to add SSO.</p>
                    <p class="mt-3 md:mt-0 mr-0 md:mr-20 text-center md:text-left text-gray-600">
                        Enable Single Sign-on (SSO) to allow your organization's SAM 2.0-based identity provider to authenticate to RSVPify.
                    </p>
                </div>

                <a
                    class="button button-inverse button-info w-52 h-12 mt-5 md:mt-0"
                    href="https://help.rsvpify.com/en/articles/5613774-how-do-i-set-up-single-sign-on-with-azure"
                    target="_blank"
                >Learn more</a>

                <button class="button button-info w-52 h-12 mt-5 md:mt-0" @click="chatWithUs">
                    <app-icon name="messages-bubble-double-stroke"></app-icon> Chat with us
                </button>
            </div>
        </div>

        <div v-if="isSsoEnabled && refreshedFinalize" :class="{ 'pointer-events-none opacity-50': isReconfigurationAvailable }">
            <div class="flex flex-col items-center text-center w-full mt-20 text-gray-600">
                <app-icon name="check-circle-stroke" class="w-24 h-24 text-green"></app-icon>
                <p class="mt-8">Single Sign-on is enabled on your domain:</p>
                <p class="mt-8 font-semibold">{{ tenant.key }}</p>
            </div>

            <stateful-button
                v-if="tenant.enabled"
                class="flex mx-auto w-56 mt-5 button-primary"
                @click="deleteTenant"
            >Reconfigure</stateful-button>
        </div>

        <div
            v-else
            class="w-full md:w-1/2 mx-auto mt-10 mb-5"
            :class="{ 'pointer-events-none opacity-50': isSsoDisabledOnPlan }"
        >
            <step-progress
                class="mx-auto mb-4"
                :current-step="currentStep"
                :steps="steps"
            ></step-progress>

            <div v-if="currentStep === STEP_VERIFY_DOMAIN" class="mt-10">
                <form-field-wrapper :error="domainNameForm.errors.get('domainName')" label="Email Domain Name">
                    <input
                        v-model="domainNameForm.domainName"
                        class="form-field w-1/2"
                        placeholder="example.com"
                        type="text"
                    >
                </form-field-wrapper>

                <form-field-wrapper class="text-gray-500" label="Verify Domain Ownership">
                    <div class="mb-2">
                        Your ownership of this domain needs to be verified. Please add a TXT record to your domain's DNS. Or, alternatively, contact our passionate support team for manual verification. In some cases, it can take several hours for DNS records to update.
                    </div>

                    <div class="flex items-center">
                        <div class="mr-2 p-2 border rounded text-sm">{{ tenant.dnsRecordValue }}</div>

                        <copy-to-clipboard :copy="tenant.dnsRecordValue" @copied="$toasted.global.success('TXT record has been copied to your clipboard.')">
                            <button class="button-icon">
                                <app-icon class="w-4 h-4" name="file-copy"></app-icon>
                            </button>
                        </copy-to-clipboard>
                    </div>
                </form-field-wrapper>

                <div class="flex justify-end space-x-2 mt-5">
                    <button class="button-text button-primary mr-4" @click="updateDomainName">Save &amp; Verify Later</button>

                    <stateful-button
                        class="button-primary"
                        :loading="domainNameForm.processing"
                        @click="verifyDomainName"
                    >Verify Now</stateful-button>
                </div>
            </div>

            <template v-else-if="currentStep === STEP_CONFIG">
                <div class="flex items-center justify-center mb-2">
                    <app-icon name="check-circle" class="w-5 h-5 mr-2 text-teal"></app-icon>
                    <strong class="mr-1">{{ tenant.key }}</strong> has been verified.
                </div>

                <div class="mb-2 text-gray-500 italic">Copy and paste the following configuration details from your SSO provider:</div>

                <form-field-wrapper :error="configForm.errors.get('idp_entity_id')" label="Entity ID">
                    <input
                        v-model="configForm.idp_entity_id"
                        class="form-field"
                        placeholder="https://example.com/metadata"
                        type="text"
                    >
                </form-field-wrapper>

                <form-field-wrapper :error="configForm.errors.get('idp_login_url')" label="Login URL">
                    <input
                        v-model="configForm.idp_login_url"
                        class="form-field"
                        placeholder="https://example.com/login"
                        type="text"
                    >
                </form-field-wrapper>

                <form-field-wrapper :error="configForm.errors.get('idp_x509_cert')" label="Certificate">
                    <textarea v-model="configForm.idp_x509_cert" class="form-field"></textarea>
                </form-field-wrapper>

                <stateful-button
                    class="button-primary mt-5"
                    :loading="configForm.processing"
                    @click="updateConfiguration"
                >Continue</stateful-button>
            </template>

            <template v-else-if="currentStep === STEP_FINALIZE">
                <div class="flex items-center justify-center mb-2">
                    <app-icon name="check-circle" class="w-5 h-5 mr-2 text-teal"></app-icon>
                    <strong class="mr-1">{{ tenant.key }}</strong> has been verified.
                </div>

                <div class="mb-2 text-center">
                    <button class="button-text button-primary" @click="currentStep = STEP_CONFIG">Edit Configuration</button>
                </div>

                <div class="mb-4 text-gray-500 italic">Enter the following configurations into your SSO provider:</div>

                <form-field-wrapper class="text-gray-500" label="Entity ID">
                    <div class="flex items-center">
                        <div class="w-full mr-2 p-2 border rounded text-sm">{{ tenant.entityId }}</div>

                        <copy-to-clipboard :copy="tenant.entityId" @copied="$toasted.global.success('The entity ID has been copied to your clipboard.')">
                            <button class="button-icon">
                                <app-icon class="w-4 h-4" name="file-copy"></app-icon>
                            </button>
                        </copy-to-clipboard>
                    </div>
                </form-field-wrapper>

                <form-field-wrapper class="text-gray-500" label="Reply URL">
                    <div class="flex items-center">
                        <div class="w-full mr-2 p-2 border rounded text-sm">{{ tenant.replyUrl }}</div>

                        <copy-to-clipboard :copy="tenant.replyUrl" @copied="$toasted.global.success('The reply URL has been copied to your clipboard.')">
                            <button class="button-icon">
                                <app-icon class="w-4 h-4" name="file-copy"></app-icon>
                            </button>
                        </copy-to-clipboard>
                    </div>
                </form-field-wrapper>

                <div class="mt-5">
                    <stateful-button
                        v-if="!tenant.enabled"
                        class="button-primary"
                        @click="enableTenant"
                    >Enable SSO</stateful-button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import axios from '@/util/axios';
import Form from '@/validation/Form';

export default {
    props: {
        initialTenant: {
            type: Object,
            default: null
        }
    },

    data () {
        return {
            STEP_VERIFY_DOMAIN: 0,
            STEP_CONFIG: 1,
            STEP_FINALIZE: 2,

            configForm: new Form({
                idp_entity_id: this.initialTenant?.idp_entity_id || '',
                idp_login_url: this.initialTenant?.idp_login_url || '',
                idp_x509_cert: this.initialTenant?.idp_x509_cert || ''
            }),
            currentStep: 0,
            domainNameForm: new Form({
                domainName: this.initialTenant?.key || ''
            }),
            tenant: cloneDeep(this.initialTenant),
            refreshedFinalize: false
        };
    },

    computed: {
        isSsoDisabledOnPlan () {
            return this.auth().user().plan.features.Sso.type === 'disabled';
        },

        isSsoEnabled () {
            return this.tenant.enabled;
        },

        steps () {
            return [
                { title: 'Domain Verification' },
                { title: 'Configuration' },
                { title: 'Finalize' }
            ];
        },

        isReconfigurationAvailable () {
            return this.isSsoDisabledOnPlan && this.isSsoEnabled;
        }
    },

    created () {
        if (this.initialTenant.configured) {
            this.currentStep = this.STEP_FINALIZE;
            this.refreshedFinalize = true;
        } else if (this.initialTenant.verified) {
            this.currentStep = this.STEP_CONFIG;
        } else {
            this.currentStep = this.STEP_VERIFY_DOMAIN;
        }
    },

    methods: {
        chatWithUs () {
            if (window.Intercom) {
                window.Intercom('showNewMessage', 'Hello, I would like more information about Single Sign-on (SSO).');
            }
        },

        deleteTenant () {
            App.alert().confirm(
                'Are you sure?',
                'Users with RSVPify accounts affiliated with your domain name will no longer be able to login with SSO. They will be able to request a password reset to assign a password to their account in place of SSO authentication.',
                { confirmButtonText: 'Disable SSO' },
                async () => {
                    try {
                        await axios.delete(this.route('api.tenants.destroy', this.tenant.uuid));

                        this.$toasted.global.success('Single sign-on disabled.');

                        window.location.reload();
                    } catch (error) {
                        this.$toasted.global.error('An error occurred.');
                    }
                }
            );
        },

        async enableTenant () {
            try {
                const { data } = await axios.patch(this.route('api.tenants.enable', this.tenant.uuid));

                this.tenant = data;

                this.$toasted.global.success('Single sign-on enabled.');
            } catch (error) {
                this.$toasted.global.error('An error occurred.');
            }
        },

        async updateConfiguration () {
            try {
                const { data } = await this.configForm.patch(this.route('api.tenants.update-configuration', this.tenant.uuid));

                this.tenant = data;

                if (this.tenant.configured) {
                    this.currentStep = this.STEP_FINALIZE;
                }
            } catch (error) {
                this.$toasted.global.error('An error occurred.');
            }
        },

        async updateDomainName () {
            try {
                const { data } = await this.domainNameForm.patch(this.route('api.tenants.update-domain-name', this.tenant.uuid));

                this.tenant = data;
            } catch (error) {
                this.$toasted.global.error('An error occurred.');
            }
        },

        async verifyDomainName () {
            try {
                const { data } = await this.domainNameForm.post(this.route('api.tenants.verify-domain-name', this.tenant.uuid));

                this.tenant = data;

                if (this.tenant.verified) {
                    this.currentStep = this.STEP_CONFIG;
                }
            } catch (error) {
                this.$toasted.global.error('An error occurred.');
            }
        }
    }
};
</script>
