<template>
    <phone-number-input
        ref="input"
        v-model="internalValue"
    ></phone-number-input>
</template>

<script>
import CustomQuestion from '@/mixins/CustomQuestion';

export default {
    name: 'FormPhoneNumberQuestion',

    mixins: [CustomQuestion],

    methods: {
        focus () {
            this.$refs.input.focus();
        }
    }
};
</script>
