<template>
    <app-modal
        :value="value"
        header-classes="bg-green-light "
        @input="$emit('input', $event)"
    >
        <template #header>
            <p class="text-green-dark uppercase tracking-wide text-sm">{{ title }}</p>
        </template>

        <template #close-button="{ close }">
            <button class="button-icon button-sm shrink" @click="close">
                <app-icon name="close" class="text-green-dark"></app-icon>
            </button>
        </template>

        <div class="flex flex-col items-center justify-center space-y-4 w-full">
            <div class="flex items-center justify-center rounded-full p-4 bg-purple-100">
                <app-icon
                    name="rocket-flying"
                    class="h-10 w-10 text-purple"
                    stroke
                ></app-icon>
            </div>

            <div class="flex flex-col items-center justify-center space-y-1">
                <p class="text-lg font-semibold text-center">Congratulations! Your event has been published.</p>
                <p class="text-center">You can still make changes any time.</p>
            </div>

            <div class="flex flex-col space-y-2">
                <div v-if="showRegistrationOpensAtLabel" class="flex items-center justify-center w-full text-center">
                    <app-icon name="calendar-clock-stroke" class="h-4 w-4 mr-2"></app-icon>
                    <p>
                        {{ registrationRsvpLabels.registrationOpensIn }} <span
                            v-tippy
                            :content="registrationOpensAtFormattedDateTime"
                            class="tooltip-text"
                        >{{ registrationOpensIn }}</span>.
                    </p>
                </div>
                <div v-if="hasDeadline" class="flex items-center w-full text-center">
                    <app-icon name="calendar-clock-stroke" class="h-4 w-4 mr-2"></app-icon>
                    <p>
                        {{ registrationRsvpLabels.registrationClosesIn }} <span
                            v-tippy
                            :content="registrationClosesAtFormattedDateTime"
                            class="tooltip-text"
                        >{{ registrationClosesIn }}</span>.
                    </p>
                </div>
            </div>

            <div class="flex flex-col md:flex-row space-y-2 md:space-x-2 md:space-y-0 w-full">
                <copy-published-url class="w-full md:w-1/2">
                    <template #default="{ copyPublishedUrl }">
                        <button class="flex items-center justify-center space-x-4 p-4 rounded-md border shadow-md w-full hover:cursor-pointer hover:border-blue transition" @click="copyPublishedUrl">
                            <app-icon
                                name="link"
                                class="h-6 w-6 text-blue-dark"
                                stroke
                            ></app-icon>
                            <p>Copy Event Link</p>
                        </button>
                    </template>
                </copy-published-url>

                <a :href="route('events.campaigns.drafts.create', event)" class="flex items-center justify-center space-x-4 p-4 rounded-md border shadow-md w-full md:w-1/2 hover:cursor-pointer hover:border-blue transition">
                    <app-icon
                        name="email-send"
                        stroke
                        class="h-6 w-6 text-blue-dark"
                    ></app-icon>
                    <div class="flex items-center justify-center">
                        <p class="mr-2">Design Email Invitations</p>
                        <span v-tippy content="Create and send email invitations (optional). You’ll need to add an Invite List first.">
                            <app-icon class="w-4 h-4" name="info-circle-stroke"></app-icon>
                        </span>
                    </div>
                </a>
            </div>

            <div class="flex flex-col md:flex-row space-y-2 md:space-x-2 md:space-y-0 w-full">
                <qr-code class="w-full md:w-1/2">
                    <template #trigger="{ showModal }">
                        <button class="flex items-center justify-center space-x-4 p-4 rounded-md border shadow-md w-full hover:cursor-pointer hover:border-blue transition" @click="showModal">
                            <app-icon
                                name="technology-qr-code-scan"
                                class="h-6 w-6 text-blue-dark"
                                stroke
                            ></app-icon>
                            <div class="flex items-center justify-center">
                                <p class="mr-2">Get QR Code</p>
                                <span v-tippy content="Allow invitees to register or RSVP for your event by scanning a QR code. Add your QR code to paper invitations or advertisements.">
                                    <app-icon class="w-4 h-4" name="info-circle-stroke"></app-icon>
                                </span>
                            </div>
                        </button>
                    </template>
                </qr-code>

                <embed-form class="w-full md:w-1/2">
                    <template #trigger="{ showModal }">
                        <button class="flex items-center justify-center space-x-4 p-4 rounded-md border shadow-md w-full hover:cursor-pointer hover:border-blue transition" @click="showModal">
                            <app-icon
                                name="monitor-code-send"
                                stroke
                                class="h-6 w-6 text-blue-dark"
                            ></app-icon>
                            <div class="flex items-center justify-center">
                                <p class="mr-2">Get Embed Code</p>
                                <span v-tippy content="Add your registration or RSVP form to another website.">
                                    <app-icon class="w-4 h-4" name="info-circle-stroke"></app-icon>
                                </span>
                            </div>
                        </button>
                    </template>
                </embed-form>
            </div>

            <div class="flex flex-col md:flex-row space-y-2 md:space-x-2 md:space-y-0 w-full">
                <div class="w-full" :class="hasDeadline ? 'md:w-full' : 'md:w-1/2'">
                    <a
                        :href="route('event.show', event.subdomain)"
                        target="_blank"
                        class="flex items-center justify-center space-x-4 p-4 rounded-md border shadow-md w-full hover:cursor-pointer hover:border-blue transition"
                    >
                        <app-icon
                            name="view"
                            stroke
                            class="h-6 w-6 text-blue-dark"
                        ></app-icon>
                        <p>View Live Event</p>
                    </a>
                </div>

                <set-registration-deadline-action
                    v-if="!hasDeadline"
                    v-slot="{ setRegistrationDeadline }"
                    class="w-full md:w-1/2"
                >
                    <button class="flex items-center justify-center space-x-4 p-4 rounded-md border shadow-md w-full hover:cursor-pointer hover:border-blue transition" @click="setRegistrationDeadline">
                        <app-icon
                            name="stop-sign"
                            stroke
                            class="h-6 w-6 text-blue-dark"
                        ></app-icon>
                        <div class="flex items-center justify-center">
                            <p class="mr-2">{{ registrationRsvpLabels.setRegistrationDeadline }}</p>
                            <span v-tippy content="Schedule your registration to automatically close and prevent new registrations.">
                                <app-icon class="w-4 h-4" name="info-circle-stroke"></app-icon>
                            </span>
                        </div>
                    </button>
                </set-registration-deadline-action>
            </div>
        </div>

        <div v-if="showFreeUserBanner" class="flex flex-col items-center justify-center mt-6">
            <p class="text-teal font-semibold">{{ freePlanRSVPLimitLabel }}</p>
            <a :href="route('account.upgrade.index')" class="button mt-2 w-1/3 bg-white border-teal text-teal hover:bg-teal-light hover:text-teal-dark">Upgrade</a>
        </div>
    </app-modal>
</template>

<script>
import { get } from 'vuex-pathify';
import RegistrationRSVPLabels from '@/mixins/RegistrationRSVPLabels';
import FormOpensAtClosesAtFormattedDateTime from '@/mixins/FormOpensAtClosesAtFormattedDateTime';

export default {
    name: 'PublishedEventInfoModal',

    mixins: [FormOpensAtClosesAtFormattedDateTime, RegistrationRSVPLabels],

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        event: get('Event/event'),
        form: get('Form/form'),
        wall: get('Wall/wall'),

        hasDeadline () {
            return this.form.closed_at && !this.form.isClosed;
        },

        freePlanRSVPLimitLabel () {
            const freePlanRSVPLimit = this.auth().user().plan?.features?.RsvpLimit.max ?? 100;

            return `Heads up! Your free event is limited to ${freePlanRSVPLimit} ${this.registrationRsvpLabels.registrationLabel}s and basic features.`;
        },

        showFreeUserBanner () {
            return !this.event.isComped && this.event.owner.plan.isFree;
        },

        showRegistrationOpensAtLabel () {
            return this.form.opened_at && !this.form.isOpened && !this.form.isClosed;
        },

        title () {
            return !this.form.isOpened ? 'Event Website Published' : `${this.registrationRsvpLabels.registrationLabel} Open`;
        }
    }
};
</script>
