/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-instagram': {
    width: 16,
    height: 16,
    viewBox: '0 0 52 52',
    data: '<path pid="0" d="M15.006.478C6.756.478.046 7.19.046 15.442V37.08c0 8.25 6.71 14.96 14.96 14.96h21.767c8.25 0 14.96-6.71 14.96-14.96V15.442c0-8.25-6.71-14.964-14.96-14.964H15.006zm0 4.433h21.767c5.872 0 10.531 4.659 10.531 10.531V37.08c0 5.872-4.66 10.528-10.531 10.528H15.006c-5.872 0-10.528-4.656-10.528-10.528V15.442c0-5.872 4.656-10.531 10.528-10.531zm24.755 4.432a3.099 3.099 0 10-.001 6.198 3.099 3.099 0 00.001-6.198zm-13.872 3.64c-7.306 0-13.275 5.97-13.275 13.276s5.97 13.275 13.275 13.275c7.306 0 13.28-5.97 13.28-13.275 0-7.306-5.974-13.276-13.28-13.276zm0 4.433a8.812 8.812 0 018.847 8.843 8.812 8.812 0 01-8.847 8.843 8.808 8.808 0 01-8.843-8.843 8.808 8.808 0 018.843-8.843z" _fill="currentColor"/>'
  }
})
