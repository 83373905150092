/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vip-crown-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 14.25V9.81h-.01c0-.42.33-.75.75-.75.17 0 .33.05.46.16l2.53 2.02 2.47-2.47h-.01c.29-.3.76-.3 1.05-.01 0 0 0 0 0 0l2.47 2.47 2.53-2.03h-.01c.32-.26.79-.21 1.05.11.1.13.16.29.16.46v4.44c0 .82-.68 1.5-1.5 1.5H6.69l-.01-.001a1.51 1.51 0 01-1.5-1.5z"/><path pid="1" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/></g>'
  }
})
