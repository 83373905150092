/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chess-rook-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19 3V.75M12.25 17.25h4.5c-1.33-2.66-1.42-6.85-.95-9.75M12.25 17.25h-4.5c1.33-2.66 1.41-6.843.95-9.753"/><path pid="1" d="M5.5 3H19v2.5c0 1.1-.9 2-2 2H7.5l-.01-.001c-1.11-.01-2-.9-2-2.01V2.988zM5.5 3V.75M12.25 3V.75M20.423 21.533c-.29-.77-1.41-3.35-3.68-4.29h-9c-2.27.93-3.39 3.51-3.673 4.28h-.01c-.06.13-.08.27-.08.41v-.01c-.01.71.58 1.3 1.29 1.3h13.9l-.01-.001c.71 0 1.3-.59 1.3-1.3 0 0 0 0 0 0v-.01c0-.15-.03-.29-.08-.42z"/></g>'
  }
})
