/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-basketball-ball-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5zM3.774 19.675C5.144 15.515 9.339 6.484 20.3 4.4M3.6 4.52c4.484 1.633 12.87 6.08 14.63 16.86"/><path pid="1" d="M10.524.84h0c.21 4.58-3.33 8.48-7.92 8.69-.53.02-1.06-.01-1.58-.08M11.79 23.24c-4.67-7.49 4.07-16.08 11.45-11.46"/></g>'
  }
})
