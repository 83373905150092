<template>
    <iframe
        v-if="query"
        class="map w-full"
        frameborder="0"
        :src="googleEmbedUrl"
    >
    </iframe>
</template>

<script>
export default {
    props: {
        address: {
            type: [String, Object],
            default: ''
        }
    },

    data () {
        return {
            googlePlacesApiKey: window.googlePlacesApiKey
        };
    },

    computed: {
        query () {
            if (typeof this.address === 'string') {
                return this.address;
            }

            if (!this.address.venue) {
                return this.address.address;
            }

            return `${this.address.venue} - ${this.address.address}`;
        },

        googleEmbedUrl () {
            return `https://www.google.com/maps/embed/v1/place?key=${this.googlePlacesApiKey}&q=${this.query}`;
        }
    }
};
</script>
