/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-reinfected-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><defs><path pid="0" d="M12.75 16.56h7.15-.01c1.84-.01 3.34 1.49 3.34 3.34 0 1.84-1.5 3.34-3.35 3.34h-6.21" id="svgicon_covid-virus-reinfected-stroke_a"/><path pid="1" d="M15.664 13.654l-2.92 2.91 2.91 2.91" id="svgicon_covid-virus-reinfected-stroke_b"/></defs><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="2" d="M12.017.75h-3.04M10.5.75v2.6M4.678 2.533L3.606 3.606 2.533 4.678M3.61 3.61l1.83 1.83M.75 8.983v3.02M.75 10.5h2.6M2.533 16.322l1.073 1.07 1.072 1.07M3.61 17.39l1.83-1.83M18.467 4.678l-1.08-1.072-1.08-1.073M17.39 3.61l-1.83 1.83M10.14 13.68a.43.43 0 100 .86.43.43 0 100-.87zM8.98 6.81a1.51 1.51 0 100 3.03 1.51 1.51 0 100-3.04z"/><path pid="3" d="M17.61 9.75h0c-.42-3.93-3.93-6.78-7.86-6.37a7.144 7.144 0 00-6.37 7.85 7.143 7.143 0 006.24 6.35"/><use xlink:href="#svgicon_covid-virus-reinfected-stroke_a"/><use xlink:href="#svgicon_covid-virus-reinfected-stroke_b"/><use xlink:href="#svgicon_covid-virus-reinfected-stroke_a"/><use xlink:href="#svgicon_covid-virus-reinfected-stroke_b"/></g>'
  }
})
