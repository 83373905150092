<template>
    <a
        :href="route('account.shared-event-users')"
        class="no-underline flex flex-col md:flex-row items-center p-6 md:py-4 bg-white shadow ring-2 ring-transparent hover:ring-purple-light hover:shadow-lg cursor-pointer rounded-md text-gray-800 transition ease-in-out duration-150"
        @click="checkFeatureAvailability"
    >
        <app-icon
            name="users-location"
            class="h-12 w-12 md:h-8 md:w-8 text-gray-600"
            stroke
        ></app-icon>

        <div class="mt-2 md:mt-0 md:ml-6 flex-auto leading-normal text-center md:text-left">
            <div class="flex items-center">
                <p class="text-lg font-semibold">Event Sharing</p>

                <img
                    v-if="!canHaveSharedUsers"
                    v-tippy
                    alt="RSVPify Premium"
                    class="w-5 h-5 ml-2"
                    content="Upgrade to access this feature."
                    :src="asset('images/account/rsvpify-premium.svg')"
                >
            </div>

            <p class="mt-4 md:m-0 md:text-sm text-gray-600">Invite other users to view or help manage your event(s).</p>
        </div>

        <app-icon
            name="arrow-right-chevron"
            class="h-6 w-6 text-gray-500 hidden md:flex"
            stroke
        ></app-icon>
    </a>
</template>

<script>
export default {
    computed: {
        canHaveSharedUsers () {
            const { unlimited, max } = this.auth().user().plan.features.SharedUserLimit;

            if (unlimited) {
                return true;
            }

            return max > 0;
        }
    },

    methods: {
        checkFeatureAvailability (event) {
            if (this.canHaveSharedUsers) {
                return;
            }

            event.preventDefault();

            this.showPaywall();
        },

        showPaywall () {
            App.alert().paymentRequired(
                'Please upgrade.',
                `Your ${this.auth().user().plan.name} plan includes <strong>0</strong> co-managers. Upgrade to add co-managers to your event(s).`
            );
        }
    }
};
</script>
