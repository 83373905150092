/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-free-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M3.218 4.937v-.01c3.9-4.84 10.98-5.6 15.82-1.7.64.52 1.23 1.11 1.74 1.76M3 18.75h0c3.71 4.96 10.74 5.97 15.71 2.26.85-.64 1.6-1.4 2.24-2.24M1.5 15.75v-6h0c-.01-.83.67-1.51 1.49-1.51h1.5M1.5 12.75h2.25M7.5 15.75v-7.5M7.5 8.25h1.125-.01a1.86 1.86 0 011.87 1.87c0 1.03-.84 1.87-1.88 1.87H7.47M10.5 15.75L8.25 12M16.5 15.75H15l-.01-.001a1.51 1.51 0 01-1.5-1.5V9.748h0c-.01-.83.67-1.51 1.49-1.51h1.5M13.5 12.75h3M22.5 15.75H21l-.01-.001a1.51 1.51 0 01-1.5-1.5V9.748h0c-.01-.83.67-1.51 1.49-1.51h1.5M19.5 12.75h3"/></g>'
  }
})
