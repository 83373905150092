<template>
    <div
        @keyup.enter="openPickerOrSelectIcon"
        @keyup.left="focusPreviousIcon"
        @keyup.right="focusNextIcon"
        @keyup.up="focusPreviousIcon"
        @keyup.down="focusNextIcon"
        @keyup.esc="close"
    >
        <a
            class="inline-block w-full h-full p-2 rounded-md border bg-gray-100 cursor-pointer hover:bg-gray-200 leading-none"
            :title="value ? value : 'No icon selected'"
            @click="open"
        >
            <app-icon
                v-if="value"
                :name="value"
                class="w-full h-full"
            ></app-icon>

            <div
                v-else
                class="text-xs text-center"
                title="No Icon"
            >
                No Icon
            </div>
        </a>

        <div
            v-if="opened"
            v-click-outside="close"
            class="absolute top-0 inset-x-0 mt-12 p-2 bg-white rounded-md border shadow z-10"
        >
            <div class="px-2">
                <search-field
                    id="section-name"
                    ref="searchInput"
                    v-model="searchTerm"
                    class="mb-2"
                    placeholder="Find an icon..."
                    small
                ></search-field>
            </div>

            <div class="flex flex-wrap h-32 overflow-y-auto">
                <div
                    class="flex items-center w-8 h-8 p-1 m-1 text-center text-xs cursor-pointer hover:text-purple rounded-md border border-transparent"
                    :class="{ 'border-gray-300': focusedIcon === 0 }"
                    title="No Icon"
                    @click.prevent="selectIcon(null)"
                >
                    No Icon
                </div>
                <div
                    v-for="(meta, iconName) in icons"
                    :key="iconName"
                    class="flex items-center justify-center w-8 h-8 p-1 m-1 cursor-pointer hover:text-purple rounded-md border border-transparent"
                    :class="{ 'border-gray-300': isIconFocused(iconName) }"
                    :title="meta"
                    @click.prevent="selectIcon(iconName)"
                >
                    <app-icon
                        :name="iconName"
                        class="h-6 w-6"
                    ></app-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSvgIcon from 'vue-svgicon';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import indexOf from 'lodash/indexOf';
import mapValues from 'lodash/mapValues';

export default {
    props: {
        value: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            allIcons: {},
            focusedIcon: 0,
            opened: false,
            restrictedIcons: new Set([
                'credit-card-amex',
                'credit-card-default',
                'credit-card-discover',
                'credit-card-mastercard',
                'credit-card-visa'
            ]),
            searchTerm: ''
        };
    },

    computed: {
        icons () {
            return pickBy(this.allIcons, (meta) => {
                return meta.indexOf(this.searchTerm.toLowerCase()) !== -1;
            });
        },

        iconsList () {
            return keys(this.icons);
        }
    },

    watch: {
        iconsList () {
            this.updateFocusedIconIndex();
        },

        value () {
            this.updateFocusedIconIndex();
        }
    },

    mounted () {
        this.allIcons = this.getAllIconsWithMetadata();
    },

    methods: {
        close () {
            this.opened = false;
        },

        getAllIconsWithMetadata () {
            const availableIcons = pickBy(VueSvgIcon.icons, (value, iconName) => {
                return !this.restrictedIcons.has(iconName);
            });

            return mapValues(availableIcons, (value, iconName) => {
                return `${iconName} ${this.getMetadataFromIconSvg(value.data)}`;
            });
        },

        getMetadataFromIconSvg (svgSource) {
            const match = svgSource.match(/<desc>(.*?)<\/desc>/);

            return match ? match[1] : '';
        },

        open () {
            this.opened = true;
            this.$nextTick(() => {
                this.$refs.searchInput.focus();
            });
        },

        selectIcon (icon) {
            this.$emit('input', icon);
            this.close();
        },

        /* Keyboard selection utilities */
        isIconFocused (iconName) {
            return indexOf(this.iconsList, iconName) + 1 === this.focusedIcon;
        },

        updateFocusedIconIndex () {
            this.focusedIcon = indexOf(this.iconsList, this.value) + 1;
        },

        openPickerOrSelectIcon () {
            if (!this.opened) {
                this.open();
                return;
            }

            if (this.focusedIcon) {
                this.selectIcon(this.iconsList[this.focusedIcon - 1]);
            } else {
                this.selectIcon(null);
            }
        },

        focusPreviousIcon () {
            this.focusedIcon = this.getCyclicIconIndex(this.focusedIcon - 1);
        },

        focusNextIcon () {
            this.focusedIcon = this.getCyclicIconIndex(this.focusedIcon + 1);
        },

        getCyclicIconIndex (newIconIndex) {
            const cycleLength = this.iconsList.length + 1;

            return ((newIconIndex % cycleLength) + cycleLength) % cycleLength;
        }
    }
};
</script>
