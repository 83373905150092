/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wedding-car-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>heart balloon</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 20.25a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM14.25 20.25a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM6.78 21.75h5.97"/><path pid="1" d="M3.75 21.75H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-.75h0a2.996 2.996 0 012.99-3.01h12.4-.01c1.49-.01 2.75 1.09 2.97 2.57l.13.96-.01-.01c.11.82-.46 1.57-1.28 1.69-.08.01-.15.01-.22.01h-2.02M11.25 16.5v-6"/><path pid="2" d="M5.25 16.5h0a5.992 5.992 0 015.99-6.01c3.31-.01 6 2.68 6 5.99 0 0 0 0 0 0v.21M17.77 21.75h2.48l-.01-.001c1.65 0 3-1.35 3-3v-2.75c0-1.55-.88-2.96-2.26-3.65l-.01-.01a4.056 4.056 0 01-2.25-3.65v-1.24"/><path pid="3" d="M22.68 1.33l-.01-.01c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06l-1.18 1.21-1.18-1.22-.01-.01c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06h-.01c-.77.79-.77 2.04 0 2.84l3.51 3.13h0c.22.23.58.24.81.01 0-.01.01-.02.01-.02l3.51-3.14v-.01c.76-.8.76-2.05-.01-2.85z"/></g>'
  }
})
