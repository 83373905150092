/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vip-celebrity-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25 17.25V15h0a3.739 3.739 0 013.74-3.76 3.736 3.736 0 013.75 3.74s0 0 0 0v2.25h-1.5l-.75 6h-3l-.75-6zM12 3.75a3 3 0 100 6 3 3 0 100-6zM.75 23.25l4.5-12.75M23.25 23.25l-4.5-12.75M22.19 1.81L23.25.75M22.19 4.99l1.06 1.06M19.01 4.99l-1.06 1.06M19.01 1.81L17.95.75M4.99 3.31l1.06-1.06M4.99 6.49l1.06 1.06M1.81 6.49L.75 7.55M1.81 3.31L.75 2.25"/></g>'
  }
})
