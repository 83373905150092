<template>
    <section class="panel flex flex-col">
        <page-header icon="chat-translate-stroke" label="Languages & Translations">
            <a class="button-icon buttom-sm" :href="route('settings.event', event)">
                <app-icon name="close"></app-icon>
            </a>
        </page-header>

        <form-field-wrapper
            label="Primary Language"
            :should-show-error="errors.has('primary-language-name')"
            error="Primary language is required"
            class="mb-8"
        >
            <div class="flex">
                <simple-picker
                    v-validate="'required'"
                    :value="primaryLanguage.id"
                    :items="allLanguages"
                    class="mr-2 w-full md:w-1/3"
                    item-label="name"
                    item-value="id"
                    @input="updatePrimaryLanguage"
                ></simple-picker>

                <button class="button button-primary h-10" @click="customizeLanguage(primaryLanguage)">
                    Customize
                </button>
            </div>
        </form-field-wrapper>

        <form-field-wrapper class="mb-2 w-full md:w-1/3">
            <template #label>
                <div class="flex items-center space-x-4">
                    <span>Additional Language(s)</span>
                    <premium-feature
                        feature="LanguageLimit"
                        :feature-usage="languageLimitUsage"
                    ></premium-feature>
                </div>
            </template>

            <draggable-list
                v-model="additionalLanguages"
                confirm-delete
                confirm-delete-message="Removing this language is permanent and cannot be undone. This language will no longer be available to guests."
                editable
                deletable
                unique-property="id"
                label-property="name"
                prevent-empty-set
                @end="onMove"
                @edit-item="customizeLanguage"
                @delete-item="deleteLanguage"
            >
                <template #editButton><div></div></template>

                <template #postItemButtons="{ model }">
                    <button class="button button-primary h-10 ml-1" @click="customizeLanguage(model)">
                        Customize
                    </button>
                </template>

            </draggable-list>

        </form-field-wrapper>

        <div class="mb-8">
            <button
                v-if="!showAddLanguageOptions"
                class="button-text button-info"
                @click="showAddLanguageOptions = true"
            >
                <app-icon
                    name="add-circle"
                    class="h-6 w-6 mr-2"
                    stroke
                ></app-icon>

                <span>Add another language</span>
            </button>

            <transition name="slide-vertical">
                <div v-if="showAddLanguageOptions">
                    <form-field-wrapper label="Language Name">
                        <div class="sm:flex">
                            <simple-picker
                                v-validate="'required'"
                                :value="newLanguage.id"
                                :items="unusedLanguages"
                                class="mr-2 sm:w-1/3"
                                item-label="name"
                                item-value="id"
                                @input="updateNewLanguage"
                            ></simple-picker>

                            <stateful-button
                                class="button-primary mr-4"
                                :loading="adding"
                                :disabled="shouldDisableAddLanguageButton"
                                @click="addNewLanguage"
                            >
                                Add
                            </stateful-button>

                            <button
                                class="button"
                                @click="setEmptyNewLanguage"
                            >
                                Cancel
                            </button>
                        </div>
                    </form-field-wrapper>
                </div>
            </transition>
        </div>

        <customize-language
            v-model="showCustomizeLanguageModal"
            :english-language="englishLanguage"
            :language="languageBeingCustomized"
            @update-language="updateLanguageTranslations"
        ></customize-language>
    </section>
</template>

<script>
import reduce from 'lodash/reduce';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import { sync } from 'vuex-pathify';
import axios from '@/util/axios';

export default {
    name: 'ManageLanguages',

    props: {
        allLanguages: {
            type: Array,
            required: true
        },

        initialLanguageLimitUsage: {
            type: Object,
            default: null
        }
    },

    data () {
        return {
            adding: false,
            additionalLanguages: [],
            enabledLanguages: [],
            languageBeingCustomized: {},
            languageLimitUsage: this.initialLanguageLimitUsage,
            newLanguage: {},
            primaryLanguage: {},
            showAddLanguageOptions: false,
            showCustomizeLanguageModal: false
        };
    },

    created () {
        this.primaryLanguage = cloneDeep(this.event.enabled_languages[0]);
        this.enabledLanguages = cloneDeep(this.event.enabled_languages);
    },

    computed: {
        ...sync('Event/*'),

        englishLanguage () {
            return find(this.allLanguages, { name: 'English' });
        },

        shouldDisableAddLanguageButton () {
            return !this.newLanguage.id;
        },

        unusedLanguages () {
            return this.allLanguages.filter((language) => {
                return !find(this.enabledLanguages, { id: language.id });
            });
        }
    },

    watch: {
        enabledLanguages () {
            this.updateAllAdditionalLanguages();
        }
    },

    methods: {
        addNewLanguage () {
            this.adding = true;

            axios
                .post(this.route('api.events.event-languages.store', this.event),
                    { languageId: this.newLanguage.id })
                .then(({ data: { language, usage } }) => {
                    this.setEmptyNewLanguage();
                    this.enabledLanguages.push(language);
                    this.languageLimitUsage = usage;
                    this.$toasted.global.success('Language was added.');
                })
                .catch(() => {
                    this.$toasted.global.error('There was a problem adding your language.');
                })
                .finally(() => {
                    this.adding = false;
                });
        },

        customizeLanguage (language) {
            this.languageBeingCustomized = language;
            this.showCustomizeLanguageModal = true;
        },

        deleteLanguage (index) {
            const eventLanguage = this.additionalLanguages[index].pivot;
            const enabledLanguageIndex = findIndex(this.enabledLanguages, {
                pivot: { id: eventLanguage }
            });

            axios
                .delete(this.route('api.events.event-languages.destroy', [this.event, eventLanguage]))
                .then(({ data: { usage } }) => {
                    this.enabledLanguages.splice(enabledLanguageIndex, 1);
                    this.$toasted.global.success('Language was removed.');
                    this.languageLimitUsage = usage;
                })
                .catch(() => {
                    this.$toasted.global.error('There was a problem deleting your language.');
                });
        },

        onMove () {
            this.adding = true;

            map(this.additionalLanguages, (language, index) => {
                language.pivot.sort = index;
                return language;
            });

            const payload = reduce(this.enabledLanguages, (carry, language) => {
                carry[language.pivot.id] = language.pivot.sort;
                return carry;
            }, {});

            axios.patch(this.route('api.event-languages.reorder', { event: this.event }), {
                sort: payload
            })
                .then(() => {
                    this.$toasted.global.success('Languages reordered.');
                })
                .catch(() => {
                    this.$toasted.global.error(`There was an error reordering the languages.`);
                })
                .finally(() => {
                    this.adding = false;
                });
        },

        setEmptyNewLanguage () {
            this.newLanguage = { name: '' };
            this.showAddLanguageOptions = false;
        },

        updateAllAdditionalLanguages () {
            this.additionalLanguages = this.enabledLanguages.filter((language) => {
                return language.id !== this.primaryLanguage.id;
            });
        },

        updateLanguageTranslations (newLanguage) {
            if (newLanguage.id === this.primaryLanguage.id) {
                Object.assign(this.primaryLanguage, newLanguage);
            }

            const index = this.enabledLanguages.findIndex((language) => {
                return language.id === newLanguage.id;
            });

            this.enabledLanguages.splice(index, 1, newLanguage);
        },

        updateNewLanguage (languageId) {
            this.newLanguage = cloneDeep(
                find(this.unusedLanguages, { id: languageId })
            );
        },

        updatePrimaryLanguage (languageId) {
            if (languageId === this.primaryLanguage.id) {
                return;
            }

            axios
                .put(this.route('api.update-primary-language', this.event),
                    { languageId })
                .then(({ data }) => {
                    const oldPrimaryLanguageIndex = find(this.enabledLanguages, { id: this.primaryLanguage.id });
                    this.enabledLanguages.splice(oldPrimaryLanguageIndex, 1);

                    this.primaryLanguage = data;
                    this.updateAllAdditionalLanguages();
                    this.$toasted.global.success('Primary language updated.');
                })
                .catch(() => {
                    this.$toasted.global.error('There was a problem updating your primary language.');
                })
                .finally(() => {
                    this.adding = false;
                });
        }
    }
};
</script>
