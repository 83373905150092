/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-badge-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9.75 3.75H5.24c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v16.5h0c0 .82.67 1.49 1.5 1.49h13.5-.01c.82 0 1.5-.68 1.5-1.5V5.24v0c0-.83-.68-1.5-1.5-1.5h-4.5"/><path pid="1" d="M14.25 3v0c0-1.25-1.01-2.25-2.25-2.25-1.25 0-2.25 1-2.25 2.25v3h0c0 .41.33.74.75.74h3v-.001c.41-.01.74-.34.74-.75zM12 9.75a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75zM18 23.25h0a6.066 6.066 0 00-6.81-5.2 6.047 6.047 0 00-5.2 5.19"/><path pid="2" d="M15.36 12.864h-.01c-2.21.63-4.58.1-6.3-1.4"/></g>'
  }
})
