/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-sanitizer-liquid-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.03 10.75v0c0 2.76-2.24 5-5 5-2.77 0-5-2.24-5-5 0-3.75 5-10 5-10s5 6.25 5 10zM10.03 9.75h4M12.03 7.75v4M3.719 23.25l-1.85-2.31h0c-.59-.74-.91-1.65-.91-2.58v-4.75L.95 13.6c-.01-.76.61-1.38 1.37-1.38v0h0c.75 0 1.37.61 1.37 1.37v3.55"/><path pid="1" d="M7.84 23.125v-2.75h0a4.26 4.26 0 00-.7-2.3l-1.06-1.57h0a1.3 1.3 0 00-1.77-.45l-.06.03v0h-.01c-.54.35-.73 1.06-.44 1.64l1.24 2.06M20.281 23.25l1.846-2.308h-.01c.58-.74.9-1.65.9-2.58v-4.75 0c0-.76-.62-1.375-1.375-1.375v0h-.01c-.76 0-1.38.61-1.38 1.37v3.55"/><path pid="2" d="M16.15 23.125v-2.75h-.01c0-.82.24-1.62.69-2.3l1.05-1.57v-.01a1.286 1.286 0 011.81-.42v0h0c.53.35.72 1.06.43 1.64l-1.24 2.06"/></g>'
  }
})
