<template>
    <single-line-text-input
        ref="input"
        v-model="internalValue"
        :placeholder="$t('placeholder-type')"
    ></single-line-text-input>
</template>

<script>
import CustomQuestion from '@/mixins/CustomQuestion';

export default {
    name: 'FormTextQuestion',

    mixins: [CustomQuestion],

    methods: {
        focus () {
            this.$refs.input.focus();
        }
    }
};
</script>
