/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-beer-mug': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>brew pub bar alcohol stein</desc><g _fill="currentColor"><path pid="0" d="M23 12.77a3 3 0 00-3-3h-.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H20a1 1 0 011 1v3.5a.949.949 0 01-.855 1h-.895a.25.25 0 00-.25.25v.938a.25.25 0 00.015.085c.063.173.13.362.2.559a.25.25 0 00.237.168h.7a2.934 2.934 0 002.855-3z"/><path pid="1" d="M20 6.6a3.687 3.687 0 00-2.967-3.611.251.251 0 01-.161-.108A6.036 6.036 0 0011.84.23a5.867 5.867 0 00-4.706 2.338.25.25 0 01-.271.089A4.481 4.481 0 005.58 2.47a4.58 4.58 0 00-1.262 8.983.248.248 0 01.182.24v6.987a.5.5 0 01-.03.172 17.743 17.743 0 00-.97 3.418 1.5 1.5 0 001.5 1.5h12a1.5 1.5 0 001.5-1.5 17.743 17.743 0 00-.97-3.418.5.5 0 01-.03-.172v-8.424a.25.25 0 01.16-.233A3.668 3.668 0 0020 6.6zM5.58 4.47a2.643 2.643 0 011.33.37 1.021 1.021 0 00.8.11 1.032 1.032 0 00.62-.52 3.878 3.878 0 013.51-2.2 3.962 3.962 0 013.58 2.13.992.992 0 00.9.55A1.692 1.692 0 0118 6.6a1.7 1.7 0 01-1.68 1.68h-4.48a1.9 1.9 0 00-1.89 1.9v.45a1.685 1.685 0 01-3.37 0 1 1 0 00-1-1 2.58 2.58 0 010-5.16zM9.5 19.52a.75.75 0 01-1.5 0v-4.25a.75.75 0 011.5 0zm4.5 0a.75.75 0 01-1.5 0v-6.75a.75.75 0 011.5 0z"/></g>'
  }
})
