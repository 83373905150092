/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-dollar-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><circle pid="0" cx="15.521" cy="3.708" r=".998"/><circle pid="1" cx="15.521" cy="6.451" r=".998"/><path pid="2" d="M14.535 9.336a.246.246 0 00.119.174.25.25 0 00.21.021 7.922 7.922 0 011.387-.354.25.25 0 00.2-.333.993.993 0 00-1.923.35.825.825 0 00.007.142z"/><path pid="3" d="M22.005.465H2a2 2 0 00-2 1.996v2.774A1.5 1.5 0 001.31 6.72a2.494 2.494 0 010 4.949A1.5 1.5 0 000 13.153v2.775a2 2 0 002 1.995h7.311a.25.25 0 00.183-.08.253.253 0 00.066-.189c-.016-.2-.025-.4-.025-.6a7.135 7.135 0 01.053-.844.253.253 0 00-.062-.2.249.249 0 00-.187-.083H2.245A.25.25 0 012 15.679v-1.913a.248.248 0 01.184-.24 4.49 4.49 0 000-8.663A.249.249 0 012 4.622V2.71a.249.249 0 01.25-.249h19.51a.249.249 0 01.25.249v1.912a.249.249 0 01-.184.241 4.494 4.494 0 00-3.294 4.045.248.248 0 00.211.262 7.944 7.944 0 011.44.36.251.251 0 00.231-.035.246.246 0 00.1-.21v-.091A2.5 2.5 0 0122.69 6.72 1.5 1.5 0 0024 5.235V2.461A2 2 0 0022.005.465z"/><path pid="4" d="M17.516 10.566A6.485 6.485 0 1024 17.05a6.492 6.492 0 00-6.484-6.484zm.947 10.173a.248.248 0 00-.2.244v.307a.748.748 0 11-1.5 0v-.249a.25.25 0 00-.25-.25h-.5a.748.748 0 010-1.5h2.029a.59.59 0 00.22-1.138l-2.06-.824a2.079 2.079 0 01.36-3.971.25.25 0 00.2-.244v-.307a.748.748 0 111.5 0v.249a.248.248 0 00.249.249h.5a.749.749 0 010 1.5h-2.027a.59.59 0 00-.22 1.138l2.059.824a2.079 2.079 0 01-.36 3.971z"/></g>'
  }
})
