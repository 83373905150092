/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-coffee-mug-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.25 22.5H3.75l-.01-.001c-1.66-.01-3-1.35-3-3V2.99h0c-.01-.83.67-1.51 1.49-1.51h13.5-.01c.82-.01 1.5.67 1.5 1.49 0 0 0 0 0 0v16.5c0 1.65-1.35 3-3 3zM17.25 4.5h.375-.01c3.1-.01 5.62 2.51 5.62 5.62 0 3.1-2.52 5.62-5.63 5.62h-.38"/></g>'
  }
})
