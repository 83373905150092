/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list-check-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>todo done</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 10.51h5.25M5.25 14.26h3M5.25 18.01h3M9.75 23.26H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V6.01h0c-.01-.83.67-1.51 1.49-1.51h3.75A3.739 3.739 0 019.72.74a3.736 3.736 0 013.75 3.74s0 0 0 0h3.75-.01c.82-.01 1.5.67 1.5 1.5v2.25"/><path pid="1" d="M9.75 3.761h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M17.25 11.26a6 6 0 100 12 6 6 0 100-12z"/><path pid="2" d="M19.924 15.516l-2.91 3.87h-.01a.76.76 0 01-1.06.14c-.03-.03-.06-.05-.08-.07l-1.5-1.5"/></g>'
  }
})
