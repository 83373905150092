/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'seafood-salmon-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food fish</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.99 5.27a5.25 6.75 0 100 13.5 5.25 6.75 0 100-13.5z"/><path pid="1" d="M5.99 8.27a2.25 3.75 0 100 7.5 2.25 3.75 0 100-7.5z"/><path pid="2" d="M5.994 18.773c5.25 0 11.3-1.7 13.5-6-2.2-4.308-8.38-8-13.5-7.5M22.33 11.407v-.01c-.76.77-1.76 1.25-2.84 1.36h0c-.05-1.1.27-2.18.92-3.06v-.01c.75-.78 1.75-1.26 2.83-1.37l-.01-.01c.04 1.09-.28 2.17-.93 3.05z"/><path pid="3" d="M22.33 14.13h0a4.603 4.603 0 00-2.84-1.37h-.01c-.05 1.09.27 2.17.92 3.05h0c.75.77 1.75 1.25 2.83 1.36v-.01a4.89 4.89 0 00-.93-3.07z"/></g>'
  }
})
