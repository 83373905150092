<template>
    <div class="call-to-action call-to-action-warning">
        <div class="flex-1">
            <p>You've added at least 1 unusually large group to your Invite List</p>

            <p class="text-gray-400">
                Groups allow you to specify invitees who will reply together (e.g. couples, families, etc.). RSVPify will require all members of an invited group to reply as part of one submission.
            </p>
        </div>

        <button class="button button-soft mr-4" @click="dismiss">Dismiss</button>
    </div>
</template>

<script>
import axios from '@/util/axios';
import { get } from 'vuex-pathify';

export default {
    name: 'LargeInviteeGroupsNotification',

    props: {
        notification: {
            type: Object,
            required: true
        }
    },

    methods: {
        dismiss () {
            axios.post(this.route('api.notifications.dismiss', this.notification))
                .then(() => { return window.location.reload(); });
        }
    },

    computed: {
        ...get('Event/*')
    }
};
</script>
