/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wedding-cake-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M1.5 23h21"/><rect pid="1" width="18" height="9" x="3" y="11" rx="1.5"/><path pid="2" d="M3 12.87h-.01a2.608 2.608 0 002.61 2.62c.93 0 1.79-.49 2.26-1.3h0c.4.78 1.21 1.28 2.1 1.29h-.01a2.26 2.26 0 002.02-1.3h0c.37.78 1.15 1.28 2.02 1.29h-.01c.88-.01 1.69-.51 2.1-1.3h0a2.62 2.62 0 003.58.93c.8-.48 1.29-1.34 1.29-2.27M17.272 2h0a2.433 2.433 0 00-3.44-.09c-.03.02-.06.05-.09.08l-1.51 1.56-1.51-1.56h0a2.433 2.433 0 00-3.44-.09c-.03.02-.06.05-.09.08h-.01a2.608 2.608 0 000 3.63l4.48 4.63h0c.28.29.75.3 1.04.02 0-.01.01-.02.02-.03l4.48-4.639h-.01a2.63 2.63 0 00-.01-3.64z"/></g>'
  }
})
