/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-pasta-bowl-warm-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>italian spaghetti sauce</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.03.75c1.43 2.87-2.9 3.88-.75 6.75M12.53.75c1.43 2.87-2.9 3.88-.75 6.75M8.03.75c1.43 2.87-2.9 3.88-.75 6.75M12 9.75a11.25 3 0 100 6 11.25 3 0 100-6z"/><path pid="1" d="M23.25 12.75c0 5.8-5.04 10.5-11.25 10.5-6.22 0-11.25-4.71-11.25-10.5"/></g>'
  }
})
