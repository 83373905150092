/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'users-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>multiple people group party</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.5 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM4.5 9.75h-.01C2.41 9.75.74 11.42.74 13.5s0 0 0 0v2.25h1.5l.75 6h3M19.5 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM19.5 9.75h-.01a3.736 3.736 0 013.75 3.74v2.25h-1.5l-.75 6h-3M12 .75a3 3 0 100 6 3 3 0 100-6zM17.25 13.5v0a5.25 5.25 0 10-10.5 0v2.25H9l.75 7.5h4.5l.75-7.5h2.25z"/></g>'
  }
})
