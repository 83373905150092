<template>
    <div class="flex flex-col w-full" :class="containerClass">
        <slot></slot>
    </div>
</template>

<script>
import isArray from 'lodash/isArray';
import castArray from 'lodash/castArray';

export default {
    provide () {
        return {
            accordionListState: this.sharedState
        };
    },

    props: {
        containerClass: {
            type: String,
            default: 'overflow-hidden'
        },

        shouldCloseFunction: {
            type: Function,
            default: () => { return true; }
        },

        value: {
            type: [String, Number, Array],
            default: null
        }
    },

    data () {
        return {
            sharedState: {
                isMultipleItemAccordion: isArray(this.value),
                activeItems: this.getActiveItems(),
                shouldCloseFunction: this.shouldCloseFunction
            }
        };
    },

    watch: {
        value () {
            this.$set(this.sharedState, 'activeItems', this.getActiveItems());
        },

        'sharedState.activeItems': function (newActiveItems) {
            this.$emit('input', this.sharedState.isMultipleItemAccordion ? newActiveItems : newActiveItems[0]);
        }
    },

    methods: {
        getActiveItems () {
            return castArray(this.value);
        }
    }
};
</script>
