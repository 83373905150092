/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-concert-dj-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25 20.25H.75v1.5h0c0 .82.67 1.49 1.5 1.49h19.5-.01c.82 0 1.5-.68 1.5-1.5zM18.669 14.25H5.32l-.01-.001c-.52-.01-1 .26-1.28.7l-3.31 5.29h22.5l-3.31-5.295h0c-.28-.44-.76-.71-1.28-.71zM11.25 17.25h1.5M7.5 16.5L6.75 18M16.5 16.5l.75 1.5M12 4.5a3.75 3.75 0 100 7.5 3.75 3.75 0 100-7.5z"/><path pid="1" d="M18 6.75v0c0-3.32-2.69-6-6-6-3.32 0-6 2.68-6 6"/><path pid="2" d="M18 5.25h-.01c1.65-.01 3 1.34 3 3 0 1.65-1.35 3-3 3zM6 5.25h-.01c-1.66 0-3 1.34-3 3 0 1.65 1.34 3 3 3z"/></g>'
  }
})
