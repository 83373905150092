/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'messages-user-alt-bubble-square-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>chat</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.75 15.75h3v4.5l4.5-4.5h1.49l-.01-.001c.83 0 1.5-.68 1.5-1.51 0 0 0 0 0 0v-12 0c0-.83-.68-1.5-1.5-1.5H9.72c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v4.5M19.88 7.88h0"/><path pid="1" d="M19.875 7.875h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375M12.38 7.88h0M12.37 7.875h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375M16.12 7.88h0M16.125 7.875h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375M6.75 9.75a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75zM12.75 23.25h0a6.066 6.066 0 00-6.81-5.2 6.047 6.047 0 00-5.2 5.19"/></g>'
  }
})
