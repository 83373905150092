<template>
    <fetch-infinite
        ref="fetcher"
        v-slot="{ items, loadNextPage, total }"
        :filter="filter"
        :predicates="predicates"
        :source="source"
        :sort="sort"
        :with-preflight="withPreflight"
        @received-response="onReceivedResponse"
        @reset="resetLazyLoading"
    >
        <data-table
            ref="table"
            :columns="columns"
            :grouped="grouped"
            lazy-load-data
            :no-results-placeholder="noResultsPlaceholder"
            :rows="mapItems(items)"
            :total="total"
            v-bind="$attrs"
            @scroll-end="loadNextPage"
            v-on="$listeners"
        >
            <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
                <slot :name="slot" v-bind="{ reload, total, ...scope }"></slot>
            </template>
        </data-table>
    </fetch-infinite>
</template>

<script>
export default {
    props: {
        columns: {
            type: Array,
            required: true
        },

        filter: {
            type: Object,
            default: () => {
                return {};
            }
        },

        groupResource: {
            type: String,
            default: null
        },

        mapper: {
            type: Function,
            default: (item) => {
                return item;
            }
        },

        noResultsPlaceholder: {
            type: String,
            required: true
        },

        predicates: {
            type: Array,
            default: () => {
                return [];
            }
        },

        source: {
            type: String,
            required: true
        },

        sort: {
            type: Object,
            default: () => {
                return {};
            }
        },

        withPreflight: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            grouped: true
        };
    },

    methods: {
        clearSorts () {
            // While not recommended, this was the easiest way of forcing the
            // DataTable component to update the arrows next to column names in
            // the heading when the sorting order is reset.
            this.$refs.table.sorts = [];
        },

        mapItems (items) {
            return items.map((item) => {
                return this.mapper(item, this.grouped);
            });
        },

        onReceivedResponse (response) {
            this.grouped = response.data.resource === this.groupResource;

            this.$emit('received-response', response);
        },

        reload () {
            this.$refs.fetcher.reset();

            this.$refs.table.resetLazyLoading();
            this.$refs.table.toggleSelectAll(false);
        },

        resetLazyLoading () {
            this.$refs?.table.resetLazyLoading();
        }
    }
};
</script>
