/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-facebook': {
    width: 16,
    height: 16,
    viewBox: '0 0 27 52',
    data: '<path pid="0" d="M19.906.863C13.013.863 8.291 5.07 8.291 12.797v6.66H.492v9.033h7.8v23.164h9.322V28.49h7.777l1.168-9.033h-8.945V13.69c0-2.615.727-4.397 4.476-4.397h4.781V1.22c-.827-.11-3.662-.356-6.965-.356z" _fill="currentColor"/>'
  }
})
