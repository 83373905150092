import { make } from 'vuex-pathify';
import GuestsSeedData from '@/components/invite-list/guests';
import find from 'lodash/find';
import filterArray from 'lodash/filter';
import Vue from 'vue';

const moduleState = {
    guests: []
};

const mutations = {
    ...make.mutations(moduleState),

    loadMoreGuests (state, guests) {
        state.guests.splice(state.guests.length, 0, ...guests);
    },

    guestSelection (state, { id, selected }) {
        const guest = find(state.guests, { id });

        Vue.set(guest, 'selected', selected);
    },

    allGuestsSelection (state, selected) {
        state.guests.forEach((guest) => {
            Vue.set(guest, 'selected', selected);
        });
    }
};

const actions = {
    // Mock API method call which paginates filtered results
    loadPaginatedGuests ({ commit }, { page, limit, filter }) {
        return new Promise((resolve) => {
            setTimeout(() => {
                const loadedGuests = GuestsSeedData.guests
                    .filter((guest) => {
                        const searchRegex = new RegExp(filter.searchTerm, 'i');

                        return !filter.searchTerm || searchRegex.test(guest.fullName);
                    })
                    .slice(page * limit, (page + 1) * limit);

                if (page === 0) {
                    commit('guests', []);
                }

                commit('loadMoreGuests', loadedGuests);

                resolve(loadedGuests);
            }, 500);
        });
    },

    setGuestSelection ({ commit }, { guest, selected }) {
        commit('guestSelection', { id: guest.id, selected });
    },

    setAllGuestsSelection ({ commit }, selected) {
        commit('allGuestsSelection', selected);
    }
};

const getters = {
    selectedGuests (state) {
        return filterArray(state.guests, { selected: true });
    }
};

export default {
    namespaced: true,
    state: moduleState,
    mutations,
    actions,
    getters
};
