/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-profile-bubble-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.03 8.62H18M15 11.62h5.25M8.85 6.375a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM12.75 13.87h0c-1.25-2.16-4-2.89-6.15-1.65-.69.39-1.26.96-1.65 1.64"/><path pid="1" d="M15.75 3.375H8.24a7.516 7.516 0 00-7.5 7.5l-.01-.01c0 1.78.66 3.5 1.83 4.85l-1.84 4.89 7.359-2.27c.04 0 .09.01.14.01h7.5l-.01-.001c4.14 0 7.5-3.36 7.5-7.5 0-4.15-3.36-7.5-7.5-7.51z"/></g>'
  }
})
