<template>
    <div>
        <tabbed-modal
            :value="value"
            :hide-tab-controls="isNewGroup"
            @input="$emit('input', $event)"
            @activate-tab="tab => activeTab = tab"
        >
            <template #header>
                <div class="flex font-normal text-xl items-start">
                    <base-dropdown class="inline-block" placement="bottom-start">
                        <template #trigger="{ triggerFunction }">
                            <button
                                class="focus:outline-none"
                                @click="triggerFunction"
                            >
                                <div class="shrink mr-2">
                                    <a class="text-gray-500 hover:text-gray-600 transition ease-in-out duration-150" role="button">
                                        <app-icon
                                            name="navigation-menu-vertical"
                                            class="w-5 h-5"
                                            stroke
                                        ></app-icon>
                                    </a>
                                </div>
                            </button>
                        </template>

                        <div class="bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden mt-1">
                            <button
                                class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-red hover:bg-gray-100"
                                @click="deleteGroup"
                            >
                                <app-icon
                                    name="trash"
                                    class="h-4 w-4 mr-3"
                                    stroke
                                ></app-icon>
                                Delete Group
                            </button>
                        </div>
                    </base-dropdown>

                    <div class="grow">
                        <div>
                            <div class="flex items-center font-semibold">
                                Group of {{ inviteesCount }}
                                <span v-if="hasAdditionalGuests">+</span>
                                <div v-if="showLargeGroupBadge" class="badge badge-warning flex items-center justify-center space-x-2 ml-2 normal-case text-sm">
                                    <app-icon
                                        name="alert-triangle"
                                        class="h-4 w-4"
                                        stroke
                                    ></app-icon>
                                    <span>
                                        This is unusually large group
                                    </span>
                                    <span
                                        v-tippy
                                        content="Groups allow you to specify invitees who will reply together (e.g. couples, families, etc.). RSVPify will require all members of an invited group to reply as part of one submission."
                                        class="hover:cursor-help"
                                    >
                                        <app-icon
                                            name="info-circle"
                                            class="h-4 w-4"
                                            stroke
                                        ></app-icon>
                                    </span>
                                </div>
                            </div>
                            <div class="text-sm">up to <span class="font-semibold">{{ maxNumberOfGuests }}</span> total attendees</div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="isNewGroup" #tab-controls>
                <ul class="flex justify-center w-full" role="tablist">
                    <li class="inline-flex mx-2 -mb-px" role="presentation">
                        <a
                            role="button"
                            class="py-2 px-4 border-b-2 font-medium transition duration-150 ease-in-out"
                            :class="groupTabsStyle('individual')"
                            @click="setInviteesCount(1)"
                        >
                            Individual
                        </a>
                        <a
                            role="button"
                            class="py-2 px-4 border-b-2 font-medium transition duration-150 ease-in-out"
                            :class="groupTabsStyle('couple')"
                            @click="setInviteesCount(2)"
                        >
                            Couple
                        </a>
                        <a
                            role="button"
                            class="py-2 px-4 border-b-2 font-medium transition duration-150 ease-in-out"
                            :class="groupTabsStyle('group')"
                            @click="setInviteesCount(3)"
                        >
                            Group
                        </a>
                    </li>
                </ul>
            </template>

            <template #content="{ initialize }">
                <tabbed-modal-item
                    id="invitees"
                    label="Invitees"
                    @hook:mounted="initialize"
                >
                    <div
                        v-if="showExistingSubmissionMessage"
                        class="alert alert-error font-normal text-sm"
                        role="alert"
                    >
                        <div>
                            This invitee group has already submitted a reply. Changes made
                            here will be reflected if the group edits their existing reply.
                            <button class="underline" @click="$emit('view-reply', initialGroup.submission)">
                                View Existing Reply
                            </button>
                        </div>
                    </div>

                    <div v-if="form.errors.has('invitees')" class="alert alert-error alert-sm mt-2">
                        <p class="w-full font-normal text-center">{{ form.errors.get('invitees') }}</p>
                    </div>

                    <form-field-wrapper
                        v-for="(invitee, index) in invitees"
                        :key="invitee.id"
                    >
                        <template slot="label">
                            <div class="flex">
                                <span>Invitee {{ index + 1 }}</span>

                                <a
                                    v-if="inviteesCount > 1"
                                    class="button-icon hover:text-red ml-2"
                                    role="button"
                                    @click="deleteInvitee(index)"
                                >
                                    <app-icon
                                        name="trash"
                                        class="w-5 h-5"
                                        stroke
                                    ></app-icon>
                                </a>
                            </div>
                        </template>

                        <invitee-details
                            :invitee="invitee"
                            :index="index"
                            :form="form"
                            :group-size="invitees.length"
                            @tag-single-invitee="tagSingleInvitee"
                            @tag-entire-group="tagEntireGroup"
                        ></invitee-details>
                    </form-field-wrapper>

                    <button
                        v-tippy
                        class="button-text button-info mt-8"
                        :class="{'opacity-50': !canAddInvitees}"
                        :content="addInviteeTooltip"
                        @click="addInvitee"
                    >
                        <app-icon
                            name="add-circle"
                            class="shrink-0 h-6 w-6 mr-2"
                            stroke
                        ></app-icon>
                        <span>Add Invitee to Group</span>
                    </button>

                    <div class="ml-8 text-xs text-teal">
                        Guests in the same group will be asked to RSVP together in one submission.
                    </div>

                </tabbed-modal-item>

                <tabbed-modal-item
                    v-if="isNotesTabVisible"
                    id="my-notes"
                    label="My Notes"
                >
                    <comment-list :invitee-group-id="initialGroup.id"></comment-list>
                </tabbed-modal-item>

                <tabbed-modal-item
                    v-if="showPasswordTab"
                    id="password"
                    label="Password"
                >
                    <invitee-group-security :invitee-group="initialGroup"></invitee-group-security>
                </tabbed-modal-item>
            </template>

            <template v-if="activeTab === 'invitees'" #footer="{ close }">
                <stateful-button
                    class="button-primary"
                    :loading="saving"
                    @click="saveGroup"
                >
                    Save &amp; Close
                </stateful-button>

                <button class="button" @click="close">
                    Cancel
                </button>
            </template>
        </tabbed-modal>

        <app-modal
            v-model="showEventSecurityModal"
            title="Event Security"
            disable-click-outside
        >
            <div slot="close-button"></div>

            <select-list v-model="inviteListSettingsForm.security">
                <select-option id="public" class="my-2">
                    Public event
                    <p class="text-sm text-gray-600">Anyone with your event's link may register or RSVP</p>
                </select-option>

                <select-option id="private" class="my-2">
                    Invite-only private event
                    <p class="text-sm text-gray-600">Attendees must be on your Invite List to RSVP or register</p>
                </select-option>
            </select-list>

            <template #footer>
                <stateful-button
                    :loading="savingInviteListSettings"
                    class="button-primary"
                    @click="updateInviteListSettings"
                >
                    Save &amp; Close
                </stateful-button>
            </template>
        </app-modal>
        <send-invitee-campaign-message-modal ref="inviteeCampaignMessageModal" :event="event"></send-invitee-campaign-message-modal>
    </div>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';
import {
    cloneDeep, find, sumBy, get as getValue
} from 'lodash';
import Form from '@/validation/Form';
import InteractsWithFeatureFlags from '@/mixins/InteractsWithFeatureFlags';

export default {
    name: 'InviteeGroupModal',

    mixins: [
        InteractsWithFeatureFlags
    ],

    props: {
        defaultInvitee: {
            type: Object,
            required: true
        },
        initialGroup: {
            type: Object,
            required: true
        },
        isInviteListEmpty: {
            type: Boolean,
            required: true
        },
        shouldRefresh: {
            type: Boolean,
            default: true
        },
        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            activeTab: 'invitees',
            form: new Form(cloneDeep(this.initialGroup)),
            inviteListSettingsForm: null,
            saving: false,
            savingInviteListSettings: false,
            showEventSecurityModal: false
        };
    },

    computed: {
        event: sync('Event/event'),

        addInviteeTooltip () {
            if (!this.canAddInvitees) {
                return `A group can have a maximum of 10 invitees. Groups should include ONLY guests that will reply together (e.g. couple, family, etc.).`;
            }

            return null;
        },

        canAddInvitees () {
            return this.inviteesCount < 10;
        },

        hasAdditionalGuests () {
            return find(this.invitees, (invitee) => {
                return invitee.additionalGuestsLimit === null || invitee.additionalGuestsLimit > 0;
            }) !== undefined;
        },

        invitees () {
            return this.form.invitees;
        },

        inviteesCount () {
            return this.invitees.length;
        },

        isNotesTabVisible () {
            return this.initialGroup.id != null;
        },

        isNewGroup () {
            return !this.initialGroup.id;
        },

        maxNumberOfGuests () {
            const hasUnlimitedAdditionalGuests = find(this.invitees, (invitee) => {
                return invitee.additionalGuestsLimit === null;
            }) !== undefined;

            if (hasUnlimitedAdditionalGuests) {
                return 'unlimited';
            }

            return this.invitees.length + sumBy(this.invitees, 'additionalGuestsLimit');
        },

        showExistingSubmissionMessage () {
            if (!this.initialGroup.submission) {
                return false;
            }

            if (!this.initialGroup.submission.testMode && this.initialGroup.hasLiveSubmission) {
                return true;
            }

            if (this.initialGroup.submission.testMode && !this.event.isCurrentlyLive) {
                return true;
            }

            return false;
        },

        showLargeGroupBadge () {
            return this.inviteesCount >= 10;
        },

        showPasswordTab () {
            if (!this.isFeatureEnabled('invitee-passwords')) {
                return false;
            }

            if (!this.initialGroup.id) {
                return false;
            }

            return getValue(this.event.settings, 'security.level') === 'inviteePassword'
                && getValue(this.event.settings, 'security.inviteePassword.type') === 'randomPasswords';
        }
    },

    watch: {
        initialGroup (newVal) {
            this.form = new Form(cloneDeep(newVal));
        },

        value (newVal) {
            if (newVal) {
                if (this.isInviteListEmpty) {
                    this.showEventSecurityModal = true;
                }
            }
        }
    },

    created () {
        this.inviteListSettingsForm = new Form(cloneDeep(this.event.invite_list.settings));
    },

    methods: {
        groupTabsStyle (tab) {
            const isTabActive = {
                individual: this.inviteesCount === 1,
                couple: this.inviteesCount === 2,
                group: this.inviteesCount >= 3
            };

            return {
                'border-transparent text-gray-500 hover:text-gray-600': !isTabActive[tab],
                'border-purple text-purple': isTabActive[tab]
            };
        },

        addInvitee () {
            if (!this.canAddInvitees) {
                return;
            }

            this.invitees.push(cloneDeep(this.defaultInvitee));
        },

        deleteGroup () {
            const attributes = {
                confirmButtonText: 'Delete Invitees',
                cancelButtonText: 'Cancel',
                html: `<p>You are deleting a <span class="font-bold">group of ${this.inviteesCount} invitee(s)</span> from your Invite List? This cannot be undone.</p>`
            };

            if (this.initialGroup.submission) {
                Object.assign(attributes, {
                    input: 'checkbox',
                    inputPlaceholder: `Also delete this group's existing reply and all associated data.`
                });
            }

            const onDelete = ({ value }) => {
                const route = this.route('api.events.invitee-groups.destroy', {
                    event: this.event,
                    invitee_group: this.form.id,
                    deleteSubmission: this.initialGroup.submission ? value : 0
                });

                this.form.delete(route)
                    .then(() => {
                        this.$emit('deleted-group');
                        this.$emit('input', false);
                    }).catch(() => {
                        this.$toasted.global.error(`There was an error deleting the invitee group.`);
                    });
            };

            App.alert().confirm(
                'Are you sure?',
                'All members of this group and all associated data will be deleted. This cannot be undone.',
                attributes,
                onDelete
            );
        },

        deleteInvitee (index) {
            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            App.alert().confirm(
                'Are you sure?',
                'Are you sure you want to remove this guest?',
                attributes,
                () => {
                    this.invitees.splice(index, 1);
                }
            );
        },

        handleFailedSave (error) {
            if (!error.response) {
                this.$toasted.global.error('There was a problem saving the invite list.');
                return;
            }

            if (error.response.status === 422) {
                return;
            }

            if (error.response.status !== 403 || error.response.data.reason !== 'exceededLimit') {
                this.$toasted.global.error('There was a problem saving the invite list.');
                return;
            }

            const { name, features } = this.auth().user().plan;

            App.alert().paymentRequired(
                'Not enough space available.',
                `Your ${name} plan allows you to invite up to ${features.InviteeLimit.max} total invitees, including additional guests (+1’s and +X’s). Please upgrade to invite more guests.`
            );
        },

        async handleSuccessfulSave (invitees) {
            if (!this.form.id) {
                await this.$refs.inviteeCampaignMessageModal.open(invitees);
            }

            this.$emit('saved', this.inviteesCount);
            this.$emit('input', false);
            this.reloadTags();

            // If group has more than 10 invitees, users will need to see the notification
            // about it, therefore page needs to be refreshed, so notification gets retrieved
            // from the backend.
            if (invitees.length >= 10 && this.shouldRefresh) {
                window.location.reload();
            }
        },

        newSaveGroupRequest () {
            return this.form.id
                ? this.form.put(this.route('api.events.invitee-groups.update', [this.event, this.form.id]))
                : this.form.post(this.route('api.events.invitee-groups.store', [this.event]));
        },

        reloadTags: call('Tags/reloadTags'),

        saveGroup () {
            this.saving = true;

            this.newSaveGroupRequest()
                .then(async (response) => {
                    await this.handleSuccessfulSave(response.data.data.invitees);
                })
                .catch(this.handleFailedSave)
                .finally(() => {
                    this.saving = false;
                });
        },

        setInviteesCount (count) {
            if (this.inviteesCount > count) {
                this.invitees.splice(count);
            } else {
                const inviteesToAdd = count - this.inviteesCount;

                for (let i = 0; i < inviteesToAdd; i++) {
                    this.addInvitee();
                }
            }
        },

        tagEntireGroup (newTags) {
            this.invitees.forEach((invitee) => {
                this.tagSingleInvitee(invitee, newTags);
            });
        },

        tagSingleInvitee (invitee, newTags) {
            invitee.tags = newTags;
        },

        updateInviteListSettings () {
            this.savingInviteListSettings = true;

            return this.inviteListSettingsForm.put(this.route('api.invite-list.settings.update', this.event))
                .then(() => {
                    this.event.invite_list.settings.security = this.inviteListSettingsForm.security;
                    this.showEventSecurityModal = false;
                })
                .finally(() => {
                    this.savingInviteListSettings = false;
                });
        }
    }
};
</script>
