/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bookmark-check-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>ribbon done</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.059 23.105l-6.177-4.5h0a1.5 1.5 0 00-1.77-.01l-6.18 4.49h-.01a.76.76 0 01-1.05-.17.731.731 0 01-.15-.44V2.22h0C3.71 1.39 4.39.71 5.21.71s0 0 0 0h13.5-.01c.82-.01 1.5.67 1.5 1.5v20.25-.01c-.01.41-.34.74-.76.74a.83.83 0 01-.44-.15z"/><path pid="1" d="M16.5 7.811l-5.47 5.46h-.01c-.3.29-.77.29-1.06 0l-.01-.01-1.72-1.72"/></g>'
  }
})
