<template>
    <div class="flex flex-col grow">
        <div
            v-show="canUpload"
            v-tippy
            class="mb-4"
            :class="uploaderClasses"
            :content="tooltip"
        >
            <div class="inline-flex items-center text-teal cursor-pointer mb-2 hover:text-teal-dark font-medium transition duration-150 ease-in-out" @click="isUploaderVisible = true">
                <slot name="placeholder">
                    <app-icon
                        name="add-circle"
                        class="h-6 w-6 mr-2"
                        stroke
                    ></app-icon>
                    {{ addImageText }}
                </slot>
            </div>

            <p v-if="maxImages > 1" class="text-sm text-gray-600 italic">
                To create a slideshow, add multiple images.
            </p>
        </div>

        <draggable
            v-if="value.length > 0"
            :value="value"
            v-bind="draggableOptions"
            @input="newValue => $emit('input', newValue)"
        >
            <transition-group tag="div" class="flex flex-wrap mt-2 mb-4">
                <div
                    v-for="({ id, src }, index) in value"
                    :key="id"
                    class="flex w-full sm:w-1/2 px-2 mb-4 justify-center box-sizing-border"
                >
                    <div class="flex flex-col w-full h-48 sm:h-32 relative rounded-md overflow-hidden">
                        <app-icon
                            v-if="imageBeingCroppedIndex === index"
                            class="h-4 w-4 m-auto text-gray-500 fill-current"
                            name="loader"
                        ></app-icon>

                        <img
                            v-else
                            :src="src"
                            class="w-full h-full object-cover draggable-handle cursor-grab"
                        >

                        <div class="absolute inset-x-0 bottom-0 flex justify-between items-center bg-purple-light text-white py-3 px-2 h-8 sm:h-4 leading-none">
                            <a
                                class="text-purple-200 hover:text-purple-100 w-6 h-6 sm:w-4 sm:h-4 transition duration-150 ease-in-out leading-0"
                                role="button"
                                @click="imageBeingCroppedIndex = index"
                            >
                                <app-icon
                                    name="resize-expand"
                                    class="w-full h-full"
                                ></app-icon>
                            </a>

                            <a
                                class="text-purple-200 hover:text-purple-100 w-6 h-6 sm:w-4 sm:h-4 transition duration-150 ease-in-out leading-0"
                                role="button"
                                @click="deleteImage(index)"
                            >
                                <app-icon
                                    name="trash"
                                    class="w-full h-full"
                                ></app-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </transition-group>
        </draggable>

        <div v-if="value.length > 1" class="flex flex-wrap">
            <form-field-wrapper label="Slide duration">
                <simple-picker
                    v-model="settings.transition.duration"
                    placeholder-empty-state="Select slide duration..."
                    :items="slideDurations"
                    item-label="label"
                    item-value="value"
                >
                </simple-picker>
            </form-field-wrapper>

            <form-field-wrapper label="Transition style">
                <simple-picker
                    v-model="settings.transition.name"
                    placeholder-empty-state="Select transition style..."
                    :items="slideNames"
                    item-label="label"
                    item-value="value"
                >
                </simple-picker>
            </form-field-wrapper>

            <form-field-wrapper>
                <toggle-switch v-model="settings.manualNavigation">
                    Manual Navigation
                </toggle-switch>
            </form-field-wrapper>
        </div>

        <image-cropper
            v-if="imageBeingCroppedIndex !== null"
            :image="imageBeingCropped"
            @cancel="imageBeingCroppedIndex = null"
            @input="uploadCroppedImage"
        ></image-cropper>

        <fancy-image-uploader-modal
            v-model="isUploaderVisible"
            :title="title"
            @select-image="saveUploadedImage"
        >
            <template #recommendations>
                <slot name="recommendations"></slot>
            </template>
        </fancy-image-uploader-modal>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';
import UploadsEventImages from '@/mixins/UploadsEventImages';

export default {
    name: 'FancyImageManager',

    components: {
        draggable
    },

    mixins: [
        GeneratesUniqueKey,
        UploadsEventImages
    ],

    props: {
        addImageText: {
            type: String,
            default: 'Add an Image'
        },
        conversion: {
            type: String,
            default: 'original'
        },
        maxImages: {
            type: Number,
            default: 10
        },
        settings: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        tooltip: {
            type: String,
            default: ''
        },
        uploaderClasses: {
            type: String,
            default: 'text-center'
        },
        value: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            imageBeingCroppedIndex: null,
            isUploaderVisible: false,
            slideDurations: [
                { label: '5 Seconds', value: 5000 },
                { label: '4 Seconds', value: 4000 },
                { label: '3 Seconds', value: 3000 },
                { label: '2 Seconds', value: 2000 },
                { label: 'Manual Only', value: 'manual-only' }
            ],
            slideNames: [
                { label: 'Slide', value: 'slide' },
                { label: 'Fade', value: 'fade' }
            ]
        };
    },

    computed: {
        canUpload () {
            return this.value.length < this.maxImages;
        },

        draggableOptions () {
            return {
                group: 'block-images',
                animation: 150,
                handle: '.draggable-handle'
            };
        },

        imageBeingCropped () {
            return this.value[this.imageBeingCroppedIndex];
        },

        isTransitionManualOnly () {
            return this.settings.transition.duration === 'manual-only';
        }
    },

    watch: {
        'settings.transition.duration': function () {
            if (this.isTransitionManualOnly) {
                this.settings.manualNavigation = true;
            }
        }
    },

    methods: {
        deleteImage (deletingIndex) {
            const newValue = this.value.slice().filter((_, index) => {
                return index !== deletingIndex;
            });

            this.$emit('input', newValue);
        },

        saveUploadedImage (urls) {
            this.$emit('input', [
                ...this.value,
                {
                    id: this.getUniqueKey(),
                    src: this.conversion === 'none' ? urls : urls[this.conversion]
                }
            ]);

            this.isUploaderVisible = false;
        },

        async uploadCroppedImage ({ file: blob }) {
            try {
                const filename = this.resolveFilenameFromUrl(this.imageBeingCropped.src);

                const urls = await this.uploadEventImage(
                    this.createImageFromBlob(filename, blob)
                );

                const newValue = Object.assign([], this.value, {
                    [this.imageBeingCroppedIndex]: {
                        ...this.imageBeingCropped,
                        src: this.conversion === 'none' ? urls : urls[this.conversion]
                    }
                });

                this.$emit('input', newValue);

                this.imageBeingCroppedIndex = null;
            } catch (error) {
                this.$toasted.global.error(error.message);
            }
        }
    }
};
</script>
