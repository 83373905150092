/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-sanitizer-virus-block-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.408 21.44H2.95a2.185 2.185 0 01-2.18-2.18s0 0 0 0V8.36H.76c0-1.21.97-2.18 2.17-2.18h7.757v-.001c1.2-.01 2.17.97 2.18 2.17v.95"/><path pid="1" d="M4.052.75h5.57-.01c.6-.01 1.08.48 1.08 1.08 0 0 0 0 0 0v4.36H2.93V1.82l-.001-.01A1.073 1.073 0 014.01.72c0-.001 0 0 0 0zM14.712 19.64h0a3.461 3.461 0 01.02-4.9 3.45 3.45 0 014.86-.01M20.62 17.193h0c0 1.91-1.55 3.46-3.46 3.46-.01 0-.01-.01-.01-.01M16.58 11.14h1.16M17.16 11.14v2.59M23.22 16.62v1.15M23.22 17.19h-2.6M21.85 21.07l-.82.81M21.44 21.48l-1.83-1.84M17.74 23.25h-1.16M17.16 23.25v-2.6M11.1 17.77v-1.15M11.1 17.19h2.6M12.47 13.32l.81-.82M12.88 12.91l1.83 1.84M23.22 11.14L11.1 23.25M.78 10.55h8.58M.78 17.09h6.54"/></g>'
  }
})
