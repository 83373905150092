/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outdoors-tree-valley-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.374 1.08h0a.756.756 0 00-1.05-.21c-.09.05-.16.12-.21.2l-3.88 6.41h2.25l-2.9 4.12h-.01c-.21.35-.09.81.27 1.02.11.06.24.09.37.09h9v-.001c.41-.01.74-.34.74-.76a.973.973 0 00-.1-.38l-2.91-4.13h2.25zM6.75 12.75v4.58M19.26 1.02h0a.614.614 0 00-.86-.17c-.07.04-.13.1-.17.16l-3.18 4.97h1.84l-2.58 3.58v-.01a.606.606 0 00.52.91h7.77c.33 0 .61-.28.61-.62 0-.11-.03-.22-.09-.31l-2.58-3.59h1.84zM18.75 10.5v4.03M.8 18v-.01c5.81-2.02 12.25.14 15.67 5.25"/><path pid="1" d="M10.96 18.518v-.01a15.64 15.64 0 0110.78-4.27c.5 0 1 .02 1.5.07"/></g>'
  }
})
