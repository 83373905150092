/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="currentColor" d="M23.414 20.591l-4.645-4.645a10.256 10.256 0 10-2.828 2.829l4.645 4.644a2.025 2.025 0 002.828 0 2 2 0 000-2.828zM10.25 3.005A7.25 7.25 0 113 10.255a7.258 7.258 0 017.25-7.25z"/>'
  }
})
