<template>
    <div>
        <button class="button-text button-primary" @click="exportSpreadsheet">
            <app-icon name="download-bottom-stroke" class="h-6 w-6 mr-2"></app-icon>
            Export passwords
        </button>

        <app-modal
            v-slot="{ close }"
            v-model="isModalVisible"
            header-classes="hidden"
        >
            <div class="text-center">
                <template v-if="state === STATE_EXPORTING">
                    <app-icon class="w-10 h-10" name="loader"></app-icon>
                    <p class="my-4 text-2xl font-semibold">We’re preparing your export</p>
                    <p>For larger events, this may take a few minutes. Please don’t leave this screen.</p>
                </template>

                <template v-if="state === STATE_EXPORT_READY">
                    <app-icon class="w-10 h-10 text-green" name="check-circle-alt-stroke"></app-icon>
                    <p class="my-4 text-2xl font-semibold">Your export is complete!</p>
                    <p class="mb-4">
                        Your download should begin automatically. If it doesn’t, <a class="text-teal hover:underline" :href="downloadUrl">click here</a>.
                    </p>
                    <button class="button button-primary text-uppercase tracking-wide" @click="close">CLOSE</button>
                </template>

                <template v-if="state === STATE_FAILED">
                    <app-icon class="w-10 h-10 text-red" name="close-circle-stroke"></app-icon>
                    <p class="my-4 text-2xl font-semibold">Something went wrong :(</p>
                    <p class="mb-4">
                        Not to worry though as our <a
                            :href="zendeskTicketUrl"
                            target="_blank"
                            class="button-text button-primary"
                        >passionate Support Team</a> would be happy to look into this for you!
                    </p>
                    <button class="button button-primary text-uppercase tracking-wide" @click="close">CLOSE</button>
                </template>
            </div>
        </app-modal>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import axios from '@/util/axios';
import EventTimezone from '@/mixins/EventTimezone';
import InteractsWithAbly from '@/mixins/InteractsWithAbly';

const STATE_EXPORTING = 'exporting';
const STATE_EXPORT_READY = 'export-ready';
const STATE_FAILED = 'failed';

export default {
    name: 'ExportInviteePasswords',

    mixins: [InteractsWithAbly, EventTimezone],

    data () {
        return {
            STATE_EXPORTING,
            STATE_EXPORT_READY,
            STATE_FAILED,
            downloadUrl: null,
            isModalVisible: false,
            state: null
        };
    },

    computed: {
        event: get('Event/event'),

        zendeskTicketUrl () {
            return window.urls.ticket;
        }
    },

    methods: {
        exportSpreadsheet () {
            this.listenForEvents();

            this.isModalVisible = true;
            this.state = STATE_EXPORTING;

            this.initiateExport();
        },

        /**
         * Starts the download process by submitting an API call.
         */
        initiateExport () {
            axios.get(this.route('invite-list.passwords.export', {
                event: this.event
            }));
        },

        /**
         * Listens for the export to fail or succeed to present the download link.
         */
        listenForEvents () {
            this.$echo.private(`events.${this.event.id}.invite-list.passwords`)
                .listen('.Domain\\InviteList\\Events\\ExportInviteePasswordsFailed', () => {
                    this.state = STATE_FAILED;
                })
                .listen('.Domain\\InviteList\\Events\\InviteePasswordsExported', ({ downloadUrl }) => {
                    this.downloadUrl = downloadUrl;
                    this.state = STATE_EXPORT_READY;
                    window.location = downloadUrl;
                });
        }
    }
};
</script>
