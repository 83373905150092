/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tags-add': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>new</desc><g _fill="currentColor"><circle pid="0" cx="17.17" cy="5.999" r="1.5"/><path pid="1" d="M9.9 21.191a.249.249 0 00-.32-.114 1.029 1.029 0 01-1.118-.2l-6.171-6.171a1 1 0 010-1.414l11-11A1 1 0 0114 2h6.67a.5.5 0 01.5.5v6.67a1 1 0 01-.293.708l-.166.166a.249.249 0 00.059.4 8.028 8.028 0 011.23.813.25.25 0 00.342-.027 2.927 2.927 0 00.825-2.06V2a2 2 0 00-2-2H14a2.98 2.98 0 00-2.122.879l-11 11a3 3 0 000 4.242l6.172 6.172a3.005 3.005 0 003.57.5.25.25 0 00.076-.373 8.073 8.073 0 01-.796-1.229z"/><path pid="2" d="M11 17.5a6.5 6.5 0 106.5-6.5 6.508 6.508 0 00-6.5 6.5zm9-.75a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25V20a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H15a.75.75 0 110-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25z"/></g>'
  }
})
