<template>
    <div>
        <multi-page-item-settings
            :items-array="options"
            :settings="settings"
            :item-creator-function="createItem"
            :default-close-handler="defaultCloseHandler"
            @save-specific-settings="saveSpecificItemSettings"
        >
            <template slot-scope="{ edit, save, create, restore, selectedItem }">
                <form-question-block-general-settings
                    key="general-settings"
                    :event="event"
                    :form="form"
                    @edit-item="edit"
                    @create-item="create"
                    @update-question="handleQuestionUpdate"
                ></form-question-block-general-settings>

                <form-question-block-item-settings
                    key="item-settings"
                    :event="event"
                    :block="block"
                    :item="selectedItem"
                    :question="block.pivot.question"
                    @save-settings="save"
                    @cancel="restore"
                ></form-question-block-item-settings>
            </template>
        </multi-page-item-settings>

        <portal v-if="questionTypeTitle" to="block-settings-title">
            <div class="flex items-center">
                <span>{{ questionTypeTitle }}</span>

                <premium-feature
                    class="ml-2"
                    feature="CustomQuestionLimit"
                ></premium-feature>
            </div>
        </portal>
    </div>
</template>

<script>
import get from 'lodash/get';
import Form from '@/validation/Form';
import HasDefaultCloseHandler from '@/mixins/HasDefaultCloseHandler';
import BlockableSettingsMixin from '@/mixins/BlockableSettingsMixin';

export default {
    name: 'FormCustomQuestionBlockSettings',

    mixins: [HasDefaultCloseHandler, BlockableSettingsMixin],

    data () {
        return {
            form: new Form(
                Object.assign(this.settings, { question: this.block.pivot.question })
            )
        };
    },

    computed: {
        newItemDefaultSettings () {
            const settings = {
                costsMoney: get(this.block, 'pivot.question.type.settings.optionsRequireMoney', false),
                description: '',
                icon: null
            };

            if (this.supportsInvites) {
                settings.calendarInvites = {
                    enabled: false,
                    description: get(this.event.settings, 'globalCalendarInvites.description', null),
                    email: get(this.event.settings, 'globalCalendarInvites.organizerEmail', null),
                    end: '',
                    location: { address: '', venue: '' },
                    organizer: get(this.event.settings, 'globalCalendarInvites.eventOrganizer', null),
                    start: '',
                    timezone: this.event.timezone_id,
                    type: this.event.starts_at ? 'event' : 'custom'
                };
            }

            if (this.supportsLimitedAvailability) {
                settings.maxCapacity = {
                    enabled: false,
                    slots: 50,
                    showMessage: true,
                    message: 'Sorry, this item is no longer available.'
                };
            }

            return settings;
        },

        options () {
            if (!this.form.question) {
                return [];
            }

            return this.form.question.options;
        },

        questionTypeTitle () {
            return get(this.block, 'pivot.question.type.title', null);
        },

        supportsInvites () {
            return get(this.block, 'question.type.slug', null) !== 'cash-donation-gift';
        },

        supportsLimitedAvailability () {
            return get(this.block, 'question.type.settings.canLimitAvailability', false);
        }
    },

    methods: {
        createItem () {
            return {
                title: '',
                type: 'primary',
                price: 0,
                settings: this.newItemDefaultSettings,
                sort: this.options.length + 1
            };
        },

        handleQuestionUpdate (newQuestion) {
            this.$set(this.block.pivot, 'question', newQuestion);
        },

        saveSpecificItemSettings (item) {
            if (item.customAmountItem) {
                this.$set(this.form.question.settings, 'customAmount', item);
            } else if (item.declineableItem) {
                this.$set(this.form.question.settings, 'declineable', item);
            }
        }
    }
};
</script>
