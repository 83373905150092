/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'anchor-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sail nautical boat</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.92.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM11.93 5.25v18M8.18 9.75h7.5M5.179 14.25H3.24l-.01-.001a.743.743 0 00-.76.74c-.01.12.03.24.09.36l-.01-.01c1.82 3.46 5.41 5.63 9.33 5.64M18.67 14.25h1.92-.01c.41-.01.75.33.75.74 0 .12-.04.24-.1.36v-.01a10.594 10.594 0 01-9.34 5.64"/></g>'
  }
})
