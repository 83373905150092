<template>
    <div
        class="relative flex group rounded-md border border-dashed"
        :class="styles.wrapper"
    >
        <div v-if="editable" class="hidden group-hover:flex absolute items-center space-x-4 right-0 mr-4 top-1/2 -translate-y-1/2">
            <div
                v-tippy
                class="flex items-center justify-center bg-gray-200 rounded-md border-2 border-gray-500 text-gray-700 hover:text-gray-900 p-1 cursor-pointer"
                content="Button settings"
                @click="showButtonEditor"
            >
                <app-icon
                    name="settings-cog"
                    stroke
                    class="w-5 h-5"
                ></app-icon>
            </div>
            <div
                v-tippy
                class="flex items-center justify-center bg-gray-200 rounded-md border-2 border-gray-500 text-gray-700 hover:text-gray-900 p-1 cursor-pointer"
                content="Delete button(s)"
                @click="deleteButton"
            >
                <app-icon name="trash" class="w-5 h-5"></app-icon>
            </div>
        </div>

        <div ref="button">
            <div
                v-for="(text, index) in buttonsToRender"
                :key="index"
                class="text-center"
                :class="index > 0 ? 'mt-3' : ''"
            >
                <div
                    class="inline-flex items-center justify-center px-4 py-2 cursor-pointer"
                    :style="styles.button"
                    @click="showButtonEditor"
                >
                    <span>{{ text }}</span>
                </div>
            </div>
        </div>
        <div
            v-if="editable"
            class="-mr-10 ml-2 shrink px-2 flex items-center text-smoke-300 group-hover:text-smoke-400 cursor-grab"
            data-drag-handle
        >
            <app-icon
                name="move-vertical-arrows"
                class="h-4 w-4"
            ></app-icon>
        </div>

        <portal v-if="buttonEditorShown" to="modal-portal-target">
            <div
                ref="dropdown"
                v-click-outside="closeButtonEditor"
                class="absolute bg-black border-b-2 border-purple-light rounded-xl p-4 w-48 z-90 whitespace-normal"
            >
                <div class="flex flex-col grow">
                    <div class="flex flex-wrap">
                        <template v-if="type === 'preselection'">
                            <div class="w-full">
                                <label class="flex items-center justify-between relative uppercase tracking-wide text-xs font-semibold text-snow-700">
                                    Attending
                                </label>

                                <div class="block relative w-full">
                                    <input
                                        v-model="buttonData.attendingText"
                                        class="form-field text-snow-700 text-sm p-1 bg-transparent"
                                    >
                                </div>
                            </div>

                            <div class="w-full mt-4">
                                <label class="flex items-center justify-between relative uppercase tracking-wide text-xs font-semibold text-snow-700">
                                    Not Attending
                                </label>

                                <div class="block relative w-full">
                                    <input
                                        v-model="buttonData.notAttendingText"
                                        class="form-field text-snow-700 text-sm p-1 bg-transparent"
                                    >
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="w-full">
                                <label class="flex items-center justify-between relative uppercase tracking-wide text-xs font-semibold text-snow-700">
                                    Button Text
                                </label>

                                <div class="block relative w-full pt-1">
                                    <input
                                        v-model="buttonData.text"
                                        class="form-field text-snow-700 text-sm p-1 bg-transparent"
                                    >
                                </div>
                            </div>
                        </template>

                        <div v-if="type !== 'custom'" class="w-full mt-4">
                            <label class="flex items-center justify-between relative uppercase tracking-wide text-xs font-semibold text-snow-700">
                                Show first on mobile
                            </label>

                            <div class="block relative w-full">
                                <div class="relative pt-1">
                                    <select v-model="buttonData.showFirstOnMobile" class="form-field text-snow-700 text-sm p-1 bg-transparent">
                                        <option class="text-gray-900" value="Wall">Website</option>
                                        <option class="text-gray-900" value="Form">Form</option>
                                    </select>

                                    <div class="absolute inset-y-0 right-0 pointer-events-none flex items-center pr-2 text-snow-300 z-90">
                                        <app-icon
                                            class="w-4 h-4"
                                            name="arrow-down-chevron"
                                            stroke
                                        ></app-icon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="type === 'custom'" class="w-full mt-4">
                            <label class="flex items-center justify-between relative uppercase tracking-wide text-xs font-semibold text-snow-700">
                                Link
                            </label>

                            <div class="block relative w-full pt-1">
                                <input
                                    v-model="buttonData.link"
                                    class="form-field text-snow-700 text-sm p-1 bg-transparent"
                                    placeholder="https://www.domain.com"
                                >
                            </div>
                        </div>

                        <div class="w-full mt-4">
                            <label class="flex items-center justify-between relative uppercase tracking-wide text-xs font-semibold text-snow-700">
                                Text Color
                            </label>

                            <div class="block relative w-full pt-1">
                                <color-bar v-model="buttonData.textColor" :has-alpha="false"></color-bar>
                            </div>
                        </div>

                        <div class="w-full mt-4">
                            <label class="flex items-center justify-between relative uppercase tracking-wide text-xs font-semibold text-snow-700">
                                Button Color
                            </label>

                            <div class="block relative w-full pt-1 rounded">
                                <color-bar v-model="buttonData.buttonColor" :has-alpha="false"></color-bar>
                            </div>
                        </div>

                        <div class="w-full mt-4">
                            <label class="flex items-center justify-between relative uppercase tracking-wide text-xs font-semibold text-snow-700">
                                Radius
                            </label>

                            <div class="block relative w-full pt-1">
                                <input
                                    v-model="buttonData.borderRadius"
                                    class="form-field text-snow-700 text-sm p-1 w-12 bg-transparent"
                                    placeholder="1"
                                >
                            </div>
                        </div>

                        <div class="w-full mt-4">
                            <label class="flex items-center justify-between relative uppercase tracking-wide text-xs font-semibold text-snow-700">
                                Alignment
                            </label>

                            <div class="block relative w-full pt-1">
                                <div class="flex justify-between items-center">
                                    <button @click="buttonData.align = 'justify-start'">
                                        <app-icon
                                            name="paragraph-align-left-stroke"
                                            class="h-5 w-5 text-white"
                                            :class="{ 'opacity-50': buttonData.align !== 'justify-start' }"
                                        ></app-icon>
                                    </button>
                                    <button @click="buttonData.align = 'justify-center'">
                                        <app-icon
                                            name="paragraph-align-center-stroke"
                                            class="h-5 w-5 text-white"
                                            :class="{ 'opacity-50': buttonData.align !== 'justify-center' }"
                                        ></app-icon>
                                    </button>
                                    <button @click="buttonData.align = 'justify-end'">
                                        <app-icon
                                            name="paragraph-align-right-stroke"
                                            class="h-5 w-5 text-white"
                                            :class="{ 'opacity-50': buttonData.align !== 'justify-end' }"
                                        ></app-icon>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="w-full mt-4">
                            <div class="flex justify-center items-center">
                                <button @click="deleteButton">
                                    <app-icon
                                        name="trash"
                                        class="h-4 w-4 text-white opacity-50 hover:opacity-100"
                                    ></app-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref="content" class="hidden"></div>
            </div>
        </portal>
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import debounce from 'lodash/debounce';
import ValidatesLinks from '@/mixins/ValidatesLinks';

export default {
    name: 'EmailButton',

    mixins: [ValidatesLinks],

    props: {
        editor: {
            type: Object,
            required: true
        },

        getPos: {
            type: Function,
            required: true
        },

        node: {
            type: Object,
            required: true
        },

        updateAttrs: {
            type: Function,
            required: true
        },

        view: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            buttonData: JSON.parse(decodeURIComponent(this.node.attrs.buttonData)),
            buttonEditorShown: false,
            popper: null
        };
    },

    computed: {
        buttonsToRender () {
            if (this.type === 'preselection') {
                return [this.buttonData.attendingText, this.buttonData.notAttendingText];
            }

            return [this.buttonData.text];
        },

        editable () {
            return this.editor && this.editor.options.editable;
        },

        styles () {
            return {
                wrapper: {
                    'hover:border-gray-200': this.editable,
                    [this.buttonData.align]: true,
                    'border-gray-200': this.buttonEditorShown,
                    'border-transparent': !this.buttonEditorShown
                },
                button: {
                    color: this.buttonData.textColor,
                    'background-color': this.buttonData.buttonColor,
                    'border-color': this.buttonData.borderColor,
                    'border-radius': `${this.buttonData.borderRadius}px`
                }
            };
        },

        type () {
            return this.node.attrs.type;
        }
    },

    watch: {
        buttonData: {
            deep: true,
            handler () {
                this.updateAttrs({
                    buttonData: encodeURIComponent(JSON.stringify(this.buttonData))
                });
            }
        },

        buttonEditorShown (newVal) {
            if (newVal) {
                this.editor.blur();

                this.$nextTick(() => {
                    if (this.popper) {
                        this.popper.update();
                    } else {
                        this.initializePopper();
                    }
                });
            } else {
                this.destroyPopper();
            }
        },

        'buttonData.link': debounce(function (href) {
            const link = this.getValidatedLink(href);

            if (href !== '' && link === null) {
                this.$toasted.show('The link is invalid.');

                return;
            }

            this.buttonData.link = link;
        }, 500)
    },

    methods: {
        closeButtonEditor () {
            if (this.buttonEditorShown) {
                this.buttonEditorShown = false;
            }
        },

        deleteButton () {
            const transaction = this.view.state.tr;
            const position = this.getPos();

            transaction.delete(position, position + this.node.nodeSize);

            this.view.dispatch(transaction);
        },

        destroyPopper () {
            this.popper?.destroy();
            this.popper = null;
        },

        showButtonEditor () {
            if (!this.editable) {
                return;
            }

            this.buttonEditorShown = !this.buttonEditorShown;
        },

        initializePopper () {
            if (this.popper) {
                return;
            }

            this.popper = createPopper(this.$refs.button, this.$refs.dropdown, {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10]
                        }
                    },
                    { name: 'flip' },
                    { name: 'preventOverflow' }
                ],
                placement: 'bottom',
                strategy: 'fixed'
            });
        }
    }
};
</script>
