/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tickets-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 11.25h0c-.01-.83.67-1.51 1.49-1.51v0h-.01c.82-.01 1.5.67 1.5 1.5v0h0c0 .48-.17.95-.47 1.33l-2.54 3.16h3"/><rect pid="1" width="12" height="16.5" x=".75" y="6.75" rx="1"/><path pid="2" d="M3.75 18.75h1.5M8.25 18.75h1.5M18 3.75h.75-.01c.41-.01.75.33.75.75v5.25M18 9.75h3"/><path pid="3" d="M15.75 17.25h6-.01c.82 0 1.5-.68 1.5-1.5V2.25v0c0-.83-.68-1.5-1.5-1.5h-9.01c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v1.5M15.75 12.75h.75M18.75 12.75h1.5"/></g>'
  }
})
