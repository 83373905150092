<template>
    <div>
        <div v-for="option in question.options" :key="option.id">
            <input
                :checked="isOptionSelected(option)"
                type="checkbox"
                @input="toggleOption(option)"
            >

            <template v-if="option.type === 'other' && isOptionSelected(option)">
                {{ option.title }}:
                <input
                    class="px-1 border"
                    type="text"
                    :value="findAnswerByOption(option).custom"
                    @input="updateCustomValue($event.target.value)"
                >
            </template>

            <template v-else>{{ getFormattedAnswer(option) }}</template>
        </div>
    </div>
</template>

<script>
import CustomQuestionEditComponent from '@/mixins/CustomQuestionEditComponent';

export default {
    name: 'CustomQuestionAnswerCheckbox',

    mixins: [CustomQuestionEditComponent],

    data () {
        return {
            newAnswers: this.parseExistingAnswers()
        };
    },

    computed: {
        otherOption () {
            return this.question.options.find((option) => {
                return option.type === 'other';
            });
        }
    },

    watch: {
        newAnswers (newValue) {
            this.$emit('input', newValue);
        }
    },

    methods: {
        hasResponse () {
            return this.answers != null && this.answers.length > 0 && this.answers[0].value != null;
        },

        deselectOption (option) {
            this.newAnswers = this.newAnswers.filter((answer) => {
                return answer.option !== option.id;
            });
        },

        findAnswerByOption (option) {
            return this.newAnswers.find((answer) => {
                return answer.option === option.id;
            });
        },

        getFormattedAnswer (option) {
            const matchingAnswer = this.findAnswerByOption(option);

            if (matchingAnswer != null) {
                return matchingAnswer.formattedText || option.title;
            }

            return option.title;
        },

        isOptionSelected (option) {
            return this.newAnswers.some((answer) => {
                return answer.option === option.id;
            });
        },

        parseExistingAnswers () {
            if (!this.hasResponse()) {
                return [];
            }

            return this.answers.map((existingAnswer) => {
                return {
                    formattedText: existingAnswer.value,
                    option: existingAnswer.id,
                    custom: existingAnswer.raw_value
                };
            });
        },

        selectOption (option) {
            this.newAnswers = [
                ...this.newAnswers,
                { option: option.id, custom: null }
            ];
        },

        toggleOption (option) {
            if (this.isOptionSelected(option)) {
                this.deselectOption(option);
            } else {
                this.selectOption(option);
            }
        },

        updateCustomValue (newValue) {
            const primaryAnswers = this.newAnswers.filter((answer) => {
                return answer.option !== this.otherOption.id;
            });

            this.newAnswers = [
                ...primaryAnswers,
                { option: this.otherOption.id, custom: newValue }
            ];
        }
    }
};
</script>
