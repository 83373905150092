/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'breakfast-cereal-bowl-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food soup</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M20.25 8.19c1.86.8 3 1.87 3 3.05 0 2.48-5.04 4.5-11.25 4.5-6.22 0-11.25-2.02-11.25-4.5 0-2.49 5.037-4.5 11.25-4.5"/><path pid="1" d="M.75 11.25v1.5c0 5.8 5.037 10.5 11.25 10.5 6.21 0 11.25-4.7 11.25-10.5v-1.5"/><path pid="2" d="M19.5 12.75c0 1.657-3.36 3-7.5 3-4.15 0-7.5-1.343-7.5-3 0-1.343 2.2-2.48 5.25-2.87M8.25 12.75h1.5M16.35 8.7L22.5.75"/><path pid="3" d="M16.642 12h-.01c.72-1.03.6-2.43-.3-3.3-1-.75-2.606-.28-3.6 1.05v-.01c-.51.64-.77 1.43-.75 2.25"/></g>'
  }
})
