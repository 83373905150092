/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'globe-web-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>earth planet internet network</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/><path pid="1" d="M9.289 22.921c-1.53-2.24-2.54-6.29-2.54-10.921 0-4.64 1.01-8.689 2.53-10.921M.75 12h22.5M2.48 18h19.04M2.48 6h19.04M14.71 1.079c1.52 2.23 2.53 6.28 2.53 10.921 0 4.63-1.02 8.689-2.54 10.921"/></g>'
  }
})
