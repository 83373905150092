<template>
    <div class="my-8">
        <form-field-wrapper
            label="Allowed File Types"
            :error="form.errors.get('question.settings.fileUploads.allowedFileTypes')"
            tooltip="Separate allowed file types with a comma. Leave blank to allow invitees to upload ANY file type."
        >
            <textarea
                :value="settings.allowedFileTypes"
                placeholder="png, gif, jpg"
                class="form-field"
                rows="2"
                @input="newAllowedFileTypes => updateSetting('allowedFileTypes', newAllowedFileTypes.target.value)"
            ></textarea>
        </form-field-wrapper>

        <form-field-wrapper
            label="Limit File Size"
            :error="form.errors.getAny(['question.settings.fileUploads.minimum', 'question.settings.fileUploads.maximum'])"
        >
            <div class="flex w-full">
                <input
                    :value="settings.minimum"
                    class="form-field pr-10 w-24 appearance-no-spinners"
                    type="number"
                    min="0"
                    max="10000"
                    @input="newMinimumFileSize => updateSetting('minimum', parseInt(newMinimumFileSize.target.value))"
                >

                <span class="text-gray-400 absolute text-center items-center justify-center w-8 left-16 py-3">
                    KB
                </span>

                <span class="text-gray-600 uppercase absolute text-center items-center justify-center w-8 left-24 py-3 tracking-tight">
                    Minimum
                </span>
            </div>

            <div class="flex w-full">
                <input
                    :value="settings.maximum"
                    class="form-field pr-10 w-24 appearance-no-spinners"
                    type="number"
                    min="0"
                    max="10000"
                    @input="newMaximumFileSize => updateSetting('maximum', parseInt(newMaximumFileSize.target.value))"
                >

                <span class="text-gray-400 absolute text-center items-center justify-center w-8 left-16 py-3 tracking-tight">
                    KB
                </span>

                <span class="text-gray-600 uppercase absolute text-center items-center justify-center w-8 left-24 py-3 tracking-tight">
                    Maximum
                </span>
            </div>
        </form-field-wrapper>

        <form-field-wrapper
            label="Number of Files"
            :error="form.errors.get('question.settings.fileUploads.maxNumberOfFiles')"
        >
            <div class="flex w-full">
                <input
                    :value="settings.maxNumberOfFiles"
                    class="form-field w-24 appearance-no-spinners"
                    type="number"
                    min="1"
                    max="10"
                    @input="newMaxNumberOfFiles => updateSetting('maxNumberOfFiles', parseInt(newMaxNumberOfFiles.target.value))"
                >

                <span class="text-gray-600 uppercase absolute text-center items-center justify-center w-8 left-24 py-3 tracking-tight">
                    Maximum
                </span>
            </div>
        </form-field-wrapper>
    </div>
</template>

<script>
import CustomQuestionSettings from '@/mixins/CustomQuestionSettings';

export default {
    name: 'FormFileUploadQuestionSettings',

    mixins: [CustomQuestionSettings],

    computed: {
        namespace () {
            return 'fileUploads';
        }
    }
};
</script>
