/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-pandemic-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.88 6.648l-.01-.01a5.35 5.35 0 015.2 5.49 5.36 5.36 0 01-5.21 5.2M12.99 2.63h1.79M13.89 2.63v4.02M19.88 4.75l1.26 1.26M20.51 5.38l-2.84 2.84M23.25 11.11v1.78M23.25 12h-4.01M21.14 17.99l-1.26 1.26M20.51 18.62l-2.84-2.84M14.78 21.36h-1.79M13.89 21.36v-4.01M10.25 21.5l-.01-.001c-5.25-.01-9.5-4.26-9.5-9.51s4.25-9.5 9.5-9.5z"/><path pid="1" d="M10.25 2.5v-.01a10.901 10.901 0 00-4.75 9.49h-.01c-.17 3.77 1.63 7.36 4.75 9.49M10.25 8.93H1.66M10 15.07H1.41"/></g>'
  }
})
