/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-champagne-bottle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>bar alcohol bubbly prosecco celebration mimosa</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15 10.125l-.01-.01c-1-2.73-1.5-5.62-1.5-8.52v-.12 0c0-.42-.34-.75-.75-.75h-1.51c-.42 0-.75.33-.75.75 0 0 0 0 0 0v.11-.01c0 2.9-.51 5.78-1.5 8.51h-.02c-1 2.72-1.5 5.61-1.5 8.51v3.11h0c0 .82.67 1.49 1.5 1.49 0 0 0 0 0 0h6-.01c.82 0 1.5-.68 1.5-1.5v-3.12h0c0-2.91-.51-5.79-1.5-8.52z"/><path pid="1" d="M15.46 11.53h-.01a5.576 5.576 0 01-6.94-.01M12 16.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM10.41 3.75h3.18"/></g>'
  }
})
