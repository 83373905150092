/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-candy': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sweet</desc><g _fill="currentColor"><path pid="0" d="M18.356 12.66a.5.5 0 00-.578-.544 11.839 11.839 0 01-1.9.154 11.29 11.29 0 01-8.472-3.764.5.5 0 00-.8.063 6.328 6.328 0 00-.551 1.091.5.5 0 00.041.447 9.891 9.891 0 0011.745 3.946.5.5 0 00.317-.339 6.405 6.405 0 00.198-1.054z"/><path pid="1" d="M14.394 16.145a11.287 11.287 0 01-8.3-3.581.25.25 0 00-.431.2 6.388 6.388 0 00.428 1.658.253.253 0 01-.03.242.247.247 0 01-.221.1A9.366 9.366 0 00.492 16.5a1.036 1.036 0 00.334 1.9 1.952 1.952 0 011.739 1.94 1.037 1.037 0 001.1 1.1 1.955 1.955 0 011.938 1.74 1.036 1.036 0 001.9.334 9.368 9.368 0 001.734-5.342.25.25 0 01.344-.25 6.4 6.4 0 007.068-1.531.25.25 0 00-.225-.418 11.727 11.727 0 01-2.03.172zM23.508 7.5a1.035 1.035 0 00-.334-1.9 1.955 1.955 0 01-1.74-1.939 1.034 1.034 0 00-.3-.8 1.022 1.022 0 00-.8-.3A1.943 1.943 0 0118.4.826a1.036 1.036 0 00-1.9-.335 9.351 9.351 0 00-1.733 5.346.25.25 0 01-.344.25 6.414 6.414 0 00-5.9.55.5.5 0 00-.223.348.507.507 0 00.115.4 9.872 9.872 0 009.253 3.229.5.5 0 00.387-.652q-.064-.194-.141-.381a.25.25 0 01.25-.344A9.642 9.642 0 0023.508 7.5z"/></g>'
  }
})
