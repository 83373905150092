/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'concert-sing-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>music party microphone</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.25 2.25h3M16.75.75v3M18.25 7.5h3M19.75 6v3M7.52 1.5a4.5 4.5 0 100 9 4.5 4.5 0 100-9zM2.01 4.24l11.02 3.52"/><path pid="1" d="M9.3 10.135l-4.36 9.41h-.01c-.32.68-1.1 1.03-1.82.79l-.34-.11-.01-.01c-.72-.23-1.16-.96-1.02-1.7l1.9-10.192M2.96 20.29l-.91 2.86M14.42 18.54a1.6 1.6 0 100 3.2 1.6 1.6 0 100-3.21zM22 18.423v0c0 .88-.72 1.6-1.6 1.6-.89 0-1.6-.72-1.6-1.6 0-.89.71-1.6 1.6-1.6h-.01c.88-.01 1.6.71 1.6 1.6 0 0 0 0 0 0z"/><path pid="2" d="M16.03 20.149v-6h-.001c0-.56.35-1.05.87-1.22l3.4-.88v-.01a1.271 1.271 0 011.68 1.21v5.14"/></g>'
  }
})
