/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'conversation-chat-text-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>meeting network group users message team</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25 18l-3 2.25v-4.5h-3l-.01-.001a1.51 1.51 0 01-1.5-1.5V2.238c0-.83.67-1.5 1.5-1.5h16.5-.01c.82-.01 1.5.67 1.5 1.5 0 0 0 0 0 0v4.5M5.25 5.25h10.5M5.25 9.75h3"/><path pid="1" d="M23.25 18.75h-3v4.5l-4.5-4.5h-4.5v-9h12v9zM20.25 12.75h-6M20.25 15.75h-6"/></g>'
  }
})
