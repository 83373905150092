/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-mobile-application-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.75 3.6a3.14 3.14 0 100 6.28 3.14 3.14 0 100-6.29zM17.23 1.25h1.04M17.75 1.25v2.36M21.27 2.49l.74.74M21.64 2.86l-1.67 1.67M23.25 6.23v1.04M23.25 6.75h-2.36M22.01 10.27l-.74.74M21.64 10.64l-1.67-1.67M18.27 12.25h-1.04M17.75 12.25V9.89M14.23 11.01l-.74-.74M13.86 10.64l1.67-1.67M12.25 7.27V6.23M12.25 6.75h2.36M13.49 3.23l.74-.74M13.86 2.86l1.67 1.67M7.5 15l1.673-1.26h-.01c.69-.52 1.67-.38 2.19.31.01.02.03.04.05.07v0h0c.35.52.35 1.21-.01 1.74l-1.65 2.46h-.01c-.51.75-1.23 1.34-2.08 1.68l-2.45.98M8.25 9.75l-3.6 1.54h-.01c-1.07.45-1.91 1.3-2.37 2.36l-1.18 2.74v-.01c-.25.56-.37 1.16-.37 1.77v5.07M23.25 14.25V21c0 1.24-1.01 2.25-2.25 2.25H10.5l-.01-.001a2.256 2.256 0 01-2.25-2.26s0 0 0 0v-1.25"/><path pid="1" d="M8.25 14.437V3h0A2.24 2.24 0 0110.49.74h1.75"/></g>'
  }
})
