<template>
    <div class="w-full h-full">
        <button
            class="h-full w-full flex flex-col p-4 bg-white ring-2 ring-transparent shadow-md cursor-pointer rounded-md text-left text-gray-800 transition duration-200 ease-in-out hover:ring-purple hover:shadow-lg"
            @click="openModal"
        >
            <div class="inline-flex mb-4">
                <div class="rounded-full bg-purple-100 p-3">
                    <app-icon
                        name="user-circle"
                        class="h-10 w-10 text-purple"
                        stroke
                    ></app-icon>
                </div>
            </div>
            <div class="text-sm font-medium uppercase trackiner-widest text-gray-500 mb-2">Impersonate User</div>
            <p class="text-xl font-light">Sign in to a user's account to troubleshoot issues</p>
        </button>

        <app-modal v-model="showModal" title="Impersonate User">
            <form-field-wrapper
                label="Email Address"
                :should-show-error="form.errors.has('email')"
                :error="form.errors.get('email')"
            >
                <input
                    ref="email"
                    v-model="form.email"
                    class="form-field"
                    placeholder="user@domain.com"
                    @keyup.enter="impersonateUser"
                >
            </form-field-wrapper>

            <template #footer="{ close }">
                <stateful-button
                    :loading="form.processing"
                    class="button button-primary"
                    @click="impersonateUser"
                >
                    Sign In as User
                </stateful-button>

                <button class="button" @click="close">
                    Cancel
                </button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import Form from '@/validation/Form';

export default {
    name: 'ImpersonateUser',

    data () {
        return {
            form: new Form({
                email: ''
            }),
            showModal: false
        };
    },

    computed: {
        isFormValid () {
            return !Object.keys(this.fields).some((key) => {
                return this.fields[key].invalid;
            });
        }
    },

    methods: {
        impersonateUser () {
            this.form.post(this.route('admin.impersonate-user'))
                .then(() => {
                    window.location.href = this.route('dashboard');
                });
        },

        openModal () {
            this.form.restore();
            this.showModal = true;

            this.waitTicks(3).then(() => {
                this.$refs.email.focus();
            });
        }

    }
};
</script>
