<template>
    <div>
        <section class="panel">
            <div class="flex flex-col md:flex-row justify-center items-center mb-2">
                <div class="flex flex-auto items-center">
                    <div class="flex rounded-full bg-teal-light p-2">
                        <app-icon
                            name="gift"
                            class="w-5 h-5 text-teal"
                            stroke
                        ></app-icon>
                    </div>

                    <p class="uppercase font-medium text-gray-600 ml-4">Gifts &amp; Donations</p>
                </div>

                <p class="text-gray-600 text-2xl font-bold mt-4 md:mt-0">{{ totalDonations }}</p>
            </div>

            <money-chart :data="chartData"></money-chart>

            <p class="hidden md:block mt-2 text-sm text-gray-600">Showing Last 30 Days</p>
        </section>

        <dashboard-donations-table></dashboard-donations-table>
    </div>
</template>

<script>
export default {
    name: 'DashboardDonations',

    props: {
        chartData: {
            type: Object,
            required: true
        },
        totalDonations: {
            type: String,
            required: true
        }
    }
};
</script>
