<template>
    <div v-if="loading" class="flex justify-center">
        <app-icon
            class="h-12 w-12 text-gray-500 fill-current"
            name="loader"
        ></app-icon>
    </div>

    <div v-else>
        <template v-if="isAddFormVisible">
            <h3 class="text-xl mb-4">Add Invoice</h3>

            <invoice-form
                :user="user"
                class="mb-4"
                @cancel="isAddFormVisible = false"
                @save-invoice="addNewInvoice"
            ></invoice-form>
        </template>

        <template v-else>
            <div class="flex justify-end mb-4">
                <search-field v-model="search" class="mr-4"></search-field>

                <div class="flex-1"></div>

                <button class="button button-primary" @click="isAddFormVisible = true">Add Invoice</button>
            </div>

            <accordion-list v-model="active">
                <accordion-list-item
                    v-for="invoice in filteredInvoices"
                    :key="invoice.id"
                    :item-id="invoice.id"
                    class="mb-2 p-2 border rounded-md"
                >
                    <template #header="{ active }">
                        <div class="w-full mr-2 mb-4 font-normal" :class="{ 'border-b': active }">
                            <p class="mb-2">
                                {{ invoice.invoiced_at | datetime('DATE_FULL') }} - {{ invoice.amount | currency(true) }}
                            </p>

                            <p class="text-gray-500 text-sm">{{ invoice.description }}</p>
                        </div>
                    </template>

                    <template #content>
                        <invoice-form
                            :initial-invoice="invoice"
                            :user="user"
                            @delete-invoice="deleteInvoice(invoice)"
                            @save-invoice="updatedInvoice => updateExistingInvoice(invoice, updatedInvoice)"
                        ></invoice-form>
                    </template>
                </accordion-list-item>
            </accordion-list>
        </template>
    </div>
</template>

<script>
import axios from '@/util/axios';
import DateTimeFilter from '@/filters/DateTimeFilter';
import CurrencyFilter from '@/filters/CurrencyFilter';

export default {
    name: 'ManageUserBillingHistoryTab',

    props: {
        user: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            active: [],
            invoices: [],
            isAddFormVisible: false,
            loading: true,
            search: ''
        };
    },

    computed: {
        filteredInvoices () {
            const regex = new RegExp(this.search, 'i');

            return this.invoices.filter((invoice) => {
                for (const field of this.getFilterableFields(invoice)) {
                    if (regex.test(field)) {
                        return true;
                    }
                }

                return false;
            });
        }
    },

    created () {
        axios.get(this.route('api.admin.invoices.index', this.user))
            .then(({ data }) => {
                this.invoices = data.data;
                this.loading = false;
            });
    },

    methods: {
        addNewInvoice (invoice) {
            this.invoices = [
                invoice,
                ...this.invoices
            ];

            this.isAddFormVisible = false;
        },

        deleteInvoice (invoice) {
            this.invoices = this.invoices.filter((currentInvoice) => {
                return currentInvoice !== invoice;
            });
        },

        getFilterableFields (invoice) {
            return Object.values({
                ...invoice,
                amount: CurrencyFilter(invoice.amount, true),
                created_at: DateTimeFilter(invoice.created_at, 'DATE_FULL')
            });
        },

        updateExistingInvoice (invoice, updatedInvoice) {
            const index = this.invoices.findIndex((currentInvoice) => {
                return currentInvoice.id === invoice.id;
            });

            this.$set(this.invoices, index, updatedInvoice);
        }
    }
};
</script>
