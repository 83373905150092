/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-share-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>event date time appointment</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.5 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM21 18.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM21 11.25a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM15.43 16.84l3.65-2.19M15.59 18.84l3.32 1.33M8.25 17.25H2.24a1.51 1.51 0 01-1.5-1.5v-12h0c-.01-.83.67-1.51 1.49-1.51h13.5-.01c.82-.01 1.5.67 1.5 1.5v4.48"/></g><g stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="1" d="M.75 6.75h16.5"/><g stroke-linecap="round"><path pid="2" d="M5.24 3.75v-3M12.74 3.75v-3"/></g></g>'
  }
})
