<template>
    <div class="flex flex-col space-y-8">
        <div class="w-full max-w-sm mx-auto">
            <search-field
                id="field_search"
                :value="searchQuery"
                small
                @input="setSearchQuery"
            ></search-field>
        </div>

        <fetch-infinite
            v-slot="{ items, loading, loadNextPage }"
            :source="templateRoute"
            @reset="infiniteLoadingId = +new Date()"
        >
            <div>
                <template-grid
                    v-if="items.length > 0"
                    :templates="items"
                    @select="selectTemplate"
                ></template-grid>

                <div
                    v-else-if="!loading"
                    id="text_zero_results"
                    class="text-center text-gray-700 text-xl tracking-wide"
                >
                    We couldn't find any templates matching your criteria.
                </div>

                <infinite-loading :identifier="infiniteLoadingId" @infinite="loadNextPage">
                    <template #no-more>&nbsp;</template>
                    <template #no-results>&nbsp;</template>

                    <template #spinner>
                        <div class="text-center m-2">
                            <app-icon class="h-6 w-6 text-gray-500" name="loader"></app-icon>
                        </div>
                    </template>
                </infinite-loading>
            </div>
        </fetch-infinite>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import { get, sync } from 'vuex-pathify';
import debounce from 'lodash/debounce';
import { intersectionBy } from 'lodash';

export default {
    name: 'EventTemplateStep',

    components: {
        InfiniteLoading
    },

    data () {
        return {
            infiniteLoadingId: +new Date(),
            searchQuery: ''
        };
    },

    computed: {
        name: get('CreateEvent/name'),
        primaryEventTypeId: get('CreateEvent/primary_event_type_id'),
        secondaryEventTypeIds: get('CreateEvent/secondary_event_type_ids'),
        eventTemplateId: sync('CreateEvent/event_template_id'),

        templateRoute () {
            return this.route('api.event-templates.index', {
                filters: {
                    event_type_ids: [this.primaryEventTypeId, ...this.secondaryEventTypeIds],
                    search: this.searchQuery,
                    eventName: this.name
                }
            });
        }
    },

    methods: {
        isTemplateAvailable (template) {
            if (!this.auth().user()?.plan) {
                return true;
            }

            return intersectionBy(
                this.auth().user().plan.eventTypes,
                template.eventTypes,
                'id'
            ).length > 0;
        },

        selectTemplate (template) {
            if (!this.isTemplateAvailable(template)) {
                App.alert().paymentRequired(
                    'Template not included.',
                    'Sorry, this template isn’t available with your current plan. Please upgrade or contact RSVPify’s passionate support team.',
                    { cancelButtonText: 'Go Back' }
                );

                return;
            }

            this.eventTemplateId = template.id;
            this.$emit('completed');
        },

        setSearchQuery: debounce(function (searchQuery) {
            this.searchQuery = searchQuery;
        }, 500)
    }
};
</script>
