/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'breakfast-english': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food egg bacon sausage</desc><g _fill="currentColor"><path pid="0" d="M14.088 15.183a3.991 3.991 0 002.4-4.038 4.118 4.118 0 00-4.159-3.637H7.245a7.165 7.165 0 00-7.186 6.078 7 7 0 1013.889 1.782.23.23 0 01.14-.185zM12 14.474a5 5 0 11-5-4.966h5.5a2 2 0 01.334 3.973 1 1 0 00-.834.993zM10 6.008h11a3 3 0 002.942-3.6 3.08 3.08 0 00-3.06-2.4h-2.168a.25.25 0 00-.176.427l1.492 1.493a.75.75 0 01-1.06 1.061L16.063.082a.247.247 0 00-.176-.074h-2.673a.25.25 0 00-.177.427l1.493 1.493a.75.75 0 01-1.06 1.061L10.562.082a.247.247 0 00-.176-.074h-.268a3.08 3.08 0 00-3.06 2.4A3 3 0 0010 6.008z"/><circle pid="1" cx="7" cy="14.508" r="2.5"/><path pid="2" d="M16.109 20.409a2.827 2.827 0 012.443-3.156 1.819 1.819 0 001.576-2.036 2.81 2.81 0 011.366-2.771.138.138 0 00.031-.23l-.636-.492a.97.97 0 00-.837-.189 2.5 2.5 0 00-1.879 2.743 1.5 1.5 0 01-1.3 1.677 2.5 2.5 0 00-2.164 2.8 1.5 1.5 0 01-.717 1.477 1.009 1.009 0 00-.491.8.99.99 0 00.384.842s.48.367.6.462a.266.266 0 00.252.064 1.817 1.817 0 001.372-1.991z"/><path pid="3" d="M23.344 13.625s-.571-.445-.628-.486a.36.36 0 00-.224-.041 1.81 1.81 0 00-1.372 1.992 2.826 2.826 0 01-2.441 3.156 1.822 1.822 0 00-1.579 2.036 2.808 2.808 0 01-.568 2.083 2.758 2.758 0 01-.8.694c-.062.035-.14.137 0 .249l.6.467a1 1 0 00.614.217.891.891 0 00.223-.028 2.5 2.5 0 001.879-2.743 1.5 1.5 0 011.3-1.677 2.5 2.5 0 002.165-2.8 1.5 1.5 0 01.715-1.476 1.01 1.01 0 00.493-.8.991.991 0 00-.377-.843z"/></g>'
  }
})
