/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-lab-research-test-tube-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17 13.82a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM16.43 11.25h1.14M17 11.25v2.57M20.84 12.6l.81.81M21.24 13.01l-1.82 1.82M23 16.68v1.14M23 17.25h-2.57M21.65 21.09l-.81.81M21.24 21.49l-1.82-1.82M17.57 23.25h-1.14M17 23.25v-2.57M13.16 21.9l-.81-.81M12.76 21.49l1.82-1.82M11 17.82v-1.14M11 17.25h2.57M12.35 13.41l.81-.81M12.76 13.01l1.82 1.82M9.549 22.25h-.01c-1.52 1.4-3.9 1.31-5.3-.2-.65-.7-1.01-1.61-1.01-2.56V.74h7.5v9.5M13 .75H1"/><path pid="1" d="M8.125 8.25h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M7.75 8.625h0c-.01-.21.16-.38.37-.38M8.125 9h-.01a.386.386 0 01-.38-.38s0 0 0 0M8.5 8.625v0c0 .2-.17.375-.375.375M5.875 12.75h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M5.5 13.125h0c-.01-.21.16-.38.37-.38M5.875 13.5h-.01a.386.386 0 01-.38-.38s0 0 0 0M6.25 13.125v0c0 .2-.17.375-.375.375M7.375 17.25h-.01c.2-.01.37.16.37.37"/><path pid="4" d="M7 17.625h0c-.01-.21.16-.38.37-.38M7.375 18h-.01a.386.386 0 01-.38-.38s0 0 0 0M7.75 17.625v0c0 .2-.17.375-.375.375M10.75 5.25h-7.5"/></g>'
  }
})
