<template>
    <div class="pt-2 pl-2">
        <decision-input
            v-for="option in visibleOptions"
            :key="option.id"
            :class="getDecisionInputStyle(option)"
            :title="option.title"
            :price="getOptionPrice(option)"
            :icon="option.settings.icon"
            :description="option.settings.description"
            :value="value === option.id"
            :availability-message="getProductAvailabilityMessage(option)"
            @input="handleOptionSelected(option)"
        >
            <button
                v-if="!editing"
                v-theme="'form.accented-text'"
                class="button-text text-sm font-normal"
                @click.stop="editing = true"
            >
                {{ $t('button-edit-selection') }}
            </button>
        </decision-input>
    </div>
</template>

<script>
import filter from 'lodash/filter';

export default {
    name: 'SecondaryEventOptionSelection',

    provide: {
        isMultipleChoice: false
    },

    props: {
        options: {
            type: Array,
            required: true
        },

        value: {
            type: Number,
            default: null
        }
    },

    data () {
        return {
            editing: true
        };
    },

    computed: {
        visibleOptions () {
            if (this.editing) {
                return filter(this.options, { active: true });
            }

            return this.options.filter(({ id }) => {
                return this.value === id;
            });
        }
    },

    methods: {
        getDecisionInputStyle (option) {
            return {
                'mb-4 last:mb-0': true,
                'opacity-25': !this.isProductAvailable(option)
            };
        },

        getOptionPrice (option) {
            return option.settings.costsMoney ? option.price : null;
        },

        getProductAvailabilityMessage (product) {
            if (this.isProductAvailable(product)) {
                return '';
            }

            const availabilitySettings = product.settings.maxCapacity || {};
            return availabilitySettings.showMessage ? availabilitySettings.message : '';
        },

        handleOptionSelected (option) {
            if (!this.editing || !this.isProductAvailable(option)) {
                return;
            }

            this.editing = false;

            this.$emit('input', Number(option.id));
        },

        isProductAvailable (product) {
            const availabilitySettings = product.settings.maxCapacity || {};

            if (!availabilitySettings.enabled) {
                return true;
            }

            return product.quantity_remaining !== 0;
        }
    }
};
</script>
