<template>
    <app-drawer
        :value="isDrawerOpen"
        :outside-click-handler="closeWithoutSaving"
        :default-close-handler="closeWithoutSaving"
    >
        <template slot="title-bar">
            <app-icon
                name="color-brush"
                class="h-6 w-6 text-gray-600"
                stroke
            ></app-icon>
            <div class="text-gray-900 font-bold text-lg">Form Theme</div>
        </template>

        <div>
            <form-field-wrapper label="Background">
                <simple-picker
                    v-model="form.settings.backgroundType"
                    :items="backgroundPickerOptions"
                    item-label="label"
                    item-value="id"
                ></simple-picker>

                <image-manager
                    v-if="form.settings.backgroundType === 'image'"
                    add-image-text="Add a Background Image"
                    class="mt-4"
                    :max-images="1"
                    :settings="{}"
                    title="Upload Background Image"
                    uploader-classes=""
                    :value="backgroundImageManagerArray"
                    @input="backgroundImageUploaded"
                >
                    <template #recommendations>
                        PNG and JPG files allowed. Max file size: 2 MB.<br>
                        Form Background Size Recommendation: 1290 px wide x 1830 px tall.
                    </template>
                </image-manager>

                <div v-else-if="form.settings.backgroundType === 'color'" class="mt-4">
                    <color-picker v-model="pageBackgroundColor" :has-alpha="false"></color-picker>
                </div>
            </form-field-wrapper>

            <form-field-wrapper>
                <template #label>
                    <div class="flex items-center">
                        <span>Logo</span>
                        <premium-feature feature="CustomLogo" class="ml-2"></premium-feature>
                    </div>
                </template>

                <image-manager
                    add-image-text="Add a Logo"
                    class="mt-4"
                    conversion="none"
                    :max-images="1"
                    :settings="{}"
                    title="Upload Logo"
                    uploader-classes=""
                    :value="logoImageManagerArray"
                    @input="logoImageUploaded"
                >
                    <template #recommendations>
                        PNG and JPG files allowed. Max file size: 2 MB.<br>
                        Logo Size Recommendation: 600 px wide.
                    </template>
                </image-manager>
            </form-field-wrapper>

            <form-field-wrapper v-if="logoImage" label="Logo Size">
                <logo-size-picker v-model="logoSize"></logo-size-picker>
            </form-field-wrapper>

            <form-field-wrapper label="Colors">
                <div class="flex justify-around">
                    <div class="w-1/5 flex flex-col items-center">
                        <color-picker v-model="accentColor" :has-alpha="false"></color-picker>
                        <div class="text-gray-500 mt-4 text-sm">Accent</div>
                    </div>

                    <div class="w-1/5 flex flex-col items-center">
                        <color-picker v-model="textColor" :has-alpha="false"></color-picker>
                        <div class="text-gray-500 mt-4 text-sm">Text</div>
                    </div>

                    <div class="w-1/5 flex flex-col items-center">
                        <color-picker v-model="backgroundColor" @input="changeBackgroundColor"></color-picker>
                        <div class="text-gray-500 mt-4 text-sm">Background</div>
                    </div>

                    <div class="w-1/5 flex flex-col items-center">
                        <color-picker v-model="buttonColor" :has-alpha="false"></color-picker>
                        <div class="text-gray-500 mt-4 text-sm">Button</div>
                    </div>

                    <div class="w-1/5 flex flex-col items-center text-center">
                        <color-picker v-model="buttonTextColor" :has-alpha="false"></color-picker>
                        <div class="text-gray-500 mt-4 text-sm">Button Text</div>
                    </div>
                </div>
            </form-field-wrapper>

            <form-field-wrapper label="Title Font">
                <font-picker v-model="titleFont"></font-picker>
            </form-field-wrapper>

            <form-field-wrapper label="Body Font">
                <font-picker v-model="bodyFont"></font-picker>
            </form-field-wrapper>

            <form-field-wrapper>
                <template #label>
                    <div class="flex items-center">
                        <span>Custom CSS</span>
                        <premium-feature feature="CustomCss" class="ml-2"></premium-feature>
                    </div>
                </template>

                <theme-drawer-css-editor v-model="customCss"></theme-drawer-css-editor>
            </form-field-wrapper>
        </div>

        <portal to="app-drawer-footer">
            <footer-save-cancel
                confirm-button-text="Save"
                :processing="saving"
                @cancel="closeWithoutSaving"
                @save="saveSettings"
            ></footer-save-cancel>
        </portal>
    </app-drawer>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import axios from '@/util/axios';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';

export default {
    name: 'FormThemeDrawer',

    mixins: [GeneratesUniqueKey],

    props: {
        isDrawerOpen: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            originalSettings: null,
            saving: false
        };
    },

    computed: {
        ...get('Form/*'),

        ...sync('Form/form@settings', {
            backgroundImage: 'background_url',
            logoImage: 'logo_url',
            emailLogoImage: 'email_logo_url'
        }),

        ...sync('Form/form@settings.theme.logo', {
            logoSize: 'size'
        }),

        ...sync('Form/form@settings.theme.colors', {
            pageBackgroundColor: 'pageBackground',
            backgroundColor: 'background',
            backgroundDropdownColor: 'dropdownBackground',
            accentColor: 'accent',
            textColor: 'text',
            buttonColor: 'button',
            buttonTextColor: 'buttonText'
        }),

        ...sync('Form/form@settings.theme.fonts', {
            titleFont: 'title',
            bodyFont: 'body'
        }),

        customCss: sync('Form/form@settings.theme.customCss'),

        backgroundPickerOptions () {
            return [
                { id: 'image', label: 'Image' },
                { id: 'color', label: 'Color' }
            ];
        },

        backgroundImageManagerArray () {
            return this.backgroundImage
                ? [{ id: 1, src: this.backgroundImage }]
                : [];
        },

        logoImageManagerArray () {
            return this.logoImage
                ? [{ id: 1, src: this.logoImage }]
                : [];
        },

        currentSettings () {
            return {
                backgroundImage: this.backgroundImage,
                logoImage: this.logoImage,
                logoSize: this.logoSize,
                backgroundColor: this.backgroundColor,
                backgroundDropdownColor: this.backgroundDropdownColor,
                accentColor: this.accentColor,
                textColor: this.textColor,
                buttonColor: this.buttonColor,
                buttonTextColor: this.buttonTextColor,
                titleFont: this.titleFont,
                bodyFont: this.bodyFont,
                customCss: this.customCss
            };
        },

        isUnmodified () {
            return isEqual(this.currentSettings, this.originalSettings);
        }
    },

    watch: {
        isDrawerOpen () {
            this.customCss = isEmpty(this.customCss) ? '' : this.customCss;
            this.originalSettings = this.currentSettings;
        }
    },

    methods: {
        changeBackgroundColor (value) {
            this.backgroundColor = value;
            this.backgroundDropdownColor = value.substring(0, 7);
        },

        logoImageUploaded (images) {
            if (images.length > 0) {
                const key = this.getUniqueKey();

                this.logoImage = `${images[0].src.original}?id=${key}`;
                this.emailLogoImage = `${images[0].src.email}?id=${key}`;
            } else {
                this.logoImage = null;
                this.emailLogoImage = null;
            }
        },

        backgroundImageUploaded (images) {
            if (images.length > 0) {
                this.backgroundImage = `${images[0].src}?id=${this.getUniqueKey()}`;
            } else {
                this.backgroundImage = null;
            }
        },

        emitUpdated () {
            this.$emit('update-settings');
        },

        cancel () {
            this.emitUpdated();
        },

        closeWithoutSaving () {
            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            const onConfirm = () => {
                this.restoreOriginalSettings();
                this.cancel();
            };

            const onCancel = () => {
                this.$emit('keep-editing');
            };

            if (this.isUnmodified) {
                onConfirm();
            } else {
                App.alert().confirm(
                    'Are you sure?',
                    `The changes you've made haven't been saved. Are you sure you want to leave without saving your changes?`,
                    attributes,
                    onConfirm,
                    onCancel
                );
            }
        },

        restoreOriginalSettings () {
            Object.assign(this, this.originalSettings);
        },

        saveSettings () {
            this.saving = true;

            const route = this.route('api.forms.settings.update', {
                form: this.form
            });

            axios.patch(route, this.form).then(() => {
                this.emitUpdated();
                this.$store.commit('Form/touch');

                this.originalSettings = this.currentSettings;
                this.$toasted.global.success('Form theme has been updated.');
            }).catch(() => {
                this.$toasted.global.error('There was a problem saving your form theme.');
            }).finally(() => {
                this.saving = false;
            });
        }
    }
};
</script>
