import castArray from 'lodash/castArray';
import startsWith from 'lodash/startsWith';
import flatten from 'lodash/flatten';
import trim from 'lodash/trim';
import find from 'lodash/find';
import keys from 'lodash/keys';
import isArray from 'lodash/isArray';

export default class Errors {
    /**
     * Create a new Errors instance.
     */
    constructor (errors = {}) {
        this.errors = errors;
    }

    /**
     * Determine if an errors exists for the given field.
     *
     * @param {string} field
     *
     * @return {boolean}
     */
    has (field) {
        /**
         * Contains wildcards, so use RegExp to see if we have an error matching
         * the error path
         */
        if (field.indexOf('*') !== -1) {
            return !!this.getByPath(field);
        }

        return !!this.errors[field];
    }

    /**
     * Determine if an error exists for any of the given fields.
     *
     * @param {string[]} fields
     *
     * @return {boolean}
     */
    hasAny (fields) {
        return fields.reduce((accumulator, field) => {
            return accumulator || this.has(field);
        }, false);
    }

    /**
     * Determine if we have any errors.
     *
     * @return {boolean}
     */
    any () {
        return Object.keys(this.errors).length > 0;
    }

    /**
     * Get all errors as an array.
     *
     * @return {array}
     */
    all () {
        return flatten(Object.values(this.errors));
    }

    /**
     * Retrieve the error message for a field.
     *
     * @param {string} field
     *
     * @return {string|null}
     */
    get (field) {
        if (this.errors[field]) {
            return this.errors[field][0];
        }

        return null;
    }

    /**
     * Retrieve the error message for a field specified by a dot-notated path
     * (supporting wildcards).
     *
     * @param {string} path
     *
     * @return {string|null}
     */
    getByPath (path) {
        const regExp = new RegExp(
            path
                .replace(/\./g, '\\.')
                .replace(/\*/g, '.*?')
        );

        const errorKey = find(keys(this.errors), (errorPath) => {
            return errorPath.match(regExp) !== null;
        });

        if (errorKey) {
            const error = this.errors[errorKey];

            if (isArray(error)) {
                return error[0];
            }

            return error;
        }

        return null;
    }

    /**
     * Retrieve a single error message for any of the fields.
     *
     * @param {string[]} fields
     *
     * @return {string|null}
     */
    getAny (fields) {
        const errors = fields
            .map((field) => {
                return this.get(field);
            })
            .filter((field) => {
                return !!field;
            });

        return errors.length === 0 ? null : errors[0];
    }

    /**
     * Record the new errors.
     *
     * @param {object} errors
     */
    record (errors) {
        this.errors = errors;
    }

    /**
     * Clear one, multiple or all error messages.
     *
     * @param {string|string[]} fields
     */
    clear (fields = []) {
        const fieldsArray = castArray(fields);

        if (fieldsArray.length === 0) {
            this.errors = {};
            return;
        }

        fieldsArray.forEach((field) => {
            delete this.errors[field];
        });
    }

    /**
     * Create a new error bag of errors under the given prefix.
     *
     * @param {string} prefix
     *
     * @return {Errors}
     */
    only (prefix) {
        const matches = Object.entries(this.errors)
            .filter(([field]) => {
                return startsWith(field, prefix);
            })
            .map(([field, value]) => {
                return [
                    trim(field.substring(prefix.length), '.'),
                    value
                ];
            });

        return new Errors(Object.fromEntries(matches));
    }
}
