<template>
    <div>
        <input
            v-model="answer.streetAddress"
            placeholder="Street Address"
            class="form-field"
        >

        <input
            v-if="question.settings.donorInformation.includeSecondStreetAddressField"
            v-model="answer.secondStreetAddress"
            placeholder="Street Address 2"
            class="form-field mt-4"
        >

        <div class="flex space-x-2 mt-4">
            <input
                v-if="question.settings.donorInformation.includeCityField"
                v-model="answer.city"
                placeholder="City"
                class="w-1/2 form-field"
            >

            <div class="w-1/2">

                <us-states-picker
                    v-if="showStatesPicker"
                    v-model="answer.state"
                    placeholder-empty-state="State"
                    placeholder-search="Search State..."
                    class="w-full"
                    item-value="name"
                ></us-states-picker>

                <input
                    v-else
                    v-model="answer.state"
                    placeholder="State"
                    class="w-full form-field"
                >
            </div>
        </div>

        <div class="flex space-x-2 mt-4">
            <input
                v-if="question.settings.donorInformation.includeZipCodeField"
                v-model="answer.zipCode"
                placeholder="Zip Code"
                class="form-field w-1/2"
            >

            <country-picker
                v-if="showCountryPicker"
                v-model="answer.country"
                placeholder-empty-state="Country"
                placeholder-search="Search Country..."
                class="w-1/2"
                item-value="name"
            ></country-picker>
        </div>

        <input
            v-model="answer.phoneNumber"
            placeholder="Phone Number"
            class="form-field mt-4"
        >

        <input
            v-model="answer.occupation"
            placeholder="Occupation"
            class="form-field mt-4"
        >

        <input
            v-model="answer.employer"
            placeholder="Employer"
            class="form-field mt-4"
        >

        <input
            v-model="answer.industry"
            placeholder="Industry"
            class="form-field mt-4"
        >
    </div>
</template>

<script>
import CustomQuestionAnswerMailingAddress from './CustomQuestionAnswerMailingAddress';

export default {
    name: 'CustomQuestionAnswerDonorInformation',

    extends: CustomQuestionAnswerMailingAddress,

    computed: {
        showCountryPicker () {
            return this.question.settings.donorInformation.includeCountryField && this.question.settings.donorInformation.stateField !== 'usStates';
        },

        showStatesPicker () {
            return this.question.settings.donorInformation.stateField === 'usStates';
        }
    }
};
</script>
