/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outdoors-tree-road-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25.75a6 6 0 100 12 6 6 0 100-12zM17.25 17.25v-12M17.25 10.5c0-2.25 1.5-3 2.25-3M17.25 10.5c0-2.25-1.5-3-2.25-3M8.48 4.5l-.01-.01a4.496 4.496 0 00-6.24 1.26 4.486 4.486 0 001.26 6.23c1.7 1.13 3.96.96 5.49-.41M6 17.25v-7.5M.75 17.25h22.5M.75 23.25h22.5M5.25 20.25H9M18.75 20.25H15"/></g>'
  }
})
