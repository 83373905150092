<template>
    <div>
        <div v-if="hasAnyEvents">
            <div class="flex flex-col md:flex-row items-center justify-between space-y-2 space-x-0 md:space-x-6 md:space-y-0">
                <div class="w-full md:w-2/3 lg:w-1/3">
                    <search-field
                        v-model="searchTerm"
                        class="w-full"
                        placeholder="Find your event..."
                    ></search-field>
                </div>

                <div class="justify-end w-full md:w-auto">
                    <create-event-action v-slot="{ createEvent }">
                        <button
                            class="button button-primary w-full md:w-auto"
                            data-cy="create-event"
                            @click="createEvent"
                        >
                            <app-icon name="add-circle" stroke></app-icon>
                            <span>New Event</span>
                        </button>
                    </create-event-action>
                </div>
            </div>

            <tab-list class="mt-6" @selected-tab="tabSwitched">
                <tab-list-item
                    id="current-events"
                    active
                    label="Current"
                >
                    <div v-if="hasAnyCurrentEvents && !pastEventTabActive">
                        <fetch-infinite
                            v-slot="{ items, loadNextPage, itemDeleted }"
                            :source="route('api.dashboard.current-events')"
                            :filter="{name: searchTerm}"
                            @reset="currentEventsInfiniteId = + new Date()"
                        >
                            <div>
                                <div class="mt-6 grid gap-6 max-w-lg mx-auto md:grid-cols-2 lg:grid-cols-3 md:max-w-none">
                                    <div v-for="event in items" :key="event.id">
                                        <dashboard-event-card
                                            :event="event"
                                            :number-of-used-events="numberOfUsedEvents"
                                            @deleted="itemDeleted"
                                        ></dashboard-event-card>
                                    </div>
                                </div>

                                <infinite-loading
                                    ref="currentEventsInfiniteLoading"
                                    :identifier="currentEventsInfiniteId"
                                    @infinite="loadNextPage"
                                >
                                    <div slot="spinner" class="w-full text-center my-4">
                                        <app-icon class="h-6 w-6 text-gray-500" name="loader"></app-icon>
                                    </div>

                                    <div slot="no-more"></div>
                                    <div slot="no-results">
                                        <div class="mt-6 alert alert-warning items-center">
                                            <app-icon name="alert-triangle" class="h-6 w-6 mr-3"></app-icon>
                                            <span>There are no current events matching "{{ searchTerm }}".</span>
                                        </div>
                                    </div>
                                </infinite-loading>
                            </div>
                        </fetch-infinite>
                    </div>
                    <div v-else class="row my-8">
                        <div class="col-12 md:col-7 items-center justify-center">
                            <img
                                class="w-128"
                                :src="asset('images/empty-states/events.svg')"
                                alt="Events empty state"
                            >
                        </div>

                        <div class="col-12 md:col-5 mt-4 md:mt-0 flex-col justify-center">
                            <p class="text-2xl text-center">You don’t have any upcoming events.</p>
                            <p class="text-center italic text-gray-500">Visit the ‘Past’ events tab or create a new event!</p>
                        </div>
                    </div>
                </tab-list-item>

                <tab-list-item
                    id="past-events"
                    label="Past Events"
                >
                    <div v-if="hasAnyPastEvents && pastEventTabActive">
                        <fetch-infinite
                            v-slot="{ items, loadNextPage, itemDeleted }"
                            :source="route('api.dashboard.past-events')"
                            :filter="{name: searchTerm}"
                            @reset="pastEventsInfiniteId = + new Date()"
                        >
                            <div>
                                <div class="mt-6 grid gap-6 max-w-lg mx-auto md:grid-cols-2 lg:grid-cols-3 md:max-w-none">
                                    <div v-for="event in items" :key="event.id">
                                        <dashboard-event-card
                                            :event="event"
                                            :number-of-used-events="numberOfUsedEvents"
                                            @deleted="itemDeleted"
                                        ></dashboard-event-card>
                                    </div>
                                </div>

                                <infinite-loading
                                    ref="pastEventsInfiniteLoading"
                                    :identifier="pastEventsInfiniteId"
                                    @infinite="loadNextPage"
                                >
                                    <div slot="spinner" class="w-full text-center my-4">
                                        <app-icon class="h-6 w-6 text-gray-500" name="loader"></app-icon>
                                    </div>

                                    <div slot="no-more"></div>
                                    <div slot="no-results">
                                        <div class="mt-6 alert alert-warning items-center">
                                            <app-icon name="alert-triangle" class="h-6 w-6 mr-3"></app-icon>
                                            <span>There are no past events matching "{{ searchTerm }}".</span>
                                        </div>
                                    </div>
                                </infinite-loading>
                            </div>
                        </fetch-infinite>
                    </div>
                    <div v-else class="row my-8">
                        <div class="col-12 md:col-7 items-center justify-center">
                            <img
                                class="w-128"
                                :src="asset('images/empty-states/events.svg')"
                                alt="Events empty state"
                            >
                        </div>

                        <div class="col-12 md:col-5 mt-4 md:mt-0 flex-col justify-center">
                            <p class="text-2xl text-center">We didn’t find any past events.</p>
                            <p class="text-center italic text-gray-500">Check your ‘Current’ events tab.</p>
                        </div>
                    </div>
                </tab-list-item>
            </tab-list>
        </div>

        <div v-show="!hasAnyEvents" class="row my-8">
            <div class="col-12 md:col-7 items-center justify-center">
                <img
                    class="w-128"
                    :src="asset('images/empty-states/events.svg')"
                    alt="Events empty state"
                >
            </div>

            <div class="col-12 md:col-5 mt-4 md:mt-0 flex-col justify-center">
                <p class="text-2xl text-center">Welcome to your events dashboard!</p>
                <p class="text-center italic text-gray-500">You don't have any events yet, try creating one!</p>
                <create-event-action v-slot="{ createEvent }">
                    <a
                        ref="createEvent"
                        role="button"
                        class="button button-primary mt-6"
                        data-cy="create-event"
                        @click="createEvent"
                    >Create Event</a>
                </create-event-action>
            </div>
        </div>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
    components: { InfiniteLoading },

    props: {
        hasAnyCurrentEvents: {
            required: true,
            type: Boolean
        },

        hasAnyPastEvents: {
            required: true,
            type: Boolean
        },

        numberOfUsedEvents: {
            required: true,
            type: Number
        }
    },

    data () {
        return {
            searchTerm: '',
            currentEventsInfiniteId: +new Date(),
            pastEventsInfiniteId: +new Date(),
            pastEventTabActive: false
        };
    },

    computed: {
        hasAnyEvents () {
            return this.hasAnyPastEvents || this.hasAnyCurrentEvents;
        }
    },

    mounted () {
        const getParams = new URLSearchParams(window.location.search);

        if (getParams.has('autoCreateEvent')) {
            this.$refs.createEvent.click();
        }
    },

    methods: {
        tabSwitched (tabId) {
            if (tabId === 'past-events') {
                this.pastEventTabActive = true;
                return;
            }

            this.pastEventTabActive = false;
        }
    }
};
</script>
