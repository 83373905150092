<template>
    <div class="slidable-cards">
        <div class="absolute right-0 z-40 mr-10 mt-4">
            <div :id="navContainerId" class="flex">
                <div
                    v-for="index in numberOfCards"
                    :key="index"
                    class="rounded-full p-1 mr-1 bg-gray-200 border"
                ></div>
            </div>
        </div>

        <vue-tiny-slider
            :mouse-drag="true"
            :loop="false"
            :controls="false"
            :responsive="responsiveOptions"
            :nav="true"
            :nav-container="`#${navContainerId}`"
        >
            <slot></slot>
        </vue-tiny-slider>
    </div>
</template>

<script>
import VueTinySlider from 'vue-tiny-slider';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@root/tailwind.config';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';

const tailwind = resolveConfig(tailwindConfig);

export default {
    name: 'SlidableCards',

    components: { VueTinySlider },

    mixins: [GeneratesUniqueKey],

    data () {
        return {
            responsiveOptions: null,
            numberOfCards: null,
            navContainerId: `sliderNavigation-${this.getUniqueKey()}`
        };
    },

    created () {
        this.numberOfCards = this.$slots.default.filter((element) => {
            return element.tag !== undefined;
        }).length;

        this.responsiveOptions = {
            [tailwind.theme.screens.sm.replace('px', '')]: {
                items: 1
            },
            [tailwind.theme.screens.md.replace('px', '')]: {
                items: this.numberOfCards
            }
        };
    }
};
</script>
