<template>
    <div>
        <div class="flex justify-center items-center mb-10">
            <div class="flex items-center px-2 bg-gray-200 border rounded-md">
                <a
                    class="block text-gray-500 text-center py-2 px-4 cursor-pointer transition duration-150 ease-in-out leading-0 hover:text-gray-600"
                    :class="{ 'text-purple hover:text-purple': view === 'browser-window' }"
                    @click="view = 'browser-window'"
                >
                    <app-icon
                        name="technology-computer"
                        class="h-8 w-8"
                        stroke
                    ></app-icon>
                </a>

                <a
                    class="block text-gray-500 text-center py-2 px-4 cursor-pointer transition duration-150 ease-in-out leading-0 hover:text-gray-600"
                    :class="{ 'text-purple hover:text-purple': view === 'mobile-window' }"
                    @click="view = 'mobile-window'"
                >
                    <app-icon
                        name="technology-mobile"
                        class="h-8 w-8"
                        stroke
                    ></app-icon>
                </a>
            </div>
        </div>

        <component
            :is="view"
            :background-color="backgroundColor"
            :body-background-color="bodyBackgroundColor"
        >
            <template v-if="$store.get('Event/event')?.plan?.features?.CampaignEmailBranding?.type !== 'rsvpify'">
                <div
                    v-if="logoUrl"
                    class="py-4"
                    :style="{ 'background-color': backgroundColor }"
                >
                    <img
                        :src="logoUrl"
                        class="mx-auto"
                        :style="styles.logo"
                    >
                </div>
            </template>

            <color-bar
                v-if="accentBarEnabled"
                v-model="eventAccentColor"
                :disabled="readOnly"
            ></color-bar>

            <tiptap-editor
                v-model="editorContent"
                :default-button-options="defaultButtonOptions"
                :default-text-color="defaultTextColor"
                image-type="email"
                show-merge-tags
                show-email-supported-options
                show-checkin-qr-code-button
                :theme-settings="event.form.settings.theme"
                :read-only="readOnly"
                class="email-editor"
                :allow-inserting-custom-button-for-email-options="allowCustomHyperLinksInEmails"
                :allow-links="allowCustomHyperLinksInEmails"
            ></tiptap-editor>

            <div :style="{ 'background-color': backgroundColor }" class="pt-4">
                <p class="text-center text-xs" :style="{ color: footerColors.text }">
                    You are receiving this email because you are invited to <a :style="styles.link" :href="eventUrl">{{ event.name }}</a>.<br>
                    You may <a :style="styles.link" href="#">unsubscribe</a> from emails sent by this event.
                    <span v-if="!hideRsvpifyFooter">
                        <br><br>
                        Create Your Event with
                        <a
                            :style="styles.link"
                            target="_blank"
                            href="https://rsvpify.com"
                            class="font-bold"
                        >RSVPify</a>
                    </span>
                </p>
            </div>
        </component>
    </div>
</template>

<script>
import tinycolor from 'tinycolor2';

export default {
    name: 'EmailEditor',

    props: {
        accentBarEnabled: {
            type: Boolean,
            default: true
        },

        accentColor: {
            type: String,
            default: '#fff'
        },

        backgroundColor: {
            type: String,
            default: '#fff'
        },

        bodyBackgroundColor: {
            type: String,
            default: '#fff'
        },

        defaultButtonOptions: {
            type: Object,
            default: () => { return {}; }
        },

        event: {
            type: Object,
            required: true
        },

        eventUrl: {
            type: String,
            default: ''
        },

        hideRsvpifyFooter: {
            type: Boolean,
            default: false
        },

        logoUrl: {
            type: String,
            default: ''
        },

        logoWidth: {
            type: Number,
            default: 200
        },

        readOnly: {
            type: Boolean,
            default: false
        },

        value: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            editorContent: this.value,
            eventAccentColor: this.accentColor,
            view: 'browser-window'
        };
    },

    computed: {
        allowCustomHyperLinksInEmails () {
            if (this.event.owner.plan.features.AllowCustomHyperLinksInEmails.type === 'always') {
                return true;
            }

            return this.event.owner.is_verified;
        },

        defaultTextColor () {
            return this.event.form.settings.theme.colors.text;
        },

        styles () {
            return {
                link: {
                    color: this.footerColors.link,
                    'text-decoration': 'underline'
                },
                logo: {
                    'max-width': '200px',
                    width: `${this.logoWidth}px`
                }
            };
        },

        footerColors () {
            const textColor = tinycolor
                .mostReadable(
                    this.backgroundColor,
                    [this.backgroundColor],
                    { includeFallbackColors: true }
                );

            const linkColor = textColor.clone();

            if (textColor.isDark()) {
                textColor.lighten(25);
                linkColor.lighten(10);
            } else {
                textColor.darken(25);
                linkColor.darken(10);
            }

            return {
                text: textColor.toHexString(),
                link: linkColor.toHexString()
            };
        }
    },

    watch: {
        accentColor (newVal) {
            this.eventAccentColor = newVal;
        },

        editorContent () {
            this.$emit('input', this.editorContent);
        },

        eventAccentColor (newVal) {
            this.$emit('updated-accent-color', newVal);
        },

        footerColors: {
            deep: true,
            handler (newVal) {
                this.$emit('updated-footer-colors', newVal);
            }
        }
    }
};
</script>
