<template>
    <div v-if="isCreatingEvent" class="flex flex-col space-y-8 mt-16 md:mt-0">
        <div class="flex flex-col space-y-4">
            <h1 class="text-3xl font-semibold text-center">RSVPify is creating your event...</h1>
            <span class="text-xs text-center text-gray-500">You'll be able to customize everything in just a moment.</span>
        </div>

        <event-finalize-step @finalized="({ destination }) => redirect(destination)"></event-finalize-step>
    </div>
</template>

<script>
import EventFinalizeStep from '@/components/create-event/steps/EventFinalizeStep.vue';

export default {
    name: 'FinalizeAuth',

    components: {
        EventFinalizeStep
    },

    props: {
        intendedUrl: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            isCreatingEvent: false
        };
    },

    created () {
        const serializedOnboardingEvent = localStorage.getItem('onboarding.event');

        if (serializedOnboardingEvent === null) {
            this.redirectToDashboard();
            return;
        }

        const onboardingEvent = JSON.parse(serializedOnboardingEvent);

        for (const key in onboardingEvent) {
            this.$store.set(`CreateEvent/${key}`, onboardingEvent[key]);
        }

        localStorage.removeItem('onboarding.event');

        this.isCreatingEvent = true;
    },

    methods: {
        redirect (url) {
            window.location.href = this.intendedUrl ?? url;
        },

        redirectToDashboard () {
            const subdomain = window.location.host.split('.')[0];

            if (subdomain === 'checkin') {
                this.redirect(this.route('check-in.dashboard'));
            } else {
                this.redirect(this.route('dashboard'));
            }
        }
    }
};
</script>
