<template>
    <div>
        <div class="flex flex-col">
            <div class="flex flex-col md:flex-row items-center">
                <div class="flex-1 text-2xl text-gray-600">
                    You're importing
                    <span
                        v-tippy
                        :content="`${totalInviteeLabel} invitees will be imported. Count includes any offered +1’s or additional guests. If a “+” is displayed, at least one invitee in your import is allowed to bring unlimited additional guests.`"
                        class="border-b border-dashed border-gray-500"
                    >
                        <span class="font-bold">{{ totalInviteeLabel }}</span> invitees
                    </span>
                    in
                    <span
                        v-tippy
                        content="Invitees who will be part of the same invitation or reply (i.e. couples, families, teams, etc.). Careful not to group together unassociated invitees!"
                        class="border-b border-dashed border-gray-500"
                    >
                        <span class="font-bold">{{ groupCount | number }}</span> groups
                    </span>
                </div>

                <a :href="route('invite-list.import', importRecord.event_id)" class="button mt-2 md:mt-0 w-full md:w-auto">Cancel</a>

                <stateful-button
                    :loading="form.processing"
                    class="button button-primary mt-2 md:mt-0 ml-0 md:ml-2 w-full md:w-auto"
                    @click="complete"
                >
                    Complete Import
                </stateful-button>
            </div>

            <div class="md:flex md:items-start my-8 py-4 border-t-2 border-b-2">
                <div>
                    <form-field-wrapper label="Event exclusivity">
                        <select-list v-model="form.security">
                            <select-option id="public" class="my-2">
                                Public event
                                <p class="text-sm text-gray-600">Anyone with your event's link may register or RSVP</p>
                            </select-option>

                            <select-option id="private" class="my-2">
                                Invite-only private event
                                <p class="text-sm text-gray-600">Attendees must be on your Invite List to RSVP or register</p>
                            </select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div v-if="!isInviteListEmpty" class="mt-8 md:mt-0 md:ml-20">
                    <form-field-wrapper :label="`${totalInviteeLabel} imported invitees`">
                        <select-list v-model="form.action" @input="selectAction">
                            <select-option
                                id="append"
                                class="my-2"
                                label="Append to existing list"
                            ></select-option>

                            <select-option
                                id="overwrite"
                                class="my-2"
                                label="Overwrite existing list"
                            ></select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>
        </div>

        <send-invitee-campaign-message-modal
            ref="inviteeCampaignMessageModal"
            :event="importRecord.event"
            :import-id="importRecord.id"
            :import-invitee-count="importRecord.metadata.inviteeCount"
            :import-group-count="groupCount"
            :import-unique-email-count="uniqueEmailCount"
        ></send-invitee-campaign-message-modal>
    </div>
</template>

<script>
import get from 'lodash/get';
import Form from '@/validation/Form';

export default {
    name: 'InviteListImportSettings',

    props: {
        groupCount: {
            type: Number,
            required: true
        },

        importRecord: {
            type: Object,
            required: true
        },

        inviteListSettings: {
            type: Object,
            required: true
        },

        isInviteListEmpty: {
            type: Boolean,
            default: false
        },

        totalInviteeLabel: {
            type: String,
            required: true
        },

        uniqueEmailCount: {
            type: Number,
            required: true
        }
    },

    data () {
        return {
            form: new Form({
                action: 'append',
                security: get(this.inviteListSettings, 'security', 'public')
            })
        };
    },

    methods: {
        complete () {
            this.form.post(this.route('api.invite-list.import.complete', this.importRecord))
                .then(() => {
                    this.$refs.inviteeCampaignMessageModal.open()
                        .then(() => {
                            window.location.href = this.route('invite-list.import.completed', [this.importRecord.event, this.importRecord]);
                        });
                })
                .catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        return Promise.reject(error);
                    }

                    const { reason } = error.response.data;

                    if (reason !== 'exceededLimit') {
                        return Promise.reject(error);
                    }

                    const currentPlan = this.auth().user().plan;

                    App.alert().paymentRequired(
                        'Not enough space available.',
                        `Your ${currentPlan.name} plan allows you to invite up to ${currentPlan.features.InviteeLimit.max} total invitees, including additional guests (+1’s and +X’s). Please upgrade to invite more guests.`
                    );
                });
        },

        selectAction (selection) {
            const overwrite = () => {
                this.form.action = 'overwrite';
            };

            const append = () => {
                this.form.action = 'append';
            };

            const attributes = {
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel'
            };

            if (selection === 'overwrite') {
                App.alert().confirm(
                    'Are you sure you want to overwrite your existing invitees?',
                    'Your existing Invite List will be deleted and replaced. Existing registrations or RSVPs will not be affected, but will no longer be connected to invitees on your Invite List. This cannot be undone.',
                    attributes,
                    overwrite,
                    append
                );
            }
        }
    }
};
</script>
