<template>
    <div>
        <test-mode-limited-capacity-alert></test-mode-limited-capacity-alert>
        <div class="w-full md:w-1/2 p-2 bg-white rounded-md border">
            <simple-picker
                v-model="selectedQuestionId"
                :items="questions"
                item-label="title"
                item-value="id"
                placeholder-empty-state="Select custom question..."
            ></simple-picker>
        </div>

        <section v-if="chart.state !== CHART_UNAVAILABLE" class="panel mt-4 text-center">
            <app-icon
                v-if="chart.state === CHART_LOADING"
                class="h-6 w-6 text-gray-500"
                name="loader"
            ></app-icon>

            <doughnut-chart
                v-else
                :data="chart.data"
                total-items-tooltip="Individual Guests"
                total-groups-tooltip="Groups Totaling"
            ></doughnut-chart>
        </section>

        <dashboard-data
            :always-loaded-columns="['formalName', answerColumnId]"
            :custom-column-definitions="customColumnDefinitions"
            :default-columns="['firstName', 'lastName', 'email', answerColumnId]"
            :event="event"
        >
            <template #export-button="{ allFilters, chosenColumns }">
                <dashboard-custom-question-export-button
                    :all-predicates="allFilters"
                    :chosen-columns="chosenColumns"
                    :custom-question-id="selectedQuestionId"
                    :show-file-export="selectedQuestion.type.slug === 'file-uploads'"
                ></dashboard-custom-question-export-button>
            </template>

            <template #row="{ property, row }">
                <template v-if="property === answerColumnId">
                    <template v-if="selectedQuestion.type.slug === 'file-uploads'">
                        <template v-if="!row[answerColumnId] || row[answerColumnId].length === 0">&nbsp;</template>

                        <span v-for="(answer, index) in row[answerColumnId]" :key="index">
                            <a
                                class="text-purple underline pr-2"
                                :href="answer.value"
                                target="_blank"
                            >
                                {{ answer.meta.filename }}
                            </a>
                        </span>
                    </template>

                    <template v-else>
                        {{ formatStringAnswers(row[answerColumnId]) }}
                    </template>
                </template>
            </template>

            <template #summary="{ row }">
                <p>{{ row.formalName }}</p>

                <p class="text-sm mt-2">
                    <template v-if="selectedQuestion.type.slug === 'file-uploads'">
                        <template v-if="!row[answerColumnId] || row[answerColumnId].length === 0">&nbsp;</template>

                        <span v-for="(answer, index) in row[answerColumnId]" :key="index">
                            <a
                                class="text-purple underline pr-2"
                                :href="answer.value"
                                target="_blank"
                            >
                                {{ answer.meta.filename }}
                            </a>
                        </span>
                    </template>

                    <template v-else>
                        {{ formatStringAnswers(row[answerColumnId]) }}
                    </template>
                </p>
            </template>
        </dashboard-data>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import axios from '@/util/axios';

const CHART_UNAVAILABLE = 'unavailable';
const CHART_LOADING = 'loading';
const CHART_RENDERED = 'rendered';

export default {
    name: 'DashboardCustomQuestionsTable',

    props: {
        questions: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            CHART_UNAVAILABLE,
            CHART_LOADING,
            CHART_RENDERED,

            chart: {
                state: CHART_UNAVAILABLE,
                data: {
                    labels: [],
                    datasets: []
                }
            },
            selectedQuestionId: this.questions[0].id,
            showChart: false
        };
    },

    computed: {
        answerColumnId () {
            return `question-${this.selectedQuestionId}`;
        },

        customColumnDefinitions () {
            return [{
                property: this.answerColumnId,
                label: this.selectedQuestion.title,
                sortable: false,
                alwaysActive: true
            }];
        },

        event: get('Event/event'),

        selectedQuestion () {
            return this.questions.find(({ id }) => {
                return id === this.selectedQuestionId;
            });
        }
    },

    watch: {
        selectedQuestion: {
            handler (newSelectedQuestion) {
                if (newSelectedQuestion.type.settings.optionBased) {
                    this.fetchChartData();
                } else {
                    this.chart.state = CHART_UNAVAILABLE;
                }
            },
            immediate: true
        }
    },

    methods: {
        async fetchChartData () {
            this.chart.state = CHART_LOADING;

            try {
                const { data } = await axios.get(this.route('api.dashboard.custom-questions.chart', {
                    event: this.event,
                    question: this.selectedQuestion.id
                }));

                this.chart.data = {
                    datasets: [{
                        data: data.data.options.map(({ selectionCount }) => {
                            return selectionCount;
                        })
                    }],
                    labels: data.data.options.map(({ title, selectionCount }) => {
                        return {
                            item: title,
                            totalItems: selectionCount
                        };
                    })
                };
            } finally {
                this.chart.state = CHART_RENDERED;
            }
        },

        formatStringAnswers (rawAnswers) {
            return rawAnswers.map(({ value }) => { return value; }).join(', ');
        }
    }
};
</script>
