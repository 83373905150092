/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'technology-qr-code-scan': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>check-in</desc><g _fill="currentColor"><path pid="0" d="M3.84 22l-1.584.037a.251.251 0 01-.256-.25V20.16a1 1 0 00-2 0v1.883A1.957 1.957 0 001.957 24H3.84a1 1 0 000-2zM1 4.84a1 1 0 001-1l-.038-1.584A.251.251 0 012.212 2H3.84a1 1 0 000-2H1.956A1.956 1.956 0 000 1.956V3.84a1 1 0 001 1zM22.043 0H20.16a1 1 0 000 2l1.584-.037a.251.251 0 01.256.25V3.84a1 1 0 002 0V1.957A1.957 1.957 0 0022.043 0zM23 19.16a1 1 0 00-1 1l.038 1.584a.251.251 0 01-.25.256H20.16a1 1 0 100 2h1.884A1.956 1.956 0 0024 22.044V20.16a1 1 0 00-1-1z"/><rect pid="1" x="5" y="4.5" width="3.5" height="3.5" rx=".5" ry=".5"/><rect pid="2" x="15.5" y="4.5" width="3.5" height="3.5" rx=".5" ry=".5"/><rect pid="3" x="15.5" y="16" width="3.5" height="3.5" rx=".5" ry=".5"/><rect pid="4" x="5" y="16" width="3.5" height="3.5" rx=".5" ry=".5"/><path pid="5" d="M10.5 8.261a.75.75 0 00.75-.75V6.25A.25.25 0 0111.5 6h1.932a.75.75 0 100-1.5H10.5a.75.75 0 00-.75.75v2.261a.75.75 0 00.75.75zM13.5 7.131a.75.75 0 00-.75.75v3.348a.75.75 0 001.5 0V7.881a.75.75 0 00-.75-.75zM10.5 14.5a.75.75 0 00.75-.75v-2.413a.75.75 0 00-1.5 0v1.413a.25.25 0 01-.25.25h-4a.75.75 0 000 1.5zM13.432 18H11.5a.25.25 0 01-.25-.25v-1.413a.75.75 0 00-1.5 0v2.413a.75.75 0 00.75.75h2.932a.75.75 0 100-1.5zM5.5 11h2a.75.75 0 000-1.5h-2a.75.75 0 000 1.5zM16 11h2.5a.75.75 0 000-1.5H16a.75.75 0 000 1.5zM18.5 13H13a.75.75 0 000 1.5h5.5a.75.75 0 000-1.5z"/></g>'
  }
})
