/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wedding-altar-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.93 5.839l-.01-.01c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06l-1.18 1.21-1.18-1.22-.01-.01c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06h-.01c-.77.79-.77 2.04 0 2.84l3.51 3.63h0c.22.23.58.24.81.01 0-.01.01-.02.01-.02l3.51-3.64v-.01c.76-.8.76-2.05-.01-2.85z"/><path pid="1" d="M23.25 23.25H.75V12h0C.74 5.78 5.78.74 11.99.74 18.2.73 23.24 5.77 23.24 11.98s0 0 0 0zM12 .75v6.31"/><path pid="2" d="M19.5 3.75V12h0c0 2.07 1.67 3.74 3.75 3.74M23.25 15.75v-.001a5.25 5.25 0 00-5.25 5.25v2.25M4.5 3.75V12c0 2.07-1.68 3.75-3.75 3.75M.75 15.75h0C3.64 15.75 5.99 18.1 6 21v2.25"/></g>'
  }
})
