/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-football-ball-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.24 3.85v-.01a2.988 2.988 0 00-2.88-3.1.885.885 0 00-.23 0c-3.67.13-9.89 1.04-14.12 5.28C1.782 10.25.87 16.463.73 20.13H.72a2.97 2.97 0 002.87 3.09c.07 0 .14 0 .22-.01 3.67-.14 9.894-1.05 14.122-5.279C22.15 13.7 23.06 7.48 23.2 3.8zM15.75 8.25l-7.5 7.5M10.88 10.88l2.24 2.24M8.25 13.5l2.25 2.25M13.5 8.25l2.25 2.25M1.43 14.93l7.64 7.64M22.57 9.07l-7.64-7.64"/></g>'
  }
})
