/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-graph-infected-increasing-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.755 22.5h-21v-21"/><path pid="1" d="M.755 19.5h2.7c8.9 0 16.06-3.39 17.955-12.762M10.5 3.75a3 3 0 100 6 3 3 0 100-6zM10.01 1.5h1M10.51 1.5v2.25M13.86 2.68l.71.71M14.22 3.04l-1.59 1.59M15.76 6.25v1M15.76 6.75h-2.25M14.57 10.11l-.71.71M14.22 10.46l-1.59-1.59M11.01 12h-1M10.51 12V9.75M7.15 10.82l-.71-.71M6.79 10.46l1.59-1.59M5.26 7.25v-1M5.26 6.75h2.25M6.44 3.39l.71-.71M6.79 3.04l1.59 1.59"/><path pid="2" d="M18.571 8.57l2.84-1.84 1.83 2.84"/></g>'
  }
})
