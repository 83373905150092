<template>
    <div class="flex-1 p-8">
        <section class="panel flex flex-col">
            <page-header icon="technology-computer-stroke" label="Manage ASN Blacklist">
                <a :href="route('admin.dashboard')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <infinite-data-table
                ref="table"
                class="-mx-8 md:mx-0 text-gray-600"
                column-header-class="z-10"
                :columns="columns"
                :filter="filter"
                no-results-placeholder="We couldn't find any blacklisted ASNs matching the filtering criteria."
                :source="source"
            >
                <template #header>
                    <div class="flex-auto">
                        <search-field
                            v-model="filter.term"
                            class="w-full md:w-80"
                            placeholder="Search by ASN..."
                        ></search-field>
                    </div>

                    <div class="flex justify-end flex-auto ml-4">
                        <button
                            class="button-icon button-primary button-lg"
                            @click="isNewBlacklistedAsnModalVisible = true"
                        >
                            <app-icon name="add-circle" stroke></app-icon>
                        </button>
                    </div>
                </template>

                <template #row="{ property, row, value }">
                    <template v-if="property === 'enabled'">
                        <toggle-switch
                            :value="value"
                            small
                            @input="newValue => update(row, { enabled: newValue })"
                        ></toggle-switch>
                    </template>

                    <template v-else>{{ value }}</template>
                </template>
            </infinite-data-table>
        </section>

        <app-modal v-model="isNewBlacklistedAsnModalVisible">
            <template #header>Add ASN to Blacklist</template>

            <template #default="{ close }">
                <form id="asn-form" @submit.prevent="store(close)">
                    <form-field-wrapper label="ASN" :error="form.errors.get('value')">
                        <input
                            v-model="form.value"
                            class="form-field"
                            placeholder="ASN"
                        >
                    </form-field-wrapper>
                </form>
            </template>

            <template #footer="{ close }">
                <stateful-button
                    class="button-primary"
                    form="asn-form"
                    :loading="form.processing"
                    type="submit"
                >
                    Add
                </stateful-button>

                <button class="button" @click="close">Cancel</button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import axios from '@/util/axios';
import Form from '@/validation/Form';

export default {
    data () {
        return {
            filter: {
                term: ''
            },
            form: new Form({
                value: ''
            }),
            isNewBlacklistedAsnModalVisible: false
        };
    },

    computed: {
        columns () {
            return [
                {
                    label: 'ASN',
                    property: 'value',
                    desktop: true
                },
                {
                    label: 'Enabled',
                    property: 'enabled',
                    desktop: true
                }
            ];
        },

        source () {
            return this.route('api.admin.blacklist.asns.index');
        }
    },

    methods: {
        store (closeFunction) {
            this.form.post(this.route('api.admin.blacklist.asns.store'))
                .then(() => {
                    closeFunction();

                    this.$refs.table.reload();
                })
                .catch(() => {
                    this.$toasted.global.error('There was an error adding an ASN to the blacklist.');
                });
        },

        update (blacklistedAsn, data) {
            axios.patch(this.route('api.admin.blacklist.asns.update', blacklistedAsn), data)
                .then(() => {
                    this.$refs.table.reload();
                })
                .catch(() => {
                    this.$toasted.global.error('There was an error updating the blacklist.');
                });
        }
    }
};
</script>
