/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'like-chat-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>thumb up</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 13.55v-4.9h.7l-.01-.001c1.93 0 3.5-1.57 3.5-3.5h0c-.01-.78.62-1.41 1.39-1.41h-.01c1.04-.01 1.9.85 1.9 1.89v.9-.01c-.01.38.31.7.69.7h.2c1.15 0 2.09.94 2.09 2.1-.01.06-.01.13-.02.2l-.22 2.17h-.01a2.808 2.808 0 01-2.79 2.52h-2.9v-.001c-.27 0-.52-.04-.77-.11l-1.7-.484h0c-.26-.08-.51-.11-.77-.11H6.66"/><path pid="1" d="M21.75 18.75h-10.5l-6 4.5v-4.5H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V2.24c0-.83.67-1.5 1.5-1.5h19.5-.01c.82-.01 1.5.67 1.5 1.5 0 0 0 0 0 0v15h0c0 .82-.68 1.5-1.5 1.5l-.01-.001z"/></g>'
  }
})
