/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-add-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>event date time plus create new appointment</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 13.5v6M9 16.5h6"/></g><g stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="1" width="22.5" height="19.5" x=".75" y="3.75" rx="1.5"/><path pid="2" d="M.75 9.75h22.5"/><g stroke-linecap="round"><path pid="3" d="M6.75 6V.75M17.25 6V.75"/></g></g>'
  }
})
