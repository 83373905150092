/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-eggs': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>dairy</desc><g _fill="currentColor"><path pid="0" d="M24 14a1 1 0 00-1-1H1a1 1 0 000 2 .623.623 0 01.608.488l.718 3.229a1 1 0 00.974.783h3.4a1 1 0 00.976-.783l.58-2.617a.25.25 0 01.488 0l.582 2.619a1 1 0 00.976.783h3.4a1 1 0 00.976-.783l.582-2.619a.25.25 0 01.488 0l.582 2.619a1 1 0 00.976.783h3.4a1 1 0 00.976-.783l.718-3.229A.623.623 0 0123 15a1 1 0 001-1zM19 4.5c-1.366 0-2.552 1.06-3.271 2.712a.249.249 0 01-.458 0C14.553 5.56 13.367 4.5 12 4.5S9.447 5.56 8.729 7.212a.249.249 0 01-.458 0C7.552 5.56 6.366 4.5 5 4.5c-2.243 0-4 2.855-4 6.5a.5.5 0 00.5.5h21a.5.5 0 00.5-.5c0-3.645-1.757-6.5-4-6.5zM5 7c-.386 0-.789.439-1.028 1.12a.5.5 0 01-.472.335.509.509 0 01-.166-.029.5.5 0 01-.306-.637C3.422 6.669 4.159 6 5 6a.5.5 0 010 1zm7.5-.5a.5.5 0 01-.5.5c-.386 0-.789.439-1.028 1.12a.5.5 0 01-.472.335.509.509 0 01-.166-.029.5.5 0 01-.306-.637C10.422 6.669 11.159 6 12 6a.5.5 0 01.5.5zM19 7c-.386 0-.789.439-1.028 1.12a.5.5 0 01-.472.335.509.509 0 01-.166-.029.5.5 0 01-.306-.637C17.422 6.669 18.159 6 19 6a.5.5 0 010 1z"/></g>'
  }
})
