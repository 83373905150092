/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-christmas-tree-ornaments-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>winter</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 11.25a6 6 0 100 12 6 6 0 100-12zM18.75 7.5a4.5 4.5 0 100 9 4.5 4.5 0 100-9z"/><path pid="1" d="M1.4 19.97h0c.24.16.52.25.81.27 1.1 0 1.1-1.3 2.2-1.3s1.1 1.3 2.2 1.3 1.1-1.3 2.2-1.3 1.1 1.3 2.205 1.3c.72 0 .97-.56 1.36-.95M.92 15.816h0c.2.39.6.65 1.04.68 1.1 0 1.1-1.5 2.2-1.5s1.1 1.5 2.2 1.5 1.1-1.5 2.2-1.5 1.1 1.5 2.2 1.5c.73 0 1.23-.8 1.63-1.24M14.311 12.72c.41-.55.75-1.19 1.56-1.19 1.41 0 1.41 1.95 2.827 1.95 1.41 0 1.41-1.96 2.82-1.96.85 0 1.2.71 1.64 1.28M6.62 6.75v-.001c.2-.01.37.16.37.36 0 0 0 0 0 0M6.245 7.12l-.01-.01c-.01-.21.16-.38.37-.38 0-.01 0-.01 0 0"/><path pid="2" d="M6.62 7.5v-.001a.38.38 0 01-.38-.38c-.01-.01-.01-.01-.001-.01M7 7.12h0c0 .2-.17.375-.375.375-.01 0-.01-.01-.01-.01M6.62 3.75v-.001c.2-.01.37.16.37.36 0 0 0 0 0 0M6.245 4.12l-.01-.01c-.01-.21.16-.38.37-.38 0-.01 0-.01 0 0"/><path pid="3" d="M6.62 4.5v-.001a.38.38 0 01-.38-.38c-.01-.01-.01-.01-.001-.01M7 4.12h0c0 .2-.17.375-.375.375-.01 0-.01-.01-.01-.01M18.29 3.757h-.01c.2-.04.39.09.43.29 0 0 0 0 0 0M17.99 4.19l-.01-.01a.37.37 0 01.29-.44v-.01"/><path pid="4" d="M18.427 4.494v-.01c-.21.03-.4-.1-.44-.3"/><path pid="5" d="M18.727 4.05h0c.03.2-.1.39-.31.43M18.29.757h-.01c.2-.04.39.09.43.29 0 0 0 0 0 0M17.99 1.19l-.01-.01a.37.37 0 01.29-.44V.73"/><path pid="6" d="M18.427 1.494v-.01c-.21.03-.4-.1-.44-.3"/><path pid="7" d="M18.727 1.057h0c.03.2-.1.39-.31.43M6.62.75V.749c.2-.01.37.16.37.36 0 0 0 0 0 0M6.245 1.125l-.01-.01c-.01-.21.16-.38.37-.38 0-.01 0-.01 0 0"/><path pid="8" d="M6.62 1.5v-.001a.38.38 0 01-.38-.38c-.01-.01-.01-.01-.001-.01"/><path pid="9" d="M7 1.125h0c0 .2-.17.375-.375.375-.01 0-.01-.01-.01-.01"/></g>'
  }
})
