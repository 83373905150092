/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-group-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.5 3.75V1.5v0c0-.42-.34-.75-.75-.75H5.24c-.42 0-.75.33-.75.75 0 0 0 0 0 0v2.25h-.01c.82-.01 1.5.67 1.5 1.5 0 .82-.68 1.5-1.5 1.5V9h0c0 .41.33.74.75.74h13.5-.01c.41-.01.74-.34.74-.75V6.74l-.01-.001a1.51 1.51 0 01-1.5-1.5c0-.83.67-1.5 1.5-1.5zM9 3.75v3M19.5 13.5a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM15.75 23.25h0a3.739 3.739 0 013.74-3.76 3.736 3.736 0 013.75 3.74s0 0 0 0M12 13.5a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM8.25 23.25h0a3.739 3.739 0 013.74-3.76 3.736 3.736 0 013.75 3.74s0 0 0 0M4.5 13.5a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM.75 23.25h0a3.739 3.739 0 013.74-3.76 3.736 3.736 0 013.75 3.74s0 0 0 0"/></g>'
  }
})
