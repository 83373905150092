/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-basket-star-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>favorite like cart</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.956 11.68l1.54 4.07h2.99-.01c.4-.02.74.3.76.7 0 .21-.09.43-.25.57l-2.6 1.81 1.44 3.314h0c.16.4-.03.85-.43 1.02-.23.09-.49.07-.7-.05l-3.49-1.97-3.48 1.96v-.01c-.38.21-.86.08-1.08-.29a.787.787 0 01-.05-.7l1.44-3.314-2.6-1.82h0a.733.733 0 01-.07-1.04c.14-.17.35-.26.57-.25h3l1.54-4.07v-.01c.2-.39.68-.54 1.07-.34.14.07.26.19.33.33z"/><path pid="1" d="M7.875 17.25H4.314h0c-.67-.02-1.25-.49-1.39-1.14l-2.14-9h0c-.22-.8.26-1.61 1.05-1.82.1-.03.21-.05.32-.05h18.14c.81.02 1.46.71 1.43 1.53-.01.11-.03.22-.05.32l-.3 1.25M3.75 5.25l4.5-4.5M18.75 5.25l-4.5-4.5"/></g>'
  }
})
