import { Validator } from 'vee-validate';
import axios from '@/util/axios';

const instance = new Validator();

instance.extend('validUrls', (value) => {
    // It is optional to include this field
    if (!value) {
        return true;
    }

    const urls = value.split(',');

    // Loop through "urls" array and return false if any url is invalid
    return urls.every((val) => {
        return Object.getPrototypeOf(instance).rules.url.validate(val.trim());
    });
});

instance.extend('noHttpNorWww', (value) => {
    return value.indexOf('www') === -1
        && value.indexOf('http') === -1;
});

instance.extend('availableSubdomain', {
    getMessage: () => { return 'The subdomain is not available.'; },
    validate: (value) => {
        return axios
            .post(window.route('api.events.check-subdomain-availability'), { subdomain: value })
            .then(() => {
                return { valid: true };
            })
            .catch(() => {
                return { valid: false };
            });
    }
});
