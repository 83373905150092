/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'board-game-dice-pawn-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>chess</desc><rect pid="0" width="10.5" height="10.5" x="1" y="12.75" rx="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" transform="matrix(-1 -.00001 0 -1 12.5 36)"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="1" d="M4.375 15.75h-.01c-.21 0-.38.16-.38.37 0 0 0 0 0 0M4.75 16.125v0c0-.21-.17-.375-.375-.375M4.375 16.5h-.01c.2 0 .37-.17.37-.38"/><path pid="2" d="M4 16.125h0c0 .2.16.37.37.37M8.125 15.75h-.01c-.21 0-.38.16-.38.37 0 0 0 0 0 0M8.5 16.125v0c0-.21-.17-.375-.375-.375M8.125 16.5h-.01c.2 0 .37-.17.37-.38"/><path pid="3" d="M7.75 16.125h0c0 .2.16.37.37.37M8.125 19.5h-.01c-.21 0-.38.16-.38.37 0 0 0 0 0 0M8.5 19.875v0c0-.21-.17-.375-.375-.375M8.125 20.25h-.01c.2 0 .37-.17.37-.38"/><path pid="4" d="M7.75 19.875h0c0 .2.16.37.37.37M4.375 19.5h-.01c-.21 0-.38.16-.38.37 0 0 0 0 0 0M4.75 19.875v0c0-.21-.17-.375-.375-.375M4.375 20.25h-.01c.2 0 .37-.17.37-.38"/><path pid="5" d="M4 19.875h0c0 .2.16.37.37.37M14.283 17.243l1.02-7.068h0c-2.51-1.01-3.73-3.86-2.73-6.36 1-2.51 3.85-3.73 6.35-2.73 2.5 1 3.72 3.85 2.72 6.35a4.927 4.927 0 01-2.73 2.72l1.35 8.189h1.28c.68 0 1.28.46 1.45 1.13l.15 1.98-.01-.01c.14.81-.4 1.59-1.22 1.73-.09.01-.18.02-.27.02h-6.46"/></g>'
  }
})
