<template>
    <div class="px-4 pb-8 pt-2" :class="{ 'cursor-pointer': !isActive }">
        <slot name="block-header"></slot>

        <div v-if="!hasMeals">
            <div v-theme="['form.accent']" class="p-6 flex flex-col items-center">
                <app-icon
                    name="food-eating-set-stroke"
                    class="h-10 w-10"
                    stroke
                ></app-icon>
                <div
                    v-if="isEditMode"
                    v-theme="'form.title-text'"
                    class="text-xl text-center mt-4"
                >
                    This Meal Block is empty.
                </div>
                <div v-theme="'form.text'" class="text-center mt-4">
                    <template v-if="isEditMode">You have not added any meals to the block yet.</template>
                    <template v-else>This event does not offer any meals yet.</template>
                </div>
            </div>
        </div>
        <template v-else>
            <div
                v-theme="['form.title-text', 'form.accent']"
                class="text-center text-2xl mt-8 mb-4 border-b pb-4 font-semibold"
            >
                {{ block.pivot.settings.title }}
            </div>

            <div
                v-if="block.pivot.settings.description"
                v-theme="['form.text']"
                class="mb-2 break-words"
            >
                {{ block.pivot.settings.description }}
            </div>

            <meal-selection
                v-if="isEditMode"
                v-model="editModeSelections"
                :choices="meals"
            ></meal-selection>

            <form-field-wrapper
                v-for="{ id, formalName } in guests"
                :key="id"
                :error="errorBag.get(`payload.meals.${id}`)"
                :should-show-error="errorBag.has(`payload.meals.${id}`)"
            >
                <div v-theme="'form.title-text'" class="text-lg">
                    {{ formalName }}
                </div>

                <meal-selection v-model="mealPreferences[id]" :choices="meals"></meal-selection>
            </form-field-wrapper>
        </template>

        <div v-if="errorBag.has('payload.meals')" class="alert alert-error alert-sm mt-2">
            <p class="w-full font-normal text-center">{{ errorBag.get('payload.meals') }}</p>
        </div>

        <slot name="block-footer" :complete-block="completeBlock"></slot>
    </div>
</template>

<script>
import map from 'lodash/map';
import times from 'lodash/times';
import zipObject from 'lodash/zipObject';
import FormBlock from '@/mixins/FormBlock';
import { getGuestsPassingCriteria } from '@/util/additional-criteria';

export default {
    name: 'FormMealBlock',

    mixins: [FormBlock],

    behaviour: {
        render: {
            when (block, submission) {
                const { askIf, additionalCriteria } = block.pivot.settings;

                return getGuestsPassingCriteria(askIf, additionalCriteria, submission).length > 0;
            },
            unless (block, submission) {
                return false;
            }
        }
    },

    data () {
        return {
            mealPreferences: {},
            editModeSelections: null
        };
    },

    computed: {
        guests () {
            const { askIf, additionalCriteria } = this.block.pivot.settings;

            return getGuestsPassingCriteria(askIf, additionalCriteria, this.submission);
        },

        hasMeals () {
            return this.meals.length > 0;
        },

        meals () {
            if (this.isEditMode) {
                return this.block.pivot.meals;
            }

            return this.block.pivot.meals.filter((meal) => {
                const availabilitySettings = meal.settings.maxCapacity || {};
                if (!availabilitySettings.enabled || !(availabilitySettings.slots > 0)) {
                    return true;
                }
                if (availabilitySettings.enabled && meal.quantity_remaining === 0) {
                    return availabilitySettings.showMessage === true;
                }
                return true;
            });
        },

        onCompletionListeners () {
            return {
                ticketing: () => { this.resetPreferences(); },
                rsvp: () => { this.resetPreferences(); }
            };
        }
    },

    methods: {
        async completeBlock () {
            return this.getCompletionObject();
        },

        resetPreferences () {
            this.mealPreferences = zipObject(
                map(this.guests, 'id'),
                times(this.guests.length, () => { return null; })
            );
        },

        serializePayload () {
            return {
                meals: { ...this.mealPreferences }
            };
        }
    }
};
</script>
