/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'performance-theater-masks-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.65 5.77h0c-.8-.23-1.63.24-1.85 1.04-.04.13-.06.26-.06.39v7.16h-.01c-.02 1.76.62 3.46 1.81 4.77l-.01-.01c1.63 1.78 3.65 3.15 5.92 3.99h0c.33.12.69.12 1.03-.01h-.01c2.26-.85 4.29-2.22 5.92-4v-.01a7.083 7.083 0 001.81-4.78V7.14h-.01c0-.83-.68-1.51-1.5-1.51-.14-.01-.28.01-.41.05h-.01a23.224 23.224 0 01-12.7 0z"/><path pid="1" d="M17.25 4.035v-1.79h0c-.01-.83-.68-1.5-1.51-1.5-.14 0-.27.01-.4.05h-.01A23.094 23.094 0 012.64.785L2.63.77A1.5 1.5 0 00.71 2.21v7.16H.7c-.02 1.76.62 3.46 1.81 4.77.17.18.35.37.544.56M18 15.75v0c0 1.65-1.35 3-3 3-1.66 0-3-1.35-3-3M9.75 11.25h0a1.496 1.496 0 112.99-.02s0 0 0 0M17.25 11.25h0a1.496 1.496 0 112.99-.02s0 0 0 0"/></g>'
  }
})
