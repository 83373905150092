/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'book-closed-bookmark-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>library read</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21 23.25H5.25l-.01-.001a2.256 2.256 0 01-2.25-2.26s0 0 0 0"/><path pid="1" d="M12 2.25H5.99c-1.66 0-3 1.34-3 3 0 0 0 0 0 0V21h0a2.24 2.24 0 012.24-2.26h15c.41 0 .75-.34.75-.75V2.98v0c0-.42-.34-.75-.75-.75h-2.25M19.5 23.25v-4.5"/><path pid="2" d="M18 12.75l-3-3-3 3V2.25h0c-.01-.83.67-1.51 1.49-1.51h3-.01c.82-.01 1.5.67 1.5 1.49 0 0 0 0 0 0z"/></g>'
  }
})
