/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'baggage-plane-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>travel air jet suitcase luggage</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M20.777 12.789l-8.89 2.987-1.2-1.19-1.766.593v-.01a.74.74 0 00-.33 1.2l2.38 2.67h0c.4.44 1.03.61 1.6.42l2.61-.88-.53 3.78h-.01c-.06.4.22.78.63.84.11.01.23 0 .34-.04l1.36-.46h-.01c.21-.08.37-.24.46-.44l1.99-5.14 2.48-.84v-.01c.98-.34 1.5-1.4 1.17-2.38-.34-.99-1.4-1.51-2.38-1.18z"/><path pid="1" d="M18.28 13.625l-2.78-1.92h0a.766.766 0 00-.67-.1l-1.34.45v-.01a.74.74 0 00-.48.94c.03.11.09.21.18.29l1.51 1.51"/><path pid="2" d="M8.25 20.249h-4.5l-.01-.001c-1.66-.01-3-1.35-3-3V8.247h0A2.998 2.998 0 013.73 5.23h13.5-.01c1.65-.01 3 1.34 3 3v1.5M6 5.25v15M15 5.25V9"/><path pid="3" d="M13.5 5.24V2.23c0-.83-.68-1.5-1.5-1.5 0 0 0 0 0 0H8.99c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v3"/></g>'
  }
})
