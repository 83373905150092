/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right-chevron-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" _fill="none" _stroke="currentColor" d="M9 5l7 7-7 7"/>'
  }
})
