<template>
    <div class="border rounded-md">
        <div class="py-2 px-6 rounded-t-md bg-gray-100 border-b">
            <span class="w-3 h-3 rounded-full inline-block bg-red"></span>
            <span class="w-3 h-3 rounded-full inline-block bg-yellow"></span>
            <span class="w-3 h-3 rounded-full inline-block bg-green"></span>
        </div>

        <div class="p-6" :style="styles.container">
            <div class="w-full">
                <div class="max-w-xl mx-auto" :style="styles.body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BrowserWindow',

    props: {
        backgroundColor: {
            type: String,
            default: '#fff'
        },

        bodyBackgroundColor: {
            type: String,
            default: '#fff'
        }
    },

    computed: {
        styles () {
            return {
                container: {
                    'background-color': this.backgroundColor
                },
                body: {
                    'background-color': this.bodyBackgroundColor
                }
            };
        }
    }
};
</script>
