/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'beach-palm-water-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>location travel</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21 18.55h0a20.27 20.27 0 00-9-1.81h0c-3.1-.1-6.18.51-9 1.79M15.75 8.48l-.77 8.43M8.91 16.92l4.94-9.06M13.85 7.85l8.27 2.7-.01-.01c.39.13.81-.09.94-.48.04-.13.04-.27.02-.4h0a4.745 4.745 0 00-3.6-4.02h0c-.79-.17-1.61-.2-2.4-.09M13.85 7.85L6.31 9.89v-.01a.77.77 0 01-.93-.53.701.701 0 01-.02-.35v-.01A4.74 4.74 0 018.97 4.9h-.01c1.02-.24 2.09-.21 3.11.07"/><path pid="1" d="M18.7 5.56l3.1-3.12v-.01c.29-.29.29-.77 0-1.06a.823.823 0 00-.22-.16h0a4.879 4.879 0 00-5.09.5h-.01c-.64.51-1.09 1.21-1.29 2.01M10.3 4.78L8.34 2.66h0a.745.745 0 01.03-1.06c.09-.1.22-.16.35-.19h-.01c.82-.16 1.66-.24 2.5-.24h0c1.66.15 3.14 1.11 3.96 2.57M.75 23.25l-.01-.01c1.64.2 3.3-.35 4.5-1.5l-.01-.01a4.54 4.54 0 006.42.32c.11-.11.22-.22.32-.33l-.01-.01a4.54 4.54 0 006.42.32c.11-.11.22-.22.32-.33l-.01-.01a5.516 5.516 0 004.49 1.5"/></g>'
  }
})
