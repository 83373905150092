/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'team-approve-disapprove-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>meeting network group users message team</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.97 14.25a2.62 2.62 0 100 5.25 2.62 2.62 0 100-5.25zM9.2 23.25h0a4.482 4.482 0 00-8.46-.01M9 9.75V12L6 9.75H2.249l-.01-.001a1.51 1.51 0 01-1.5-1.5V2.248h0A1.489 1.489 0 012.229.73h8.25"/><path pid="1" d="M3.749 5.25l1.5 1.5 3.75-3M19.02 14.25a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM14.8 23.25h-.01a4.467 4.467 0 018.44-.01M13.5 12h.75v2.25l3-2.25h4.5l-.01-.001c.82 0 1.5-.68 1.5-1.5v-6 0c0-.83-.68-1.5-1.5-1.5h-8.26c-.83 0-1.5.67-1.5 1.5v6h0c0 .82.67 1.49 1.5 1.49zM19.5 9l-3.75-3M15.75 9l3.75-3"/></g>'
  }
})
