/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download-cloud': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M24 10.663A5.817 5.817 0 0022.228 6.5a5.711 5.711 0 00-3.447-1.585.249.249 0 01-.191-.12 7.684 7.684 0 00-14.1 2.294.251.251 0 01-.227.2A4.59 4.59 0 000 11.859a4.324 4.324 0 001.236 3.21 5.529 5.529 0 003.605 1.377 1 1 0 00.985-1.015 1.023 1.023 0 00-1.015-.985 3.3 3.3 0 01-2.172-.8A2.374 2.374 0 012 11.859a2.576 2.576 0 01.954-2.007 2.6 2.6 0 012.167-.527 1 1 0 001.2-.919 5.686 5.686 0 0110.82-2.088.959.959 0 00.941.57 3.687 3.687 0 012.758 1.049A3.752 3.752 0 0122 10.663a3.835 3.835 0 01-3.438 3.791 1 1 0 00.132 1.992.961.961 0 00.131-.009A5.807 5.807 0 0024 10.663z"/><path pid="1" d="M15.5 17.446h-1.75a.25.25 0 01-.25-.25v-7.25a1.5 1.5 0 00-3 0V17.2a.25.25 0 01-.25.25H8.5a1 1 0 00-.707 1.707l3.5 3.5a1 1 0 001.414 0l3.5-3.5a1 1 0 00-.707-1.707z"/></g>'
  }
})
