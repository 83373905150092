<template>
    <div class="pb-6">
        <search-field
            v-model="searchQuery"
            class="w-1/2 mb-4"
            placeholder="Search my images..."
        ></search-field>

        <div>
            <div v-masonry>
                <div
                    v-for="image in images"
                    :key="image.id"
                    v-masonry-tile
                    class="p-2 w-1/3"
                >
                    <div class="relative group" :class="getWrapperStyles(image)">
                        <img
                            class="cursor-pointer"
                            :src="image.urls.original"
                            @click="selectedImage = image"
                        >

                        <div class="absolute inset-x-0 bottom-0 hidden group-hover:flex justify-center py-1 bg-gray-100 hover:bg-gray-200 border border-gray-300 cursor-pointer" @click="deleteImage(image)">
                            <app-icon
                                name="trash"
                                class="w-4 h-4"
                                stroke
                            ></app-icon>
                        </div>
                    </div>
                </div>
            </div>

            <infinite-loading :identifier="infiniteId" @infinite="loadNextPage">
                <div slot="spinner" class="text-center m-2">
                    <app-icon class="h-6 w-6 text-gray-500" name="loader"></app-icon>
                </div>

                <div slot="no-more"></div>

                <div slot="no-results">
                    <template v-if="filtered">There are no images in your library which match your search query.</template>
                    <template v-else>There are no images in your library.</template>
                </div>
            </infinite-loading>
        </div>

        <portal to="upload-modal-footer">
            <div class="space-x-2">
                <button
                    class="button button-primary disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="!isImageSelected"
                    @click="$emit('select-image', selectedImage.urls)"
                >Add Image</button>

                <stateful-button
                    class="button button-outline button-primary disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="!isImageSelected"
                    @click="isCropperVisible = true"
                >Crop Image</stateful-button>
            </div>
        </portal>

        <image-cropper
            v-if="isCropperVisible"
            :image="{ src: selectedImage.urls.original }"
            @cancel="isCropperVisible = false"
            @input="uploadCroppedImage"
        ></image-cropper>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import debounce from 'lodash/debounce';
import axios from '@/util/axios';
import UploadsEventImages from '@/mixins/UploadsEventImages';

export default {
    name: 'MyImagesTab',

    components: {
        InfiniteLoading
    },

    mixins: [
        UploadsEventImages
    ],

    data () {
        return {
            images: [],
            infiniteId: +new Date(),
            isCropperVisible: false,
            nextPage: 1,
            searchQuery: '',
            selectedImage: null
        };
    },

    computed: {
        filtered () {
            return this.predicates.length > 0;
        },

        isImageSelected () {
            return this.selectedImage !== null;
        },

        predicates () {
            if (!this.searchQuery) {
                return [];
            }

            return [{
                field: 'name',
                comparison: 'contains',
                value: this.searchQuery
            }];
        }
    },

    watch: {
        predicates: debounce(function () {
            this.reset();
        }, 500)
    },

    methods: {
        async confirmDelete () {
            return new Promise((resolve) => {
                App.alert().confirm(
                    'Are you sure you want to delete this image?',
                    'It will be deleted from your image library, but not from any existing placements. This cannot be undone.',
                    {},
                    () => { return resolve(true); },
                    () => { return resolve(false); }
                );
            });
        },

        async deleteImage (image) {
            if (!await this.confirmDelete()) {
                return;
            }

            try {
                await axios.delete(this.route('api.account.images.destroy', image.id));

                this.reset();

                if (this.selectedImage === image) {
                    this.selectedImage = null;
                }
            } catch (error) {
                this.$toasted.global.error('There was an error deleting the image from your library.');
            }
        },

        getWrapperStyles (image) {
            if (this.selectedImage !== image) {
                return null;
            }

            return 'outline outline-2 outline-purple';
        },

        async loadNextPage ($state) {
            const { data } = await this.submitRequest();

            if (data.data.length === 0) {
                $state.complete();
                return;
            }

            this.images.push(...data.data);

            this.nextPage += 1;

            $state.loaded();
        },

        reset () {
            this.images = [];
            this.infiniteId = +new Date();
            this.nextPage = 1;
        },

        async submitRequest () {
            return axios.get(this.route('api.account.images.index'), {
                params: {
                    page: this.nextPage,
                    predicates: this.predicates
                }
            });
        },

        async uploadCroppedImage ({ file: blob }) {
            const filename = this.resolveFilenameFromUrl(this.selectedImage.urls.original);

            const image = this.createImageFromBlob(filename, blob);

            try {
                this.$emit('select-image', await this.uploadEventImage(image));
            } catch (error) {
                this.$toasted.global.error(error.message);
            }
        }
    }
};
</script>
