<template>
    <div>
        <div class="row mb-4">
            <div class="col-12 md:col-4">
                <search-field
                    v-model="filter.creatorNameAndEmailOrConfirmationCode"
                    class="w-full"
                    placeholder="Name, email, confirmation..."
                ></search-field>
            </div>

            <dashboard-export-button class="hidden md:flex md:col-offset-5 md:col-3 justify-end"></dashboard-export-button>
        </div>

        <div v-for="submission in submissions" :key="submission.id">
            <div class="flex flex-col bg-white md:rounded-md border-t border-b md:border-l md:border-r -mx-8 md:mx-0 mb-4 p-6">
                <div class="flex">
                    <div class="flex items-center flex-auto">
                        <div
                            v-tippy
                            :content="submission.declined ? 'Not Attending' : 'Attending'"
                            class="flex justify-center items-center"
                        >
                            <app-icon
                                :name="submission.declined ? 'close-square' : 'check-square'"
                                class="w-4 h-4"
                                :class="submission.declined ? 'text-red' : 'text-green'"
                                stroke
                            ></app-icon>
                        </div>

                        <p class="font-semibold mx-2"> {{ submission.creator.formalName }}</p>

                        <a
                            role="button"
                            class="flex text-gray-600 hover:text-purple hover:cursor-pointer"
                            @click="openPartyDetailsModal(submission)"
                        >
                            <app-icon
                                name="user-alt-search"
                                class="w-4 h-4"
                                stroke
                            ></app-icon>
                        </a>
                    </div>

                    <div
                        v-if="!submission.declined"
                        v-tippy
                        class="flex items-center justify-center text-gray-600"
                        :content="`Group of ${submission.guests.length}`"
                    >
                        <app-icon name="users-location" class="w-5 h-5"></app-icon>
                        <p class="ml-2">{{ submission.guests.length }}</p>
                    </div>
                </div>

                <p class="leading-normal text-gray-600 mt-4">{{ submission.note.note }}</p>
            </div>
        </div>

        <infinite-loading :identifier="infiniteId" @infinite="loadMore">
            <div slot="spinner" class="text-center m-2">
                <app-icon class="h-6 w-6 text-gray-500 spinning" name="loading-half"></app-icon>
            </div>

            <div slot="no-more"></div>

            <div slot="no-results" class="flex items-center text-purple text-lg font-semibold">
                <app-icon name="alert-triangle" class="h-5 w-5 mr-2"></app-icon>
                <span>No notes found matching your criteria.</span>
            </div>
        </infinite-loading>

        <party-details-modal
            v-model="showPartyDetailsModal"
            :initial-submission="activeSubmission"
            @delete-submission="deleteSubmission(submission)"
            @saved="updateDataTable"
        ></party-details-modal>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import InfiniteLoading from 'vue-infinite-loading';
import findIndex from 'lodash/findIndex';
import { get } from 'vuex-pathify';
import axios from '@/util/axios';

export default {
    name: 'DashboardNotes',

    components: { InfiniteLoading },

    data () {
        return {
            activeSubmission: {},
            filter: {
                creatorNameAndEmailOrConfirmationCode: ''
            },
            infiniteId: 1,
            page: 1,
            showPartyDetailsModal: false,

            /**
             * On the initial mount of this component, the <InfiniteLoading/>
             * component is going to fire the "@infinite" event. This executes
             * the "loadMore" method on this component and retrieves the first
             * batch of submissions.
             */
            submissions: []
        };
    },

    computed: {
        event: get('Event/event')
    },

    watch: {
        filter: {
            deep: true,
            handler: debounce(function () {
                this.infiniteId += 1;
                this.page = 1;
                this.submissions = [];
            }, 500)
        }
    },

    methods: {
        deleteSubmission ({ id }) {
            const index = findIndex(this.submissions, { id });

            this.submissions.splice(index, 1);

            this.showPartyDetailsModal = false;

            this.$toasted.global.success('This group and all associated data have been deleted.');
        },

        /**
         * By firing the "@infinite" event, the <InfiniteLoading/> component
         * notifies us whenever the user is near the bottom of the list, which
         * allows us to fetch more items.
         *
         * The <InfiniteLoading/> component needs to know when more items have
         * been fetched or when the end of the list has been reached. We use
         * the "loaded" and "complete" methods on the "$state" object to
         * communicate this information back to the component.
         */
        loadMore ($state) {
            axios.get(this.route('api.dashboard.notes', this.event), {
                params: {
                    filter: this.filter,
                    page: this.page
                }
            }).then(({ data }) => {
                if (data.data.length === 0) {
                    // We've reached the end of the list and there are no more
                    // items to load
                    $state.complete();
                    return;
                }

                this.page += 1;
                this.submissions.push(...data.data);
                $state.loaded();
            });
        },

        openPartyDetailsModal (submission) {
            this.activeSubmission = submission;
            this.showPartyDetailsModal = true;
        },

        updateDataTable () {
            this.showPartyDetailsModal = false;
            this.$refs.table.reload();
        }
    }
};
</script>
