<template>
    <div class="flex flex-col items-center">
        <h1 class="text-2xl font-semibold text-center">Create a new seating chart</h1>

        <div class="w-full md:w-1/2 mt-8">
            <form-field-wrapper
                label="Chart title"
                :error="form.errors.get('title')"
                :should-show-error="form.errors.has('title')"
            >
                <input
                    v-model="form.title"
                    class="form-field"
                    placeholder="Name your chart..."
                >
            </form-field-wrapper>

            <form-field-wrapper
                label="Add guests"
                :error="form.errors.get('audience.target')"
                :should-show-error="form.errors.has('audience.target')"
            >
                <select-list v-model="form.audience.target">
                    <select-option
                        id="all"
                        :label="`All invitees and confirmed guests (${allInviteesAndGuestsCount.toLocaleString()})`"
                        tooltip="Include ALL guests in invite list AND any off-list guests who have replied as well."
                        class="my-2"
                    ></select-option>

                    <select-option
                        id="secondary-event"
                        :label="secondaryEventsOptionLabel"
                        tooltip="Include ALL guests who have been invited to and/or replied to a selected secondary event."
                        class="my-2"
                        :disabled="!availableSecondaryEvents.length"
                    ></select-option>

                    <form-field-wrapper
                        v-if="form.audience.target === 'secondary-event'"
                        class="mb-4"
                        :error="form.errors.get('audience.id')"
                        :should-show-error="form.errors.has('audience.id')"
                    >
                        <simple-picker
                            v-model="form.audience.id"
                            :items="availableSecondaryEvents"
                            item-label="name"
                            item-value="id"
                            placeholder-empty-state="Select secondary event..."
                        ></simple-picker>
                    </form-field-wrapper>

                    <select-option
                        id="tagged"
                        :label="tagsOptionLabel"
                        tooltip="Include ALL invitees and guests who have replied that are tagged with selected tag."
                        class="my-2"
                        :disabled="!availableTags.length"
                    ></select-option>

                    <form-field-wrapper
                        v-if="form.audience.target === 'tagged'"
                        :error="form.errors.get('audience.id')"
                        :should-show-error="form.errors.has('audience.id')"
                    >
                        <simple-picker
                            v-model="form.audience.id"
                            :items="availableTags"
                            item-label="name"
                            item-value="id"
                            placeholder-empty-state="Select tag..."
                        ></simple-picker>
                    </form-field-wrapper>
                </select-list>
            </form-field-wrapper>

            <transition name="slide-fade-vertical">
                <div v-if="showLargeSeatingChartWarning" class="alert alert-warning text-center my-4">
                    Creating large seating charts is not recommended and you will not be able to seat more than 2,000 people.
                </div>
            </transition>

            <div class="mt-8 mb-4">
                <p class="uppercase tracking-wide text-lg font-semibold text-gray-600">Add tables</p>
                <p class="text-gray-500 text-sm">(You can add, edit, and customize tables later.)</p>
            </div>

            <form-field-wrapper
                label="Number of tables"
                :error="form.errors.get('numberOfSeatingAreas')"
                :should-show-error="form.errors.has('numberOfSeatingAreas')"
            >
                <input
                    v-model.number="form.numberOfSeatingAreas"
                    class="form-field"
                    type="number"
                    min="0"
                    max="500"
                >
            </form-field-wrapper>

            <form-field-wrapper
                label="Seats per table"
                :error="form.errors.get('numberOfInitialSeats')"
                :should-show-error="form.errors.has('numberOfInitialSeats')"
            >
                <input
                    v-model.number="form.numberOfInitialSeats"
                    class="form-field"
                    type="number"
                    min="0"
                    max="500"
                >
            </form-field-wrapper>

            <stateful-button
                class="button button-primary button-lg w-full mt-8"
                :loading="form.processing"
                @click="createSeatingChart"
            >
                Create seating chart
            </stateful-button>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import Form from '@/validation/Form';

export default {
    name: 'CreateSeatingChart',

    props: {
        availableAudience: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            form: new Form({
                title: null,
                audience: {
                    target: null,
                    id: null
                },
                numberOfSeatingAreas: 20,
                numberOfInitialSeats: 8
            })
        };
    },

    computed: {
        ...get('Event/*'),

        allInviteesAndGuestsCount () {
            return this.availableAudience.find((audience) => { return audience.target === 'all'; }).numberOfSeatables;
        },

        availableSecondaryEvents () {
            return this.availableAudience.filter((audience) => { return audience.target === 'secondary-event'; });
        },

        availableTags () {
            return this.availableAudience.filter((audience) => { return audience.target === 'tagged'; });
        },

        selectedSecondaryEventTarget () {
            return this.availableAudience.find((audience) => {
                return audience.target === 'secondary-event' && audience.id === this.form.audience.id;
            });
        },

        selectedTagTarget () {
            return this.availableAudience.find((audience) => {
                return audience.target === 'tagged' && audience.id === this.form.audience.id;
            });
        },

        secondaryEventsOptionLabel () {
            if (this.form.audience.target === 'secondary-event' && this.form.audience.id) {
                return `Guests and invitees from ${this.selectedSecondaryEventTarget.name} (${this.selectedSecondaryEventTarget.numberOfSeatables.toLocaleString()})`;
            }

            return 'Guests and invitees from secondary event...';
        },

        showLargeSeatingChartWarning () {
            if (this.form.audience.target === 'secondary-event' && this.form.audience.id) {
                return this.selectedSecondaryEventTarget.numberOfSeatables > 2000;
            }

            if (this.form.audience.target === 'tagged' && this.form.audience.id) {
                return this.selectedTagTarget.numberOfSeatables > 2000;
            }

            if (this.form.audience.target === 'all') {
                return this.allInviteesAndGuestsCount > 2000;
            }

            return false;
        },

        tagsOptionLabel () {
            if (this.form.audience.target === 'tagged' && this.form.audience.id) {
                return `Guests and invitees tagged with ${this.selectedTagTarget.name} (${this.selectedTagTarget.numberOfSeatables.toLocaleString()})`;
            }

            return 'Guests and invitees tagged with...';
        }
    },

    watch: {
        'form.audience.target': function () {
            this.form.audience.id = null;
        }
    },

    methods: {
        createSeatingChart () {
            this.form.post(this.route('api.events.seating-charts.store', this.event))
                .then(this.handleSuccessfulCreation)
                .catch(this.handleFailedCreation);
        },

        handleFailedCreation (error) {
            if (error.response.data.reason === 'exceededLimit') {
                const { title, message } = error.response.data.upgradeMessage;
                App.alert().paymentRequired(title, message);
                return;
            }

            this.$toasted.global.error('There was a problem creating seating chart.');
        },

        handleSuccessfulCreation ({ data }) {
            this.$toasted.global.success('Seating chart successfully created.');

            const launchCreatedSeatingChart = () => {
                window.location.href = this.route('events.seating-charts.index', {
                    event: this.event,
                    newSeatingChartId: data.seatingChart.id
                });
            };

            if (!data.showTryOnlyModal) {
                launchCreatedSeatingChart();
                return;
            }

            const attributes = {
                showCancelButton: false,
                confirmButtonText: 'Try now'
            };

            App.alert().paymentRequired(
                'Try it free.',
                `Seating Charts are free to try. Upgrade to seat additional guests.`,
                attributes,
                launchCreatedSeatingChart
            );
        }
    }
};
</script>
