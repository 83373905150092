/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-graph-cured-increasing-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.755 22.5h-21v-21"/><path pid="1" d="M15.75 6v0c0-.56-.45-1-1-1h-2.5V2.5v0c0-.56-.45-1-1-1H9.74c-.56 0-1 .44-1 1 0 0 0 0 0 0V5H6.23c-.56 0-1 .44-1 1 0 0 0 0 0 0v1.5h0c0 .55.44.99 1 .99h2.5v2.5h0c0 .55.44.99 1 .99h1.5l-.01-.001c.55 0 1-.45 1-1v-2.5h2.5l-.01-.001c.55 0 1-.45 1-1zM.755 19.512h2.7c8.9 0 16.06-3.39 17.955-12.762"/><path pid="2" d="M18.571 8.582l2.84-1.84 1.83 2.84"/></g>'
  }
})
