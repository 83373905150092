<template>
    <div>
        <button
            v-if="! hasAdvancedExportOptions"
            class="flex items-center bg-white rounded-md border p-2 hover:border-purple hover:cursor-pointer text-gray-600 hover:text-purple"
            @click="exportSpreadsheet"
        >
            <app-icon
                name="download-circle"
                class="w-5 h-5 mr-2"
                stroke
            ></app-icon>
            Export
        </button>

        <base-dropdown
            v-else
            placement="bottom-end"
        >
            <template slot="trigger" slot-scope="{ triggerFunction }">
                <button
                    class="flex items-center bg-white rounded-md border p-2 hover:border-purple hover:cursor-pointer text-gray-600 hover:text-purple"
                    @click="triggerFunction"
                >
                    <app-icon
                        name="download-circle"
                        class="w-5 h-5 mr-2"
                        stroke
                    ></app-icon>
                    Export
                </button>
            </template>

            <div class="p-2 bg-white border shadow flex flex-col w-64 tracking-wider">
                <form-field-wrapper label="Columns">
                    <select-list
                        v-model="columnExportType"
                        class="space-y-2"
                    >
                        <select-option
                            id="all"
                            label="All columns"
                        >
                        </select-option>
                        <select-option
                            id="selected"
                            label="Just visible columns"
                        >
                        </select-option>
                    </select-list>
                </form-field-wrapper>

                <div class="pt-2">
                    <form-field-wrapper
                        label="Rows"
                    >
                        <select-list
                            v-model="rowExportType"
                            class="space-y-2"
                        >
                            <select-option
                                id="all"
                                label="All rows"
                            >
                            </select-option>
                            <select-option
                                id="filtered"
                                label="Filtered only"
                            >
                            </select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <button
                    class="button button-primary w-full text-sm h-4 mt-4"
                    @click="exportSpreadsheet"
                >
                    Download Spreadsheet
                </button>
            </div>
        </base-dropdown>

        <export-button-modal
            v-model="isModalVisible"
            :state="state"
            :download-url="downloadUrl"
            @closed="isModalVisible = false"
        ></export-button-modal>
    </div>
</template>

<script>
import Export from '@/mixins/Export';

export default {
    mixins: [Export]
};
</script>
