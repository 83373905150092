/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-visible-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.82 13.5a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM16.25 10.93h1.15M16.82 10.93v2.57M20.66 12.28l.81.81M21.07 12.69l-1.82 1.82M22.82 16.36v1.14M22.82 16.93h-2.57M21.47 20.77l-.81.81M21.07 21.17l-1.82-1.82M17.4 22.93h-1.15M16.82 22.93v-2.57M12.98 21.58l-.8-.81M12.58 21.17l1.82-1.82M10.82 17.5v-1.14M10.82 16.93h2.58M12.18 13.09l.8-.81M12.58 12.69l1.82 1.82M22.093 9.693c.25-.26.5-.51.73-.77v-.01c.56-.64.56-1.6-.01-2.23-2.63-2.89-6.8-5.71-10.83-5.64C7.95.96 3.78 3.793 1.16 6.67h-.01c-.57.63-.57 1.59 0 2.22h0c1.76 1.96 3.91 3.54 6.31 4.64"/><path pid="1" d="M9.256 10.37l-.01-.01a3.76 3.76 0 01.18-5.31c1.51-1.42 3.88-1.33 5.3.18.75.81 1.11 1.92.97 3.02"/></g>'
  }
})
