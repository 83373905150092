/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'night-moon-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" fill-rule="evenodd" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.437 11.95h-.01c0-4.92 3.18-9.26 7.87-10.74l-.01-.01C13.357-.67 7.03 2.63 5.177 8.56 3.3 14.48 6.6 20.8 12.53 22.66c2.19.68 4.54.68 6.74-.01h0a11.269 11.269 0 01-7.88-10.74z"/>'
  }
})
