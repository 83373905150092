<template>
    <div class="container">
        <section class="panel">
            <page-header icon="megaphone-announce" label="Site Announcements">
                <a :href="route('admin.dashboard')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <div class="flex items-center mt-4">
                <div class="grow md:grow-0">
                    <search-field
                        v-model="searchTerm"
                        class="w-full md:w-80"
                        placeholder="Find an announcement..."
                    ></search-field>
                </div>

                <div class="flex-auto text-right ml-4">
                    <a :href="route('admin.announcements.create')" class="button-icon button-lg button-info">
                        <app-icon name="add-circle"></app-icon>
                    </a>
                </div>
            </div>

            <data-table
                class="mt-4 overflow-x-auto"
                :columns="columns"
                :rows="filteredAnnouncements"
                no-results-placeholder="No announcements found matching your criteria."
                primary-key="id"
            >
                <template slot="row" slot-scope="{ row, property, value }">
                    <div v-if="property === 'title'">
                        <span v-if="!!value">{{ value }}</span>
                    </div>

                    <!--Note that the Options column is a "fake" column/property name and it does not actually have a label -->
                    <div
                        v-else-if="property === 'options'"
                        class="flex justify-end items-center"
                    >
                        <a href="#" class="button-icon">
                            <app-icon name="view"></app-icon>
                        </a>
                        <a
                            :href="route('admin.announcements.edit', row)"
                            class="button-icon mx-4"
                        >
                            <app-icon name="edit"></app-icon>
                        </a>
                        <delete-item
                            class="button-icon hover:text-red"
                            :endpoint="route('admin.announcements.destroy', row)"
                            @delete-item="itemDeleted(announcements, index)"
                            @error="deleteError('Something went wrong while deleting this announcement.')"
                        >
                            <app-icon name="trash" class="h-5 w-5"></app-icon>
                        </delete-item>
                    </div>

                    <div v-else>
                        {{ value }}
                    </div>
                </template>
            </data-table>
        </section>
    </div>
</template>

<script>
import truncate from 'lodash/truncate';
import Deleteable from '@/mixins/Deleteable';

export default {
    mixins: [Deleteable],

    props: {
        initialAnnouncements: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            announcements: this.initialAnnouncements,
            columns: [
                {
                    label: 'Title', property: 'title', desktop: true, mobile: true, sortable: true, minWidth: '150px'
                },
                {
                    label: '', property: 'options', desktop: true, mobile: true, minWidth: '100px'
                }
            ],
            searchTerm: ''
        };
    },

    computed: {
        filteredAnnouncements () {
            return this.announcements.filter((announcement) => {
                const searchRegex = new RegExp(this.searchTerm, 'i');

                return searchRegex.test(`${announcement.title}`);
            });
        }
    },

    methods: {
        rowStyle (index) {
            return {
                'bg-gray-100': index % 2 === 0
            };
        },

        formatContent (content) {
            const cleanedContent = this.excludeHtmlTagsFromText(content);

            return truncate(cleanedContent, {
                length: 100
            });
        },

        excludeHtmlTagsFromText (text) {
            return text.replace(/<\/?[^>]+(>|$)/g, '');
        }
    }
};
</script>
