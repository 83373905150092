/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vip-seating-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 6.75v-6M20.25 6.75v-6M20.25.75H21h-.01c1.24-.01 2.25 1 2.25 2.24 0 1.24-1.01 2.25-2.25 2.25h-.75M11.25.75V1.8l-.01-.01c0 1.76.52 3.48 1.49 4.95v-.01a9.03 9.03 0 001.5-4.96V.72M6 2.24a2.62 2.62 0 100 5.25 2.62 2.62 0 100-5.25zM13.667 23.241v-.01c-.83.09-1.57-.5-1.66-1.33l-.01-.01-.38-3.4-.01-.01c-.11-.74-.75-1.28-1.49-1.28H7.98V17.2c-1.47 0-2.72-1.06-2.96-2.5l-.55-3.25h0a1.492 1.492 0 112.94-.51l.54 3.24h2.128-.01a4.469 4.469 0 014.44 3.8c0 .04.4 3.526.4 3.526l-.01-.01c.09.82-.5 1.56-1.33 1.66h-.01zM.75 23.25h7.382l-.01-.001c.49 0 .9-.41.9-.9 0-.04-.01-.07-.01-.11l-.14-1.22h0a.887.887 0 00-.88-.79h-.01c-2.94 0-5.45-2.12-5.92-5.01l-.34-1.99h0c-.1-.57-.5-1.03-1.04-1.19"/></g>'
  }
})
