/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hotel-double-bed-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M3 11.25V9h0c-.01-.83.67-1.51 1.49-1.51h6-.01c.82-.01 1.5.67 1.5 1.49 0 0 0 0 0 0v2.25M12 11.25V9h0c-.01-.83.67-1.51 1.49-1.51h6-.01c.82-.01 1.5.67 1.5 1.49 0 0 0 0 0 0v2.25"/><path pid="1" d="M2.75 11.25h18.5-.01c1.1-.01 2 .89 2 1.99v4.75H.74v-4.75h0c-.01-1.11.89-2.01 1.99-2.01zM.75 18v3M23.25 18v3"/><path pid="2" d="M21 11.25V4.5v0c0-.83-.68-1.5-1.5-1.5H4.49c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v6.75"/></g>'
  }
})
