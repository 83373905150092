<template>
    <div>
        <div class="flex items-center">
            <tag
                v-for="(tag, index) in value"
                :key="tag.name"
                :name="tag.name"
                :deleteable="isEditable"
                @click="deleteTag(index)"
            ></tag>

            <button
                v-if="isEditable"
                class="button-text button-info text-sm"
                :class="{ 'ml-2': !!value.length }"
                @click.stop="showTagsModal = true"
            >
                <app-icon name="add" class="h-3 w-3 mr-2"></app-icon>

                <span>Add tag</span>
            </button>
        </div>

        <select-tags-modal
            v-model="showTagsModal"
            :group-size="groupSize"
            :tags="value"
            nested
            :z-index="110"
            @tag-group="newTags => $emit('tag-group', newTags)"
            @save-tags="newTags => $emit('input', newTags)"
        ></select-tags-modal>
    </div>
</template>

<script>
export default {
    name: 'TagList',

    props: {
        editable: {
            type: Boolean,
            default: true
        },
        groupSize: {
            type: Number,
            default: 1
        },
        value: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            showTagsModal: false
        };
    },

    computed: {
        isEditable () {
            return this.editable;
        }
    },

    methods: {
        deleteTag (deletedIndex) {
            if (!this.isEditable) {
                return;
            }

            this.$emit('input', this.value.filter((_, index) => { return index !== deletedIndex; }));
        }
    }
};
</script>
