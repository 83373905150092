export default {
    props: {
        blockId: {
            type: Number,
            default: 0
        },
        blockType: {
            type: String,
            default: ''
        }
    }
};
