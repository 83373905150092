/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music-note-3-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.96 15.06a3.75 3.75 0 100 7.5 3.75 3.75 0 100-7.5z"/><path pid="1" d="M12.712 18.81V5.39h-.01c0-1.3.82-2.44 2.05-2.85l4.02-1.117M12.71 7.4l5.6-1.68"/></g>'
  }
})
