<template>
    <div v-theme="'form.text'" class="px-4 py-8 group">
        <slot name="block-header"></slot>

        <tiptap-editor
            v-model="textHtml"
            :read-only="!isEditMode"
            show-embed-video-button
            hint-classes="-my-4 -mx-2"
            with-hint
        ></tiptap-editor>

        <slot name="block-footer" :complete-block="completeBlock"></slot>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import getValue from 'lodash/get';
import FormBlock from '@/mixins/FormBlock';
import SelfEditableBlockMixin from '@/mixins/SelfEditableBlockMixin';
import { groupPassesAskIf, wholeGroupPassesAskIf } from '@/util/ask-if';
import { groupPassesAdditionalCriteria } from '@/util/additional-criteria';

export default {
    name: 'FormTextBlock',

    mixins: [FormBlock, SelfEditableBlockMixin],

    behaviour: {
        render: {
            when (block, submission) {
                const { askIf, additionalCriteria } = block.pivot.settings;

                /**
                 * If askIf target is Not Attending, the Note Block can be submitted
                 * only if ALL guests in the group are not attending.
                 */
                const passingAskIfCriteria = getValue(askIf, 'target') === 'not-attending'
                    ? wholeGroupPassesAskIf(askIf, submission.guests)
                    : groupPassesAskIf(askIf, submission.guests);

                const passingAdditionalCriteria = groupPassesAdditionalCriteria(additionalCriteria, submission.guests);

                return passingAskIfCriteria && passingAdditionalCriteria;
            },
            unless (block, submission) {
                return false;
            }
        }
    },

    props: {
        block: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            blockId: this.block.pivot.id,
            textHtml: this.block.pivot.settings.html,
            syncSelfEditableSettings: {
                textHtml: 'html'
            }
        };
    },

    computed: {
        event: get('Event/event'),

        guests: get('Submission/submission@guests'),

        firstGuestEmail () {
            if (!this.guests || !this.guests.length) {
                return null;
            }

            return this.guests[0].email;
        },

        content () {
            return this.block.pivot.settings.html
                .replace('#EMAIL#', this.firstGuestEmail);
        }
    }
};
</script>
