/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'app-page-text': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>window browser layout design web</desc><g _fill="currentColor"><rect pid="0" width="16" height="3" x="4" rx=".5" y="8.25"/><path pid="1" d="M13.25 12.75h-.01c-.42 0-.75.33-.75.75 0 .41.33.75.75.75h6-.01a.749.749 0 100-1.5zM19.25 16.25h-6.01c-.42 0-.75.33-.75.75 0 .41.33.75.75.75h6-.01a.749.749 0 100-1.5z"/><rect pid="2" width="7" height="6.5" x="4" rx="1" y="12.75"/><path pid="3" d="M24 4.75c0-1.66-1.35-3-3-3H2.99c-1.66 0-3 1.34-3 3v14.5c0 1.65 1.34 2.99 3 2.99h18-.01c1.65 0 3-1.35 3-3zm-14.346-1v-.01c.25-.47.84-.64 1.31-.38.15.08.29.21.37.37l-.01-.01c.09.14.14.32.15.5h-.01c-.01.17-.06.35-.16.49V4.7a.976.976 0 01-1.7-.01l-.01-.01c-.1-.15-.15-.33-.16-.5v-.01c0-.18.05-.36.15-.51zm-3.5 0v-.01c.25-.47.84-.64 1.31-.38.15.08.29.21.37.37l-.01-.01c.09.14.14.32.15.49h-.01c-.01.17-.06.35-.16.49v-.01a.976.976 0 01-1.7-.01l-.01-.01c-.1-.15-.15-.33-.16-.51v-.01c0-.18.05-.36.15-.5zm-3.57.09h-.01c.16-.36.51-.6.9-.6l-.01-.001c.35 0 .67.19.84.49l-.01-.01c.09.14.14.32.15.5h-.01c-.01.17-.06.35-.16.49v-.01a.976.976 0 01-1.7-.01l-.01-.01c-.1-.15-.15-.33-.16-.5h-.01c0-.15.03-.29.09-.41zm19.408 15.4c0 .55-.45 1-1 1H2.982c-.56-.01-1-.45-1-1.01V6.98c-.01-.14.11-.26.24-.26h19.5-.01c.13-.01.25.11.25.24z"/></g>'
  }
})
