/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-pinterest-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12.63.5C6.35.5 3.19 5 3.19 8.752c0 2.27.86 4.3 2.7 5.04l-.01-.01c.22.11.49.03.61-.19.02-.05.03-.1.04-.15.06-.24.2-.82.27-1.061v-.01c.1-.27.02-.56-.2-.74h0a3.78 3.78 0 01-.88-2.59l-.01-.01a6.25 6.25 0 016.18-6.34c.1-.01.21 0 .31 0 3.548 0 5.5 2.16 5.5 5.06 0 3.808-1.69 7.02-4.19 7.02h-.01c-1.13.05-2.09-.82-2.15-1.95-.02-.21 0-.41.05-.61.4-1.68 1.16-3.48 1.16-4.69v-.01c.11-.98-.58-1.86-1.55-1.98-.08-.01-.16-.02-.24-.02-1.42 0-2.548 1.46-2.548 3.42h-.01c-.01.71.13 1.43.42 2.08l-1.7 7.2h-.01c-.3 1.65-.32 3.35-.04 5.01h0c.01.09.1.16.2.14.04-.01.08-.04.11-.07v-.01c1.04-1.3 1.84-2.76 2.38-4.33.16-.59.92-3.64.92-3.64l-.01-.01a3.758 3.758 0 003.22 1.64c4.24-.01 7.12-3.88 7.12-9.06 0-3.92-3.32-7.57-8.37-7.57z"/>'
  }
})
