<template>
    <input v-model="answer" class="w-full form-field">
</template>

<script>
import CustomQuestionEditComponent from '@/mixins/CustomQuestionEditComponent';

export default {
    name: 'CustomQuestionAnswerText',

    mixins: [CustomQuestionEditComponent]
};
</script>
