/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-protection-face-mask-3-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7 11.12h-.01c1.65-.28 3.32-.45 5-.51M7 14.752l-.01-.01c1.64.3 3.32.47 4.99.5"/><path pid="1" d="M12 19.5l-.01-.01c2.74.07 5.47-.44 8-1.5v-9s-4.9-4.5-8-4.5c-3.1 0-8 4.5-8 4.5v9h0a19.2 19.2 0 008 1.49"/><path pid="2" d="M17 11.12h0c-1.66-.28-3.33-.45-5-.51M17 14.752v-.01c-1.65.3-3.33.47-5 .49M4 18l-.01-.01a6.846 6.846 0 01-3.25-5.82V8.03l-.01-.01c-.01-.6.48-1.09 1.08-1.09.02-.01.05 0 .08 0l-.01-.01c.43.03.83.25 1.08.61l1 1.41M20 18v-.01a6.86 6.86 0 003.24-5.82V8.03c0-.6-.49-1.09-1.09-1.09H22.05c-.44.03-.84.25-1.09.61l-1 1.41"/></g>'
  }
})
