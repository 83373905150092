/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-clock-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>event date time appointment</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25 17.25H2.24a1.51 1.51 0 01-1.5-1.5V3.749h0c-.01-.83.67-1.51 1.49-1.51h13.5-.01c.82-.01 1.5.67 1.5 1.5v4.5M.75 6.75h16.5M5.25 3.75v-3M12.75 3.75v-3"/><path pid="1" d="M17.25 11.25a6 6 0 100 12 6 6 0 100-12z"/><path pid="2" d="M19.9 17.25h-2.66v-2.651"/></g>'
  }
})
