/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bread-slice-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>loaf food wheat gluten</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.5 8.23h.19-.01c1.96-.01 3.55-1.6 3.55-3.56v-.39l-.01-.01A3.568 3.568 0 0019.66.71H4.27V.709a3.541 3.541 0 00-3.56 3.55v.38L.7 4.62a3.55 3.55 0 003.55 3.55h.19"/><path pid="1" d="M3.75 8.192v13.544h0c0 .82.67 1.49 1.5 1.49h13.5-.01c.82 0 1.5-.68 1.5-1.5V8.17M7.31 12.17l3.19-3.18M8.44 16.3l6.61-6.61M12.75 17.24l3.19-3.19"/></g>'
  }
})
