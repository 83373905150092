<template>
    <div class="flex grow items-center">
        <a
            class="button flex-1 mr-2"
            role="button"
            @click="$emit('cancel')"
        >
            {{ cancelButtonText }}
        </a>

        <stateful-button
            class="button-primary flex-1 ml-2"
            :loading="processing"
            @click="$emit('save')"
        >
            {{ confirmButtonText }}
        </stateful-button>
    </div>
</template>

<script>
export default {
    name: 'FooterSaveCancel',

    props: {
        cancelButtonText: {
            type: String,
            default: 'Cancel'
        },
        confirmButtonText: {
            type: String,
            default: 'Save'
        },
        processing: {
            type: Boolean,
            default: false
        }
    }
};
</script>
