/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-champagne-cheers': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>bar alcohol bubbly prosecco celebration mimosa</desc><g _fill="currentColor"><path pid="0" d="M22.382 21.3l-1 .144a.5.5 0 01-.566-.419l-.492-3.17a.5.5 0 01.289-.533 5.041 5.041 0 002.888-5.365C21.73 4.38 22.464 7.511 20.974 1.17A1.492 1.492 0 0019.3.02l-3.647.53a.225.225 0 00-.053.429 2.741 2.741 0 011.283 1.1.5.5 0 00.5.236l1.3-.189a.5.5 0 01.559.38l.574 2.445a.5.5 0 01-.415.609l-2.485.358a.5.5 0 00-.4.339L13.9 14.2a.5.5 0 00.008.333 4.947 4.947 0 004.009 3.167.5.5 0 01.43.419l.493 3.181a.5.5 0 01-.422.572l-.985.142a1 1 0 10.287 1.978l4.948-.715a1 1 0 10-.286-1.978zm-5.091-8.307a1.022 1.022 0 01-.145.011 1 1 0 11.145-.011zm1.673-3.133a1 1 0 01-.141-1.989 1.022 1.022 0 01.145-.011 1 1 0 01.141 1.99 1.191 1.191 0 01-.145.01zm.32 4.148a1 1 0 01.846-1.132 1.019 1.019 0 01.144-.011 1 1 0 11-.99 1.143z"/><path pid="1" d="M15.948 3.97v-.005a1.483 1.483 0 00-.839-1.834l-4.68-2A1.5 1.5 0 008.526.8l-2.39 4.469-2.867 5.387A5.007 5.007 0 004.711 16.5a.5.5 0 01.14.595l-1.438 3.156a.5.5 0 01-.651.252l-.931-.4a1 1 0 00-.786 1.84l4.6 1.965a1 1 0 00.786-1.84l-.908-.388a.5.5 0 01-.259-.667L6.7 17.871a.5.5 0 01.521-.288 4.875 4.875 0 00.639.042 5.006 5.006 0 004.628-3.125l1.88-5.72zm-9.091 9.682a1 1 0 11-.527-1.312 1 1 0 01.527 1.312zm.711-5.285a1 1 0 01.921-.607.984.984 0 01.391.08 1 1 0 11-1.312.527zm2.789 4.285a1 1 0 11-.527-1.312 1 1 0 01.527 1.312zm2.536-5.789a.252.252 0 01-.134.15.247.247 0 01-.2 0L8.665 5.356a.249.249 0 01-.122-.348l1.421-2.657a.248.248 0 01.318-.112l3.41 1.461a.25.25 0 01.139.308z"/></g>'
  }
})
