/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-classic-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 12a3.751 3.751 0 013.124-3.7.749.749 0 00.626-.739V5.25a1.5 1.5 0 00-1.5-1.5H2.25a1.5 1.5 0 00-1.5 1.5v2.313a.749.749 0 00.626.739 3.751 3.751 0 010 7.4.75.75 0 00-.626.739v2.309a1.5 1.5 0 001.5 1.5h19.5a1.5 1.5 0 001.5-1.5v-2.313a.749.749 0 00-.626-.739A3.751 3.751 0 0119.5 12zM12 9h5.25" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><circle pid="1" cx="15.375" cy="14.625" r="1.875" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="2" d="M7.5 7.875a.375.375 0 11.375.375.375.375 0 01-.375-.375M7.5 12.375a.375.375 0 11.375.375.375.375 0 01-.375-.375M7.5 16.875a.375.375 0 11.375.375.375.375 0 01-.375-.375" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>'
  }
})
