<template>
    <div>
        <test-mode-limited-capacity-alert></test-mode-limited-capacity-alert>

        <div class="w-full md:w-1/2 p-2 bg-white rounded-md border">
            <simple-picker
                :value="selectedMealBlock"
                :items="mealBlockChoices"
                item-label="title"
                placeholder-empty-state="Select meal block..."
                @input="selectMealBlock"
            ></simple-picker>
        </div>

        <section class="panel mt-4 text-center">
            <app-icon
                v-if="chart.loading"
                class="h-6 w-6 text-gray-500"
                name="loader"
            ></app-icon>

            <doughnut-chart v-else :data="chart.data"></doughnut-chart>
        </section>

        <dashboard-data
            :always-loaded-columns="['formalName', mealChoiceColumnId]"
            :custom-column-definitions="customColumnDefinitions"
            :default-columns="['firstName', 'lastName', 'email', mealChoiceColumnId]"
            :event="event"
        >
            <template #summary="{ row }">
                <p>{{ row.formalName }}</p>
                <p class="text-sm mt-2">{{ row[mealChoiceColumnId] }}</p>
            </template>
        </dashboard-data>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import axios from '@/util/axios';

export default {
    name: 'DashboardMealsTable',

    props: {
        mealBlocks: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            chart: {
                loading: true,
                data: {}
            },
            selectedMealBlock: null
        };
    },

    computed: {
        customColumnDefinitions () {
            return [
                {
                    property: this.mealChoiceColumnId,
                    label: this.selectedMealBlock.title,
                    sortable: false,
                    alwaysActive: true
                }
            ];
        },

        event: get('Event/event'),

        mealBlockChoices () {
            return this.mealBlocks.map(({ pivot }) => {
                return {
                    id: pivot.id,
                    title: pivot.settings.title
                };
            });
        },

        mealChoiceColumnId () {
            return `mealChoice-${this.selectedMealBlock.id}`;
        }
    },

    watch: {
        selectedMealBlock () {
            this.fetchChartData();
        }
    },

    created () {
        this.selectedMealBlock = this.mealBlockChoices[0];
    },

    methods: {
        async fetchChartData () {
            this.chart.loading = true;

            try {
                const { data } = await axios.get(this.route('api.dashboard.meal-preferences.chart', {
                    event: this.event,
                    mealBlock: this.selectedMealBlock.id
                }));

                this.chart.data = {
                    datasets: [{
                        data: data.data.map(({ selectionCount }) => {
                            return selectionCount;
                        })
                    }],
                    labels: data.data.map(({ title, selectionCount }) => {
                        return {
                            item: title,
                            totalItems: selectionCount
                        };
                    })
                };
            } finally {
                this.chart.loading = false;
            }
        },

        selectMealBlock (newMealBlock) {
            if (newMealBlock != null) {
                this.selectedMealBlock = newMealBlock;
            }
        }
    }
};
</script>
