/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-attention-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 17.25v6M4.25 22.25l-2-2 2-2M23.25 17.25v6M19.75 22.25l2-2-2-2M21.75 20.25H2.25M12 11.52h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.38-.38-.375v0M12 9.02V5.64"/><path pid="1" d="M18.269 15.25l-.01-.001c.54 0 .98-.44.98-.99 0-.15-.04-.3-.11-.44L13.01 1.37h0a1.174 1.174 0 00-2.1-.01L4.8 13.79h-.01a.981.981 0 00.44 1.31c.13.06.28.1.43.1z"/></g>'
  }
})
