/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>back</desc><g _fill="currentColor"><path pid="0" d="M12 0h-.01c-6.63 0-12 5.37-12 12 0 6.62 5.37 12 12 12 6.62-.01 12-5.38 12-12l-.01-.01c-.01-6.63-5.38-12-12.01-12zm0 2h-.01c5.52-.01 10 4.47 10 9.99 0 5.52-4.48 10-10 10-5.53 0-10-4.48-10.01-10h-.01c0-5.53 4.47-10 9.99-10.01z"/><path pid="1" d="M11.88 8.38l-1.939 1.94h-.01c-.1.09-.1.25-.01.35.04.04.11.07.17.07h7.88-.01c.69-.01 1.25.55 1.25 1.24s-.56 1.25-1.25 1.25h-7.88v-.001a.248.248 0 00-.18.42l1.939 1.94c.48.48.48 1.27-.01 1.76-.49.48-1.28.48-1.77-.01l-4.5-4.5c-.49-.49-.49-1.28-.01-1.77v-.01l4.5-4.5h-.01c.48-.49 1.27-.49 1.76-.01.48.48.48 1.27 0 1.76z"/></g>'
  }
})
