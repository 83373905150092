import Form from '@/validation/Form';

export default {
    props: {
        block: {
            type: Object,
            required: true
        },
        event: {
            type: Object,
            required: true
        },
        settings: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            form: new Form(this.settings)
        };
    },

    mounted () {
        this.$emit('input', this.form);
    },

    watch: {
        form: {
            deep: true,
            handler () {
                this.$emit('input', this.form);
            }
        }
    }
};
