/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>new plus create</desc><path pid="0" _fill="currentColor" d="M0 12c0 .82.67 1.49 1.5 1.49h8.75-.01c.13-.01.25.11.25.24v8.75c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51v-8.75c-.01-.14.11-.26.24-.26h8.75l-.01-.001c.82 0 1.5-.68 1.5-1.5 0-.83-.68-1.5-1.5-1.5H13.7a.263.263 0 01-.25-.26V1.43c0-.83-.68-1.5-1.5-1.5-.83 0-1.5.67-1.5 1.5v8.75c0 .13-.12.25-.25.25H1.44c-.83 0-1.5.67-1.5 1.5z"/>'
  }
})
