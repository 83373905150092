<template>
    <div
        class="app-drawer"
        :class="drawerClasses"
    >
        <div v-if="value" class="flex flex-col justify-between h-full">
            <div class="flex items-center px-4 py-2 border-b">
                <div class="flex items-center flex-auto space-x-3">
                    <slot name="title-bar"></slot>
                </div>

                <div class="shrink ml-2">
                    <slot name="title-buttons" :slot-props="{ defaultCloseHandler: close }">
                        <a
                            class="leading-0 text-gray-500 transition duration-150 ease-in-out hover:text-gray-600"
                            role="button"
                            @click="closeFromTitleBar"
                        >
                            <app-icon name="close" class="h-4 w-4"></app-icon>
                        </a>
                    </slot>
                </div>
            </div>

            <div class="app-drawer-main">
                <div class="w-full h-full"> <!-- This div is required to enable the slot to expand to full size -->
                    <slot></slot>
                </div>
            </div>

            <div :class="footerClasses">
                <portal-target
                    name="app-drawer-footer"
                    class="flex grow justify-center"
                    :slot-props="{ defaultCloseHandler: close }"
                    :transition="fadeTransition"
                    slim
                ></portal-target>
            </div>
        </div>

        <portal v-if="isOpen" to="body-portal-target">
            <slot name="overlay" :click-outside-of-drawer="clickOutsideOfDrawer">
                <div
                    class="fixed w-screen h-screen z-10 bg-smoke-800"
                    @click="clickOutsideOfDrawer"
                ></div>
            </slot>
        </portal>
    </div>
</template>

<script>
import BodyElementClassesHelper from '@/mixins/BodyElementClassesHelper';

export default {
    name: 'AppDrawer',

    mixins: [BodyElementClassesHelper],

    props: {
        defaultCloseHandler: {
            type: Function,
            default: null
        },

        ignoreOutsideClick: {
            type: Boolean,
            default: false
        },

        outsideClickHandler: {
            type: Function,
            default: null
        },

        showFooter: {
            type: Boolean,
            default: true
        },

        side: {
            type: String,
            default: 'left'
        },

        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            isOpen: this.value
        };
    },

    computed: {
        drawerClasses () {
            return {
                open: this.isOpen,
                left: this.side === 'left',
                right: this.side === 'right'
            };
        },

        fadeTransition () {
            return {
                functional: true,
                render (h, context) {
                    return h('transition', { props: { name: 'fade', mode: 'out-in' } }, context.children);
                }
            };
        },

        footerClasses () {
            return {
                'app-drawer-footer': this.showFooter
            };
        }
    },

    watch: {
        isOpen (newVal) {
            this.toggleBodyElementClasses(['overflow-hidden', 'sm:overflow-auto'], newVal);

            this.$emit('input', newVal);
        },

        value (newVal) {
            setTimeout(() => {
                this.isOpen = newVal;
            }, 100);
        }
    },

    methods: {
        clickOutsideOfDrawer () {
            if (!this.isOpen || this.ignoreOutsideClick) {
                return;
            }

            const actualOutsideClickHandler = this.outsideClickHandler ? this.outsideClickHandler : this.close;

            actualOutsideClickHandler();
        },

        close () {
            if (this.defaultCloseHandler) {
                this.defaultCloseHandler();
            } else {
                // Default close behavior
                this.isOpen = false;
            }

            this.$emit('close-drawer');
        },

        closeFromTitleBar () {
            if (this.outsideClickHandler) {
                this.outsideClickHandler();
            } else {
                // Default close from title bar behavior
                this.close();
            }
        }
    }
};
</script>
