/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alert-triangle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>exclamation warning notification</desc><path pid="0" _fill="currentColor" d="M23.11 20L13.76 2.15l-.01-.01c-.52-.98-1.73-1.36-2.7-.85-.37.18-.66.48-.85.84L.85 19.98v-.01a1.981 1.981 0 001.76 2.91H21.3c1.1 0 2-.9 2-2 0-.33-.08-.65-.23-.93zM10.99 8.423a.99.99 0 01.99-1.01c.55-.01 1 .44 1 .99v6c0 .55-.45 1-1 1-.56 0-1-.45-1-1zm1.05 11.51h-.03c-.82-.01-1.5-.66-1.53-1.47-.03-.82.6-1.5 1.42-1.53 0-.01.01-.01.02-.01h.02l-.01-.001c.81 0 1.49.64 1.52 1.46.03.81-.61 1.49-1.42 1.53h-.04z"/>'
  }
})
