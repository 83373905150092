<template>
    <div class="w-full">
        <div class="flex items-center">
            <div class="relative mr-2 md:mr-0">
                <input
                    v-model="search.term"
                    type="text"
                    class="form-field outline-none pl-18 pr-10"
                    :placeholder="searchPlaceholder"
                >

                <button
                    v-tippy
                    class="absolute inset-y-0 flex items-center px-2 button-icon"
                    :class="searchBarStyles.seatablesIcon"
                    content="Search seated guests by name."
                    @click="search.target = 'seated-seatable'"
                >
                    <app-icon
                        class="w-5 h-5"
                        name="team-meeting"
                        stroke
                    ></app-icon>
                </button>

                <button
                    v-tippy
                    class="absolute inset-y-0 ml-8 flex items-center pl-2 pr-4 button-icon"
                    :class="searchBarStyles.seatingAreaIcon"
                    content="Search tables by name."
                    @click="search.target = 'seating-area-name'"
                >
                    <app-icon
                        class="w-5 h-5"
                        name="chair"
                        stroke
                    ></app-icon>
                </button>

                <button
                    v-if="search.term"
                    class="absolute inset-y-0 right-0 flex items-center pl-4 pr-2 button-icon button-sm"
                    @click="search.term = ''"
                >
                    <app-icon class="w-5 h-5" name="close"></app-icon>
                </button>
            </div>

            <div class="flex flex-1 justify-end space-x-2">
                <button
                    class="flex items-center bg-white rounded-md border p-2 text-gray-600 hover:border-purple hover:cursor-pointer hover:text-purple"
                    @click="toggleSeatingAreas"
                >
                    <app-icon
                        :name="expandSeatingAreasIconState"
                        class="w-5 h-5 mr-2"
                        stroke
                    ></app-icon>
                    {{ toggleSeatingAreasText }}
                </button>

                <export-seating-chart-button
                    class="hidden md:block"
                    :seating-chart="seatingChart"
                ></export-seating-chart-button>

                <button class="button button-primary p-2" @click="createSeatingArea">
                    <app-icon
                        class="w-5 h-5 mr-2"
                        name="add-circle"
                        stroke
                    ></app-icon>
                    Add Table
                </button>
            </div>
        </div>

        <draggable
            v-model="sortableSeatingAreas"
            handle=".seating-area"
            :group="{ name: 'seating-area', pull: false, put: false }"
            :animation="150"
            :disabled="disableSeatingAreasReorder"
            class="flex flex-wrap max-h-screen-80 overflow-y-auto md:mt-4 md:-mb-4 md:-mx-2 select-none"
            :delay="500"
            :delay-on-touch-only="true"
        >
            <div
                v-for="seatingArea in filteredSeatingAreas"
                :key="seatingArea.id"
                class="w-full md:px-2 md:mb-4 lg:w-1/2 xl:w-1/3"
            >
                <seating-area
                    :expanded="seatingAreaExpandedState(seatingArea)"
                    :seating-area="seatingArea"
                    @toggle-expanded="toggleSeatingArea(seatingArea)"
                    @edit-seating-area="editSeatingArea(seatingArea)"
                ></seating-area>
            </div>
        </draggable>

        <seating-area-settings
            v-model="displaySettings"
            :initial-number-of-seats="seatingChart.number_of_initial_seats"
            :original-seating-area="seatingAreaToEdit"
        ></seating-area-settings>
    </div>
</template>

<script>
import { call, get } from 'vuex-pathify';
import { includes, forEach } from 'lodash';
import draggable from 'vuedraggable';

export default {
    name: 'SeatingAreas',

    components: { draggable },

    data () {
        return {
            displaySettings: false,
            seatingAreaToEdit: null,
            seatingAreasExpanded: true,
            seatingAreasExpandedStates: {},
            search: {
                term: '',
                target: 'seating-area-name'
            }
        };
    },

    computed: {
        ...get('Seating/*'),

        disableSeatingAreasReorder () {
            return this.saving || !!this.search.term;
        },

        expandSeatingAreasIconState () {
            return this.seatingAreasExpanded ? 'arrow-shrink' : 'arrow-expand';
        },

        filteredSeatingAreas () {
            if (!this.search.term) {
                return this.seatingAreas;
            }

            if (this.searchBySeatingAreaName) {
                return this.seatingAreasFilteredByName;
            }

            if (this.searchBySeatedSeatable) {
                return this.seatingAreasFilteredBySeatedSeatable;
            }
        },

        searchBarStyles () {
            return {
                seatablesIcon: {
                    'button-primary': this.searchBySeatedSeatable,
                    'button-soft': !this.searchBySeatedSeatable
                },

                seatingAreaIcon: {
                    'button-primary': this.searchBySeatingAreaName,
                    'button-soft': !this.searchBySeatingAreaName
                }
            };
        },

        searchBySeatedSeatable () {
            return this.search.target === 'seated-seatable';
        },

        searchBySeatingAreaName () {
            return this.search.target === 'seating-area-name';
        },

        seatingAreasFilteredByName () {
            return this.seatingAreas.filter((seatingArea) => {
                const searchRegex = new RegExp(this.search.term, 'i');
                return searchRegex.test(seatingArea.name);
            });
        },

        seatingAreasFilteredBySeatedSeatable () {
            const seatingAreaIdsOfMatchingSeatables = this.seatedSeatables.filter((seatable) => {
                const searchRegex = new RegExp(this.search.term, 'i');
                return searchRegex.test(seatable.fullName);
            }).map((seatable) => {
                return seatable.seating_area_id;
            });

            return this.seatingAreas.filter((seatingArea) => {
                return seatingAreaIdsOfMatchingSeatables.includes(seatingArea.id);
            });
        },

        searchPlaceholder () {
            return this.search.target === 'seated-seatable' ? 'Search seated...' : 'Search tables...';
        },

        sortableSeatingAreas: {
            get () {
                return this.seatingAreas;
            },

            set (seatingAreas) {
                this.reorder(seatingAreas);
            }
        },

        toggleSeatingAreasText () {
            return this.seatingAreasExpanded ? 'Collapse' : 'Expand';
        }
    },

    watch: {
        seatingAreasExpandedStates: {
            deep: true,
            handler (seatingAreasExpandedStates) {
                if (!includes(seatingAreasExpandedStates, true)) {
                    this.seatingAreasExpanded = false;
                }

                if (!includes(seatingAreasExpandedStates, false)) {
                    this.seatingAreasExpanded = true;
                }
            }
        }
    },

    methods: {
        createSeatingArea () {
            this.seatingAreaToEdit = null;
            this.displaySettings = true;
        },

        editSeatingArea (seatingArea) {
            this.seatingAreaToEdit = seatingArea;
            this.displaySettings = true;
        },

        reorder: call('Seating/reorderSeatingAreas'),

        seatingAreaExpandedState (seatingArea) {
            if (this.seatingAreasExpandedStates[seatingArea.id] === undefined) {
                this.$set(this.seatingAreasExpandedStates, seatingArea.id, true);
            }

            return this.seatingAreasExpandedStates[seatingArea.id];
        },

        toggleSeatingArea (seatingArea) {
            this.seatingAreasExpandedStates[seatingArea.id] = !this.seatingAreasExpandedStates[seatingArea.id];
        },

        toggleSeatingAreas () {
            forEach(this.seatingAreasExpandedStates, (expanded, id) => {
                this.seatingAreasExpandedStates[id] = !this.seatingAreasExpanded;
            });

            this.seatingAreasExpanded = !this.seatingAreasExpanded;
        }
    }
};
</script>
