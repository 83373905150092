/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-wash-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M1.1 19.61l3.04 2.44-.01-.01c.96.77 2.16 1.19 3.4 1.19h6.26l-.01-.001c1 0 1.81-.82 1.81-1.82 0 0 0 0 0 0v0h0a1.827 1.827 0 00-1.82-1.82h-4.7"/><path pid="1" d="M1.26 14.166h3.636-.01c1.07-.01 2.12.31 3.02.91l2.07 1.38h0c.8.48 1.06 1.52.57 2.33-.02.02-.03.04-.04.06v0h-.01c-.48.71-1.41.95-2.17.57l-2.73-1.64M21.863 5.94v-.01a2.61 2.61 0 01-2.75 2.44 2.61 2.61 0 01-2.45-2.45c0-1.947 2.59-5.191 2.59-5.191s2.59 3.24 2.59 5.19zM22.9 18.11v-.01c0-1.06-.86-1.92-1.92-1.92l-.01-.01c-.05 0-.1.01-.14.01h-.01c.54-2.04-.66-4.13-2.7-4.68a3.83 3.83 0 00-4.07 1.41l-.01-.01a2.855 2.855 0 00-3.65.14"/></g>'
  }
})
