<template>
    <button @click="cancel">Cancel Subscription</button>
</template>

<script>
import Alert from '@/util/alert';

export default {
    props: {
        contactAdmin: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            isContactAdminModalVisible: false
        };
    },

    methods: {
        cancel () {
            if (!this.contactAdmin) {
                window.location.href = this.route('account.cancel-plan.b2c');
                return;
            }

            Alert.createAlert(
                'info',
                'Please contact your Account Manager.',
                'To cancel your RSVPify subscription or make changes to your billing plan, please get in touch.',
                { confirmButtonText: 'CHAT NOW' }
            ).then(({ isConfirmed }) => {
                if (isConfirmed && window.Intercom != null) {
                    window.Intercom('showNewMessage');
                }
            });
        }

    }
};
</script>
