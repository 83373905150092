/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down-left-thick-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>south west</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.841 4.68h0c-.88-.88-2.31-.88-3.19-.01h-.01l-8.89 8.88V9.23v0c0-1.25-1.01-2.25-2.25-2.25-1.25 0-2.25 1-2.25 2.25v9.75h0c0 1.24 1 2.24 2.25 2.24h9.75l-.01-.001c1.24 0 2.25-1.01 2.25-2.25 0-1.25-1.01-2.25-2.25-2.26h-4.32l8.887-8.887h-.01c.87-.88.87-2.31 0-3.19l-.01-.01z"/>'
  }
})
