<template>
    <div class="flex flex-col grow justify-center">
        <div class="flex flex-wrap">
            <form-field-wrapper
                label="Sponsor Name"
                :error="form.errors.get('name')"
                :should-show-error="form.errors.has('name')"
            >
                <input
                    id="sponsor-name"
                    v-model="form.name"
                    class="form-field"
                    type="text"
                    placeholder="Enter the sponsor’s name"
                >
            </form-field-wrapper>

            <form-field-wrapper label="Sponsor Link">
                <input
                    id="sponsor-url"
                    v-model="form.url"
                    class="form-field"
                    type="text"
                    placeholder="Enter the sponsor's website URL"
                >
            </form-field-wrapper>

            <form-field-wrapper label="Image or Logo">
                <image-manager
                    v-model="form.images"
                    :max-images="1"
                    :settings="{}"
                    title="Upload Image or Logo"
                    uploader-classes="text-left"
                ></image-manager>
            </form-field-wrapper>

            <portal to="app-drawer-footer">
                <footer-save-cancel
                    :processing="loading"
                    @cancel="$emit('cancel')"
                    @save="save"
                ></footer-save-cancel>
            </portal>
        </div>
    </div>
</template>

<script>
import WallItemSettingsMixin from '@/mixins/WallItemSettingsMixin';

export default {
    name: 'WallSponsorsBlockItemSettings',

    mixins: [WallItemSettingsMixin]
};
</script>
