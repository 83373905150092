/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-mutation-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.52 12.34a3.71 3.71 0 100 7.42 3.71 3.71 0 100-7.43zM6.91 9.56h1.24M7.53 9.56v2.79M11.69 11.03l.87.87M12.12 11.47l-1.97 1.97M14.03 15.44v1.24M14.03 16.06h-2.79M12.56 20.22l-.87.88M12.12 20.66l-1.97-1.97M8.15 22.56H6.91M7.53 22.56v-2.78M3.37 21.1l-.88-.88M2.93 20.66l1.97-1.97M1.03 16.68v-1.24M1.03 16.06h2.78M2.49 11.9l.88-.87M2.93 11.47l1.97 1.97M18.34 3.72a2.71 2.71 0 100 5.42 2.71 2.71 0 100-5.43zM17.9 1.69h.9M18.35 1.69v2.03M21.39 2.76l.64.64M21.71 3.08l-1.44 1.44M23.1 5.99v.9M23.1 6.44h-2.04M22.03 9.48l-.64.64M21.71 9.8l-1.44-1.44M18.8 11.19h-.9M18.35 11.19V9.15M15.31 10.12l-.64-.64M14.99 9.8l1.44-1.44M13.6 6.89v-.9M13.6 6.44h2.03M14.67 3.4l.64-.64M14.99 3.08l1.44 1.44M2.748 7.9V3.28h0c-.01-1.02.82-1.85 1.84-1.85h4.61"/><path pid="1" d="M4.59 6.052L2.74 7.898.89 6.052M21.056 14.437v6.15-.01a1.857 1.857 0 01-1.85 1.84h-2.62"/><path pid="2" d="M19.21 16.28l1.846-1.85 1.84 1.84"/></g>'
  }
})
