/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'time-hourglass-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sands</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.038 5.47c.55 3.2 2.51 5.4 5.962 6.52 3.452-1.12 5.4-3.32 5.962-6.53v-.01c.12-.82-.44-1.59-1.26-1.72-.08-.02-.16-.02-.24-.02H7.51h0C6.682 3.7 6 4.37 6 5.2c-.01.07 0 .15.01.23zM3.75.75h16.5"/><path pid="1" d="M7.529 20.25v-.001c-.83 0-1.51-.67-1.51-1.5-.01-.08 0-.16.01-.24.55-3.21 2.51-5.41 5.96-6.53 3.452 1.11 5.4 3.314 5.962 6.523h0c.12.81-.44 1.58-1.26 1.7-.08.01-.16.01-.24.01zM3.75 23.25h16.5"/></g>'
  }
})
