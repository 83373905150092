/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'resize-expand': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><rect pid="0" x="20" y="7.5" width="2" height="9" rx=".25" ry=".25"/><rect pid="1" x="7.5" y="2" width="9" height="2" rx=".25" ry=".25"/><rect pid="2" width="6" height="6" rx="2" ry="2"/><rect pid="3" x="18" width="6" height="6" rx="2" ry="2"/><rect pid="4" x="18" y="18" width="6" height="6" rx="2" ry="2"/><path pid="5" d="M12 13.164a.251.251 0 01.073-.177l2.25-2.25a.25.25 0 01.427.177V12.5a.75.75 0 001.5 0v-4a.753.753 0 00-.75-.75h-4a.75.75 0 000 1.5h1.586a.25.25 0 01.177.427l-2.25 2.25a.251.251 0 01-.177.073H4.25a.25.25 0 01-.25-.25v-4a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V21a1 1 0 001 1h13.25a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25h-4a.25.25 0 01-.25-.25zm-8 .586a.25.25 0 01.25-.25h6a.25.25 0 01.25.25v6a.25.25 0 01-.25.25H4.5a.5.5 0 01-.5-.5z"/></g>'
  }
})
