<template>
    <section class="panel">
        <page-header icon="tags-double" label="Manage Tags & Custom Data Fields">
            <a :href="route('settings.event', event)" class="button-icon button-sm">
                <app-icon name="close"></app-icon>
            </a>
        </page-header>

        <tab-list
            equal-width-tabs
        >
            <tab-list-item
                id="tags"
                :label="tagLabel"
                class="py-6 px-0"
            >
                <manage-tags @total="totalTags = $event.meta.total"></manage-tags>
            </tab-list-item>

            <tab-list-item
                id="custom-data-fields"
                :label="customDataFieldLabel"
                class="py-6 px-0"
            >
                <manage-custom-data-fields @total="totalCustomDataFields = $event.meta.total"></manage-custom-data-fields>
            </tab-list-item>
        </tab-list>
    </section>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    data () {
        return {
            totalCustomDataFields: 0,
            totalTags: 0
        };
    },

    computed: {
        ...get('Event/*'),

        customDataFieldLabel () {
            return `Custom Data Fields (${this.totalCustomDataFields})`;
        },

        tagLabel () {
            return `Tags (${this.totalTags})`;
        }
    }
};
</script>
