/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chart-analytics-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>chart analytics bar</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M.75 23.25h22.5M6 17.25H3a.75.75 0 00-.75.75v5.25h4.5V18a.75.75 0 00-.75-.75zM13.5 9.75h-3a.75.75 0 00-.75.75v12.75h4.5V10.5a.75.75 0 00-.75-.75zM21 14.25h-3a.75.75 0 00-.75.75v8.25h4.5V15a.75.75 0 00-.75-.75z"/><circle pid="1" cx="2.25" cy="10.5" r="1.5" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><circle pid="2" cx="9" cy="2.25" r="1.5" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><circle pid="3" cx="21.75" cy="6" r="1.5" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="4" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.2 9.339l4.85-5.928M20.311 5.576l-9.872-2.903"/>'
  }
})
