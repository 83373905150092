<template>
    <app-modal
        title="Default Application Settings"
        :value="value"
        @input="$emit('input', $event)"
    >

        <div class="flex -mx-2">
            <div class="inline-block flex items-center px-2 max-w-sm mr-4">
                <form-field-wrapper label="Percent Fee">
                    <div class="flex items-center w-20">
                        <input
                            v-model.number="form.percentage"
                            type="number"
                            class="form-field p-1"
                            min="0"
                            max="100"
                            step="0.01"
                        >
                        <span class="input-append p-1 text-gray-400">%</span>
                    </div>
                </form-field-wrapper>
            </div>

            <div class="inline-block flex items-center px-2 max-w-sm mr-4">
                <form-field-wrapper label="Fixed Fee">
                    <div class="flex items-center w-20">
                        <span class="input-prepend p-1 text-gray-400">$</span>
                        <input
                            v-model.number="form.fixed"
                            type="number"
                            class="form-field p-1"
                            min="0"
                            step="0.01"
                        >
                    </div>
                </form-field-wrapper>
            </div>
        </div>

        <div v-if="form.errors.any()" class="w-full">
            <div
                v-for="(errorMessage, index) in form.errors.all()"
                :key="index"
                class="alert alert-error alert-sm mt-2"
            >
                <p class="w-full font-normal text-center">
                    {{ errorMessage }}
                </p>
            </div>
        </div>

        <template #footer="{ close }">
            <stateful-button
                class="button-primary"
                :disabled="!form.isModified()"
                :loading="form.processing"
                @click="saveDefaultFeeSettings"
            >
                Save &amp; Close
            </stateful-button>

            <button class="button" @click="close">
                Cancel
            </button>
        </template>
    </app-modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import getValue from 'lodash/get';
import Form from '@/validation/Form';

export default {
    name: 'DefaultApplicationSettingsModal',

    props: {
        settings: {
            type: Object,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            form: new Form(cloneDeep(this.settings))
        };
    },

    watch: {
        settings (newVal) {
            this.form = new Form(cloneDeep(newVal));
        }
    },

    methods: {
        saveDefaultFeeSettings () {
            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            App.alert().confirm(
                'Are you sure?',
                `You are changing the default fees for the entire application and all submissions going forward. Do you want to proceed?`,
                attributes,
                this.updateDefaultFeeSettings
            );
        },

        updateDefaultFeeSettings () {
            this.form.put(this.route('api.admin.users.default-application-settings.update'))
                .then(({ data }) => {
                    this.form = new Form({
                        fixed: getValue(data, 'formattedFixedFee'),
                        fixedAmount: getValue(data, 'fixed'),
                        percentage: getValue(data, 'percentage')
                    });

                    this.$emit('saved-default-fee-settings', data);
                    this.$emit('input', false);

                    this.$toasted.global.success('Default user fees have been updated.');
                });
        }
    }
};
</script>
