/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 8.25h1.5-.01c.82-.01 1.5.67 1.5 1.5v12c0 .82-.68 1.5-1.5 1.5H5.23a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-12h0c-.01-.83.67-1.51 1.49-1.51h1.5M12 .75v10.5M8.25 4.5L12 .75l3.75 3.75"/></g>'
  }
})
