<template>
    <div>
        <button
            class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
            @click="showTagModal = true"
        >
            <app-icon
                name="tags-add"
                class="h-4 w-4 mr-3"
            ></app-icon>
            <p>Tag ({{ bulkActionData.totalSelected | number }})</p>
        </button>

        <select-tags-modal
            v-model="showTagModal"
            :title="modalTitle"
            :tags="[]"
            :confirm-button-text="confirmButtonText"
            :z-index="110"
            @save-tags="saveTags"
        ></select-tags-modal>
    </div>
</template>

<script>
import map from 'lodash/map';
import { call } from 'vuex-pathify';
import axios from '@/util/axios';

export default {
    props: {
        bulkActionData: {
            type: Object,
            required: true
        },

        confirmButtonText: {
            type: String,
            default: 'Tag records(s)'
        },

        confirmationMessage: {
            type: String,
            default: 'Tagging the selected records. This may take a few minutes.'
        },

        endpoint: {
            type: String,
            required: true
        },

        modalTitle: {
            type: String,
            default: 'Tag record(s)'
        }
    },

    data () {
        return {
            showTagModal: false
        };
    },

    methods: {
        makeTagRequest (tags) {
            return axios.post(this.endpoint, {
                all: this.bulkActionData.all,
                ids: this.bulkActionData.ids,
                filters: this.bulkActionData.filters,
                predicates: this.bulkActionData.predicates,
                data: {
                    tags: map(tags, 'name')
                }
            });
        },

        reloadTags: call('Tags/reloadTags'),

        saveTags (tags) {
            this.makeTagRequest(tags)
                .then(() => {
                    return this.reloadTags();
                })
                .then(() => {
                    this.$toasted.global.success(this.confirmationMessage);
                })
                .catch(() => {
                    this.$toasted.global.error('An error occured tagging the records.');
                })
                .finally(() => {
                    this.$emit('rows-tagged');
                });
        }
    }
};
</script>
