/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.97 12.125a2.62 2.62 0 100 5.25 2.62 2.62 0 100-5.25zM9.2 21.125h0a4.482 4.482 0 00-8.46-.01M19.02 12.125a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM23.25 21.125h0a4.482 4.482 0 00-8.46-.01M6 2.875a.5.5 0 100 1 .5.5 0 100-1zM12 2.875a.5.5 0 100 1 .5.5 0 100-1zM18 2.875a.5.5 0 100 1 .5.5 0 100-1z"/></g>'
  }
})
