export default {
    methods: {
        getValidatedLink (link) {
            if (link.indexOf('mailto:') === 0) {
                return link;
            }

            if (link.search(/^http[s]?\:\/\//) === -1) {
                link = `https://${link}`;
            }

            if (this.isValidUrl(link)) {
                return link;
            }

            return null;
        },

        isValidUrl (link) {
            return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(link);
        }
    }
};
