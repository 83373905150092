<template>
    <component
        :is="componentName"
        ref="blockComponent"
        v-bind="$props"
        @complete-block="$emit('complete-block', $event)"
    >
        <template #block-header>
            <slot name="block-header"></slot>
        </template>

        <template #block-footer="{ completeBlock }">
            <slot name="block-footer" :complete-block="completeBlock"></slot>
        </template>
    </component>
</template>

<script>
import { get } from 'vuex-pathify';
import FormBlock from '@/mixins/FormBlock';

export default {
    name: 'FormRsvpBlock',

    mixins: [FormBlock],

    computed: {
        event: get('Event/event'),

        componentName () {
            return this.isInviteeFlow
                ? 'invitee-rsvp-block'
                : 'open-rsvp-block';
        },

        isInviteeFlow () {
            return (this.event.invite_list.is_enabled && this.event.has_invitees) || !!this.submission.invitee_group;
        }
    },

    methods: {
        enterKeyCompletionFunction () {
            this.$refs.blockComponent.handleEnterKeyPressedEvent();
        }
    }
};
</script>
