/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-heal-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25 17.25v0c0-.56-.45-1-1-1h-2.5v-2.5 0c0-.56-.45-1-1-1h-1.51c-.56 0-1 .44-1 1 0 0 0 0 0 0v2.5h-2.51c-.56 0-1 .44-1 1 0 0 0 0 0 0v1.5h0c0 .55.44.99 1 .99h2.5v2.5h0c0 .55.44.99 1 .99h1.5-.01c.55 0 1-.45 1-1v-2.5h2.5-.01c.55 0 1-.45 1-1zM4.183 12.754H2.162h0a1.42 1.42 0 01-1.38-1.46 1.4 1.4 0 011.37-1.38h2.02M9.929 4.183V2.162h-.01c.02-.79.66-1.4 1.45-1.38.75.01 1.35.62 1.37 1.37v2.02"/><path pid="1" d="M15.4 5.28l-.01-.01a7.295 7.295 0 00-8.13 0M18.5 9.929h0c-.19-.95-.57-1.86-1.1-2.66M7.277 17.4l-.01-.01c.75.5 1.59.86 2.47 1.06M5.28 7.277h-.01a7.279 7.279 0 000 8.12"/><path pid="2" d="M6.848 9.511h0c-.01-.43-.17-.84-.48-1.14l-1.86-1.86h0a1.4 1.4 0 010-2 1.4 1.4 0 012 0l1.85 1.86h0c.3.3.71.47 1.13.47M9.511 15.835h0c-.43-.01-.84.16-1.14.47l-1.86 1.85v-.01c-.56.55-1.45.55-2 0a1.41 1.41 0 01-.01-2l1.859-1.86v-.01c.3-.31.47-.71.47-1.14M13.172 6.848h-.01c.42-.01.83-.17 1.13-.48l1.86-1.859h-.01a1.41 1.41 0 012-.01c.55.55.55 1.44 0 2l-1.87 1.85v-.01c-.31.3-.48.71-.48 1.13"/></g>'
  }
})
