/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vegetables-salad-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food appetizer vegetarian vegan</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.249 13.125c0 4.971-5.04 9-11.25 9-6.22 0-11.25-4.03-11.25-9zM8.74 10.125h0a6.747 6.747 0 00-2-2.25"/><path pid="1" d="M12.173 6.853v-.01c.26-1.64-.84-3.18-2.48-3.45-.59-.1-1.19-.02-1.73.22l-.01-.01a3.017 3.017 0 00-3.98-1.5 3.003 3.003 0 00-1.5 3.97h-.01A2.985 2.985 0 00.97 10.04l.01.03M21.67 10.125v-.01c.41-2.03-.9-4.01-2.93-4.43a3.75 3.75 0 00-4.43 2.92c-.1.49-.1 1 0 1.49"/></g>'
  }
})
