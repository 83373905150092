<template>
    <div v-if="intercomExists">
        <slot :open-chat-window="openChatWindow">
            <button class="button button-primary" @click="openChatWindow">
                {{ buttonLabel }}
            </button>
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        buttonLabel: {
            required: false,
            type: String,
            default: 'Contact Support'
        },
        supportMessage: {
            required: false,
            type: String,
            default: null
        }
    },

    data () {
        return {
            intercomExists: false
        };
    },

    mounted () {
        window.addEventListener('load', () => {
            this.intercomExists = !!window.Intercom;
        });
    },

    methods: {
        openChatWindow () {
            window.Intercom('showNewMessage', this.supportMessage);
        }
    }
};
</script>
