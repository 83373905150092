/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'religion-peace-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 1.5a10.5 10.5 0 100 21 10.5 10.5 0 100-21z"/><path pid="1" d="M18.974 14.73h-.01c1.52-3.84-.36-8.18-4.2-9.71-.42-.17-.85-.3-1.29-.38v4.6zM13.5 13.5v5.849-.01a7.518 7.518 0 003.78-2.07zM5.026 14.73h0c-1.53-3.84.35-8.18 4.19-9.71.41-.17.84-.3 1.28-.38v4.6zM10.5 13.5v5.849l-.01-.01a7.507 7.507 0 01-3.79-2.07z"/></g>'
  }
})
