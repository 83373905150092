<template>
    <div>
        <div class="row">
            <div class="col-12 md:col-4">
                <title-picker v-model="invitee.title_id" clearable></title-picker>
            </div>

            <div class="col-12 md:col-4">
                <input
                    v-model="invitee.firstName"
                    class="form-field"
                    placeholder="First Name"
                >
            </div>

            <div class="col-12 md:col-4">
                <input
                    v-model="invitee.lastName"
                    class="form-field"
                    placeholder="Last Name"
                >
            </div>
        </div>

        <div class="row mt-0 md:mt-2">
            <div class="col-12 md:col-8">
                <input
                    v-model="invitee.email"
                    class="form-field"
                    placeholder="Email"
                >
            </div>
        </div>

        <div>
            <div v-if="getError('firstName')" class="alert alert-error alert-sm mt-2">
                <p class="w-full font-normal text-center">{{ getError('firstName') }}</p>
            </div>

            <div v-else-if="getError('lastName')" class="alert alert-error alert-sm mt-2">
                <p class="w-full font-normal text-center">{{ getError('lastName') }}</p>
            </div>

            <div v-else-if="getError('email')" class="alert alert-error alert-sm mt-2">
                <p class="w-full font-normal text-center">{{ getError('email') }}</p>
            </div>

            <div v-if="getError('title')" class="alert alert-error alert-sm mt-2">
                <p class="w-full font-normal text-center">{{ getError('title') }}</p>
            </div>
        </div>

        <div class="row mt-4">
            <div class="flex items-center text-sm text-gray-600">
                <div class="ml-4 mr-2 uppercase">Additional guests allowed:</div>

                <select-list
                    v-model="invitee.additionalGuestsType"
                    class="flex"
                    :name="`additionalGuestsSelectList-${index}`"
                >
                    <select-option
                        id="unlimited"
                        label="Unlimited"
                        class="mx-2"
                        small
                    ></select-option>

                    <select-option
                        id="none"
                        label="None"
                        class="mx-2"
                        small
                    ></select-option>

                    <select-option
                        id="up-to"
                        label="Up to:"
                        class="mx-2"
                        small
                    ></select-option>

                    <input
                        v-model.number="invitee.additionalGuestsLimit"
                        type="number"
                        min="0"
                        class="form-field w-16"
                        :disabled="isAdditionalGuestsInputDisabled"
                        :class="{ 'opacity-50 cursor-not-allowed no-spinner': isAdditionalGuestsInputDisabled }"
                    >

                    <div class="flex shrink items-center ml-2">
                        <img
                            v-if="premiumAdditionalGuestsFeatureTooltip"
                            v-tippy
                            class="h-5 w-5 inline"
                            :src="asset('images/account/rsvpify-premium.svg')"
                            :content="premiumAdditionalGuestsFeatureTooltip"
                        >
                    </div>
                </select-list>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <tag-list
                    :value="invitee.tags"
                    :group-size="groupSize"
                    @input="newTags => $emit('tag-single-invitee', invitee, newTags)"
                    @tag-group="newTags => $emit('tag-entire-group', newTags)"
                ></tag-list>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import HasAdditionalGuests from './HasAdditionalGuests';

export default {
    name: 'InviteeDetails',

    mixins: [
        HasAdditionalGuests
    ],

    props: {
        form: {
            type: Object,
            required: true
        },

        groupSize: {
            type: Number,
            required: true
        },

        index: {
            type: Number,
            required: true
        },

        invitee: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...get('Event/*'),

        isAdditionalGuestsInputDisabled () {
            return this.invitee.additionalGuestsType !== 'up-to';
        }
    },

    watch: {
        'invitee.additionalGuestsLimit': function () {
            this.setAdditionalGuestsType();
        },

        'invitee.additionalGuestsType': function () {
            if (this.invitee.additionalGuestsType === 'unlimited') {
                this.invitee.additionalGuestsLimit = null;
            } else if (this.invitee.additionalGuestsType === 'none') {
                this.invitee.additionalGuestsLimit = 0;
            }
        }
    },

    mounted () {
        this.setAdditionalGuestsType();
    },

    methods: {
        confirm (confirmationMessage, onConfirm) {
            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            App.alert().confirm(
                'Are you sure?',
                confirmationMessage,
                attributes,
                onConfirm
            );
        },

        getError (field) {
            return this.form.errors.get(`invitees.${this.index}.${field}`);
        },

        setAdditionalGuestsType () {
            if (this.invitee.additionalGuestsLimit === null) {
                this.$set(this.invitee, 'additionalGuestsType', 'unlimited');
            } else if (this.invitee.additionalGuestsLimit === 0) {
                this.$set(this.invitee, 'additionalGuestsType', 'none');
            } else {
                this.$set(this.invitee, 'additionalGuestsType', 'up-to');
            }
        }
    }
};
</script>
