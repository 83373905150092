/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-vaccine-pill-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.6 16.4v-.01a4.6 4.6 0 01-6.5-.27 4.603 4.603 0 01-.01-6.24l7.8-7.8h-.01c1.86-1.73 4.77-1.61 6.5.26a4.6 4.6 0 010 6.23M6 6l4.25 4.25"/><path pid="1" d="M23.25 15.75h0a7.67 7.67 0 01-6.01 7.49l-.01-.01a7.672 7.672 0 01-6-7.51v-2.26h0c-.01-.83.67-1.51 1.49-1.51h9-.01c.82-.01 1.5.67 1.5 1.5zM17.25 15v4.5M15 17.25h4.5"/></g>'
  }
})
