/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-christmas-snow-globe-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>winter</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.65 18.754h0C.5 14.69.43 8.044 4.49 3.9c4.05-4.15 10.7-4.22 14.84-.16 4.14 4.05 4.21 10.7.15 14.84-.05.05-.1.09-.15.14"/><path pid="1" d="M19.5 18.75h-15l-1.45 2.16v-.01a1.493 1.493 0 001.23 2.33h15.4v-.001c.82-.01 1.49-.68 1.49-1.51-.01-.3-.09-.59-.26-.84zM7.5 14.25l4.5-4.5 4.5 4.5M8.25 5.62h-.01c.2-.01.37.16.37.37 0 0 0 0 0 0"/><path pid="2" d="M7.875 6h0c-.01-.21.16-.38.37-.38M8.25 6.375h-.01a.386.386 0 01-.38-.38s0 0 0 0M8.625 6v0c0 .2-.17.375-.375.375M5.25 10.87h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M4.875 11.25h0c-.01-.21.16-.38.37-.38M5.25 11.625h-.01a.386.386 0 01-.38-.38s0 0 0 0"/><path pid="4" d="M5.62 11.25v0c0 .2-.17.375-.375.375M15.75 7.12h-.01c.2-.01.37.16.37.37M15.37 7.5h0c-.01-.21.16-.38.37-.38M15.75 7.875h-.01a.386.386 0 01-.38-.38s0 0 0 0M16.125 7.5v0c0 .2-.17.375-.375.375M12.75 4.12h-.01c.2-.01.37.16.37.37M12.37 4.5h0c-.01-.21.16-.38.37-.38M12.75 4.875h-.01a.386.386 0 01-.38-.38s0 0 0 0M13.125 4.5v0c0 .2-.17.375-.375.375M19.5 10.125h-.01c.2-.01.37.16.37.37"/><path pid="5" d="M19.125 10.5h0c-.01-.21.16-.38.37-.38M19.5 10.87h-.01a.386.386 0 01-.38-.38s0 0 0 0M19.875 10.5v0c0 .2-.17.375-.375.375M9 12.75v6M15 12.75v6"/></g>'
  }
})
