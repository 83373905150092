/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video-game-controller-wifi-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.75 14.25H5.25l-.01-.01C2.75 14.22.73 16.23.71 18.71c-.02 2.48 1.99 4.5 4.47 4.52 1.28 0 2.51-.54 3.37-1.5h6.836l-.01-.01a4.49 4.49 0 006.34.33c1.84-1.67 1.99-4.51.33-6.35a4.509 4.509 0 00-3.35-1.49zM5.25 17.25v3M3.75 18.75h3"/><path pid="1" d="M17.25 19.125h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M16.875 19.5h0c-.01-.21.16-.38.37-.38M17.25 19.875h-.01a.386.386 0 01-.38-.38s0 0 0 0M17.625 19.5v0c0 .2-.17.375-.375.375M19.5 16.875h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M19.125 17.25h0c-.01-.21.16-.38.37-.38M19.5 17.625h-.01a.386.386 0 01-.38-.38s0 0 0 0M19.875 17.25v0c0 .2-.17.375-.375.375M8.818 9.568h-.01a4.5 4.5 0 016.36-.01s0 0 0 0M6.16 6.916h-.01a8.236 8.236 0 0111.66-.01"/><path pid="4" d="M3.51 4.26H3.5C8.18-.43 15.78-.43 20.47 4.25"/></g>'
  }
})
