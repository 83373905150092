/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-money-case-share-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sponsor</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 14.25v9M.75 21.75h15v0c0-1.66-1.35-3-3-3H9v0c0-1.66-1.35-3-3-3H.75M6 18.75h3M17.829 15.351h0c.43.57 1.13.9 1.85.87 1.13 0 2.06-.7 2.06-1.548 0-.86-.93-1.55-2.07-1.55-1.14 0-2.07-.7-2.07-1.548 0-.86.92-1.55 2.06-1.55v-.01c.72-.03 1.41.29 1.85.87M19.69 16.23v1.03M19.69 9.01v1.03"/><path pid="1" d="M15 13.5h-3.9l-.01-.01a1.39 1.39 0 01-1.36-1.4V5.11l-.01-.01c-.02-.76.59-1.39 1.35-1.4h10.8l-.01-.01c.75.01 1.36.63 1.35 1.39v3.85"/><path pid="2" d="M14.25 3.757v-1.04h-.01a2.105 2.105 0 012.24-1.97v0h-.01c1.16-.08 2.16.8 2.25 1.96v1.03"/></g>'
  }
})
