<script>
import axios from '@/util/axios';
import ExportButton from '../core/ExportButton.vue';

const STATE_EXPORT_READY = 'export-ready';
const STATE_FAILED = 'failed';

export default {
    extends: ExportButton,

    props: {
        campaign: {
            type: Object,
            required: true
        }
    },

    methods: {
        initiateExport () {
            axios.get(this.route('events.campaigns.export', {
                event: this.event,
                campaign: this.campaign,
                timezone: this.eventTimezone
            }));
        },

        listenForEvents () {
            this.$echo.private(`events.${this.event.id}.campaigns.${this.campaign.id}`)
                .listen('.Domain\\Campaigns\\Events\\SentCampaignReportExportFailed', () => {
                    this.state = STATE_FAILED;
                })
                .listen('.Domain\\Campaigns\\Events\\SentCampaignReportExported', ({ downloadUrl }) => {
                    this.downloadUrl = downloadUrl;
                    this.state = STATE_EXPORT_READY;
                    window.location = downloadUrl;
                });
        }
    }
};
</script>
