/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sort descending</desc><g _fill="currentColor"><path pid="0" d="M12 0h-.01c-6.63 0-12 5.37-12 12 0 6.62 5.37 12 12 12 6.62-.01 12-5.38 12-12l-.01-.01c-.01-6.63-5.38-12-12.01-12zm0 22l-.01-.001c-5.53-.01-10-4.48-10-10.01 0-5.53 4.47-10 10-10 5.52 0 10 4.47 9.99 10v-.01c-.01 5.52-4.48 9.99-10 10z"/><path pid="1" d="M15.615 12.116l-1.94 1.939v-.01c-.1.09-.26.09-.36 0a.269.269 0 01-.08-.18v-7.88c0-.7-.56-1.25-1.25-1.25-.7 0-1.25.55-1.25 1.25v7.878-.01c-.01.13-.12.24-.26.24a.298.298 0 01-.18-.08l-1.94-1.939c-.49-.49-1.28-.49-1.77-.01s-.49 1.27-.01 1.76l4.5 4.5-.01-.01c.48.48 1.27.48 1.76 0v-.01l4.5-4.5v-.01c.48-.49.48-1.28-.01-1.77s-1.28-.49-1.77 0z"/></g>'
  }
})
