/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fruit-watermelon': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g _fill="currentColor"><path pid="0" d="M19.907 19.907a13.961 13.961 0 01-19.751 0 .505.505 0 010-.73l1.479-1.487a.5.5 0 01.7-.012A10.852 10.852 0 1017.678 2.33a.5.5 0 01.012-.7L19.177.156a.5.5 0 01.73 0 13.961 13.961 0 010 19.751z"/><path pid="1" d="M16.765 3.315a.5.5 0 00-.722-.016L3.3 16.043a.5.5 0 00.016.722 9.511 9.511 0 0013.45-13.45zM7.5 17.2c-.538 0-.972-.652-.972-1.457s.434-1.458.972-1.458.971.653.971 1.458S8.038 17.2 7.5 17.2zm6.525-3.175c-.379.379-1.148.225-1.718-.343s-.722-1.338-.343-1.717 1.149-.227 1.718.342.725 1.341.345 1.72zm1.718-5.554c-.806 0-1.458-.435-1.458-.972s.652-.971 1.458-.971 1.457.437 1.457.972-.651.973-1.455.973z"/></g>'
  }
})
