/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grid-lock-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>crop aspect ratio layers</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="9" height="7.5" x="14.25" y="15.75" rx="1"/><path pid="1" d="M18.75 11.25v0h-.01c-1.66 0-3 1.34-3 3 0 0 0 0 0 0v1.5h6v-1.51c0-1.66-1.35-3-3-3h-.01zM18.75 19.15h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M11.25 18.75h-7.5l-.01-.001c-1.66-.01-3-1.35-3-3V3.748h0A2.998 2.998 0 013.73.73h12-.01c1.65-.01 3 1.34 3 3v4.5M6.75.75v18M12.75.75v12M18.75 6.75h-18M12.75 12.75h-12"/></g>'
  }
})
