<template>
    <div class="w-full">
        <slidable-list v-model="activeSettingsPage" :animate="animateTransition">
            <slot
                :edit="edit"
                :save="save"
                :close="close"
                :create="create"
                :duplicate="duplicate"
                :restore="restoreOriginalSettings"
                :selected-item="selectedItem"
            ></slot>
        </slidable-list>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';
import HasDefaultCloseHandler from '@/mixins/HasDefaultCloseHandler';

export default {
    name: 'MultiPageItemSettings',

    mixins: [HasDefaultCloseHandler, GeneratesUniqueKey],

    props: {
        animateTransition: {
            type: Boolean,
            default: true
        },
        itemsArray: {
            type: Array,
            default: null
        },
        itemsArrayName: {
            type: String,
            default: ''
        },
        itemCreatorFunction: {
            type: Function,
            default: () => { return {}; }
        },
        settings: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            originalSelectedItem: null,
            selectedItem: null
        };
    },

    computed: {
        activeSettingsPage () {
            return this.selectedItem ? 'item-settings' : 'general-settings';
        },

        items () {
            if (this.itemsArray) {
                return this.itemsArray;
            }

            return this.settings[this.itemsArrayName];
        }
    },

    methods: {
        edit (item) {
            this.originalSelectedItem = cloneDeep(item);
            this.selectedItem = item;
        },

        save (item) {
            if (item.new && !item.id) {
                this.items.push({
                    id: this.getUniqueKey(),
                    ...item
                });
            } else if (item.id) {
                const index = findIndex(this.items, { id: item.id });
                this.$set(this.items, index, item);
            } else {
                /*
                    If the item being saved does not have an `id` nor a `new`
                    property, it means it's one of the "non-standard" items such
                    as the "Amount of Your Choice" item in the Donation Block,
                    or the "Can't Make It" item in the Ticketing block.
                 */
                this.$emit('save-specific-settings', item);
            }

            this.close();
        },

        close () {
            this.selectedItem = null;
            this.originalSelectedItem = null;
        },

        create () {
            this.edit({
                new: true,
                ...this.itemCreatorFunction()
            });
        },

        duplicate (item, titleAttribute = 'title') {
            const newItem = this.itemCreatorFunction();

            Object.keys(newItem).forEach((key) => {
                newItem[key] = cloneDeep(item[key]);
            });

            if (titleAttribute) {
                newItem[titleAttribute] += ' - DUPLICATED';
            }

            this.edit({
                ...newItem,
                new: true,
                sort: this.items.length + 1
            });
        },

        restoreOriginalSettings () {
            const index = findIndex(this.items, { id: this.selectedItem.id });
            this.$set(this.items, index, this.originalSelectedItem);

            this.close();
        }
    }
};
</script>
