export default {
    methods: {
        hyperloopUrl (utmMedium) {
            switch (this.event?.type?.slug) {
                case 'corporate':
                    return `https://rsvpify.com/event-registration/?utm_source=hyperloop_corporate&utm_medium=${utmMedium}&utm_campaign=${this.event.subdomain}`;
                case 'fundraiser':
                    return `https://rsvpify.com/event-registration/?utm_source=hyperloop_gala&utm_medium=${utmMedium}&utm_campaign=${this.event.subdomain}`;
                case 'performance':
                    return `https://rsvpify.com/sell-tickets/?utm_source=hyperloop_show&utm_medium=${utmMedium}&utm_campaign=${this.event.subdomain}`;
                case 'wedding':
                    return `https://rsvpify.com/weddings/?utm_source=hyperloop_wedding&utm_medium=${utmMedium}&utm_campaign=${this.event.subdomain}`;
                case 'reunion':
                    return `https://rsvpify.com/sell-tickets/reunion/?utm_source=hyperloop_reunion&utm_medium=${utmMedium}&utm_campaign=${this.event.subdomain}`;
                case 'party':
                    return `https://rsvpify.com/event-registration/?utm_source=hyperloop_holiday_party&utm_medium=${utmMedium}&utm_campaign=${this.event.subdomain}`;
                case 'military':
                    return `https://rsvpify.com/event-registration/?utm_source=hyperloop_military&utm_medium=${utmMedium}&utm_campaign=${this.event.subdomain}`;
                case 'birthday':
                    return `https://rsvpify.com/private-events/?utm_source=hyperloop_birthday&utm_medium=${utmMedium}&utm_campaign=${this.event.subdomain}`;
                case 'mitzvah':
                    return `https://rsvpify.com/mitzvahs/?utm_source=hyperloop_mitzvah&utm_medium=${utmMedium}&utm_campaign=${this.event.subdomain}`;
                case 'faith-based':
                    return `https://rsvpify.com/event-registration/churches/?utm_source=hyperloop_faith&utm_medium=${utmMedium}&utm_campaign=${this.event.subdomain}`;
                case 'shower':
                    return `https://rsvpify.com/private-events/?utm_source=hyperloop_babyshower&utm_medium=${utmMedium}&utm_campaign=${this.event.subdomain}`;
                case 'other':
                    return `https://rsvpify.com/event-registration/?utm_source=hyperloop_other&utm_medium=${utmMedium}&utm_campaign=${this.event.subdomain}`;
                default:
                    return `https://rsvpify.com/?utm_source=hyperloop&utm_medium=${utmMedium}&utm_campaign=${this.event.subdomain}`;
            }
        }
    }

};
