<template>
    <div>
        <button
            class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
            @click="confirmDelete"
        >
            <app-icon
                name="trash"
                class="h-4 w-4 mr-3"
                stroke
            ></app-icon>
            <p>Delete ({{ bulkActionData.totalSelected | number }})</p>
        </button>
    </div>
</template>

<script>
import axios from '@/util/axios';

export default {
    props: {
        bulkActionData: {
            type: Object,
            required: true
        },

        endpoint: {
            type: String,
            required: true
        },

        confirmationMessage: {
            type: String,
            required: false,
            default: 'Deleting the selected records. This may take a few minutes.'
        },

        deleteCancelButtonText: {
            type: String,
            required: false,
            default: 'CANCEL'
        },

        deleteConfirmButtonText: {
            type: String,
            required: false,
            default: 'DELETE'
        },

        deleteMessage: {
            type: String,
            required: false,
            default: 'You are permanently deleting the selected records. This action cannot be undone.'
        },

        requireTypeDeleteConfirmation: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    methods: {
        confirmDelete () {
            const alertMethod = this.requireTypeDeleteConfirmation ? 'confirmType' : 'confirm';

            App.alert()[alertMethod](
                `Are you sure?`,
                this.deleteMessage,
                {
                    confirmButtonText: this.deleteConfirmButtonText,
                    cancelButtonText: this.deleteCancelButtonText
                },
                this.bulkDelete
            );
        },

        bulkDelete () {
            axios.delete(this.endpoint, {
                data: {
                    all: this.bulkActionData.all,
                    ids: this.bulkActionData.ids,
                    filters: this.bulkActionData.filters,
                    predicates: this.bulkActionData.predicates
                }
            })
                .then(() => {
                    this.$toasted.global.success(this.confirmationMessage);
                }, () => {
                    this.$toasted.global.error('An error occurred deleting the records.');
                })
                .finally(() => {
                    this.$emit('rows-deleted');
                });
        }
    }
};
</script>
