import { get, sync } from 'vuex-pathify';

export default {
    props: {
        block: {
            type: Object,
            required: true
        },

        processing: {
            type: Boolean,
            default: false
        },

        showCompleteButtonMessage: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        activeBlock: get('Form/activeBlock'),

        errorBag: get('Submission/errorBag'),

        isActive () {
            return this.activeBlock.id === this.block.pivot.id;
        },

        isCollapsed () {
            return this.block.pivot.settings.isCollapsed;
        },

        isEditMode: get('Form/isEditMode'),
        isPreview: get('Form/isPreview'),

        /**
         * This computed property should return a mapping of blocks to handlers
         * that should execute whenever that block is completed. The key is the
         * block slug and the value is the function to execute.
         */
        onCompletionListeners () {
            return {};
        },

        submission: sync('Submission/submission'),
        submissionStarted: get('Submission/submissionStarted')
    },

    data () {
        return {
            enterKeyTriggersCompletion: true
        };
    },

    created () {
        App.$on('complete-block', this.handleBlockCompletedEvent);

        if (this.enterKeyTriggersCompletion) {
            App.$on('enter-key-pressed', this.handleEnterKeyPressedEvent);
        }
    },

    destroyed () {
        App.$off('complete-block', this.handleBlockCompletedEvent);
    },

    methods: {
        /**
         * This function is technically a hook meant to be overriden in specific
         * block components using this mixin, in order to execute block-specific
         * actions before successfully completing this block and scrolling to
         * the next block.
         *
         * If the completion object `valid` property is true, the Form will move
         * to the next block. Otherwise, the Form will remain on the same block.
         */
        async completeBlock () {
            return this.getCompletionObject();
        },

        async defaultEnterKeyCompletionFunction () {
            const completionPayload = await this.completeBlock();

            this.$emit('complete-block', completionPayload);
        },

        collapse () {
            this.block.pivot.settings.isCollapsed = true;
        },

        expand () {
            this.block.pivot.settings.isCollapsed = false;
        },

        /**
         * A shorthand function for generating a block completion object.
         */
        getCompletionObject (valid = true) {
            return {
                payload: this.serializePayload(),
                valid
            };
        },

        handleBlockCompletedEvent ({ block, result }) {
            if (block.slug in this.onCompletionListeners) {
                this.onCompletionListeners[block.slug]({ block, result });
            }
        },

        handleEnterKeyPressedEvent () {
            if (!this.isActive) {
                return null;
            }

            if (this.enterKeyCompletionFunction) {
                return this.enterKeyCompletionFunction();
            }

            return this.defaultEnterKeyCompletionFunction();
        },

        /**
         * This function is meant to be overriden in every specific block
         * component and it represents the value/chosen options/guest input
         * for each particular block that will be stored as part of the
         * submission.
         */
        serializePayload () {
            return {};
        }
    }
};
