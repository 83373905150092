<template>
    <tabbed-modal :value="value" @input="$emit('input', $event)">
        <template #header>
            {{ user.name }}
            <div class="font-base text-gray-600 text-base tracking-wide">{{ user.email }}</div>
        </template>

        <template #content="{ close, initialize }">
            <tabbed-modal-item
                id="account"
                label="Account"
                @hook:mounted="initialize"
            >
                <manage-user-account-tab
                    :user="user"
                    :plans="plans"
                    :plan-segments="planSegments"
                    @updated="handleAccountUpdated(close)"
                ></manage-user-account-tab>
            </tabbed-modal-item>

            <tabbed-modal-item
                id="billing-history"
                label="Billing History"
            >
                <manage-user-billing-history-tab :user="user"></manage-user-billing-history-tab>
            </tabbed-modal-item>

            <tabbed-modal-item
                id="feature-flag-overrides"
                label="Feature Flag Overrides"
            >
                <manage-user-feature-flag-overrides
                    :user="user"
                    :feature-flags="featureFlags"
                    @updated="handleAccountUpdated(close)"
                ></manage-user-feature-flag-overrides>
            </tabbed-modal-item>
        </template>
    </tabbed-modal>
</template>

<script>
export default {
    name: 'ManageUserModal',

    props: {
        user: {
            type: Object,
            required: true
        },
        featureFlags: {
            type: Array,
            required: true
        },
        plans: {
            type: Array,
            required: true
        },
        planSegments: {
            type: Array,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        handleAccountUpdated (closeFunction) {
            this.$emit('updated');

            closeFunction();
        }
    }
};
</script>
