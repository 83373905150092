<template>
    <div v-if="!event.isPublished && event.hasTestSubmissions && event.hasAnyItemsWithLimitedCapacityEnabled" class="alert alert-info">
        Your event is in DRAFT mode. Test submissions do not impact limited availability and capacity limits.
    </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    name: 'TestModeLimitedCapacityAlert',

    computed: {
        ...get('Event/*')
    }
};
</script>
