/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-symptoms-virus-stomach-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.9 7.94a2.77 2.77 0 100 5.55 2.77 2.77 0 100-5.56zM13.44 5.86h.93M13.91 5.86v2.09M17.01 6.96l.66.65M17.34 7.29l-1.47 1.47M18.76 10.26v.92M18.76 10.72h-2.08M17.67 13.83l-.66.65M17.34 14.16l-1.47-1.47M14.37 15.58h-.93M13.91 15.58V13.5M10.8 14.48l-.66-.65M10.47 14.16l1.47-1.47M9.05 11.18v-.92M9.05 10.72h2.08M10.14 7.61l.66-.65M10.47 7.29l1.47 1.47"/><path pid="1" d="M8.083 23.25v-.01c.05-1.29.33-2.55.82-3.74 4.13.68 11.26-1.62 13.4-5.9 2.86-5.73-2.13-12.85-8.71-11.75h-.01c-1.98.33-3.81 1.21-5.3 2.55C7.03 3.21 6.98 2.77 5.96.72M.86.75L.85.74C1.7 3.62 3.19 6.29 5.2 8.53c-1.4 2.93-1.63 6.15-.53 8.27v-.01a14.13 14.13 0 00-1.73 6.42"/></g>'
  }
})
