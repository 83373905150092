/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-golf-ball-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a8.25 8.25 0 100 16.5 8.25 8.25 0 100-16.5zM6 18.51l-.01-.01c3.66 2.31 8.33 2.31 12 0M12 20.25v3"/><path pid="1" d="M12 13.87h-.01c.2-.01.37.16.37.37M11.625 14.25v-.01c0-.21.16-.38.37-.38M12 14.625h-.01a.386.386 0 01-.38-.38s0 0 0 0"/><path pid="2" d="M12.37 14.25v0c0 .2-.17.375-.375.375M17.25 10.125h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M16.875 10.5h0c-.01-.21.16-.38.37-.38M17.25 10.87h-.01a.386.386 0 01-.38-.38s0 0 0 0M17.625 10.5v0c0 .2-.17.375-.375.375M15.75 13.125h-.01c.2-.01.37.16.37.37M15.37 13.5h0c-.01-.21.16-.38.37-.38"/><path pid="4" d="M15.75 13.87h-.01a.386.386 0 01-.38-.38s0 0 0 0M16.125 13.5v0c0 .2-.17.375-.375.375M13.5 10.87h-.01c.2-.01.37.16.37.37"/><path pid="5" d="M13.125 11.25h0c-.01-.21.16-.38.37-.38M13.5 11.625h-.01a.386.386 0 01-.38-.38s0 0 0 0"/><path pid="6" d="M13.87 11.25v0c0 .2-.17.375-.375.375"/></g>'
  }
})
