<template>
    <a
        role="button"
        class="flex leading-normal"
        :class="styles.animation"
        @click="selectItem"
    >
        <div class="flex items-start">
            <app-icon
                v-if="icon"
                v-theme="'form.accented-text'"
                :name="icon"
                class="shrink-0 opacity-50 h-6 w-6 mr-4 mt-1"
            ></app-icon>

            <div
                v-theme="['form.accent']"
                class="flex shrink-0 justify-center items-center h-8 w-8 mr-4 border-2"
                :class="styles.outer"
            >
                <div
                    v-if="value"
                    class="h-5 w-5 option-selected"
                    :class="styles.inner"
                ></div>
            </div>
        </div>

        <slot v-if="isActiveViewVisible" name="active-view"></slot>

        <div
            v-else
            v-theme="['form.text']"
            class="w-full"
        >
            <div class="my-1">
                <div class="font-semibold">
                    {{ title }}
                </div>

                <money-field
                    v-if="price"
                    static
                    :value="price"
                ></money-field>
            </div>

            <div class="opacity-75 text-sm">
                {{ description }}
            </div>

            <tiptap-editor
                v-if="availabilityMessage"
                :value="availabilityMessage"
                read-only
            ></tiptap-editor>

            <slot></slot>
        </div>
    </a>
</template>

<script>
import SlotHelpers from '@/mixins/SlotHelpers';

export default {
    name: 'DecisionInput',

    mixins: [
        SlotHelpers
    ],

    inject: ['isMultipleChoice'],

    props: {
        availabilityMessage: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        price: {
            type: Number,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        value: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            itemChanged: false
        };
    },

    computed: {
        isActiveViewVisible () {
            if (!this.hasSlot('active-view')) {
                return false;
            }

            return this.value;
        },

        isSingleChoice () {
            return !this.isMultipleChoice;
        },

        styles () {
            return {
                animation: {
                    'animate-[pulse_1s_ease-in-out_2]': this.itemChanged && this.value
                },
                inner: {
                    'rounded-full': this.isSingleChoice,
                    'rounded-sm': this.isMultipleChoice
                },
                outer: {
                    'rounded-full': this.isSingleChoice,
                    'rounded-md': this.isMultipleChoice
                }
            };
        }
    },

    methods: {
        selectItem () {
            this.$emit('input', !this.value);
            this.itemChanged = true;
        }
    }
};
</script>
