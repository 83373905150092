/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'layout-three-columns': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="currentColor" d="M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm-7 2.5v19a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h5a.5.5 0 01.5.5zM2.5 2h4a.5.5 0 01.5.5v19a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5zm19 20h-4a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v19a.5.5 0 01-.5.5z"/>'
  }
})
