<template>
    <div class="flex-1 p-8">
        <div class="panel flex flex-col">
            <page-header icon="money-basket-stroke" label="Manage Plans">
                <a :href="route('admin.dashboard')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <data-table
                :columns="columns"
                :rows="filteredPlans"
                no-results-placeholder="No plans match your criteria."
            >
                <template #header>
                    <search-field v-model="searchQuery"></search-field>

                    <div class="flex-1"></div>

                    <a :href="route('admin.plans.create')" class="button-icon button-primary button-lg">
                        <app-icon name="add-circle" stroke></app-icon>
                    </a>
                </template>

                <template #row="{ property, row, value }">
                    <template v-if="property === 'billing_period'">
                        {{ value[0].toUpperCase() + value.substring(1) }}
                    </template>

                    <template v-else-if="property === 'price'">
                        {{ isNaN(value) ? value : `$${formatNumber(value / 100, 2)}` }}
                    </template>

                    <template v-else-if="property === 'features.EventLimit'">
                        <template v-if="value.unlimited">Unlimited</template>
                        <template v-else>{{ value.max }}</template>
                    </template>

                    <template v-else-if="property === 'features.RsvpLimit'">
                        {{ value.max }} ({{ value.type }})
                    </template>

                    <template v-else-if="property === 'features.InviteeLimit'">
                        <template v-if="value.unlimited">Unlimited</template>
                        <template v-else>{{ value.max }}</template>
                    </template>

                    <template v-else-if="property === 'features.EmailCredits'">
                        {{ value.allowance }}
                    </template>

                    <div v-else-if="property === 'actions'" class="flex items-center justify-end">
                        <base-dropdown placement="bottom-end">
                            <template slot="trigger" slot-scope="{ triggerFunction }">
                                <button @click="triggerFunction">
                                    <app-icon
                                        name="navigation-menu-horizontal"
                                        class="h-6 w-6"
                                        stroke
                                    ></app-icon>
                                </button>
                            </template>

                            <div class="w-40 bg-white rounded-md border shadow text-sm overflow-hidden">
                                <a :href="route('admin.plans.edit', row)" class="w-full px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-purple hover:bg-gray-100 space-x-2">
                                    <app-icon
                                        name="edit"
                                        class="h-4 w-4"
                                        stroke
                                    ></app-icon>
                                    <span>Edit Plan</span>
                                </a>

                                <button class="w-full px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-purple hover:bg-gray-100 space-x-2" @click="duplicatePlan(row)">
                                    <app-icon
                                        name="duplicate-square"
                                        class="h-4 w-4"
                                        stroke
                                    ></app-icon>
                                    <span>Duplicate Plan</span>
                                </button>

                                <button
                                    v-if="!row.public"
                                    class="w-full px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-red hover:bg-gray-100 space-x-2"
                                    @click="deletePlan(row)"
                                >
                                    <app-icon
                                        name="trash"
                                        class="h-4 w-4"
                                        stroke
                                    ></app-icon>
                                    <span>Delete Plan</span>
                                </button>
                            </div>
                        </base-dropdown>
                    </div>
                </template>
            </data-table>
        </div>
    </div>
</template>

<script>
import axios from '@/util/axios';
import formatNumber from '@/util/format-number';

export default {
    props: {
        plans: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            searchQuery: ''
        };
    },

    computed: {
        columns () {
            return [
                {
                    label: 'Internal Name',
                    property: 'internal_name'
                },
                {
                    label: 'Billing Period',
                    property: 'billing_period'
                },
                {
                    label: 'Price',
                    property: 'price'
                },
                {
                    label: 'Event Limit',
                    property: 'features.EventLimit'
                },
                {
                    label: 'RSVP Limit',
                    property: 'features.RsvpLimit'
                },
                {
                    label: 'Invitees / Event',
                    property: 'features.InviteeLimit'
                },
                {
                    label: 'Emails / Month',
                    property: 'features.EmailCredits'
                },
                {
                    label: '',
                    property: 'actions'
                }
            ];
        },

        filteredPlans () {
            const regex = new RegExp(this.searchQuery, 'i');

            return this.plans.filter(({ name }) => {
                return name.match(regex);
            });
        }
    },

    methods: {
        deletePlan (plan) {
            App.alert().confirm(
                'Are you sure?',
                `Are you sure you want to delete the ${plan.name} plan?`,
                {},
                () => {
                    axios.delete(this.route('api.admin.plans.destroy', plan))
                        .then(() => {
                            window.location.reload();
                        })
                        .catch(() => {
                            this.$toasted.global.error('There was an error deleting the plan.');
                        });
                }
            );
        },

        duplicatePlan (plan) {
            axios.post(this.route('api.admin.plans.duplicate', plan))
                .then(({ data }) => {
                    window.location.href = this.route('admin.plans.edit', data);
                })
                .catch(() => {
                    this.$toasted.global.error('There was an error duplicating the plan.');
                });
        },

        formatNumber
    }
};
</script>
