/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'users': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M21.716 5.63l-.01-.01a.26.26 0 01-.15-.33.23.23 0 01.12-.15v-.01a2.74 2.74 0 001.31-3.62 2.735 2.735 0 00-3.62-1.32 2.72 2.72 0 00-1.32 3.61c.26.57.73 1.04 1.31 1.31l-.01-.01c.09.04.14.13.14.23v-.01c-.01.1-.07.18-.17.22v-.01a3.454 3.454 0 00-2.29 3.25v1.48l-.01-.01c0 .27.22.49.49.49h.81v-.001c.12-.01.23.09.24.21l.42 3.78c.02.25.23.43.49.43h1.97c.25 0 .46-.19.49-.44l.42-3.79v-.01c.01-.13.11-.22.24-.22h.81-.01c.27-.01.49-.23.49-.5V8.7a3.49 3.49 0 00-2.29-3.26z" id="svgicon_users_c"/><path pid="1" d="M13.273 11.86c-.14-.05-.21-.19-.16-.32.02-.07.07-.13.13-.15v-.01c1.63-.7 2.39-2.58 1.69-4.22-.7-1.64-2.58-2.4-4.22-1.7-1.64.69-2.4 2.57-1.7 4.21.32.76.93 1.37 1.69 1.69l-.01-.01c.12.05.18.2.13.32-.03.06-.09.11-.15.13v-.01c-1.6.54-2.68 2.04-2.68 3.73v2.96h-.001c-.01.27.22.49.49.49h.88-.01c.12-.01.23.09.24.22l.35 4.259-.01-.01c.02.25.23.45.49.45h2.96l-.01-.001c.25 0 .47-.2.49-.46l.35-4.259h-.01c0-.13.11-.23.24-.23h.88-.01c.27-.01.49-.23.49-.5v-2.97c-.01-1.7-1.08-3.2-2.68-3.74z" id="svgicon_users_a"/><path pid="2" d="M4.63 5.63c-.13-.05-.2-.2-.16-.33a.23.23 0 01.12-.15v-.01A2.74 2.74 0 005.9 1.52 2.735 2.735 0 002.28.2 2.72 2.72 0 00.96 3.81c.26.57.73 1.04 1.31 1.31.12.05.17.2.11.33-.03.05-.08.1-.15.12v-.01A3.44 3.44 0 00-.06 8.81v1.48l-.01-.01c0 .27.22.49.49.49h.819v-.001c.12-.01.23.09.24.21l.42 3.78c.02.25.23.43.49.43h1.97c.25 0 .46-.19.49-.44l.42-3.79v-.01c.01-.13.11-.22.24-.22h.81-.01a.5.5 0 00.49-.5V8.73a3.49 3.49 0 00-2.29-3.26z" id="svgicon_users_b"/></defs><g _fill="currentColor"><use xlink:href="#svgicon_users_a"/><use xlink:href="#svgicon_users_b"/><use xlink:href="#svgicon_users_c"/><use xlink:href="#svgicon_users_a"/><use xlink:href="#svgicon_users_b"/><use xlink:href="#svgicon_users_c"/></g>'
  }
})
