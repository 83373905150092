<template>
    <div>
        <button :class="buttonClass" @click="launchModal">
            Update Credit Card
        </button>

        <portal to="cc">
            <form-field-wrapper :error="cardElementError">
                <div ref="card" class="px-4 py-2 border rounded-md bg-white"></div>
            </form-field-wrapper>
        </portal>

        <app-modal
            v-model="modalOpen"
            title="Billing Information"
            @opened="mountStripe"
            @closed="closeModal"
        >
            <select-list v-model="selectedCard" full-option-select>
                <select-option
                    v-if="cardLastFour"
                    id="existing"
                    class="w-full bg-white border rounded-md mb-2 p-3 tracking-wider hover:border-purple-light hover:shadow-md cursor-pointer"
                    :class="{'border-purple-light shadow-md': selectedCard === 'existing'}"
                >
                    Use existing {{ readableCardBrand(cardBrand) }} ending in <span class="font-bold">{{ cardLastFour }}</span>
                </select-option>

                <select-option
                    id="new"
                    class="w-full bg-white border rounded-md mb-2 p-3 tracking-wider hover:border-purple-light hover:shadow-md cursor-pointer"
                    :class="{'border-purple-light shadow-md': selectedCard === 'new'}"
                >
                    Use a new credit card
                </select-option>
            </select-list>

            <div v-show="selectedCard === 'new'" class="w-full my-2">
                <form-field-wrapper :error="cardElementError">
                    <div ref="card" class="px-4 py-2 border rounded-md bg-white"></div>
                </form-field-wrapper>
            </div>

            <stateful-button
                class="button button-primary w-full my-2"
                :loading="loading"
                :disabled="selectedCard === 'existing'"
                @click="submitUpdate"
            >
                Update Card
            </stateful-button>
        </app-modal>
    </div>
</template>

<script>
import axios from '@/util/axios';
import HasStripeElements from '@/mixins/HasStripeElements';
import CurrencyFilter from '@/filters/CurrencyFilter';
import FormatsCreditCardBrand from '@/mixins/FormatsCreditCardBrand';

export default {
    name: 'UpdateCreditCard',

    mixins: [
        FormatsCreditCardBrand,
        HasStripeElements
    ],

    props: {
        buttonClass: {
            type: String,
            default: 'button-text button-primary'
        },

        cardBrand: {
            type: String,
            default: null
        },

        cardLastFour: {
            type: String,
            default: null
        },

        setupIntent: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            modalOpen: false,
            loading: false,
            paymentMethod: null,
            selectedCard: this.cardLastFour ? 'existing' : 'new'
        };
    },

    watch: {
        modalOpen () {
            this.cardElementError = null;
        }
    },

    methods: {
        mountStripe () {
            // Gotta wait for the ref from inside the modal to become available
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.initializeStripeCard(this.$refs.card);
                });
            });
        },

        closeModal () {
            this.selectedCard = this.cardLastFour ? 'existing' : 'new';

            this.stripeObject.card.unmount();
        },

        launchModal () {
            this.modalOpen = true;
        },

        async submitUpdate () {
            this.loading = true;

            const paymentMethod = await this.confirmCardSetup(this.setupIntent.client_secret);

            if (!paymentMethod) {
                this.loading = false;
                return;
            }

            try {
                await axios.post(this.route('account.update-credit-card'), { paymentMethod });

                this.modalOpen = false;

                App.alert().success(
                    '',
                    'You have successfully updated your billing information.',
                    {
                        onClose () {
                            window.location.reload();
                        }
                    }
                );
            } catch (error) {
                const { type } = error.response.data;

                if (type === 'card') {
                    const { amount, card, declineCode } = error.response.data;

                    App.alert().warning('', `Sorry, we were not able to process the charge of ${CurrencyFilter(amount, true)} with your credit card ending in ${card.lastFour}. Bank error: ${declineCode}. Please try again or add a new credit card. Your most recently added credit card will be used for all future renewals.`);
                } else if (type === 'generic') {
                    const { message } = error.response.data;

                    App.alert().error('', message);
                }
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
