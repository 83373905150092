<template>
    <div>
        <a
            v-theme="['wall.background', 'wall.branding-footer']"
            class="flex items-center justify-center space-x-2 h-16 bg-purple-light"
            :href="hyperloopUrl('event_wall')"
            target="_blank"
        >
            <p v-theme="['wall.text-color']" class="font-semibold">Made with</p>
            <logo-rsvpify-full
                v-theme="['wall.rsvpify-logo']"
                class="w-20 h-auto"
                :box="null"
                :checkmark="null"
                :text="null"
            ></logo-rsvpify-full>
        </a>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import HyperloopUrls from '@/mixins/HyperloopUrls';

export default {
    name: 'WallBrandedFooterBlock',
    mixins: [HyperloopUrls],

    computed: {
        event: get('Event/event')
    }
};
</script>
