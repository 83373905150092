<template>
    <div class="p-1 md:p-2 md:col-3 touch-action-none">
        <div
            class="flex flex-col w-full h-full text-gray-600 items-center p-4 bg-white"
            :class="contentClass"
        >
            <p class="mt-3 md:mt-0 uppercase text-gray-500 text-center text-sm tracking-wide">
                <slot name="title">
                    {{ title }}
                </slot>
            </p>

            <div class="mt-2">
                <slot name="icon"></slot>
            </div>

            <p class="mt-2 text-3xl font-bold text-center w-full">
                <slot></slot>
            </p>

            <p class="mt-2 text-center text-sm">
                <slot name="footer">
                    {{ footer }}
                </slot>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SlidableCard',

    props: {
        contentClass: {
            type: String,
            default: 'shadow rounded-md justify-center'
        },
        footer: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    }
};
</script>
