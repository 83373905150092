/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-symptoms-cold-fever-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.96 15.17a2.71 2.71 0 100 5.42 2.71 2.71 0 100-5.43zM17.51 13.14h.91M17.97 13.14v2.03M21 14.21l.65.64M21.33 14.53l-1.44 1.44M22.72 17.44v.9M22.72 17.89h-2.04M21.65 20.93l-.65.64M21.33 21.25l-1.44-1.44M18.42 22.64h-.91M17.97 22.64V20.6M14.93 21.57l-.64-.64M14.61 21.25l1.44-1.44M13.22 18.34v-.9M13.22 17.89h2.03M14.29 14.85l.64-.64M14.61 14.53l1.44 1.44M11.41.75a3 3 0 100 6 3 3 0 100-6zM15.96 10.888l-.01-.01a5.252 5.252 0 00-7.18-1.93 5.213 5.213 0 00-2.63 4.53v2.25h2.25l.75 7.5h2.8M2.96 3.888l-1.45 1.68H1.5c-.33.37-.33.92 0 1.3l.88 1.02h0c.32.37.32.92-.01 1.3l-.885 1.03h-.01c-.33.37-.33.92 0 1.3l.88 1.032h0c.32.37.32.92-.01 1.3l-.89 1.03h-.01c-.33.37-.33.92 0 1.3l1.442 1.68M19.524 9.206l-.01-.01a.977.977 0 010-1.3l.88-1.032v-.01c.32-.38.32-.93-.01-1.3l-1.45-1.69"/></g>'
  }
})
