/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-pasta-plate-warm-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>italian spaghetti sauce</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75l-.62.823v-.01c-.52.67-.41 1.63.24 2.17v0l-.01-.01c.65.54.75 1.5.24 2.17l-.62.82M5.8 4.5l-.62.82v-.01c-.52.67-.41 1.63.24 2.17v0h0c.64.54.75 1.5.24 2.17l-.617.82M17.63 4.5l.61.82h0c.51.67.4 1.63-.25 2.17v0-.01c-.66.54-.76 1.5-.25 2.17l.61.82M.75 18.75h22.5M21.44 18.75c-.58-1.7-1.9-3.12-3.44-3.12h-.01c-.5 0-.99.12-1.43.36h0a5.337 5.337 0 00-4.58-2.5h0c-1.86-.03-3.6.91-4.58 2.49h0c-.44-.24-.93-.36-1.43-.37-1.54 0-2.863 1.41-3.44 3.11"/><path pid="1" d="M17.256 23.25H6.734a1.91 1.91 0 01-1.48-.67l-3.02-3.84h19.5l-3.02 3.83v-.01c-.37.43-.91.67-1.48.66z"/></g>'
  }
})
