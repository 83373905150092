/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'seafood-shrimp': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M14.27 18.549l-2.9-1.58a.25.25 0 01.119-.469h2.059a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5 5.387 5.387 0 00-5.5 5.5v6.834a.667.667 0 00.667.666.5.5 0 00.486-.381A4.729 4.729 0 0113.8 19.5a.646.646 0 00.6-.113.5.5 0 00.155-.362.583.583 0 00-.285-.476zM18.29 7.323a.25.25 0 00.176.427h4.981a.5.5 0 00.494-.583 7.968 7.968 0 00-1.371-3.3.5.5 0 00-.761-.063zM13.5 1.9a8.252 8.252 0 013.033 4.525.25.25 0 00.418.114l3.8-3.8a.5.5 0 00-.063-.761A7.956 7.956 0 0016.052.5h-3.666a.25.25 0 00-.15.45zM18.466 9.25a.25.25 0 00-.176.427l3.519 3.52a.5.5 0 00.762-.064 7.967 7.967 0 001.37-3.3.5.5 0 00-.494-.583zM14.278 9a1 1 0 001-1.088A6.762 6.762 0 0012.6 3.1L9.669.9a2 2 0 00-1.2-.4H1.052a1 1 0 00-1 1.071A8.023 8.023 0 008.035 9zM7.052 4a1 1 0 111-1 1 1 0 01-1 1zM17.138 10.646a.5.5 0 00-.353-.146h-.733a.5.5 0 00-.5.5v5a.5.5 0 00.5.5 7.956 7.956 0 004.634-1.482.5.5 0 00.063-.761z"/></g>'
  }
})
