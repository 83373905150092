import get from 'lodash/get';
import { findIndex } from 'lodash';

export default {
    data () {
        return {
            isBlockPlacementAvailable: true
        };
    },

    methods: {
        blockMove (event) {
            /**
             * The future index of the block we're dragging if it were dropped
             */
            const { futureIndex } = event.draggedContext;

            /**
             * Is the block being inserted before or after the related block
             */
            const { willInsertAfter } = event;

            /**
             * Gets the forced order value for the related block - a block
             * that's before or after the block we're dragging. Whether the related
             * block is before or after our block depends on the value of
             * the willInsertAfter variable
             */
            let relatedBlockForcedOrder = get(event.related.dataset, 'forcedOrder', undefined);

            /**
             * Gets the forced order values for the block before and after the
             * related block. We need this information to make sure we're not
             * dropping the new block in between two blocks with forced order
             * (i.e. WelcomeMessage and TicketingBlock)
             */
            const nextBlockForcedOrder = get(event.related.nextSibling, 'dataset.forcedOrder', 999);
            const previousBlockForcedOrder = get(event.related.previousSibling, 'dataset.forcedOrder', -999);

            /**
             * Since the data attribute we've fetched is a string, we need to
             * parse it and deal with it as an integer
             */
            if (relatedBlockForcedOrder) {
                relatedBlockForcedOrder = parseInt(relatedBlockForcedOrder, 10);
            }

            if (willInsertAfter) {
                /**
                 * If the related block is before the block we're dragging
                 * and doesn't have a forcedOrder value, assume this block
                 * can stay before the block we're dragging by giving
                 * the forcedOrder a really low value
                 */
                if (relatedBlockForcedOrder === undefined) {
                    relatedBlockForcedOrder = -999;
                }

                this.isBlockPlacementAvailable = futureIndex > relatedBlockForcedOrder && futureIndex < nextBlockForcedOrder;

                return this.isBlockPlacementAvailable;
            }

            /**
             * If the related block is after the block we're dragging
             * and doesn't have a forcedOrder value, assume this block
             * can stay after the block we're dragging by giving
             * the forcedOrder a really high value
             */
            if (relatedBlockForcedOrder === undefined) {
                relatedBlockForcedOrder = 999;
            }

            this.isBlockPlacementAvailable = futureIndex < relatedBlockForcedOrder && futureIndex > previousBlockForcedOrder;

            return this.isBlockPlacementAvailable;
        },

        findLastAvailableNewBlockIndex (section) {
            const sectionBlocksLength = section.blocks.length;

            for (let futureIndex = sectionBlocksLength; futureIndex >= 0; futureIndex--) {
                const previousBlock = futureIndex >= 1 ? section.blocks[futureIndex - 1] : null;
                const nextBlock = futureIndex < sectionBlocksLength ? section.blocks[futureIndex] : null;

                const fakeDragEvent = {
                    draggedContext: {
                        futureIndex
                    },
                    willInsertAfter: true,
                    related: {
                        dataset: {
                            forcedOrder: previousBlock?.properties?.forcedOrder || -999
                        },
                        nextSibling: {
                            dataset: {
                                forcedOrder: nextBlock?.properties?.forcedOrder || 999
                            }
                        },
                        previousSibling: {
                            dataset: {
                                forcedOrder: previousBlock?.properties?.forcedOrder || -999
                            }
                        }
                    }
                };

                if (this.blockMove(fakeDragEvent)) {
                    return futureIndex;
                }
            }

            // Default to last place in the section
            return sectionBlocksLength;
        }
    }
};
