/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>profile avatar account person</desc><path pid="0" _fill="currentColor" d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 2.5a9.482 9.482 0 017.3 15.561.5.5 0 01-.589.136c-.88-.4-1.945-.79-3.16-1.237l-.744-.274a.8.8 0 01-.324-.552 1.99 1.99 0 01.187-1.534c1.068-1.176 1.73-2.44 1.73-5.1A4.294 4.294 0 0012 4.832 4.294 4.294 0 007.6 9.5c0 2.663.662 3.927 1.73 5.1a1.99 1.99 0 01.192 1.533.8.8 0 01-.324.552l-.744.274c-1.215.447-2.28.84-3.16 1.237a.5.5 0 01-.589-.136A9.482 9.482 0 0112 2.5z"/>'
  }
})
