/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wedding-ring-heart-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.75 9.93L7.27 5.26l-.01-.01a2.648 2.648 0 01-.5-3.06v0-.01A2.647 2.647 0 0110.31.99c.25.12.48.29.68.49l.73.73.73-.74h-.01a2.632 2.632 0 013.74 0c.2.2.36.43.49.68v0l-.01-.01c.5 1.02.3 2.25-.5 3.05z"/><path pid="1" d="M17 8.636h0c3.51 2.89 4.01 8.09 1.11 11.61-2.9 3.51-8.1 4.01-11.62 1.11a8.252 8.252 0 01-1.12-11.62c.33-.41.7-.78 1.11-1.12"/><path pid="2" d="M14.75 10.69l-.01-.01a5.243 5.243 0 011.3 7.3c-1.66 2.37-4.93 2.96-7.31 1.3s-2.97-4.93-1.31-7.31c.35-.52.79-.96 1.3-1.31"/></g>'
  }
})
