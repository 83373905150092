import { Extension } from 'tiptap';
import {
    chainCommands, newlineInCode, createParagraphNear, liftEmptyBlock, splitBlockKeepMarks
} from 'tiptap-commands';
import { nodeIsActive } from 'tiptap-utils';

export default class RetainNewParagraphFormatting extends Extension {
    keys () {
        return {
            Enter (state, dispatch, view) {
                if (!nodeIsActive(state, state.schema.nodes.list_item)) {
                    chainCommands(newlineInCode, createParagraphNear, liftEmptyBlock, splitBlockKeepMarks)(state, dispatch, view);
                    return true;
                }

                return false;
            }
        };
    }
}
