/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'casino-dealer-woman-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.447 13.5h-.01a5.31 5.31 0 00.72-4.51c-3.6-.15-5.03-3.745-5.03-3.745s-1.44 3.59-5.04 3.74v-.01c-.46 1.53-.2 3.19.72 4.5"/><path pid="1" d="M20 18.53h-.01c.46-.15.92-.32 1.36-.52h-.01c.74-.34 1.08-1.21.76-1.97l-1.375-3.21-.01-.01c-.25-.57-.37-1.17-.37-1.78V8.96v0c0-4.56-3.7-8.25-8.25-8.25-4.56 0-8.25 3.69-8.25 8.25v2.07h0c0 .6-.13 1.21-.37 1.77l-1.38 3.21h-.01c-.33.75.01 1.62.76 1.96h0c.44.2.9.37 1.36.51M21.61 23.25h0c-.54-1.12-1.26-2.14-2.15-3M4.8 20.25v-.01c-.89.86-1.61 1.88-2.14 2.99"/><path pid="2" d="M8.527 15.834h0a.588.588 0 00-.81.2c-.06.09-.09.19-.09.29v3.32h0c0 .32.26.58.59.58.1-.01.2-.03.29-.09l7.221-4.34v-.01c.27-.17.63-.08.8.19.05.09.08.19.08.3v3.32h-.01c-.01.32-.27.58-.59.58a.693.693 0 01-.31-.09z"/></g>'
  }
})
