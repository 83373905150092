import Vue from 'vue';
import axios from '@/util/axios';

const STATE_WAITING = 'waiting';
const STATE_LOADING = 'loading';
const STATE_READY = 'ready';
const featureCacheKey = `feature-flags.user.${window.user.id}`;

const store = Vue.observable({
    cacheKey: document.querySelector('meta[name="features-cache-key"]').content,
    featureFlags: {},
    fetchRequest: null,
    state: STATE_WAITING
});

export default {
    computed: {
        areFeatureFlagsLoaded () {
            return store.state === STATE_READY;
        },

        isInIFrame () {
            // We can not rely on local storage to cache feature flags
            // if the app is loaded within an iframe.  Some browsers block
            // the use of local storage while within an iframe for security reasons.
            // If we are within an iframe the cache system will be turned off.
            return window.self !== window.top;
        },

        isFeatureEnabled () {
            return (feature) => {
                this.loadFeatureFlags();

                if (!this.areFeatureFlagsLoaded) {
                    return false;
                }

                if (Object.prototype.hasOwnProperty.call(store.featureFlags, feature)) {
                    return store.featureFlags[feature];
                }

                return false;
            };
        }
    },

    methods: {
        hasFeaturesFlagsInCache () {
            if (this.isInIFrame) {
                return false;
            }

            const cacheKey = localStorage.getItem('cache-key');

            if (cacheKey === store.cacheKey && localStorage.getItem(featureCacheKey) !== null) {
                return true;
            }

            return false;
        },

        loadFeatureFlags () {
            if (store.state === STATE_WAITING && this.hasFeaturesFlagsInCache()) {
                store.state = STATE_READY;
                store.featureFlags = JSON.parse(localStorage.getItem(featureCacheKey));
                return Promise.resolve();
            }

            if (store.state !== STATE_WAITING) {
                return store.fetchRequest || Promise.resolve();
            }

            store.state = STATE_LOADING;

            store.fetchRequest = axios.get(this.route('api.feature-flags.index'))
                .then(({ data }) => {
                    store.state = STATE_READY;
                    this.storeFeatureFlags(data.features);
                });

            return store.fetchRequest;
        },

        storeFeatureFlags (featureFlags) {
            store.featureFlags = featureFlags;

            if (this.isInIFrame) {
                return;
            }

            localStorage.setItem('cache-key', store.cacheKey);
            localStorage.setItem(featureCacheKey, JSON.stringify(featureFlags));
        }
    }
};
