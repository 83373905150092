/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bookmark-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>ribbon</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.6 2.032L21.96 8.4l-.01-.01c.58.58.58 1.53 0 2.12L10.28 22.17v-.01a.767.767 0 01-1.3-.54l-.31-6.37-6.37-.32-.01-.001a.758.758 0 01-.75-.76c0-.2.07-.39.21-.53L13.41 1.96v-.01c.58-.59 1.53-.59 2.11 0z"/>'
  }
})
