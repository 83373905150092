<template>
    <div class="pb-6">
        <div>
            <search-field
                v-model="searchQuery"
                class="w-1/2 mb-4"
                placeholder="Search for fashion, wedding..."
            ></search-field>

            <div v-masonry>
                <div
                    v-for="image in images"
                    :key="image.id"
                    v-masonry-tile
                    class="w-1/3 p-2"
                >
                    <img
                        class="w-full cursor-pointer"
                        :class="getWrapperStyles(image)"
                        :src="image.previewURL"
                        @click="selectedImage = image"
                    >
                </div>
            </div>

            <infinite-loading :identifier="infiniteId" @infinite="loadNextPage">
                <div slot="spinner" class="text-center m-2">
                    <app-icon class="h-6 w-6 text-gray-500" name="loader"></app-icon>
                </div>

                <div slot="no-more"></div>
                <div slot="no-results"></div>
            </infinite-loading>
        </div>

        <portal to="upload-modal-footer">
            <div class="space-x-2">
                <stateful-button
                    class="button button-primary disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="areButtonsDisabled"
                    :loading="adding"
                    @click="addImage"
                >Add Image</stateful-button>

                <button
                    class="button button-outline button-primary disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="areButtonsDisabled"
                    @click="isCropperVisible = true"
                >Crop Image</button>
            </div>
        </portal>

        <image-cropper
            v-if="isCropperVisible"
            :image="{ src: selectedImage.webformatURL }"
            @cancel="isCropperVisible = false"
            @input="uploadCroppedImage"
        ></image-cropper>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import UploadsEventImages from '@/mixins/UploadsEventImages';

export default {
    name: 'ImageLibraryTab',

    components: {
        InfiniteLoading
    },

    mixins: [
        UploadsEventImages
    ],

    data () {
        return {
            adding: false,
            debounceTimer: null,
            images: [],
            infiniteId: +new Date(),
            isCropperVisible: false,
            nextPage: 1,
            searchQuery: '',
            selectedImage: null
        };
    },

    computed: {
        areButtonsDisabled () {
            return !this.isImageSelected || this.adding;
        },

        isImageSelected () {
            return !!this.selectedImage;
        }
    },

    watch: {
        searchQuery () {
            clearTimeout(this.debounceTimer);

            this.debounceTimer = setTimeout(() => {
                this.infiniteId = +new Date();
                this.images = [];
                this.nextPage = 1;
            }, 500);
        }
    },

    methods: {
        async addImage () {
            this.adding = true;

            try {
                const urls = await this.uploadEventImage(
                    await this.downloadImage()
                );

                this.$emit('select-image', urls);
            } catch (error) {
                this.$toasted.global.error(error);
            } finally {
                this.adding = false;
            }
        },

        async downloadImage () {
            try {
                const response = await fetch(this.selectedImage.webformatURL);

                return this.createImageFromBlob(this.selectedImage.name, await response.blob());
            } catch (error) {
                throw new Error('Something went wrong while fetching the image from Pixabay.');
            }
        },

        getWrapperStyles (image) {
            if (this.selectedImage?.id !== image.id) {
                return null;
            }

            return 'outline outline-2 outline-purple';
        },

        async loadNextPage ($state) {
            const queryParams = new URLSearchParams({
                key: window.pixabay.key,
                page: this.nextPage,
                q: this.searchQuery,
                safesearch: true
            });

            const response = await fetch(`${window.pixabay.url}?${queryParams}`);
            const { hits } = await response.json();

            if (hits.length === 0) {
                $state.complete();
                return;
            }

            this.images = this.images.concat(
                hits.map(({ id, previewURL, webformatURL }) => {
                    return {
                        id,
                        previewURL,
                        webformatURL,
                        name: this.resolveFilenameFromUrl(previewURL)
                    };
                })
            );

            this.nextPage += 1;

            $state.loaded();
        },

        async uploadCroppedImage ({ file: blob }) {
            const image = this.createImageFromBlob(this.selectedImage.name, blob);

            try {
                this.$emit('select-image', await this.uploadEventImage(image));
            } catch (error) {
                this.$toasted.global.error(error.message);
            }
        }
    }
};
</script>
