/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'payment-stripe-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.89 10c-1.83-.71-1.83-1.07-1.83-1.46s.35-.85 1.33-.85a4 4 0 011.89.47l.63.28a.5.5 0 00.7-.44V5.28a.52.52 0 00-.31-.47 7 7 0 00-2.86-.54c-3 0-5.09 1.87-5.09 4.44 0 3.11 3 4.18 4 4.52 1.7.65 1.7 1.12 1.7 1.53 0 1-1 1.11-1.59 1.11a4.69 4.69 0 01-2.36-.67l-1.22-.7v4a7.83 7.83 0 003.47.79c3.75 0 5.42-2.33 5.42-4.64-.02-2.83-2.09-3.99-3.88-4.65z" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><rect pid="1" x=".74" y=".75" width="22.5" height="22.5" rx="1" ry="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>'
  }
})
