<template>
    <div>
        <div
            v-for="option in question.options"
            :key="option.id"
            class="flex items-center"
        >
            <div class="mr-2">
                {{ option.title }}
            </div>
            <input
                v-model.number="answer[option.id]"
                type="number"
                class="form-field w-28"
                placeholder="Quantity"
            >
        </div>
    </div>
</template>

<script>
import CustomQuestionEditComponent from '@/mixins/CustomQuestionEditComponent';

export default {
    name: 'CustomQuestionAnswerQuantity',

    mixins: [CustomQuestionEditComponent],

    data () {
        return {
            answer: this.answers.reduce((a, b) => {
                if (!b || !b.id) {
                    return a;
                }

                let value = parseInt(b.raw_value, 10);

                if (!value) {
                    value = 0;
                }

                this.$set(a, b.id, value);

                return a;
            }, {})
        };
    },

    watch: {
        answer: {
            deep: true,
            handler (newVal) {
                this.$emit('input', newVal);
            }
        }
    }
};
</script>
