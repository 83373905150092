/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'animal-cat-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>kitten</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.563 5.5l-.01-.01c.54.43 1.32.43 1.87 0l1.59-1.29v-.01c.32-.26.79-.21 1.05.11.1.13.16.29.16.46V9.2c0 1.65-1.68 3-3.75 3-2.08 0-3.75-1.35-3.75-3V4.76h0c-.01-.42.33-.76.74-.76.17-.01.33.05.46.16z"/><path pid="1" d="M16.252 10.74H7.48c-1.25 0-2.25 1-2.25 2.25 0 1.24 1 2.25 2.25 2.24h9v-.001a2.27 2.27 0 002.25-2.27 2.55 2.55 0 00-.16-.83M15.75 15.25v6M17.93 14.73l.82 6.52M8.25 15.25v6M6.07 14.73l-.82 6.52M1.68 7.746v0l-.01-.01a3.185 3.185 0 01-.01-4.506v-.02c.99-1 2.6-1 3.59-.01 0 0 0 0 0 0h0c.79.79.79 2.08-.01 2.88M5.86 11.45L1.68 7.74"/></g>'
  }
})
