<template>
    <div class="flex flex-col absolute z-50 text-2xl text-white bg-black items-center justify-center w-full h-full">
        <img
            v-if="form.settings.logo_url"
            :src="form.settings.logo_url"
            alt="logo"
            class="lg:w-1/6 w-1/4 block mx-auto mb-10"
        >
        <div class="flex flex-col">
            <button
                v-for="language in languages"
                :key="language.name"
                v-theme="'form.button'"
                class=" justify-center border border-smoke-200 shadow-md rounded-md p-4 flex items-center mb-4 w-64"
                @click="selectLanguage(language)"
            >
                {{ language.name }}
            </button>
        </div>
    </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';

export default {
    name: 'RsvpPageLanguageSelector',

    computed: {
        ...get('Form/*'),
        ...get('Event/event@', {
            languages: 'enabled_languages'
        }),
        submission: sync('Submission/submission')
    },

    methods: {
        selectLanguage (language) {
            this.$i18n.locale = language.name;
            this.submission.language_id = language.id;
            this.$emit('disable-language-selector');
        }
    }
};
</script>
