/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat-translate-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>international languages foreign</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.652.748l-3.75 2.25 2.25 3.75"/><path pid="1" d="M23.25 8.187h0a6.742 6.742 0 00-6.89-4.42M4.348 23.24l3.75-2.25-2.25-3.75"/><path pid="2" d="M.75 15.808l-.01-.01a6.726 6.726 0 006.88 4.41"/><rect pid="3" width="12" height="12" x=".75" y=".748" rx="1.5"/><path pid="4" d="M15.75 11.24h6-.01c.82-.01 1.5.67 1.5 1.5v9c0 .82-.68 1.5-1.5 1.5h-9.01a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-6"/><path pid="5" d="M15.75 20.24v-4.5h0a1.496 1.496 0 112.99-.02s0 0 0 0v4.5M15.75 18.75h3M6.75 3.75v1.5M3.75 5.25h6M8.25 5.248s-1.5 4.5-4.5 4.5"/><path pid="6" d="M6.74 8.014l-.01-.01a3.9 3.9 0 002.99 1.73"/></g>'
  }
})
