import Vue from 'vue';
import keys from 'lodash/keys';
import isArray from 'lodash/isArray';
import store from '@/store/index';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@root/tailwind.config';

const tailwind = resolveConfig(tailwindConfig);

Vue.directive('class-when-screen', (el, { modifiers, value, arg }, vnode) => {
    const screenClasses = keys(modifiers);

    if (screenClasses.length) {
        let satisfiesBreakpoints = true;

        screenClasses.forEach((screenClass) => {
            const currentScreenWidth = vnode.context.$data.screenWidth;
            const screenClassWidth = tailwind.theme.screens[screenClass].replace('px', '');

            switch (String(arg)) {
                case 'lt':
                    satisfiesBreakpoints = satisfiesBreakpoints && (currentScreenWidth < screenClassWidth);
                    break;
                case 'lte':
                    satisfiesBreakpoints = satisfiesBreakpoints && (currentScreenWidth <= screenClassWidth);
                    break;
                case 'gt':
                    satisfiesBreakpoints = satisfiesBreakpoints && (currentScreenWidth > screenClassWidth);
                    break;
                case 'gte':
                    satisfiesBreakpoints = satisfiesBreakpoints && (currentScreenWidth >= screenClassWidth);
                    break;
                default:
                    satisfiesBreakpoints = false;
            }
        });

        const classes = value.split(' ');

        if (el.previouslyAppliedClasses) {
            el.classList.remove(...el.previouslyAppliedClasses);
        }

        if (satisfiesBreakpoints) {
            el.classList.add(...classes);
            el.previouslyAppliedClasses = classes;
        } else {
            el.classList.remove(...classes);
        }
    }
});

function getSafeClassName (styleKey) {
    return styleKey.replace('.', '-');
}

function applyThemeClass (el, value) {
    Vue.nextTick(() => {
        if (el.classList.contains('themed-element')) {
            return;
        }

        let classList = ['themed-element'];

        if (isArray(value)) {
            classList = classList.concat([
                ...value.map((styleKey) => {
                    return store.state.Theme.styles[styleKey];
                }),
                ...value.map((styleKey) => {
                    return `themed-element-${getSafeClassName(styleKey)}`;
                })
            ]);
        } else {
            classList = classList.concat([
                store.state.Theme.styles[value],
                `themed-element-${getSafeClassName(value)}`
            ]);
        }

        el.classList.add(...classList);
    });
}

Vue.directive('theme', {
    bind (el, { value }) {
        applyThemeClass(el, value);
    },

    update (el, { value }) {
        applyThemeClass(el, value);
    }
});
