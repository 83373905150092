/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>pencil write</desc><g _fill="currentColor"><path pid="0" d="M15.067 3.986a.5.5 0 00-.354-.148.5.5 0 00-.354.147L3.437 14.91a.5.5 0 000 .707l4.948 4.948a.5.5 0 00.707 0L20.009 9.648a.5.5 0 000-.706zM2.43 16.8a.5.5 0 00-.489-.127.5.5 0 00-.351.364L.084 23.314a.5.5 0 00.133.47.507.507 0 00.47.132l6.272-1.5a.5.5 0 00.237-.84zM23.2 2.924L21.077.8a2.5 2.5 0 00-3.532 0l-1.418 1.417a.5.5 0 000 .707l4.95 4.949a.5.5 0 00.707 0L23.2 6.454a2.5 2.5 0 000-3.53z"/></g>'
  }
})
