<template>
    <div>
        <div
            ref="dropZone"
            v-theme="['form.file-upload', 'form.background', 'form.text']"
            class="rounded-md p-4 bg-gray-100 text-gray-800 block cursor-pointer"
            @click="openFilePicker"
        >
            <div
                class="dz-message my-6 min-h-full flex items-center justify-center"
            >
                <app-icon
                    name="upload-button"
                    class="h-6 w-6 mr-2 opacity-50"
                ></app-icon>
                {{ $t('placeholder-file-upload') }}
            </div>
        </div>

        <div v-show="false" ref="template">
            <div class="dz-preview p-4 pb-0 mb-4 bg-white shadow-lg rounded-md">
                <div class="flex flex-col">
                    <div class="flex mb-4">
                        <div class="mr-4 border-2 border-gray-50 rounded-lg max-w-20 dz-thumbnail">
                            <img data-dz-thumbnail>
                        </div>
                        <div class="flex-1 text-sm">
                            <div class="font-semibold text-gray-800">
                                <span data-dz-name></span>
                                <div class="animate-pulse inline">
                                    <span class="dz-success-mark">✔</span>
                                    <span class="dz-error-mark">✘</span>
                                </div>
                            </div>

                            <div class="text-gray-600" data-dz-size></div>
                        </div>
                        <div
                            class="h-3 w-3 flex-none text-gray-800 cursor-pointer"
                        >
                            <svg
                                data-dz-remove
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                            >
                                <title>close</title><desc>delete remove</desc><path fill="currentColor" d="M14.3,12.179a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.442L12.177,9.7a.25.25,0,0,1-.354,0L2.561.442A1.5,1.5,0,0,0,.439,2.563L9.7,11.825a.25.25,0,0,1,0,.354L.439,21.442a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,0,0,2.122-2.121Z" />
                            </svg>
                        </div>
                    </div>

                    <div class="h-4">
                        <div
                            class="h-1 rounded dz-progress"
                            role="progressbar"
                            aria-valuenow="0"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            data-dz-uploadprogress
                        ></div>
                    </div>
                    <div class="dz-error-message">
                        <div class="text-sm alert alert-error" data-dz-errormessage></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { find, forEach } from 'lodash';
import DropZone from 'dropzone';
import Vapor from 'laravel-vapor';
import CustomQuestion from '@/mixins/CustomQuestion';
import formatNumber from '@/util/format-number';

export default {
    name: 'FormFileUploadsQuestion',

    mixins: [CustomQuestion],

    data () {
        return {
            dropZone: null,
            dropZoneMounted: false,
            files: []
        };
    },

    computed: {
        defaultValue () {
            return [];
        },

        errorBag: get('Submission/errorBag'),

        namespace () {
            return 'fileUploads';
        }
    },

    watch: {
        errorBag: {
            deep: true,
            handler (errorBag) {
                forEach(this.files, (file, index) => {
                    const fileErrorKey = `answers.${this.answerableId}.${index}.key`;
                    if (errorBag.has(fileErrorKey)) {
                        this.dropZone.emit('error', this.dropZone.files[index], errorBag.get(fileErrorKey));
                    }
                });
            }
        },

        files (newValue) {
            this.$emit('input', newValue);
        },

        value (newValue) {
            if (newValue.length === 0) {
                this.dropZone.removeAllFiles();
            }
        }
    },

    mounted () {
        DropZone.autoDiscover = false;

        this.dropZone = new DropZone(this.$refs.dropZone, {
            accept: (file, done) => {
                const fileSizeInKb = Math.ceil(file.size / 1000);
                const minimum = this.settings.minimum || 0;
                const maximum = this.settings.maximum || 10000;
                const maxFiles = this.settings.maxNumberOfFiles || 10;
                const fileExtension = file.name.split('.').pop();
                const allowedFileTypes = this.getAllowedFileTypes();

                this.$emit('disable-completion');

                if (this.dropZone.files.length > maxFiles) {
                    return done(this.$t('label-you-can-not-upload-more-than-x-files').replace('#NUMBER_OF_FILES#', maxFiles));
                }

                if (allowedFileTypes && !allowedFileTypes.has(fileExtension)) {
                    return done(`${this.$t('label-please-upload-one-of-the-following-file-types')} ${this.settings.allowedFileTypes}`);
                }

                if (fileSizeInKb < minimum) {
                    return done(`${this.$t('label-the-minimum-file-size-is')} ${formatNumber(minimum)}KB`);
                }

                if (fileSizeInKb > maximum) {
                    return done(`${this.$t('label-the-maximum-file-size-is')} ${formatNumber(maximum)}KB`);
                }

                return done();
            },
            clickable: this.$refs.dropZone,
            maxFilesize: null,
            addRemoveLinks: false,
            url: '#',
            previewTemplate: this.$refs.template.innerHTML
        });

        this.dropZone.uploadFiles = async (files) => {
            return files.forEach(this.uploadFile);
        };

        this.dropZone.on('removedfile', (file) => {
            if (find(this.files, { uuid: file.uuid })) {
                this.files.splice(find(this.files, { uuid: file.uuid }), 1);
            }
        });

        this.dropZone.on('complete', this.completeFiles);
        this.dropZone.on('cancelled', this.completeFiles);
        this.dropZone.on('removedfile', this.completeFiles);

        this.dropZoneMounted = true;
    },

    methods: {
        completeFiles () {
            if (
                this.dropZone.getQueuedFiles().length === 0
                && this.dropZone.getUploadingFiles().length === 0
                && this.dropZone.getRejectedFiles().length === 0
            ) {
                this.$emit('enable-completion');
            }
        },

        getAllowedFileTypes () {
            // If `allowedFileTypes` is empty, then all file types are allowed.
            if (!this.settings.allowedFileTypes) {
                return null;
            }

            // Set data structure will prevent duplicates.
            const allowedFileTypes = new Set(this.settings.allowedFileTypes.replace(/ /g, '').split(','));

            // Jpg and jpeg are treated as the same as both are considered valid extensions for image/jpg.
            if (allowedFileTypes.has('jpg') || allowedFileTypes.has('jpeg')) {
                return allowedFileTypes.add('jpg').add('jpeg');
            }

            return allowedFileTypes;
        },

        openFilePicker (event) {
            /**
             * For whatever stupid reason, iOS safari has a bug (that apple refuses to fix)
             * that marks event.isTrusted as false for "non-interactive" elements as defined by Apple.
             * This check forces the file browser to open on a single click if the event is not trusted.
             *
             * See: https://developer.mozilla.org/en-US/docs/Web/API/Element/click_event#safari_mobile
             */
            if (!event.isTrusted) {
                this.dropZone.hiddenFileInput.click();
                this.dropZone.element.focus();
            }
        },

        async uploadFile (file) {
            await Vapor.store(file, {
                progress: (progress) => {
                    const percentage = Math.round(progress * 100 * 0.9);
                    this.dropZone.emit('uploadprogress', file, percentage);
                }
            }).then((uploadedFile) => {
                this.dropZone.emit('uploadprogress', file, 100);
                this.$set(file, 'status', DropZone.SUCCESS);
                this.$set(file, 'uuid', uploadedFile.uuid);
                this.dropZone.emit('success', file);
                this.dropZone.emit('complete', file);
                this.dropZone.processQueue();

                this.$set(uploadedFile, 'filename', file.upload.filename);

                this.files.push(uploadedFile);
            }).catch(() => {
                this.dropZone.emit('error', file, this.$t('label-something-went-wrong-trying-to-upload-the-file'));
            });
        }
    }
};
</script>
