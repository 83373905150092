<template>
    <button-picker
        :options="[
            { id: 'no', label: noButtonText },
            { id: 'yes', label: yesButtonText }
        ]"
        class="w-96 mx-auto"
        @input="complete"
    ></button-picker>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
    name: 'EventDetailsTicketsStep',

    props: {
        noButtonText: {
            type: String,
            required: true
        },
        yesButtonText: {
            type: String,
            required: true
        }
    },

    computed: {
        sellsTickets: sync('CreateEvent/sells_tickets')
    },

    methods: {
        complete (selectedOptionId) {
            this.sellsTickets = selectedOptionId === 'yes';
            this.$emit('completed');
        }
    }
};
</script>
