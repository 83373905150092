<template>
    <div class="flex flex-col items-center justify-center h-full">
        <app-icon
            name="users-upload-circle"
            class="w-20 h-20 text-gray-600"
            stroke
        ></app-icon>

        <h1 class="text-3xl text-gray-600 text-center my-6">
            Processing your import! You’ll see a preview and be able to make sure everything looks good in just a moment.
        </h1>

        <app-icon name="loader" class="w-20 h-20 my-8 text-gray-600"></app-icon>

        <stateful-button
            class="button w-full md:w-auto mt-6"
            :loading="canceling"
            @click="cancel"
        >
            Cancel
        </stateful-button>

        <p class="mt-10 font-bold text-gray-600">Please do not leave this page.</p>
    </div>
</template>

<script>
import axios from '@/util/axios';
import InteractsWithAbly from '@/mixins/InteractsWithAbly';

export default {
    mixins: [InteractsWithAbly],

    props: {
        importRecord: {
            required: true,
            type: Object
        }
    },

    data () {
        return {
            canceling: false
        };
    },

    created () {
        this.$echo.private(`imports.${this.importRecord.id}`)
            .listen('.Domain\\InviteList\\Events\\ImportProcessed', () => {
                window.location.href = this.route('invite-list.import.preview', [this.importRecord.event, this.importRecord]);
            });

        // We've seen situations where the import is processed before Echo has
        // booted up, in which case the emitted event is lost. We're delaying
        // processing to allow Echo to boot up before the event is emitted.
        setTimeout(() => {
            axios.post(this.route('api.invite-list.import.process', this.importRecord));
        }, 1000);
    },

    methods: {
        cancel () {
            this.canceling = true;

            axios.delete(this.route('api.invite-list.import.cancel', this.importRecord))
                .then(() => {
                    this.$toasted.global.success('Import canceled successfully.');
                    window.location.href = this.route('invite-list.index', this.importRecord.event);
                }, () => {
                    this.$toasted.global.error('Something went wrong canceling the import.  Please try again.');
                })
                .finally(() => {
                    this.canceling = false;
                });
        }
    }
};
</script>
