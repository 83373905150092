/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-quarantine-house-shield-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.34 15.54a2.8 2.8 0 100 5.6 2.8 2.8 0 100-5.61zM17.88 13.44h.93M18.34 13.44v2.1M21.48 14.54l.66.66M21.81 14.88l-1.48 1.48M23.25 17.88v.93M23.25 18.34h-2.1M22.14 21.48l-.66.66M21.81 21.81l-1.48-1.48M18.81 23.25h-.93M18.34 23.25v-2.1M15.2 22.14l-.66-.66M14.88 21.81l1.48-1.48M13.44 18.81v-.93M13.44 18.34h2.1M14.54 15.2l.66-.66M14.88 14.88l1.48 1.48M19.25 9.5V3.75c0-.56-.32-1.07-.83-1.3l-.01-.01A20.553 20.553 0 009.98.72h0c-2.9-.03-5.78.55-8.43 1.71h-.01c-.51.23-.83.73-.83 1.29v7.22c0 3.532 1.54 8.35 8.22 10.92h0c.65.25 1.38.25 2.04-.01M14.81 10.85V7.07"/><path pid="1" d="M5.06 7.068v6.286h0c0 .89.72 1.62 1.62 1.62h3.75"/><path pid="2" d="M3.43 8.49l4.9-4.283h-.01c.91-.81 2.29-.81 3.21-.01l4.88 4.28M11.56 14.97H8.31v-3.25h0c-.01-.9.72-1.63 1.62-1.63.89-.01 1.62.72 1.62 1.62 0 0 0 0 0 0z"/></g>'
  }
})
