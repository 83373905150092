<template>
    <transition name="fade">
        <div
            v-show="value"
            class="absolute inset-0 z-50 flex flex-col bg-white"
            :class="{'py-14': isPreview}"
        >
            <div class="flex px-4 py-2 border-b">
                <h1 class="text-gray-600 grow">{{ $t('title-payment-screen') }}</h1>

                <button
                    role="button"
                    class="button-icon button-sm"
                    @click="$emit('input', false)"
                >
                    <app-icon name="close"></app-icon>
                </button>
            </div>

            <div class="flex-1 py-4 overflow-y-auto">
                <img
                    v-if="form.settings.logo_url"
                    v-theme="'form.logo'"
                    :src="form.settings.logo_url"
                    alt="Logo"
                    class="block mx-auto mb-4"
                    :class="formLogoStyle"
                >

                <div class="px-4 xl:px-20">
                    <div class="mb-4">
                        <h3 v-theme="'form.title-font'" class="mb-2 font-semibold">
                            {{ event.name }}
                        </h3>

                        <accordion-list
                            v-if="order"
                            v-model="activeAccordionItem"
                            class="border rounded-md"
                        >
                            <accordion-list-item
                                :item-id="1"
                                active-header-class="border-b pb-2"
                                class="px-4 py-2"
                            >
                                <div slot="header" class="font-normal">
                                    <div class="text-xs text-gray-600">{{ $t('text-order-summary') }}</div>
                                    <div class="text-2xl">
                                        <money-field static :value="order.total"></money-field>
                                    </div>
                                </div>

                                <div slot="content" class="text-sm">
                                    <div
                                        v-for="item in order.items"
                                        :key="item.id"
                                        class="flex justify-between border-b p-2"
                                    >
                                        <div>
                                            <div class="flex items-center font-semibold">
                                                <app-icon
                                                    v-if="item.orderable.settings.icon"
                                                    v-theme="'form.accented-text'"
                                                    :name="item.orderable.settings.icon"
                                                    class="h-5 w-5 mr-2"
                                                ></app-icon>

                                                {{ item.orderable.discountable_label }}
                                            </div>

                                            <div class="flex items-end color-gray-500">
                                                <div class="h-5 w-5 mr-2 mt-1"></div>
                                                {{ item.quantity }}&nbsp;x&nbsp;
                                                <money-field static :value="item.price"></money-field>
                                            </div>
                                        </div>

                                        <div class="flex items-center font-semibold">
                                            <money-field static :value="item.totalPrice"></money-field>
                                        </div>
                                    </div>

                                    <div v-theme="'form.accent'" class="flex justify-between px-2 pt-4 pb-2 font-semibold">
                                        <div>Taxes &amp; Service Fees</div>
                                        <money-field static :value="order.tax"></money-field>
                                    </div>
                                </div>
                            </accordion-list-item>
                        </accordion-list>
                    </div>

                    <div class="mb-4">
                        <h3 v-theme="'form.title-font'" class="mb-2 font-semibold">
                            {{ $t('subtitle-name-on-card') }}
                        </h3>

                        <form-field-wrapper
                            class="mb-4"
                            :should-show-error="cardholder.errors.has('name')"
                            :error="cardholder.errors.get('name')"
                        >
                            <div class="border rounded-md overflow-hidden">
                                <input
                                    v-model="cardholder.name"
                                    type="text"
                                    class="block w-full appearance-none px-4 py-2 bg-transparent leading-normal outline-none placeholder-gray-400 focus:shadow"
                                    :placeholder="$t('label-name')"
                                >
                            </div>
                        </form-field-wrapper>

                        <div>
                            <form-field-wrapper
                                :should-show-error="cardholder.errors.has('email')"
                                :error="cardholder.errors.get('email')"
                            >
                                <div class="border rounded-md overflow-hidden">
                                    <input
                                        v-model="cardholder.email"
                                        type="email"
                                        class="block w-full appearance-none px-4 py-2 bg-transparent leading-normal outline-none placeholder-gray-400 focus:shadow"
                                        :placeholder="$t('label-email')"
                                    >
                                </div>
                            </form-field-wrapper>
                        </div>
                    </div>

                    <div class="mb-8">
                        <h3 v-theme="'form.title-font'" class="mb-2 font-semibold">
                            {{ $t('subtitle-credit-card-details') }}
                        </h3>

                        <form-field-wrapper :error="cardError || paymentError">
                            <div ref="card" class="px-4 py-2 border rounded-md"></div>
                        </form-field-wrapper>
                    </div>

                    <button
                        v-if="order"
                        v-theme="['form.button', 'form.title-font']"
                        :disabled="disablePaymentButton"
                        class="w-full mb-12 p-2 border border-smoke-200 rounded-md shadow font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
                        @click="confirmPayment"
                    >
                        <template v-if="displayLoader">
                            <app-icon name="loader" class="w-5 h-5 fill-current"></app-icon>
                        </template>
                        <template v-else>
                            {{ $t('button-pay') }} <money-field static :value="order.total"></money-field>
                        </template>
                    </button>

                    <a
                        href="https://rsvpify.com"
                        target="_blank"
                        class="flex flex-col items-center block mx-auto text-gray-400"
                    >
                        <span class="text-3xs mb-1">{{ $t('text-powered-by') }}</span>

                        <logo-rsvpify-full
                            box="gray-400"
                            text="gray-400"
                            class="w-18 h-auto"
                        ></logo-rsvpify-full>
                    </a>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { get } from 'vuex-pathify';
import find from 'lodash/find';
import Form from '@/validation/Form';

export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },

        paymentError: {
            type: String,
            default: null
        },

        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            cardholder: new Form({
                name: '',
                email: ''
            }),
            activeAccordionItem: null,
            isCardIncomplete: true,
            cardError: null
        };
    },

    computed: {
        cardFieldOptions () {
            return {
                style: {
                    base: {
                        color: '#525252',
                        fontFamily: 'Open Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                        fontSize: '16px',
                        lineHeight: '1.5',
                        letterSpacing: 'normal',

                        '::placeholder': {
                            color: '#B0B0B0'
                        }
                    },
                    invalid: {
                        color: '#D64345'
                    }
                }
            };
        },

        disablePaymentButton () {
            return this.isCardIncomplete || this.displayLoader;
        },

        displayLoader () {
            return this.cardholder.processing || this.loading;
        },

        formLogoStyle () {
            return {
                'w-1/4 lg:w-1/6': !this.isWallActive
            };
        },

        guests: get('Submission/submission@guests'),
        testMode: get('Submission/submission@test_mode'),

        guestName () {
            return find(this.guests, (guest) => {
                return !!guest.fullName;
            }).fullName;
        },

        guestEmail () {
            return find(this.guests, (guest) => {
                return !!guest.email;
            }).email;
        },

        isPreview: get('Form/isPreview'),

        isWallActive: get('Wall/wall@active'),

        order: get('Submission/submission@order'),

        form: get('Form/form'),

        event: get('Event/event'),

        stripeOptions () {
            if (!this.event.stripeAccountId) {
                return {};
            }

            return {
                stripeAccount: this.event.stripeAccountId
            };
        }
    },

    watch: {
        value (newValue) {
            if (newValue) {
                this.cardholder.name = this.guestName;
                this.cardholder.email = this.guestEmail;
            }
        },

        testMode (newValue, oldValue) {
            if (newValue !== oldValue) {
                if (window.StripeObject.card) {
                    window.StripeObject.card.unmount();
                }

                this.initializeStripe();
            }
        }
    },

    mounted () {
        this.initializeStripe();
    },

    methods: {
        confirmPayment () {
            this.cardholder.post(this.route('api.payments.validate-cardholder')).then(() => {
                this.$emit('confirm', this.cardholder);
            });
        },

        initializeStripe () {
            const instance = Stripe(this.testMode ? window.stripeTestKey : window.stripeKey, this.stripeOptions);
            const card = instance.elements().create('card', this.cardFieldOptions);

            card.on('change', ({ complete, error }) => {
                this.isCardIncomplete = !complete;
                this.cardError = error == null ? null : error.message;
            })
                .mount(this.$refs.card);

            window.StripeObject = {
                instance,
                card
            };
        }
    }
};
</script>
