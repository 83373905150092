<template>
    <div class="container">
        <section class="panel">
            <page-header icon="color-palette-sample-stroke" label="Event Templates">
                <a :href="route('admin.dashboard')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <div class="row no-gutters">
                <div class="grid-rows-1 md:col-6 mt-2 md:mt-0">
                    <search-field
                        v-model="searchTerm"
                        class="w-full h-full"
                        placeholder="Find an event template..."
                    ></search-field>
                </div>

                <div class="grid-rows-2 md:col-3 mt-2 md:mt-0">
                    <simple-picker
                        v-model="selectedEventTypeId"
                        :items="eventTypeFilters"
                        item-value="id"
                        item-label="name"
                        form-field-class="form-field h-11"
                        class="w-full max-w-xs ml-0 md:ml-2 font-normal normal-case"
                    >
                    </simple-picker>
                </div>

                <div class=" grid-rows-3 md:col-3 mt-2 md:mt-0 justify-end">
                    <a
                        class="button-icon button-primary button-lg"
                        @click="showNewTemplateModal"
                    >
                        <app-icon name="add-circle" stroke></app-icon>
                    </a>
                </div>
            </div>

            <div v-show="filteredTemplates.length === 0" class="flex items-center text-purple text-lg my-8 font-semibold">
                <app-icon name="alert-triangle" class="h-6 w-6 mr-2 text-purple-light"></app-icon>
                <span>No results found</span>
            </div>
            <div class="row">
                <div
                    v-for="eventTemplate in filteredTemplates"
                    :key="eventTemplate.id"
                    class="col-12 md:col-6 lg:col-4 my-8 items-start"
                >
                    <div class="w-full group cursor-pointer">
                        <div class="absolute z-10 top-0 right-0 mt-4 mr-6 cursor-pointer">
                            <base-dropdown class="inline-block" placement="left-start">
                                <template slot="trigger" slot-scope="{ triggerFunction }">
                                    <a
                                        role="button"
                                        @click="triggerFunction"
                                    >
                                        <app-icon
                                            name="navigation-menu-vertical"
                                            class="w-7 h-7 text-snow-600 hover:text-white"
                                            stroke
                                        ></app-icon>
                                    </a>
                                </template>

                                <div class="bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden">
                                    <a
                                        role="button"
                                        class="px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                                        @click="duplicateTemplate(eventTemplate)"
                                    >
                                        <app-icon
                                            name="duplicate-square"
                                            class="h-5 w-5 mr-3"
                                            stroke
                                        ></app-icon>
                                        <p>Duplicate Template</p>
                                    </a>

                                    <a
                                        role="button"
                                        class="px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                                        @click="editTemplate(eventTemplate)"
                                    >
                                        <app-icon
                                            name="settings-slider"
                                            class="h-5 w-5 mr-3"
                                            stroke
                                        ></app-icon>
                                        <p>Edit Template</p>
                                    </a>

                                    <button
                                        v-if="eventTemplate.isPublished"
                                        role="button"
                                        class="w-full px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                                        @click="unpublishTemplate(eventTemplate)"
                                    >
                                        <app-icon
                                            name="close-circle"
                                            class="w-5 h-5 mr-3"
                                            stroke
                                        ></app-icon>
                                        Unpublish
                                    </button>

                                    <button
                                        v-else
                                        role="button"
                                        class="w-full px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                                        @click="publishTemplate(eventTemplate)"
                                    >
                                        <app-icon
                                            name="laptop-launch"
                                            class="w-5 h-5 mr-3"
                                            stroke
                                        ></app-icon>
                                        Publish
                                    </button>

                                    <delete-item
                                        :endpoint="route('api.admin.event-templates.destroy', eventTemplate)"
                                        :confirmation-message="{ title: 'Are you sure?', text: 'Your template and all associated data will be deleted. This cannot be undone.'}"
                                        class="px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-red hover:bg-gray-100"
                                        @delete-item="deleteTemplate(eventTemplate.id)"
                                        @error="deleteError('Something went wrong while deleting this template.')"
                                    >
                                        <app-icon
                                            name="trash"
                                            class="h-5 w-5 mr-3"
                                            stroke
                                        ></app-icon>
                                        <p>Delete Template</p>
                                    </delete-item>
                                </div>
                            </base-dropdown>
                        </div>
                        <div class="relative pb-5/6" @click="openTemplate(eventTemplate)">
                            <img
                                v-if="eventTemplate.previewImageUrl"
                                class="absolute inset-0 h-full w-full rounded-xl shadow-md object-cover opacity-75 group-hover:shadow-xl group-hover:opacity-100"
                                :src="eventTemplate.previewImageUrl"
                                :alt="eventTemplate.name"
                            >
                            <div v-else class="absolute flex h-full w-full rounded-xl items-center justify-center text-xl text-white bg-gray-200">
                                No Preview
                            </div>
                        </div>
                        <div class="relative px-4 -mt-12" @click="openTemplate(eventTemplate)">
                            <div class="flex flex-col items-center bg-white rounded-xl px-4 py-4 shadow-lg group-hover:shadow-2xl">
                                <div v-if="eventTemplate.isPublished" class="badge badge-success uppercase">PUBLISHED</div>
                                <div v-else class="badge badge-warning uppercase">DRAFT</div>

                                <div class="text-lg text-center">
                                    {{ eventTemplate.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <app-modal v-model="showTemplateModal">
            <template slot="header">
                <template v-if="template.duplicate">
                    Duplicate Template
                    <div class="font-normal text-base">{{ `"${template.originalName}"` }}</div>
                </template>
                <template v-else-if="template.id">
                    Edit Template
                </template>
                <template v-else>
                    Add New Template
                </template>
            </template>

            <form-field-wrapper label="Template Name" :error="errorBag.get('name')">
                <input
                    v-model="template.name"
                    class="form-field"
                    placeholder="Enter a template name"
                >
            </form-field-wrapper>

            <form-field-wrapper label="Positive keywords" :error="errorBag.get('positive_keywords')">
                <input
                    v-model="template.positive_keywords"
                    class="form-field"
                    placeholder="mitzvah corporate"
                >
            </form-field-wrapper>

            <form-field-wrapper label="Event Types For Which This Template Will Be Displayed" :error="errorBag.get('event_type_ids')">
                <search-field v-model="eventTypeSearch" placeholder="Type event type name"></search-field>
                <select-list
                    v-model="template.event_types"
                    class="flex flex-wrap"
                    multi-select
                >
                    <select-option
                        v-for="{ id, name } in filteredEventTypes"
                        :id="id"
                        :key="id"
                        class="w-1/2 mt-2"
                    >
                        {{ name }}
                    </select-option>
                </select-list>
            </form-field-wrapper>

            <form-field-wrapper label="Template Preview" :error="errorBag.get('showcase_url')">
                <input
                    v-model="template.showcase_url"
                    class="form-field"
                    placeholder="Enter a template preview / showcase URL"
                >
            </form-field-wrapper>

            <form-field-wrapper label="Preview Image">
                <div>
                    <image-uploader @upload-image="previewImageUploaded">
                        <a class="button-text button-info" role="button">
                            <template v-if="isImageAddedToTemplate">
                                <app-icon
                                    name="add-circle"
                                    class="h-6 w-6 mr-2"
                                    stroke
                                ></app-icon>

                                <span>Add Image</span>
                            </template>
                            <template v-else>
                                <app-icon
                                    name="trash"
                                    class="h-6 w-6 mr-2"
                                ></app-icon>

                                <span>Replace Image</span>
                            </template>
                        </a>
                    </image-uploader>
                </div>

                <img
                    v-if="selectedTemplatePreviewImage"
                    :alt="`${template.name}`"
                    :src="selectedTemplatePreviewImage"
                    class="w-1/3 mt-4"
                >
            </form-field-wrapper>

            <template #footer="{ close }">
                <stateful-button
                    class="button-primary"
                    :loading="saving"
                    @click="saveTemplate"
                >
                    Save
                </stateful-button>

                <button class="button" @click="close">
                    Cancel
                </button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import { serialize } from 'object-to-formdata';
import draggable from 'vuedraggable';
import Deleteable from '@/mixins/Deleteable';
import axios from '@/util/axios';
import Errors from '@/validation/Errors';

export default {
    name: 'EventTemplatesDashboard',

    components: { draggable },

    mixins: [Deleteable],

    props: {
        initialTemplates: {
            type: Array,
            required: true
        },

        eventTypes: {
            type: Array,
            required: true
        },

        planSegments: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            errorBag: new Errors(),
            preview_image_src: null,
            saving: false,
            searchTerm: '',
            showTemplateModal: false,
            templates: cloneDeep(this.initialTemplates),
            template: {},
            selectedEventTypeId: null,
            eventTypeSearch: ''
        };
    },

    computed: {
        eventTypeFilters () {
            return [
                { id: null, name: 'All' },
                ...this.eventTypes
            ];
        },

        filteredByType () {
            return this.selectedEventTypeId !== null;
        },

        filteredEventTypes () {
            const searchRegex = new RegExp(this.eventTypeSearch, 'i');

            return this.eventTypes.filter((type) => {
                return searchRegex.test(type.name);
            });
        },

        filteredTemplates () {
            const searchRegex = new RegExp(this.searchTerm, 'i');

            return this.templates.filter((template) => {
                if (this.filteredByType) {
                    return searchRegex.test(template.name) && find(template.event_types, { id: this.selectedEventTypeId });
                }

                return searchRegex.test(template.name);
            });
        },

        isImageAddedToTemplate () {
            return !this.template.previewImageUrl && !this.preview_image_src;
        },

        selectedTemplatePreviewImage () {
            return this.preview_image_src
                ? this.preview_image_src
                : this.template.previewImageUrl;
        }
    },

    methods: {
        editTemplate (template) {
            this.preview_image_src = null;
            this.template = cloneDeep(template);
            this.template.event_types = this.template.event_types.map(({ id }) => { return id; });
            this.errorBag.clear();
            this.showTemplateModal = true;
        },

        createDuplicateTemplate () {
            this.submit(this.route('api.admin.event-templates.duplicate', this.template))
                .then(({ data }) => {
                    this.templates.unshift(data);

                    this.$toasted.global.success('Your template has been duplicated.');
                    this.showTemplateModal = false;
                    this.openTemplate(data);
                });
        },

        createTemplate () {
            this.submit(this.route('api.admin.event-templates.store'))
                .then(({ data }) => {
                    this.templates.unshift(data);

                    this.$toasted.show('Template has been created.');
                    this.showTemplateModal = false;
                });
        },

        deleteTemplate (eventId) {
            this.templates = this.templates.filter((event) => {
                return event.id !== eventId;
            });

            this.$toasted.show('Your template has been deleted.');
        },

        duplicateTemplate (template) {
            this.template = {
                id: template.id,
                originalName: template.name,
                positive_keywords: template.positive_keywords,
                event_types: template.event_types.map(({ id }) => { return id; }),
                duplicate: true
            };

            this.errorBag.clear();

            this.showTemplateModal = true;
        },

        openTemplate (template) {
            window.location.href = this.route('walls.edit', template);
        },

        previewImageUploaded (image) {
            this.$set(this.template, 'preview_image', image.file);
            this.preview_image_src = image.src;
        },

        publishTemplate (eventTemplate) {
            axios.post(this.route('api.admin.event-templates.publish', eventTemplate))
                .then(({ data }) => {
                    const index = findIndex(this.templates, { id: eventTemplate.id });

                    this.$set(this.templates, index, data);

                    this.$toasted.show('Template published.');
                })
                .catch(() => {
                    this.$toasted.global.error('There was an error publishing the template.');
                });
        },

        showNewTemplateModal () {
            this.preview_image_src = null;
            this.template = {
                event_types: []
            };
            this.errorBag.clear();
            this.showTemplateModal = true;
        },

        saveTemplate () {
            if (this.template.duplicate) {
                this.createDuplicateTemplate();
            } else if (this.template.id) {
                this.updateTemplate();
            } else {
                this.createTemplate();
            }
        },

        submit (url) {
            this.saving = true;

            return axios.post(url, serialize(this.template))
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        this.errorBag.record(error.response.data.errors);
                    } else {
                        this.errorBag.clear();
                    }

                    return Promise.reject(error);
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        unpublishTemplate (eventTemplate) {
            axios.post(this.route('api.admin.event-templates.unpublish', eventTemplate))
                .then(({ data }) => {
                    const index = findIndex(this.templates, { id: eventTemplate.id });

                    this.$set(this.templates, index, data);

                    this.$toasted.show('Template unpublished.');
                })
                .catch(() => {
                    this.$toasted.global.error('There was an error unpublishing the template.');
                });
        },

        updateTemplate () {
            this.submit(this.route('api.admin.event-templates.update', this.template))
                .then(({ data }) => {
                    const templateIndex = findIndex(this.templates, { id: data.id });
                    this.$set(this.templates, templateIndex, data);

                    this.$toasted.show('Template has been updated.');
                    this.showTemplateModal = false;
                });
        }
    }
};
</script>
