<template>
    <div class="container">
        <div class="py-2 font-semibold border-b text-xl text-black">Custom Fonts</div>

        <div v-if="areCustomFontsDisabled" class="flex flex-col md:flex md:flex-row md:flex-1 items-center md:items-start space-x-0 md:space-x-3 border-t p-4 mt-2 bg-white md:bg-gray-50">
            <img class="w-8 h-8 mr-0 mt-0 md:mr-2 md:mt-1" :src="asset('images/account/rsvpify-premium.svg')">
            <div class="w-2/3">
                <p class="mt-3 md:mt-0 text-center md:text-left text-gray-600 tracking-wide font-semibold">Contact your Account Manager to add Custom Fonts</p>
                <p class="mt-3 md:mt-0 mr-0 md:mr-20 text-center md:text-left text-gray-600">
                    Offer an on-brand experience by importing your organization's font libraries.
                </p>
            </div>

            <a
                class="button button-inverse button-info w-52 h-12 mt-5 md:mt-0"
                href="https://help.rsvpify.com/en/articles/5635187-can-i-upload-custom-fonts"
                target="_blank"
            >Learn more</a>

            <button class="button button-info w-52 h-12 mt-5 md:mt-0" @click="chatWithUs">
                <app-icon name="messages-bubble-double-stroke"></app-icon> Chat with us
            </button>
        </div>

        <section class="flex flex-col mt-5" :class="{ 'pointer-events-none opacity-50': areCustomFontsDisabled }">
            <div class="w-full p-4">
                <div v-if="fonts.length === 0" class="w-full flex flex-col items-center justify-center space-y-6">
                    <p class="text-center">
                        For each font, import a .woff, .woff2 and .ttf font file.<br>
                        Imported fonts will only be available to your account.
                    </p>
                    <button class="button button-primary" @click="showImportFontModal = true">
                        <app-icon
                            name="add-circle"
                            class="h-6 w-6 mr-2"
                            stroke
                        ></app-icon>

                        <span>Import Custom Font</span>
                    </button>
                </div>
                <div v-else class="w-full">
                    <div class="flex items-center justify-between space-x-6">
                        <div class="w-full md:w-2/3 lg:w-1/3">
                            <search-field
                                v-model="searchTerm"
                                class="w-full"
                                placeholder="Search fonts ..."
                            ></search-field>
                        </div>

                        <div class="justify-end">
                            <a
                                class="button-icon button-primary button-lg"
                                @click="showImportFontModal = true"
                            >
                                <app-icon name="add-circle" stroke></app-icon>
                            </a>
                        </div>
                    </div>

                    <data-table
                        :columns="columns"
                        :rows="groupedFonts"
                        no-row-border
                        no-results-placeholder="There are currently no custom fonts."
                    >
                        <template slot="row" slot-scope="{ property, row }">
                            <template v-if="property === 'options'">
                                <delete-item
                                    :endpoint="route('api.account.custom-fonts.destroy', row[0].custom_properties.id)"
                                    :confirmation-message="{ title: 'Are you sure?', text: 'Are you sure you want to remove this custom font?' }"
                                    class="flex w-full items-center justify-center cursor-pointer hover:text-red"
                                    @delete-item="refreshFonts"
                                    @error="deleteError('Something went wrong while deleting this custom font.')"
                                >
                                    <app-icon
                                        name="trash"
                                        class="w-5 h-5 mr-5"
                                        stroke
                                    ></app-icon>
                                </delete-item>
                            </template>
                            <template v-else-if="property === 'name'">
                                {{ row[0].custom_properties.name }}
                            </template>
                            <template v-else-if="property === 'ttf'">
                                {{ row[0].custom_properties.ttf }}
                            </template>
                            <template v-else-if="property === 'woff'">
                                {{ row[0].custom_properties.woff }}
                            </template>
                            <template v-else-if="property === 'woff2'">
                                {{ row[0].custom_properties.woff2 }}
                            </template>
                        </template>
                    </data-table>

                </div>
            </div>
        </section>

        <app-modal v-model="showImportFontModal" title="Import Font">
            <template v-if="showImportFontModal" title="Import Font">
                <form-field-wrapper
                    label="Font Name"
                >
                    <input
                        v-model="name"
                        class="form-field"
                        placeholder="Enter a name ..."
                    >
                </form-field-wrapper>

                <div class="flex flex-row mt-3">
                    <input
                        ref="fileTtf"
                        type="file"
                        class="hidden"
                        accept="font/ttf"
                        @change="setFile('Ttf')"
                    >
                    <input
                        ref="fileWoff"
                        type="file"
                        class="hidden"
                        accept="application/font-woff"
                        @change="setFile('Woff')"
                    >
                    <input
                        ref="fileWoff2"
                        type="file"
                        class="hidden"
                        accept="font/woff2"
                        @change="setFile('Woff2')"
                    >

                    <div class="flex flex-row w-full space-x-3">
                        <button class="flex items-center justify-center w-1/3 rounded shadow-md border border-transparent hover:border-gray-400 text-center p-4" @click="$refs.fileTtf.click()">
                            <span v-if="!fileTtf">1. Select .ttf</span>
                            <span v-else class="truncate w-full text-ellipsis">{{ fileTtf.name }}</span>
                        </button>
                        <button class="flex items-center justify-center w-1/3 rounded shadow-md border border-transparent hover:border-gray-400 text-center p-4" @click="$refs.fileWoff.click()">
                            <span v-if="!fileWoff">2. Select .woff</span>
                            <span v-else class="truncate w-full text-ellipsis">{{ fileWoff.name }}</span>
                        </button>
                        <button class="flex items-center justify-center w-1/3 rounded shadow-md border border-transparent hover:border-gray-400 text-center p-4" @click="$refs.fileWoff2.click()">
                            <span v-if="!fileWoff2">3. Select .woff2</span>
                            <span v-else class="truncate w-full text-ellipsis">{{ fileWoff2.name }}</span>
                        </button>
                    </div>
                </div>

                <stateful-button
                    :disabled="!canSaveFont"
                    class="button-primary mt-3"
                    :loading="loading"
                    @click="importFont"
                >
                    Save
                </stateful-button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import {
    cloneDeep, filter, groupBy, values
} from 'lodash';
import axios from '@/util/axios';
import Deleteable from '@/mixins/Deleteable';

export default {
    name: 'CustomFonts',

    mixins: [Deleteable],

    props: {
        initialFonts: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            name: '',
            fonts: cloneDeep(this.initialFonts),
            fileWoff: null,
            fileWoff2: null,
            fileTtf: null,
            loading: false,
            searchTerm: '',
            showImportFontModal: false
        };
    },

    computed: {
        areCustomFontsDisabled () {
            return this.auth().user().plan.features.CustomFonts.type === 'disabled';
        },

        canSaveFont () {
            return this.name && (!!this.fileWoff || !!this.fileWoff2 || !!this.fileTtf);
        },

        columns () {
            return [
                {
                    label: '', property: 'options'
                },
                {
                    label: 'Name', property: 'name'
                },
                {
                    label: '.TTF', property: 'ttf'
                },
                {
                    label: '.WOFF', property: 'woff'
                },
                {
                    label: '.WOFF2', property: 'woff2'
                }
            ];
        },

        groupedFonts () {
            const filteredFonts = filter(this.fonts, (font) => {
                return font.custom_properties.name.toLowerCase().includes(this.searchTerm.toLowerCase());
            });

            return values(groupBy(filteredFonts, (font) => { return font.custom_properties.id; }));
        }
    },

    methods: {
        chatWithUs () {
            if (window.Intercom) {
                window.Intercom('showNewMessage', 'Hello, I would like more information about Custom Fonts.');
            }
        },

        importFont () {
            this.loading = true;

            const formData = new FormData();
            formData.append('name', this.name);

            if (this.fileTtf) {
                formData.append('ttf', this.fileTtf);
            }

            if (this.fileWoff) {
                formData.append('woff', this.fileWoff);
            }

            if (this.fileWoff2) {
                formData.append('woff2', this.fileWoff2);
            }

            axios.post(this.route('api.account.custom-fonts.store'), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(({ data }) => {
                this.$set(this, 'fonts', data);
                this.showImportFontModal = false;
                this.name = '';
                this.fileTtf = null;
                this.fileWoff = null;
                this.fileWoff2 = null;
            }).catch(() => {
                this.$toasted.global.error('An error occurred while importing the selected font.');
            }).finally(() => {
                this.loading = false;
            });
        },

        refreshFonts ({ data }) {
            this.$set(this, 'fonts', data);
        },

        setFile (type) {
            const file = this.$refs[`file${type}`].files[0];

            this.$set(this, `file${type}`, file);
        }
    }
};
</script>
