/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'receipt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>shopping pay</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.5 23.25h-.01a2.591 2.591 0 013.56-.94c.38.22.71.54.93.93M12 23.25h-.01a2.591 2.591 0 013.56-.94c.38.22.71.54.93.93"/><path pid="1" d="M21 23.25V.74c-.47.79-1.33 1.29-2.25 1.29h0c-.93-.01-1.79-.5-2.25-1.3V.72c-.47.79-1.33 1.29-2.25 1.29h0a2.65 2.65 0 01-2.26-1.3h-.01A2.64 2.64 0 019.72 2l-.01-.001c-.93-.01-1.79-.5-2.25-1.3v-.01c-.47.79-1.33 1.29-2.25 1.29h0c-.93-.01-1.79-.5-2.25-1.3v22.5h-.01a2.591 2.591 0 013.56-.94c.38.22.71.54.93.93h-.01a2.591 2.591 0 013.56-.94c.38.22.71.54.93.93M6 6.75h6M6 11.25h6M6 15.75h6M16.5 6.75H18M16.5 11.25H18M16.5 15.75H18"/></g>'
  }
})
