<template>
    <div>
        <div
            :id="blockId"
            class="text-block relative p-6 group"
            :style="{ backgroundColor: backgroundColor }"
        >
            <div
                v-if="displayTitle"
                v-theme="['wall.title-color', 'wall.title-font', 'wall.title-font-size']"
                class="flex justify-center items-center flex-auto mb-6 font-bold leading-normal"
            >
                <app-icon
                    v-if="icon"
                    :name="icon"
                    class="h-8 w-8 mr-4"
                ></app-icon>

                {{ title }}
            </div>

            <tiptap-editor
                v-model="textHtml"
                v-theme="'wall.text'"
                :read-only="!isEditMode"
                class="content px-2"
                show-embed-video-button
                hint-classes="-my-4 -mx-2"
                with-hint
            ></tiptap-editor>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import SelfEditableBlockMixin from '@/mixins/SelfEditableBlockMixin';
import WallBlock from '@/mixins/WallBlock';

export default {
    name: 'WallTextBlock',

    mixins: [SelfEditableBlockMixin, WallBlock],

    props: {
        backgroundColor: {
            type: String,
            required: true
        },
        displayTitle: {
            type: Boolean,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        variant: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            textHtml: this.text,
            syncSelfEditableSettings: {
                textHtml: 'text'
            }
        };
    },

    computed: {
        ...get('Wall/*')
    }
};
</script>
