/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-virus-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4 14.77a1.95 1.95 0 100 3.9 1.95 1.95 0 100-3.9zM7.25 23.22v0c0-1.8-1.46-3.25-3.25-3.25-1.8 0-3.25 1.45-3.25 3.25M20 14.77a1.95 1.95 0 100 3.9 1.95 1.95 0 100-3.9zM16.75 23.22h0a3.244 3.244 0 013.24-3.26 3.241 3.241 0 013.25 3.24s0 0 0 0M12 3.02a3 3 0 100 6 3 3 0 100-6zM11.5.77h1M12 .77v2.26M15.36 1.96l.71.71M15.71 2.31L14.12 3.9M17.25 5.53v1M17.25 6.03H15M16.07 9.38l-.71.71M15.71 9.74l-1.59-1.59M12.5 11.27h-1M12 11.27V9.02M8.64 10.09l-.71-.71M8.29 9.74l1.59-1.59M6.75 6.53v-1M6.75 6.03H9M7.93 2.67l.71-.71M8.29 2.31L9.88 3.9M9.25 13.775l-1 1 1 1M15.75 14.77h-7.5M14.75 13.775l1 1-1 1"/></g>'
  }
})
