/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-correct-6-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.97 14.125a2.62 2.62 0 100 5.25 2.62 2.62 0 100-5.25zM9.2 23.125h0a4.482 4.482 0 00-8.46-.01M19.02 14.125a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM23.25 23.125h0a4.482 4.482 0 00-8.46-.01M.75 6.88h3.5M2.75 8.87l-2-2 2-2M23.25 6.88h-3.5M21.25 8.87l2-2-2-2M12 .875a5 5 0 100 10 5 5 0 100-10z"/><path pid="1" d="M10 6.375l1.08 1.08-.01-.01c.19.19.51.19.7-.01.01-.02.03-.05.05-.07l2.15-3.02"/></g>'
  }
})
