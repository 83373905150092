<template>
    <div>
        <slot v-bind="{ closeFormRegistrations }"></slot>

        <app-modal v-model="showEditingMessageModal" :title="registrationRsvpLabels.closeRegistrationsNow">
            <p>Customize message to display once closed:</p>

            <div v-theme="['form.text', 'form.background']" class="p-4 mt-4 rounded-md advanced-editor min-h-sm">
                <tiptap-editor
                    ref="editor"
                    v-model="closeFormData.closedMessage"
                    hint-classes="-m-2"
                    with-hint
                ></tiptap-editor>
            </div>

            <template #footer="{ close }">
                <stateful-button
                    class="button-primary"
                    :loading="closeFormData.processing"
                    @click="closeForm"
                >
                    {{ registrationRsvpLabels.closeRegistrationsNow }}
                </stateful-button>
            </template>
        </app-modal>
    </div>
</template>
<script>
import merge from 'lodash/merge';
import { get } from 'vuex-pathify';
import { get as getValue } from 'lodash';
import Form from '@/validation/Form';
import RegistrationRSVPLabels from '@/mixins/RegistrationRSVPLabels';

export default {
    name: 'CloseFormRegistrationsAction',

    mixins: [RegistrationRSVPLabels],

    data () {
        return {
            showEditingMessageModal: false,
            closeFormData: new Form({
                closedMessage: null
            })
        };
    },

    watch: {
        'closeFormData.processing': function (newVal) {
            this.$emit('action-in-progress', newVal);
        }
    },

    computed: {
        event: get('Event/event'),
        form: get('Form/form'),
        wall: get('Wall/wall')
    },

    methods: {
        closeFormRegistrations () {
            this.showEditingMessageModal = true;
            this.closeFormData = new Form({
                closedMessage: getValue(this.form.settings, 'closedMessage', 'Sorry, we\'re no longer accepting registrations or RSVPs!')
            });
        },

        closeForm () {
            this.closeFormData.post(this.route('api.events.schedule-form-registrations-to-close', this.event)).then(({ data }) => {
                this.showEditingMessageModal = false;

                this.$nextTick(() => {
                    merge(this.event, data);
                    merge(this.form, data.form);
                    merge(this.wall, data.wall);
                });

                this.$toasted.global.success(`Your event form is closed for ${this.registrationRsvpLabels.registrationLabel}s now.`);
            }).catch(() => {
                this.$toasted.global.error(`There was a problem closing this event form for ${this.registrationRsvpLabels.registrationLabel}s.`);
            });
        }
    }
};
</script>
