<template>
    <div>
        <div v-for="option in question.options" :key="option.id">
            <input
                :checked="isOptionSelected(option)"
                type="radio"
                @input="selectOption(option)"
            >

            {{ option.title }}

            <input
                v-if="option.type === 'other' && isOptionSelected(option)"
                class="px-1 border"
                type="text"
                :value="customValue"
                @input="updateCustomValue($event.target.value)"
            >
        </div>

        <input
            :checked="answer === null"
            type="radio"
            @input="answer = null"
        > No response
    </div>
</template>

<script>
import CustomQuestionEditComponent from '@/mixins/CustomQuestionEditComponent';

export default {
    name: 'CustomQuestionAnswerRadio',

    mixins: [CustomQuestionEditComponent],

    data () {
        return {
            answer: this.parseExistingAnswer()
        };
    },

    computed: {
        customValue () {
            return this.answer?.custom;
        },

        otherOption () {
            return this.question.options.find((option) => {
                return option.type === 'other';
            });
        }
    },

    watch: {
        answer (newValue) {
            this.$emit('input', newValue);
        }
    },

    methods: {
        isOptionSelected (option) {
            return this.answer?.option === option.id;
        },

        parseExistingAnswer () {
            if (this.answers == null || this.answers.length === 0) {
                return null;
            }

            return {
                formattedText: this.answers[0].value,
                option: this.answers[0].id,
                custom: this.answers[0].raw_value
            };
        },

        selectOption (option) {
            this.answer = {
                option: option.id,
                custom: null
            };
        },

        updateCustomValue (newValue) {
            if (!this.isOptionSelected(this.otherOption)) {
                return;
            }

            this.answer = {
                option: this.otherOption.id,
                custom: newValue
            };
        }
    }
};
</script>
