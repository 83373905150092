<template>
    <div>
        <div class="flex items-center justify-center w-full h-96 bg-gray-100 text-gray-400 rounded-md overflow-hidden">
            <img
                v-show="isImageSelected"
                class="w-full h-auto"
                :src="selectedImageUrl"
            >

            <div
                v-show="isDropzoneVisible"
                ref="dropzoneElement"
                class="flex flex-col w-full h-full items-center justify-center space-y-8"
                @click="openFilePicker"
            >
                <app-icon
                    class="w-16 h-16"
                    name="image-upload"
                    stroke
                ></app-icon>

                <div class="space-y-2 text-center">
                    <div>Drag and drop image here</div>

                    <div class="px-2">
                        <slot name="recommendations"></slot>
                    </div>
                </div>

                <button ref="browseButton" class="button button-primary">Browse</button>
            </div>
        </div>

        <portal to="upload-modal-footer">
            <div class="space-x-2">
                <button
                    class="button-link disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="!isImageSelected"
                    @click="selectedImage = null"
                >Remove Image</button>

                <stateful-button
                    class="button button-primary disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="!isImageSelected"
                    :loading="uploading"
                    @click="uploadSelectedImage"
                >Add Image</stateful-button>

                <button
                    class="button button-outline button-primary disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="!isImageSelected"
                    @click="isCropperVisible = true"
                >Crop Image</button>
            </div>
        </portal>

        <image-cropper
            v-if="isCropperVisible"
            :image="{ src: selectedImageUrl }"
            @cancel="isCropperVisible = false"
            @input="saveCroppedImage"
        ></image-cropper>
    </div>
</template>

<script>
import Dropzone from 'dropzone';
import UploadsEventImages from '@/mixins/UploadsEventImages';

export default {
    name: 'UploadImageTab',

    mixins: [
        UploadsEventImages
    ],

    data () {
        return {
            dropzone: null,
            isCropperVisible: false,
            selectedImage: null,
            selectedImageUrl: null,
            uploading: false
        };
    },

    computed: {
        isDropzoneVisible () {
            return !this.isImageSelected;
        },

        isImageSelected () {
            return this.selectedImage !== null;
        }
    },

    watch: {
        selectedImage (newValue) {
            if (this.selectedImageUrl) {
                URL.revokeObjectURL(this.selectedImageUrl);
            }

            this.selectedImageUrl = newValue ? URL.createObjectURL(newValue) : null;
        }
    },

    async mounted () {
        this.dropzone = new Dropzone(this.$refs.dropzoneElement, {
            acceptedFiles: 'image/jpeg,image/png,image/webp',
            addRemoveLinks: false,
            clickable: [
                this.$refs.browseButton
            ],
            // By default, Dropzone will automatically attempt to upload a file. That shouldn't happen in this case
            // because there is a button to trigger the upload. Setting the URL to "#" will disable automatic
            // uploads and allow a custom upload procedure.
            url: '#'
        });

        // As soon as a file is selected, a copy of that file is saved in the local data of the component. As such,
        // whatever data Dropzone stored internally about that file is unnecessary and can be discarded.
        this.dropzone.uploadFiles = ([image]) => {
            this.selectedImage = image;
            this.dropzone.removeAllFiles(true);
        };
    },

    methods: {
        openFilePicker (event) {
            /**
             * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/click_event#safari_mobile
             */
            if (!event.isTrusted) {
                this.dropzone.hiddenFileInput.click();
                this.dropzone.element.focus();
            }
        },

        saveCroppedImage ({ file: blob }) {
            this.isCropperVisible = false;
            this.selectedImage = this.createImageFromBlob(this.selectedImage.name, blob);
        },

        async uploadSelectedImage () {
            this.uploading = true;

            try {
                this.$emit('upload-image', await this.uploadEventImage(this.selectedImage));
            } catch (error) {
                this.$toasted.global.error(error.message);
            } finally {
                this.uploading = false;
            }
        }
    }
};
</script>
