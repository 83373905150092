/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>at messages send</desc><path pid="0" _fill="currentColor" d="M12 .5A11.634 11.634 0 00.262 12 11.634 11.634 0 0012 23.5a11.836 11.836 0 006.624-2 1.25 1.25 0 10-1.393-2.076A9.34 9.34 0 0112 21a9.132 9.132 0 01-9.238-9A9.132 9.132 0 0112 3a9.132 9.132 0 019.238 9v.891a1.943 1.943 0 01-3.884 0V12A5.355 5.355 0 1012 17.261a5.376 5.376 0 003.861-1.634 4.438 4.438 0 007.877-2.736V12A11.634 11.634 0 0012 .5zm0 14.261A2.763 2.763 0 1114.854 12 2.812 2.812 0 0112 14.761z"/>'
  }
})
