<template>
    <div class="flex flex-col w-full max-w-2xl mx-auto">
        <button
            id="button_skip"
            class="text-purple text-xs font-bold"
            type="button"
            @click="clearAndSkip"
        >
            Skip for now
        </button>

        <form-field-wrapper
            id="field_start_date"
            class="mt-8"
            :error="validationForm?.errors.get('starts_at')"
            label="Event Start"
        >
            <date-time-picker
                v-model="startsAt"
                class="w-full"
                focused-selectable-time="12:00"
            ></date-time-picker>
        </form-field-wrapper>

        <form-field-wrapper
            id="field_end_date"
            :error="validationForm?.errors.get('ends_at')"
            label="Event End"
        >
            <date-time-picker
                ref="endsAt"
                v-model="endsAt"
                class="w-full"
            ></date-time-picker>
        </form-field-wrapper>

        <form-field-wrapper
            id="field_timezone"
            :error="validationForm?.errors.get('timezone_id')"
            label="Timezone"
        >
            <timezone-picker v-model="timezoneId" guess-timezone></timezone-picker>

            <timezone-picker
                v-model="browserTimezoneId"
                guess-timezone
                class="hidden"
            ></timezone-picker>
        </form-field-wrapper>

        <stateful-button
            id="button_continue"
            class="button-primary mt-8"
            :loading="validationForm?.processing"
            type="button"
            @click="validateAndComplete"
        >
            Next: Event Location
        </stateful-button>
    </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import { DateTime } from '@node_modules/luxon';
import Form from '@/validation/Form';

export default {
    name: 'EventDetailsTimeStep',

    data () {
        return {
            validationForm: null
        };
    },

    computed: {
        startsAt: sync('CreateEvent/starts_at'),
        endsAt: sync('CreateEvent/ends_at'),
        timezoneId: sync('CreateEvent/timezone_id'),
        browserTimezoneId: sync('CreateEvent/browser_timezone_id')
    },

    watch: {
        startsAt (newVal) {
            if (!newVal) {
                return;
            }

            const startsAt = DateTime.fromISO(newVal, { zone: 'utc' });

            if (!this.endsAt && !this.$refs.endsAt.date && !this.$refs.endsAt.time) {
                this.endsAt = startsAt.plus({ hours: 6 }).toISO();
            }
        }
    },

    methods: {
        clearAndSkip () {
            this.$store.commit('CreateEvent/resetTime');
            this.$emit('completed');
        },

        async validateAndComplete () {
            try {
                this.validationForm = new Form({
                    starts_at: this.startsAt,
                    ends_at: this.endsAt,
                    timezone_id: this.timezoneId,
                    browser_timezone_id: this.browserTimezoneId
                });

                await this.validationForm.validate(this.route('api.events.validate-store'), null, {
                    time_tbd: false
                });

                this.$emit('completed');
            } catch (error) {
                if (error.response?.status !== 422) {
                    throw error;
                }
            }
        }
    }
};
</script>
