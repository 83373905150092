/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-graph-death-rate-increasing-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.753 22.5h-21L.75 1.5"/><path pid="1" d="M15.378 6.375h-.01c.01-2.7-2.16-4.89-4.86-4.9a4.859 4.859 0 00-4.9 4.85c-.01 1.5.68 2.93 1.87 3.86v.3-.01c-.01.82.67 1.5 1.49 1.5v-.001h3l-.01-.001c.82 0 1.5-.68 1.5-1.5v-.32a4.847 4.847 0 001.87-3.82z"/><path pid="2" d="M8.62 6.375h0c.2 0 .37.16.37.37M8.253 6.75l-.01-.01c-.01-.21.16-.38.37-.38 0-.01 0-.01 0 0"/><path pid="3" d="M8.62 7.12v-.001a.38.38 0 01-.38-.38c-.01-.01-.01-.01-.001-.01M9 6.75v0c0 .2-.17.375-.375.375M12.378 6.375h-.01c.2-.01.37.16.37.37M12 6.75l-.01-.01c-.01-.21.16-.38.37-.38 0-.01 0-.01 0 0M12.378 7.12h-.01c-.21 0-.38-.17-.38-.37l-.01-.01M12.753 6.75v0c0 .2-.17.375-.375.375M10.5 10.5V12M.758 19.512h2.7c8.894 0 16.064-3.39 17.954-12.762"/><path pid="4" d="M18.574 8.582l2.84-1.84 1.83 2.84"/></g>'
  }
})
