/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vegetables-corn-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.769 16.42h-.01c-1.43 2-2.26 4.36-2.43 6.82h.2l-.01-.001c4.11 0 7.54-3.18 7.85-7.28v0h-.01c.12-1.62.73-3.15 1.73-4.41l.41-.52v-.01c.14-.18.11-.44-.07-.59a.42.42 0 00-.43-.06h-.01a16.287 16.287 0 00-7.31 6.02z"/><path pid="1" d="M11.34 23.25v0l-.01-.001c-3.5-.01-6.41-2.7-6.67-6.18l-.09-1.1h0a8.135 8.135 0 00-1.74-4.41l-.42-.52h0a.416.416 0 01.06-.59.4.4 0 01.42-.06l-.01-.01c4.29 1.73 7.59 5.29 9.01 9.69"/><path pid="2" d="M7.274 13.081l.74-8.595h-.01a4.083 4.083 0 014.07-3.74v0l-.01-.001c2.12 0 3.89 1.62 4.07 3.73l.73 8.44M7.95 5.25h3.4M12.85 8.25h3.65M7.43 11.25h3.17"/></g>'
  }
})
