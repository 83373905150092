/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-store-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.75 12.75v9c0 .82-.68 1.5-1.5 1.5H3.74a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-9M21.148.75H2.85L2.84.749a.741.741 0 00-.74.58L.73 7.489h0c0 1.24 1 2.24 2.25 2.24a2.253 2.253 0 002.24-2.26h0c0 1.24 1 2.24 2.25 2.24a2.253 2.253 0 002.24-2.26h0c0 1.24 1 2.24 2.25 2.24a2.253 2.253 0 002.24-2.26h0c0 1.24 1 2.24 2.25 2.24a2.253 2.253 0 002.24-2.26h0c0 1.24 1 2.24 2.25 2.24a2.253 2.253 0 002.24-2.26l-1.37-6.17-.01-.01a.758.758 0 00-.74-.59zM2.25 17.25H15M15 23.25v-10.5"/></g>'
  }
})
