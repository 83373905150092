/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-burger-meal-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>restaurant fast hamburger cheeseburger drink</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 23.251H3L.75 6.75H13.5l-.37 2.68"/><path pid="1" d="M6 17.251L8.25.75l4.5.75M11.25 20.251h10.5v1.5c0 .82-.68 1.5-1.5 1.5h-7.51a1.51 1.51 0 01-1.5-1.5s0 0 0 0zM14.25 12.751h4.5-.01c1.65-.01 3 1.34 3 3v1.5h-10.5v-1.51c0-1.66 1.34-3 3-3z"/><rect pid="2" width="13.5" height="3" x="9.75" y="17.251" rx="1.5"/></g>'
  }
})
