/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vip-diamond-hand-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>gem sparkle premium luxury</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.279 9.327h0c1.33.81 2.18 2.23 2.25 3.8h0c.02.72-.23 1.42-.71 1.96l-1 1.13v-.01c-.69.78-1.88.86-2.66.17a.902.902 0 01-.1-.09l-1.94-1.94h0a1.41 1.41 0 00-2-.01 1.41 1.41 0 00-.01 2s0 0 0 0l3.51 3.98h0c.7.7 1.1 1.66 1.1 2.66v.2M23.25 23.24v-9.18h0c-.04-.87-.18-1.73-.42-2.56h0a7.705 7.705 0 00-4.46-4.96M.88 5.49h14.74M3.47.75L3.46.749a.937.937 0 00-.84.5l-1.8 3.45H.81c-.16.29-.11.65.12.9l6.648 6.98h0c.34.34.9.35 1.25.01 0-.01 0-.01.01-.02l6.648-6.988v-.01c.23-.25.28-.61.12-.9l-1.95-3.48-.01-.01a.937.937 0 00-.82-.48zM.75 14.99h3M2.25 13.49v3M20.25 2.24h3M21.75.74v3M4.46.75l3.75 12.11M12.04.75L8.29 12.86"/></g>'
  }
})
