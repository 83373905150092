<template>
    <div class="call-to-action call-to-action-warning">
        <div class="flex-1">
            <p>{{ notification.data.bouncedEmailsCount | number }} email(s) "bounced" and were not received by your intended recipient(s).</p>

            <p class="text-gray-400">
                {{ campaign.title }} |
                Last sent on {{ campaign.sent_at | dateTimeTz(eventTimezone.name) }}
            </p>
        </div>

        <div class="flex">
            <button
                v-if="dismissable"
                class="button button-soft mr-2"
                @click="dismiss"
            >Dismiss</button>

            <button class="button call-to-action-button" @click="$emit('fix-bounced', campaign)">
                <app-icon
                    class="w-4 h-4 mr-2"
                    name="email-close"
                    stroke
                ></app-icon>
                View &amp; Fix
            </button>
        </div>
    </div>
</template>

<script>
import axios from '@/util/axios';
import EventTimezone from '@/mixins/EventTimezone';
import { get } from 'vuex-pathify';

export default {
    mixins: [EventTimezone],

    props: {
        campaign: {
            type: Object,
            required: true
        },
        dismissable: {
            type: Boolean,
            default: false
        },
        notification: {
            type: Object,
            required: true
        }
    },

    methods: {
        dismiss () {
            axios.post(this.route('api.notifications.dismiss', this.notification))
                .then(() => { return window.location.reload(); });
        }
    },

    computed: {
        ...get('Event/*')
    }
};
</script>
