<template>
    <div :class="{'flex flex-row': !vertical}">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'SelectList',

    provide () {
        return {
            selectListState: this.sharedState
        };
    },

    props: {
        color: {
            type: String,
            default: 'purple'
        },

        multiSelect: {
            type: Boolean,
            default: false
        },

        fullOptionSelect: {
            type: Boolean,
            default: false
        },

        value: {
            type: [Array, String, Number, Boolean],
            default: null
        },

        vertical: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            sharedState: {
                color: this.color,
                multiSelect: this.multiSelect,
                fullOptionSelect: this.fullOptionSelect,
                selected: this.value,
                vertical: this.vertical
            }
        };
    },

    watch: {
        value (newValue) {
            this.sharedState.selected = newValue;
        },

        'sharedState.selected': function (newSelection) {
            this.$emit('input', newSelection);
        }
    }
};
</script>
