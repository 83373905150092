export default function (number, decimals = 0, decimal_point = '.', thousands_separator = ',') {
    const [int, fraction] = parseFloat(number).toFixed(decimals).split('.');

    const formattedInt = int.replace(/\B(?=(\d{3})+(?!\d))/g, thousands_separator);

    if (decimals === 0) {
        return formattedInt;
    }

    return [formattedInt, fraction].join(decimal_point);
}
