/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'technology-laptop-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21 14.25V4.5v0c0-.83-.68-1.5-1.5-1.5H4.49c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v9.75z"/><path pid="1" d="M23.12 18.89l-.01-.01c.33.75-.01 1.64-.77 1.97-.2.08-.4.12-.61.12H2.22c-.83 0-1.51-.68-1.51-1.5-.01-.21.04-.42.12-.61l2.12-4.65h18zM10.5 18h3"/></g>'
  }
})
