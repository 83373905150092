<template>
    <canvas ref="lineChart"></canvas>
</template>

<script>
import {
    Chart, CategoryScale, LineController, LineElement, PointElement, LinearScale, Tooltip
} from 'chart.js';
import merge from 'lodash/merge';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@root/tailwind.config';
import ListensToWindowResizeEvent from '@/mixins/ListensToWindowResizeEvent';

Chart.register(CategoryScale, LineController, LineElement, PointElement, LinearScale, Tooltip);
const tailwind = resolveConfig(tailwindConfig);

const defaultOptions = {
    responsive: true,
    legend: {
        display: false
    },
    elements: {
        line: {
            borderWidth: 2
        }
    },
    maintainAspectRatio: true,
    scales: {
        y: {
            grid: {
                display: false
            },
            ticks: {
                display: false
            }
        },
        x: {
            grid: {
                display: false
            },
            ticks: {
                display: false
            }
        }
    }
};

export default {
    name: 'LineChart',

    mixins: [ListensToWindowResizeEvent],

    props: {
        data: {
            type: Object,
            required: true
        },

        baseColor: {
            type: String,
            default: 'purple'
        },

        gradient: {
            type: Boolean,
            default: false
        },

        options: {
            type: Object,
            default: () => {
                return {};
            }
        },

        tooltipLabelGenerator: {
            type: Function,
            default: null
        },

        tooltipLabelPrefix: {
            type: String,
            default: ''
        },

        tooltipLabelSuffix: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            colors: {
                elements: {
                    line: {
                        borderColor: tailwind.theme.colors.purple.DEFAULT,
                        backgroundColor: tailwind.theme.colors.purple['100']
                    }
                }
            },
            defaultTooltipLabelGenerator: (tooltipItem, data) => {
                return `${this.tooltipLabelPrefix}${data.datasets[0].data[tooltipItem.dataIndex]}${this.tooltipLabelSuffix}`;
            },
            lineChart: null
        };
    },

    computed: {
        aspectRatio () {
            return this.screenWidthIs('lt', 'md') ? 3 : 10;
        },

        baseColorExistsInConfiguration () {
            return tailwind.theme.colors[this.baseColor].DEFAULT;
        },

        chartOptions () {
            const pluginOptions = {
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: this.tooltipLabelGenerator ? this.tooltipLabelGenerator : this.defaultTooltipLabelGenerator
                        }
                    }
                }
            };

            return merge({}, defaultOptions, this.colors, pluginOptions, { aspectRatio: this.aspectRatio }, this.options);
        }
    },

    watch: {
        screenWidth () {
            this.lineChart.options.aspectRatio = this.aspectRatio;
            this.lineChart.update();
        }
    },

    mounted () {
        this.createChart();
    },

    methods: {
        createChart () {
            this.setChartColors();

            this.lineChart = new Chart(this.$refs.lineChart, {
                type: 'line',
                data: this.data,
                options: this.chartOptions
            });
        },

        setChartColors () {
            if (this.baseColorExistsInConfiguration) {
                this.setBackground();
                this.setBorderColor();
            }
        },

        setBackground () {
            if (this.gradient) {
                const gradient = this.$refs.lineChart.getContext('2d').createLinearGradient(0, 0, 0, 450);

                gradient.addColorStop(1, tailwind.theme.colors[this.baseColor].light);
                gradient.addColorStop(0, tailwind.theme.colors.white);

                this.colors.elements.line.backgroundColor = gradient;
            } else {
                this.colors.elements.line.backgroundColor = tailwind.theme.colors[this.baseColor].light;
            }
        },

        setBorderColor () {
            this.colors.elements.line.borderColor = tailwind.theme.colors[this.baseColor].DEFAULT;
        }
    }
};
</script>
