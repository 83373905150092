/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-wine-bottle-glass-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>bar alcohol grape red white rose</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.811 9.311L11.25 6.75V1.5v0c0-.42-.34-.75-.75-.75H8.99c-.42 0-.75.33-.75.75 0 0 0 0 0 0v5.25L5.679 9.311h-.01c-.29.28-.44.66-.44 1.06v11.37h0c0 .82.67 1.49 1.5 1.49h3.74M8.25 4.5h3"/><path pid="1" d="M18.75 15.682v0c0 1.65-1.35 3-3 3-1.66 0-3-1.35-3-3v-3.69h6zM15.75 18.75v4.5M18 23.25h-4.5"/></g>'
  }
})
