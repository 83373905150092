<template>
    <div>
        <test-mode-limited-capacity-alert></test-mode-limited-capacity-alert>
        <div class="w-full md:w-1/2 p-2 bg-white rounded-md border">
            <simple-picker
                v-model="selectedSecondaryEvent"
                :items="event.secondaryEvents"
                item-label="settings.title"
                placeholder-empty-state="Select secondary event..."
            ></simple-picker>
        </div>

        <slidable-cards v-if="products.length !== 0">
            <slidable-card v-for="(product, index) in products" :key="index">
                <div slot="footer">
                    <div class="uppercase">{{ product.title }}</div>
                    <div v-if="product.has_limited_availability" class="text-gray-400">
                        <strong class="tracking-wider">{{ product.quantity_remaining }}</strong> still available
                    </div>
                </div>
                <template slot="icon">
                    <div
                        :class="productStatusColor(product)"
                        class="flex items-center justify-center p-3 rounded-full"
                    >
                        <app-icon
                            v-if="product.short_guest_state === 'Attending'"
                            name="check-square"
                            class="w-6 h-6 text-green"
                            stroke
                        ></app-icon>
                        <app-icon
                            v-if="product.short_guest_state === 'Maybe'"
                            name="remove-square"
                            class="w-6 h-6 text-yellow"
                            stroke
                        ></app-icon>
                        <app-icon
                            v-if="product.short_guest_state === 'NotAttending'"
                            name="close-square"
                            class="w-6 h-6 text-red"
                            stroke
                        ></app-icon>
                    </div>
                </template>
                {{ product.guests_count }}
            </slidable-card>
        </slidable-cards>

        <dashboard-data
            v-if="selectedSecondaryEvent"
            ref="dataColumns"
            :always-loaded-columns="['formalName', secondaryEventReplyColumnId]"
            :custom-column-definitions="customColumnDefinitions"
            :default-columns="['firstName', 'lastName', 'email', secondaryEventReplyColumnId]"
            :event="event"
        >
            <template #summary="{ row }">
                <p>{{ row.formalName }}</p>

                <template v-if="row[secondaryEventReplyColumnId]">
                    <div class="flex items-center">
                        <guest-reply-icon
                            class="mr-2 min-h-5 min-w-5"
                            :state="row[secondaryEventReplyColumnId].state"
                            colored
                        ></guest-reply-icon>
                        {{ row[secondaryEventReplyColumnId].product }}
                    </div>
                </template>
            </template>

            <template #row="{ property, row }">
                <template v-if="property === secondaryEventReplyColumnId && row[secondaryEventReplyColumnId]">
                    <div class="flex items-center">
                        <guest-reply-icon
                            class="mr-2 min-h-5 min-w-5"
                            :state="row[secondaryEventReplyColumnId].state"
                            colored
                        ></guest-reply-icon>
                        {{ row[secondaryEventReplyColumnId].product }}
                    </div>
                </template>
            </template>
        </dashboard-data>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import axios from '@/util/axios';

export default {
    name: 'DashboardSecondaryEvents',

    data () {
        return {
            products: [],
            selectedSecondaryEvent: null
        };
    },

    computed: {
        customColumnDefinitions () {
            return [
                {
                    property: this.secondaryEventReplyColumnId,
                    label: 'Reply',
                    sortable: false,
                    alwaysActive: true
                }
            ];
        },

        event: get('Event/event'),

        secondaryEventReplyColumnId () {
            return `secondaryEvent-${this.selectedSecondaryEvent.id}`;
        }
    },

    watch: {
        selectedSecondaryEvent: {
            handler () {
                this.refreshProducts();
                this.$refs?.dataColumns?.resetFilters();
            },
            immediate: true
        }
    },

    mounted () {
        this.selectedSecondaryEvent = this.event.secondaryEvents[0];
    },

    methods: {
        productStatusColor (product) {
            switch (product.short_guest_state) {
                case 'Attending':
                    return 'bg-green-light';

                case 'Maybe':
                    return 'bg-yellow-light';

                case 'NotAttending':
                    return 'bg-red-light';

                default:
                    return '';
            }
        },

        async refreshProducts () {
            if (!this.selectedSecondaryEvent) {
                return;
            }

            const { data } = await axios.get(this.route('api.dashboard.secondary-events.products.list', {
                event: this.event,
                secondaryEventId: this.selectedSecondaryEvent.id
            }));

            this.products = data;
        }
    }
};
</script>
