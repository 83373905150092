/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="currentColor" d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm4.748 14.664l-4 4.5a1 1 0 01-1.5 0l-4-4.5A1 1 0 018 13h2.25a.25.25 0 00.25-.25V5.5a1.5 1.5 0 013 0v7.25a.25.25 0 00.25.25H16a1 1 0 01.748 1.664z"/>'
  }
})
