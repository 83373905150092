/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alert-laptop-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>exclamation warning notification message computer</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.75 15.75v3M2.25 18.75V8.25h0c-.01-.83.67-1.51 1.49-1.51h3.75"/><path pid="1" d="M14.584 18.75h-.01a2.987 2.987 0 01-5.18-.01H.72v1.5h0c0 1.65 1.34 2.99 3 2.99h16.5l-.01-.001c1.65 0 3-1.35 3-3v-1.5zM18.658 1.01h0c-3.37-1.01-6.92.9-7.92 4.27-.45 1.49-.33 3.1.33 4.52l-2.07 4.18 4.72-1.29-.01-.01c3.07 1.72 6.96.62 8.69-2.46a6.4 6.4 0 00-2.46-8.7c-.42-.24-.87-.43-1.32-.56z"/><path pid="2" d="M16.875 9.032h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38l-.01-.01c-.01-.21.16-.38.37-.38 0-.01 0-.01 0 0"/></g><path pid="3" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-width="1.5" d="M16.88 6.78v-3"/>'
  }
})
