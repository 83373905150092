/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-donut': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sweet pastry bakery</desc><path pid="0" d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm8 9a1 1 0 11-1 1 1 1 0 011-1zm-4.5-6A1.5 1.5 0 1114 4.5 1.5 1.5 0 0115.5 3zM12 8.5A3.5 3.5 0 118.5 12 3.5 3.5 0 0112 8.5zM8.5 4a1 1 0 11-1 1 1 1 0 011-1zM4.75 7.5A1.25 1.25 0 113.5 8.75 1.25 1.25 0 014.75 7.5zM12 22a10 10 0 01-9.408-6.6.5.5 0 01.605-.651 5.4 5.4 0 003.439-.18.5.5 0 01.615.212 5.5 5.5 0 009.5 0 .5.5 0 01.615-.212 5.4 5.4 0 003.439.18.5.5 0 01.605.651A10 10 0 0112 22z" _fill="currentColor"/>'
  }
})
