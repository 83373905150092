/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notes-stack-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.629 20.25H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V2.25h0C.73 1.42 1.41.74 2.23.74h16.5-.01c.82-.01 1.5.67 1.5 1.5v8.37-.01c0 .39-.16.77-.44 1.06l-8.122 8.12h-.01c-.29.28-.67.43-1.07.43z"/><path pid="1" d="M11.25 20.11v-7.37h0c-.01-.83.67-1.51 1.49-1.51h7.365"/><path pid="2" d="M23.25 10.5v11.25c0 .82-.68 1.5-1.5 1.5h-15"/></g>'
  }
})
