/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email-check-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>done ok yes envelope</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 11.25a6 6 0 100 12 6 6 0 100-12z"/><path pid="1" d="M19.924 15.506l-2.91 3.87v-.01a.76.76 0 01-1.06.14c-.03-.03-.06-.05-.08-.07l-1.5-1.5M8.25 15.75h-6l-.01-.001a1.51 1.51 0 01-1.5-1.5V2.238c0-.83.67-1.5 1.5-1.5h18-.01c.82-.01 1.5.67 1.5 1.5 0 0 0 0 0 0v6.75"/><path pid="2" d="M21.411 1.3l-8.15 6.26v-.01a3.33 3.33 0 01-4.04 0L1.07 1.28"/></g>'
  }
})
