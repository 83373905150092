<template>
    <div v-theme="['form.text']">
        <confirmation-widget
            v-model="textHtml"
            :editing="isEditMode"
            :guests="guests"
        ></confirmation-widget>

        <div v-theme="'form.background'" class="mt-16 px-4 py-8 w-full rounded-md relative transition-all">
            <div
                v-if="isEditMode"
                v-theme="'form.accent'"
                class="p-6 flex flex-col items-center"
            >
                <app-icon
                    name="list-check-stroke"
                    class="h-10 w-10"
                    stroke
                ></app-icon>

                <div v-theme="'form.title-text'" class="text-xl text-center mt-4">
                    {{ $t('text-submission-summary') }}
                </div>
            </div>

            <template v-else>
                <div v-if="showConfirmationCode" class="text-center">
                    <div class="text-sm uppercase tracking-wide">{{ $t('text-confirmation-code') }}</div>
                    <div class="text-xl font-bold">{{ submission.confirmation_code }}</div>
                </div>

                <div v-for="guest in guests" :key="guest.id">
                    <div v-theme="'form.accent'" class="p-6 flex items-center border-b">
                        <div class="flex flex-1 font-semibold">{{ guest.formalName }}</div>

                        <div class="mr-4">
                            {{ findPrimaryProduct(guest).title }}
                            <div v-if="findPrimaryProduct(guest).settings.costsMoney" class="text-right">
                                <money-field static :value="findPrimaryProduct(guest).price"></money-field>
                            </div>
                        </div>

                        <guest-reply-icon class="w-5 h-5" :state="guest.state"></guest-reply-icon>
                    </div>
                </div>
            </template>
        </div>

        <div
            v-if="order.total > 0"
            v-theme="'form.background'"
            class="my-8 px-4 py-8 w-full rounded-md relative transition-all"
        >
            <div v-for="orderItem in order.items" :key="orderItem.id">
                <div v-theme="'form.accent'" class="p-6 flex justify-between border-b">
                    <div>
                        <div class="flex font-semibold">
                            <app-icon
                                v-if="orderItem.orderable.settings.icon"
                                :name="orderItem.orderable.settings.icon"
                                class="h-5 w-5 mr-2"
                            ></app-icon>
                            {{ orderItem.orderable.title }}
                        </div>

                        <div class="flex items-end">
                            <div class="h-5 w-5 mr-2 mt-1"></div>

                            <span v-if="orderItem.isDonation">
                                {{ orderItem.orderable.settings.description }}
                            </span>

                            <span v-else>
                                {{ orderItem.quantity | number }} x <money-field :value="orderItem.price" static></money-field> {{ block.pivot.settings.currency }}
                            </span>
                        </div>
                    </div>

                    <div class="flex items-center font-semibold">
                        <money-field :value="orderItem.totalPrice" static></money-field>
                    </div>
                </div>
            </div>

            <div
                v-if="order.coupon"
                v-theme="'form.accent'"
                class="p-6 flex justify-between border-b-2"
            >
                <div class="font-semibold">
                    <span class="uppercase mr-2">"{{ order.coupon.code }}"</span>
                </div>

                <div class="font-semibold">(-<money-field :value="order.discount" static></money-field>)</div>
            </div>

            <div
                v-if="order.tax"
                v-theme="'form.accent'"
                class="p-6 flex justify-between border-b-2"
            >
                <div class="font-semibold">{{ event.sales_tax.label }}</div>
                <div class="font-semibold">
                    <money-field :value="order.tax" static></money-field>
                </div>
            </div>

            <div v-theme="'form.accent'" class="px-6 pt-6 flex justify-between">
                <div class="font-semibold uppercase tracking-wide">{{ $t('text-order-total') }}</div>
                <div class="font-semibold">
                    <money-field :value="order.total" static></money-field>
                    {{ block.pivot.settings.currency }}
                </div>
            </div>

            <div v-if="paymentSuccessful" class="text-right p-6 opacity-50">
                <div class="mb-2">{{ order.payment.purchaserName }}</div>

                <div class="font-mono flex items-center justify-end">
                    <app-icon
                        v-if="order.payment.creditCardBrand"
                        :name="getCreditCardBrandIcon(order.payment.creditCardBrand)"
                        class="w-8 h-8 mr-2 text-gray-800"
                    ></app-icon>

                    <div v-if="order.payment.creditCardLast4">
                        **** **** **** {{ order.payment.creditCardLast4 }}
                    </div>
                </div>
            </div>
        </div>

        <div class="flex w-full mt-6">
            <edit-reply-button
                v-if="canEditSubmission"
                :event="event"
                :submission="submission"
            ></edit-reply-button>
        </div>
    </div>
</template>

<script>
import find from 'lodash/find';
import getValue from 'lodash/get';
import { get } from 'vuex-pathify';
import FormBlock from '@/mixins/FormBlock';
import SelfEditableBlockMixin from '@/mixins/SelfEditableBlockMixin';

export default {
    name: 'RsvpSummaryBlock',

    mixins: [FormBlock, SelfEditableBlockMixin],

    data () {
        return {
            blockId: this.block.pivot.id,
            textHtml: this.block.pivot.settings.html,
            syncSelfEditableSettings: {
                textHtml: 'html'
            }
        };
    },

    computed: {
        ...get('Event/*'),

        event: get('Event/event'),
        guests: get('Submission/submission@guests'),
        order: get('Submission/submission@order'),
        paymentSectionCompleted: get('Submission/paymentSectionCompleted'),
        submission: get('Submission/submission'),

        canEditSubmission () {
            return (this.submission.editable || this.submission.hasEditPreview) && getValue(this.event, 'settings.security.allowGuestSubmissionEditing');
        },

        isOrderEmpty () {
            return !this.order || this.order.items.length === 0;
        },

        paymentSuccessful () {
            return this.order.payment && this.order.payment.isSuccessful;
        },

        showConfirmationCode () {
            return getValue(this.event, 'settings.confirmationEmails.includeConfirmationCode');
        }
    },

    methods: {
        findPrimaryProduct (guest) {
            return find(guest.products, { type: 'primary' });
        }
    }
};
</script>
