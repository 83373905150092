<template>
    <simple-picker
        :value="value"
        :items="planSegments"
        item-label="label"
        item-value="id"
        placeholder-empty-state="Select plan segment..."
        @input="$emit('input', $event)"
    >
        <div
            slot="input"
            slot-scope="{ selected }"
            class="w-full flex items-center text-gray-700"
        >
            <span>{{ selected.heading }}</span>
        </div>

        <div
            slot="item"
            slot-scope="{ item }"
            class="w-full flex items-center"
        >
            <span>{{ item.heading }}</span>
        </div>
    </simple-picker>
</template>

<script>
export default {
    name: 'PlanSegmentPicker',

    props: {
        value: {
            type: Number,
            default: null
        },

        planSegments: {
            type: Array,
            required: true
        }
    }
};
</script>
