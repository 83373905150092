/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-day-7': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M22.687 9.52A1.5 1.5 0 0024 8.031V4a2 2 0 00-2-2H2a2 2 0 00-2 2v4.031A1.5 1.5 0 001.313 9.52 2.307 2.307 0 013.25 12a2.307 2.307 0 01-1.937 2.48A1.5 1.5 0 000 15.969V20a2 2 0 002 2h20a2 2 0 002-2v-4.031a1.5 1.5 0 00-1.313-1.489A2.307 2.307 0 0120.75 12a2.307 2.307 0 011.937-2.48zm-1.033 6.771a.5.5 0 01.346.475V19.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-2.734a.5.5 0 01.346-.475A4.344 4.344 0 005.25 12a4.344 4.344 0 00-2.9-4.291.5.5 0 01-.35-.475V4.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v2.734a.5.5 0 01-.346.475A4.344 4.344 0 0018.75 12a4.344 4.344 0 002.904 4.291z"/><path pid="1" d="M12 11.75a1.752 1.752 0 00-1.75 1.75v4a.75.75 0 001.5 0v-1.25h.5v1.25a.75.75 0 001.5 0v-4A1.752 1.752 0 0012 11.75zm-.25 3V13.5c0-.275.5-.275.5 0v1.25zM17.5 11.75a.75.75 0 00-.75.75V14c0 .275-.5.275-.5 0v-1.5a.75.75 0 00-1.5 0V14a1.748 1.748 0 001 1.574V17.5a.75.75 0 001.5 0v-1.926a1.748 1.748 0 001-1.574v-1.5a.75.75 0 00-.75-.75zM6.5 11.75a.75.75 0 00-.75.75v5a.75.75 0 00.75.75 2.752 2.752 0 002.75-2.75v-1a2.752 2.752 0 00-2.75-2.75zm1.25 3.75a1.246 1.246 0 01-.5 1v-3a1.246 1.246 0 01.5 1zM13 5.25h-1.278a.75.75 0 000 1.5h.917L11.52 9.736a.75.75 0 001.4.528l1.25-3.325A1.25 1.25 0 0013 5.25z"/></g>'
  }
})
