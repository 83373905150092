/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fruit-pear-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 5.215h0a3.197 3.197 0 00-3.38 2.64l-.02.09v-.01a9.322 9.322 0 01-2.65 5.08v-.01a7.478 7.478 0 00-2.23 4.97c0 3.94 4.27 5.32 8.25 5.23 3.974.1 8.25-1.29 8.25-5.24h0a7.55 7.55 0 00-2.23-4.98h0a9.624 9.624 0 01-2.53-4.43M18.074 4.582h-.01c-.73.67-1.64 1.12-2.61 1.3h-.01a.948.948 0 01-1.19-.59.912.912 0 010-.61h-.01c.17-.98.63-1.88 1.3-2.61v-.01c.72-.68 1.63-1.14 2.61-1.32v-.01a.927.927 0 011.18.58c.06.19.06.4-.01.6v-.01a5.24 5.24 0 01-1.31 2.6z"/><path pid="1" d="M8.25.75v0h-.01a3.736 3.736 0 013.75 3.74v3"/></g>'
  }
})
