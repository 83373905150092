<template>
    <div>
        <input
            ref="phoneNumber"
            v-theme="['form.accent', 'form.text']"
            class="form-field bg-transparent"
            type="tel"
            :value="value"
            @input="$emit('input', $event.target.value)"
        >
    </div>
</template>

<script>
import intlTelInput from 'intl-tel-input';
import axios from '@/util/axios';

export default {
    name: 'PhoneNumberInput',

    props: {
        value: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            phoneNumberInput: null
        };
    },

    mounted () {
        this.initializePhoneNumberInput();
    },

    methods: {
        focus () {
            this.$refs.phoneNumber.focus();
        },

        initializePhoneNumberInput () {
            this.phoneNumberInput = intlTelInput(this.$refs.phoneNumber, {
                initialCountry: 'auto',
                utilsScript: require('intl-tel-input/build/js/utils'),
                nationalMode: false,
                formatOnDisplay: true,
                geoIpLookup: (callback) => {
                    axios.get(this.route('api.geoip.show'))
                        .then((response) => {
                            callback(response.data.iso_code);
                        })
                        .catch(() => {
                            callback('US');
                        });
                }
            });

            // Listens for changes and formats the input.
            this.$refs.phoneNumber.addEventListener('change', this.formatIntlTelInput);
            this.$refs.phoneNumber.addEventListener('keyup', this.formatIntlTelInput);

            // If telephone number is entered, and then country gets changed
            // new formatted number should be emitted, so field stays reactive.
            this.$refs.phoneNumber.addEventListener('countrychange', this.emitChange);
            this.$refs.phoneNumber.addEventListener('change', this.emitChange);
            this.$refs.phoneNumber.addEventListener('keyup', this.emitChange);
        },

        emitChange () {
            this.$emit('input', this.phoneNumberInput.getNumber());
        },

        formatIntlTelInput () {
            const currentNumber = this.phoneNumberInput.getNumber();

            // currentNumber can be an object sometime, so it needs to be checked.
            if (typeof currentNumber === 'string') {
                // Auto-formats because of formatOnDisplay is set to true.
                this.phoneNumberInput.setNumber(currentNumber);
            }
        }
    }
};
</script>
