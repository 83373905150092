<template>
    <div class="flex flex-col grow">
        <div class="flex flex-wrap">
            <form-field-wrapper
                label="Button Text"
                :error="form.errors.get('text')"
                :should-show-error="form.errors.has('text')"
            >
                <input
                    v-model="form.text"
                    class="form-field"
                >
            </form-field-wrapper>
        </div>
    </div>
</template>

<script>
import BlockableSettingsMixin from '@/mixins/BlockableSettingsMixin';

export default {
    name: 'WallRsvpButtonBlockSettings',

    mixins: [BlockableSettingsMixin]
};
</script>
