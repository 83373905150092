<template>
    <div>
        <div class="flex flex-wrap items-center">
            <template v-for="(predicate, index) in value">
                <div
                    v-if="index > 0"
                    :key="`operator-${index}`"
                    class="mr-2"
                >
                    and
                </div>

                <div :key="`item-${index}`" class="mr-2">
                    <predicate-list-item
                        :choosable-fields="filterableFields"
                        :initial-visibility="predicate.id === latestPredicateId"
                        :value="predicate"
                        @input="updatedPredicate => updatePredicate(predicate.id, updatedPredicate)"
                        @remove="removePredicate(predicate.id)"
                    ></predicate-list-item>
                </div>
            </template>

            <base-dropdown>
                <template #trigger="{ triggerFunction }">
                    <button class="button-text button-info" @click="triggerFunction">
                        <app-icon
                            name="add"
                            class="w-3 h-3 mr-2"
                        ></app-icon>
                        <span>{{ addFilterText }}</span>
                    </button>
                </template>

                <template #default="{ triggerFunction }">
                    <div class="w-64 p-2 bg-white rounded-md shadow">
                        <search-field
                            v-model="searchQuery"
                            placeholder="Search filters..."
                            small
                        ></search-field>

                        <div
                            v-for="{ icon, name, slug } in filteredFields"
                            :key="slug"
                            class="flex items-center px-2 py-1 hover:bg-gray-100 cursor-pointer text-left"
                            @click="addPredicate(slug, triggerFunction)"
                        >
                            <app-icon :name="icon" class="w-4 h-4 mr-2 flex-none"></app-icon>
                            <span>{{ name }}</span>
                        </div>
                    </div>
                </template>
            </base-dropdown>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';

export default {
    props: {
        filterableFields: {
            type: Array,
            required: true
        },
        value: {
            type: Array,
            required: true
        },
        addFilterText: {
            type: String,
            default: 'Add Filter'
        }
    },

    data () {
        return {
            latestPredicateId: null,
            searchQuery: ''
        };
    },

    computed: {
        filteredFields () {
            const regex = new RegExp(this.searchQuery, 'i');

            return this.filterableFields.filter(({ name }) => {
                return name.match(regex);
            });
        }
    },

    methods: {
        addPredicate (field, dropdownToggleFunction) {
            dropdownToggleFunction();

            this.latestPredicateId = uniqueId();

            const { slug, defaultValue } = get(this.findFieldBySlug(field), 'comparisons[0]', {});

            this.$emit('input', [
                ...this.value,
                {
                    id: this.latestPredicateId,
                    field,
                    comparison: slug,
                    value: defaultValue
                }
            ]);
        },

        findFieldBySlug (slug) {
            return this.filterableFields.find((field) => {
                return field.slug === slug;
            });
        },

        removePredicate (id) {
            this.$emit('input', this.value.filter((predicate) => { return predicate.id !== id; }));
        },

        updatePredicate (id, updatedPredicate) {
            const index = this.value.findIndex((predicate) => {
                return predicate.id === id;
            });

            this.$emit('input', Object.assign([], this.value, {
                [index]: updatedPredicate
            }));
        }
    }
};
</script>
