/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-vaccine-syringe-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus needle</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.123 9.03l-4.82-4.82-8.186 8.18h-.01a1.34 1.34 0 000 1.92l2.56 2.57-.01-.01c.58.58 1.53.58 2.12 0v-.01 0"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M2.65 15.61h3.4v2.72H2.64z" transform="matrix(.7071 -.70711 .7071 .7071 -10.726 8.056)"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="2" d="M.75 20.59l2.41-2.41M10.86 2.77l1.45 1.44M13.75 5.66l3.37-3.37M19.05 4.21l-3.37 3.38M15.68.84l4.81 4.82M23.25 15.657l-.01-.01a7.69 7.69 0 01-6 7.5l-.01-.01a7.67 7.67 0 01-6-7.5v-2.25h0c-.01-.83.67-1.51 1.49-1.51h9-.01c.82-.01 1.5.67 1.5 1.5zM17.25 14.91v4.5M15 17.16h4.5"/></g>'
  }
})
