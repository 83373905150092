<template>
    <simple-picker
        :value="value"
        :items="fonts"
        item-label="label"
        item-value="value"
        placeholder-empty-state="Select Font..."
        @input="$emit('input', $event)"
    >
        <div
            slot="input"
            slot-scope="{ selected }"
            :style="{ 'font-family': selected.value }"
        >
            {{ selected.label }}
        </div>
        <div
            slot="item"
            slot-scope="{ item }"
            :style="{ 'font-family': item.value }"
        >
            {{ item.label }}
        </div>
    </simple-picker>
</template>

<script>
export default {
    name: 'FontPicker',

    props: {
        value: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            fonts: window.fonts
        };
    }
};
</script>
