/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fruit-cherry': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><path pid="0" _fill="currentColor" d="M17.894 11.15a1 1 0 01-.761-.754 19.654 19.654 0 01-.333-8.564A1.5 1.5 0 0014.448.291a22 22 0 00-7.488 10.2 1 1 0 01-.741.634 6.5 6.5 0 103.158.15.5.5 0 01-.319-.666 20.306 20.306 0 015.061-7.459.25.25 0 01.418.207 23.6 23.6 0 00.6 7.282.5.5 0 01-.355.591 6.54 6.54 0 00-.979.355.5.5 0 00-.175.776 7.983 7.983 0 010 10.273.5.5 0 00.175.777 6.5 6.5 0 104.091-12.261z"/>'
  }
})
