/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'credit-card-visa': {
    width: 16,
    height: 16,
    viewBox: '0 0 750 471',
    data: '<desc>debit charge plastic</desc><path pid="0" d="M52.885 28.204c-13.276 0-24.039 10.522-24.039 23.503v367.587c0 12.98 10.762 23.503 24.039 23.503h644.23c13.277 0 24.04-10.523 24.04-23.503V51.707c0-12.98-10.763-23.503-24.04-23.503H52.886zm0-28.204h644.23C726.324 0 750 23.15 750 51.707v367.587C750 447.85 726.323 471 697.116 471H52.885C23.677 471 0 447.85 0 419.294V51.707C0 23.15 23.677 0 52.885 0z" _fill="currentColor"/><g _fill="currentColor"><path pid="1" d="M282.598 330.126l31.845-186.864h50.933L333.51 330.126zM517.521 147.293c-10.091-3.786-25.902-7.849-45.65-7.849-50.327 0-85.779 25.344-86.08 61.668-.284 26.85 25.309 41.829 44.629 50.767 19.825 9.16 26.49 15.001 26.396 23.18-.126 12.525-15.832 18.248-30.472 18.248-20.386 0-31.217-2.833-47.943-9.81l-6.564-2.97-7.148 41.832c11.896 5.216 33.895 9.735 56.736 9.97 53.54 0 88.296-25.055 88.692-63.843.191-21.256-13.378-37.434-42.763-50.77-17.803-8.644-28.706-14.413-28.59-23.166 0-7.767 9.228-16.072 29.167-16.072 16.656-.26 28.72 3.373 38.121 7.159l4.565 2.156 6.904-40.5M648.587 143.263H609.23c-12.192 0-21.316 3.327-26.67 15.496l-75.644 171.248H560.4s8.746-23.026 10.724-28.081c5.844 0 57.801.08 65.23.08 1.523 6.542 6.196 28 6.196 28h47.262l-41.225-186.743zm-62.444 120.66c4.213-10.766 20.293-52.235 20.293-52.235-.3.497 4.181-10.819 6.754-17.835l3.443 16.111s9.752 44.605 11.79 53.959h-42.28zM239.361 143.263L189.496 270.69l-5.314-25.896c-9.282-29.853-38.205-62.195-70.54-78.387l45.596 163.421 53.89-.062 80.185-186.504H239.36"/><path pid="2" d="M142.969 143.263h-82.13l-.65 3.887c63.896 15.468 106.176 52.847 123.726 97.76l-17.859-85.872c-3.083-11.832-12.025-15.363-23.088-15.776"/></g>'
  }
})
