<template>
    <div v-theme="['wall.background']" class="event-wall h-full relative">
        <transition-group
            id="event-block-list"
            name="block-list"
            class="block-list h-full"
            tag="div"
        >
            <component
                :is="`wall-${block.slug}-block`"
                v-for="block in blocks"
                :id="`block-anchor-${block.pivot.id}`"
                :key="block.pivot.id"
                :hidden="block.pivot.settings.isDisplayed === false"
                v-bind="block.pivot.settings"
                :block-id="block.pivot.id"
                :block-type="block.type"
                :data-pivot-id="block.pivot ? block.pivot.id : null"
            >
                <settings-button
                    v-if="isEditMode && block.properties.editable"
                    class="absolute top-0 m-6 z-10"
                    :class="isFirstShownBlock(block) ? 'right-0' : 'left-0'"
                    :block="block"
                ></settings-button>
            </component>
        </transition-group>

        <wall-menu
            key="wall-menu"
            :event="event"
            :preview="preview"
            :is-edit-mode="isEditMode"
        ></wall-menu>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { get as getValue } from 'lodash';
import WallMenu from './WallMenu.vue';
import SettingsButton from './blocks/SettingsButton.vue';

export default {
    name: 'EventWall',

    components: {
        WallMenu,
        SettingsButton
    },

    props: {
        event: {
            type: Object,
            required: true
        },
        preview: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...get('Wall/*'),

        languages: get('Event/event@enabled_languages'),

        blocks () {
            const blocksToDisplay = this.event.wall.blocks.filter((block) => {
                return !!block.pivot && !!block.pivot.settings;
            });

            if (!this.isEditMode && this.event.plan && this.event.plan.features.BrandedFooter.enabled) {
                const brandedFooterBlockDefinition = {
                    slug: 'branded-footer',
                    pivot: {
                        id: 'branded-footer-block',
                        settings: {
                            isDisplayed: true
                        }
                    },
                    type: 'branded-footer'
                };

                return blocksToDisplay.concat(brandedFooterBlockDefinition);
            }

            return blocksToDisplay;
        },

        firstShownBlock () {
            return this.blocks.filter((block) => {
                return block.slug !== 'rsvp-button' && getValue(block, 'pivot.settings.isDisplayed', true);
            })[0];
        }
    },

    mounted () {
        this.languages.forEach(({ name, translations }) => {
            this.$i18n.setLocaleMessage(name, translations);
        });

        this.$i18n.locale = this.languages[0].name;

        this.$emit('mount-wall');
    },

    methods: {
        isFirstShownBlock (block) {
            return this.firstShownBlock.pivot.id === block.pivot.id;
        }
    }
};
</script>
