<template>
    <div class="w-full h-full">
        <button
            class="h-full w-full flex flex-col p-4 bg-white ring-2 ring-transparent shadow-md cursor-pointer rounded-md text-left text-gray-800 transition duration-200 ease-in-out hover:ring-purple hover:shadow-lg"
            @click="isModalVisible = true"
        >
            <div class="inline-flex mb-4">
                <div class="rounded-full bg-purple-100 p-3">
                    <app-icon
                        name="user-process-stroke"
                        class="h-10 w-10 text-purple"
                        stroke
                    ></app-icon>
                </div>
            </div>

            <div class="text-sm font-medium uppercase trackiner-widest text-gray-500 mb-2">Purge Guest Data</div>
            <p class="text-xl font-light">Purge all information about a guest</p>
        </button>

        <app-modal v-model="isModalVisible" title="Purge Guest">
            <form id="purge-guest-form" @submit.prevent="purgeGuestConfirm">
                <form-field-wrapper label="Guest Email" :error="form.errors.get('email')">
                    <input
                        v-model="form.email"
                        class="form-field"
                        placeholder="user@domain.com"
                    >
                </form-field-wrapper>
            </form>

            <template #footer="{ close }">
                <stateful-button
                    class="button button-primary"
                    form="purge-guest-form"
                    :loading="form.processing"
                    type="submit"
                >
                    Purge Guest
                </stateful-button>

                <button class="button" @click="close">
                    Cancel
                </button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import Form from '@/validation/Form';

export default {
    data () {
        return {
            form: new Form({
                email: null
            }),
            isModalVisible: false
        };
    },

    watch: {
        isModalVisible () {
            this.form.restore();
        }
    },

    methods: {
        purgeGuestConfirm () {
            const attributes = {
                confirmButtonText: 'Purge Guest Data',
                cancelButtonText: 'Cancel'
            };

            App.alert().confirm(
                `Purge ${this.form.email}'s email?`,
                'This action will delete ALL submissions, guests, and invitees across all events associated with this email address. THIS ACTION CAN NOT BE UNDONE.',
                attributes,
                this.purgeGuest
            );
        },

        purgeGuest () {
            this.form.post(this.route('api.admin.guests.purge'))
                .then(() => {
                    this.$toasted.global.success(`${this.form.email} was purged from the app.`);
                    this.isModalVisible = false;
                    this.form.restore();
                });
        }
    }
};
</script>
