<template>
    <button @click="deleteAccount">Close my account and delete my data</button>
</template>

<script>
import axios from '@/util/axios';

export default {
    methods: {
        deleteAccount () {
            const attributes = {
                confirmButtonText: 'Delete Account',
                cancelButtonText: 'Cancel',
                inputValidator: (value) => {
                    if (value.toLowerCase() !== this.auth().user().email.toLowerCase()) {
                        return 'Please enter your email to confirm deleting your account.';
                    }
                }
            };

            const onConfirm = (result) => {
                axios
                    .delete(this.route('api.account.destroy'), {
                        data: {
                            email: result.value
                        }
                    })
                    .then(() => {
                        this.$toasted.show(`Your account has been deleted and you will now be logged out.`, {
                            onComplete: () => {
                                window.location.href = 'https://www.rsvpify.com';
                            }
                        });
                    })
                    .catch(() => {
                        this.$toasted.global.error('Enter your email address to confirm deleting your account.');
                    });
            };

            App.alert().confirmType(
                'Destroy your RSVPify account?',
                'You are permanently deleting your account and ALL ASSOCIATED DATA. To proceed, enter your email address below and click ‘Delete Account’. THIS ACTION CAN NOT BE UNDONE.',
                attributes,
                onConfirm
            );
        }
    }
};
</script>
