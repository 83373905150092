import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from './store';
import Alert from './util/alert';
import axios from './util/axios';
import Themer from './themer';
import Trackers from './util/trackers';

export default class App {
    constructor () {
        this.bus = new Vue();
        this.themer = new Themer();
        this.trackers = new Trackers();
    }

    run () {
        this.app = new Vue({
            el: '#app',
            i18n: new VueI18n(),
            store,

            mounted () {
                window.addEventListener('online', () => {
                    this.$toasted.clear();
                });

                window.addEventListener('offline', () => {
                    this.$toasted.error('Your internet connection has disconnected. Please reconnect to the internet to continue.', {
                        duration: null
                    });
                });
            }
        });
    }

    setEvent (event) {
        if (event) {
            const { wall = {}, form = {}, ...currentEvent } = event;

            store.set('Event/event', currentEvent);
            store.set('Form/form', form);
            store.set('Wall/wall', wall);
        }

        this.themer.setup();

        document.dispatchEvent(new Event('RSVPifyAppLoaded'));
    }

    alert () {
        return new Alert();
    }

    request (options) {
        if (options !== undefined) {
            return axios(options);
        }

        return axios;
    }

    $on (...args) {
        this.bus.$on(...args);
    }

    $once (...args) {
        this.bus.$once(...args);
    }

    $off (...args) {
        this.bus.$off(...args);
    }

    $emit (...args) {
        this.bus.$emit(...args);
    }
}
