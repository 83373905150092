/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'duplicate-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>clone copy</desc><g _fill="currentColor"><path pid="0" d="M6.223 18.115a.948.948 0 00-.346-.292 6.489 6.489 0 010-11.647.946.946 0 00.346-.291l.021-.03a.992.992 0 00-1.229-1.484 8.49 8.49 0 000 15.258.993.993 0 001.229-1.485z"/><path pid="1" d="M14.75 3a9 9 0 109 9 9.01 9.01 0 00-9-9zm0 16a7 7 0 117-7 7.008 7.008 0 01-7 7z"/><path pid="2" d="M18.515 10.75H16.25a.25.25 0 01-.25-.25V8.235a1.25 1.25 0 10-2.5 0V10.5a.25.25 0 01-.25.25h-2.265a1.25 1.25 0 100 2.5h2.265a.25.25 0 01.25.25v2.264a1.25 1.25 0 102.5 0V13.5a.25.25 0 01.25-.25h2.265a1.25 1.25 0 100-2.5z"/></g>'
  }
})
