<template>
    <date-time-picker
        v-model="answer"
        :has-time="hasTime"
        class="w-full"
        :min-datetime="null"
    ></date-time-picker>
</template>

<script>
import CustomQuestionEditComponent from '@/mixins/CustomQuestionEditComponent';

export default {
    name: 'CustomQuestionAnswerDatetime',

    mixins: [CustomQuestionEditComponent],

    data () {
        return {
            answer: this.answers.length > 0 ? this.answers[0].raw_value : null
        };
    },

    computed: {
        hasTime () {
            return this.question.settings.dateTime.format !== 'dateOnly';
        }
    }
};
</script>
