/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-lab-research-syringe-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus needle</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.96 13.81a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM16.39 11.25h1.14M16.96 11.25v2.57M20.8 12.6l.81.81M21.21 13l-1.82 1.82M22.96 16.67v1.15M22.96 17.25h-2.57M21.61 21.08l-.81.81M21.21 21.49l-1.82-1.82M17.53 23.25h-1.14M16.96 23.25v-2.58M13.12 21.89l-.8-.81M12.72 21.49l1.82-1.82M10.96 17.82v-1.15M10.96 17.25h2.57M12.32 13.41l.8-.81M12.72 13l1.82 1.82M7.3 17.12l-2.893-2.89-.01-.01c-.54-.54-.54-1.4 0-1.93l8.186-8.19 4.81 4.81"/></g><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M2.94 15.52h3.4v2.72H2.93z" transform="matrix(.7071 -.70711 .7071 .7071 -10.579 8.232)"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="2" d="M1.04 20.5l2.4-2.41M11.15 2.68l5.81 5.82"/></g><path pid="3" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.3 3.48h4.76V6.2h-4.77z" transform="matrix(.7071 -.70711 .7071 .7071 1.46 13.219)"/><path pid="4" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.96.75l4.82 4.82"/>'
  }
})
