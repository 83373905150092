/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-correct-4-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.08 14.28a2.62 2.62 0 100 5.25 2.62 2.62 0 100-5.25zM9.313 23.28h0a4.482 4.482 0 00-8.46-.01M19.14.78a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM23.364 9.78h0a4.469 4.469 0 00-5.7-2.75c-1.29.44-2.3 1.46-2.75 2.74M11.61 19.03a.5.5 0 100 1 .5.5 0 100-1zM14.61 16.03a.5.5 0 100 1 .5.5 0 100-1zM17.61 13.03a.5.5 0 100 1 .5.5 0 100-1zM5.13.71a4.5 4.5 0 100 9 4.5 4.5 0 100-9z"/><path pid="1" d="M3.33 5.66l.97.97-.01-.01c.17.17.45.17.63 0 .01-.02.03-.04.04-.06L6.9 3.84"/></g>'
  }
})
