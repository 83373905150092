/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat-translate': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M6.5 4.25a.751.751 0 00-.75.75v.5a.25.25 0 00.25.25h1a.25.25 0 00.25-.25V5a.751.751 0 00-.75-.75zM17.223 17.25a.25.25 0 00-.234.339 3.975 3.975 0 00.3.614.249.249 0 00.43 0 4.058 4.058 0 00.3-.614.25.25 0 00-.234-.339z"/><path pid="1" d="M22.5 11h-7.75a.25.25 0 00-.25.25v2.5a.75.75 0 01-.75.75h-3a.25.25 0 00-.25.25v7.75A1.5 1.5 0 0012 24h10.5a1.5 1.5 0 001.5-1.5v-10a1.5 1.5 0 00-1.5-1.5zm-8 4.75h2a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v.5a.25.25 0 00.25.25h2a.75.75 0 010 1.5h-.627a.249.249 0 00-.243.192 5.51 5.51 0 01-.876 1.905.249.249 0 00-.041.2.246.246 0 00.118.163 5.881 5.881 0 001.372.57.75.75 0 11-.406 1.444 7.057 7.057 0 01-2.153-1 .248.248 0 00-.287 0 7.057 7.057 0 01-2.153 1 .75.75 0 11-.406-1.444 5.881 5.881 0 001.372-.57.25.25 0 00.119-.163.253.253 0 00-.042-.2 5.51 5.51 0 01-.876-1.905.249.249 0 00-.243-.192H14.5a.75.75 0 010-1.5z"/><path pid="2" d="M12.75 13a.25.25 0 00.25-.25V1.5A1.5 1.5 0 0011.5 0h-10A1.5 1.5 0 000 1.5v10A1.5 1.5 0 001.5 13zM6 7.25a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0V5a2.25 2.25 0 014.5 0v4.5a.75.75 0 01-1.5 0v-2A.25.25 0 007 7.25z"/></g>'
  }
})
