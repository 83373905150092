<template>
    <div class="relative">
        <template v-if="visible && !editorFocused">
            <div class="hidden group-hover:block absolute inset-0 border-2 border-teal rounded" :class="classes"></div>

            <div
                class="hidden group-hover:flex absolute bottom-0 right-0 bg-teal text-white h-6 w-6 items-center justify-center rounded-tl"
                :class="classes"
                @click="edit"
            >
                <app-icon name="edit" class="h-4 w-4"></app-icon>
            </div>
        </template>

        <slot :change-focus="changeFocus" :set-editor="setEditor"></slot>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },

        classes: {
            type: String,
            default: '-my-4'
        }
    },

    data () {
        return {
            editorFocused: false,
            editor: null
        };
    },

    methods: {
        changeFocus (focused) {
            this.editorFocused = focused;
        },

        edit () {
            if (this.editor) {
                this.editor.focus();
            }
        },

        setEditor (editor) {
            this.editor = editor;
        }
    }
};
</script>
