<template>
    <div class="flex items-center justify-center">
        <div class="w-1/2">
            <div class="shadow-md">
                <p class="bg-white text-xs p-2 text-gray-500">Simple Date Picker</p>

                <date-time-picker
                    v-model="date1"
                    title="Event Date"
                    :has-time="false"
                    class="m-6"
                ></date-time-picker>

                <p class="bg-white text-xs p-2 text-gray-500">Selected: <b>{{ date1 }}</b><br></p>
            </div>

            <div class="shadow-md mt-4">
                <p class="bg-white text-xs p-2 text-gray-500">Date Time Picker</p>

                <date-time-picker
                    v-model="date2"
                    title="Event Start Date & Time"
                    placeholder="Please select a date and time ..."
                    class="m-6"
                    has-time
                ></date-time-picker>

                <p class="bg-white text-xs p-2 text-gray-500">Selected: <b>{{ date2 }}</b><br></p>
            </div>

            <div class="shadow-md mt-4">
                <p class="bg-white text-xs p-2 text-gray-500">Time Picker</p>

                <date-time-picker
                    v-model="date3"
                    title="Lunch Time"
                    placeholder="Please select the time ..."
                    class="m-6"
                    has-time
                    :has-date="false"
                ></date-time-picker>

                <p class="bg-white text-xs p-2 text-gray-500">Selected: <b>{{ date3 }}</b><br></p>
            </div>

            <div class="shadow-md mt-4">
                <p class="bg-white text-xs p-2 text-gray-500">Date and Time Picker (AM/PM)</p>

                <date-time-picker
                    v-model="date4"
                    title="Dinner Time"
                    placeholder="Please select the time ..."
                    class="m-6"
                    has-time
                ></date-time-picker>

                <p class="bg-white text-xs p-2 text-gray-500">Selected: <b>{{ date4 }}</b><br></p>
            </div>

            <div class="shadow-md mt-4">
                <p class="bg-white text-xs p-2 text-gray-500">Date and Time Picker With Custom Date Format</p>

                <date-time-picker
                    v-model="date5"
                    title="Dinner Time"
                    placeholder="Please select the time ..."
                    date-format="cccc - dd.LL.yyyy @ H:mm"
                    class="m-6"
                    has-time
                ></date-time-picker>

                <p class="bg-white text-xs p-2 text-gray-500">Selected: <b>{{ date5 }}</b><br></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            date1: null,
            date2: null,
            date3: null,
            date4: null,
            date5: null
        };
    }
};
</script>
