<template>
    <div class="no-underline flex flex-col md:flex-row items-center p-6 md:py-4 bg-white shadow ring-2 ring-transparent hover:ring-purple-light hover:shadow-lg cursor-pointer rounded-md text-gray-800 transition ease-in-out duration-150" @click="goToCustomFontsList">
        <app-icon
            name="font-picker-stroke"
            class="h-12 w-12 md:h-8 md:w-8 text-gray-600"
            stroke
        ></app-icon>

        <div class="mt-2 md:mt-0 md:ml-6 flex-auto leading-normal text-center md:text-left">
            <div class="flex items-center">
                <p class="text-lg font-semibold">Custom Fonts</p>

                <img
                    v-if="!canUseCustomFonts"
                    v-tippy
                    alt="RSVPify Premium"
                    class="w-5 h-5 ml-2"
                    content="Upgrade to access this feature."
                    :src="asset('images/account/rsvpify-premium.svg')"
                >
            </div>

            <p class="mt-4 md:m-0 md:text-sm text-gray-600">Import custom fonts into RSVPify.</p>
        </div>

        <app-icon
            name="arrow-right-chevron"
            class="h-6 w-6 text-gray-500 hidden md:flex"
            stroke
        ></app-icon>
    </div>
</template>

<script>
export default {
    name: 'CustomFontsOption',

    data () {
        return {

        };
    },

    computed: {
        canUseCustomFonts () {
            return this.auth().user().plan.features.CustomFonts.type === 'enabled';
        }
    },

    methods: {
        goToCustomFontsList () {
            if (!this.canUseCustomFonts) {
                this.showPaywall();
                return;
            }

            window.location = this.route('account.custom-fonts');
        },

        showPaywall () {
            App.alert().paymentRequired(
                'Please upgrade.',
                `Your ${this.auth().user().plan.name} plan doesn't include Custom Fonts. Please contact your Account Manager.`,
                {
                    chatWithUs: true,
                    supportMessage: 'Hello, I would like some more information about Custom Fonts.'
                }
            );
        }
    }
};
</script>
