/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-question-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>user person account avatar question</desc><path pid="0" d="M.75 17.25a6.753 6.753 0 019.4-6.208" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><circle pid="1" cx="7.5" cy="4.875" r="4.125" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><circle pid="2" cx="17.25" cy="17.25" r="6" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="3" d="M15.375 16.125A1.875 1.875 0 1117.25 18M17.25 20.25a.375.375 0 10.375.375.374.374 0 00-.375-.375" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>'
  }
})
