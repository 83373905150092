/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-day-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M20.25 12h0c0 1.65 1.34 2.99 3 2.99v3.75h-.01a3.009 3.009 0 01-3.01 2.99H3.73l-.01-.001c-1.66-.01-3-1.35-3-3v-3.75l-.01-.001c1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3V5.21c0-1.66 1.34-3 3-3h16.5c1.65 0 2.99 1.34 2.99 3v3.75-.001c-1.66 0-3 1.34-3.01 3z"/><path pid="1" d="M10.5 18.75v-4.5h0a1.496 1.496 0 112.99-.02s0 0 0 0v4.5M10.5 17.25h3M15.75 12.75v1.5h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51v-1.5M17.25 15.75v3M5.25 18.75c4 0 3.92-6 0-6zM11.25 5.25H12h-.01c.41-.01.75.33.75.75v3.75M11.25 9.75h3"/></g>'
  }
})
