/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-forbidden-touch-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M20.847 10.46l-1.08-5.4h0a1.621 1.621 0 00-1.9-1.27c-.64.12-1.14.62-1.27 1.26h-.01l-.03.13-.37 3.25-7.23-7.23-.01-.01a1.57 1.57 0 00-2.23 0c-.62.61-.62 1.6 0 2.22l-1.12-1.12h0c-.62-.62-1.61-.62-2.23-.01-.62.61-.62 1.6-.01 2.22l1.11 1.11h0a1.58 1.58 0 00-2.23.1c-.55.59-.55 1.51-.01 2.11l1.11 1.11h0c-.63-.61-1.63-.58-2.23.04-.59.6-.59 1.56-.01 2.17l2.078 2.12M9.17 5.86l-2.4-2.41M6.08 7.22L4.54 5.68"/><path pid="1" d="M12.38 12.47c-4.12-3.75-5.93-2.76-6.49-2.19l-3.72 4.39h-.01c-.52.59-.83 1.33-.9 2.11M10.324 17.991l-1.25-.02 2.26-2.59M4.421 22.15l.71-.9-.01-.01c.77.16 1.58.14 2.35-.09l3.25-.72M18.25 13.23a4.99 4.99 0 100 9.98 4.99 4.99 0 100-9.99zM21.79 14.7l-7.06 7.06"/></g>'
  }
})
