<template>
    <base-picker
        ref="basePicker"
        v-bind="$attrs"
        v-model="selected"
        :items="pickerItems"
        :filter-function="filterFunctionImplementation"
        :placeholder-empty-state="placeholderEmptyState"
        :placeholder-search="placeholderSearch"
        :form-field-class="formFieldClass"
        :starting-highlighted-index="startingHighlightedIndex"
        :is-item-disabled-function="isItemDisabledFunction"
        :disabled="disabled"
        :after-list-action="afterListAction"
        @updated-search-term="updatedSearchTerm"
        @afterListClicked="$emit('afterListClicked', $event)"
    >
        <div
            slot="input"
            slot-scope="{ selected }"
        >
            <slot
                name="input"
                :selected="selected"
            >
                {{ selectedItemLabel }}
            </slot>
        </div>
        <div
            slot="item"
            slot-scope="{ item, index }"
        >
            <slot
                name="item"
                :item="item"
                :index="index"
            >
                {{ getItemLabel(item) }}
            </slot>
        </div>
    </base-picker>
</template>

<script>
import get from 'lodash/get';

export default {
    props: {
        afterListAction: {
            type: String,
            default: null
        },

        disabled: {
            type: Boolean,
            default: false
        },

        filterableProperty: {
            type: String,
            default: null
        },

        fetchFunction: {
            type: Function,
            default: null
        },

        filterFunction: {
            type: Function,
            default: null
        },

        formFieldClass: {
            type: String,
            default: 'form-field'
        },

        items: {
            type: Array,
            default: () => { return []; }
        },

        itemLabel: {
            type: String,
            default: null
        },

        itemValue: {
            type: String,
            default: null
        },

        isFilterable: {
            type: Boolean,
            default: false
        },

        isItemDisabledFunction: {
            type: Function,
            default: () => { return false; }
        },

        placeholderEmptyState: {
            type: String,
            default: 'Select an item ...'
        },

        placeholderSearch: {
            type: String,
            default: 'Search for an item ...'
        },

        startingHighlightedIndex: {
            type: Number,
            default: 0
        },

        value: {
            type: [String, Number, Object],
            default: null
        }
    },

    data () {
        return {
            fetchedPickerItems: [],
            selected: this.value,
            filterFunctionImplementation: this.filterFunction
        };
    },

    computed: {
        selectedItemLabel () {
            return this.getItemLabel(this.selected);
        },

        pickerItems () {
            if (this.fetchFunction) {
                return this.fetchedPickerItems;
            }

            return this.items;
        }
    },

    watch: {
        value () {
            this.preselect();
        },

        selected (newVal) {
            this.$emit('input', this.getItemValue(newVal));
            this.$emit('selected-label-change', this.getItemLabel(newVal));
        }
    },

    mounted () {
        this.createFilterFunction();
        this.fetchItems().then(() => {
            this.preselect();
        });
    },

    methods: {
        getItemValue (item) {
            if (!item) {
                return null;
            }

            if (this.itemValue) {
                return get(item, this.itemValue);
            }

            return item;
        },

        updatedSearchTerm (searchTerm) {
            if (this.fetchFunction) {
                this.fetchItems(searchTerm);
            }
        },

        preselect () {
            const selectedItemIndex = this.pickerItems.findIndex((item) => {
                return this.getItemValue(item) === this.value;
            });

            if (this.value) {
                this.selected = this.pickerItems[selectedItemIndex];
                this.$refs.basePicker.highlightedIndex = selectedItemIndex;
            } else if (this.value === null || this.value === undefined || this.value === '') {
                this.selected = this.pickerItems[selectedItemIndex] || null;
            }
        },

        getItemLabel (item) {
            if (!item) {
                return null;
            }

            if (this.itemLabel) {
                return get(item, this.itemLabel);
            }

            return item;
        },

        createFilterFunction () {
            // This BasePicker is not filterable
            if (!this.isFilterable) {
                this.filterFunctionImplementation = null;

                return;
            }

            // A custom filter function has been provided
            if (this.filterFunctionImplementation !== null) {
                return;
            }

            if (this.fetchFunction !== null) {
                this.filterFunctionImplementation = (searchTerm, items) => {
                    return this.pickerItems;
                };

                return;
            }

            // Default filtering method for filtering by a specified object property
            if (this.filterableProperty) {
                this.filterFunctionImplementation = (searchTerm, items) => {
                    return items.filter((item) => {
                        const searchRegex = new RegExp(searchTerm, 'i');
                        return searchRegex.test(get(item, this.filterableProperty));
                    });
                };

                return;
            }

            // Default filtering method for filtering items of primitive type
            this.filterFunctionImplementation = (searchTerm, items) => {
                return items.filter((item) => {
                    const searchRegex = new RegExp(searchTerm, 'i');
                    return searchRegex.test(item);
                });
            };
        },

        fetchItems (searchTerm = '') {
            return new Promise((resolve) => {
                if (!this.fetchFunction) {
                    resolve();
                }

                this.fetchFunction(searchTerm).then((items) => {
                    this.$set(this, 'fetchedPickerItems', items);

                    resolve();
                });
            });
        }
    }
};
</script>
