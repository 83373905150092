/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fruit-apple-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 7.509h-.01c-.53.04-1.06-.06-1.54-.28-6.747-3.99-7.99 2.82-7.3 7.02.76 4.67 2.74 8.609 5.474 8.973l-.01-.01c.27.02.54-.03.78-.17l1.91-.98h-.01c.41-.22.9-.22 1.31-.01l1.91.97-.01-.01c.23.13.51.18.78.16 2.73-.37 4.71-4.3 5.474-8.973.37-2.26.18-5.28-1.12-6.96M18.549 4.584v-.01c-.72.67-1.6 1.12-2.56 1.3h-.01a.93.93 0 01-1.17-.59.883.883 0 010-.6h-.01c.17-.98.61-1.88 1.28-2.61h-.01c.7-.68 1.59-1.13 2.56-1.31v-.01c.48-.16 1 .1 1.16.58.06.19.06.4-.01.59h-.01c-.18.97-.62 1.87-1.29 2.6zM12 3.76v3.75"/></g>'
  }
})
