/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-cocktail-coconut-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>alcohol bar beach tropical</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.826 6h-7.1v-.001c-.38 0-.75.14-1.02.39-5.6 5.17-5.943 13.05 3.65 16.75h0c.17.06.35.09.53.09h4.715l-.01-.001c.18 0 .36-.04.53-.1 7.33-2.83 8.858-8.1 6.66-12.74M.75 3.75l2.7-2.671h-.01a1.5 1.5 0 012 .1l4.3 4.81"/><path pid="1" d="M1.6 10.588l-.01-.01c1.68 2.83 5.35 3.75 8.18 2.07.55-.33 1.04-.75 1.46-1.23h0a5.957 5.957 0 008.41.61c.48-.42.89-.92 1.22-1.47M18 4.58a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/><path pid="2" d="M23.252 5.417v-.01a2.318 2.318 0 00-2.28-2.34c-.22-.01-.44.02-.66.08v-.09h0a2.343 2.343 0 00-2.39-2.29 2.327 2.327 0 00-2.29 2.28v.08h0c-1.25-.34-2.52.4-2.85 1.64-.29 1.08.23 2.21 1.24 2.69h-.01a2.31 2.31 0 001.34 2.99c.9.34 1.93.08 2.57-.65l-.01-.01c.83.97 2.29 1.08 3.27.25.74-.65 1.01-1.69.65-2.61v-.01c.8-.39 1.32-1.2 1.32-2.1z"/></g>'
  }
})
