<template>
    <portal to="event-wall-footer">
        <div
            :id="blockId"
            class="w-full bottom-0 bg-snow-500"
            :class="blockClasses"
        >
            <a
                v-theme="'wall.button'"
                role="button"
                class="block p-3 mx-12 my-3 rounded-md text-xl font-semibold text-center truncate"
                @click="showRsvpForm"
            >
                {{ text }}
            </a>
        </div>
    </portal>
</template>

<script>
import { get } from 'vuex-pathify';
import WallBlock from '@/mixins/WallBlock';

export default {
    mixins: [WallBlock],

    props: {
        text: {
            type: String,
            required: true
        }
    },

    computed: {
        ...get('Wall/*'),

        blockClasses () {
            return {
                hidden: this.isEditMode && !this.isMobileView,
                'md:hidden': !this.isEditMode,
                sticky: !this.isEditMode,
                'absolute z-50': this.isEditMode
            };
        }
    },

    methods: {
        showRsvpForm () {
            App.$emit('toggle-event-wall-display', false);
        }
    }
};
</script>
