/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings-slider-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>option</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="22.5" height="22.5" x=".751" y=".75" rx="1.5"/><path pid="1" d="M12.75 15.75h6M5.25 15.75h3"/><path pid="2" fill-rule="evenodd" d="M12.751 15.75v-.01c0 1.24-1.01 2.25-2.25 2.25-1.25 0-2.25-1.01-2.25-2.25 0-1.25 1-2.25 2.24-2.25h-.01c1.24-.01 2.25 1 2.25 2.24 0 0 0 0 0 0z"/><path pid="3" d="M14.25 8.25h-9"/><path pid="4" fill-rule="evenodd" d="M18.751 8.25h0c0-1.25-1.01-2.25-2.25-2.25-1.25 0-2.25 1-2.25 2.25 0 1.24 1 2.24 2.24 2.24h0c1.24 0 2.25-1.01 2.25-2.26z"/></g>'
  }
})
