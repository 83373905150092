<template>
    <div class="mt-8 -mx-8 md:mx-0">
        <infinite-data-table
            ref="table"
            class="text-gray-600"
            clickable-rows
            :columns="chosenColumns"
            :filter="filter"
            no-results-placeholder="Sorry, we couldn't find any seating charts."
            remote-sort
            :sort="sort"
            :source="dataSource"
            @change-sort="handleChangedSort"
            @click-row="editSeatingChart"
        >
            <template #header>
                <div class="flex-auto">
                    <search-field
                        v-model="filter.title"
                        class="w-full md:w-72"
                        placeholder="Search by chart name..."
                    ></search-field>
                </div>

                <choosable-columns
                    v-model="chosenColumns"
                    class="mr-2 hidden md:block"
                    :columns="columns"
                ></choosable-columns>

                <a :href="route('events.seating-charts.create', event)" class="button button-primary p-2">
                    <app-icon
                        class="w-5 h-5 mr-2"
                        name="add-circle"
                        stroke
                    ></app-icon>
                    New Chart
                </a>
            </template>

            <template slot="row" slot-scope="{ row, property, value }">
                <!-- Summary column represents mobile representation of table data. -->
                <div v-if="property === 'summary'" class="flex">
                    <div class="flex flex-col flex-auto text-gray-600">
                        <p>{{ row.title }}</p>
                        <p class="text-sm">Edited by {{ row.updated_by }} on {{ row.updated_at | dateTimeTz(eventTimezone.name) }}</p>
                        <p class="text-sm">
                            <span class="font-semibold text-teal">{{ row.stats_counts.numberOfSeatables.toLocaleString() }}</span> unseated |
                            <span class="font-semibold text-teal">{{ row.stats_counts.numberOfSeated.toLocaleString() }}</span> seated |
                            <span class="font-semibold text-teal">{{ row.stats_counts.numberOfSeatingAreas.toLocaleString() }}</span> tables
                        </p>
                    </div>

                    <div class="flex items-center ml-4" @click.stop>
                        <base-dropdown v-cloak placement="bottom-end">
                            <template slot="trigger" slot-scope="{ triggerFunction }">
                                <button class="flex button-icon" @click.stop="triggerFunction">
                                    <app-icon name="navigation-menu-horizontal" stroke></app-icon>
                                </button>
                            </template>

                            <div class="w-48 bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden">
                                <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="editSeatingChart(row)">
                                    <app-icon
                                        name="edit"
                                        class="h-5 w-5 mr-3"
                                        stroke
                                    ></app-icon>
                                    Edit chart
                                </button>
                                <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="duplicateSeatingChart(row)">
                                    <app-icon
                                        name="duplicate-square"
                                        class="h-5 w-5 mr-3"
                                        stroke
                                    ></app-icon>
                                    Duplicate chart
                                </button>
                                <delete-item
                                    class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-red hover:bg-gray-100 transition duration-150 ease-in-out"
                                    :confirmation-message="{ title: 'Are you sure?', text: 'Do you really want to delete this seating chart? This cannot be undone.' }"
                                    :endpoint="route('api.events.seating-charts.destroy', { event, seatingChart: row.id })"
                                    @delete-item="seatingChartDeleted"
                                >
                                    <app-icon
                                        name="trash"
                                        class="h-5 w-5 mr-3"
                                        stroke
                                    ></app-icon>
                                    Delete chart
                                </delete-item>
                            </div>
                        </base-dropdown>
                    </div>
                </div>

                <div v-else-if="property === 'stats_counts'" class="flex items-center">
                    <div class="flex items-center justify-center p-2 rounded text-teal bg-teal-light">
                        <app-icon
                            name="table-restaurant"
                            class="h-6 w-6"
                            stroke
                        ></app-icon>
                    </div>

                    <div class="flex flex-col ml-4 text-sm">
                        <p class="font-semibold">{{ value.numberOfSeatingAreas.toLocaleString() }}</p>
                        <p>Tables</p>
                    </div>

                    <div class="flex items-center justify-center p-2 ml-8 rounded text-green bg-green-light">
                        <app-icon
                            name="users-alt-check-circle"
                            class="h-6 w-6"
                            stroke
                        ></app-icon>
                    </div>

                    <div class="flex flex-col ml-4 text-sm">
                        <p class="font-semibold">{{ value.numberOfSeated.toLocaleString() }} of {{ value.numberOfSeatables.toLocaleString() }}</p>
                        <p>Seated</p>
                    </div>
                </div>

                <div v-else-if="property === 'updated_at'">
                    {{ row.updated_at | dateTimeTz(eventTimezone.name) }}
                </div>

                <!-- Note that the Options column is a "fake" column/property name and it does not actually have a label. -->
                <div
                    v-else-if="property === 'options'"
                    class="flex items-center mx-4"
                    @click.stop
                >
                    <base-dropdown v-cloak placement="bottom-end">
                        <template slot="trigger" slot-scope="{ triggerFunction }">
                            <button class="flex button-icon" @click.stop="triggerFunction">
                                <app-icon name="navigation-menu-horizontal" stroke></app-icon>
                            </button>
                        </template>

                        <div class="w-48 bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden">
                            <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="editSeatingChart(row)">
                                <app-icon
                                    name="edit"
                                    class="h-5 w-5 mr-3"
                                    stroke
                                ></app-icon>
                                Edit chart
                            </button>
                            <button class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-purple hover:bg-gray-100 transition duration-150 ease-in-out" @click="duplicateSeatingChart(row)">
                                <app-icon
                                    name="duplicate-square"
                                    class="h-5 w-5 mr-3"
                                    stroke
                                ></app-icon>
                                Duplicate chart
                            </button>
                            <delete-item
                                class="flex w-full items-center px-4 py-2 cursor-pointer font-medium hover:text-red hover:bg-gray-100 transition duration-150 ease-in-out"
                                :confirmation-message="{ title: 'Are you sure?', text: 'Do you really want to delete this seating chart? This cannot be undone.' }"
                                :endpoint="route('api.events.seating-charts.destroy', { event, seatingChart: row.id })"
                                @delete-item="seatingChartDeleted"
                            >
                                <app-icon
                                    name="trash"
                                    class="h-5 w-5 mr-3"
                                    stroke
                                ></app-icon>
                                Delete chart
                            </delete-item>
                        </div>
                    </base-dropdown>
                </div>

                <div v-else>
                    {{ value }}
                </div>
            </template>
        </infinite-data-table>

        <seating-chart-editor v-model="showEditSeatingChartModal" :seating-chart-to-edit="seatingChartToEdit"></seating-chart-editor>

        <duplicate-seating-chart-modal
            v-model="showDuplicateSeatingChartModal"
            :available-audience="availableAudience"
            :original-seating-chart="originalSeatingChart"
        ></duplicate-seating-chart-modal>
    </div>
</template>

<script>
import EventTimezone from '@/mixins/EventTimezone';

export default {
    name: 'EventSeatingCharts',

    mixins: [EventTimezone],

    props: {
        availableAudience: {
            type: Array,
            required: true
        },

        event: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            chosenColumns: [],
            dataSource: this.route('api.events.seating-charts.index', this.event),
            filter: {
                title: ''
            },
            sort: {},
            showDuplicateSeatingChartModal: false,
            originalSeatingChart: {},
            showEditSeatingChartModal: false,
            seatingChartToEdit: {}
        };
    },

    computed: {
        columns () {
            return [
                {
                    label: 'Seating chart',
                    property: 'summary',
                    desktop: false,
                    mobile: true,
                    alwaysActive: true
                },
                {
                    label: 'Chart title',
                    property: 'title',
                    desktop: true,
                    sortable: true,
                    alwaysActive: true
                },
                {
                    label: 'Counts',
                    property: 'stats_counts',
                    desktop: true,
                    sortable: false
                },
                {
                    label: 'Last edited',
                    property: 'updated_at',
                    desktop: true,
                    sortable: true
                },
                {
                    label: 'Edited By',
                    property: 'updated_by',
                    desktop: true,
                    sortable: false
                },
                {
                    label: '',
                    property: 'options',
                    desktop: true,
                    sortable: false
                }
            ];
        }
    },

    watch: {
        showEditSeatingChartModal (newValue) {
            if (!newValue) {
                this.$refs.table.reload();
            }
        }
    },

    mounted () {
        const getParams = new URLSearchParams(window.location.search);

        if (getParams.has('newSeatingChartId')) {
            this.editSeatingChart({ id: getParams.get('newSeatingChartId') });
        }
    },

    methods: {
        duplicateSeatingChart (seatingChart) {
            this.originalSeatingChart = seatingChart;
            this.showDuplicateSeatingChartModal = true;
        },

        editSeatingChart (seatingChart) {
            this.seatingChartToEdit = seatingChart;
            this.showEditSeatingChartModal = true;
        },

        handleChangedSort ([newSort]) {
            this.sort = newSort || {};
        },

        seatingChartDeleted () {
            this.$toasted.global.success('Seating chart has been successfully deleted.');
            this.$refs.table.reload();
        }
    }
};
</script>
