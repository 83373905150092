/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'credit-card-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="22.5" height="16.5" x=".75" y="3.75" rx="1.5"/><path pid="1" d="M.75 8.25h22.5M5.25 12.75h8.25M5.25 15.75h5.25"/></g>'
  }
})
