<template>
    <validation-provider
        ref="provider"
        v-slot="{ flags, errors }"
        tag="div"
        mode="passive"
        name="subdomain"
        :rules="validationRules"
    >
        <div class="relative">
            <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2"
                :class="getClassObject(flags, 'text-green', 'text-red')"
            >
                <span class="text-gray-500 mr-2">.rsvpify.com</span>
                <app-icon
                    v-if="shouldShowIcon(flags)"
                    :name="getIconName(flags)"
                    class="h-6 w-6 transition"
                    :class="getIconClassObject(flags)"
                ></app-icon>
            </div>

            <input
                :value="actualValue"
                class="form-field pr-32 transition"
                :class="getClassObject(flags, 'form-field-success', 'form-field-error')"
                placeholder="myevent"
                maxlength="25"
                @input="updateActualValue($event)"
                @change="$emit('change')"
            >
        </div>

        <div v-if="shouldShowErrors(flags)" class="alert alert-error alert-sm mt-2">
            <p class="w-full font-normal text-center">{{ errors[0] }}</p>
        </div>
    </validation-provider>
</template>

<script>
import debounce from 'lodash/debounce';
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'EventSubdomain',

    components: { ValidationProvider },

    props: {
        validateOnInit: {
            type: Boolean,
            default: false
        },

        value: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            isDirty: false,
            isValidating: false,
            actualValue: this.value,
            initialValue: this.value.length > 0 ? this.value : null
        };
    },

    computed: {
        validationRules () {
            return {
                max: 35,
                availableSubdomain: true
            };
        }
    },

    watch: {
        actualValue () {
            this.isDirty = true;

            this.validate();
            this.$emit('input', this.actualValue);
        },

        value () {
            this.actualValue = this.value;
        }
    },

    mounted () {
        if (!this.initialValue) {
            return;
        }
        if (this.validateOnInit) {
            this.forceValidate();
        } else {
            this.markAsValid();
        }
    },

    methods: {
        shouldShowIcon ({ pending, validated }) {
            return pending || validated;
        },

        shouldShowErrors ({ validated, valid }) {
            return validated && !valid;
        },

        getIconName ({ pending, valid }) {
            if (pending) {
                return 'loading-half';
            }

            return valid ? 'check-circle-stroke' : 'close-circle-stroke';
        },

        getIconClassObject ({ pending }) {
            return {
                spinning: pending,
                'text-gray-500': pending
            };
        },

        getClassObject ({ pending, validated, valid }, validClass, invalidClass) {
            return {
                [validClass]: !pending && validated && valid,
                [invalidClass]: !pending && validated && !valid
            };
        },

        markAsValid () {
            this.$refs.provider.setFlags({ valid: true, validated: true });
        },

        runValidation () {
            this.isDirty = false;
            this.isValidating = true;

            if (this.actualValue === this.initialValue) {
                this.markAsValid();

                this.isValidating = false;

                return;
            }

            this.forceValidate();
        },

        forceValidate () {
            this.$refs.provider.validate(this.actualValue).then(() => {
                if (this.isDirty) {
                    this.runValidation();
                } else {
                    this.isValidating = false;
                }
            });
        },

        updateActualValue (event) {
            this.actualValue = event.target.value;
        },

        validate: debounce(function () {
            if (this.isValidating) {
                return;
            }

            this.runValidation();
        }, 750)
    }
};
</script>
