/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cursor-double-click-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>target mouse arrow pointer</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.536 11.568l2.68 8.931-.01-.01c.13.43.59.68 1.03.55a.85.85 0 00.34-.21l1.36-1.37 3.52 3.52h0c.32.32.84.32 1.17 0v-.01l2.34-2.35v-.01c.32-.33.32-.85 0-1.18l-.01-.01-3.53-3.53 1.36-1.37h-.01c.32-.33.32-.85 0-1.18-.1-.1-.22-.17-.35-.21l-8.932-2.68-.01-.01a.83.83 0 00-1.04.56c-.05.15-.05.31-.01.46z"/><path pid="1" d="M21.723 10.5l-.01-.01C21.29 4.7 16.27.35 10.48.76 4.69 1.17.34 6.19.75 11.98c.37 5.2 4.51 9.35 9.72 9.72"/><path pid="2" d="M16.81 9h0a6.008 6.008 0 00-7.82-3.32 5.993 5.993 0 00-3.32 7.81c.6 1.5 1.8 2.7 3.31 3.31"/></g>'
  }
})
