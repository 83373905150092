<template>
    <button
        class="menu-button"
        @click="addVideo"
    >
        <app-icon name="video-stroke" class="h-5 w-5"></app-icon>
    </button>
</template>

<script>
export default {
    name: 'EditorEmbedVideoButton',

    props: {
        editor: {
            type: Object,
            required: true
        }
    },

    methods: {
        addVideo () {
            const url = prompt('Please enter the video embed URL:');

            this.editor.commands.iframe({
                src: this.convertUrlToEmbedSource(url)
            });
        },

        convertUrlToEmbedSource (url) {
            if (url.indexOf('youtube') !== -1) {
                const [, id] = url.match(/\?v=([a-zA-Z0-9\-_]+)/);

                if (id != null) {
                    return `https://www.youtube.com/embed/${id}`;
                }
            }

            if (url.indexOf('youtu.be') !== -1) {
                const [, id] = url.match(/youtu\.be\/([a-zA-Z0-9\-_]+)/);

                if (id != null) {
                    return `https://www.youtube.com/embed/${id}`;
                }
            }

            if (url.indexOf('vimeo') !== -1) {
                const [, id] = url.match(/vimeo\.com\/(\d+)/);

                if (id != null) {
                    return `https://player.vimeo.com/video/${id}`;
                }
            }

            return url;
        }
    }
};
</script>
