/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'technology-qr-code-scan-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>check-in</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 5.25h3v3h-3zM5.25 15.75h3v3h-3zM15.75 5.25h3v3h-3zM5.25 12.75h6v1.5M14.25 14.25v4.5h4.5v-4.5h-1.5M11.25 17.25v1.5M11.25 5.25v4.5h1.5M15.75 11.25h3M.75 6V2.25h0C.74 1.42 1.42.74 2.24.74h3.75M18 .75h3.75-.01c.82-.01 1.5.67 1.5 1.5V6M23.25 18v3.75c0 .82-.68 1.5-1.5 1.5H18M6 23.25H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V18"/></g>'
  }
})
