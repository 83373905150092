/**
 * Utils: getMarkExtent
 * @author mitar
 * @see https://discuss.prosemirror.net/t/expanding-the-selection-to-the-active-mark/478/7
 */

export function getMarkExtent (editor, pos, type) {
    const $pos = editor.view.state.doc.resolve(pos);

    const start = $pos.parent.childAfter($pos.parentOffset);

    if (!start.node) {
        return;
    }

    const mark = start.node.marks.find((mark) => {
        return mark.type === editor.view.state.schema.marks[type];
    });

    if (!mark) {
        return;
    }

    let startIndex = $pos.index();
    let startPos = $pos.start() + start.offset;

    while (startIndex > 0 && mark.isInSet($pos.parent.child(startIndex - 1).marks)) {
        startIndex -= 1;
        startPos -= $pos.parent.child(startIndex).nodeSize;
    }

    let endIndex = $pos.indexAfter();
    let endPos = startPos + start.node.nodeSize;

    while (endIndex < $pos.parent.childCount && mark.isInSet($pos.parent.child(endIndex).marks)) {
        endPos += $pos.parent.child(endIndex).nodeSize;
        endIndex += 1;
    }

    return { from: startPos, to: endPos };
}
