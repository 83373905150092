/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="currentColor" d="M23.2 10.53a1 1 0 00.8-.98V5.5A1.5 1.5 0 0022.5 4h-21A1.5 1.5 0 000 5.5v4.05a1 1 0 00.8.98 1.5 1.5 0 010 2.94 1 1 0 00-.8.98v4.05A1.5 1.5 0 001.5 20h21a1.5 1.5 0 001.5-1.5v-4.05a1 1 0 00-.8-.98 1.5 1.5 0 010-2.94zM5.25 18.25A.75.75 0 116 17.5a.75.75 0 01-.75.75zm0-3.667a.75.75 0 11.75-.75.75.75 0 01-.75.75zm0-3.666a.75.75 0 11.75-.75.75.75 0 01-.75.75zm0-3.667A.75.75 0 116 6.5a.75.75 0 01-.75.75zm11.75 9a1.75 1.75 0 111.75-1.75A1.751 1.751 0 0117 16.25zm1.5-7.5h-9a.75.75 0 010-1.5h9a.75.75 0 010 1.5z"/>'
  }
})
