/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'write-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>write pencil paper stroke</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.667 14.136l-3.72.53.53-3.72L19.02 1.4h-.01c.87-.88 2.3-.88 3.18-.01.87.87.87 2.3 0 3.18z"/><path pid="1" d="M19.122 14.25v7.5c0 .82-.68 1.5-1.5 1.5H2.612a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-15h0c-.01-.83.67-1.51 1.49-1.51h7.5"/></g>'
  }
})
