/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vegetables-plate-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.99.75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/><path pid="1" d="M14.735 16.85l-.01-.01c.55 1.2.03 2.62-1.17 3.18-1.06.48-2.3.15-2.97-.79l-4.541-6.8h0a1.5 1.5 0 01.41-2.09c.02-.02.05-.04.07-.05l2.42-1.41h-.01c.71-.42 1.63-.18 2.05.54.01.02.03.05.04.08zM7.68 14.88l2.35-1.37M7.77 9.62L4.31 8.15M7.77 9.62l.43-3.73M12.374 6.8h-.01a2.992 2.992 0 014.1-1.07c1.37.8 1.87 2.54 1.14 3.96z"/><path pid="2" d="M16.418 9.036h-.01c-.92.14-1.72.69-2.17 1.51l5.25 2.9h-.01c.8-1.45.27-3.28-1.18-4.08-.16-.09-.31-.16-.48-.21"/></g>'
  }
})
