/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video-game-magic-wand-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.65 6.04l4.068 1.25 2.96-2.45h-.01c.18-.17.47-.16.64.02.07.08.12.19.11.31l-.12 4.33 3.69 2.03h0c.22.13.29.43.15.65-.06.09-.16.16-.26.2l-4.04 1.44-1.45 4.03v-.01c-.08.25-.35.38-.6.31a.514.514 0 01-.27-.21l-2.04-3.7-4.338.11h-.01c-.26 0-.46-.2-.47-.45-.01-.12.04-.23.11-.32l2.45-2.97-1.26-4.07h0c-.08-.27.08-.55.35-.62.08-.03.17-.03.25-.01zM13.87 13.87l9.38 9.38M18 2.25h3M19.5.75v3M2.25 20.25h3M3.75 18.75v3M9 3V.75M.75 9.75H3"/></g>'
  }
})
