/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-champagne-glass-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>bar alcohol bubbly prosecco celebration mimosa</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9 23.25h6M12 23.25v-6M14.344 16.54v0h-.01c-1.42.94-3.27.94-4.69-.01v0h0a4.224 4.224 0 01-1.86-4.01L8.91 1.41H8.9a.74.74 0 01.74-.68h4.64l-.01-.001c.38 0 .7.29.74.67l1.13 11.1h0c.18 1.57-.54 3.12-1.86 4zM8.39 6.75h7.22"/><path pid="1" d="M10.87 9h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38v-.001M10.87 13.5h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M13.125 11.25h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38"/></g>'
  }
})
