import Form from '@/validation/Form';

export default {
    props: {
        block: {
            type: Object,
            default: () => { return {}; }
        },
        event: {
            type: Object,
            default: () => { return {}; }
        },
        item: {
            type: Object,
            default: () => { return {}; }
        }
    },

    data () {
        return {
            form: new Form({ _item: true, ...this.item }),
            loading: false
        };
    },

    methods: {
        save () {
            this.loading = true;

            const route = this.route('api.walls.blockables.validate', {
                event: this.event,
                blockable: this.block.pivot
            });

            this.form.put(route).then(() => {
                this.$emit('save-settings', this.form.data());
            })
                .catch(() => {})
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
