<template>
    <div>
        <div
            v-if="settings.legalese"
            v-theme="['form.accent', 'form.text']"
            class="overflow-auto max-h-xs pb-2 mb-4 py-2 px-4 border rounded-lg leading-normal text-sm opacity-75"
        >
            {{ settings.legalese }}
        </div>

        <decision-input
            v-if="settings.confirmation"
            v-model="internalValue"
            class="mb-6"
            :title="settings.confirmation"
        ></decision-input>
    </div>
</template>

<script>
import CustomQuestion from '@/mixins/CustomQuestion';

export default {
    name: 'FormTermsConditionsQuestion',

    mixins: [CustomQuestion],

    provide () {
        return {
            isMultipleChoice: true
        };
    },

    computed: {
        defaultValue () {
            return false;
        },

        namespace () {
            return 'termsConditions';
        }
    }
};
</script>
