/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-hold-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>hand</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.5 15l1.673-1.26v-.01c.69-.52 1.67-.38 2.19.31.01.02.03.04.04.06v0h0c.35.52.35 1.21-.01 1.74L9.74 18.3h-.01c-.51.75-1.23 1.34-2.08 1.68l-2.45.98M8.25 9.75l-3.6 1.54v-.01c-1.07.45-1.91 1.3-2.37 2.36L1.1 16.38v-.01c-.25.56-.37 1.16-.37 1.77v5.07"/><path pid="1" d="M8.25 19.755v1.24h0c0 1.24 1 2.24 2.25 2.24H21l-.01-.001c1.24 0 2.25-1.01 2.25-2.25v-18h0c0-1.25-1.01-2.25-2.25-2.25h-2.25v0c0 1.65-1.35 3-3 3-1.66 0-3-1.35-3-3h-2.26c-1.25 0-2.25 1-2.25 2.25 0 0 0 0 0 0v11.43M11.25 7.5h9M19.5 10.5v9.75"/><path pid="2" d="M14.25 15.75v-.001c1.24-.01 2.25 1 2.25 2.24a2.253 2.253 0 01-3.76 1.67"/></g>'
  }
})
