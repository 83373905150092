<template>
    <single-line-text-input
        ref="input"
        v-model="internalValue"
        :placeholder="$t('placeholder-enter-url')"
    ></single-line-text-input>
</template>

<script>
import CustomQuestion from '@/mixins/CustomQuestion';

export default {
    name: 'FormSocialProfileQuestion',

    mixins: [CustomQuestion],

    computed: {
        namespace () {
            return 'socialProfile';
        }
    },

    methods: {
        focus () {
            this.$refs.input.focus();
        }
    }
};
</script>
