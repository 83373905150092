/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email-send-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>paper airplane</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M2.75 15.629h0a1.68 1.68 0 01-1.67-1.67c0-.58.29-1.11.78-1.42L20.34.97h-.01c.77-.49 1.8-.26 2.29.52.22.35.3.78.22 1.19l-3.6 19.17v-.01c-.18.9-1.05 1.49-1.95 1.32-.41-.08-.78-.31-1.03-.64l-5.21-6.96z"/><path pid="1" d="M11.1 15.629H8.6v5.17h-.01c-.01.91.74 1.66 1.66 1.66.33 0 .66-.11.93-.29l3.17-2.17zM11.1 15.63L22.18 1.04"/></g>'
  }
})
