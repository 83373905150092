/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'technology-mobile-launch-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>rocket</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15 17.25v3c0 1.65-1.35 3-3 3H4.5l-.01-.001c-1.66-.01-3-1.35-3-3V3.74h0A2.996 2.996 0 014.48.73h7.5"/><path pid="1" d="M10.367 15.27c-1.07 1.06-3.205.6-3.205.6s-.47-2.14.6-3.2v-.01a1.568 1.568 0 012.43.15h0c.7.5.87 1.48.36 2.19-.07.08-.14.16-.21.23zM21.43 1.82L22.5.75M21.572 5.563l-.15-3.742-3.75-.15v-.01c-1.13.02-2.17.6-2.77 1.55l-4.07 6.73 2.43 2.436L19.99 8.3h-.01c.94-.61 1.52-1.65 1.55-2.77z"/><path pid="2" d="M12.55 7.14l-1.12-.53h0c-.58-.31-1.28-.22-1.76.2L8.09 8.38h-.01c-.11.11-.13.29-.06.43l-.01-.01c.07.16.21.29.38.35l2.41.77M16.14 10.67l.48 1.14h0c.3.57.22 1.27-.21 1.76l-1.58 1.57v-.01c-.13.1-.3.12-.44.05h0a.688.688 0 01-.36-.39l-.78-2.42M17.71 4.03a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM1.5 19.5H15"/></g>'
  }
})
