<template>
    <div class="flex flex-col px-4 py-8">
        <slot name="block-header"></slot>

        <div v-theme="['form.title-text', 'form.accent']" class="text-center text-2xl mt-8 mb-4 border-b pb-4 font-semibold">
            {{ block.pivot.settings.title }}
        </div>

        <div
            v-if="block.pivot.settings.description"
            v-theme="['form.text']"
            class="mb-2 break-words"
        >
            {{ block.pivot.settings.description }}
        </div>

        <secondary-event-option-selection
            v-if="isEditMode"
            v-model="editModeSelection"
            :options="activeProducts"
        ></secondary-event-option-selection>

        <form-field-wrapper
            v-for="{ id, formalName } in guests"
            :key="id"
            :error="errorBag.get(`payload.productSelections.${id}`)"
            :should-show-error="errorBag.has(`payload.productSelections.${id}`)"
        >
            <div v-theme="'form.title-text'" class="text-lg">
                {{ formalName }}
            </div>

            <secondary-event-option-selection v-model="selections[id]" :options="activeProducts"></secondary-event-option-selection>
        </form-field-wrapper>

        <div v-if="errorBag.has('payload.productSelections')" class="alert alert-error alert-sm mt-2">
            <p class="w-full font-normal text-center">{{ errorBag.get('payload.productSelections') }}</p>
        </div>

        <slot name="block-footer" :complete-block="completeBlock"></slot>
    </div>
</template>

<script>
import map from 'lodash/map';
import times from 'lodash/times';
import zipObject from 'lodash/zipObject';
import FormBlock from '@/mixins/FormBlock';
import BlockWithProducts from '@/mixins/BlockWithProducts';
import { getGuestsPassingCriteria } from '@/util/additional-criteria';
import { filter } from 'lodash';

export default {
    name: 'FormSecondaryEventBlock',

    mixins: [FormBlock, BlockWithProducts],

    behaviour: {
        render: {
            when (block, submission) {
                const { askIf, additionalCriteria } = block.pivot.settings;

                return getGuestsPassingCriteria(askIf, additionalCriteria, submission).length > 0;
            },
            unless (block, submission) {
                return false;
            }
        }
    },

    data () {
        return {
            editModeSelection: null,
            selections: {}
        };
    },

    computed: {
        activeProducts () {
            if (this.isEditMode) {
                return filter(this.products, { active: true });
            }

            return this.filterSoldOutProducts(this.products);
        },

        guests () {
            const { askIf, additionalCriteria } = this.block.pivot.settings;

            return getGuestsPassingCriteria(askIf, additionalCriteria, this.submission);
        },

        onCompletionListeners () {
            return {
                ticketing: () => { this.resetPreferences(); },
                rsvp: () => { this.resetPreferences(); }
            };
        }
    },

    methods: {
        resetPreferences () {
            this.selections = zipObject(
                map(this.guests, 'id'),
                times(this.guests.length, () => { return null; })
            );
        },

        serializePayload () {
            return {
                productSelections: { ...this.selections }
            };
        }
    }
};
</script>
