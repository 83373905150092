/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'umbrella-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>weather</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.64 6.711l4.04-4.04h-.01a1.5 1.5 0 012.12.05c.54.57.54 1.48-.01 2.06l-.53.53M2.25 21.092C-.375 18.467 11.328 1.4 11.328 1.4l-.01-.01c.65 2.6 2.69 4.64 5.29 5.3l-.01-.01c.65 2.6 2.69 4.64 5.29 5.3 0 0-17.06 11.7-19.68 9.08zM2.26 21.09l-1.5 1.5"/><path pid="1" d="M2.25 21.092v-.01a154.25 154.25 0 0111.86-15.56"/><path pid="2" d="M2.25 21.09h-.01C7.66 17.46 12.86 13.5 17.8 9.22"/></g>'
  }
})
