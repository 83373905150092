<template>
    <base-dropdown
        ref="dropdown"
        class="inline-block"
        placement="bottom-start"
        :position-fixed="false"
        trigger-class="flex items-center"
    >
        <template slot="trigger" slot-scope="{ triggerFunction, isOpen }">
            <button
                class="flex items-center justify-center text-sm text-snow-800 truncate mr-6"
                @click="triggerFunction"
            >
                <span>{{ selectedSize.label }}</span>

                <app-icon
                    :name="isOpen ? 'arrow-up-chevron' : 'arrow-down-chevron'"
                    class="ml-2 h-2 w-2 text-snow-800"
                    stroke
                ></app-icon>
            </button>
        </template>

        <template #default="{ triggerFunction }">
            <div v-bar="{ useScrollbarPseudo: true }" class="bg-black border-b-2 border-purple-light rounded-xl py-2 px-1 w-48 -ml-2 mt-2">
                <div>
                    <div
                        v-for="size in sizes"
                        :key="size.value"
                        class="w-full px-2 py-1 text-white hover:bg-smoke-300 rounded-xl text-cursor text-sm"
                        @click="setSize(size, triggerFunction)"
                    >
                        {{ size.label }}
                    </div>
                </div>
            </div>
        </template>
    </base-dropdown>
</template>

<script>
import find from 'lodash/find';

const defaultSize = { label: 'Normal', value: '16px' };

export default {
    name: 'EditorTextSizePicker',

    props: {
        editor: {
            type: Object,
            required: true
        },

        isMenuActive: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            selectedSize: defaultSize,
            sizes: [
                { label: 'Small', value: '10px' },
                { label: 'Normal', value: '16px' },
                { label: 'Medium', value: '20px' },
                { label: 'Large', value: '28px' },
                { label: 'Extra Large', value: '32px' },
                { label: 'Huge', value: '40px' }
            ]
        };
    },

    watch: {
        isMenuActive (newVal) {
            if (newVal) {
                const { textSize } = this.editor.getMarkAttrs('textSize');

                if (textSize) {
                    this.selectedSize = find(this.sizes, { value: textSize });
                } else {
                    this.selectedSize = defaultSize;
                }
            }
        }
    },

    methods: {
        setSize (size, toggleDropdown) {
            this.editor.commands.textSize({ textSize: size.value });
            this.selectedSize = size;
            toggleDropdown();
        }
    }
};
</script>
