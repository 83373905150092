<template>
    <div class="flex flex-col grow">
        <div class="flex flex-wrap">
            <form-field-wrapper label="Section Icon">
                <icon-picker
                    v-model="form.icon"
                    class="text-gray-800 w-11 h-11"
                ></icon-picker>
            </form-field-wrapper>

            <form-field-wrapper
                label="Section Title"
                :error="form.errors.get('title')"
                :should-show-error="form.errors.has('title')"
            >
                <input
                    v-model="form.title"
                    class="form-field"
                >
            </form-field-wrapper>

            <form-field-wrapper>
                <toggle-switch v-model="form.displayTitle">
                    Display Section Icon &amp; Title
                </toggle-switch>

                <toggle-switch
                    v-model="form.displayInMenu"
                    class="mt-4"
                >
                    Show Section In Menu
                </toggle-switch>
            </form-field-wrapper>

            <form-field-wrapper label="Stores">
                <a
                    class="button-text button-info"
                    role="button"
                    @click="createItem"
                >
                    <app-icon
                        name="add-circle"
                        class="h-5 w-5 mr-2"
                        stroke
                    ></app-icon>
                    <span>Add a Store</span>
                </a>

                <draggable-list
                    v-model="form.stores"
                    :editable="true"
                    unique-property="id"
                    label-property="name"
                    switchable-property="active"
                    @edit-item="editItem"
                    @delete-item="deleteItem"
                ></draggable-list>
            </form-field-wrapper>

            <feature-enabled feature="wall-donations">
                <form-panel v-model="form.acceptingCashGifts" label="Accept Cash Gifts">
                    <form-field-wrapper
                        label="Button Text"
                        :error="form.errors.get('rawButtonText')"
                        :should-show-error="form.errors.has('rawButtonText')"
                    >
                        <input
                            id="button-text"
                            v-model="form.rawButtonText"
                            class="form-field"
                        >

                        <template slot="help">
                            <div>Hint: You can use <strong class="font-semibold">#AMOUNT#</strong> to show the currently selected gift amount as the button text.</div>
                        </template>
                    </form-field-wrapper>
                </form-panel>
            </feature-enabled>
        </div>
    </div>
</template>

<script>
import BlockableGeneralSettingsMixin from '@/mixins/BlockableGeneralSettingsMixin';

export default {
    mixins: [BlockableGeneralSettingsMixin],

    data () {
        return {
            itemsProperty: 'stores'
        };
    }
};
</script>
