/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-christmas-santa-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>winter saint nick hoho</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9 21.48c1.24 1.89 4.948 2.58 6.7.47v-.01c.05-.07.05-.17-.01-.24l-.01-.01c-.06-.07-.15-.1-.23-.06h-.01c-.25.1-.52.16-.78.16h-.01a2.29 2.29 0 01-2.31-1.93h0a1.965 1.965 0 00-2-1.93 1.9 1.9 0 00-1.4.62h0c-.74-.8-1.98-.84-2.77-.1-.39.36-.62.86-.63 1.39v-.01a2.305 2.305 0 01-2.31 1.92h-.01c-.27 0-.54-.06-.78-.17h0c-.09-.04-.18-.02-.23.05v-.01c-.06.06-.06.16 0 .23 1.75 2.11 5.45 1.42 6.7-.48zM15.74 9l-.66-1.44-.01-.01c-.33-.72-.01-1.56.7-1.89.25-.12.54-.16.82-.11l4.25.7c-1.38-1.18-5.7-7.02-12.232-5.16-3.7 1.05-5.4 4.02-6.18 7.87"/><path pid="1" d="M21.75 6a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/><rect pid="2" width="16.5" height="3" x=".75" y="9" rx="1.5"/><path pid="3" d="M6.375 14.25h-.01c.2-.01.37.16.37.37"/><path pid="4" d="M6 14.625h0c-.01-.21.16-.38.37-.38M6.375 15h-.01a.386.386 0 01-.38-.38s0 0 0 0M6.75 14.625v0c0 .2-.17.375-.375.375M11.625 14.25h-.01c.2-.01.37.16.37.37"/><path pid="5" d="M11.25 14.625h0c-.01-.21.16-.38.37-.38M11.625 15h-.01a.386.386 0 01-.38-.38s0 0 0 0M12 14.625v0c0 .2-.17.375-.375.375"/></g>'
  }
})
