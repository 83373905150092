/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alert-diamond-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>exclamation warning notification</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 16.5h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.38-.38-.375v0"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none"><path pid="1" d="M12 13.5V6"/><path pid="2" stroke-linejoin="round" d="M1.28 13.28l-.01-.01c-.71-.71-.71-1.86 0-2.57l9.44-9.44v-.01c.7-.71 1.84-.71 2.55-.01 0 0 0 0 0 0l9.44 9.439h0c.7.7.7 1.85-.01 2.56l-9.44 9.43h-.01c-.71.7-1.86.7-2.56-.01z"/></g>'
  }
})
