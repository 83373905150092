import Vue from 'vue';
import { DateTime } from 'luxon';

/**
 * Formats a UTC timestamp of a timeslot to a human-readable string such as
 * February 1, 2021 at 9:00 AM. Can convert the UTC timestamp to a zone
 * of choice.
 *
 * @param {string} value
 * @param {string} zone
 */
const timeslotDateFilter = (value, zone = 'UTC') => {
    if (!value) {
        return '';
    }

    const formatted = DateTime.fromFormat(value, 'yyyy-LL-dd HH:mm:ss', { zone: 'UTC' })
        .setZone(zone)
        .toFormat(`LLLL d, yyyy 'at' h:mm a`);

    if (formatted !== 'Invalid DateTime') {
        return formatted;
    }

    return DateTime.fromISO(value, { zone: 'UTC' })
        .setZone(zone)
        .toFormat(`LLLL d, yyyy 'at' h:mm a`);
};
Vue.filter('timeslotDate', timeslotDateFilter);

export default timeslotDateFilter;
