<template>
    <div class="space-y-12">
        <stripe-connect :event="event"></stripe-connect>

        <section class="panel flex flex-col">
            <page-header icon="credit-card-stroke" label="Payments & Coupons">
                <button class="button-icon button-sm" @click="closePage">
                    <app-icon name="close"></app-icon>
                </button>
            </page-header>

            <item-modified-warning v-if="form.isModified()"></item-modified-warning>

            <form-field-wrapper label="currency">
                <div v-if="livePaymentCount === 0" class="w-full sm:w-2/3 md:w-1/3">
                    <simple-picker
                        v-model="form.paymentCurrency"
                        :items="currencies"
                        :is-filterable="true"
                        :filter-function="currencyFilterFunction"
                        item-value="id"
                        placeholder-empty-state="Select a currency ..."
                    >
                        <div slot="input" slot-scope="{ selected }">
                            {{ selected.name }} ({{ selected.currency_code }})
                        </div>
                        <div slot="item" slot-scope="{ item }">
                            {{ item.name }} ({{ item.currency_code }})
                        </div>
                    </simple-picker>
                </div>
                <div v-else>
                    <p class="font-semibold">{{ selectedPaymentCurrency.name }}</p>
                    <p class="mt-1 text-sm text-gray-600 ">You’ve already accepted a live payment for this event. The event’s currency cannot be changed once a live payment has been processed.</p>
                </div>
            </form-field-wrapper>

            <form-panel
                v-model="form.salesTax.enabled"
                label="Sales Tax"
                tooltip="Add an additional fixed or percentage-based fee to orders."
                class="mt-8"
            >
                <select-list v-model="form.salesTax.type" @input="form.salesTax.amount=''">
                    <select-option
                        id="percentage"
                        class="my-2"
                        label="Percent of Total Order"
                        tooltip="Applied to total order value NOT including gifts and donations."
                    ></select-option>

                    <select-option
                        id="fixed"
                        class="my-2"
                        label="Fixed Additional Fee"
                    ></select-option>
                </select-list>

                <div v-if="form.errors.has('salesTax.type')" class="alert alert-error alert-sm mt-2 w-1/2">
                    <span class="w-full font-normal text-center">
                        {{ form.errors.get('salesTax.type') }}
                    </span>
                </div>

                <div class="relative w-28 mt-4">
                    <money-field v-if="form.salesTax.type === 'fixed'" v-model.number="form.salesTax.amount"></money-field>

                    <div v-else>
                        <input
                            v-model.number="form.salesTax.amount"
                            class="form-field"
                            type="number"
                        >

                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-500">
                            <span>%</span>
                        </div>
                    </div>

                </div>

                <div v-if="form.errors.has('salesTax.amount')" class="alert alert-error alert-sm mt-2 w-1/2">
                    <p class="w-full font-normal text-center">
                        {{ form.errors.get('salesTax.amount') }}
                    </p>
                </div>

                <form-field-wrapper
                    label="Label on customer receipt"
                    class="mt-8 sm:w-2/3 md:w-1/3"
                    :should-show-error="form.errors.has('salesTax.label')"
                    :error="form.errors.get('salesTax.label')"
                >
                    <input
                        v-model="form.salesTax.label"
                        class="form-field"
                        placeholder="Please enter the label..."
                    >
                </form-field-wrapper>
            </form-panel>

            <form-panel
                :value="form.feePassthrough.enabled"
                label="Fee Pass Through"
                tooltip="Pass along credit card processing and service fees to customers. This fee will be charged in addition to any tax or service fees you may have added."
                class="mt-8"
                @input="toggleFeePassthrough"
            >
                <form-field-wrapper
                    label="Label on customer receipt"
                    class="sm:w-2/3 md:w-1/3"
                    :error="form.errors.get('feePassthrough.label')"
                >
                    <input
                        v-model="form.feePassthrough.label"
                        v-validate="'required'"
                        class="form-field"
                        name="fee-passthrough-label"
                        placeholder="Please enter the label..."
                    >
                </form-field-wrapper>

                <div class="alert alert-info mt-4 mb-0 md:items-center">
                    <app-icon
                        name="info-circle"
                        class="shrink-0 w-12 h-12 md:w-6 md:h-6 mr-4"
                        stroke
                    ></app-icon>
                    Credit card processing fees may vary. Transactions made with credit cards issued in a foreign currency may incur additional fees not passed to purchaser. Rates are subject to the terms of your payment processing partner.
                </div>
            </form-panel>

            <div class="flex grow justify-end mt-4">
                <button
                    v-if="form.isModified()"
                    class="button mr-4"
                    @click="form.restore()"
                >
                    Cancel
                </button>

                <stateful-button
                    :loading="form.processing"
                    :disabled="!form.isModified()"
                    class="button button-primary"
                    @click="saveSettings"
                >
                    Save
                </stateful-button>
            </div>
        </section>

        <section class="panel flex flex-col">
            <coupons
                :initial-coupons="coupons"
                :currency="selectedPaymentCurrency.currency_code"
                :event="event"
            ></coupons>
        </section>
    </div>
</template>

<script>
import Form from '@/validation/Form';

export default {
    name: 'PaymentsAndCoupons',

    props: {
        coupons: {
            type: Array,
            required: true
        },
        currencies: {
            type: Array,
            required: true
        },
        event: {
            type: Object,
            required: true
        },
        livePaymentCount: {
            type: Number,
            required: false,
            default: 0
        }
    },

    data () {
        return {
            form: new Form({
                paymentCurrency: this.event.payment_currency.id,
                salesTax: {
                    enabled: !!this.event.sales_tax,
                    label: this.event.sales_tax
                        ? this.event.sales_tax.label
                        : 'Tax',
                    ...this.event.sales_tax
                },
                feePassthrough: this.event.settings.feePassthrough || {
                    enabled: false,
                    label: 'Service Fee'
                }
            })
        };
    },

    computed: {
        selectedPaymentCurrency () {
            return this.currencies.find((currency) => {
                return currency.id === this.form.paymentCurrency;
            });
        },

        submitFields () {
            const submitFields = Object.keys(this.form);

            if (!this.form.salesTax.enabled) {
                submitFields.splice(submitFields.indexOf('salesTax'), 1);
            }

            return submitFields;
        }
    },

    methods: {
        closePage () {
            const backToSettingsIndexPage = () => {
                window.location.href = this.route('settings.event', this.event);
            };

            if (this.form.isModified()) {
                const attributes = {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                };

                App.alert().confirm(
                    'Are you sure?',
                    'Are you sure you want to discard all your changes and go back to settings page?',
                    attributes,
                    backToSettingsIndexPage
                );
            } else {
                backToSettingsIndexPage();
            }
        },

        currencyFilterFunction (searchTerm, items) {
            return items.filter((item) => {
                const searchRegex = new RegExp(searchTerm, 'i');
                return searchRegex.test(`${item.name} ${item.currency_code}`);
            });
        },

        saveSettings () {
            this.form.post(this.route('api.events.payments-settings', this.event), this.submitFields)
                .then(() => {
                    this.$toasted.global.success('Payments settings saved.');
                    window.location.reload();
                });
        },

        toggleFeePassthrough (newValue) {
            const toggleValue = () => {
                this.form.feePassthrough.enabled = newValue;
            };

            const attributes = {
                html: `<p>In <a class='font-semibold text-purple hover:text-purple-light no-underline' href='https://support.stripe.com/questions/what-is-the-scope-of-the-surcharge-ban-under-psd2' target='_blank'> some global territories</a>, directly passing through credit card and service fees to your customer may be restricted or prohibited by law. Consult a legal professional in your territory.</p><p class="mt-4">Credit card processing fees may vary. Transactions made with credit cards issued in a foreign currency may incur additional fees not passed to purchaser. Rates are subject to the terms of your payment processing partner.</p>`,
                confirmButtonText: 'Enable Fee Pass-through',
                cancelButtonText: 'Cancel'
            };

            if (newValue) {
                App.alert().confirm(
                    'Are you sure?',
                    null,
                    attributes,
                    toggleValue
                );
            } else {
                toggleValue();
            }
        }
    }
};
</script>
