<template>
    <div class="max-w-lg space-y-2 leading-2">
        <div v-if="ticketsRemaining > 0" class="bg-green-light rounded p-2 mb-4 text-lg text-green-dark inline-block">
            <div class="flex items-center">
                <app-icon class="w-6 h-6 mr-4 flex-none" name="ticket-classic-stroke"></app-icon>
                <p><span class="font-semibold">{{ ticketsRemaining | number }}</span> tickets still available</p>
            </div>
        </div>
        <div v-if="ticketsRemaining === 0" class="bg-yellow-light rounded p-2 mb-4 text-lg text-yellow-dark inline-block">
            <div class="flex items-center">
                <app-icon class="w-6 h-6 mr-4 flex-none" name="ticket-classic-stroke"></app-icon>
                <p>Sorry, there are no remaining tickets for this event. You can choose to continue to wait in case any tickets do become available.</p>
            </div>
        </div>
        <p class="text-xl font-bold text-gray-800">We're experiencing heavy traffic right now. You've been placed in queue.</p>
        <p class="text-lg font-normal text-gray-600">This page will refresh automatically when it's your turn. Thank you for your patience.</p>
        <p class="text-lg font-normal text-gray-600">To hold your place in line, please do not refresh this page.</p>
        <p class="text-gray-400 ">Estimated wait time:
            <span v-if="estimatedWaitInMinutes" class="animate-pulse">{{ estimatedWaitInMinutes }} minute(s).</span>
            <span v-else class="animate-pulse">calculating...</span>
        </p>
    </div>
</template>

<script>
import { random } from 'lodash';
import axios from '@/util/axios';

export default {
    props: {
        event: {
            type: Object,
            required: true
        },

        redirectUrl: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            fetching: false,
            estimatedWaitInMinutes: null,
            ticketsRemaining: null,
            securityToken: new URLSearchParams(window.location.search).get('securityToken')
        };
    },

    mounted () {
        setTimeout(() => {
            this.getStatus();
        }, random(10, 30) * 1000);

        // We set a random time offset in seconds to force API requests to be offset from
        // each other when an event sees a massive spike at once.
        // This helps prevent waiting room API requests from all coming in
        // around the same time.
        setInterval(() => {
            this.getStatus();
        }, random(180, 300) * 1000);
    },

    methods: {
        getStatus () {
            if (this.fetching) {
                return;
            }

            this.fetching = true;

            axios.get(this.route('api.waiting-rooms.status', {
                event: this.event.id,
                securityToken: this.securityToken
            })).then(({ data }) => {
                if (data.authorized) {
                    window.location.href = this.redirectUrl;
                }

                this.estimatedWaitInMinutes = data.estimatedWaitInMinutes;
                this.ticketsRemaining = data.ticketsRemaining;
            }).finally(() => {
                this.fetching = false;
            });
        }
    }
};
</script>
