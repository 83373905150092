/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tags-minus': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>delete remove</desc><g _fill="currentColor"><circle pid="0" cx="17.171" cy="5.999" r="1.5"/><path pid="1" d="M9.9 21.191a.25.25 0 00-.32-.114 1.029 1.029 0 01-1.118-.2l-6.17-6.171a1 1 0 010-1.414l11-11A1 1 0 0114 2h6.671a.5.5 0 01.5.5v6.671a.993.993 0 01-.293.707l-.166.166a.251.251 0 00.059.4 8.023 8.023 0 011.229.813.25.25 0 00.342-.027 2.926 2.926 0 00.825-2.059V2a2 2 0 00-2-2H14a2.982 2.982 0 00-2.122.879l-11 11a3 3 0 000 4.242l6.172 6.171a3 3 0 003.57.5.251.251 0 00.077-.373 7.956 7.956 0 01-.797-1.228z"/><path pid="2" d="M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-5a.75.75 0 110-1.5h5a.75.75 0 010 1.5z"/></g>'
  }
})
