<template>
    <div class="flex-1 p-8">
        <div class="panel flex flex-col">
            <page-header icon="app-page-account-stroke" label="Manage Plan Pages">
                <a :href="route('admin.dashboard')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <data-table
                :columns="columns"
                :rows="filteredPlanSegments"
                no-results-placeholder="No plan pages match your criteria."
            >
                <template #header>
                    <search-field v-model="searchQuery"></search-field>
                </template>

                <template #row="{ property, row }">
                    <div v-if="property === 'actions'" class="flex items-center justify-end">
                        <base-dropdown placement="bottom-end">
                            <template slot="trigger" slot-scope="{ triggerFunction }">
                                <button @click="triggerFunction">
                                    <app-icon
                                        name="navigation-menu-horizontal"
                                        class="h-6 w-6"
                                        stroke
                                    ></app-icon>
                                </button>
                            </template>

                            <div class="w-40 bg-white rounded-md border shadow text-sm overflow-hidden">
                                <a :href="route('admin.plans.segments.edit', row)" class="px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-purple hover:bg-gray-100">
                                    <app-icon
                                        name="edit"
                                        class="h-4 w-4 mr-2"
                                        stroke
                                    ></app-icon> Edit Plan Page
                                </a>
                            </div>
                        </base-dropdown>
                    </div>
                </template>
            </data-table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        planSegments: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            searchQuery: ''
        };
    },

    computed: {
        columns () {
            return [
                {
                    label: 'Plan Page',
                    property: 'label'
                },
                {
                    label: '',
                    property: 'actions'
                }
            ];
        },

        filteredPlanSegments () {
            const regex = new RegExp(this.searchQuery, 'i');

            return this.planSegments.filter(({ label }) => {
                return label.match(regex);
            });
        }
    }
};
</script>
