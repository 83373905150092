<template>
    <div class="flex flex-col grow">
        <div class="flex flex-wrap">
            <form-field-wrapper label="Location Name">
                <input
                    v-model="location.name"
                    class="form-field"
                >
            </form-field-wrapper>

            <form-field-wrapper label="Address">
                <location-picker v-model="location.address.address"></location-picker>
            </form-field-wrapper>

            <portal to="app-drawer-footer">
                <footer-save-cancel
                    @cancel="$emit('cancel')"
                    @save="$emit('save-settings')"
                ></footer-save-cancel>
            </portal>
        </div>
    </div></template>

<script>
export default {
    props: {
        location: {
            type: Object,
            default: () => { return {}; }
        }
    }
};
</script>
