<template>
    <section class="panel">
        <page-header icon="location-pin-map-stroke" label="Event Location"></page-header>

        <item-modified-warning v-if="isLocationModified"></item-modified-warning>

        <form-field-wrapper>
            <toggle-switch :value="!isLocationEnabled" @input="toggleLocation">Undecided</toggle-switch>
        </form-field-wrapper>

        <div v-if="isLocationEnabled" class="mt-4 flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
            <div class="flex-1">
                <form-field-wrapper>
                    <location-type-picker v-if="isLocationEnabled" v-model="newLocation.type"></location-type-picker>
                </form-field-wrapper>

                <form-field-wrapper v-if="newLocation.type !== 'virtual'">
                    <google-autocomplete-input
                        v-model="venueWithAddress"
                        name="address"
                        placeholder="Venue, address, or virtual URL"
                        class="form-field mt-4"
                    >
                    </google-autocomplete-input>
                </form-field-wrapper>

                <form-field-wrapper v-if="newLocation.type !== 'in-person'">
                    <input
                        v-model="newLocation.virtual_url"
                        class="form-field"
                        placeholder="Paste virtual meeting link"
                        type="text"
                    >
                </form-field-wrapper>

                <form-field-wrapper v-if="newLocation.type !== 'virtual'" label="Current Address">
                    <span v-html="formattedAddress"></span>
                </form-field-wrapper>
            </div>

            <div class="flex-1">
                <location-map
                    v-if="newLocation.type !== 'virtual' && newLocation.address"
                    :address="newLocation.address"
                    class="h-80"
                ></location-map>
            </div>
        </div>

        <div class="flex mt-8 md:justify-end">
            <button
                v-if="isLocationModified"
                class="button flex-1 md:flex-none mr-4"
                @click="restoreLocation"
            >
                Cancel
            </button>

            <stateful-button
                class="button-primary flex-1 md:flex-none"
                :disabled="!isLocationModified"
                @click="saveLocation"
            >
                Save
            </stateful-button>
        </div>
    </section>
</template>

<script>
import isEqual from 'lodash/isEqual';
import { sync } from 'vuex-pathify';
import axios from '@/util/axios';

export default {
    name: 'LocationSettings',

    data () {
        return {
            newLocation: null
        };
    },

    computed: {
        ...sync('Event/*'),

        isLocationEnabled () {
            return this.newLocation !== null;
        },

        formattedAddress () {
            return this.newLocation.address
                ? this.newLocation.address.replace(/,/g, '<br>')
                : '';
        },

        isLocationModified () {
            return !isEqual(this.event.location, this.newLocation);
        },

        venueName () {
            return this.newLocation.venue;
        },

        venueWithAddress: {
            get () {
                return {
                    address: this.newLocation.address,
                    venue: this.newLocation.venue_name
                };
            },

            set ({ address, venue }) {
                this.newLocation.address = address;
                this.newLocation.venue_name = venue;
            }
        }
    },

    created () {
        this.restoreSavedLocation();
    },

    methods: {
        restoreSavedLocation () {
            this.newLocation = this.event.location ? { ...this.event.location } : null;
        },

        restoreLocation () {
            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            const onConfirm = () => {
                this.restoreSavedLocation();
            };

            App.alert().confirm(
                'Are you sure?',
                'Are you sure you want to discard all your changes?',
                attributes,
                onConfirm
            );
        },

        async saveLocation () {
            try {
                this.saving = true;

                await axios.patch(this.route('api.events.update', this.event), {
                    location: this.newLocation
                });

                this.$toasted.global.success({
                    message: 'Event location has been updated.',
                    onComplete: () => {
                        window.location.reload();
                    }
                });
            } catch (error) {
                this.$toasted.global.error(error.response.data.message);
            } finally {
                this.saving = false;
            }
        },

        toggleLocation (disabled) {
            this.newLocation = disabled ? null : {
                type: 'in-person',
                address: '',
                venue_name: '',
                virtual_url: null
            };
        }
    }
};
</script>
