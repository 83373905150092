/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-symptoms-virus-loss-smell-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.88 3.38a3.5 3.5 0 100 7.01 3.5 3.5 0 100-7.02zM6.3.75h1.17M6.89.75v2.63M10.81 2.13l.83.83M11.23 2.55L9.37 4.41M13.02 6.3v1.17M13.02 6.89h-2.63M11.64 10.81l-.83.83M11.23 11.23L9.37 9.37M7.47 13.02H6.3M6.89 13.02v-2.63M2.96 11.64l-.83-.83M2.55 11.23l1.86-1.86M.75 7.47V6.3M.75 6.89h2.63M2.13 2.96l.83-.83M2.55 2.55l1.86 1.86M22.46.75s-2.82 5.83-5.48 7.821c-3.128 2.346-1.564 5.474.782 5.474h5.47M19.578 10.977c.76-1.03 1.53-1.03 3.06-1.03M14.48 17.625v-.01c.4-.47.74-.98 1-1.54M10.37 20.182v-.01c.71-.19 1.4-.48 2.04-.85M20.184 21.205h-.01c-.05.7.09 1.41.41 2.04M21.623 17.11c-.35.68-.69 1.36-.95 2.04M15.487 22.227v-.01c-.55.56-1.27.92-2.05 1.02M17.532 19.15h-.01c-.2.53-.46 1.04-.76 1.53"/></g>'
  }
})
