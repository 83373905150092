<template>
    <div class="flex flex-col space-y-2">
        <button
            v-for="button in options"
            :key="button.id"
            class="w-full px-4 py-3 border rounded text-sm text-center font-semibold"
            :class="{
                'bg-gray-50 border hover:bg-blue-light hover:border-blue': button.id !== value,
                'bg-blue-light border-blue': button.id === value
            }"
            type="button"
            @click="$emit('input', button.id)"
        >
            {{ button.label }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'ButtonPicker',

    props: {
        options: {
            type: Array,
            default: () => { return []; }
        },
        value: {
            type: [Number, String],
            default: null
        }
    }
};
</script>
