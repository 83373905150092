/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'design-tool-layout': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M24 1.98A1.982 1.982 0 0022.02 0H1.98A1.982 1.982 0 000 1.98v20.04A1.982 1.982 0 001.98 24h20.04A1.982 1.982 0 0024 22.02zM22 21.5a.5.5 0 01-.5.5l-19 .02a.5.5 0 01-.5-.5L1.98 2.5a.5.5 0 01.5-.5l19.02-.02a.5.5 0 01.5.5z"/><path pid="1" d="M4.5 16.5h11a1 1 0 001-1v-11a1 1 0 00-1-1h-11a1 1 0 00-1 1v11a1 1 0 001 1zm.833-3.343l1.584-3.083a.749.749 0 011.164-.219L9.3 10.936a.252.252 0 00.386-.069l1.735-3.222a.75.75 0 011.375.127l1.75 5.5a.75.75 0 01-.715.978H6a.75.75 0 01-.667-1.093z"/><rect pid="2" x="13" y="18" width="3" height="2.5" rx=".833" ry=".833"/><rect pid="3" x="17.5" y="18" width="3" height="2.5" rx=".833" ry=".833"/><path pid="4" d="M4.25 20h4.5a.75.75 0 000-1.5h-4.5a.75.75 0 000 1.5zM18.75 5h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM18.75 8.5h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM18.75 12h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5z"/></g>'
  }
})
