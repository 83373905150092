<template>
    <app-drawer
        :value="value"
        :outside-click-handler="closeWithoutSaving"
        :default-close-handler="closeWithoutSaving"
        :ignore-outside-click="isClosing"
        @input="$emit('input', $event)"
    >
        <template slot="title-bar">
            <app-icon
                name="color-brush"
                class="h-6 w-6 text-gray-600"
                stroke
            ></app-icon>
            <div class="text-gray-900 font-bold text-lg">Website Theme</div>
        </template>

        <template #overlay="{ clickOutsideOfDrawer }">
            <transition
                enter-class="opacity-0"
                enter-active-class="ease-out duration-300"
                enter-to-class="opacity-100"
                leave-class="opacity-100"
                leave-active-class="ease-in duration-200"
                leave-to-class="opacity-0"
                appear
            >
                <div
                    class="fixed w-screen h-screen z-90"
                    @click="clickOutsideOfDrawer"
                ></div>
            </transition>
        </template>

        <div>
            <form-field-wrapper label="Colors">
                <div class="flex justify-around">
                    <div class="w-1/5 flex flex-col items-center">
                        <color-picker v-model="accentColor" :has-alpha="false"></color-picker>
                        <div class="text-gray-500 mt-4 text-sm">Accent</div>
                    </div>
                    <div class="w-1/5 flex flex-col items-center">
                        <color-picker v-model="titleFontColor" :has-alpha="false"></color-picker>
                        <div class="text-gray-500 mt-4 text-sm">Title</div>
                    </div>
                    <div class="w-1/5 flex flex-col items-center">
                        <color-picker v-model="bodyFontColor" :has-alpha="false"></color-picker>
                        <div class="text-gray-500 mt-4 text-sm">Text</div>
                    </div>
                    <div class="w-1/5 flex flex-col items-center">
                        <color-picker v-model="backgroundColor"></color-picker>
                        <div class="text-gray-500 mt-4 text-sm">Background</div>
                    </div>
                    <div class="w-1/5 flex flex-col items-center text-center">
                        <color-picker v-model="buttonTextColor" :has-alpha="false"></color-picker>
                        <div class="text-gray-500 mt-4 text-sm">Button Text</div>
                    </div>
                </div>
            </form-field-wrapper>

            <form-field-wrapper label="Title Font">
                <font-picker v-model="titleFont"></font-picker>
            </form-field-wrapper>

            <form-field-wrapper label="Title Size">
                <font-size-picker v-model="titleFontSize"></font-size-picker>
            </form-field-wrapper>

            <form-field-wrapper label="Body Font">
                <font-picker v-model="bodyFont"></font-picker>
            </form-field-wrapper>

            <form-field-wrapper label="Body Size">
                <font-size-picker v-model="bodyFontSize"></font-size-picker>
            </form-field-wrapper>

            <form-field-wrapper label="Custom CSS">
                <theme-drawer-css-editor v-model="customCss"></theme-drawer-css-editor>
            </form-field-wrapper>
        </div>

        <portal to="app-drawer-footer">
            <footer-save-cancel
                confirm-button-text="Save"
                :processing="saving"
                @cancel="closeWithoutSaving"
                @save="save"
            ></footer-save-cancel>
        </portal>
    </app-drawer>
</template>

<script>
import { sync } from 'vuex-pathify';
import {
    isEmpty, delay, isEqual, cloneDeep
} from 'lodash';
import axios from '@/util/axios';

export default {
    name: 'WallThemeDrawer',

    props: {
        value: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            originalSettings: null,
            isClosing: false,
            saving: false
        };
    },

    computed: {
        ...sync('Wall/wall@settings.theme.colors', {
            accentColor: 'accent',
            titleFontColor: 'title',
            bodyFontColor: 'body',
            backgroundColor: 'background',
            buttonTextColor: 'buttonText'
        }),

        ...sync('Wall/wall@settings.theme.fonts', {
            titleFont: 'title',
            bodyFont: 'body'
        }),

        ...sync('Wall/wall@settings.theme.fontSizes', {
            titleFontSize: 'title',
            bodyFontSize: 'body'
        }),

        customCss: sync('Wall/wall@settings.theme.customCss'),

        currentSettings () {
            return {
                accentColor: this.accentColor,
                titleFontColor: this.titleFontColor,
                titleFont: this.titleFont,
                titleFontSize: this.titleFontSize,
                bodyFontColor: this.bodyFontColor,
                bodyFont: this.bodyFont,
                bodyFontSize: this.bodyFontSize,
                customCss: this.customCss,
                backgroundColor: this.backgroundColor,
                buttonTextColor: this.buttonTextColor
            };
        },

        isUnmodified () {
            return isEqual(this.currentSettings, this.originalSettings);
        }
    },

    watch: {
        currentSettings () {
            this.isClosing = false;
        }
    },

    mounted () {
        this.customCss = isEmpty(this.customCss) ? '' : this.customCss;
        this.originalSettings = cloneDeep(this.currentSettings);
    },

    methods: {
        cancel () {
            this.emitUpdated();
        },

        closeWithoutSaving () {
            this.isClosing = true;

            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            const onConfirm = () => {
                this.restoreOriginalSettings();
                this.close();
            };

            const onCancel = () => {
                delay(() => {
                    this.isClosing = false;
                }, 200);
            };

            if (this.isUnmodified) {
                onConfirm();
            } else {
                App.alert().confirm(
                    'Are you sure?',
                    `The changes you've made haven't been saved. Are you sure you want to leave without saving your changes?`,
                    attributes,
                    onConfirm,
                    onCancel
                );
            }
        },

        emitUpdated () {
            this.$emit('update-settings');
        },

        restoreOriginalSettings () {
            this.accentColor = this.originalSettings.accentColor;
            this.titleFontColor = this.originalSettings.titleFontColor;
            this.titleFont = this.originalSettings.titleFont;
            this.titleFontSize = this.originalSettings.titleFontSize;
            this.bodyFontColor = this.originalSettings.bodyFontColor;
            this.bodyFont = this.originalSettings.bodyFont;
            this.bodyFontSize = this.originalSettings.bodyFontSize;
            this.customCss = this.originalSettings.customCss;
            this.backgroundColor = this.originalSettings.backgroundColor;
            this.buttonTextColor = this.originalSettings.buttonTextColor;
        },

        save () {
            this.saving = true;

            const wall = this.$store.get('Wall.wall');

            const route = this.route('api.walls.settings.update', {
                wall: wall.id
            });

            axios.patch(route, wall).then(() => {
                Object.assign(this.originalSettings, this.currentSettings);
                this.emitUpdated();

                this.$store.commit('Wall/touch');

                this.$toasted.global.success('Website theme has been updated.');
            }).catch(() => {
                this.$toasted.global.error('There was a problem saving your website theme.');
            }).finally(() => {
                this.saving = false;
            });
        },

        close () {
            this.$emit('input', false);
        }
    }
};
</script>
