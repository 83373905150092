import Vue from 'vue';
import { DateTime } from 'luxon';

/**
 * Formats the date time.
 *
 * @param {string} value A date time value in ISO 8601 format.
 * @param {string} format The format to print the date time in.  See https://moment.github.io/luxon/docs/manual/formatting.html.
 * @param {boolean} inUtc Will output the formatted date time in UTC.
 */
const dateFilter = (value, format = 'DATETIME_FULL', inUtc = false, timezone = false) => {
    if (!value) {
        return '';
    }

    if (inUtc) {
        return DateTime.fromISO(value, { zone: 'utc' }).setLocale('en').toUTC().toLocaleString(DateTime[format]);
    }

    if (timezone) {
        return DateTime.fromISO(value, { zone: 'utc' }).setLocale('en').setZone(timezone).toLocaleString(DateTime[format]);
    }

    return DateTime.fromISO(value, { zone: 'utc' }).setLocale('en').toLocaleString(DateTime[format]);
};

Vue.filter('datetime', dateFilter);

export default dateFilter;
