/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email-forward-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>envelope arrow right</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.24 11.25a6 6 0 100 12 6 6 0 100-12zM14.25 17.25h6M20.25 17.25L18 19.5M20.25 17.25L18 15"/><path pid="1" d="M8.24 15.75H2.23a1.51 1.51 0 01-1.5-1.5V2.249h0A1.5 1.5 0 012.22.73h18-.01c.82-.01 1.5.67 1.5 1.5v6.74"/><path pid="2" d="M21.409 1.3l-8.15 6.266v-.01a3.33 3.33 0 01-4.04 0L1.06 1.28"/></g>'
  }
})
