/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'image-landscape-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>image picture landscape stroke</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.75 5.25v-.99c0-1.12-.89-2.02-2-2.03l-.01-.01H2.73c-1.12 0-2.01.91-2.01 2.02 0 0 0 0 0 0v9.456H.71c-.01 1.11.88 2.01 1.99 2.02 0 0 0 0 0 0M11.339 21.75l4.43-6.31h-.01c.47-.68 1.41-.85 2.08-.37.09.06.18.14.26.24l4.93 5.64M10.87 12.75h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38"/><rect pid="1" width="17.25" height="13.5" x="6" y="8.25" rx="1"/></g>'
  }
})
