/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'house-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>home</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M3.753 13.94v8.25h6v-6h0c-.01-.83.67-1.51 1.49-1.51h1.5-.01c.82-.01 1.5.67 1.5 1.5v6h6v-8.25M.75 12.44L10.939 2.25v-.01a1.5 1.5 0 012.12-.01s0 0 0 0l10.18 10.18"/></g>'
  }
})
