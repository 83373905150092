/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'seafood-lobster-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.387 14.018c0 4.365-1.405 8.563-3.137 8.563s-3.137-4.2-3.137-8.563h0a3.147 3.147 0 013.137-1.976h0a3.147 3.147 0 013.137 1.976z"/><path pid="1" d="M9.113 14.018L8.486 12.8c0-4.317 2.514-6.78 3.764-6.78s3.764 2.463 3.764 6.775l-.627 1.223M9.721 18.927l-2.2 2.927h0a.754.754 0 00.345 1.16h0a2.543 2.543 0 003.306-.989M14.781 18.93l2.194 2.924h0a.754.754 0 01-.345 1.16h0a2.543 2.543 0 01-3.306-.989M9.249 16.383h0c.8-.8 1.87-1.275 3-1.33h0a4.624 4.624 0 013 1.33"/><path pid="2" d="M9.743 19.01h0a4.023 4.023 0 012.507-.946h0c.92.02 1.804.353 2.507.946M13.747 6.784l1.514-2.27h0a15.2 15.2 0 017.528 13.55M10.753 6.784l-1.514-2.27h0a15.2 15.2 0 00-7.528 13.55M17.17 3.008h0A3.057 3.057 0 0119.778.75c1.663 0 3.011 2.022 3.011 4.517h0c.006.77-.13 1.536-.4 2.258M19.78.75v3.01M7.33 3.008h0A3.057 3.057 0 004.722.75c-1.663 0-3.011 2.022-3.011 4.517h0c-.006.77.13 1.536.4 2.258M4.72.75v3.01M15.36 15.05h2.91M15.13 17.28l3.14.78M9.14 15.05H6.23M9.37 17.28l-3.14.78"/></g>'
  }
})
