/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'baby-care-pacifier-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.308 5.24l-.01-.01c2.92 4.61 6.82 8.52 11.44 11.44"/><path pid="1" d="M14.374 13.44l2.28-2.29h-.01a5.34 5.34 0 012.89-1.51v-.01c.9-.16 1.73-.59 2.38-1.24v-.01c1.68-1.83 1.57-4.68-.26-6.36a4.489 4.489 0 00-6.11 0v-.01c-.65.64-1.09 1.48-1.24 2.38v-.01c-.19 1.09-.72 2.1-1.5 2.89l-2.29 2.28M7.94 12.45a3.59 3.59 0 100 7.19 3.59 3.59 0 100-7.2z"/><path pid="2" d="M14.8 13.8l-.01-.01c1.23 3.77-.83 7.84-4.6 9.08-3.78 1.23-7.85-.83-9.09-4.6a7.204 7.204 0 014.6-9.09 7.22 7.22 0 014.48-.01"/></g>'
  }
})
