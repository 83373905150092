/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vip-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5zM12 15.75v-7.5M15 15.75v-7.5"/><path pid="1" d="M15 8.25h.75-.01c1.24-.01 2.25 1 2.25 2.24 0 1.24-1.01 2.25-2.25 2.25h-.76M6 8.25v2.55l-.01-.01c0 1.76.52 3.48 1.49 4.95h-.01a9.08 9.08 0 001.5-4.95V8.23"/></g>'
  }
})
