/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'barbecue-grill': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>cookout bbq</desc><g _fill="currentColor"><path pid="0" d="M16.964 15.651a.25.25 0 00-.373-.108 8.159 8.159 0 01-1.271.73.25.25 0 00-.126.325l.873 2.054a.251.251 0 01-.022.236.248.248 0 01-.208.112H8.163a.248.248 0 01-.208-.112.251.251 0 01-.022-.236l.873-2.052a.249.249 0 00-.125-.325 8.159 8.159 0 01-1.271-.73.247.247 0 00-.212-.036.251.251 0 00-.162.143L4.08 22.609a1 1 0 00.529 1.311A.984.984 0 005 24a1 1 0 00.92-.609l.951-2.239A.25.25 0 017.1 21h9.8a.25.25 0 01.23.152l.951 2.239A1 1 0 0019 24a.984.984 0 00.391-.08 1 1 0 00.529-1.311z"/><path pid="1" d="M18.247 8.829A1.017 1.017 0 0017.5 8.5H6.505a1.016 1.016 0 00-.746.328.975.975 0 00-.256.741 6.526 6.526 0 0013 0 .977.977 0 00-.256-.74zM7.293 7.207a1 1 0 010-1.409A1.015 1.015 0 007.5 5.47a16.87 16.87 0 00-.271-.33A2.5 2.5 0 016.5 3.5a2.672 2.672 0 01.793-1.707 1 1 0 011.414 1.414 1.014 1.014 0 00-.207.323c.02.028.171.21.271.33A2.5 2.5 0 019.5 5.5a2.672 2.672 0 01-.793 1.707 1 1 0 01-1.414 0zM15.293 7.207a1 1 0 010-1.409 1.015 1.015 0 00.207-.328 16.87 16.87 0 00-.271-.33A2.5 2.5 0 0114.5 3.5a2.672 2.672 0 01.793-1.707 1 1 0 011.414 1.414 1.014 1.014 0 00-.209.323c.02.028.171.21.271.33A2.5 2.5 0 0117.5 5.5a2.672 2.672 0 01-.793 1.707 1 1 0 01-1.414 0zM11.293 7.207a1 1 0 010-1.411 1.481 1.481 0 00.4-.671c0-.187-.221-.453-.456-.736a3.127 3.127 0 01-.919-2.014 3.279 3.279 0 01.975-2.082 1 1 0 011.414 1.414 1.484 1.484 0 00-.4.668c0 .187.222.453.457.736a3.126 3.126 0 01.918 2.014 3.279 3.279 0 01-.98 2.082 1 1 0 01-1.409 0z"/></g>'
  }
})
