/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tags-double': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M19.291 16.95a3 3 0 000-4.243l-8-8a2.982 2.982 0 00-2.122-.878H2a2 2 0 00-2 2V13a2.982 2.982 0 00.879 2.122l8 8a3 3 0 004.242 0zm-1.414-1.414l-6.172 6.171a1.025 1.025 0 01-1.414 0l-8-8A.991.991 0 012 13V6.329a.5.5 0 01.5-.5h6.669a1 1 0 01.708.293l8 8a1 1 0 010 1.414z"/><circle pid="1" cx="5.998" cy="9.829" r="1.5"/><path pid="2" d="M3.828 2.249a.249.249 0 00.25.25H5.62a.25.25 0 00.236-.167A.5.5 0 016.328 2H13a.993.993 0 01.707.293l8 8a1 1 0 010 1.414l-.712.712a.252.252 0 000 .354l1.061 1.06a.249.249 0 00.353 0l.713-.712a3 3 0 000-4.242l-8-8A2.982 2.982 0 0013 0H5.828a2 2 0 00-2 2z"/></g>'
  }
})
