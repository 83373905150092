<template>
    <div class="flex items-center form-field py-0 px-0">
        <app-icon class="w-5 h-5 ml-4" name="clock-timezone-stroke"></app-icon>

        <div class="flex flex-col w-full">
            <simple-picker
                :value="value"
                :items="showAllTimezones ? timezones : simpleTimezones"
                placeholder-empty-state="Select your timezone"
                item-label="label"
                item-value="id"
                is-filterable
                form-field-class="form-field border-none"
                filterable-property="label"
                class="w-full"
                :disabled="disabled"
                :after-list-action="!showAllTimezones ? 'Show all timezones' : null"
                @afterListClicked="showAllTimezones = true"
                @input="updateTimezone"
            >
            </simple-picker>

            <div v-if="currentTime" class="text-gray-500 tracking-wide font-light ml-4 -mt-1 pb-2">
                {{ currentTime }} now
            </div>
        </div>
    </div>
</template>

<script>
import { DateTime, Settings } from 'luxon';
import {
    cloneDeep, find, filter, map, sortBy
} from 'lodash';

export default {
    name: 'TimezonePicker',

    props: {
        disabled: {
            type: Boolean,
            default: false
        },

        guessTimezone: {
            type: Boolean,
            default: false
        },

        value: {
            type: Number,
            default: null
        }
    },

    data () {
        return {
            currentTime: null,
            showAllTimezones: false
        };
    },

    computed: {
        selectedTimezoneIsSimple () {
            return find(this.simpleTimezones, { id: this.value });
        },

        selectedTimezone () {
            return find(this.timezones, { id: this.value });
        },

        simpleTimezones () {
            let timezones = filter(this.timezones, (timezone) => {
                return timezone.alias !== null;
            });

            timezones = map(timezones, (tempTimezone) => {
                const timezone = tempTimezone;
                timezone.label = `${timezone.alias} - ${timezone.name}`.replace('_', ' ');

                return timezone;
            });

            return sortBy(timezones, 'sort');
        },

        timezones () {
            let timezones = cloneDeep(window.timezones);

            timezones = map(timezones, (tempTimezone) => {
                const timezone = tempTimezone;
                timezone.label = timezone.name.replace('_', ' ');

                return timezone;
            });

            return sortBy(timezones, 'name');
        }
    },

    created () {
        setInterval(this.updateCurrentTime, 200);

        if (this.value && !find(this.simpleTimezones, { id: this.value })) {
            this.showAllTimezones = true;
        }

        if (!this.guessTimezone || this.value !== null || this.disabled) {
            return;
        }

        const matchingRecord = find(this.timezones, {
            name: Settings.defaultZone.name
        });

        if (!find(this.simpleTimezones, { name: matchingRecord.name })) {
            this.showAllTimezones = true;
        }

        if (matchingRecord) {
            this.$emit('input', matchingRecord.id);
        }
    },

    methods: {
        updateCurrentTime () {
            if (!this.selectedTimezone) {
                this.currentTime = null;
                return;
            }

            this.currentTime = DateTime.now().setZone(this.selectedTimezone.name).toLocaleString(DateTime.TIME_SIMPLE);
        },

        updateTimezone (newVal) {
            this.currentTime = null;

            this.$emit('input', newVal);
        }
    }
};
</script>
