<template>
    <div class="wall-designer relative">
        <div
            v-if="selectedBlock"
            id="blockPositioner"
            class="bg-black fixed top-1/2 -translate-x-1/2 -translate-y-1/2 z-90 opacity-0"
            :style="{ width: `${selectedBlockElementBox.width}px`, height: `${selectedBlockElementBox.height}px`, left: 'calc(50% + 185px)'}"
        ></div>

        <div v-if="wall.hasUnpublishedChanges && isFloatingRepublishButtonVisible" class="fixed md:top-18 top-13 left-0 z-[52] right-0">
            <stateful-button
                class="w-full rounded-none bg-yellow text-yellow-dark hover:bg-yellow hover:text-white"
                @click="scrollToTop"
            >
                Republish?
                <app-icon name="arrow-up-chevron-stroke"></app-icon>

            </stateful-button>
        </div>

        <transition name="slide-fade-vertical">
            <div
                v-if="wall.hasUnpublishedChanges"
                ref="unpublishedChangesNotification"
                class="call-to-action call-to-action-warning flex-col max-w-2xl mx-auto"
            >
                You've made changes to your Event Website since the last time you published it. You'll need to re-publish your Event Website before your guests will be able to see the updates.

                <div class="flex flex-col md:flex-row mt-6">
                    <div class="flex flex-col md:flex-row flex-auto">
                        <stateful-button
                            class="button call-to-action-button mb-4 mr-0 md:mb-0 md:mr-4 "
                            :loading="publishing"
                            @click="republish"
                        >
                            Republish
                        </stateful-button>

                        <a
                            class="button button-soft mb-4 md:mb-0"
                            :href="event.previewUrl"
                            target="_blank"
                        >
                            Preview Changes
                        </a>
                    </div>

                    <a
                        :href="route('publish-invite.index', event)"
                        class="w-auto inline-flex button button-soft"
                    >
                        <app-icon name="settings-cog" stroke></app-icon>
                        Publish Settings
                    </a>
                </div>
            </div>
        </transition>

        <transition name="slide-fade-vertical">
            <div v-if="!wall.active" class="call-to-action call-to-action-primary flex-col max-w-2xl mx-auto">
                <p>
                    Your Event Website is turned <span class="font-semibold">OFF</span>. Invitees will not see this content on your event page. Only your event's RSVP or registration form is visible.
                </p>

                <div class="flex flex-col md:flex-row mt-6">
                    <div class="flex flex-col md:flex-row flex-auto">
                        <stateful-button
                            class="button call-to-action-button"
                            :loading="activatingWall"
                            @click="activateWall"
                        >
                            Turn On
                        </stateful-button>
                    </div>
                </div>
            </div>
        </transition>

        <block-settings
            v-if="selectedBlock"
            domain="wall"
            :event="event"
            :saving="saving"
            @close-settings="closeBlockSettings"
            @save-settings="saveBlockSettings"
        >
            <template #overlay="{ clickOutsideOfDrawer }">
                <transition
                    enter-class="opacity-0"
                    enter-active-class="ease-out duration-300"
                    enter-to-class="opacity-100"
                    leave-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-to-class="opacity-0"
                    appear
                >
                    <div
                        class="fixed w-screen h-screen z-90"
                        :class="{ 'bg-snow-950': !isMobileView }"
                        @click="clickOutsideOfDrawer"
                    ></div>
                </transition>
            </template>
        </block-settings>

        <div class="flex flex-wrap md:flex-nowrap my-4">
            <div class="flex flex-col w-full mr-0 sm:mr-4 md:w-2/5">
                <div class="flex">
                    <a
                        role="button"
                        class="button-text button-info mb-4"
                        @click="showThemeDrawer = true"
                    >
                        <app-icon
                            name="color-brush"
                            class="w-5 h-5 mr-2"
                            stroke
                        ></app-icon>
                        Update Website Theme
                    </a>
                </div>

                <div class="blocks-drag-list border-b md:border-0">
                    <a
                        class="flex items-center py-2 cursor-pointer md:cursor-default"
                        role="button"
                        @click="accordionOpened.addedBlocks = !accordionOpened.addedBlocks"
                    >
                        <div class="grow uppercase tracking-wide text-gray-500 font-semibold">
                            Added Blocks
                        </div>

                        <div
                            v-show="accordionOpened.addedBlocks"
                            class="shrink text-purple md:hidden"
                        >
                            <app-icon
                                name="arrow-down-chevron"
                                class="h-5 w-5"
                                stroke
                            ></app-icon>
                        </div>
                        <div
                            v-show="!accordionOpened.addedBlocks"
                            class="shrink text-purple md:hidden"
                        >
                            <app-icon
                                name="arrow-right-chevron"
                                class="h-5 w-5"
                                stroke
                            ></app-icon>
                        </div>
                    </a>

                    <added-wall-block-item
                        :block="coverBlock"
                        @delete="deleteBlock"
                    ></added-wall-block-item>

                    <draggable
                        v-model="draggableBlocks"
                        v-bind="draggableOptions.addedBlocks"
                        class="md:block"
                        :class="{ 'hidden': !accordionOpened.addedBlocks }"
                        @add="dragBlock"
                        @end="onMoved"
                    >
                        <transition-group tag="div">
                            <added-wall-block-item
                                v-for="block in draggableBlocks"
                                :key="!!block.pivot ? block.pivot.id : block.id"
                                :block="block"
                                :movable="true"
                                @delete="deleteBlock"
                            ></added-wall-block-item>
                        </transition-group>
                    </draggable>

                    <added-wall-block-item
                        :block="rsvpButtonBlock"
                        @delete="deleteBlock"
                    ></added-wall-block-item>
                </div>

                <div class="blocks-drag-list mt-3">
                    <a
                        class="flex items-center py-2 cursor-pointer md:cursor-default"
                        role="button"
                        @click="accordionOpened.availableBlocks = !accordionOpened.availableBlocks"
                    >
                        <div class="grow uppercase tracking-wide text-gray-500 font-semibold">
                            Available Blocks
                        </div>

                        <div
                            v-show="accordionOpened.availableBlocks"
                            class="shrink text-purple md:hidden"
                        >
                            <app-icon
                                name="arrow-down-chevron"
                                class="h-5 w-5"
                                stroke
                            ></app-icon>
                        </div>
                        <div
                            v-show="!accordionOpened.availableBlocks"
                            class="shrink text-purple md:hidden"
                        >
                            <app-icon
                                name="arrow-right-chevron"
                                class="h-5 w-5"
                                stroke
                            ></app-icon>
                        </div>
                    </a>

                    <draggable
                        v-model="availableBlocks"
                        v-bind="draggableOptions.availableBlocks"
                        :clone="handleClone"
                        class="md:block"
                        :class="{ 'hidden': !accordionOpened.availableBlocks }"
                    >
                        <transition-group>
                            <div
                                v-for="block in availableBlocks"
                                :key="block.type"
                                v-class-when-screen:gt.md="'draggable-handle'"
                                class="group min-h-12 flex items-center p-2 pl-3 bg-white border my-2 rounded-sm draggable-block cursor-grab"
                                :data-block-id="nextAvailableBlockId"
                                @click="addBlock(block)"
                            >
                                <div class="flex-auto text-sm">
                                    {{ block.title }}
                                </div>
                                <a
                                    v-class-when-screen:lt.md="'draggable-handle'"
                                    v-tippy
                                    class="hidden button-text button-primary mr-2 leading-0 group-hover:flex"
                                    content="Add block to bottom of website."
                                    role="button"
                                >
                                    <app-icon
                                        name="add-circle"
                                        class="h-5 w-5 shrink"
                                        stroke
                                    ></app-icon>
                                </a>
                            </div>
                        </transition-group>
                    </draggable>
                </div>
            </div>

            <div class="flex flex-col w-full mt-4 md:mt-0">
                <wall-preview :event="event"></wall-preview>
            </div>

            <wall-theme-drawer
                v-model="showThemeDrawer"
                @update-settings="showThemeDrawer = false"
            ></wall-theme-drawer>
        </div>

        <requires-upgrade-modal v-model="isRequiresUpgradeModalVisible" :used-features="upgradeFeatureList"></requires-upgrade-modal>
    </div>
</template>

<script>
import {
    find, findIndex, merge, noop, reduce
} from 'lodash';
import draggable from 'vuedraggable';
import VueScrollTo from 'vue-scrollto';
import { sync, get } from 'vuex-pathify';
import CSSInjector from '@/mixins/CssInjector';
import AnimatesBlocks from '@/mixins/AnimatesBlocks';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';
import DeterminesBlockMenuTitle from '@/mixins/DeterminesBlockMenuTitle';
import axios from '@/util/axios';

export default {
    name: 'WallDesigner',

    components: { draggable },

    mixins: [
        AnimatesBlocks,
        GeneratesUniqueKey,
        DeterminesBlockMenuTitle,
        CSSInjector
    ],

    props: {
        availableBlocks: {
            type: Array,
            required: true
        },

        initialEvent: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            address: null,

            accordionOpened: {
                addedBlocks: true,
                availableBlocks: false
            },
            event: this.initialEvent,
            showThemeDrawer: false,

            /* Event Website General Settings */
            cssInjectorNamespace: '.event-wall',

            /* Settings for vue-draggable groups */
            draggableOptions: {
                addedBlocks: {
                    filter: '.locked',
                    group: 'wall-blocks',
                    animation: 150,
                    handle: '.draggable-handle'
                },
                availableBlocks: {
                    group: {
                        name: 'wall-blocks',
                        pull: 'clone',
                        put: false
                    },
                    animation: 150,
                    sort: false,
                    handle: '.draggable-handle'
                }
            },

            nextAvailableBlockId: this.getUniqueKey(),

            saving: false,
            publishing: false,
            activatingWall: false,

            isRequiresUpgradeModalVisible: false,
            upgradeFeatureList: [],
            isFloatingRepublishButtonVisible: null
        };
    },

    computed: {
        ...sync('Wall/*'),

        customCssToInject: get('Wall/wall@settings.theme.customCss'),

        coverBlock () {
            return find(this.event.wall.blocks, { slug: 'cover' });
        },

        rsvpButtonBlock () {
            return find(this.event.wall.blocks, { slug: 'rsvp-button' });
        },

        draggableBlocks: {
            get () {
                return this.event.wall.blocks.filter((block) => {
                    return ![
                        'cover',
                        'rsvp-button'
                    ].includes(block.slug);
                });
            },
            set (value) {
                this.event.wall.blocks = [
                    this.coverBlock,
                    ...value,
                    this.rsvpButtonBlock
                ];
            }
        }
    },

    created () {
        this.$store.set('Wall/wall', this.event.wall);
        this.$store.set('Form/form', this.event.form);
    },

    mounted () {
        App.$on('select-block', this.blockSelected);
        App.$on('modified-block', this.blockModified);

        this.isEditMode = true;

        window.addEventListener('scroll', () => {
            this.isFloatingRepublishButtonVisible = this.$refs.unpublishedChangesNotification?.getBoundingClientRect()?.bottom < 0;
        });
    },

    methods: {
        activateWall () {
            this.activatingWall = true;

            axios.patch(this.route('api.walls.toggle-activity', this.wall.event_id), {
                active: true
            }).then((response) => {
                this.$set(this.wall, 'active', response.data.active);
                this.$toasted.show('Event website is successfully turned on.');
            }).catch(() => {
                this.$toasted.global.error('Something went wrong with updating event website. Please try again.');
            }).finally(() => {
                this.activatingWall = false;
            });
        },

        addBlock (block) {
            this.storeBlock(block.id, this.event.wall.blocks.length - 1, 0);
        },

        async blockSelected ({
            block, preSelect, postSelect, selectionData
        }) {
            if (preSelect) {
                preSelect();
            }

            const selectedBlock = find(this.event.wall.blocks, (wallBlock) => {
                return wallBlock.pivot.id === block.pivot.id;
            });

            const selectedBlockElementSelector = `.event-wall div[data-pivot-id="${selectedBlock.pivot.id}"]`;

            if (selectionData && selectionData.scrollToBlock) {
                // Animate the scrolling first, and then open settings / bring into focus
                await (new Promise((resolve) => {
                    VueScrollTo.scrollTo(selectedBlockElementSelector, 500, {
                        duration: 500,
                        easing: [0.25, 0.46, 0.45, 0.94],
                        cancelable: false,
                        onDone: () => {
                            setTimeout(() => {
                                resolve();
                            }, 100);
                        },
                        offset: -100
                    });
                }));
            }

            this.selectedBlock = selectedBlock;

            if (!this.isMobileView && this.selectedBlock.slug !== 'rsvp-button') {
                this.animateBlockToScreenCenter(selectedBlockElementSelector);
            }

            if (postSelect) {
                this.$nextTick(() => {
                    postSelect();
                });
            }
        },

        closeBlockSettings () {
            this.selectedBlock = null;

            if (this.selectedBlockElement) {
                if (!this.isMobileView) {
                    this.animateBlockBackToOriginalPosition();
                }

                this.selectedBlockElement = null;
            }
        },

        deleteBlock (block) {
            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            const onDelete = () => {
                const route = this.route('api.walls.blockables.destroy', {
                    event: this.event,
                    blockable: block.pivot
                });

                axios.delete(route).then(({ data }) => {
                    const index = findIndex(this.event.wall.blocks, (deletedBlock) => {
                        return deletedBlock.pivot.id === block.pivot.id;
                    });

                    if (index) {
                        this.event.wall.blocks.splice(index, 1);

                        this.$toasted.show(`The ${block.pivot.settings.title} block has been removed.`);

                        this.touchWall();
                    }
                }).catch((response) => {
                    this.$toasted.global.error(`There was an error removing the ${block.pivot.settings.title} block.`);
                });
            };

            App.alert().confirm(
                'Are you sure?',
                `The ${block.pivot.settings.title} block and all of its content will be removed from your website. This cannot be undone.`,
                attributes,
                onDelete
            );
        },

        dragBlock ($event) {
            const addedBlockIndex = findIndex(this.event.wall.blocks, {
                id: parseInt($event.clone.dataset.blockId)
            });

            const addedBlock = this.event.wall.blocks[addedBlockIndex];

            this.storeBlock(addedBlock.blockId, addedBlockIndex, 1);
        },

        blockModified ({ id, ...settings }) {
            const block = this.event.wall.blocks.find((wallBlock) => {
                return wallBlock.pivot.id === id;
            });

            Object.assign(block.pivot.settings, settings);

            this.saveBlockSettings({
                defaultCloseHandler: noop,
                block
            });
        },

        handleClone (originalObject) {
            return {
                id: this.nextAvailableBlockId,
                blockId: originalObject.id
            };
        },

        onMoved () {
            this.reorderBlocks();

            return 1;
        },

        reorderBlocks () {
            this.event.wall.blocks.map((block, index) => {
                block.pivot.sort = index + 1;
                return block;
            });

            const payload = reduce(this.event.wall.blocks, (carry, block) => {
                carry[block.pivot.id] = block.pivot.sort;
                return carry;
            }, {});

            axios.patch(this.route('api.walls.reorder', { event: this.event }), {
                sort: payload
            })
                .then(this.touchWall)
                .catch(() => {
                    this.$toasted.global.error(`There was an error reordering the blocks.`);
                });
        },

        saveBlockSettings ({ defaultCloseHandler, block, form }) {
            this.saving = true;

            const route = this.route('api.walls.blockables.update', {
                event: this.event,
                blockable: block.pivot
            });

            // The Axios call is used for self-modifying blocks such as the TextBlcok
            const apiCall = form
                ? form.put(route)
                : axios.put(route, block.pivot.settings);

            apiCall.then(() => {
                this.$toasted.show(`${block.title} block settings have been updated.`);
                this.touchWall();

                defaultCloseHandler();
            }).catch((error) => {
                if (error.response.status !== 422) {
                    this.$toasted.global.error(`There was an error updating the ${block.title} block settings.`);
                }
            }).finally(() => {
                this.saving = false;
            });
        },

        scrollToTop () {
            window.scroll({ top: 0, behavior: 'smooth' });
        },

        storeBlock (availableBlockId, insertionIndex, howManyToSplice) {
            const route = this.route('api.walls.blockables.store', {
                event: this.event
            });

            return axios.post(route, {
                block: availableBlockId
            }).then(({ data }) => {
                this.event.wall.blocks.splice(insertionIndex, howManyToSplice, data);
                this.touchWall();

                this.reorderBlocks();
            }).catch(() => {
                this.$toasted.global.error('There was an error adding the block to your wall.');
            });
        },

        touchWall () {
            this.$store.commit('Wall/touch');
        },

        republish () {
            this.publishing = true;

            const route = this.route(`api.events.republish`, this.event);

            axios.post(route, { model: 'wall' }).then(({ data }) => {
                merge(this.wall, data.wall);

                this.$toasted.show('Your event has been published.');
            }).catch(({ response }) => {
                const upgradeFeatureList = response.data.used_features;

                if (response.data.publishing_blocker === 'beyondLimit' && upgradeFeatureList && upgradeFeatureList.length > 0) {
                    this.upgradeFeatureList = upgradeFeatureList;
                    this.isRequiresUpgradeModalVisible = true;
                    return;
                }

                this.$toasted.global.error('There was a problem publishing your event.');
            }).finally(() => {
                this.publishing = false;
            });
        }
    }
};
</script>
