/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-shuffle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>shuffle reorder</desc><g _fill="currentColor"><path pid="0" d="M.375 6.74h-.01c-.01.69.55 1.25 1.24 1.25h4.77c1.08.1 2.04.72 2.6 1.65l-.01-.01a1.246 1.246 0 002.03-1.45l-.01-.01a5.797 5.797 0 00-4.65-2.72H1.55l-.01-.001A1.223 1.223 0 00.29 6.68z"/><path pid="1" d="M23.457 7.3h-.01c.22-.34.22-.78 0-1.11l-1.91-2.86c-.31-.46-.93-.59-1.39-.28-.28.18-.45.49-.45.83v1.36c0 .13-.12.25-.25.25h-1.62c-3.08 0-4.958 2.96-6.782 5.82-1.47 2.29-2.97 4.67-4.67 4.67H1.59c-.7 0-1.25.55-1.25 1.25 0 .69.55 1.25 1.25 1.24h4.77c3.07 0 4.958-2.97 6.782-5.83 1.45-2.3 2.97-4.68 4.67-4.68h1.61-.01c.13-.01.25.11.25.24v1.36l-.001-.01c0 .44.28.82.7.95.09.02.19.04.28.04l-.01-.001c.33 0 .64-.17.83-.45z"/><path pid="2" d="M20.426 21.069c.09.02.19.04.28.04l-.01-.001c.33 0 .64-.17.83-.45l1.9-2.87h-.01c.22-.34.22-.78 0-1.11L21.5 13.8c-.31-.46-.93-.59-1.39-.28-.28.18-.45.49-.45.83v1.36c0 .13-.12.25-.25.25h-1.62a4.62 4.62 0 01-3.13-1.58 1.25 1.25 0 00-1.77-.04c-.5.47-.52 1.27-.04 1.76l-.01-.01a6.997 6.997 0 004.91 2.34h1.61-.01c.13-.01.25.11.25.24v1.36h-.01c-.01.44.28.83.71.96z"/></g>'
  }
})
