/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'remove-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>delete subtract minus</desc><g _fill="currentColor"><path pid="0" d="M12 .25A11.75 11.75 0 1023.75 12 11.763 11.763 0 0012 .25zm0 21A9.25 9.25 0 1121.25 12 9.26 9.26 0 0112 21.25z"/><path pid="1" d="M6.273 10.75a1.25 1.25 0 000 2.5h11.454a1.25 1.25 0 000-2.5z"/></g>'
  }
})
