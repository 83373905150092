/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-balloons-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M22.13 18.525h-.01c.2-.01.37.16.37.37"/><path pid="1" d="M21.758 18.9h0c-.01-.21.16-.38.37-.38M22.13 19.275h-.01a.386.386 0 01-.38-.38s0 0 0 0M22.508 18.9v0c0 .2-.17.375-.375.375M10.87 21.5h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M10.5 21.875h0c-.01-.21.16-.38.37-.38M10.87 22.25h-.01a.386.386 0 01-.38-.38s0 0 0 0M11.25 21.875v0c0 .2-.17.375-.375.375M22.875 7.25h-.01c.2-.01.37.16.37.37M22.5 7.62h0c-.01-.21.16-.38.37-.38M22.875 8h-.01a.386.386 0 01-.38-.38s0 0 0 0M23.25 7.62v0c0 .2-.17.375-.375.375 0 0 0 0 0 0M1.125 17h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M.75 17.375h0c-.01-.21.16-.38.37-.38M1.125 17.75h-.01a.386.386 0 01-.38-.38s0 0 0 0M1.5 17.375v0c0 .2-.17.375-.375.375M15 2a4.5 5.25 0 100 10.5A4.5 5.25 0 1015 2zM15 12.5l-1.35 1.8v-.01c-.25.33-.19.8.14 1.05.12.09.28.15.45.15h1.5-.01c.41 0 .75-.34.75-.75a.76.76 0 00-.15-.45zM15.157 15.5h-.01c-.75 1.05-.63 2.48.28 3.4l-.01-.01a2.638 2.638 0 010 3.73h-.01M9 1.95l-.01-.01a3.97 3.97 0 00-2.26-.7c-2.49 0-4.5 2.35-4.5 5.25 0 2.899 2.01 5.25 4.5 5.25v-.001c.8-.01 1.58-.25 2.25-.7M6.75 11.75l1.35 1.8h0c.24.33.18.8-.16 1.05-.13.09-.29.15-.45.15H5.98a.755.755 0 01-.75-.75c0-.17.05-.33.15-.45z"/><path pid="4" d="M6.593 14.75l-.01-.01c.74 1.05.62 2.48-.29 3.4v-.01a2.629 2.629 0 00-.01 3.73s0 0 0 0"/></g>'
  }
})
