<template>
    <portal to="modal-portal-target">
        <div
            v-if="isActive"
            class="fixed inset-0 flex justify-center items-center"
            :style="{'z-index': zIndex}"
        >
            <transition
                ref="modal"
                enter-class="opacity-0"
                enter-active-class="transition-all duration-75 ease-out"
                enter-to-class="opacity-100"
                leave-class="opacity-100"
                leave-active-class="transition-all duration-200 ease-in"
                leave-to-class="opacity-0"
                appear
                @before-leave="backdropLeaving = true"
                @after-leave="backdropLeaving = false"
            >
                <div v-if="isBackdropActive">
                    <div class="absolute inset-0 bg-black opacity-50" @click="clickOutside"></div>
                </div>
            </transition>

            <transition
                enter-class="opacity-0 scale-125"
                enter-active-class="transition-all duration-300 ease-out"
                enter-to-class="opacity-100 scale-100"
                leave-class="opacity-100 scale-100"
                leave-active-class="transition-all duration-200 ease-in"
                leave-to-class="opacity-0 scale-125"
                appear
                @before-leave="cardLeaving = true"
                @after-leave="cardLeaving = false"
            >
                <div
                    v-if="isCardActive"
                    class="modal-content"
                    :class="contentClasses"
                >
                    <div class="flex justify-between shrink-0 px-6 py-4 bg-gray-100 border-b" :class="headerClasses">
                        <div class="flex-1 font-semibold text-xl text-gray-800">
                            <slot name="header" :close="close">
                                {{ title }}
                            </slot>
                        </div>

                        <div class="flex grow-0">
                            <slot name="close-button" :close="close">
                                <button class="button-icon button-sm shrink" @click="close">
                                    <app-icon name="close"></app-icon>
                                </button>
                            </slot>
                        </div>
                    </div>

                    <div class="flex-1 overflow-y-auto overflow-x-hidden px-6 py-4">
                        <slot :close="close"></slot>
                    </div>

                    <div v-if="hasSlot('footer')" class="flex flex-col items-center md:flex-row-reverse md:space-x-2 md:space-x-reverse md:justify-start shrink-0 px-6 py-4 bg-gray-100 border-t modal-footer">
                        <div class="md:ml-auto flex flex-col items-center md:flex-row-reverse md:space-x-2 md:space-x-reverse w-full md:w-auto" :class="{ 'mb-4 md:mb-0': hasSlot('footer-split-options') }">
                            <slot name="footer" :close="close"></slot>
                        </div>

                        <slot name="footer-split-options" :close="close"></slot>
                    </div>
                </div>
            </transition>
        </div>
    </portal>
</template>

<script>
import Mousetrap from 'mousetrap';
import SlotHelpers from '@/mixins/SlotHelpers';
import BodyElementClassesHelper from '@/mixins/BodyElementClassesHelper';
import Toggleable from '@/mixins/Toggleable';

export default {
    name: 'AppModal',

    mixins: [Toggleable, BodyElementClassesHelper, SlotHelpers],

    props: {
        closeOnEsc: {
            type: Boolean,
            default: true
        },
        confirmClose: {
            type: Boolean,
            default: false
        },
        disableClickOutside: {
            type: Boolean,
            default: false
        },
        fullScreen: {
            type: Boolean,
            default: false
        },
        headerClasses: {
            type: String,
            default: 'items-baseline'
        },
        nested: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        zIndex: {
            type: Number,
            default: 60
        }
    },

    data () {
        return {
            backdropLeaving: false,
            cardLeaving: false,
            isBackdropActive: false,
            isCardActive: false
        };
    },

    computed: {
        contentClasses () {
            return [
                this.fullScreenClasses
            ];
        },

        fullScreenClasses () {
            return {
                'full-screen': this.fullScreen,
                'full-screen-on-mobile': true
            };
        },

        isLeaving () {
            return this.backdropLeaving || this.cardLeaving;
        }
    },

    watch: {
        isActive (val) {
            if (!this.nested) {
                this.toggleBodyElementClasses(['overflow-hidden'], val);
            }

            if (val) {
                this.show();
            } else {
                this.close();
            }
        },

        isLeaving (val) {
            if (val === false) {
                this.isActive = false;
            }
        }
    },

    mounted () {
        if (this.closeOnEsc) {
            const mouseTrap = new Mousetrap(this.$refs.modal);

            mouseTrap.bind('esc', () => {
                if (this.closeOnEsc) {
                    this.close();
                }
            });
        }

        if (this.isActive) {
            this.show();
        }
    },

    methods: {
        clickOutside () {
            if (this.disableClickOutside) {
                return;
            }

            this.close();
        },

        close () {
            // Already closed
            if (!this.isBackdropActive && !this.isCardActive) {
                return;
            }

            const closeModal = () => {
                this.isBackdropActive = false;
                this.isCardActive = false;
                this.$emit('closed');
            };

            if (!this.confirmClose) {
                return closeModal();
            }

            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            App.alert().confirm(
                'Are you sure?',
                `The changes you've made haven't been saved. Are you sure you want to leave without saving your changes?`,
                attributes,
                closeModal
            );
        },

        show () {
            this.isBackdropActive = true;
            this.isCardActive = true;
            this.$emit('opened');
        }
    }
};
</script>
