import { make } from 'vuex-pathify';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import Vue from 'vue';
import { asset } from '@codinglabs/laravel-asset';

const showFeatureUsageNotification = ({ title, message }) => {
    const icon = asset('/images/account/rsvpify-premium.svg');

    Vue.toasted.usedFeatures.show(`
        <div class="w-full">
            <div class="title">
                <img src="${icon}">

                <span>${title}</span>
            </div>
            <div class="message">${message}</div>
        </div>
    `);
};

const moduleState = {
    usedFeatures: {}
};

const getters = {
};

const mutations = {
    ...make.mutations(moduleState),

    setUsedFeature (state, featureUsage) {
        Vue.set(state.usedFeatures, featureUsage.feature, featureUsage);
    }
};

const actions = {
    initialize ({ commit }, featureUsages) {
        commit('usedFeatures', keyBy(featureUsages, 'feature'));
    },

    trackFeatures ({ commit, state }, featureUsages) {
        featureUsages.forEach((usage) => {
            const existingFeatureUsage = state.usedFeatures[usage.feature];

            const shouldShowNotification = usage.requires_upgrade
                && !get(existingFeatureUsage, 'requires_upgrade', false)
                && get(usage.message, 'notifyImmediately', false);

            if (shouldShowNotification) {
                showFeatureUsageNotification(usage.message);
            }

            commit('setUsedFeature', usage);
        });
    }
};

export default {
    namespaced: true,
    state: moduleState,
    mutations,
    actions,
    getters
};
