/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'seafood-crab': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M4.619 9.765a.5.5 0 00.157-.787 6.051 6.051 0 01-.52-.666.25.25 0 01.187-.388 6.562 6.562 0 004.208-2.1.505.505 0 00-.407-.835 12.631 12.631 0 01-1.68-.016.249.249 0 01-.183-.392A7.28 7.28 0 019.3 2.378a.5.5 0 00.072-.878C5.526-1.065.236 3.343 1.186 6.225a11.419 11.419 0 001.959 3.858.5.5 0 00.632.115c.252-.145.556-.298.842-.433zM19.381 9.765a.5.5 0 01-.157-.787 6.051 6.051 0 00.52-.666.25.25 0 00-.187-.388 6.56 6.56 0 01-4.208-2.1.505.505 0 01.407-.835 12.631 12.631 0 001.68-.016.25.25 0 00.183-.392 7.276 7.276 0 00-2.917-2.2.506.506 0 01-.075-.879c3.847-2.564 9.136 1.844 8.187 4.726a11.419 11.419 0 01-1.959 3.858.5.5 0 01-.633.115 10.82 10.82 0 00-.841-.436zM23.531 19.321a1 1 0 00.317-1.379 8.639 8.639 0 00-3.684-3.163.5.5 0 01-.277-.665c.087-.2.19-.438.285-.653a1.5 1.5 0 00-.643-1.933 15.421 15.421 0 00-7.521-1.885h-.016a15.418 15.418 0 00-7.521 1.885 1.5 1.5 0 00-.643 1.933c.1.216.2.451.285.653a.5.5 0 01-.278.665 8.64 8.64 0 00-3.683 3.163 1 1 0 001.694 1.064 6.5 6.5 0 012.891-2.428.5.5 0 01.6.25c.23.456.478.905.754 1.343a.5.5 0 01-.19.71 9.726 9.726 0 00-3.138 2.832 1 1 0 001.656 1.121 7.073 7.073 0 013.116-2.473.5.5 0 01.475.106 10.373 10.373 0 002.509 1.644 1.5 1.5 0 00.645.145h1.674a1.5 1.5 0 00.645-.145 10.432 10.432 0 002.509-1.644.5.5 0 01.473-.107 6.93 6.93 0 013.117 2.474 1 1 0 001.657-1.121 9.522 9.522 0 00-3.139-2.837.5.5 0 01-.191-.711c.275-.435.522-.883.751-1.337a.5.5 0 01.6-.25 6.49 6.49 0 012.891 2.428 1 1 0 001.377.315zM7.37 12.3a.75.75 0 011 .359l.862 1.828a.75.75 0 01-.358 1 .741.741 0 01-.319.072.749.749 0 01-.679-.431L7.011 13.3a.75.75 0 01.359-1zm5.38 2.176a.75.75 0 01-1.5 0v-2.024a.75.75 0 111.5 0zm1.761.011l.863-1.828a.75.75 0 111.356.64l-.862 1.828a.75.75 0 01-.679.431.738.738 0 01-.319-.072.75.75 0 01-.359-1.001z"/></g>'
  }
})
