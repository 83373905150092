<template>
    <app-modal
        :value="value"
        :title="title"
        :z-index="1002"
        full-screen
        :confirm-close="value && hasUnsavedChanges"
        @input="$emit('input', $event)"
    >
        <div>
            <div v-if="loading" class="w-full text-center m-4">
                <app-icon class="h-6 w-6 text-gray-500" name="loader"></app-icon>
            </div>

            <template v-else>
                <div class="mx-auto flex items-center justify-center w-60 pb-4 space-x-2">
                    <button
                        class="shrink hover:text-purple-600"
                        :class="{'invisible': !canGoToPreviousWeek}"
                        @click="previousWeek"
                    >
                        <app-icon
                            name="arrow-left-chevron"
                            class="h-6 w-6"
                        ></app-icon>
                    </button>

                    <div class="grow font-semibold text-xl text-center">{{ startDate.toFormat('MMM d') }} - {{ endDate.toFormat('MMM d') }}</div>

                    <button
                        class="shrink hover:text-purple-600"
                        :class="{'invisible': !canGoToNextWeek}"
                        @click="nextWeek"
                    >
                        <app-icon
                            name="arrow-right-chevron"
                            class="h-6 w-6"
                        ></app-icon>
                    </button>
                </div>

                <table v-if="dates.length" class="w-full">
                    <thead>
                        <tr>
                            <th class="w-28"></th>
                            <th
                                v-for="(date, index) in dates"
                                :key="index"
                                class="font-normal text-lg"
                            >
                                {{ date.toFormat('M/d') }}
                            </th>
                        </tr>
                        <tr>
                            <th class="font-normal text-right text-lg pb-4">Available</th>
                            <th
                                v-for="(date, index) in dates"
                                :key="index"
                                class="font-normal text-center pb-4"
                            >
                                <input v-model="dateAvailability[date.toFormat('yyyy-MM-dd')]" type="checkbox">
                            </th>
                        </tr>
                        <tr>
                            <th class="font-normal text-right text-lg pb-4">Day Total</th>
                            <th
                                v-for="(date, index) in dates"
                                :key="index"
                                class="font-normal text-center pb-4"
                            >
                                <span v-if="dateAvailability[date.toFormat('yyyy-MM-dd')]">
                                    {{ getTotalNumberOfAvailableSlots(date.toFormat('yyyy-MM-dd')) }}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(timeslot, timeslotIndex) in timeslots" :key="timeslotIndex">
                            <td class="text-right">
                                {{ timeslot.toFormat('h:mm a') }}
                            </td>
                            <td
                                v-for="(date, dateIndex) in dates"
                                :key="dateIndex"
                                class="font-normal text-center"
                            >
                                <input
                                    v-model.number="overrides[date.toFormat('yyyy-MM-dd')][timeslot.toFormat('H:mm')]"
                                    type="number"
                                    class="form-field text-center mx-auto w-16 placeholder-gray-300 disabled:opacity-50 no-spinner"
                                    :class="quantityInputStyle(overrides[date.toFormat('yyyy-MM-dd')][timeslot.toFormat('H:mm')])"
                                    :placeholder="maxQuantityPlaceholder"
                                    :disabled="!dateAvailability[date.toFormat('yyyy-MM-dd')]"
                                    min="0"
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-else class="text-center">
                    No available timeslots in the selected dates.
                </div>
            </template>
        </div>

        <template #footer="{ close }">
            <button class="button mr-2" @click="close">Cancel</button>

            <stateful-button
                class="button button-primary mr-2"
                :disabled="!hasUnsavedChanges"
                @click="save"
            >Save</stateful-button>
        </template>
    </app-modal>
</template>

<script>
import { DateTime } from 'luxon';
import { get } from 'vuex-pathify';
import {
    keys, cloneDeep, find, isEqual, sumBy
} from 'lodash';
import axios from '@/util/axios';

export default {
    name: 'ManageTimeSlotsOverridesModal',

    props: {
        selectedProduct: {
            type: Object,
            default: () => { return {}; }
        },

        selectedTimeslot: {
            type: Object,
            default: () => { return {}; }
        },

        value: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            dateAvailability: {},
            dates: [],
            endDate: DateTime.local().plus({ days: 10 }).toUTC(),
            startDate: DateTime.local().toUTC(),
            loading: false,
            timeslots: [],
            overrides: {},
            originalOverrides: {},
            originalDateAvailability: {}
        };
    },

    computed: {
        ...get('Event/*'),

        minimumDate () {
            const currentDate = DateTime.local();

            if (!this.selectedTimeslot || !this.selectedTimeslot.id) {
                return currentDate;
            }

            const timeslotStartDate = DateTime.fromISO(this.selectedTimeslot.recurrence_rule.startDate).toUTC();

            return currentDate < timeslotStartDate
                ? timeslotStartDate
                : currentDate;
        },

        hasUnsavedChanges () {
            return !isEqual(this.overrides, this.originalOverrides)
                || !isEqual(this.dateAvailability, this.originalDateAvailability);
        },

        maxQuantityPlaceholder () {
            if (this.isPerProductOverride) {
                return this.selectedProduct.max_quantity;
            }

            return this.selectedTimeslot.max_quantity;
        },

        canGoToNextWeek () {
            if (!this.selectedTimeslot) {
                return false;
            }

            const timeslotEndDate = DateTime.fromISO(this.selectedTimeslot.recurrence_rule.endDate);

            return timeslotEndDate === null || this.endDate.year < timeslotEndDate.year || this.endDate.ordinal <= timeslotEndDate.ordinal;
        },

        canGoToPreviousWeek () {
            return this.startDate.ordinal > this.minimumDate.ordinal || this.startDate.year > this.minimumDate.year;
        },

        isPerProductOverride () {
            return this.selectedProduct && this.selectedProduct.product_id;
        },

        title () {
            return `Manage Time Slot Overrides`;
        }
    },

    watch: {
        value (newValue) {
            if (newValue) {
                this.$set(this, 'overrides', {});
                this.$set(this, 'dateAvailability', {});

                this.startDate = this.minimumDate;
                this.endDate = this.startDate.plus({ days: 10 });

                this.getTimeslots();
            }
        }
    },

    methods: {
        getTimeslots () {
            this.loading = true;

            const route = this.route('api.events.timeslots.overrides.for-date-range', [
                this.event,
                this.selectedTimeslot
            ]);

            const payload = {
                startDate: this.startDate,
                endDate: this.endDate,
                product: this.isPerProductOverride ? this.selectedProduct.product_id : null
            };

            axios.post(route, payload)
                .then(({
                    data: {
                        dates, timeslots, exclusions, overrides
                    }
                }) => {
                    /**
                     * The header of the table / available dates.
                     */
                    this.$set(this, 'dates', dates.map((date) => {
                        return DateTime.fromFormat(date, 'yyyy-MM-dd');
                    }));

                    /**
                     * The left-most column of the table / available times.
                     */
                    this.$set(this, 'timeslots', timeslots.map((timeslot) => {
                        return DateTime.fromFormat(timeslot, 'yyyy-LL-dd HH:mm:ss');
                    }));

                    /**
                     * The "all day" availability of particular dates.
                     */
                    this.$set(this, 'dateAvailability', this.dates.reduce((availabilities, date) => {
                        const dateString = date.toFormat('yyyy-MM-dd');
                        const isAvailable = find(exclusions, { timeslot_date: dateString }) === undefined;

                        this.$set(availabilities, dateString, isAvailable);

                        return availabilities;
                    }, {}));

                    /**
                     * Object containing "placeholder" overrides for all
                     * times in a particular day - this is only used to build
                     * up the main "overrides" matrix below.
                     */
                    const timeslotOverrides = this.timeslots.reduce((carry, timeslot) => {
                        this.$set(carry, timeslot.toFormat('H:mm'), null);

                        return carry;
                    }, {});

                    /**
                     * Object holding the matrix of all the specific
                     * date/time max_quantity overrides.
                     */
                    this.$set(this, 'overrides', this.dates.reduce((carry, date) => {
                        this.$set(carry, date.toFormat('yyyy-MM-dd'), cloneDeep(timeslotOverrides));

                        return carry;
                    }, {}));

                    /**
                     * Prefill the matrix with existing overrides from the DB.
                     */
                    overrides.forEach((override) => {
                        const date = DateTime.fromFormat(override.timeslot_from, 'yyyy-LL-dd HH:mm:ss');
                        const dateString = date.toFormat('yyyy-MM-dd');
                        const timeString = date.toFormat('H:mm');

                        if (!this.overrides[dateString]) {
                            this.$set(this.overrides, dateString, {});
                        }

                        this.$set(this.overrides[dateString], timeString, override.max_quantity);
                    });

                    this.$set(this, 'originalOverrides', cloneDeep(this.overrides));
                    this.$set(this, 'originalDateAvailability', cloneDeep(this.dateAvailability));
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        confirmDateRangeChange (callback) {
            if (this.hasUnsavedChanges) {
                App.alert().confirm(
                    'Are you sure?',
                    'Are you sure you want to discard all your changes in the selected date range?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                    },
                    callback
                );
            } else {
                callback();
            }
        },

        getTotalNumberOfAvailableSlots (date) {
            return sumBy(this.timeslots, (timeslot) => {
                const quantityOverride = this.overrides[date][timeslot.toFormat('H:mm')];

                return quantityOverride !== null && quantityOverride !== ''
                    ? quantityOverride
                    : this.maxQuantityPlaceholder;
            });
        },

        nextWeek () {
            if (!this.canGoToNextWeek) {
                return;
            }

            this.confirmDateRangeChange(() => {
                this.startDate = this.startDate.plus({ days: 10 });
                this.endDate = this.endDate.plus({ days: 10 });

                this.getTimeslots();
            });
        },

        previousWeek () {
            if (!this.canGoToPreviousWeek) {
                return;
            }

            this.confirmDateRangeChange(() => {
                this.startDate = this.startDate.minus({ days: 10 });
                this.endDate = this.endDate.minus({ days: 10 });

                this.getTimeslots();
            });
        },

        save () {
            const overrides = {};
            const exclusions = [];

            keys(this.overrides)
                .forEach((date) => {
                    const times = keys(this.overrides[date]);

                    times.forEach((time) => {
                        const override = this.overrides[date][time];

                        if (override !== null && override !== undefined) {
                            this.$set(overrides, `${date} ${time}`, override);
                        }
                    });
                });

            keys(this.dateAvailability)
                .forEach((date) => {
                    if (!this.dateAvailability[date]) {
                        exclusions.push(date);
                    }
                });

            const route = this.route('api.events.timeslots.overrides.store', [
                this.event,
                this.selectedTimeslot
            ]);

            const payload = {
                startDate: this.startDate.toFormat('yyyy-MM-dd'),
                endDate: this.endDate.toFormat('yyyy-MM-dd'),
                overrides,
                exclusions,
                product: this.isPerProductOverride ? this.selectedProduct.product_id : null
            };

            this.loading = true;

            axios.post(route, payload)
                .then(() => {
                    this.$set(this, 'originalOverrides', cloneDeep(this.overrides));
                    this.$set(this, 'originalDateAvailability', cloneDeep(this.dateAvailability));

                    this.$toasted.global.success('Timeslot capacity overrides successfully saved.');
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        quantityInputStyle (quantity) {
            return {
                'bg-gray-100': quantity !== null && quantity !== this.maxQuantityPlaceholder
            };
        }
    }
};
</script>
