export default {
    props: {
        form: {
            type: Object,
            required: true
        }
    },

    methods: {
        createItem () {
            this.$emit('create-item');
        },

        deleteItem (index) {
            this.form[this.itemsProperty].splice(index, 1);
        },

        duplicateItem (item) {
            this.$emit('duplicate-item', item);
        },

        editItem (item) {
            this.$emit('edit-item', item);
        }
    }
};
