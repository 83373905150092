<template>
    <div class="flex flex-row items-center space-x-2 py-1 relative">
        <p v-if="label" class="font-semibold text-gray-600 w-16 flex-none">{{ label }}</p>

        <date-picker
            v-if="hasDate"
            ref="datePicker"
            v-model="date"
            v-click-outside="validateMinDate"
            :clear-button="clearable"
            :disabled="disabled"
            input-class="form-field"
            typeable
            placeholder="Date"
            full-month-name
            format="MMMM dd, yyyy"
            :disabled-dates="disabledDates"
            calendar-class="rounded-lg text-gray overflow-hidden"
            class="flex-auto w-7/12"
            append-to-body
        >
            <template #prevIntervalBtn>
                <app-icon class="h-4 w-4" name="arrow-left-chevron-stroke"></app-icon>
            </template>

            <template #nextIntervalBtn>
                <app-icon class="h-4 w-4" name="arrow-right-chevron-stroke"></app-icon>
            </template>

            <template #clearBtn>
                <app-icon
                    class="-ml-8 -mt-1 h-4 w-4 button-icon"
                    name="close"
                ></app-icon>
            </template>
        </date-picker>

        <time-picker
            v-if="hasTime"
            v-model="time"
            :clearable="clearable"
            :focused-selectable-time="focusedSelectableTime"
            placeholder="Time"
            class="w-5/12"
            :disabled="disabled"
            :min-time="minTime"
            :in24-hour="in24Hour"
        >
        </time-picker>
    </div>
</template>

<script>
import { DateTime } from 'luxon';
import DatePicker from '@sum.cumo/vue-datepicker';

export default {
    components: {
        DatePicker
    },

    props: {
        clearable: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        focusedSelectableTime: {
            type: String,
            default: null
        },

        value: {
            type: [String, Number, Object, Date],
            default: null
        },

        label: {
            type: String,
            default: null
        },

        placeholder: {
            type: String,
            default: 'Please choose a date ...'
        },

        hasDate: {
            type: Boolean,
            default: true
        },

        hasTime: {
            type: Boolean,
            default: true
        },

        minDatetime: {
            type: String,
            default: () => {
                return DateTime.local().toISO();
            }
        },

        in24Hour: {
            type: Boolean,
            default: () => {
                return Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).resolvedOptions().hourCycle === 'h23';
            }
        }
    },

    data () {
        return {
            date: null,
            time: null,
            originalChosenDate: this.value
        };
    },

    computed: {
        dateSelectedIsOnMinDay () {
            return DateTime.fromJSDate(this.date).hasSame(DateTime.fromISO(this.selectableMinDateTime), 'day');
        },

        disabledDates () {
            if (this.minDatetime) {
                return {
                    to: DateTime.fromISO(this.selectableMinDateTime).minus({ days: 1 }).endOf('day').toJSDate()
                };
            }

            return {};
        },

        minTime () {
            if (this.dateSelectedIsOnMinDay) {
                return DateTime.fromISO(this.selectableMinDateTime).toFormat('HH:mm');
            }

            return '';
        },

        selectableMinDateTime () {
            if (this.originalChosenDate && this.originalChosenDate < this.minDatetime) {
                return this.originalChosenDate;
            }

            return this.minDatetime;
        }
    },

    watch: {
        date () {
            this.emitChange();
        },

        time: {
            immediate: true,
            handler () {
                this.emitChange();
            }
        },

        value (newVal) {
            this.setDateTime(newVal);
        }
    },

    created () {
        this.setDateTime(this.value);

        document.addEventListener('scroll', this.handleScroll, true);
    },

    destroyed () {
        document.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        emitChange () {
            if (this.hasTime && !this.time) {
                this.$emit('input', null);
                return;
            }

            if (this.hasDate && !this.date) {
                this.$emit('input', null);
                return;
            }

            if (this.hasTime && this.hasDate) {
                const [hours, minutes] = this.time.split(':');
                const newDate = DateTime.utc(this.date.getFullYear(), this.date.getMonth() + 1, this.date.getDate(), parseInt(hours, 10), parseInt(minutes, 10)).toISO();

                this.$emit('input', newDate);
                return;
            }

            if (this.hasDate) {
                const newDate = DateTime.utc(this.date.getFullYear(), this.date.getMonth() + 1, this.date.getDate(), 0, 0).toISO();

                this.$emit('input', newDate);
                return;
            }

            this.$emit('input', this.time);
        },

        handleScroll () {
            if (this.$refs.datePicker) {
                this.$refs.datePicker.$refs.popup.setTopStyle();
            }
        },

        setDateTime (value) {
            if (value && this.hasDate) {
                let newDate = DateTime.fromISO(value, { zone: 'utc' });
                newDate = new Date(newDate.get('year'), newDate.get('month') - 1, newDate.get('day'), 0, 0);

                if (newDate !== this.date) {
                    this.date = newDate;
                }
            }

            if (value && this.hasTime) {
                const newTime = DateTime.fromISO(value, { zone: 'utc' }).toFormat('HH:mm');

                if (newTime !== this.time) {
                    this.time = newTime;
                }
            }
        },

        validateMinDate () {
            if (DateTime.fromJSDate(this.date).startOf('day') < DateTime.fromISO(this.selectableMinDateTime).startOf('day')) {
                this.$refs.datePicker.clearDate();
            }
        }
    }
};
</script>
