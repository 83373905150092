/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'credit-card': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>amex visa mastercard charge debit fee</desc><g _fill="currentColor"><path pid="0" d="M21 3.5H3a3 3 0 00-3 3v11a3 3 0 003 3h18a3 3 0 003-3v-11a3 3 0 00-3-3zm-18 2h18a1 1 0 011 1v1a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-1a1 1 0 011-1zm18 13H3a1 1 0 01-1-1v-6a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v6a1 1 0 01-1 1z"/><path pid="1" d="M8 14.5H5a1 1 0 000 2h3a1 1 0 000-2z"/></g>'
  }
})
