<template>
    <div class="flex items-center flex-col space-y-4 justify-center md:flex-row md:space-y-0 md:space-x-4">
        <button
            v-for="type in manualInferenceTypes"
            :key="type.id"
            class="relative border rounded max-w-xs w-full md:w-1/3 py-8 flex space-x-2 justify-center items-center bg-gray-50 hover:border-purple-light"
            :class="{ 'opacity-50 cursor-not-allowed': loadingTemplatesForEventType !== null }"
            type="button"
            @click="selectEventType(type.id)"
        >
            <div v-if="loadingTemplatesForEventType === type.id" class="absolute inset-0 bg-snow-900 flex items-center justify-center">
                <app-icon
                    class="h-8 w-8 text-gray-500 mr-2"
                    name="loader"
                ></app-icon>
            </div>
            <span>{{ type.name }}</span>
        </button>
    </div>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
    name: 'EventTypeInferenceStep',

    props: {
        manualInferenceTypes: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            loadingTemplatesForEventType: null
        };
    },

    computed: {
        primaryEventTypeId: sync('CreateEvent/primary_event_type_id')
    },

    methods: {
        selectEventType (eventTypeId) {
            this.primaryEventTypeId = eventTypeId;
            this.loadingTemplatesForEventType = eventTypeId;
            this.$emit('completed');
        }
    }
};
</script>
