/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-christmas-tree-ornament-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>winter</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 5.25a9 9 0 100 18 9 9 0 100-18z"/><path pid="1" d="M4.36 19.022l2.49-2.5h-.01c.58-.59 1.52-.59 2.11-.01l1.99 1.95h0c.57.56 1.5.57 2.08.01l2.077-1.99h-.01c.59-.57 1.52-.56 2.1.02l2.436 2.43M19.678 9.55l-2.49 2.48v-.01c-.59.58-1.53.58-2.12 0l-2.01-1.98h0c-.6-.59-1.54-.58-2.13.01l-1.98 2v-.01c-.59.59-1.54.59-2.13.01l-.01-.01L4.26 9.5M12 2.25V.75M9 5.762V3.74h0c-.01-.83.67-1.51 1.49-1.51h3-.01c.82-.01 1.5.67 1.5 1.49 0 0 0 0 0 0v2.01"/></g>'
  }
})
