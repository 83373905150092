<template>
    <div class="relative">
        <input
            ref="input"
            v-model="time"
            placeholder="Time"
            class="form-field"
            :maxlength="in24Hour ? 5 : 8"
            :disabled="disabled"
            @paste.prevent=""
            @keydown.backspace="backspace"
            @keydown.delete.prevent
            @focus="openTimeSelector"
            @keydown.escape.prevent="closeTimeSelector"
            @keydown.tab.prevent="closeTimeSelector"
            @dblclick="reset"
            @keydown="filtering = true"
            @blur="validateTime"
        >

        <div
            v-if="clearable && time"
            class="absolute inset-y-0 right-2 flex items-center pl-4 pr-2 button-icon button-sm"
            @click="reset"
        >
            <app-icon
                class="h-4 w-4"
                name="close"
            ></app-icon>
        </div>

        <div
            v-show="timeSelectorOpen && filteredSelectableTimes.length > 0"
            ref="dropdown"
            v-click-outside="pleaseGodSafariWork"
            class="time-picker absolute max-h-xs bg-white overflow-y-auto w-40 p-0 rounded z-10 text-center border shadow"
            @mouseenter="isBlurListenerDisabled = true"
            @mouseleave="isBlurListenerDisabled = false"
        >
            <ul ref="selectableTimesList" class="cursor-pointer">
                <li
                    v-for="selectableTime in filteredSelectableTimes"
                    :key="selectableTime"
                    class="hover:bg-gray-200 hover:text-purple py-2"
                    @click="selectTime(selectableTime)"
                >{{ selectableTime }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import { DateTime } from 'luxon';

export default {
    props: {
        clearable: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        focusedSelectableTime: {
            type: String,
            default: null
        },

        in24Hour: {
            type: Boolean,
            default: () => {
                return Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).resolvedOptions().hourCycle === 'h23';
            }
        },

        minTime: {
            type: String,
            default: null
        },

        placeholder: {
            type: String,
            default: 'Select a time ...'
        },

        value: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            filtering: false,
            popper: null,
            time: '',
            timeSelectorOpen: false,
            isBlurListenerDisabled: false
        };
    },

    computed: {
        filteredSelectableTimes () {
            if (!this.filtering) {
                return this.selectableTimes;
            }

            return this.selectableTimes.filter((time) => {
                return time.toUpperCase().includes(
                    this.time.toUpperCase()
                );
            });
        },

        selectableTimes () {
            const hours = [];
            const minTime = this.minTime || '00:00';
            const nearest15Minutes = 15 - (DateTime.fromFormat(minTime, 'HH:mm').minute % 15);
            const roundedMinTime = DateTime.fromFormat(minTime, 'HH:mm').plus({ minute: nearest15Minutes === 15 ? 0 : nearest15Minutes }).toFormat('HH:mm');

            let time = DateTime.fromFormat(roundedMinTime, 'HH:mm');
            const endTime = DateTime.fromFormat('23:59', 'HH:mm');

            for (time; time < endTime; time = time.plus({ minutes: 15 })) {
                hours.push(this.in24Hour ? time.toFormat('HH:mm') : time.toFormat('hh:mm a'));
            }

            return hours;
        }
    },

    watch: {
        time (newVal) {
            this.time = this.in24Hour ? this.format24Hour(newVal) : this.format12Hour(newVal.toUpperCase());
        },

        value (newVal) {
            this.setTime(newVal);
        }
    },

    created () {
        if (this.value) {
            this.setTime(this.value);
        }

        window.addEventListener('scroll', this.handleScroll, true);
    },

    destroyed () {
        this.destroyPopper();

        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        backspace () {
            if (this.time.length === 3 || this.time.length === 6) {
                this.time = this.time.substring(0, this.time.length - 3);
                return;
            }

            this.time = this.time.substring(0, this.time.length - 2);
        },

        emitChange () {
            if (this.in24Hour) {
                this.$emit('input', this.time);
                return;
            }

            const isPm = this.time.substring(6).toUpperCase() === 'PM';
            const timeWithoutMeridiem = this.time.substring(0, 5);
            const [hours, minutes] = timeWithoutMeridiem.split(':');

            if (!isPm) {
                this.$emit('input', parseInt(hours, 10) === 12 ? `00:${minutes}` : timeWithoutMeridiem);
                return;
            }

            const timeIn24Hours = parseInt(hours, 10) === 12 ? `12:${minutes}` : `${(parseInt(hours, 10) + 12).toString()}:${minutes}`;

            this.$emit('input', timeIn24Hours);
        },

        format12Hour (time) {
            // If time is an empty string, return empty
            if (time.length === 0) {
                return '';
            }

            // The 1st digit must be a 0 or 1.  If greater than 1, pad with a 0.
            if (time.length === 1 && ![0, 1].includes(parseInt(time.charAt(0), 10))) {
                return time.padStart(2, '0');
            }

            // If the first two characters are 0:, replace with 00:.
            if (time.length === 2 && time === '0:') {
                return '00:';
            }

            // If the first two characters are 1:, replace with 01:.
            if (time.length === 2 && time === '1:') {
                return '01:';
            }

            // The first 2 digits must be mess than or equal to 12.  If not, reset.
            if (time.length === 2 && parseInt(time, 10) > 12) {
                return '';
            }

            // The first 2 digits must be numbers.  If not, reset.
            if (time.length === 2 && (Number.isNaN(parseInt(time[0], 10)) || Number.isNaN(parseInt(time[1], 10)))) {
                return '';
            }

            // The 3rd character must always be a :.
            if (time.length === 2) {
                return `${time}:`;
            }

            // The 4th digit must be a 1, 2, 3, 4, 5.  If greater, pad with a 0.
            if (time.length === 4 && ![0, 1, 2, 3, 4, 5].includes(parseInt(time.charAt(3), 10))) {
                return time[0] + time[1] + time[2] + 0 + time[3];
            }

            // The 4th and 5th digit combined must be less than 60.  If not, reset to hours
            if (parseInt(time[3] + time[4], 10) > 60) {
                return time[0] + time[1] + time[2];
            }

            // The 4th and 5th digit must both be numbers.  If not reset to hours.
            if (time.length === 5 && (Number.isNaN(parseInt(time[3], 10)) || Number.isNaN(parseInt(time[4], 10)))) {
                return time[0] + time[1] + time[2];
            }

            // The 6th digit must always be a space
            if (time.length === 5) {
                return `${time} `;
            }

            // The 7th digit must always be A or P
            if (time.length === 7 && !['A', 'P'].includes(time.charAt(6))) {
                return time.substring(0, 6);
            }

            // The 8th digit must be a M
            if (time.length === 7) {
                return `${time}M`;
            }

            return time;
        },

        format24Hour (time) {
            // If time is an empty string, return empty
            if (time.length === 0) {
                return '';
            }

            // The first digit must be a 0, 1, or 2.  If greater than 2, pad with a 0.
            if (time.length === 1 && ![0, 1, 2].includes(parseInt(time.charAt(0), 10))) {
                return time.padStart(2, '0');
            }

            // The first 2 digits must be mess than or equal to 23.  If not, reset.
            if (time.length === 2 && parseInt(time, 10) > 23) {
                return '';
            }

            // The first 2 digits must be numbers.  If not, reset.
            if (time.length === 2 && (Number.isNaN(parseInt(time[0], 10)) || Number.isNaN(parseInt(time[1], 10)))) {
                return '';
            }

            // The 3rd character must always be a :.
            if (time.length === 2) {
                return `${time}:`;
            }

            // The 4th digit must be a 1, 2, 3, 4, 5.  If greater, pad with a 0.
            if (time.length === 4 && ![0, 1, 2, 3, 4, 5].includes(parseInt(time.charAt(3), 10))) {
                return time[0] + time[1] + time[2] + 0 + time[3];
            }

            // The 4th and 5th digit combined must be less than 60.  If not, reset to hours
            if (parseInt(time[3] + time[4], 10) > 60) {
                return time[0] + time[1] + time[2];
            }

            // The 4th and 5th digit must both be numbers.  If not reset to hours.
            if (time.length === 5 && (Number.isNaN(parseInt(time[3], 10)) || Number.isNaN(parseInt(time[4], 10)))) {
                return time[0] + time[1] + time[2];
            }

            return time;
        },

        initializePopper () {
            if (this.popper) {
                return;
            }

            this.popper = createPopper(this.$refs.input, this.$refs.dropdown, {
                modifiers: [
                    { name: 'flip' },
                    { name: 'preventOverflow' }
                ],
                placement: 'bottom-start',
                strategy: 'fixed'
            });
        },

        handleScroll () {
            this.popper?.update();
        },

        destroyPopper () {
            this.popper?.destroy();
            this.popper = null;
        },

        pleaseGodSafariWork (event) {
            if (event.target !== this.$refs.input) {
                this.closeTimeSelector();
            }
        },

        closeTimeSelector () {
            this.filtering = false;
            this.timeSelectorOpen = false;
            this.destroyPopper();
        },

        async openTimeSelector () {
            this.timeSelectorOpen = true;

            this.initializePopper();

            if (!this.focusedSelectableTime) {
                return;
            }

            await this.waitTicks(1);

            const searchQuery = this.in24Hour
                ? this.focusedSelectableTime
                : DateTime.fromFormat(this.focusedSelectableTime, 'HH:mm').toFormat('hh:mm a');

            Array.from(this.$refs.selectableTimesList.children)
                .find((child) => {
                    return child.textContent === searchQuery;
                })
                ?.scrollIntoView();
        },

        reset () {
            if (!this.clearable) {
                return;
            }

            this.time = '';

            this.emitChange();
        },

        setTime (value) {
            if (value) {
                this.time = this.in24Hour ? DateTime.fromFormat(value, 'HH:mm').toFormat('HH:mm') : DateTime.fromFormat(value, 'HH:mm').toFormat('hh:mm a');
            }
        },

        selectTime (time) {
            this.time = time;

            this.closeTimeSelector();
            this.emitChange();
        },

        validateTime () {
            // There's a race condition between the blur listener on the input field and the click listener on the li
            // field. Because blur happens before and takes precedence, this listener is called and updates the time.
            // That causes the li elements to update, thus invalidating the click.
            if (this.isBlurListenerDisabled) {
                return;
            }

            if (this.in24Hour && this.format24Hour(this.time).length !== 5) {
                this.time = '';
            }

            if (!this.in24Hour && this.format12Hour(this.time).length === 6) {
                this.time += 'AM';
            }

            if (!this.in24Hour && this.format12Hour(this.time).length !== 8) {
                this.time = '';
            }

            this.emitChange();
        }
    }
};
</script>
