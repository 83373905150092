/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'family-frame-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>photo heart love</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.93 4.339l-.01-.01c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06l-1.18 1.21-1.18-1.22-.01-.01c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06h-.01c-.77.79-.77 2.04 0 2.84l3.51 3.63h0c.22.23.58.24.81.01 0-.01.01-.02.01-.02l3.51-3.64v-.01c.76-.8.76-2.05-.01-2.85zM16.35 12.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM20.25 20.25h0c-1.25-2.16-4-2.89-6.15-1.65-.69.39-1.26.96-1.65 1.64M7.64 12.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM11.543 20.25h0c-1.25-2.16-4-2.89-6.15-1.65-.69.39-1.26.96-1.65 1.64"/><rect pid="1" width="22.5" height="22.5" x=".75" y=".75" rx="1.5"/></g>'
  }
})
