/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dessert-cookie-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M20.973 8.989h-.01c-2.07 0-3.75-1.68-3.75-3.75-.01-.01 0-.02 0-.03v-.01c-.65.49-1.44.76-2.26.76v-.001c-2.07 0-3.75-1.68-3.76-3.75-.01-.52.1-1.02.3-1.49v-.01C5.28.95.46 6.18.71 12.388c.25 6.2 5.48 11.02 11.68 10.77 6.2-.26 11.02-5.49 10.77-11.69a11.51 11.51 0 00-.53-2.98v-.01c-.53.27-1.12.42-1.72.42z"/><path pid="1" d="M7.47 8.23a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM14.22 15.73a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM7.1 16.48h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M13.03 9.74h-.01c.2-.04.39.1.43.3.03.2-.11.39-.31.43-.21.03-.4-.11-.44-.31l-.01-.01-.01-.01c-.04-.21.1-.4.3-.43V9.7M19.038 12.74h-.01c.2-.04.39.1.43.3.03.2-.11.39-.31.43-.21.03-.4-.11-.44-.31l-.01-.01-.01-.01c-.04-.21.1-.4.31-.43"/></g>'
  }
})
