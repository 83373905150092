import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@root/tailwind.config';

const tailwind = resolveConfig(tailwindConfig);

export default {
    data () {
        return {
            screenWidth: window.innerWidth
        };
    },

    mounted () {
        window.addEventListener('resize', this.windowResizeEventHandler);
    },

    beforeDestroy () {
        window.removeEventListener('resize', this.windowResizeEventHandler);
    },

    methods: {
        windowResizeEventHandler () {
            this.screenWidth = document.documentElement.clientWidth;
        },

        screenWidthIs (operator, screenClass) {
            const screenClassWidth = tailwind.theme.screens[screenClass].replace('px', '');

            switch (operator) {
                case 'lt':
                    return this.screenWidth < screenClassWidth;
                case 'lte':
                    return this.screenWidth <= screenClassWidth;
                case 'gt':
                    return this.screenWidth > screenClassWidth;
                case 'gte':
                    return this.screenWidth >= screenClassWidth;
                default:
                    break;
            }

            return false;
        }
    }
};
