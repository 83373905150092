<template>
    <div>
        <div
            :id="blockId"
            v-theme="'wall.background'"
            class="relative p-6"
        >
            <div
                v-if="displayTitle"
                v-theme="['wall.title-color', 'wall.title-font', 'wall.title-font-size']"
                class="flex justify-center items-center flex-auto mb-6 font-bold leading-normal"
            >
                <app-icon
                    v-if="icon"
                    :name="icon"
                    class="h-8 w-8 mr-4"
                ></app-icon>

                {{ title }}
            </div>

            <div
                v-for="accommodation in activeAccommodations"
                :key="accommodation.id"
                class="flex mt-6"
            >
                <div v-if="accommodation.images.length > 0" class="mr-6 shrink-0">
                    <img
                        v-for="image in accommodation.images"
                        :key="image.id"
                        class="w-32 rounded-md"
                        :src="image.src"
                    >
                </div>
                <div v-theme="'wall.text'" class="items-start">
                    <div class="text-left leading-normal">
                        <p class="font-semibold">{{ accommodation.name }}</p>
                        <p class="text-sm whitespace-pre-wrap break-anywhere opacity-75">{{ accommodation.description }}</p>

                        <div v-if="shouldShowUrl(accommodation)" class="inline-block text-sm mt-2 mr-6">
                            <div class="flex items-center">
                                <app-icon
                                    v-theme="'wall.accent-icon'"
                                    name="link"
                                    class="shrink h-4 w-4 mr-2"
                                ></app-icon>

                                <a
                                    class="shrink no-underline text-sm"
                                    :href="normalizeUrl(accommodation.url)"
                                    target="_blank"
                                    rel="nofollow"
                                >
                                    {{ accommodation.buttonText }}
                                </a>
                            </div>
                        </div>

                        <div v-if="shouldShowMap(accommodation)" class="inline-block text-sm">
                            <div class="flex items-center">
                                <app-icon
                                    v-theme="'wall.accent-icon'"
                                    name="location-pin-stroke"
                                    class="shrink h-4 w-4 mr-2"
                                ></app-icon>

                                <a
                                    class="shrink no-underline text-sm"
                                    :href="accommodationMapLink(accommodation)"
                                    target="_blank"
                                    rel="nofollow"
                                >
                                    View On Map
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import WallBlock from '@/mixins/WallBlock';
import NormalizesUrls from '@/mixins/NormalizesUrls';

export default {
    name: 'WallAccommodationsBlock',

    mixins: [
        WallBlock,
        NormalizesUrls
    ],

    props: {
        accommodations: {
            type: Array,
            required: true
        },
        displayTitle: {
            type: Boolean,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },

    computed: {
        activeAccommodations () {
            return this.accommodations.filter((accommodation) => { return accommodation.active; });
        }
    },

    methods: {
        accommodationMapLink (accommodation) {
            return `//www.google.com/maps?&q=${accommodation.address.address}`;
        },

        shouldShowMap (accommodation) {
            return accommodation.viewOnMap && accommodation.address;
        },

        shouldShowUrl (accommodation) {
            return accommodation.includeUrl && accommodation.url;
        }
    }
};
</script>
