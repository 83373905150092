/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'duplicate-square-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>clone copy</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="19.5" height="19.5" x="3.75" y=".746" rx="1.5"/><path pid="1" d="M20.25 23.246H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-18M14.25 5.25v9M9.75 9.75h9"/></g>'
  }
})
