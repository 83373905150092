<template>
    <app-modal
        :value="value"
        :title="modalTitle"
        @input="$emit('input', $event)"
    >
        <form-field-wrapper
            label="Coupon Code"
            tooltip="The code guests will enter at checkout."
            :should-show-error="form.errors.has('code')"
            :error="form.errors.get('code')"
        >
            <input
                v-model="form.code"
                class="form-field uppercase"
                placeholder="Enter a Code"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Discount" tooltip="Apply a percentage-based discount or fixed discount.">
            <select-list v-model="form.type" @input="form.discount=''">
                <select-option
                    id="percentage"
                    class="my-2"
                    label="Percentage"
                ></select-option>
                <select-option
                    id="fixed"
                    class="my-2"
                    label="Fixed Amount"
                ></select-option>
            </select-list>

            <div v-if="form.errors.has('type')" class="alert alert-error alert-sm mt-2">
                <p class="w-full font-normal text-center">{{ form.errors.get('type') }}</p>
            </div>

            <div v-if="form.type" class="relative w-24 mt-4">

                <money-field v-if="form.type === 'fixed'" v-model.number="form.discount"></money-field>

                <div v-else>
                    <input
                        v-model.number="form.discount"
                        class="appearance-none block w-full text-gray-600 font-semibold border-b py-1 bg-transparent focus:outline-none"
                        type="number"
                    >

                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-500 font-bold">
                        <span>%</span>
                    </div>
                </div>

            </div>

            <div v-if="form.errors.has('discount')" class="alert alert-error alert-sm mt-2">
                <p class="w-full font-normal text-center">
                    {{ form.errors.get('discount') }}
                </p>
            </div>
        </form-field-wrapper>

        <form-panel
            v-model="form.hasExpiryDate"
            label="Set Expiration Date"
            tooltip="Prevent redemptions of this coupon after a pre-set date (event's timezone)."
            @collapse-form-panel="form.expires_at = null"
        >
            <div>
                <date-time-picker
                    v-model="form.expires_at"
                    placeholder="Select Date"
                    title="Coupon Expiration Date"
                    :has-time="false"
                    class="w-full"
                ></date-time-picker>
            </div>

            <div v-if="form.errors.has('expires_at')" class="alert alert-error alert-sm mt-2">
                <p class="w-full font-normal text-center">{{ form.errors.get('expires_at') }}</p>
            </div>
        </form-panel>

        <form-panel
            v-model="form.hasRedemptions"
            label="Limit Number of Redemptions"
            tooltip="Prevent redemptions of this coupon after a pre-set number of uses."
            @collapse-form-panel="form.max_redemptions = null"
        >
            <div class="flex mt-4 items-center">
                <div class="w-16">
                    <input
                        v-model="form.max_redemptions"
                        class="form-field"
                        type="number"
                    >
                </div>

                <div class="px-4">
                    Redemptions
                </div>
            </div>

            <div v-if="form.errors.has('max_redemptions')" class="alert alert-error alert-sm mt-2">
                <p class="w-full font-normal text-center">
                    {{ form.errors.get('max_redemptions') }}
                </p>
            </div>
        </form-panel>

        <form-panel
            v-model="hasLimits"
            label="Limit What Coupon Applies To"
            tooltip="Select what events and blocks can this coupon be applied to."
            @collapse-form-panel="form.discountables = []"
        >
            <div class="p-4 mt-4 bg-white border rounded-md">
                <div v-if="noAvailableDiscountables" class="text-center">
                    <p class="italic text-gray-500">You don't have any products yet!</p>
                    <a :href="route('forms.edit', event)" class="button button-primary mt-6">Form Builder</a>
                </div>
                <template v-else>
                    <div class="flex items-center">
                        <div class="grow md:grow-0">
                            <search-field
                                v-model="searchTerm"
                                class="w-full md:w-80"
                                placeholder="Find a ticket or add-on..."
                            ></search-field>
                        </div>
                    </div>

                    <div class="mt-4">
                        <form-field-wrapper label="Tickets & Add-ons">
                            <select-list v-model="discountablesSelection" multi-select>
                                <select-option
                                    v-for="discountable in filteredAvailableDiscountables"
                                    :id="getUniqueDiscountableId(discountable)"
                                    :key="discountable.id"
                                    :label="discountable.discountable_label"
                                    class="my-2"
                                ></select-option>
                            </select-list>
                        </form-field-wrapper>
                    </div>
                </template>
            </div>
        </form-panel>

        <template #footer="{ close }">
            <stateful-button
                class="button button-primary disabled:opacity-50 disabled:cursor-not-allowed"
                :loading="form.processing"
                @click="saveCoupon"
            >
                Save
            </stateful-button>

            <button class="button" @click="close">
                Cancel
            </button>
        </template>

        <template #footer-split-options>
            <button
                v-if="isEditing"
                class="button-text button-primary"
                @click="deleteCoupon"
            >
                <app-icon name="trash" class="mr-2 w-4 h-4"></app-icon>
                Delete Coupon
            </button>
        </template>
    </app-modal>
</template>

<script>
import { get } from 'vuex-pathify';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';
import Form from '@/validation/Form';

export default {
    name: 'CouponDetails',

    mixins: [GeneratesUniqueKey],

    props: {
        value: {
            type: Boolean,
            required: true
        },
        initialCoupon: {
            type: Object,
            required: true
        },
        availableDiscountables: {
            type: Array,
            required: true
        },
        currency: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            form: new Form({
                ...this.initialCoupon
            }),
            discountablesSelection: [],
            hasLimits: false,
            searchTerm: ''
        };
    },

    computed: {
        event: get('Event/event'),

        filteredAvailableDiscountables () {
            return this.availableDiscountables.filter((discountable) => {
                const searchRegex = new RegExp(this.searchTerm, 'i');

                return searchRegex.test(discountable.label);
            });
        },

        isEditing () {
            return !!this.form.id;
        },

        modalTitle () {
            return this.isEditing ? 'Edit Coupon' : 'Add New Coupon';
        },

        noAvailableDiscountables () {
            return !this.availableDiscountables.length;
        }
    },

    watch: {
        initialCoupon () {
            this.form = new Form({
                ...this.initialCoupon
            });
            this.setDiscountablesSelection();
            this.setHasLimitsToggle();
            this.initializeType();
        },

        discountablesSelection (newSelection) {
            this.form.discountables = filter(this.availableDiscountables, (discountable) => {
                return includes(newSelection, this.getUniqueDiscountableId(discountable));
            });

            this.hasLimits = this.form.discountables.length > 0;
        }
    },

    mounted () {
        this.setDiscountablesSelection();
        this.setHasLimitsToggle();
        this.initializeType();
    },

    methods: {
        deleteCoupon () {
            this.$emit('delete-coupon', this.coupon);
        },

        getUniqueDiscountableId (discountable) {
            return `${discountable.id}-${discountable.discountable_type}`;
        },

        initializeType () {
            if (!this.form.type) {
                this.form.type = 'percentage';
            }
        },

        saveCoupon () {
            if (this.form.code) {
                this.form.code = this.form.code.toUpperCase();
            }

            this.form.post(this.route('api.coupons.validate-store', this.event)).then(() => {
                this.$emit('save-coupon', this.form);
            });
        },

        setDiscountablesSelection () {
            if (!this.initialCoupon.discountables) {
                this.discountablesSelection = [];

                return;
            }

            this.discountablesSelection = this.initialCoupon.discountables.map((discountable) => {
                return `${discountable.discountable_id}-${discountable.discountable_type}`;
            });
        },

        setHasLimitsToggle () {
            if (!this.form.discountables) {
                this.hasLimits = false;

                return;
            }

            this.hasLimits = this.form.discountables.length > 0;
        }

    }
};
</script>
