/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'app-page-account-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="21" height="19.5" x="1.499" y="2.25" rx="1.5"/><path pid="1" d="M1.5 6.75h21"/><rect pid="2" width="6" height="6" x="4.499" y="9.75" rx=".75"/><path pid="3" d="M4.5 18.75h6"/><rect pid="4" width="6" height="9" x="13.499" y="9.75" rx=".75"/><path pid="5" d="M13.5 14.25h6"/></g>'
  }
})
