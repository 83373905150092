/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'animal-chicken-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 8.13H.74c.59-.41 1.28-.62 2-.63h0c2.34.14 4.12 2.15 3.99 4.5v1.5h0c0 3.72 3.02 6.74 6.75 6.74a6.76 6.76 0 006.74-6.76v-6 0c0-2.08-1.68-3.75-3.75-3.75-2.08 0-3 1.67-3 3.75v2.25-.01c-.06.8-.7 1.44-1.5 1.5H9.72s-.75 3.75 3 3.75"/><path pid="1" d="M.75 12.012H.74a4.371 4.371 0 015.93-.79M.75 15.762s2.26-3.779 6.044-1.48M16.875 6.762h-.01c-.21 0-.38.16-.38.37 0 0 0 0 0 0M17.25 7.137v0c0-.21-.17-.375-.375-.375"/><path pid="2" d="M16.875 7.512h0c.2 0 .37-.17.37-.38l-.001-.01M16.5 7.137h-.01c-.01.2.16.37.37.37v-.01M20.175 6.76c1.52-.05 3.07 1.17 3.07 2.4-.75.6-3 .6-3 .6M14.526 4.512h-.28s-.75 0-.75-1.363c0-1.318 1.51-2.387 3.375-2.387 1.86 0 3.375 1.06 3.375 2.38v-.01c-.09.52-.36 1-.75 1.36l-.37.33M13.5 20.26v3"/></g>'
  }
})
