/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gift-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>present donation box bow ribbon surprise</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.75 11.25H2.25v10.5h0c0 .82.67 1.49 1.5 1.49h16.5-.01c.82 0 1.5-.68 1.5-1.5zM21.75 6.75H2.24c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v2.25h0c0 .41.33.74.75.74h21-.01c.41 0 .75-.34.75-.75V8.239c0-.83-.68-1.5-1.5-1.5h-.01zM11.25 6.75c-3.314 0-6.75-2.686-6.75-6M4.5.75c3.314 0 6.75 2.686 6.75 6M12.75 6.75c3.314 0 6.75-2.686 6.75-6M19.5.75c-3.314 0-6.75 2.686-6.75 6"/><path pid="1" d="M9.75 6.75h4.5v16.5h-4.5z"/></g>'
  }
})
