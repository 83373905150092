/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin-bar-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.75 9.754v0c0 1.65-1.35 3-3 3-1.66 0-3-1.35-3-3v-1.5h6zM11.75 12.75v3M10.25 15.75h3M10.625 5.254h-.01c.2-.01.37.16.37.37"/><path pid="1" d="M10.25 5.629h0c-.01-.21.16-.38.37-.38M10.625 6h-.01a.386.386 0 01-.38-.38s0 0 0 0M11 5.629v-.01c-.01.2-.17.37-.38.37M13.625 3.754h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M13.25 4.129h0c-.01-.21.16-.38.37-.38M13.625 4.5h-.01a.386.386 0 01-.38-.38s0 0 0 0M14 4.129v0c0 .2-.17.375-.375.375"/><path pid="3" d="M20.75 9.754c0 7.9-6.932 12.33-8.629 13.3v-.01a.77.77 0 01-.75 0c-1.7-.97-8.63-5.4-8.63-13.3h0a8.988 8.988 0 018.99-9.01c4.97-.01 9 4.02 9 8.99 0 0 0 0 0 0z"/></g>'
  }
})
