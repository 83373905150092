<template>
    <simple-picker
        :value="value"
        :items="eventTypes"
        item-label="name"
        item-value="id"
        placeholder-empty-state="Select event type..."
        @input="$emit('input', $event)"
    >
        <div
            slot="input"
            slot-scope="{ selected }"
            class="w-full flex items-center text-gray-700"
        >
            <app-icon
                v-if="selected.icon"
                :name="selected.icon"
                class="w-6 h-6 mr-2"
            ></app-icon>
            <span>{{ selected.name }}</span>
        </div>

        <div
            slot="item"
            slot-scope="{ item }"
            class="w-full flex items-center"
        >
            <app-icon
                v-if="item.icon"
                :name="item.icon"
                class="w-6 h-6 mr-2"
            ></app-icon>
            <span>{{ item.name }}</span>
        </div>
    </simple-picker>
</template>

<script>
export default {
    name: 'EventTypePicker',

    props: {
        value: {
            type: Number,
            default: null
        },

        eventTypes: {
            type: Array,
            required: true
        }
    }
};
</script>
