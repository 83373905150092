<template>
    <div>
        <div
            v-for="(guest, index) in guests"
            :key="index"
        >
            <div class="font-semibold text-sm">{{ $t('text-guest-of') }} {{ invitee.formalName }} ({{ index + 1 }})</div>

            <guest-details
                v-model="guests[index]"
                :require-name="product.settings.requireName.enabled"
                :require-title="requireGuestTitle"
                :require-email="product.settings.requireEmail.enabled"
                class="-ml-6"
                text-class="text-sm"
            ></guest-details>
        </div>
    </div>
</template>

<script>
import times from 'lodash/times';
import isNumber from 'lodash/isNumber';
import Errors from '@/validation/Errors';

const defaultGuest = {
    title_id: null,
    first_name: '',
    last_name: '',
    email: ''
};

export default {
    name: 'AdditionalGuests',

    props: {
        errorBag: {
            type: Errors,
            default: () => { return new Errors(); }
        },
        invitee: {
            type: Object,
            required: true
        },
        product: {
            type: Object,
            required: true
        },
        quantity: {
            type: Number,
            required: true
        },
        requireGuestTitle: {
            type: Boolean,
            default: true
        },
        value: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            guests: this.getPlaceholderGuests(this.quantity)
        };
    },

    watch: {
        guests: {
            deep: true,
            handler () {
                this.$emit('input', this.guests);
            }
        },

        quantity (newValue, oldValue) {
            if (!isNumber(newValue)) {
                return;
            }

            const difference = Math.max(newValue, 0) - Math.max(oldValue, 0);

            if (difference < 0) {
                this.guests.splice(
                    this.guests.length + difference,
                    Math.abs(difference)
                );
            } else {
                this.guests.push(
                    ...this.getPlaceholderGuests(difference)
                );
            }
        }
    },

    created () {
        this.guests = this.value;
    },

    methods: {
        getPlaceholderGuests (quantity) {
            return times(quantity, () => {
                return { ...defaultGuest };
            });
        }
    }
};
</script>
