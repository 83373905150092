/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'laptop-launch-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 5.25h1.5-.01c.82-.01 1.5.67 1.5 1.5v7.5c0 .82-.68 1.5-1.5 1.5h-2.25M7.5 15.75H5.25l-.01-.001a1.51 1.51 0 01-1.5-1.5V6.748h0c-.01-.83.67-1.51 1.49-1.51h1.5"/><path pid="1" d="M18.571 15.75l-.01-.001c1.13 0 2.17.64 2.68 1.65l1.83 3.67-.01-.01c.37.74.07 1.64-.68 2.01-.21.1-.44.15-.68.15H2.19c-.83 0-1.51-.68-1.51-1.5-.01-.24.05-.47.15-.68l1.83-3.68v-.01c.5-1.02 1.54-1.66 2.68-1.66M14.25 3.95C14.25 2.17 12 .74 12 .74s-2.25 1.435-2.25 3.2v7.48h4.5zM9.75 11.437H7c-.17 0-.33-.07-.45-.19h0c-.11-.12-.15-.27-.11-.42h-.01a5.093 5.093 0 013.28-3.15"/><path pid="2" d="M14.25 11.437h2.73c.16 0 .32-.07.44-.19v-.01c.1-.12.14-.27.09-.42h0a5.13 5.13 0 00-3.29-3.15M10.5 14.25v2.25M13.5 14.25v4.5"/></g>'
  }
})
