/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-eating-set-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>restaurant knife fork plate utensils silverware flatware</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M2.25 9v13.5M20.247 15H22.5c.41 0 .74-.34.74-.75 0-3.84.02-8.458-1.56-12.289l-.01-.01a.746.746 0 00-.98-.41c-.29.11-.47.38-.47.69v20.25M.75 1.5v6h0c0 .82.67 1.49 1.5 1.49v0l-.01-.001c.82 0 1.5-.68 1.5-1.5v-6M16.5 8.283h0a7.18 7.18 0 00-9.75-.71M6 18.71l-.01-.01a7.183 7.183 0 0010.14.35c.12-.12.24-.24.35-.36"/></g>'
  }
})
