import { Mark } from 'tiptap';
import { updateMark, toggleMark } from 'tiptap-commands';

const defaultSize = '16px';

const allowedSizes = [
    '10px',
    '16px',
    '20px',
    '28px',
    '32px',
    '40px'
];

export default class TextSize extends Mark {
    get name () {
        return 'textSize';
    }

    get schema () {
        return {
            attrs: {
                textSize: {
                    default: defaultSize
                }
            },
            content: 'inline*',
            group: 'block',
            draggable: false,
            parseDOM: [
                {
                    style: 'font-size',
                    getAttrs: (value) => {
                        if (allowedSizes.includes(value)) {
                            return { textSize: value };
                        }

                        return { textSize: defaultSize };
                    }
                }
            ],
            toDOM: (mark) => {
                return [
                    'span',
                    { style: `font-size: ${mark.attrs.textSize}` },
                    0
                ];
            }
        };
    }

    commands ({ type }) {
        return (attrs) => {
            if (attrs.textSize) {
                return updateMark(type, attrs);
            }

            return toggleMark(type, attrs);
        };
    }
}
