/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-sanitizer-spray-virus-block-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.673 19.577l-.01-.01a3.492 3.492 0 01.01-4.94 3.476 3.476 0 014.92 0M20.632 17.109v-.01a3.5 3.5 0 01-3.49 3.49M16.56 11h1.16M17.14 11v2.62M23.25 16.53v1.16M23.25 17.11h-2.62M21.87 21.02l-.82.82M21.46 21.43l-1.85-1.85M17.72 23.22h-1.16M17.14 23.22V20.6M11.03 17.69v-1.16M11.03 17.11h2.62M12.41 13.2l.82-.82M12.82 12.79l1.85 1.85M23.25 11L11.03 23.22M8.525 22.235H3.67l-.01-.001a2.934 2.934 0 01-2.93-2.93v-8.54h0c-.01-.78.3-1.53.85-2.08l2.06-2.07h5.851L11.57 8.68h0c.35.36.61.8.75 1.3"/><path pid="1" d="M5.62.78h1.95-.01c1.07-.01 1.95.87 1.95 1.94v3.9H3.65v-3.9h0C3.64 1.64 4.52.76 5.59.76c0-.001 0 0 0 0zM14.91 1.93l2.94-.8M14.91 6.71l2.94.8M14.91 4.32h2.94"/></g>'
  }
})
