/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-golf-hole-aim-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 11.019V.75l4.21 2.1h0a.5.5 0 01.22.68c-.05.09-.14.17-.23.22l-1.97.98M23.25 6l-2.17 16.6v-.01c-.05.37-.37.65-.75.65h-2.72l-.01-.001c-1.04-.01-1.88-.84-1.88-1.88s.83-1.88 1.87-1.88h0c.72 0 1.42.27 1.95.76l1.62 1.48M11.25 19.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/><path pid="1" d="M8.25 8.273c3.375.2 6 1.8 6 3.72 0 2.07-3.03 3.75-6.75 3.75-3.73 0-6.75-1.68-6.75-3.75H.74c.07-.99.63-1.88 1.49-2.36"/></g>'
  }
})
