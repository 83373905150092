<template>
    <section class="panel flex flex-col">
        <page-header icon="user-card-stroke" label="Titles & Salutations">
            <a class="button-icon button-sm" :href="route('settings.event', eventId)">
                <app-icon name="close" class="h-4 w-4"></app-icon>
            </a>
        </page-header>

        <div class="mb-6">
            <button class="button-text button-info" @click="isNewTitleFieldVisible = true">
                <app-icon
                    name="add-circle"
                    class="h-6 w-6 mr-2"
                    stroke
                ></app-icon>
                <span>Add Title</span>
            </button>
        </div>

        <transition name="slide-vertical">
            <div v-if="isNewTitleFieldVisible" class="w-full md:w-5/12">
                <form-field-wrapper label="Title">
                    <vue-autosuggest
                        v-model="filterQuery"
                        :input-props="autosuggestInputProps"
                        :suggestions="[{ data: filteredSuggestions }]"
                        :render-suggestion="getSuggestionValue"
                        :get-suggestion-value="getSuggestionValue"
                        @selected="handleSelected"
                    ></vue-autosuggest>
                </form-field-wrapper>
            </div>
        </transition>

        <div v-if="form.errors.has('value')" class="alert alert-error alert-sm mt-2 w-full md:w-1/2">
            <p class="w-full font-normal text-center">{{ form.errors.get('value') }}</p>
        </div>

        <div class="flex flex-col w-full md:w-1/2">
            <draggable
                :value="titles"
                v-bind="draggableOptions"
                @input="handleOrderUpdate"
            >
                <transition-group>
                    <div
                        v-for="title in titles"
                        :key="title.id"
                        class="flex items-center justify-center mt-4"
                    >
                        <div class="flex-auto p-4 rounded-md shadow border">
                            <p>{{ title.value }}</p>
                        </div>

                        <template v-if="titles.length > 1">
                            <a class="button-icon draggable-handle cursor-grab" role="button">
                                <app-icon
                                    name="move-vertical-arrows"
                                    class="h-6 w-6 mx-2"
                                    stroke
                                ></app-icon>
                            </a>

                            <delete-item
                                class="button-icon hover:text-red"
                                :endpoint="route('api.titles.destroy', [eventId, title.id])"
                                :confirmation-message="{ title: 'Are you sure?', text: 'Do you really want to remove this title?' }"
                                @delete-item="deleteTitle(title.id)"
                                @error="deleteError($event.response.data.error)"
                            >
                                <app-icon
                                    name="trash"
                                    class="h-6 w-6"
                                    stroke
                                ></app-icon>
                            </delete-item>
                        </template>
                    </div>
                </transition-group>
            </draggable>
        </div>
    </section>
</template>

<script>
import draggable from 'vuedraggable';
import { VueAutosuggest } from 'vue-autosuggest';
import { get, sync } from 'vuex-pathify';
import axios from '@/util/axios';
import Deleteable from '@/mixins/Deleteable';
import Form from '@/validation/Form';

export default {
    name: 'TitlesAndSalutations',

    components: { draggable, VueAutosuggest },

    mixins: [Deleteable],

    props: {
        defaultTitles: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            filterQuery: '',
            isNewTitleFieldVisible: false,
            form: new Form({
                value: null
            })
        };
    },

    computed: {
        eventId: get('Event/event@id'),
        titles: sync('Event/event@titles'),

        autosuggestInputProps () {
            return {
                class: 'form-field relative',
                placeholder: 'Enter new title'
            };
        },

        filteredSuggestions () {
            const suggestions = this.defaultTitles.filter((title) => {
                const titleValue = title.value;

                return titleValue.toUpperCase().includes(
                    this.filterQuery.toUpperCase()
                );
            });

            if (suggestions.length > 0) {
                return suggestions;
            }

            return [{ id: null, value: `Create "${this.filterQuery}" title` }];
        },

        draggableOptions () {
            return {
                animation: 150,
                handle: '.draggable-handle'
            };
        }
    },

    methods: {
        getSuggestionValue (suggestion) {
            return suggestion.item.value;
        },

        handleSelected (suggestion) {
            if (!suggestion && !this.filterQuery) {
                return;
            }

            this.createTitle(
                suggestion && suggestion.item.id ? suggestion.item.value : this.filterQuery
            );

            this.filterQuery = '';
            this.isNewTitleFieldVisible = false;
        },

        createTitle (newTitle) {
            if (this.isAssociatedWithEvent(newTitle)) {
                this.form.errors.clear();

                return;
            }

            this.form.value = newTitle;

            this.form.post(this.route('api.titles.store', this.eventId))
                .then(({ data }) => {
                    this.titles.push(data);

                    this.$toasted.global.success('Your title has been created.');
                }).catch(() => {
                    this.isNewTitleFieldVisible = true;
                });
        },

        isAssociatedWithEvent (titleValue) {
            const index = this.titles.findIndex((title) => {
                return title.value === titleValue;
            });

            return index !== -1;
        },

        deleteTitle (titleId) {
            this.titles = this.titles.filter((title) => {
                return title.id !== titleId;
            });

            this.$toasted.global.success('Your title has been removed.');
        },

        handleOrderUpdate (newTitles) {
            this.titles = newTitles;

            const sort = newTitles.reduce((carry, title, index) => {
                return { ...carry, [title.id]: index + 1 };
            }, {});

            axios
                .patch(this.route('api.titles.sort', this.eventId), { sort })
                .then(() => {
                    this.$toasted.global.success('Titles have been updated.');
                })
                .catch(() => {
                    this.$toasted.global.error('There was a problem saving the order of the titles.');
                });
        }
    }
};
</script>
