/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'army-badge-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>military rank grade star navy marine air force</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.564 1.01h-.01c.12-.25.41-.34.65-.22.09.04.16.12.21.21l1.01 2.04-.01-.01c.07.14.2.24.36.26l2.27.32h0c.18.02.33.15.39.32h0c.05.17 0 .36-.13.48l-1.65 1.6h-.01c-.12.1-.17.26-.14.42l.38 2.25-.01-.01c.03.18-.05.36-.2.46h-.01c-.16.1-.35.11-.52.03L12.11 8.1h0a.5.5 0 00-.46-.01L9.62 9.14v-.01a.51.51 0 01-.52-.04h0a.487.487 0 01-.2-.47l.38-2.255h-.001a.516.516 0 00-.14-.43l-1.65-1.6-.01-.01a.478.478 0 01-.13-.49v-.01c.05-.18.2-.3.39-.33l2.27-.33v-.01c.15-.03.29-.13.36-.27zM5.25 13.257l5.75 5.11-.01-.01c.56.5 1.42.5 1.99 0l5.753-5.12"/><path pid="1" d="M18.247 18.2L13 22.87h-.01c-.57.5-1.43.5-2-.01l-5.26-4.68-.01-.01c-.32-.29-.51-.7-.5-1.13v-6.66h-.01c0-.42.33-.75.75-.75.18 0 .35.06.49.18l4.5 4-.01-.01c.56.5 1.42.5 1.99 0l4.5-4v-.01a.735.735 0 011.23.55v6.65h-.01c-.01.42-.19.83-.51 1.11z"/></g>'
  }
})
