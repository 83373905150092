<template>
    <div>
        <div class="flex flex-col md:flex-row">
            <div class="flex-auto md:self-start rounded-md border bg-white p-4 shadow-md">
                <form-field-wrapper label="Title">
                    <input
                        id="announcement-title"
                        v-model="announcement.title"
                        name="title"
                        class="form-field"
                        placeholder="Title"
                    >
                </form-field-wrapper>

                <form-field-wrapper label="Content">
                    <textarea
                        id="announcement-content"
                        v-model="announcement.content"
                        name="content"
                        rows="10"
                        class="form-field"
                        placeholder="Content text. Can be paragraph."
                    ></textarea>
                </form-field-wrapper>

                <form-field-wrapper label="Action Text">
                    <input
                        id="announcement-action-text"
                        v-model="announcement.action_text"
                        name="action_text"
                        class="form-field"
                        placeholder="RSVPify"
                    >
                </form-field-wrapper>

                <form-field-wrapper label="URL">
                    <input
                        id="announcement-url"
                        v-model="announcement.url"
                        name="url"
                        class="form-field"
                        placeholder="https://rsvpify.com"
                    >
                </form-field-wrapper>

                <form-field-wrapper label="Image URL">
                    <input
                        id="announcement-image"
                        v-model="announcement.image"
                        name="image"
                        class="form-field"
                        placeholder="http://via.placeholder.com/640x360"
                    >
                </form-field-wrapper>

                <div class="flex mt-6">
                    <div class="flex-auto">
                        <button
                            class="w-full bg-purple text-white p-4 rounded-md font-semibold hover:bg-purple-lighter"
                            type="submit"
                        >
                            {{ buttonLabel }}
                        </button>
                    </div>
                    <div class="ml-2">
                        <a
                            :href="route('admin.announcements.index')"
                            class="flex items-center no-underline p-4 rounded-md font-semibold bg-gray-200 hover:bg-gray-300 text-gray-800"
                            role="button"
                        >
                            <app-icon name="close" class="h-4 w-4 mr-2 text-gray-700"></app-icon>
                            <span>Cancel</span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="md:w-80 ml-0 mt-6 md:mt-0 md:ml-8">
                <announcement-display
                    class="rounded-md border bg-white p-4 shadow-md w-full md:self-start"
                    :announcement="announcement"
                >
                </announcement-display>
            </div>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

export default {

    props: {
        initialAnnouncement: {
            type: Object,
            default: () => { return {}; }
        }
    },

    data () {
        return {
            announcement: cloneDeep(this.initialAnnouncement)
        };
    },

    computed: {
        buttonLabel () {
            return this.isEditing ? 'Update' : 'Create';
        },

        isCreating () {
            return isEmpty(this.initialAnnouncement);
        },

        isEditing () {
            return !this.isCreating;
        }
    }
};
</script>
