/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashboard-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>gauge speedometer</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 4.5v-.01C5.73 4.53.7 9.65.74 15.91s0 0 0 0v2.07h0c0 .82.67 1.49 1.5 1.49h19.5-.01c.82 0 1.5-.68 1.5-1.5v-2.09c.04-6.26-5-11.38-11.26-11.42z"/><path pid="1" d="M9 19.5v-.75h0a2.996 2.996 0 115.99-.02s0 0 0 0v.75M14.25 9L12 15.75M4.88 15h0M4.875 15h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.38-.38-.375M6.38 11.25h0M6.375 11.253h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375M19.12 15h0M19.125 15h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.38-.38-.375M17.62 11.25h0M17.625 11.253h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375M9.38 8.25h0M9.37 8.253h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375"/></g>'
  }
})
