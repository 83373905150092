/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-vaccine-people-shield-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.75 14.6a3.14 3.14 0 100 6.28 3.14 3.14 0 100-6.29zM17.23 12.25h1.04M17.75 12.25v2.36M21.27 13.49l.74.74M21.64 13.86l-1.67 1.67M23.25 17.23v1.04M23.25 17.75h-2.36M22.01 21.27l-.74.74M21.64 21.64l-1.67-1.67M18.27 23.25h-1.04M17.75 23.25v-2.36M14.23 22.01l-.74-.74M13.86 21.64l1.67-1.67M12.25 18.27v-1.04M12.25 17.75h2.36M13.49 14.23l.74-.74M13.86 13.86l1.67 1.67"/><path pid="1" d="M19.25 8.25v-4.5c0-.56-.32-1.07-.83-1.3l-.01-.01A20.553 20.553 0 009.98.72h0c-2.9-.03-5.78.55-8.43 1.71h-.01c-.51.23-.83.73-.83 1.29v7.22c0 3.532 1.54 8.35 8.22 10.92h0c.65.25 1.38.25 2.04-.01"/><path pid="2" d="M10.14 4.41a2.79 2.79 0 100 5.58 2.79 2.79 0 100-5.59zM11.867 11.73h0c-2.34-.95-5.01.17-5.96 2.51a4.35 4.35 0 00-.34 1.71"/></g>'
  }
})
