<template>
    <div>
        <div class="flex flex-col md:flex-row items-center justify-center mt-12">
            <a
                href="https://rsvpifyresources.s3-us-west-2.amazonaws.com/imports/RSVPify+Invite+List+Import+Template.xlsx"
                target="_blank"
                class="flex flex-col items-center w-full h-40 md:w-80 p-4 rounded-md shadow ring-2 ring-transparent bg-white text-gray-800 hover:ring-purple-light hover:shadow-lg cursor-pointer transition duration-150 ease-in-out"
            >
                <app-icon
                    name="grid-download"
                    class="w-14 h-14 text-gray-600"
                    stroke
                ></app-icon>
                <p class="mt-4 font-bold">Download Spreadsheet Template</p>
                <p class="mt-2 text-sm text-gray-600">Recommended</p>
            </a>

            <import-invitees-file
                :event="event"
                class="flex flex-col items-center ml-0 mt-4 md:ml-6 md:mt-0 w-full h-40 md:w-80 p-4 shadow ring-2 ring-transparent rounded-md bg-white text-gray-800 hover:ring-purple-light hover:shadow-lg cursor-pointer transition duration-150 ease-in-out"
                @has-errors="toggleHasErrors"
            >
                <app-icon
                    name="users-upload-circle"
                    class="w-14 h-14 text-gray-600"
                    stroke
                ></app-icon>
                <p class="mt-4 font-bold">Import from Spreadsheet</p>
            </import-invitees-file>
        </div>

        <p v-if="hasErrors" class="w-full mt-6 alert alert-error">
            We had some trouble processing your import. Please upload a .xlsx or .csv file. If using a Windows-based PC and Chrome, upload a .xlsx file or use another browser to complete your import.
        </p>
    </div>
</template>

<script>

export default {
    name: 'DownloadAndImportInviteeSpreadsheet',

    props: {
        event: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            hasErrors: false
        };
    },

    methods: {
        toggleHasErrors (value) {
            this.hasErrors = value;
        }
    }
};
</script>
