/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-inhale-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9.02 23.25v-2.49h1.72v-.001c.45-.01.82-.38.82-.83v-1.79h.95l-.01-.001c.35 0 .63-.29.63-.64 0-.1-.02-.19-.06-.27-.625-1.4-1.54-3.1-1.54-3.1v-1.37c0-.19-.04-.37-.12-.53h0a4.561 4.561 0 00-3.1-2.82l-.01-.01a5.96 5.96 0 00-7.39 4.08c-.14.45-.21.91-.23 1.39H.67c-.02 1.64.68 3.21 1.91 4.31v4M18 3a3 3 0 100 6 3 3 0 100-6zM17.5.75h1M18 .75V3M21.36 1.93l.71.71M21.71 2.29l-1.59 1.59M23.25 5.5v1M23.25 6H21M22.07 9.36l-.71.71M21.71 9.71l-1.59-1.59M18.5 11.25h-1M18 11.25V9M14.64 10.07l-.71-.71M14.29 9.71l1.59-1.59M12.75 6.5v-1M12.75 6H15M13.93 2.64l.71-.71M14.29 2.29l1.59 1.59M21.754 14.25v4c0 1.65-1.35 3-3 3h-4"/><path pid="1" d="M16.754 23.25l-2-2 2-2"/></g>'
  }
})
