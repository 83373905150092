/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up-down-chevron': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sort ascending descending expand</desc><g _fill="currentColor"><path pid="0" d="M3.61 9.609c.48.48 1.27.48 1.76-.01l6.43-6.423h-.01c.09-.1.25-.1.35-.01l6.439 6.423c.48.48 1.27.48 1.76 0 .48-.49.48-1.28 0-1.77L12.84.32c-.49-.49-1.28-.49-1.77-.01L3.57 7.8h-.01c-.49.48-.49 1.27-.01 1.76zM20.384 14.391c-.49-.49-1.28-.49-1.77-.01L12.175 20.8h-.01c-.1.09-.26.09-.36-.01l-6.44-6.43-.01-.01c-.49-.49-1.28-.49-1.77-.01s-.49 1.27-.01 1.76l7.5 7.48c.48.48 1.27.48 1.76-.01l7.5-7.49v-.01c.48-.49.48-1.28 0-1.77l-.01-.01z"/></g>'
  }
})
