/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'table-restaurant-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>seating chair</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M1.5 4.88L3 13.12M1.5 19.125l1.5-6h3-.01c.82-.01 1.5.67 1.5 1.5v4.5M22.5 4.88L21 13.12M22.5 19.125l-1.5-6h-3.01c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v4.5M12 8.62v10.5M6.75 8.62h10.5"/></g>'
  }
})
