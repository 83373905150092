/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'paragraph-align-left-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>text</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 3h21M.75 7.5h18M.75 12h22.5M.75 16.5h18M.75 21h21"/></g>'
  }
})
