/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-quarantine-self-lockdown-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM13.5 23.25l.75-4.5h1.5V16.5v0c0-2.08-1.68-3.75-3.75-3.75-2.08 0-3.75 1.67-3.75 3.75v2.25h1.5l.75 4.5z"/><path pid="1" d="M22.272 23.25v-.001c.53-.01.97-.44.97-.98V9.73h0c-.02-.31-.16-.6-.38-.8L11.98.73 1.1 8.93h-.01c-.23.2-.36.49-.38.8v12.52l-.01-.01c0 .53.43.97.97.97z"/></g>'
  }
})
