<template>
    <div class="bg-white max-w-4xl mx-auto rounded divide-y">
        <div class="flex items-center p-10">
            <h1 class="grow capitalize text-2xl font-semibold">Event checklist</h1>
            <div class="w-52">
                <p class="uppercase text-xs font-semibold text-gray-800">Event progress <span class="text-gray-500">{{ completedProgress }}%</span></p>
                <progress-bar
                    class="mt-4 mb-2"
                    :bars="[{ color: 'bg-green', current: completedProgress, total: 100 },]"
                ></progress-bar>
            </div>
        </div>
        <div class="p-10">
            <p class="uppercase text-xs font-semibold text-gray-800">Quick start</p>
            <h2 class="grow capitalize text-4xl font-semibold mb-6">Customize</h2>
            <div class="space-y-4">
                <event-task
                    v-for="(task, index) in getTasksForSection('customize')"
                    :key="index"
                    v-bind="task"
                    @toggle="toggleTask(task.name)"
                    @complete="completeTask(task.name, task.sendToServer)"
                    @incomplete="incompleteTask(task.name)"
                ></event-task>
            </div>
        </div>
        <div class="p-10">
            <p class="uppercase text-xs font-semibold text-gray-800">Phase 2</p>
            <h2 class="grow capitalize text-4xl font-semibold mb-6">Launch</h2>
            <div class="space-y-4">
                <event-task
                    v-for="(task, index) in getTasksForSection('launch')"
                    :key="index"
                    v-bind="task"
                    @toggle="toggleTask(task.name)"
                    @complete="completeTask(task.name, task.sendToServer)"
                    @incomplete="incompleteTask(task.name)"
                ></event-task>
            </div>
        </div>
        <div class="p-10">
            <p class="uppercase text-xs font-semibold text-gray-800">Phase 3</p>
            <h2 class="grow capitalize text-4xl font-semibold mb-6">Organize</h2>
            <div class="space-y-4">
                <event-task
                    v-for="(task, index) in getTasksForSection('manage')"
                    :key="index"
                    v-bind="task"
                    @toggle="toggleTask(task.name)"
                    @complete="completeTask(task.name, task.sendToServer)"
                    @incomplete="incompleteTask(task.name)"
                ></event-task>
            </div>
        </div>
        <div class="p-10">
            <p class="uppercase text-xs font-semibold text-gray-800">Phase 4</p>
            <h2 class="grow capitalize text-4xl font-semibold mb-6">Follow up</h2>
            <div class="space-y-4">
                <event-task
                    v-for="(task, index) in getTasksForSection('followUp')"
                    :key="index"
                    v-bind="task"
                    @toggle="toggleTask(task.name)"
                    @complete="completeTask(task.name, task.sendToServer)"
                    @incomplete="incompleteTask(task.name)"
                ></event-task>
            </div>
        </div>
    </div>
</template>

<script>
import { filter, find } from 'lodash';
import { get } from 'vuex-pathify';
import axios from '@/util/axios';

export default {
    name: 'EventChecklist',

    props: {
        statuses: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            tasks: []
        };
    },

    computed: {
        event: get('Event/event'),

        completedProgress () {
            return Math.round((filter(this.tasks, (task) => { return task.checked; }).length / this.tasks.length) * 100);
        }
    },

    created () {
        this.tasks = [
            {
                action: {
                    type: 'url',
                    href: this.route('walls.edit', { event: this.event.id })
                },
                color: 'bg-teal',
                checked: find(this.statuses, { task: 'created-website' }).checked,
                description: 'Create a website to showcase details about your event. Or turn this feature off to only display a registration form.',
                icon: { name: 'app-page-text', stroke: false },
                name: 'created-website',
                section: 'customize',
                title: 'Event website',
                tooltip: 'Happy with your website? Mark it complete!'
            },
            {
                action: {
                    type: 'url',
                    href: this.route('forms.edit', { event: this.event.id })
                },
                color: 'bg-teal',
                checked: find(this.statuses, { task: 'created-form' }).checked,
                description: 'Customize your registration form. Ask custom questions, manage capacity limits, calendar invitations and more.',
                icon: { name: 'write', stroke: true },
                name: 'created-form',
                section: 'customize',
                title: 'Form builder',
                tooltip: 'Happy with your form’s set up? Mark it complete!'
            },
            {
                action: {
                    type: 'url',
                    href: this.route('invite-list.index', { event: this.event.id })
                },
                color: 'bg-teal',
                checked: find(this.statuses, { task: 'invited-invitees' }).checked,
                description: 'Add invitees to receive email invitations and/or choose to make your event exclusive to your list.',
                icon: { name: 'user-process', stroke: true },
                name: 'invited-invitees',
                optional: true,
                section: 'customize',
                title: 'Invite list',
                tooltip: 'Don’t need an invite list or have a finalized list? Mark it complete!'
            },
            {
                action: {
                    type: 'url',
                    href: this.route('settings.event.payments-and-coupons', { event: this.event.id })
                },
                color: 'bg-teal',
                checked: find(this.statuses, { task: 'connected-stripe' }).checked,
                description: 'Collecting payments for your event? Create a Stripe account, or connect an existing account to process payments.',
                icon: { name: 'credit-card', stroke: true },
                name: 'connected-stripe',
                section: 'customize',
                show: this.event.hasEverHadPayableItems,
                title: 'Connect Stripe',
                tooltip: 'Not selling tickets or collecting payments? Mark it complete!',
                uncheckable: true
            },
            {
                action: {
                    type: 'url',
                    href: this.route('settings.event.emails-and-notifications', { event: this.event.id })
                },
                color: 'bg-teal',
                checked: find(this.statuses, { task: 'configured-confirmation-emails' }).checked,
                description: 'Customize the automated emails guests will receive upon completing a submission.',
                icon: { name: 'email-send', stroke: true },
                name: 'configured-confirmation-emails',
                section: 'customize',
                title: 'Confirmation emails and reminders',
                tooltip: 'Happy with your automated emails? Mark it complete!'
            },
            {
                action: {
                    type: 'urlAndComplete',
                    href: this.event.previewUrl
                },
                color: 'bg-orange',
                checked: find(this.statuses, { task: 'previewed-event' }).checked,
                description: 'Test out the full experience from start to finish. If everything looks great, you can go ahead and publish!',
                icon: { name: 'view', stroke: false },
                name: 'previewed-event',
                section: 'launch',
                title: 'Preview your event',
                tooltip: 'Previewed your event? Mark it complete!'
            },
            {
                action: {
                    type: 'publish',
                    href: this.route('invite-list.index', { event: this.event.id })
                },
                color: 'bg-orange',
                checked: find(this.statuses, { task: 'published-event' }).checked,
                description: 'Make your event link live so you can start sharing it with guests and collect live responses.',
                icon: { name: 'rocket-flying', stroke: true },
                name: 'published-event',
                section: 'launch',
                sendToServer: false,
                title: 'Publish',
                tooltip: 'Your event is still unpublished. Publish to mark this step complete!',
                uncheckable: true
            },
            {
                action: {
                    type: 'url',
                    href: this.route('events.campaigns.drafts.create', { event: this.event.id })
                },
                color: 'bg-orange',
                checked: find(this.statuses, { task: 'sent-email-invitations' }).checked,
                description: 'Create and send customizable email invitations, updates, and reminders.',
                icon: { name: 'email-envelope', stroke: true },
                name: 'sent-email-invitations',
                optional: true,
                section: 'launch',
                title: 'Send email invitations',
                tooltip: 'Not sending email invites? Mark this step complete!'
            },
            {
                action: {
                    type: 'copyPublishedUrl'
                },
                color: 'bg-orange',
                checked: find(this.statuses, { task: 'copied-event-link' }).checked,
                description: 'You can share your event however you’d like by sharing your event URL.',
                icon: { name: 'link', stroke: false },
                name: 'copied-event-link',
                section: 'launch',
                title: 'Copy event link',
                tooltip: 'Shared your link? Mark this step complete!'
            },
            {
                action: {
                    type: 'urlAndComplete',
                    href: this.route('events.dashboard.overview', { event: this.event.id })
                },
                color: 'bg-blue',
                checked: find(this.statuses, { task: 'viewed-reporting' }).checked,
                description: 'Review and export all of the data associated with your event responses.',
                icon: { name: 'chart', stroke: true },
                name: 'viewed-reporting',
                section: 'manage',
                title: 'Reporting',
                tooltip: 'Registrations received? Mark this step complete!'
            },
            {
                action: {
                    type: 'url',
                    href: this.route('events.seating-charts.index', { event: this.event.id })
                },
                color: 'bg-blue',
                checked: find(this.statuses, { task: 'created-seating-chart' }).checked,
                description: 'Create drag-and-drop seating charts or groupings.',
                icon: { name: 'chair', stroke: true },
                name: 'created-seating-chart',
                section: 'manage',
                title: 'Seating',
                tooltip: 'No need for seating? Mark this step complete!'
            },
            {
                action: {
                    type: 'url',
                    href: this.route('check-in.suite', { event: this.event.id })
                },
                color: 'bg-blue',
                checked: find(this.statuses, { task: 'viewed-check-in-suite' }).checked,
                description: 'Check-in guests by name, email, confirmation code, or by scanning a QR code. Available for desktop, Apple and Android devices.',
                icon: { name: 'technology-qr-code-scan', stroke: false },
                name: 'viewed-check-in-suite',
                section: 'manage',
                title: 'Check-in'
            },
            {
                action: {
                    type: 'url',
                    href: this.route('events.dashboard.overview', { event: this.event.id })
                },
                color: 'bg-purple',
                checked: find(this.statuses, { task: 'created-export' }).checked,
                description: 'Review and analyze your event data and export it for safe-keeping.',
                icon: { name: 'download-circle', stroke: true },
                name: 'created-export',
                section: 'followUp',
                title: 'Export reporting',
                tooltip: 'Saved your data? Mark this step complete!'
            },
            {
                action: {
                    type: 'urlAndComplete',
                    href: this.route('check-in.feed', { event: this.event.id })
                },
                color: 'bg-purple',
                checked: find(this.statuses, { task: 'viewed-check-in-activity' }).checked,
                description: 'See who attended and when they checked in.',
                icon: { name: 'user-process', stroke: true },
                name: 'viewed-check-in-activity',
                section: 'followUp',
                title: 'Review attendance',
                tooltip: 'All set? Mark this step complete!'
            },
            {
                action: {
                    type: 'url',
                    href: this.route('events.campaigns.drafts.create', { event: this.event.id, template: 'thank-you' })
                },
                color: 'bg-purple',
                checked: find(this.statuses, { task: 'sent-thank-you-emails' }).checked,
                description: 'Thank guests for attending your big event.',
                icon: { name: 'email-envelope', stroke: true },
                name: 'sent-thank-you-emails',
                section: 'followUp',
                title: 'Send thank you emails',
                tooltip: 'Guests thanked? Mark this step complete!'
            }
        ];
    },

    methods: {
        getTasksForSection (section) {
            return filter(this.tasks, (task) => { return task.section === section; });
        },

        completeTask (taskName, sendToServer = true) {
            const task = find(this.tasks, { name: taskName });

            if (task.checked) {
                return;
            }

            task.checked = true;

            if (sendToServer) {
                axios.post(this.route('api.events.tasks.complete', { event: this.event, task: taskName }));
            }
        },

        incompleteTask (taskName) {
            const task = find(this.tasks, { name: taskName });

            if (!task.checked) {
                return;
            }

            task.checked = false;
            axios.delete(this.route('api.events.tasks.incomplete', { event: this.event, task: taskName }));
        },

        toggleTask (taskName) {
            const toggledTask = find(this.tasks, { name: taskName });

            toggledTask.checked ? this.incompleteTask(taskName) : this.completeTask(taskName);
        }

    }
};
</script>
