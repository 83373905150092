/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-float-wind-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.92 9.4a4.85 4.85 0 100 9.71 4.85 4.85 0 100-9.72zM10.12 5.76h1.61M10.93 5.76V9.4M18.05 1.24l1.38.86M18.74 1.67l-1.93 3.09M20.43 7.61l1.14 1.15M21 8.18l-2.57 2.58M17.51 19.7l-1.15 1.14M16.94 20.27l-2.58-2.58M11.73 22.76h-1.61M10.93 22.76v-3.64M5.49 20.84L4.34 19.7M4.91 20.27l2.58-2.58M2.43 15.07v-1.62M2.43 14.26h3.64M4.34 8.82l1.15-1.14M4.91 8.25l2.58 2.57"/></g>'
  }
})
