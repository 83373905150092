import { find, filter } from 'lodash';

function guestPassesPrimaryEventAskIf (askIf, guest) {
    if (!askIf) {
        return true;
    }

    const { target, targetId } = askIf;

    // A primary event will will always have a target ID of null.
    if (targetId !== null) {
        return false;
    }

    // A primary event/product must be in the guest's products property in order
    // to properly handle Attending guest states. We should not show "Attending"
    // blocks if the only product guests selected are add-ons
    const hasPrimaryProduct = filter(guest.products, { type: 'primary' }).length > 0;

    if (hasPrimaryProduct && target === 'attending') {
        return guest.shortState === 'Attending';
    }

    if (target === 'maybe') {
        return guest.shortState === 'Maybe';
    }

    if (target === 'attending/maybe') {
        return (hasPrimaryProduct && guest.shortState === 'Attending') || guest.shortState === 'Maybe';
    }

    if (target === 'not-attending') {
        return guest.shortState === 'NotAttending';
    }

    return false;
}

function guestPassesSecondaryEventAskIf (askIf, guest) {
    if (!askIf) {
        return true;
    }

    const { target, targetId } = askIf;
    const secondaryEvent = find(guest.products, { blockable_id: targetId });

    // A target ID that is null is looking for the primary event.
    if (targetId === null) {
        return false;
    }

    if (!secondaryEvent) {
        return false;
    }

    const { short_guest_state: shortGuestState } = secondaryEvent;

    if (!shortGuestState) {
        return false;
    }

    if (target === 'attending') {
        return shortGuestState === 'Attending';
    }

    if (target === 'maybe') {
        return shortGuestState === 'Maybe';
    }

    if (target === 'attending/maybe') {
        return shortGuestState === 'Attending' || shortGuestState === 'Maybe';
    }

    if (target === 'not-attending') {
        return shortGuestState === 'NotAttending';
    }

    return false;
}

function guestPassesAskIf (askIf, guest) {
    if (!askIf) {
        return true;
    }

    if (askIf.target === 'always') {
        return true;
    }

    if (guestPassesPrimaryEventAskIf(askIf, guest)) {
        return true;
    }

    return guestPassesSecondaryEventAskIf(askIf, guest);
}

function groupPassesAskIf (askIf, guests) {
    if (!askIf) {
        return true;
    }

    const filteredGuests = filter(guests, (guest) => {
        return guestPassesAskIf(askIf, guest);
    });

    return filteredGuests.length > 0;
}

function wholeGroupPassesAskIf (askIf, guests) {
    if (!askIf) {
        return true;
    }

    if (!guests) {
        return false;
    }

    const filteredGuests = filter(guests, (guest) => {
        return guestPassesAskIf(askIf, guest);
    });

    return filteredGuests.length === guests.length;
}

export {
    groupPassesAskIf,
    guestPassesAskIf,
    guestPassesPrimaryEventAskIf,
    guestPassesSecondaryEventAskIf,
    wholeGroupPassesAskIf
};
