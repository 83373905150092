/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-dance-woman-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.5 5.25a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/><path pid="1" d="M22.13.75h-.01c-.67.16-1.14.76-1.14 1.45v4.54M21.75 14.25a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM23.25 12v3.75M7.5 1.12a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM11.7 10.49c.91-.27 1.81-.52 2.74-.82h-.01c.77-.29 1.18-1.15.9-1.93a1.512 1.512 0 00-1.86-.93c-4.369 1.45-7.75 1.8-12.311 6.36v-.01a1.49 1.49 0 000 2.12c.58.58 1.53.58 2.122-.01v-.01c.97-1 2.06-1.88 3.24-2.64l-2.04 6.22h-.01c-.07.19.04.4.24.47.05.01.12.02.18.01l1.29-.25-.4 2.35v-.01c-.14.81.41 1.58 1.23 1.72L7 23.12c.08.01.16.02.24.02h-.01a1.51 1.51 0 001.47-1.25l.64-3.85 1.42-.44.8 1.07h0c.13.17.18.41.12.62l-.5 1.96h-.01c-.21.8.28 1.61 1.08 1.81 0 0 0 0 0 0l-.01-.01c.11.02.24.04.36.04h-.01c.68-.01 1.28-.47 1.45-1.14l.49-1.97v-.01c.27-1.1.04-2.26-.64-3.16l-.16-.22.98-.17v-.01c.2-.04.34-.23.3-.44a.472.472 0 00-.1-.2z"/></g>'
  }
})
