/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gift-tag-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.125 22.98h0c.33.33.88.33 1.21-.01l2.65-2.66v-.01c.33-.34.33-.89-.01-1.22l-3.88-3.88h0a.865.865 0 00-.61-.26h-2.66l-.01-.001a.854.854 0 00-.87.85c-.01 0-.01 0 0 0v2.65l-.01-.01c0 .22.09.44.25.6zM15.17 15.34l-1.85-1.85M12 17.238H2.99a.755.755 0 01-.75-.75s0 0 0 0v-6.75M15.75 9.74v2.25"/><rect pid="1" width="16.5" height="3.75" x=".75" y="5.988" rx=".75"/><path pid="2" d="M9 17.24V5.99M4.734 3.86l-.01-.01c1.25.98 2.69 1.7 4.24 2.12h0a11.9 11.9 0 00-2.13-4.25C5.514.4 4.76.6 4.174 1.18c-.59.58-.79 1.333.53 2.651zM13.266 3.86v-.01c-1.26.98-2.71 1.7-4.25 2.12v-.01c.41-1.55 1.13-2.99 2.12-4.25 1.31-1.32 2.06-1.12 2.65-.53.59.58.78 1.333-.54 2.651z"/></g>'
  }
})
