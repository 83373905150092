<template>
    <div>
        <div class="flex items-center border-2 h-12 mt-4 md:mt-0 cursor-move seating-area">
            <div class="flex flex-1 h-full items-center">
                <base-dropdown placement="bottom-start">
                    <template slot="trigger" slot-scope="{ triggerFunction }">
                        <button @click="triggerFunction">
                            <app-icon
                                class="text-gray-600 h-6 w-6"
                                name="navigation-menu-vertical"
                                stroke
                            ></app-icon>
                        </button>
                    </template>

                    <div class="bg-white rounded-md border shadow cursor-pointer text-sm text-gray-600 overflow-hidden">
                        <button
                            class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                            @click="$emit('edit-seating-area')"
                        >
                            <app-icon
                                class="h-4 w-4 mr-3"
                                name="settings-slider"
                                stroke
                            ></app-icon>
                            Edit Table
                        </button>

                        <div
                            class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-red hover:bg-gray-100"
                            @click="deleteArea"
                        >
                            <app-icon
                                class="h-4 w-4 mr-3"
                                name="trash"
                                stroke
                            ></app-icon>
                            Delete Table
                        </div>
                    </div>
                </base-dropdown>

                <div class="flex flex-col h-full justify-center text-gray-600 ml-2 leading-none cursor-none">
                    <span class="font-semibold text-xs">{{ seatingArea.name }}</span>
                    <span class="text-xs mt-1">{{ occupiedSeats }} of {{ seatingArea.number_of_seats }}</span>
                </div>
            </div>

            <div class="flex items-center mx-2">
                <div
                    v-tippy
                    :content="seatingArea.description"
                    class="rounded-full h-5 w-5"
                    :style="{ 'background-color': seatingArea.color }"
                >
                </div>

                <button class="button-text button-sm hover:text-purple-lighter ml-2" @click="$emit('toggle-expanded')">
                    <app-icon :name="expandedIconState" stroke></app-icon>
                </button>
            </div>
        </div>

        <transition name="slide-vertical">
            <div v-if="expanded" class="flex flex-col space-y-1">
                <div class="flex flex-col space-y-1">
                    <div
                        v-for="(seatable, index) in seats"
                        :key="index"
                        class="bg-white py-2 pl-2 text-gray-300 hover:bg-gray-100 hover:cursor-pointer select-none"
                    >
                        <draggable
                            :value="[seatable]"
                            :disable="saving"
                            :delay="500"
                            :delay-on-touch-only="true"
                            :animation="150"
                            :group="{ name: 'seatables', pull: 'clone', put: true }"
                            ghost-class="seatable-ghost"
                            chosen-class="seatable-clone"
                            drag-class="seatable-clone"
                            handle=".seatable"
                            :move="moveSeatable"
                            :remove-clone-on-hide="false"
                            :data-position="index"
                            :class="{'droppable': seatable === null}"
                            @end="endDraggable"
                        >
                            <div v-if="isSeatActionInProgress(index, seatable)" class="flex items-center justify-center border border-dashed border-purple rounded-md p-2">
                                <app-icon name="loader" class="w-6 h-6 text-purple"></app-icon>
                            </div>

                            <div
                                v-else-if="seatable && !isSeatActionInProgress(index)"
                                class="flex items-center text-gray-600 cursor-move seat-taken"
                            >
                                <div class="flex flex-1 break-words mr-2 seatable">
                                    <div class="clone-hide mr-4">{{ index + 1 }}.</div>
                                    <div class="flex flex-col justify-center">
                                        <p>{{ getSeatableIdentificator(seatable) }}</p>

                                        <p v-if="seatable.parentInviteeFirstName || seatable.parentInviteeLastName" class="text-xs">
                                            Guest of {{ seatable.parentInviteeFirstName }} {{ seatable.parentInviteeLastName }}
                                        </p>

                                        <div class="flex items-center text-sm">
                                            <span v-if="seatable.reply">
                                                {{ seatable.reply.title }}
                                            </span>

                                            <span v-else>
                                                Haven't heard
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex items-center ml-2 clone-hide">
                                    <guest-reply-icon
                                        v-if="seatable.reply"
                                        :state="seatable.reply.guest_state"
                                        colored
                                        class="h-5 w-5"
                                    ></guest-reply-icon>

                                    <base-dropdown placement="bottom-start">
                                        <template slot="trigger" slot-scope="{ triggerFunction }">
                                            <button @click="triggerFunction">
                                                <app-icon
                                                    class="h-6 w-6 ml-1 cursor-pointer"
                                                    name="navigation-menu-vertical"
                                                    stroke
                                                ></app-icon>
                                            </button>
                                        </template>

                                        <div class="bg-white rounded-md border shadow cursor-pointer text-sm text-gray-600 overflow-hidden">
                                            <button
                                                class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-red hover:bg-gray-100"
                                                @click="prepareAndUnseat(seatable)"
                                            >
                                                <app-icon
                                                    class="h-4 w-4 mr-3"
                                                    name="rotate-back"
                                                    stroke
                                                ></app-icon>
                                                Unseat {{ getSeatableIdentificator(seatable) }}
                                            </button>

                                            <button class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple-600 hover:bg-gray-100" @click="launchDetailsModal(seatable)">
                                                <app-icon
                                                    class="h-4 w-4 mr-3"
                                                    name="user-alt-search"
                                                    stroke
                                                ></app-icon>
                                                View Details
                                            </button>
                                        </div>
                                    </base-dropdown>
                                </div>
                            </div>

                            <div v-else class="flex">
                                <span class="mr-4 text-gray-500">{{ index + 1 }}.</span>

                                <base-dropdown class="inline-block flex-1" placement="bottom-start">
                                    <template slot="trigger" slot-scope="{ triggerFunction, isOpen }">
                                        <input
                                            v-model="seatSearches[index]"
                                            class="placeholder-gray-500 outline-none bg-transparent text-gray-700 w-full"
                                            @mouseenter="(event) => event.target.placeholder = 'Type to search...'"
                                            @mouseleave="(event) => event.target.placeholder = ''"
                                            @blur="seatSearches[index] = ''"
                                            @focus="triggerFunction"
                                        >
                                    </template>

                                    <template #default="{ isOpen }">
                                        <div class="bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden mt-2">
                                            <div v-if="isOpen">
                                                <div
                                                    v-for="seatable in searchedSeatables(index)"
                                                    :key="`${seatable.id}-${seatable.type}`"
                                                    class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full font-medium"
                                                    :class="seatableDropdownOptionStyle(seatable)"
                                                    @click="prepareAndSeat(seatable, index)"
                                                >
                                                    <span v-if="isEligibleForSeating(seatable)">Seat &nbsp;</span>
                                                    <span>{{ getSeatableIdentificator(seatable) }}&nbsp;</span>
                                                    <span v-if="seatable.reply">({{ seatable.reply.title }})</span>
                                                    <span v-else>(Haven't heard)</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </base-dropdown>

                            </div>
                        </draggable>
                    </div>
                </div>
            </div>
        </transition>

        <seatable-details-modal v-model="showSeatableDetailsModal" :seatable="selectedSeatable"></seatable-details-modal>
    </div>
</template>

<script>
import {
    find, get as lodashGet, isEmpty, trim
} from 'lodash';
import { call, get } from 'vuex-pathify';
import draggable from 'vuedraggable';
import MovesSeatables from './Mixins/MovesSeatables';

export default {
    name: 'SeatingArea',

    components: { draggable },

    mixins: [MovesSeatables],

    props: {
        expanded: {
            type: Boolean,
            required: true
        },

        seatingArea: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            seatSearches: [],
            showSeatableDetailsModal: false,
            selectedSeatable: {}
        };
    },

    computed: {
        ...get('Seating/*'),

        expandedIconState () {
            return this.expanded ? 'arrow-down-chevron' : 'arrow-up-chevron';
        },

        occupiedSeats () {
            return this.seatingArea.seats.length;
        },

        seats () {
            return [...Array(this.seatingArea.number_of_seats)].map((seat, index) => {
                return this.getSeatable(index);
            });
        },

        saving: get('Seating/saving'),

        seatables: get(`Seating/seatables`)
    },

    watch: {
        showSeatableDetailsModal (newValue) {
            if (!newValue) {
                this.loadSeatables(this.seatingChart);
                this.loadFilteredSeatables(this.seatingChart);
            }
        }
    },

    methods: {
        deleteArea () {
            if (!this.occupiedSeats) {
                this.remove(this.seatingArea);
                return;
            }

            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            const messageText = `Deleting the table ${this.seatingArea.name} will unseat ${this.occupiedSeats} guests. Continue?`;

            App.alert().confirm(
                'Are you sure?',
                messageText,
                attributes,
                () => {
                    this.remove(this.seatingArea);
                }
            );
        },

        searchedSeatables (seat) {
            if (!this.seatSearches[seat]) {
                return [];
            }

            return this.seatables.filter((seatable) => {
                const searchRegex = new RegExp(this.seatSearches[seat], 'i');
                return searchRegex.test(this.getSeatableIdentificator(seatable));
            }).splice(0, 10);
        },

        getSeatable (seatPosition) {
            const seat = find(this.seatingArea.seats, { seat_position: seatPosition });

            if (!seat) {
                return null;
            }

            return find(this.seatables, { type: seat.seatable_type, id: seat.seatable_id });
        },

        getSeatableIdentificator (seatable) {
            return isEmpty(trim(seatable.fullName)) ? seatable.email : seatable.fullName;
        },

        remove: call('Seating/removeSeatingArea'),

        isEligibleForSeating (seatable) {
            if (!lodashGet(seatable, 'reply.guest_state')) {
                return !seatable.seating_area_id;
            }

            return !lodashGet(seatable, 'reply.guest_state').includes('NotAttending') && !seatable.seating_area_id;
        },

        isSeatActionInProgress (seatPosition, seatable) {
            if (!this.seatActionInProgress) {
                return false;
            }

            if (seatable) {
                return this.seatActionInProgress.seatable.type === seatable.type
                    && this.seatActionInProgress.seatable.id === seatable.id;
            }

            return this.seatActionInProgress.seatingAreaId === this.seatingArea.id
                && this.seatActionInProgress.seatPosition === seatPosition;
        },

        launchDetailsModal (seatable) {
            this.selectedSeatable = seatable;
            this.showSeatableDetailsModal = true;
        },

        loadSeatables: call('Seating/loadFilteredSeatables'),
        loadFilteredSeatables: call('Seating/loadFilteredSeatables'),

        prepareAndSeat (seatable, position) {
            if (!this.isEligibleForSeating(seatable) || this.saving) {
                return;
            }

            this.seat({
                seatable,
                position,
                seatingArea: this.seatingArea
            });
        },

        prepareAndUnseat (seatable) {
            if (!seatable.seating_area_id || this.saving) {
                return;
            }

            this.unseat({
                seatable,
                seatingArea: this.seatingArea
            });
        },

        seatableDropdownOptionStyle (seatable) {
            return {
                'text-gray-600 cursor-pointer hover:text-purple hover:bg-gray-100': this.isEligibleForSeating(seatable),
                'text-gray-400 cursor-not-allowed': !this.isEligibleForSeating(seatable)
            };
        },

        seat: call('Seating/seat'),

        unseat: call('Seating/unseat')
    }
};
</script>
