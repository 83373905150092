<template>
    <app-icon :name="icon" :class="styles"></app-icon>
</template>

<script>
export default {
    props: {
        colored: {
            type: Boolean,
            default: false
        },
        state: {
            type: String,
            required: true
        }
    },

    computed: {
        iconMapping () {
            return {
                Attending: 'check-circle-stroke',
                NotAttending: 'close-circle-stroke',
                Maybe: 'remove-circle-stroke'
            };
        },

        icon () {
            return this.iconMapping[this.shortState];
        },

        shortState () {
            return this.state.split('\\').slice(-1)[0];
        },

        styles () {
            return {
                'text-green': this.colored && this.shortState === 'Attending',
                'text-yellow': this.colored && this.shortState === 'Maybe',
                'text-red': this.colored && this.shortState === 'NotAttending'
            };
        }
    }
};
</script>
