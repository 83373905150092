<template>
    <base-dropdown>
        <template slot="trigger" slot-scope="{ triggerFunction }">
            <button
                class="flex items-center bg-white rounded-md border p-2 ml-2 hover:border-purple text-gray-600 hover:text-purple"
                :class="shouldDisableButton ? 'cursor-not-allowed disabled:opacity-50': 'hover:cursor-pointer'"
                :disabled="shouldDisableButton"
                @click="triggerFunction"
            >
                <span
                    v-tippy
                    :content="tooltip"
                    class="outline-none"
                >
                    Actions ({{ totalSelected | number }})

                    <app-icon
                        name="arrow-down-chevron"
                        class="w-4 h-4 ml-2"
                        stroke
                    ></app-icon>
                </span>
            </button>
        </template>
        <div class="bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden">
            <slot v-bind="bulkActionData"></slot>
        </div>
    </base-dropdown>
</template>

<script>
import numberFilter from '@/filters/NumberFilter';

export default {
    props: {
        all: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        disabledTooltip: {
            type: String,
            default: null
        },

        ids: {
            type: Array,
            required: true
        },

        predicates: {
            type: Array,
            default: () => { return []; }
        },

        total: {
            type: Number,
            default: 0
        }
    },

    computed: {
        bulkActionData () {
            return {
                ids: this.ids,
                predicates: this.predicates,
                all: this.all,
                total: this.total,
                totalSelected: this.totalSelected,
                formatted: {
                    total: numberFilter(this.total),
                    totalSelected: numberFilter(this.totalSelected)
                }
            };
        },

        hasSelectedItems () {
            return this.totalSelected > 0 || this.all;
        },

        shouldDisableButton () {
            if (this.disabled) {
                return true;
            }

            return !this.hasSelectedItems;
        },

        tooltip () {
            return this.shouldDisableButton ? this.disabledTooltip : null;
        },

        totalSelected () {
            if (this.all) {
                return this.total;
            }

            return this.ids.length;
        }
    }
};
</script>
