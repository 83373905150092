/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-discount-square-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 17.25l10.5-10.5M8.25 6.75a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM15.75 14.25a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM.75 3.75v-1.5h0C.74 1.42 1.42.74 2.24.74h1.5M3.75 23.25H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-1.5M.75 7.5v3M.75 13.5v3M23.25 7.5v3M7.5.75h3M23.25 20.25v1.5c0 .82-.68 1.5-1.5 1.5h-1.5M23.25 16.5v-3M16.5 23.25h-3M13.5.75h3M20.25.75h1.5-.01c.82-.01 1.5.67 1.5 1.5v1.5M10.5 23.25h-3"/></g>'
  }
})
