/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'megaphone-announce': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>advertising announce</desc><g _fill="currentColor"><path pid="0" d="M23.854 14.293l-2.163-3.561a.25.25 0 01-.035-.149l.334-4.154a1 1 0 00-.76-1.052l-4.048-.987a.254.254 0 01-.131-.081l-2.7-3.174a1 1 0 00-1.282-.206l-3.56 2.163a.248.248 0 01-.15.035l-4.154-.334a1.025 1.025 0 00-1.052.76L3.069 8a1 1 0 001.944.474l.831-3.407a.25.25 0 01.263-.189l3.5.281a1.024 1.024 0 00.6-.143l3-1.82a.249.249 0 01.32.052l2.263 2.666a1 1 0 00.524.323l3.407.831a.25.25 0 01.19.263l-.281 3.5a1 1 0 00.142.6l1.821 3a.252.252 0 01-.052.32l-2.671 2.263a1 1 0 00-.323.525l-.831 3.406a.25.25 0 01-.263.19l-3.495-.281a1 1 0 00-.16 1.994l4.556.366h.082a1 1 0 00.97-.762l.988-4.049a.248.248 0 01.081-.131l3.173-2.7a1 1 0 00.206-1.279z"/><path pid="1" d="M.114 19.226a.5.5 0 00-.031.6l1.008 1.518a.5.5 0 00.559.2l2.257-.675a.249.249 0 01.28.1l.6.91A2.87 2.87 0 009.9 19.365a.249.249 0 01.182-.324l4.294-.964a.249.249 0 01.263.1l.078.117a.752.752 0 00.626.335.743.743 0 00.414-.125.751.751 0 00.21-1.04L9.588 7.857a.75.75 0 10-1.25.83l.071.106a.249.249 0 01-.015.3zm7.836 2.207a1.375 1.375 0 01-1.906-.385l-.268-.4a.251.251 0 01.137-.378l2.233-.67a.251.251 0 01.3.139 1.375 1.375 0 01-.496 1.694z"/></g>'
  }
})
