/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transportation-bus-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 20.213v1.5h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51v-1.5M18.75 20.213v1.5c0 .82-.68 1.5-1.5 1.5-.83 0-1.5-.68-1.5-1.5v-1.5"/><path pid="1" d="M20.25 18.713v0c0 .82-.68 1.5-1.5 1.5H5.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V5.21h0A4.494 4.494 0 018.23.7h7.5-.01c2.48-.01 4.5 2.01 4.5 4.49zM9.75 3.71h4.5"/><path pid="2" d="M3.75 6.71h16.5v7.5H3.75zM6.75 17.21h3M14.25 17.21h3M3.75 8.213H2.24c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v4.5M20.25 8.213h1.5-.01c.82-.01 1.5.67 1.5 1.5v4.5"/></g>'
  }
})
