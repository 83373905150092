<template>
    <data-columns
        :default-columns="['firstName', 'lastName', 'email']"
        empty-placeholder="Sorry, we couldn't find any recipients matching the searched term."
        :event="event"
        :groupable="false"
        :has-select-all="false"
        :initial-predicates="initialPredicates"
        :is-selected-function="isRecipientSelected"
        :required-columns="['type', 'id']"
        :route-resolver="routeResolver"
        :selectable="mode === 'custom'"
        :total-item-count="100"
        @click-row="toggleSelection"
        @received-response="onReceivedResponse"
        @row-selected="handleChangedSelection"
    >
        <template #header="{ availableColumns, chosenColumns, predicates, searchQuery, setChosenColumns, setPredicates, setSearchQuery }">
            <div class="w-full flex flex-wrap items-center space-x-2">
                <div class="flex-1">
                    <search-field
                        class="w-full md:w-80 ml-2 pr-6"
                        placeholder="Name, email, confirmation..."
                        :value="searchQuery"
                        @input="newSearchQuery => handleSearchQueryUpdate(newSearchQuery, setSearchQuery)"
                    ></search-field>
                </div>

                <choosable-columns
                    class="hidden"
                    :columns="availableColumns"
                    :value="chosenColumns"
                    @input="setChosenColumns"
                ></choosable-columns>

                <div class="p-4 flex items-center justify-spread bg-gray-100 border rounded-md">
                    <span><strong>{{ counts.invitees | number }}</strong> invitee(s)</span>
                    <span class="px-6"><strong>{{ counts.uniqueEmails | number }}</strong> unique email(s)</span>
                    <span><strong>{{ counts.groups | number }}</strong> group(s)</span>
                </div>
            </div>

            <data-columns-predicate-list
                class="mt-2 pl-2 hidden md:block"
                :value="predicates"
                add-filter-text="Custom Audience Filter"
                @input="newPredicates => handlePredicateUpdate(newPredicates, setPredicates)"
            ></data-columns-predicate-list>
        </template>
    </data-columns>
</template>

<script>
import get from 'lodash/get';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'CampaignAudienceBuilder',

    props: {
        event: {
            type: Object,
            required: true
        },

        mode: {
            type: String,
            required: true
        },

        initialPredicates: {
            type: Array,
            default: () => {
                return [];
            }
        },

        value: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    data () {
        return {
            counts: {
                invitees: 0,
                uniqueEmails: 0,
                groups: 0
            },
            predicates: cloneDeep(this.initialPredicates),
            searchQuery: ''
        };
    },

    computed: {
        allPredicates () {
            if (this.searchQuery === '') {
                return this.predicates;
            }

            return this.predicates.concat([{
                field: 'term',
                comparison: 'contains',
                value: this.searchQuery
            }]);
        }
    },

    watch: {
        allPredicates (newVal) {
            this.$emit('predicates', newVal);
        }
    },

    methods: {
        handleChangedSelection ({ row, isSelected }) {
            const newValue = this.value.filter(({ id, type }) => {
                return id !== row.id || type !== row.type;
            });

            if (isSelected) {
                newValue.push({ id: row.id, type: row.type });
            }

            this.$emit('input', newValue);
        },

        handlePredicateUpdate (newPredicates, setPredicatesFunction) {
            this.predicates = newPredicates;
            setPredicatesFunction(newPredicates);
        },

        handleSearchQueryUpdate (newSearchQuery, setSearchQueryFunction) {
            this.searchQuery = newSearchQuery;
            setSearchQueryFunction(newSearchQuery);
        },

        isRecipientSelected (recipient) {
            return this.value.some(({ id, type }) => {
                return id === recipient.id && type === recipient.type;
            });
        },

        onReceivedResponse ({ data }) {
            this.counts.invitees = data.invitees;
            this.counts.uniqueEmails = data.uniqueEmails;
            this.counts.groups = data.groups;

            this.$emit('audience-size', this.counts.invitees);
        },

        routeResolver () {
            return 'api.events.guests-and-invitees.index';
        },

        toggleSelection (row) {
            this.handleChangedSelection({
                row,
                isSelected: this.isRecipientSelected(row)
            });
        }
    }
};
</script>
