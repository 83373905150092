/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-cocktail-glass': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>bar alcohol liquor</desc><g _fill="currentColor"><path pid="0" d="M4.75 3.139l.056.227a.5.5 0 00.486.381h1.035a.5.5 0 00.484-.623L6.69 2.65A3.488 3.488 0 003.3 0H1a1 1 0 000 2h2.3a1.488 1.488 0 011.45 1.139zM19.5 0a4.5 4.5 0 00-4.27 3.1.5.5 0 00.475.655h1.065a.5.5 0 00.453-.29A2.5 2.5 0 1120.97 6.52a.487.487 0 01-.01.12A10.187 10.187 0 0120.692 8a.5.5 0 00.693.583A4.5 4.5 0 0019.5 0z"/><path pid="1" d="M19.228 5.611a1.029 1.029 0 00-.78-.361H3.052a1.026 1.026 0 00-.78.361 1.073 1.073 0 00-.248.838 8.911 8.911 0 007.308 7.678.5.5 0 01.418.493v6.88a.5.5 0 01-.5.5h-3a1 1 0 000 2h9a1 1 0 000-2h-3a.5.5 0 01-.5-.5v-6.88a.5.5 0 01.418-.493 8.911 8.911 0 007.308-7.678 1.077 1.077 0 00-.248-.838zm-2.691 3.523a.464.464 0 01-.423.273H5.386a.464.464 0 01-.423-.273 5.127 5.127 0 01-.456-1.3.6.6 0 01.177-.517.79.79 0 01.558-.222h11.016a.783.783 0 01.557.223.6.6 0 01.179.516 5.168 5.168 0 01-.457 1.3z"/></g>'
  }
})
