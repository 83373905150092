const map = require('lodash/map');
const isString = require('lodash/isString');

module.exports = function () {
    return function ({ theme, addUtilities, e }) {
        const borderColorUtilities = map(theme('colors'), (colors, colorName) => {
            if (isString(colors)) {
                return {
                    [`.${e(`border-b-${colorName}`)}`]: {
                        'border-bottom-color': colors
                    },
                    [`.${e(`border-t-${colorName}`)}`]: {
                        'border-top-color': colors
                    },
                    [`.${e(`border-l-${colorName}`)}`]: {
                        'border-left-color': colors
                    },
                    [`.${e(`border-r-${colorName}`)}`]: {
                        'border-right-color': colors
                    }
                };
            }

            return map(colors, (color, colorIndex) => {
                return {
                    [`.${e(`border-b-${colorName}-${colorIndex}`)}`]: {
                        'border-bottom-color': color
                    },
                    [`.${e(`border-t-${colorName}-${colorIndex}`)}`]: {
                        'border-top-color': color
                    },
                    [`.${e(`border-l-${colorName}-${colorIndex}`)}`]: {
                        'border-left-color': color
                    },
                    [`.${e(`border-r-${colorName}-${colorIndex}`)}`]: {
                        'border-right-color': color
                    }
                };
            });
        });

        addUtilities(borderColorUtilities, {
            variants: ['hover']
        });
    };
};
