import axios from '@/util/axios';

export default {
    methods: {
        duplicateCampaign (event, campaign) {
            axios.post(this.route('api.events.campaigns.duplicate', [event, campaign]))
                .then(({ data }) => {
                    this.$toasted.global.success({
                        message: 'Campaign was duplicated successfully.',
                        onComplete: () => {
                            window.location = this.route('events.campaigns.edit', [event, data.id]);
                        }
                    });
                }).catch(() => {
                    this.$toasted.global.error('There was an error duplicating this campaign');
                });
        }
    }
};
