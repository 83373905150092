/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'microphone-podcast-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 17.25v6M9 23.25h6M4.5 8.25v3h0a5.99 5.99 0 006 5.99h3l-.01-.001c3.31 0 6-2.69 6-6v-3"/><path pid="1" d="M13.5 14.25h-3 0a3.204 3.204 0 01-3-3.38V4.12l-.01-.01a3.204 3.204 0 013-3.38h3c1.75.1 3.09 1.61 3 3.37v6.75h0a3.216 3.216 0 01-3.01 3.37zM7.5 5.25h3M13.5 5.25h3M7.5 8.25h3M13.5 8.25h3"/></g>'
  }
})
