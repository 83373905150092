<template>
    <app-modal
        :value="value"
        full-screen
        :z-index="100"
        @input="$emit('input', $event)"
    >
        <div v-if="loading" class="flex flex-col items-center justify-center h-full">
            <app-icon name="loader" class="w-20 h-20 mb-8"></app-icon>

            <h1 class="text-2xl text-center">
                We're loading your seating chart, it will be ready in just a moment.
            </h1>
        </div>

        <template v-else>
            <template slot="header">
                <div class="flex items-center">
                    <div class="flex items-center w-auto md:w-1/3">
                        <div class="shrink-0 mr-4">
                            <app-icon
                                v-if="!saving"
                                name="edit"
                                class="w-5 h-5"
                                stroke
                            ></app-icon>

                            <app-icon
                                v-if="saving"
                                name="loading-half"
                                class="w-5 h-5 text-purple spinning"
                            ></app-icon>
                        </div>

                        <button
                            v-if="!editingTitle"
                            v-tippy
                            :content="`Click to edit the title of ${seatingChart.title}.`"
                            class="border-b border-dashed border-gray-500 p-2 truncate"
                            @click="editingTitle = true"
                        >
                            {{ seatingChart.title }}
                        </button>

                        <form-field-wrapper v-else :error="seatingChartEditsForm.errors.get('title')">
                            <input
                                v-model="seatingChartEditsForm.title"
                                class="form-field"
                                @focusout="updateSeatingChartName"
                                @keyup.enter="updateSeatingChartName"
                            >
                        </form-field-wrapper>
                    </div>

                    <div class="md:flex items-center justify-end mr-4 flex-1 hidden text-sm">
                        <div class="flex items-center justify-center rounded text-teal bg-teal-light">
                            <app-icon
                                name="table-restaurant"
                                class="h-8 w-8"
                                stroke
                            ></app-icon>
                        </div>

                        <div class="flex flex-col ml-4">
                            <p class="font-semibold">{{ seatingChart.stats_counts.numberOfSeatingAreas.toLocaleString() }}</p>
                            <p>Tables</p>
                        </div>

                        <div class="flex items-center justify-center ml-8 rounded text-green bg-green-light">
                            <app-icon
                                name="users-alt-check-circle"
                                class="h-8 w-8"
                                stroke
                            ></app-icon>
                        </div>

                        <div class="flex flex-col ml-4">
                            <p class="font-semibold">{{ seatingChart.stats_counts.numberOfSeated.toLocaleString() }} of {{ seatingChart.stats_counts.numberOfSeatables.toLocaleString() }}</p>
                            <p>Seated</p>
                        </div>
                    </div>
                </div>
            </template>

            <div class="flex">
                <div class="hidden mr-4 md:flex w-1/2 lg:w-1/3 mr-10">
                    <seatable-data-table></seatable-data-table>
                </div>

                <div class="flex flex-col w-full md:w-1/2 lg:w-2/3">
                    <seating-areas></seating-areas>
                </div>
            </div>
        </template>
    </app-modal>
</template>

<script>
import lodashGet from 'lodash/get';
import { get, sync, call } from 'vuex-pathify';
import pako from 'pako';
import Form from '@/validation/Form';
import InteractsWithAbly from '@/mixins/InteractsWithAbly';

export default {
    name: 'SeatingChartEditor',

    mixins: [InteractsWithAbly],

    props: {
        seatingChartToEdit: {
            type: Object,
            required: true
        },

        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            editingTitle: false,
            seatingChartEditsForm: null
        };
    },

    computed: {
        ...sync('Seating/*'),
        ...get('Event/event@', {
            eventId: 'id'
        })
    },

    watch: {
        seatingChartToEdit () {
            this.loadSeatingChart(this.seatingChartToEdit);
            this.seatingChartEditsForm = new Form({
                title: lodashGet(this.seatingChartToEdit, 'title')
            });
        },

        value (newValue) {
            this.editingTitle = false;

            if (newValue) {
                this.listenForEvents();
            } else {
                this.stopListeningForEvents();
            }
        }
    },

    methods: {
        loadSeatingChart: call('Seating/loadSeatingChart'),
        updateSeatingChart: call('Seating/updateSeatingChart'),
        seatSeatable: call('Seating/seatSeatable'),
        unseatSeatable: call('Seating/unseatSeatable'),
        updateUnseatedSeatables: call('Seating/updateUnseatedSeatables'),

        addNewestSeatingArea (data) {
            const decompressedData = this.decompress(data.compressedData);

            this.toggleChangesDetected();
            this.$store.commit('Seating/addSeatingArea', decompressedData.seatingArea);
            this.seatingChart = decompressedData.seatingChart;
        },

        deleteSeatingArea (data) {
            const decompressedData = this.decompress(data.compressedData);

            this.toggleChangesDetected();
            this.$store.commit('Seating/removeSeatingArea', decompressedData.seatingArea);
            this.updateUnseatedSeatables(decompressedData.removedSeats);
            this.seatingChart = decompressedData.seatingChart;
        },

        listenForEvents () {
            this.$echo.private(`events.${this.eventId}.seating-charts.${this.seatingChartToEdit.id}`)
                .listen('.Domain\\Seating\\Events\\SeatingChartUpdated', (data) => {
                    this.updateSeatingChartWithNewestChanges(data);
                }).listen('.Domain\\Seating\\Events\\SeatableSeated', (data) => {
                    this.seatSeatableOnNewestPosition(data);
                }).listen('.Domain\\Seating\\Events\\SeatableUnseated', (data) => {
                    this.unseatSeatableOnNewestChanges(data);
                })
                .listen('.Domain\\Seating\\Events\\SeatingAreaCreated', (data) => {
                    this.addNewestSeatingArea(data);
                })
                .listen('.Domain\\Seating\\Events\\SeatingAreaUpdated', (data) => {
                    this.updateSeatingAreaWithNewestChanges(data);
                })
                .listen('.Domain\\Seating\\Events\\SeatingAreaDeleted', (data) => {
                    this.deleteSeatingArea(data);
                })
                .listen('.Domain\\Seating\\Events\\SeatingAreasReordered', (data) => {
                    this.applyNewestReorderToSeatingAreas(data);
                });
        },

        applyNewestReorderToSeatingAreas (data) {
            const decompressedData = this.decompress(data.compressedData);

            this.toggleChangesDetected();
            this.seatingAreas = decompressedData.seatingAreas;
        },

        seatSeatableOnNewestPosition (data) {
            const decompressedData = this.decompress(data.compressedData);

            this.toggleChangesDetected();
            this.seatSeatable({
                seatable: {
                    id: decompressedData.seatingData.seatable_id,
                    type: decompressedData.seatingData.seatable_type
                },
                newSeatingArea: decompressedData.newSeatingArea,
                previousSeatingArea: decompressedData.previousSeatingArea,
                seatingChart: decompressedData.seatingChart
            });
        },

        decompress (data) {
            // Decode base64 (convert ascii to binary)
            const stringData = atob(data);

            // Convert binary string to character-number array
            const charData = stringData.split('').map((x) => { return x.charCodeAt(0); });

            // Turn number array into byte-array
            const binData = new Uint8Array(charData);

            // Pako logic for unzipping
            const pakoData = pako.inflate(binData);

            // Convert gzipped byteArray back to ascii string
            const strData = String.fromCharCode.apply(null, new Uint16Array(pakoData));

            // Return JSON object from decompressed data
            return JSON.parse(strData);
        },

        stopListeningForEvents () {
            this.$echo.leave(`events.${this.eventId}.seating-charts.${this.seatingChartToEdit.id}`);
        },

        toggleChangesDetected () {
            this.$toasted.global.success(`Changes were applied to this chart and it has been updated.`);
        },

        unseatSeatableOnNewestChanges (data) {
            const decompressedData = this.decompress(data.compressedData);

            this.toggleChangesDetected();
            this.unseatSeatable({
                seatable: {
                    id: decompressedData.unseatingData.seatable_id,
                    type: decompressedData.unseatingData.seatable_type
                },
                seatingChart: decompressedData.seatingChart,
                seatingArea: decompressedData.seatingArea
            });
        },

        updateSeatingAreaWithNewestChanges (data) {
            const decompressedData = this.decompress(data.compressedData);

            this.toggleChangesDetected();
            this.$store.commit('Seating/updateSeatingArea', decompressedData.seatingArea);
        },

        updateSeatingChartWithNewestChanges (data) {
            const decompressedData = this.decompress(data.compressedData);

            this.toggleChangesDetected();
            this.seatingChartEditsForm.title = decompressedData.seatingChart.title;
            this.seatingChart = decompressedData.seatingChart;
        },

        updateSeatingChartName () {
            if (!this.editingTitle) {
                return;
            }

            this.updateSeatingChart(this.seatingChartEditsForm).then(() => {
                this.editingTitle = false;
                this.$toasted.global.success('Seating chart title has been updated.');
            });
        }
    }
};
</script>
