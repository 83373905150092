/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ui-webpage-bullets': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>ui bullets designer</desc><g _fill="currentColor"><circle pid="0" cx="4" cy="5" r="3"/><path pid="1" d="M9.5 4.25h12a1 1 0 000-2h-12a1 1 0 000 2zM16.5 7.75a1 1 0 000-2h-7a1 1 0 000 2z"/><circle pid="2" cx="11" cy="13" r="2"/><path pid="3" d="M22 11.5h-6.5a1 1 0 000 2H22a1 1 0 000-2z"/><circle pid="4" cx="11" cy="19" r="2"/><path pid="5" d="M18.5 17.5h-3a1 1 0 000 2h3a1 1 0 000-2zM6 9.5a1 1 0 00-1 1V12a1 1 0 002 0v-1.5a1 1 0 00-1-1zM6 14a1 1 0 00-1 1v1.5a1 1 0 002 0V15a1 1 0 00-1-1zM6 18.5a1 1 0 00-1 1V21a1 1 0 002 0v-1.5a1 1 0 00-1-1z"/></g>'
  }
})
