<template>
    <div v-theme="['form.text']" class="px-4 pb-16">
        <slot name="block-header"></slot>

        <div v-if="isOrderEmpty">
            <div v-theme="'form.accent'" class="p-6 flex flex-col items-center">
                <app-icon
                    name="shopping-basket-star"
                    class="h-10 w-10"
                    stroke
                ></app-icon>
                <div v-theme="'form.title-text'" class="text-xl text-center mt-4">
                    {{ $t('title-empty-order') }}
                </div>
                <div v-theme="'form.text'" class="text-center mt-4">
                    {{ $t('subtitle-please-respond-to-ticketing-block') }}
                </div>
            </div>
        </div>
        <template v-else>
            <div v-for="orderItem in selectedNonDonationPaidOrderItems" :key="orderItem.id">
                <div v-theme="'form.accent'" class="p-6 flex justify-between border-b">
                    <div>
                        <div class="flex font-semibold">
                            <app-icon
                                v-if="orderItem.orderable.settings.icon"
                                :name="orderItem.orderable.settings.icon"
                                class="h-5 w-5 mr-2"
                            ></app-icon>
                            {{ orderItem.orderable.title }}
                        </div>
                        <div class="flex items-end">
                            <div class="h-5 w-5 mr-2 mt-1"></div>
                            {{ orderItem.quantity | number }}&nbsp;x&nbsp;
                            <money-field static :value="orderItem.price"></money-field>
                        </div>
                    </div>
                    <div class="flex items-center font-semibold">
                        <money-field static :value="orderItem.totalPrice"></money-field>
                    </div>
                </div>
            </div>

            <template v-if="event.settings.showFreeQuestionOptionsInReceiptBlock">
                <div v-for="answer in nonpaidCustomQuestionOptionAnswers" :key="answer.id">
                    <div v-theme="'form.accent'" class="p-6 flex justify-between border-b">
                        <div>
                            <div class="flex font-semibold">
                                <app-icon
                                    v-if="answer.icon"
                                    :name="answer.icon"
                                    class="h-5 w-5 mr-2"
                                ></app-icon>
                                {{ answer.title }}
                            </div>
                            <div class="flex items-end">
                                <div class="h-5 w-5 mr-2 mt-1"></div>
                                {{ answer.quantity | number }}&nbsp;x&nbsp;
                                <money-field static :value="0"></money-field>
                            </div>
                        </div>
                        <div class="flex items-center font-semibold">
                            <money-field static :value="0"></money-field>
                        </div>
                    </div>
                </div>
            </template>

            <div v-theme="'form.accent'" class="px-6 pt-6 flex justify-between">
                <div class="font-semibold uppercase tracking-wide">
                    Subtotal
                </div>
                <div class="font-semibold">
                    <money-field static :value="order.subtotal"></money-field>
                </div>
            </div>

            <div
                v-if="event.sales_tax.enabled"
                v-theme="'form.accent'"
                class="p-6 flex justify-between"
                :class="salesTaxSectionStyles"
            >
                <div class="font-semibold">
                    {{ event.sales_tax.label }}
                </div>
                <div class="font-semibold">
                    <money-field static :value="order.tax"></money-field>
                </div>
            </div>

            <div
                v-if="order.fee_passthrough_enabled"
                v-theme="'form.accent'"
                class="p-6 flex justify-between"
                :class="serviceFeeSectionStyles"
            >
                <div class="font-semibold">
                    {{ event.settings.feePassthrough.label }}
                </div>
                <div class="font-semibold">
                    <money-field static :value="order.passthroughFee"></money-field>
                </div>
            </div>

            <div
                v-if="order.coupon"
                v-theme="'form.accent'"
                class="p-6 flex justify-between"
                :class="couponSectionStyles"
            >
                <div class="font-semibold">
                    <span class="uppercase mr-2">
                        "{{ order.coupon.code }}"
                    </span>
                    <button
                        v-if="!paymentSuccessful"
                        class="accent-text"
                        @click="removeCoupon"
                    >
                        {{ $t('button-remove') }}
                    </button>
                </div>
                <div class="font-semibold">(<money-field static :value="-order.discount"></money-field>)</div>
            </div>

            <div v-for="(orderItem, index) in donationOrderItems" :key="orderItem.id">
                <div
                    v-theme="'form.accent'"
                    class="p-6 flex justify-between"
                    :class="donationSectionStyles(index)"
                >
                    <div>
                        <div class="flex font-semibold">
                            <app-icon
                                v-if="orderItem.orderable.settings.icon"
                                :name="orderItem.orderable.settings.icon"
                                class="h-5 w-5 mr-2"
                            ></app-icon>
                            {{ orderItem.orderable.title }}
                        </div>
                        <div class="flex items-end">
                            <div class="h-5 w-5 mr-2 mt-1"></div>
                            {{ orderItem.orderable.settings.description }}
                        </div>
                    </div>
                    <div class="flex items-center font-semibold">
                        <money-field static :value="orderItem.totalPrice"></money-field>
                    </div>
                </div>
            </div>

            <div v-theme="'form.accent'" class="px-6 pt-6 flex justify-between">
                <div class="font-semibold uppercase tracking-wide">
                    {{ $t('text-order-total') }}
                </div>
                <div class="font-semibold">
                    <money-field static :value="order.total"></money-field>
                </div>
            </div>

            <button
                v-if="canApplyCoupon"
                class="text-left ml-6 accent-text"
                @click="showCouponForm"
            >
                {{ $t('button-apply-coupon') }}
            </button>

            <div v-if="showCouponInput" class="pl-6">
                <div v-if="couponError" class="mt-4 badge badge-error">
                    {{ couponError }}
                </div>
                <input
                    v-model="coupon"
                    v-theme="['form.accent', 'form.text']"
                    class="uppercase h-full block w-72 py-2 border-b-2 rounded-none mb-4 bg-transparent outline-none placeholder no-mousetrap"
                    type="text"
                    :placeholder="$t('placeholder-enter-coupon-code')"
                    :disabled="paymentSuccessful"
                    @keydown.enter="applyCoupon"
                >

                <button
                    v-theme="['form.secondary-button']"
                    class="button-text border rounded-md px-2 py-1 mr-2"
                    @click="applyCoupon"
                >{{ $t('button-apply') }}</button>

                <button
                    v-theme="['form.secondary-button']"
                    class="button-text"
                    @click="removeCoupon"
                >{{ $t('button-cancel') }}</button>
            </div>
            <div v-if="paymentSuccessful" class="text-right p-6 opacity-50">
                <div class="mb-2">
                    {{ cardholder.name }}
                </div>
                <div>
                    Visa **** **** **** 8876
                </div>
            </div>

            <div v-if="!paymentSuccessful" class="absolute mx-auto left-0 right-0 bottom-0 mx-auto mb-5">
                <rsvp-next-button
                    v-if="isActive"
                    class="-mb-10"
                    :loading="loading"
                    @click="handleCompletion"
                >
                    <template v-if="!loading">
                        <app-icon
                            class="mx-2 h-6 w-6 fill-current"
                            name="check-circle"
                        ></app-icon>

                        <div class="mx-2 text-left text-sm">
                            <div v-if="order.total !== 0">
                                {{ $t('button-pay') }} {{ $t('button-and') }}
                            </div>
                            <div class="tracking-wide font-semibold uppercase">{{ $t('button-submit') }}</div>
                        </div>
                    </template>
                </rsvp-next-button>
            </div>
            <div v-else>
                <div class="text-xl text-center mt-4">
                    {{ $t('text-successful-payment') }}
                </div>
            </div>
        </template>

        <checkout-preview
            v-model="showPreviewCheckoutModal"
            @confirm="handleCheckoutConfirmation"
        ></checkout-preview>

        <portal to="rsvp-form-target">
            <payment-screen
                v-model="showPaymentScreen"
                :loading="loading"
                :payment-error="paymentError"
                @confirm="completeBlock"
            ></payment-screen>
        </portal>
    </div>
</template>

<script>
import getValue from 'lodash/get';
import {
    flatMap, groupBy, values, mapValues, sumBy
} from 'lodash';
import { get, sync } from 'vuex-pathify';
import FormBlock from '@/mixins/FormBlock';
import axios from '@/util/axios';

export default {
    name: 'FormReceiptBlock',

    mixins: [FormBlock],

    behaviour: {
        render: {
            when (block, submission) {
                return true;
            },
            unless (block, submission) {
                if (getValue(submission, 'order.total', 0) > 0) {
                    return false;
                }

                return getValue(submission, 'order.subtotal', 0) === 0;
            }
        }
    },

    data () {
        return {
            cardholder: {
                name: '',
                email: '',
                last4: ''
            },
            coupon: '',
            couponError: null,
            loading: false,
            paymentError: null,
            showCouponInput: false,
            showPreviewCheckoutModal: false,
            registrationType: ''
        };
    },

    computed: {
        ...get('Event/*'),

        guests: get('Submission/submission@guests'),
        order: get('Submission/submission@order'),
        paymentSectionCompleted: sync('Submission/paymentSectionCompleted'),
        showPaymentScreen: sync('Form/showPaymentScreen'),

        canApplyCoupon () {
            return this.event.hasCoupons
                && !this.showCouponInput
                && !this.order.coupon
                && !this.paymentSuccessful;
        },

        couponSectionStyles () {
            return {
                'border-b': this.donationOrderItems.length > 0,
                'border-b-2': this.donationOrderItems.length === 0
            };
        },

        donationOrderItems () {
            return this.order.items.filter((orderItem) => {
                return orderItem.isDonation;
            });
        },

        isCourtesyRegistration () {
            return this.registrationType === 'courtesy';
        },

        isOrderEmpty () {
            return !this.order || this.order.items.length === 0;
        },

        securityToken: get('Submission/securityToken'),

        selectedNonDonationPaidOrderItems () {
            return this.order.items.filter((orderItem) => {
                return !orderItem.isDonation && orderItem.price > 0 && orderItem.quantity > 0;
            });
        },

        nonpaidCustomQuestionOptionAnswers () {
            const groupedAnswers = groupBy(
                flatMap(this.submission.allQuestionAnswers, (question) => {
                    return question.answers.map((answer) => {
                        return {
                            ...answer,
                            slug: question.slug
                        };
                    });
                })
                    .filter((answer) => {
                        return (!answer.costsMoney || answer.price === 0) && answer.id !== null;
                    }),
                'id'
            );

            return values(mapValues(groupedAnswers, (answers, questionOptionId) => {
                const { slug } = answers[0];
                let title = answers[0].value;
                let quantity = answers.length;

                // Remove the `x [quantity]` suffix from value
                if (slug === 'quantity') {
                    title = title.replace(/ x (\d)*?$/g, '');
                    quantity = sumBy(answers, (answer) => { return parseInt(answer.raw_value, 10); });
                }

                return {
                    question_option_id: questionOptionId,
                    quantity,
                    icon: answers[0].icon,
                    price: answers[0].price,
                    title
                };
            }));
        },

        paymentSuccessful () {
            return this.order.payment && this.order.payment.isSuccessful;
        },

        salesTaxSectionStyles () {
            return {
                'border-b': this.order.fee_passthrough_enabled || this.order.coupon || this.donationOrderItems.length > 0,
                'border-b-2': !this.order.fee_passthrough_enabled && !this.order.coupon && this.donationOrderItems.length === 0
            };
        },

        serviceFeeSectionStyles () {
            return {
                'border-b': this.order.coupon || this.donationOrderItems.length > 0,
                'border-b-2': !this.order.coupon && this.donationOrderItems.length === 0
            };
        }
    },

    methods: {
        applyCoupon () {
            this.couponError = null;

            const route = this.route('api.submissions.apply-coupon', [
                this.submission.id,
                this.submission.uuid
            ]);

            axios.post(route, { couponCode: this.coupon.toUpperCase() })
                .then((response) => {
                    Object.assign(this.submission, response.data);
                    this.showCouponInput = false;
                }).catch(({ response }) => {
                    if (response.status === 422) {
                        this.couponError = response.data.errors.couponCode[0];
                    }
                });
        },

        async completeBlock (cardholder = null) {
            this.loading = true;

            this.$set(this, 'cardholder', cardholder);

            let successful = true;

            if (!this.isCourtesyRegistration && this.order.total > 0) {
                successful = await this.handleLiveRegistration();
            }

            if (successful) {
                this.$emit('complete-block', this.getCompletionObject());

                this.showPaymentScreen = false;
                this.paymentSectionCompleted = true;
            }

            this.loading = false;
        },

        donationSectionStyles (index) {
            return {
                'border-b': index < this.donationOrderItems.length - 1,
                'border-b-2': index === this.donationOrderItems.length - 1
            };
        },

        enterKeyCompletionFunction () {
            if (this.showPreviewCheckoutModal) {
                return;
            }

            if (this.showPaymentScreen) {
                return;
            }

            this.handleCompletion();
        },

        handleCheckoutConfirmation (registrationType) {
            this.registrationType = registrationType;

            this.showPreviewCheckoutModal = false;

            if (registrationType === 'charge') {
                this.showPaymentScreen = true;
            } else if (registrationType === 'courtesy') {
                this.loading = true;
                this.$emit('complete-block', this.getCompletionObject());
            } else if (registrationType === 'test') {
                this.submission.test_mode = true;
                this.handleCompletion();
            }
        },

        handleCompletion () {
            if (this.order.total === 0) {
                this.completeBlock();
                return;
            }

            const isEventHost = this.can('create-submission.event');

            if (this.submission.test_mode || !isEventHost) {
                this.showPaymentScreen = true;
            } else if (isEventHost) {
                this.showPreviewCheckoutModal = true;
            }
        },

        async handleLiveRegistration () {
            const route = window.route('api.submissions.complete-block', {
                submission: this.submission.id,
                uuid: this.submission.uuid
            });

            const { data } = await axios.post(route, {
                block: this.block.pivot.id,
                payload: {
                    registrationType: this.registrationType
                },
                securityToken: this.securityToken
            });

            return this.handlePayment(data.result);
        },

        handlePayment (clientSecret) {
            return window.StripeObject.instance.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: window.StripeObject.card,
                    billing_details: {
                        name: this.cardholder.name,
                        email: this.cardholder.email
                    }
                }
            }).then((result) => {
                this.paymentError = null;

                if (result.error) {
                    this.paymentError = result.error.message;

                    return false;
                }

                if (result.paymentIntent.status === 'succeeded') {
                    return true;
                }
            });
        },

        removeCoupon () {
            const route = this.route('api.submissions.remove-coupon', [
                this.submission.id,
                this.submission.uuid
            ]);

            axios.delete(route)
                .then((response) => {
                    Object.assign(this.submission, response.data);

                    this.coupon = '';
                    this.couponError = null;
                    this.showCouponInput = false;
                }).catch((error) => {
                    this.$toasted.global.error('Could not remove coupon.');
                });
        },

        serializePayload () {
            return {
                registrationType: this.registrationType
            };
        },

        showCouponForm () {
            this.showCouponInput = true;
        }
    }
};
</script>
