<template>
    <div class="flex flex-col grow">
        <div class="flex flex-wrap">
            <form-field-wrapper label="Donation tier title">
                <input
                    id="tier-title"
                    v-model="tier.title"
                    class="form-field"
                    type="text"
                    placeholder="Enter a title for your donation tier"
                >
            </form-field-wrapper>

            <form-field-wrapper :label="tierPriceLabel">
                <input
                    id="tier-price"
                    v-model="tier.price"
                    class="form-field"
                    type="number"
                    step="1"
                    min="1"
                    placeholder="Enter the donation amount"
                >
            </form-field-wrapper>

            <form-field-wrapper label="Details">
                <textarea
                    id="description"
                    v-model="tier.description"
                    class="form-field"
                    rows="5"
                    placeholder="Provide some details for your guests about this donation tier..."
                ></textarea>
            </form-field-wrapper>

            <portal to="app-drawer-footer">
                <footer-save-cancel
                    @cancel="$emit('cancel')"
                    @save="$emit('save-settings')"
                ></footer-save-cancel>
            </portal>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WallDonationBlockItemSettings',

    props: {
        tier: {
            type: Object,
            default: () => { return {}; }
        }
    },

    computed: {
        tierPriceLabel () {
            if (this.tier.canModifyPrice) {
                return 'Default Donation Amount';
            }

            return 'Donation Amount';
        }
    }
};
</script>
