/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-copy': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>duplicate clone</desc><g _fill="currentColor"><path pid="0" d="M21.81 3.85L18.39.43l-.01-.01c-.29-.29-.67-.45-1.07-.45H6.71c-.83 0-1.5.67-1.5 1.5v17.5c0 .82.67 1.49 1.5 1.49h14-.01c.82 0 1.5-.68 1.5-1.5V4.87h-.01c0-.4-.16-.78-.44-1.07z"/><path pid="1" d="M17.75 22H4.74c-.56-.01-1-.45-1-1.01V3.48c0-.56-.45-1-1-1-.56 0-1 .44-1 1v17.5c0 1.65 1.34 2.99 3 2.99h13-.01c.55 0 1-.45 1-1 0-.56-.45-1-1-1.01z"/></g>'
  }
})
