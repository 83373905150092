/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gift-share-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>products give</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.75 23.238v0c0-1.66-1.35-3-3-3H9v0c0-1.66-1.35-3-3-3H.75v6zM6 20.24h3M11.25 17.238H21h-.01c.41 0 .75-.34.75-.75v-6.75M8.25 9.74v4.5"/><rect pid="1" width="16.5" height="3.75" x="6.75" y="5.988" rx=".75"/><path pid="2" d="M15 17.24V5.99M19.26 3.86h-.01c-1.26.98-2.7 1.7-4.25 2.12v-.01c.41-1.55 1.13-2.99 2.12-4.25C18.43.4 19.18.6 19.77 1.19c.59.58.78 1.333-.54 2.651zM10.73 3.86l-.01-.01c1.25.98 2.69 1.7 4.24 2.12l-.01-.01a11.9 11.9 0 00-2.13-4.25C11.5.39 10.75.59 10.16 1.18c-.59.58-.79 1.333.53 2.651z"/></g>'
  }
})
