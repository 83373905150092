<template>
    <div v-if="!event.classyIntegration" class="flex items-center">
        <base-dropdown placement="bottom">
            <template #trigger="{ isOpen, triggerFunction }">
                <div class="flex h-full items-center">
                    <div class="flex overflow-hidden rounded border hover:border-purple text-gray-500 hover:text-gray-700 transition">
                        <div v-if="actionInProgress" class="inline-flex items-center justify-center py-1 px-16">
                            <app-icon class="h-8 w-8 text-purple" name="loader"></app-icon>
                        </div>
                        <button
                            v-else
                            class="inline-flex items-center justify-center relative py-2 px-4 space-x-2 bg-transparent font-semibold"
                            @click="triggerFunction"
                        >
                            <div class="rounded-full border-2 h-2 w-2 p-1" :class="eventStatus.circleStyle"></div>
                            <span>{{ eventStatus.label }}</span>
                            <app-icon
                                :name="isOpen ? 'arrow-up-chevron' : 'arrow-down-chevron'"
                                class="h-4 w-4"
                                stroke
                            ></app-icon>
                        </button>
                    </div>
                </div>
            </template>
            <div class="min-w-56 mt-2 bg-white border rounded">
                <div v-for="(item, index) in menuItems.publishActions" :key="`publishActions-${index}`">
                    <publish-now-action
                        v-if="item === 'open-registrations'"
                        v-slot="{ publishNow }"
                        @action-in-progress="toggleActionInProgress"
                    >
                        <div class="flex flex-col space-y-2 w-full px-4 py-2 cursor-pointer hover:bg-gray-100 transition">
                            <button class="flex items-center space-x-4" @click="publishNow">
                                <div class="flex items-center">
                                    <div class="rounded-full bg-green border-2 border-green h-2 w-2 p-1"></div>
                                </div>
                                <div class="flex flex-col flex-1 items-start justify-center">
                                    {{ registrationRsvpLabels.registrationOpen }}
                                    <span class="text-gray-400 font-light">Accepting {{ registrationRsvpLabels.registrationLabel }}s</span>
                                </div>
                                <div v-if="isInState('registration-open')" class="flex items-center justify-center text-gray-400">
                                    <app-icon
                                        name="check"
                                        class="h-4 w-4"
                                    ></app-icon>
                                </div>
                            </button>
                            <div v-if="showRegistrationClosesInEditInfo" class="flex space-x-3">
                                <div class="flex items-center">
                                    <app-icon name="calendar-clock" class="h-4 w-4"></app-icon>
                                </div>
                                <div class="flex-1 text-sm font-light text-gray-400">
                                    Deadline in
                                    <span
                                        v-tippy
                                        :content="registrationClosesAtFormattedDateTime"
                                        class="tooltip-text font-semibold"
                                    >
                                        {{ registrationClosesIn }}
                                    </span>
                                </div>
                                <set-registration-deadline-action v-slot="{ setRegistrationDeadline }" @action-in-progress="toggleActionInProgress">
                                    <button class="text-sm flex items-center justify-center font-semibold text-purple hover:text-purple-light" @click="setRegistrationDeadline">
                                        Edit
                                    </button>
                                </set-registration-deadline-action>
                            </div>
                        </div>
                    </publish-now-action>

                    <div v-if="item === 'unpublish'" class="flex space-x-4 w-full px-4 py-2 cursor-pointer hover:bg-gray-100 transition">
                        <div class="flex items-center">
                            <div class="rounded-full border-2 border-yellow h-2 w-2 p-1"></div>
                        </div>
                        <div class="flex flex-col flex-1 items-start justify-center">
                            Unpublished
                            <span class="text-gray-400 font-light">Event website not live</span>
                        </div>
                        <div v-if="isInState('unpublished')" class="flex items-center justify-center text-gray-400">
                            <app-icon
                                name="check"
                                class="h-4 w-4"
                            ></app-icon>
                        </div>
                    </div>

                    <div v-if="item === 'canceled'" class="flex space-x-4 w-full px-4 py-2 cursor-pointer hover:bg-gray-100 transition">
                        <div class="flex items-center">
                            <div class="rounded-full border-2 border-red bg-red h-2 w-2 p-1"></div>
                        </div>
                        <div class="flex flex-col flex-1 items-start justify-center">
                            Canceled
                            <span class="text-gray-400 font-light">Event has been canceled</span>
                        </div>
                        <div v-if="isInState('canceled')" class="flex items-center justify-center text-gray-400">
                            <app-icon
                                name="check"
                                class="h-4 w-4"
                            ></app-icon>
                        </div>
                    </div>

                    <close-form-registrations-action
                        v-if="item === 'close'"
                        v-slot="{ closeFormRegistrations }"
                        @action-in-progress="toggleActionInProgress"
                    >
                        <button class="flex space-x-4 items-center w-full px-4 py-2 cursor-pointer hover:bg-gray-100 transition" @click="closeFormRegistrations">
                            <div class="flex items-center">
                                <div class="rounded-full border-2 border-red h-2 w-2 p-1"></div>
                            </div>
                            <div class="flex flex-col flex-1 items-start justify-center">
                                Closed
                                <span class="text-gray-400 font-light">No new {{ registrationRsvpLabels.registrationLabel }}s</span>
                                <span v-if="event.isClosed" class="text-purple text-sm font-semibold text-purple">
                                    Edit public message
                                </span>
                            </div>
                            <div v-if="isInState('closed')" class="flex items-center justify-center text-gray-400">
                                <app-icon
                                    name="check"
                                    class="h-4 w-4"
                                ></app-icon>
                            </div>
                        </button>
                    </close-form-registrations-action>

                    <schedule-to-open-action
                        v-show="item === 'schedule'"
                        v-slot="{ scheduleToOpen }"
                        class="flex flex-col space-y-1 w-full px-4 py-2 cursor-pointer hover:bg-gray-100 transition"
                        @action-in-progress="toggleActionInProgress"
                    >
                        <div class="flex space-x-4">
                            <div class="flex items-center">
                                <div class="rounded-full border-2 border-green h-2 w-2 p-1"></div>
                            </div>
                            <div class="flex flex-col flex-1 items-start justify-center">
                                Scheduled
                                <span class="text-gray-400 font-light">Website is live</span>
                            </div>
                            <div v-if="isInState('scheduled')" class="flex items-center justify-center text-gray-400">
                                <app-icon
                                    name="check"
                                    class="h-4 w-4"
                                ></app-icon>
                            </div>
                        </div>
                        <div v-if="showRegistrationOpensInEditInfo" class="flex space-x-3">
                            <div class="flex items-center">
                                <app-icon name="calendar-clock" class="h-4 w-4"></app-icon>
                            </div>
                            <div class="flex-1 text-sm font-light text-gray-400">
                                {{ registrationRsvpLabels.registrationOpensIn }}
                                <span
                                    v-tippy
                                    :content="registrationOpensAtFormattedDateTime"
                                    class="tooltip-text font-semibold"
                                >
                                    {{ registrationOpensIn }}
                                </span>
                            </div>
                            <button class="text-sm flex items-center justify-center font-semibold text-purple hover:text-purple-light" @click="scheduleToOpen">
                                Edit
                            </button>
                        </div>
                    </schedule-to-open-action>
                </div>

                <div v-for="(item, index) in menuItems.additionalActions" :key="`additionalActions-${index}`">
                    <div class="border-b"></div>

                    <a
                        v-if="item === 'connect-payment-processor'"
                        :href="route('settings.event.payments-and-coupons', event)"
                        class="flex space-x-3 items-center w-full px-4 py-2 cursor-pointer hover:bg-gray-100 transition"
                    >
                        <div class="flex items-center">
                            <app-icon name="credit-card" class="h-4 w-4"></app-icon>
                        </div>
                        <span class="flex-1">
                            Connect payment processor
                        </span>
                    </a>

                    <set-registration-deadline-action
                        v-show="item === 'set-registration-deadline'"
                        v-slot="{ setRegistrationDeadline }"
                        @action-in-progress="toggleActionInProgress"
                    >
                        <button class="flex space-x-3 items-center w-full px-4 py-2 cursor-pointer hover:bg-gray-100 transition" @click="setRegistrationDeadline">
                            <div class="flex items-center">
                                <app-icon
                                    name="stop-sign"
                                    stroke
                                    class="h-4 w-4"
                                ></app-icon>
                            </div>
                            <span class="flex flex-1">
                                {{ hasDeadline ? 'Edit' : 'Set' }} {{ registrationRsvpLabels.registrationLabel }} deadline
                            </span>
                        </button>
                    </set-registration-deadline-action>

                    <a
                        v-if="item === 'cancel-event'"
                        :href="route('publish-invite.cancel', event)"
                        class="flex space-x-3 items-center w-full px-4 py-2 cursor-pointer hover:bg-red-light hover:text-red-dark transition"
                    >
                        <div class="flex items-center">
                            <app-icon
                                name="calendar-disable"
                                stroke
                                class="h-4 w-4"
                            ></app-icon>
                        </div>
                        <span class="flex-1">
                            Cancel event
                        </span>
                    </a>

                    <copy-to-clipboard
                        v-if="item === 'share-preview-link'"
                        :copy="event.previewUrl"
                        @copied="$toasted.global.success('Preview link copied to clipboard and ready to share!')"
                    >
                        <div class="flex space-x-3 items-center w-full px-4 py-2 cursor-pointer hover:bg-gray-100 transition">
                            <div class="flex items-center">
                                <app-icon name="link" class="h-4 w-4"></app-icon>
                            </div>
                            <span class="flex-1">
                                Share Preview Link
                            </span>
                        </div>
                    </copy-to-clipboard>

                    <copy-published-url v-if="item === 'copy-event-link'">
                        <template #default="{ copyPublishedUrl }">
                            <button class="flex space-x-3 items-center w-full px-4 py-2 cursor-pointer hover:bg-gray-100 transition" @click="copyPublishedUrl">
                                <div class="flex items-center">
                                    <app-icon
                                        name="link"
                                        class="h-4 w-4"
                                        stroke
                                    ></app-icon>
                                </div>
                                <span>
                                    Copy event link
                                </span>
                            </button>
                        </template>
                    </copy-published-url>

                    <schedule-to-open-action
                        v-show="item === 'schedule-to-open'"
                        v-slot="{ scheduleToOpen }"
                        @action-in-progress="toggleActionInProgress"
                    >
                        <button class="flex space-x-3 items-center w-full px-4 py-2 cursor-pointer hover:bg-gray-100 transition" @click="scheduleToOpen">
                            <div class="flex items-center">
                                <app-icon
                                    name="time-clock"
                                    stroke
                                    class="h-4 w-4"
                                ></app-icon>
                            </div>
                            <span class="flex flex-1">
                                Schedule to open
                            </span>
                            <div
                                v-tippy
                                class="text-sm flex items-center justify-center"
                                content="Set a date to open your event’s registration."
                            >
                                <app-icon
                                    name="info-circle"
                                    class="h-4 w-4"
                                    stroke
                                ></app-icon>
                            </div>
                        </button>
                    </schedule-to-open-action>

                    <unpublish-action
                        v-if="item === 'unpublish'"
                        v-slot="{ unpublish }"
                        @action-in-progress="toggleActionInProgress"
                    >
                        <button class="flex space-x-3 items-center w-full px-4 py-2 cursor-pointer hover:bg-red-light hover:text-red-dark transition" @click="unpublish">
                            <div class="flex items-center">
                                <app-icon
                                    name="power-button"
                                    stroke
                                    class="h-4 w-4"
                                ></app-icon>
                            </div>

                            <span>Unpublish event</span>
                        </button>
                    </unpublish-action>
                </div>
            </div>
        </base-dropdown>

        <button
            v-if="showViewEvent"
            v-tippy
            class="inline-flex md ml-2 p-2 border hover:border-purple rounded text-gray-500 hover:text-gray-700 transition"
            :content="viewEventTooltip"
            @click="previewEvent"
        >
            <app-icon name="view" class="h-6 w-6"></app-icon>
        </button>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import EventStatus from '@/mixins/EventStatus';
import IsEventPublished from '@/mixins/IsEventPublished';
import RegistrationRSVPLabels from '@/mixins/RegistrationRSVPLabels';
import FormOpensAtClosesAtFormattedDateTime from '@/mixins/FormOpensAtClosesAtFormattedDateTime';
import CompletesTasks from '@/mixins/CompletesTasks';

export default {
    name: 'EventActions',

    mixins: [
        IsEventPublished,
        FormOpensAtClosesAtFormattedDateTime,
        RegistrationRSVPLabels,
        EventStatus,
        CompletesTasks
    ],

    props: {
        showViewEvent: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            actionInProgress: false
        };
    },

    computed: {
        event: get('Event/event'),
        form: get('Form/form'),

        hasDeadline () {
            return this.isEventPublished && this.form.closed_at && !this.form.isClosed;
        },

        menuItems () {
            const publishActions = [];
            const additionalActions = [];

            if (!this.isEventPublished) {
                publishActions.push('open-registrations', 'unpublish');
                this.event.hasPayableItems && !this.event.isStripeConnected
                    ? additionalActions.push('connect-payment-processor', 'share-preview-link', 'schedule-to-open')
                    : additionalActions.push('share-preview-link', 'schedule-to-open');
            }

            if (this.event.isCurrentlyLive) {
                publishActions.push('open-registrations', 'close');
                additionalActions.push('copy-event-link', 'set-registration-deadline', 'cancel-event', 'unpublish');
            }

            if (this.event.isScheduledToOpen) {
                publishActions.push('open-registrations', 'schedule', 'close');
                additionalActions.push('copy-event-link', 'set-registration-deadline', 'unpublish');
            }

            if (this.event.isClosed) {
                publishActions.push('open-registrations', 'close');
                additionalActions.push('copy-event-link', 'cancel-event', 'unpublish');
            }

            if (this.event.isCancelled) {
                publishActions.push('open-registrations', 'canceled');
                additionalActions.push('copy-event-link', 'unpublish');
            }

            return {
                publishActions,
                additionalActions
            };
        },

        showRegistrationClosesInEditInfo () {
            return this.hasDeadline && this.event.isCurrentlyLive && this.registrationClosesIn;
        },

        showRegistrationOpensInEditInfo () {
            return this.event.isScheduledToOpen && this.registrationOpensIn;
        },

        viewEventTooltip () {
            return this.isEventPublished ? 'View Event' : 'Preview Event';
        },

        viewEventUrl () {
            if (this.isEventTemplate) {
                return this.event.previewUrl;
            }

            return this.isEventPublished
                ? this.route('event.show', this.event.subdomain)
                : this.event.previewUrl;
        }
    },

    methods: {
        isInState (state) {
            if (state === 'registration-open') {
                return this.event.isCurrentlyLive;
            }

            if (state === 'unpublished') {
                return !this.isEventPublished;
            }

            if (state === 'closed') {
                return this.event.isClosed;
            }

            if (state === 'canceled') {
                return this.event.isCancelled;
            }

            if (state === 'scheduled') {
                return this.event.isScheduledToOpen;
            }

            return false;
        },

        toggleActionInProgress (isInProgress) {
            this.actionInProgress = isInProgress;
        }
    }
};
</script>
