<template>
    <div class="relative mb-4">
        <textarea
            ref="commentField"
            class="w-full h-10 pt-2 pr-10 pb-2 pl-10 border focus:border-gray-300 rounded overflow-hidden transitions-all outline-none"
            placeholder="Click to type note..."
            v-bind="$attrs"
            @input="event => $emit('input', event.target.value)"
            v-on="omit($listeners, 'input')"
        ></textarea>

        <img class="absolute top-2 left-2 w-6 h-6" :src="asset('images/svg/comment.svg')">

        <div v-if="hasSlot('button')" class="absolute top-2 right-2">
            <slot name="button"></slot>
        </div>
    </div>
</template>

<script>
import omit from 'lodash/omit';
import autosize from 'autosize';
import SlotHelpers from '@/mixins/SlotHelpers';

export default {
    mixins: [
        SlotHelpers
    ],

    mounted () {
        autosize(this.$refs.commentField);
    },

    beforeDestroy () {
        autosize.destroy(this.$refs.commentField);
    },

    methods: {
        omit
    }
};
</script>
