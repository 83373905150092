/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-symptoms-virus-headache-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.326 23.25v-3.99h2.76l-.01-.001c.73 0 1.32-.6 1.33-1.33v-2.88h1.53a1.026 1.026 0 00.93-1.45c-1.01-2.237-2.48-4.987-2.48-4.987V6.42c0-.3-.07-.59-.19-.85l-.01-.01a7.316 7.316 0 00-4.98-4.53C8.22-.54 2.01 3.64 1.96 9.82h-.01a9.139 9.139 0 003.07 6.93v6.44"/><path pid="1" d="M10.98 6.92a2.77 2.77 0 100 5.55 2.77 2.77 0 100-5.56zM10.52 4.84h.93M10.98 4.84v2.08M14.09 5.94l.66.65M14.42 6.26l-1.47 1.48M15.84 9.24v.92M15.84 9.7h-2.08M14.75 12.81l-.66.65M14.42 13.13l-1.47-1.47M11.45 14.56h-.93M10.98 14.56v-2.08M7.88 13.46l-.66-.65M7.55 13.13l1.47-1.47M6.13 10.16v-.92M6.13 9.7h2.08M7.22 6.59l.66-.65M7.55 6.26l1.47 1.48"/></g>'
  }
})
