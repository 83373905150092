<template>
    <div class="w-full h-full">
        <button
            class="h-full w-full flex flex-col p-4 bg-white ring-2 ring-transparent shadow-md cursor-pointer rounded-md text-left text-gray-800 transition duration-200 ease-in-out hover:ring-purple hover:shadow-lg"
            @click="isModalVisible = true"
        >
            <div class="inline-flex mb-4">
                <div class="rounded-full bg-purple-100 p-3">
                    <app-icon
                        name="duplicate-square"
                        class="h-10 w-10 text-purple"
                        stroke
                    ></app-icon>
                </div>
            </div>

            <div class="text-sm font-medium uppercase trackiner-widest text-gray-500 mb-2">Clone Event</div>
            <p class="text-xl font-light">Clone an event into a user's account</p>
        </button>

        <app-modal v-model="isModalVisible" title="Clone Event">
            <form id="clone-form" @submit.prevent="cloneEvent">
                <form-field-wrapper label="Event ID" :error="form.errors.get('event_id')">
                    <input
                        v-model="form.event_id"
                        class="form-field"
                        placeholder="12345"
                    >
                </form-field-wrapper>

                <form-field-wrapper label="Destination Account" :error="form.errors.get('user_email')">
                    <input
                        v-model="form.user_email"
                        class="form-field"
                        placeholder="user@domain.com"
                    >
                </form-field-wrapper>

                <form-field-wrapper label="RSVPify Event URL" :error="form.errors.get('subdomain')">
                    <event-subdomain v-model="form.subdomain"></event-subdomain>
                </form-field-wrapper>
            </form>

            <template #footer="{ close }">
                <stateful-button
                    class="button button-primary"
                    form="clone-form"
                    :loading="form.processing"
                    type="submit"
                >
                    Clone Event
                </stateful-button>

                <button class="button" @click="close">
                    Cancel
                </button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import Form from '@/validation/Form';

export default {
    data () {
        return {
            form: new Form({
                event_id: null,
                subdomain: '',
                user_email: null
            }),
            isModalVisible: false
        };
    },

    watch: {
        isModalVisible () {
            this.cloned = false;
            this.form.restore();
        }
    },

    methods: {
        cloneEvent () {
            this.form.post(this.route('api.admin.events.clone'))
                .then(() => {
                    this.$toasted.global.success(`${this.form.event_id} was cloned into ${this.form.user_email}.`);
                    this.isModalVisible = false;
                    this.form.restore();
                });
        }
    }
};
</script>
