/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'conversation-chat-bubble-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>meeting network group users message team</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 11.25a3 3 0 100 6 3 3 0 100-6zM.75 23.25h0a4.494 4.494 0 014.49-4.51c2.48-.01 4.5 2.01 4.5 4.49 0 0 0 0 0 0M21.349 12.75l-.01-.01c.82 1.43.33 3.27-1.11 4.09-1.44.82-3.28.33-4.1-1.11-.27-.46-.4-.97-.4-1.5M14.25 23.25h0a4.494 4.494 0 014.49-4.51c2.48-.01 4.5 2.01 4.5 4.49 0 0 0 0 0 0M8.25 9.75h3v4.5l4.5-4.5h4.5v-9h-12v9zM11.25 3.75h6M11.25 6.75h6"/></g>'
  }
})
