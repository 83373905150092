/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-cheers-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>beer drink alcohol</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25 12.75h-2.26c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v7.5h0c0 .82.67 1.49 1.5 1.49h2.25"/><path pid="1" d="M19.5 15h-1.33l-.01-.001a2.742 2.742 0 01-2.43-1.51v0l-.01-.01c-.46-.92-1.4-1.5-2.43-1.5h-.58v1.5c0 .82-.68 1.5-1.5 1.5H6.7c-.83 0-1.5.67-1.5 1.5 0 .08 0 .17.02.26l.95 5.25h0c.12.71.75 1.23 1.47 1.23h6.19c.23 0 .46-.06.67-.16l4.92-2.1"/><path pid="2" d="M6.75 15V8.85h-.01c0-.89.26-1.75.74-2.48v0-.01c.48-.74.74-1.6.75-2.48v-2.4h0c-.01-.42.33-.76.74-.76V.71h1.5-.01c.41-.01.75.33.75.75v2.4l-.01-.01c0 .88.26 1.74.74 2.47v0h0c.48.73.74 1.59.74 2.47v3.14M6.75 9h6M18.75 2.25v3M17.25 3.75h3M2.25 10.5v3M.75 12h3M18.038 7.875h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M17.663 8.25h0c-.01-.21.16-.38.37-.38M18.038 8.625h-.01a.386.386 0 01-.38-.38s0 0 0 0M18.413 8.25v0c0 .2-.17.375-.375.375M2.625.75h-.01c.2-.01.37.16.37.37"/><path pid="4" d="M2.25 1.125h0c-.01-.21.16-.38.37-.38M2.625 1.5l-.01-.001a.386.386 0 01-.38-.38v-.001M3 1.125v0c0 .2-.17.375-.375.375"/></g>'
  }
})
