/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-mutation-stronger-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.408.966h-3.04M10.89.97v2.6M5.07 2.749l-1.08 1.07-1.072 1.07M4 3.82l1.84 1.84M1.14 9.2v3.026M1.14 10.72h2.6M2.925 16.538l1.072 1.07 1.07 1.07M4 17.61l1.84-1.84M18.858 4.89l-1.08-1.08-1.08-1.08M17.79 3.82l-1.84 1.84M11.57 12.81a.43.43 0 100 .86.43.43 0 100-.87zM9.375 7.03a1.51 1.51 0 100 3.03 1.51 1.51 0 100-3.04z"/><path pid="1" d="M17.74 8.683h0a7.157 7.157 0 00-8.89-4.83 7.142 7.142 0 00-4.83 8.88c.68 2.32 2.5 4.13 4.82 4.82M11.858 23.03h8.45c1.39 0 2.53-1.13 2.54-2.53v-6.61h-.01c.02-1.12-.8-2.07-1.9-2.22h0c-1.16-.12-2.19.71-2.31 1.87-.1.87.36 1.7 1.15 2.09v-.01c-.57 1.42-.57 3-.01 4.42l-.01-.01a4.675 4.675 0 00-6.31-2c-.38.19-.73.44-1.04.73"/></g>'
  }
})
