/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'conversation-chat-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>meeting network group users message team</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.75 8.25h-12l-4.5 4.5v-4.5h-3l-.01-.001a1.51 1.51 0 01-1.5-1.5V2.248h0A1.5 1.5 0 012.23.73h19.5-.01c.82-.01 1.5.67 1.5 1.5v4.5c0 .82-.68 1.49-1.5 1.5z"/><path pid="1" d="M2.25 11.25h-.01c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v4.5-.01c-.01.82.67 1.5 1.49 1.5h12l4.5 4.5v-4.5h3-.01c.82 0 1.5-.68 1.5-1.5v-4.5 0c0-.83-.68-1.5-1.5-1.5h-10.5"/></g>'
  }
})
