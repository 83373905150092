/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music-note-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.5 15.75a3.75 3.75 0 100 7.5 3.75 3.75 0 100-7.5zM19.5 11.25a3.75 3.75 0 100 7.5 3.75 3.75 0 100-7.5z"/><path pid="1" d="M8.25 19.5V6.71l-.01-.01c-.01-1.3.82-2.44 2.05-2.85L21.33.78h-.01c.79-.23 1.62.24 1.84 1.04.03.13.05.26.05.39v12.75M8.25 8.72l15-4.5"/></g>'
  }
})
