<template>
    <div>
        <slot :duplicate="duplicate"></slot>

        <duplicate-event-modal
            v-model="isDuplicateEventModalVisible"
            :number-of-used-events="numberOfUsedEvents"
            :original-event="event"
        ></duplicate-event-modal>
    </div>
</template>

<script>
export default {
    name: 'DuplicateEventAction',

    props: {
        event: {
            type: Object,
            required: true
        },

        numberOfUsedEvents: {
            type: Number,
            required: true
        }
    },

    data () {
        return {
            isDuplicateEventModalVisible: false
        };
    },

    methods: {
        duplicate () {
            this.isDuplicateEventModalVisible = true;
        }
    }
};
</script>
