/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-burger-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>restaurant fast hamburger cheeseburger</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.375 7.12l-.01-.001c.2 0 .37.16.37.37-.01.2-.17.37-.38.37a.386.386 0 01-.38-.38v-.01l-.01-.01c-.01-.21.16-.38.37-.38 0-.01 0-.01 0 0M10.4 8.251h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38l-.01-.01c-.01-.21.16-.38.37-.38 0-.01 0-.01 0 0M17.625 8.251h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38l-.01-.01c-.01-.21.16-.38.37-.38 0-.01 0-.01 0 0M14.15 6.751h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38l-.01-.01c-.01-.21.16-.38.37-.38 0-.01 0-.01 0 0"/><rect pid="1" width="22.5" height="4.5" x=".75" y="11.251" rx="2.25"/><path pid="2" d="M21.75 11.38V9.93c0-3.42-2.78-6.189-6.19-6.189H8.43h0A6.172 6.172 0 002.24 9.92h-.01v1.44M21.75 15.623v1.62c0 1.65-1.35 3-3 3H5.25l-.01-.001c-1.66-.01-3-1.35-3-3V15.611"/></g>'
  }
})
