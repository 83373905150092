<template>
    <app-modal
        :value="value"
        :title="title"
        :nested="nested"
        :z-index="zIndex"
        @input="$emit('input', $event)"
    >
        <div class="h-80 overflow-y-auto">
            <search-field
                v-model="searchTerm"
                class="w-full mb-2"
                placeholder="Type to search or create a new tag ..."
                @keyup-enter="addNewTag"
            ></search-field>

            <div v-if="selectedTags.length" class="flex items-center my-2">
                <div class="inline-flex items-center w-auto text-xs mr-2 font-semibold">
                    Selected Tags:
                </div>

                <tag
                    v-for="(tag, index) in selectedTags"
                    :key="tag.name"
                    :name="tag.name"
                    @click="deleteTag(index)"
                ></tag>
            </div>

            <div
                v-for="tag in filteredTags"
                :key="tag.id"
                class="hover:bg-gray-100 py-1 px-2 w-full cursor-pointer"
                @click="selectTag(tag)"
            >
                <div>{{ tag.name }}</div>
            </div>

            <div
                v-if="totalTagsNumber > loadedTags.length"
                class="py-1 px-2 text-gray-400 italic w-full"
            >
                <div>Filter further to show more tags ({{ totalTagsNumber }} total) ...</div>
            </div>

            <div
                v-if="canCreateTag"
                class="flex items-center hover:bg-gray-100 py-1 w-full cursor-pointer px-2"
                @click="addNewTag"
            >
                <app-icon
                    class="mr-2 h-5 w-5"
                    name="tags-add"
                ></app-icon>

                <div>Add new tag <span class="font-bold">{{ searchTerm }}</span></div>
            </div>
        </div>

        <template #footer="{ close }">
            <stateful-button
                class="button-primary"
                @click="saveTags"
            >
                {{ confirmButtonText }}
            </stateful-button>

            <button class="button" @click="cancel(close)">
                Cancel
            </button>
        </template>

        <template v-if="groupSize > 1" #footer-split-options>
            <label>
                <input
                    v-model="tagEntireGroup"
                    class="mr-2"
                    type="checkbox"
                >
                Tag ALL ({{ groupSize }}) guests in group
            </label>
        </template>
    </app-modal>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import differenceBy from 'lodash/differenceBy';
import debounce from 'lodash/debounce';

export default {
    name: 'SelectTagsModal',

    props: {
        value: {
            type: Boolean,
            required: true
        },

        title: {
            type: String,
            default: 'Select Tags'
        },

        nested: {
            type: Boolean,
            default: false
        },

        tags: {
            type: Array,
            required: true
        },

        confirmButtonText: {
            type: String,
            default: 'Save Tags'
        },

        groupSize: {
            type: Number,
            default: 1
        },

        zIndex: {
            type: Number,
            default: 60
        }
    },

    data () {
        return {
            searchTerm: '',
            selectedTags: cloneDeep(this.tags),
            tagEntireGroup: false
        };
    },

    computed: {
        loadedTags: sync('Tags/loadedTags'),
        canCreateTag: sync('Tags/canCreateTag'),
        totalTagsNumber: sync('Tags/totalTagsNumber'),
        event: get('Event/event'),
        loading: get('Tags/loading'),

        availableTags () {
            return differenceBy(this.loadedTags, this.selectedTags, 'name');
        },

        filteredTags () {
            return this.availableTags.filter((tag) => {
                const searchRegex = new RegExp(this.searchTerm, 'gi');

                return searchRegex.test(tag.name);
            });
        }
    },

    watch: {
        tags (newVal) {
            this.$set(this, 'selectedTags', cloneDeep(newVal));
        },

        searchTerm: debounce(function (newVal) {
            this.loadTags({ name: newVal, event: this.event.id });
        }, 500),

        value (newVal) {
            if (newVal) {
                this.reloadTags();
            }
        }
    },

    created () {
        this.loadTags({ event: this.event.id });
    },

    methods: {
        addNewTag () {
            if (!this.canCreateTag) {
                return;
            }

            const tag = { name: this.searchTerm };

            this.createTag(tag);
            this.selectTag(tag);

            this.searchTerm = '';
        },

        cancel (closeModal) {
            // Restore initial tags
            this.selectedTags = cloneDeep(this.tags);

            closeModal();
        },

        createTag (tag) {
            this.loadedTags.push(tag);
        },

        deleteTag (index) {
            this.selectedTags.splice(index, 1);
        },

        loadTags: call('Tags/loadTags'),
        reloadTags: call('Tags/reloadTags'),

        selectTag (tag) {
            if (find(this.selectedTags, { name: tag.name }) === undefined) {
                this.selectedTags.push(tag);
            }
        },

        saveTags () {
            if (this.tagEntireGroup) {
                this.$emit('tag-group', this.selectedTags);
            } else {
                this.$emit('save-tags', this.selectedTags);
            }

            this.$emit('input', false);
        }
    }
};
</script>
