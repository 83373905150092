<template>
    <div class="flex flex-col space-y-8 mt-16 md:mt-0">
        <create-event-navigation
            v-if="activeSubstep.hideNav !== true"
            :steps="steps"
            :active-step="activeStep"
            :max-navigatable-step="maxVisitedStep"
            :show-back-button="isBackButtonVisible"
            @back="navigateToPreviousStep"
            @navigate="navigateToStep"
        ></create-event-navigation>

        <create-event-header
            :step="activeStepIndex.toString()"
            :step-order="stepOrder"
            :title="activeSubstep.title"
            :subtitle="activeSubstep.subtitle"
        ></create-event-header>

        <component
            :is="activeSubstep.component"
            v-bind="activeSubstep.data"
            v-on="{
                completed: navigateToNextStep,
                ...activeSubstep.listeners
            }"
        ></component>

        <event-limit-warning-modal
            v-model="showEventLimitWarningModal"
            @create-ticketed-event="forceTicketsSetup = true; showEventLimitWarningModal = false"
            @closed="handleEventLimitWarningModalClosing"
        ></event-limit-warning-modal>
    </div>
</template>

<script>
import { get as dataGet } from 'lodash';
import { sync } from 'vuex-pathify';
import { v4 as uuidv4 } from 'uuid';
import tap from 'lodash/tap';
import axios from '@/util/axios';
import EventNameStep from '@/components/create-event/steps/EventNameStep.vue';
import EventTemplateStep from '@/components/create-event/steps/EventTemplateStep.vue';
import EventDetailsTicketsStep from '@/components/create-event/steps/details/EventDetailsTicketsStep.vue';
import EventDetailsMultipleDatesStep from '@/components/create-event/steps/details/EventDetailsMultipleDatesStep.vue';
import EventDetailsTimeStep from '@/components/create-event/steps/details/EventDetailsTimeStep.vue';
import EventDetailsLocationStep from '@/components/create-event/steps/details/EventDetailsLocationStep.vue';
import EventDetailsUrlStep from '@/components/create-event/steps/details/EventDetailsUrlStep.vue';
import EventAccountStep from '@/components/create-event/steps/EventAccountStep.vue';
import EventFinalizeStep from '@/components/create-event/steps/EventFinalizeStep.vue';
import EventTypeInferenceStep from '@/components/create-event/steps/EventTypeInferenceStep.vue';
import { insertIf, insertUnless } from '@/util/arrays';

export default {
    name: 'CreateEvent',

    props: {
        authenticated: {
            type: Boolean,
            default: false
        },
        hasReachedEventLimit: {
            type: Boolean,
            default: false
        },
        invitationToken: {
            type: String,
            default: null
        },
        manualInferenceTypes: {
            type: Array,
            default: () => { return []; }
        }
    },

    data () {
        return {
            activeStepIndex: 0,
            activeSubstepIndex: 0,
            primaryEventType: null,
            manualTypeInference: false,
            skippedToSignup: false,
            preselectedEventTemplateId: false,
            maxVisitedStepIndex: 0,
            forceTicketsSetup: false,
            showEventLimitWarningModal: false
        };
    },

    computed: {
        setupType: sync('CreateEvent/setup_type'),
        eventTemplateId: sync('CreateEvent/event_template_id'),

        stepOrder () {
            return 'b';
        },

        isInvitationLink () {
            return !!this.invitationToken;
        },

        sessionId () {
            return uuidv4();
        },

        maxVisitedStep () {
            return this.steps[this.maxVisitedStepIndex];
        },

        stepLogicDefinitions () {
            return [
                {
                    sellsTickets: 'no',
                    multipleDates: 'no',
                    setupType: 'rsvp',
                    askForTime: true,
                    askForLocation: true
                },
                {
                    sellsTickets: 'no',
                    multipleDates: 'yes',
                    setupType: 'tickets',
                    askForTime: false,
                    askForLocation: false
                },
                {
                    sellsTickets: 'no',
                    multipleDates: 'rts',
                    setupType: 'tickets',
                    askForTime: false,
                    askForLocation: false
                },
                {
                    sellsTickets: 'no',
                    multipleDates: 'hidden',
                    setupType: 'rsvp',
                    askForTime: false,
                    askForLocation: true
                },
                {
                    sellsTickets: 'yes',
                    multipleDates: 'no',
                    setupType: 'tickets',
                    askForTime: true,
                    askForLocation: true
                },
                {
                    sellsTickets: 'yes',
                    multipleDates: 'yes',
                    setupType: 'tickets',
                    askForTime: false,
                    askForLocation: false
                },
                {
                    sellsTickets: 'yes',
                    multipleDates: 'rts',
                    setupType: 'tickets',
                    askForTime: false,
                    askForLocation: false
                },
                {
                    sellsTickets: 'yes',
                    multipleDates: 'hidden',
                    setupType: 'tickets',
                    askForTime: false,
                    askForLocation: false
                },
                {
                    sellsTickets: 'hidden',
                    multipleDates: 'no',
                    setupType: 'rsvp',
                    askForTime: true,
                    askForLocation: true
                },
                {
                    sellsTickets: 'hidden',
                    multipleDates: 'yes',
                    setupType: 'tickets',
                    askForTime: true,
                    askForLocation: true
                },
                {
                    sellsTickets: 'hidden',
                    multipleDates: 'rts',
                    setupType: 'tickets',
                    askForTime: true,
                    askForLocation: true
                },
                {
                    sellsTickets: 'hidden',
                    multipleDates: 'hidden',
                    setupType: 'rsvp',
                    askForTime: true,
                    askForLocation: true
                }
            ];
        },

        activeStepLogic () {
            let sellsTickets = 'hidden';
            let multipleDates = 'hidden';

            if (this.forceTicketsSetup) {
                sellsTickets = 'yes';
            } else if (dataGet(this.primaryEventType?.cms, 'selling_tickets.enabled')) {
                sellsTickets = this.$store.get('CreateEvent/sells_tickets') ? 'yes' : 'no';
            }

            if (dataGet(this.primaryEventType?.cms, 'multiple_dates.enabled')) {
                multipleDates = this.$store.get('CreateEvent/multiple_dates');
            }

            return this.stepLogicDefinitions.find((stepLogic) => {
                return stepLogic.sellsTickets === sellsTickets && stepLogic.multipleDates === multipleDates;
            });
        },

        stepDefinitions () {
            return [
                ...insertUnless(this.isInvitationLink, [
                    {
                        id: 'event_name',
                        label: 'Event name',
                        substeps: [
                            {
                                id: 'event_name',
                                title: 'What is the name of your event?',
                                component: EventNameStep,
                                data: {
                                    canSkipToSignup: !this.authenticated
                                },
                                listeners: {
                                    completed: async () => {
                                        if (this.$store.get('CreateEvent/primary_event_type_id') === null) {
                                            this.manualTypeInference = true;
                                        } else {
                                            this.manualTypeInference = false;
                                            await this.loadPrimaryEventType();
                                        }

                                        this.navigateToNextStep();
                                    },
                                    'skip-to-signup': () => {
                                        this.skippedToSignup = true;

                                        const signupStepIndex = this.steps.findIndex(({ id }) => {
                                            return id === 'signup';
                                        });

                                        if (signupStepIndex) {
                                            this.navigateToStep(signupStepIndex);
                                        }
                                    }
                                }
                            },
                            ...insertIf(this.manualTypeInference, {
                                id: 'event_type',
                                title: `What best describes "${this.$store.get('CreateEvent/name')}"?`,
                                subtitle: 'This helps us customize your experience.',
                                component: EventTypeInferenceStep,
                                data: {
                                    manualInferenceTypes: this.manualInferenceTypes
                                },
                                listeners: {
                                    completed: async () => {
                                        await this.loadPrimaryEventType();
                                        this.navigateToNextStep();
                                    }
                                }
                            })
                        ]
                    },
                    ...insertUnless(this.preselectedEventTemplateId, {
                        id: 'template',
                        label: 'Choose a theme',
                        substeps: [
                            {
                                id: 'template',
                                title: dataGet(this.primaryEventType?.cms, 'choose_template.header'),
                                subtitle: dataGet(this.primaryEventType?.cms, 'choose_template.subheader'),
                                component: EventTemplateStep
                            }
                        ]
                    }),
                    {
                        id: 'details',
                        label: 'Event details',
                        substeps: [
                            ...insertIf(!this.forceTicketsSetup && dataGet(this.primaryEventType?.cms, 'selling_tickets.enabled'), {
                                id: 'selling_tickets',
                                title: dataGet(this.primaryEventType?.cms, 'selling_tickets.header'),
                                subtitle: dataGet(this.primaryEventType?.cms, 'selling_tickets.subheader'),
                                component: EventDetailsTicketsStep,
                                data: {
                                    noButtonText: dataGet(this.primaryEventType?.cms, 'selling_tickets.no_button_text'),
                                    yesButtonText: dataGet(this.primaryEventType?.cms, 'selling_tickets.yes_button_text')
                                }
                            }),
                            ...insertIf(dataGet(this.primaryEventType?.cms, 'multiple_dates.enabled'), {
                                id: 'multiple_locations_dates',
                                title: dataGet(this.primaryEventType?.cms, 'multiple_dates.header'),
                                subtitle: dataGet(this.primaryEventType?.cms, 'multiple_dates.subheader'),
                                component: EventDetailsMultipleDatesStep,
                                data: {
                                    singleButtonText: dataGet(this.primaryEventType?.cms, 'multiple_dates.single_button_text'),
                                    multipleButtonText: dataGet(this.primaryEventType?.cms, 'multiple_dates.multiple_button_text'),
                                    rtsButtonText: dataGet(this.primaryEventType?.cms, 'multiple_dates.rts_button_text')
                                }
                            }),
                            ...insertIf(this.activeStepLogic?.askForTime, {
                                id: 'date_time',
                                title: dataGet(this.primaryEventType?.cms, 'when.header'),
                                subtitle: dataGet(this.primaryEventType?.cms, 'when.subheader'),
                                component: EventDetailsTimeStep
                            }),
                            ...insertIf(this.activeStepLogic?.askForLocation, {
                                id: 'location',
                                title: dataGet(this.primaryEventType?.cms, 'where.header'),
                                subtitle: dataGet(this.primaryEventType?.cms, 'where.subheader'),
                                component: EventDetailsLocationStep,
                                data: {
                                    allowVirtualLocations: dataGet(this.primaryEventType?.cms, 'where.virtual_enabled')
                                }
                            }),
                            {
                                id: 'event_url',
                                title: dataGet(this.primaryEventType?.cms, 'url.title'),
                                subtitle: dataGet(this.primaryEventType?.cms, 'url.subtitle'),
                                component: EventDetailsUrlStep,
                                data: {
                                    authenticated: this.authenticated,
                                    eventUrlText: dataGet(this.primaryEventType?.cms, 'url.event_url'),
                                    hasPickedTemplate: this.eventTemplateId !== null
                                }
                            }
                        ]
                    }
                ]),
                ...insertUnless(this.authenticated, {
                    id: 'signup',
                    label: 'Account information',
                    substeps: [
                        {
                            id: 'signup',
                            component: EventAccountStep,
                            data: {
                                allowLogin: !this.isInvitationLink,
                                disableEventSerialization: this.isInvitationLink || this.skippedToSignup,
                                invitationToken: this.invitationToken,
                                urlImage1: dataGet(this.primaryEventType?.cms, 'account.image_1_url'),
                                urlImage2: dataGet(this.primaryEventType?.cms, 'account.image_2_url')
                            }
                        }
                    ]
                }),
                {
                    id: 'finalizing',
                    hideFromNav: true,
                    substeps: [
                        {
                            id: 'finalizing',
                            hideNav: true,
                            title: this.isInvitationLink || this.skippedToSignup ? 'You\'re seconds away from event planning bliss...' : 'RSVPify is creating your event...',
                            subtitle: this.isInvitationLink || this.skippedToSignup ? 'We\'re creating your RSVPify account now.' : 'You\'ll be able to customize everything in just a moment.',
                            component: EventFinalizeStep,
                            data: {
                                skipCreate: this.isInvitationLink || this.skippedToSignup
                            },
                            listeners: {
                                finalized: ({ destination }) => {
                                    window.location.href = destination;
                                }
                            }
                        }
                    ]
                }
            ];
        },

        steps () {
            const order = this.stepOrder === 'a'
                ? ['name', 'template', 'details', 'signup', 'finalizing']
                : ['name', 'details', 'template', 'signup', 'finalizing'];

            const steps = this.stepDefinitions.filter((step) => {
                return step.substeps.length > 0;
            });

            steps.sort(({ id: a }, { id: b }) => {
                return order.indexOf(a) - order.indexOf(b);
            });

            return steps;
        },

        activeStep () {
            return this.steps[this.activeStepIndex];
        },

        activeSubstep () {
            return this.activeStep?.substeps[this.activeSubstepIndex];
        },

        isBackButtonVisible () {
            return this.activeStepIndex > 0 || this.activeSubstepIndex > 0;
        }
    },

    watch: {
        activeSubstep ({ id }) {
            const queryString = tap(new URLSearchParams(window.location.search), (qs) => {
                return qs.set('step', id);
            });

            const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${queryString.toString()}`;

            window.history.pushState({ url }, '', url);
        },

        activeStepLogic (newVal) {
            this.setupType = newVal?.setupType;
        }
    },

    created () {
        this.$store.set('CreateEvent/user@authenticated', this.authenticated);

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden' && !this.isInvitationLink) {
                this.trackBehavior();
            }
        });
    },

    async mounted () {
        const getParams = new URLSearchParams(window.location.search);

        if (getParams.has('templateId')) {
            this.eventTemplateId = parseInt(getParams.get('templateId'), 10);
            this.preselectedEventTemplateId = true;
        }

        if (this.hasReachedEventLimit) {
            this.showEventLimitWarningModal = true;
        }
    },

    methods: {
        handleEventLimitWarningModalClosing () {
            if (this.forceTicketsSetup) {
                return;
            }
            window.location.href = this.route('dashboard');
        },

        trackBehavior () {
            const data = {
                uuid: this.sessionId,
                data: {
                    flow: {
                        step_order: this.stepOrder,
                        active_step_index: this.activeStepIndex,
                        active_step: this.activeStep.id,
                        active_substep_index: this.activeSubstepIndex,
                        active_substep: this.activeSubstep.id
                    },
                    store: this.$store.get('CreateEvent/*')
                }
            };

            const blob = new Blob([JSON.stringify(data)], {
                type: 'application/json'
            });

            navigator.sendBeacon(this.route('api.onboarding-logs.store'), blob);
        },

        async loadPrimaryEventType () {
            const { data } = await axios.get(
                this.route('api.event-types.show', this.$store.get('CreateEvent/primary_event_type_id'))
            );

            this.primaryEventType = data.data;
        },

        navigateToPreviousStep () {
            if (this.skippedToSignup && this.activeSubstepIndex === 0) {
                this.skippedToSignup = false;
                this.activeStepIndex = 0;
                return;
            }

            this.activeSubstepIndex -= 1;

            if (this.activeSubstepIndex < 0) {
                this.activeSubstepIndex = this.steps[this.activeStepIndex - 1].substeps.length - 1;
                this.activeStepIndex -= 1;
            }
        },

        navigateToStep (id) {
            this.activeSubstepIndex = 0;
            this.activeStepIndex = id;
        },

        navigateToNextStep () {
            this.activeSubstepIndex += 1;

            if (this.activeSubstepIndex >= this.activeStep.substeps.length) {
                this.activeSubstepIndex = 0;
                this.activeStepIndex += 1;
                this.maxVisitedStepIndex = Math.max(this.maxVisitedStepIndex, this.activeStepIndex);
            }
        }
    }
};
</script>
