/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M23.092 9.665C20.463 6.77 16.594 4.2 12.584 4.012 12.389 4 12.2 4 12 4c-4.253.034-8.124 2.47-10.942 5.5l-.15.164a3.457 3.457 0 000 4.669C2.113 15.662 6.471 20 11.872 20h.256c5.4 0 9.759-4.338 10.966-5.667a3.456 3.456 0 00-.002-4.668zm-1.478 3.322c-2.17 2.388-5.32 4.655-8.642 4.968A8.317 8.317 0 0112 18c-3.63-.085-6.84-2.11-9.286-4.658q-.167-.174-.329-.353a1.476 1.476 0 010-1.978C4.752 8.4 8.332 5.915 12 6a9.407 9.407 0 013.747.774 15.249 15.249 0 013.4 1.977 18.091 18.091 0 012.47 2.257 1.476 1.476 0 01-.003 1.979z"/><path pid="1" d="M12 8a3.9 3.9 0 00-.392.02.249.249 0 00-.183.385A1.994 1.994 0 018.5 11.049a.25.25 0 00-.4.137A3.584 3.584 0 008 12a4 4 0 104-4z"/></g>'
  }
})
