<template>
    <section class="panel flex flex-col">
        <page-header icon="lock-stroke" label="Security & Passwords">
            <a class="button-icon button-sm" @click="closePage">
                <app-icon name="close"></app-icon>
            </a>
        </page-header>

        <item-modified-warning v-if="form.isModified()"></item-modified-warning>

        <form-field-wrapper label="Password" :error="form.errors.get('securityLevel')">
            <select-list v-model="form.securityLevel" class="space-y-2">
                <select-option id="none">None</select-option>

                <select-option id="eventPassword" tooltip="Require all guests to enter the same password to be able to view and respond to your event.">
                    <div class="flex items-center space-x-4">
                        <span class="tooltip-text">Event Password</span>
                        <premium-feature feature="PasscodeProtection" :feature-usage="passcodeUsage"></premium-feature>
                    </div>
                </select-option>

                <select-option
                    v-if="isFeatureEnabled('invitee-passwords')"
                    id="inviteePassword"
                    :disabled="disableInviteePasswordsOption"
                    :tooltip="inviteePasswordOptionTooltip"
                >
                    <div class="flex items-center space-x-4">
                        <span class="tooltip-text">Invitee Passwords</span>
                        <img
                            v-if="!canEventUseInviteePasswords"
                            class="w-5 h-5"
                            :src="asset('images/account/rsvpify-premium.svg')"
                        >
                    </div>
                </select-option>
            </select-list>
        </form-field-wrapper>

        <form-field-wrapper
            v-if="isEventPasswordSecurityLevel"
            label="Event password"
            :error="form.errors.get('passcode')"
        >
            <input
                v-model="form.passcode"
                type="password"
                placeholder="Enter a password or phrase"
                class="form-field w-full md:w-80"
                @keypress.enter="updateSecuritySettings"
            >

            <template slot="help">
                <div>Passwords are case-sensitive and must be at least 4 characters. Control the message your guests see above the password entry field in <a class="ml-2px underline" :href="route('settings.event.languages-and-translations', event)">Language & Translations</a>.</div>
            </template>
        </form-field-wrapper>

        <form-field-wrapper
            v-if="isInviteePasswordSecurityLevel && isFeatureEnabled('invitee-passwords')"
            label="Invitee passwords"
            class="w-full md:w-1/2"
            :error="form.errors.get('inviteePassword.type')"
        >
            <select-list v-model="form.inviteePassword.type" class="space-y-2">
                <select-option id="randomPasswords">Generate random passwords</select-option>

                <div v-if="showRandomPasswordsOptions" class="flex flex-col space-y-2 ml-10">
                    <export-invitee-passwords></export-invitee-passwords>

                    <button class="button-text button-primary" @click="regeneratePasswords">
                        <app-icon name="refresh-arrow-stroke" class="h-6 w-6 mr-2"></app-icon>
                        Regenerate
                    </button>

                    <p v-if="passwordsUpdatedAt" class="text-sm text-gray-500 ml-8">Last Generated {{ passwordsUpdatedAt | dateTimeTz(eventTimezone.name) }}</p>
                </div>

                <select-option
                    id="customDataField"
                    :disabled="disableAssignCustomDataFieldOption"
                    :tooltip="assignCustomDataFieldOptionTooltip"
                >
                    <div class="flex items-center space-x-4">
                        <span class="tooltip-text">Assign custom data field</span>
                        <img
                            v-if="!canEventUseCustomDataFields"
                            class="w-5 h-5"
                            :src="asset('images/account/rsvpify-premium.svg')"
                        >
                    </div>
                </select-option>

                <form-field-wrapper
                    v-if="form.inviteePassword.type === 'customDataField'"
                    class="ml-8"
                    :error="form.errors.get('inviteePassword.dataFieldId')"
                >
                    <simple-picker
                        v-model="form.inviteePassword.dataFieldId"
                        :items="customDataFields"
                        item-value="id"
                        item-label="field"
                        placeholder-empty-state="Select a custom data field..."
                        placeholder-search="Search for a custom data field..."
                        filterable-property="field"
                        is-filterable
                    ></simple-picker>
                </form-field-wrapper>
            </select-list>
        </form-field-wrapper>

        <div class="mt-10 uppercase tracking-wide text-sm font-semibold text-gray-600">Invite List</div>

        <div class="mt-2 ml-2px text-gray-600 leading-normal italic">
            Control who can respond to your event and how many guests they can bring with <a class="underline text-teal" :href="route('invite-list.index', event)">Invite List</a>.<br>
            Manage Invite List settings and security in <a class="underline text-teal" :href="route('invite-list.settings', event)">Invite List Settings</a>.
        </div>

        <form-field-wrapper
            label="Registration Edits & Cancellations"
            class="mt-8"
        >
            <div class="space-y-4">
                <div>
                    <toggle-switch
                        v-model="form.allowGuestSubmissionEditing"
                    >
                        <span
                            v-tippy
                            class="tooltip-text"
                            content="Guests can edit their existing RSVP or registration until you choose to close your event."
                        >
                            Allow guests to edit their registration
                        </span>
                    </toggle-switch>
                </div>

                <div>

                    <toggle-switch
                        v-if="showSubmissionEditingMfaOption"
                        v-model="form.submissionEditingMfaEnabled"
                        :disabled="disableSubmissionEditingMfaOption"
                    >
                        <span
                            v-tippy
                            class="tooltip-text"
                            content="Prevent malicious parties from editing someone’s else’s existing registration. RSVPify will send a verification link to the email address associated with existing registrations prior to allowing edits to be made."
                        >
                            Enable multi-factor authentication
                        </span>
                    </toggle-switch>
                </div>
            </div>
        </form-field-wrapper>

        <feature-enabled feature="bot-protection">
            <form-field-wrapper
                v-if="showBotProtectionSetting"
                label="Human Check"
                class="mt-8"
                :tooltip="disabledBotProtectionSettingTooltip"
            >
                <div class="space-y-4">
                    <toggle-switch
                        v-model="form.recaptchaEnabled"
                        :disabled="!isBotProtectionSettingEnabled"
                    >
                        <span
                            v-if="isBotProtectionSettingEnabled"
                            v-tippy
                            class="tooltip-text"
                            content="Require all guests to solve a CAPTCHA to confirm they are not a robot."
                        >
                            Enable CAPTCHA
                        </span>

                        <template v-else>Enable CAPTCHA</template>
                    </toggle-switch>
                </div>
            </form-field-wrapper>
        </feature-enabled>

        <div class="flex mt-6 justify-end">
            <button
                v-if="form.isModified()"
                class="button flex-1 md:flex-none mr-4"
                @click="form.restore()"
            >
                Cancel
            </button>

            <stateful-button
                class="button-primary flex-1 md:flex-none"
                :disabled="!form.isModified()"
                :loading="form.processing"
                @click="updateSecuritySettings"
            >
                Save
            </stateful-button>
        </div>

        <app-modal v-model="showGeneratingRandomPasswordsModal" header-classes="hidden">
            <div class="text-center">
                <app-icon class="w-10 h-10" name="loader"></app-icon>
                <p class="my-4 text-2xl font-semibold">We’re generating random passwords for your invitees!</p>
                <p>For larger events, this may take a few minutes. Please don’t leave this screen.</p>
            </div>
        </app-modal>
    </section>
</template>

<script>
import { get, isEmpty } from 'lodash';
import Form from '@/validation/Form';
import InteractsWithAbly from '@/mixins/InteractsWithAbly';
import InteractsWithFeatureFlags from '@/mixins/InteractsWithFeatureFlags';
import EventTimezone from '@/mixins/EventTimezone';
import FeatureEnabled from '../core/FeatureEnabled.vue';

export default {
    name: 'SecurityAndPasswords',
    components: { FeatureEnabled },

    mixins: [InteractsWithAbly, InteractsWithFeatureFlags, EventTimezone],

    props: {
        customDataFields: {
            type: Array,
            required: true
        },

        event: {
            type: Object,
            required: true
        },

        passcodeUsage: {
            type: Object,
            default: null
        }
    },

    data () {
        return {
            form: new Form({
                securityLevel: get(this.event.settings, 'security.level', 'none'),
                passcode: get(this.event, 'passcode.value', ''),
                inviteePassword: get(this.event.settings, 'security.inviteePassword', {
                    type: 'randomPasswords',
                    dataFieldId: null
                }),
                allowGuestSubmissionEditing: get(this.event.settings, 'security.allowGuestSubmissionEditing', true),
                submissionEditingMfaEnabled: get(this.event.settings, 'security.submissionEditingMfaEnabled', false),
                recaptchaEnabled: get(this.event.settings, 'security.recaptchaEnabled', false)
            }),
            showGeneratingRandomPasswordsModal: false,
            passwordsUpdatedAt: get(this.event.settings, 'security.inviteePassword.passwordsUpdatedAt', null)
        };
    },

    computed: {
        assignCustomDataFieldOptionTooltip () {
            if (!this.canEventUseCustomDataFields) {
                return 'Your plan does not include the ability to import custom data fields. Contact your Account Manager to add this feature.';
            }

            if (isEmpty(this.customDataFields)) {
                return 'You don\'t have any custom data fields. Add a custom data field to enable this option.';
            }

            return 'Allow your invitees to access the form using the value from the custom data field.';
        },

        canEventUseInviteePasswords () {
            return this.event.plan.features.InviteePasswords.allowed;
        },

        canEventUseCustomDataFields () {
            return !this.event.plan.features.CustomDataFieldLimit.hidden;
        },

        disableAssignCustomDataFieldOption () {
            return isEmpty(this.customDataFields) || !this.canEventUseCustomDataFields;
        },

        disableInviteePasswordsOption () {
            return !this.event.hasInvitees || !this.canEventUseInviteePasswords;
        },

        disabledBotProtectionSettingTooltip () {
            if (this.isBotProtectionSettingEnabled) {
                return null;
            }

            return 'Please upgrade to enable CAPTCHA.';
        },

        disableSubmissionEditingMfaOption () {
            return this.event.plan.features.SubmissionEditMfa.type !== 'enabled';
        },

        inviteePasswordOptionTooltip () {
            if (!this.canEventUseInviteePasswords) {
                return `Your plan ${this.event.plan.name} does not include Invitee Passwords. Please upgrade to access this feature.`;
            }

            return this.event.hasInvitees
                ? 'Create a unique password for each invitee or group of invitees.'
                : 'Add an Invite List to enable Invitee Passwords.';
        },

        isBotProtectionSettingEnabled () {
            return this.form.recaptchaEnabled || this.event.plan.features.BotProtection.type === 'enabled';
        },

        isEventPasswordSecurityLevel () {
            return this.form.securityLevel === 'eventPassword';
        },

        isInviteePasswordSecurityLevel () {
            return this.form.securityLevel === 'inviteePassword';
        },

        showRandomPasswordsOptions () {
            return this.form.inviteePassword.type === 'randomPasswords' && this.event.hasAnyRandomPasswordsGenerated;
        },

        showBotProtectionSetting () {
            if (this.form.recaptchaEnabled) {
                return true;
            }

            return this.event.plan.features.BotProtection.type !== 'hidden';
        },

        showSubmissionEditingMfaOption () {
            return this.event.plan.features.SubmissionEditMfa.type !== 'hidden' && this.form.allowGuestSubmissionEditing;
        }
    },

    methods: {
        closePage () {
            const backToSettingsIndexPage = () => {
                window.location.href = this.route('settings.event', this.event);
            };

            if (!this.form.isModified()) {
                backToSettingsIndexPage();
                return;
            }

            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            App.alert().confirm(
                'Are you sure?',
                'Are you sure you want to discard all your changes and go back to settings page?',
                attributes,
                backToSettingsIndexPage
            );
        },

        regeneratePasswords () {
            const onConfirm = () => {
                this.listenForPasswordGeneratorEvents();

                this.form.post(this.route('api.events.regenerate-invitees-passwords', this.event))
                    .then()
                    .catch(() => {
                        this.showGeneratingRandomPasswordsModal = false;
                        this.$toasted.global.error('There was an error regenerating passwords.');
                    });
            };

            App.alert().confirm(
                'Are you sure?',
                'Invitees existing passwords will be deleted and new passwords will be assigned.',
                {
                    confirmButtonText: 'Regenerate',
                    cancelButtonText: 'Cancel'
                },
                onConfirm
            );
        },

        updateSecuritySettings () {
            if (!this.form.isModified()) {
                return;
            }

            const passwordsNeedToBeGenerated = this.form.securityLevel === 'inviteePassword' && this.form.inviteePassword.type === 'randomPasswords';

            if (passwordsNeedToBeGenerated) {
                this.listenForPasswordGeneratorEvents();
            }

            this.form.post(this.route('api.events.update-security-settings', this.event))
                .then(() => {
                    if (!passwordsNeedToBeGenerated) {
                        this.$toasted.global.success({
                            message: 'Security settings have been updated.',
                            onComplete: () => {
                                window.location.reload();
                            }
                        });
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        return;
                    }

                    this.$toasted.global.error('There was an error updating the security settings.');
                });
        },

        listenForPasswordGeneratorEvents () {
            this.$echo.private(`events.${this.event.id}`)
                .listen('.Domain\\InviteList\\Events\\RandomPasswordsForInviteesGenerated', () => {
                    this.showGeneratingRandomPasswordsModal = false;

                    this.$toasted.global.success({
                        message: 'Security settings have been updated.',
                        onComplete: () => {
                            window.location.reload();
                        }
                    });
                });

            this.showGeneratingRandomPasswordsModal = true;
        }
    }
};
</script>
