/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transportation-sailboat-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 23.25l-.01-.01c1.64.2 3.3-.35 4.5-1.5l-.01-.01a4.54 4.54 0 006.42.32c.11-.11.22-.22.32-.33l-.01-.01a4.54 4.54 0 006.42.32c.11-.11.22-.22.32-.33l-.01-.01a5.516 5.516 0 004.49 1.5"/><path pid="1" d="M3.56 22.88l-1.53-3.05-.01-.01a.745.745 0 01.33-1.01c.1-.06.21-.08.33-.08h18.57c.41 0 .74.33.74.75a.94.94 0 01-.08.33l-1.53 3.04"/><path pid="2" d="M12 18.75v-15 0c0-1.66-1.35-3-3-3C7.44 5.106 5.268 12.22 2.25 15H12M15 3c2.4 2.245 4.66 8.754 6.75 12h-6"/></g>'
  }
})
