/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'performance-microphone-stage-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M2.25 9.75v3M.75 11.25h3M5.25.75v3M3.75 2.25h3M10.5 5.25a3.75 3.75 0 100 7.5 3.75 3.75 0 100-7.5zM7.85 11.65l5.3-5.3"/><path pid="1" d="M14.213 9.53l4.77 4.76h0c.92.87.97 2.34.09 3.27v0h-.01c-.94.87-2.4.83-3.28-.1l-4.773-4.78M12.75 14.43v8.82M10.5 23.25H15M16.56 5.74l5.18-5 1.5 21.669M.88 22.07l5.14-6.17"/><path pid="2" d="M22.44 23.25c.51-.26.8-.54.8-.84 0-1.25-5.04-2.25-11.25-2.25-6.22 0-11.25 1-11.25 2.25 0 .29.28.57.8.83"/></g>'
  }
})
