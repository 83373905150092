import App from './rsvpify';

import './filters';
import './plugins';
import './components';
import './directives';
import './webfonts';

window.App = new App();
window.globalAxios = require('axios');
