/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star-share-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>favorite like give</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 14.25v9M.75 21.75h15v0c0-1.66-1.35-3-3-3H9v0c0-1.66-1.35-3-3-3H.75M6 18.75h3M16.932 1.23l2 3.94h3.42c.48 0 .88.39.88.88-.01.23-.1.46-.27.62l-3.12 3.06 1.74 4-.01-.01c.19.44-.01.96-.46 1.16a.88.88 0 01-.79-.05l-4.23-2.38-4.23 2.37v-.01c-.43.23-.97.08-1.21-.34a.884.884 0 01-.05-.79l1.74-4L9.21 6.61 9.2 6.6c-.35-.35-.36-.91-.02-1.25a.87.87 0 01.63-.27h3.42l2-3.95h-.01c.21-.44.74-.62 1.18-.41.17.08.31.22.4.4z"/></g>'
  }
})
