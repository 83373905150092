/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buildings-official-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>office business city</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.5 14.25h3v6h-3zM10.5 14.25h3v6h-3zM16.5 14.25h3v6h-3zM21 13.5v0c0 .41-.34.75-.75.75H3.74a.755.755 0 01-.75-.75s0 0 0 0l-.01-.01c-.01-.45.28-.85.71-.99l8.051-2.69V9.8a.69.69 0 01.47-.01l8.05 2.68-.01-.01c.42.14.71.53.71.98z"/><path pid="1" d="M15 8.25h3v3.5M6 11.75v-3.5h3M6 8.25h0a5.992 5.992 0 015.99-6.01c3.31-.01 6 2.68 6 5.99 0 0 0 0 0 0M12 .75v1.5M1.5 23.25h21M3 20.25h18"/></g>'
  }
})
