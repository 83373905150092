<template>
    <div>
        <search-field v-model="searchTerm" placeholder="Search for feature flag..."></search-field>

        <div class="row">
            <div
                v-for="featureFlag in filteredFeatureFlags"
                :key="featureFlag.id"
                class="col-12 md:col-6 mt-4"
            >
                <div class="flex w-full items-center p-4 rounded-md border shadow">
                    <p class="flex-1">{{ featureFlag.title }}</p>
                    <toggle-switch
                        :value="isFeatureEnabled(featureFlag.feature)"
                        @input="toggleFeature(featureFlag)"
                    ></toggle-switch>
                </div>
            </div>
        </div>

        <div class="flex justify-end mt-4">
            <stateful-button
                class="button-primary"
                :loading="loading"
                @click="save"
            >
                Save &amp; Close
            </stateful-button>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import axios from '@/util/axios';

export default {
    name: 'ManageUserFeatureFlagOverrides',

    props: {
        user: {
            type: Object,
            required: true
        },
        featureFlags: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            featureFlagOverrides: cloneDeep(this.user.feature_flag_overrides),
            loading: false,
            searchTerm: ''
        };
    },

    computed: {
        filteredFeatureFlags () {
            const regex = new RegExp(this.searchTerm, 'i');

            return this.featureFlags.filter(({ title, feature, enabled }) => {
                if (!title.match(regex) && !feature.match(regex)) {
                    return false;
                }

                if (this.filterToEnabledFeatureFlags && !enabled) {
                    return false;
                }

                return true;
            });
        }
    },

    methods: {
        isFeatureEnabled (feature) {
            return get(this.featureFlagOverrides, feature, false);
        },

        toggleFeature ({ feature }) {
            if (!this.featureFlagOverrides || isArray(this.featureFlagOverrides)) {
                this.$set(this, 'featureFlagOverrides', {});
            }

            this.$set(this.featureFlagOverrides, feature, !this.isFeatureEnabled(feature));
        },

        save () {
            this.loading = true;

            const route = this.route('api.admin.users.settings.update-feature-flag-overrides', this.user.id);

            axios.put(route, { features: this.featureFlagOverrides })
                .then(() => {
                    this.$emit('updated');
                })
                .catch((error) => {
                    App.alert().error('Whoops!', error.response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
