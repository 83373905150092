/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings-cog-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>option</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.546 2.43h0c.72.8 1.96.86 2.76.14.05-.05.09-.1.14-.15l.94-1.04h-.01a1.95 1.95 0 013.4 1.4l-.08 1.4h-.01c-.06 1.08.77 1.99 1.85 2.05h.19l1.4-.08h-.01a1.95 1.95 0 012.05 1.86c.02.58-.21 1.15-.65 1.54l-1.05.94v-.01a1.95 1.95 0 00-.15 2.76l.14.14 1.04.94h0c.8.72.86 1.96.14 2.76-.4.43-.97.67-1.56.64l-1.4-.08-.01-.01c-1.08-.06-2.01.77-2.06 1.85a.67.67 0 000 .2l.07 1.4h0c.04 1.08-.79 1.99-1.87 2.04a1.99 1.99 0 01-1.54-.64l-.95-1.05-.01-.01c-.73-.81-1.97-.87-2.77-.15-.05.04-.1.09-.15.14l-.95 1.04v-.01c-.73.8-1.97.85-2.77.13-.44-.4-.67-.96-.64-1.54l.07-1.4v-.01c.05-1.08-.78-2.01-1.86-2.06a.739.739 0 00-.21 0l-1.4.07v-.01c-1.08.05-2.01-.78-2.06-1.86-.04-.59.2-1.16.64-1.56l1.04-.94h-.01c.8-.73.86-1.97.14-2.77l-.15-.15-1.05-.95-.01-.01c-.81-.73-.87-1.97-.14-2.77.39-.44.95-.67 1.54-.65l1.4.07h0c1.07.05 2-.78 2.05-1.86 0-.07 0-.14-.01-.21l-.07-1.41-.01-.01c-.06-1.09.78-2 1.86-2.05.58-.03 1.14.2 1.54.63z"/><path pid="1" d="M12 7.5a4.5 4.5 0 100 9 4.5 4.5 0 100-9z"/></g>'
  }
})
