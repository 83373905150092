<template>
    <app-modal
        :value="value"
        header-classes="items-center"
        @input="$emit('input', $event)"
    >
        <template #header>
            <div class="flex items-center">
                <div class="text-lg text-teal font-bold grow">
                    Please connect a payment processor
                </div>
            </div>
        </template>

        <div class="text-gray-600">Your event has a paid item and/or a donation field in the registration form. Please connect a payment processor so you can go live!</div>

        <div class="mt-4 flex flex-col md:flex-row">
            <a
                :href="route('stripe.connect.start-authorization', event)"
                class="button button-primary w-full"
            >
                Connect Processor
            </a>

            <button
                class="mt-2 md:ml-2 md:mt-0 button button-secondary w-full"
                @click="removePaidProduct"
            >
                Edit Registration Form
            </button>
        </div>
    </app-modal>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    name: 'EventRequiresStripeAction',

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        event: get('Event/event')
    },

    methods: {
        removePaidProduct () {
            this.$emit('removePaidProduct');
            this.$emit('input', false);
        }
    }

};
</script>
