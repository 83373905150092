/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email-envelope-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>postal</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/><rect pid="1" width="13.5" height="10.5" x="5.25" y="6.75" rx="1.5"/><path pid="2" d="M5.25 8.25L12 12l6.75-3.75"/></g>'
  }
})
