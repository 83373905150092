/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'loading-half': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M20.25 3.99a3.5 3.5 0 100 7 3.5 3.5 0 100-7zM13.25.49a3 3 0 100 6 3 3 0 100-6zM6.25 2.49a3 3 0 100 6 3 3 0 100-6zM2.75 8.99a2.5 2.5 0 100 5 2.5 2.5 0 100-5zM4.25 14.99a2.5 2.5 0 100 5 2.5 2.5 0 100-5zM9.25 19.49a2 2 0 100 4 2 2 0 100-4zM14.625 20.14a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/></g>'
  }
})
