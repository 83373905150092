import { forOwn } from 'lodash';

export default {
    created () {
        App.$on('modified-block', this.onBlockModified);
    },

    beforeDestroy () {
        App.$off('modified-block', this.onBlockModified);
    },

    methods: {
        onBlockModified ({ id, ...properties }) {
            if (this.block?.pivot?.id === id) {
                forOwn(properties, (value, key) => {
                    if (this.form[key]) {
                        this.form[key] = value;
                    }
                });
            }
        }
    }
};
