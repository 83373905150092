/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-lab-research-magnifier-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.61 7.39a3.22 3.22 0 100 6.44 3.22 3.22 0 100-6.44zM10.08 4.98h1.07M10.62 4.98V7.4M14.22 6.25l.76.76M14.6 6.63l-1.71 1.71M16.25 10.08v1.07M16.25 10.62h-2.41M14.98 14.22l-.76.76M14.6 14.6l-1.71-1.71M11.15 16.25h-1.07M10.62 16.25v-2.41M7.01 14.98l-.76-.76M6.63 14.6l1.71-1.71M4.98 11.15v-1.07M4.98 10.62H7.4M6.25 7.01l.76-.76M6.63 6.63l1.71 1.71"/><path pid="1" d="M10.61.75a9.86 9.86 0 100 19.73 9.86 9.86 0 100-19.74zM23.25 23.25l-5.66-5.66"/></g>'
  }
})
