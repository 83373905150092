/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-shrink-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>collapse smaller in hide none</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25.75l-9 9M9.75 20.99v-6.75H3M14.25 2.99v6.75H21M9.75 14.25l-9 9"/></g>'
  }
})
