/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-blazer-jacket-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18 2.25l4.5 2.25-3.75 5.25V22.5c0 .41-.34.75-.75.75H15.84c-1.01 0-1.95-.51-2.5-1.34l-1.61-2.41-.01-.01c-.33-.5-.51-1.08-.5-1.67v-5.85"/><path pid="1" d="M22.5 4.5l.71 14.96h0c.01.41-.3.76-.72.78H18.73M15 .75l3 1.5c-1.33 6.15-3.83 8.621-6.75 9.75C11.25 12 15 4.5 15 .75zM1.5 4.5L.78 19.46H.77c-.02.41.29.76.71.78H5.22"/><path pid="2" d="M6 2.25L1.5 4.5l3.75 5.25V22.5h0c0 .41.33.74.75.74h2.14l-.01-.001c1 0 1.94-.51 2.5-1.34l1.35-2.04"/><path pid="3" d="M12 10.411C10.9 7.991 9 3.42 9 .75l-3 1.5c1.13 5.27 3.135 7.84 5.52 9.179M9 .75h6M14.25 15.37h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38"/></g>'
  }
})
