/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-confetti-whistle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M3.58 23.25l9.423-9.424h-.01c.58-.59 1.53-.59 2.122-.01.58.58.58 1.53 0 2.122l-7.3 7.3M15.129 13.826l5.3-5.3v-.01a4.52 4.52 0 000-6.37l-.01-.01-.01-.01c-.92-.84-2.34-.78-3.18.13-.79.86-.79 2.18 0 3.04l-.01-.01c.58.58 1.53.58 2.12 0M4.5 4.5h3M6 3v3M18.75 16.5h3M20.25 15v3M10.125 9h-.01c.2-.01.37.16.37.37M9.75 9.37h0c-.01-.21.16-.38.37-.38 0 0 0 0 0 0M10.125 9.75h-.01a.386.386 0 01-.38-.38s0 0 0 0M10.5 9.37v0c0 .2-.17.375-.375.375M12.37.75h-.01c.2-.01.37.16.37.37"/><path pid="1" d="M12 1.125h0c-.01-.21.16-.38.37-.38 0 0 0 0 0 0M12.37 1.5l-.01-.001a.386.386 0 01-.38-.38v-.001M12.75 1.125v0c0 .2-.17.375-.375.375M1.125 8.25h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M.75 8.625h0c-.01-.21.16-.38.37-.38M1.125 9h-.01a.386.386 0 01-.38-.38s0 0 0 0M1.5 8.625v0c0 .2-.17.375-.375.375M22.875 10.5h-.01c.2-.01.37.16.37.37M22.5 10.87h0c-.01-.21.16-.38.37-.38M22.875 11.25h-.01a.386.386 0 01-.38-.38s0 0 0 0M23.25 10.87v0c0 .2-.17.375-.375.375"/></g>'
  }
})
