/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-truck-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 16.5a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM18.75 16.5a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5z"/><path pid="1" d="M23.25 10.5V18c0 .82-.68 1.5-1.5 1.5h-.88M16.63 19.5H7.37M3.128 19.5h-.89a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-5.25L3.3 8.27h-.01c.26-.48.76-.78 1.31-.78h1.36M8.25 11.25v8.25M.75 12.75h7.5M14.25 6.75v0c0 .82-.68 1.5-1.5 1.5-.83 0-1.5-.68-1.5-1.5M17.25 6.75v0c0 .82-.68 1.5-1.5 1.5-.83 0-1.5-.68-1.5-1.5M20.25 6.75v0c0 .82-.68 1.5-1.5 1.5-.83 0-1.5-.68-1.5-1.5"/><path pid="2" d="M20.25 6.75h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51l-1.5-3.75h-12l-1.5 3.75h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51M20.25 10.5v3h-9v-3"/></g>'
  }
})
