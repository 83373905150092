/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'upload-cloud-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 23.25v-9M8.25 18L12 14.25 15.75 18"/><path pid="1" d="M17.25 15.75h1.125c2.69 0 4.87-2.18 4.88-4.87 0-2.7-2.18-4.88-4.87-4.89-1-.01-1.97.3-2.79.86h0C14.825 2.77 10.9.09 6.83.86A7.484 7.484 0 00.84 9.61c.56 3 2.88 5.35 5.88 5.96"/></g>'
  }
})
