/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'transportation-bus-school-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 17.21a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM17.25 17.21a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/><path pid="1" d="M3.75 18.713H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-3h0c-.01-.83.67-1.51 1.49-1.51h1.5l-.01-.001c.82 0 1.5-.68 1.5-1.5V6.7h0c-.01-.83.67-1.51 1.49-1.51h15-.01c.82-.01 1.5.67 1.5 1.5v10.5c0 .82-.68 1.5-1.5 1.5h-3M6.75 18.71h9M5.25 11.21h18M11.25 11.21v-6M17.25 11.21v-6M23.25 14.21h-15"/></g>'
  }
})
