/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-concert-music-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.5 12h0a3.739 3.739 0 013.74-3.76V4.49l-.001-.001c0-.83-.68-1.5-1.5-1.5H2.22c-.83 0-1.5.67-1.5 1.5v3.75H.71a3.736 3.736 0 013.75 3.74c0 2.07-1.68 3.75-3.75 3.75v3.75-.01c-.01.82.67 1.5 1.49 1.5h19.5-.01c.82 0 1.5-.68 1.5-1.5v-3.75h0a3.751 3.751 0 01-3.75-3.75z"/><path pid="1" d="M8.25 15a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM14.25 13.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/><path pid="2" d="M15.75 15v-4.08h0c0-.83-.68-1.5-1.5-1.5-.13 0-.25.01-.37.04l-3 .75h-.01c-.67.16-1.14.76-1.14 1.45v4.82M4.875 6h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38v-.001M9.37 6h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38v-.001M14.625 6h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38v-.001M19.125 6h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38v-.001"/></g>'
  }
})
