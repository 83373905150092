/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'remove-square-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>delete subtract minus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.5 12h9"/><rect pid="1" width="22.5" height="22.5" x=".75" y=".75" rx="3"/></g>'
  }
})
