<template>
    <line-chart
        :data="rawChartData"
        :tooltip-label-generator="tooltipLabelGenerator"
        gradient
    ></line-chart>
</template>

<script>
import pick from 'lodash/pick';

export default {
    name: 'TotalSalesChart',

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
        rawChartData () {
            return pick(this.data, ['labels', 'datasets']);
        },

        formattedAmounts () {
            return this.data.formatted;
        }
    },

    methods: {
        tooltipLabelGenerator (tooltipItem) {
            return this.formattedAmounts[tooltipItem.dataIndex];
        }
    }
};
</script>
