/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help-chat-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>question support bubble message</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.75.75h0C6.95.74 2.25 5.42 2.24 11.21c-.01 1.96.54 3.88 1.58 5.55L.73 23.23l6.47-3.09h0c4.92 3.06 11.39 1.55 14.46-3.38 3.06-4.93 1.55-11.4-3.38-14.47C16.6 1.25 14.68.7 12.71.7zM12.75 15h0"/><path pid="1" d="M12.75 15h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.38-.38-.375M9.75 9.75h0a2.996 2.996 0 115.99-.02c0 1.65-1.35 3-3 3"/></g>'
  }
})
