/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outdoors-water-sun-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.251 17h-.01c-2.37.95-5.09.3-6.75-1.64v-.01c-2.14 2.48-5.88 2.77-8.36.64-.23-.2-.45-.42-.65-.65v-.01a5.974 5.974 0 01-6.76 1.62M.75 21.31h0c2.36.96 5.08.3 6.75-1.64l-.01-.01a5.923 5.923 0 008.35.64c.22-.2.44-.42.64-.65h0a5.96 5.96 0 006.75 1.63M6 12.75h0a5.992 5.992 0 015.99-6.01c3.31-.01 6 2.68 6 5.99 0 0 0 0 0 0M3 12.75H.75M23.25 12.75H21M12 2.25V4.5M18 6.75l1.5-1.5M6 6.75l-1.5-1.5"/></g>'
  }
})
