<template>
    <app-modal
        v-slot="{ close }"
        :value="value"
        header-classes="hidden"
        :z-index="1000"
        @closed="$emit('closed', $event)"
    >
        <div class="text-center">
            <template v-if="state === STATE_EXPORTING">
                <app-icon class="w-10 h-10" name="loader"></app-icon>
                <p class="my-4 text-2xl font-semibold">We’re preparing your export</p>
                <p>For larger events, this may take a few minutes. Please don’t leave this screen.</p>
                <div class="text-center text-sm mt-4">
                    <a
                        role="button"
                        class="text-teal"
                        @click="showNothingHappeningModal"
                    >Nothing happening?</a>
                </div>
            </template>

            <template v-if="state === STATE_EXPORT_READY">
                <app-icon class="w-10 h-10 text-green" name="check-circle-alt-stroke"></app-icon>
                <p class="my-4 text-2xl font-semibold">Your export is complete!</p>
                <p class="mb-4">
                    Your download should begin automatically. If it doesn’t, <a class="text-teal hover:underline" :href="downloadUrl">click here</a>.
                </p>
                <button class="button button-primary text-uppercase tracking-wide" @click="close">CLOSE</button>
            </template>

            <template v-if="state === STATE_FAILED">
                <app-icon class="w-10 h-10 text-red" name="close-circle-stroke"></app-icon>
                <p class="my-4 text-2xl font-semibold">Something went wrong :(</p>
                <p class="mb-4">
                    Not to worry though as our <a
                        :href="zendeskTicketUrl"
                        target="_blank"
                        class="button-text button-primary"
                    >passionate Support Team</a> would be happy to look into this for you!
                </p>
                <button class="button button-primary text-uppercase tracking-wide" @click="close">CLOSE</button>
            </template>
        </div>
    </app-modal>
</template>

<script>
const STATE_EXPORTING = 'exporting';
const STATE_EXPORT_READY = 'export-ready';
const STATE_FAILED = 'failed';

export default {
    props: {
        downloadUrl: {
            type: String,
            default: null
        },

        state: {
            type: String,
            default: null
        },

        value: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            STATE_EXPORTING,
            STATE_EXPORT_READY,
            STATE_FAILED
        };
    },

    computed: {
        zendeskTicketUrl () {
            return window.urls.ticket;
        }
    },

    methods: {
        showNothingHappeningModal () {
            App.alert().warning(
                '',
                '',
                {
                    html: `Please confirm that you don’t have any ad blockers browser plugins that may be blocking file downloads. Still having trouble? Try another browser or <a role="button" onclick="window.Intercom('showNewMessage', null)" class="text-teal">contact our passionate support team</a>!`
                }
            );
        }
    }
};
</script>
