/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dessert-ice-cream-cone-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.5 11.25v-1.5h0c-.01-.83.67-1.51 1.49-1.51h0-.01c.82-.01 1.5.67 1.5 1.5v1.5M13.5 11.25v0c0 .82-.68 1.5-1.5 1.5v0l-.01-.001a1.51 1.51 0 01-1.5-1.5v-.001 0M13.5 11.25v-1.5h0c-.01-.83.67-1.51 1.49-1.51v0h-.01c.82-.01 1.5.67 1.5 1.5 0 0 0 0 0 0v1.5"/><path pid="1" d="M7.5 11.25v0c0 .82-.68 1.5-1.5 1.5v0l-.01-.001a1.51 1.51 0 01-1.5-1.5V8.248h-.01A7.53 7.53 0 0111.97.73v0l-.01-.01c4.13.01 7.48 3.36 7.5 7.5v3c0 .82-.68 1.5-1.5 1.5v0l-.01-.001a1.51 1.51 0 01-1.5-1.5v-.001 0"/><path pid="2" d="M16.92 12.3l-3.55 10.032v-.01c-.33.76-1.21 1.12-1.97.79-.37-.16-.65-.44-.8-.8L7.05 12.27"/></g>'
  }
})
