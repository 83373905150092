/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.25 16.5h-.76a1.51 1.51 0 01-1.5-1.5v-3.751 0c0-.42-.34-.75-.75-.75h-.75M11.625 6.75h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v-.001 0c0-.21-.17-.375-.375-.375v0"/><path pid="1" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/></g>'
  }
})
