export default {
    computed: {
        eventStatus () {
            if (this.event.isCancelled) {
                return {
                    label: 'Canceled',
                    circleStyle: 'border-red bg-red'
                };
            }

            if (!this.event.isPublished) {
                return {
                    label: 'Unpublished',
                    circleStyle: 'border-yellow'
                };
            }

            if (this.event.isCurrentlyLive) {
                const label = this.event.setup_type === 'rsvp' ? 'RSVP open' : 'Registration open';
                return {
                    label,
                    circleStyle: 'border-green bg-green'
                };
            }

            if (this.event.isScheduledToOpen) {
                return {
                    label: 'Scheduled',
                    circleStyle: 'border-green'
                };
            }

            if (this.event.isClosed) {
                return {
                    label: 'Closed',
                    circleStyle: 'border-red'
                };
            }

            return null;
        }
    }
};
