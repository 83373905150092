<template>
    <div>
        <portal to="rsvp-form-target">
            <existing-submission-screen
                :existing-submission="existingSubmission"
                @close="existingSubmission = null"
                @submit-block="continueWithNewSubmission"
            ></existing-submission-screen>
        </portal>

        <div
            v-theme="['form.text', 'form.background']"
            class="px-4 py-8 w-full rounded-md relative transition-all"
        >
            <slot v-if="!isEventWithInvitees" name="block-header"></slot>

            <div
                v-theme="['form.title-text']"
                class="text-center text-2xl my-8 border-b pb-4 font-semibold"
            >
                {{ block.pivot.settings.title }}
            </div>

            <div v-if="!declined" class="flex items-start justify-center w-full space-x-6">
                <div v-if="displayCalendarPicker" class="flex items-center justify-center">
                    <timeslots-calendar v-model="selectedDate" themed></timeslots-calendar>
                </div>

                <transition name="fade">
                    <div v-if="displayDaySlotPicker" class="flex flex-col items-center justify-center flex-1">
                        <recurring-time-slots-day-slot-picker :selected-date="selectedDate" @select-another-date="pickAnotherDate"></recurring-time-slots-day-slot-picker>
                    </div>
                </transition>
            </div>

            <div v-if="displayDeclineOption" class="my-6">
                <div class="flex items-start">
                    <div v-theme="['form.accent']" class="flex">
                        <app-icon
                            v-if="declineable.settings.icon"
                            :name="declineable.settings.icon"
                            class="shrink-0 opacity-50 h-6 w-6 mr-4"
                        ></app-icon>
                        <div v-else class="h-6 w-6 mr-4"></div>
                    </div>

                    <div v-theme="['form.text']" class="flex-1">
                        <div class="mb-1">
                            <div class="font-semibold">
                                {{ declineable.title }}
                            </div>
                        </div>

                        <div class="opacity-75 text-sm">
                            {{ declineable.settings.description }}
                        </div>
                    </div>

                    <div>
                        <div
                            role="button"
                            class="flex items-start leading-normal"
                            @click="declined = !declined"
                        >
                            <div
                                v-theme="['form.accent']"
                                class="rounded-md flex shrink-0 justify-center items-center h-8 w-8 mr-4 border-2"
                            >
                                <div
                                    v-if="declined"
                                    class="rounded-sm h-5 w-5 option-selected"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="declined" class="mt-4">
                    <guest-details
                        v-model="decliningGuest"
                        :error="errorBag.only('payload.decliningGuest').all()[0]"
                        :require-name="declineable.settings.requireName.enabled"
                        :require-title="block.pivot.settings.guestTitles.enabled"
                        :require-email="true"
                    ></guest-details>
                </div>
            </div>

            <slot
                v-if="declined"
                name="block-footer"
                :complete-block="completeBlock"
            ></slot>
        </div>
    </div>
</template>

<script>
import { isEmpty, pick, cloneDeep } from 'lodash';
import { get } from 'vuex-pathify';
import ListensToWindowResizeEvent from '@/mixins/ListensToWindowResizeEvent';
import OpenTicketingBlock from '../ticketing/OpenTicketingBlock';

export default {
    name: 'FormRecurringTimeSlotsBlock',

    extends: OpenTicketingBlock,

    mixins: [ListensToWindowResizeEvent],

    data () {
        return {
            selectedDate: null
        };
    },

    computed: {
        inviteeLookUpDetails: get('Submission/inviteeLookUpDetails'),

        displayCalendarPicker () {
            if (this.screenWidthIs('gt', 'sm')) {
                return true;
            }

            return !this.selectedDate;
        },

        displayDaySlotPicker () {
            return !!this.selectedDate;
        },

        displayDeclineOption () {
            return this.shouldShowDecline && !this.selectedDate;
        }
    },

    watch: {
        declined (newVal) {
            if (newVal) {
                this.clearChosenProducts();

                if (!isEmpty(this.submission.guests)) {
                    this.decliningGuest = pick(
                        this.submission.guests[0],
                        ['title_id', 'first_name', 'last_name', 'email']
                    );

                    return;
                }

                if (this.submission.lookedUpGuest) {
                    this.decliningGuest = pick(
                        this.submission.lookedUpGuest,
                        ['title_id', 'first_name', 'last_name', 'email']
                    );

                    return;
                }

                if (!isEmpty(this.inviteeLookUpDetails)) {
                    this.decliningGuest = pick(
                        this.inviteeLookUpDetails,
                        ['title_id', 'first_name', 'last_name', 'email']
                    );
                }
            }
        },

        decliningGuest: {
            deep: true,
            handler (newVal) {
                this.primaryGuest = cloneDeep(newVal);
            }
        }
    },

    methods: {
        pickAnotherDate () {
            this.selectedDate = null;
        }
    }
};
</script>
