<script>
import axios from '@/util/axios';
import ExportButton from '@/components/core/ExportButton.vue';

const STATE_EXPORT_READY = 'export-ready';
const STATE_FAILED = 'failed';

export default {
    name: 'ExportSeatingChartButton',

    extends: ExportButton,

    props: {
        seatingChart: {
            type: Object,
            required: true
        }
    },

    methods: {
        initiateExport () {
            axios.get(this.route('events.seating-charts.export', [this.event, this.seatingChart]));
        },

        listenForEvents () {
            this.$echo.private(`events.${this.event.id}.seating-charts.${this.seatingChart.id}`)
                .listen('.Domain\\Seating\\Events\\SeatingChartExportFailed', () => {
                    this.state = STATE_FAILED;
                })
                .listen('.Domain\\Seating\\Events\\SeatingChartExported', ({ downloadUrl }) => {
                    this.downloadUrl = downloadUrl;
                    this.state = STATE_EXPORT_READY;
                    window.location = downloadUrl;
                });
        }
    }
};
</script>
