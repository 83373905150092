/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-accessory-hat-cap-3-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>baseball hat</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.535 12.76L3.35 17.64v-.01c-.79.26-1.64-.15-1.91-.93l-.25-.71h0C-.3 11.68 1.99 6.98 6.3 5.5c4.3-1.49 9 .8 10.48 5.11z"/><path pid="1" d="M17.535 12.76l4.759 1.73-.01-.01c.77.3 1.15 1.17.85 1.94-.12.29-.33.54-.6.72h-.01a11.827 11.827 0 01-11.96.25l-2.45-1.42M6.31 5.529s4.21 2.293 5.43 9.23"/></g>'
  }
})
