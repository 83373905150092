<template>
    <simple-picker
        v-model="dataValue"
        :items="titles"
        item-label="value"
        item-value="id"
        :placeholder-empty-state="placeholder"
        class="w-full"
        :form-field-class="formFieldClass"
        :clearable="clearable"
        @input="$emit('input', $event)"
    ></simple-picker>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    name: 'TitlePicker',

    props: {
        clearable: {
            type: Boolean,
            default: false
        },

        placeholder: {
            type: String,
            default: 'Title'
        },

        value: {
            type: Number,
            default: null
        },

        formFieldClass: {
            type: String,
            default: 'form-field'
        }
    },

    data () {
        return {
            dataValue: this.value
        };
    },

    watch: {
        value (newValue) {
            this.dataValue = newValue;
        }
    },

    computed: {
        titles: get('Event/event@titles')
    }
};
</script>
