export default {
    data () {
        return {
            shouldShowErrorMessages: false
        };
    },

    computed: {
        validationRules () {
            return {
                price: 'required|min_value:1|decimal:2',
                integer: 'required|min_value:1|decimal:0'
            };
        }
    },

    methods: {
        showError (error) {
            return error && this.shouldShowErrorMessages;
        }
    }
};
