<template>
    <div>
        <slot name="trigger" :show-modal="openModal">
            <button
                class="w-full no-underline flex flex-col md:flex-row items-center p-6 md:py-4 bg-white shadow ring-2 ring-transparent hover:ring-purple-light hover:shadow-lg cursor-pointer rounded-md text-gray-800 transition ease-in-out duration-150"
                @click="openModal"
            >
                <app-icon
                    name="technology-qr-code-scan"
                    class="h-12 w-12 md:h-8 md:w-8 text-gray-600"
                    stroke
                ></app-icon>

                <div class="mt-2 md:mt-0 md:ml-6 flex-auto leading-normal text-center md:text-left">
                    <p class="text-lg font-semibold">
                        RSVP By QR Code
                        <premium-feature
                            class="inline-block"
                            feature="QrCode"
                            tooltip="Upgrade to access this feature."
                            :requires-upgrade="!event.plan.features.QrCode.allowed"
                        ></premium-feature>
                    </p>
                    <p class="mt-4 md:m-0 md:text-sm text-gray-600">Allow invitees to register for your event by scanning a QR code. Add your QR to paper invitations or advertisements.</p>
                </div>

                <div class="mt-4 md:mt-0 md:mr-4">
                    Create QR Code
                </div>

                <app-icon
                    name="arrow-right-chevron"
                    class="h-6 w-6 text-gray-500 hidden md:flex"
                    stroke
                ></app-icon>
            </button>
        </slot>

        <a
            ref="download"
            class="hidden"
            :href="`data:image/png;base64,${src}`"
            :download="`${event.name} QrCode.png`"
        ></a>

        <app-modal
            v-model="open"
            title="Create QR Code"
        >
            <div class="flex flex-col md:flex-row space-y-4 min-h-sm">
                <div class="w-full md:w-1/2">
                    <form-field-wrapper>
                        <div class="flex items-center">
                            <color-picker
                                :value="qrCode.foregroundColor"
                                :has-alpha="false"
                                @input="newValue => debouncedUpdate('foregroundColor', newValue)"
                            ></color-picker>
                            <div class="ml-2 text-gray-500 text-sm uppercase">Foreground Color</div>
                        </div>
                    </form-field-wrapper>

                    <form-field-wrapper>
                        <div class="flex items-center">
                            <color-picker
                                :value="qrCode.backgroundColor"
                                :has-alpha="false"
                                @input="newValue => debouncedUpdate('backgroundColor', newValue)"
                            ></color-picker>
                            <div class="ml-2 text-gray-500 text-sm uppercase">Background Color</div>
                        </div>
                    </form-field-wrapper>

                    <form-field-wrapper label="Style">
                        <simple-picker
                            v-model="qrCode.style"
                            item-label="label"
                            item-value="id"
                            :items="styles"
                            placeholder-empty-state="Select style..."
                        ></simple-picker>
                    </form-field-wrapper>
                </div>

                <div class="flex items-center justify-center order-first md:order-last w-full md:w-1/2">
                    <app-icon
                        v-if="loading"
                        name="loader"
                        class="w-10 h-10 text-purple"
                    ></app-icon>

                    <img
                        v-show="!loading"
                        ref="qrCode"
                        class="mx-auto"
                        :src="`data:image/png;base64,${src}`"
                        @load="loading = false"
                    >
                </div>
            </div>

            <template #footer="{ close }">
                <stateful-button
                    id="button-qr-code-download"
                    class="button button-primary"
                    :loading="loading"
                    @click="$refs.download.click()"
                >
                    Download
                </stateful-button>

                <button class="button mr-2" @click="close">Close</button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { get } from 'vuex-pathify';
import axios from '@/util/axios';

export default {
    data () {
        return {
            open: false,
            loading: true,
            src: null,
            qrCode: {
                foregroundColor: null,
                backgroundColor: null,
                style: 'square'
            },
            styles: [
                { id: 'round', label: 'Round' },
                { id: 'square', label: 'Square' }
            ]
        };
    },

    computed: {
        ...get('Event/*')
    },

    watch: {
        qrCode: {
            deep: true,
            handler () {
                this.loading = true;
                this.getQrCodeImageUrl();
            }
        }
    },

    methods: {
        debouncedUpdate: debounce(function (property, value) {
            this.qrCode[property] = value;
        }, 500),

        getQrCodeImageUrl () {
            axios.get(this.route('api.events.qr-code', {
                event: this.event.id,
                foreground_color: this.qrCode.foregroundColor,
                background_color: this.qrCode.backgroundColor,
                style: this.qrCode.style
            })).then(({ data }) => {
                this.src = data.qrCode;
            }).catch(() => {
                this.$toasted.global.error('There was an error generating the QR Code.');
            });
        },

        openModal () {
            if (!this.event.plan.features.QrCode.allowed) {
                App.alert().paymentRequired(
                    'Please upgrade.',
                    `Your ${window.user.plan.name} plan doesn’t include QR code registration. Please upgrade to access this feature.`
                );
                return;
            }

            this.reset();
            this.open = true;
        },

        reset () {
            this.qrCode.foregroundColor = '#000000';
            this.qrCode.backgroundColor = '#FFFFFF';
            this.style = 'square';
        }
    }
};
</script>
