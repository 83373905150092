/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'animal-bird-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sparrow cardinal raven</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.97 7.449h0c-.73-.69-1.78-.89-2.7-.52v-.01c-.92.35-1.52 1.22-1.53 2.19v2.59l-.01-.01a4.851 4.851 0 004.94 4.77h6.42l-.01-.01a.6.6 0 00.43-1.03zM4.5 9.75h2.25"/><path pid="1" d="M9.37 9.75h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M9 10.125h0c-.01-.21.16-.38.37-.38M9.37 10.5h-.01a.386.386 0 01-.38-.38v-.001M9.75 10.125v0c0 .2-.17.375-.375.375"/><path pid="3" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5zM12.75 16.5v2.25"/></g>'
  }
})
