/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sports-hiking-person-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M22.25 9L18.5 23.25M2.887 13.158l-.01-.01c-.62-.14-1-.75-.86-1.36v-.03l1.2-4.79h-.01c.14-.6.74-.97 1.34-.84l1.14.25h0c.61.13.99.74.85 1.35-.01.01-.01.02-.01.03l-1.2 4.78v-.01c-.15.59-.75.96-1.35.83zM2.75 9l3.35.75M17 10.5l-.01-.01c-1.87-.1-3.61-.97-4.8-2.4l-.01-.01a1.509 1.509 0 00-2.67.54l-3 12.75H6.5c-.19.8.31 1.6 1.11 1.8l-.01-.01c.11.02.22.04.34.04h-.01c.69-.01 1.3-.48 1.46-1.16l1.14-4.83.06.08h0c.33.41.58.88.75 1.38l1.16 3.48-.01-.01c.26.78 1.11 1.21 1.89.94.78-.27 1.21-1.12.94-1.9l-.01-.01-1.17-3.49-.01-.01c-.29-.84-.72-1.63-1.27-2.32l-1.54-1.92.4-1.74h0a8.943 8.943 0 005.12 1.67l-.01-.001c.82 0 1.5-.68 1.5-1.5 0-.83-.68-1.5-1.5-1.5zM12.5.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5z"/></g>'
  }
})
