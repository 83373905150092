/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-accessory-beanie-winter-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>hat cap</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="19.5" height="6" x="2.25" y="17.25" rx="1.5"/><path pid="1" d="M20.25 17.25H3.75V13.5h0c-.01-4.56 3.69-8.26 8.24-8.26 4.55-.01 8.25 3.69 8.25 8.24 0 0 0 0 0 0z"/><path pid="2" d="M12 .75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM6.75 23.25v-6s0-12 5.25-12M17.25 23.25v-6s0-12-5.25-12M12 5.25v18"/></g>'
  }
})
