import { call } from 'vuex-pathify';

export default {
    data () {
        return {
            draggedData: {}
        };
    },

    methods: {
        endDraggable (event) {
            if (!this.draggedData.to) {
                this.resetTargets();
                return;
            }

            if (this.draggedData.from.seatingArea && this.draggedData.to.$options.name === 'SeatableDataTable') {
                this.unseat({
                    seatingArea: this.draggedData.from.seatingArea,
                    seatable: this.draggedData.seatable
                });
            }

            if (this.draggedData.to.$options.name === 'SeatingArea') {
                this.draggedData.to.seat({
                    seatable: this.draggedData.seatable,
                    position: parseInt(event.to.dataset.position, 10),
                    seatingArea: this.draggedData.to.seatingArea
                });
            }

            this.resetTargets();
        },

        moveSeatable (event) {
            const toElement = event.to;

            /**
             * Sets the "unseatingViaDragAndDrop" text when the user hovers over the SeatableDataTable.
             */
            if (event.relatedContext.component.$parent.$options.name === 'SeatableDataTable') {
                this.toggleUnseatingViaDragAndDrop(true);
            }

            if (this.draggedData.to && this.draggedData.to.$options.name === 'SeatableDataTable') {
                this.toggleUnseatingViaDragAndDrop(false);
            }

            /**
             * Saves the information about the draggable object that is being
             * moved around the screen.  This is used within the "endDraggable" to correctly
             * assign and unassign seatables from their seats.
             *
             * from: The component in which the draggable event started.
             * to: The component that the current draggable is hovering over.
             * seatable: The seatable being moved around.
             */
            this.$set(this, 'draggedData', {
                from: event.from.__vue__.$parent,
                to: event.relatedContext.component.$parent,
                seatable: event.draggedContext.element
            });

            /**
             * Only droppable divs can accept a draggable.  If the parent div is not droppable
             * do not allow the draggable to be dropped.
             */
            if (!toElement.classList.contains('droppable')) {
                return false;
            }

            /**
             * This forces the draggable to be the FIRST child which allows for
             * the display:none on the following child. (Position. Type...) element.
             */
            return -1;
        },

        toggleUnseatingViaDragAndDrop: call('Seating/toggleUnseatingViaDragAndDrop'),

        resetTargets () {
            this.$set(this, 'draggedData', {});

            this.toggleUnseatingViaDragAndDrop(false);
        }
    }
};
