/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'barbecue-grill-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>cookout bbq</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.625 9h-.01c.62-.01 1.13.49 1.14 1.12 0 .07-.01.15-.03.22v-.01c-.82 3.72-4.51 6.07-8.23 5.25a6.906 6.906 0 01-5.26-5.26l-.01-.01c-.12-.62.28-1.21.89-1.33.07-.02.15-.03.23-.03zM19.5 23.25l-3.27-8.92M4.5 23.25l3.27-8.92M18.4 20.25H5.6M6.92.75l-.62.823h-.01c-.52.67-.41 1.63.24 2.17v0h0c.64.54.75 1.5.24 2.17l-.17.21M12.55.75l-.62.823v-.01c-.52.67-.41 1.63.24 2.17v0h0c.65.54.75 1.5.24 2.17l-.17.21M17.806.75l-.62.823v-.01c-.52.67-.41 1.63.24 2.17v0h0c.65.54.75 1.5.24 2.17l-.17.21"/></g>'
  }
})
