/* eslint-disable no-param-reassign */
import { make } from 'vuex-pathify';

const emptyLocation = {
    type: null,
    address: null,
    venue_name: null,
    virtual_url: null
};

const moduleState = {
    name: '',
    primary_event_type_id: null,
    secondary_event_type_ids: [],
    canContinue: false,
    event_template_id: null,
    sells_tickets: null,
    multiple_dates: null,
    starts_at: null,
    ends_at: null,
    timezone_id: null,
    browser_timezone_id: null,
    location: { ...emptyLocation },
    subdomain: '',
    setup_type: null,
    user: {
        authenticated: false,
        name: null,
        email: null
    }
};

export default {
    namespaced: true,
    state: moduleState,
    mutations: {
        ...make.mutations(moduleState),

        resetTime (state) {
            state.starts_at = null;
            state.ends_at = null;
            state.timezone_id = null;
            state.browser_timezone_id = null;
        },

        resetLocation (state) {
            state.location = { ...emptyLocation };
        }
    }
};
