<template>
    <section class="panel flex flex-col">
        <page-header icon="calendar-add-circle-stroke" label="Calendar Invites Global Settings"></page-header>

        <item-modified-warning v-if="form.isModified()"></item-modified-warning>

        <div class="w-full md:w-1/2">
            <form-field-wrapper
                label="Description"
                :error="form.errors.get('description')"
                tooltip="Add event details to the calendar invitation that guests will receive when they register for your event."
            >
                <textarea v-model="form.description" class="form-field"></textarea>
            </form-field-wrapper>

            <form-field-wrapper
                label="Event Organizer"
                :error="form.errors.get('eventOrganizer')"
                tooltip="The name of the person or company organizing the event. Displayed in some calendaring applications."
            >
                <input v-model="form.eventOrganizer" class="form-field">
            </form-field-wrapper>

            <form-field-wrapper
                label="Organizer’s Email Address"
                :error="form.errors.get('organizerEmail')"
                tooltip="The email address guests will see when adding your event to their calendar. Displayed in some calendaring applications."
            >
                <input v-model="form.organizerEmail" class="form-field">
            </form-field-wrapper>
        </div>

        <div class="flex mt-6 justify-end">
            <button
                v-if="form.isModified()"
                class="button flex-1 md:flex-none mr-4"
                @click="form.restore()"
            >
                Cancel
            </button>

            <stateful-button
                class="button-primary flex-1 md:flex-none"
                :disabled="!form.isModified()"
                :loading="form.processing"
                @click="updateSettings"
            >
                Save
            </stateful-button>
        </div>
    </section>
</template>

<script>
import { get } from 'lodash';
import { sync } from 'vuex-pathify';
import Form from '@/validation/Form';

export default {
    name: 'GlobalCalendarInvitesSettings',

    data () {
        return {
            form: {}
        };
    },

    computed: {
        ...sync('Event/*')
    },

    created () {
        this.form = new Form({
            description: get(this.event.settings, 'globalCalendarInvites.description'),
            eventOrganizer: get(this.event.settings, 'globalCalendarInvites.eventOrganizer'),
            organizerEmail: get(this.event.settings, 'globalCalendarInvites.organizerEmail')
        });
    },

    methods: {
        updateSettings () {
            this.form.post(this.route('api.events.update-global-calendar-invites-settings', this.event)).then(() => {
                this.$toasted.global.success({
                    message: 'Global calendar invites settings are updated successfully.',
                    onComplete: () => {
                        window.location.reload();
                    }
                });
            }).catch(() => {
                this.$toasted.global.error('There was a problem updating global calendar invites settings.');
            });
        }
    }
};
</script>
