<template>
    <div>
        <slot v-bind="{ createEvent }"></slot>

        <app-modal v-model="showCreateEventModal" @closed="creatingClassyEvent = false">
            <template #header>
                <template v-if="creatingClassyEvent">Sync a new Classy campaign</template>
                <template v-else>New Event</template>
            </template>

            <template #default="{ close }">
                <template v-if="creatingClassyEvent">
                    <div class="w-full flex items-center justify-center p-4">
                        <app-icon
                            v-if="fetchingClassyCampaigns"
                            name="loader"
                            class="w-5 h-5 fill-current"
                        ></app-icon>

                        <div v-else class="flex flex-col items-center justify-center space-y-4">
                            <div class="text-center flex items-center justify-center space-x-4">
                                <app-icon
                                    name="check-circle"
                                    class="w-6 h-6 text-green"
                                ></app-icon>

                                <span>You're connected to Classy organization {{ auth().user().classyOrganizationName }}.</span>
                            </div>

                            <simple-picker
                                v-model="form.external_event_id"
                                :items="classyCampaigns"
                                item-value="id"
                                item-label="label"
                                form-field-class="field"
                                class="w-full max-w-xs"
                                placeholder-empty-state="Please select a campaign ..."
                                is-filterable
                                filterable-property="label"
                            ></simple-picker>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="w-full flex flex-col md:flex-row space-x-2 space-y-2 md:space-y-0 p-4">
                        <div class="w-full md:w-1/2 flex items-center justify-center">
                            <a
                                v-tippy
                                role="button"
                                class="w-full mx-2 border-2 rounded-lg hover:border-teal p-4 flex flex-col space-y-4 items-center justify-center"
                                :content="!canCreateClassyEvent ? 'Click here to integrate with the Classy API first via your My Account page.' : ''"
                                @click="createClassyEvent"
                            >
                                <logo-classy class="w-auto h-16"></logo-classy>

                                <p class="text-center">Sync an existing Classy campaign with RSVPify.</p>
                            </a>
                        </div>
                        <div class="w-full md:w-1/2 flex items-center justify-center">
                            <a :href="route('events.create')" class="w-full mx-2 border-2 rounded-lg hover:border-teal p-4 flex flex-col space-y-4 items-center justify-center">
                                <logo-rsvpify-full class="w-auto h-16"></logo-rsvpify-full>

                                <p class="text-center">Create a full service event on RSVPify.</p>
                            </a>
                        </div>
                    </div>
                </template>
            </template>

            <template #footer="{ close }">
                <template v-if="creatingClassyEvent">
                    <stateful-button
                        class="button-primary"
                        :loading="form.processing"
                        @click="submitNewClassyEvent"
                    >
                        Create
                    </stateful-button>

                    <button class="button" @click="close">Cancel</button>
                </template>
            </template>
        </app-modal>
    </div>
</template>

<script>
import { get } from 'lodash';
import axios from '@/util/axios';
import Form from '@/validation/Form';
import InteractsWithAbly from '@/mixins/InteractsWithAbly';

export default {
    name: 'CreateEventAction',

    mixins: [InteractsWithAbly],

    data () {
        return {
            showCreateEventModal: false,
            form: new Form({ external_event_id: null }),
            creatingClassyEvent: false,
            classyCampaigns: [],
            fetchingClassyCampaigns: false,
            selectedClassyCampaignId: null
        };
    },

    computed: {
        canCreateClassyEvent () {
            return this.auth().user().hasClassyIntegration && this.auth().user().classyIntegrationEnabled;
        }
    },

    methods: {
        createEvent () {
            if (!this.auth().user().plan.features.ClassyIntegration.allowed) {
                window.location.href = this.route('events.create');

                return;
            }

            this.showCreateEventModal = true;
        },

        createClassyEvent () {
            if (!this.canCreateClassyEvent) {
                window.location.href = this.route('account.show', { classy: 1 });

                return;
            }

            this.creatingClassyEvent = true;
            this.fetchingClassyCampaigns = true;

            axios.post(this.route('api.classy.get-campaigns'))
                .then(({ data }) => {
                    this.$set(this, 'classyCampaigns', data.map((campaign) => {
                        return {
                            id: campaign.id,
                            label: campaign.name
                        };
                    }));
                })
                .catch((error) => {
                    this.$toasted.global.error(get(error, 'response.data.error'));
                    this.creatingClassyEvent = false;
                })
                .finally(() => {
                    this.fetchingClassyCampaigns = false;
                });
        },

        submitNewClassyEvent () {
            this.form.post(this.route('api.classy.new-event'))
                .then(({ data }) => {
                    this.showCreateEventModal = false;
                    window.location.href = this.route('events.dashboard.overview', data);
                })
                .catch((error) => {
                    this.$toasted.global.error(get(error, 'response.data.error'));
                });
        }
    }
};
</script>
