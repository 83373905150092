/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fruit-watermelon-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M1.2 17.94v-.01c-.61.6-.61 1.58-.01 2.19.04.04.08.08.13.11l-.01-.01a13.61 13.61 0 0018.04-.88v-.01c4.81-4.93 5.18-12.68.87-18.05h0c-.54-.68-1.51-.79-2.18-.26-.05.03-.09.07-.14.11z"/><path pid="1" d="M2.1 17.049h0c4.09 4.21 10.82 4.3 15.04.21v-.01c4.09-4.22 3.99-10.95-.22-15.05M15 9.75h1.5M12.75 12.75l1.5 1.5M9.75 15v1.5"/></g>'
  }
})
