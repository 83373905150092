/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'font-picker-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<rect pid="0" x=".75" y="15.748" width="22.5" height="7.5" rx="1.5" ry="1.5" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.25 8.248h7.5M15 12.748h1.5M7.5 12.748H9M8.25 12.748V4.5a3.75 3.75 0 017.5 0v8.25M19.5 18.748h-3l1.5 1.5 1.5-1.5z"/>'
  }
})
