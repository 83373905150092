/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-fork-spoon': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>restaurant knife utensils silverware flatware</desc><g _fill="currentColor"><path pid="0" d="M10.25 0a1 1 0 00-1 1v5a1.993 1.993 0 01-.571 1.4.251.251 0 01-.429-.175V1a1 1 0 00-2 0v6.223a.251.251 0 01-.429.175A1.993 1.993 0 015.25 6V1a1 1 0 00-2 0v5a4.009 4.009 0 002.667 3.772.5.5 0 01.333.471V23a1 1 0 002 0V10.243a.5.5 0 01.333-.471A4.009 4.009 0 0011.25 6V1a1 1 0 00-1-1zM16.75 0a4 4 0 00-4 4v2a4.009 4.009 0 002.667 3.772.5.5 0 01.333.471V23a1 1 0 002 0V10.243a.5.5 0 01.333-.471A4.009 4.009 0 0020.75 6V4a4 4 0 00-4-4zm-.5 2.5a1 1 0 00-1 1 .5.5 0 01-1 0 2 2 0 012-2 .5.5 0 010 1z"/></g>'
  }
})
