<template>
    <div class="flex flex-col grow">
        <div class="flex flex-wrap">
            <form-field-wrapper
                label="Speaker Name"
                :error="form.errors.get('name')"
                :should-show-error="form.errors.has('name')"
            >
                <input
                    v-model="form.name"
                    class="form-field"
                >
            </form-field-wrapper>

            <form-field-wrapper label="Description">
                <textarea
                    v-model="form.description"
                    class="form-field"
                    rows="5"
                    placeholder="Enter speaker description and additional details."
                ></textarea>
            </form-field-wrapper>

            <form-field-wrapper label="Image or Logo">
                <image-manager
                    v-model="form.images"
                    :max-images="1"
                    :settings="{}"
                    title="Upload Image or Logo"
                    uploader-classes="text-left"
                ></image-manager>
            </form-field-wrapper>

            <form-panel v-model="form.includeUrl" label="Include Website Link">
                <form-field-wrapper
                    label="URL"
                    :error="form.errors.get('url')"
                    :should-show-error="form.errors.has('url')"
                >
                    <input
                        v-model="form.url"
                        class="form-field"
                    >
                </form-field-wrapper>

                <form-field-wrapper
                    label="Button Text"
                    :error="form.errors.get('buttonText')"
                    :should-show-error="form.errors.has('buttonText')"
                >
                    <input
                        v-model="form.buttonText"
                        class="form-field"
                    >
                </form-field-wrapper>
            </form-panel>

            <portal to="app-drawer-footer">
                <footer-save-cancel
                    :processing="loading"
                    @cancel="$emit('cancel')"
                    @save="save"
                ></footer-save-cancel>
            </portal>
        </div>
    </div>
</template>

<script>
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';
import WallItemSettingsMixin from '@/mixins/WallItemSettingsMixin';

export default {
    name: 'WallSpeakersBlockItemSettings',

    mixins: [GeneratesUniqueKey, WallItemSettingsMixin],

    data () {
        return {
            imageBeingCropped: null
        };
    },

    methods: {
        cropImage (croppedImage) {
            this.form.image.src = croppedImage;
            this.finishCroppingImage();
        },

        deleteImage () {
            this.form.image = null;
        },

        finishCroppingImage () {
            this.imageBeingCropped = null;
        },

        imageUploaded (image) {
            this.$set(this.form, 'image', {
                id: this.getUniqueKey(),
                ...image
            });
        },

        startCroppingImage (image) {
            this.imageBeingCropped = image;
        }
    }
};
</script>
