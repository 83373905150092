/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'family-father-child-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>dad son daughter heart love</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25.75a3 3 0 100 6 3 3 0 100-6zM5.26 8.25v6M7.509 23.25l.75-7.5h1.5v-3 0a4.5 4.5 0 00-9 0v3h1.5l.75 7.5zM14.25 8.25a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM13.057 14.447v-.01c1.96-.67 4.09.39 4.75 2.35.13.38.19.79.19 1.19v.75h-1.5l-.75 4.5h-3l-.38-2.26M22.69 1.33h0C21.96.57 20.76.54 20 1.26c-.03.02-.05.04-.07.06l-1.18 1.21-1.18-1.22h0c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06h-.01c-.77.79-.77 2.04 0 2.84l3.51 3.63-.01-.01c.22.23.58.24.81.01 0-.01.01-.02.01-.02l3.51-3.64V4.1c.75-.8.75-2.05-.01-2.85z"/></g>'
  }
})
