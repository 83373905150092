/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fruit-banana-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M22.5 10.954h0c0 6.79-5.51 12.3-12.3 12.3-4.48 0-5.8-1.84-8.227-2.82h0a.757.757 0 01-.42-.97c.14-.37.32-.84.51-1.36h-.01c.14-.38.56-.58.94-.45 7.22 2.49 17.62-5.72 12.36-15.2l-.01-.01a.756.756 0 01.29-1.03c.04-.03.09-.05.13-.06.6-.2 1.38-.45 1.95-.62v-.01c.38-.12.79.08.93.47 1.29 3.73 3.8 3.95 3.8 9.7z"/>'
  }
})
