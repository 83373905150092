/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'color-palette-sample-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>theme art</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="7.5" height="22.5" x="1.5" y=".75" rx="1.5"/><path pid="1" d="M11.765.86l4.15 1.717-.01-.01c.76.31 1.13 1.19.81 1.95h-.01l-7.74 17.21M19.081 5.79l3.02 3.32h0c.55.61.51 1.56-.1 2.11h-.01L8.8 22.43M1.5 5.25H9M1.5 9.75H9M1.5 14.25H9M5.25 19.5h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38 0 0 0 0 0 0"/></g>'
  }
})
