/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-quarantine-hospital-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.24 15.01a2.85 2.85 0 100 5.71 2.85 2.85 0 100-5.72zM17.76 12.87h.96M18.24 12.87v2.15M21.44 14l.67.67M21.77 14.34l-1.51 1.51M23.24 17.4v.95M23.24 17.87H21.1M22.11 21.07l-.67.67M21.77 21.41l-1.51-1.52M18.72 22.87h-.96M18.24 22.87v-2.14M15.04 21.74l-.67-.67M14.7 21.41l1.52-1.52M13.24 18.35v-.95M13.24 17.87h2.14M14.37 14.67l.67-.67M14.7 14.34l1.52 1.51M3.22 10.259v7.4-.01c-.01.68.55 1.23 1.23 1.23h5.8M19.26 9.026L11.37 1.66h0C10.6.94 9.4.94 8.64 1.65L.75 9.016M10.01 7.98v6M7.01 10.98h6"/></g>'
  }
})
