/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-protection-face-shield-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.167 17.1v-2.05h1.53a1.024 1.024 0 00.93-1.44c-1-2.24-2.48-4.994-2.48-4.994V6.41c0-.3-.06-.59-.19-.85-1.12-2.409-2.62-4.08-5.5-4.66C6.58-.28.77 3.83.73 9.82v-.01a9.139 9.139 0 003.07 6.93v6.44M14.07 23.25v-1.34M.78 9.43h10.41"/><path pid="1" d="M11.2 4.628h8.04-.01c2.2-.01 4 1.79 4 3.99 0 0 0 0 0 0v11.57h-5.05l-.01-.001a7.01 7.01 0 01-7-7.01V4.59zM11.19 4.63H2.53"/></g>'
  }
})
