/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hotel-bedroom-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.5 15.59a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM12 15.6h6.25-.01c1.1-.01 2 .89 2 1.99 0 0 0 0 0 0v2.5h-8.25v-4.5zM12 20.099H3.75v3M20.25 20.1v3M20.25 6.1v6.5M3.75 12.6V6.1M.75 8.1l10.418-6.95h-.01c.5-.34 1.16-.34 1.66-.01l10.41 6.94"/></g>'
  }
})
