/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-halloween-spider-web-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>spooky scary haunted</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.926 7.63l-.01-.01a11.403 11.403 0 01-5.95-5.75v-.01c-1.39.28-2.79.42-4.2.42h0c-1.42-.01-2.82-.15-4.2-.43v-.01A11.436 11.436 0 011.6 7.58h0a9.793 9.793 0 01-.01 8.12l-.01-.01c2.64 1.08 4.76 3.14 5.94 5.74h-.01c1.37-.33 2.78-.47 4.2-.43v-.01c1.41-.05 2.82.1 4.19.42h-.01a11.17 11.17 0 015.94-5.75l-.01-.01a9.778 9.778 0 010-8.13z"/><path pid="1" d="M18.7 8.93l-.01-.01A7.845 7.845 0 0114.63 5v-.01c-.95.19-1.91.28-2.87.29h0c-.97-.01-1.93-.1-2.87-.29v-.01a7.825 7.825 0 01-4.06 3.91h0c.8 1.75.8 3.78-.01 5.54h0c1.8.74 3.25 2.14 4.05 3.91v-.01c.93-.23 1.9-.32 2.86-.3v-.01c.96-.03 1.92.06 2.86.29h-.01a7.61 7.61 0 014.05-3.92l-.01-.01a6.642 6.642 0 010-5.55zM7.11 1l9.29 21.75M16.4 1L7.11 22.75M23.25 7.24L.75 16.32M23.25 16.32L.75 7.24"/></g>'
  }
})
