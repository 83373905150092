/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-wine-glass-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>bar alcohol grape red white rose</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9 23.25h6M12 23.25v-8.69M18.887 6.51h0c.37 2.46-.59 4.94-2.54 6.49v0h-.01a6.976 6.976 0 01-8.71-.01v0h0a6.968 6.968 0 01-2.54-6.5l.78-5.14h-.01c.05-.37.37-.64.74-.64H17.3L17.297.7c.37 0 .68.27.74.63zM5.08 6.75h13.84"/></g>'
  }
})
