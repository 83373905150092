/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin-cart-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>shopping ecommerce</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.163 5.254l-2.25 7.5h-6l-1.5-4.5h8.85M9.28 15h-.01c.2-.01.37.16.37.37M8.913 15.37l-.01-.01c-.01-.21.16-.38.37-.38v-.01M9.28 15.754h-.01a.386.386 0 01-.38-.38s0 0 0 0M9.663 15.37v0c0 .2-.17.375-.375.375M14.53 15h-.01c.2-.01.37.16.37.37M14.163 15.37l-.01-.01c-.01-.21.16-.38.37-.38v-.01M14.53 15.754h-.01a.386.386 0 01-.38-.38s0 0 0 0M14.913 15.37v0c0 .2-.17.375-.375.375"/><path pid="1" d="M21 9.754c0 7.9-6.932 12.33-8.629 13.3v-.01a.77.77 0 01-.75 0c-1.7-.97-8.63-5.41-8.63-13.3h0a8.988 8.988 0 018.99-9.01c4.97-.01 9 4.02 9 8.99 0 0 0 0 0 0z"/></g>'
  }
})
