/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-christmas-snowflake-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>winter</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 1.5v21M22.5 12h-21M18 6L6 18M6 6l12 12M9 2.25l3 3 3-3M21.75 9l-3 3 3 3M15 21.75l-3-3-3 3M2.25 15l3-3-3-3"/></g>'
  }
})
