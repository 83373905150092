/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-touch-hand-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17 13.82a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM16.43 11.25h1.14M17 11.25v2.57M20.84 12.6l.81.81M21.24 13.01l-1.82 1.82M23 16.68v1.14M23 17.25h-2.57M21.65 21.09l-.81.81M21.24 21.49l-1.82-1.82M17.57 23.25h-1.14M17 23.25v-2.57M13.16 21.9l-.81-.81M12.76 21.49l1.82-1.82M11 17.82v-1.14M11 17.25h2.57M12.35 13.41l.81-.81M12.76 13.01l1.82 1.82M4 11.25v-7.5h0a1.496 1.496 0 112.99-.02s0 0 0 0"/><path pid="1" d="M4 9v0c0-.83-.68-1.5-1.5-1.5C1.67 7.5 1 8.17 1 9v5.84H.99c-.01 1.5.74 2.9 2 3.74l1.82 1.21h0c.41.27.67.74.67 1.24v2.2M7 7.5V2.25h0a1.496 1.496 0 112.99-.02s0 0 0 0v5.25"/><path pid="2" d="M10 3h0a1.496 1.496 0 112.99-.02s0 0 0 0v2.25"/><path pid="3" d="M13 8.25v-3h0a1.496 1.496 0 112.99-.02s0 0 0 0v3"/></g>'
  }
})
