/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-briefcase-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.36 5.39a2.85 2.85 0 100 5.71 2.85 2.85 0 100-5.72zM11.89 3.25h.96M12.37 3.25v2.14M15.57 4.38l.67.67M15.9 4.71l-1.51 1.52M17.37 7.77v.96M17.37 8.25h-2.14M16.24 11.45l-.67.67M15.9 11.79l-1.51-1.52M12.85 13.25h-.96M12.37 13.25v-2.14M9.17 12.12l-.67-.67M8.83 11.79l1.52-1.52M7.37 8.73v-.96M7.37 8.25h2.14M8.5 5.05l.67-.67M8.83 4.71l1.52 1.52M6.75 2.25V.75M17.25 2.25V.75M7.5 18.75h0c0 .82.67 1.49 1.5 1.49h6-.01c.82 0 1.5-.68 1.5-1.5"/><rect pid="1" width="22.5" height="7.5" x=".75" y="15.75" rx="1"/><path pid="2" d="M15.75 2.25h3.75-.01c.82-.01 1.5.67 1.5 1.5v12M3 15.75v-12h0c-.01-.83.67-1.51 1.49-1.51h3.94"/></g>'
  }
})
