<template>
    <a
        class="button button-primary flex-1"
        role="button"
        @click="$emit('save')"
    >
        {{ confirmButtonText }}
    </a>
</template>

<script>
export default {
    name: 'FooterSave',

    props: {
        confirmButtonText: {
            type: String,
            default: 'Save'
        }
    }
};
</script>
