/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fruit-orange-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.21 6l-.01-.01c4.75.2 8.44 4.23 8.24 8.99-.21 4.75-4.24 8.44-9 8.24-4.62-.21-8.25-4-8.26-8.62M9.47.75h7.5M13.22.75v10.5"/><path pid="1" d="M10.967 10.569v0l-.01-.01c1.36.9 3.13.9 4.5 0v0M8.082 9.635h-.01a8.244 8.244 0 01-3.9 2.03v-.01c-.69.2-1.4-.19-1.6-.87-.08-.24-.08-.5-.01-.74h-.01a8.153 8.153 0 012.03-3.9h-.01A8.048 8.048 0 018.46 4.1h-.01a1.282 1.282 0 011.6 1.59v-.01a8.351 8.351 0 01-2.04 3.9z"/></g>'
  }
})
