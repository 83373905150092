/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'umbrella-rain-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>weather</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M2.545 5.34h-.01c4.39-4.4 11.51-4.4 15.91-.01 4.39 4.39 4.39 11.51 0 15.91l-.1-1.06h0a4.724 4.724 0 00-5.12-4.27h-.1v0c0-2.93-2.38-5.3-5.3-5.3h-.01c.28-2.59-1.57-4.92-4.16-5.21-.04-.01-.07-.01-.1-.01z"/><path pid="1" d="M11.6 12.2l-7.994 7.98h-.01a1.5 1.5 0 00-.01 2.12c.58.58 1.53.58 2.12 0M18.45 5.34l1.07-1.06"/></g>'
  }
})
