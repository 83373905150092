/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus-minus-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 11.87s2.25 5.25 6 5.25l-.01-.001c2.89 0 5.25-2.36 5.25-5.25 0-2.9-2.36-5.25-5.25-5.25-3.76-.001-6 5.25-6 5.25z"/><path pid="1" d="M12 11.87s-2.25 5.25-6 5.25l-.01-.001C3.09 17.1.74 14.75.74 11.86c0-2.9 2.35-5.25 5.25-5.25 3.74-.001 6 5.25 6 5.25zM3.75 11.88h4.5M15.75 11.88h4.5M18 9.62v4.5"/></g>'
  }
})
