<template>
    <div>
        <div
            v-for="(guest, index) in guests"
            :key="guest.id"
            class="row"
        >
            <div class="col-6">
                <input
                    v-model="guest.firstName"
                    class="form-field"
                    placeholder="First Name"
                >
            </div>
            <div class="col-6">
                <input
                    v-model="guest.lastName"
                    class="form-field"
                    placeholder="Last Name"
                >
            </div>
        </div>
        <div class="flex justify-end mt-4">
            <button
                class="text-purple hover:text-purple-lighter mr-2"
                @click="removeRow"
            >
                <app-icon
                    name="remove-circle"
                    class="h-6 w-6"
                    stroke
                ></app-icon>
            </button>
            <button
                :class="addGuestButtonStyle"
                :disabled="!canAddMoreGuests"
                @click="addRow"
            >
                <app-icon name="add-circle" class="h-6 w-6"></app-icon>
            </button>
        </div>
    </div>
</template>

<script>
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';

export default {
    name: 'PartyInviteList',

    mixins: [GeneratesUniqueKey],

    data () {
        return {
            guests: [{ id: this.getUniqueKey(), firstName: '', lastName: '' }]
        };
    },

    computed: {
        canAddMoreGuests () {
            return this.guests.length < 10;
        },

        addGuestButtonStyle () {
            return {
                'text-purple hover:text-purple-lighter': this.canAddMoreGuests,
                'text-gray-300 cursor-not-allowed': !this.canAddMoreGuests
            };
        }
    },

    methods: {
        addRow () {
            if (this.canAddMoreGuests) {
                this.addNewEmptyGuest();
            }
        },

        removeRow () {
            if (this.guests.length === 1) {
                this.resetGuests();
            } else {
                this.guests.pop();
            }
        },

        addNewEmptyGuest () {
            this.guests.push({ id: this.getUniqueKey(), firstName: '', lastName: '' });
        },

        resetGuests () {
            this.guests = [{ id: this.getUniqueKey(), firstName: '', lastName: '' }];
        }
    }
};
</script>
