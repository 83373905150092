import { Plugin, TextSelection } from 'tiptap';
import { Link } from 'tiptap-extensions';
import { getMarkAttrs, getMarkRange } from 'tiptap-utils';

export default class EditorLink extends Link {
    get schema () {
        return {
            attrs: {
                href: {
                    default: null
                }
            },
            inclusive: false,
            parseDOM: [{
                tag: 'a[href]',
                getAttrs: (dom) => {
                    return {
                        href: dom.getAttribute('href')
                    };
                }
            }],
            toDOM: (node) => {
                return ['a', {
                    ...node.attrs,
                    rel: 'noopener noreferrer nofollow',
                    target: '_blank'
                }, 0];
            }
        };
    }

    get plugins () {
        if (!this.options.openOnClick) {
            return [];
        }

        return [new Plugin({
            props: {
                handleClick: (view, pos, event) => {
                    const {
                        schema,
                        doc,
                        tr
                    } = view.state;

                    const attrs = getMarkAttrs(view.state, schema.marks.link);

                    if (attrs.href) {
                        event.stopPropagation();
                    }

                    const range = getMarkRange(doc.resolve(pos), schema.marks.link);

                    if (!range) {
                        return;
                    }

                    const $start = doc.resolve(range.from);
                    const $end = doc.resolve(range.to);
                    const transaction = tr.setSelection(new TextSelection($start, $end));

                    view.dispatch(transaction);
                }
            }
        })];
    }
}
