<template>
    <app-drawer
        :value="isSelected"
        :outside-click-handler="closeWithoutSaving"
        :default-close-handler="close"
        :ignore-outside-click="isClosing"
    >
        <template v-if="isSelected">
            <template slot="title-bar">
                <app-icon
                    name="settings-cogs"
                    class="h-6 w-6 text-gray-700"
                    stroke
                ></app-icon>

                <div class="text-gray-800 font-bold text-lg">
                    <portal-target name="block-settings-title">
                        <div class="flex items-center">
                            <span>{{ block.title }}</span>

                            <premium-feature
                                v-if="block.properties.feature"
                                class="ml-2"
                                :feature="block.properties.feature"
                            ></premium-feature>
                        </div>
                    </portal-target>
                </div>
            </template>

            <div>
                <component
                    :is="settingsComponent"
                    :event="event"
                    :block="block"
                    :settings="block.pivot.settings"
                    :default-close-handler="close"
                    @input="blockSettingsModified"
                    @original-settings-override-input="originalSettingsOverride"
                ></component>

                <div v-if="isEventTemplate" class="border p-4 mt-8 rounded-md">
                    <div class="flex items-center mb-4">
                        <app-icon
                            name="color-painting-palette"
                            class="h-5 w-5 text-gray-700 mr-2"
                            stroke
                        ></app-icon>

                        <div class="text-gray-800 font-bold text-base">
                            Template Settings
                        </div>
                    </div>

                    <form-field-wrapper label="Show Block To">
                        <simple-picker
                            v-model="block.pivot.settings.setup_type"
                            placeholder-empty-state="All Setup Types"
                            :items="setupTypes"
                            item-label="label"
                            item-value="value"
                        ></simple-picker>
                    </form-field-wrapper>
                </div>
            </div>
        </template>

        <template #overlay="{ clickOutsideOfDrawer }">
            <template v-if="hasSlot('overlay')">
                <slot name="overlay" :click-outside-of-drawer="clickOutsideOfDrawer"></slot>
            </template>
        </template>

        <portal to="app-drawer-footer">
            <template slot-scope="{ defaultCloseHandler }">
                <portal-target
                    name="block-settings-footer"
                    :slot-props="{
                        defaultCloseHandler,
                        saveBlockSettings,
                        closeWithoutSaving,
                        saving
                    }"
                >
                    <footer-save-cancel
                        :processing="saving"
                        @cancel="closeWithoutSaving"
                        @save="saveBlockSettings(defaultCloseHandler)"
                    ></footer-save-cancel>
                </portal-target>
            </template>
        </portal>
    </app-drawer>
</template>

<script>
import Vue from 'vue';
import {
    camelCase, cloneDeep, delay, isEqual, upperFirst
} from 'lodash';

import SlotHelpers from '@/mixins/SlotHelpers';

export default {
    name: 'BlockSettings',

    mixins: [
        SlotHelpers
    ],

    props: {
        domain: {
            type: String,
            required: true
        },

        event: {
            type: Object,
            required: true
        },

        saving: {
            type: Boolean,
            default: false
        },

        setupTypes: {
            type: Array,
            default: () => { return []; }
        }
    },

    data () {
        return {
            block: {},
            isClosing: false,
            originalBlock: {},
            form: null
        };
    },

    computed: {
        selectedBlock () {
            return this.domain === 'wall' ? this.$store.get('Wall.selectedBlock') : this.$store.get('Form.selectedBlock');
        },

        isSelected () {
            return !!this.block;
        },

        isUnmodified () {
            /**
             * There's no need to compare values that cannot actually be changed
             * by the host. So, we're only comparing the actual pivot settings,
             * and possible block relations such as question, meals and products.
             */
            const {
                question, meals, products, ...settings
            } = this.block.pivot.settings;

            const currentBlock = {
                ...this.block.pivot,
                settings,
                question,
                meals,
                products
            };

            /**
             * We'ere also extracting "changeable" values from the original
             * block, instead of comparing the whole object (which comes with
             * Block properties, the Form, Event, Wall objects that are loaded
             * with it etc.), we just compare the relavant ones.
             */
            const originalBlock = {
                ...this.originalBlock.pivot,
                question: this.originalBlock.pivot.question,
                meals: this.originalBlock.pivot.meals,
                products: this.originalBlock.pivot.products
            };

            return isEqual(currentBlock, originalBlock);
        },

        isEventTemplate () {
            return this.event.user_id === null;
        },

        settingsComponent () {
            const component = `${this.domain}-${this.block.slug}-block-settings`;

            // First check is such a component registered
            if (Vue.options.components[upperFirst(camelCase(component))]) {
                return component;
            }

            return 'div';
        }
    },

    watch: {
        'selectedBlock.pivot.settings': {
            deep: true,
            handler (newVal) {
                if (!this.block) {
                    return;
                }

                this.block.pivot.settings = newVal;
            }
        }
    },

    mounted () {
        /**
         * Certain self-editable block properties (text blocks with WYSIWYGS)
         * can be edited outside of the sidebar and we shouldn't consider
         * such changes "unsaved".
         */
        App.$on('modified-block', ({ id, ...settings }) => {
            if (this.selectedBlock?.pivot?.id === id) {
                Object.assign(this.originalBlock.pivot.settings, settings);
            }
        });
    },

    beforeMount () {
        this.block = this.selectedBlock;
        this.originalBlock = cloneDeep(this.selectedBlock);
    },

    methods: {
        blockSettingsModified (form) {
            this.form = form;

            if (this.block) {
                Object.assign(this.block.pivot.settings, form.data());
            }
        },

        /**
         * Used for blocks which add Event-level settings to the BLock
         * settings component, as is the case with Recurring Time Slots
         * settings on the Ticketing Block.
         */
        originalSettingsOverride (override) {
            if (this.block) {
                Object.assign(this.originalBlock.pivot.settings, override);
            }
        },

        close () {
            this.block = null;
            this.$emit('close-settings');
        },

        closeWithoutSaving () {
            this.isClosing = true;

            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            const onConfirm = () => {
                this.restoreOriginalBlockSettings();
                this.close();
            };

            const onCancel = () => {
                delay(() => {
                    this.isClosing = false;
                }, 200);
            };

            if (this.isUnmodified) {
                onConfirm();
            } else {
                App.alert().confirm(
                    'Are you sure?',
                    `The changes you've made haven't been saved. Are you sure you want to leave without saving your changes?`,
                    attributes,
                    onConfirm,
                    onCancel
                );
            }
        },

        restoreOriginalBlockSettings () {
            this.selectedBlock.pivot = this.originalBlock.pivot;
        },

        saveBlockSettings (defaultCloseHandler) {
            this.$emit('save-settings', {
                defaultCloseHandler,
                block: this.selectedBlock,
                form: this.form
            });
        }
    }
};
</script>
