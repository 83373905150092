/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'book-closed-heart-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>library read favorite</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 23.25H3.75l-.01-.001a2.256 2.256 0 01-2.25-2.26s0 0 0 0"/><path pid="1" d="M19.5 9.75V1.5v0c0-.42-.34-.75-.75-.75H4.49c-1.66 0-3 1.34-3 3 0 0 0 0 0 0V21h0a2.24 2.24 0 012.24-2.26h4.5"/><path pid="2" d="M16.5 23.25l-5.12-5.34-.01-.01a3.028 3.028 0 01-.57-3.5v0h-.01c.74-1.5 2.56-2.11 4.06-1.36.29.14.55.33.78.56l.83.83.83-.84h-.01a3.009 3.009 0 014.83.77v0l-.01-.01c.58 1.16.35 2.57-.57 3.49z"/></g>'
  }
})
