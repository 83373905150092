<template>
    <div class="flex items-center justify-center">
        <div class="w-1/2">
            <div class="shadow-md">
                <p class="bg-white text-xs p-2 text-gray-500">Simplest picker, not filterable, primitive type items</p>
                <simple-picker
                    v-model="selectedPrimitiveItem"
                    :items="primitiveItems"
                    placeholder-empty-state="Please choose an item ..."
                    placeholder-search="Type to search ..."
                    class="m-6"
                ></simple-picker>
                <p class="bg-white text-xs p-2 text-gray-500">Selected: <b>{{ selectedPrimitiveItem }}</b><br></p>
            </div>

            <div class="shadow-md">
                <p class="bg-white text-xs p-2 text-gray-500">Simple picker, a lot of items</p>
                <simple-picker
                    v-model="selectedItemFromLongArray"
                    :items="lotOfItems"
                    placeholder-empty-state="Please choose an item ..."
                    placeholder-search="Type to search ..."
                    class="m-6"
                ></simple-picker>
                <p class="bg-white text-xs p-2 text-gray-500">Selected: <b>{{ selectedPrimitiveItem }}</b><br></p>
            </div>

            <div class="shadow-md mt-4">
                <p class="bg-white text-xs p-2 text-gray-500">Filterable, primitive type items</p>

                <simple-picker
                    v-model="selectedPrimitiveItem2"
                    :items="primitiveItems"
                    :is-filterable="true"
                    class="m-6"
                ></simple-picker>

                <p class="bg-white text-xs p-2 text-gray-500">Selected: <b>{{ selectedPrimitiveItem2 }}</b><br></p>
            </div>

            <div class="shadow-md mt-4">
                <p class="bg-white text-xs p-2 text-gray-500">Filterable (by label), object type items</p>

                <simple-picker
                    v-model="selectedObjectItem"
                    :items="objectItems"
                    :is-filterable="true"
                    filterable-property="name"
                    item-label="name"
                    class="m-6"
                ></simple-picker>
                <p class="bg-white text-xs p-2 text-gray-500">Selected: <b>{{ selectedObjectItem }}</b><br></p>
            </div>

            <div class="shadow-md mt-4">
                <p class="bg-white text-xs p-2 text-gray-500">Filterable (by number), object type items</p>
                <simple-picker
                    v-model="selectedObjectItemValue"
                    :items="objectItems"
                    :is-filterable="true"
                    filterable-property="numberRepresentation"
                    item-label="name"
                    item-value="numberRepresentation"
                    class="m-6"
                ></simple-picker>
                <p class="bg-white text-xs p-2 text-gray-500">Selected: <b>{{ selectedObjectItemValue }}</b><br></p>
            </div>

            <div class="shadow-md mt-4">
                <p class="bg-white text-xs p-2 text-gray-500">Filterable, with custom filterFunction searching for numbers in italian, custom slots</p>
                <simple-picker
                    v-model="selectedObjectItem2"
                    :items="objectItems"
                    :is-filterable="true"
                    :filter-function="italianFilterFunction"
                    item-label="name"
                    class="m-6"
                >
                    <div
                        slot="input"
                        slot-scope="{ selected }"
                        class="w-full flex text-white text-center"
                    >
                        <div class="shrink mr-2">
                            <app-icon :name="selected.icon" class="w-5 h-5 text-purple"></app-icon>
                        </div>
                        <div class="flex-1 bg-purple rounded-md mr-2 items-center">
                            <span>{{ selected.name }}</span>
                        </div>
                        <div class="flex-1 bg-purple-200 rounded-md ml-2 items-center">
                            <span>{{ selected.numberRepresentation }}</span>
                        </div>
                    </div>

                    <div
                        slot="item"
                        slot-scope="{ item, index }"
                        class="w-full flex items-center"
                    >
                        <div class="shrink mr-2">
                            {{ index }}.
                        </div>
                        <div class="shrink mr-2">
                            <app-icon :name="item.icon" class="w-4 h-4 text-purple"></app-icon>
                        </div>
                        <div class="grow">
                            <div class="grow bg-purple-200 rounded-md text-white p-2 ml-2">
                                <i>Actual Item Name</i>: {{ item.name }}
                            </div>
                        </div>
                    </div>
                </simple-picker>
                <p class="bg-white text-xs p-2 text-gray-500">Selected: <b>{{ selectedObjectItem2 }}</b><br></p>
            </div>

            <div class="shadow-md mt-4">
                <p class="bg-white text-xs p-2 text-gray-500">Label as a deep property, value as a deep property and filterable by a deep object property</p>
                <simple-picker
                    v-model="selectedObjectItem3"
                    :items="objectItems"
                    :is-filterable="true"
                    filterable-property="translations.italianName"
                    item-label="translations.italianName"
                    item-value="translations.italianName"
                    class="m-6"
                >
                </simple-picker>
                <p class="bg-white text-xs p-2 text-gray-500">Selected: <b>{{ selectedObjectItem3 }}</b><br></p>
            </div>
        </div>
    </div>
</template>

<script>
import range from 'lodash/range';

export default {
    data () {
        return {
            selectedPrimitiveItem: null,
            selectedPrimitiveItem2: null,
            primitiveItems: [
                'One',
                'Two',
                'Three',
                'Four'
            ],

            selectedItemFromLongArray: null,
            lotOfItems: range(1, 100),

            selectedObjectItem: null,
            selectedObjectItem2: null,
            selectedObjectItem3: null,
            selectedObjectItemValue: null,
            objectItems: [
                {
                    name: 'One', translations: { italianName: 'uno' }, numberRepresentation: 1, icon: 'add'
                },
                {
                    name: 'Two', translations: { italianName: 'due' }, numberRepresentation: 2, icon: 'chair'
                },
                {
                    name: 'Three', translations: { italianName: 'tre' }, numberRepresentation: 3, icon: 'settings-cog'
                },
                {
                    name: 'Four', translations: { italianName: 'quattro' }, numberRepresentation: 4, icon: 'calendar'
                }
            ],

            italianFilterFunction: (searchTerm, items) => {
                return items.filter((item) => {
                    const searchRegex = new RegExp(searchTerm, 'i');
                    return searchRegex.test(item.translations.italianName);
                });
            }
        };
    }
};
</script>
