<template>
    <div class="space-y-4">
        <form-field-wrapper :error="fieldError('streetAddress')">
            <single-line-text-input
                ref="streetAddressInput"
                placeholder="Street Address"
                :value="internalValue.streetAddress"
                @input="setProperty('streetAddress', $event)"
            ></single-line-text-input>
        </form-field-wrapper>

        <form-field-wrapper v-if="settings.includeSecondStreetAddressField" :error="fieldError('secondStreetAddress')">
            <single-line-text-input
                placeholder="Street Address 2"
                :value="internalValue.secondStreetAddress"
                @input="setProperty('secondStreetAddress', $event)"
            ></single-line-text-input>
        </form-field-wrapper>

        <div v-if="settings.includeCityField || settings.includeStateField" class="flex space-x-2">
            <div v-if="settings.includeCityField" class="flex-1">
                <form-field-wrapper :error="fieldError('city')">
                    <single-line-text-input
                        placeholder="City"
                        :value="internalValue.city"
                        @input="setProperty('city', $event)"
                    ></single-line-text-input>
                </form-field-wrapper>
            </div>

            <div v-if="settings.includeStateField" class="flex-1">
                <form-field-wrapper :error="fieldError('state')">
                    <us-states-picker
                        v-if="showStatesPicker"
                        v-theme="['form.text', 'form.accent', 'form.dropdown']"
                        class="w-full text-lg"
                        item-value="name"
                        placeholder-empty-state="State"
                        placeholder-search="Search states..."
                        :value="internalValue.state"
                        @input="setProperty('state', $event)"
                    ></us-states-picker>

                    <single-line-text-input
                        v-else
                        class="w-full"
                        placeholder="State"
                        :value="internalValue.state"
                        @input="setProperty('state', $event)"
                    ></single-line-text-input>
                </form-field-wrapper>
            </div>
        </div>

        <div v-if="settings.includeZipCodeField || showCountryPicker" class="flex space-x-2">
            <div v-if="settings.includeZipCodeField" class="flex-1">
                <form-field-wrapper :error="fieldError('zipCode')">
                    <single-line-text-input
                        placeholder="Zip Code"
                        :value="internalValue.zipCode"
                        @input="setProperty('zipCode', $event)"
                    ></single-line-text-input>
                </form-field-wrapper>
            </div>

            <div v-if="showCountryPicker" class="flex-1">
                <form-field-wrapper :error="fieldError('country')">
                    <country-picker
                        v-theme="['form.text', 'form.accent', 'form.dropdown']"
                        class="w-full text-lg"
                        item-value="name"
                        placeholder-empty-state="Country"
                        placeholder-search="Search countries..."
                        :value="internalValue.country"
                        @input="setProperty('country', $event)"
                    ></country-picker>
                </form-field-wrapper>
            </div>
        </div>

        <form-field-wrapper v-if="settings.includePhoneNumberField" :error="fieldError('phoneNumber')">
            <phone-number-input
                placeholder="Phone Number"
                :value="internalValue.phoneNumber"
                @input="setProperty('phoneNumber', $event)"
            ></phone-number-input>
        </form-field-wrapper>

        <form-field-wrapper v-if="settings.includeOccupationField" :error="fieldError('occupation')">
            <single-line-text-input
                placeholder="Occupation"
                :value="internalValue.occupation"
                @input="setProperty('occupation', $event)"
            ></single-line-text-input>
        </form-field-wrapper>

        <form-field-wrapper v-if="settings.includeEmployerField" :error="fieldError('employer')">
            <single-line-text-input
                placeholder="Employer"
                :value="internalValue.employer"
                @input="setProperty('employer', $event)"
            ></single-line-text-input>
        </form-field-wrapper>

        <form-field-wrapper v-if="settings.includeIndustryField" :error="fieldError('industry')">
            <single-line-text-input
                placeholder="Industry"
                :value="internalValue.industry"
                @input="setProperty('industry', $event)"
            ></single-line-text-input>
        </form-field-wrapper>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import CustomQuestion from '@/mixins/CustomQuestion';

export default {
    name: 'FormDonorInformationQuestion',

    mixins: [
        CustomQuestion
    ],

    computed: {
        errorBag: get('Submission/errorBag'),

        defaultValue () {
            return {
                streetAddress: '',
                secondStreetAddress: '',
                city: '',
                state: '',
                zipCode: '',
                country: this.showCountryPicker ? this.settings.defaultCountry : '',
                phoneNumber: '',
                occupation: '',
                employer: '',
                industry: ''
            };
        },

        namespace () {
            return 'donorInformation';
        },

        showCountryPicker () {
            return this.settings.includeCountryField && !this.showStatesPicker;
        },

        showStatesPicker () {
            return this.settings.stateField === 'usStates';
        }
    },

    methods: {
        focus () {
            this.$refs.streetAddressInput.focus();
        },

        fieldError (field) {
            return this.errorBag.get(`answers.${this.answerableId}.${field}`);
        }
    }
};
</script>
