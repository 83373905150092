/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>profile avatar account person</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 4.5A5.25 5.25 0 1012 15a5.25 5.25 0 100-10.5zM18.913 20.876h0c-3.8-3.82-9.97-3.84-13.79-.05-.02.01-.03.02-.05.04"/><path pid="1" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/></g>'
  }
})
