/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'users-location-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>multiple circle people group party</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a3 3 0 100 6 3 3 0 100-6zM15.75 14.25V12v0c0-2.08-1.68-3.75-3.75-3.75-2.08 0-3.75 1.67-3.75 3.75v2.25h1.5l.75 6h3l.75-6zM4.5 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM5.7 9.947l-.01-.01c-1.97-.67-4.1.39-4.76 2.35-.13.38-.2.78-.2 1.19v.75h1.5l.75 4.49h3l.37-2.26M19.5 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM18.3 9.947v-.01c1.96-.67 4.09.39 4.75 2.35.12.38.19.78.19 1.19v.75h-1.5l-.75 4.49h-3l-.38-2.26M23.25 21c0 1.24-5.04 2.25-11.25 2.25C5.78 23.25.75 22.24.75 21"/></g>'
  }
})
