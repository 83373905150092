/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'board-game-deuce-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>domino</desc><rect pid="0" width="21.92" height="11.657" x="1.04" y="6.172" rx="1.5" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" transform="matrix(.7071 -.70711 .7071 .7071 -4.971 12)"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="1" d="M7.88 7.88l8.24 8.24M18.994 8.612h0c.14.14.14.38 0 .52h-.01M18.464 8.612h-.01c.14-.15.38-.15.52-.01 0 0 0 0 0 0"/><path pid="2" d="M18.464 9.142l-.01-.01a.368.368 0 010-.53"/><path pid="3" d="M18.994 9.142h-.01c-.15.14-.39.14-.53-.01M13.328 7.066h0c.14.14.14.38-.01.53M12.8 7.066v-.01c.14-.15.38-.15.53 0M12.8 7.6l-.01-.01a.38.38 0 010-.54"/><path pid="4" d="M13.328 7.6v-.01a.4.4 0 01-.54 0M8.176 12.21h0c.14.14.14.38-.01.53M7.646 12.21h-.01c.14-.15.38-.15.52-.01 0 0 0 0 0 0"/><path pid="5" d="M7.646 12.74h0a.377.377 0 01-.01-.53v-.01"/><path pid="6" d="M8.176 12.74v-.01c-.15.14-.39.14-.53 0l-.01-.01M11.782 15.824h0c.14.14.14.38 0 .52h-.01M11.252 15.824v-.01c.14-.15.38-.15.53 0M11.252 16.35h0a.377.377 0 01-.01-.53v-.01"/><path pid="7" d="M11.782 16.35v-.01c-.15.14-.39.14-.53 0l-.01-.01M5.085 15.3h0c.14.14.14.38-.01.53M4.555 15.3h-.01c.14-.15.38-.15.52-.01 0 0 0 0 0 0"/><path pid="8" d="M4.555 15.839h0a.377.377 0 01-.01-.53v-.01"/><path pid="9" d="M5.085 15.839v-.01c-.15.14-.39.14-.53 0l-.01-.01M8.69 18.915h0c.14.14.14.38 0 .52h-.01M8.161 18.915v-.01c.14-.15.38-.15.53 0M8.161 19.44h0a.377.377 0 01-.01-.53v-.01"/><path pid="10" d="M8.69 19.44v-.01c-.15.14-.39.14-.53 0l-.01-.01"/></g>'
  }
})
