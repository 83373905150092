import { waitUntil } from 'async-wait-until';

async function sleep (ms) {
    await new Promise((resolve) => { return setTimeout(resolve, ms); });
}

export default async function (actions) {
    for (let i = 0; i < actions.length; i++) {
        const { method, selector } = actions[i];

        if (actions[i]?.sleep) {
            // eslint-disable-next-line no-await-in-loop
            await sleep(actions[i].sleep);
        }

        // eslint-disable-next-line no-await-in-loop
        await waitUntil(() => { return document.querySelector(selector) !== null; });

        document.querySelector(selector)[method]();
    }
}
