<template>
    <draggable
        v-model="models"
        v-bind="draggableOptions"
        @end="$emit('end', $event)"
    >
        <transition-group tag="div">
            <div
                v-for="(model, index) in models"
                :key="model[uniqueProperty]"
                class="flex items-center"
            >
                <div class="flex grow items-center font-semibold p-2 shadow rounded-md text-sm bg-white border my-2 draggable-block draggable-handle cursor-grab text-gray-500">
                    <div class="flex-1 break-anywhere text-gray-600">
                        {{ model[labelProperty] }}
                    </div>

                    <div
                        v-if="switchableProperty"
                        class="shrink mr-2 leading-none"
                    >
                        <toggle-switch
                            v-model="model[switchableProperty]"
                            small
                        ></toggle-switch>
                        <input
                            v-model="model[switchableProperty]"
                            type="checkbox"
                            class="hidden"
                        >
                    </div>

                    <slot
                        v-if="editable"
                        name="editButton"
                        :model="model"
                        :edit-model="editModel"
                    >
                        <a

                            class="shrink cursor-pointer hover:text-purple leading-none"
                            role="button"
                            data-descriptor="edit-item"
                            @click="editModel(model)"
                        >
                            <app-icon
                                name="settings-cog"
                                class="h-5 w-5"
                                stroke
                            ></app-icon>
                        </a>
                    </slot>

                    <app-icon
                        name="move-vertical-arrows"
                        class="h-5 w-5 shrink ml-2 leading-none"
                        stroke
                    ></app-icon>
                </div>

                <slot name="postItemButtons" :model="model"></slot>

                <a
                    v-if="isDeletable(model)"
                    role="button"
                    @click="deleteModal(index, model)"
                >
                    <app-icon
                        name="trash"
                        class="h-5 w-5 shrink ml-2 text-gray-500 hover:text-red hover:cursor-pointer"
                        stroke
                    ></app-icon>
                </a>

                <button
                    v-if="duplicable"
                    class="mx-2 text-gray-500 hover:text-gray-600"
                    @click="duplicateModel(model)"
                >
                    <app-icon
                        name="duplicate-square"
                        class="w-5 h-5"
                        stroke
                    ></app-icon>
                </button>
            </div>
        </transition-group>
    </draggable>
</template>

<script>
import draggable from 'vuedraggable';

export default {
    components: { draggable },

    props: {
        value: {
            type: Array,
            default: () => { return ([]); }
        },

        confirmDelete: {
            type: Boolean,
            default: false
        },

        confirmDeleteMessage: {
            type: String,
            default: 'This action cannot be undone. Existing data collected for this selection will not be affected, but new guests will not be able to select this option.'
        },

        deletable: {
            type: Boolean,
            default: false
        },

        duplicable: {
            type: Boolean,
            default: false
        },

        editable: {
            type: Boolean,
            default: true
        },

        uniqueProperty: {
            type: String,
            required: true
        },

        setSortProperty: {
            type: Boolean,
            default: false
        },

        labelProperty: {
            type: String,
            required: true
        },

        switchableProperty: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            models: this.value
        };
    },

    computed: {
        draggableOptions () {
            return {
                animation: 150,
                handle: '.draggable-handle'
            };
        }
    },

    watch: {
        value (newValue) {
            this.models = newValue;
        },

        models (newModels) {
            if (this.setSortProperty) {
                newModels.forEach((model, index) => {
                    this.$set(model, 'sort', index + 1);
                });
            }

            this.$emit('input', newModels);
        }
    },

    methods: {
        editModel (model) {
            this.$emit('edit-item', model);
        },

        deleteModal (index, model) {
            if (!this.confirmDelete) {
                this.$emit('delete-item', index, model);
                return;
            }

            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'question'
            };

            const onConfirm = () => {
                this.$emit('delete-item', index, model);
            };

            App.alert().confirm(
                'Are you sure?',
                this.confirmDeleteMessage,
                attributes,
                onConfirm
            );
        },

        duplicateModel (model) {
            this.$emit('duplicate-item', model);
        },

        isDeletable (model) {
            return !!model.deletable || this.deletable;
        }
    }
};
</script>
