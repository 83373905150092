/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin-camera-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>photography</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 10.5h-.01c.2-.01.37.16.37.37M11.625 10.87l-.01-.01c-.01-.21.16-.38.37-.38v-.01M12 11.254h-.01a.386.386 0 01-.38-.38s0 0 0 0M12.37 10.87v0c0 .2-.17.375-.375.375"/><path pid="1" d="M16.5 9.754v0c0-.83-.68-1.5-1.5-1.5h-.75v-.37h0c-.17-.67-.77-1.14-1.45-1.14h-1.88c-.69 0-1 .46-1.18 1.13v.36h-.76c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v3h0c0 .82.67 1.49 1.5 1.49h6l-.01-.001c.82 0 1.5-.68 1.5-1.5z"/><path pid="2" d="M21 9.754c0 7.9-6.932 12.33-8.629 13.3v-.01a.77.77 0 01-.75 0c-1.7-.97-8.63-5.41-8.63-13.3h0a8.988 8.988 0 018.99-9.01c4.97-.01 9 4.02 9 8.99 0 0 0 0 0 0z"/></g>'
  }
})
