/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vip-crown-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M3 16.681h18l-.01-.001c.31 0 .63.06.92.19l1.32-9.951h-.01c.05-.42-.23-.8-.64-.85-.16-.03-.31 0-.45.07l-4.29 3.26h-.01c-.33.26-.8.21-1.06-.11-.02-.02-.03-.04-.04-.06L12.5 3.04l-.01-.01a.748.748 0 00-1.06-.08.27.27 0 00-.08.07L7.12 9.2v-.01c-.24.34-.71.42-1.05.19-.02-.02-.04-.03-.06-.04L1.72 6.08h0a.745.745 0 00-1.01.33.74.74 0 00-.08.44l1.32 9.951v-.01c.28-.14.6-.21.92-.21z"/><path pid="1" d="M21 16.681H2.99c-1.25 0-2.25 1-2.25 2.25 0 0 0 0 0 0v0h0c0 1.24 1 2.24 2.25 2.24h18l-.01-.001c1.24 0 2.25-1.01 2.25-2.25v0-.01c0-1.25-1.01-2.25-2.25-2.25h-.01z"/></g>'
  }
})
