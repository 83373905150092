/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'makeup-lipstick-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>beauty</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.71 12.751h8.2-.01c.27-.01.5.22.5.49v7.18c0 .55-.45 1-1 1H4.19c-.56-.01-1-.45-1-1.01V13.22h0c-.01-.28.22-.51.49-.51z" transform="matrix(.7071 .7071 -.70711 .7071 14.376 -.52)"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.977 8.79h6.15-.01c.27-.01.5.22.5.49v5.63h-7.16V9.28h0c-.01-.28.22-.51.49-.51z" transform="matrix(.7071 .7071 -.70711 .7071 12.206 -5.757)"/><path pid="2" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.7 7.82l-3.68 3.67-3.62-3.612 6.714-6.72h-.01c.19-.2.52-.2.72 0 .09.09.14.22.14.36v5.57h-.01c-.01.27-.11.52-.31.71z"/>'
  }
})
