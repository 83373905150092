<template>
    <div>
        <form-field-wrapper label="Icon">
            <icon-picker
                v-model="form.settings.icon"
                class="text-gray-800 w-11 h-11"
            ></icon-picker>
        </form-field-wrapper>

        <form-field-wrapper
            label="Title"
            :error="form.errors.get('title')"
            :should-show-error="form.errors.has('title')"
        >
            <input
                v-model="form.title"
                class="form-field"
                type="text"
                name="title"
            >
        </form-field-wrapper>

        <form-field-wrapper v-if="question.type.slug !== 'dropdown'" label="Description (optional)">
            <textarea
                v-model="form.settings.description"
                class="form-field"
                placeholder="Enter a description and details about this selection."
                rows="5"
            ></textarea>
        </form-field-wrapper>

        <form-panel
            v-if="shouldShowMoneyField"
            v-model="form.settings.costsMoney"
            label="Selection Requires Payment"
            tooltip="Choose to require a payment for this selection or option."
            :error="form.errors.get('price')"
            :should-show-error="form.errors.has('price')"
        >
            <div v-if="question.type.settings.optionsRequireMoney" slot="trigger">
                Amount
            </div>

            <money-field v-model="form.price"></money-field>
        </form-panel>

        <component
            :is="itemSettingsComponent"
            v-model="form.settings"
            :item="item"
            :form="form"
        ></component>

        <div v-if="supportsInvites">
            <form-panel
                v-model="form.settings.calendarInvites.enabled"
                class="mt-8"
                label="Include Calendar Invitation"
                tooltip="Set a time and date to associate with this selection, and automatically send guests calendar invitations with their confirmation emails."
            >
                <form-field-wrapper
                    :error="form.errors.get('settings.calendarInvites.type')"
                    :should-show-error="form.errors.has('settings.calendarInvites.type')"
                >
                    <select-list v-model="form.settings.calendarInvites.type">
                        <select-option
                            id="event"
                            class="my-2"
                            :disabled="disableDefaultSettingsCalendarInvitesOption"
                            label="Set As Primary Event Date/Time"
                            tooltip="This calendar invitation will use your primary event’s date and time settings (Setup > Event Settings > Event Details)."
                        ></select-option>
                        <select-option
                            id="custom"
                            class="my-2"
                        >
                            <div class="flex items-center">
                                <span v-tippy content="Use any date or time and customize all details included in the calendar invitation(s) guests receive.">Other Date&#47;Time</span>
                                <premium-feature feature="CalendarInvitations" class="ml-2"></premium-feature>
                            </div>
                        </select-option>
                    </select-list>
                </form-field-wrapper>

                <div v-if="displayCustomCalendarInvitesOptions" class="mt-8">
                    <form-field-wrapper
                        :error="form.errors.get('settings.calendarInvites.start')"
                        :should-show-error="form.errors.has('settings.calendarInvites.start')"
                        label="Start Date"
                    >
                        <date-time-picker
                            v-model="form.settings.calendarInvites.start"
                            title="Event Start Date"
                            has-time
                            class="w-full"
                            name="start-date"
                            placeholder="Select date and time..."
                        ></date-time-picker>
                    </form-field-wrapper>

                    <form-field-wrapper
                        class-wrapper="mt-2 mb-2"
                        :error="form.errors.get('settings.calendarInvites.end')"
                        :should-show-error="form.errors.has('settings.calendarInvites.end')"
                        label="End Date"
                    >
                        <date-time-picker
                            v-model="form.settings.calendarInvites.end"
                            title="Event End Date"
                            has-time
                            class="w-full"
                            name="end-date"
                            placeholder="Select date and time..."
                        ></date-time-picker>
                    </form-field-wrapper>

                    <form-field-wrapper
                        :error="form.errors.get('settings.calendarInvites.timezone')"
                        :should-show-error="form.errors.has('settings.calendarInvites.timezone')"
                        label="Timezone"
                    >
                        <timezone-picker
                            v-model="form.settings.calendarInvites.timezone"
                            name="timezone"
                        ></timezone-picker>
                    </form-field-wrapper>

                    <form-field-wrapper
                        label="Location"
                        :error="form.errors.get('settings.calendarInvites.location')"
                        :should-show-error="form.errors.has('settings.calendarInvites.location')"
                    >
                        <google-autocomplete-input
                            v-model="form.settings.calendarInvites.location"
                            placeholder="Start typing a location ..."
                            class="form-field"
                            name="location"
                        >
                        </google-autocomplete-input>
                    </form-field-wrapper>

                    <form-field-wrapper v-if="form.settings.calendarInvites.location">
                        <location-map
                            :address="form.settings.calendarInvites.location"
                            class="h-80"
                        ></location-map>
                    </form-field-wrapper>

                    <form-field-wrapper
                        label="Description"
                        :error="form.errors.get('settings.calendarInvites.description')"
                        :should-show-error="form.errors.has('settings.calendarInvites.description')"
                    >
                        <textarea
                            v-model="form.settings.calendarInvites.description"
                            class="form-field"
                            rows="5"
                            placeholder="Enter a description and details about this calendar invitation."
                            name="description"
                        ></textarea>
                    </form-field-wrapper>

                    <form-field-wrapper
                        label="Event Organizer"
                        :error="form.errors.get('settings.calendarInvites.organizer')"
                        :should-show-error="form.errors.has('settings.calendarInvites.organizer')"
                    >
                        <input
                            v-model="form.settings.calendarInvites.organizer"
                            class="form-field"
                            type="text"
                            name="event-organizer"
                        >
                    </form-field-wrapper>

                    <form-field-wrapper
                        label="Organizer's Email Address"
                        :error="form.errors.get('settings.calendarInvites.email')"
                        :should-show-error="form.errors.has('settings.calendarInvites.email')"
                    >
                        <input
                            v-model="form.settings.calendarInvites.email"
                            class="form-field"
                            type="email"
                            name="organizer-email"
                        >
                    </form-field-wrapper>
                </div>
            </form-panel>
        </div>

        <div v-if="supportsLimitedSelectableCount">
            <div class="mt-8">
                <form-panel
                    v-model="hasMinimum"
                    label="Minimum Required"
                    tooltip="Require a minimum number of this selection to be included per registration."
                    :error="form.errors.get('settings.minimumRequiredQuantity')"
                    :should-show-error="form.errors.has('settings.minimumRequiredQuantity')"
                >
                    <div class="flex items-end">
                        <input
                            :value="form.settings.minimumRequiredQuantity"
                            class="form-field w-32 mr-4"
                            type="number"
                            name="minimum"
                            step="1"
                            min="1"
                            @input="form.settings.minimumRequiredQuantity = Number($event.target.value)"
                        >
                        <div class="py-2 bold uppercase tracking-wide">
                            Minimum
                        </div>
                    </div>
                </form-panel>

                <form-panel
                    v-model="hasMaximum"
                    label="Maximum Selectable"
                    tooltip="Set the maximum number of this selection that a guest can select in a single registration."
                    :error="form.errors.get('settings.maximumSelectable')"
                    :should-show-error="form.errors.has('settings.maximumSelectable')"
                >
                    <div class="flex items-end">
                        <input
                            :value="form.settings.maximumSelectable"
                            class="form-field w-32 mr-4"
                            type="number"
                            name="maximum"
                            step="1"
                            min="1"
                            @input="form.settings.maximumSelectable = Number($event.target.value)"
                        >
                        <div class="py-2 bold uppercase tracking-wide">
                            Maximum
                        </div>
                    </div>
                </form-panel>
            </div>
        </div>

        <div v-if="supportsLimitedAvailability">
            <form-panel
                v-model="maxCapacitySettings.enabled"
                class="mt-8"
                label="Limited Number Available"
                tooltip="Choose to hide or disable this option or selection based on availability or capacity."
            >
                <template #trigger-appended>
                    <premium-feature feature="LimitedCapacity" class="ml-2"></premium-feature>
                </template>

                <form-field-wrapper
                    :error="form.errors.get('settings.maxCapacity.slots')"
                    :should-show-error="form.errors.has('settings.maxCapacity.slots')"
                >
                    <div class="flex items-end">
                        <input
                            v-model.number="maxCapacitySettings.slots"
                            class="form-field w-32 mr-4"
                            type="number"
                            name="number-available"
                            step="1"
                            min="1"
                        >
                        <div class="py-2 bold uppercase tracking-wide">
                            Available
                        </div>
                    </div>
                </form-field-wrapper>

                <form-field-wrapper v-if="supportsAvailabilityMessage" label="When no longer available...">
                    <select-list v-model="maxCapacitySettings.showMessage">
                        <select-option
                            :id="true"
                            label="Disable and display a message"
                            class="my-2"
                        ></select-option>
                        <select-option
                            :id="false"
                            label="Hide completely"
                            class="my-2"
                        ></select-option>
                    </select-list>
                </form-field-wrapper>

                <form-field-wrapper
                    v-if="supportsAvailabilityMessage && maxCapacitySettings.showMessage"
                    label="Message to display"
                    :error="form.errors.get('settings.maxCapacity.message')"
                    :should-show-error="form.errors.has('settings.maxCapacity.message')"
                >
                    <tiptap-editor
                        v-model="maxCapacitySettings.message"
                        simple
                        class="form-field"
                    ></tiptap-editor>
                </form-field-wrapper>
            </form-panel>
        </div>

        <portal to="app-drawer-footer">
            <footer-save-cancel
                :processing="loading"
                @cancel="$emit('cancel')"
                @save="save"
            ></footer-save-cancel>
        </portal>
    </div>
</template>

<script>
import Vue from 'vue';
import camelCase from 'lodash/camelCase';
import arrayGet from 'lodash/get';
import merge from 'lodash/merge';
import upperFirst from 'lodash/upperFirst';
import FormItemSettingsMixin from '@/mixins/FormItemSettingsMixin';

export default {
    name: 'FormQuestionBlockItemSettings',

    mixins: [FormItemSettingsMixin],

    props: {
        question: {
            type: Object,
            default: () => { return {}; }
        }
    },

    data () {
        return {
            hasMinimum: false,
            hasMaximum: false,
            maxCapacitySettings: {
                enabled: false,
                slots: 50,
                showMessage: true,
                message: 'No longer available.'
            }
        };
    },

    computed: {
        disableDefaultSettingsCalendarInvitesOption () {
            return !this.event.starts_at;
        },

        displayCustomCalendarInvitesOptions () {
            return this.form.settings.calendarInvites.type === 'custom';
        },

        itemSettingsComponent () {
            const componentName = `form-${this.question.type.slug}-question-item-settings`;

            if (upperFirst(camelCase(componentName)) in Vue.options.components) {
                return componentName;
            }

            return null;
        },

        shouldShowMoneyField () {
            return this.form.declineableItem !== true
                && this.form.customAmountItem !== true;
        },

        supportsInvites () {
            return this.question.type.slug !== 'cash-donation-gift' && this.form.type !== 'other';
        },

        supportsAvailabilityMessage () {
            return arrayGet(this.question, 'type.settings.canShowAvailabilityMessage', true);
        },

        supportsLimitedSelectableCount () {
            return arrayGet(this.question, 'type.settings.canLimitSelectableCount', false);
        },

        supportsLimitedAvailability () {
            return arrayGet(this.question, 'type.settings.canLimitAvailability', false);
        }
    },

    watch: {
        hasMinimum (newValue) {
            if (!newValue) {
                this.form.settings.minimumRequiredQuantity = null;
            }
        },

        hasMaximum (newValue) {
            if (!newValue) {
                this.form.settings.maximumSelectable = null;
            }
        },

        maxCapacitySettings (newValue) {
            this.form.settings.maxCapacity = newValue;
        },

        'form.settings.minimumRequiredQuantity': function (newValue) {
            if (!this.hasMaximum) {
                return;
            }

            if (newValue > this.form.settings.maximumSelectable) {
                this.form.settings.maximumSelectable = newValue;
            }
        },

        'form.settings.maximumSelectable': function (newValue) {
            if (!this.hasMinimum) {
                return;
            }

            if (newValue < this.form.settings.minimumRequiredQuantity) {
                this.form.settings.minimumRequiredQuantity = newValue;
            }
        }
    },

    mounted () {
        this.hasMinimum = this.form.settings.minimumRequiredQuantity > 0;
        this.hasMaximum = this.form.settings.maximumSelectable > 0;
        this.maxCapacitySettings = merge({
            enabled: false,
            slots: 50,
            showMessage: true,
            message: 'No longer available.'
        }, this.form.settings.maxCapacity || {});
    }
};
</script>
