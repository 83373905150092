/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-july4-fireworks-stars-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.03 1.07l1.24 2.45h2.12v-.01c.32-.01.58.24.59.57 0 .16-.07.31-.18.42L7.84 6.41l1.08 2.48h0c.12.3-.02.64-.32.77-.18.07-.37.05-.53-.04L5.45 8.14 2.83 9.61V9.6c-.29.16-.65.06-.81-.22a.585.585 0 01-.04-.53l1.08-2.49L1.1 4.43v-.01a.577.577 0 01-.01-.83.56.56 0 01.42-.18h2.12L4.87.95h-.01c.14-.3.5-.42.79-.27.11.05.2.14.26.26zM18.78 4.82l1.24 2.45h2.12v-.01c.32-.01.58.24.59.57 0 .16-.07.31-.18.42l-1.96 1.92 1.07 2.484h0c.12.3-.02.64-.32.77-.18.07-.37.05-.53-.04l-2.62-1.48-2.615 1.471v-.01c-.29.16-.65.06-.81-.22a.585.585 0 01-.04-.53l1.08-2.49L13.84 8.2l-.001-.001a.577.577 0 01-.01-.83.56.56 0 01.42-.18h2.12l1.24-2.457h-.01c.14-.3.5-.41.79-.27.11.05.2.15.26.26zM6.25 11.25h0c2.51 3.48 3.83 7.69 3.74 12M16 23.25l-.01-.01c-.1-2.83.41-5.64 1.5-8.25"/></g>'
  }
})
