<template>
    <nav
        id="in-event-sidebar-nav"
        class="flex flex-col fixed z-90 w-full md:w-72 transition-all duration-150 ease-in-out"
        :class="sidebarStyle"
        @mouseenter="mouseEnter"
        @mouseleave="mouseLeave"
    >
        <div class="relative flex items-center justify-center py-3 md:py-6 bg-black">
            <div
                v-tippy
                class="absolute top-0 right-0 w-4 h-10 bg-black text-white -mr-4 z-90 flex items-center justify-center rounded-br-lg cursor-pointer"
                :content="forceCollapse ? 'Expand' : 'Collapse'"
                @click="toggleCollapse"
            >
                <app-icon
                    class="w-3 h-3"
                    :name="forceCollapse ? 'arrow-right-chevron' : 'arrow-left-chevron'"
                    stroke
                ></app-icon>
            </div>

            <div class="w-1/5 flex items-center">
                <button
                    v-if="showBackButton"
                    class="ml-4"
                    onclick="window.history.go(-1); return false;"
                >
                    <app-icon
                        name="arrow-left-chevron"
                        class="h-7 w-7 cursor-pointer text-white"
                        stroke
                    ></app-icon>
                </button>
            </div>

            <a :href="route('dashboard')" class="w-3/5 flex items-center justify-center">
                <logo-rsvpify-full
                    class="h-10 w-auto"
                    text="white"
                    :show-text="!collapsed"
                ></logo-rsvpify-full>
            </a>

            <div class="w-1/5 flex items-center justify-end">
                <button
                    v-if="showMobileIcons"
                    class="mr-4"
                    @click="toggleMenu"
                >
                    <app-icon :name="toggleMenuIcon" class="h-7 w-7 text-white cursor-pointer"></app-icon>
                </button>
            </div>
        </div>

        <transition :name="sidebarTransition">
            <event-nav
                v-if="showMenu"
                :events="events"
                :number-of-used-events="numberOfUsedEvents"
                :user="user"
                :collapsed="collapsed"
                class="bg-black w-72 md:w-full overflow-hidden"
            ></event-nav>
        </transition>
    </nav>
</template>

<script>
import debounce from 'lodash/debounce';
import ListensToWindowResizeEvent from '@/mixins/ListensToWindowResizeEvent';

export default {
    name: 'InEventSidebarNav',

    mixins: [ListensToWindowResizeEvent],

    props: {
        events: {
            type: Array,
            required: true
        },

        numberOfUsedEvents: {
            type: Number,
            required: true
        },

        user: {
            type: Object,
            required: true
        }
    },

    data () {
        const forceCollapse = (JSON.parse(localStorage.getItem('forceNavCollapse')) || false);

        document.body.classList.toggle('nav-collapsed', forceCollapse);

        return {
            collapsed: forceCollapse,
            forceCollapse,
            showMenu: false,
            sidebarStyle: {},
            sidebarTransition: 'slide-right'
        };
    },

    computed: {
        showBackButton () {
            return this.showMobileIcons && !this.showMenu;
        },

        showMobileIcons () {
            return this.screenWidthIs('lt', 'md');
        },

        toggleMenuIcon () {
            return this.showMenu ? 'close' : 'navigation-menu';
        }
    },

    watch: {
        screenWidth () {
            this.showMenu = !this.screenWidthIs('lt', 'md');
        },

        showMenu: debounce(function (newVal) {
            this.sidebarTransition = newVal ? 'slide-left' : 'slide-right';
            this.sidebarStyle = newVal ? 'h-full bg-smoke-400' : '';
        }, 400),

        collapsed (newVal) {
            document.body.classList.toggle('nav-collapsed', newVal);
        },

        forceCollapse (newVal) {
            localStorage.setItem('forceNavCollapse', JSON.stringify(newVal));
        }
    },

    created () {
        this.showMenu = !this.screenWidthIs('lt', 'md');
    },

    methods: {
        toggleCollapse () {
            this.forceCollapse = !this.forceCollapse;
            this.collapsed = this.forceCollapse;
        },

        toggleMenu () {
            this.showMenu = !this.showMenu;
        },

        mouseEnter () {
            if (this.forceCollapse) {
                this.collapsed = false;
            }
        },

        mouseLeave () {
            if (this.forceCollapse) {
                this.collapsed = true;
            }
        }
    }
};
</script>
