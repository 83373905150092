<template>
    <div>
        <div class="relative">
            <input
                ref="searchInput"
                :value="value"
                type="text"
                class="form-field px-10"
                :class="styles.input"
                :placeholder="placeholder"
                :disabled="loading"
                v-bind="$attrs"
                @input="event => $emit('input', event.target.value)"
                @keyup.enter="$emit('keyup-enter')"
            >

            <div class="absolute inset-y-0 pointer-events-none flex items-center pl-2 pr-4 text-gray-500">
                <app-icon
                    :class="styles.icon"
                    name="search"
                    stroke
                ></app-icon>
            </div>

            <button
                v-if="isClearButtonVisible"
                class="absolute inset-y-0 right-0 flex items-center pl-4 pr-2 button-icon button-sm"
                @click="$emit('input', '')"
            >
                <app-icon :class="styles.icon" name="close"></app-icon>
            </button>

            <div v-if="loading" class="absolute inset-y-0 right-0 flex items-center pl-4 pr-3 text-gray-400 hover:text-gray-500">
                <app-icon
                    class="spinning"
                    :class="styles.icon"
                    name="loading-half"
                ></app-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchField',

    inheritAttrs: false,

    props: {
        loading: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Search...'
        },
        small: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: ''
        }
    },

    computed: {
        isClearButtonVisible () {
            if (this.loading) {
                return false;
            }

            return !!this.value;
        },

        styles () {
            return {
                input: {
                    'h-8': this.small,
                    'h-11': !this.small
                },
                icon: {
                    'w-4 h-4': this.small,
                    'w-5 h-5': !this.small
                }
            };
        }
    },

    methods: {
        focus () {
            this.$refs.searchInput.focus();
        }
    }
};
</script>
