/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin-map-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 6.309c0 3.07-5.25 7.941-5.25 7.941S6.75 9.37 6.75 6.309l-.01-.01A5.408 5.408 0 0111.99.73h-.01a5.39 5.39 0 015.24 5.55z"/><path pid="1" d="M20.25 5.25h1.27c.53 0 .97.43.97.97v16.04-.01c-.01.53-.44.97-.98.97H2.46l-.01-.001a.988.988 0 01-.98-.98V6.18h-.01c0-.54.43-.98.97-.98H3.7"/></g>'
  }
})
