/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin-target-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>marker gps</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 6.75a4.5 4.5 0 100 9 4.5 4.5 0 100-9zM12 5.25v3M6 11.25h3M12 17.25v-3M18 11.25h-3"/><path pid="1" d="M21 9.754c0 7.9-6.932 12.33-8.629 13.3v-.01a.77.77 0 01-.75 0c-1.7-.97-8.63-5.41-8.63-13.3h0a8.988 8.988 0 018.99-9.01c4.97-.01 9 4.02 9 8.99 0 0 0 0 0 0z"/></g>'
  }
})
