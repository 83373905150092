/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'animal-dog-head-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>puppy</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.086 2.91V2.9c2.58-2.72 6.88-2.82 9.59-.24l.1.1M20.05 13.5c-.69 5.15-3.21 9.75-8.054 9.75-4.85 0-7.37-4.6-8.054-9.75"/><path pid="1" d="M7.07 3.99v-.01c.3-1.21-.43-2.43-1.64-2.74-.35-.09-.71-.1-1.05-.02h-.01a4.85 4.85 0 00-3.15 2.42c-1.13 1.87-.18 4.359.81 6.11l-.01-.01c.4.72 1.31.98 2.04.58.25-.15.46-.36.59-.62v-.01c.99-1.84 1.78-3.76 2.37-5.76zM16.764 3.99h0a2.25 2.25 0 011.63-2.74c.34-.09.7-.1 1.05-.02h0c1.34.32 2.48 1.2 3.13 2.42 1.12 1.87.17 4.35-.81 6.111v-.01c-.41.72-1.32.98-2.05.58-.26-.15-.47-.36-.6-.62l-.01-.01c-1-1.84-1.79-3.76-2.38-5.76zM12 15a2.25 1.5 0 100 3 2.25 1.5 0 100-3zM7.889 21.849C10.329 21.319 12 18 12 18s1.66 3.32 4.11 3.849M8.625 9.75h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M8.25 10.125h0c-.01-.21.16-.38.37-.38M8.625 10.5h-.01a.386.386 0 01-.38-.38s0 0 0 0M9 10.125v0c0 .2-.17.375-.375.375M15.37 9.75h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M15 10.125h0c-.01-.21.16-.38.37-.38M15.37 10.5h-.01a.386.386 0 01-.38-.38v-.001M15.75 10.125v0c0 .2-.17.375-.375.375"/></g>'
  }
})
