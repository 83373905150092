/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-dance-man-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.75 12v-.01a9.289 9.289 0 004.06-2.37v-.01c.58-.59.58-1.54-.01-2.13-.59-.59-1.54-.59-2.13 0v-.01a6.303 6.303 0 01-4.38 1.81h-.39l-.01-.001a6.27 6.27 0 01-4.38-1.82h0c-.59-.59-1.54-.59-2.13-.01a1.5 1.5 0 00-.01 2.12h0a9.159 9.159 0 004.06 2.36v2.55l-2.5 1.782h-.01c-.79.56-1.26 1.47-1.26 2.44v2.97h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51v-2.98l3-2.15v1.5h0c0 .79.31 1.55.87 2.12l2.56 2.561-.01-.01c.58.58 1.53.58 2.12 0 .58-.59.58-1.54 0-2.122l-.001-.001-2.561-2.56zM16.5 1.12a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM2.25 5.25a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/><path pid="1" d="M4.886.75h-.01c-.67.16-1.14.76-1.14 1.45v4.54M5.25 14.25a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM6.75 12v3.75"/></g>'
  }
})
