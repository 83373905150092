export function wrap (arr) {
    return Array.isArray(arr) ? arr : [arr];
}

export function insertIf (condition, elements) {
    return condition ? wrap(elements) : [];
}

export function insertUnless (condition, elements) {
    return insertIf(!condition, elements);
}
