/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-pin-star-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>favorite like</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.564 6.272h-.01c.12-.25.41-.34.65-.22.09.04.16.12.21.21l1.01 2.042-.01-.01c.07.14.2.24.36.26l2.27.32h0c.18.02.33.15.39.32h0c.05.17 0 .36-.13.48l-1.65 1.6h-.01c-.12.1-.17.26-.14.42l.38 2.26-.01-.01c.03.18-.05.36-.2.46v-.01c-.16.1-.35.12-.52.03l-2.03-1.06h0a.5.5 0 00-.46-.01L9.63 14.4h-.01a.51.51 0 01-.52-.04h0a.487.487 0 01-.2-.47l.38-2.26v-.01a.516.516 0 00-.14-.43l-1.65-1.6-.01-.01a.478.478 0 01-.13-.49v-.01c.05-.18.2-.31.39-.34l2.271-.33V8.4c.15-.03.29-.13.36-.27z"/><path pid="1" d="M21 9.754c0 7.9-6.932 12.33-8.629 13.3v-.01a.77.77 0 01-.75 0c-1.7-.97-8.63-5.41-8.63-13.3h0a8.988 8.988 0 018.99-9.01c4.97-.01 9 4.02 9 8.99 0 0 0 0 0 0z"/></g>'
  }
})
