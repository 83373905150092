<template>
    <div>
        <portal to="rsvp-form-target">
            <existing-submission-screen
                :existing-submission="existingSubmission"
                @close="existingSubmission = null"
                @submit-block="continueWithNewSubmission"
            ></existing-submission-screen>
        </portal>

        <div v-theme="['form.text']">
            <div
                v-if="!!chosenProducts"
                v-theme="'form.background'"
                class="px-4 py-8 w-full rounded-md relative transition-all"
                :class="{ 'cursor-pointer': !isActive }"
            >
                <slot v-if="!isEventWithInvitees" name="block-header"></slot>

                <div
                    v-theme="['form.title-text', 'form.accent']"
                    class="text-center text-2xl mt-8 mb-4 border-b pb-4 font-semibold"
                >
                    {{ block.pivot.settings.title }}
                </div>

                <div
                    v-if="block.pivot.settings.description && (event.published_at === null || new Date(event.published_at) > Date.UTC(2024, 0, 12, 19))"
                    v-theme="['form.text']"
                    class="mb-2 break-words"
                >
                    {{ block.pivot.settings.description }}
                </div>

                <form-field-wrapper :error="errorBag.get('payload.products')">
                    <div
                        v-for="product in activeProducts"
                        :key="product.id"
                        class="mb-6"
                    >
                        <div v-if="product.short_guest_state === 'NotAttending'" class="flex items-start">
                            <div v-theme="['form.accent']" class="flex">
                                <app-icon
                                    v-if="product.settings.icon"
                                    :name="product.settings.icon"
                                    class="shrink-0 opacity-50 h-6 w-6 mr-4"
                                ></app-icon>
                                <div v-else class="h-6 w-6 mr-4"></div>
                            </div>

                            <div v-theme="['form.text']" class="flex-1">
                                <div class="mb-1">
                                    <div class="font-semibold">
                                        {{ product.title }}
                                    </div>
                                </div>

                                <div class="opacity-75 text-sm break-word">
                                    {{ product.settings.description }}
                                </div>
                            </div>

                            <div>
                                <div
                                    role="button"
                                    class="flex items-start leading-normal"
                                    @click="toggleDecline(product)"
                                >
                                    <div
                                        v-theme="['form.accent']"
                                        class="rounded-md flex shrink-0 justify-center items-center h-8 w-8 mr-4 border-2"
                                    >
                                        <div
                                            v-if="!!getProductQuantity(product)"
                                            class="rounded-sm h-5 w-5 option-selected"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <quantity-input
                            v-else
                            :event="event"
                            :value="getProductQuantity(product)"
                            :item="product"
                            :is-active="isActive"
                            :editable="isEditMode"
                            :disabled-increment="!isProductSelectable(product)"
                            @input="setProductQuantity(product, $event)"
                            @complete-block="completeBlock"
                        ></quantity-input>

                        <product-guests
                            v-model="chosenProducts[product.id].guests"
                            :quantity="getProductQuantity(product)"
                            :product="product"
                            :require-guest-title="block.pivot.settings.guestTitles.enabled"
                            :error-bag="errorBag.only(`payload.products.${product.id}`)"
                        ></product-guests>
                    </div>
                </form-field-wrapper>
            </div>

            <div
                v-if="showPrimaryGuestDetailsBlock"
                v-theme="'form.background'"
                class="my-8 px-4 py-8 w-full rounded-md relative transition-all"
            >
                <guest-details
                    v-model="primaryGuest"
                    :error="errorBag.only('payload.primaryGuest').all()[0]"
                    :require-name="!hasGuestWithName"
                    :require-title="block.pivot.settings.guestTitles.enabled"
                    :require-email="!hasGuestWithEmail"
                ></guest-details>
            </div>

            <slot name="block-footer" :complete-block="completeBlock"></slot>
        </div>
    </div>
</template>

<script>
import find from 'lodash/find';
import filter from 'lodash/filter';
import OpenTicketingBlock from '../ticketing/OpenTicketingBlock';

export default {
    name: 'OpenRsvpBlock',

    extends: OpenTicketingBlock,

    computed: {
        notAttendingProductSelected () {
            return !!this.getProductQuantity(find(this.products, { short_guest_state: 'NotAttending' }));
        }
    },

    methods: {
        toggleDecline (product) {
            if (!this.getProductQuantity(product)) {
                this.clearChosenProducts();
                this.setProductQuantity(product, 1);
                return;
            }

            this.setProductQuantity(product, 0);
        },

        isProductSelectable (product) {
            if (this.notAttendingProductSelected) {
                return false;
            }

            if (product.short_guest_state !== 'Attending') {
                return true;
            }

            if (!this.block.pivot.settings.limitedAttendancePerGroup.enabled) {
                return true;
            }

            const totalAttendees = filter(this.products, { short_guest_state: 'Attending' })
                .reduce((accumulator, { id }) => {
                    return accumulator + this.chosenProducts[id].quantity;
                }, 0);

            return totalAttendees < this.block.pivot.settings.limitedAttendancePerGroup.limit;
        }
    }
};
</script>
