<template>
    <div class="w-full p-4 tracking-wider ring-2 ring-transparent">
        <h2 class="text-xl font-bold text-gray-900 ">{{ plan.name }}</h2>

        <h3 v-if="subscription && subscription.ends_at === null" class="text-gray-600">
            <span class="font-bold">{{ plan.price | currency(true) }}</span>
            <span v-if="plan.humanBillingPeriod" class="text-gray-500">every {{ plan.humanBillingPeriod }}</span>
        </h3>

        <p v-if="planOverrideEndsAt !== null" class="text-xs mt-1">
            <template v-if="planOverrideRenews === true">
                Renews {{ planOverrideEndsAt | datetime('DATE_FULL', true) }}
            </template>

            <template v-else>
                Ends {{ planOverrideEndsAt | datetime('DATE_FULL', true) }}
            </template>
        </p>

        <template v-if="planOverrideEndsAt === null">
            <p class="text-xs mt-1">
                <template v-if="subscription">
                    <template v-if="subscription.ends_at">
                        Ends {{ subscription.ends_at | datetime('DATE_FULL') }}
                    </template>

                    <template v-else-if="subscription.ends_at === null">
                        Renews {{ billingPeriod.end | datetime('DATE_FULL') }}
                    </template>
                </template>

                <template v-else>
                    You have no active paid subscription.
                </template>
            </p>
        </template>
    </div>
</template>

<script>
export default {
    name: 'AccountPlan',

    props: {
        billingPeriod: {
            type: Object,
            required: true
        },

        onGracePeriod: {
            type: Boolean,
            required: true
        },

        plan: {
            type: Object,
            required: true
        },

        planOverrideEndsAt: {
            type: String,
            default: null
        },

        planOverrideRenews: {
            type: Boolean,
            default: null
        },

        planSegment: {
            type: Object,
            default: null
        },

        subscription: {
            type: Object,
            default: null
        }
    }
};
</script>
