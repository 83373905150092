/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-dollar-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>shopping money usd</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.75 11.758l-2.32-2.32h0a1.3 1.3 0 00-1.83-.15c-.06.04-.11.09-.15.14-1.08 1.07 1.4 5.2.31 6.282v-.01c-.53.54-1.39.55-1.94.03l-.04-.04-2.32-2.32M14.1 10.11l1.24-1.24M7.91 16.3l1.24-1.24M18.24 4a1.44 1.44 0 100 2.89 1.44 1.44 0 100-2.89z"/><path pid="1" d="M21.705.75h-9.38l-.01-.001c-.43-.01-.84.17-1.13.48L1.14 11.89h-.01c-.59.61-.56 1.58.06 2.17l.04.04 10.03 8.749-.01-.01c.62.54 1.57.49 2.14-.11l9.37-10.017h-.01c.26-.29.41-.67.41-1.06V2.25h0c-.01-.86-.7-1.55-1.55-1.55h-.01z"/></g>'
  }
})
