<template>
    <multi-page-item-settings
        :items-array="form.meals"
        :settings="settings"
        :item-creator-function="createItem"
        :default-close-handler="defaultCloseHandler"
    >
        <template slot-scope="{ edit, save, close, create, restore, selectedItem }">
            <form-meal-block-general-settings
                key="general-settings"
                :event="event"
                :form="form"
                @edit-item="edit"
                @create-item="create"
                @update-meals="handleMealsUpdate"
            ></form-meal-block-general-settings>

            <form-meal-block-item-settings
                key="item-settings"
                :event="event"
                :block="block"
                :item="selectedItem"
                @save-settings="save"
                @cancel="restore"
            ></form-meal-block-item-settings>
        </template>
    </multi-page-item-settings>
</template>

<script>
import Form from '@/validation/Form';
import HasDefaultCloseHandler from '@/mixins/HasDefaultCloseHandler';
import BlockableSettingsMixin from '@/mixins/BlockableSettingsMixin';

export default {
    mixins: [HasDefaultCloseHandler, BlockableSettingsMixin],

    data () {
        return {
            form: new Form(
                Object.assign(this.settings, { meals: this.block.pivot.meals })
            )
        };
    },

    methods: {
        createItem () {
            return {
                new: true,
                title: '',
                price: 0,
                active: true,
                sort: this.form.meals.length + 1,
                settings: {
                    costsMoney: false,
                    icon: 'food-eating-set-stroke',
                    description: '',
                    maxCapacity: {
                        enabled: false,
                        slots: 50,
                        showMessage: true,
                        message: 'Sorry, meal sold out!'
                    }
                }
            };
        },

        handleMealsUpdate (updatedMeals) {
            this.$set(this.block.pivot, 'meals', updatedMeals);
        }
    }
};
</script>
