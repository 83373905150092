<template>
    <multi-page-item-settings
        :items-array="form.products"
        :settings="settings"
        :default-close-handler="defaultCloseHandler"
    >
        <template slot-scope="{ edit, save, restore, selectedItem }">
            <form-secondary-event-block-general-settings
                key="general-settings"
                :block="block"
                :form="form"
                @edit-item="edit"
            ></form-secondary-event-block-general-settings>

            <form-product-item-settings
                key="item-settings"
                use-guest-attending-titles
                :event="event"
                :block="block"
                :item="selectedItem"
                @save-settings="save"
                @cancel="restore"
            ></form-product-item-settings>
        </template>
    </multi-page-item-settings>
</template>

<script>
import Form from '@/validation/Form';
import HasDefaultCloseHandler from '@/mixins/HasDefaultCloseHandler';
import BlockableSettingsMixin from '@/mixins/BlockableSettingsMixin';

export default {
    name: 'FormSecondaryEventBlockSettings',

    mixins: [HasDefaultCloseHandler, BlockableSettingsMixin],

    data () {
        return {
            form: new Form(
                Object.assign(this.settings, { products: this.block.pivot.products })
            )
        };
    }
};
</script>
