/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-add': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>event date time plus create new appointment</desc><g _fill="currentColor"><path pid="0" d="M21.5 3h-2.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v4.75a.75.75 0 11-1.5 0V3.5a.5.5 0 00-.5-.5H8.25A.25.25 0 018 2.751V1a1 1 0 10-2 0v4.75a.75.75 0 11-1.5 0V3.5A.5.5 0 004 3H2.5a2 2 0 00-2 2v17a2 2 0 002 2h19a2 2 0 002-2V5a2 2 0 00-2-2zm0 18.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-12A.5.5 0 013 9h18a.5.5 0 01.5.5z"/><path pid="1" d="M12 10.5a5 5 0 105 5 5.006 5.006 0 00-5-5zm2 5.75h-1a.25.25 0 00-.25.25v1a.75.75 0 01-1.5 0v-1a.25.25 0 00-.25-.25h-1a.75.75 0 010-1.5h1a.25.25 0 00.25-.25v-1a.75.75 0 111.5 0v1a.25.25 0 00.25.25h1a.75.75 0 010 1.5z"/></g>'
  }
})
