<template>
    <base-dropdown
        v-if="hasDropdown"
        placement="bottom-end"
    >
        <template slot="trigger" slot-scope="{ triggerFunction }">
            <a
                v-tippy
                role="button"
                class="flex items-center justify-center h-8 w-8 p-1 leading-0 rounded-full border-2 border-white text-white bg-purple transition duration-150 ease-in-out cursor-pointer hover:bg-purple-light"
                content="Settings"
                @click="triggerFunction"
            >
                <app-icon
                    name="settings-cog"
                    :class="iconClasses"
                ></app-icon>
            </a>
        </template>

        <template #default="{ triggerFunction }">
            <div class="bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden mt-1 flex flex-col">
                <toggle-switch
                    v-if="block.properties.toggleable"
                    v-model="block.pivot.settings.isDisplayed"
                    class="font-semibold p-4"
                >
                    Visible
                </toggle-switch>

                <button
                    v-if="showSettingsMenuOption"
                    class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                    @click="openSettings(triggerFunction)"
                >
                    <app-icon
                        name="settings-cog"
                        class="h-4 w-4 mr-3"
                        stroke
                    ></app-icon>
                    Settings
                </button>

                <button
                    v-if="block.properties.cloneable"
                    class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                    @click="$emit('duplicate-block')"
                >
                    <app-icon
                        name="duplicate-square"
                        class="h-4 w-4 mr-3"
                        stroke
                    ></app-icon>
                    Duplicate
                </button>

                <button
                    v-if="block.properties.deletable"
                    class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-red hover:bg-gray-100"
                    @click="$emit('delete-block')"
                >
                    <app-icon
                        name="trash"
                        class="h-4 w-4 mr-3"
                        stroke
                    ></app-icon>
                    Remove
                </button>
            </div>
        </template>
    </base-dropdown>

    <a
        v-else
        v-tippy
        role="button"
        class="flex items-center justify-center h-8 w-8 block p-1 leading-0 rounded-full border-2 border-white text-white bg-purple transition duration-150 ease-in-out cursor-pointer hover:bg-purple-light"
        content="Settings"
        @click="openSettings"
    >
        <app-icon
            name="settings-cog"
            :class="iconClasses"
        ></app-icon>
    </a>
</template>

<script>
import isFunction from 'lodash/isFunction';

export default {
    name: 'FormBuilderSettingsButton',

    props: {
        block: {
            type: Object,
            default: () => { return {}; }
        },
        isEventTemplate: {
            type: Boolean,
            default: false
        },
        iconClasses: {
            type: [Array, String, Object],
            default: 'h-4 w-4'
        }
    },

    computed: {
        hasDropdown () {
            return this.block.properties.deletable
                || this.block.properties.cloneable
                || this.block.properties.toggleable;
        },

        showSettingsMenuOption () {
            return (this.block.pivot.settings && !this.block.properties.hideSettings)
                || this.isEventTemplate;
        }
    },

    methods: {
        openSettings (triggerFunction = undefined) {
            if (isFunction(triggerFunction)) {
                triggerFunction();
            }

            this.$emit('click');
            App.$emit('select-block', { block: this.block });
        }
    }
};
</script>
