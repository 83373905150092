import { render, staticRenderFns } from "./ExportButton.vue?vue&type=template&id=0a47b8ef"
import script from "./ExportButton.vue?vue&type=script&lang=js"
export * from "./ExportButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports