/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-star-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>event date time appointment favorite</desc><g stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="22.5" height="19.5" x=".75" y="3.75" rx="1.5"/><path pid="1" d="M.75 9.75h22.5"/><g stroke-linecap="round"><path pid="2" d="M6.75 6V.75M17.25 6V.75M11.564 12.682h-.01c.12-.25.41-.34.65-.22.09.04.16.12.21.21l1.01 2.042h0c.07.14.2.23.36.26l2.27.32h0c.18.02.33.15.39.32h0c.05.17 0 .36-.13.48l-1.65 1.6h-.01c-.12.1-.17.26-.14.42l.38 2.25-.01-.01c.03.18-.05.36-.2.46v-.01c-.16.1-.35.12-.52.03l-2.03-1.06h0a.5.5 0 00-.46-.01l-2.03 1.05v-.01a.51.51 0 01-.52-.04l-.01-.01a.487.487 0 01-.2-.47l.38-2.26v-.01a.516.516 0 00-.14-.43l-1.65-1.6-.01-.01a.478.478 0 01-.13-.49h-.01c.05-.18.2-.3.38-.33l2.27-.33h-.01c.15-.03.29-.12.36-.27z"/></g></g>'
  }
})
