/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-road-trip-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>marker gps</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.75 4.875h-.01c.2-.01.37.16.37.37"/><path pid="1" d="M18.375 5.25h0c-.01-.21.16-.38.37-.38M18.75 5.62h-.01a.386.386 0 01-.38-.38s0 0 0 0M19.125 5.25v0c0 .2-.17.375-.375.375"/><path pid="2" d="M18.75.75h-.01c2.48-.01 4.5 2.01 4.5 4.49 0 1.92-2.69 5.57-3.91 7.13v-.01a.759.759 0 01-1.19-.01c-1.23-1.57-3.91-5.22-3.91-7.14h0A4.494 4.494 0 0118.72.7zM.75 23.25l6-15M21.75 23.25l-3-7.5M11.25 23.25v-1.5M11.25 17.25v-1.5M11.25 11.25v-1.5"/></g>'
  }
})
