<template>
    <div class="flex flex-col w-full">
        <div v-show="unseatingViaDragAndDrop" class="h-full flex flex-col items-center justify-center alert alert-warning">
            <app-icon name="rotate-back" class="w-16 h-16"></app-icon>
            <p class="text-2xl mt-2">Unseat</p>
        </div>

        <div v-show="!unseatingViaDragAndDrop">
            <search-field
                v-model="seatablesQuery.searchTerm"
                class="w-2/3"
                placeholder="Search unseated guests..."
            ></search-field>

            <div class="my-4">
                <dashboard-predicate-list v-model="seatablesQuery.predicates" :blockable-id="secondaryEventBlockableId"></dashboard-predicate-list>
            </div>

            <div class="w-full">
                <div class="flex items-center p-4 border-b">
                    <div class="flex-auto uppercase tracking-wide text-sm font-semibold text-gray-600">Guests</div>

                    <button
                        v-tippy
                        class="flex h-auto items-center rounded-md border p-2 mx-2"
                        :class="headerButtonStyle.sorted"
                        content="Change default sorting."
                        @click="changeSort()"
                    >
                        <app-icon
                            name="arrange-letter-stroke"
                            class="w-5 h-5"
                            stroke
                        ></app-icon>
                    </button>

                    <button
                        v-tippy
                        class="flex h-auto items-center rounded-md border p-2 mx-2"
                        :class="headerButtonStyle.grouped"
                        content="Group or ungroup guests."
                        @click="grouped = !grouped"
                    >
                        <app-icon
                            name="team"
                            class="w-5 h-5"
                            stroke
                        ></app-icon>
                    </button>

                    <button
                        v-tippy
                        class="flex h-auto items-center rounded-md border p-2 mx-2"
                        :class="headerButtonStyle.includeSeated"
                        content="Include or exclude already seated guests."
                        @click="includeSeated = !includeSeated"
                    >
                        <app-icon
                            name="chair"
                            class="w-5 h-5"
                        ></app-icon>
                    </button>
                </div>

                <div v-if="loadingSeatables" class="text-center my-4">
                    <app-icon class="h-6 w-6 text-gray-500" name="loader"></app-icon>
                </div>

                <div v-else class="max-h-screen-64 overflow-y-auto select-none">
                    <div
                        v-for="(seatableGroup, index) in seatableGroups"
                        :key="index"
                        class="mb-2"
                    >
                        <draggable
                            :value="getSortableSeatables(index)"
                            :animation="150"
                            :disabled="saving"
                            :group="{ name: 'seatables', pull: 'clone', put: true }"
                            ghost-class="seatable-ghost"
                            chosen-class="seatable-clone"
                            drag-class="seatable-clone"
                            handle=".seatable"
                            :move="moveSeatable"
                            :remove-clone-on-hide="false"
                            :delay="500"
                            :delay-on-touch-only="true"
                            @end="endDraggable"
                        >
                            <div
                                v-for="seatable in seatableGroup"
                                :key="getSeatableKey(seatable)"
                                class="bg-white border-l-2 border-gray-500"
                                :class="seatableRowStyle(seatable)"
                            >
                                <div v-if="isSeatActionInProgress(seatable)" class="flex items-center justify-center p-3">
                                    <app-icon name="loader" class="w-6 h-6 text-purple"></app-icon>
                                </div>

                                <div v-else>
                                    <div
                                        v-if="isSeatableFocused(seatable)"
                                        class="flex w-full p-3 border-b border-gray-100"
                                    >
                                        <button class="flex items-center justify-center hover:cursor-pointer" @click="launchDetailsModal(seatable)">
                                            <guest-reply-icon
                                                v-if="seatable.reply"
                                                :state="seatable.reply.guest_state"
                                                :colored="!seatable.seating_area_id"
                                            ></guest-reply-icon>

                                            <app-icon
                                                v-else
                                                name="question-circle"
                                                stroke
                                            ></app-icon>
                                        </button>

                                        <div
                                            class="flex w-full"
                                            @click="prepareAndUnseat(seatable)"
                                            @mouseleave="focusedRow = null"
                                        >
                                            <button v-if="getSeatableSeatArea(seatable)" class="flex items-center justify-center w-full text-yellow">
                                                <span class="font-semibold">Unseat from</span>
                                                <span class="italic mx-2">{{ getSeatableSeatArea(seatable).name }}</span>
                                                <app-icon
                                                    name="rotate-back"
                                                    class="w-4 h-4"
                                                    stroke
                                                ></app-icon>
                                            </button>

                                            <div v-else class="flex items-center justify-center w-full text-green-500">
                                                <span class="font-semibold">Unseated</span>
                                                <app-icon
                                                    name="check-circle"
                                                    class="w-4 h-4 ml-2"
                                                    stroke
                                                ></app-icon>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-else
                                        class="flex items-center space-x-3 p-3 border-b border-gray-100 droppable"
                                    >
                                        <button class="flex items-center justify-center hover:cursor-pointer" @click="launchDetailsModal(seatable)">
                                            <guest-reply-icon
                                                v-if="seatable.reply"
                                                :state="seatable.reply.guest_state"
                                                :colored="!seatable.seating_area_id"
                                            ></guest-reply-icon>

                                            <app-icon
                                                v-else
                                                name="question-circle"
                                                stroke
                                            ></app-icon>
                                        </button>

                                        <div
                                            class="w-full"
                                            :class="{'seatable' : isEligibleForSeating(seatable)}"
                                            @mouseover="focusSeatable(seatable)"
                                            @mouseleave="focusedRow = null"
                                        >
                                            {{ getSeatableIdentificator(seatable) }}
                                            <p v-if="seatable.parentInviteeFirstName || seatable.parentInviteeLastName" class="text-xs">
                                                Guest of {{ seatable.parentInviteeFirstName }} {{ seatable.parentInviteeLastName }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </draggable>
                    </div>
                </div>
            </div>
        </div>
        <seatable-details-modal v-model="showSeatableDetailsModal" :seatable="selectedSeatable"></seatable-details-modal>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import groupBy from 'lodash/groupBy';
import debounce from 'lodash/debounce';
import { call, get } from 'vuex-pathify';
import { get as lodashGet, isEmpty, trim } from 'lodash';
import MovesSeatables from './Mixins/MovesSeatables';

export default {
    name: 'SeatableDataTable',

    components: { draggable },

    mixins: [MovesSeatables],

    data () {
        return {
            focusedRow: null,
            grouped: true,
            includeSeated: true,
            showSeatableDetailsModal: false,
            selectedSeatable: {}
        };
    },

    computed: {
        ...get('Seating/*'),
        ...get('Event/*'),

        headerButtonStyle () {
            return {
                grouped: {
                    'bg-purple text-white border-purple hover:cursor-pointer': this.grouped,
                    'text-gray-600 border-gray-500 hover:cursor-pointer': !this.grouped
                },

                sorted: {
                    'bg-purple text-white border-purple hover:cursor-pointer': this.seatablesQuery.sort,
                    'text-gray-600 border-gray-500 hover:cursor-pointer': !this.seatablesQuery.sort
                },

                includeSeated: {
                    'bg-purple text-white border-purple hover:cursor-pointer': this.includeSeated,
                    'text-gray-600 border-gray-500 hover:cursor-pointer': !this.includeSeated
                }
            };
        },

        seatableGroups () {
            if (!this.grouped) {
                return [this.seatablesToDisplay];
            }

            return groupBy(this.seatablesToDisplay, (seatable) => {
                return `${seatable.type}-${seatable.group_id}`;
            });
        },

        seatablesToDisplay () {
            if (this.includeSeated) {
                return this.filteredSeatables;
            }

            return this.filteredSeatables.filter((seatable) => { return !seatable.seating_area_id; });
        },

        secondaryEventBlockableId () {
            return lodashGet(this.seatingChart, 'audience.target') === 'secondary-event'
                ? lodashGet(this.seatingChart, 'audience.id')
                : null;
        }
    },

    watch: {
        'seatablesQuery.predicates': function () {
            this.loadFilteredSeatables(this.seatingChart);
        },

        'seatablesQuery.searchTerm': debounce(function () {
            this.loadFilteredSeatables(this.seatingChart);
        }, 500),

        showSeatableDetailsModal (newValue) {
            if (!newValue) {
                this.loadFilteredSeatables(this.seatingChart);
            }
        }
    },

    mounted () {
        this.applyDefaultFilters();
    },

    methods: {
        applyDefaultFilters () {
            if (this.event.setup_type === 'rsvp' && this.seatingChart.audience.target === 'all') {
                this.seatablesQuery.predicates = [{
                    id: 1, field: 'reply', comparison: 'state-neq', value: 'not-attending'
                }];
            }

            if (this.seatingChart.audience.target === 'secondary-event') {
                const secondaryEventNotAttendingProduct = this.event.products.find(({ blockable_id, type, short_guest_state }) => {
                    return blockable_id === this.seatingChart.audience.id
                    && type === 'secondary'
                    && short_guest_state === 'NotAttending';
                });

                this.seatablesQuery.predicates = [{
                    id: 1,
                    field: 'secondary-reply',
                    comparison: 'doesnt-have',
                    value: secondaryEventNotAttendingProduct.id
                }];
            }
        },

        changeSort () {
            if (!this.seatablesQuery.sort) {
                this.seatablesQuery.sort = `+full_name`;
                this.grouped = false;
            } else if (this.seatablesQuery.sort && this.seatablesQuery.sort === `+full_name`) {
                this.seatablesQuery.sort = `-full_name`;
                this.grouped = false;
            } else {
                this.seatablesQuery.sort = null;
                this.grouped = true;
            }

            this.loadFilteredSeatables(this.seatingChart);
        },

        focusSeatable (seatable) {
            if (!seatable.seating_area_id) {
                return;
            }

            this.focusedRow = this.getSeatableKey(seatable);
        },

        getSeatableIdentificator (seatable) {
            return isEmpty(trim(seatable.fullName)) ? seatable.email : seatable.fullName;
        },

        getSortableSeatables (index) {
            return this.seatableGroups[index];
        },

        getSeatableKey (seatable) {
            return `${seatable.type}-${seatable.id}`;
        },

        getSeatableSeatArea (seatable) {
            return this.seatingAreas.find((seatingArea) => {
                return seatingArea.id === seatable.seating_area_id;
            });
        },

        isEligibleForSeating (seatable) {
            if (!lodashGet(seatable, 'reply.guest_state')) {
                return !seatable.seating_area_id;
            }

            return !lodashGet(seatable, 'reply.guest_state').includes('NotAttending') && !seatable.seating_area_id;
        },

        isSeatableFocused (seatable) {
            return this.focusedRow === this.getSeatableKey(seatable);
        },

        isSeatActionInProgress (seatable) {
            if (!this.seatActionInProgress) {
                return false;
            }

            return this.seatActionInProgress.seatable.type === seatable.type
                && this.seatActionInProgress.seatable.id === seatable.id;
        },

        launchDetailsModal (seatable) {
            this.selectedSeatable = seatable;
            this.showSeatableDetailsModal = true;
        },

        loadFilteredSeatables: call('Seating/loadFilteredSeatables'),

        prepareAndUnseat (seatable) {
            if (!seatable.seating_area_id || this.saving) {
                return;
            }

            this.unseat({
                seatable,
                seatingArea: this.getSeatableSeatArea(seatable)
            });
        },

        seatableRowStyle (seatable) {
            return {
                'text-gray-600 hover:border-purple hover:bg-gray-100 seatable-draggable-handle cursor-move': this.isEligibleForSeating(seatable),
                'text-gray-300 hover:border-yellow cursor-pointer': !this.isEligibleForSeating(seatable)
            };
        },

        unseat: call('Seating/unseat')
    }
};
</script>
