/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'toolbox-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="22.5" height="15" x=".75" y="6.751" rx="1.5"/><path pid="1" d="M16.5 6.751v0a4.5 4.5 0 00-9 0M.75 12.75h9.75M13.5 12.75h9.75M12 15l-.01-.001a1.51 1.51 0 01-1.5-1.5v-1.501h0a1.496 1.496 0 112.99-.02s0 0 0 0v1.5h0c0 .82-.68 1.5-1.5 1.5l-.01-.001z"/></g>'
  }
})
