/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'casino-clover-lucky-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 14.25v7.43l-.01-.01c.03.88.78 1.58 1.67 1.54a1.65 1.65 0 001.32-.8"/><path pid="1" d="M20.924 11.625v-.01c1.45-.79 2-2.6 1.22-4.05a2.992 2.992 0 00-2.65-1.58c-1.43 0-2.3.85-3.375 1.5.45-.99 1.5-2.5 1.5-3.75v0c0-1.66-1.35-3-3-3h0c-1.09-.03-2.1.55-2.63 1.5l-.01-.01a2.919 2.919 0 00-2.63-1.5h-.01c-1.66 0-3 1.34-3 3 0 0 0 0 0 0 0 1.24 1.05 2.76 1.5 3.75-1.08-.65-1.96-1.51-3.38-1.51l-.01-.001c-1.66-.01-3 1.32-3.01 2.97-.01 1.1.6 2.12 1.57 2.64v-.01a2.981 2.981 0 00-1.23 4.04c.52.97 1.54 1.58 2.64 1.57 5.25 0 7.5-3 7.5-3s2.25 3 7.5 3l-.01-.01c1.65 0 2.99-1.33 3-2.98 0-1.11-.61-2.13-1.58-2.65z"/></g>'
  }
})
