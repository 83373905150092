/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'family-home-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>heart love house</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.259 9L12.009.75.75 9M6 9.75a3 3 0 100 6 3 3 0 100-6zM7.509 17.506l-.01-.01c-2.35-.83-4.92.39-5.75 2.74-.18.48-.26.98-.26 1.5M22.509 21.75h-.01c0-2.49-2.02-4.51-4.5-4.51-.52-.01-1.02.08-1.51.25M18 9.75a3 3 0 100 6 3 3 0 100-6zM21.009 12.75s0 3.75 2.25 3.75M12 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM15.906 23.25h0c-1.25-2.16-4-2.89-6.15-1.65-.69.39-1.26.96-1.65 1.64"/></g>'
  }
})
