/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>question support faq</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9 9h-.01a2.996 2.996 0 115.99 0c-.01 1.27-.81 2.4-2 2.82v-.01c-.6.21-1.01.77-1 1.41v1M12 17.25h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.38-.38-.375v0"/></g><path pid="1" _fill="none" _stroke="currentColor" stroke-width="1.5" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/>'
  }
})
