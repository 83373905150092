/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video-game-controller-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6 4.25h1.5-.01c2.48-.01 4.5 2.01 4.5 4.49v1.5"/><path pid="1" d="M18.75 10.25H5.25l-.01-.01C2.75 10.22.73 12.23.71 14.71c-.02 2.48 1.99 4.5 4.47 4.52 1.28 0 2.51-.54 3.37-1.5h6.836l-.01-.01a4.49 4.49 0 006.34.33c1.84-1.67 1.99-4.51.33-6.35a4.509 4.509 0 00-3.35-1.49zM5.25 13.25v3M3.75 14.75h3"/><path pid="2" d="M17.25 15.125h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M16.875 15.5h0c-.01-.21.16-.38.37-.38M17.25 15.87h-.01a.386.386 0 01-.38-.38s0 0 0 0M17.625 15.5v0c0 .2-.17.375-.375.375M19.5 12.87h-.01c.2-.01.37.16.37.37"/><path pid="4" d="M19.125 13.25h0c-.01-.21.16-.38.37-.38M19.5 13.625h-.01a.386.386 0 01-.38-.38s0 0 0 0M19.875 13.25v0c0 .2-.17.375-.375.375"/></g>'
  }
})
