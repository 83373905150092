/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bowling-set-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>pin ball lane</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25 8.25V3v0C8.25 1.75 7.24.75 6 .75c-1.25 0-2.25 1-2.25 2.25v5.25h-.01a11.389 11.389 0 00-3 7.5l-.01-.01c.04 2.52.92 4.96 2.5 6.93h0c.28.35.71.56 1.17.56h3.13l-.01-.001c.45 0 .89-.21 1.17-.57h-.01c1.57-1.97 2.46-4.41 2.5-6.94l-.01-.01c-.06-2.79-1.13-5.45-3.01-7.51zM12.461 22.49h0A7.5 7.5 0 0019.021 9a7.502 7.502 0 00-6.57 0M3.75 8.25h4.5M8.25 5.25h-4.5"/><path pid="1" d="M16.125 15h-.01c.2-.01.37.16.37.37M15.75 15.37h0c-.01-.21.16-.38.37-.38 0 0 0 0 0 0M16.125 15.75h-.01a.386.386 0 01-.38-.38s0 0 0 0M16.5 15.37v0c0 .2-.17.375-.375.375M19.875 14.25h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M19.5 14.625h0c-.01-.21.16-.38.37-.38M19.875 15h-.01a.386.386 0 01-.38-.38s0 0 0 0M20.25 14.625v0c0 .2-.17.375-.375.375M16.875 11.25h-.01c.2-.01.37.16.37.37"/><path pid="3" d="M16.5 11.625h0c-.01-.21.16-.38.37-.38M16.875 12h-.01a.386.386 0 01-.38-.38s0 0 0 0M17.25 11.625v0c0 .2-.17.375-.375.375"/></g>'
  }
})
