/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'baby-care-bottle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.416 1.18l6.4 6.4h0c.58.58.58 1.52 0 2.11h-.01l-8.668 8.669-8.51-8.51 8.66-8.68v-.01c.58-.59 1.52-.59 2.11-.01 0 0 0 0 0 0z"/><path pid="1" d="M3.2 9.85v-.01c.67-.68 1.75-.68 2.43 0l8.51 8.51h0c.67.67.67 1.75 0 2.43-.68.67-1.76.67-2.44 0l-8.52-8.52h0c-.68-.68-.68-1.76-.01-2.44v-.01zM10.5 9.86L8.06 7.43M12.93 7.43L10.5 4.99M3.4 12.49l-.42.82h-.01c-.41.8-.55 1.72-.4 2.61h0c.19 1.33-.26 2.69-1.22 3.64h-.01c-.85.83-.87 2.19-.03 3.03.83.84 2.19.86 3.03.02 0-.01.01-.02.02-.03v-.01c.95-.96 2.3-1.41 3.64-1.22l-.01-.01c.89.14 1.81 0 2.61-.4l.82-.42"/></g>'
  }
})
