/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email-send-envelope-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>postal</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.23 16.776h-.01c-.14.7-.76 1.22-1.48 1.22H2.53c-.83 0-1.51-.68-1.51-1.5-.01-.1 0-.19.02-.28l1.68-9v-.01a1.5 1.5 0 011.47-1.23h17.2c.82 0 1.49.67 1.49 1.5-.01.09-.01.18-.03.27z"/><path pid="1" d="M22.917 7.77l-10.92 4.22L2.76 7.21"/></g>'
  }
})
