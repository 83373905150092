/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-cheese': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>dairy</desc><g _fill="currentColor"><circle pid="0" cx="18.75" cy="13.249" r="1.75"/><circle pid="1" cx="6" cy="14.999" r="1"/><circle pid="2" cx="10.25" cy="17.249" r="1.75"/><path pid="3" d="M23.98 8.8c0-4.879-5.5-8.107-10.6-8.3a1.953 1.953 0 00-1.343.464L.687 10.511a1.706 1.706 0 00-.419.532 1.757 1.757 0 00-.248.906v5.2a1 1 0 001 1 .869.869 0 01.91.82.835.835 0 01-.58.776 1.967 1.967 0 00-1.33 1.844 1.836 1.836 0 00.661 1.423 1.905 1.905 0 001.543.47l20.052-2.5a1.93 1.93 0 001.7-1.892V8.8zM3.358 21.32a.25.25 0 01-.212-.42 2.8 2.8 0 00.784-1.931 2.818 2.818 0 00-1.63-2.53.5.5 0 01-.284-.451V12.86a.5.5 0 01.438-.5L21.418 10a.5.5 0 01.562.5v8.063a.5.5 0 01-.438.5z"/><circle pid="4" cx="16" cy="16.999" r="1"/></g>'
  }
})
