<template>
    <div>
        <img
            v-if="announcement.image"
            class="rounded-md w-full h-48 object-fit-cover"
            :src="announcement.image"
        >
        <p class="font-bold uppercase border-b border-purple pb-2 my-4 w-full">{{ announcement.title }}</p>

        <p class="mb-4 flex-1">{{ announcement.content }}</p>

        <a
            class="button-text button-primary mt-4"
            :href="announcement.url"
            target="_blank"
        >
            {{ announcement.action_text }}
        </a>
    </div>
</template>

<script>
export default {
    name: 'AnnouncementDisplay',

    props: {
        announcement: {
            type: Object,
            required: true
        }
    }
};
</script>
