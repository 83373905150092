/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-parking-car-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.875.75a6 6 0 100 12 6 6 0 100-12z"/><path pid="1" d="M15.37 6.75h1.5l-.01-.001c.82 0 1.5-.68 1.5-1.5 0-.83-.68-1.5-1.5-1.5h-1.5v6M3.327 13.607l1.75-3.9v-.01c.33-.88 1.16-1.46 2.1-1.46h.69M1.875 20.049v1.7h0c0 .82.67 1.49 1.5 1.49.82-.01 1.49-.68 1.49-1.51v-1.5M21.375 20.049v1.7c0 .82-.68 1.5-1.5 1.5-.83 0-1.5-.68-1.5-1.5v-1.5M18.75 16.5h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M18.375 16.875h0c-.01-.21.16-.38.37-.38M18.75 17.25h-.01a.386.386 0 01-.38-.38s0 0 0 0M19.125 16.875v0c0 .2-.17.375-.375.375M4.5 16.5h-.01c-.21 0-.38.16-.38.37 0 0 0 0 0 0M4.875 16.875v0c0-.21-.17-.375-.375-.375M4.5 17.25h-.01c.2 0 .37-.17.37-.38M4.12 16.875h0c0 .2.16.37.37.37"/><path pid="3" d="M10.87 13.5H4.11c-1.66 0-3 1.34-3 3 0 0 0 0 0 0v2.25h0c0 .82.67 1.49 1.5 1.49h18-.01c.82 0 1.5-.68 1.5-1.5v-2.26h-.01c0-.53-.14-1.05-.4-1.51"/></g>'
  }
})
