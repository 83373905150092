/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'duplicate-circle-filled': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>clone copy</desc><g _fill="currentColor"><path pid="0" d="M8.75 20.5c.146 0 .291 0 .435-.014a.249.249 0 00.133-.45 9.97 9.97 0 010-16.071.25.25 0 00-.133-.451A6.873 6.873 0 008.75 3.5a8.5 8.5 0 000 17z"/><path pid="1" d="M15.25 3.5a8.5 8.5 0 108.5 8.5 8.5 8.5 0 00-8.5-8.5zm5 8.5a1 1 0 01-1 1H16.5a.25.25 0 00-.25.25V16a1 1 0 11-2 0v-2.75A.25.25 0 0014 13h-2.75a1 1 0 110-2H14a.25.25 0 00.25-.25V8a1 1 0 112 0v2.75c0 .138.112.25.25.25h2.75a1 1 0 011 1z"/></g>'
  }
})
