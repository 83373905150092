<template>
    <div>
        <slot :commands="commands"></slot>

        <app-modal v-model="longContentModal" title="This is a Header">
            <template #footer>
                <button class="button">
                    Cancel
                </button>

                <button class="button button-primary ml-4">
                    Save
                </button>
            </template>

            <p class="leading-normal mb-8">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto harum pariatur adipisci voluptates aut maiores laboriosam, nam nesciunt cum debitis eos asperiores eligendi, a alias amet numquam consequuntur dignissimos nihil. Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis et inventore libero laborum est, fuga quia non, vel provident porro autem voluptates, atque praesentium rerum earum distinctio quisquam itaque aperiam? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minima, porro. Et placeat cum exercitationem quo minima itaque nisi qui animi, facilis eum at molestias error saepe, amet pariatur corrupti enim? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam dolore repudiandae magnam veritatis incidunt corporis accusamus impedit obcaecati possimus, minus dolor. Nihil placeat, tempore voluptatibus dolorum quaerat ad non quidem.</p>

            <p class="leading-normal mb-8">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto harum pariatur adipisci voluptates aut maiores laboriosam, nam nesciunt cum debitis eos asperiores eligendi, a alias amet numquam consequuntur dignissimos nihil. Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis et inventore libero laborum est, fuga quia non, vel provident porro autem voluptates, atque praesentium rerum earum distinctio quisquam itaque aperiam? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minima, porro. Et placeat cum exercitationem quo minima itaque nisi qui animi, facilis eum at molestias error saepe, amet pariatur corrupti enim? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam dolore repudiandae magnam veritatis incidunt corporis accusamus impedit obcaecati possimus, minus dolor. Nihil placeat, tempore voluptatibus dolorum quaerat ad non quidem.</p>

            <p class="leading-normal">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto harum pariatur adipisci voluptates aut maiores laboriosam, nam nesciunt cum debitis eos asperiores eligendi, a alias amet numquam consequuntur dignissimos nihil. Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis et inventore libero laborum est, fuga quia non, vel provident porro autem voluptates, atque praesentium rerum earum distinctio quisquam itaque aperiam? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minima, porro. Et placeat cum exercitationem quo minima itaque nisi qui animi, facilis eum at molestias error saepe, amet pariatur corrupti enim? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam dolore repudiandae magnam veritatis incidunt corporis accusamus impedit obcaecati possimus, minus dolor. Nihil placeat, tempore voluptatibus dolorum quaerat ad non quidem.</p>
        </app-modal>

        <app-modal v-model="regularModal" title="Test Title">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta optio, molestias corrupti, cum corporis sapiente minima, cupiditate in odit explicabo odio quis obcaecati commodi repudiandae labore dolor natus non fugit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem veniam deleniti ipsam suscipit et consequatur doloremque accusantium esse! Voluptas quasi illo provident dolor nisi praesentium illum ipsa quam nam facilis.
        </app-modal>

        <app-modal
            v-model="showModalWithoutOverlay"
            title="No Overlay"
            :full-screen-on-mobile="false"
        >
            <div class="text-center">
                <h3>Modal that is not full screen on mobile</h3>
            </div>
        </app-modal>

        <app-modal v-model="showRegularModal2" title="Regular Modal Title">
            <div class="text-center">
                <h3>Regular Modal 3</h3>
            </div>

            <div class="p-4 py-32 bg-purple text-center">
                <a
                    class="bg-white border-black rounded-md p-2"
                    role="button"
                    @click="showRegularModal3 = true"
                >Open Another Modal On Top</a>
            </div>
        </app-modal>

        <app-modal v-model="showRegularModal3" title="Very Top">
            <div class="text-center">
                <h3>Modal that's on the very top</h3>
            </div>
        </app-modal>

        <app-modal
            v-model="showFullScreenModal"
            title="Fully Covers the Screen"
            :full-screen="true"
        >
            <div class="text-center">
                <h3>Full Screen Modal</h3>
            </div>
        </app-modal>
    </div>
</template>

<script>
export default {
    data () {
        return {
            regularModal: false,
            showRegularModal2: false,
            showRegularModal3: false,
            showModalWithoutOverlay: false,
            showFullScreenModal: false,
            longContentModal: false
        };
    },

    computed: {
        commands () {
            return {
                showLongContentModal: this.showLongContentModal,
                showRegularModal: this.showRegularModal
            };
        }
    },

    methods: {
        showLongContentModal () {
            this.longContentModal = true;
        },

        showRegularModal () {
            this.regularModal = true;
        }
    }
};
</script>
