/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'app-link': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>window browser hyper chain</desc><g _fill="currentColor"><path pid="0" d="M17.51 11.772l-1.19 1.19h-.01a.996.996 0 00-.03 1.41c.38.39 1.01.4 1.41.02 0-.01.01-.02.02-.03l1.18-1.19h-.01a1.81 1.81 0 012.58 0c.71.71.71 1.87-.01 2.58l-1.19 1.18v-.01a.996.996 0 00-.03 1.41c.38.39 1.01.4 1.41.02 0-.01.01-.02.02-.03l1.189-1.189h-.01c1.5-1.49 1.51-3.91.03-5.42a3.836 3.836 0 00-5.42-.04c-.02.01-.03.02-.04.03zM17.078 20.21l-1.26 1.25v-.01c-.72.71-1.88.71-2.59-.01-.72-.72-.72-1.88 0-2.59l1.25-1.26v-.01c.38-.4.37-1.04-.03-1.42a.99.99 0 00-1.39 0l-1.26 1.25h-.01a3.815 3.815 0 00.03 5.41 3.82 3.82 0 005.37 0l1.25-1.26v-.01c.38-.4.37-1.04-.03-1.42a.99.99 0 00-1.39 0z"/><path pid="1" d="M19.74 14.961c-.4-.4-1.03-.4-1.42-.01l-3.54 3.53v-.01a.996.996 0 00-.03 1.41c.38.39 1.01.4 1.41.02 0-.01.01-.02.02-.03l3.535-3.535v-.01c.39-.39.39-1.03 0-1.42l-.01-.01zM7.465 18.5H2.93c-.56-.01-1-.45-1-1.01V5.23l-.01-.01c-.01-.14.11-.26.24-.26v-.01h19.49-.01c.13-.01.25.11.25.24v3.26h-.01c-.01.55.43 1 .99 1 .55 0 1-.44 1-1V2.91c0-1.66-1.35-3-3-3H2.86c-1.66 0-3 1.34-3 3v14.5c0 1.65 1.34 2.99 3 2.99h4.52-.01c.55-.01.99-.46.99-1.01-.01-.56-.46-1-1.01-1zM9.6 2v-.01a.96.96 0 011.31-.39c.16.08.29.22.38.38l-.01-.01c.09.14.14.32.15.5v-.01c-.01.17-.06.35-.16.49h-.01a.98.98 0 01-1.71-.01c-.1-.15-.15-.33-.16-.5v-.01c0-.18.05-.36.15-.5zM6.1 2v-.01a.96.96 0 011.31-.39c.16.08.29.22.38.38l-.01-.01c.09.14.14.32.15.5v-.01c-.01.17-.06.35-.16.49h-.01a.98.98 0 01-1.71-.01c-.1-.15-.15-.33-.16-.5v-.01c0-.18.05-.36.15-.5zm-3.561.09h-.01c.16-.36.51-.6.91-.6.35 0 .67.19.85.5l-.01-.01c.09.14.14.32.15.5v-.01c-.01.17-.06.35-.16.5v-.01a.98.98 0 01-1.71-.01c-.1-.15-.15-.33-.16-.5h-.01c0-.15.03-.29.09-.41z"/></g>'
  }
})
