<template>
    <div class="my-4 mx-8 space-y-8">
        <div class="flex flex-col md:flex-row items-start">
            <div class="flex-auto w-full md:w-2/3 p-6 bg-white shadow rounded-md space-y-6">
                <div class="flex text-xs w-fit items-center bg-green-light border border-green rounded-lg py-1 px-2">
                    <app-icon class="text-green w-4 h-4 mr-2" name="time-clock-stroke"></app-icon>
                    <span class="font-bold">Limited time offer</span>
                </div>

                <div>
                    <h1 class="text-3xl font-bold">Give a free month, get a free month</h1>
                    <p class="text-gray-500">Get rewarded when your referral signs up for RSVPify and upgrades</p>
                </div>

                <div class="space-y-2">
                    <p class="font-semibold">Copy and share your link</p>
                    <div class="flex flex-col md:flex-row items-center">
                        <input
                            type="text"
                            class="form-field bg-gray-100 cursor-not-allowed flex-shrink"
                            :value="affiliateLink"
                            disabled
                        >
                        <button class="w-full mt-4 md:mt-0 md:ml-4 md:w-auto button button-primary flex-none" @click="copyReferralLink">Copy Link</button>
                    </div>
                </div>

                <div class="space-x-4 flex flex-col lg:flex-row">
                    <span class="text-gray-500">Share the link on:</span>

                    <a
                        target="_blank"
                        class="flex items-center text-purple font-semibold hover:text-purple-lighter"
                        :href="`https://www.facebook.com/sharer/sharer.php?u=${affiliateLink}`"
                    >
                        Facebook
                        <app-icon
                            class="ml-1 h-4 w-4"
                            name="social-facebook"
                        ></app-icon>
                    </a>

                    <a
                        target="_blank"
                        class="flex items-center text-purple font-semibold hover:text-purple-lighter"
                        :href="`https://twitter.com/intent/tweet?url=${affiliateLink}&text=RSVPify+makes+event+planning+a+breeze.+Try+it+out+for+your+next+event+free.`"
                    >
                        Twitter
                        <app-icon class="ml-1 h-4 w-4" name="social-twitter"></app-icon>
                    </a>

                    <a
                        target="_blank"
                        class="flex items-center text-purple font-semibold hover:text-purple-lighter"
                        :href="`https://www.linkedin.com/sharing/share-offsite/?url=${affiliateLink}`"
                    >
                        Linked In
                        <app-icon class="ml-1 h-4 w-4" name="social-linkedin"></app-icon>
                    </a>
                </div>

                <form class="space-y-6" @submit.prevent="sendEmail">
                    <div>
                        <form-field-wrapper
                            id="field_email"
                            :error="form.errors.get('email')"
                            label="Invite by email"
                        >
                            <input
                                id="input_password"
                                v-model="form.email"
                                class="form-field"
                                placeholder="Enter email address"
                            >
                        </form-field-wrapper>
                    </div>
                    <hr>
                    <div class="flex justify-end md:space-x-6 items-center flex-wrap md:flex-nowrap space-y-2 md:space-y-0">
                        <button
                            type="button"
                            class="w-1/2 md:w-auto button-text button-primary uppercase"
                            @click="previewEmail"
                        >Preview email</button>

                        <stateful-button
                            type="submit"
                            class="w-1/2 md:w-auto button button-soft"
                            :loading="form.processing"
                        >Send email</stateful-button>
                    </div>
                </form>
            </div>

            <div class="flex-auto w-full md:w-1/3 p-0 md:p-4 my-4 ml-0 md:ml-8">
                <h2 class="font-bold text-2xl">How it works</h2>

                <div class="divide-y ml-2">
                    <div class="flex items-center space-x-4 py-8">
                        <div class="flex-none w-12 h-12 rounded-lg bg-teal flex items-center justify-center">
                            <app-icon class="w-5 h-5 text-white" name="link"></app-icon>
                        </div>

                        <p><span class="text-bold">1. </span>Share your link with a friend or event planner.</p>
                    </div>

                    <div class="flex items-center space-x-4 py-8">
                        <div class="flex-none w-12 h-12 rounded-lg bg-teal flex items-center justify-center">
                            <app-icon class="w-5 h-5 text-white" name="users-add-circle-stroke"></app-icon>
                        </div>

                        <p><span class="text-bold">2. </span>They sign up and upgrade to a premium plan.</p>
                    </div>

                    <div class="flex items-center space-x-4 py-8">
                        <div class="flex-none w-12 h-12 rounded-lg bg-teal flex items-center justify-center">
                            <app-icon class="w-5 h-5 text-white" name="gift-stroke"></app-icon>
                        </div>

                        <p><span class="text-bold">3. </span>You both get a free month of RSVPify!</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="hidden md:block w-full md:w-2/3 px-4">
            <h2 class="font-bold text-2xl p-4">Trusted by:</h2>
            <img
                :src="asset('images/account/trusted-by.png')"
                alt="Trusted by"
                class="max-h-32 mx-auto"
            >
        </div>

        <a
            href="https://rsvpify.com/referafriend/terms/"
            target="_blank"
            class="underline px-4 my-2 font-semibold text-gray-500 text-sm block"
        ><span class="text-teal">Terms</span> apply</a>

        <app-modal
            v-model="preview.open"
            title="Refer A Friend"
        >
            <p v-if="preview.loading" class="text-center">
                <app-icon
                    name="loader"
                    class="w-5 h-5 text-purple"
                ></app-icon>
            </p>

            <iframe
                v-else
                ref="preview"
                width="100%"
                :srcdoc="preview.content"
                @load="setIframeHeight"
            ></iframe>
        </app-modal>
    </div>
</template>

<script>
import axios from '@/util/axios';
import Form from '@/validation/Form';

export default {
    name: 'ReferAFriend',

    props: {
        affiliateId: {
            type: String,
            required: true
        },

        affiliateLink: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            preview: {
                loading: false,
                open: false,
                content: ''
            },
            form: new Form({
                email: ''
            })
        };
    },

    methods: {
        copyReferralLink () {
            this.$copyText(this.affiliateLink);
            this.$toasted.global.success('The referral URL has been copied to your clipboard and is ready for sharing!');
        },

        async previewEmail () {
            this.preview.open = true;
            this.preview.loading = true;

            await axios.get(this.route('api.account.referrals.email.show'))
                .then(({ data }) => {
                    this.preview.content = data;
                }).finally(() => {
                    this.preview.loading = false;
                });
        },

        async sendEmail () {
            await this.form.post(this.route('api.account.referrals.email.store'))
                .then(() => {
                    this.$toasted.global.success('The referral email has been sent!');
                    this.form.restore();
                }).catch((error) => {
                    if (error.response.status === 429) {
                        this.$toasted.global.error('You can send a maximum of 10 referrals per month.');
                    }
                });
        },

        setIframeHeight () {
            const iframe = this.$refs.preview;
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            iframe.style.height = `${iframeDocument.documentElement.scrollHeight}px`;
        }
    }
};
</script>
