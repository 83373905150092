/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-protection-face-mask-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 4.563h-.01c-.65 0-1.29.21-1.8.6-.78.58-2.726 2.15-3.7 2.15l-1.5.12v9l3.285 1.971-.01-.01c1.12.67 2.4 1.02 3.71 1.02"/><path pid="1" d="M5 16.438l-.36-.17-.01-.01a6.786 6.786 0 01-3.9-6.14H.72a2.983 2.983 0 014.24-2.71M12 4.563h-.01c.64-.01 1.28.21 1.8.59.77.58 2.72 2.15 3.7 2.15l1.5.125v9L15.7 18.39v-.01a7.28 7.28 0 01-3.72 1.02"/><path pid="2" d="M19 16.438l.35-.17v-.01a6.794 6.794 0 003.89-6.14h0c0-1.65-1.34-2.981-2.99-2.981-.44 0-.88.09-1.27.28M9.5 12.19h5M12 14.69v-5"/></g>'
  }
})
