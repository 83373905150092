<template>
    <div class="flex" @click="toggleIfFullOptionSelect">
        <div
            class="focus:outline-none"
            :class="styles.cursor"
            @click="toggle"
        >
            <div class="transition duration-150 ease-in-out flex items-center justify-center shrink-0 border-2 select-list-outer" :class="styles.outer">
                <div
                    v-if="isSelected"
                    class="select-list-check"
                    :class="styles.check"
                ></div>
            </div>

            <div
                v-tippy="{ placement: tooltipPlacement }"
                :class="{'ml-2': selectListState.vertical}"
                class="focus:outline-none"
                :content="tooltip ? tooltip : null"
            >
                <slot :selected="isSelected">
                    {{ label }}
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectOption',

    inject: ['selectListState'],

    props: {
        disabled: {
            type: Boolean,
            default: false
        },

        id: {
            type: [String, Number, Boolean],
            default: () => {
                return null;
            }
        },

        label: {
            type: String,
            default: ''
        },

        tooltip: {
            type: String,
            default: null
        },

        tooltipPlacement: {
            type: String,
            default: 'top'
        },

        small: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isMultiSelect () {
            return this.selectListState.multiSelect;
        },

        isSelected () {
            if (this.isMultiSelect) {
                return this.selectListState.selected.includes(this.id);
            }

            return this.selectListState.selected === this.id;
        },

        styles () {
            return {
                cursor: {
                    'cursor-pointer': !this.disabled,
                    'opacity-75 cursor-not-allowed': this.disabled,
                    'inline-flex items-center': this.selectListState.vertical
                },

                check: {
                    'rounded-sm': this.isMultiSelect,
                    'rounded-full': !this.isMultiSelect,
                    [`bg-${this.selectListState.color}`]: this.isSelected,
                    'h-3 w-3': !this.small,
                    'h-2 w-2': this.small
                },

                outer: {
                    'rounded-md': this.isMultiSelect,
                    'rounded-full': !this.isMultiSelect,
                    'border-gray-600': !this.isSelected,
                    [`border-${this.selectListState.color}`]: this.isSelected,
                    'h-7 w-7': !this.small,
                    'h-4 w-4': this.small
                }
            };
        }
    },

    methods: {
        toggle () {
            if (this.disabled) {
                return;
            }

            if (this.isMultiSelect) {
                if (this.isSelected) {
                    const index = this.selectListState.selected.indexOf(this.id);
                    this.selectListState.selected.splice(index, 1);
                } else {
                    this.selectListState.selected.push(this.id);
                }
            } else {
                this.selectListState.selected = this.id;
            }
        },

        toggleIfFullOptionSelect () {
            if (this.selectListState.fullOptionSelect) {
                this.toggle();
            }
        }
    }
};
</script>
