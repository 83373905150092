/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-beer-mug-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>brew pub bar alcohol stein</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 9.75h2.1-.01a3.765 3.765 0 013.9 3.59v1.8h-.01a3.768 3.768 0 01-3.91 3.59h-2.1"/><path pid="1" d="M17.25 8.256v13.6h0c.01.75-.6 1.38-1.35 1.39H5.09l-.01-.001c-.76-.02-1.37-.64-1.35-1.4V9.44M8.25 14.9v4.18M12.75 12.81v6.27"/><path pid="2" d="M19.365 5.1l-.01-.01a3.443 3.443 0 00-4.25-2.35c-.34.09-.66.24-.96.44h0a4.056 4.056 0 00-5.32-2.13 4.004 4.004 0 00-2.27 2.52l-.01-.01a3.431 3.431 0 00-4.86.05 3.415 3.415 0 00.045 4.85c.53.52 1.22.85 1.97.95h0l-.01-.01a3.364 3.364 0 003.44 3.29c.06-.01.12-.01.18-.01v-.01c1.14-.13 2.15-.82 2.69-1.83h-.01c.22-.4.36-.85.41-1.3h-.01c.06-.78.71-1.37 1.48-1.36h5.27c1.17.01 2.15-.88 2.24-2.05v-.01c.01-.38-.04-.75-.14-1.11z"/></g>'
  }
})
