/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-pay-coin-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.45 9.22l2.87-2.72v-.01c.32-.22.7-.33 1.08-.33h3.68v-.001c.68-.01 1.32.35 1.67.94l.35.57-.01-.01c.34.57.37 1.29.07 1.89l-.32.63v-.01a1.98 1.98 0 01-2.14 1.04l-3.17-.64h0c-.17-.04-.35 0-.49.09v-.01c-.61.39-.94 1.09-.86 1.81l-.01-.01c.02.28.21.52.47.62l5.34 2.05h0c.41.15.86.17 1.28.04l5.709-1.79"/><path pid="1" d="M23.06 5.77l-4.37-2.69h0c-.35-.21-.75-.31-1.15-.29l-7.03.41v-.01c-.44.02-.86.19-1.19.48l-3.5 3.11h-.01c-.26.22-.45.51-.56.84l-.68 2.01M8.437 12.206H6.39l-.01-.001c-.75-.01-1.35.59-1.35 1.33-.01.55.33 1.04.84 1.25l2.06.82-.01-.01c.68.27 1.02 1.05.74 1.74-.21.5-.7.84-1.25.84H5.38M6.94 12.21v-.75M6.94 18.96v-.75"/><path pid="2" d="M8.22 9.344h0a5.994 5.994 0 00-7.14 4.57c-.71 3.23 1.34 6.42 4.57 7.13 3.23.7 6.42-1.35 7.13-4.58.17-.83.18-1.68.01-2.5"/></g>'
  }
})
