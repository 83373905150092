/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buildings-outdoors-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>office business</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.6 23.25v-3.91M13.47 23.25v-3.91M5.64 19.34h9.78M.75 23.25V6.94c.02-.56.49-.99 1.05-.96h8.4v-.01c.55-.03 1.02.4 1.05.95v8.79"/><path pid="1" d="M8.25 3.5h0a.545.545 0 00-.57-.51H4.3h0a.53.53 0 00-.57.5v2.5h4.5zM5.98 2.71L6 .91M3.75 9.75h4.5M3.75 14.25h4.5M.75 23.25h22.5M19.5 9a3.75 3.75 0 100 7.5 3.75 3.75 0 100-7.5zM19.5 23.25V13.5"/></g>'
  }
})
