<template>
    <div class="flex items-center justify-center">
        <div class="w-1/2">
            <div class="text-center mb-4">
                <base-dropdown class="inline-block" placement="bottom">
                    <template slot="trigger" slot-scope="{ triggerFunction }">
                        <button
                            class="bg-gray-500 border-black text-white shadow rounded-md p-4 focus:outline-none"
                            @click="triggerFunction"
                        >
                            <div class="flex items-center justify-center">
                                <span class="grow">Simplest Dropdown, Bottom Placement</span>
                                <app-icon
                                    name="arrow-down-chevron"
                                    class="shrink ml-2 h-4 w-4"
                                    stroke
                                ></app-icon>
                            </div>
                        </button>
                    </template>

                    <div class="mt-1 p-4 bg-white border shadow">
                        <h3>A super simple dropdown with plain text here.</h3>
                    </div>
                </base-dropdown>
            </div>

            <div class="text-center mb-4">
                <base-dropdown class="inline-block" placement="bottom-start">
                    <template slot="trigger" slot-scope="{ triggerFunction }">
                        <button
                            class="bg-gray-500 border-black text-white shadow rounded-md p-4 focus:outline-none"
                            @click="triggerFunction"
                        >
                            <div class="flex items-center justify-center">
                                <span class="grow">Simplest Dropdown, Bottom Start Placement</span>
                                <app-icon
                                    name="arrow-down-chevron"
                                    class="shrink ml-2 h-4 w-4"
                                    stroke
                                ></app-icon>
                            </div>
                        </button>
                    </template>

                    <div class="mt-1 p-4 bg-white border shadow">
                        <h3>A super simple dropdown with plain text here.</h3>
                    </div>
                </base-dropdown>
            </div>

            <div class="text-center mb-4">
                <base-dropdown class="inline-block">
                    <template slot="trigger" slot-scope="{ triggerFunction }">
                        <button
                            class="bg-gray-500 border-black text-white shadow rounded-md p-4 focus:outline-none"
                            @click="triggerFunction"
                        >
                            <div class="flex items-center justify-center">
                                <span class="grow">Custom Dropdown Content</span>
                                <app-icon
                                    name="arrow-down-chevron"
                                    class="shrink ml-2 h-4 w-4"
                                    stroke
                                ></app-icon>
                            </div>
                        </button>
                    </template>

                    <div class="mt-1 shadow bg-purple-base-to-light w-48 h-48 flex items-center justify-center text-white rounded-md">
                        <h1>&check;</h1>
                    </div>
                </base-dropdown>
            </div>

            <div class="text-center mb-4">
                <base-dropdown class="inline-block">
                    <template slot="trigger" slot-scope="{ triggerFunction }">
                        <button
                            class="bg-gray-500 border-black text-white shadow rounded-md p-4 focus:outline-none"
                            @click="triggerFunction"
                        >
                            <div class="flex items-center justify-center">
                                <span class="grow">A Table In The Dropdown</span>
                                <app-icon
                                    name="arrow-down-chevron"
                                    class="shrink ml-2 h-4 w-4"
                                    stroke
                                ></app-icon>
                            </div>
                        </button>
                    </template>

                    <div class="my-2 p-4 w-180 bg-white shadow border-t-4 border-purple shadow-lg">
                        <table class="w-full">
                            <thead>
                                <tr class="font-bold">
                                    <td>One</td>
                                    <td>Two</td>
                                    <td>Three</td>
                                    <td>Four</td>
                                    <td>Five</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </base-dropdown>
            </div>

            <div class="text-center mb-4">
                <button
                    class="bg-gray-500 border-black text-white shadow rounded-md p-4 focus:outline-none"
                    @click="showModal = true"
                >
                    Show Modal With Dropdown
                </button>
            </div>
        </div>

        <app-modal v-model="showModal" title="Very Top">
            <div class="text-center">
                <base-dropdown class="inline-block">
                    <template slot="trigger" slot-scope="{ triggerFunction }">
                        <button
                            class="bg-gray-500 border-black text-white shadow rounded-md p-4 focus:outline-none"
                            @click="triggerFunction"
                        >
                            <div class="flex items-center justify-center">
                                <span class="grow">A Table In The Dropdown</span>
                                <app-icon
                                    name="arrow-down-chevron"
                                    class="shrink ml-2 h-4 w-4"
                                    stroke
                                ></app-icon>
                            </div>
                        </button>
                    </template>

                    <div class="my-2 p-4 w-180 bg-white shadow border-t-4 border-purple shadow-lg">
                        <table class="w-full">
                            <thead>
                                <tr class="font-bold">
                                    <td>One</td>
                                    <td>Two</td>
                                    <td>Three</td>
                                    <td>Four</td>
                                    <td>Five</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </base-dropdown>
            </div>
        </app-modal>
    </div>
</template>

<script>
export default {
    data () {
        return {
            showModal: false
        };
    }
};
</script>
