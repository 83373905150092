/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'religion-church-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M16.5 22.5h-9V12h0a4.494 4.494 0 014.49-4.51c2.48-.01 4.5 2.01 4.5 4.49 0 0 0 0 0 0z"/><path pid="1" d="M12 12.37l-.01-.001c-.21-.01-.38-.17-.38-.38s.16-.38.37-.38c.2 0 .37.16.37.37v0c0 .2-.17.375-.375.375M13.5 22.5h-3v-5.25h0a1.496 1.496 0 112.99-.02s0 0 0 0zM12 1.5v6M10.5 3h3M4.12 18.75h-.01c.2 0 .37-.17.37-.38s-.17-.38-.38-.38a.365.365 0 00-.38.37h0c0 .2.16.37.37.37 0 0 0 0 0 0"/><path pid="2" d="M.75 21h0c0 .82.67 1.49 1.5 1.49H7.5v-9l-5.58 1.23h-.01c-.69.15-1.18.75-1.18 1.46zM19.875 18.75h-.01c-.21-.01-.38-.17-.38-.38s.16-.38.37-.38c.2 0 .37.16.37.37v0c0 .2-.17.375-.375.375"/><path pid="3" d="M23.25 21v0c0 .82-.68 1.5-1.5 1.5H16.5v-9l5.57 1.23-.01-.001c.68.15 1.17.75 1.17 1.46z"/></g>'
  }
})
