/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lighthouse-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 11.25h9v12h-9z"/><path pid="1" d="M12.75 23.25h-3v-3h0a1.496 1.496 0 112.99-.02s0 0 0 0zM5.25 11.25h12M8.25 5.25h6v6h-6zM8.25 5.25h0a2.996 2.996 0 115.99-.02s0 0 0 0M6.75 5.25h9M11.25.75v1.5M.75 23.25h22.5M.75 5.25l3 .75M.75 9.75l3-.75M21.75 5.25l-3 .75M21.75 9.75l-3-.75"/></g>'
  }
})
