/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'open-quote': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="currentColor" d="M5.25 20.248A5.257 5.257 0 010 15.014v-.024C.005 8.792 5.051 3.75 11.25 3.75a.75.75 0 010 1.5 9.763 9.763 0 00-8.625 5.209 5.24 5.24 0 012.625-.711 5.256 5.256 0 015.25 5.25 5.256 5.256 0 01-5.25 5.25zM1.5 15.005a3.755 3.755 0 003.75 3.743c2.068 0 3.75-1.682 3.75-3.75s-1.682-3.75-3.75-3.75a3.754 3.754 0 00-3.75 3.748v.009zm15.75 5.243A5.258 5.258 0 0112 15.014V15c.002-6.205 5.049-11.25 11.25-11.25a.75.75 0 010 1.5 9.763 9.763 0 00-8.625 5.209 5.24 5.24 0 012.625-.711 5.256 5.256 0 015.25 5.25 5.256 5.256 0 01-5.25 5.25zm0-9a3.754 3.754 0 00-3.75 3.748 3.755 3.755 0 003.75 3.752c2.068 0 3.75-1.682 3.75-3.75s-1.682-3.75-3.75-3.75z"/>'
  }
})
