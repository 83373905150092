/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-graph-cured-decreasing-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.777 22.5h-21L.77 1.5M21.777 6v0c0-.56-.45-1-1-1h-2.5V2.5v0c0-.56-.45-1-1-1h-1.51c-.56 0-1 .44-1 1 0 0 0 0 0 0V5h-2.51c-.56 0-1 .44-1 1 0 0 0 0 0 0v1.5h0c0 .55.44.99 1 .99h2.5v2.5h0c0 .55.44.99 1 .99h1.5l-.01-.001c.55 0 1-.45 1-1v-2.5h2.5l-.01-.001c.55 0 1-.45 1-1z"/><path pid="1" d="M.77 9.5h1.4v-.001c3.37-.01 6.55 1.57 8.6 4.25l-.01-.01c2.04 2.67 5.22 4.25 8.59 4.25h3.846"/><path pid="2" d="M20.83 15.609L23.22 18l-2.39 2.39"/></g>'
  }
})
