/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-lifelong-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.017.767h-3.04M10.5.77v2.6M4.678 2.55L3.606 3.622l-1.073 1.07M3.61 3.62l1.83 1.84M.75 9v3.026M.75 10.52h2.6M2.533 16.338l1.073 1.07 1.072 1.07M3.61 17.41l1.83-1.84M18.467 4.69l-1.08-1.08L16.3 2.53M17.39 3.62l-1.83 1.84M9.41 12.61a.43.43 0 100 .86.43.43 0 100-.87zM8.98 6.83a1.51 1.51 0 100 3.03 1.51 1.51 0 100-3.04z"/><path pid="1" d="M17.356 8.483h0a7.157 7.157 0 00-8.89-4.83 7.142 7.142 0 00-4.83 8.88c.68 2.32 2.5 4.13 4.82 4.82"/><path pid="2" d="M17.25 11.23a6 6 0 100 12 6 6 0 100-12z"/><path pid="3" d="M19.9 17.233h-2.66v-2.651"/></g>'
  }
})
