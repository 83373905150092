<template>
    <div class="flex flex-col">
        <div class="flex items-start" :class="styles.disabled">
            <div v-theme="['form.accent']" class="flex">
                <app-icon
                    v-if="item.settings.icon"
                    :name="item.settings.icon"
                    class="shrink-0 opacity-50 h-6 w-6 mr-4"
                ></app-icon>
                <div v-else class="h-6 w-6 mr-4"></div>
            </div>
            <div v-theme="['form.text']" class="flex-1 mr-4">
                <div class="mb-1">
                    <div class="font-semibold">
                        {{ item.title }}
                    </div>

                    <money-field
                        v-if="item.settings.costsMoney"
                        static
                        :value="item.price"
                    ></money-field>
                </div>

                <div class="opacity-75 text-sm">
                    {{ item.settings.description }}
                </div>

                <div v-if="showAvailabilityMessage">
                    <template v-if="isEventSoldOut">
                        <tiptap-editor
                            read-only
                            :value="event.settings.maxCapacity.message"
                        ></tiptap-editor>
                    </template>
                    <template v-else>
                        <tiptap-editor
                            read-only
                            :value="item.settings.maxCapacity.message"
                        ></tiptap-editor>
                    </template>
                </div>
            </div>

            <number-picker
                v-model="count"
                v-theme="['form.accent', 'form.input-border-accent']"
                :min-value="minValue"
                :max-value="ceiling"
                class="text-2xl w-32"
                :disabled="isDisabled"
                :disabled-decrement="disabledDecrement"
                :disabled-increment="disabledIncrement"
                validation-error-message="Invalid"
            >
                <template slot="decrement-icon">
                    <app-icon
                        v-show="showIcons"
                        name="remove-circle"
                        class="w-6 h-6"
                        :class="{ 'opacity-25': count <= item.minimumRequiredQuantity || disabledDecrement }"
                        stroke
                    ></app-icon>
                </template>
                <template slot="increment-icon">
                    <app-icon
                        v-show="showIcons"
                        name="add-circle"
                        class="w-6 h-6"
                        :class="{ 'opacity-25': count >= item.maximumSelectable || disabledIncrement }"
                        stroke
                    ></app-icon>
                </template>
            </number-picker>
        </div>

        <div v-if="showError" class="alert alert-error alert-sm mt-2">
            <p class="w-full font-normal text-center">{{ error }}</p>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import arrayGet from 'lodash/get';
import HandleErrorMessages from '@/mixins/HandleErrorMessages';

export default {
    mixins: [HandleErrorMessages],

    props: {
        event: {
            type: Object,
            default: () => { return null; }
        },

        disabled: {
            type: Boolean,
            required: false
        },

        disabledDecrement: {
            type: Boolean,
            required: false
        },

        disabledIncrement: {
            type: Boolean,
            required: false
        },

        isActive: {
            type: Boolean,
            required: true
        },

        item: {
            type: Object,
            required: true
        },

        value: {
            type: Number,
            required: true
        }
    },

    data () {
        return {
            count: this.value
        };
    },

    computed: {
        isEditMode: get('Form/isEditMode'),

        ceiling () {
            let limits = [
                (this.item.quantity_remaining || 0),
                (this.item.settings.maximumSelectable || 0)
            ];

            limits = limits.filter((limitMax) => {
                return limitMax > 0;
            });

            return limits.length > 0 ? Math.min(...limits) : null;
        },

        isDisabled () {
            if (this.isEditMode) {
                return false;
            }

            if (this.isSoldOut) {
                return true;
            }

            return this.disabled;
        },

        isEventSoldOut () {
            return this.event
                && this.event.hasReachedMaxCapacity
                && this.item.type === 'primary'
                && this.item.category !== 'add-on'
                && this.item.short_guest_state === 'Attending';
        },

        isSoldOut () {
            if (this.isEventSoldOut) {
                return true;
            }

            const minQuantity = this.item.settings.minimumRequiredQuantity || 0;

            if (this.item.has_limited_availability && this.item.quantity_remaining === 0) {
                return true;
            }

            if (this.item.has_limited_availability && this.item.quantity_remaining < minQuantity) {
                return true;
            }

            return false;
        },

        minValue () {
            return this.isSoldOut ? 0 : this.item.settings.minimumRequiredQuantity;
        },

        showAvailabilityMessage () {
            if (this.isEditMode) {
                return false;
            }

            if (this.isEventSoldOut) {
                return arrayGet(this.event, 'settings.maxCapacity.message') !== '';
            }

            if (this.isSoldOut) {
                return arrayGet(this.item, 'settings.maxCapacity.message') !== '';
            }

            return false;
        },

        showIcons () {
            return !this.item.settings.maximumSelectable || !this.item.settings.minimumRequiredQuantity
            || this.item.settings.maximumSelectable !== this.item.settings.minimumRequiredQuantity || this.value < this.minValue;
        },

        styles () {
            return {
                disabled: {
                    'opacity-25': this.isDisabled
                }
            };
        }
    },

    watch: {
        count () {
            this.$emit('input', Number(this.count));
        },

        value (newValue) {
            this.count = newValue;
        }
    }
};
</script>
