/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grid-download-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25 18.75h-4.5l-.01-.001c-1.66-.01-3-1.35-3-3V3.748h0A2.998 2.998 0 013.73.73h12-.01c1.65-.01 3 1.34 3 3v4.5M6.75.75v18M12.75.75v9M18.75 6.75h-18M9.75 12.75h-9"/><path pid="1" d="M17.25 11.25a6 6 0 100 12 6 6 0 100-12zM17.25 14.25v6M17.25 20.25L15 18M17.25 20.25L19.5 18"/></g>'
  }
})
