import cloneDeep from 'lodash/cloneDeep';
import castArray from 'lodash/castArray';
import isEqual from 'lodash/isEqual';
import axios from '@/util/axios';
import Errors from './Errors';

export default class Form {
    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor (data) {
        this.processing = false;
        this.errors = new Errors();
        this.originalData = cloneDeep(data);

        Object.assign(this, cloneDeep(data));
    }

    /**
     * Fetch all or a part of relevant data for the form.
     *
     * @param {string|string[]|null} fields
     *
     * @return {object}
     */
    data (fields = null) {
        const keys = fields ? castArray(fields) : Object.keys(this.originalData);

        return keys.reduce((data, field) => {
            return { ...data, [field]: cloneDeep(this[field]) };
        }, {});
    }

    /**
     * Check if the form values have been modified.
     *
     * @return {boolean}
     */
    isModified () {
        return !isEqual(this.data(), this.originalData);
    }

    /**
     * Restore the form to its original state.
     */
    restore () {
        this.errors.clear();

        Object.assign(this, cloneDeep(this.originalData));
    }

    /**
     * Get an array of fields on the form.
     *
     * @returns {Array}
     */
    fields () {
        return Object.keys(this.originalData);
    }

    /**
     * Get an array of dirty fields.
     *
     * @return {string[]}
     */
    dirtyFields () {
        return this.fields().filter((field) => {
            return !isEqual(this[field], this.originalData[field]);
        });
    }

    /**
     * Send a request to a validation endpoint. Optionally provide an array of
     * fields to validate.
     *
     * @param {string} url
     * @param {string|string[]|null} fields
     * @param {object} additionalData
     * @param {string} requestType
     *
     * @return {Promise}
     */
    validate (url, fields = null, additionalData = {}, requestType = 'post') {
        return this.submit(requestType, url, fields, additionalData);
    }

    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     * @param {string|string[]|null} fields
     * @param {object} additionalData
     *
     * @return {Promise}
     */
    post (url, fields = null, additionalData = {}) {
        return this.submit('post', url, fields, additionalData);
    }

    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     * @param {string|string[]|null} fields
     * @param {object} additionalData
     *
     * @return {Promise}
     */
    put (url, fields = null, additionalData = {}) {
        return this.submit('put', url, fields, additionalData);
    }

    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     * @param {string|string[]|null} fields
     * @param {object} additionalData
     *
     * @return {Promise}
     */
    patch (url, fields = null, additionalData = {}) {
        return this.submit('patch', url, fields, additionalData);
    }

    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     * @param {string|string[]|null} fields
     * @param {object} additionalData
     *
     * @return {Promise}
     */
    delete (url, fields = null, additionalData = {}) {
        return this.submit('delete', url, fields, additionalData);
    }

    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     * @param {string|string[]} fields
     * @param {object} additionalData
     *
     * @return {Promise}
     */
    submit (requestType, url, fields = null, additionalData = {}) {
        this.processing = true;

        const data = { ...additionalData, ...this.data(fields) };

        return axios[requestType](url, requestType !== 'delete' ? data : { data })
            .then((response) => {
                this.onSuccess();

                return response;
            })
            .catch((error) => {
                this.onFail(error);

                return Promise.reject(error);
            })
            .finally(() => {
                this.processing = false;
            });
    }

    /**
     * Handle a successful form submission.
     */
    onSuccess () {
        this.errors.clear();
    }

    /**
     * Handle a failed form submission.
     *
     * @param {object} error
     */
    onFail (error) {
        if (error.response && error.response.status === 422) {
            this.errors.record(error.response.data.errors);
        }
    }
}
