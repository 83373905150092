<template>
    <div class="block lg:hidden justify-end mr-3 py-3">
        <div v-click-outside="clickOutside">
            <div class="text-right">
                <a
                    v-show="!opened"
                    class="text-white"
                    role="button"
                    @click="toggle"
                >
                    <app-icon name="navigation-menu" class="h-6 w-6 cursor-pointer"></app-icon>
                </a>

                <a
                    v-show="opened"
                    class="text-white"
                    role="button"
                    @click="toggle"
                >
                    <app-icon name="close" class="h-6 w-6 cursor-pointer"></app-icon>
                </a>
            </div>

            <transition name="fade">
                <div v-if="opened" class="absolute bg-black left-0 right-0 z-10 mt-10 mx-5 rounded-xl shadow-lg">
                    <div class="flex items-center justify-between p-4 cursor-pointer" @click="eventListOpened = !eventListOpened">
                        <div class="flex flex-col">
                            <div class="mb-1 p-0 text-xs block tracking-wide uppercase text-gray-500">
                                <span v-if="eventListOpened">Back to</span>
                                <span v-else>Event</span>
                            </div>
                            <div class="text-lg block">
                                <template v-if="eventListOpened">
                                    <span>Main menu</span>
                                </template>
                                <template v-else>
                                    <span v-if="event">
                                        {{ event.name }}
                                    </span>
                                    <span v-else>
                                        Select an event
                                    </span>
                                </template>
                            </div>
                        </div>
                        <div class="shrink">
                            <a class="text-purple-lighter cursor-pointer">
                                <app-icon
                                    :name="eventListOpened ? 'arrow-up-chevron' : 'arrow-down-chevron'"
                                    class="h-5 w-5"
                                    stroke
                                ></app-icon>
                            </a>
                        </div>
                    </div>
                    <div v-if="eventListOpened" class="w-full dropdown-inverse mb-2">
                        <div v-for="userEvent in listOfAvailableEvents" :key="userEvent.id">
                            <button class="dropdown-item p-4 no-underline" @click.prevent="selectEvent(userEvent)">
                                {{ userEvent.name }}
                            </button>
                        </div>
                        <template v-if="events.length > 5">
                            <div>
                                <a class="dropdown-item p-4 no-underline" :href="route('dashboard')">
                                    View All Events
                                </a>
                            </div>
                        </template>
                    </div>

                    <template v-if="!eventListOpened">
                        <div v-if="event" class="flex flex-wrap my-2 px-3">
                            <nav-mobile-event-page-button
                                route-name="events.dashboard.overview"
                                icon="dashboard-stroke"
                                text="Dashboard"
                                :event-id="event.id"
                            ></nav-mobile-event-page-button>

                            <nav-mobile-event-page-button
                                route-name="invite-list.index"
                                icon="list-check-stroke"
                                text="Invite List"
                                :event-id="event.id"
                            ></nav-mobile-event-page-button>

                            <template v-if="can('update.event')">
                                <nav-mobile-event-page-button
                                    route-name="publish-invite.index"
                                    icon="megaphone-stroke"
                                    text="Publish & Invite"
                                    :event-id="event.id"
                                ></nav-mobile-event-page-button>

                                <nav-mobile-event-page-button
                                    route-name="walls.edit"
                                    icon="settings-slider-stroke"
                                    text="Setup"
                                    :event-id="event.id"
                                ></nav-mobile-event-page-button>
                            </template>

                            <nav-mobile-event-page-button
                                icon="chair-stroke"
                                text="Seating"
                                :event-id="event.id"
                            ></nav-mobile-event-page-button>

                            <feature-enabled feature="check-in">
                                <nav-mobile-event-page-button
                                    icon="qr-code-scan"
                                    text="Check-In"
                                    :event-id="event.id"
                                ></nav-mobile-event-page-button>
                            </feature-enabled>
                        </div>

                        <div class="w-full dropdown-inverse mb-2">
                            <a :href="route('account.show')" class="dropdown-item p-4 no-underline">
                                <app-icon
                                    name="user-circle"
                                    class="h-5 w-5 mr-2 shrink-0"
                                    stroke
                                ></app-icon>
                                My Account
                            </a>

                            <a :href="route('account.shared-event-users')" class="dropdown-item p-4 no-underline">
                                <app-icon
                                    name="users-location"
                                    class="h-5 w-5 mr-2 shrink-0"
                                    stroke
                                ></app-icon>
                                Event Sharing
                            </a>

                            <a
                                href="https://help.rsvpify.com"
                                target="_blank"
                                class="dropdown-item p-4 no-underline"
                            >
                                <app-icon
                                    name="help-chat-alt"
                                    class="h-5 w-5 mr-2 shrink-0"
                                    stroke
                                ></app-icon>
                                Get Support
                            </a>

                            <a
                                href="https://community.rsvpify.com"
                                target="_blank"
                                class="dropdown-item p-4 no-underline"
                            >
                                <app-icon
                                    name="community-chat"
                                    class="h-5 w-5 mr-2 shrink-0"
                                ></app-icon>
                                Community Forum
                            </a>

                            <template v-if="showAdminDashboardOption">
                                <a :href="route('admin.dashboard')" class="dropdown-item p-4 no-underline">
                                    <app-icon
                                        name="dashboard"
                                        class="h-5 w-5 mr-2 shrink-0"
                                        stroke
                                    ></app-icon>
                                    Admin Dashboard
                                </a>
                            </template>

                            <a
                                v-if="auth().user().impersonated"
                                :href="route('admin.leave-impersonation')"
                                class="dropdown-item p-4 no-underline"
                            >
                                <app-icon
                                    name="dashboard"
                                    class="h-5 w-5 mr-2 shrink-0"
                                    stroke
                                ></app-icon>
                                Back to Admin
                            </a>

                            <a
                                href="/logout"
                                class="dropdown-item p-4 no-underline"
                                @click.prevent="logout"
                            >
                                <app-icon
                                    name="power-button"
                                    class="h-5 w-5 mr-2 shrink-0"
                                    stroke
                                ></app-icon>
                                Sign Out
                            </a>

                            <form
                                ref="logoutForm"
                                :action="route('logout')"
                                method="POST"
                            >
                                <input
                                    type="hidden"
                                    name="_token"
                                    :value="csrfToken"
                                >
                            </form>
                        </div>
                    </template>
                </div>
            </transition>
        </div>

        <portal to="pre-main-content-target">
            <div ref="mobileSpacerElement" class="block w-full md:hidden"></div>
        </portal>
    </div>
</template>

<script>
import find from 'lodash/find';
import CurrentEventUrlParser from '@/mixins/CurrentEventUrlParser';
import ReadsCsrfToken from '@/mixins/ReadsCsrfToken';
import NavMobileEventPageButton from './NavMobileEventPageButton';

export default {
    name: 'NavMobile',

    components: { NavMobileEventPageButton },

    mixins: [
        CurrentEventUrlParser,
        ReadsCsrfToken
    ],

    props: {
        events: {
            type: Array,
            default: () => { return ([]); }
        }
    },

    data () {
        return {
            event: null,
            opened: false,
            eventListOpened: false
        };
    },

    computed: {
        listOfAvailableEvents () {
            return this.events.filter((event) => {
                return !this.event || event.id !== this.event.id;
            }).splice(0, 5);
        },

        showAdminDashboardOption () {
            return this.auth().user().isAdmin
                || this.auth().user().isJuniorAdmin
                || this.auth().user().isLimitedAdmin;
        }
    },

    watch: {
        screenWidth (width, oldWidth) {
            if (width !== oldWidth) {
                this.setMobileSpacerElementHeight();
            }
        }
    },

    mounted () {
        this.$nextTick(() => {
            this.setMobileSpacerElementHeight();
        });

        if (this.currentUrlEventId) {
            this.selectEvent(find(this.events, { id: this.currentUrlEventId }));
        }
    },

    methods: {
        isNotLastEvent (index) {
            return index < this.listOfAvailableEvents.length - 1;
        },

        logout () {
            this.$refs.logoutForm.submit();
        },

        selectEvent (event) {
            this.event = event;
            this.eventListOpened = false;
        },

        toggle () {
            this.opened = !this.opened;

            // Blur should be applied differently, just for demonstration
            window.document.querySelectorAll('main, footer').forEach((element) => {
                element.classList.toggle('blur', this.opened);
            });

            window.document.querySelector('body').classList.toggle('overflow-y-hidden', this.opened);
        },

        clickOutside () {
            if (this.opened) {
                this.toggle();
            }
        },

        setMobileSpacerElementHeight () {
            const headerHeight = window.document.querySelector('#mainNavbarHeader').clientHeight;
            this.$refs.mobileSpacerElement.style.flex = `0 0 ${headerHeight}px`;
        }
    }
};
</script>
