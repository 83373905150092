<template>
    <div class="flex flex-col space-y-2 px-6 py-4 bg-white shadow ring-2 ring-transparent rounded-md text-gray-800">
        <event-actions :show-view-event="false"></event-actions>
        <p class="text-lg font-semibold">{{ event.name }}</p>
        <div class="flex flex-col space-y-2">
            <div v-if="showRegistrationOpensAtLabel" class="flex items-center space-x-2 w-full text-center">
                <app-icon name="calendar-clock-stroke" class="h-4 w-4"></app-icon>
                <p>
                    {{ registrationRsvpLabels.registrationOpensIn }} <span
                        v-tippy
                        :content="registrationOpensAtFormattedDateTime"
                        class="tooltip-text"
                    >{{ registrationOpensIn }}</span>.
                </p>

                <schedule-to-open-action v-slot="{ scheduleToOpen }">
                    <button class="flex items-center justify-center font-semibold text-purple hover:text-purple-light" @click="scheduleToOpen">
                        Edit
                    </button>
                </schedule-to-open-action>
            </div>
            <div v-if="showRegistrationClosesAtLabel" class="flex items-center space-x-2 w-full text-center">
                <app-icon name="calendar-clock-stroke" class="h-4 w-4"></app-icon>
                <p>
                    Deadline in <span
                        v-tippy
                        :content="registrationClosesAtFormattedDateTime"
                        class="tooltip-text"
                    >{{ registrationClosesIn }}</span>.
                </p>

                <set-registration-deadline-action v-slot="{ setRegistrationDeadline }">
                    <button class="flex items-center justify-center font-semibold text-purple hover:text-purple-light" @click="setRegistrationDeadline">
                        Edit
                    </button>
                </set-registration-deadline-action>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import IsEventPublished from '@/mixins/IsEventPublished';
import FormOpensAtClosesAtFormattedDateTime from '@/mixins/FormOpensAtClosesAtFormattedDateTime';
import RegistrationRSVPLabels from '@/mixins/RegistrationRSVPLabels';

export default {
    name: 'PublishEvent',

    mixins: [IsEventPublished, FormOpensAtClosesAtFormattedDateTime, RegistrationRSVPLabels],

    computed: {
        event: get('Event/event'),
        form: get('Form/form'),
        wall: get('Wall/wall'),

        showRegistrationClosesAtLabel () {
            return this.form.closed_at && !this.form.isClosed;
        },

        showRegistrationOpensAtLabel () {
            return this.form.opened_at && !this.form.isOpened && !this.form.isClosed;
        }
    }
};
</script>
