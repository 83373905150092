/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music-clef-sheet-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 4.5h4.5M17.24 4.5h5.99M.75 9.75h2.99M15.74 9.78l7.51-.03M.75 15h2.24M16.49 15h6.75M.75 20.25L3 20.24M15 20.25h8.24M10.626 18.17h0c.16.86.28 1.74.35 2.63l-.01-.01a2.37 2.37 0 01-2.3 2.44h-.15 0a2.454 2.454 0 01-2.45-2.45v-.01"/><path pid="1" d="M9.31 15.52l-.01-.01c-.58-.93-.3-2.16.63-2.74.31-.2.66-.3 1.03-.31 1.08 0 1.95.97 1.95 2.44v0c0 1.89-1.54 3.42-3.43 3.42a3.43 3.43 0 01-3.43-3.43c0-2.7 2.03-4.25 4.89-5.381 3.47-1.38 3.42-8.8.97-8.8s-2.94 4.39-2.94 6.84l-.01-.01c.12 1.79.35 3.58.69 5.34"/></g>'
  }
})
