/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-human-transmit-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 13.69a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM16.68 11.12h1.14M17.25 11.12v2.57M21.09 12.48l.81.8M21.49 12.88l-1.82 1.82M23.25 16.55v1.14M23.25 17.12h-2.57M21.9 20.96l-.81.81M21.49 21.36l-1.82-1.81M17.82 23.12h-1.14M17.25 23.12v-2.57M13.41 21.77l-.81-.81M13.01 21.36l1.82-1.81M11.25 17.69v-1.14M11.25 17.12h2.57M12.6 13.28l.81-.8M13.01 12.88l1.82 1.82M6.16 3.87a3.31 3.31 0 100 6.62 3.31 3.31 0 100-6.63zM.75 17.12H.74c0-3 2.42-5.42 5.42-5.42.73 0 1.45.14 2.12.43M14.007 7.87l.24-.97h-.01a3.998 3.998 0 013.87-3.04h4.87"/><path pid="1" d="M20.007 6.87l3-3-3-3"/></g>'
  }
})
