/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'technology-mobile-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>cell phone android ios iphone samsung</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="13.5" height="22.5" x="5.251" y=".75" rx="3"/><path pid="1" d="M5.25 18.75h13.5"/></g>'
  }
})
