<template>
    <div class="sm:grid sm:grid-cols-2 sm:gap-x-4 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        <div
            v-for="template in templates"
            :key="template.id"
            class="flex flex-col mb-8"
        >
            <div class="group relative aspect-[1.23] overflow-hidden border-gray-300 rounded bg-white">
                <img
                    v-if="template.previewImageUrl"
                    class="w-full transition group-hover:blur"
                    :src="template.previewImageUrl"
                >

                <div class="absolute z-10 inset-0 h-full w-full px-4 hidden group-hover:flex flex-col justify-center space-y-4">
                    <a
                        v-if="template.showcaseUrl"
                        class="button button-sm button-primary-soft normal-case preview-button"
                        :href="template.showcaseUrl"
                        target="_blank"
                    >
                        Preview
                    </a>

                    <button
                        class="button button-sm button-primary-soft normal-case !py-3 select-button"
                        type="button"
                        @click="$emit('select', template)"
                    >
                        Select
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TemplateGrid',

    props: {
        templates: {
            type: Array,
            default: () => { return []; }
        }
    }
};
</script>
