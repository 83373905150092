/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up-thick-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sort ascending</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.894 23.25l-.01-.001c1.24 0 2.25-1.01 2.25-2.25V8.42l3.05 3.05h0c.87.87 2.3.87 3.18-.01.87-.88.87-2.31-.01-3.19l-.01-.01-6.9-6.9-.01-.01c-.88-.88-2.31-.88-3.19-.01h-.01l-6.9 6.89h-.01c-.88.87-.88 2.3 0 3.18.87.87 2.3.87 3.18-.01l3.05-3.05v12.568h0c0 1.24 1 2.24 2.25 2.24 0 0 0 0 0 0z"/>'
  }
})
