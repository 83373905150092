<template>
    <div>
        <div v-theme="'wall.background'" class="relative map-block pt-6">
            <div
                v-if="displayTitle"
                v-theme="['wall.title-color', 'wall.title-font', 'wall.title-font-size']"
                class="flex justify-center items-center flex-auto mb-6 font-bold leading-normal"
            >
                <app-icon
                    v-if="icon"
                    :name="icon"
                    class="h-8 w-8 mr-4"
                ></app-icon>

                {{ title }}
            </div>

            <div
                v-if="locationToDisplay.address === '' && locationToDisplay.venue === ''"
                v-theme="['wall.text-color']"
                class="italic text-center p-4 text-sm"
            >
                Please set your event location in <a v-theme="['wall.text-color']" :href="route('settings.event.details', event)">Event Settings > Event Details.</a>
            </div>

            <location-map
                v-if="locationToDisplay"
                class="h-80"
                :address="locationToDisplay"
            ></location-map>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    name: 'WallMapBlock',

    props: {
        displayTitle: {
            type: Boolean,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        location: {
            type: String,
            default: null
        },
        locationToUse: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },

    computed: {
        event: get('Event/event'),

        locationToDisplay () {
            return this.locationToUse === 'custom' ? this.location : {
                address: this.event.location?.address || '',
                venue: this.event.location?.venue_name || ''
            };
        }
    }
};
</script>
