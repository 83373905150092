<template>
    <accordion-list v-cloak class="my-8">
        <accordion-list-item item-id="advanced-settings">
            <template slot="header">Advanced Settings</template>
            <template slot="content">
                <form-field-wrapper label="Fields" class="mt-8">
                    <div class="flex flex-col space-y-2">
                        <toggle-switch
                            :value="settings.includeStreetAddressField"
                            small
                            disabled
                            @input="newValue => updateSetting('includeStreetAddressField', newValue)"
                        >
                            Street Address
                        </toggle-switch>

                        <toggle-switch
                            :value="settings.includeSecondStreetAddressField"
                            small
                            @input="newValue => updateSetting('includeSecondStreetAddressField', newValue)"
                        >
                            Street Address 2
                        </toggle-switch>

                        <toggle-switch
                            :value="settings.includeCityField"
                            small
                            @input="newValue => updateSetting('includeCityField', newValue)"
                        >
                            City
                        </toggle-switch>

                        <toggle-switch
                            :value="settings.includeStateField"
                            small
                            @input="newValue => updateSetting('includeStateField', newValue)"
                        >
                            State
                        </toggle-switch>

                        <toggle-switch
                            :value="settings.includeZipCodeField"
                            small
                            @input="newValue => updateSetting('includeZipCodeField', newValue)"
                        >
                            Zip Code
                        </toggle-switch>

                        <toggle-switch
                            :value="settings.includeCountryField"
                            small
                            @input="newValue => updateSetting('includeCountryField', newValue)"
                        >
                            Country
                        </toggle-switch>
                    </div>
                </form-field-wrapper>

                <form-field-wrapper v-if="showStateFieldOptions" label="State Field">
                    <select-list :value="settings.stateField" @input="newValue => updateSetting('stateField', newValue)">
                        <select-option id="textBox" class="my-2">
                            Text Box
                        </select-option>
                        <select-option id="usStates" class="my-2">
                            U.S. States Dropdown
                        </select-option>
                    </select-list>
                </form-field-wrapper>

                <form-field-wrapper v-if="showDefaultCountryPicker" label="Default Country">
                    <country-picker
                        :value="settings.defaultCountry"
                        item-value="name"
                        @input="newValue => updateSetting('defaultCountry', newValue)"
                    ></country-picker>
                </form-field-wrapper>
            </template>
        </accordion-list-item>
    </accordion-list>
</template>

<script>
import CustomQuestionSettings from '@/mixins/CustomQuestionSettings';

export default {
    name: 'FormMailingAddressQuestionSettings',

    mixins: [CustomQuestionSettings],

    computed: {
        namespace () {
            return 'mailingAddress';
        },

        showDefaultCountryPicker () {
            return this.settings.includeCountryField && this.settings.stateField !== 'usStates';
        },

        showStateFieldOptions () {
            return this.settings.includeStateField;
        }
    },

    watch: {
        'settings.includeCountryField': function (value) {
            if (value) {
                this.settings.stateField = 'textBox';
            }
        },

        'settings.stateField': function (value) {
            this.settings.includeCountryField = value === 'textBox';
        }
    }
};
</script>
