<template>
    <base-dropdown
        class="w-full"
        placement="bottom-start"
        :position-fixed="false"
        :bounded-by-viewport="false"
    >
        <template slot="trigger" slot-scope="{ triggerFunction }">
            <div
                class="h-3 border border-transparent"
                :class="styles"
                :style="{ backgroundColor: colors[colorType] }"
                @click="toggle(triggerFunction)"
            ></div>
        </template>

        <sketch-color-picker
            ref="colorPicker"
            :value="colors"
            class="mt-4"
            :preset-colors="presetColors"
            :disable-alpha="!hasAlpha"
            @input="updateColor"
        ></sketch-color-picker>
    </base-dropdown>
</template>

<script>
import ColorPicker from '@/components/core/pickers/ColorPicker';

export default {
    name: 'ColorBar',

    extends: ColorPicker,

    props: {
        hasAlpha: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        styles () {
            return {
                'hover:border-white cursor-pointer': !this.disabled
            };
        }
    }
};
</script>
