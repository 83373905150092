/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'night-club-disco-ball-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9.75 5.25a9 9 0 100 18 9 9 0 100-18z"/><path pid="1" d="M10.123 23.23c2.42-1.48 4.12-4.95 4.12-8.987 0-4.05-1.71-7.52-4.14-8.992M9.75 5.25V.75M9.377 23.23c-2.43-1.48-4.13-4.95-4.13-8.99 0-4.041 1.7-7.52 4.138-8.993M17.25.75v3M15.75 2.25h3M21.75 5.25v3M20.25 6.75h3"/><path pid="2" d="M9.75 11.25a9 3 0 100 6 9 3 0 100-6z"/></g>'
  }
})
