/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-allergy-gluten-free-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>bread wheat</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18 18.37l-1.59-1.59M13.333 18.31v-.001c.76-.01 1.37-.63 1.37-1.4-.01-.09-.01-.17-.03-.25h0a1.4 1.4 0 00-1.4-1.38c-.09 0-.17 0-.25.02-1.03.1-2.93.95-2.85 1.782.08.82 2.09 1.3 3.12 1.2zM9.581 14.55v-.001c.76-.01 1.37-.63 1.37-1.4-.01-.09-.01-.17-.03-.25h0c-.01-.77-.63-1.38-1.4-1.38-.09 0-.17 0-.25.02-1.04.1-2.93.95-2.85 1.78.08.82 2.1 1.3 3.13 1.2zM17.94 13.7v-.01a1.39 1.39 0 01-1.39 1.37c-.09-.01-.17-.01-.26-.03l-.01-.001a1.4 1.4 0 01-1.38-1.4c0-.09 0-.17.02-.25.1-1.03.95-2.93 1.78-2.85.82.07 1.3 2.09 1.2 3.12zM14.189 9.951v-.01c-.01.76-.63 1.37-1.4 1.37-.09-.01-.17-.01-.25-.03h0c-.77-.01-1.38-.63-1.38-1.39 0-.09 0-.17.02-.25.1-1.04.95-2.93 1.78-2.85.82.08 1.3 2.09 1.2 3.13zM8.984 7.23l-.01-.01c.58.48.66 1.36.17 1.94-.06.06-.12.12-.18.17h-.01a1.39 1.39 0 01-2.13-.01c-.74-.74-1.646-2.6-1.061-3.19.585-.59 2.45.32 3.18 1.06z"/><path pid="1" d="M12 .73a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5zM19.95 4.03l-2.7 2.71M4.05 19.94l2.7-2.7"/></g>'
  }
})
