/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'concert-beach-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>music party</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.13 8.48l-.76 14.76M4.29 23.25L9.23 7.86M9.23 7.85l8.276 2.7-.01-.01c.39.13.81-.08.95-.48a.74.74 0 00.02-.39h0a4.745 4.745 0 00-3.6-4.02c-3.75-.75-5.25 1.5-5.25 1.5l-.4.66"/><path pid="1" d="M9.23 7.85L1.69 9.89v-.01c-.41.1-.82-.13-.93-.53A.701.701 0 01.74 9v-.01A4.74 4.74 0 014.35 4.9c2.18-.44 4.27 0 5.528 1.92M14.078 5.568l3.1-3.117h-.01a.764.764 0 00-.21-1.22h0a4.879 4.879 0 00-5.09.5c-1.7 1.27-1.5 3.938-1.5 3.938v.76"/><path pid="2" d="M5.679 4.785l-1.95-2.12h0a.745.745 0 01.03-1.06c.09-.1.22-.16.35-.19h-.01c.82-.16 1.66-.24 2.5-.24h0c1.66.15 3.14 1.11 3.96 2.57M.75 23.25h22.5M19.5 4.5h3M21 3v3M14.25 18h3M15.75 16.5v3M21 13.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM22.5 15v-4.5"/></g>'
  }
})
