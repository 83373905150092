<template>
    <div
        v-click-outside="close"
        class="relative"
    >
        <div
            ref="trigger"
            class="h-full"
            :class="triggerClass"
        >
            <slot
                name="trigger"
                :trigger-function="toggle"
                :is-open="isOpen"
            ></slot>
        </div>

        <div
            v-show="isOpen"
            ref="dropdown"
            class="rounded-md z-50"
        >
            <slot :trigger-function="toggle" :is-open="isOpen"></slot>
        </div>
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
    props: {
        placement: {
            type: String,
            default: 'bottom-start'
        },
        positionFixed: {
            type: Boolean,
            default: true
        },
        boundedByViewport: {
            type: Boolean,
            default: true
        },
        triggerClass: {
            type: String,
            default: ''
        },
        initialState: {
            type: String,
            default: 'closed'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            isOpen: false
        };
    },

    beforeDestroy () {
        this.popper?.destroy();
    },

    mounted () {
        this.initializePopper();

        if (this.initialState === 'open') {
            this.open();
        }
    },

    methods: {
        toggle () {
            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        },

        open () {
            if (this.disabled) {
                return;
            }

            this.isOpen = true;
            this.$emit('open');

            this.$nextTick(() => {
                this.popper?.update();
            });
        },

        close () {
            if (this.isOpen) {
                this.$emit('close');

                this.isOpen = false;
            }
        },

        initializePopper () {
            if (this.popper) {
                return;
            }

            const modifiers = this.boundedByViewport ? [
                {
                    name: 'flip',
                    options: {
                        altBoundary: true
                    }
                },
                {
                    name: 'preventOverflow',
                    options: {
                        altBoundary: true
                    }
                }
            ] : [];

            this.popper = createPopper(this.$refs.trigger, this.$refs.dropdown, {
                modifiers,
                placement: this.placement,
                strategy: this.positionFixed ? 'fixed' : 'absolute'
            });
        }
    }
};
</script>
