<template>
    <accordion-list
        v-cloak
        id="accordion-event-nav"
        v-model="activeAccordionItems"
        class="select-none"
    >
        <template v-for="navItem in activeNavItems">
            <template v-if="(navItem.type === 'divider' || navItem.type === 'header') && !collapsed">
                <div class="hidden md:block">
                    <hr v-if="navItem.type === 'divider'" class="w-10/12 h-px my-4 mx-auto bg-violet-700 border-0 rounded">
                    <p v-if="navItem.name" class="ml-4 mb-4 uppercase text-purple-200 text-sm">{{ navItem.name }}</p>
                </div>
            </template>

            <accordion-list-item
                v-else-if="navItem.subItems && navItem.subItems.length > 0"
                :key="navItem.id"
                :item-id="navItem.id"
                :expand-collapse-icon="false"
                class="cursor-pointer"
            >
                <template slot="header">
                    <div
                        class="flex w-full items-center py-2 border-l-4 cursor-pointer transition duration-150 ease-in-out nav-item"
                        :class="{'px-9': !collapsed, 'px-2': collapsed}"
                        :data-id="navItem.id"
                    >
                        <div class="flex items-center flex-auto">
                            <app-icon :name="navItem.icon" class="w-5 h-5"></app-icon>
                            <p v-if="!collapsed" class="text-sm font-normal ml-5">
                                <span
                                    v-if="navItem.locked"
                                    v-tippy
                                    content="Read-only users do not have access to this section."
                                    class="mr-1"
                                >
                                    <app-icon name="lock" class="w-3 h-3"></app-icon>
                                </span>
                                {{ navItem.name }}
                            </p>
                        </div>

                        <app-icon
                            :name="getAccordionChevronIcon(navItem.id)"
                            class="h-4 w-4 text-gray-600"
                        ></app-icon>
                    </div>
                </template>

                <template v-if="!collapsed" slot="content">
                    <div class="flex flex-col my-2 ml-6">
                        <div
                            v-for="subItem in navItem.subItems"
                            :key="subItem.id"
                            class="flex items-center py-1 text-sm transition duration-150 ease-in-out nav-sub-item"
                            :data-id="subItem.id"
                        >
                            <a
                                :href="subItem.locked ? false : subItem.route"
                                class="pl-14"
                                :class="{'w-full': subItem.id !== 'event-wall'}"
                            >
                                <span
                                    v-if="subItem.locked"
                                    v-tippy
                                    content="Read-only users do not have access to this section."
                                    class="mr-1"
                                >
                                    <app-icon name="lock" class="w-3 h-3"></app-icon>
                                </span>

                                {{ subItem.name }}

                                <span v-if="subItem.status" class="text-gray-600">
                                    {{ subItem.status }}
                                </span>
                            </a>
                        </div>
                    </div>
                </template>
            </accordion-list-item>

            <accordion-event-nav-item-popover
                v-else
                :key="navItem.id"
                :component="navItem.popover"
            >
                <a
                    :key="navItem.id"
                    v-tippy="{...tippyOptions, content: navItem.tooltip}"
                    :href="navItem.route"
                    :data-id="navItem.id"
                    :target="navItem.target ?? '_self'"
                    class="flex w-full items-center py-2 border-l-4 cursor-pointer transition duration-150 ease-in-out nav-item"
                    :class="{'px-9': !collapsed, 'px-2': collapsed}"
                    @click="navClick(navItem)"
                >
                    <div class="flex items-center flex-auto">
                        <app-icon :name="navItem.icon" class="w-5 h-5"></app-icon>
                        <span
                            v-if="navItem.locked"
                            v-tippy
                            content="Read-only users do not have access to this section."
                            class="mr-1"
                        >
                            <app-icon name="lock" class="w-5 h-5"></app-icon>
                        </span>

                        <p v-if="!collapsed" class="text-sm font-normal ml-5">
                            {{ navItem.name }}
                        </p>

                        <span
                            v-if="navItem.id === 'event-wall' && userHasEditAccess && !collapsed"
                            v-tippy="{ boundary: 'viewport' }"
                            class="ml-2 -mb-1"
                            content="Turn off the “Event Website.” Your event page will only show RSVP or registration form."
                        >
                            <event-wall-activity-toggle :is-wall-active="isWallActive"></event-wall-activity-toggle>
                        </span>

                        <app-icon
                            v-if="navItem.id === 'preview' && !collapsed"
                            name="share-button"
                            class="w-4 h-4 ml-2 text-gray-500"
                        ></app-icon>

                        <app-icon
                            v-if="navItem.id === 'publish' && publishing && !collapsed"
                            class="h-4 w-4 ml-4"
                            name="loader"
                        ></app-icon>
                    </div>
                </a>
            </accordion-event-nav-item-popover>
        </template>
    </accordion-list>
</template>

<script>
import { get } from 'vuex-pathify';
import InteractsWithFeatureFlags from '@/mixins/InteractsWithFeatureFlags';

export default {
    name: 'AccordionEventNav',

    mixins: [InteractsWithFeatureFlags],

    props: {
        event: {
            type: Object,
            required: true
        },

        campaignCounts: {
            type: Object,
            required: true
        },

        collapsed: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            activeAccordionItems: [],
            publishing: false
        };
    },

    computed: {
        ...get('Wall/wall@', {
            isWallActive: 'active'
        }),

        tippyOptions () {
            return {
                placement: 'right',
                theme: 'light bold',
                touch: false,
                popperOptions: {
                    modifiers: [
                        {
                            name: 'flip'
                        }
                    ]
                }
            };
        },

        activeNavItems () {
            const isNavItemActive = (navItem) => {
                if (navItem.hidden) {
                    return false;
                }
                return navItem.featureFlag === undefined || this.isFeatureEnabled(navItem.featureFlag);
            };

            return this.navItems.filter(isNavItemActive)
                .map((navItem) => {
                    return {
                        ...navItem,
                        subItems: navItem.subItems ? navItem.subItems.filter(isNavItemActive) : []
                    };
                });
        },

        navItems () {
            return [
                {
                    id: 'dashboard-divider',
                    type: 'header',
                    name: 'Dashboard'
                },
                {
                    id: 'checklist',
                    name: 'Event Checklist',
                    locked: false,
                    route: this.route('events.tasks.index', this.event),
                    icon: 'list-check-stroke',
                    subItems: []
                },
                {
                    id: 'dashboard',
                    name: 'Reporting',
                    locked: false,
                    route: '',
                    icon: 'chart-stroke',
                    subItems: [
                        {
                            id: 'overview',
                            name: 'Overview',
                            locked: false,
                            route: this.route('events.dashboard.overview', this.event)
                        },
                        {
                            id: 'secondary-events',
                            name: 'Secondary Events',
                            locked: false,
                            route: this.route('events.dashboard.secondary-events', this.event),
                            hidden: !!this.event.classyIntegration
                        },
                        {
                            id: 'custom-questions',
                            name: 'Custom Questions',
                            locked: false,
                            route: this.route('events.dashboard.custom-questions', this.event)
                        },
                        {
                            id: 'meal-preferences',
                            name: 'Meal Preferences',
                            locked: false,
                            route: this.route('events.dashboard.meal-preferences', this.event),
                            hidden: !!this.event.classyIntegration
                        },
                        {
                            id: 'notes-from-guests',
                            name: 'Notes from Guests',
                            locked: false,
                            route: this.route('events.dashboard.notes', this.event),
                            hidden: !this.event.hasNoteBlock || !!this.event.classyIntegration
                        },
                        {
                            id: 'sales',
                            name: 'Sales',
                            locked: false,
                            route: this.route('events.dashboard.sales', this.event),
                            hidden: !this.event.hasPayableItems || !!this.event.classyIntegration
                        },
                        {
                            id: 'gifts-and-donations',
                            name: 'Donations & Gifts',
                            locked: false,
                            route: this.route('events.dashboard.donations', this.event),
                            hidden: !!this.event.classyIntegration
                        }
                    ]
                },
                {
                    id: 'reporting-divider',
                    type: 'divider',
                    name: 'Customize'
                },
                {
                    id: 'event-wall',
                    name: 'Event Website Builder',
                    locked: !this.userHasEditAccess,
                    route: this.route('walls.edit', this.event),
                    icon: 'app-page-text',
                    hidden: !!this.event.classyIntegration
                },
                {
                    id: 'form-builder',
                    name: 'Form Builder',
                    locked: !this.userHasEditAccess,
                    route: this.route('forms.edit', this.event),
                    icon: 'write-stroke',
                    hidden: !!this.event.classyIntegration
                },
                {
                    id: 'event-settings',
                    name: 'Event Settings',
                    locked: !this.userHasEditAccess,
                    route: this.route('settings.event', this.event),
                    icon: 'settings-cog-stroke',
                    hidden: !!this.event.classyIntegration
                },
                {
                    id: 'invite-list',
                    name: 'Invite List',
                    route: '',
                    icon: 'user-process-stroke',
                    hidden: !!this.event.classyIntegration,
                    subItems: [
                        {
                            id: 'invitees',
                            name: 'Invitees',
                            route: this.route('invite-list.index', this.event)
                        },
                        {
                            id: 'invite-list-import',
                            name: 'Import',
                            locked: !this.userHasEditAccess,
                            route: this.route('invite-list.import', this.event)
                        },
                        {
                            id: 'invite-list-settings',
                            name: 'Settings',
                            locked: !this.userHasEditAccess,
                            route: this.route('invite-list.settings', this.event)
                        }
                    ]
                },
                {
                    id: 'launch-divider',
                    type: 'divider',
                    name: 'Launch'
                },
                {
                    id: 'preview',
                    name: 'Preview',
                    locked: false,
                    icon: 'view',
                    hidden: !!this.event.classyIntegration,
                    subItems: []
                },
                {
                    id: 'publish',
                    name: 'Publish',
                    locked: !this.userHasEditAccess,
                    icon: 'rocket-flying-stroke',
                    hidden: !!this.event.classyIntegration,
                    subItems: []
                },
                {
                    id: 'share-and-invite',
                    name: 'Share & Invite',
                    locked: false,
                    route: this.route('publish-invite.index', this.event),
                    icon: 'megaphone-stroke',
                    hidden: !!this.event.classyIntegration,
                    subItems: []
                },
                {
                    id: 'manage-divider',
                    type: 'divider',
                    name: 'Organize'
                },
                {
                    id: 'email',
                    name: 'Email Communications',
                    route: '',
                    icon: 'email-send-stroke',
                    subItems: [
                        {
                            id: 'new-email',
                            name: 'New Email',
                            locked: !this.userHasEditAccess,
                            route: this.route('events.campaigns.drafts.create', this.event)
                        },
                        {
                            id: 'draft-emails',
                            name: 'Drafts',
                            route: this.route('events.campaigns.drafts.index', this.event),
                            status: `${this.campaignCounts.drafts}`
                        },
                        {
                            id: 'scheduled-emails',
                            name: 'Scheduled',
                            route: this.route('events.campaigns.scheduled.index', this.event),
                            status: `${this.campaignCounts.scheduled}`
                        },
                        {
                            id: 'sent-emails',
                            name: 'Sent',
                            route: this.route('events.campaigns.sent.index', this.event),
                            status: `${this.campaignCounts.sent}`
                        }
                    ]
                },
                {
                    id: 'co-managers',
                    name: 'Invite Event Collaborator',
                    route: this.route('account.shared-event-users'),
                    icon: 'users-location-stroke',
                    subItems: []
                },
                {
                    id: 'seating',
                    name: 'Seating',
                    route: this.route('events.seating-charts.index', this.event),
                    icon: 'chair-stroke',
                    subItems: []
                },
                {
                    id: 'check-in',
                    name: 'Check-in',
                    route: this.route('check-in.suite', this.event),
                    icon: 'technology-qr-code-scan',
                    subItems: [],
                    hidden: this.auth().plan().features.CheckIn.type === 'hide'
                },
                {
                    id: 'referral-divider',
                    type: 'divider',
                    featureFlag: 'rewardful'
                },
                {
                    id: 'refer-and-earn',
                    name: 'Refer & Earn',
                    featureFlag: 'rewardful',
                    icon: 'gift-stroke',
                    subItems: [],
                    tooltip: 'Refer RSVPify and get a free month!',
                    popover: 'refer-a-friend-popover'
                }
            ];
        },

        userHasEditAccess () {
            return this.auth().user().can('update.event');
        }
    },

    mounted () {
        this.initializeAccordion();
    },

    methods: {
        getAccordionChevronIcon (accordionId) {
            return this.activeAccordionItems.includes(accordionId) ? 'arrow-down-chevron-stroke' : 'arrow-right-chevron-stroke';
        },

        initializeAccordion () {
            /**
             * We're "manually" finding the active menu item because the
             * component was very slow when dealing with computed properties
             * and recalculating the styles for each menu item using a
             * method that would call isActiveRoute().
             *
             * The component would recalculate the styles unnecessarily,
             * so now we're only finding the active menu items during
             * initialization and setting an 'active' class on them. Browser
             * handles the rest with CSS from navigation.css
             */
            this.navItems.find((navItem) => {
                if (navItem.type === 'divider') {
                    return false;
                }

                let isNavItemActive = navItem.route && this.isActiveRoute(navItem.route.name);

                if (navItem.subItems && navItem.subItems.length > 0) {
                    const activeSubItem = navItem.subItems.find((subItem) => {
                        return this.isActiveRoute(subItem.route.name);
                    });

                    if (activeSubItem !== undefined) {
                        isNavItemActive = true;
                        document.querySelector(`#accordion-event-nav .nav-sub-item[data-id='${activeSubItem.id}']`)?.classList?.add('active');
                    }
                }

                if (isNavItemActive) {
                    document.querySelector(`#accordion-event-nav .nav-item[data-id='${navItem.id}']`)?.classList?.add('active');
                    this.activeAccordionItems.push(navItem.id);

                    return true;
                }

                return false;
            });
        },

        isActiveRoute (routeName) {
            return routeName === this.route().current() || this.route().current(`${routeName}.*`);
        },

        navClick (navItem) {
            if ((navItem.id !== 'publish' && navItem.id !== 'preview') || !this.userHasEditAccess) {
                return;
            }

            if (navItem.id === 'preview') {
                App.$emit('previewed-event');
                window.open(this.event.previewUrl, '_blank');
            }

            if (navItem.id === 'publish') {
                this.publishing = true;
                App.$emit('publish-event');

                App.$on('publish-flow-completed', () => {
                    this.publishing = false;
                });

                App.$on('publish-flow-errored', () => {
                    this.publishing = false;
                });
            }
        }
    }
};
</script>
