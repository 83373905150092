<template>
    <div class="pt-2 pl-2">
        <div
            v-for="product in visibleProducts"
            :key="product.id"
            class="mb-4 last:mb-0"
        >
            <decision-input
                :title="product.title"
                :price="getProductPrice(product)"
                :icon="product.settings.icon"
                :description="product.settings.description"
                :value="invitee.selection === product.id"
                :class="isUnavailable(product) ? 'opacity-25' : ''"
                :availability-message="getAvailabilityMessage(product)"
                @input="handleItemSelected(product)"
            >
                <div v-if="product.short_guest_state === 'Attending'">
                    <div
                        v-if="canBringAdditionalGuests"
                        :class="{ 'opacity-50': !isAttending }"
                    >
                        <div class="w-28 mt-2 text-xl">
                            <number-picker
                                v-model="additionalGuestsNumber"
                                v-theme="['form.accent', 'form.input-border-accent']"
                                :min-value="0"
                                :max-value="getAdditionalGuestLimit(product)"
                                :disabled="!isAttending"
                            >
                                <template slot="decrement-icon">
                                    <app-icon
                                        name="remove-circle"
                                        class="w-7 h-7"
                                        stroke
                                    ></app-icon>
                                </template>
                                <template slot="increment-icon">
                                    <app-icon
                                        name="add-circle"
                                        class="w-7 h-7"
                                        stroke
                                    ></app-icon>
                                </template>
                            </number-picker>

                            <div class="mt-1 text-xs text-center opacity-75">
                                {{ $t('text-additional-guests') }}
                            </div>
                        </div>

                        <additional-guests
                            v-model="additionalGuests"
                            :invitee="invitee"
                            :quantity="additionalGuestsNumber"
                            :product="product"
                            class="mt-4"
                            :require-guest-title="requireGuestTitle"
                        ></additional-guests>
                    </div>
                </div>

                <button
                    v-if="!editing"
                    v-theme="'form.accented-text'"
                    class="button-text text-sm font-normal mt-2"
                    @click.stop="editing = true"
                >
                    {{ $t('button-edit-selection') }}
                </button>
            </decision-input>
        </div>
    </div>
</template>

<script>
import find from 'lodash/find';
import getValue from 'lodash/get';
import { get } from 'vuex-pathify';

export default {
    name: 'RsvpSelection',

    provide: {
        isMultipleChoice: false
    },

    props: {
        invitee: {
            type: Object,
            required: true
        },

        products: {
            type: Array,
            required: true
        },

        remainingQuantities: {
            type: Object,
            required: true
        },

        requireGuestTitle: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            editing: true,
            additionalGuests: [],
            additionalGuestsNumber: 0
        };
    },

    computed: {
        ...get('Event/*'),

        attendingProduct () {
            return find(this.products, {
                short_guest_state: 'Attending'
            });
        },

        canBringAdditionalGuests () {
            return this.invitee.additional_guests_limit !== 0;
        },

        isAttending () {
            return this.invitee.selection === this.attendingProduct.id;
        },

        visibleProducts () {
            if (this.editing) {
                return this.products;
            }

            return this.products.filter(({ id }) => {
                return this.invitee.selection === id;
            });
        }
    },

    watch: {
        additionalGuests: {
            deep: true,
            handler () {
                this.$emit('update-additional-guests', this.additionalGuests);
            }
        }
    },

    created () {
        this.additionalGuests = getValue(this.invitee, 'additionalGuests', []);
        this.additionalGuestsNumber = this.additionalGuests.length;
    },

    methods: {
        getAdditionalGuestLimit (product) {
            return Math.min(
                this.remainingQuantities[product.id] + this.additionalGuests.length,
                this.invitee.additional_guests_limit === null ? 99 : this.invitee.additional_guests_limit,
                product.settings?.maximumSelectable ?? 99
            );
        },

        getAvailabilityMessage (product) {
            if (!this.isUnavailable(product)) {
                return '';
            }

            const availabilitySettings = product.settings.maxCapacity || {};

            return availabilitySettings.showMessage ? availabilitySettings.message : '';
        },

        getProductPrice (product) {
            return product.settings.costsMoney ? product.price : null;
        },

        handleItemSelected (product) {
            if (!this.editing || this.isUnavailable(product)) {
                return;
            }

            this.editing = false;

            this.$emit('change-selection', Number(product.id));
        },

        isUnavailable (product) {
            if (product.id === this.invitee.selection) {
                return false;
            }

            return this.remainingQuantities[product.id] === 0;
        }
    }
};
</script>
