/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'casino-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>gamble money coin 777 slots</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="18" height="18" x=".75" y="5.25" rx="1"/><path pid="1" d="M.75 8.25h18M.75 15.75h18M6.75 8.25v7.5M12.75 8.25v7.5M16.5 5.25v-.375C16.5 2.6 13.47.75 9.75.75 6.02.75 3 2.6 3 4.875v.375M3.75 20.25h3M21.75 12v5.25c0 1.65-1.35 3-3 3M3.75 11.625h-.01c.2-.01.37.16.37.37M3.37 12h0c-.01-.21.16-.38.37-.38"/><path pid="2" d="M3.75 12.37l-.01-.001a.386.386 0 01-.38-.38v-.001M4.12 12v0c0 .2-.17.375-.375.375M9.75 11.625h-.01c.2-.01.37.16.37.37M9.37 12h0c-.01-.21.16-.38.37-.38"/><path pid="3" d="M9.75 12.37l-.01-.001a.386.386 0 01-.38-.38s0 0 0 0M10.125 12v0c0 .2-.17.375-.375.375M15.75 11.625h-.01c.2-.01.37.16.37.37M15.37 12h0c-.01-.21.16-.38.37-.38"/><path pid="4" d="M15.75 12.37l-.01-.001a.386.386 0 01-.38-.38s0 0 0 0M16.125 12v0c0 .2-.17.375-.375.375M21.75 9a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/></g>'
  }
})
