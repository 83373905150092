import { Image as TiptapImage } from 'tiptap-extensions';
import { Plugin } from 'tiptap';
import Vapor from 'laravel-vapor';
import { undo } from 'prosemirror-history';
import axios from '@/util/axios';
import EditorImage from './EditorImage';

const alignmentClassMapping = {
    left: 'float-left',
    center: 'float-center',
    right: 'float-right'
};

function getAttrs (dom) {
    const width = dom.style.width || dom.getAttribute('width');
    const height = dom.style.height || dom.getAttribute('height');

    let alignment = dom.getAttribute('alignment');

    // Load alignment setting from TipTap content
    if (alignment == null) {
        const classAttribute = dom.getAttribute('class') || '';

        Object.entries(alignmentClassMapping).forEach(([alignmentKey, alignmentValue]) => {
            if (classAttribute.includes(alignmentValue)) {
                alignment = alignmentKey;
            }
        });
    }

    // Load alignment setting from Quill content
    if (alignment == null) {
        if (dom.style.float === 'right') {
            alignment = 'right';
        } else if (dom.style.margin === 'auto') {
            alignment = 'center';
        } else {
            alignment = 'left';
        }
    }

    return {
        src: dom.getAttribute('src') || '',
        alt: dom.getAttribute('alt') || '',
        caption: dom.getAttribute('caption') || '',
        ref: dom.getAttribute('ref') || '',
        alignment,
        width: width == null || width === 'auto' ? null : parseInt(width, 10),
        height: height == null || height === 'auto' ? null : parseInt(height, 10)
    };
}

function toDOM (node) {
    const {
        alignment,
        alt,
        src,
        caption,
        ref,
        width,
        height
    } = node.attrs;

    const attrs = {
        src,
        alt,
        caption,
        ref,
        width,
        height,
        class: alignmentClassMapping[alignment]
    };

    return [
        'img',
        attrs
    ];
}

export default class Image extends TiptapImage {
    get schema () {
        return {
            attrs: {
                alignment: { default: 'left' },
                src: { default: '' },
                alt: { default: 'Event invitation' },
                caption: { default: '' },
                ref: { default: '' },
                width: { default: null },
                height: { default: null },
                class: { default: '' },
                loader: { default: false }
            },
            inline: 'true',
            group: 'inline',
            draggable: true,
            parseDOM: [{
                tag: 'img[src]',
                getAttrs
            }],
            toDOM
        };
    }

    get view () {
        return EditorImage;
    }

    get plugins () {
        return [
            new Plugin({
                props: {
                    handleDOMEvents: {
                        drop (view, event) {
                            const dataTypes = event.dataTransfer.types;
                            if (dataTypes.length === 1 && dataTypes[0] === 'text/html') {
                                // Nodes being dragged inside the editor
                                return;
                            }

                            event.preventDefault();

                            if (!view.dom.parentElement.getAttribute('allow-uploads')) {
                                return;
                            }

                            if (!event.dataTransfer?.files?.length) {
                                return;
                            }

                            const images = Array.from(event.dataTransfer.files)
                                .filter((file) => { return (/image/i).test(file.type); });

                            if (images.length === 0) {
                                return;
                            }

                            const coordinates = view.posAtCoords({
                                top: event.clientY,
                                left: event.clientX
                            });

                            const node = view.state.schema.nodes.image.create({
                                loader: true
                            });

                            view.dispatch(
                                view.state.tr.insert(coordinates.pos, node)
                            );

                            images.forEach((image) => {
                                Vapor.store(image)
                                    .then((uploadedFile) => {
                                        return axios.post(window.route('api.account.images.store'), {
                                            image: {
                                                ...uploadedFile,
                                                filename: image.name
                                            }
                                        });
                                    })
                                    .then(({ data }) => {
                                        const url = data.data.urls[
                                            view.dom.parentElement.getAttribute('image-type')
                                        ];

                                        view.dispatch(
                                            view.state.tr.setNodeMarkup(coordinates.pos, null, {
                                                loader: false,
                                                src: url
                                            })
                                        );
                                    })
                                    .catch(() => {
                                        undo(view.state, view.dispatch);
                                        App.app.$toasted.global.error('There was an error uploading the image.');
                                    });
                            });
                        },

                        paste (view, event) {
                            const hasContent = [
                                'text/plain',
                                'text/rtf',
                                'text/unicode',
                                'text/x-moz-text-internal',
                                'text/html',
                                'text/enriched',
                                'text/html',
                                'text/richtext',
                                'text/xml'
                            ].some((type) => {
                                return event.clipboardData.getData(type);
                            });

                            if (!hasContent) {
                                event.preventDefault();
                            }
                        }
                    }
                }
            })
        ];
    }
}
