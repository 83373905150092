<template>
    <div>
        <a
            v-if="link"
            :href="route('publish-invite.index', { event })"
            class="inline-flex items-center justify-center py-2 px-4 space-x-2 bg-transparent"
        >
            <template v-if="eventStatus">
                <div class="rounded-full border-2 h-2 w-2 p-1" :class="eventStatus.circleStyle"></div>
                <span>{{ eventStatus.label }}</span>
            </template>
        </a>
        <div v-else class="inline-flex items-center justify-center py-2 px-4 space-x-2 bg-transparent">
            <template v-if="eventStatus">
                <div class="rounded-full border-2 h-2 w-2 p-1" :class="eventStatus.circleStyle"></div>
                <span>{{ eventStatus.label }}</span>
            </template>
        </div>
    </div>
</template>

<script>
import EventStatus from '@/mixins/EventStatus';

export default {
    name: 'EventStatus',

    mixins: [EventStatus],

    props: {
        event: {
            type: Object,
            required: true
        },

        link: {
            type: Boolean,
            default: false
        }
    }
};
</script>
