/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'animal-cat-head-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>kitten</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M3.51 16.04l-.01-.01c-.33-.85-.5-1.75-.52-2.66 0-4.08 4.02-7.39 9-7.39M16.5 21.07h-.01c-1.33.91-2.9 1.4-4.5 1.42h0a8.195 8.195 0 01-4.5-1.43M20.48 16.04v-.01c.32-.85.49-1.75.51-2.66 0-4.08-4.03-7.39-9-7.39M14.25 16.125v-.01a2.46 2.46 0 01-2.25 1.87l-.01-.001c-1.09-.06-2-.83-2.25-1.88-.01-.63 1-1.13 2.24-1.13 1.24 0 2.25.5 2.25 1.125zM9.75 19.5h-.01c.95-.08 1.81-.65 2.25-1.5l-.01-.01c.43.85 1.29 1.42 2.24 1.5M7.875 12h-.01c.2-.01.37.16.37.37M7.5 12.37h0c-.01-.21.16-.38.37-.38 0 0 0 0 0 0M7.875 12.75h-.01a.386.386 0 01-.38-.38s0 0 0 0M8.25 12.37v0c0 .2-.17.375-.375.375M16.125 12h-.01c.2-.01.37.16.37.37M15.75 12.37h0c-.01-.21.16-.38.37-.38 0 0 0 0 0 0M16.125 12.75h-.01a.386.386 0 01-.38-.38s0 0 0 0M16.5 12.37v0c0 .2-.17.375-.375.375M12 6v3.75M9 6.75V9M15 6.75V9"/><path pid="1" d="M3 13.381V3.12h-.01c0-.83.67-1.5 1.5-1.5.34 0 .67.11.93.32l5.17 4.13M21 13.381V3.12h0c0-.83-.68-1.5-1.5-1.5-.35 0-.68.11-.94.32l-5.18 4.13M18.75 15.75l4.5.75M18 18l3.75 2.25M5.25 15.75l-4.5.75M6 18l-3.75 2.25"/></g>'
  }
})
