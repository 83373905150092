/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'house-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>home</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M3.75 13.923v8.25h6v-6h0c-.01-.83.67-1.51 1.49-1.51h1.5-.01c.82-.01 1.5.67 1.5 1.5v6h6v-8.25M.75 12.42L10.94 2.23h-.01a1.5 1.5 0 012.12-.01L23.23 12.4M16.504 5.673v-1.5h3.75v5.25"/></g>'
  }
})
