/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'seafood-shrimp-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.227 11.25H11.25l-.01-.001C5.44 11.239.74 6.53.74.74V.739h12.75-.01c5.38-.01 9.75 4.36 9.75 9.74s-4.37 9.75-9.75 9.75H6.72h0a4.494 4.494 0 014.49-4.51h3"/><path pid="1" d="M14.25 20.221V11.25c0-2.25-1.5-10.5-6-10.5M14.25 11.25L21.1 4.4M14.25 11.25l6.14 6.14M8.625 5.25h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M6.75 20.25l-4.5-3v6l4.5-3z"/></g>'
  }
})
