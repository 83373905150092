/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-soap-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="11.786" height="15.995" x="1.504" y="7.255" rx="3.367"/><path pid="1" d="M16.5 14.57h0c1.56.54 3.27-.28 3.82-1.84.38-1.11.1-2.33-.74-3.15v-.01a5.002 5.002 0 00-1.19-6.95 4.984 4.984 0 00-6.05.22h0A2.991 2.991 0 008.62.82 2.991 2.991 0 006.47 3.7M20.49 18.25a1.99 1.99 0 100 3.99 1.99 1.99 0 100-4zM7.5 13.25v4M5.5 15.25h4"/></g>'
  }
})
