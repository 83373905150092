/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-champagne-bottle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>bar alcohol bubbly prosecco celebration mimosa</desc><g _fill="currentColor"><path pid="0" d="M16.326 11.511a.267.267 0 00-.461-.074 4.821 4.821 0 01-3.842 1.813H12a4.818 4.818 0 01-3.844-1.839.276.276 0 00-.479.076A11.188 11.188 0 007 15.258V21.5A2.5 2.5 0 009.5 24h5a2.5 2.5 0 002.5-2.5v-6.242a11.193 11.193 0 00-.674-3.747zM15 19a1 1 0 01-1 1h-4a1 1 0 01-1-1v-2a1 1 0 011-1h4a1 1 0 011 1z"/><path pid="1" d="M9.348 10.5a3.325 3.325 0 002.661 1.255h.014a3.328 3.328 0 002.66-1.237c.1-.131.365-.473.565-.721a.5.5 0 00.057-.537A11.774 11.774 0 0114 3.868V1.5A1.5 1.5 0 0012.5 0h-1A1.5 1.5 0 0010 1.5v2.368a12.085 12.085 0 01-1.27 5.344.5.5 0 00.049.546c.204.258.473.613.569.742zM12 7.5a1 1 0 11-1 1 1 1 0 011-1z"/></g>'
  }
})
