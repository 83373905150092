/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-lifelong-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.16 8.71a3.28 3.28 0 100 6.57 3.28 3.28 0 100-6.58zM10.62 6.25h1.09M11.17 6.25v2.46M14.85 7.55l.77.77M15.23 7.93l-1.74 1.75M16.92 11.45v1.1M16.92 12h-2.47M15.62 15.68l-.77.77M15.23 16.07l-1.74-1.75M11.71 17.75h-1.09M11.17 17.75v-2.46M7.49 16.45l-.78-.77M7.1 16.07l1.74-1.75M5.42 12.55v-1.1M5.42 12h2.46M6.71 8.32l.78-.77M7.1 7.93l1.74 1.75"/><path pid="1" d="M19.707 17.625h-.01c-3.11 4.77-9.5 6.12-14.27 3.02-4.78-3.11-6.13-9.5-3.03-14.27 3.1-4.78 9.49-6.13 14.26-3.03 2.92 1.9 4.68 5.15 4.69 8.64v.93"/><path pid="2" d="M19.5 11.063l1.875 1.87 1.875-1.88"/></g>'
  }
})
