/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'baby-care-clothes-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>heart</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.5 8.2v8.572h0c0 .31.2.59.5.7l-.01-.01a4.91 4.91 0 013.24 4.92h-.01c-.03.41.28.77.7.79h6.05v-.001c.41-.01.74-.34.74-.76-.01-.02-.01-.04-.01-.05l-.01-.01a4.864 4.864 0 013.24-4.92h-.01c.29-.11.49-.39.5-.71V8.14"/><path pid="1" d="M4.5 9.7H2.25l-.01-.001a1.51 1.51 0 01-1.5-1.5V3.98H.73c0-.63.38-1.19.97-1.41C3.37 1.94 6.63.73 6.72.73v0c0 .92.8 5.2 5.24 5.2s5.241-4.238 5.24-5.2v0c.09 0 3.34 1.213 5.02 1.83l-.01-.01c.58.21.97.77.97 1.4v4.2c0 .82-.68 1.5-1.5 1.5h-2.25"/><path pid="2" d="M15.93 10.293l-.01-.01c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06l-1.18 1.21-1.18-1.22-.01-.01c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06h-.01c-.77.79-.77 2.04 0 2.84l3.51 3.13h0c.22.23.58.24.81.01 0-.01.01-.02.01-.02l3.51-3.14v-.01c.76-.8.76-2.05-.01-2.85z"/></g>'
  }
})
