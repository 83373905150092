<template>
    <section class="panel flex flex-col p-2 md:p-0">
        <div class="flex flex-col md:flex-row w-full group">
            <a
                class="flex items-center w-full md:w-48 lg:w-32 p-4 border-b md:border-r rounded-l-md relative transition duration-150 ease-in-out"
                :class="{ 'group-hover:bg-purple group-hover:bg-border-purple-light': !isStripeConnected }"
                role="button"
                @click="connect"
            >
                <img
                    class="w-full h-20 md:h-auto transition duration-150 ease-in-out"
                    :class="{ 'group-hover:hidden': !isStripeConnected }"
                    :src="asset('images/vendor/stripe/logo-slate.svg')"
                    alt="Stripe Logo (Slate)"
                >
                <img
                    v-if="!isStripeConnected"
                    class="w-full h-20 md:h-auto hidden group-hover:block transition duration-150 ease-in-out"
                    :src="asset('images/vendor/stripe/logo-white.svg')"
                    alt="Stripe Logo (White)"
                >
            </a>

            <template v-if="isStripeConnected">
                <div class="group flex-1 flex items-center justify-center text-gray-600 text-center p-4">
                    <div>
                        <p class="text-green">
                            You are connected with
                            <span class="font-semibold tracking-wide">{{ event.stripe_connection.email }}</span>
                        </p>

                        <p class="text-sm mt-2">
                            {{ applicationFeesDetails }}

                            <a
                                href="https://stripe.com/pricing"
                                target="_blank"
                                class="text-teal hover:text-teal-dark"
                            >{{ stripeFeesDetails }}</a>.<br>

                            By processing any payments, you agree to our <a
                                href="https://rsvpify.com/tos/"
                                target="_blank"
                                class="text-teal hover:text-teal-dark"
                            >Terms of Service</a>.
                        </p>
                    </div>
                </div>

                <div class="flex items-center">
                    <a
                        role="button"
                        target="_blank"
                        class="button button-danger-soft w-full md:w-auto my-4 mr-0 md:mr-4"
                        @click="disconnect"
                    >Disconnect</a>
                </div>
            </template>
            <template v-else>
                <div class="flex-1 flex flex-col items-center justify-center text-gray-600 text-sm text-center p-4">
                    <p>You haven't connected a Stripe account to your RSVPify account.</p>

                    <p class="mt-2">
                        Don't have an account?
                        <a
                            href="https://dashboard.stripe.com/register"
                            target="_blank"
                            class="font-semibold text-teal hover:text-teal-dark"
                        >
                            Sign up
                        </a>
                        is quick, easy and free!
                    </p>
                </div>

                <button class="button button-primary w-full md:w-auto my-2 md:my-4 mr-0 md:mr-4" @click="connect">Connect Now</button>

                <a
                    href="https://rsvpify.com/sell-tickets/"
                    target="_blank"
                    class="button button-soft w-full md:w-auto my-2 md:my-4 mr-0 md:mr-4"
                >
                    Learn More
                </a>
            </template>
        </div>
    </section>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
    name: 'StripeConnect',

    computed: {
        ...sync('Event/*'),

        applicationFeesDetails () {
            if (this.userHasFixedApplicationFee && this.userHasPercentageApplicationFee) {
                return `Your RSVPify service fee is ${this.event.owner.application_fee.percentage}% of the total order value plus $${this.event.owner.application_fee.formattedFixedFee} per ticket, paid item, and/or donation.`;
            }

            if (this.userHasFixedApplicationFee) {
                return `Your RSVPify service fee is $${this.event.owner.application_fee.formattedFixedFee} per ticket, paid item, and/or donation.`;
            }

            if (this.userHasPercentageApplicationFee) {
                return `Your RSVPify service fee is ${this.event.owner.application_fee.percentage}% of the total order value.`;
            }

            return null;
        },

        isStripeConnected () {
            return this.event.isStripeConnected;
        },

        stripeFeesDetails () {
            if (!this.userHasFixedApplicationFee && !this.userHasPercentageApplicationFee) {
                return 'Stripe processing fees will be applied';
            }

            return 'Stripe processing fees also apply';
        },

        userHasFixedApplicationFee () {
            return this.event.owner.application_fee.fixed !== 0;
        },

        userHasPercentageApplicationFee () {
            return this.event.owner.application_fee.percentage !== 0;
        }
    },

    methods: {
        connect () {
            if (this.isStripeConnected) {
                return;
            }

            window.location.href = this.route('stripe.connect.start-authorization', this.event);
        },

        disconnect () {
            App.alert().confirm(
                'Are you sure?',
                'Disconnecting your Stripe account will disable your ability to accept payments for this event and your event will automatically switch to test mode.',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                },
                () => {
                    window.location.href = this.route('stripe.connect.deauthorize', this.event);
                }
            );
        }
    }
};
</script>
