/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'house-heart-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>home like favorite</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M3.75 14v7.5h0c0 .82.67 1.49 1.5 1.49h13.5-.01c.82 0 1.5-.68 1.5-1.5v-7.5M.75 12.629l10.189-10.19v-.01a1.5 1.5 0 012.12-.01s0 0 0 0l10.18 10.19"/><path pid="1" d="M16.5 5.87V3.49h3.75v6.129M15.93 11.845l-.01-.01c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06l-1.18 1.21-1.18-1.22-.01-.01c-.73-.76-1.93-.79-2.69-.07-.03.02-.05.04-.07.06h-.01c-.77.79-.77 2.04 0 2.84l3.51 3.63h0c.22.23.58.24.81.01 0-.01.01-.02.01-.02l3.51-3.64v-.01c.76-.8.76-2.05-.01-2.85z"/></g>'
  }
})
