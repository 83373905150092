<template>
    <div>
        <div
            ref="qrCode"
            class="border border-dashed border-transparent hover:border-gray-200 mx-auto flex justify-center text-center my-0 p-0"
            @click="showEditorBubble"
        >
            <div
                v-if="loading"
                style="height: 225px; width: 225px;"
            >
                <app-icon
                    name="loader"
                    class="w-10 h-10"
                ></app-icon>
            </div>

            <img
                v-show="!loading"
                class="mx-auto"
                :src="`data:image/png;base64,${src}`"
                width="225px"
                height="225px"
                @load="loading = false"
            >
        </div>

        <portal v-if="editorBubble" to="modal-portal-target">
            <div
                ref="dropdown"
                v-click-outside="closeEditorBubble"
                class="absolute bg-black border-b-2 border-purple-light rounded-xl p-4 z-90 whitespace-normal"
            >
                <button @click="deleteQrCode">
                    <app-icon
                        name="trash"
                        class="h-4 w-4 text-white opacity-50 hover:opacity-100"
                    ></app-icon>
                </button>
            </div>
        </portal>
    </div>

</template>

<script>
import { createPopper } from '@popperjs/core';
import debounce from 'lodash/debounce';
import { get } from 'vuex-pathify';
import axios from '@/util/axios';

export default {
    name: 'CheckinQrCode',

    props: {
        editor: {
            type: Object,
            required: true
        },

        getPos: {
            type: Function,
            required: true
        },

        node: {
            type: Object,
            required: true
        },

        updateAttrs: {
            type: Function,
            required: true
        },

        view: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            editorBubble: false,
            loading: true,
            popper: null,
            src: null
        };
    },

    computed: {
        ...get('Event/*'),

        editable () {
            return this.editor && this.editor.options.editable;
        }
    },

    watch: {
        editorBubble (newVal) {
            if (newVal) {
                this.editor.blur();

                this.$nextTick(() => {
                    if (this.popper) {
                        this.popper.update();
                    } else {
                        this.initializePopper();
                    }
                });
            } else {
                this.destroyPopper();
            }
        }
    },

    mounted () {
        this.loadQrCode();
    },

    methods: {
        closeEditorBubble () {
            if (this.editorBubble) {
                this.editorBubble = false;
            }
        },

        debouncedUpdate: debounce(function (property, value) {
            this[property] = value;
        }, 500),

        deleteQrCode () {
            const transaction = this.view.state.tr;
            const position = this.getPos();

            transaction.delete(position, position + this.node.nodeSize);

            this.view.dispatch(transaction);
        },

        destroyPopper () {
            this.popper?.destroy();
            this.popper = null;
        },

        initializePopper () {
            if (this.popper) {
                return;
            }

            this.popper = createPopper(this.$refs.qrCode, this.$refs.dropdown, {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10]
                        }
                    },
                    { name: 'flip' },
                    { name: 'preventOverflow' }
                ],
                placement: 'bottom',
                strategy: 'fixed'
            });
        },

        loadQrCode () {
            this.loading = true;

            axios.get(this.route('api.events.qr-code', {
                event: this.event.id,
                style: 'round',
                logo: false
            })).then(({ data }) => {
                this.loading = false;
                this.src = data.qrCode;
            }).catch(() => {
                this.$toasted.global.error('There was an error generating the QR Code.');
            });
        },

        showEditorBubble () {
            if (!this.editable) {
                return;
            }

            this.editorBubble = !this.editorBubble;
        }
    }
};
</script>
