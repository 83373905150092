/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-dollar-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>shopping usd</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9 19.188v-.01c-.59.58-1.54.58-2.13 0l-5.38-5.38-.01-.01c-.59-.59-.59-1.54-.01-2.13v-.01l10.5-10.5h-.01c.28-.29.66-.44 1.06-.44h5.378-.01c.82-.01 1.5.67 1.5 1.5 0 0 0 0 0 0v5.37-.01c0 .39-.16.77-.44 1.06"/><path pid="1" d="M16.189 4.12h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.375-.375-.375v0M18.439 14.24h-2.04l-.01-.001c-.75-.01-1.35.59-1.35 1.33-.01.55.33 1.04.84 1.25l2.06.82-.01-.01c.68.27 1.02 1.05.74 1.74-.21.5-.7.84-1.25.84h-2.04M16.94 14.25v-.75M16.94 21v-.75"/><path pid="2" d="M16.93 11.24a6 6 0 100 12 6 6 0 100-12z"/></g>'
  }
})
