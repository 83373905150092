<template>
    <app-modal
        ref="modal"
        :value="value"
        :full-screen="true"
        :title="language.name"
        :z-index="100"
        @input="$emit('input', $event)"
    >
        <template #close-button="{ close }">
            <button class="button-icon button-sm shrink" @click="cancelChanges(close)">
                <app-icon name="close"></app-icon>
            </button>
        </template>

        <div class="flex flex-col md:flex-row md:justify-between items-center">
            <search-field
                v-model="searchTerm"
                class="w-full md:w-1/2"
                placeholder="Find a language item..."
            ></search-field>

            <button
                class="button-text button-primary flex-auto md:justify-end mt-4 md:mt-0"
                @click="restoreLanguage"
            >
                Restore All to Defaults
            </button>
        </div>

        <div class="mt-10 h-screen">
            <div class="flex flex-col">
                <form-field-wrapper
                    v-for="(categoryLabel, categoryId) in filteredCategoryMappings"
                    :key="categoryId"
                    :label="categoryLabel"
                >
                    <div
                        v-for="translationKey in filteredTranslationKeysByCategory(categoryId)"
                        :key="translationKey"
                        class="row px-4 py-2 mx-0 odd:bg-gray-100"
                    >
                        <div class="col-12 md:col-4 flex-col items-center md:items-start justify-center">
                            <p v-if="isForeignLanguage" class="italic text-gray-600 mb-2">
                                {{ getEnglishTranslation(translationKey) }}
                            </p>

                            <p class="italic">{{ getDefaultLanguageTranslation(translationKey) }}</p>

                            <p v-if="hasDescription(translationKey)" class="mt-2 text-xs text-gray-600">{{ descriptions[translationKey] }}</p>
                        </div>

                        <div class="col-12 md:col-8 mt-4 md:mt-0">
                            <form-field-wrapper
                                :should-show-error="form.errors.has(`items.${translationKey}`)"
                                :error="form.errors.get(`items.${translationKey}`)"
                            >
                                <input v-model="form.items[translationKey]" class="form-field text-center md:text-left">
                            </form-field-wrapper>
                        </div>
                    </div>
                </form-field-wrapper>
            </div>
        </div>

        <template #footer="{ close }">
            <stateful-button
                class="button-primary"
                :loading="form.processing"
                @click="saveLanguage"
            >
                Save
            </stateful-button>

            <button class="button" @click="cancelChanges(close)">
                Cancel
            </button>
        </template>
    </app-modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import map from 'lodash/map';
import pick from 'lodash/pick';
import union from 'lodash/union';
import startsWith from 'lodash/startsWith';
import { get } from 'vuex-pathify';
import axios from '@/util/axios';
import Form from '@/validation/Form';

export default {
    name: 'CustomizeLanguage',

    props: {
        englishLanguage: {
            type: Object,
            required: true
        },
        language: {
            type: Object,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            searchTerm: '',
            form: new Form({
                items: {}
            })
        };
    },

    computed: {
        ...get('Event/event@', {
            eventId: 'id'
        }),

        descriptions () {
            return {
                'subject-confirmation-attending': 'Email confirmation subject line sent to attending guests',
                'subject-confirmation-not-attending': 'Email confirmation subject line sent to not attending guests',
                'subject-confirmation-canceled': 'Email confirmation subject line sent to guests who have canceled their reservation',
                'subject-reminders-lt-24hrs': 'Subject line of reminder email sent less than 24 hours in advance',
                'subject-reminders-gte-24hrs': 'Subject line of reminder email sent 24 hours or more in advance'
            };
        },

        filteredCategoryMappings () {
            const mappings = {
                button: 'Buttons',
                text: 'Text',
                title: 'Titles',
                subtitle: 'Subtitles',
                label: 'Labels',
                placeholder: 'Placeholders',
                email: 'Emails',
                subject: 'Email Subjects',
                checkin: 'Check-in'
            };

            const categoryKeys = map(this.filteredTranslations, (_value, key) => {
                return key.slice(0, key.indexOf('-'));
            });

            return pick(mappings, categoryKeys);
        },

        filteredTranslationKeysByCategory () {
            return (categoryId) => {
                return Object.keys(this.filteredTranslations).filter((key) => {
                    return startsWith(key, categoryId) && !this.hiddenFields.includes(key);
                });
            };
        },

        filteredTranslations () {
            const matchingKeys = union(
                this.getKeysMatchingSearchFilter(this.englishLanguage.items),
                this.getKeysMatchingSearchFilter(this.language.items)
            );

            return pick(this.form.items, matchingKeys);
        },

        hiddenFields () {
            return [
                'text-powered-by'
            ];
        },

        isForeignLanguage () {
            return this.language.name !== 'English';
        }
    },

    watch: {
        value (newValue) {
            if (newValue) {
                this.loadTranslations();
            }
        },

        language: {
            deep: true,
            handler () {
                this.loadTranslations();
            }
        }
    },

    methods: {
        cancelChanges (closeHandler) {
            if (!this.form.isModified()) {
                closeHandler();
                return;
            }

            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            const closeAndClear = () => {
                this.form.restore();
                closeHandler();
            };

            App.alert().confirm(
                'Are you sure?',
                'Are you sure you want to discard your changes?',
                attributes,
                closeAndClear
            );
        },

        getDefaultLanguageTranslation (key) {
            return this.language.items.find((item) => {
                return item.key === key;
            }).value;
        },

        getEnglishTranslation (key) {
            return this.englishLanguage.translations[key];
        },

        getKeysMatchingSearchFilter (items) {
            const searchRegex = new RegExp(this.searchTerm, 'i');

            const matches = filter(items, ({ value }) => {
                return searchRegex.test(value);
            });

            return map(matches, 'key');
        },

        hasDescription (key) {
            return key in this.descriptions;
        },

        loadTranslations () {
            this.form.items = cloneDeep(this.language.translations);
        },

        restoreLanguage () {
            const attributes = {
                confirmButtonText: 'Reset to Defaults',
                cancelButtonText: 'Cancel'
            };

            const onConfirm = () => {
                axios
                    .post(this.route('api.languages.reset', [this.eventId, this.language]))
                    .then(({ data }) => {
                        this.$toasted.global.success('Language has been restored to defaults.');

                        this.$emit('update-language', data);
                    })
                    .catch(() => {
                        this.$toasted.global.error('There was an error restoring the language defaults.');
                    });
            };

            App.alert().confirm(
                'Are you sure?',
                `This will remove any customizations you've made for this language and return it to the default translation.`,
                attributes,
                onConfirm
            );
        },

        saveLanguage () {
            this.form.patch(this.route('api.languages.update', [this.eventId, this.language]))
                .then(({ data }) => {
                    this.$toasted.global.success('Language has been updated.');

                    this.$emit('update-language', data);

                    this.$refs.modal.close();
                });
        }
    }
};
</script>
