<template>
    <div v-theme="'form.background'" class="px-4 py-8 w-full rounded-md relative transition-all group">
        <div v-theme="'form.text'">
            <tiptap-editor
                :read-only="!editing"
                :value="message"
                show-embed-video-button
                hint-classes="-my-4 -mx-2"
                with-hint
                @input="updatedValue => $emit('input', updatedValue)"
            ></tiptap-editor>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    props: {
        editing: {
            type: Boolean,
            default: false
        },
        guests: {
            type: Array,
            default: () => { return []; }
        },
        value: {
            type: String,
            required: true
        }
    },

    computed: {
        event: get('Event/event'),

        message () {
            if (this.editing) {
                return this.value;
            }

            return this.value.replace('#EMAIL#', this.firstGuestEmail);
        },

        firstGuestEmail () {
            if (!this.guests || this.guests.length === 0) {
                return null;
            }

            const firstGuestWithEmail = this.guests.find(({ email }) => {
                return email != null;
            });

            return firstGuestWithEmail.email;
        }
    }
};
</script>
