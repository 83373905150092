/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-forbidden-close-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.85.75a2.88 2.88 0 100 5.77 2.88 2.88 0 100-5.78zM4.833 9.52v-.01c-.37.81-1.32 1.17-2.14.81l-1-.45-.01-.01C.86 9.49.49 8.53.86 7.72c.36-.82 1.32-1.19 2.13-.82l.98.43-.01-.01c.81.36 1.18 1.32.81 2.13zM7.14 21.63v-5.6M10.37 12.458v9.173h0a1.62 1.62 0 01-3.24.07.139.139 0 010-.09"/><path pid="1" d="M7.139 21.63v0c0 .89-.73 1.61-1.62 1.61-.9 0-1.62-.73-1.62-1.62v-8.18M1.2 13.2v2.42h-.01c-.01.89.72 1.61 1.61 1.61v-.01h1.08M15.51.75a2.88 2.88 0 100 5.77 2.88 2.88 0 100-5.78z"/><path pid="2" d="M21.084 10.968h0a5.404 5.404 0 00-5.32-4.45l-.01-.01c-.62-.03-1.23-.02-1.84.05M13.074 11.919h-.001c0-2.99-2.42-5.4-5.4-5.4h0a7.42 7.42 0 00-2.85.29h-.01c-.33.1-.61.3-.82.56M18.25 13.22a5 5 0 100 10 5 5 0 100-10zM21.78 14.69l-7.06 7.07"/></g>'
  }
})
