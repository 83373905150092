/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>new plus create</desc><g _fill="currentColor"><path pid="0" d="M12 23.75l-.01-.001c6.48 0 11.75-5.27 11.75-11.75C23.74 5.5 18.47.24 11.99.24 5.5.23.24 5.5.24 11.99c0 6.48 5.26 11.74 11.75 11.74zm0-21h-.01c5.1-.01 9.25 4.14 9.25 9.24 0 5.1-4.15 9.25-9.25 9.25-5.11 0-9.25-4.15-9.26-9.25v-.01c0-5.11 4.14-9.25 9.25-9.25z"/><path pid="1" d="M6.273 13.25H10.5h-.01c.13-.01.25.11.25.24v4.22c0 .69.55 1.24 1.25 1.24a1.25 1.25 0 001.24-1.26v-4.23c-.01-.14.11-.26.24-.26h4.227l-.01-.001c.69 0 1.25-.56 1.25-1.25 0-.7-.56-1.25-1.25-1.26h-4.24a.263.263 0 01-.25-.26V6.19c0-.7-.56-1.25-1.25-1.25-.7 0-1.25.55-1.25 1.25v4.228c0 .13-.12.25-.25.25H6.2c-.7 0-1.25.55-1.25 1.25 0 .69.55 1.25 1.25 1.24z"/></g>'
  }
})
