/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-forbidden-close-3-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.21.75a4.75 4.75 0 100 9.5 4.75 4.75 0 100-9.5zM17.021.94h-.01a4.74 4.74 0 015.9 3.2c.74 2.51-.69 5.15-3.21 5.9-.67.19-1.37.24-2.06.14M6.47 17.724v-.01c-.83.82-2.18.82-3 0l-2.1-2.1h0c-.8-.87-.74-2.21.12-3.01.81-.75 2.06-.75 2.87 0l2.1 2.1h0c.82.83.82 2.17-.01 3zM2.97 20.76v2.49"/><path pid="1" d="M4.25 12.508v-.01a6.159 6.159 0 014.8-2.26h3.22-.01c.97-.01 1.94.22 2.81.65M18.26 13.22a5 5 0 100 10 5 5 0 100-10zM21.8 14.69l-7.07 7.07"/></g>'
  }
})
