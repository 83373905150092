<template>
    <base-dropdown
        class="inline-block"
        placement="bottom-start"
        :disabled="!isEditing"
    >
        <template slot="trigger" slot-scope="{ triggerFunction }">
            <button
                class="uppercase tracking-wide text-gray-500 flex items-center text-sm"
                @click.stop="triggerFunction"
            >
                <app-icon
                    v-if="selectedProduct && selectedProduct.settings.icon"
                    :name="selectedProduct.settings.icon"
                    class="w-4 h-4 mr-3"
                ></app-icon>

                <span>{{ selectedProductTitle }}</span>

                <span v-if="selectedProduct && selectedProduct.timeslot_from" class="ml-2 font-light normal-case">
                    ({{ selectedProduct.timeslot_from | timeslotDate(eventTimezone.name) }})
                </span>

                <app-icon
                    v-if="isEditing || isNewGuest"
                    name="arrow-down-chevron"
                    class="ml-2 h-2 w-2"
                    stroke
                ></app-icon>
            </button>
        </template>

        <template #default="{ triggerFunction }">
            <div class="bg-white rounded-md border shadow cursor-pointer text-sm mt-1 z-90 max-h-sm overflow-y-auto">
                <template v-if="!selectedProduct || !selectedProduct.timeslot_from">
                    <button
                        v-for="product in products"
                        :key="product.id"
                        class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                        @click.stop="selectProduct(product, triggerFunction)"
                    >
                        <app-icon
                            v-if="product.settings.icon"
                            :name="product.settings.icon"
                            class="w-4 h-4 mr-3"
                        ></app-icon>

                        {{ product.title }}

                        <app-icon
                            v-if="isProductSelected(product)"
                            name="check-circle-filled"
                            class="ml-4 text-purple"
                        ></app-icon>
                    </button>
                </template>
                <button
                    v-if="canClearProductSelection || (selectedProduct && selectedProduct.timeslot_from)"
                    class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                    @click.stop="selectProduct(null, triggerFunction)"
                >
                    {{ unselectedProductTitle }}

                    <app-icon
                        v-if="isProductUnselected"
                        name="check-circle-filled"
                        class="ml-4 text-purple"
                    ></app-icon>
                </button>
            </div>
        </template>
    </base-dropdown>
</template>

<script>
import { find } from 'lodash';
import EventTimezone from '@/mixins/EventTimezone';

export default {
    name: 'GuestPrimaryProductSelector',

    mixins: [EventTimezone],

    props: {
        event: {
            type: Object,
            required: true
        },

        guest: {
            type: Object,
            required: true
        },

        products: {
            type: Array,
            required: true
        },

        isEditing: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isProductUnselected () {
            if (this.isNewGuest) {
                return this.guest.product === null;
            }

            return this.guest.notAttending;
        },

        isNewGuest () {
            return this.guest.isNewGuest;
        },

        canClearProductSelection () {
            return find(this.products, { guest_state: 'NotAttending' }) === undefined;
        },

        unselectedProductTitle () {
            return `Not Attending`;
        },

        selectedProduct () {
            if (this.isNewGuest) {
                return find(this.event.products, { id: this.guest.product });
            }

            return this.guest.products[0];
        },

        selectedProductTitle () {
            if (!this.selectedProduct || this.guest.notAttending) {
                return this.unselectedProductTitle;
            }

            return this.selectedProduct.title;
        }
    },

    methods: {
        isProductSelected (product) {
            return this.selectedProduct && this.selectedProduct.id === product.id;
        },

        selectProduct (product, dropdownTriggerFunction) {
            this.$emit('selected-product', product);

            dropdownTriggerFunction();
        }
    }
};
</script>
