<template>
    <div class="flex flex-wrap">
        <form-field-wrapper label="Section Icon">
            <icon-picker
                v-model="form.icon"
                class="text-gray-800 w-11 h-11"
            ></icon-picker>
        </form-field-wrapper>

        <form-field-wrapper
            label="Section Title"
            :error="form.errors.get('title')"
            :should-show-error="form.errors.has('title')"
        >
            <input
                v-model="form.title"
                class="form-field"
            >
        </form-field-wrapper>

        <form-field-wrapper>
            <toggle-switch v-model="form.displayTitle">
                Display Section Icon &amp; Title
            </toggle-switch>

            <toggle-switch
                v-model="form.displayInMenu"
                class="mt-4"
            >
                Show Section In Menu
            </toggle-switch>
        </form-field-wrapper>

        <form-field-wrapper label="Image(s)">
            <image-manager
                v-model="form.slider.images"
                :settings="form.slider"
                title="Upload Images"
            >
                <template #recommendations>
                    PNG and JPG files allowed. Max file size: 2 MB.<br>
                    Size Recommendation: at least 2,000 px wide.
                </template>
            </image-manager>
        </form-field-wrapper>

        <form-field-wrapper
            v-if="form.slider.images.length > 1"
            label="Image height"
            :error="form.errors.get('height')"
            :should-show-error="form.errors.has('height')"
        >
            <input
                id="image-height"
                v-model.number="form.height"
                class="form-field"
                type="number"
                min="100"
                max="2000"
                step="1"
            >
        </form-field-wrapper>
    </div>
</template>

<script>
import BlockableSettingsMixin from '@/mixins/BlockableSettingsMixin';

export default {
    name: 'WallImageBlockSettings',

    mixins: [BlockableSettingsMixin]
};
</script>
