<template>
    <div class="flex justify-center">
        <button
            class="button-icon button-bg button-primary button-sm mr-1 disabled:cursor-not-allowed disabled:opacity-50"
            :disabled="disablePreviousButton"
            @click="openPrevious"
        >
            <app-icon name="arrow-left-chevron" stroke></app-icon>
        </button>

        <button
            class="button-icon button-bg button-primary button-sm disabled:cursor-not-allowed disabled:opacity-50"
            :disabled="disableNextButton"
            @click="openNext"
        >
            <app-icon name="arrow-right-chevron" stroke></app-icon>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        currentPage: {
            type: Number,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        totalPages: {
            type: Number,
            required: true
        }
    },

    computed: {
        disablePreviousButton () {
            return this.disabled || !this.hasPreviousPage;
        },

        disableNextButton () {
            return this.disabled || !this.hasNextPage;
        },

        hasNextPage () {
            return this.currentPage < this.totalPages;
        },

        hasPreviousPage () {
            return this.currentPage > 1;
        }
    },

    methods: {
        openNext () {
            if (this.hasNextPage) {
                this.openPage(this.currentPage + 1);
            }
        },

        openPage (page) {
            this.$emit('open-page', page);
        },

        openPrevious () {
            if (this.hasPreviousPage) {
                this.openPage(this.currentPage - 1);
            }
        }
    }
};
</script>
