<template>
    <div class="w-1/3 p-1">
        <a
            :href="routeLink"
            class="h-24 sm:h-32 flex justify-center items-center no-underline text-white text-center p-2 py-4 rounded-xl border border-purple shadow-md"
            :class="buttonClass"
        >
            <div>
                <app-icon :name="icon" class="h-6 w-6"></app-icon>
                <div class="mt-2 text-sm">
                    {{ text }}
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import CurrentEventUrlParser from '@/mixins/CurrentEventUrlParser';

export default {
    mixins: [CurrentEventUrlParser],

    props: {
        routeName: {
            type: String,
            default: null
        },
        eventId: {
            type: Number,
            default: null
        },
        icon: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    },

    computed: {
        routeLink () {
            if (!this.routeName) {
                return '#';
            }

            if (!this.eventId) {
                return this.route(this.routeName);
            }

            return this.route(this.routeName, this.eventId);
        },

        buttonClass () {
            // Current route is completely different
            if (this.route().current() !== this.routeName) {
                return [];
            }

            // The route name is the same, so we'll make the link active only if the event IDs match,
            // or alternatively, this route does not have an event ID paramater at all
            if (!this.eventId || this.currentUrlEventId == this.eventId) {
                return ['bg-purple'];
            }

            return [];
        }
    }
};
</script>
