<template>
    <div>
        <div class="relative">
            <google-autocomplete-input
                v-model="address"
                name="address"
                placeholder="Start typing a location..."
                class="form-field pr-8 mb-2"
            >
            </google-autocomplete-input>

            <a
                v-if="address"
                class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-300 cursor-pointer"
                role="button"
                @click="clearAddress"
            >
                <app-icon
                    class="h-3 w-3"
                    name="close"
                ></app-icon>
            </a>
        </div>

        <slot v-if="address">
            <location-map :address="value"></location-map>
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: null
        }
    },

    data () {
        return {
            address: this.value
        };
    },

    watch: {
        address (newAddress) {
            this.$emit('input', newAddress);
        }
    },

    methods: {
        clearAddress () {
            this.address = null;
        }
    }
};
</script>
