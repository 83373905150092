/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'receipt-dollar-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>money finance</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6 6.75h6M6 11.25h3.75M6 15.75h3.75M16.5 6.75H18M13.89 16.564l-.01-.01c.43.57 1.13.9 1.85.87 1.13 0 2.06-.7 2.06-1.55 0-.86-.93-1.55-2.07-1.55-1.14 0-2.07-.7-2.07-1.548 0-.86.92-1.55 2.06-1.55v-.01c.72-.03 1.41.29 1.85.87M15.75 17.44v1.03M15.75 10.22v1.03"/><path pid="1" d="M16.5 23.25h-.01a2.591 2.591 0 013.56-.94c.38.22.71.54.93.93V.73c-.47.79-1.33 1.29-2.25 1.29l-.01-.001c-.93-.01-1.79-.5-2.25-1.3v-.01c-.47.79-1.33 1.29-2.25 1.29h0c-.93-.01-1.79-.5-2.25-1.3h-.01a2.64 2.64 0 01-2.26 1.29l-.01-.001c-.93-.01-1.79-.5-2.25-1.3v-.01c-.47.79-1.33 1.29-2.25 1.29h0c-.93-.01-1.79-.5-2.25-1.3v22.5h-.01a2.591 2.591 0 013.56-.94c.38.22.71.54.93.93h-.01a2.591 2.591 0 013.56-.94c.38.22.71.54.93.93"/><path pid="2" d="M12 23.25h-.01a2.591 2.591 0 013.56-.94c.38.22.71.54.93.93"/></g>'
  }
})
