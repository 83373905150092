/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-coffee-cold-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18 9.56H6l2.25 13.5h8.25L18 9.56zM3.75 9.56h16.5M18 9.56v0c0-3.32-2.69-6-6-6-3.32 0-6 2.68-6 6"/><path pid="1" d="M11.276 15.56l2.62-13.445h-.01a1.5 1.5 0 011.87-1.12l3.71 1.06M7 15.56h10.33"/></g>'
  }
})
