<template>
    <div class="flex-1 md:p-8">
        <section class="panel flex flex-col">
            <page-header icon="email-check-stroke" label="Review Campaigns From Free Users">
                <a :href="route('admin.dashboard')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <infinite-data-table
                ref="table"
                class="md:mx-0 text-gray-600"
                column-header-class="z-10"
                :columns="columns"
                no-results-placeholder="We couldn't find any campaigns that need review."
                :source="source"
                @click-row="campaignClicked"
            >
                <template #row="{ property, row, value }">
                    <template v-if="property === 'reviewed_by'">
                        <div class="flex items-center space-x-2">
                            <div v-if="getCampaignIcon(row)">
                                <app-icon
                                    class="h-5 w-5"
                                    :class="getCampaignIcon(row).color"
                                    :name="getCampaignIcon(row).name"
                                ></app-icon>
                            </div>

                            <div>
                                <div v-if="row.approved === null && row.reviewed_by === null">Awaiting next action</div>

                                <div v-else-if="row.approved === false">
                                    <div class="font-semibold">Blocked</div>
                                </div>

                                <template v-else-if="row.approved === true">
                                    <div class="font-semibold">Verified</div>
                                    <div class="text-2xs">Sent: {{ row.sent_at | datetime('DATETIME_SHORT') }}</div>
                                    <div class="text-2xs">Approved: {{ row.verified_at | datetime('DATETIME_SHORT') }}</div>
                                    <div class="text-2xs">Approved By: {{ row.reviewed_by?.name ?? 'System' }}</div>
                                </template>

                                <div v-else>
                                    <div class="font-semibold">Moved to draft</div>
                                    <div class="text-2xs">Rejected By: {{ row.reviewed_by?.name ?? 'System' }}</div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="property === 'title'">
                        <div class="text-sm">{{ value }}</div>
                        <div class="text-2xs">{{ row.event.owner.name }} ({{ row.event.owner.email }})</div>
                    </template>

                    <template v-else-if="property === 'actions'">
                        <div class="flex items-center space-x-0 md:space-x-2 space-y-2 flex-col md:flex-row md:space-y-0">
                            <copy-to-clipboard :copy="row.event.owner.email" @copied="$toasted.global.success('Email has been copied to your clipboard.')">
                                <stateful-button
                                    class="button-primary !p-2"
                                >
                                    <app-icon class="w-4 h-4" name="file-copy"></app-icon>
                                </stateful-button>
                            </copy-to-clipboard>

                            <stateful-button
                                class="button-primary !p-2"
                                @click="campaignClicked(row)"
                            >
                                <app-icon
                                    name="search"
                                    stroke
                                    class="w-4 h-4"
                                ></app-icon>
                            </stateful-button>

                        </div>
                    </template>

                    <template v-else>{{ value }}</template>
                </template>
            </infinite-data-table>
        </section>

        <app-modal v-model="showCampaignContent">
            <template #header>{{ selectedCampaign?.title }}</template>

            <template #default>
                <email-editor
                    read-only
                    :value="selectedCampaign.body"
                    :accent-bar-enabled="selectedCampaign.settings.accentBarColorEnabled"
                    :accent-color="selectedCampaign.settings.accentBarColor"
                    :event="selectedCampaign.event"
                    :background-color="selectedCampaign.settings.backgroundColor"
                    :body-background-color="selectedCampaign.settings.emailBodyBackgroundColor"
                ></email-editor>
            </template>

            <template #footer="{ close }">
                <stateful-button
                    class="button-primary"
                    type="submit"
                    :loading="loading"
                    @click="review('verified')"
                >
                    Verify
                </stateful-button>

                <stateful-button
                    class="button-danger"
                    type="submit"
                    :loading="loading"
                    @click="review('blocked')"
                >
                    Block
                </stateful-button>

                <stateful-button
                    class="button-danger"
                    type="submit"
                    :loading="loading"
                    @click="review('draft')"
                >
                    Switch to draft
                </stateful-button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import axios from '@/util/axios';
import { cloneDeep } from 'lodash';

export default {
    data () {
        return {
            selectedCampaign: null,
            showCampaignContent: false,
            loading: false
        };
    },

    computed: {
        columns () {
            return [
                {
                    label: 'State',
                    property: 'reviewed_by',
                    desktop: true
                },
                {
                    label: 'Campaign',
                    property: 'title',
                    desktop: true
                },
                {
                    label: 'Actions',
                    property: 'actions',
                    desktop: true
                }
            ];
        },

        source () {
            return this.route('api.admin.campaigns.index');
        }
    },

    methods: {
        campaignClicked (campaign) {
            this.selectedCampaign = cloneDeep(campaign);
            this.showCampaignContent = true;
        },

        review (state) {
            this.loading = true;

            axios.post(this.route('api.admin.campaigns.review', this.selectedCampaign.id), { state })
                .then(() => {
                    let message = '';

                    if (state === 'verified') {
                        message = 'Campaign verified successfully.';
                    } else if (state === 'blocked') {
                        message = 'Campaign blocked successfully, user suspended.';
                    } else if (state === 'draft') {
                        message = 'Campaign has been set back to draft.';
                    }

                    this.$toasted.global.success(message);

                    this.showCampaignContent = false;
                    this.$refs.table.reload();
                })
                .catch(() => {
                    this.$toasted.global.error('Something went wrong.');
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getCampaignIcon (campaign) {
            if (campaign.approved === false) {
                return { name: 'alert-triangle', color: 'text-red' };
            }

            if (!campaign.sent_at) {
                return { name: 'alert-triangle', color: 'text-yellow' }; // Draft
            }

            if (campaign.approved === true) {
                return { name: 'check-circle-filled', color: 'text-green' }; // Verified
            }

            return null;
        }
    }
};
</script>
