/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-virus-lab-research-microscope-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.04 3.375a3 3 0 100 6 3 3 0 100-6zM5.55 1.12h1M6.05 1.12v2.26M9.41 2.31l.7.71M9.76 2.66L8.17 4.25M11.3 5.88v1M11.3 6.38H9.05M10.11 9.73l-.7.71M9.76 10.09L8.17 8.5M6.55 11.62h-1M6.05 11.62V9.38M2.69 10.44l-.71-.71M2.34 10.09L3.93 8.5M.8 6.88v-1M.8 6.38h2.25M1.98 3.02l.71-.71M2.34 2.66l1.59 1.59M18.146 9.1l-.01-.01c2.58 2.19 2.9 6.06.71 8.64-.13.14-.26.28-.4.42v-.01a6.424 6.424 0 01-8.95 0h0a6.117 6.117 0 01-1.56-2.53"/><path pid="1" d="M16.15 11.054v-.01a.93.93 0 01-1.28 0l-1.28-1.26h0a.872.872 0 01-.02-1.24c0-.01.01-.02.01-.02l3.83-3.77h-.01c.35-.35.92-.35 1.27-.01l1.27 1.255-.01-.01c.34.33.35.89.01 1.23-.01 0-.02.01-.02.01zM19.168 5.23l2.59-2.6-1.45-1.445M23.2 4.09l-1.44-1.45M5.8 15.65h5.05M6.59 22.88h13.72M13.81 19.99v2.89"/></g>'
  }
})
