<template>
    <button
        class="action-menu-item"
        :class="active ? 'text-gray-700' : 'text-gray-200'"
        :disabled="!active"
        v-on="$listeners"
    >
        <app-icon :name="icon" class="h-6 w-6 mb-2"></app-icon>
        <div class="text-xs">
            {{ label }}
        </div>
    </button>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            default: true
        }
    }
};
</script>
