import get from 'lodash/get';

export default {
    arrayLength (pathToArray) {
        return (a, b) => {
            return get(a, pathToArray, a).length < get(b, pathToArray, b).length ? -1 : 1;
        };
    },

    date (pathToDate) {
        return (a, b) => {
            return new Date(get(a, pathToDate, null)) - new Date(get(b, pathToDate, null));
        };
    }
};
