/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'team-idea-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>brainstorm light bulb meeting</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M3.37 15.85a2.62 2.62 0 100 5.25 2.62 2.62 0 100-5.25zM6.581 23.2h0a4.505 4.505 0 00-5.84-.51M20.625 15.85a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM17.51 23.105h-.01a4.482 4.482 0 015.73-.41M12 14.35a3.375 3.375 0 100 6.75 3.375 3.375 0 100-6.75zM14.72 23.25l-.01-.01c-1.7-.86-3.69-.87-5.4-.03M15.75 4.6v-.01c0-2.08-1.68-3.75-3.75-3.75-2.08 0-3.75 1.67-3.75 3.75 0 1.49.88 2.84 2.25 3.43v1.81h3V8.01h-.01a3.76 3.76 0 002.24-3.44zM10.5 12.1h3M19.81 5.25h1.5M19.5 8.69l1.06 1.06M19.5 1.81L20.56.75M4.19 5.25h-1.5M4.5 8.69L3.44 9.75M4.5 1.81L3.44.75"/></g>'
  }
})
