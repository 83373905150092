/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-symptoms-nausea-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.22 3.83l2.92-1.95v1.94l2.92-1.95M17.35 17.105l3.23 1.38-1.63 1.06 3.23 1.38M17.086 11.182l3.35-1.06-.55 1.86 3.35-1.06M4.834 12h0C2.574 11.99.74 10.17.74 7.91c0-.14 0-.27.01-.4V7.5a4.191 4.191 0 014.24-3.69h.84v-.001c.56-.01 1.02-.46 1.02-1.03V.72"/><path pid="1" d="M4.834 12v-.001a4.08 4.08 0 00-4.09 4.08c0 .13 0 .26.01.39h0a4.19 4.19 0 004.24 3.68h.84-.01c.56-.01 1.02.45 1.02 1.02 0 0 0 0 0 0v2.04M11.981 23.231V20.151c0-2.26-1.83-4.09-4.09-4.09h2.04l-.01-.001c2.25 0 4.08-1.83 4.08-4.09s-1.83-4.09-4.09-4.09H7.87h0c2.25 0 4.08-1.83 4.08-4.09l-.001-.01V.71M4.83 12h2.05M7.9 7.92H6.88M7.9 16.08H6.88"/></g>'
  }
})
