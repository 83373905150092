<script>
import findIndex from 'lodash/findIndex';

let oldPageIndex = 0;

export default {
    name: 'SlidableList',

    props: {
        value: {
            type: [String, Number],
            required: true
        },

        animate: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        getTransitionName (newPageIndex) {
            if (!this.animate) {
                return '';
            }

            return newPageIndex > oldPageIndex ? 'slide-left' : 'slide-right';
        }
    },

    render (h) {
        const activeChildIndex = findIndex(this.$slots.default, { data: { key: this.value } });
        const activeChild = this.$slots.default[activeChildIndex];
        const transitionName = this.getTransitionName(activeChildIndex);

        oldPageIndex = activeChildIndex;

        return h('div', { class: 'h-full' }, [
            h('transition', {
                props: {
                    name: transitionName,
                    mode: 'out-in'
                }
            }, [
                activeChild
            ])
        ]);
    }
};
</script>
