/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-graph-death-rate-decreasing-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.777 6.375v-.01c.01-2.7-2.16-4.89-4.85-4.9a4.857 4.857 0 00-4.9 4.84c-.01 1.5.68 2.93 1.87 3.86v.3-.01c-.01.82.67 1.49 1.49 1.5h3l-.01-.001c.82 0 1.5-.68 1.5-1.5v-.32a4.877 4.877 0 001.87-3.82z"/><path pid="1" d="M15.027 6.375h-.01c.2-.01.37.16.37.37M14.65 6.75h0c-.01-.21.16-.38.37-.38M15.027 7.12h-.01a.386.386 0 01-.38-.38s0 0 0 0M15.4 6.75v0c0 .2-.17.375-.375.375M18.777 6.375h-.01c.2-.01.37.16.37.37M18.4 6.75h0c-.01-.21.16-.38.37-.38"/><path pid="2" d="M18.777 7.12v-.001a.38.38 0 01-.38-.38l-.01-.01M19.152 6.75v0c0 .2-.17.375-.375.375M16.9 10.5V12M21.777 22.5h-21L.77 1.5"/><path pid="3" d="M.77 9.5h1.4v-.001c3.37-.01 6.55 1.57 8.6 4.25l-.01-.01c2.04 2.67 5.22 4.25 8.59 4.25h3.846"/><path pid="4" d="M20.83 15.609L23.22 18l-2.39 2.39"/></g>'
  }
})
