/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'paragraph-align-right-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>text</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23 3H2M23 7.5H5M23 12H.5M23 16.5H5M23 21H2"/></g>'
  }
})
