/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-basket-add-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>cart new plus create</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 11.25a6 6 0 100 12 6 6 0 100-12zM17.25 14.25v6M14.25 17.25h6"/><path pid="1" d="M7.875 17.25H4.314l-.01-.01c-.67-.02-1.25-.49-1.39-1.14l-2.14-9-.01-.01c-.21-.8.26-1.61 1.05-1.82.1-.03.21-.05.32-.05h18.14c.81.02 1.46.71 1.43 1.53-.01.11-.03.21-.05.32l-.3 1.25M3.75 5.25l4.5-4.5M18.75 5.25l-4.5-4.5"/></g>'
  }
})
