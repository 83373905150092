<template>
    <div class="flex">
        <button
            v-for="({ id, label }, index) in options"
            :key="id"
            class="flex-1 py-2.5 border text-sm font-semibold hover:border-purple hover:bg-purple hover:text-white"
            :class="{
                'rounded-l': index === 0,
                'rounded-r': index === options.length - 1,
                'bg-white hover:border-purple hover:bg-purple hover:text-white': value !== id,
                'border-purple bg-purple text-white': value === id
            }"
            type="button"
            @click="$emit('input', id)"
        >
            {{ label }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'LocationTypePicker',

    props: {
        value: {
            type: String,
            default: null
        }
    },

    computed: {
        options () {
            return [
                { id: 'in-person', label: 'In-person' },
                { id: 'virtual', label: 'Virtual' },
                { id: 'hybrid', label: 'Hybrid' }
            ];
        }
    }
};
</script>
