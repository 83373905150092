/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'award-trophy-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>achievement star</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 15.75v3M18.361 23.25h0a6.758 6.758 0 00-8.62-4.12 6.717 6.717 0 00-4.12 4.11zM16.5.75h3.75-.01c.82-.01 1.5.67 1.5 1.5V6c0 5.38-4.37 9.75-9.75 9.75-5.39 0-9.75-4.37-9.75-9.75V2.25h0C2.23 1.42 2.91.74 3.73.74h3.75"/><path pid="1" d="M12.53 2.57l1.24 2.45h2.12-.001c.32-.01.58.24.59.56 0 .16-.07.31-.18.43l-1.96 1.91 1.08 2.48h0c.12.3-.02.64-.32.77-.18.07-.37.05-.53-.04l-2.62-1.48-2.62 1.471v-.01c-.29.16-.65.06-.81-.22a.585.585 0 01-.04-.53l1.08-2.49-1.96-1.93-.01-.01a.586.586 0 01-.02-.83c.11-.12.26-.19.43-.18h2.12l1.24-2.46v-.01a.587.587 0 011.05-.01z"/></g>'
  }
})
