<template>
    <div>
        <div
            v-if="block.pivot.question"
            v-theme="['form.text']"
            class="px-4 py-2"
        >
            <slot name="block-header"></slot>

            <div class="mt-8 mb-4 text-2xl break-words">
                <span v-if="block.pivot.question.title" v-theme="'form.title-text'">{{ block.pivot.question.title }}</span>
                <span v-else>Type a question...</span>
            </div>

            <div
                v-if="block.pivot.question.settings.description"
                v-theme="['form.text']"
                class="my-2 text-white break-words"
            >
                {{ block.pivot.question.settings.description }}
            </div>

            <component
                :is="`form-${block.pivot.question.type.slug}-question`"
                v-if="isEditMode"
                :question="block.pivot.question"
            ></component>

            <form-field-wrapper
                v-for="{ id, title } in answerables"
                :key="id"
                :error="getAnswerError(id)"
            >
                <div
                    v-if="title"
                    v-theme="'form.title-text'"
                    class="text-lg mb-4"
                >
                    {{ title }}
                </div>

                <component
                    :is="`form-${block.pivot.question.type.slug}-question`"
                    ref="customQuestion"
                    v-model="answers[id]"
                    :question="block.pivot.question"
                    :answerable-id="id"
                    class="mb-8"
                    @disable-completion="disableCompleteBlock"
                    @enable-completion="enableCompleteBlock"
                ></component>
            </form-field-wrapper>

            <div v-if="errorBag.has('payload.answers')" class="alert alert-error alert-sm mt-2">
                <p class="w-full font-normal text-center">{{ errorBag.get('payload.answers') }}</p>
            </div>

            <!-- Used only to get the default answer value when resetting answers -->
            <component
                :is="`form-${block.pivot.question.type.slug}-question`"
                ref="defaultCustomQuestionInput"
                :question="block.pivot.question"
                class="hidden"
            ></component>
        </div>

        <div
            v-else
            v-theme="['form.text', 'form.accent']"
            class="text-white rounded-md border p-10"
        >
            Custom Question

            <slot name="block-footer" :complete-block="completeBlock"></slot>
        </div>

        <slot
            name="block-footer"
            :complete-block="completeBlock"
            :next-button-disabled="nextButtonDisabled"
        ></slot>
    </div>
</template>

<script>
import map from 'lodash/map';
import zipObject from 'lodash/zipObject';
import cloneDeep from 'lodash/cloneDeep';
import times from 'lodash/times';
import FormBlock from '@/mixins/FormBlock';
import { getGuestsPassingCriteria } from '@/util/additional-criteria';

export default {
    name: 'FormCustomQuestionBlock',

    mixins: [FormBlock],

    behaviour: {
        render: {
            when (block, submission) {
                // == null checks for both undefined and null
                if (block.question == null) {
                    return false;
                }

                const { target } = block.question.settings;
                const { declined } = submission;
                if (declined && target === 'not-attending') {
                    return true;
                }

                const { askIf, additionalCriteria } = block.question.settings;

                return getGuestsPassingCriteria(askIf, additionalCriteria, submission).length > 0;
            },
            unless (block, submission) {
                return false;
            }
        }
    },

    data () {
        return {
            answers: {},
            nextButtonDisabled: false
        };
    },

    computed: {
        answerables () {
            if (!this.submission.id) {
                return [];
            }

            if (this.block.pivot.question.settings.target === 'group') {
                return [{ id: this.submission.id }];
            }

            const { askIf, additionalCriteria } = this.block.question.settings;

            const guests = getGuestsPassingCriteria(askIf, additionalCriteria, this.submission);

            return guests.map(({ id, formalName }) => {
                return { id, title: formalName };
            });
        },

        guests () {
            return this.submission.guests || [];
        },

        onCompletionListeners () {
            return {
                ticketing: () => { this.resetAnswers(); },
                rsvp: () => { this.resetAnswers(); }
            };
        }
    },

    watch: {
        isActive (newValue, oldValue) {
            // Detect transitions from inactive to active
            if (newValue && !oldValue) {
                // There can be multiple questions within the same block. Only
                // one can be in focus at a time
                if (this.$refs.customQuestion.length >= 1) {
                    this.$refs.customQuestion[0].focus();
                }
            }
        }
    },

    methods: {
        async completeBlock () {
            return this.getCompletionObject();
        },

        disableCompleteBlock () {
            this.nextButtonDisabled = true;
            this.enterKeyTriggersCompletion = false;
        },

        enableCompleteBlock () {
            this.nextButtonDisabled = false;
            this.enterKeyTriggersCompletion = true;
        },

        getAnswerError (answerId) {
            return this.errorBag.get(`answers.${answerId}`)
                || this.errorBag.get(`answers.${answerId}.custom`)
                || this.errorBag.getByPath(`answers.${answerId}.*.custom`);
        },

        resetAnswers () {
            const { defaultValue } = this.$refs.defaultCustomQuestionInput;

            this.answers = zipObject(
                map(this.answerables, 'id'),
                times(this.answerables.length, () => { return cloneDeep(defaultValue); })
            );
        },

        serializePayload () {
            return {
                answers: { ...this.answers }
            };
        }
    }
};
</script>
