/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-process': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>person task check tick stop no yes</desc><g _fill="currentColor"><path pid="0" d="M16 5.5a1 1 0 011-1h.5a1 1 0 000-2H17a3 3 0 00-3 3v5.75a.25.25 0 01-.25.25H12.5a1 1 0 000 2h1.25a.25.25 0 01.25.25v4.75a3 3 0 003 3h.5a1 1 0 100-2H17a1 1 0 01-1-1v-4.75a.25.25 0 01.25-.25h1.25a1 1 0 000-2h-1.25a.25.25 0 01-.25-.25zM20.3 4.707a1 1 0 001.332.074l1.991-1.594a1 1 0 10-1.248-1.562L21.082 2.66l-.164-.16A1 1 0 1019.5 3.91zM22.376 10.625l-1.294 1.035-.164-.164A1 1 0 1019.5 12.91l.8.8a1 1 0 001.332.074l1.991-1.594a1 1 0 10-1.248-1.562zM23.634 18.793a1 1 0 00-1.414 0l-.312.312a.25.25 0 01-.353 0l-.312-.312a1 1 0 00-1.414 1.414l.312.312a.25.25 0 010 .353l-.312.312a1 1 0 101.414 1.416l.312-.312a.249.249 0 01.353 0l.312.312a1 1 0 001.414-1.414l-.312-.312a.25.25 0 010-.353l.312-.312a1 1 0 000-1.416zM.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.689 18H10.5a.5.5 0 00.5-.5V14a5.5 5.5 0 10-11 0v3.5a.5.5 0 00.5.5z"/><circle pid="1" cx="5.499" cy="4" r="3.5"/><path pid="2" d="M16 5.5a1 1 0 011-1h.5a1 1 0 000-2H17a3 3 0 00-3 3v5.75a.25.25 0 01-.25.25H12.5a1 1 0 000 2h1.25a.25.25 0 01.25.25v4.75a3 3 0 003 3h.5a1 1 0 100-2H17a1 1 0 01-1-1v-4.75a.25.25 0 01.25-.25h1.25a1 1 0 000-2h-1.25a.25.25 0 01-.25-.25zM20.3 4.707a1 1 0 001.332.074l1.991-1.594a1 1 0 10-1.248-1.562L21.082 2.66l-.164-.16A1 1 0 1019.5 3.91zM22.376 10.625l-1.294 1.035-.164-.164A1 1 0 1019.5 12.91l.8.8a1 1 0 001.332.074l1.991-1.594a1 1 0 10-1.248-1.562zM23.634 18.793a1 1 0 00-1.414 0l-.312.312a.25.25 0 01-.353 0l-.312-.312a1 1 0 00-1.414 1.414l.312.312a.25.25 0 010 .353l-.312.312a1 1 0 101.414 1.416l.312-.312a.249.249 0 01.353 0l.312.312a1 1 0 001.414-1.414l-.312-.312a.25.25 0 010-.353l.312-.312a1 1 0 000-1.416zM.5 18h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.689 18H10.5a.5.5 0 00.5-.5V14a5.5 5.5 0 10-11 0v3.5a.5.5 0 00.5.5z"/><circle pid="3" cx="5.499" cy="4" r="3.5"/></g>'
  }
})
