/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grid-lock': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>crop aspect ratio layers</desc><g _fill="currentColor"><path pid="0" d="M8 19a.25.25 0 01-.25-.25V15a.25.25 0 01.25-.25h5a.75.75 0 00.75-.75V8a.25.25 0 01.25-.25h3.75A.25.25 0 0118 8v1.566a.253.253 0 00.089.191.249.249 0 00.2.055 4.073 4.073 0 011.416 0A.25.25 0 0020 9.566V3a3 3 0 00-3-3H3a3 3 0 00-3 3v15a3 3 0 003 3h9.25a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25zm4.25-6a.25.25 0 01-.25.25H8a.25.25 0 01-.25-.25V8A.25.25 0 018 7.75h4a.25.25 0 01.25.25zM2 8a.25.25 0 01.25-.25H6a.25.25 0 01.25.25v5a.25.25 0 01-.25.25H2.25A.25.25 0 012 13zm6-1.75A.25.25 0 017.75 6V2.25A.25.25 0 018 2h4a.25.25 0 01.25.25V6a.25.25 0 01-.25.25zM18 3v3a.25.25 0 01-.25.25H14a.25.25 0 01-.25-.25V2.25A.25.25 0 0114 2h3a1 1 0 011 1zM3 2h3a.25.25 0 01.25.25V6a.25.25 0 01-.25.25H2.25A.25.25 0 012 6V3a1 1 0 011-1zM2 18v-3a.25.25 0 01.25-.25H6a.25.25 0 01.25.25v3.75A.25.25 0 016 19H3a1 1 0 01-1-1z"/><path pid="1" d="M19 11a3.244 3.244 0 00-3.225 3c-.006.084-.025.164-.025.25v1a.25.25 0 01-.25.25A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.5-1.5.25.25 0 01-.25-.25v-1c0-.086-.019-.166-.025-.25A3.244 3.244 0 0019 11zm1 9.25a1 1 0 11-1-1 1 1 0 011 1zm.75-6v1a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25v-1a1.708 1.708 0 01.025-.25 1.742 1.742 0 013.45 0 1.708 1.708 0 01.025.25z"/></g>'
  }
})
