/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chair-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>seat</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 17.25v6M18.75 17.25v6"/><rect pid="1" width="13.5" height="6" x="5.25" y="2.25" rx="1.5"/><rect pid="2" width="16.5" height="3" x="3.75" y="14.25" rx=".75"/><path pid="3" d="M6.75 8.25v6M6.75 2.25V.75M17.25 8.25v6M17.25 2.25V.75"/></g>'
  }
})
