/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drink-cocktail-glass-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>bar alcohol liquor</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.25 23.25h6M11.25 23.25V13.5M.75.75h2.82-.01c.56-.01 1.08.32 1.34.82l1.83 3.67"/><path pid="1" d="M18.71 5.25l-.01-.001c.41 0 .75.33.75.75-.01.02-.01.04-.01.07v-.01a8.256 8.256 0 01-16.43-.01L3 6.03c-.05-.42.26-.79.67-.83.02-.01.04-.01.07-.01z"/><path pid="2" d="M15.828 5.256l-.01-.01a3.749 3.749 0 117.34-1.53c.41 2.02-.89 4.01-2.92 4.43-.25.05-.51.07-.76.07h0c-.19-.01-.37-.02-.55-.04"/></g>'
  }
})
