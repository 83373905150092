/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chart-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>analytics board graph line report</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="22.5" height="16.5" x=".75" y="3.75" rx="1.5"/><path pid="1" d="M3.75 15L6.9 9.75 9.75 15l3-3 3 3 4.5-6.75"/></g>'
  }
})
