<template>
    <app-modal :value="value" @closed="$emit('input', false)">
        <template #header>
            Email Campaign Usage Agreement
        </template>

        <div class="p-6">
            <p class="mb-6">
                By checking the box below, I agree to the following terms regarding the use of RSVPify's email campaign services:
            </p>

            <ul class="space-y-6 list-disc ml-8">
                <li>
                    <span class="font-bold">Permission-Based Lists:</span> I confirm that all email addresses in my mailing list have been obtained with explicit consent from the recipients.
                </li>
                <li>
                    <span class="font-bold">No Paid Lists:</span> I agree not to use any purchased, rented, or third-party lists of email addresses.
                </li>
                <li>
                    <span class="font-bold">Low Bounce Rate:</span> I confirm that my list is up-to-date with <span class="italic">active</span> email addresses of willing recipients only.
                </li>
                <li>
                    <span class="font-bold">Compliance with Laws:</span> I will adhere to all applicable laws and regulations regarding email communication, including but not limited to anti-spam laws.
                </li>
                <li>
                    <span class="font-bold">Liability for Violations:</span> I understand that I am solely responsible for any legal violations or complaints arising from my use of the service.
                </li>
                <li>
                    <span class="font-bold">Right to Terminate Service:</span> RSVPify reserves the right to suspend or terminate my account if any of these terms are violated or if bounce or SPAM rates are above industry standards for the emails you send.
                </li>
            </ul>

            <p class="mt-6 flex items-center">
                <input
                    id="email-agreement"
                    v-model="accepted"
                    class="h-6 w-6 rounded mr-4"
                    type="checkbox"
                >
                <label for="email-agreement">
                    I have read and agree to the terms outlined in this Email Campaign Usage Agreement.
                </label>
            </p>
        </div>

        <template #footer="{ close }">
            <button class="button" @click="close">
                Cancel
            </button>

            <stateful-button
                class="button-primary"
                :loading="saving"
                :disabled="!accepted"
                @click="accept"
            >
                Agree
            </stateful-button>
        </template>
    </app-modal>
</template>

<script>

import axios from '@/util/axios';

export default {
    name: 'EmailAgreement',

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            accepted: false,
            saving: false
        };
    },

    methods: {
        accept () {
            this.saving = true;

            axios.post(this.route('api.account.accept-email-terms'))
                .then(() => {
                    this.$toasted.global.success({
                        message: 'You have accepted the Email Campaign Usage Agreement.'
                    });

                    this.$emit('input', false);
                    this.$emit('accepted');
                })
                .catch(() => {
                    this.$toasted.global.error('Something went wrong.  Please try again.');
                })
                .finally(() => {
                    this.saving = false;
                });
        }
    }
};
</script>
