import { get } from 'vuex-pathify';

export default {
    props: {
        block: {
            type: Object,
            required: true
        }
    },

    computed: {
        event: get('Event/event'),

        canLimitAttendance () {
            if (!this.event.hasInvitees) {
                return true;
            }

            return this.block.slug === 'ticketing' && !this.event.invite_list.is_enabled;
        },

        limitedAttendancePanelTooltip () {
            if (!this.canLimitAttendance) {
                return 'You are currently using an invite list. If you’re hosting a private event, you can control the maximum number of attendees at a group-level.';
            }

            return 'Limit the total number of attendees that can be included in a single registration or RSVP. Applies to Admit Ones, and total guest counts included in Group Packages and Tables. Does not apply to invite-only private events.';
        }
    }
};
