/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rotate-back-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>arrow update synchronize refresh history undo</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.75 7.5l-3-3 3-3M3.03 12.75c-.02.24-.04.5-.04.75h-.01c-.01.75.09 1.51.28 2.24M5.106 7.715h-.01c-.49.58-.91 1.22-1.23 1.9M5.12 19.312c.16.18.32.37.508.55h0c.53.53 1.13 1 1.79 1.38"/><path pid="1" d="M11.25 22.46c.24.02.5.03.75.03l-.01-.001a9 9 0 009-9c0-4.98-4.03-9-9-9.01H9.73"/></g>'
  }
})
