/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-halloween-pumpkin-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>jack lantern spooky scary haunted</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.9 5.888c.24.01.6.04.85.08h0c4.56.66 7.82 4.75 7.46 9.34v0-.01c-.47 4.54-4.33 7.97-8.89 7.92H9.65a8.84 8.84 0 01-8.89-7.93v0l-.01-.01a8.742 8.742 0 017.61-9.37c.2-.03.5-.05.7-.07"/><path pid="1" d="M17.25 17.25l-1.513 1.4v-.01c-.24.2-.59.19-.8-.03l-.87-.94h0a.595.595 0 00-.83-.01l-.84.9v-.01c-.23.22-.6.22-.83 0l-.84-.9h0a.595.595 0 00-.83-.01l-.87.93v-.01c-.22.21-.57.22-.8.02l-1.51-1.4M4.5 13.53h-.01c.05-1.19 1.06-2.1 2.24-2.05v-.01c1.18-.06 2.19.85 2.25 2.04M19.5 13.53l-.01-.01a2.162 2.162 0 00-2.25-2.05h0c-1.19-.06-2.2.86-2.25 2.04M12 4.84V.75M16.61 8.25h0a5.11 5.11 0 00-4.62-3.41h-.01c-2.1.08-3.93 1.43-4.62 3.4"/></g>'
  }
})
