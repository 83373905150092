/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tool-wrench-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.4 16.74l7.34-7.35-.01-.01c2.26 1.08 4.98.12 6.06-2.15.59-1.24.59-2.68 0-3.92l-1.43 1.42v-.01c-.6.59-1.56.59-2.15 0-.6-.6-.6-1.56-.01-2.15l1.42-1.43h0a4.555 4.555 0 00-6.07 2.15 4.54 4.54 0 000 3.91L7.2 14.54h0a4.542 4.542 0 00-6.07 2.14c-.6 1.23-.6 2.67-.01 3.91l1.42-1.43h-.01a1.51 1.51 0 112.144 2.13l-1.43 1.42-.01-.01c2.26 1.07 4.98.11 6.06-2.16.58-1.24.58-2.68-.01-3.92z"/>'
  }
})
