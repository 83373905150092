/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download-bottom-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12.001 3.75v12M7.501 11.25l4.5 4.5 4.5-4.5M23.251 15.75v1.5a3 3 0 01-3 3h-16.5a3 3 0 01-3-3v-1.5"/>'
  }
})
