/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'globe-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>earth planet</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/><path pid="1" d="M7.07 15.75v-.001c.82-.01 1.49-.68 1.49-1.51a2.2 2.2 0 00-.05-.37l-.75-3h0c-.17-.67-.77-1.14-1.46-1.14H.93c-.85 4.14.7 8.4 4.01 11.04l1-5.05zM20.98 5.25h-4.07v-.001c-.69 0-1.29.46-1.46 1.13l-.75 3h-.01c-.21.8.28 1.61 1.09 1.81.11.02.24.04.36.04h1.57l.79 4.75-.01-.01c.12.72.74 1.25 1.48 1.25h1.92v-.01a11.21 11.21 0 00-.97-12z"/></g>'
  }
})
