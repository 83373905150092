/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-cart-cloud-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.625 12.75l-2.25 7.5h-6l-1.5-4.5h8.85M9.75 22.5h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38 0 0 0 0 0 0M15 22.5h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38 0 0 0 0 0 0M10.125 10.5l2.25 2.25V7.5M14.62 10.5l-2.24 2.25"/><path pid="1" d="M7.3 9.24l-.01-.01c-2.22-1.14-3.1-3.85-1.97-6.06a4.494 4.494 0 016.05-1.97c1.37.7 2.29 2.06 2.43 3.6v-.01a2.985 2.985 0 114.58 3.83c-.34.39-.76.69-1.24.87"/></g>'
  }
})
