/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>hyperlink url</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M10.082 9.5l-.01-.01c-.85-.96-2.06-1.5-3.34-1.5H5.21c-2.49 0-4.5 2.01-4.5 4.5 0 2.48 2.01 4.5 4.5 4.49h1.5-.01c1.27-.01 2.48-.55 3.33-1.5M13.918 9.5h-.01c.84-.96 2.05-1.5 3.33-1.5h1.5-.01c2.48-.01 4.5 2.01 4.5 4.49 0 2.48-2.02 4.5-4.5 4.5h-1.5l-.01-.001a4.517 4.517 0 01-3.34-1.5M6.75 12.5h10.5"/></g>'
  }
})
