<script>
import axios from '@/util/axios';
import ExportButton from '../core/ExportButton.vue';

const STATE_EXPORT_READY = 'export-ready';
const STATE_FAILED = 'failed';

export default {
    extends: ExportButton,

    methods: {
        initiateExport () {
            axios.get(
                this.route('invite-list.export', {
                    event: this.event,
                    columns: this.columnsByName,
                    predicates: this.predicates,
                    columnExportType: this.columnExportType,
                    rowExportType: this.rowExportType,
                    timezone: this.eventTimezone
                })
            );
        },

        listenForEvents () {
            this.$echo.private(`events.${this.event.id}`)
                .listen('.Domain\\InviteList\\Events\\ExportFailed', () => {
                    this.state = STATE_FAILED;
                })
                .listen('.Domain\\InviteList\\Events\\InviteListExported', ({ downloadUrl }) => {
                    this.downloadUrl = downloadUrl;
                    this.state = STATE_EXPORT_READY;
                    window.location = downloadUrl;
                })
                .listen('.Domain\\Submissions\\Events\\ExportFailed', () => {
                    this.state = STATE_FAILED;
                })
                .listen('.Domain\\Submissions\\Events\\SubmissionsExported', ({ downloadUrl }) => {
                    this.downloadUrl = downloadUrl;
                    this.state = STATE_EXPORT_READY;
                    window.location = downloadUrl;
                });
        }
    }
};
</script>
