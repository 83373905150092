/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'navigation-menu-vertical': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M12-.001a3.25 3.25 0 100 6.5 3.25 3.25 0 100-6.5zM12 8.75a3.25 3.25 0 100 6.5 3.25 3.25 0 100-6.5zM12 17.5a3.25 3.25 0 100 6.5 3.25 3.25 0 100-6.5z"/></g>'
  }
})
