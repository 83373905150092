/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'move-vertical-arrows-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>scroll drag drop grab</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 9.74a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM6 6.308l5.25-5.25h-.01a1.06 1.06 0 011.5-.01l5.25 5.25M18 17.687l-5.25 5.25v-.01c-.42.41-1.09.41-1.5 0L6 17.677"/></g>'
  }
})
