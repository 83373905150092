import axios from 'axios';
import stringify from 'qs-stringify';

const instance = axios.create({
    paramsSerializer (params) {
        return stringify(params);
    }
});

const token = document.head.querySelector('meta[name="csrf-token"]');

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

if (token) {
    instance.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error(
        'CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token'
    );
}

instance.interceptors.response.use(
    (response) => {
        return response;
    },

    (error) => {
        if (error.response && error.response.status >= 500) {
            App.$emit('error', error.response.data.message);
        }

        return Promise.reject(error);
    }
);

export default instance;
