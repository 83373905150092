/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dessert-ice-cream-bowl': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food</desc><g _fill="currentColor"><path pid="0" d="M9.25 7a4.985 4.985 0 013.321 1.265.5.5 0 00.617.038 4.478 4.478 0 011.969-.764.5.5 0 00.41-.653 3.461 3.461 0 00-.82-1.338 3.328 3.328 0 00-1.342-.826.254.254 0 01-.165-.18.249.249 0 01.067-.235 1.481 1.481 0 00.443-1.057 1.5 1.5 0 00-3 0 1.48 1.48 0 00.442 1.057.249.249 0 01.067.235.252.252 0 01-.165.18 3.344 3.344 0 00-1.342.825 3.486 3.486 0 00-.734 1.106A.251.251 0 009.25 7zM13.435 19.423c3.66-.474 6.528-3.093 6.813-5.952a.4.4 0 00-.13-.331.539.539 0 00-.368-.14h-15a.536.536 0 00-.369.141.4.4 0 00-.13.33c.286 2.859 3.153 5.478 6.812 5.952a.5.5 0 01.436.5V22a.5.5 0 01-.5.5h-1.75a.75.75 0 000 1.5h6a.75.75 0 100-1.5H13.5a.5.5 0 01-.5-.5v-2.082a.5.5 0 01.435-.495zM6.165 7.81a.5.5 0 00.435-.047 4.9 4.9 0 01.821-.415.5.5 0 00.29-.623L5.7.685a1 1 0 00-1.9.631L5.859 7.5a.5.5 0 00.306.31zM13.69 9.7a4.955 4.955 0 01.54 1.843.5.5 0 00.5.454h4.263a.25.25 0 00.183-.08.253.253 0 00.066-.189 3.5 3.5 0 00-5.377-2.679.5.5 0 00-.175.651zM9.25 8a4 4 0 00-3.991 3.734.25.25 0 00.249.266h7.484a.25.25 0 00.182-.079.248.248 0 00.067-.188A4 4 0 009.25 8z"/></g>'
  }
})
