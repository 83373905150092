<template>
    <div class="flex flex-col grow">
        <div class="flex flex-wrap">
            <form-field-wrapper label="Section Icon">
                <icon-picker
                    v-model="form.icon"
                    class="text-gray-800 w-11 h-11"
                ></icon-picker>
            </form-field-wrapper>

            <form-field-wrapper
                label="Section Title"
                :error="form.errors.get('title')"
                :should-show-error="form.errors.has('title')"
            >
                <input
                    v-model="form.title"
                    class="form-field"
                >
            </form-field-wrapper>

            <form-field-wrapper label="About this block">
                <p>This block will publically share the names of all registered attendees.</p>
            </form-field-wrapper>

            <form-field-wrapper>
                <toggle-switch v-model="form.displayTitle">
                    Display Section Icon &amp; Title
                </toggle-switch>

                <toggle-switch
                    v-model="form.displayInMenu"
                    class="mt-4"
                >
                    Show Section In Menu
                </toggle-switch>
            </form-field-wrapper>

            <form-field-wrapper label="Sort Guests By">
                <select-list v-model="form.sort">
                    <select-option
                        id="-submissionTimestamp"
                        tooltip="The newest registrations will be listed at the top."
                        class="my-2"
                    >
                        <span class="tooltip-text">Submission Date</span>
                    </select-option>
                    <select-option
                        id="+lastName"
                        tooltip="Attendees will be sorted in ascending order by last name."
                        class="my-2"
                    >
                        <span class="tooltip-text">Last Name (Alphabetically)</span>
                    </select-option>
                </select-list>
            </form-field-wrapper>

            <portal to="app-drawer-footer">
                <footer-save-cancel
                    @cancel="$emit('cancel')"
                    @save="$emit('save-settings')"
                ></footer-save-cancel>
            </portal>
        </div>
    </div>
</template>

<script>
import HasDefaultCloseHandler from '@/mixins/HasDefaultCloseHandler';
import BlockableSettingsMixin from '@/mixins/BlockableSettingsMixin';

export default {
    name: 'WallGuestListBlockSettings',

    mixins: [HasDefaultCloseHandler, BlockableSettingsMixin]
};
</script>
