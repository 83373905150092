/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-graph-infected-decreasing-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.777 22.5h-21L.77 1.5M16.52 3.75a3 3 0 100 6 3 3 0 100-6zM16.03 1.5h1M16.53 1.5v2.25M19.89 2.68l.7.71M20.24 3.04l-1.59 1.59M21.78 6.25v1M21.78 6.75h-2.25M20.59 10.11l-.7.71M20.24 10.46l-1.59-1.59M17.03 12h-1M16.53 12V9.75M13.17 10.82l-.71-.71M12.81 10.46l1.59-1.59M11.28 7.25v-1M11.28 6.75h2.25M12.46 3.39l.71-.71M12.81 3.04l1.59 1.59"/><path pid="1" d="M.77 8.87h1.4v-.001c3.37-.01 6.55 1.57 8.6 4.25l-.01-.01c2.04 2.67 5.22 4.25 8.59 4.25h3.846"/><path pid="2" d="M20.83 14.984l2.39 2.391-2.39 2.39"/></g>'
  }
})
