<template>
    <div>
        <textarea
            ref="textarea"
            v-theme="['form.accent', 'form.text']"
            class="w-full appearance-none outline-none py-2 px-4 border rounded-lg bg-transparent leading-normal transition duration-150 ease-in-out resize-none"
            :placeholder="placeholderText"
            :value="value"
            :readonly="readonly"
            @input="$emit('input', $event.target.value)"
            @click.stop
        ></textarea>

        <div v-if="showError" class="alert alert-error alert-sm mt-2">
            <p class="w-full font-normal text-center">{{ error }}</p>
        </div>
    </div>
</template>

<script>
import HandleErrorMessages from '@/mixins/HandleErrorMessages';

export default {
    mixins: [HandleErrorMessages],

    props: {
        placeholder: {
            type: String,
            default: 'Type here...'
        },

        readonly: {
            type: Boolean,
            default: false
        },

        value: {
            type: String,
            required: true
        }
    },

    computed: {
        placeholderText () {
            return this.readonly ? '' : this.placeholder;
        }
    },

    methods: {
        focus () {
            this.$refs.textarea.focus();
        }
    }
};
</script>
