<template>
    <div>
        <form-field-wrapper :error="getError('streetAddress')" class="-mb-4">
            <single-line-text-input
                ref="input"
                :value="internalValue.streetAddress"
                :placeholder="$t('placeholder-street-address')"
                @input="setProperty('streetAddress', $event)"
            ></single-line-text-input>
        </form-field-wrapper>

        <form-field-wrapper
            v-if="question.settings.mailingAddress.includeSecondStreetAddressField"
            :error="getError('secondStreetAddress')"
            class="-mb-4"
        >
            <single-line-text-input
                :value="internalValue.secondStreetAddress"
                :placeholder="$t('placeholder-second-street-address')"
                @input="setProperty('secondStreetAddress', $event)"
            ></single-line-text-input>
        </form-field-wrapper>

        <div class="flex space-x-2" :class="question.settings.mailingAddress.includeCityField ? '-mt-4' : 'mt-4'">
            <form-field-wrapper
                v-if="question.settings.mailingAddress.includeCityField"
                :error="getError('city')"
                class="w-1/2 mt-8"
            >
                <single-line-text-input
                    :value="internalValue.city"
                    :placeholder="$t('placeholder-city')"
                    class="w-full"
                    @input="setProperty('city', $event)"
                ></single-line-text-input>
            </form-field-wrapper>

            <form-field-wrapper
                v-if="question.settings.mailingAddress.includeStateField"
                :error="getError('state')"
                class="w-1/2"
                :class="question.settings.mailingAddress.includeCityField ? '' : 'mt-4'"
            >
                <div class="w-full">
                    <us-states-picker
                        v-if="showStatesPicker"
                        v-theme="['form.text', 'form.accent', 'form.dropdown']"
                        :value="internalValue.state"
                        :placeholder-empty-state="$t('placeholder-state')"
                        :placeholder-search="$t('placeholder-search-state')"
                        class="w-full text-lg"
                        item-value="name"
                        @input="setProperty('state', $event)"
                    ></us-states-picker>

                    <single-line-text-input
                        v-else
                        :value="internalValue.state"
                        :placeholder="$t('placeholder-state')"
                        class="w-full"
                        @input="setProperty('state', $event)"
                    ></single-line-text-input>
                </div>
            </form-field-wrapper>
        </div>

        <div class="flex space-x-2 mb-8" :class="question.settings.mailingAddress.includeZipCodeField ? '-mt-4' : 'mt-4'">
            <form-field-wrapper
                v-if="question.settings.mailingAddress.includeZipCodeField"
                :error="getError('zipCode')"
                class="w-1/2 mt-8"
            >
                <single-line-text-input
                    :value="internalValue.zipCode"
                    :placeholder="$t('placeholder-zip-code')"
                    class="w-full"
                    @input="setProperty('zipCode', $event)"
                ></single-line-text-input>
            </form-field-wrapper>

            <form-field-wrapper
                v-if="showCountryPicker"
                :error="getError('country')"
                class="w-1/2"
            >
                <country-picker
                    v-theme="['form.text', 'form.accent', 'form.dropdown']"
                    :value="internalValue.country"
                    :placeholder-empty-state="$t('placeholder-country')"
                    :placeholder-search="$t('placeholder-search-country')"
                    class="w-full text-lg"
                    item-value="name"
                    @input="setProperty('country', $event)"
                ></country-picker>
            </form-field-wrapper>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import CustomQuestion from '@/mixins/CustomQuestion';

export default {
    name: 'FormMailingAddressQuestion',

    mixins: [CustomQuestion],

    computed: {
        errorBag: get('Submission/errorBag'),

        defaultValue () {
            return {
                streetAddress: '',
                secondStreetAddress: '',
                city: '',
                state: '',
                zipCode: '',
                country: this.showCountryPicker ? this.question.settings.mailingAddress.defaultCountry : ''
            };
        },

        showCountryPicker () {
            return this.question.settings.mailingAddress.includeCountryField && this.question.settings.mailingAddress.stateField !== 'usStates';
        },

        showStatesPicker () {
            return this.question.settings.mailingAddress.stateField === 'usStates';
        }
    },

    methods: {
        focus () {
            this.$refs.input.focus();
        },

        getError (field) {
            return this.errorBag.get(`answers.${this.answerableId}.${field}`);
        }
    }
};
</script>
