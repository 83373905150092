/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video-game-controller-team-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25 14.25v3M3.75 15.75h3M17.25 16.5h-.01c.2-.01.37.16.37.37"/><path pid="1" d="M16.875 16.875h0c-.01-.21.16-.38.37-.38M17.25 17.25h-.01a.386.386 0 01-.38-.38s0 0 0 0M17.625 16.875v0c0 .2-.17.375-.375.375M19.5 14.25h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M19.125 14.625h0c-.01-.21.16-.38.37-.38M19.5 15h-.01a.386.386 0 01-.38-.38s0 0 0 0M19.875 14.625v0c0 .2-.17.375-.375.375"/><path pid="3" d="M15.029 19.5h0c.45 0 .88.2 1.17.56l1.63 2.04h0a3.035 3.035 0 004.27.47c.72-.58 1.14-1.46 1.14-2.38v-5.96 0c0-1.66-1.35-3-3-3H3.72c-1.66 0-3 1.34-3 3 0 0 0 0 0 0v5.96-.01a3.03 3.03 0 003.03 3.04c.92 0 1.79-.42 2.37-1.15l1.63-2.05h-.01c.28-.36.71-.57 1.17-.57zM4.5.75A2.62 2.62 0 104.5 6a2.62 2.62 0 100-5.25zM8.25 9h0A4.251 4.251 0 002.5 7.24c-.75.39-1.36 1-1.75 1.75M12 .75A2.625 2.625 0 1012 6a2.625 2.625 0 100-5.25zM15.75 9h0A4.251 4.251 0 0010 7.24c-.75.39-1.36 1-1.75 1.75M19.5.75a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM23.25 9h0a4.251 4.251 0 00-5.75-1.76c-.75.39-1.36 1-1.75 1.75"/></g>'
  }
})
