/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-protect-shield-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4 8.94a1.95 1.95 0 100 3.9 1.95 1.95 0 100-3.9zM5.3 23.25l.65-3.9h1.3V17.4v0c0-1.8-1.46-3.25-3.25-3.25-1.8 0-3.25 1.45-3.25 3.25v1.95h1.3l.65 3.9zM20 8.94a1.95 1.95 0 100 3.9 1.95 1.95 0 100-3.9zM18.7 23.25l-.65-3.9h-1.3V17.4h0a3.244 3.244 0 013.24-3.26 3.241 3.241 0 013.25 3.24s0 0 0 0v1.95h-1.3l-.65 3.9zM4 4.75h4.65M4 5.75v-2M20 4.75h-4.65M20 5.75v-2M12 .75V.74c-.93.56-1.97.89-3.05.97h-.01c-.26.02-.46.24-.46.5v1.22l-.01-.01c0 2.3 1.37 4.39 3.49 5.3v-.01c2.12-.92 3.49-3 3.5-5.31V2.17c0-.26-.2-.48-.46-.5h0a6.857 6.857 0 01-3.05-.98z"/></g>'
  }
})
