/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chess-pawn-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a3.75 3.75 0 100 7.5 3.75 3.75 0 100-7.5z"/><path pid="1" d="M9.29 7.092c1.58 3.386-.1 6.77-1.79 10.15h9c-1.7-3.39-3.375-6.772-1.79-10.158M20.25 21.75v0c0-.83-.68-1.5-1.5-1.5H5.24c-.83 0-1.5.67-1.5 1.5 0 .82.67 1.5 1.5 1.5h13.5v-.001c.82-.01 1.49-.68 1.49-1.5z"/><path pid="2" d="M16.5 17.25H7.49a2.907 2.907 0 00-2.25 3h13.5v-.01c.07-1.42-.88-2.68-2.26-3z"/></g>'
  }
})
