<script>
export default {
    props: {
        scrollable: {
            type: Boolean,
            default: true
        },
        isFullWidth: {
            type: Boolean,
            default: false
        }
    },

    render (h) {
        const directives = this.scrollable
            ? [{ name: 'bar', value: { useScrollbarPseudo: true } }]
            : [];

        const scrollerContent = !this.isFullWidth
            ? this.$slots.default
            : [
                h(
                    'div',
                    {
                        class: 'scrollbar-none'
                    },
                    [
                        h(
                            'div',
                            {
                                class: 'max-w-3xl mx-auto'
                            },
                            this.$slots.default
                        )
                    ]
                )
            ];

        return h(
            'div',
            {
                class: 'flex items-start relative wrapper',
                directives
            },
            scrollerContent
        );
    }
};
</script>
