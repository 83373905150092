/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'concert-microphone-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>music party stage</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><rect pid="0" width="22.5" height="4.5" x=".75" y=".75" rx="1"/><path pid="1" d="M2.25 5.25v18M21.75 5.25v18M2.25.75l4.5 4.5L12 .75l5.25 4.5 4.5-4.5M12 13.02v6.48M.75 23.25h22.5M9.75 10.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM14.53 14.17l-3.41-1.55M8.25 23.25L12 19.5l3.75 3.75"/></g>'
  }
})
