/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-not-allowed-space-woman-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M1 19.05l4 4M5 19.05l-4 4M19 19.05l4 4M23 19.05l-4 4M12 .75a3 3 0 100 6 3 3 0 100-6zM9.11 9.11H9.1a5.23 5.23 0 00-2.37 4.38v2.25h2.25l.75 7.5h4.5l.75-7.5h2.25v-2.25h-.01c0-1.77-.89-3.42-2.37-4.39l-2.89 4.38-2.89-4.39M9 3.75S9 7.5 6.75 7.5M15 3.75s0 3.75 2.25 3.75"/></g>'
  }
})
