/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-barcode-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>shopping</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.183 7.605l-.55 3.88v-.01c-.01.4.15.78.43 1.06l9.5 9.5h0c.58.58 1.53.58 2.12 0v-.01l6.36-6.37h-.01c.58-.59.58-1.54 0-2.13L12.4 3.89h0c-.3-.3-.69-.45-1.1-.44l-3.84.6"/><path pid="1" d="M8.44 6.94a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM7.38 7.38L1.5 1.5M11.45 11.45l2.12-2.12M13.57 13.57l2.13-2.12M15.7 15.7l2.12-2.13M9.33 13.57l4.24 4.25"/></g>'
  }
})
