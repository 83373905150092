/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>forward</desc><g _fill="currentColor"><path pid="0" d="M12 24l-.01-.001c6.62 0 12-5.38 12-12 0-6.63-5.38-12-12-12-6.63-.01-12 5.37-12 12 0 6.62 5.37 11.99 12 11.99zm0-2l-.01-.001c-5.53-.01-10-4.48-10-10.01 0-5.53 4.47-10 10-10 5.52 0 10 4.47 9.99 10v-.01c-.01 5.52-4.48 9.99-10 10z"/><path pid="1" d="M12.115 15.616l1.939-1.94h-.01c.09-.1.09-.26-.01-.36a.25.25 0 00-.18-.08H5.97l-.01-.001c-.7-.01-1.25-.56-1.25-1.26s.55-1.25 1.25-1.25h7.87c.13 0 .25-.12.25-.25 0-.07-.03-.14-.08-.18L12.06 8.35l-.01-.01a1.25 1.25 0 010-1.77c.48-.49 1.27-.49 1.76 0l4.5 4.5c.48.48.48 1.27 0 1.76h-.01l-4.5 4.5v-.01c-.49.48-1.28.48-1.77 0-.49-.49-.49-1.28-.01-1.77z"/></g>'
  }
})
