/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email-sync-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>refresh update arrows</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15 18.75h-3.75v3.75"/><path pid="1" d="M22.667 19.48v-.01c-1 2.91-4.17 4.46-7.08 3.46a5.576 5.576 0 01-3.67-4.21M19.5 15.75h3.75V12"/><path pid="2" d="M11.833 15.017h-.01a5.555 5.555 0 017.07-3.47c1.88.64 3.28 2.24 3.66 4.2"/><path pid="3" d="M8.25 15.75h-6l-.01-.001a1.51 1.51 0 01-1.5-1.5V2.238c0-.83.67-1.5 1.5-1.5h18-.01c.82-.01 1.5.67 1.5 1.5 0 0 0 0 0 0v6"/><path pid="4" d="M21.411 1.3l-8.15 6.26v-.01a3.33 3.33 0 01-4.04 0L1.07 1.28"/></g>'
  }
})
