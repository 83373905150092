/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-alt-add-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 11.25a6 6 0 100 12 6 6 0 100-12zM17.25 14.25v6M14.25 17.25h6M.75 17.25v-.01c0-3.73 3.02-6.76 6.75-6.75.9 0 1.8.18 2.64.54M7.5.75A4.125 4.125 0 107.5 9a4.125 4.125 0 100-8.25z"/></g>'
  }
})
