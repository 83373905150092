/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-touch-finger-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.5 3.32a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM5.93.75h1.14M6.5.75v2.57M10.34 2.1l.81.81M10.74 2.51L8.92 4.33M12.5 6.18v1.14M12.5 6.75H9.93M11.15 10.59l-.81.81M10.74 10.99L8.92 9.17M7.07 12.75H5.93M6.5 12.75v-2.57M2.66 11.4l-.81-.81M2.26 10.99l1.82-1.82M.5 7.32V6.18M.5 6.75h2.57M1.85 2.91l.81-.81M2.26 2.51l1.82 1.82M13 23.25l-2.77-3.11-.01-.01c-.58-.64-.54-1.63.1-2.2a1.54 1.54 0 012.19.1l.02.02 1.93 2.17v-9h0a1.496 1.496 0 112.99-.02s0 0 0 0v6.75h1.5-.01c2.48-.01 4.5 2.01 4.5 4.49v.75"/></g>'
  }
})
