<template>
    <app-modal
        :value="value"
        title="Manage Time Slots"
        :z-index="1001"
        full-screen
        :close-on-esc="!showOverridesModal"
        @input="$emit('input', $event)"
    >
        <div>
            <div v-if="loading" class="w-full text-center m-4">
                <app-icon class="h-6 w-6 text-gray-500" name="loader"></app-icon>
            </div>

            <div v-else class="mx-auto max-w-4xl space-y-4">
                <div class="text-center">
                    <button
                        class="flex-inline items-center justify-center py-2 px-6 rounded-full border-2 border-purple no-underline text-purple hover:border-purple-light hover:text-purple-light transition duration-150 ease-in-out shrink"
                        @click="addTimeslot"
                    >
                        Add Time Slots
                    </button>
                </div>

                <accordion-list
                    v-model="activeTimeslot"
                    class="space-y-4"
                    :should-close-function="cancelTimeslot"
                >
                    <accordion-list-item
                        v-for="(timeslot, timeslotIndex) in timeslots"
                        :key="timeslotIndex"
                        :item-id="timeslotIndex"
                        active-header-class="text-purple border-b pb-4"
                        class="w-full p-4 border rounded-md"
                    >
                        <template #header>
                            <div class="w-full" :class="{ 'opacity-25': !timeslot.active }">
                                <div class="tracking-tight font-medium">
                                    <div v-if="timeslot.id">
                                        <div v-if="timeslot.recurrence_rule.frequency === 'none'">
                                            {{ timeslot.formatted_start_date }}
                                        </div>
                                        <div v-else>
                                            <span v-if="!timeslot.formatted_end_date">From </span>
                                            {{ timeslot.formatted_start_date }}
                                            <span v-if="timeslot.formatted_end_date">to {{ timeslot.formatted_end_date }}</span>
                                        </div>
                                    </div>
                                    <div v-else>New Time Slot</div>
                                </div>
                                <div v-if="timeslot.id" class="tracking-tight font-light text-gray-500">
                                    {{ timeslot.formatted_start_time }} to {{ timeslot.formatted_end_time }}
                                    {{ getTimeslotHumanReadableFrequency(timeslot) }}
                                </div>
                            </div>
                        </template>

                        <template #content>
                            <div class="row py-6">
                                <div class="col-12">
                                    <div
                                        v-if="!timeslot.updateable"
                                        class="alert alert-warning w-full font-normal"
                                        role="alert"
                                    >
                                        <p>
                                            This block of time slots has existing registrations, and time ranges cannot be edited.
                                            You can unpublish or delete this time range and create a new series with revised parameters.
                                            Note that deleting or unpublishing an existing series <b>will NOT</b> impact existing registrations.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 items-start">
                                    <div class="w-full">
                                        <form-field-wrapper
                                            label="Start Date"
                                            :error="timeslot.errors.get('recurrence_rule.startDate')"
                                            :should-show-error="timeslot.errors.has('recurrence_rule.startDate')"
                                            tooltip="The date of your first available time slot"
                                            with-tooltip-icon
                                        >
                                            <date-time-picker
                                                v-model="timeslot.recurrence_rule.startDate"
                                                placeholder="Set start date..."
                                                :has-time="false"
                                                class="w-full"
                                                :disabled="!timeslot.updateable"
                                            ></date-time-picker>
                                        </form-field-wrapper>

                                        <form-field-wrapper
                                            label="Start Time"
                                            :error="timeslot.errors.get('recurrence_rule.startTime')"
                                            :should-show-error="timeslot.errors.has('recurrence_rule.startTime')"
                                            tooltip="The start time of your first available time slot"
                                            with-tooltip-icon
                                        >
                                            <time-picker
                                                v-model="timeslot.recurrence_rule.startTime"
                                                placeholder="Set start time..."
                                                :disabled="!timeslot.updateable"
                                            ></time-picker>
                                        </form-field-wrapper>

                                        <form-field-wrapper
                                            label="Days"
                                            :error="timeslot.errors.get('recurrence_rule.days')"
                                            :should-show-error="timeslot.errors.has('recurrence_rule.days')"
                                            tooltip="Select the days of the week that you will be offering available time slots"
                                            with-tooltip-icon
                                        >
                                            <div :class="getDayPickerStyle(timeslot)">
                                                <div
                                                    v-for="(day, dayIndex) in days"
                                                    :key="day"
                                                    class="inline-flex items-center justify-center h-10 w-10 mr-1 uppercase rounded-md cursor-pointer"
                                                    :class="timeslotDayStyles[day]"
                                                    @click="toggleDay(dayIndex)"
                                                >
                                                    {{ day.substring(0, 1) }}
                                                </div>
                                            </div>
                                        </form-field-wrapper>

                                        <form-field-wrapper
                                            v-if="products.length"
                                            label="Selectable ticket types & limited availability"
                                            tooltip="Cap how many attendees can select a single available time slot"
                                            with-tooltip-icon
                                        >
                                            <div class="italic">Leave blank if unlimited availability per time slot.</div>

                                            <div v-if="timeslot.errors.has(`max_quantity`)" class="alert alert-error alert-sm mt-2">
                                                <p class="w-full font-normal text-center">{{ timeslot.errors.get(`max_quantity`) }}</p>
                                            </div>

                                            <div class="w-full mt-6">
                                                <table class="w-full">
                                                    <thead>
                                                        <tr>
                                                            <td class="w-8"></td>
                                                            <td></td>
                                                            <td class="w-24"></td>
                                                        </tr>
                                                    </thead>
                                                    <tr>
                                                        <td class="w-8"></td>
                                                        <td></td>
                                                        <td class="w-24 text-center text-xs uppercase">Limit</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="w-8"></td>
                                                        <td class="font-bold">
                                                            <span>Total Headcount</span>
                                                            <app-icon
                                                                v-tippy
                                                                name="info-circle"
                                                                class="h-4 w-4 ml-2"
                                                                content="If offering multiple ticket types, cap the TOTAL number of attendees who can register for a given time slot"
                                                                stroke
                                                            ></app-icon>
                                                        </td>
                                                        <td class="w-24">
                                                            <input v-model.number="timeslot.max_quantity" class="form-field w-full">
                                                        </td>
                                                        <td v-if="timeslot.max_quantity !== null" class="w-8">
                                                            <button
                                                                v-tippy
                                                                class="button-icon ml-2"
                                                                :class="{ 'opacity-50': !timeslot.id }"
                                                                :content="getTimeslotOverridesButtonTooltip (timeslot)"
                                                                @click="openOverridesModal(timeslot)"
                                                            >
                                                                <app-icon name="calendar-edit" class="h-5 w-5"></app-icon>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr v-for="product in timeslot.products" :key="product.id">
                                                        <td class="w-8">
                                                            <input v-model="product.active" type="checkbox">
                                                        </td>
                                                        <td>{{ product.title }}</td>
                                                        <td class="w-24">
                                                            <input v-model.number="product.max_quantity" class="form-field w-full">
                                                        </td>
                                                        <td v-if="product.max_quantity !== null" class="w-8">
                                                            <button
                                                                v-tippy
                                                                class="button-icon ml-2"
                                                                :class="{ 'opacity-50': !timeslot.id }"
                                                                :content="getTimeslotOverridesButtonTooltip (timeslot)"
                                                                @click="openOverridesModal(timeslot, product)"
                                                            >
                                                                <app-icon name="calendar-edit" class="h-5 w-5"></app-icon>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </form-field-wrapper>
                                    </div>
                                </div>
                                <div class="col-6 items-start">
                                    <div class="w-full">
                                        <form-field-wrapper
                                            label="End Date"
                                            :error="timeslot.errors.get('recurrence_rule.endDate')"
                                            :should-show-error="timeslot.errors.has('recurrence_rule.endDate')"
                                            tooltip="The date of your last available time slot (optional)"
                                            with-tooltip-icon
                                        >
                                            <date-time-picker
                                                v-model="timeslot.recurrence_rule.endDate"
                                                placeholder="Set optional end date..."
                                                :has-time="false"
                                                class="w-full"
                                                clearable
                                                :disabled="!timeslot.updateable"
                                                :min-datetime="timeslot.recurrence_rule.startDate"
                                            ></date-time-picker>
                                        </form-field-wrapper>

                                        <form-field-wrapper
                                            label="End Time"
                                            :error="timeslot.errors.get('recurrence_rule.endTime')"
                                            :should-show-error="timeslot.errors.has('recurrence_rule.endTime')"
                                            tooltip="The time that your last available time slot will conclude"
                                            with-tooltip-icon
                                        >
                                            <time-picker
                                                v-model="timeslot.recurrence_rule.endTime"
                                                placeholder="Set end time..."
                                                :min-time="timeslot.recurrence_rule.startTime"
                                                :disabled="!timeslot.updateable"
                                            ></time-picker>
                                        </form-field-wrapper>

                                        <form-field-wrapper
                                            label="Increment"
                                            :error="timeslot.errors.get('Increment')"
                                            :should-show-error="timeslot.errors.has('Increment')"
                                            tooltip="The duration of each available time slot (optional)"
                                            with-tooltip-icon
                                        >
                                            <simple-picker
                                                :value="timeslot.selectedIncrementId"
                                                :items="increments"
                                                placeholder-empty-state="Select time interval..."
                                                item-label="label"
                                                item-value="id"
                                                :disabled="!timeslot.updateable"
                                                @input="selectIncrement"
                                            ></simple-picker>

                                            <div v-if="showCustomInterval" class="flex items-center mt-2">
                                                <input
                                                    v-model.number="timeslot.recurrence_rule.interval"
                                                    class="form-field mr-2 w-24"
                                                    type="number"
                                                    min="0"
                                                    :disabled="!timeslot.updateable"
                                                >

                                                <div>minutes</div>
                                            </div>
                                        </form-field-wrapper>

                                        <form-field-wrapper
                                            label="When time slot is full"
                                            :error="timeslot.errors.get('hide_when_unavailable')"
                                            :should-show-error="timeslot.errors.has('hide_when_unavailable')"
                                            tooltip="Choose to hide or disable individual time slots when those slots are no longer available"
                                            with-tooltip-icon
                                        >
                                            <select-list v-model="timeslot.hide_when_unavailable">
                                                <select-option
                                                    :id="true"
                                                    label="Hide completely"
                                                    class="my-2"
                                                ></select-option>
                                                <select-option
                                                    :id="false"
                                                    label="Disable"
                                                    class="my-2"
                                                ></select-option>
                                            </select-list>
                                        </form-field-wrapper>

                                        <form-field-wrapper
                                            label="When ticket type sold out"
                                            :error="timeslot.errors.get('show_unavailability_message')"
                                            :should-show-error="timeslot.errors.has('show_unavailability_message')"
                                            tooltip="Choose to hide or disable individual individual ticket types available within a given time slot"
                                            with-tooltip-icon
                                        >
                                            <select-list v-model="timeslot.show_unavailability_message">
                                                <select-option
                                                    :id="false"
                                                    label="Hide completely"
                                                    class="my-2"
                                                ></select-option>
                                                <select-option
                                                    :id="true"
                                                    label="Disable and display a message"
                                                    class="my-2"
                                                ></select-option>
                                            </select-list>

                                            <input
                                                v-if="timeslot.show_unavailability_message"
                                                v-model="timeslot.unavailability_message"
                                                class="form-field mt-2"
                                                type="text"
                                                placeholder="Sold out"
                                            >
                                        </form-field-wrapper>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="w-full">
                                        <div v-if="timeslot.errors.has(`products`)" class="alert alert-error alert-sm mt-4">
                                            <p class="w-full font-normal text-center">{{ timeslot.errors.get(`products`) }}</p>
                                        </div>
                                        <div v-if="timeslot.errors.has(`timeslot`)" class="alert alert-error alert-sm mt-4">
                                            <p class="w-full font-normal text-center">{{ timeslot.errors.get(`timeslot`) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="form"
                                :key="activeTimeslot"
                                class="flex flex-col items-center md:flex-row-reverse md:justify-start shrink-0 px-6 py-2 -mx-4 -mb-4 bg-gray-100 border-t rounded-md"
                            >
                                <div class="md:ml-auto flex flex-col items-center md:flex-row-reverse w-full md:w-auto">
                                    <stateful-button
                                        class="button button-primary mr-2"
                                        :loading="form.processing"
                                        :disabled="!form.isModified"
                                        @click="saveTimeslot"
                                    >Save</stateful-button>

                                    <button class="button mr-2" @click="cancelTimeslot(timeslotIndex)">Cancel</button>
                                </div>

                                <div v-if="timeslot.id" class="flex grow items-center justify-right space-x-6">
                                    <toggle-switch
                                        v-model="timeslot.active"
                                        v-tippy
                                        content="Make time slots unavailable for new registrations"
                                        @click.prevent="timeslot.active = !timeslot.active"
                                    ></toggle-switch>

                                    <button
                                        v-tippy
                                        class="transition duration-150 ease-in-out cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                                        content="Duplicate block of time slots"
                                        @click="duplicateTimeslot(timeslot)"
                                    >
                                        <app-icon
                                            name="duplicate-square"
                                            class="h-4 w-4"
                                            stroke
                                        ></app-icon>
                                    </button>

                                    <button
                                        v-tippy
                                        class="transition duration-150 ease-in-out cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                                        content="Delete block of time slots"
                                        @click="deleteTimeslot(timeslot)"
                                    >
                                        <app-icon
                                            name="trash"
                                            class="h-5 w-5"
                                            stroke
                                        ></app-icon>
                                    </button>
                                </div>
                            </div>
                        </template>
                    </accordion-list-item>
                </accordion-list>
            </div>
        </div>

        <manage-time-slots-overrides-modal
            v-model="showOverridesModal"
            :selected-product="selectedProduct"
            :selected-timeslot="selectedTimeslot"
        ></manage-time-slots-overrides-modal>

        <template #footer="{ close }">
            <button class="button mr-2" @click="close">Exit</button>
        </template>
    </app-modal>
</template>

<script>
import { get } from 'vuex-pathify';
import { find, cloneDeep, orderBy } from 'lodash';
import axios from '@/util/axios';
import Form from '@/validation/Form';

const DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const AVAILABLE_INCREMENTS = [
    { id: 1, label: 'None', value: { frequency: 'none', interval: 1 } },
    { id: 2, label: 'Every 10 minutes', value: { frequency: 'minutes', interval: 10 } },
    { id: 3, label: 'Every 15 minutes', value: { frequency: 'minutes', interval: 15 } },
    { id: 4, label: 'Every 30 minutes', value: { frequency: 'minutes', interval: 30 } },
    { id: 5, label: 'Every 45 minutes', value: { frequency: 'minutes', interval: 45 } },
    { id: 6, label: 'Every hour', value: { frequency: 'hours', interval: 1 } },
    { id: 7, label: 'Every 2 hours', value: { frequency: 'hours', interval: 2 } },
    { id: 8, label: 'Every 3 hours', value: { frequency: 'hours', interval: 3 } },
    { id: 9, label: 'Other', value: { frequency: 'other', interval: 0 } }
];

export default {
    name: 'ManageTimeSlotsModal',

    props: {
        blockable: {
            type: Object,
            required: true
        },

        value: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            activeTimeslot: null,
            days: DAYS,
            form: null,
            increments: AVAILABLE_INCREMENTS,
            loading: false,
            products: [],
            timeslots: [],
            saving: false,
            selectedProduct: null,
            selectedTimeslot: null,
            showOverridesModal: false
        };
    },

    computed: {
        ...get('Event/*'),

        showCustomInterval () {
            return this.form && this.form.recurrence_rule.frequency === 'other';
        },

        timeslotDayStyles () {
            return this.days
                .reduce((accumulator, day, index) => {
                    accumulator[day] = this.isDaySelected(index)
                        ? 'bg-purple text-white'
                        : 'border-2 hover:border-purple';

                    return accumulator;
                }, {});
        }
    },

    watch: {
        value (newValue) {
            if (newValue) {
                this.getTimeslots();
                this.$set(this, 'form', null);
                this.activeTimeslot = null;
            }
        },

        activeTimeslot (timeslotIndex) {
            if (timeslotIndex !== null) {
                this.$set(this, 'form', this.timeslots[timeslotIndex]);
            } else {
                this.$set(this, 'form', null);
            }
        }
    },

    methods: {
        addTimeslot () {
            this.timeslots.push(new Form({
                id: null,
                active: true,
                recurrence_rule: {
                    startDate: null,
                    startTime: null,
                    endDate: null,
                    endTime: null,
                    days: [0, 1, 2, 3, 4, 5, 6],
                    frequency: 'none',
                    interval: 1
                },
                selectedIncrementId: 1,
                max_quantity: null,
                show_unavailability_message: false,
                unavailability_message: 'SOLD OUT',
                hide_when_unavailable: true,
                updateable: true,
                products: this.products.map((product) => {
                    return {
                        product_id: product.id,
                        max_quantity: null,
                        active: true,
                        title: product.title
                    };
                })
            }));
        },

        cancelTimeslot (timeslotIndex) {
            const closeTimeslot = () => {
                this.activeTimeslot = null;

                if (!this.form.id) {
                    this.timeslots.splice(timeslotIndex, 1);
                }
            };

            if (this.form.dirtyFields().length === 0) {
                closeTimeslot();

                return true;
            }

            App.alert().confirm(
                'Are you sure?',
                'Are you sure you want to discard all your changes?',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                },
                () => {
                    closeTimeslot();

                    this.form.restore();
                }
            );

            return false;
        },

        deleteTimeslot (timeslot) {
            App.alert().confirm(
                'Are you sure?',
                'You are permanently deleting this timeslot. It cannot be restored.',
                {
                    confirmButtonText: 'Delete Timeslot',
                    cancelButtonText: 'Cancel'
                },
                () => {
                    axios
                        .delete(this.route('api.events.timeslots.destroy', [this.event, timeslot]))
                        .then(() => {
                            this.$toasted.global.success(`Timeslot successfully deleted.`);
                            this.timeslots.splice(this.activeTimeslot, 1);
                            this.activeTimeslot = null;
                        })
                        .catch(() => {
                            this.$toasted.global.error(`There was an error deleting this timeslot.`);
                        });
                }
            );
        },

        duplicateTimeslot (timeslot) {
            this.timeslots.push(new Form({
                ...timeslot,
                id: null,
                updateable: true
            }));

            this.activeTimeslot = this.timeslots.length - 1;
        },

        getDayPickerStyle (timeslot) {
            return !timeslot.updateable ? 'opacity-50 pointer-events-none' : '';
        },

        getTimeslots () {
            this.loading = true;

            axios.get(this.route('api.events.timeslots.index', this.event))
                .then(({ data: { products, timeslots } }) => {
                    this.$set(this, 'products', products);
                    this.$set(this, 'timeslots', timeslots.map(this.mapTimeslot));
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getTimeslotHumanReadableFrequency (timeslot) {
            const { frequency } = timeslot.recurrence_rule;

            if (frequency === 'none') {
                return '';
            }

            return `every ${timeslot.recurrence_rule.interval} ${frequency} (${this.getFormattedDays(timeslot)})`;
        },

        getFormattedDays (timeslot) {
            if (!timeslot || !timeslot.recurrence_rule.days) {
                return ``;
            }

            return orderBy(timeslot.recurrence_rule.days)
                .map((dayIndex) => {
                    return this.days[dayIndex].substring(0, 1).toUpperCase(); // M, W, F, etc.
                })
                .join(', ');
        },

        getTimeslotOverridesButtonTooltip (timeslot) {
            return !timeslot.id
                ? 'Please save this block of time slots before editing time slot capacity limit overrides.'
                : 'Edit time slot capacity limit overrides';
        },

        mapTimeslot (timeslot) {
            const clonedTimeslot = cloneDeep(timeslot);

            let selectedIncrement = find(this.increments, {
                value: {
                    frequency: timeslot.recurrence_rule.frequency,
                    interval: timeslot.recurrence_rule.interval
                }
            });

            // A predefined increment was not selected
            if (!selectedIncrement) {
                selectedIncrement = find(this.increments, { label: 'Other' });
                clonedTimeslot.recurrence_rule.frequency = 'other';
            }

            return new Form({
                ...clonedTimeslot,
                selectedIncrementId: selectedIncrement.id,
                products: this.products.map((product) => {
                    const timeslotProduct = find(timeslot.products, { id: product.id });

                    return {
                        product_id: product.id,
                        max_quantity: timeslotProduct ? timeslotProduct.pivot.max_quantity : null,
                        active: !!timeslotProduct,
                        title: product.title
                    };
                })
            });
        },

        isDaySelected (day) {
            if (!this.form) {
                return false;
            }

            return this.form.recurrence_rule.days.indexOf(day) !== -1;
        },

        saveTimeslot () {
            const additionalData = { blockable_id: this.blockable.id };

            const saveRequest = this.form.id
                ? this.form.put(this.route('api.events.timeslots.update', [this.event, this.form]), null, additionalData)
                : this.form.post(this.route('api.events.timeslots.store', this.event), null, additionalData);

            saveRequest.then(({ data }) => {
                this.$toasted.global.success(`Timeslot successfully saved.`);
                this.timeslots.splice(this.activeTimeslot, 1, this.mapTimeslot(data));
                this.activeTimeslot = null;
            });
        },

        selectIncrement (selectedIncrementId) {
            if (!this.form) {
                return;
            }

            this.$set(this.form, 'selectedIncrementId', selectedIncrementId);

            const selectedIncrement = find(this.increments, { id: selectedIncrementId });

            this.$set(this.form.recurrence_rule, 'frequency', selectedIncrement.value.frequency);
            this.$set(this.form.recurrence_rule, 'interval', selectedIncrement.value.interval);
        },

        toggleDay (day) {
            const selectedIndex = this.form.recurrence_rule.days.indexOf(day);

            if (selectedIndex === -1) {
                this.form.recurrence_rule.days.push(day);
            } else {
                this.form.recurrence_rule.days.splice(selectedIndex, 1);
            }
        },

        openOverridesModal (timeslot, product = null) {
            if (!timeslot.id) {
                return;
            }

            this.selectedTimeslot = timeslot;
            this.selectedProduct = product;
            this.showOverridesModal = true;
        }
    }
};
</script>
