/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'users-check-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>multiple tick yes ok people group party</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6 .75a3 3 0 100 6 3 3 0 100-6zM10.55 10.888h0c-1.45-2.52-4.66-3.39-7.17-1.94a5.222 5.222 0 00-2.64 4.55v2.25h2.25l.75 7.5h4.5L8.56 20M16.5.75a2.625 2.625 0 100 5.25 2.625 2.625 0 100-5.25zM20.4 9h0c-1.25-2.16-4-2.89-6.15-1.65-.43.24-.82.56-1.14.93M17.25 11.25a6 6 0 100 12 6 6 0 100-12z"/><path pid="1" d="M19.924 15.505l-2.91 3.87h-.01a.76.76 0 01-1.06.14c-.03-.03-.06-.05-.08-.07l-1.5-1.5"/></g>'
  }
})
