<template>
    <div
        v-if="isInternetExplorer"
        class="alert alert-warning my-0 fixed bottom-0 left-0 right-0 z-9999"
        role="alert"
    >
        We’ve detected that you may be using an outdated, unsupported web browser. Please upgrade to a modern web browser for the best RSVPify experience.
    </div>
</template>

<script>
export default {
    data () {
        return {
            isInternetExplorer: !!window.document.documentMode
        };
    },

    mounted () {
        if (this.isInternetExplorer) {
            document.querySelector('body').classList.add('pb-20');
        }
    }
};
</script>
