<template>
    <svg-icon
        v-cloak
        :name="icon"
        v-bind="attributes"
    ></svg-icon>
</template>

<script>
import './icons';

export default {
    props: {
        name: {
            type: String,
            required: true
        },
        options: {
            type: Object,
            default: () => { return {}; }
        },
        stroke: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            attributes: {}
        };
    },

    computed: {
        icon () {
            if (this.isStroke) {
                if (this.name.includes('-stroke')) {
                    return this.name;
                }

                return `${this.name}-stroke`;
            }

            return this.name;
        },

        isStroke () {
            if (this.name.includes('-stroke')) {
                return true;
            }

            return this.stroke;
        }
    },

    watch: {
        name (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.setAttributes();
            }
        }
    },

    mounted () {
        this.setAttributes();
    },

    methods: {
        setAttributes () {
            this.attributes = {
                fill: !this.isStroke,
                ...this.options
            };
        }
    }
};
</script>
