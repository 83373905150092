<template>
    <div
        class="relative flex"
        @mouseenter="toggle"
        @mouseleave="toggle"
    >
        <a
            href="#"
            class="no-underline text-white flex py-3 px-6 items-center space-x-3"
        >
            <app-icon
                name="calendar"
                class="h-5 w-5"
                stroke
            ></app-icon>
            <span>My Events</span>
            <app-icon
                name="arrow-down-chevron"
                class="h-4 w-4 ml-6 text-gray-500"
                stroke
            ></app-icon>
        </a>
        <base-nav v-if="show" :links="links"></base-nav>
    </div>
</template>

<script>
export default {
    props: {
        events: {
            type: Array,
            default: () => { return ([]); }
        }
    },

    data () {
        return {
            show: false
        };
    },

    computed: {
        links () {
            return [
                {
                    title: 'Create a New Event',
                    icon: 'add-circle-stroke',
                    url: this.route('events.create')
                },
                ...this.eventLinks
            ];
        },

        eventLinks () {
            const links = this.events.map((event) => {
                return {
                    title: event.name,
                    icon: null,
                    url: this.route('events.dashboard.overview', event)
                };
            }).slice(0, 5);

            if (this.events.length > 5) {
                links.push({
                    title: 'View All Events',
                    icon: null,
                    url: this.route('dashboard')
                });
            }

            return links;
        }
    },

    methods: {
        toggle () {
            this.show = !this.show;
        }
    }
};
</script>
