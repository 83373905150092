/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-add-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>event date time plus create new appointment</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.25 17.25H2.24a1.51 1.51 0 01-1.5-1.5V3.749h0c-.01-.83.67-1.51 1.49-1.51h13.5-.01c.82-.01 1.5.67 1.5 1.5v4.5"/><g stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="1" d="M.75 6.75h16.5"/><g stroke-linecap="round"><path pid="2" d="M5.25 3.75v-3M12.75 3.75v-3M17.25 11.25a6 6 0 100 12 6 6 0 100-12zM17.25 14.25v6M14.25 17.25h6"/></g></g>'
  }
})
