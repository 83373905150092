/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-group': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="currentColor"><path pid="0" d="M5 10.25h14a1 1 0 001-1V7.181a.49.49 0 00-.37-.477 1.5 1.5 0 010-2.908.491.491 0 00.37-.477V1.25a1 1 0 00-1-1H5a1 1 0 00-1 1v2.069a.491.491 0 00.37.477 1.5 1.5 0 010 2.908.49.49 0 00-.37.477V9.25a1 1 0 001 1zm3.25-9A.75.75 0 117.5 2a.75.75 0 01.75-.75zm0 3A.75.75 0 117.5 5a.75.75 0 01.75-.75zm0 3A.75.75 0 117.5 8a.75.75 0 01.75-.75zM5.576 20.142a.248.248 0 01-.059-.419 2.488 2.488 0 00.868-2.74A2.456 2.456 0 004.7 15.347a2.506 2.506 0 00-3.2 2.4 2.475 2.475 0 00.981 1.971.248.248 0 01-.056.42A3.486 3.486 0 00.5 23.233a.507.507 0 00.5.517h6a.507.507 0 00.5-.517 3.485 3.485 0 00-1.924-3.091zM13.576 20.142a.248.248 0 01-.059-.419 2.488 2.488 0 00.868-2.74 2.456 2.456 0 00-1.685-1.636 2.506 2.506 0 00-3.2 2.4 2.477 2.477 0 00.98 1.971.247.247 0 01-.055.42A3.486 3.486 0 008.5 23.233a.507.507 0 00.5.517h6a.507.507 0 00.5-.517 3.485 3.485 0 00-1.924-3.091zM21.576 20.142a.248.248 0 01-.059-.419 2.488 2.488 0 00.868-2.74 2.456 2.456 0 00-1.685-1.636 2.506 2.506 0 00-3.2 2.4 2.477 2.477 0 00.98 1.971.247.247 0 01-.055.42 3.486 3.486 0 00-1.925 3.095.507.507 0 00.5.517h6a.507.507 0 00.5-.517 3.485 3.485 0 00-1.924-3.091z"/></g>'
  }
})
