export default {
    methods: {
        readableCardBrand (brand) {
            switch (brand) {
                case 'amex': return 'American Express';
                case 'diners': return 'Diners';
                case 'discover': return 'Discover';
                case 'jcb': return 'JCB';
                case 'mastercard': return 'Mastercard';
                case 'unionpay': return 'UnionPay';

                case 'visa':
                case 'Visa':
                    return 'Visa';

                default: return '';
            }
        }
    }
};
