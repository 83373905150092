/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-graph-document-infected-report-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.75 14.25a3.13 3.13 0 100 6.27 3.13 3.13 0 100-6.28zM17.23 11.9h1.04M17.75 11.9v2.36M21.26 13.14l.74.74M21.63 13.51l-1.66 1.67M23.24 16.87v1.05M23.24 17.4h-2.35M22 20.91l-.74.74M21.63 21.28l-1.66-1.67M18.27 22.89h-1.04M17.75 22.89v-2.36M14.24 21.65l-.74-.74M13.87 21.28l1.66-1.67M12.26 17.92v-1.05M12.26 17.4h2.35M13.5 13.88l.74-.74M13.87 13.51l1.66 1.67M3.76 7.11v9h4.5"/><path pid="1" d="M3.76 13.115l2.689-2.689v-.01a1.5 1.5 0 012.12-.01s0 0 0 0l.58.58h0c.58.58 1.53.58 2.12 0 .08-.09.16-.19.22-.29l.35-.6"/><path pid="2" d="M9.01 20.61H2.25a1.51 1.51 0 01-1.5-1.5s0 0 0 0V2.61h0c-.01-.83.67-1.51 1.49-1.51h10.62l-.01-.001c.39 0 .77.15 1.06.43l2.872 2.87h0c.28.28.43.66.43 1.06v2.37"/></g>'
  }
})
