export default {
    props: {
        question: {
            type: Object,
            required: true
        },

        value: {
            type: [Object, Array, String, Number, Boolean],
            default: null
        },

        answerableId: {
            type: Number,
            default: null
        }
    },

    computed: {
        /**
         * This computed property is meant to be overriden is type-specific
         * Vue components. It should return a sensible default answer value
         * depending on the question type.
         */
        defaultValue () {
            return '';
        },

        /**
         * This computed property should be used in type-specific components to
         * read or modify the current answer value. It automatically returns
         * the default value if the actual value is blank and forwards any
         * modifications to the parent component.
         */
        internalValue: {
            get () {
                return this.value || this.defaultValue;
            },

            set (newValue) {
                this.$emit('input', newValue);
            }
        },

        /**
         * Configuration can sometimes be specific to a question type. For
         * items like that, we'd want them to live in a separate object inside
         * of the question's settings. Override this method to specify the
         * namespace where the type-specific settings will live.
         */
        namespace () {
            return null;
        },

        /**
         * A shorthand for the namespaces settings.
         */
        settings () {
            return this.question.settings[this.namespace] || {};
        }
    },

    methods: {
        /**
         * This method can be overridden in components that use this mixin It's
         * executed whenever the custom question component becomes active.
         */
        focus () {
            document.activeElement.blur();
        },

        setProperty (property, newVal) {
            this.internalValue = { ...this.internalValue, [property]: newVal };
        }
    }
};
