<template>
    <a role="button" @click="unshareItem">
        <slot></slot>
    </a>
</template>

<script>
import axios from '@/util/axios';

export default {
    props: {
        endpoint: {
            type: String,
            default: ''
        },
        postData: {
            type: Object,
            default: () => { return {}; }
        },
        confirmationMessage: {
            type: Object,
            default: () => {
                return {
                    title: 'Are you sure?',
                    text: 'You will no longer be able to view or manage this event.'
                };
            }
        }
    },

    methods: {
        unshareItem () {
            const attributes = {
                confirmButtonText: 'Unshare Event',
                cancelButtonText: 'Cancel',
                type: 'question'
            };

            const onConfirm = () => {
                if (this.endpoint === '') {
                    return this.$emit('unshare-item');
                }

                this.handleUnshare();
            };

            App.alert().confirm(
                this.confirmationMessage.title,
                this.confirmationMessage.text,
                attributes,
                onConfirm
            );
        },

        handleUnshare () {
            axios.post(this.endpoint, this.postData)
                .then((response) => {
                    this.$emit('unshare-item', response);
                }).catch((error) => {
                    this.$emit('error', error);
                });
        }
    }
};
</script>
