/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dessert-cake-birthday-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M1.47 23.25h21"/><rect pid="1" width="18" height="10.5" x="2.973" y="9.75" rx="1.5"/><path pid="2" d="M11.97 7.5v2.25M13.473 3v0c0 .82-.68 1.5-1.5 1.5-.83 0-1.5-.68-1.5-1.5 0-.83 1.5-2.25 1.5-2.25s1.5 1.42 1.5 2.25zM17.97 7.5v2.25M19.473 3v0c0 .82-.68 1.5-1.5 1.5-.83 0-1.5-.68-1.5-1.5 0-.83 1.5-2.25 1.5-2.25s1.5 1.42 1.5 2.25zM5.97 7.5v2.25M7.47 3v0c0 .82-.68 1.5-1.5 1.5-.83 0-1.5-.68-1.5-1.5 0-.83 1.5-2.25 1.5-2.25S7.47 2.17 7.47 3zM2.97 13.125v-.01a2.614 2.614 0 002.61 2.62c.7 0 1.38-.29 1.88-.8h0c1 1.03 2.65 1.05 3.69.05.16-.17.31-.36.43-.56l-.01-.01a2.597 2.597 0 004.49-.01h0a2.62 2.62 0 003.58.93c.8-.48 1.29-1.34 1.29-2.27"/></g>'
  }
})
