<template>
    <section class="panel">
        <page-header icon="alert-triangle-stroke" label="Global Site Alerts">
            <a
                class="button-icon button-sm"
                :href="route('admin.dashboard')"
            >
                <app-icon name="close"></app-icon>
            </a>
        </page-header>

        <div v-if="displayForm" class="flex-col">
            <form-field-wrapper v-if="form.message" label="Preview">
                <div
                    role="alert"
                    class="alert alert-global w-full"
                    :class="styles.preview"
                >
                    <p>{{ form.message }}</p>
                    <a
                        v-if="hasLink"
                        class="alert-button"
                        :href="form.link"
                        target="_blank"
                    >
                        {{ form.link_text }}
                    </a>
                </div>
            </form-field-wrapper>

            <div class="p-4 w-full">
                <form-field-wrapper label="Type" :error="form.errors.get('type')">
                    <simple-picker
                        v-model="form.type"
                        :items="alertTypes"
                        class="w-full"
                        item-label="label"
                        item-value="value"
                    ></simple-picker>
                </form-field-wrapper>

                <form-field-wrapper label="Message" :error="form.errors.get('message')">
                    <textarea
                        v-model="form.message"
                        class="form-field"
                        rows="2"
                        placeholder="Message text."
                    ></textarea>
                </form-field-wrapper>

                <form-field-wrapper label="Link Text" :error="form.errors.get('link_text')">
                    <input
                        v-model="form.link_text"
                        class="form-field"
                        placeholder="Link text."
                    >
                </form-field-wrapper>

                <form-field-wrapper label="Link URL" :error="form.errors.get('link')">
                    <input
                        v-model="form.link"
                        class="form-field"
                        placeholder="https://rsvpify.com"
                    >
                </form-field-wrapper>
            </div>

            <div class="flex justify-end pr-4">
                <stateful-button
                    v-if="isEdit"
                    :loading="deleting"
                    role="button"
                    class="button button-danger-soft m-4"
                    @click="deleteAlert"
                >
                    Delete
                </stateful-button>

                <stateful-button
                    class="button-primary my-4"
                    :loading="form.processing"
                    role="button"
                    @click="createOrUpdate"
                >
                    {{ createOrUpdateText }}
                </stateful-button>
            </div>
        </div>

        <div v-else class="flex flex-col items-center justify-center">
            <button
                type="button"
                class="button button-primary my-8"
                @click="showForm"
            >
                <app-icon name="add-circle" stroke></app-icon>
                Add a Site Alert
            </button>

            <img
                class="w-128"
                :src="asset('images/empty-states/site-alerts.svg')"
                alt="Site alerts empty state"
            >
        </div>
    </section>
</template>

<script>
import axios from '@/util/axios';
import Form from '@/validation/Form';

export default {
    props: {
        initialSiteAlert: {
            type: Object,
            default: () => { return {}; }
        }
    },

    data () {
        return {
            form: new Form({
                type: this.initialSiteAlert.type,
                message: this.initialSiteAlert.message,
                link: this.initialSiteAlert.link,
                link_text: this.initialSiteAlert.link_text
            }),
            alertTypes: [
                { label: 'Info', value: 'info' },
                { label: 'Warning', value: 'warning' },
                { label: 'Error', value: 'error' }
            ],
            deleting: false,
            displayForm: !!this.initialSiteAlert.id
        };
    },

    computed: {
        styles () {
            return {
                preview: `alert-${this.form.type}`
            };
        },

        createOrUpdateText () {
            return this.isEdit ? 'Update' : 'Create';
        },

        hasLink () {
            return this.form.link && this.form.link_text;
        },

        isEdit () {
            return !!this.initialSiteAlert.id;
        }
    },

    methods: {
        create () {
            this.form.post(this.route('api.admin.site-alerts.store'))
                .then(() => {
                    this.$toasted.global.success('Site alert created.');

                    // We want to reload so the page will really show the non-JS site alert at the top
                    window.location.reload();
                });
        },

        createOrUpdate () {
            if (this.isEdit) {
                this.update();
            } else {
                this.create();
            }
        },

        deleteAlert () {
            this.deleting = true;

            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            };

            const onDelete = () => {
                axios.delete(this.route('api.admin.site-alerts.destroy', this.initialSiteAlert))
                    .then(() => {
                        this.$toasted.global.success('Site alert deleted.');

                        // We want to reload so the page will really show the removal of the non-JS site alert at the top
                        window.location.reload();
                    }).catch(({ response }) => {
                        this.$toasted.global.error(response.data.message);
                    }).finally(() => {
                        this.deleting = false;
                    });
            };

            const onCancel = () => {
                this.deleting = false;
            };

            App.alert().confirm(
                'Are you sure?',
                'Are you sure you want to delete this site alert?',
                attributes,
                onDelete,
                onCancel
            );
        },

        showForm () {
            this.displayForm = true;
        },

        update () {
            this.form.patch(this.route('api.admin.site-alerts.update', this.initialSiteAlert))
                .then(() => {
                    this.$toasted.global.success('Site alert updated.');

                    // We want to reload so the page will really show the non-JS site alert at the top
                    window.location.reload();
                });
        }
    }
};
</script>
