<template>
    <div class="flex items-center justify-between w-full mb-8">
        <div class="flex items-center space-x-4">
            <slot name="icon">
                <app-icon
                    v-if="hasIcon"
                    :name="icon"
                    class="h-8 w-8 text-gray-600"
                    :stroke="iconStroke"
                ></app-icon>
            </slot>
            <div class="text-lg font-semibold text-gray-700">
                <slot name="label">
                    {{ label }}
                </slot>
            </div>
        </div>

        <slot v-if="hasSlot('default')"></slot>
    </div>
</template>

<script>
import SlotHelpers from '@/mixins/SlotHelpers';

export default {
    name: 'PageHeader',

    mixins: [SlotHelpers],

    props: {
        icon: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        iconStroke: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        hasIcon () {
            return this.icon !== '';
        }
    }
};
</script>
