/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-sanitizer-liquid-virus-block-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.4 20.1l-.01-.01a3.145 3.145 0 010-4.45 3.132 3.132 0 014.44 0M20.76 17.875v0c0 1.73-1.41 3.14-3.15 3.14 0 0 0 0 0 0M17.1 12.38h1.05M17.62 12.38v2.35M23.12 17.35v1.05M23.12 17.88h-2.35M21.88 21.39l-.74.74M21.51 21.76l-1.66-1.66M18.15 23.38H17.1M17.62 23.38v-2.36M12.12 18.4v-1.05M12.12 17.88h2.36M13.37 14.36l.74-.74M13.74 13.99l1.66 1.66M23.12 12.38l-11 11M9.93 19.622h-.01c-3.3 1.36-7.08-.2-8.45-3.49-.33-.79-.5-1.63-.5-2.48C.97 8.8 7.42.73 7.42.73h0c2.28 2.89 4.19 6.06 5.68 9.43"/><path pid="1" d="M7.454 17.13l-.01-.001c-1.92-.01-3.48-1.56-3.48-3.48v-.001"/></g>'
  }
})
