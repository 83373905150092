<template>
    <app-modal
        :value="value"
        header-classes="items-center"
        @input="$emit('input', $event)"
    >
        <template #header>
            <div class="flex items-center">
                <div class="shrink pr-4">
                    <img
                        class="w-10"
                        :src="asset('/images/account/rsvpify-premium.svg')"
                        alt="RSVPify Premium"
                    >
                </div>
                <div class="text-lg text-teal font-bold grow">
                    Your current plan doesn't include these features...
                </div>
            </div>
        </template>

        <template>
            <used-feature-short-message
                v-for="usage in usedFeaturesRequiringUpgrade"
                :key="usage.feature"
                :used-feature="usage"
            ></used-feature-short-message>
        </template>

        <!-- Required in order for footer-split-options to render -->
        <template #footer>&nbsp;</template>

        <template #footer-split-options="{ close }">
            <div class="w-full">
                <div class="flex items-center mb-2 text-yellow-600">
                    <app-icon name="info-circle" class="w-5 h-5 mr-2"></app-icon>
                    <span>Please remove these features or upgrade your plan.</span>
                </div>

                <div class="flex mt-4">
                    <button class="button mr-4" @click="close">Go Back</button>

                    <a
                        class="button button-info"
                        :href="route('account.upgrade.index')"
                        target="_blank"
                    >Upgrade Options</a>
                </div>
            </div>
        </template>
    </app-modal>
</template>

<script>
import filter from 'lodash/filter';

export default {
    name: 'RequiresUpgradeModal',

    props: {
        usedFeatures: {
            type: Array,
            required: true
        },

        value: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        usedFeaturesRequiringUpgrade () {
            return filter(this.usedFeatures, { requires_upgrade: 1 });
        }
    }
};
</script>
