<template>
    <div>
        <div class="flex rounded-md overflow-hidden" :class="barClasses">
            <div
                v-for="(bar, index) in bars"
                :key="index"
                class="text-center text-white text-xs"
                :class="getBarClasses(bar)"
                :style="getBarStyle(bar)"
            >
                <template v-if="shouldShowPercentage(bar)">
                    {{ getBarPercentage(bar) }}%
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { forEach, has } from 'lodash';

export default {
    props: {
        bars: {
            type: Array,
            required: true,
            validator (bars) {
                let isValid = true;

                forEach(bars, (bar) => {
                    if (!has(bar, 'color')) {
                        isValid = false;
                    }

                    // The bar percentage, or current and total must be provided.
                    if (!has(bar, 'percentage') && (!has(bar, 'current') && !has(bar, 'total'))) {
                        isValid = false;
                    }
                });

                return isValid;
            }
        },

        barClasses: {
            type: [String, Object, Array],
            default: 'bg-gray-400 h-2'
        },

        showPercentage: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        displayHeight () {
            return `h-${this.height}`;
        }
    },

    methods: {
        getBarPercentage (bar) {
            if (has(bar, 'percentage')) {
                return bar.percentage;
            }

            return parseInt(100 * (bar.current / bar.total), 10);
        },

        getBarClasses (bar) {
            const additionalStyles = bar.styles || {};

            return {
                [bar.color]: true,
                ...additionalStyles
            };
        },

        getBarStyle (bar) {
            return {
                width: `${this.getBarWidth(bar)}%`
            };
        },

        getBarWidth (bar) {
            if (this.bars.length === 1 && this.getBarPercentage(bar) <= 2) {
                return 2;
            }

            return this.getBarPercentage(bar);
        },

        shouldShowPercentage (bar) {
            return bar.showPercentage && this.getBarPercentage(bar) > 10;
        }
    }
};
</script>
