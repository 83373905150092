/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right-chevron': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="currentColor" d="M7.2 23.75l12.471-10.99h-.01c.42-.41.43-1.09.03-1.51l-.04-.04L7.17.23 7.16.22a.985.985 0 00-1.4.08c-.01 0-.02.01-.03.02L4.17 2.17h-.01c-.37.42-.32 1.06.11 1.43l9.45 8.16-.01-.01c.1.09.11.24.02.35-.01 0-.02.01-.03.02l-9.45 8.168h-.01c-.21.17-.34.42-.35.7v-.01c-.03.26.06.53.23.74l1.55 1.85-.01-.01c.35.41.97.47 1.39.12 0-.01.01-.02.02-.02z"/>'
  }
})
