<template>
    <div v-if="!isDataLoaded" class="text-center">
        <app-icon
            class="h-12 w-12 text-gray-500 fill-current"
            name="loader"
        ></app-icon>
    </div>

    <div v-else :key="loadedDataCount">
        <party-details-guest-stats
            :attending-count="attendingCount"
            :not-attending-count="notAttendingCount"
            :order-total="orderTotal"
            :donation-total="data.order.donation"
            :donation-card-visible="isDonationCardVisible"
            :total-sales-card-visible="isTotalSalesCardVisible"
        >

        </party-details-guest-stats>

        <div
            v-if="manualRsvpNote"
            class="alert alert-sm text-left items-start"
            :class="styles.manualRsvpClass"
        >
            <app-icon
                class="mr-3 mt-2px shrink-0 h-5 w-5"
                stroke
                name="info-circle"
            ></app-icon>
            {{ manualRsvpNote }}
        </div>

        <div class="flex justify-between items-center text-sm text-gray-500 mb-4">
            <div class="edit-actions">
                <button
                    v-if="userCanEditReplies"
                    class="button button-sm button-soft"
                    @click="toggleEditMode"
                >
                    <app-icon name="edit"></app-icon>
                    Edit
                </button>
            </div>
            <button
                class="button-text"
                @click="toggleAllItems"
            >
                {{ accordionCollapseText }}
            </button>
        </div>

        <accordion-list
            v-cloak
            v-model="activeIds"
            class="text-left rounded-md"
        >
            <accordion-list-item
                v-for="guest in guests"
                :key="guest.id"
                :item-id="guest.id"
                class="mb-2 border rounded-md p-4"
            >
                <template #header="{ active }">
                    <div>
                        <div class="flex items-center">
                            <div>
                                <div class="text-semibold">{{ guest.formalName }}</div>
                                <div v-if="hasDifferentInviteeName(guest)" class="text-sm font-normal text-gray-500">
                                    Invited as {{ guest.invitee.formalName }}
                                </div>
                                <div v-if="guest.parentInvitee" class="text-sm font-normal text-gray-500">
                                    Guest of {{ guest.parentInvitee.formalName }}
                                </div>
                            </div>

                            <button
                                v-if="canDeleteGuests"
                                class="button-icon hover:text-red ml-2"
                                @click.stop.prevent="deleteGuest(guest)"
                            >
                                <app-icon
                                    name="trash"
                                    class="h-5 w-5"
                                    stroke
                                ></app-icon>
                            </button>
                        </div>

                        <div class="mt-2">
                            <guest-primary-product-selector
                                v-tippy
                                :event="event"
                                :guest="guest"
                                :products="individualProducts"
                                :is-editing="isProductReplyEditable(guest) && isEditing"
                                :content="getProductReplyTooltip(guest)"
                                @selected-product="selectProduct(guest, $event)"
                            >
                            </guest-primary-product-selector>

                            <party-details-form-field-error :name="`guestProducts.${guest.id}`" :form-errors="formErrors"></party-details-form-field-error>
                        </div>

                        <tag-list
                            v-if="!active"
                            class="mt-2"
                            :group-size="initialGuests.length"
                            :value="getGuestDetail(guest, 'tags')"
                            @input="newTags => tagSingleGuest(guest, newTags)"
                            @tag-group="tagEntireGroup"
                        ></tag-list>
                    </div>
                </template>

                <template slot="content">
                    <template v-if="isEditing">
                        <div class="p-4 border-t border-b last:border-b-0 mt-4">
                            <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">Name</div>

                            <div class="flex items-center">
                                <div class="w-1/2 px-4">
                                    <input
                                        :value="getGuestDetail(guest, 'firstName')"
                                        class="form-field"
                                        placeholder="First Name"
                                        @input="setGuestDetail(guest, 'firstName', $event.target.value)"
                                    >

                                    <party-details-form-field-error
                                        :name="`guestDetails.${guest.id}.firstName`"
                                        :form-errors="formErrors"
                                    ></party-details-form-field-error>
                                </div>
                                <div class="w-1/2 px-4">
                                    <input
                                        :value="getGuestDetail(guest, 'lastName')"
                                        class="form-field"
                                        placeholder="Last Name"
                                        @input="setGuestDetail(guest, 'lastName', $event.target.value)"
                                    >

                                    <party-details-form-field-error
                                        :name="`guestDetails.${guest.id}.lastName`"
                                        :form-errors="formErrors"
                                    ></party-details-form-field-error>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="p-4 border-t border-b last:border-b-0 mt-4">
                        <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">Email</div>

                        <div class="flex items-center">
                            <template v-if="isEditing">
                                <input
                                    :value="getGuestDetail(guest, 'email')"
                                    class="form-field"
                                    placeholder="Email"
                                    @input="setGuestDetail(guest, 'email', $event.target.value)"
                                >
                            </template>
                            <template v-else>
                                <template v-if="guest.email">
                                    {{ guest.email }}

                                    <copy-to-clipboard :copy="guest.email" @copied="displaySuccessfulCopyToast">
                                        <a role="button" class="button-icon button-sm flex hover:text-purple ml-2">
                                            <app-icon name="file-copy"></app-icon>
                                        </a>
                                    </copy-to-clipboard>
                                </template>
                                <template v-else>Email not given.</template>
                            </template>
                        </div>

                        <party-details-form-field-error
                            :name="`guestDetails.${guest.id}.email`"
                            :form-errors="formErrors"
                        ></party-details-form-field-error>
                    </div>

                    <div
                        v-for="product in guest.products"
                        :key="product.id"
                        class="p-4 border-b last:border-b-0"
                    >
                        <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">{{ product.title }}</div>

                        <div class="flex items-center">
                            <app-icon
                                v-if="product.settings.icon"
                                :name="product.settings.icon"
                                class="mr-2 h-6 w-6"
                            ></app-icon>
                            1
                        </div>
                    </div>

                    <div
                        v-for="(products, blockableId) in secondaryEventsByBlockable"
                        :key="blockableId"
                        class="w-full mb-4 border-b"
                    >
                        <div class="p-4">
                            <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">{{ products[0].blockable.settings.title }}</div>

                            <guest-secondary-product-selector
                                :event="event"
                                :guest="guest"
                                :products="products"
                                :is-editing="isEditing"
                                :blockable-id="parseInt(blockableId, 10)"
                                @selected-product="selectSecondaryEventProduct(guest, $event, parseInt(blockableId, 10))"
                            >
                            </guest-secondary-product-selector>

                            <party-details-form-field-error :name="`guestSecondaryEventSelections.${guest.id}`" :form-errors="formErrors"></party-details-form-field-error>
                        </div>
                    </div>

                    <div
                        v-for="(meals, blockableId) in eventMealsByBlockable"
                        :key="blockableId"
                        class="w-full mb-4 border-b"
                    >
                        <div class="p-4">
                            <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">{{ meals[0].blockable.settings.title }}</div>

                            <guest-meal-selector
                                :event="event"
                                :guest="guest"
                                :all-event-meals="eventMeals"
                                :blockable-id="parseInt(blockableId, 10)"
                                :is-editing="isProductReplyEditable(guest) && isEditing"
                                @selected-meal="selectMeal(guest, $event, parseInt(blockableId, 10))"
                            ></guest-meal-selector>

                            <party-details-form-field-error :name="`guestMeals.${guest.id}`" :form-errors="formErrors"></party-details-form-field-error>
                        </div>
                    </div>

                    <div class="w-full mb-4">
                        <div
                            v-for="question in perAttendeeEventQuestions"
                            :key="question.id"
                            class="p-4 border-b last:border-b-0"
                        >
                            <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">{{ question.title }}</div>

                            <div class="flex items-center">
                                <custom-question-answer
                                    :is-editing="isEditing"
                                    :question="getGuestQuestionAnswer(guest, question)"
                                    @input="modifyCustomQuestion(guest, question, $event)"
                                ></custom-question-answer>
                            </div>

                            <party-details-form-field-error
                                :name="`guestAnswers.${guest.id}.${question.id}`"
                                :form-errors="formErrors"
                            ></party-details-form-field-error>
                        </div>
                    </div>

                    <div class="w-full mb-4">
                        <div
                            v-for="customData in getGuestCustomDataFields(guest)"
                            :key="`custom-data-value-${guest.id}-${customData.id}`"
                            class="p-4 border-b last:border-b-0"
                        >
                            <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">{{ customData.field }}</div>

                            <div class="flex items-center">
                                <template v-if="isEditing">
                                    <input
                                        :value="getGuestCustomDataValue(guest, customData.id)"
                                        class="form-field"
                                        @input="setGuestCustomData(guest, customData.id, $event.target.value)"
                                    >
                                </template>
                                <template v-else>
                                    {{ customData.value || 'No Value' }}
                                </template>

                                <party-details-form-field-error
                                    :name="`guestCustomData.${guest.id}.${customData.id}`"
                                    :form-errors="formErrors"
                                ></party-details-form-field-error>
                            </div>
                        </div>
                    </div>

                    <div class="w-full">
                        <tag-list
                            :group-size="initialGuests.length"
                            :value="getGuestDetail(guest, 'tags')"
                            @input="newTags => tagSingleGuest(guest, newTags)"
                            @tag-group="tagEntireGroup"
                        ></tag-list>
                    </div>
                </template>
            </accordion-list-item>

            <accordion-list-item
                v-for="(guest, index) in editPayload.newGuests"
                :key="guest.id"
                :item-id="guest.id"
                class="mb-2 border rounded-md p-4"
            >
                <template #header="{ active }">
                    <div>
                        <div class="flex items-center">
                            <div>
                                <div class="text-semibold">New Guest</div>
                            </div>

                            <button
                                v-if="canDeleteGuests"
                                class="button-icon hover:text-red ml-2"
                                @click.stop.prevent="editPayload.newGuests.splice(index, 1)"
                            >
                                <app-icon
                                    name="trash"
                                    class="h-5 w-5"
                                    stroke
                                ></app-icon>
                            </button>
                        </div>
                    </div>
                </template>

                <template slot="content">
                    <div class="p-4 border-t border-b last:border-b-0 mt-4">
                        <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">Name</div>

                        <div class="flex items-center">
                            <div class="w-1/2 px-4">
                                <input
                                    v-model="guest.firstName"
                                    class="form-field"
                                    placeholder="First Name"
                                >

                                <party-details-form-field-error
                                    :name="`newGuests.${index}.firstName`"
                                    :form-errors="formErrors"
                                ></party-details-form-field-error>
                            </div>
                            <div class="w-1/2 px-4">
                                <input
                                    v-model="guest.lastName"
                                    class="form-field"
                                    placeholder="Last Name"
                                >

                                <party-details-form-field-error
                                    :name="`newGuests.${index}.lastName`"
                                    :form-errors="formErrors"
                                ></party-details-form-field-error>
                            </div>
                        </div>
                    </div>

                    <div class="p-4 border-b last:border-b-0 mt-4">
                        <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">Email</div>

                        <div class="flex items-center">
                            <input
                                v-model="guest.email"
                                class="form-field"
                                placeholder="Email"
                            >
                        </div>

                        <party-details-form-field-error
                            :name="`newGuests.${index}.email`"
                            :form-errors="formErrors"
                        ></party-details-form-field-error>
                    </div>

                    <div class="p-4 border-b last:border-b-0 mt-4">
                        <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">Reply</div>

                        <div class="flex items-center">
                            <guest-primary-product-selector
                                :event="event"
                                :guest="guest"
                                :products="individualProducts"
                                :is-editing="isEditing"
                                @selected-product="selectProduct(guest, $event)"
                            >
                            </guest-primary-product-selector>
                        </div>

                        <party-details-form-field-error
                            :name="`newGuests.${index}.product`"
                            :form-errors="formErrors"
                        ></party-details-form-field-error>
                    </div>

                    <div class="p-4">
                        <tag-list
                            v-model="guest.tags"
                            class="mt-2"
                        ></tag-list>
                    </div>

                    <div
                        v-for="(products, blockableId) in secondaryEventsByBlockable"
                        :key="blockableId"
                        class="w-full mb-4 border-b"
                    >
                        <div class="p-4">
                            <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">{{ products[0].blockable.settings.title }}</div>

                            <guest-secondary-product-selector
                                :event="event"
                                :guest="guest"
                                :products="products"
                                :is-editing="isEditing"
                                :blockable-id="parseInt(blockableId, 10)"
                                @selected-product="selectSecondaryEventProduct(guest, $event, parseInt(blockableId, 10))"
                            >
                            </guest-secondary-product-selector>

                            <party-details-form-field-error :name="`guestSecondaryEventSelections.${guest.id}`" :form-errors="formErrors"></party-details-form-field-error>
                        </div>
                    </div>

                    <div
                        v-for="(meals, blockableId) in eventMealsByBlockable"
                        :key="blockableId"
                        class="w-full mb-4 border-b"
                    >
                        <div class="p-4">
                            <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">{{ meals[0].blockable.settings.title }}</div>

                            <guest-meal-selector
                                :event="event"
                                :guest="guest"
                                :all-event-meals="eventMeals"
                                :blockable-id="parseInt(blockableId, 10)"
                                :is-editing="isProductReplyEditable(guest) && isEditing"
                                @selected-meal="selectMeal(guest, $event, parseInt(blockableId, 10))"
                            ></guest-meal-selector>

                            <party-details-form-field-error :name="`guestMeals.${guest.id}`" :form-errors="formErrors"></party-details-form-field-error>
                        </div>
                    </div>

                    <div class="w-full mb-4">
                        <div
                            v-for="question in perAttendeeEventQuestions"
                            :key="question.id"
                            class="p-4 border-b last:border-b-0"
                        >
                            <div class="mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">{{ question.title }}</div>

                            <div class="flex items-center">
                                <custom-question-answer
                                    :is-editing="true"
                                    :question="getGuestQuestionAnswer(guest, question)"
                                    @input="modifyNewGuestCustomQuestion(guest, question, $event)"
                                ></custom-question-answer>
                            </div>

                            <party-details-form-field-error
                                :name="`guestAnswers.${index}.${question.id}`"
                                :form-errors="formErrors"
                            ></party-details-form-field-error>
                        </div>
                    </div>
                </template>
            </accordion-list-item>
        </accordion-list>

        <button
            v-if="userCanEditReplies"
            class="button button-info w-full my-4"
            @click="addNewGuest"
        >Add Guest to Group</button>

        <portal to="party-details-modal-footer">
            <edit-party-details-footer
                :loading="saving"
                :disabled="!hasChanges"
                @cancel="cancel"
                @save="save"
            ></edit-party-details-footer>
        </portal>
    </div>
</template>

<script>
import {
    cloneDeep, get, find, isEmpty, map, filter, groupBy, mapValues, findIndex
} from 'lodash';
import { call } from 'vuex-pathify';
import axios from '@/util/axios';
import EventTimezone from '@/mixins/EventTimezone';
import GeneratesUniqueKey from '@/mixins/GeneratesUniqueKey';

export default {
    name: 'PartyDetailsGuestsTab',

    mixins: [EventTimezone, GeneratesUniqueKey],

    props: {
        event: {
            type: Object,
            required: true
        },
        isActive: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        initialSubmission: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            activeIds: [],
            data: null,
            editPayload: {
                guestDetails: {},
                guestProducts: {},
                guestSecondaryEventSelections: {},
                guestMeals: {},
                guestAnswers: {},
                guestCustomData: {},
                deletedGuests: [],
                newGuests: []
            },
            initialGuests: [],
            eventMeals: [],
            secondaryEventProducts: [],
            eventQuestions: [],
            customDataFields: [],
            attendingCount: 0,
            notAttendingCount: 0,
            formErrors: {},
            individualProducts: [],
            isDataLoaded: false,
            loadedDataCount: 0,
            saving: false,
            acceptsDonations: false,
            hasPayableItems: false,
            grossSales: 0,
            totalDonations: 0
        };
    },

    computed: {
        accordionCollapseText () {
            return this.activeIds.length ? 'Collapse All' : 'Expand All';
        },

        canDeleteGuests () {
            return this.isEditing && (this.guests.length + this.editPayload.newGuests.length) > 1;
        },

        eventMealsByBlockable () {
            return groupBy(this.eventMeals, 'blockable_id');
        },

        hasChanges () {
            return !isEmpty(this.editPayload.guestProducts)
                || !isEmpty(this.editPayload.guestSecondaryEventSelections)
                || !isEmpty(this.editPayload.guestMeals)
                || !isEmpty(this.editPayload.guestAnswers)
                || !isEmpty(this.editPayload.guestDetails)
                || !isEmpty(this.editPayload.guestCustomData)
                || !isEmpty(this.editPayload.deletedGuests)
                || !isEmpty(this.editPayload.newGuests);
        },

        guests () {
            return this.data.guests.filter((guest) => {
                return this.editPayload.deletedGuests.indexOf(guest.id) === -1;
            });
        },

        isDonationCardVisible () {
            return this.acceptsDonations || this.totalDonations > 0;
        },

        isTotalSalesCardVisible () {
            return this.hasPayableItems || this.grossSales > 0;
        },

        perAttendeeEventQuestions () {
            return filter(this.eventQuestions, { settings: { target: 'individual' } });
        },

        manualRsvpNote () {
            if (this.initialSubmission.testMode) {
                return `This is a test registration. Test registrations will no longer be included in dashboard metrics after you've published your event.`;
            } if (this.initialSubmission.isCourtesyRegistration) {
                return `This is a Courtesy Registration. No charge is associated with this registration.`;
            }

            return ``;
        },

        orderTotal () {
            return Math.max(0, this.data.order.total - this.data.order.donation);
        },

        secondaryEventsByBlockable () {
            return groupBy(this.secondaryEventProducts, 'blockable_id');
        },

        styles () {
            return {
                manualRsvpClass: {
                    'alert-warning': this.initialSubmission.testMode,
                    'alert-error': this.initialSubmission.isCourtesyRegistration
                }
            };
        },

        userCanEditReplies () {
            if (this.event.classyIntegration) {
                return false;
            }

            return this.auth().user().can('update.event') || false;
        }
    },

    watch: {
        isActive (activated) {
            if (activated) {
                this.loadData();
            }
        }
    },

    methods: {
        addNewGuest () {
            if (!this.isEditing) {
                this.$emit('toggle-edit-mode');
            }

            const newGuest = {
                id: this.getUniqueKey(),
                product: get(this.individualProducts[0], 'id', null),
                firstName: undefined,
                lastName: undefined,
                email: undefined,
                tags: [],
                questionAnswers: {},
                meals: mapValues(this.eventMealsByBlockable, () => { return null; }),
                secondaryEventProducts: mapValues(this.secondaryEventsByBlockable, () => { return null; }),
                isNewGuest: true
            };

            this.editPayload.newGuests.push(newGuest);

            this.activeIds.push(newGuest.id);
        },

        cancel () {
            this.toggleEditMode();
            this.clearChanges();
        },

        clearChanges () {
            this.$set(this.editPayload, 'guestProducts', {});
            this.$set(this.editPayload, 'guestSecondaryEventSelections', {});
            this.$set(this.editPayload, 'guestMeals', {});
            this.$set(this.editPayload, 'guestAnswers', {});
            this.$set(this.editPayload, 'guestDetails', {});
            this.$set(this.editPayload, 'guestCustomData', {});
            this.$set(this.editPayload, 'deletedGuests', []);
            this.$set(this.editPayload, 'newGuests', []);
        },

        displaySuccessfulCopyToast () {
            this.$toasted.show('Email address copied to clipboard.');
        },

        deleteGuest (guest) {
            this.editPayload.deletedGuests.push(guest.id);
        },

        getGuestQuestionAnswer (guest, question) {
            let questionAnswer = guest.isNewGuest
                ? get(guest.questionAnswers, question.id)
                : find(guest.questionAnswers, { id: question.id });

            if (!questionAnswer) {
                questionAnswer = {
                    answers: [{ value: null }]
                };
            }

            return {
                ...question,
                slug: question.type.slug,
                ...questionAnswer
            };
        },

        getAvailableSecondaryEventBlockSelections (blockableId) {
            return this.secondaryEventProducts.filter((product) => {
                return product.blockable.id === blockableId;
            });
        },

        getGuestCustomDataFields (guest) {
            return this.customDataFields.map((customDataField) => {
                const value = get(find(guest.customData, { field_id: customDataField.id }), 'value', '');

                return {
                    id: customDataField.id,
                    field: customDataField.field,
                    value
                };
            });
        },

        getGuestCustomDataValue (guest, customDataFieldId) {
            const defaultValue = get(find(guest.customData, { field_id: customDataFieldId }), 'value', '');

            return get(this.editPayload.guestCustomData[guest.id], customDataFieldId, defaultValue);
        },

        getProductReplyTooltip (guest) {
            if (!this.isEditing) {
                return false;
            }

            if (get(guest, 'products.0.category') === 'group') {
                return `Add-ons and groups packages aren't editable at this time. Please delete and resubmit this group's reply if you'd like to update these selections.`;
            }

            return '';
        },

        hasDifferentInviteeName (guest) {
            return guest.invitee && guest.invitee.formalName !== guest.formalName;
        },

        isProductReplyEditable (guest) {
            if (!this.isEditing) {
                return false;
            }

            return get(guest, 'products.0.category') !== 'group';
        },

        loadData (force = false) {
            if (this.isDataLoaded && !force) {
                return null;
            }

            return axios.get(this.route('api.submissions.reporting.guest-details', this.initialSubmission))
                .then(({ data }) => {
                    this.isDataLoaded = true;
                    this.data = data.data;
                    this.initialGuests = cloneDeep(this.data.guests);
                    this.eventMeals = data.eventMeals;
                    this.secondaryEventProducts = data.secondaryEventProducts;
                    this.eventQuestions = data.eventQuestions;
                    this.attendingCount = data.attendingCount;
                    this.notAttendingCount = data.notAttendingCount;
                    this.individualProducts = data.individualProducts;
                    this.customDataFields = data.customDataFields;
                    this.loadedDataCount += 1;
                    this.acceptsDonations = data.acceptsDonations;
                    this.totalDonations = data.totalDonations;
                    this.grossSales = data.grossSales;
                    this.hasPayableItems = data.hasPayableItems;

                    this.$emit('fetched-guests', this.data.guests);
                });
        },

        reloadTags: call('Tags/reloadTags'),

        tagEntireGroup (newTags) {
            this.data.guests.forEach((guest) => {
                this.setGuestDetail(guest, 'tags', newTags);
            });

            this.updateTags(
                map(this.data.guests, 'id'),
                newTags
            );
        },

        tagSingleGuest (guest, newTags) {
            this.updateTags([guest.id], newTags);
            this.setGuestDetail(guest, 'tags', newTags);
        },

        toggleAllItems () {
            this.activeIds = this.activeIds.length > 0 ? [] : map(this.data.guests, 'id');
        },

        toggleEditMode () {
            this.$emit('toggle-edit-mode');
        },

        selectProduct (guest, product) {
            if (guest.isNewGuest) {
                this.$set(guest, 'product', product ? product.id : null);

                return;
            }

            this.$set(this.editPayload.guestProducts, guest.id, {
                guest: guest.id,
                product: product ? product.id : null
            });

            if (product) {
                this.$set(guest.products, 0, product);
                this.$set(guest, 'state', product.guest_state);
                this.$set(guest, 'notAttending', false);
            } else {
                // Selected Not Attending
                this.$set(guest, 'products', []);
                this.$set(guest, 'notAttending', true);
            }
        },

        selectMeal (guest, meal, blockableId) {
            if (guest.isNewGuest) {
                this.$set(guest.meals, blockableId, meal ? meal.id : null);

                return;
            }

            const initialGuestMeals = find(this.initialGuests, { id: guest.id }).meals;
            const initialMeal = find(initialGuestMeals, { blockable_id: blockableId });

            this.$set(
                this.editPayload.guestMeals,
                `${blockableId}-${guest.id}`,
                {
                    guest: guest.id,
                    oldMeal: initialMeal ? initialMeal.id : null,
                    newMeal: meal ? meal.id : null
                }
            );

            const mealIndex = findIndex(guest.meals, { blockable_id: blockableId });

            if (mealIndex !== -1) {
                guest.meals.splice(mealIndex, 1);
            }

            if (meal) {
                guest.meals.push({ ...meal, blockable: meal.blockable });
            }
        },

        selectSecondaryEventProduct (guest, product, blockableId) {
            if (guest.isNewGuest) {
                this.$set(guest.secondaryEventProducts, blockableId, product ? product.id : null);

                return;
            }

            const initialSecondaryEventProducts = find(this.initialGuests, { id: guest.id }).secondaryEventProducts;
            const initialProduct = find(initialSecondaryEventProducts, { blockable_id: blockableId });

            this.$set(
                this.editPayload.guestSecondaryEventSelections,
                `${blockableId}-${guest.id}`,
                {
                    guest: guest.id,
                    oldSelection: initialProduct ? initialProduct.id : null,
                    newSelection: product ? product.id : null
                }
            );

            const productIndex = findIndex(guest.secondaryEventProducts, { blockable_id: blockableId });

            if (productIndex !== -1) {
                guest.secondaryEventProducts.splice(productIndex, 1);
            }

            if (product) {
                guest.secondaryEventProducts.push({ ...product, blockable: product.blockable });
            }
        },

        modifyCustomQuestion (guest, question, answers) {
            if (!get(this.editPayload.guestAnswers, guest.id)) {
                this.$set(this.editPayload.guestAnswers, guest.id, {});
            }

            this.$set(this.editPayload.guestAnswers[guest.id], question.id, answers);
        },

        modifyNewGuestCustomQuestion (guest, question, answers) {
            this.$set(guest.questionAnswers, question.id, answers);
        },

        setGuestCustomData (guest, customDataFieldId, value) {
            if (!get(this.editPayload.guestCustomData, guest.id)) {
                this.$set(this.editPayload.guestCustomData, guest.id, {});
            }

            this.$set(this.editPayload.guestCustomData[guest.id], customDataFieldId, value);
        },

        getGuestDetail (guest, field) {
            return get(this.editPayload.guestDetails, `${guest.id}.${field}`, guest[field]);
        },

        setGuestDetail (guest, field, value) {
            if (!get(this.editPayload.guestDetails, guest.id)) {
                this.$set(this.editPayload.guestDetails, guest.id, {});
            }

            this.$set(this.editPayload.guestDetails[guest.id], field, value);
        },

        save () {
            this.saving = true;
            this.$set(this, 'formErrors', {});

            axios.post(
                this.route('api.events.submissions.update', [this.event, this.initialSubmission]),
                this.editPayload
            )
                .then(() => {
                    this.$emit('saved');
                })
                .catch((error) => {
                    this.$set(this, 'formErrors', error.response.data.errors);
                    this.$toasted.global.error('There was an error updating the submission.');
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        updateTags (ids, tags) {
            axios.post(this.route('api.dashboard.guests.tags.sync', this.event), {
                ids,
                tags: map(tags, 'name')
            }).then(() => { return this.reloadTags(); });
        }
    }
};
</script>
