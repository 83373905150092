<template>
    <div>
        <button
            class="menu-button relative mr-2"
            @click="launchModal"
        >
            <app-icon name="tags-add" class="h-5 w-5"></app-icon>
        </button>

        <app-modal
            v-model="showModal"
            :title="modalTitle"
        >
            <div v-if="showMainList" class="flex flex-col -mx-6">
                <a
                    v-for="mergeTag in mergeTags"
                    :key="mergeTag.key"
                    role="button"
                    class="group flex items-center px-6 py-2 hover:bg-gray-100"
                    @click="selectMergeTag(mergeTag)"
                >
                    <span class="flex-1">{{ mergeTag.label }}</span>
                    <span class="button-icon button-primary hidden group-hover:flex">
                        <app-icon
                            v-if="mergeTag.value"
                            name="add-circle"
                            stroke
                        ></app-icon>
                        <app-icon
                            v-else
                            name="arrow-right-chevron"
                            stroke
                        ></app-icon>
                    </span>
                </a>
            </div>
            <div v-else class="flex flex-col space-y-2">
                <button class="flex -mx-1 items-center font-semibold space-x-2 hover:text-purple" @click="unselectDynamicMergeTag">
                    <app-icon
                        name="arrow-left-chevron"
                        stroke
                        class="h-5 w-5"
                    ></app-icon>
                    Go Back
                </button>

                <component
                    :is="`${selectedDynamicMergeTag.key}-merge-tags`"
                    @insert-merge-tag="insertDynamicMergeTag"
                ></component>
            </div>
        </app-modal>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    name: 'EditorMergeTagsButton',

    props: {
        editor: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            showModal: false,
            selectedDynamicMergeTag: null
        };
    },

    computed: {
        event: get('Event/event'),

        eventHasRecurringTimeslots () {
            return this.event.settings.recurringTimeSlots.active;
        },

        eventHasTags () {
            return this.event.tags.length > 0;
        },

        mergeTags () {
            const mergeTags = [
                {
                    key: 'first-name', label: 'First Name', value: '{first_name}'
                },
                {
                    key: 'last-name', label: 'Last Name', value: '{last_name}'
                },
                {
                    key: 'title', label: 'Title', value: '{title}'
                },
                {
                    key: 'all-guests', label: 'List All Guests in Group', value: '{all_guests}'
                },
                {
                    key: 'confirmation-code', label: 'Confirmation Code', value: '{confirmation_code}'
                },
                {
                    key: 'invitee-password', label: 'Invitee Password', value: '{invitee_password}'
                },
                {
                    key: 'seating-assignment', label: 'Seating Assignment', value: null
                },
                {
                    key: 'custom-data-field', label: 'Custom Data Field', value: null
                },
                {
                    key: 'custom-question-answer', label: 'Custom Question Answer', value: null
                },
                {
                    key: 'meal-preference', label: 'Meal Preference', value: null
                }
            ];

            if (this.eventHasTags) {
                mergeTags.push(
                    {
                        key: 'assigned-tags', label: 'Assigned Tags', value: '{assigned_tags}'
                    }
                );
            }

            if (this.eventHasRecurringTimeslots) {
                mergeTags.push(
                    {
                        key: 'appointment-date-time', label: 'Appointment Date and Time', value: '{appointment_date_time}'
                    }
                );
            }

            return mergeTags;
        },

        modalTitle () {
            return this.selectedDynamicMergeTag ? `Add merge tag: ${this.selectedDynamicMergeTag.label}` : 'Add merge tag';
        },

        showMainList () {
            return !this.selectedDynamicMergeTag;
        }
    },

    watch: {
        showModal (newVal) {
            if (!newVal) {
                this.selectedDynamicMergeTag = null;
                this.$nextTick(() => {
                    this.$emit('insert-tag');
                });
            }
        }
    },

    methods: {
        insertMergeTag (value) {
            this.editor.view.dispatch(
                this.editor.state.tr.insertText(value)
            );

            this.showModal = false;
        },

        insertDynamicMergeTag (tags) {
            this.selectedDynamicMergeTag = null;

            this.insertMergeTag(tags);
        },

        launchModal () {
            this.showModal = true;
        },

        selectMergeTag (tag) {
            if (tag.value) {
                this.insertMergeTag(tag.value);
                return;
            }

            this.selectedDynamicMergeTag = tag;
        },

        unselectDynamicMergeTag () {
            this.selectedDynamicMergeTag = null;
        }
    }
};
</script>
