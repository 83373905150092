/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wedding-ring-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.635 15l-6-5.25 2.77-3.468v-.01c.14-.18.36-.29.59-.29h5.27l-.01-.001c.22 0 .44.1.58.28l2.77 3.46zM11.64 3V.75M19.06 6.07l1.59-1.59M4.21 6.07L2.62 4.48M5.64 9.75h12M11.64 6v9"/><path pid="1" d="M2 23.25v-.01c.83-5.33 5.81-8.97 11.13-8.14 4.19.65 7.47 3.94 8.13 8.13"/><path pid="2" d="M5.82 23.25h-.01a5.99 5.99 0 017.3-4.32c2.11.54 3.77 2.19 4.31 4.31"/></g>'
  }
})
