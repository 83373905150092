<template>
    <div>
        <div class="pb-1 mb-5 flex justify-between border-b">
            <div class="flex font-semibold text-lg uppercase text-gray-600 tracking-wide">
                Account Status
            </div>
        </div>

        <div class="flex flex-col">

            <form-field-wrapper
                label="Manual verification status"
                :should-show-error="form.errors.has('is_verified')"
                :error="form.errors.get('is_verified')"
            >
                <select-list
                    v-model="form.is_verified"
                    class="space-y-2"
                >
                    <select-option
                        v-for="(manualVerificationItem, key) in manualVerificationItems"
                        :id="manualVerificationItem.value"
                        :key="key"
                        :label="manualVerificationItem.label"
                    >
                    </select-option>
                </select-list>
            </form-field-wrapper>

            <form-field-wrapper>
                <toggle-switch v-model="form.email_verified">
                    <span class="font-base text-gray-600">User's email is verified</span>
                </toggle-switch>
            </form-field-wrapper>

            <form-field-wrapper
                :should-show-error="form.errors.has('account_status')"
                :error="form.errors.get('account_status')"
            >
                <select-list v-model="form.account_status" class="flex space-x-4">
                    <select-option
                        v-for="({ label, value }) in accountStatuses"
                        :id="value"
                        :key="value"
                        :label="label"
                        class="font-base text-gray-600"
                    ></select-option>
                </select-list>
            </form-field-wrapper>
        </div>

        <div class="w-full">
            <div class="pt-6 pb-1 mb-5 flex justify-between border-b">
                <div class="flex font-semibold text-lg uppercase text-gray-600 tracking-wide">
                    Plan
                </div>
            </div>

            <div class="w-full">
                <div class="flex">
                    <div class="flex-none">
                        <toggle-switch v-model="form.plan_override">
                            <span class="font-base text-gray-600 text-xs">Enable manual plan management</span>
                        </toggle-switch>
                    </div>
                    <div class="ml-auto mr-auto">
                        <form-field-wrapper
                            v-if="planOverrideHasEndDate"
                            :should-show-error="form.errors.has('plan_override_renews')"
                            :error="form.errors.get('plan_override_renews')"
                            class="flex-1 ml-auto"
                        >
                            <select-list
                                v-model="form.plan_override_renews"
                                class="inline-flex flex-wrap"
                            >
                                <select-option
                                    :id="true"
                                    class="ml-2 p-1 font-base text-gray-600"
                                    label="Renews On"
                                >
                                </select-option>

                                <select-option
                                    :id="false"
                                    class="ml-2 p-1 font-base text-gray-600"
                                    label="Ends On"
                                >
                                </select-option>
                            </select-list>
                        </form-field-wrapper>
                    </div>
                </div>

                <div v-if="form.plan_override" class="flex items-center mt-4">
                    <div class="w-1/2 pr-2">
                        <div class="pr-2 text-xs">Plan:</div>

                        <simple-picker
                            v-model="form.plan_id"
                            :items="plans"
                            item-value="id"
                            is-filterable
                            filterable-property="internal_name"
                            placeholder-empty-state="Select a plan..."
                            placeholder-search="Search for a plan ..."
                        >
                            <template #input="{ selected }">
                                {{ selected.internal_name }} ({{ selected.price | currency(true) }})
                            </template>

                            <template #item="{ item }">
                                {{ item.internal_name }} ({{ item.price | currency(true) }})
                            </template>
                        </simple-picker>
                    </div>

                    <div v-if="planOverrideHasEndDate" class="pl-2 grow">
                        <date-time-picker
                            v-model="form.plan_override_ends_at"
                            placeholder="Select date ..."
                            title="Plan Override"
                            :has-time="false"
                            class="w-full mt-4"
                        ></date-time-picker>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full">
            <div class="pt-6 pb-1 mb-5 flex justify-between border-b">
                <div class="flex font-semibold text-lg uppercase text-gray-600 tracking-wide">
                    Plan Segment
                </div>
            </div>

            <div class="w-full">
                <simple-picker
                    v-model="form.user_segment_id"
                    :items="planSegments"
                    item-value="id"
                    is-filterable
                    item-label="label"
                    filterable-property="label"
                    placeholder-empty-state="Select a plan segment..."
                    placeholder-search="Search for a plan segment..."
                >
                </simple-picker>
            </div>
        </div>

        <div class="pt-6 pb-1 mb-5 flex justify-between border-b">
            <div class="flex font-semibold text-lg uppercase text-gray-600 tracking-wide">
                MULTI-FACTOR AUTHENTICATION
            </div>
        </div>

        <div class="flex items-center">
            <toggle-switch v-model="form.settings.mfa.requiredByAdmin">Require Multi-Factor Authentication</toggle-switch>
        </div>

        <div class="pt-6 pb-1 mb-5 flex justify-between border-b">
            <div class="flex font-semibold text-lg uppercase text-gray-600 tracking-wide uppercase">
                Email Whitelabeling
            </div>
        </div>

        <div class="flex items-center space-x-4">
            <toggle-switch v-model="form.settings.emailWhitelabeling.enabled">Enable for user and admin-level co-managers</toggle-switch>
        </div>

        <form-field-wrapper
            v-if="form.settings.emailWhitelabeling.enabled"
            :should-show-error="form.errors.has('settings.emailWhitelabeling.emails')"
            :error="form.errors.get('settings.emailWhitelabeling.emails')"
            class="mt-2"
            label="Domains"
        >
            <input
                v-model="form.settings.emailWhitelabeling.emails"
                class="form-field w-full p-1"
            >
        </form-field-wrapper>

        <div class="pt-6 pb-1 mb-5 flex justify-between border-b">
            <div class="flex font-semibold text-lg uppercase text-gray-600 tracking-wide">
                Email Credits
            </div>
        </div>

        <div class="flex">
            <div class="grow-0 flex items-center mr-6">
                <form-field-wrapper
                    :should-show-error="form.errors.has('additional_email_credits')"
                    :error="form.errors.get('additional_email_credits')"
                >
                    <label class="flex items-center justify-between relative uppercase tracking-wide text-sm font-semibold text-gray-600">
                        Additional Credits
                    </label>

                    <input
                        v-model.number="form.additional_email_credits"
                        type="number"
                        class="form-field w-18 p-1"
                        min="0"
                    >
                </form-field-wrapper>
            </div>

            <div class="grow">
                <label class="flex items-center justify-between relative uppercase tracking-wide text-sm font-semibold text-gray-600">
                    Resets
                </label>

                <div class="p-1 flex justify-between relative font-base text-gray-600">
                    {{ user.credits_period_end | datetime('DATE_FULL') }}
                </div>
            </div>
        </div>

        <div class="pt-6 pb-1 mb-5 flex justify-between border-b">
            <div class="flex font-semibold text-lg uppercase text-gray-600 tracking-wide">
                Fees
            </div>
        </div>

        <div class="flex mb-8">
            <div class="grow-0 mr-6">
                <form-field-wrapper
                    :should-show-error="form.errors.has('percentage')"
                    :error="form.errors.get('percentage')"
                >
                    <label class="flex items-center justify-between relative mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">
                        Percent Fee
                    </label>

                    <div class="flex items-center w-24">
                        <input
                            v-model.number="form.percentage"
                            type="number"
                            class="form-field p-1"
                            min="0"
                            max="100"
                            step="0.01"
                        >
                        <span class="input-append p-1 text-gray-600">%</span>
                    </div>
                </form-field-wrapper>
            </div>

            <div class="flex">
                <form-field-wrapper
                    :should-show-error="form.errors.has('fixed')"
                    :error="form.errors.get('fixed')"
                >
                    <label class="flex items-center justify-between relative mb-2 uppercase tracking-wide text-sm font-semibold text-gray-600">
                        Fixed Fee
                    </label>

                    <div class="flex items-center w-24">
                        <span class="input-prepend p-1 text-gray-600">$</span>
                        <input
                            v-model.number="form.fixed"
                            type="number"
                            class="form-field p-1"
                            min="0"
                            step="0.01"
                        >
                    </div>
                </form-field-wrapper>
            </div>
        </div>

        <div class="pt-6 pb-1 mb-5 flex justify-between border-b">
            <div class="flex font-semibold text-lg uppercase text-gray-600 tracking-wide">
                Security Details
            </div>
        </div>

        <div class="flex mb-8">
            <form-field-wrapper label="Reset password" :error="form.errors.get('new_password')">
                <input v-model="form.new_password" class="form-field">
            </form-field-wrapper>
        </div>

        <div class="flex space-x-2 justify-end">
            <stateful-button
                class="button button-danger"
                :loading="purgingUser"
                @click="purgeUser"
            >
                PERMANENTLY DELETE USER
            </stateful-button>

            <stateful-button
                class="button-primary"
                :disabled="!form.isModified()"
                :loading="form.processing"
                @click="confirmSave"
            >
                Save &amp; Close
            </stateful-button>
        </div>
    </div>
</template>

<script>
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import Form from '@/validation/Form';
import axios from '@/util/axios';

export default {
    name: 'ManageUserAccountTab',

    props: {
        user: {
            type: Object,
            required: true
        },

        plans: {
            type: Array,
            required: true
        },

        planSegments: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            accountStatuses: [
                { value: 'good_standing', label: 'Good standing' },
                { value: 'requires_license', label: 'Requires license' },
                { value: 'suspended', label: 'Suspended' },
                { value: 'closed', label: 'Closed' }
            ],
            form: new Form({
                fixed: this.user.fixed,
                percentage: this.user.percentage,
                additional_email_credits: this.user.additional_email_credits,
                is_verified: this.user.is_verified,
                email_verified: !!this.user.email_verified_at,
                account_status: this.user.account_status,
                plan_override: this.user.plan_override,
                plan_override_renews: this.user.plan_override_renews,
                plan_id: this.user.plan_id,
                plan_override_ends_at: this.user.plan_override_ends_at,
                settings: cloneDeep(this.user.settings),
                user_segment_id: this.user.user_segment_id,
                new_password: null
            }),
            purgingUser: false,
            manualVerificationItems: [
                { value: null, label: 'No action performed' },
                { value: true, label: 'Verified' },
                { value: false, label: 'Blocked' }
            ]
        };
    },

    computed: {
        planOverrideHasEndDate () {
            return this.selectedPlan && !this.selectedPlan.stripe_id;
        },

        selectedPlan () {
            return find(this.plans, { id: this.form.plan_id });
        },

        shouldConfirmSave () {
            return this.form.plan_override && this.selectedPlan && this.selectedPlan.stripe_id && this.form.plan_id !== this.user.plan_id;
        }
    },

    watch: {
        user (newVal) {
            this.form = new Form({
                fixed: newVal.fixed,
                percentage: newVal.percentage,
                additional_email_credits: newVal.additional_email_credits,
                is_verified: newVal.is_verified,
                email_verified: !!newVal.email_verified_at,
                account_status: newVal.account_status,
                plan_override: this.user.plan_override,
                plan_id: this.user.plan_id,
                plan_override_ends_at: this.user.plan_override_ends_at,
                user_segment_id: this.user.user_segment_id
            });
        },

        'form.plan_override': function (enabled) {
            if (!enabled) {
                this.form.plan_id = null;
                this.form.plan_override_ends_at = null;
                this.form.plan_override_renews = null;
            }
        }
    },

    methods: {
        purgeUser () {
            const onConfirm = () => {
                this.purgingUser = true;

                axios.delete(this.route('api.admin.users.purge', this.user)).then(() => {
                    this.$toasted.global.success(`${this.user.email} has been permanently deleted.`);
                    window.location.reload();
                }).catch(() => {
                    this.$toasted.global.error('Something went wrong deleting this user.');
                }).finally(() => {
                    this.purgingUser = false;
                });
            };

            App.alert().confirm(
                `Destroy ${this.user.email}'s account permanently?`,
                'This action will delete ALL data associated with this user. THIS ACTION CAN NOT BE UNDONE.',
                {
                    confirmButtonText: 'PERMANENTLY DELETE USER',
                    cancelButtonText: 'Cancel'
                },
                onConfirm
            );
        },

        confirmSave () {
            if (!this.shouldConfirmSave) {
                this.save();
                return;
            }

            App.alert().confirm(
                'Are you sure?',
                `This operation will perform an actual Stripe upgrade on the user's existing plan.`,
                {
                    confirmButtonText: 'Go Ahead',
                    cancelButtonText: 'Cancel'
                },
                () => { return this.save(); }
            );
        },

        save () {
            this.form.put(this.route('api.admin.users.settings.update', this.user.id))
                .then(() => {
                    this.$emit('updated');
                })
                .catch((error) => {
                    App.alert().error('Whoops!', error.response.data.message);
                });
        }
    }
};
</script>
