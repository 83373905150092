/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dessert-ice-cream-popsicle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food</desc><g _fill="currentColor"><path pid="0" d="M13.5 22.5a1.5 1.5 0 01-3 0V19a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zM17 6a3 3 0 01-3-3 2.912 2.912 0 01.322-1.336 1 1 0 00-.6-1.413A6 6 0 006 6v9.5A1.5 1.5 0 007.5 17h9a1.5 1.5 0 001.5-1.5V7a1 1 0 00-1-1z"/></g>'
  }
})
