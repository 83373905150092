/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-task-list-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>todo</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M21.75 21.76v0c0 .82-.68 1.5-1.5 1.5H3.74a1.51 1.51 0 01-1.5-1.5s0 0 0 0V2.26h0C2.23 1.43 2.91.75 3.73.75h10.62l-.01-.01c.39 0 .77.15 1.06.43l5.872 5.872h0c.28.28.43.66.43 1.06z"/><path pid="1" d="M21.75 8.261h-6l-.01-.001a1.51 1.51 0 01-1.5-1.5V.759M9.669 9.619l-2.14 2.84h-.01a.76.76 0 01-1.06.14c-.03-.03-.06-.05-.08-.07l-1.16-1.16M9.669 16.369l-2.14 2.84h-.01a.76.76 0 01-1.06.14c-.03-.03-.06-.05-.08-.07l-1.16-1.16M12.75 12.76h4.5M12.75 18.76h4.5"/></g>'
  }
})
