/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'toys-building': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>toy wall brick hammer event</desc><g _fill="currentColor"><path pid="0" d="M15 11V8a2 2 0 00-2-2h-.5a.5.5 0 01-.5-.5V3a2 2 0 00-2-2H2a2 2 0 00-2 2v3a2 2 0 002 2h.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H2a2 2 0 00-2 2v3a1.987 1.987 0 00.114.666.992.992 0 010 .669A1.981 1.981 0 000 18v3a2 2 0 002 2h8a2 2 0 002-2v-3a1.981 1.981 0 00-.114-.665 1 1 0 010-.669A1.987 1.987 0 0012 16v-3h1a2 2 0 002-2zM2.5 6a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zM10 20.5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h7a.5.5 0 01.5.5zm0-5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h7a.5.5 0 01.5.5zm2.5-4.5h-7a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zM23.913 6.635a1.439 1.439 0 10-2.706-.981l-3.673 10.132a.5.5 0 01-.641.3l-1.811-.656a.832.832 0 00-1.067.5l-.977 2.694a1 1 0 00.6 1.281l6.955 2.521a1 1 0 001.281-.6l.976-2.694a.833.833 0 00-.5-1.068l-1.811-.656a.5.5 0 01-.3-.641z"/></g>'
  }
})
