/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings-cogs-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M7.28 9.37l-.01-.01c.48.53 1.31.57 1.84.09.03-.03.06-.06.09-.1l.62-.7v-.01a1.3 1.3 0 011.84-.1c.29.26.44.64.42 1.03l-.05.93v-.01c-.05.71.5 1.33 1.22 1.37h.14l.93-.05v-.01c.71-.04 1.33.51 1.37 1.23.02.39-.14.77-.43 1.03l-.7.62v-.01a1.3 1.3 0 00-.1 1.84c.02.03.06.06.09.09l.69.62h0c.53.48.57 1.3.09 1.84-.27.29-.65.45-1.04.42l-.94-.05h0c-.72-.04-1.34.51-1.38 1.23-.01.04-.01.09-.01.13l.04.93h0c.03.72-.52 1.33-1.24 1.37-.4.01-.78-.14-1.04-.43l-.63-.7h0c-.49-.54-1.32-.58-1.85-.1-.04.02-.07.05-.1.09l-.63.7v-.01c-.49.53-1.31.57-1.85.09a1.3 1.3 0 01-.43-1.04l.04-.94h-.01c.03-.73-.52-1.34-1.24-1.38-.05-.01-.09-.01-.14-.01l-.94.04v-.01a1.317 1.317 0 01-.96-2.28l.69-.63h-.01c.53-.49.57-1.32.09-1.85a.474.474 0 00-.1-.1l-.7-.63-.01-.01c-.54-.49-.58-1.31-.1-1.85.26-.3.64-.45 1.03-.43l.93.04-.01-.01c.71.03 1.33-.52 1.37-1.24v-.14l-.05-.94-.01-.01a1.302 1.302 0 012.26-.96z"/><path pid="1" d="M8.25 13.87a1.875 1.875 0 100 3.75 1.875 1.875 0 100-3.75zM18.638 5h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.38-.38-.375v0"/><path pid="2" d="M21.19 1.84v-.01c.38-.16.82.04.97.42.06.17.06.36-.01.54l-.5 1.28v-.01c-.12.3-.03.65.22.85l1.07.86h0c.32.25.37.73.11 1.05a.8.8 0 01-.48.27l-1.37.21h-.01c-.33.04-.58.3-.63.62l-.22 1.36h-.01c-.07.4-.45.68-.86.62a.767.767 0 01-.48-.28l-.87-1.08h0a.77.77 0 00-.86-.24l-1.29.5v-.01a.765.765 0 01-.98-.43.746.746 0 010-.55l.5-1.29v-.01c.11-.31.02-.66-.23-.86l-1.08-.87h0a.75.75 0 01-.12-1.06c.11-.15.28-.25.46-.28l1.36-.22v-.01c.32-.06.57-.31.62-.63l.21-1.37h-.01c.06-.41.44-.69.85-.63.18.02.35.12.47.27l.86 1.07-.01-.01c.2.25.55.34.85.22z"/></g>'
  }
})
