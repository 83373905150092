<template>
    <div class="container">
        <section class="flex flex-col mt-8">
            <div class="py-2 mb-0 font-semibold text-xl border-b text-black">Billing History</div>

            <data-table
                :columns="columns"
                :rows="invoices"
                no-row-border
                no-results-placeholder="There are currently no invoices in your billing history."
            >
                <template slot="row" slot-scope="{ property, value }">
                    <template v-if="property === 'amount'">
                        {{ value | currency(true) }}
                    </template>

                    <template v-else-if="property === 'receipt_url'">
                        <a
                            v-if="value"
                            :href="value"
                            target="_blank"
                            class="font-semibold hover:underline"
                        >View Invoice</a>
                    </template>

                    <template v-else>{{ value }}</template>
                </template>
            </data-table>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        invoices: {
            type: Array,
            required: true
        }
    },

    computed: {
        columns () {
            return [
                {
                    label: 'Date', property: 'formattedDate'
                },
                {
                    label: 'Amount', property: 'amount'
                },
                {
                    label: 'Description', property: 'description'
                },
                {
                    label: 'Invoice Number', property: 'number'
                },
                {
                    label: 'Invoice', property: 'receipt_url'
                }
            ];
        }
    }
};
</script>
