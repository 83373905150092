/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-concert-duet-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.5 3.75h0a2.996 2.996 0 10-.02 5.99c.73 0 1.43-.27 1.98-.75M.75 17.25V15h0a3.739 3.739 0 013.74-3.76 3.736 3.736 0 013.75 3.74s0 0 0 0v2.25h-1.5l-.75 6h-3l-.75-6zM17.25 23.25l-.75-4.5h-2.25l1.13-4.134c.67-2.019 1.5-3.37 3.36-3.37 1.85 0 2.69 1.34 3.36 3.36l1.13 4.134h-2.25l-.75 4.5zM18.75 3.75a3 3 0 100 6 3 3 0 100-6zM9 3.75a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM10.5 5.25V.75M12 10.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM13.5 12V7.5"/></g>'
  }
})
