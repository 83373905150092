import alert from '@/util/alert';
import { asset } from '@codinglabs/laravel-asset';

/**
 * Adding VueJS components within sweetalert2 is possible, but not easy.
 * For now we decided to use the SVGs directly in the HTML as the easier solution.
 * If this comes up in the future again we should consider refactoring our sweetalert2
 * system to support VueJS components within the modal.
 */
const compedUpgradeAlert = function () {
    const title = `<div class="text-2xl">Congratulations, <br> you've unlocked RSVPify's premium features!</div>`;
    const message = `
    <div>
        <p class="text-base">Events selling tickets through RSVPify deserve a <strong>free upgrade.</strong> It's now 100% free to use RSVPify's professional-grade features.</p>
        <p class="text-center text-base mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-purple-600 inline" viewBox="0 0 24 24"><title>check</title><desc>ok yes correct done task</desc><path fill="currentColor" d="M23.146,5.4,20.354,2.6a.5.5,0,0,0-.708,0L7.854,14.4a.5.5,0,0,1-.708,0L4.354,11.6a.5.5,0,0,0-.708,0L.854,14.4a.5.5,0,0,0,0,.707L7.146,21.4a.5.5,0,0,0,.708,0L23.146,6.1A.5.5,0,0,0,23.146,5.4Z"/></svg>
            No subscription or hidden fees.
        </p>
        <p class="text-center text-base">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-purple-600 inline" viewBox="0 0 24 24"><title>check</title><desc>ok yes correct done task</desc><path fill="currentColor" d="M23.146,5.4,20.354,2.6a.5.5,0,0,0-.708,0L7.854,14.4a.5.5,0,0,1-.708,0L4.354,11.6a.5.5,0,0,0-.708,0L.854,14.4a.5.5,0,0,0,0,.707L7.146,21.4a.5.5,0,0,0,.708,0L23.146,6.1A.5.5,0,0,0,23.146,5.4Z"/></svg>
            Pay an industry-leading low ${window.user.fees.percentage}% + $${window.user.fees.fixedFormatted} per ticket.
        </p>
    </div>`;

    const customAttributes = {
        customClass: {
            confirmButton: 'button button-primary'
        },
        buttonsStyling: false,
        confirmButtonText: 'Got it',
        icon: false,
        imageUrl: asset('/images/account/rsvpify-congrats.svg'),
        imageHeight: 90,
        width: 620,
        html: message
    };

    return alert.createAlert('info', title, null, customAttributes);
};

/**
 * The checkInCreditsData variable contains a JSON object response from the
 * SetGuestsCheckInStateController that contains the following properties:
 * type, remaining_credits, max_credits, used_credits and optionally - error.
 *
 * The `type` indicates the CheckIn feature type (hide, limited, unlimited, try-free).
 *
 * @param checkInCreditsData
 */
const checkInUpgradeAlert = function (checkInCreditsData, checkInLimits) {
    const isPlatformIos = window.document.body.classList.contains('cordova')
        && window.document.body.classList.contains('ios');

    let message = ``;
    let title = `Please upgrade.`;

    /**
     * Using the check-in for the first time.
     */
    if (checkInCreditsData.used_credits === 0) {
        title = 'Try for free!';
    }

    if (isPlatformIos) {
        if (checkInCreditsData.used_credits > 0) {
            title = 'No check-in credits';
        }

        message = `You have ${checkInCreditsData.remaining_credits} check-in credits remaining this period.
                To add additional credits, login through a web browser or contact the RSVPify Support Team
                or your Enterprise Account manager.`;
    } else if (checkInCreditsData.type === 'try-free') {
        /**
         * Try for free with a certain limit.
         */
        if (checkInCreditsData.error === 'over_limit') {
            message = `You're out of free trial check-in credits. Upgrade to get full access to the Check-In Suite.`;
        } else {
            /**
             * Using check-in for the first time
             */
            message = `You have ${checkInCreditsData.remaining_credits} free trial check-in credits remaining this period.
                Upgrade to get full access to the Check-In Suite.`;
        }
    } else if (checkInCreditsData.type === 'limited') {
        message = `Your ${window.user.plan.name} plan is limited to ${checkInLimits.max} check-in and
                check-out actions per month. Upgrade to continue to use the Check-In suite.`;
    }

    const buttonOptions = isPlatformIos
        ? { confirmButtonText: 'Got it' }
        : {
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: 'Upgrade',
            cancelButtonText: 'Cancel'
        };

    const customAttributes = {
        customClass: {
            confirmButton: 'button button-primary',
            cancelButton: 'button mx-2'
        },
        buttonsStyling: false,
        icon: false,
        width: 620,
        html: `<div><p class="text-base">${message}</p></div>`,
        imageUrl: asset('/images/account/rsvpify-premium.svg'),
        imageHeight: 90,
        reverseButtons: true,
        ...buttonOptions
    };

    return alert.createAlert('info', title, null, customAttributes).then(
        (result) => {
            if (result.isConfirmed && !isPlatformIos) {
                window.open(window.route('account.upgrade.index'), '_system');
            }
        }
    );
};

export {
    compedUpgradeAlert,
    checkInUpgradeAlert
};
