<template>
    <div>
        <div
            :id="blockId"
            v-theme="'wall.background'"
            class="relative p-6"
        >
            <div
                v-if="displayTitle"
                v-theme="['wall.title-color', 'wall.title-font', 'wall.title-font-size']"
                class="flex justify-center items-center flex-auto mb-6 font-bold leading-normal"
            >
                <app-icon
                    v-if="icon"
                    :name="icon"
                    class="h-8 w-8 mr-4"
                ></app-icon>

                {{ title }}
            </div>

            <div
                v-for="speaker in activeSpeakers"
                :key="speaker.id"
                class="flex mt-6"
            >
                <div v-if="speaker.images.length > 0" class="mr-6 shrink-0">
                    <img
                        v-for="image in speaker.images"
                        :key="image.id"
                        class="w-32 rounded-md"
                        :src="image.src"
                    >
                </div>
                <div v-theme="'wall.text'" class="items-start">
                    <div class="text-left leading-normal">
                        <p class="font-semibold">{{ speaker.name }}</p>
                        <p class="text-sm whitespace-pre-wrap break-anywhere opacity-75">{{ speaker.description }}</p>

                        <div v-if="shouldShowUrl(speaker)" class="inline-block text-sm mt-2 mr-6">
                            <div class="flex items-center">
                                <app-icon
                                    v-theme="'wall.accent-icon'"
                                    name="link"
                                    class="shrink h-4 w-4 mr-2"
                                ></app-icon>

                                <a
                                    class="shrink no-underline text-sm"
                                    :href="normalizeUrl(speaker.url)"
                                    :target="speaker.url ? '_blank' : '_self'"
                                    rel="nofollow"
                                >
                                    {{ speaker.buttonText }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import WallBlock from '@/mixins/WallBlock';
import NormalizesUrls from '@/mixins/NormalizesUrls';

export default {
    name: 'WallSpeakersBlock',

    mixins: [
        WallBlock,
        NormalizesUrls
    ],

    props: {
        displayTitle: {
            type: Boolean,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        speakers: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },

    computed: {
        activeSpeakers () {
            return this.speakers.filter((speaker) => { return speaker.active; });
        }
    },

    methods: {
        shouldShowUrl (speaker) {
            return speaker.includeUrl && speaker.url;
        }
    }
};
</script>
