/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'makeup-nail-polish-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>beauty</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.78 15.085l-2.03 7.07v-.01c-.19.64-.78 1.08-1.45 1.08H8.56l-.01-.001c-.67-.01-1.26-.45-1.45-1.09l-2.03-7.08-.01-.01c-.43-1.48.33-3.04 1.75-3.61v-.01c3.25-1.31 6.89-1.31 10.14 0h0c1.42.57 2.18 2.13 1.75 3.6z"/><path pid="1" d="M8.924 10.842l.72-9.4h-.01c.03-.4.35-.7.75-.7h3.11c.39 0 .71.3.74.69l.72 9.4M14.2 20.25H9.7l-.75-6h6l-.75 6z"/></g>'
  }
})
