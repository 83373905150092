export default {
    computed: {
        affiliateName () {
            if (!window.Rewardful) {
                return null;
            }

            return window.Rewardful.affiliate.name;
        },

        rewardfulReferralId () {
            if (!window.Rewardful || !this.wasReferred) {
                return null;
            }

            return window.Rewardful.referral;
        },

        wasReferred () {
            return window.Rewardful && window.Rewardful.referral && window.Rewardful.campaign.id === '89f04425-42e7-4e52-bcaa-21e4f764dc3f';
        }
    }
};
