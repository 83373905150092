<template>
    <div class="text-center">
        <div class="marvel-device iphone-x">
            <div class="top-bar"></div>
            <div class="sleep"></div>
            <div class="bottom-bar"></div>
            <div class="volume"></div>
            <div class="overflow">
                <div class="shadow shadow--tr"></div>
                <div class="shadow shadow--tl"></div>
                <div class="shadow shadow--br"></div>
                <div class="shadow shadow--bl"></div>
            </div>
            <div class="inner-shadow"></div>
            <div
                ref="mobileScrollContainer"
                v-bar="{ useScrollbarPseudo: true }"
                class="screen"
                :style="styles.container"
            >
                <div class="mobile-container" :style="styles.body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BrowserWindow',

    props: {
        backgroundColor: {
            type: String,
            default: '#fff'
        },

        bodyBackgroundColor: {
            type: String,
            default: '#fff'
        }
    },

    computed: {
        styles () {
            return {
                container: {
                    'background-color': this.backgroundColor
                },
                body: {
                    'background-color': this.bodyBackgroundColor
                }
            };
        }
    }
};
</script>
