<template>
    <div>
        <form-field-wrapper label="Icon">
            <icon-picker
                v-model="form.settings.icon"
                class="text-gray-800 w-11 h-11"
            ></icon-picker>
        </form-field-wrapper>

        <form-field-wrapper
            label="Title"
            :error="form.errors.get('title')"
            :should-show-error="form.errors.has('title')"
        >
            <input
                v-model="form.title"
                class="form-field"
                type="text"
                name="title"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Description (optional)">
            <textarea
                v-model="form.settings.description"
                class="form-field"
                placeholder="Enter a description and details about this ticket or selection."
                rows="5"
            ></textarea>
        </form-field-wrapper>

        <form-panel
            v-model="form.settings.costsMoney"
            label="Selection Requires Payment"
            tooltip="Choose to require a payment for this selection or option."
            :error="form.errors.get('price')"
            :should-show-error="form.errors.has('price')"
        >
            <money-field v-model="form.price"></money-field>
        </form-panel>

        <form-panel
            v-model="form.settings.maxCapacity.enabled"
            class="mt-8"
            label="Limited Number Available"
            tooltip="Choose to hide or disable this option or selection based on availability or capacity."
        >
            <template #trigger-appended>
                <premium-feature feature="LimitedCapacity" class="ml-2"></premium-feature>
            </template>

            <form-field-wrapper
                :error="form.errors.get('settings.maxCapacity.slots')"
                :should-show-error="form.errors.has('settings.maxCapacity.slots')"
            >
                <div class="flex items-end">
                    <input
                        v-model.number="form.settings.maxCapacity.slots"
                        class="form-field w-32 mr-4"
                        type="number"
                        name="number-available"
                        step="1"
                        min="1"
                    >
                    <div class="py-2 bold uppercase tracking-wide">
                        Available
                    </div>
                </div>
            </form-field-wrapper>

            <form-field-wrapper label="When no longer available...">
                <select-list v-model="form.settings.maxCapacity.showMessage">
                    <select-option
                        :id="true"
                        label="Disable and display a message"
                        class="my-2"
                    ></select-option>

                    <select-option
                        :id="false"
                        label="Hide completely"
                        class="my-2"
                    ></select-option>
                </select-list>
            </form-field-wrapper>

            <form-field-wrapper
                v-if="form.settings.maxCapacity.showMessage"
                label="Message to display"
                :error="form.errors.get('settings.maxCapacity.message')"
                :should-show-error="form.errors.has('settings.maxCapacity.message')"
            >
                <tiptap-editor
                    v-model="form.settings.maxCapacity.message"
                    simple
                    class="form-field"
                ></tiptap-editor>
            </form-field-wrapper>
        </form-panel>

        <portal to="app-drawer-footer">
            <footer-save-cancel
                :processing="loading"
                @cancel="$emit('cancel')"
                @save="save"
            ></footer-save-cancel>
        </portal>
    </div>
</template>

<script>
import FormItemSettingsMixin from '@/mixins/FormItemSettingsMixin';
import InteractsWithFeatureFlags from '@/mixins/InteractsWithFeatureFlags';

export default {
    name: 'FormMealBlockItemSettings',

    mixins: [FormItemSettingsMixin, InteractsWithFeatureFlags],

    props: {
        item: {
            type: Object,
            default: () => { return {}; }
        }
    },

    methods: {
        saveSettings () {
            if (!this.errors.any()) {
                this.$emit('save-settings');
            } else {
                this.shouldShowErrorMessages = true;
            }
        }
    }
};
</script>
