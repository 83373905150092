/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-protection-face-mask-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 20.625v-.01c-2.75.07-5.48-.44-8-1.5v-9s5.99-3.13 7.99-3.13"/><path pid="1" d="M7 12.875h-.01c1.65-.28 3.32-.45 5-.51M7 16.375l-.01-.01c1.64.3 3.32.47 4.99.5M12 20.625l-.01-.01c2.74.07 5.47-.44 8-1.5v-9s-6-3.13-8-3.13"/><path pid="2" d="M17 12.875h0c-1.66-.28-3.33-.45-5-.51M17 16.375v-.01c-1.65.3-3.33.47-5 .49M4 10.125l-.76-.4-.01-.01a4.74 4.74 0 01-2.5-4.19v-.01c0-1.2.96-2.17 2.16-2.17h-.01c.38-.01.77.08 1.12.25l-.01-.01c1.05.5 1.49 1.77.98 2.82-.08.15-.17.29-.27.42M4 19.125l-.01-.01a6.846 6.846 0 01-3.25-5.82h0c-.01-.55.03-1.09.12-1.62M20 10.125l.75-.4v-.01a4.77 4.77 0 002.49-4.19l-.01-.01c-.01-1.2-.97-2.17-2.17-2.17l-.01-.001c-.39-.01-.78.08-1.13.25h-.01c-1.06.5-1.5 1.77-.99 2.82.07.15.16.29.26.42M20 19.125v-.01a6.86 6.86 0 003.24-5.82h-.01c0-.55-.04-1.09-.13-1.62"/></g>'
  }
})
