/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alert-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>exclamation warning notification</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 16.5h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.38-.38-.375v0"/><path pid="1" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-width="1.5" d="M12 13.5V5.25"/><path pid="2" _fill="none" _stroke="currentColor" stroke-width="1.5" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/>'
  }
})
