/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'upload-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="currentColor" d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm4.912 10.409A1 1 0 0116 11h-2.25a.25.25 0 00-.25.25v7.25a1.5 1.5 0 01-3 0v-7.25a.25.25 0 00-.25-.25H8a1 1 0 01-.748-1.664l4-4.5a1.032 1.032 0 011.5 0l4 4.5a1 1 0 01.16 1.073z"/>'
  }
})
