/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-add-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>create new plus</desc><g _fill="currentColor"><circle pid="0" cx="15.34" cy="3.34" r=".99"/><circle pid="1" cx="15.34" cy="6.062" r=".99"/><circle pid="2" cx="15.34" cy="8.784" r=".99"/><path pid="3" d="M9.6 15.464H2.227a.248.248 0 01-.248-.248v-1.9a.248.248 0 01.183-.239 4.454 4.454 0 000-8.593.248.248 0 01-.183-.239v-1.9a.248.248 0 01.248-.245h19.3a.247.247 0 01.247.248v1.9a.247.247 0 01-.182.239 4.442 4.442 0 00-3.241 4.89.248.248 0 00.214.212 7.793 7.793 0 011.569.367.247.247 0 00.312-.321 2.411 2.411 0 01-.156-.851 2.476 2.476 0 012.164-2.455 1.487 1.487 0 001.3-1.473V2.1A1.982 1.982 0 0021.773.124H1.979A1.982 1.982 0 000 2.1v2.756a1.487 1.487 0 001.3 1.473 2.474 2.474 0 010 4.909A1.487 1.487 0 000 12.711v2.753a1.982 1.982 0 001.979 1.979h7.427a.247.247 0 00.247-.239 7.923 7.923 0 01.185-1.439.247.247 0 00-.242-.3z"/><path pid="4" d="M17.567 11.01A6.433 6.433 0 1024 17.443a6.441 6.441 0 00-6.433-6.433zm2.474 7.176h-1.484a.247.247 0 00-.248.247v1.485a.742.742 0 01-1.484 0v-1.485a.247.247 0 00-.248-.247h-1.484a.743.743 0 010-1.485h1.484a.248.248 0 00.248-.247v-1.485a.742.742 0 111.484 0v1.485a.248.248 0 00.248.247h1.484a.743.743 0 110 1.485z"/></g>'
  }
})
