<template>
    <div class="flex flex-wrap">
        <form-field-wrapper label="Section Icon">
            <icon-picker
                v-model="form.icon"
                class="text-gray-800 w-11 h-11"
            ></icon-picker>
        </form-field-wrapper>

        <form-field-wrapper
            label="Section Title"
            :error="form.errors.get('title')"
            :should-show-error="form.errors.has('title')"
        >
            <input
                v-model="form.title"
                class="form-field"
            >
        </form-field-wrapper>

        <form-field-wrapper label="Text, Image, Video">
            <p>Customize text by clicking directly into the preview (at-right). Click the (+) button to add images or video.</p>

            <img
                :src="asset('images/gifs/editing-text-block.gif')"
                class="mt-4 border rounded-md"
                alt="How to edit the text block"
            >
        </form-field-wrapper>

        <form-field-wrapper>
            <toggle-switch v-model="form.displayTitle">
                Display Section Icon &amp; Title
            </toggle-switch>

            <toggle-switch
                v-model="form.displayInMenu"
                class="mt-4"
            >
                Show Section In Menu
            </toggle-switch>
        </form-field-wrapper>

        <form-field-wrapper label="Background Color">
            <color-picker v-model="form.backgroundColor"></color-picker>
        </form-field-wrapper>
    </div>
</template>

<script>
import BlockableSettingsMixin from '@/mixins/BlockableSettingsMixin';
import BaseBlockIsSelfEditable from '@/mixins/BaseBlockIsSelfEditable';

export default {
    name: 'WallTextBlockSettings',

    mixins: [BlockableSettingsMixin, BaseBlockIsSelfEditable]
};
</script>
