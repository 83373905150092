/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'technology-computer-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>monitor</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.75 23.25h-7.5l.75-4.5h6l.75 4.5zM6 23.25h12M.75 15.75h22.5"/><rect pid="1" width="22.5" height="18" x=".75" y=".75" rx="3"/></g>'
  }
})
