/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>pencil write</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M22.19 1.81h0a3.636 3.636 0 00-5.15.01c-.01 0-.02.01-.03.02l-14.5 14.5-1.78 6.9 6.9-1.78 14.5-14.5h-.01a3.655 3.655 0 00.06-5.15l-.03-.03zM16.61 2.26l5.13 5.13M2.52 16.34l5.14 5.13"/></g>'
  }
})
