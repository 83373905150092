<template>
    <div class="h-full flex flex-col">
        <form-field-wrapper
            label="Block Title"
            :error="form.errors.get('title')"
            :should-show-error="form.errors.has('title')"
        >
            <input
                v-model="form.title"
                class="form-field"
            >
        </form-field-wrapper>

        <form-field-wrapper
            label="Event description (optional)"
            :error="form.errors.get('description')"
            :should-show-error="form.errors.has('description')"
        >
            <textarea
                v-model="form.description"
                class="form-field"
                rows="4"
            ></textarea>
        </form-field-wrapper>

        <form-field-wrapper class="-mb-4">
            <a
                class="button-text button-info"
                @click="createItem"
            >
                <app-icon
                    name="add-circle"
                    class="h-5 w-5 mr-2"
                    stroke
                ></app-icon>
                <span>Add Tier, Package, or Add-on</span>
            </a>
        </form-field-wrapper>

        <form-field-wrapper data-descriptor="ticket-list">
            <draggable-list
                v-model="form.products"
                confirm-delete
                :editable="true"
                :deletable="true"
                duplicable
                unique-property="id"
                label-property="title"
                switchable-property="active"
                :set-sort-property="true"
                @input="productsUpdated"
                @delete-item="deleteItem"
                @duplicate-item="duplicateItem"
                @edit-item="editItem"
            >
                <template #editButton="{ model, editModel }">
                    <a

                        class="shrink cursor-pointer hover:text-purple leading-none"
                        role="button"
                        data-descriptor="edit-item"
                        :data-price="getItemPrice(model)"
                        @click="editModel(model)"
                    >
                        <app-icon
                            name="settings-cog"
                            class="h-5 w-5"
                            stroke
                        ></app-icon>
                    </a>
                </template>
            </draggable-list>

            <!-- Decline Settings -->
            <div class="flex items-center">
                <div class="flex grow items-center font-semibold p-2 shadow rounded-md text-sm bg-white border my-2 draggable-block text-gray-500">
                    <div class="flex-auto text-gray-600">
                        {{ declineSettings.title }}
                    </div>

                    <div class="shrink mr-2 leading-none">
                        <toggle-switch
                            v-model="declineSettings.active"
                            small
                        ></toggle-switch>
                    </div>

                    <button
                        class="shrink cursor-pointer hover:text-purple leading-none"
                        @click="editItem(declineSettings)"
                    >
                        <app-icon
                            name="settings-cog"
                            class="h-5 w-5"
                            stroke
                        ></app-icon>
                    </button>
                </div>
            </div>
        </form-field-wrapper>

        <div v-if="form.errors.has('products')" class="alert alert-error alert-sm mt-2">
            <p class="w-full font-normal text-center">{{ form.errors.get('products') }}</p>
        </div>

        <form-panel
            v-model="form.maxCapacity.enabled"
            class="mt-8"
            label="Set Event Capacity Limit"
            tooltip="Limit the total number of attendees to your event. "
            :disabled="form.recurringTimeSlots.active"
        >
            <template #trigger-appended>
                <premium-feature feature="LimitedCapacity" class="ml-2"></premium-feature>
            </template>

            <form-field-wrapper
                :error="form.errors.get('maxCapacity.slots')"
                :should-show-error="form.errors.has('maxCapacity.slots')"
            >
                <div class="flex items-end">
                    <input
                        v-model.number="form.maxCapacity.slots"
                        class="form-field w-32 mr-4"
                        type="number"
                        name="number-available"
                        step="1"
                        min="1"
                    >
                    <div class="py-2 bold uppercase tracking-wide">
                        Available
                    </div>
                </div>
            </form-field-wrapper>

            <form-field-wrapper label="When no longer available...">
                <select-list v-model="form.maxCapacity.showMessage">
                    <select-option
                        :id="true"
                        label="Disable and display a message"
                        class="my-2"
                    ></select-option>
                    <select-option
                        :id="false"
                        label="Hide completely"
                        class="my-2"
                    ></select-option>
                </select-list>
            </form-field-wrapper>

            <form-field-wrapper
                v-if="form.maxCapacity.showMessage"
                label="Message to display"
                :error="form.errors.get('maxCapacity.message')"
                :should-show-error="form.errors.has('maxCapacity.message')"
            >
                <tiptap-editor
                    v-model="form.maxCapacity.message"
                    class="form-field"
                    simple
                ></tiptap-editor>
            </form-field-wrapper>
        </form-panel>

        <form-panel
            v-model="form.recurringTimeSlots.active"
            :disabled="!canManageRecurringTimeSlots"
            label="Recurring Time Slots"
        >
            <template #trigger-appended>
                <premium-feature
                    feature="RecurringTimeSlots"
                    :requires-upgrade="!recurringTimeSlotsAllowed"
                    class="ml-2"
                    tooltip="Recurring time slots is an Enterprise feature. Contact support to learn more."
                ></premium-feature>
            </template>
            <button
                v-tippy
                class="flex items-center justify-center w-full py-2 rounded-md border-2 border-purple no-underline text-purple hover:border-purple-light hover:text-purple-light transition duration-150 ease-in-out"
                :class="manageTimeslotsButtonStyles"
                :content="timeslotsTooltip"
                @click="openManageSlotsModal"
            >
                <app-icon class="w-6 h-6 mr-2" name="calendar-clock"></app-icon>
                Manage Time Slots
            </button>

            <div class="mt-4">
                <accordion-list v-cloak container-class="">
                    <accordion-list-item item-id="advanced-recurring-time-slots-settings">
                        <template slot="header">Advanced Settings</template>
                        <template slot="content">
                            <form-field-wrapper
                                label="Allow invitee registration"
                                class="mt-4"
                                :error="form.errors.get('recurringTimeSlots.allowRegistration.type')"
                            >
                                <select-list v-model="form.recurringTimeSlots.allowRegistration.type">
                                    <select-option
                                        id="any"
                                        class="my-2"
                                        label="Any available time slot"
                                    ></select-option>

                                    <select-option
                                        id="limit"
                                        class="my-2"
                                        label="Limit to..."
                                    ></select-option>
                                </select-list>
                            </form-field-wrapper>

                            <div v-if="displayLimitToOptions">
                                <form-field-wrapper class="mt-4" :error="form.errors.getAny(['recurringTimeSlots.allowRegistration.until.value', 'recurringTimeSlots.allowRegistration.until.unit'])">
                                    <div class="flex items-end space-x-2 w-full">
                                        <input
                                            v-model="form.recurringTimeSlots.allowRegistration.until.value"
                                            class="form-field w-14 text-sm"
                                            type="number"
                                            min="0"
                                        >
                                        <simple-picker
                                            v-model="form.recurringTimeSlots.allowRegistration.until.unit"
                                            form-field-class="form-field text-sm"
                                            placeholder-empty-state="Select..."
                                            item-label="name"
                                            item-value="value"
                                            :items="limitToOptions"
                                        ></simple-picker>
                                        <p
                                            v-tippy
                                            class="tooltip-text text-sm text-center"
                                            content="Guests can register for a time slot this far into the future from the current date."
                                        >into the future</p>
                                    </div>
                                </form-field-wrapper>

                                <form-field-wrapper :error="form.errors.getAny(['recurringTimeSlots.allowRegistration.from.value', 'recurringTimeSlots.allowRegistration.from.unit'])">
                                    <div class="flex items-end space-x-2 w-full">
                                        <input
                                            v-model="form.recurringTimeSlots.allowRegistration.from.value"
                                            class="form-field w-14 text-sm"
                                            type="number"
                                            min="0"
                                        >
                                        <simple-picker
                                            v-model="form.recurringTimeSlots.allowRegistration.from.unit"
                                            form-field-class="form-field text-sm"
                                            placeholder-empty-state="Select..."
                                            item-label="name"
                                            item-value="value"
                                            :items="limitToOptions"
                                        ></simple-picker>
                                        <p
                                            v-tippy
                                            class="tooltip-text text-sm text-center"
                                            content="Guests can register for a time slot up to  this amount of time before a time slot."
                                        >ahead of start</p>
                                    </div>
                                </form-field-wrapper>
                            </div>

                            <form-field-wrapper
                                label="Timezone"
                                :error="form.errors.get('recurringTimeSlots.timezoneId')"
                                class="mt-8"
                            >
                                <timezone-picker
                                    v-model="form.recurringTimeSlots.timezoneId"
                                    v-tippy
                                    :content="timezonePickerTooltip"
                                    :disabled="event.hasSoldTimeslots"
                                    guess-timezone
                                ></timezone-picker>
                            </form-field-wrapper>

                            <form-field-wrapper label="Scheduling Format" :error="form.errors.get('recurringTimeSlots.timeFormat')">
                                <select-list v-model="form.recurringTimeSlots.timeFormat">
                                    <select-option
                                        id="12-hours"
                                        class="my-2"
                                        label="12 hour (AM/PM)"
                                    ></select-option>

                                    <select-option
                                        id="24-hours"
                                        class="my-2"
                                        label="24 hour"
                                    ></select-option>
                                </select-list>
                            </form-field-wrapper>

                            <form-field-wrapper>
                                <toggle-switch
                                    v-model="form.recurringTimeSlots.displayDuration"
                                >
                                    <div
                                        v-tippy
                                        content="Display the end time (e.g. 9:00 AM to 10:00 AM) of available time slots. By default, guests will only see the start time."
                                        class="tooltip-text"
                                    >
                                        Display Time Slot Duration
                                    </div>
                                </toggle-switch>
                            </form-field-wrapper>
                        </template>
                    </accordion-list-item>
                </accordion-list>
            </div>
        </form-panel>

        <form-field-wrapper>
            <form-panel
                v-model="form.guestTitles.enabled"
                label="Request Guest Titles/Salutations"
                tooltip="Request Guest Titles/Salutations: Ask guests to select their title or salutation (e.g. Mr., Mrs., Dr., etc.). You can customize the list in Setup > Event Settings > Titles and Salutations."
            >
                <toggle-switch v-model="form.guestTitles.required" class="mt-4 w-full">
                    <div
                        v-tippy
                        content="Guests will be required to select a title/salutation."
                        class="tooltip-text"
                    >
                        Make Required
                    </div>
                </toggle-switch>
            </form-panel>
        </form-field-wrapper>

        <form-panel
            v-model="form.limitedAttendancePerGroup.enabled"
            label="Limit Max Attendees Per Group"
            :tooltip="limitedAttendancePanelTooltip"
            :disabled="!canLimitAttendance"
        >
            <div class="flex items-end">
                <input
                    :value="form.limitedAttendancePerGroup.limit"
                    class="form-field w-32 mr-4"
                    type="number"
                    name="maximum"
                    step="1"
                    min="1"
                    @input="form.limitedAttendancePerGroup.limit = Number($event.target.value)"
                >

                <div class="py-2 bold uppercase tracking-wide">Maximum</div>
            </div>
        </form-panel>

        <div v-if="event.user_id" class="flex-1 flex items-end mt-8">
            <div class="w-full text-center pt-4 border-t">
                <button
                    v-tippy
                    class="button-text font-normal hover:text-teal"
                    content="Switch from ticket tiers to attending, might attend, and can’t make it response options."
                    type="button"
                    @click="switchToRsvpSetup"
                >
                    <app-icon name="rotate-back-stroke"></app-icon>
                    <span class="ml-2">Switch to Simple RSVP</span>
                </button>
            </div>
        </div>

        <manage-time-slots-modal v-model="showManageSlotsModal" :blockable="block.pivot"></manage-time-slots-modal>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import axios from 'axios';
import BlockableGeneralSettingsMixin from '@/mixins/BlockableGeneralSettingsMixin';
import LimitedAttendanceSettingsMixin from '@/mixins/LimitedAttendanceSettingsMixin';

export default {
    mixins: [
        BlockableGeneralSettingsMixin,
        LimitedAttendanceSettingsMixin
    ],

    data () {
        return {
            showManageSlotsModal: false,
            itemsProperty: 'products',
            limitToOptions: [{ name: 'calendar day(s)', value: 'days' }, { name: 'hour(s)', value: 'hours' }],
            originalProducts: cloneDeep(this.block.pivot.products)
        };
    },

    computed: {
        declineSettings () {
            return this.form.declineable;
        },

        displayLimitToOptions () {
            return this.form.recurringTimeSlots.allowRegistration.type === 'limit';
        },

        timezonePickerTooltip () {
            if (!this.event.hasSoldTimeslots) {
                return null;
            }

            return `You’ve begun receiving registrations. To change time zones, delete your existing registration(s) or create a new event.`;
        },

        timeslotsAreCloning () {
            return get(this.event, 'metadata.duplicating.rts', false);
        },

        timeslotsTooltip () {
            if (this.timeslotsAreCloning) {
                return 'You’re currently duplicating this event. Please wait for this process to finish before modifying existing timeslots.';
            }

            if (this.hasUnsavedProductChanges) {
                return 'Please save your changes first before modifying timeslot settings.';
            }

            return null;
        },

        recurringTimeSlotsAllowed () {
            if (this.event.user_id === null) {
                return true;
            }

            return this.event.plan.features.RecurringTimeSlots.allowed;
        },

        canManageRecurringTimeSlots () {
            if (this.form.recurringTimeSlots.active || this.event.user_id === null) {
                return true;
            }

            return this.recurringTimeSlotsAllowed;
        },

        hasUnsavedProductChanges () {
            return !isEqual(this.originalProducts, this.form.products);
        },

        manageTimeslotsButtonStyles () {
            return {
                'opacity-50 cursor-not-allowed': this.hasUnsavedProductChanges || this.timeslotsAreCloning
            };
        }
    },

    watch: {
        'form.recurringTimeSlots.active': function (newVal) {
            if (newVal) {
                this.form.maxCapacity.enabled = false;
            }
        }
    },

    methods: {
        getItemPrice (product) {
            return product?.price;
        },

        openManageSlotsModal () {
            if (this.hasUnsavedProductChanges) {
                return;
            }

            this.showManageSlotsModal = true;
        },

        productsUpdated (newProducts) {
            this.form.products = newProducts;
        },

        async confirmSwitchToRsvpSetup () {
            return App.alert().confirmAsync(
                'You are changing your event\'s setup.',
                'Any ticket tiers that have been created (and their settings) will be removed. This action cannot be undone.',
                {
                    confirmButtonText: 'Switch to Simple RSVP',
                    reverseButtons: true
                }
            );
        },

        async switchToRsvpSetup () {
            try {
                if (!await this.confirmSwitchToRsvpSetup()) {
                    return;
                }

                await axios.patch(this.route('api.events.update', this.event), {
                    setup_type: 'rsvp'
                });

                this.$toasted.global.success({
                    message: 'Event\'s new setup saved.',
                    onComplete: () => {
                        window.location.reload();
                    }
                });
            } catch (error) {
                this.$toasted.error('An error occurred while switching the event\'s setup.');
            }
        }
    }
};
</script>
