/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-symptoms-virus-diarrhea-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.88 3.38a3.5 3.5 0 100 7.01 3.5 3.5 0 100-7.02zM6.3.75h1.17M6.89.75v2.63M10.81 2.13l.83.83M11.23 2.55L9.37 4.41M13.02 6.3v1.17M13.02 6.89h-2.63M11.64 10.81l-.83.83M11.23 11.23L9.37 9.37M7.47 13.02H6.3M6.89 13.02v-2.63M2.96 11.64l-.83-.83M2.55 11.23l1.86-1.86M.75 7.47V6.3M.75 6.89h2.63M2.13 2.96l.83-.83M2.55 2.55l1.86 1.86M18.61 15.136h3.47-.01c.64 0 1.15-.52 1.15-1.16V7.01v0c0-.65-.52-1.16-1.16-1.16H19.73c-.65 0-1.16.51-1.16 1.15z"/><path pid="1" d="M20.509 17.335h-.01c.94-.32 1.58-1.21 1.58-2.2H9.319a6.341 6.341 0 003.47 5.67l-.58 2.43h7.53v-4.8l-.01-.01c-.02-.5.27-.94.73-1.12z"/></g>'
  }
})
