/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email-send-envelope': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>postal</desc><g _fill="currentColor"><path pid="0" d="M4 7H1a1 1 0 000 2h3a1 1 0 000-2zM3 11H1a1 1 0 000 2h2a1 1 0 000-2zM2.247 15H1a1 1 0 000 2h1.247a1 1 0 000-2zM23.934 7.375a.145.145 0 00-.152.029l-8.327 7.308a2.363 2.363 0 01-1.547.6 1.743 1.743 0 01-1.362-.6L6.416 7.4a.123.123 0 00-.143-.029.169.169 0 00-.1.125l-1.43 9A1.247 1.247 0 006 18h14.967a1.8 1.8 0 001.718-1.5L24 7.5a.115.115 0 00-.066-.125z"/><path pid="1" d="M13.461 13.917a.931.931 0 001.322 0l8.275-7.264a.421.421 0 00.142-.383c-.053-.288-.377-.27-.474-.27H7.894a.563.563 0 00-.556.27.355.355 0 00.029.383z"/></g>'
  }
})
