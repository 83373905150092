<template>
    <block-select v-model="selectedItem">
        <block-select-item
            :id="1"
            title="Pick me"
            sample-link-text="Preview this"
            sample-link="https://rsvpify.com"
        >
            I'm your first choice, and I have sample link.
        </block-select-item>
        <block-select-item
            :id="2"
            title="Pick me, why not?"
            tooltip="This is tooltip."
        >
            I have tooltip, but I don't have simple link, so you should just probably pick me.
        </block-select-item>
        <block-select-item
            :id="3"
            title="Choose me"
        >
            I don't have tooltip, I don't have sample link.
        </block-select-item>
        <block-select-item
            :id="4"
            title="Have it all"
            tooltip="This is tooltip."
            sample-link-text="Sample Text"
            sample-link="https://rsvpify.com"
        >
            I have tooltip, I have simple link, I have it all.
        </block-select-item>
        <block-select-item
            :id="5"
            title="Empty one"
        >
        </block-select-item>
    </block-select>
</template>

<script>
export default {
    name: 'BlockSelectExamples',

    data () {
        return {
            selectedItem: null
        };
    }
};
</script>
