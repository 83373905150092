export default {
    computed: {
        registrationRsvpLabels () {
            const isEventRsvp = this.event.setup_type === 'rsvp';
            const registrationLabel = isEventRsvp ? 'RSVP' : 'registration';
            const registrationLabelUppercase = isEventRsvp ? 'RSVP' : 'Registration';
            const registerLabel = isEventRsvp ? 'RSVP' : 'register';

            return {
                closeRegistrationsNow: `Close ${registrationLabelUppercase}s Now`,
                registrationOpen: `${registrationLabelUppercase} Open`,
                registrationOpensIn: `${registrationLabelUppercase} opens in`,
                registrationClosesIn: `${registrationLabelUppercase} closes in`,
                setRegistrationDeadline: `Set ${registrationLabel} deadline`,
                registrationLabel,
                registrationLabelUppercase,
                registerLabel
            };
        }
    }
};
