/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-pay-cash-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M22.125 23.25l-3-4.5V13.5c0-2-3.8-4.14-5.25-5.25M15.8 14.79l-3.65-2.93h0c-.51-.51-1.34-.51-1.85-.01s-.51 1.33-.01 1.84c0 0 0 0 0 0l3.53 3.98v2.5c0 1.18 1.68 3.04 1.68 3.04"/><path pid="1" d="M13.86 20.424v-.01c-.09.75-.73 1.32-1.49 1.32H3.36a1.51 1.51 0 01-1.5-1.5s0 0 0 0v-18h0A1.499 1.499 0 013.35.72h9-.01c.82-.01 1.5.67 1.5 1.5v11"/><path pid="2" d="M7.875 14.25l-.01-.001a3.01 3.01 0 01-3-3.01c0-1.66 1.34-3 3-3 .85 0 1.66.36 2.23.99M4.5 3h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38v-.001M11.25 18.75h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38"/></g>'
  }
})
