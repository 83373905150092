/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'navigation-menu-horizontal-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" _fill="none" _stroke="currentColor" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"/>'
  }
})
