/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right-thick-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M.75 12.394h0c0 1.24 1 2.24 2.25 2.24h12.56l-3.06 3.05h-.01c-.88.87-.88 2.3 0 3.18.87.87 2.3.87 3.18-.01v-.01l6.89-6.895v-.01c.87-.88.87-2.31 0-3.19l-.01-.01-6.9-6.9h0c-.88-.88-2.31-.88-3.19-.01-.88.87-.88 2.3-.01 3.18 0 0 0 0 0 0l3.05 3.05H2.91c-1.25 0-2.25 1-2.25 2.25 0 0 0 0 0 0z"/>'
  }
})
