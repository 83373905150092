<template>
    <div class="max-w-lg flex flex-col leading-2">
        <p class="mb-6 text-xl font-bold text-gray-800">Please verify you are human.</p>

        <vue-recaptcha
            :sitekey="siteKey"
            @verify="submitRecaptcha"
        ></vue-recaptcha>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import axios from '@/util/axios';

export default {
    components: {
        VueRecaptcha
    },

    props: {
        event: {
            type: Object,
            required: true
        },

        redirectUrl: {
            type: String,
            required: true
        },

        siteKey: {
            type: String,
            required: true
        }
    },

    methods: {
        submitRecaptcha (captchaCode) {
            axios.post(this.route('api.waiting-rooms.recaptcha', {
                event: this.event,
                securityToken: new URLSearchParams(window.location.search).get('securityToken')
            }), {
                'g-recaptcha-response': captchaCode
            }).then(() => {
                window.location.href = this.redirectUrl;
            });
        }
    }
};
</script>
