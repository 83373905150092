/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'religion-christianity-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M23.13 4.955c-1.16 6.82-6.442 11.97-12.79 11.97h0a12.29 12.29 0 01-9.31-4.45l-.01-.01a.734.734 0 010-.98v-.01c2.3-2.78 5.7-4.4 9.3-4.45 6.34 0 11.63 5.15 12.79 11.977"/>'
  }
})
