/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left-chevron': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="currentColor" d="M16.793.24L4.32 11.22h-.01c-.43.4-.44 1.08-.04 1.5l.03.03 12.47 10.97-.01-.01c.4.36 1.03.32 1.39-.09 0-.01.01-.02.02-.03l1.55-1.85v-.01c.36-.43.31-1.07-.12-1.44l-9.46-8.17-.01-.01a.255.255 0 01-.03-.36c0-.01.01-.02.02-.03l9.45-8.168h-.01a1 1 0 00.34-.7h-.01c.02-.27-.07-.54-.24-.75L18.09.25a1 1 0 00-.69-.36.919.919 0 00-.73.24z"/>'
  }
})
