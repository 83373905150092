/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-hand-wipe-paper-2-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.5 23.25l1.5-.44v-.01c.18-.06.37-.07.56-.03l1.56.29-.01-.01c.56.1 1.14.07 1.7-.09l9.23-2.66h-.01c.64-.21 1-.9.8-1.55-.2-.63-.86-.99-1.49-.83l1.77-.52v-.01c.65-.18 1.05-.85.88-1.51-.18-.66-.85-1.06-1.51-.89-.03 0-.05.01-.07.01l.593-.18v-.01c.65-.18 1.05-.85.87-1.51-.18-.66-.85-1.06-1.51-.88-.02 0-.04 0-.06.01l-1.19.34h-.01c.64-.21 1-.9.8-1.55-.2-.63-.86-.99-1.49-.83l-4.15 1.19h-.01a1.24 1.24 0 00-.69-2.38h-.01l-4.62 1.32h-.01a3.672 3.672 0 00-2.5 2.43l-.57 1.72v-.01c-.13.39-.44.69-.84.81l-1.737.5M13.59 12.22l1.78-.51M17.92 16.11l3.56-1.02M17.24 13.74l2.96-.85M18.01 18.65l2.37-.68"/><path pid="1" d="M22.61 8.092l.33-1.34-.5-2.5-2.5.5-.5-2.5-2.5.5-1.5-2c-1 2-2.44 3.09-6.78 3.865-4.28.75-6.77 2.31-7.73 4.63l2.5.5-.5 2.5 1.5.3"/></g>'
  }
})
