<template>
    <app-modal
        :value="value"
        :title="modalTitle"
        @input="$emit('input', $event)"
        @closed="$emit('modal-closed')"
    >
        <form-field-wrapper
            label="Email Address"
            :should-show-error="validationErrors.has('user.email')"
            :error="validationErrors.get('user.email')"
        >
            <input
                v-model="user.email"
                class="form-field"
                name="email-address"
                placeholder="user@domain.com"
                :disabled="isEditing"
                @blur="$emit('email-set', $event.target.value)"
            >
        </form-field-wrapper>

        <template v-if="events.length > 0">
            <data-table
                container-class="block overflow-auto w-full my-4 mt-4 overflow-x-auto"
                :columns="columns"
                :rows="filteredPermissions"
                no-results-placeholder="No events found matching your criteria."
                row-primary-key="event_id"
            >
                <template #header>
                    <div class="flex-auto">
                        <search-field
                            v-model="searchTerm"
                            class="w-full md:w-80"
                            placeholder="Find an event..."
                        ></search-field>
                    </div>
                </template>

                <template #column="{ column }">
                    <template v-if="column.label === 'Permissions'">
                        <span
                            v-tippy
                            class="flex items-center"
                            :content="permissionTooltip"
                        >
                            Permissions
                            <app-icon class="w-4 h-4 ml-2" name="info-circle-stroke"></app-icon>
                        </span>
                    </template>
                    <template v-else>
                        {{ column.label }}
                    </template>
                </template>

                <template #row="{ row, property, value }">
                    <div v-if="property === 'access_type'">
                        <simple-picker
                            v-model="row.access_type"
                            :items="permissionAccessTypes"
                            item-label="label"
                            item-value="value"
                            placeholder-empty-state="No Access"
                            class="w-64"
                        ></simple-picker>
                    </div>
                    <div v-else>
                        {{ value }}
                    </div>
                </template>
            </data-table>
        </template>

        <div v-else class="text-center p-16">
            <app-icon name="calendar" class="h-16 w-16 text-gray-500"></app-icon>

            <p class="text-gray-500 text-xl tracking-wide m-2">You don't have any events in your calendar at the moment ...</p>

            <a
                class="flex justify-center items-center flex-auto font-bold hover:cursor-pointer p-4 text-purple hover:text-purple-lighter no-underline text-sm m-2"
                :href="route('events.create')"
                target="_blank"
            >
                <app-icon name="add-circle" class="w-6 h-6 mr-4"></app-icon>

                Create an Event
            </a>
        </div>

        <template #footer="{ close }">
            <stateful-button
                class="button button-primary w-full disabled:opacity-50 disabled:cursor-not-allowed"
                :disabled="!isFormValid"
                :loading="saving"
                @click="saveUser"
            >
                Save
            </stateful-button>

            <button class="button" @click="close">
                Cancel
            </button>
        </template>

        <template #footer-split-options>
            <button
                v-if="isEditing"
                class="button-text button-primary"
                @click="deleteUser(user)"
            >
                <app-icon
                    name="trash"
                    class="mr-2 w-6 h-6 shrink-0"
                    stroke
                ></app-icon>
                Delete User
            </button>
        </template>
    </app-modal>
</template>

<script>
import find from 'lodash/find';

export default {
    name: 'SharedEventUserDetails',

    props: {
        validationErrors: {
            type: Object,
            required: true
        },

        events: {
            type: Array,
            required: true
        },

        saving: {
            type: Boolean,
            default: false
        },

        user: {
            type: Object,
            default: () => { return {}; }
        },

        value: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            columns: [
                {
                    label: 'Event', property: 'eventName', desktop: true, mobile: true, minWidth: '150px'
                },
                {
                    label: 'Permissions', property: 'access_type', desktop: true, mobile: true, minWidth: '150px', maxWidth: '200px'
                }
            ],
            permissions: [],
            permissionAccessTypes: [
                {
                    label: 'No Access',
                    value: null
                },
                {
                    label: 'Read-Only + Check-In',
                    value: 'read-only'
                },
                {
                    label: 'Administrator',
                    value: 'admin'

                }

            ],
            searchTerm: ''
        };
    },

    computed: {
        isEditing () {
            return !!this.user.id;
        },

        modalTitle () {
            return this.isEditing ? 'Edit User Permissions' : 'Add Collaborator or Event Staff';
        },

        hasAtLeastOneAccessTypeAssigned () {
            return this.permissions.filter((permission) => {
                return !!permission.access_type;
            }).length > 0;
        },

        isFormValid () {
            return !Object.keys(this.fields).some((key) => {
                return this.fields[key].invalid;
            }) && this.hasAtLeastOneAccessTypeAssigned;
        },

        filteredPermissions () {
            return this.permissions.filter((permission) => {
                const searchRegex = new RegExp(this.searchTerm, 'i');

                return searchRegex.test(permission.eventName);
            });
        },

        permissionTooltip () {
            return `<p class="mb-4">Read Only + Check-in: Can view dashboard and data, check-in guests, tag and untag guests, and add notes.</p><p>Administrator: Full access to setup and administer your event.</p>`;
        }
    },

    watch: {
        value (newValue) {
            this.permissions = [];

            if (newValue && this.user) {
                this.loadUserPermissions();
            }
        },

        user () {
            if (this.isEditing) {
                this.permissions = [];
                this.loadUserPermissions();
            }
        }
    },

    methods: {
        loadUserPermissions () {
            this.permissions = this.events.map((event) => {
                const existingSharedEvent = (this.user && this.user.sharedEvents)
                    ? find(this.user.sharedEvents, { id: event.id }) || {}
                    : {};

                return {
                    ...(existingSharedEvent.permission),
                    eventName: event.name,
                    event_id: event.id
                };
            });
        },

        saveUser () {
            // Remove events that haven't been shared (no access type has been set)
            const permissionData = this.permissions.filter((permission) => {
                return !!permission.access_type;
            });

            this.$emit('save-user', {
                user: this.user,
                permissions: permissionData
            });
        },

        deleteUser (user) {
            this.$emit('delete-user', user);
        }
    }
};
</script>
