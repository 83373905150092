/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sort ascending</desc><g _fill="currentColor"><path pid="0" d="M24 12c0-6.63-5.38-12-12-12C5.37 0 0 5.37 0 12c0 6.62 5.37 12 12 12h-.01c6.62-.01 11.99-5.38 12-12zm-2 0c0 5.52-4.48 10-10 10-5.53 0-10-4.48-10-10C2 6.47 6.47 2 12 2c5.52 0 9.99 4.47 9.99 10z"/><path pid="1" d="M15.615 11.88l-1.94-1.939c-.1-.1-.26-.1-.36-.01-.05.04-.08.11-.08.17v7.87a1.25 1.25 0 01-2.5 0v-7.88a.263.263 0 00-.26-.25c-.07 0-.13.02-.18.07L8.35 11.85v-.01c-.49.48-1.28.48-1.77 0-.49-.49-.49-1.28-.01-1.77l4.5-4.5v-.01c.48-.49 1.27-.49 1.76-.01l4.5 4.5c.48.48.48 1.27-.01 1.76-.49.48-1.28.48-1.77-.01z"/></g>'
  }
})
