<template>
    <form class="flex flex-col space-y-8 w-full max-w-2xl mx-auto" @submit.prevent="validateAndComplete">
        <button
            id="button_skip"
            class="text-purple text-xs font-bold"
            type="button"
            @click="clearAndSkip"
        >
            Skip for now
        </button>

        <location-type-picker v-if="allowVirtualLocations" v-model="locationType"></location-type-picker>

        <form-field-wrapper
            v-if="locationType !== 'virtual'"
            id="field_address"
            :error="validationForm?.errors.getAny(['location.address', 'location.venue_name'])"
        >
            <google-autocomplete-input
                id="input_address"
                v-model="venueWithAddress"
                class="form-field"
                placeholder="Venue name or address"
            ></google-autocomplete-input>
        </form-field-wrapper>

        <form-field-wrapper
            v-if="locationType !== 'in-person'"
            id="field_virtual_url"
            :error="validationForm?.errors.get('location.virtual_url')"
        >
            <input
                id="input_virtual_url"
                v-model="virtualUrl"
                class="form-field"
                placeholder="Paste virtual meeting link"
                type="text"
            >
        </form-field-wrapper>

        <stateful-button
            id="button_continue"
            class="button-primary"
            :loading="validationForm?.processing"
            type="button"
            @click="validateAndComplete"
        >
            Next: Event URL
        </stateful-button>
    </form>
</template>

<script>
import { sync } from 'vuex-pathify';
import Form from '@/validation/Form';
import LocationTypePicker from '@/components/create-event/LocationTypePicker.vue';

export default {
    name: 'EventDetailsLocationStep',

    components: {
        LocationTypePicker
    },

    props: {
        allowVirtualLocations: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            validationForm: null
        };
    },

    computed: {
        locationType: sync('CreateEvent/location@type'),
        address: sync('CreateEvent/location@address'),
        venueName: sync('CreateEvent/location@venue_name'),
        virtualUrl: sync('CreateEvent/location@virtual_url'),

        venueWithAddress: {
            get () {
                return {
                    address: this.address,
                    venue: this.venueName
                };
            },
            set ({ address, venue }) {
                this.address = address;
                this.venueName = venue;
            }
        }
    },

    created () {
        this.locationType = 'in-person';
    },

    methods: {
        clearAndSkip () {
            this.$store.commit('CreateEvent/resetLocation');
            this.$emit('completed');
        },

        async validateAndComplete () {
            try {
                this.validationForm = new Form({
                    location: this.$store.copy('CreateEvent/location')
                });

                await this.validationForm.validate(this.route('api.events.validate-store'));

                this.$emit('completed');
            } catch (error) {
                if (error.response?.status !== 422) {
                    throw error;
                }
            }
        }
    }
};
</script>
