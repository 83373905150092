<template>
    <span>
        <slot v-bind="{copyPublishedUrl}"></slot>
    </span>
</template>

<script>
import { get } from 'vuex-pathify';
import CompletesTasks from '@/mixins/CompletesTasks';

export default {
    name: 'CopyPublishedUrl',

    mixins: [CompletesTasks],

    computed: {
        event: get('Event/event')
    }
};
</script>
