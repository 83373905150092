/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email-search-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>envelope postal find</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.75 10.5a5.25 5.25 0 100 10.5 5.25 5.25 0 100-10.5zM23.25 23.25l-3.79-3.79M9.75 21.75H2.24a1.51 1.51 0 01-1.5-1.5s0 0 0 0V9l7.5 5.948M21.75 9l-8.307-7.3-.01-.01a3.005 3.005 0 00-4.25-.15c-.06.04-.1.09-.15.14L.72 8.98M7.44 14.31L4.5 17.25"/></g>'
  }
})
