/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-concert-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.189 20.25h-.01a1.51 1.51 0 01-1.5-1.5c0-.83.67-1.5 1.5-1.5V15v0c0-.42-.34-.75-.75-.75h-10.51c-.42 0-.75.33-.75.75 0 0 0 0 0 0v2.25h-.01c.82-.01 1.5.67 1.5 1.5 0 .82-.68 1.5-1.5 1.5v2.25h0c0 .41.33.74.75.74h10.5-.01c.41 0 .75-.34.75-.75zM17.939 11.25v-6 0c0-.83-.68-1.5-1.5-1.5v0h-.01c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0V9M8.939 9V2.25v0c0-.83-.68-1.5-1.5-1.5v0h-.01c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0V13.5l-2.44-1.96h0c-.67-.54-1.64-.43-2.17.24a.7.7 0 00-.08.1v0-.01c-.35.51-.35 1.18-.01 1.7l3.65 5.474.3.42v0h0c.76.95 1.8 1.65 2.97 1.99"/><path pid="1" d="M11.939 11.25V9v0c0-.83-.68-1.5-1.5-1.5v0h-.01c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v2.25"/><path pid="2" d="M14.939 11.25V9v0c0-.83-.68-1.5-1.5-1.5v0h-.01c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v2.25"/></g>'
  }
})
