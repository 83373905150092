/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'food-pizza-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.625 13.5l.63-9.073v-.01a.646.646 0 00-.6-.68c-.02-.01-.03-.01-.05-.01l-.01-.001c-5.4-.02-9.79 4.35-9.8 9.75-.02 5.39 4.35 9.78 9.75 9.79 5.39.01 9.78-4.36 9.79-9.76 0-1.19-.22-2.37-.63-3.48h0a.762.762 0 00-.98-.44c-.02 0-.04.01-.06.02z"/><path pid="1" d="M14.625 9l7.5-3.75-.01-.01a7.217 7.217 0 00-6-4.5z"/><path pid="2" d="M20.022 6.3h0c-1-1.56-2.56-2.66-4.35-3.08M17.726 10.61l-.01-.01c.41.9.64 1.89.64 2.89v-.01c0 3.72-3.03 6.75-6.75 6.75-3.73 0-6.75-3.03-6.75-6.75a6.747 6.747 0 017.12-6.74h.09"/><path pid="3" d="M9 11.251h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38l-.01-.01c-.01-.21.16-.38.37-.38 0-.01 0-.01 0 0M9 15h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38l-.01-.01c-.01-.21.16-.38.37-.38 0-.01 0-.01 0 0M14.25 15.751l-.01-.001c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38c0 0 0 0 0 0l-.01-.01c-.01-.21.16-.38.37-.38 0-.01 0-.01 0 0"/></g>'
  }
})
