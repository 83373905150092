<template>
    <slidable-cards v-cloak class="-mx-4 border-b mb-4">
        <slidable-card content-class="">
            <span slot="footer" class="uppercase tracking-wide">Attending</span>
            <template slot="icon">
                <div class="flex items-center p-3 bg-green-light rounded-full">
                    <app-icon
                        name="check-square"
                        class="w-5 h-5 text-green-dark"
                        stroke
                    ></app-icon>
                </div>
            </template>

            {{ attendingCount | number }}
        </slidable-card>

        <slidable-card content-class="">
            <span slot="footer" class="uppercase tracking-wide whitespace-nowrap">Not Attending</span>
            <template slot="icon">
                <div class="flex items-center p-3 bg-red-light rounded-full">
                    <app-icon
                        name="close-square"
                        class="w-5 h-5 text-red"
                        stroke
                    ></app-icon>
                </div>
            </template>

            {{ notAttendingCount | number }}
        </slidable-card>

        <slidable-card v-if="totalSalesCardVisible" content-class="">
            <span slot="footer" class="uppercase tracking-wide">Total Sales</span>
            <template slot="icon">
                <div class="flex items-center p-3 bg-purple-50 rounded-full">
                    <app-icon
                        name="money-bag-dollar"
                        class="w-5 h-5 text-purple"
                        stroke
                    ></app-icon>
                </div>
            </template>

            <money-field :value="orderTotal" static></money-field>
        </slidable-card>

        <slidable-card v-if="donationCardVisible" content-class="">
            <span slot="footer" class="uppercase tracking-wide">Donations</span>
            <template slot="icon">
                <div class="flex items-center p-3 bg-teal-light rounded-full">
                    <app-icon
                        name="gift"
                        class="w-5 h-5 text-teal"
                        stroke
                    ></app-icon>
                </div>
            </template>

            <money-field :value="donationTotal" static></money-field>
        </slidable-card>
    </slidable-cards>
</template>

<script>
export default {
    name: 'PartyDetailsGuestStats',

    props: {
        attendingCount: {
            type: Number,
            default: null
        },

        notAttendingCount: {
            type: Number,
            default: null
        },

        orderTotal: {
            type: Number,
            default: null
        },

        donationCardVisible: {
            type: Boolean,
            default: null
        },

        donationTotal: {
            type: Number,
            default: null
        },

        totalSalesCardVisible: {
            type: Boolean,
            default: null
        }
    }
};
</script>
