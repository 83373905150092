<template>
    <div>
        <div v-theme="['wall.text', 'wall.background']" class="relative p-6 leading-normal">
            <div
                v-if="displayTitle"
                v-theme="['wall.title-color', 'wall.title-font', 'wall.title-font-size']"
                class="flex justify-center items-center flex-auto mb-6 font-bold leading-normal"
            >
                <app-icon
                    v-if="icon"
                    :name="icon"
                    class="h-8 w-8 mr-4"
                ></app-icon>

                {{ title }}
            </div>

            <div v-if="showIntroText" class="my-4">
                <tiptap-editor
                    v-model="textHtml"
                    v-theme="'wall.text'"
                    :read-only="!isEditMode"
                    show-embed-video-button
                    class="px-8"
                ></tiptap-editor>
            </div>

            <div class="flex row justify-center">
                <div
                    v-for="sponsor in activeSponsors"
                    :key="sponsor.id"
                    class="col-4 mb-6 justify-center"
                >
                    <a
                        v-theme="'wall.hoverable-accent-border'"
                        :href="normalizeUrl(sponsor.url)"
                        :target="sponsor.url ? '_blank' : '_self'"
                        class="flex items-center justify-center h-full w-40 border hover:shadow-md rounded-md"
                        rel="nofollow"
                    >
                        <img
                            v-if="sponsor.images[0]"
                            class="w-full object-cover rounded-md p-1"
                            :src="sponsor.images[0].src"
                        >

                        <div
                            v-else
                            v-theme="'wall.text'"
                            class="flex text-center break-anywhere min-h-xs items-center font-semibold text-lg"
                        >
                            {{ sponsor.name }}
                        </div>
                    </a>
                </div>
            </div>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import WallBlock from '@/mixins/WallBlock';
import SelfEditableBlockMixin from '@/mixins/SelfEditableBlockMixin';
import NormalizesUrls from '@/mixins/NormalizesUrls';

export default {
    name: 'WallSponsorsBlock',

    mixins: [
        SelfEditableBlockMixin,
        WallBlock,
        NormalizesUrls
    ],

    props: {
        displayTitle: {
            type: Boolean,
            required: true
        },

        icon: {
            type: String,
            default: null
        },

        sponsors: {
            type: Array,
            required: true
        },

        text: {
            type: String,
            required: true
        },

        title: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            textHtml: this.text,
            syncSelfEditableSettings: {
                textHtml: 'text'
            }
        };
    },

    computed: {
        ...get('Wall/*'),

        activeSponsors () {
            return this.sponsors.filter((sponsor) => { return sponsor.active; });
        },

        showIntroText () {
            return this.sponsors.length > 0;
        }
    }
};
</script>
