/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>ok yes correct done task</desc><g _fill="currentColor"><path pid="0" d="M18.48 6.449a1.249 1.249 0 00-1.747.265l-5.924 8.04-3.767-3.014a1.251 1.251 0 00-1.563 1.953l4.783 3.826a1.263 1.263 0 001.787-.235l6.7-9.087a1.25 1.25 0 00-.269-1.748z"/><path pid="1" d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z"/></g>'
  }
})
