<template>
    <button-picker
        :options="[
            { id: 'no', label: singleButtonText },
            { id: 'yes', label: multipleButtonText },
            { id: 'rts', label: rtsButtonText }
        ]"
        class="w-96 mx-auto"
        @input="complete"
    ></button-picker>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
    name: 'EventDetailsMultipleDatesStep',

    props: {
        singleButtonText: {
            type: String,
            required: true
        },
        multipleButtonText: {
            type: String,
            required: true
        },
        rtsButtonText: {
            type: String,
            required: true
        }
    },

    computed: {
        multipleDates: sync('CreateEvent/multiple_dates')
    },

    methods: {
        complete (selectedOptionId) {
            this.multipleDates = selectedOptionId;
            this.$emit('completed');
        }
    }
};
</script>
