/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location-parking-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9.75 17.25V6.75M9.75 6.75h1.8c1.73 0 3.15 1.41 3.14 3.15a3.16 3.16 0 01-3.15 3.14h-1.8"/><path pid="1" d="M12 .75a11.25 11.25 0 100 22.5 11.25 11.25 0 100-22.5z"/></g>'
  }
})
