/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-cough-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M9.069 23v-2.49h1.72l-.01-.001c.45 0 .82-.38.82-.83v-1.79h.95l-.01-.001c.35 0 .63-.29.63-.64 0-.1-.02-.19-.06-.27-.625-1.4-1.54-3.1-1.54-3.1v-1.37c0-.19-.04-.37-.12-.53h0a4.576 4.576 0 00-3.11-2.82h0a5.96 5.96 0 00-7.39 4.08c-.14.45-.21.91-.23 1.38h-.01c-.02 1.64.68 3.21 1.9 4.31v4M16.95 3.03a2.71 2.71 0 100 5.42 2.71 2.71 0 100-5.43zM16.51 1h.9M16.96 1v2.04M20 2.07l.64.64M20.32 2.39l-1.44 1.44M21.71 5.3v.9M21.71 5.75h-2.04M20.64 8.79l-.64.64M20.32 9.11l-1.44-1.44M17.41 10.5h-.9M16.96 10.5V8.46M13.92 9.43l-.64-.64M13.6 9.11l1.44-1.44M12.21 6.2v-.9M12.21 5.75h2.03M13.28 2.71l.64-.64M13.6 2.39l1.44 1.44M16.209 15l3.5-2v2l3.5-2M16.209 19l3.92.88-.85 1.23 3.92.88"/></g>'
  }
})
