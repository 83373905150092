/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-transmission-virus-handshake-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.25 2.97a2.85 2.85 0 100 5.71 2.85 2.85 0 100-5.72zM17.77.83h.96M18.25.83v2.14M21.45 1.96l.67.67M21.79 2.29l-1.52 1.52M23.25 5.35v.95M23.25 5.83h-2.14M22.12 9.03l-.67.67M21.79 9.36l-1.52-1.51M18.73 10.83h-.96M18.25 10.83V8.69M15.05 9.7l-.67-.67M14.71 9.36l1.52-1.51M13.25 6.3v-.95M13.25 5.83h2.14M14.38 2.63l.67-.67M14.71 2.29l1.52 1.52M16.23 19.96l-4.27.85M12.38 15.971l-2.02.85h-.01c-.33.13-.69.12-1-.02l-.01-.01c-.33-.15-.58-.42-.7-.75h0c-.25-.65.02-1.37.63-1.7l2.01-1.01v-.01c.44-.23.95-.26 1.42-.09l4.13 1.63"/><path pid="1" d="M4.14 20.848h1.42l2.71 2.07-.01-.01c.26.29.71.33 1.02.07l3.8-3.14v-.01c.31-.26.36-.72.11-1.04l-2.1-2.33M9.085 14.516l-.22-.19h0c-.45-.33-1.03-.41-1.54-.2l-3.19 1.35"/><path pid="2" d="M.75 21.51h2.372c.55.02 1.03-.4 1.07-.96v-4.78h0a1.03 1.03 0 00-1.07-.96H.742M19.675 21.51H17.3v-.01c-.56.02-1.04-.4-1.07-.96v-4.78h-.01c.03-.56.51-.99 1.06-.96h2.372M10.28 5.83h-9M4.27 2.828l-3 3 3 3"/></g>'
  }
})
