/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-pants-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>slacks trousers</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.25.75h15.5-.01c.27-.01.5.22.5.49v2.5H3.74v-2.5h0c-.01-.28.22-.51.49-.51zM20.25 3.75H3.75L2.31 22.44H2.3c-.04.41.27.77.68.8H8.3v-.001c.38-.01.69-.29.74-.67l1.24-10.6h-.01a1.68 1.68 0 013.34-.01l1.24 10.6h0c.04.37.36.66.74.66h5.27c.41 0 .75-.34.75-.75 0-.02-.01-.04-.01-.06z"/><path pid="1" d="M3.348 8.97l-.01-.01c.13.01.26.03.39.03 2.07-.001 3.75-2.36 3.75-5.25H3.72zM20.652 8.97v-.01c-.14.01-.27.03-.4.03-2.08-.001-3.76-2.36-3.76-5.25h3.75zM11.25 3.75v3h0c0 .41.33.74.75.74h.75"/></g>'
  }
})
