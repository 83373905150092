<template>
    <div>
        <slot v-bind="{ setRegistrationDeadline }"></slot>

        <app-modal v-model="isModalVisible" :title="registrationRsvpLabels.setRegistrationDeadline">
            <p class="mb-4">Deadline:</p>

            <form-field-wrapper :error="registrationDeadlineForm.errors.get('closed_at')" class="-mb-6">
                <date-time-picker v-model="registrationDeadlineForm.closed_at"></date-time-picker>
            </form-field-wrapper>
            <form-field-wrapper :error="registrationDeadlineForm.errors.get('closed_at_timezone_id')">
                <timezone-picker v-model="registrationDeadlineForm.closed_at_timezone_id" guess-timezone></timezone-picker>
            </form-field-wrapper>

            <p class="my-4">Customize message to display once closed:</p>
            <div v-theme="['form.text', 'form.background']" class="p-4 rounded-md advanced-editor group">
                <tiptap-editor
                    v-model="registrationDeadlineForm.closedMessage"
                    hint-classes="-m-2"
                    with-hint
                ></tiptap-editor>
            </div>

            <template #footer="{ close }">
                <stateful-button
                    class="button-primary ml-4"
                    :loading="registrationDeadlineForm.processing"
                    @click="updateRegistrationDeadline"
                >
                    {{ scheduleButtonText }}
                </stateful-button>

                <button class="button" @click="close">Cancel</button>
            </template>

            <template v-if="showRemoveDeadlineOption" #footer-split-options>
                <button class="text-purple hover:text-purple-light" @click="removeDeadline">Remove Deadline</button>
            </template>
        </app-modal>

        <published-event-info-modal v-model="showPublishedEventInfoModal"></published-event-info-modal>

        <requires-upgrade-modal v-model="isUpgradeModalVisible" :used-features="usedFeaturesList"></requires-upgrade-modal>
        <comp-requires-action v-model="isCompRequiresActionModal" @addPaidProduct="openPrimaryBlock"></comp-requires-action>
        <event-requires-stripe-action v-model="isEventRequiresStripeActionModal" @removePaidProduct="openPrimaryBlock"></event-requires-stripe-action>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { get as getValue, merge } from 'lodash';
import Form from '@/validation/Form';
import RegistrationRSVPLabels from '@/mixins/RegistrationRSVPLabels';
import CheckEventPublishabilityInfo from '@/mixins/CheckEventPublishabilityInfo';
import FormOpensAtClosesAtFormattedDateTime from '@/mixins/FormOpensAtClosesAtFormattedDateTime';

export default {
    name: 'SetRegistrationDeadlineAction',

    mixins: [CheckEventPublishabilityInfo, FormOpensAtClosesAtFormattedDateTime, RegistrationRSVPLabels],

    data () {
        return {
            isModalVisible: false,
            showPublishedEventInfoModal: false,
            registrationDeadlineForm: new Form({
                closed_at: null,
                closed_at_timezone_id: null,
                closedMessage: null
            })
        };
    },

    computed: {
        event: get('Event/event'),
        form: get('Form/form'),
        wall: get('Wall/wall'),

        scheduleButtonText () {
            return this.form.closed_at ? 'Update' : 'Schedule Deadline';
        },

        showRemoveDeadlineOption () {
            return this.form.closed_at && !this.form.isClosed;
        }
    },

    watch: {
        'registrationDeadlineForm.processing': function (newVal) {
            this.$emit('action-in-progress', newVal);
        },

        isModalVisible () {
            this.loadDataToForm();
        }
    },

    mounted () {
        this.loadDataToForm();
    },

    methods: {
        loadDataToForm () {
            this.registrationDeadlineForm = new Form({
                closed_at: this.form.closed_at,
                closed_at_timezone_id: this.form.closed_at_timezone_id ? this.form.closed_at_timezone_id : this.event.timezone_id,
                closedMessage: getValue(this.form.settings, 'closedMessage', 'Sorry, we\'re no longer accepting registrations!')
            });
        },

        async setRegistrationDeadline () {
            const result = await this.getPublishabilityInfo();

            if (!result.publishable) {
                this.showBlockerModal(result);
                return;
            }

            this.isModalVisible = true;
        },

        updateRegistrationDeadline () {
            this.registrationDeadlineForm.post(this.route('api.events.schedule-form-registrations-to-close', this.event)).then(({ data }) => {
                this.isModalVisible = false;

                this.$nextTick(() => {
                    merge(this.event, data);
                    merge(this.form, data.form);
                    merge(this.wall, data.wall);

                    this.showPublishedEventInfoModal = true;

                    const successMessage = this.registrationClosesIn
                        ? `${this.registrationRsvpLabels.registrationLabelUppercase} scheduled to close in ${this.registrationClosesIn}.`
                        : `Your ${this.registrationRsvpLabels.registrationLabel} will close immediately.`;

                    this.$toasted.global.success(successMessage);
                });
            }).catch(() => {
                this.$toasted.global.error(`There was a problem with setting ${this.registrationRsvpLabels.registrationLabel} deadline for this event.`);
            });
        },

        removeDeadline () {
            const closeFormData = new Form({
                closedMessage: getValue(this.form.settings, 'closedMessage', 'Sorry, we\'re no longer accepting registrations or RSVPs!'),
                closed_at: null,
                closed_at_timezone_id: null
            });

            closeFormData.post(this.route('api.events.schedule-form-registrations-to-close', this.event)).then(({ data }) => {
                this.isModalVisible = false;

                this.$nextTick(() => {
                    merge(this.event, data);
                    merge(this.form, data.form);
                    merge(this.wall, data.wall);
                });

                this.$toasted.global.success(`Deadline removed. ${this.registrationRsvpLabels.registrationLabelUppercase} will remain open indefinitely.`);
            }).catch(() => {
                this.$toasted.global.error('There was a problem removing the deadline.');
            });
        }
    }
};
</script>
