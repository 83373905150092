<template>
    <predicate-list
        :filterable-fields="filterableFields"
        v-bind="$attrs"
        v-on="$listeners"
    ></predicate-list>
</template>

<script>
import { call, get } from 'vuex-pathify';
import filter from 'lodash/filter';

export default {
    props: {
        includeReplyField: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        addons () {
            return this.event.products.filter(({ category }) => {
                return category === 'add-on';
            });
        },

        filterableFields () {
            const fields = [];

            if (this.event.setup_type === 'rsvp' || this.includeReplyField) {
                fields.push(this.replyField());
            }

            if (this.event.setup_type === 'tickets') {
                fields.push(this.ticketField());
            }

            fields.push(...this.secondaryReplyFields());

            return fields.concat([
                this.additionalGuestsField(),
                this.addonField(),
                this.tagField(),
                this.emailField(),
                this.nameField(),
                this.checkInStateField()
            ]);
        },

        event: get('Event/event'),
        loadedTags: get('Tags/loadedTags'),

        products () {
            return this.event.products.filter(({ category }) => {
                return category !== 'add-on';
            });
        }
    },

    created () {
        this.loadTags();
    },

    methods: {
        additionalGuestsField () {
            return {
                slug: 'additionalGuests',
                name: 'Additional guests',
                icon: 'users-check-circle-stroke',
                comparisons: [
                    {
                        slug: 'greaterThan',
                        label: 'is offered guests',
                        defaultValue: 0,
                        summaryGenerator: () => {
                            return 'are offered';
                        }
                    },
                    {
                        slug: 'equal',
                        label: 'is offered unlimited guests',
                        defaultValue: 'Unlimited',
                        summaryGenerator: () => {
                            return 'are unlimited';
                        }
                    },
                    {
                        slug: 'lessThanOrEqualTo',
                        label: 'is not offered guests',
                        defaultValue: 0,
                        summaryGenerator: () => {
                            return 'are not offered';
                        }
                    }
                ]
            };
        },

        addonField () {
            const addonPickerItems = this.addons.map(({ id, title }) => {
                return {
                    label: title,
                    value: id
                };
            });

            return {
                slug: 'addons:combinedIds',
                name: 'Add-on',
                icon: 'add-circle-stroke',
                comparisons: [
                    {
                        slug: 'has',
                        label: 'is',
                        summaryGenerator: (value) => {
                            return `is ${this.findLabelByValue(addonPickerItems, value)}`;
                        },
                        input: 'picker',
                        items: addonPickerItems
                    },
                    {
                        slug: 'doesNotHave',
                        label: 'is not',
                        summaryGenerator: (value) => {
                            return `isn't ${this.findLabelByValue(addonPickerItems, value)}`;
                        },
                        input: 'picker',
                        items: addonPickerItems
                    },
                    {
                        slug: 'blank',
                        label: 'has no value',
                        summaryGenerator: () => {
                            return 'has no value';
                        }
                    }
                ]
            };
        },

        checkInStateField () {
            const checkInStateItems = [
                { value: 'Unseen', label: 'Unseen' },
                { value: 'CheckedIn', label: 'Checked In' },
                { value: 'CheckedOut', label: 'Checked Out' }
            ];

            return {
                slug: 'checkInState',
                name: 'Check-In State',
                icon: 'messages-user-check-stroke',
                comparisons: [
                    {
                        slug: 'equal',
                        label: 'is',
                        summaryGenerator: (value) => { return `is ${this.findLabelByValue(checkInStateItems, value)}`; },
                        input: 'picker',
                        items: checkInStateItems
                    },
                    {
                        slug: 'different',
                        label: 'is not',
                        summaryGenerator: (value) => { return `isn't ${this.findLabelByValue(checkInStateItems, value)}`; },
                        input: 'picker',
                        items: checkInStateItems
                    }
                ]
            };
        },

        emailField () {
            return {
                slug: 'email',
                name: 'Email address',
                icon: 'email-envelope-stroke',
                comparisons: [
                    {
                        slug: 'contains',
                        label: 'contains',
                        summaryGenerator: (value) => { return `contains ${value}`; },
                        input: 'text'
                    },
                    {
                        slug: 'doesNotContain',
                        label: 'does not contain',
                        summaryGenerator: (value) => { return `doesn't contain ${value}`; },
                        input: 'text'
                    },
                    {
                        slug: 'blank',
                        label: 'has no value',
                        summaryGenerator: (value) => { return 'has no value'; }
                    }
                ]
            };
        },

        findLabelByValue (items, valueQuery) {
            const item = items.find(({ value }) => {
                return value === valueQuery;
            });

            return item.label;
        },

        loadTags: call('Tags/loadTags'),

        nameField () {
            return {
                slug: 'fullName',
                name: 'Name',
                icon: 'user-alt-search-stroke',
                comparisons: [
                    {
                        slug: 'contains',
                        label: 'contains',
                        summaryGenerator: (value) => { return `contains ${value}`; },
                        input: 'text'
                    },
                    {
                        slug: 'doesNotContain',
                        label: 'does not contain',
                        summaryGenerator: (value) => { return `doesn't contain ${value}`; },
                        input: 'text'
                    }
                ]
            };
        },

        replyField () {
            const replyPickerItems = [
                { value: 'Attending', label: 'Attending' },
                { value: 'NotAttending', label: 'Not attending' },
                { value: 'Maybe', label: 'Maybe' }
            ];

            return {
                slug: 'reply:state',
                name: 'Reply',
                icon: 'messages-user-check-stroke',
                comparisons: [
                    {
                        slug: 'equal',
                        label: 'is',
                        summaryGenerator: (value) => { return `is ${this.findLabelByValue(replyPickerItems, value)}`; },
                        input: 'picker',
                        items: replyPickerItems
                    },
                    {
                        slug: 'different',
                        label: 'is not',
                        summaryGenerator: (value) => { return `isn't ${this.findLabelByValue(replyPickerItems, value)}`; },
                        input: 'picker',
                        items: replyPickerItems
                    },
                    {
                        slug: 'blank',
                        label: 'no reply yet',
                        summaryGenerator: () => { return 'is empty'; }
                    }
                ]
            };
        },

        tagField () {
            const fetchFunction = async (searchTerm = '') => {
                await this.loadTags({ name: searchTerm });

                return this.loadedTags.map(({ id, name }) => {
                    return { label: name, value: id };
                });
            };

            return {
                slug: 'tags:combinedIds',
                name: 'Tag',
                icon: 'tag-stroke',
                comparisons: [
                    {
                        slug: 'has',
                        label: 'is',
                        summaryGenerator: (value, label) => { return `is ${label}`; },
                        input: 'picker',
                        isFilterable: true,
                        fetchFunction
                    },
                    {
                        slug: 'doesNotHave',
                        label: 'is not',
                        summaryGenerator: (value, label) => { return `isn't ${label}`; },
                        input: 'picker',
                        isFilterable: true,
                        fetchFunction
                    },
                    {
                        slug: 'blank',
                        label: 'has no tags',
                        summaryGenerator: () => { return 'is empty'; }
                    }
                ]
            };
        },

        ticketField () {
            const tickets = this.products.filter(({ type }) => {
                return type === 'primary';
            });

            const ticketPickerItems = tickets.map(({ id, title }) => {
                return { label: title, value: id };
            });

            return {
                slug: 'reply:productId',
                name: 'Ticket/Selection',
                icon: 'ticket-stroke',
                comparisons: [
                    {
                        slug: 'equal',
                        label: 'is',
                        summaryGenerator: (value) => { return `is ${this.findLabelByValue(ticketPickerItems, value)}`; },
                        input: 'picker',
                        items: ticketPickerItems
                    },
                    {
                        slug: 'different',
                        label: 'is not',
                        summaryGenerator: (value) => { return `isn't ${this.findLabelByValue(ticketPickerItems, value)}`; },
                        input: 'picker',
                        items: ticketPickerItems
                    },
                    {
                        slug: 'blank',
                        label: 'is not attending',
                        summaryGenerator: () => { return 'is not attending'; }
                    }
                ]
            };
        },

        secondaryReplyFields () {
            if (!this.event.secondaryEvents) {
                return [];
            }

            return this.event.secondaryEvents.map((secondaryEvent) => {
                const secondaryReplies = filter(this.products, { blockable_id: secondaryEvent.id });
                const secondaryReplyPickerItems = secondaryReplies.map(({ id, title }) => {
                    return { label: title, value: id };
                });

                return {
                    slug: `secondaryEvent-${secondaryEvent.id}:id`,
                    name: `${secondaryEvent.settings.title} Reply`,
                    icon: 'messages-user-check-stroke',
                    comparisons: [
                        {
                            slug: 'equal',
                            label: 'is',
                            summaryGenerator: (value) => { return `is ${this.findLabelByValue(secondaryReplyPickerItems, value)}`; },
                            input: 'picker',
                            items: secondaryReplyPickerItems
                        },
                        {
                            slug: 'different',
                            label: 'is not',
                            summaryGenerator: (value) => { return `isn't ${this.findLabelByValue(secondaryReplyPickerItems, value)}`; },
                            input: 'picker',
                            items: secondaryReplyPickerItems
                        }
                    ]
                };
            });
        }
    }
};
</script>
