export default {
    data () {
        return {
            cssInjectorElement: null
        };
    },

    watch: {
        customCssToInject () {
            this.applyCustomCss();
        }
    },

    mounted () {
        this.createCustomCssStyleElement();
    },

    methods: {
        // Prepend .event-wall to each custom CSS rule so it only applies
        // to the Event Website instead of applying the CSS to the whole page
        addNamespaceToCustomCss () {
            let safeCustomCss = '';

            const { sheet } = this.cssInjectorElement;

            for (let i = 0; i < sheet.rules.length; i++) {
                safeCustomCss += `${this.cssInjectorNamespace} ${sheet.rules[i].cssText} `;
            }

            this.cssInjectorElement.innerHTML = safeCustomCss;
        },

        applyCustomCss () {
            this.cssInjectorElement.innerHTML = this.customCssToInject;
            this.addNamespaceToCustomCss();
        },

        createCustomCssStyleElement () {
            const style = document.createElement('style');
            style.dataset.injected = true;
            style.type = 'text/css';
            style.appendChild(document.createTextNode(this.customCssToInject));

            document.head.appendChild(style);

            this.cssInjectorElement = style;
            this.addNamespaceToCustomCss();
        }
    }
};
