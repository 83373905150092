/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'casino-dealer-man-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M8.39 15.584l-.01-.01a.594.594 0 00-.9.49v3.32h0c0 .32.26.58.58.58.1-.01.2-.03.3-.09l7.221-4.34h-.01a.578.578 0 01.88.49v3.32h-.01c-.01.32-.27.58-.6.58a.62.62 0 01-.3-.09zM2.25 23l-.01-.01c-.01-2.52.96-4.94 2.71-6.75M19.035 16.25h0a9.737 9.737 0 012.71 6.75"/><path pid="1" d="M21.75 23v-.01c0-2.52-.97-4.94-2.72-6.75M4.965 16.25h-.01A9.702 9.702 0 002.235 23M17.25 7.5v-.01a5.258 5.258 0 01-6.98-2.47h-.01a5.288 5.288 0 01-3.53 3.5"/><path pid="2" d="M15.674 13.25v-.01c1-.99 1.57-2.34 1.57-3.75v-3 0a5.25 5.25 0 10-10.5 0v3h-.01c-.01 1.41.56 2.76 1.57 3.74"/></g>'
  }
})
