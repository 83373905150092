/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vegetables-carrot-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food vegetarian vegan</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.63 10.483l-.01-.01a8.859 8.859 0 00-2.52-3.6l-.01-.01a8.994 8.994 0 00-3.6-2.52l-.01-.01c-1.44-.5-3.03.16-3.68 1.53 0 0-6.49 10.23-8.9 15.13v-.01c-.36.74-.05 1.64.7 2 .4.19.88.19 1.29 0 4.9-2.41 15.139-8.9 15.139-8.9v-.01a3.006 3.006 0 001.54-3.67zM17.12 6.88l3.2-3.2M18.54 8.44l4.67-.81M15.56 5.46l.81-4.67M9.84 5.92l3.59 3.59M4.21 15.14L7.06 18M10.78 13.22l3.38 3.38"/></g>'
  }
})
