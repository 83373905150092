/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clothing-accessory-tie-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>fancy suit</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M13.85 6.108h-.01c-1.09.85-2.62.85-3.71-.01l-2.65 12.64 4.5 4.5 4.5-4.5z"/><path pid="1" d="M12 .75a3 3 0 100 6 3 3 0 100-6z"/></g>'
  }
})
