import Vapor from 'laravel-vapor';
import flatten from 'lodash/flatten';
import axios from '@/util/axios';

export default {
    methods: {
        createImageFromBlob (filename, blob) {
            return new File([blob], filename, {
                type: blob.type
            });
        },

        resolveFilenameFromUrl (url) {
            return new URL(url).pathname.split('/').pop();
        },

        async uploadEventImage (image) {
            try {
                const uploadedFile = await Vapor.store(image);

                const { data } = await axios.post(this.route('api.account.images.store'), {
                    image: {
                        ...uploadedFile,
                        filename: image.name
                    }
                });

                return data.data.urls;
            } catch (error) {
                if (error.response && error.response.status === 422) {
                    const validationErrors = flatten(Object.values(error.response.data.errors));

                    throw new Error(validationErrors[0]);
                }

                throw new Error('There was an error uploading the image.');
            }
        }
    }
};
