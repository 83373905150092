<template>
    <div>
        <button
            class="menu-button relative"
            @click="insertQrCode"
        >
            <app-icon name="technology-qr-code-scan" class="w-5 h-5"></app-icon>
        </button>
    </div>
</template>

<script>
export default {
    name: 'EditorInsertQrCodeButton',

    props: {
        editor: {
            type: Object,
            required: true
        },

        themeSettings: {
            type: Object,
            required: true
        }
    },

    methods: {
        insertQrCode () {
            this.editor.commands.checkinQrCode({
                backgroundColor: '#FFFFFF',
                foregroundColor: '#000000'
            });

            this.editor.blur();

            this.$emit('insert-button');
        }
    }
};
</script>
