/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-basketball-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>sports</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25 20.25h-.01a1.51 1.51 0 01-1.5-1.5c0-.83.67-1.5 1.5-1.5V15v0c0-.42-.34-.75-.75-.75H11.98c-.42 0-.75.33-.75.75 0 0 0 0 0 0v2.25h-.01c.82-.01 1.5.67 1.5 1.5 0 .82-.68 1.5-1.5 1.5v2.25h0c0 .41.33.74.75.74h10.5-.01c.41 0 .75-.34.75-.75z"/><path pid="1" d="M8.25 18.626l-.01-.01A9.006 9.006 0 01.86 8.23C1.68 3.32 6.33.02 11.23.85c4.32.73 7.49 4.48 7.49 8.87v-.01c0 .5-.05 1-.13 1.5M3.169 15.89c1.1-3.33 4.452-10.56 13.22-12.215"/><path pid="2" d="M3.027 3.76l-.01-.01c3.92 1.35 7.28 3.98 9.53 7.48"/><path pid="3" d="M8.569.82h0c.17 3.67-2.67 6.78-6.34 6.96-.43.01-.85-.01-1.26-.07M8.612 14.25v-.01a6.695 6.695 0 017.65-5.59c.88.13 1.72.44 2.48.91"/></g>'
  }
})
