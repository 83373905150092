/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'megaphone-alt-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>announce</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 9v6M23.25 1.5v21M.75 10.5L23.25 3M.75 13.5l22.5 7.5M5.12 14.95v.04h0c.11 3.7 3.21 6.61 6.91 6.49 1.98-.07 3.83-1 5.05-2.55"/></g>'
  }
})
