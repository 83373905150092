<template>
    <div>
        <app-icon
            v-if="reply === 'yes'"
            name="check-circle-filled"
            class="shrink h-5 w-5 text-green"
        ></app-icon>
        <app-icon
            v-else-if="reply === 'no'"
            name="remove-circle"
            class="shrink-0 text-red h-5 w-5"
            stroke
        ></app-icon>
        <app-icon
            v-else-if="reply === 'maybe'"
            name="help-circle"
            class="shrink h-5 w-5"
            stroke
        ></app-icon>
        <div v-else>
            No Reply
        </div>
    </div>
</template>

<script>
export default {
    props: {
        reply: {
            type: null,
            required: true
        }
    }
};
</script>
