<template>
    <div>
        <data-columns
            ref="dataColumns"
            :event="event"
            :mapper="mapper"
            :required-columns="requiredColumns"
            :route-resolver="routeResolver"
            :total-item-count="totalGuestCount"
            v-bind="$attrs"
            @click-row="openSubmissionModal"
            @received-response="onReceivedResponse"
            v-on="$listeners"
        >
            <template #header="{ allPredicates, availableColumns, chosenColumns, grouped, predicates, reload, reset, searchQuery, selectedAll, selectedIds, setChosenColumns, setPredicates, setSearchQuery }">
                <div class="w-full flex flex-wrap items-center space-x-2">
                    <bulk-actions
                        :ids="selectedIds"
                        :all="selectedAll"
                        :predicates="allPredicates"
                        :total="totalGuestCount"
                        class="hidden md:block"
                        :disabled="actionsButton.disabled"
                        :disabled-tooltip="actionsButton.disabledTooltip"
                    >
                        <template #default="bulkActionData">
                            <bulk-tag
                                :bulk-action-data="bulkActionData"
                                :confirmation-message="`Tagging ${bulkActionData.formatted.totalSelected} guest(s). This may take a few minutes.`"
                                :modal-title="`Tagging ${bulkActionData.formatted.totalSelected} guest(s)`"
                                :confirm-button-text="`Tag ${bulkActionData.formatted.totalSelected} Guest(s)`"
                                :endpoint="route('api.dashboard.bulk.tag', event)"
                                @rows-tagged="reload"
                            ></bulk-tag>

                            <bulk-untag
                                :bulk-action-data="bulkActionData"
                                :confirmation-message="`Untagging guest(s). This may take a few minutes.`"
                                :modal-title="`Untagging guest(s)`"
                                :confirm-button-text="`Untag Guest(s)`"
                                :counts-endpoint="route('api.dashboard.guests.tags.counts', event)"
                                :endpoint="route('api.dashboard.bulk.untag', event)"
                                @rows-untagged="reload"
                            ></bulk-untag>

                            <bulk-delete
                                :bulk-action-data="bulkActionData"
                                :endpoint="route('api.dashboard.bulk.delete', event)"
                                :delete-confirm-button-text="`DELETE ${bulkActionData.formatted.totalSelected} GUEST(S)`"
                                :delete-message="`You are deleting replies from ${bulkActionData.formatted.totalSelected} guest(s). All data will be permanently deleted. This cannot be undone. Type delete below to confirm.`"
                                :confirmation-message="`Deleting replies from ${bulkActionData.formatted.totalSelected} guest(s).  This may take a few minutes.`"
                                require-type-delete-confirmation
                                @rows-deleted="reload"
                            ></bulk-delete>

                            <bulk-resend-confirmation-email :bulk-action-data="bulkActionData" :event="event"></bulk-resend-confirmation-email>
                        </template>
                    </bulk-actions>

                    <div class="flex-auto">
                        <search-field
                            class="w-full md:w-80 ml-2 pr-6"
                            placeholder="Name, email, confirmation..."
                            :value="searchQuery"
                            @input="setSearchQuery"
                        ></search-field>
                    </div>

                    <choosable-columns
                        class="hidden md:block"
                        :columns="availableColumns"
                        :value="chosenColumns"
                        @input="setChosenColumns"
                    ></choosable-columns>

                    <button
                        class="bg-white rounded-md border p-2 disabled:opacity-50 hidden md:block"
                        :class="resetButtonStyles(grouped)"
                        :disabled="grouped"
                        @click="reset"
                    >
                        <app-icon
                            name="team"
                            class="w-6 h-6"
                            stroke
                        ></app-icon>
                    </button>

                    <slot
                        name="export-button"
                        :chosen-columns="chosenColumns"
                        :predicates="allPredicates"
                    >
                        <dashboard-export-button
                            :chosen-columns="chosenColumns"
                            :predicates="allPredicates"
                            class="hidden md:block"
                        ></dashboard-export-button>
                    </slot>
                </div>

                <data-columns-predicate-list
                    class="mt-2 pl-2 hidden md:block"
                    :value="predicates"
                    @input="setPredicates"
                ></data-columns-predicate-list>
            </template>

            <template
                v-for="(_, slotName) of $scopedSlots"
                #[slotName]="slotScope"
            >
                <slot
                    :name="slotName"
                    v-bind="slotScope"
                    :open-submission-modal="openSubmissionModal"
                ></slot>
            </template>
        </data-columns>

        <party-details-modal
            v-model="isSubmissionModalVisible"
            :initial-submission="selectedSubmission"
            @delete-submission="onSubmissionDeleted"
            @saved="onSubmissionUpdated"
        ></party-details-modal>
    </div>
</template>

<script>
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import axios from '@/util/axios';
import BulkActionsButtonAuthorization from '@/mixins/BulkActionsButtonAuthorization';

export default {
    name: 'DashboardData',

    mixins: [
        BulkActionsButtonAuthorization
    ],

    props: {
        alwaysLoadedColumns: {
            type: Array,
            default: () => {
                return [];
            }
        },

        event: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            isSubmissionModalVisible: false,
            selectedSubmission: {},
            totalGuestCount: 0
        };
    },

    computed: {
        requiredColumns () {
            return ['id', 'submissionId'].concat(this.alwaysLoadedColumns);
        }
    },

    methods: {
        mapper (items, grouped) {
            if (!grouped) {
                return items;
            }

            const groups = Object.values(
                groupBy(items, 'submissionId')
            );

            return sortBy(groups, (group) => {
                return items.indexOf(group[0]);
            });
        },

        onReceivedResponse ({ data }) {
            this.totalGuestCount = data.totalGuestCount;
        },

        onSubmissionDeleted () {
            this.$refs?.dataColumns?.reload();
            this.$toasted.global.success('This group and all associated data have been deleted.');
        },

        onSubmissionUpdated () {
            this.$refs?.dataColumns?.reload();
            this.isSubmissionModalVisible = false;
        },

        async openSubmissionModal ({ submissionId }) {
            const { data } = await axios.get(this.route('api.events.submissions.show', {
                event: this.event,
                submission: submissionId
            }));

            this.selectedSubmission = data.data;
            this.isSubmissionModalVisible = true;
        },

        resetButtonStyles (grouped) {
            return {
                'hover:border-purple hover:cursor-pointer text-gray-600 hover:text-purple': !grouped
            };
        },

        routeResolver (grouped) {
            return grouped
                ? 'api.events.submissions.index'
                : 'api.events.guests.index';
        }
    }
};
</script>
