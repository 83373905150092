/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camping-tent-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.411.75L.75 23.25h22.5L9.589.75"/><path pid="1" d="M12 16.018l-4.018 7.23h8.03L11.992 16z"/></g>'
  }
})
