import setAlphaInRgba from './helpers';
import hexToRGBA from './hexToRGBA';

export default {
    'wall.title-font': (theme) => {
        return `
            themedElementSelector {
                font-family: ${theme.wall.fonts.title};
            }
        `;
    },

    'wall.title-color': (theme) => {
        return `
            themedElementSelector {
                color: ${hexToRGBA(theme.wall.colors.title)};
            }
        `;
    },

    'wall.title-font-size': (theme) => {
        return `
            themedElementSelector {
                font-size: ${theme.wall.fontSizes.title};
            }
        `;
    },

    'wall.text': (theme) => {
        return `
            themedElementSelector {
                color: ${hexToRGBA(theme.wall.colors.body)} !important;
                font-family: ${theme.wall.fonts.body} !important;
                font-size: ${theme.wall.fontSizes.body};
            }
            themedElementSelector div:not(.absolute) > a {
                color: ${hexToRGBA(theme.wall.colors.accent)};
            }
        `;
    },

    'wall.text-color': (theme) => {
        return `
            themedElementSelector {
                color: ${hexToRGBA(theme.wall.colors.body)} !important;
            }
            themedElementSelector div:not(.absolute) > a {
                color: ${hexToRGBA(theme.wall.colors.accent)};
            }
        `;
    },

    'wall.rsvpify-logo': (theme) => {
        return `
            themedElementSelector {
                color: ${hexToRGBA(setAlphaInRgba(theme.wall.colors.body, 0.8))} !important;

            }
            themedElementSelector .logo-checkmark {
                color: ${hexToRGBA(theme.wall.colors.background)} !important;
            }
        `;
    },

    'wall.text-font-family': (theme) => {
        return `
            themedElementSelector {
                font-family: ${theme.wall.fonts.body} !important;
            }
        `;
    },

    'wall.menu-item': (theme) => {
        return `
            themedElementSelector {
                color: ${hexToRGBA(theme.wall.colors.accent)};
            }
        `;
    },

    'wall.accent-icon': (theme) => {
        return `
            themedElementSelector {
                color: ${hexToRGBA(theme.wall.colors.accent)};
            }
        `;
    },

    'wall.hover-border': (theme) => {
        return `
            themedElementSelector:hover {
                border-color: ${hexToRGBA(theme.wall.colors.accent)};
            }
        `;
    },

    'wall.input-focus-border': (theme) => {
        return `
            themedElementSelector input:focus,
            themedElementSelector textarea:focus
             {
                border-color: ${hexToRGBA(theme.wall.colors.accent)};
            }
        `;
    },

    'wall.background': (theme) => {
        return `
            themedElementSelector {
                background-color: ${hexToRGBA(theme.wall.colors.background)} !important;
            }
        `;
    },

    'wall.accent-background': (theme) => {
        return `
            themedElementSelector {
                background-color: ${hexToRGBA(theme.wall.colors.accent)};
            }
        `;
    },

    'wall.accent-border': (theme) => {
        return `
            themedElementSelector {
                border-color: ${hexToRGBA(theme.wall.colors.accent)};
            }
        `;
    },

    'wall.button': (theme) => {
        return `
            themedElementSelector {
                color: ${theme.wall.colors.buttonText ? hexToRGBA(theme.wall.colors.buttonText) : hexToRGBA(theme.wall.colors.body)};
                background-color: ${hexToRGBA(theme.wall.colors.accent)};
            }
            themedElementSelector svg {
                color: ${theme.wall.colors.buttonText ? hexToRGBA(theme.wall.colors.buttonText) : hexToRGBA(theme.wall.colors.body)};
            }
        `;
    },

    'wall.select-list': (theme) => {
        return `
            themedElementSelector .select-list-outer {
                border-color: ${hexToRGBA(theme.wall.colors.accent)} !important;
            }
           themedElementSelector .select-list-check {
                background-color: ${hexToRGBA(theme.wall.colors.accent)} !important;
            }
        `;
    },

    'wall.hoverable-accent-border': (theme) => {
        return `
            themedElementSelector {
                border-color: ${hexToRGBA(setAlphaInRgba(theme.wall.colors.accent, 0.7))};
            }
            themedElementSelector:hover {
                border-color: ${hexToRGBA(theme.wall.colors.accent)};
            }
        `;
    }
};
