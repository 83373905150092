/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'season-winter-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>leaf snowflake</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M14.579 20.379s1.21 2.49 3.27 2.308c4.15-.38 3.88-5.907 5.4-7.859h0a9.1 9.1 0 00-8.27.07c-2.16 1.21-2.31 3.27-.41 5.47zM17.52 17.844s-2.7.33-4.63 5.03M9.75 1.12v18M18.75 10.12h-18M7.5 12.38l-2.89 2.89M14.89 4.98L12 7.88M4.61 4.98l2.89 2.9M7.179 1.768l2.57 2.57 2.57-2.58M18.11 7.55l-2.57 2.57M9.75 15.91l-2.57 2.57M1.393 12.696l2.57-2.58-2.58-2.58"/></g>'
  }
})
