<script>
import pick from 'lodash/pick';
import debounce from 'lodash/debounce';
import axios from '@/util/axios';

export default {
    props: {
        additionalFields: {
            type: Array,
            default: () => { return ['resource']; }
        },
        filter: {
            type: Object,
            default: () => { return {}; }
        },
        groupedResource: {
            type: String,
            default: null
        },
        mapFunction: {
            type: Function,
            default: null
        },
        predicates: {
            type: Array,
            default: () => { return []; }
        },
        requestMethod: {
            type: String,
            default: 'get',
            validator (value) {
                return ['get', 'post'].includes(value);
            }
        },
        sort: {
            type: Object,
            default: () => { return {}; }
        },
        source: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            currentPage: 1,
            grouped: true,
            items: [],
            loading: false,
            pageBeingLoaded: 1,
            totalPages: 1,
            total: 0
        };
    },

    computed: {
        additionalParams () {
            return {
                filter: this.filter,
                predicates: this.predicates,
                sort: this.getSortDescriptor()
            };
        },

        rows () {
            if (!this.mapFunction) {
                return this.items;
            }

            return this.items.map((item) => {
                return this.mapFunction(item, this.grouped);
            });
        }
    },

    watch: {
        additionalParams: {
            deep: true,
            handler: debounce(function () {
                this.load(1);
            }, 500)
        },

        source () {
            this.load(1);
        }
    },

    created () {
        this.load(1);
    },

    methods: {
        getSortDescriptor () {
            if (this.sort.property) {
                return `${this.sort.type === 'desc' ? '-' : '+'}${this.sort.property}`;
            }

            return null;
        },

        load (page) {
            this.loading = true;
            this.pageBeingLoaded = page;

            this.makeRequest(page).then(({ data }) => {
                this.grouped = data.resource === this.groupedResource;
                this.items = data.data;
                this.currentPage = data.meta.current_page;
                this.totalPages = data.meta.last_page;
                this.total = data.meta.total;

                if (this.additionalFields.length) {
                    this.$emit('fetched-data', pick(data, this.additionalFields));
                }
            }).finally(() => {
                this.loading = false;
                this.pageBeingLoaded = null;
            });
        },

        makeRequest (page) {
            const payload = {
                page,
                ...this.additionalParams
            };

            switch (this.requestMethod) {
                case 'get':
                    return axios.get(this.source, { params: payload });
                case 'post':
                    return axios.post(this.source, payload);
            }
        },

        reload () {
            this.load(this.currentPage);
        }
    },

    render () {
        return this.$scopedSlots.default({
            currentPage: this.currentPage,
            grouped: this.grouped,
            items: this.items,
            loading: this.loading,
            pageBeingLoaded: this.pageBeingLoaded,
            load: this.load,
            reload: this.reload,
            rows: this.rows,
            totalPages: this.totalPages,
            total: this.total
        });
    }
};
</script>
