/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-mutation-temperature-change-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M17.25 3.32a3.42 3.42 0 100 6.85 3.42 3.42 0 100-6.86zM16.68.75h1.14M17.25.75v2.57M21.09 2.1l.81.81M21.49 2.51l-1.82 1.82M23.25 6.18v1.14M23.25 6.75h-2.57M21.9 10.59l-.81.81M21.49 10.99l-1.82-1.82M17.82 12.75h-1.14M17.25 12.75v-2.57M13.41 11.4l-.81-.81M13.01 10.99l1.82-1.82M11.25 7.32V6.18M11.25 6.75h2.57M12.6 2.91l.81-.81M13.01 2.51l1.82 1.82M8.25 15.418V3.75v0c0-1.66-1.35-3-3-3-1.66 0-3 1.34-3 3v11.668-.01c-1.85 1.66-2 4.5-.34 6.34a4.49 4.49 0 006.34.33c.94-.86 1.48-2.07 1.48-3.35h0a4.517 4.517 0 00-1.5-3.34zM5.25 5.25v12"/><path pid="1" d="M5.25 17.25a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/></g>'
  }
})
