/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>ok yes correct done task</desc><path pid="0" _fill="currentColor" d="M23.146 5.4l-2.8-2.8c-.2-.2-.52-.2-.71-.01h-.01l-11.8 11.8v-.01c-.2.19-.52.19-.71 0l-.01-.01-2.8-2.8c-.2-.2-.52-.2-.71-.01h-.01l-2.8 2.8v-.01c-.2.19-.2.51-.01.7l6.29 6.29-.01-.01c.19.19.51.19.7 0v-.01l15.29-15.3v-.01c.19-.2.19-.51-.01-.7z"/>'
  }
})
