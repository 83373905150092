<template>
    <div>
        <template v-if="cancelled">
            <div class="flex flex-col items-center justify-center space-y-6">
                <template v-if="loading">
                    <app-icon
                        v-if="loading"
                        name="loader"
                        class="w-8 h-8 text-purple"
                    ></app-icon>
                </template>
                <template v-else>
                    <div class="rounded-full bg-purple-100 p-6">
                        <app-icon
                            name="calendar-disable-stroke"
                            class="h-14 w-14 text-purple"
                            stroke
                        ></app-icon>
                    </div>

                    <p class="text-center">
                        Your event has been successfully canceled.<br>
                        If you change your mind, you can choose to re-open your event registration form any time.
                    </p>

                    <a :href="route('events.dashboard.overview', event)" class="button button-primary">Go to dashboard</a>

                    <button class="button-text button-primary" @click="undoCancellation">Undo and re-open registration</button>
                </template>
            </div>
        </template>
        <template v-else>
            <step-progress
                class="mx-auto mb-4"
                :current-step="currentStep"
                :steps="steps"
            ></step-progress>

            <div class="flex items-center justify-center flex-col text-center space-y-10">
                <template v-if="currentStep === STEP_CONFIRMATION">
                    <h1 class="font-semibold text-xl">Are you sure you want to cancel {{ event.name }}?</h1>

                    <p class="max-w-4xl">Your event's link will remain publicly available, but your {{ registrationRsvpLabels.registrationLabel }} will be closed. Auto-reminders and any emails you have scheduled will be canceled and will not send.</p>

                    <div class="flex items-center space-x-4">
                        <a :href="route('publish-invite.index', event)" class="button">Nevermind</a>
                        <button class="button button-primary" @click="currentStep = STEP_PUBLIC_MESSAGE">Continue</button>
                    </div>
                </template>
                <template v-else-if="currentStep === STEP_PUBLIC_MESSAGE">
                    <p class="max-w-3xl">Customize the message guests will see in place of your event's registration form:</p>

                    <div v-theme="['form.text', 'form.background']" class="p-4 mt-4 rounded-md advanced-editor group w-full max-w-4xl text-left">
                        <tiptap-editor
                            ref="editor"
                            v-model="unavailableMessage"
                            hint-classes="-m-2"
                            with-hint
                        ></tiptap-editor>
                    </div>

                    <div class="flex items-center space-x-4">
                        <button class="button" @click="currentStep = STEP_CONFIRMATION">Go Back</button>
                        <button class="button button-primary" @click="currentStep = STEP_EMAIL_GUESTS">Continue</button>
                    </div>
                </template>
                <template v-else-if="currentStep === STEP_EMAIL_GUESTS">
                    <template v-if="loading">
                        <app-icon
                            v-if="loading"
                            name="loader"
                            class="w-8 h-8 text-purple"
                        ></app-icon>
                    </template>
                    <template v-else>
                        <div class="flex flex-col max-w-3xl space-y-4 items-center justify-center text-center">
                            <p class="text-xl">
                                Email <span class="font-bold">{{ audienceCounts.attending }}</span> confirmed attendees a cancelation notification?
                            </p>
                            <button class="button-text button-primary" @click="confirmCancellation(false)">Skip and finalize cancelation</button>
                        </div>

                        <div v-if="!canSendCancellationEmail" class="alert alert-error">
                            <p>This email requires <span class="font-bold">{{ audienceCounts.attending }}</span> credit(s), and you have only <span class="font-bold">{{ event.owner.remainingEmailCredits }}</span> credit(s) remaining this period. Please consider upgrading your RSVPify plan to add additional email credits.</p>
                        </div>

                        <accordion-list
                            v-model="emailStep"
                            class="mt-4"
                            container-class="text-left"
                        >
                            <accordion-list-item
                                item-id="compose"
                                class="bg-white border rounded-md"
                                :expand-collapse-icon="false"
                            >
                                <template #header="{ active }">
                                    <div class="w-full flex items-center px-4 py-2 border-b">
                                        Compose Cancelation Email
                                    </div>
                                </template>

                                <compose-email
                                    v-if="form"
                                    slot="content"
                                    :available-from-email-domains="availableFromEmailDomains"
                                    :event="initialEvent"
                                    :form="emailForm"
                                    :default-button-options="defaultButtonOptions"
                                    :site-key="siteKey"
                                    @logo-uploaded="logoUploaded"
                                ></compose-email>
                            </accordion-list-item>
                        </accordion-list>

                        <div class="flex items-center space-x-4">
                            <button class="button" @click="currentStep = STEP_PUBLIC_MESSAGE">Go Back</button>
                            <stateful-button
                                :disabled="!canSendCancellationEmail"
                                class="button button-primary"
                                @click="confirmCancellation(true)"
                            >
                                Send Email &amp; Cancel Event
                            </stateful-button>
                        </div>
                    </template>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import tinycolor from 'tinycolor2';
import { merge } from 'lodash';
import axios from '@/util/axios';
import IsEventPublished from '@/mixins/IsEventPublished';
import Form from '@/validation/Form';
import RegistrationRSVPLabels from '@/mixins/RegistrationRSVPLabels';

export default {
    name: 'CancelEvent',

    mixins: [IsEventPublished, RegistrationRSVPLabels],

    props: {
        audienceCounts: {
            required: true,
            type: Object
        },

        availableFromEmailDomains: {
            type: Array,
            required: true
        },

        initialEvent: {
            type: Object,
            required: true
        },

        defaultButtonOptions: {
            type: Object,
            default: () => { return {}; }
        },

        defaultContent: {
            type: String,
            default: ''
        },

        scheduledAt: {
            type: Object,
            default: () => { return { timestamp: null, timezone_id: null }; }
        },

        siteKey: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            STEP_CONFIRMATION: 0,
            STEP_PUBLIC_MESSAGE: 1,
            STEP_EMAIL_GUESTS: 2,

            cancelled: !!this.initialEvent.cancelled_at,
            loading: false,
            unavailableMessage: `<p style="text-align: center;">Sorry, ${this.initialEvent.name} has been canceled.</p>`,
            currentStep: 0,
            emailStep: 'compose',
            emailForm: null,
            steps: [
                { title: 'Continue?' },
                { title: 'Public Message' },
                { title: 'Email Guests' }
            ]
        };
    },

    computed: {
        ...get('Event/*'),
        ...get('Wall/*'),
        ...get('Form/*'),

        canSendCancellationEmail () {
            return this.audienceCounts.attending <= this.initialEvent.owner.remainingEmailCredits;
        }
    },

    mounted () {
        this.initializeForm();
    },

    methods: {
        initializeForm () {
            const emailBodyBackgroundColor = tinycolor(this.initialEvent.form.settings.theme.colors.background).toHexString();
            const accentBarColor = tinycolor(this.initialEvent.form.settings.theme.colors.accent).toHexString();

            this.$set(this, 'emailForm', new Form({
                title: `Canceled: ${this.event.name}`,
                body: this.defaultContent,
                subject: `Canceled: ${this.event.name}`,
                from_name: 'RSVPify',
                from_email_local_part: 'invitations',
                from_email_domain: 'rsvpify.com',
                from_email: 'invitations@rsvpify.org',
                reply_to: window.user.email,
                settings: {
                    logo: 'existing',
                    logo_url: null,
                    logo_width: 200,
                    backgroundColor: '#F2F2F2',
                    emailBodyBackgroundColor,
                    accentBarColor,
                    accentBarColorEnabled: true,
                    footerColors: { text: '#888888', link: '#555555' }
                }
            }));
        },

        confirmCancellation (sendEmail = false) {
            this.loading = true;

            this.emailForm.post(this.route('api.publish-invite.cancel', this.initialEvent), null, {
                sendEmail,
                unavailableMessage: this.unavailableMessage
            }).then(({ data }) => {
                this.cancelled = true;

                merge(this.event, data);
            }).finally(() => {
                this.loading = false;
            });
        },

        logoUploaded (url) {
            this.emailForm.settings.logo_url = url;
        },

        undoCancellation () {
            this.loading = true;

            axios.post(this.route('api.publish-invite.undo-cancellation', this.initialEvent)).then(() => {
                App.alert().success(
                    '',
                    'Your event is not cancelled anymore! Please note that you will have to manually re-schedule any previously scheduled campaigns or reminders.',
                    {
                        confirmButtonText: 'OK',
                        onClose () {
                            window.location.reload();
                        }
                    }
                );
            }).finally(() => {
                this.loading = false;
            });
        }
    }
};
</script>
