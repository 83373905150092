/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'award-ribbon-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>star achievement</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.8 13.042L.74 18l3.75.75 1.5 4.5 3.94-6.26M19.193 13.042L23.24 18l-3.75.75-1.5 4.5-3.95-6.26"/><path pid="1" d="M12 .75a8.25 8.25 0 100 16.5 8.25 8.25 0 100-16.5z"/><path pid="2" d="M12.53 4.292l1.24 2.458h2.12-.001c.32-.01.58.24.59.56 0 .16-.07.31-.18.43l-1.96 1.91 1.08 2.48h0c.12.3-.02.64-.32.77-.18.07-.37.05-.53-.04l-2.62-1.48-2.62 1.471v-.01c-.29.16-.65.06-.81-.22a.585.585 0 01-.04-.53l1.08-2.49-1.96-1.93-.01-.01a.586.586 0 01-.02-.83c.11-.12.26-.19.43-.18h2.12l1.24-2.46v-.01a.598.598 0 011.06-.01z"/></g>'
  }
})
