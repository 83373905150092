/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'breakfast-english-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food egg bacon sausage</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.81 11.67a2.36 2.36 0 100 4.72 2.36 2.36 0 100-4.73z"/><path pid="1" d="M6.15 8.978h0a5.394 5.394 0 10-.04 10.79c2.48 0 4.65-1.69 5.25-4.1v-.01a3.38 3.38 0 002.84-3.83 3.371 3.371 0 00-3.35-2.88zM23.028 10.673c-.61 1.26.35 2.5-.26 3.75s-2.17 1.28-2.78 2.54c-.61 1.26.35 2.5-.26 3.75s-2.17 1.28-2.78 2.54l-3.15-1.52c.6-1.26 2.16-1.29 2.77-2.55.6-1.26-.36-2.5.25-3.76.6-1.26 2.16-1.29 2.77-2.55.6-1.26-.36-2.5.25-3.76zM20.625 5.97l-.01-.001a2.631 2.631 0 00-.01-5.26H8.595A2.612 2.612 0 005.96 3.32c0 1.44 1.17 2.62 2.62 2.62zM9.75.73l.75 2.25M13.5.73l.75 2.25M17.25.73L18 2.98"/></g>'
  }
})
