/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-bag-dollar-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M18.552 8.022h0c-.02-.68-.55-1.23-1.22-1.28H6.65v-.01c-.68.04-1.2.59-1.22 1.27l-1.7 10.65h-.01c-.13.73.38 1.43 1.12 1.55.03 0 .06 0 .09.01h14.05v-.01c.74-.07 1.29-.73 1.22-1.48-.01-.04-.01-.07-.02-.1z"/><path pid="1" d="M8.25 6.75c0-.71-.54-5.28 3.74-5.28v0c4.27 0 3.75 4.66 3.75 5.27M13.65 10.5H11.6c-.75 0-1.35.6-1.35 1.34 0 .54.33 1.03.84 1.24l2.064.82-.01-.01c.68.27 1.02 1.05.74 1.74-.21.51-.7.84-1.25.84h-2.04M12.15 10.5v-.75M12.15 17.25v-.75M.75 3.75v-1.5h0C.74 1.42 1.42.74 2.24.74h1.5M23.25 3.75v-1.5 0c0-.83-.68-1.5-1.5-1.5h-1.5M.75 20.25v1.5h0c0 .82.67 1.49 1.5 1.49h1.5M23.25 20.25v1.5c0 .82-.68 1.5-1.5 1.5h-1.5"/></g>'
  }
})
