/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M23.2 2.05v-.01a.753.753 0 00-.71-.8c-.02-.01-.04-.01-.05-.01H1.48v-.001c-.42 0-.75.33-.75.75v.04L.72 2c.36 5.03 4.02 9.2 8.97 10.22v9.875l-.01-.01c0 .41.33.74.75.74.17-.01.34-.07.47-.18l3-2.5v-.01c.17-.15.27-.36.26-.58v-7.39c4.94-1.01 8.61-5.19 8.97-10.23z"/>'
  }
})
