/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-pinterest': {
    width: 16,
    height: 16,
    viewBox: '0 0 39 50',
    data: '<path pid="0" d="M20.219.902C7.436.902.65 9.48.65 18.83c0 4.34 2.31 9.75 6.01 11.47.564.262.862.147.99-.394.099-.413.6-2.421.823-3.36.074-.298.037-.556-.207-.852-1.218-1.478-2.199-4.211-2.199-6.755 0-6.531 4.946-12.85 13.37-12.85 7.28 0 12.37 4.955 12.37 12.046 0 8.013-4.04 13.562-9.306 13.562-2.909 0-5.086-2.4-4.388-5.354.838-3.518 2.454-7.321 2.454-9.857 0-2.278-1.221-4.175-3.748-4.175-2.971 0-5.36 3.076-5.36 7.196 0 2.622.888 4.394.888 4.394s-2.934 12.414-3.47 14.724c-.915 3.921.12 10.274.21 10.83.047.297.378.398.565.147.288-.385 3.826-5.69 4.827-9.528.355-1.384 1.84-7.04 1.84-7.04.971 1.842 3.787 3.4 6.789 3.4 8.932 0 15.37-8.219 15.37-18.422 0-9.787-7.983-17.109-18.26-17.109" _fill="currentColor"/>'
  }
})
