<template>
    <simple-picker
        :value="value"
        :items="fontSizes"
        item-label="title"
        item-value="value"
        placeholder-empty-state="Select Font Size..."
        @input="$emit('input', $event)"
    >
        <div
            slot="input"
            slot-scope="{ selected }"
            :style="{ 'font-size': selected.value }"
        >
            {{ selected.title }}
        </div>
        <div
            slot="item"
            slot-scope="{ item }"
            :style="{ 'font-size': item.value }"
        >
            {{ item.title }}
        </div>
    </simple-picker>
</template>

<script>
export default {
    name: 'FontSizePicker',

    props: {
        value: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            fontSizes: [
                { title: 'Extra Small', value: '0.875rem' },
                { title: 'Small', value: '1rem' },
                { title: 'Normal', value: '1.125rem' },
                { title: 'Large', value: '1.5rem' },
                { title: 'Extra Large', value: '1.875rem' },
                { title: 'Jumbo', value: '2.25rem' }
            ]
        };
    }
};
</script>
