/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'beach-sun-birds-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>location travel</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 23.25l-.01-.01c1.64.2 3.3-.35 4.5-1.5l-.01-.01a4.54 4.54 0 006.42.32c.11-.11.22-.22.32-.33l-.01-.01a4.54 4.54 0 006.42.32c.11-.11.22-.22.32-.33l-.01-.01a5.516 5.516 0 004.49 1.5M.75 18.75l-.01-.01c1.64.2 3.3-.35 4.5-1.5l-.01-.01a4.54 4.54 0 006.42.32c.11-.11.22-.22.32-.33l-.01-.01a4.54 4.54 0 006.42.32c.11-.11.22-.22.32-.33l-.01-.01a5.516 5.516 0 004.49 1.5M18 3.75l1.5 1.5 1.5-1.5M20.25 9l1.5 1.5 1.5-1.5M6.75 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5zM11.25 6h1.5M2.25 6H.75M9 2.05l.75-1.3M4.5 9.95l-.75 1.3M4.5 2.05L3.75.75M9 9.95l.75 1.3"/></g>'
  }
})
