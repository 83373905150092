import axios from '@/util/axios';
import { make } from 'vuex-pathify';
import { isEqual } from 'lodash';

const STATE_EMPTY = 'empty';
const STATE_LOADING = 'loading';
const STATE_LOADED = 'loaded';

const moduleState = {
    loadedTags: [],
    loadedFilter: {},
    totalTagsNumber: 25,
    canCreateTag: false,
    state: STATE_EMPTY
};

const getters = {
    loading () {
        return moduleState.state === STATE_LOADING;
    }
};

const actions = {
    async loadTags ({ commit, rootState, state }, filter = {}) {
        if (state.state !== STATE_EMPTY && isEqual(filter, state.loadedFilter)) {
            return Promise.resolve();
        }

        commit('state', STATE_LOADING);

        commit('loadedFilter', filter);

        return axios.get(window.route('api.events.tags.index', rootState.Event.event), {
            params: {
                filter,
                perPage: 25
            }
        })
            .then(({ data }) => {
                commit('loadedTags', data.data);
                commit('canCreateTag', data.canCreateTag);
                commit('totalTagsNumber', data.meta.total);
                commit('state', STATE_LOADED);

                return new Promise((resolve) => {
                    resolve();
                });
            });
    },

    async reloadTags ({
        commit, dispatch, rootState, state
    }, filter = {}) {
        if (state.state === STATE_EMPTY && isEqual(filter, state.loadedFilter)) {
            return dispatch('loadTags');
        }

        if (state.state === STATE_LOADING) {
            return Promise.resolve();
        }

        commit('state', STATE_LOADING);

        commit('loadedFilter', filter);

        return axios.get(window.route('api.events.tags.index', rootState.Event.event), { params: { perPage: 25 } })
            .then(({ data }) => {
                commit('loadedTags', data.data);
                commit('canCreateTag', data.canCreateTag);
                commit('totalTagsNumber', data.meta.total);
                commit('state', STATE_LOADED);
            });
    }
};

export default {
    namespaced: true,
    state: moduleState,
    mutations: make.mutations(moduleState),
    actions,
    getters
};
