/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bookmark-star-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>rating ribbon favorite</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M19.059 23.105l-6.177-4.5h0a1.5 1.5 0 00-1.77-.01l-6.18 4.49h-.01a.76.76 0 01-1.05-.17.731.731 0 01-.15-.44V2.22h0C3.71 1.39 4.39.71 5.21.71s0 0 0 0h13.5-.01c.82-.01 1.5.67 1.5 1.5v20.25-.01c-.01.41-.34.74-.76.74a.83.83 0 01-.44-.15z"/><path pid="1" d="M12.53 5.791l1.24 2.458h2.12-.001c.32-.01.58.24.59.56 0 .16-.07.31-.18.43l-1.96 1.92 1.08 2.48h0c.12.3-.02.64-.32.77-.18.07-.37.05-.53-.04l-2.62-1.48-2.62 1.47v-.01c-.29.16-.65.06-.81-.22a.585.585 0 01-.04-.53l1.08-2.49-1.96-1.93-.01-.01a.586.586 0 01-.02-.83c.11-.12.26-.19.43-.18h2.12l1.24-2.46v-.01a.587.587 0 011.05-.01z"/></g>'
  }
})
