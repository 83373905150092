<template>
    <div class="relative w-full h-full group cursor-pointer group">
        <div class="absolute z-10 top-0 left-0 mt-4 ml-4">
            <img
                v-if="event.isComped"
                v-tippy
                class="w-6 h-6"
                :src="asset('images/account/rsvpify-premium.svg')"
                title="You’re selling tickets to this event and received a free upgrade to RSVPify’s professional-grade features!"
            >
        </div>

        <div class="absolute z-10 top-0 right-0 mt-4 mr-4 group-hover:bg-white rounded-full">
            <base-dropdown class="inline-block" placement="left-start">
                <template slot="trigger" slot-scope="{ triggerFunction }">
                    <a
                        role="button"
                        @click="triggerFunction"
                    >
                        <app-icon
                            name="navigation-menu-vertical"
                            class="w-7 h-7 p-1 text-snow-600 group-hover:text-gray-800 transition ease-in-out duration-150"
                            stroke
                        ></app-icon>
                    </a>
                </template>

                <div class="bg-white rounded-md border shadow cursor-pointer text-sm overflow-hidden">
                    <a :href="route('events.dashboard.overview', event)" class="px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-purple hover:bg-gray-100">
                        <app-icon
                            name="dashboard"
                            class="h-5 w-5 mr-3"
                            stroke
                        ></app-icon>
                        <p>Dashboard</p>
                    </a>

                    <a
                        v-if="hasNoIntegrations(event)"
                        :href="route('walls.edit', event)"
                        class="px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                    >
                        <app-icon
                            name="settings-slider"
                            class="h-5 w-5 mr-3"
                            stroke
                        ></app-icon>
                        <p>Setup</p>
                    </a>

                    <a
                        v-if="hasNoIntegrations(event)"
                        :href="route('publish-invite.index', event)"
                        class="px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                    >
                        <app-icon
                            name="megaphone"
                            class="h-5 w-5 mr-3"
                            stroke
                        ></app-icon>
                        <p>Publish</p>
                    </a>

                    <copy-to-clipboard
                        v-if="hasNoIntegrations(event)"
                        :copy="route('event.show', event.subdomain)"
                        @copied="showEventURLCopiedAlert"
                    >
                        <div class="px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer border-t font-medium hover:text-purple hover:bg-gray-100">
                            <app-icon
                                name="file-copy"
                                class="h-5 w-5 mr-3"
                                stroke
                            ></app-icon>
                            <p>Copy Link</p>
                        </div>
                    </copy-to-clipboard>

                    <a
                        v-if="hasNoIntegrations(event)"
                        :href="viewEventUrl(event)"
                        target="_blank"
                        class="px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                    >
                        <app-icon
                            name="view"
                            class="h-5 w-5 mr-3"
                            stroke
                        ></app-icon>
                        <p>View As Guest</p>
                    </a>

                    <duplicate-event-action
                        v-if="hasNoIntegrations(event)"
                        v-slot="{ duplicate }"
                        :event="event"
                        :number-of-used-events="numberOfUsedEvents"
                    >
                        <a
                            class="px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
                            role="button"
                            @click="duplicate"
                        >
                            <app-icon
                                name="duplicate-square"
                                class="h-5 w-5 mr-3"
                                stroke
                            ></app-icon>
                            Duplicate Event
                        </a>
                    </duplicate-event-action>

                    <feature-enabled feature="check-in">
                        <a :href="route('check-in.feed', event)" class="px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-purple hover:bg-gray-100">
                            <app-icon
                                name="technology-qr-code-scan"
                                class="h-5 w-5 mr-3"
                                stroke
                            ></app-icon>
                            Check-in
                        </a>
                    </feature-enabled>

                    <delete-item
                        v-if="event.owner.email === auth().user().email"
                        :endpoint="route('api.events.destroy', event)"
                        :confirmation-message="getDeleteEventConfirmationMessage(event)"
                        class="px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-red hover:bg-gray-100"
                        @delete-item="eventDeleted(event)"
                        @error="deleteError('Something went wrong while deleting this event.')"
                    >
                        <app-icon
                            name="trash"
                            class="h-5 w-5 mr-3"
                            stroke
                        ></app-icon>
                        <p>Delete Event</p>
                    </delete-item>

                    <unshare-item
                        v-else
                        :endpoint="route('account.shared-event-users.unshare')"
                        :post-data="{ event_id: event.id }"
                        class="px-4 py-2 transition duration-150 ease-in-out flex items-center cursor-pointer font-medium hover:text-red hover:bg-gray-100"
                        @unshare-item="eventDeleted(event.id)"
                        @error="deleteError('Something went wrong while removing shared access to this event.')"
                    >
                        <app-icon
                            name="trash"
                            class="h-5 w-5 mr-3"
                            stroke
                        ></app-icon>
                        <p>Unshare</p>
                    </unshare-item>
                </div>
            </base-dropdown>
        </div>
        <div
            class="relative pb-5/6 rounded-xl overflow-hidden"
            :style="getEventCardBackgroundColor(event)"
            @click="openEvent(event)"
        >
            <img
                v-if="getEventCardBackgroundImage(event)"
                class="absolute inset-0 h-full w-full shadow-md object-cover opacity-75 group-hover:shadow-xl group-hover:opacity-100"
                :src="getEventCardBackgroundImage(event)"
                alt=""
            >
            <img
                v-if="event.form.settings.logo_url"
                class="absolute inset-0 mx-auto my-6 mt-12 h-auto w-2/3 object-cover group-hover:opacity-100"
                :src="event.form.settings.logo_url"
                alt=""
            >
        </div>
        <div class="relative px-4 -mt-24" @click="openEvent(event)">
            <div class="bg-white rounded-xl px-4 py-4 shadow-lg group-hover:shadow-2xl">
                <div class="text-lg text-center">
                    {{ event.name }}
                </div>

                <progress-bar
                    class="mt-4 mb-2"
                    :bars="bars(event)"
                ></progress-bar>

                <div class="row items-start">
                    <div class="justify-center" :class="hasMaybeAttending(event) ? 'col-4' : 'col-6'">
                        <div class="flex flex-col items-center text-green">
                            <div class="font-medium text-2xl">
                                {{ event.attending_count | number }}
                            </div>
                            <div class="text-center uppercase tracking-wide text-xs text-gray-600">
                                Attending
                            </div>
                        </div>
                    </div>

                    <div v-if="hasMaybeAttending(event)" class="col-4 justify-center">
                        <div class="flex flex-col items-center text-yellow">
                            <div class="font-medium text-2xl">
                                {{ event.maybe_attending_count | number }}
                            </div>
                            <div class="text-center uppercase tracking-wide text-xs text-gray-600">
                                Maybe
                            </div>
                        </div>
                    </div>

                    <div class="justify-center" :class="hasMaybeAttending(event) ? 'col-4' : 'col-6'">
                        <div class="flex flex-col items-center text-red">
                            <div class="font-medium text-2xl">
                                {{ event.not_attending_count | number }}
                            </div>
                            <div class="text-center uppercase tracking-wide text-xs text-gray-600">
                                Not attending
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!hidePublishedState" class="flex items-center justify-center">
                    <event-status
                        v-if="hasNoIntegrations(event)"
                        :event="event"
                        link
                    ></event-status>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Deleteable from '@/mixins/Deleteable';

export default {
    mixins: [Deleteable],

    props: {
        event: {
            type: Object,
            required: true
        },
        hidePublishedState: {
            type: Boolean,
            default: false
        },
        numberOfUsedEvents: {
            type: Number,
            required: true
        }
    },

    methods: {
        bars (event) {
            return [
                { color: 'bg-green', current: event.attending_count, total: this.getTotalGuestCount(event) },
                { color: 'bg-yellow', current: event.maybe_attending_count, total: this.getTotalGuestCount(event) },
                { color: 'bg-red', current: event.not_attending_count, total: this.getTotalGuestCount(event) }
            ];
        },

        getTotalGuestCount (event) {
            return event.attending_count + event.not_attending_count + event.maybe_attending_count;
        },

        eventDeleted (event) {
            this.$emit('deleted', event);
        },

        hasMaybeAttending (event) {
            return event.setup_type !== 'tickets';
        },

        hasNoIntegrations (event) {
            return event.integrations.length === 0;
        },

        getDeleteEventConfirmationMessage (event) {
            return {
                title: 'Are you sure?',
                text: `Your event "${event.name}" and all associated data will be deleted. This cannot be undone.`
            };
        },

        getEventCardBackgroundColor (event) {
            return `background-color: ${event.form.settings.theme.colors.background}`;
        },

        getEventCardBackgroundImage (event) {
            if (event.wall_cover_image_url) {
                return event.wall_cover_image_url;
            }

            if (event.form.settings.background_url) {
                return event.form.settings.background_url;
            }

            return null;
        },

        openEvent (event) {
            window.location.href = this.route('events.tasks.index', event);
        },

        showEventURLCopiedAlert () {
            this.$toasted.global.success('Event URL has been copied to your clipboard.');
        },

        viewEventUrl (event) {
            return event.isPublished ? this.route('event.show', event.subdomain) : event.previewUrl;
        }
    }
};
</script>
