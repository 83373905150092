/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flag-small-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75.75v22.5M.75 2.25H22.5c.41 0 .74.33.74.75-.01.18-.07.36-.19.49l-4.01 4.5v-.01c-.51.56-.51 1.42-.01 1.99l4 4.5h0c.27.3.24.78-.07 1.05-.14.12-.32.18-.5.18H.71"/></g>'
  }
})
