/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wedding-bride-groom-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M2.665 5.22l-.01-.01a4.975 4.975 0 005.55 1.11M5.25 11.25h-.01c-2.49 0-4.5 2.01-4.5 4.5 0 0 0 0 0 0v3h2.25l.75 4.5h3l.75-4.5h2.25v-3.01a4.5 4.5 0 00-4.5-4.5h-.01zM5.25 11.25v5.25"/><path pid="1" d="M5.25 3.75a3 3 0 100 6 3 3 0 100-6zM19.835 5.22h-.01a4.994 4.994 0 01-5.56 1.11"/><path pid="2" d="M17.25 3.75a3 3 0 100 6 3 3 0 100-6z"/><path pid="3" d="M19.86 12.75h1.89l-.01-.001c.82 0 1.5-.68 1.5-1.5v-4.5 0c0-3.32-2.69-6-6-6-3.32 0-6 2.68-6 6v4.5h0c0 .82.67 1.49 1.5 1.49h1.89"/><path pid="4" d="M17.25 11.25l-.01-.001c-1.34-.01-2.52.87-2.9 2.15l-1.6 5.34h2.25l.75 4.5h3l.75-4.5h2.25l-1.6-5.35-.01-.01a3.03 3.03 0 00-2.9-2.16z"/><path pid="5" d="M19.47 12.22l-2.23 5.02s-1.88-4.6-2.23-5.03"/></g>'
  }
})
