<template>
    <div>
        <button
            class="px-4 py-2 transition duration-150 ease-in-out flex items-center w-full cursor-pointer font-medium hover:text-purple hover:bg-gray-100"
            @click="openModal"
        >
            <app-icon
                name="tags-minus"
                class="h-4 w-4 mr-3"
            ></app-icon>
            <p>Untag ({{ bulkActionData.totalSelected | number }})</p>
        </button>

        <app-modal
            v-model="showUntagModal"
            :title="modalTitle"
            :z-index="110"
        >
            <div class="h-80 overflow-y-auto">
                <div v-if="loading" class="flex items-center justify-center p-6">
                    <app-icon class="h-12 w-12 text-gray-500" name="loader"></app-icon>
                </div>
                <template v-else>
                    <template v-if="tags.length > 0">
                        <search-field
                            v-model="searchTerm"
                            class="w-full mb-2"
                            placeholder="Type to search tags..."
                        ></search-field>

                        <div
                            v-for="tag in filteredTags"
                            :key="tag.id"
                            class="py-2 tracking-wide text-sm font-semibold text-gray-600 hover:bg-gray-100 w-full cursor-pointer"
                            @click="selectTag(tag)"
                        >
                            <label>
                                <input
                                    v-model="tag.selected"
                                    type="checkbox"
                                    class="mr-1 leading-tight"
                                >
                                <span class="cursor-pointer" @click="selectTag(tag)">
                                    {{ tag.name }} ({{ tag.count }})
                                </span>
                            </label>
                        </div>
                    </template>

                    <div v-else>
                        <p class="text-center text-md font-semibold tracking-wide">
                            The selected rows do not have any tags.
                        </p>
                    </div>
                </template>
            </div>

            <template #footer="{ close }">
                <stateful-button
                    :disabled="hasSelectedTags"
                    :loading="saving"
                    class="button-primary"
                    @click="saveTags"
                >
                    {{ confirmButtonText }}
                </stateful-button>

                <button class="button" @click="close">
                    Cancel
                </button>
            </template>
        </app-modal>
    </div>
</template>

<script>
import find from 'lodash/find';
import filter from 'lodash/filter';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import axios from '@/util/axios';

export default {
    props: {
        bulkActionData: {
            type: Object,
            required: true
        },

        confirmButtonText: {
            type: String,
            default: 'Untag records(s)'
        },

        confirmationMessage: {
            type: String,
            default: 'Untagging the selected records. This may take a few minutes.'
        },

        countsEndpoint: {
            type: String,
            required: true
        },

        endpoint: {
            type: String,
            required: true
        },

        modalTitle: {
            type: String,
            default: 'Untag record(s)'
        }

    },

    data () {
        return {
            loading: true,
            tags: [],
            saving: false,
            searchTerm: null,
            showUntagModal: false
        };
    },

    computed: {
        filteredTags () {
            if (!this.searchTerm) {
                return this.tags;
            }

            return this.tags.filter((tag) => {
                const searchRegex = new RegExp(this.searchTerm, 'gi');

                return searchRegex.test(tag.name);
            });
        },

        hasSelectedTags () {
            return find(this.tags, { selected: true }) === undefined;
        }
    },

    methods: {
        getTagCounts () {
            axios.get(this.countsEndpoint, {
                params: omitBy({
                    all: this.bulkActionData.all,
                    ids: this.bulkActionData.ids,
                    filters: this.bulkActionData.filters,
                    predicates: this.bulkActionData.predicates
                }, isNil)
            })
                .then(({ data: response }) => {
                    response.data.forEach((tag) => {
                        if (tag.count === 0) {
                            return;
                        }

                        this.$set(tag, 'selected', false);
                        this.tags.push(tag);
                    });

                    this.loading = false;
                });
        },

        openModal () {
            this.tags = [];
            this.loading = true;
            this.showUntagModal = true;

            this.getTagCounts();
        },

        selectTag (tag) {
            this.$set(tag, 'selected', !tag.selected);
        },

        saveTags () {
            this.saving = true;

            axios.post(this.endpoint, {
                all: this.bulkActionData.all,
                ids: this.bulkActionData.ids,
                filters: this.bulkActionData.filters,
                predicates: this.bulkActionData.predicates,
                data: {
                    tags: map(filter(this.tags, { selected: true }), 'name')
                }
            })
                .then(() => {
                    this.$toasted.global.success(this.confirmationMessage);
                }, () => {
                    this.$toasted.global.error('An error occured untagging the records.');
                })
                .finally(() => {
                    this.$emit('rows-untagged');
                    this.showUntagModal = false;
                    this.saving = false;
                });
        }
    }
};
</script>
