import { asset } from '@codinglabs/laravel-asset';
import axios from '@/util/axios';

export default {
    props: {
        event: {
            type: Object,
            required: true
        }
    },

    methods: {
        confirmResend () {
            return new Promise((resolve) => {
                const attributes = {
                    icon: false,
                    imageUrl: asset('/images/svg/resend-confirmation.svg'),
                    imageHeight: 90
                };

                App.alert().confirm('Re-send confirmation email?', '', attributes, resolve);
            });
        },

        makeResendRequest (bulkActionData) {
            return axios.post(this.route('api.dashboard.bulk.confirmations.resend', this.event), bulkActionData)
                .then(() => {
                    this.$toasted.global.success('Resending confirmation emails. They may take a few minutes to arrive.');
                })
                .catch(() => {
                    this.$toasted.global.error('An error occurred while resending confirmation emails.');
                });
        }
    }
};
