/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-mutation-1-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12.408 1.208h-3.04M10.89 1.21v2.6M5.07 2.99L3.99 4.06 2.918 5.13M4 4.06L5.84 5.9M1.14 9.442v3.026M1.14 10.96h2.6M2.925 16.78l1.072 1.07 1.07 1.07M4 17.85l1.84-1.84M18.858 5.136l-1.08-1.08-1.08-1.08M17.79 4.06L15.95 5.9M9.42 13.79a.43.43 0 100 .86.43.43 0 100-.87zM9.375 7.27a1.51 1.51 0 100 3.03 1.51 1.51 0 100-3.04z"/><path pid="1" d="M17.3 7.791h0a7.16 7.16 0 00-9.58-3.26c-3.55 1.74-5 6.03-3.26 9.57a7.179 7.179 0 004.38 3.69M19.32 20.94H14.7h0a1.859 1.859 0 01-1.85-1.85v-4.62M16.4 12.63h4.61H21c1.01-.01 1.84.82 1.84 1.84 0 0 0 0 0 0v4.61"/><path pid="2" d="M17.474 19.099l1.846 1.84-1.846 1.846M18.243 14.484l-1.85-1.85 1.84-1.85"/></g>'
  }
})
