/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vegetables-salad': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>food appetizer vegetarian vegan</desc><g _fill="currentColor"><path pid="0" d="M23.348 14.48a.5.5 0 00-.366-.161H1.019a.5.5 0 00-.5.537C.864 19.577 5.735 23.319 12 23.319s11.135-3.742 11.48-8.463a.493.493 0 00-.132-.376zM11.449 8.506a.5.5 0 00.248.3 4.2 4.2 0 012.22 3.536.5.5 0 00.5.463h.36a.5.5 0 00.5-.487 14.056 14.056 0 011.67-6.581l.19-.379a.75.75 0 011.352.651c-.061.125-.13.261-.2.409a12.481 12.481 0 00-1.506 5.872.5.5 0 00.5.515h4.567a.5.5 0 00.377-.172 2.654 2.654 0 00.116-3.621.25.25 0 01-.022-.312 2.556 2.556 0 00-.567-3.61.251.251 0 01-.1-.28A3.117 3.117 0 0019.533.8a3.574 3.574 0 00-4.255 2.25.248.248 0 01-.225.165 2.839 2.839 0 00-2.717 3.032.25.25 0 01-.237.27 3.524 3.524 0 00-1.224.271.25.25 0 00-.1.383 4.122 4.122 0 01.674 1.335zM1.839 11.945a.384.384 0 01-.035.431.25.25 0 00.196.407h3.591a.5.5 0 00.5-.581 4.617 4.617 0 00-.71-1.812.75.75 0 011.248-.832 6.111 6.111 0 01.991 2.775.5.5 0 00.5.45h3.78a.5.5 0 00.495-.57 2.7 2.7 0 00-2.089-2.343.251.251 0 01-.185-.236 2.473 2.473 0 00-2.166-2.467.25.25 0 01-.219-.174 2.875 2.875 0 00-3.442-2.076 2.876 2.876 0 00-1.8 3.592.251.251 0 01-.092.264 2.418 2.418 0 00-.792 2.62 2.564 2.564 0 00.229.552z"/></g>'
  }
})
