/**
 * This mixin provides a way for rendered blocks that have self-editable settings
 * in the preview screen of the Wall Designer and the Form Builder (i.e. text
 * editors in the the TextBlocks) to update "themselves" without having to open
 * the Settings sidebar.
 *
 * The way this works is by syncing the properties specified in the block component
 * via the `syncSelfEditableSettings` data property to their respective properties
 * in the actual block settings object. Dynamically created watchers emit an event
 * with the settings payload after a 1s debounce.
 *
 * Upon receiving a `modified-block` event, the Wall Designer and the Form Builder
 * update the block settings object with the new settings and send an update
 * request to the API.
 */
import debounce from 'lodash/debounce';

export default {
    mounted () {
        const syncedSettings = this.syncSelfEditableSettings;

        if (syncedSettings) {
            const propertiesToSync = Object.keys(syncedSettings);

            for (let i = 0; i < propertiesToSync.length; i++) {
                const localProperty = propertiesToSync[i];
                const property = syncedSettings[localProperty];

                this.$watch(localProperty, function (value) {
                    this.emitSettingUpdate(property, value);
                });
            }
        }
    },

    methods: {
        emitSettingUpdate: debounce(function (property, value) {
            App.$emit('modified-block', {
                id: this.blockId,
                [property]: value
            });
        }, 1000)
    }
};
