/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-process-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>person task check tick stop no yes</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M5.25.75a3 3 0 100 6 3 3 0 100-6zM7.5 23.25l.75-7.5h1.5v-3 0a4.5 4.5 0 00-9 0v3h1.5l.75 7.5zM16.5 11.25h-3.75M16.5 2.25h-.76c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v15h0c0 .82.67 1.49 1.5 1.49h.75M18.75 2.25l1.5 1.5 3-3M18.75 11.25l1.5 1.5 3-3M18.75 18.75l4.5 4.5M23.25 18.75l-4.5 4.5"/></g>'
  }
})
