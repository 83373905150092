<template>
    <base-dropdown
        ref="dropdown"
        class="inline-block"
        placement="bottom-start"
        :position-fixed="false"
        trigger-class="flex items-center"
    >
        <template slot="trigger" slot-scope="{ triggerFunction, isOpen }">
            <button
                class="flex items-center justify-center text-sm text-snow-800 truncate mr-6"
                :style="{ 'font-family': selectedFont.value }"
                @click="triggerFunction"
            >
                <span>{{ selectedFont.label }}</span>

                <app-icon
                    :name="isOpen ? 'arrow-up-chevron' : 'arrow-down-chevron'"
                    class="ml-2 h-2 w-2 text-snow-800"
                    stroke
                ></app-icon>
            </button>
        </template>

        <template #default="{ triggerFunction }">
            <div v-bar="{ useScrollbarPseudo: true }" class="bg-black border-b-2 border-purple-light rounded-xl py-2 px-1 w-48 h-48 -ml-2 mt-2">
                <div>
                    <div
                        v-for="font in availableFonts"
                        :key="font.value"
                        class="w-full px-2 py-1 text-white hover:bg-smoke-300 rounded-xl text-cursor text-sm"
                        :style="{ 'font-family': font.value }"
                        @click="setFont(font, triggerFunction)"
                    >
                        {{ font.label }}
                    </div>
                </div>
            </div>
        </template>
    </base-dropdown>
</template>

<script>
import { find, sortBy } from 'lodash';

export default {
    name: 'EditorFontPicker',

    props: {
        editor: {
            type: Object,
            required: true
        },

        isMenuActive: {
            type: Boolean,
            required: true
        },

        onlyEmailFonts: {
            type: Boolean,
            default: false
        }
    },

    data () {
        const defaultFont = this.onlyEmailFonts
            ? { label: 'Arial', value: `Arial, Helvetica, sans-serif` }
            : { label: 'Open Sans', value: `"Open Sans", sans-serif` };

        return {
            defaultFont,
            selectedFont: defaultFont,
            fonts: window.fonts
        };
    },

    computed: {
        availableFonts () {
            if (this.onlyEmailFonts) {
                return this.fonts.filter((font) => {
                    return font.email;
                });
            }

            return this.fonts;
        }
    },

    watch: {
        isMenuActive (newVal) {
            if (newVal) {
                const { fontFamily } = this.editor.getMarkAttrs('fontFamily');

                if (fontFamily) {
                    this.selectedFont = find(this.fonts, { value: fontFamily }) || this.defaultFont;
                } else {
                    this.selectedFont = this.defaultFont;
                }
            }
        }
    },

    methods: {
        setFont (font, toggleDropdown) {
            this.editor.commands.fontFamily({ fontFamily: font.value });
            this.selectedFont = font;
            toggleDropdown();
        }
    }
};
</script>
