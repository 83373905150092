/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ticket-music-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 13.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM12.75 12a1.5 1.5 0 100 3 1.5 1.5 0 100-3z"/><path pid="1" d="M14.25 13.5V9h0c0-.83-.68-1.5-1.5-1.5-.13 0-.25.01-.37.04l-3 .75h-.01c-.67.16-1.14.76-1.14 1.45v5.25M17.625 15.75h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38M17.625 12h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38v-.001M17.625 8.25h-.01c.2-.01.37.16.37.37 0 .2-.17.37-.38.37s-.38-.17-.38-.38h0c-.01-.21.16-.38.37-.38"/><path pid="2" d="M21 12h0a2.24 2.24 0 012.24-2.26V5.99v0c0-.83-.68-1.5-1.5-1.5H2.23c-.83 0-1.5.67-1.5 1.5 0 0 0 0 0 0v3.75H.72c1.24-.01 2.25 1 2.25 2.24 0 1.24-1.01 2.25-2.25 2.25v3.75h0c0 .82.67 1.49 1.5 1.49h19.5-.01c.82 0 1.5-.68 1.5-1.5v-3.751c-1.25 0-2.26-1.01-2.26-2.25l-.001-.01z"/></g>'
  }
})
