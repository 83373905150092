<template>
    <date-time-picker
        v-model="internalValue"
        v-theme="['form.datetime-picker', 'form.time-picker']"
        :has-time="hasTime"
        :in24-hour="in24Hour"
        :min-datetime="null"
        :placeholder="$t('placeholder-please-choose-date')"
        class="w-full text-white"
    ></date-time-picker>
</template>

<script>
import CustomQuestion from '@/mixins/CustomQuestion';

export default {
    name: 'FormDateTimeQuestion',

    mixins: [CustomQuestion],

    computed: {
        hasTime () {
            return this.settings.format !== 'dateOnly';
        },

        in24Hour () {
            return this.settings.format !== 'twelveHour';
        },

        namespace () {
            return 'dateTime';
        }
    }
};
</script>
