<template>
    <div>
        <div v-theme="['wall.text', 'wall.background']" class="relative p-6 leading-normal">
            <div
                v-if="displayTitle"
                v-theme="['wall.title-color', 'wall.title-font', 'wall.title-font-size']"
                class="flex justify-center items-center flex-auto mb-6 font-bold leading-normal"
            >
                <app-icon
                    v-if="icon"
                    :name="icon"
                    class="h-8 w-8 mr-4"
                ></app-icon>

                {{ title }}
            </div>

            <tiptap-editor
                v-model="textHtml"
                v-theme="'wall.text'"
                :read-only="!isEditMode"
                show-embed-video-button
                class="px-8"
            ></tiptap-editor>

            <select-list
                v-model="selectedTierId"
                v-theme="'wall.select-list'"
                class="ml-4"
            >
                <select-option
                    v-for="tier in activeTiers"
                    :id="tier.id"
                    :key="tier.id"
                    class="my-4"
                >
                    <p class="text-left font-semibold">
                        {{ tier.title }}

                        <template v-if="!tier.canModifyPrice">
                            ({{ tier.price | currency }})
                        </template>
                    </p>

                    <p v-if="tier.description" class="text-sm opacity-75">{{ tier.description }}</p>

                    <div
                        v-if="isTierSelectedAndCanModifyPrice(tier)"
                        v-theme="'wall.input-focus-border'"
                        class="relative w-28 mt-1"
                    >
                        <input
                            id="button-text"
                            v-model="tier.price"
                            v-theme="'wall.text'"
                            class="form-field"
                            type="number"
                        >
                        <div v-theme="'wall.text'" class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-5 opacity-50">
                            USD
                        </div>
                    </div>
                </select-option>
            </select-list>

            <div v-if="selectedTierId !== null" class="px-14 py-6">
                <div v-if="requireDonorName" class="row mb-8">
                    <div class="col-6">
                        <form-field-wrapper
                            v-theme="'wall.input-focus-border'"
                            error="First name is required."
                            :should-show-error="errors.has('donor-first-name')"
                        >
                            <input
                                v-model="donorFirstName"
                                v-validate="'required'"
                                v-theme="'wall.text'"
                                name="donor-first-name"
                                placeholder="First Name"
                                class="form-field"
                            >
                        </form-field-wrapper>
                    </div>

                    <div class="col-6">
                        <form-field-wrapper
                            v-theme="'wall.input-focus-border'"
                            error="Last name is required."
                            :should-show-error="errors.has('donor-last-name')"
                        >
                            <input
                                v-model="donorLastName"
                                v-validate="'required'"
                                v-theme="'wall.text'"
                                name="donor-last-name"
                                placeholder="Last Name"
                                class="form-field"
                            >
                        </form-field-wrapper>
                    </div>
                </div>

                <form-field-wrapper
                    v-if="requireDonorEmail"
                    v-theme="'wall.input-focus-border'"
                    error="Email is required."
                    :should-show-error="errors.has('donor-email')"
                >
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-300">
                        <app-icon name="email" class="h-6 w-6"></app-icon>
                    </div>

                    <input
                        v-model="donorEmail"
                        v-validate="'required'"
                        v-theme="'wall.text'"
                        name="donor-email"
                        placeholder="Email Address"
                        class="form-field pr-10"
                    >
                </form-field-wrapper>

                <form-field-wrapper v-theme="'wall.input-focus-border'">
                    <textarea
                        v-model="donorNote"
                        v-theme="'wall.text'"
                        rows="3"
                        placeholder="Include a note (optional)"
                        class="form-field"
                    >
                </textarea>
                </form-field-wrapper>
            </div>

            <div class="flex justify-center p-4">
                <button
                    v-if="selectedTier"
                    v-theme="'wall.button'"
                    class="text-white p-4 rounded-md font-semibold"
                >
                    {{ buttonText }}
                </button>
            </div>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import find from 'lodash/find';
import { get } from 'vuex-pathify';
import FormatNumbers from '@/mixins/FormatNumbers';
import SelfEditableBlockMixin from '@/mixins/SelfEditableBlockMixin';
import WallBlock from '@/mixins/WallBlock';

export default {
    mixins: [FormatNumbers, SelfEditableBlockMixin, WallBlock],

    props: {
        title: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        displayTitle: {
            type: Boolean,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        rawButtonText: {
            type: String,
            required: true
        },
        tiers: {
            type: Array,
            required: true
        },
        requireDonorName: {
            type: Boolean,
            required: true
        },
        requireDonorEmail: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            textHtml: this.text,
            selectedTierId: null,
            donorFirstName: '',
            donorLastName: '',
            donorEmail: '',
            donorNote: '',
            syncSelfEditableSettings: {
                textHtml: 'text'
            }
        };
    },

    computed: {
        ...get('Wall/*'),

        activeTiers () {
            return this.tiers.filter((tier) => { return tier.active; });
        },

        buttonText () {
            if (!this.selectedTier) {
                return this.rawButtonText;
            }

            const price = this.formatCurrency(this.selectedTier.price);
            const placeholders = {
                '#AMOUNT#': `${price} USD`,
                '#TITLE#': this.selectedTier.title
            };

            return this.rawButtonText.replace(
                new RegExp(Object.keys(placeholders).join('|'), 'g'),
                (match) => { return placeholders[match]; }
            );
        },

        selectedTier () {
            return find(this.activeTiers, ['id', this.selectedTierId]);
        }
    },

    methods: {
        isTierSelected (tier) {
            return this.selectedTierId && this.selectedTierId === tier.id;
        },

        isTierSelectedAndCanModifyPrice (tier) {
            return this.isTierSelected(tier) && tier.canModifyPrice;
        }
    }
};
</script>
