/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-hygiene-clean-bottle-virus-block-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M15.529 19.971l-.01-.01a3.145 3.145 0 01-.06-4.45 3.133 3.133 0 014.44-.06l.05.05M20.89 17.749v0c0 1.73-1.41 3.14-3.15 3.14 0 0 0 0 0 0M17.23 12.25h1.04M17.75 12.25v2.36M23.25 17.23v1.04M23.25 17.75h-2.36M22.01 21.27l-.74.74M21.64 21.64l-1.67-1.67M18.27 23.25h-1.04M17.75 23.25v-2.36M12.25 18.27v-1.04M12.25 17.75h2.36M13.49 14.23l.74-.74M13.86 13.86l1.67 1.67M23.25 12.25l-11 11M8.24 20.24h-4.5l-.01-.001c-1.66-.01-3-1.35-3-3V9.738h0A2.998 2.998 0 013.72 6.72h6-.01c1.65-.01 3 1.34 3 3v.75M.75 2.252l.621-.621h-.01c.56-.57 1.32-.88 2.12-.88h7"/><path pid="1" d="M9.74 6.751h-6v-1.5h0c-.01-.83.67-1.51 1.49-1.51h3-.01c.82-.01 1.5.67 1.5 1.5zM6.75 3.75v-3M6.75 9.75v6M3.75 12.75h6"/></g>'
  }
})
