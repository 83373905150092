<template>
    <a
        role="button"
        class="flex flex-col items-center py-4 px-2 bg-white border hover:border-purple-light hover:shadow-md cursor-pointer rounded-md"
    >
        <app-icon :name="icon" class="h-8 w-8"></app-icon>
        <div v-if="title" class="uppercase tracking-wide text-xs font-semibold mt-4">{{ title }}</div>
    </a>
</template>

<script>
export default {
    name: 'IconTextBox',

    props: {
        icon: {
            type: String,
            required: true
        },

        title: {
            type: String,
            default: ''
        }
    }
};
</script>
