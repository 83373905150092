/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-social-distancing-not-allowed-space-man-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 .75a3 3 0 100 6 3 3 0 100-6zM17.25 13.5v0a5.25 5.25 0 10-10.5 0v2.25H9l.75 7.5h4.5l.75-7.5h2.25zM1 18.99l4 4M5 18.99l-4 4M19 18.99l4 4M23 18.99l-4 4"/></g>'
  }
})
