<template>
    <div class="flex-1 p-8">
        <div class="panel flex flex-col">
            <page-header icon="app-page-account-stroke" :label="`Manage Pages > ${initialPlanSegment.heading}`">
                <a :href="route('admin.plans.segments.index')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <div class="flex">
                <div class="w-4/5">
                    <div class="flex mb-4">
                        <div class="flex-1 mr-8">
                            <form-field-wrapper label="Page Heading" :error="form.errors.get('heading')">
                                <input
                                    v-model="form.heading"
                                    type="text"
                                    class="form-field"
                                    placeholder="Choose a plan"
                                >
                            </form-field-wrapper>
                        </div>

                        <div class="flex-1 mr-8">
                            <form-field-wrapper label="External category name" :error="form.errors.get('label')">
                                <input
                                    v-model="form.label"
                                    type="text"
                                    class="form-field"
                                >
                            </form-field-wrapper>
                        </div>

                        <div class="flex-1 mr-8">
                            <form-field-wrapper label="Accessible Plan Pages" :error="form.errors.get('linked_plan_id')">
                                <select-list
                                    v-model="form.settings.additional_plan_segments"
                                    class="flex flex-wrap"
                                    multi-select
                                >
                                    <select-option
                                        v-for="{ id, heading } in allPlanSegments"
                                        :id="id"
                                        :key="id"
                                        class="w-1/2 mt-2"
                                    >
                                        {{ heading }}
                                    </select-option>
                                </select-list>
                            </form-field-wrapper>
                        </div>

                        <div class="flex-1 mr-8">
                            <form-field-wrapper label="Default Pricing" :error="form.errors.get('default_pricing')">
                                <select-list
                                    v-model="form.settings.default_pricing"
                                >
                                    <select-option id="monthly" class="mt-2">
                                        Monthly
                                    </select-option>
                                    <select-option id="yearly" class="mt-2">
                                        Yearly
                                    </select-option>
                                </select-list>
                            </form-field-wrapper>
                            <form-field-wrapper label="Yearly Wording" :error="form.errors.get('yearly_wording')">
                                <input
                                    v-model="form.settings.yearly_line_1"
                                    type="text"
                                    class="form-field"
                                    placeholder="annual"
                                >
                                <input
                                    v-model="form.settings.yearly_line_2"
                                    type="text"
                                    class="form-field"
                                    placeholder="save up to 40%"
                                >
                            </form-field-wrapper>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-full mt-8">
                <div class="flex items-center mb-4">
                    <div class="w-20"></div>
                    <div class="font-bold uppercase text-gray-600 w-48 ml-4 text-center">Monthly</div>
                    <div class="font-bold uppercase text-gray-600 w-48 ml-4 text-center">Yearly</div>
                </div>

                <div
                    v-for="(spot, index) in form.spots"
                    :key="index"
                >
                    <div class="flex items-center mt-2">
                        <div class="font-bold uppercase text-gray-600 w-20">Spot {{ index + 1 }}</div>

                        <simple-picker
                            v-model="spot.plan_id"
                            :items="availablePlans"
                            item-value="id"
                            class="w-48 ml-4"
                            is-filterable
                            filterable-property="name"
                        >
                            <template #input="{ selected }">
                                {{ selected.internal_name }} ({{ selected.price | currency(true) }})
                            </template>

                            <template #item="{ item }">
                                {{ item.internal_name }} ({{ item.price | currency(true) }})
                            </template>
                        </simple-picker>

                        <simple-picker
                            v-model="spot.annual_plan_id"
                            :items="availablePlans"
                            item-value="id"
                            class="w-48 ml-4"
                            is-filterable
                            filterable-property="name"
                        >
                            <template #input="{ selected }">
                                {{ selected.internal_name }} ({{ selected.price | currency(true) }})
                            </template>

                            <template #item="{ item }">
                                {{ item.internal_name }} ({{ item.price | currency(true) }})
                            </template>
                        </simple-picker>

                        <div class="flex items-center ml-4">
                            <label :for="`highlight-as-${index}`" class="flex items-center">
                                <input
                                    :id="`highlight-as-${index}`"
                                    :checked="!!spot.highlighted_as"
                                    type="checkbox"
                                    class="mr-2"
                                    disabled
                                > Highlight as:

                                <input
                                    ref="highlightedAs"
                                    v-model="spot.highlighted_as"
                                    type="text"
                                    class="form-field w-32 ml-2"
                                >
                            </label>

                        </div>

                        <div class="flex items-center ml-4">
                            <label :for="`contact-us-${index}`">
                                <input
                                    :id="`contact-us-${index}`"
                                    v-model="spot.requires_contact"
                                    type="checkbox"
                                    class="mr-2"
                                > Make CTA &quot;Chat Now&quot;
                            </label>
                        </div>

                        <div class="flex items-center ml-4">
                            <label :for="`show-starting-at-${index}`">
                                <input
                                    :id="`show-starting-at-${index}`"
                                    v-model="spot.show_starting_at"
                                    type="checkbox"
                                    class="mr-2"
                                > Show &quot;starting at&quot;
                            </label>
                        </div>

                        <div class="flex items-center ml-4">
                            <label :for="`show-anchor-link-${index}`">
                                <input
                                    :id="`show-anchor-link-${index}`"
                                    v-model="spot.show_anchor_link"
                                    type="checkbox"
                                    class="mr-2"
                                > Show anchor link
                            </label>
                        </div>

                        <div class="flex items-center ml-4">
                            <label :for="`hide-cta-${index}`">
                                <input
                                    :id="`hide-cta-${index}`"
                                    v-model="spot.hide_cta"
                                    type="checkbox"
                                    class="mr-2"
                                > Hide CTA
                            </label>
                        </div>

                        <div class="flex items-center ml-4">
                            <label :for="`non-profit-${index}`">
                                <input
                                    :id="`non-profit-${index}`"
                                    v-model="spot.non_profit_cta"
                                    type="checkbox"
                                    class="mr-2"
                                > Non-profit CTA
                            </label>
                        </div>
                    </div>

                    <div v-if="form.errors.has(`spots.${index}.plan_id`)" class="alert alert-error alert-sm mt-2">
                        <p class="w-full font-normal text-center">{{ form.errors.get(`spots.${index}.plan_id`) }}</p>
                    </div>
                </div>
            </div>

            <form-field-wrapper
                label="Comparison chart title"
                class="mt-8"
                :error="form.errors.get('chart_title')"
            >
                <input
                    v-model="form.chart_title"
                    type="text"
                    class="form-field"
                ></input>
            </form-field-wrapper>

            <form-field-wrapper
                label="Comparison chart HTML"
                class="mt-8"
                :error="form.errors.get('chart_html')"
            >
                <textarea v-model="form.chart_html" class="form-field"></textarea>
            </form-field-wrapper>

            <form-field-wrapper
                label="Logos slider title"
                class="mt-8"
                :error="form.errors.get('slider_title')"
            >
                <input
                    v-model="form.slider_title"
                    type="text"
                    class="form-field"
                ></input>
            </form-field-wrapper>

            <form-field-wrapper
                label="Logos slider HTML"
                class="mt-8"
                :error="form.errors.get('slider_html')"
            >
                <textarea v-model="form.slider_html" class="form-field"></textarea>
            </form-field-wrapper>

            <form-field-wrapper
                label="FAQs title"
                class="mt-8"
                :error="form.errors.get('faqs_title')"
            >
                <input
                    v-model="form.faqs_title"
                    type="text"
                    class="form-field"
                ></input>
            </form-field-wrapper>

            <form-field-wrapper
                label="Faqs HTML"
                class="mt-8"
                :error="form.errors.get('faqs_html')"
            >
                <textarea v-model="form.faqs_html" class="form-field"></textarea>
            </form-field-wrapper>

            <form-field-wrapper label="Headline" class="mt-8">
                <textarea v-model="form.shared_users_widget_headline" class="form-field"></textarea>
            </form-field-wrapper>

            <form-field-wrapper label="Sub-heading" class="mt-8">
                <textarea v-model="form.shared_users_widget_subheading" class="form-field"></textarea>
            </form-field-wrapper>

            <div class="w-full flex mt-8 space-x-8">
                <div class="flex-1">
                    <form-field-wrapper label="Trusted by label">
                        <input
                            v-model="form.settings.trusted_by_label"
                            type="text"
                            class="form-field"
                        >
                    </form-field-wrapper>
                </div>

                <div class="flex-1">
                    <form-field-wrapper label="Trusted by image">
                        <image-uploader @upload-image="trustedByImageUploaded">
                            <a class="button-text button-info" role="button">
                                <template v-if="!form.settings.trusted_by_image_src">
                                    <app-icon
                                        name="add-circle"
                                        class="h-6 w-6 mr-2"
                                        stroke
                                    ></app-icon>

                                    <span>Add Image</span>
                                </template>

                                <template v-else>
                                    <app-icon
                                        name="trash"
                                        class="h-6 w-6 mr-2"
                                    ></app-icon>

                                    <span>Replace Image</span>
                                </template>
                            </a>
                        </image-uploader>

                        <img
                            v-if="trustedByImagePreview"
                            :src="trustedByImagePreview"
                            class="w-1/3 mt-4"
                        >
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex flex-row-reverse mt-8">
                <stateful-button
                    class="button-primary"
                    :loading="form.processing"
                    @click="save"
                >Save</stateful-button>

                <a class="button mr-2" :href="route('admin.plans.segments.index')">Cancel</a>
            </div>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import times from 'lodash/times';
import Vapor from '@node_modules/laravel-vapor';
import Form from '@/validation/Form';
import axios from '@/util/axios';

export default {
    name: 'PlanSegmentEditor',

    props: {
        allPlans: {
            type: Array,
            required: true
        },
        allPlanSegments: {
            type: Array,
            required: true
        },
        initialPlanSegment: {
            type: Object,
            default: null
        },
        initialSpots: {
            type: Array,
            default: () => { return []; }
        }
    },

    data () {
        const { spots } = this.initialPlanSegment;

        spots.push(...times(5 - spots.length, (index) => {
            return {
                plan_segment_id: this.initialPlanSegment.id,
                plan_id: null,
                annual_plan_id: null,
                sort: index,
                highlighted_as: null,
                requires_contact: false,
                show_starting_at: false,
                show_anchor_link: false
            };
        }));

        return {
            form: new Form({
                ...cloneDeep(this.initialPlanSegment),
                trusted_by_image: null,
                spots
            })
        };
    },

    computed: {
        availablePlans () {
            return [{ name: 'None', id: null }, ...this.allPlans];
        },

        trustedByImagePreview () {
            if (this.form.trusted_by_image) {
                return this.form.trusted_by_image.src;
            }

            return this.form.trusted_by_image_url;
        }
    },

    methods: {
        async trustedByImageUploaded (image) {
            const file = new File([image.file], image.fileName, {
                type: image.file.type
            });

            this.form.trusted_by_image = {
                src: image.src,
                filename: image.fileName,
                ...await Vapor.store(file)
            };
        },

        save () {
            this.form.put(this.route('api.admin.plans.segments.update', this.initialPlanSegment))
                .then(() => {
                    window.location.href = this.route('admin.plans.segments.index');
                })
                .catch(() => {
                    this.$toasted.global.error('There was an error saving the plan segment.');
                });
        }
    }
};
</script>
