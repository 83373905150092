<template>
    <section class="panel">
        <page-header icon="calendar-share-stroke" label="Social Network Sharing">
            <a :href="route('settings.event', event)" class="button-icon button-sm">
                <app-icon name="close"></app-icon>
            </a>
        </page-header>

        <item-modified-warning v-if="form.isModified()"></item-modified-warning>

        <div class="row">
            <div class="col-12 md:col-6 flex-col">
                <form-field-wrapper
                    label="Title"
                    :should-show-error="form.errors.has('title')"
                    :error="form.errors.get('title')"
                >
                    <input v-model="form.title" class="form-field">
                </form-field-wrapper>

                <form-field-wrapper
                    label="Description"
                    :should-show-error="form.errors.has('description')"
                    :error="form.errors.get('description')"
                >
                    <textarea
                        v-model="form.description"
                        class="form-field"
                        rows="4"
                    ></textarea>
                </form-field-wrapper>

                <form-field-wrapper
                    label="Image"
                    :should-show-error="form.errors.has('imageToUse')"
                    :error="form.errors.get('imageToUse')"
                >
                    <select-list v-model="form.imageToUse">
                        <select-option
                            id="default"
                            class="my-2"
                            :disabled="!hasDefaultImage"
                            @click.native="useDefaultImage"
                        >
                            Default
                        </select-option>

                        <select-option
                            id="custom"
                            class="my-2"
                            tooltip="For the best display on high resolution devices, upload an image that is at least 1200 x 630 pixels."
                        >
                            <span class="border-b border-dashed border-gray-500 cursor-help">Custom</span>
                        </select-option>
                    </select-list>

                    <fancy-image-uploader
                        v-if="useCustomImage"
                        v-slot="{ launch }"
                        class="flex"
                        @select-image="url => form.imageUrl = url"
                    >
                        <button class="button-text button-info mt-4" @click="launch">
                            <app-icon
                                name="add-circle"
                                class="h-6 w-6 mr-2"
                                stroke
                            ></app-icon>
                            {{ imageUploadText }}
                        </button>
                    </fancy-image-uploader>
                </form-field-wrapper>
            </div>

            <div class="col-12 md:col-6 flex-col justify-start items-start mt-2 md:mt-0">
                <div class="flex flex-col w-full md:max-w-xl">
                    <p class="text-sm text-center font-semibold text-gray-600 mb-2">Preview</p>

                    <div class="border">
                        <div class="flex items-center justify-center h-80">
                            <img class="h-full w-full object-cover" :src="previewImage">
                        </div>

                        <div class="flex flex-col p-2 border-t">
                            <p class="text-lg font-semibold">{{ title }}</p>
                            <p class="text-gray-600">{{ description }}</p>
                        </div>
                    </div>
                </div>

                <p
                    v-tippy
                    content="Most social networks temporarily store (“cache”) the image set when your event is shared for the first time on a given network. Social networks will typically start to use a newly-applied image within 2 to 24 hours for new shares. Changes to your sharing settings are not retroactive, and will apply to future shares only."
                    class="mt-2 text-sm text-gray-600 cursor-help border-b border-dashed border-gray-500"
                >
                    Why am I still seeing the old image when sharing?
                </p>
            </div>
        </div>

        <div class="flex flex-col md:flex-row justify-end mt-6">
            <button
                v-if="form.isModified()"
                class="button w-full md:w-auto"
                @click="form.restore()"
            >
                Cancel
            </button>

            <stateful-button
                class="button-primary mt-2 md:mt-0 ml-0 md:ml-2 w-full md:w-auto"
                :loading="form.processing"
                :disabled="!form.isModified()"
                @click="saveSettings"
            >
                Save
            </stateful-button>
        </div>
    </section>
</template>

<script>
import get from 'lodash/get';
import { DateTime } from 'luxon';
import Form from '@/validation/Form';

export default {
    name: 'SocialNetworkSharingSettings',

    props: {
        event: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            form: new Form({
                title: get(this.event.settings, 'socialNetworkSharing.title', '{{event_name}} - RSVPify'),
                description: get(this.event.settings, 'socialNetworkSharing.description', '{{event_date}} - {{event_location}}'),
                imageToUse: get(this.event.settings, 'socialNetworkSharing.imageToUse', 'custom'),
                imageUrl: this.event.socialNetworkSharingImageUrl
            })
        };
    },

    computed: {
        description () {
            return this.replacePlaceholders(this.form.description);
        },

        firstCoverImage () {
            return this.event.wall.blocks.find((block) => {
                return block.slug === 'cover';
            }).pivot.settings.slider.images[0];
        },

        formattedStartDate () {
            if (!this.event.starts_at) {
                return '';
            }

            return DateTime.fromISO(this.event.starts_at).setZone('UTC').toFormat('LLLL d, yyyy @ h:mm a');
        },

        hasDefaultImage () {
            return this.firstCoverImage || this.event.form.settings.background_url;
        },

        imageUploadText () {
            return this.previewImage ? 'Replace Image' : 'Add Image';
        },

        previewImage () {
            if (this.useCustomImage && this.form.imageUrl) {
                return this.form.imageUrl;
            }

            if (this.firstCoverImage) {
                return this.firstCoverImage.src;
            }

            if (this.event.form.settings.background_url) {
                return this.event.form.settings.background_url;
            }

            return this.event.defaultSocialNetworkSharingImageUrl;
        },

        title () {
            return this.form.title ? this.replacePlaceholders(this.form.title) : this.event.name;
        },

        useCustomImage () {
            return this.form.imageToUse === 'custom';
        }
    },

    created () {
        this.setDefaultImageToUseValue();
    },

    methods: {
        replacePlaceholders (text) {
            const placeholderReplacements = {
                '{{event_name}}': this.event.name,
                '{{event_date}}': this.formattedStartDate,
                '{{event_location}}': this.event.location ? this.event.location : ''
            };

            Object.keys(placeholderReplacements).forEach((item) => {
                text = text.replace(new RegExp(item, 'g'), placeholderReplacements[item]);
            });

            return text;
        },

        saveSettings () {
            this.form.put(this.route('api.settings.event.social-network-sharing.update', this.event))
                .then(() => {
                    this.$toasted.global.success('Social network sharing settings have been saved.');
                    window.location.reload();
                });
        },

        setDefaultImageToUseValue () {
            if (!get(this.event.settings, 'socialNetworkSharing.imageToUse') && this.hasDefaultImage) {
                this.form.imageToUse = 'default';
                this.form.originalData.imageToUse = 'default';
            }
        },

        useDefaultImage () {
            if (!this.form.imageUrl || !this.hasDefaultImage) {
                return;
            }

            const attributes = {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'question'
            };

            const onCancel = () => {
                this.form.imageToUse = 'custom';
            };

            const onConfirm = () => {
                this.deleteImage();
            };

            App.alert().confirm(
                'Are you sure?',
                'By selecting this option, the old social network sharing image will be removed.',
                attributes,
                onConfirm,
                onCancel
            );
        }
    }
};
</script>
