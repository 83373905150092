/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-square-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>ok yes correct done task</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6 13.22l2.45 3.47h0c.32.48.97.61 1.45.28.09-.07.18-.15.25-.24l7.84-9.93"/><rect pid="1" width="22.5" height="22.5" x=".75" y=".749" rx="1.5"/></g>'
  }
})
