/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'question-circle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 9a3 3 0 114 2.829 1.5 1.5 0 00-1 1.415v1.006M12 17.25a.375.375 0 10.375.375.375.375 0 00-.375-.375h0" stroke-linecap="round" stroke-linejoin="round" _fill="none" _stroke="currentColor" stroke-width="1.5"/><circle pid="1" cx="12" cy="12" r="11.25" stroke-miterlimit="10" _fill="none" _stroke="currentColor" stroke-width="1.5"/>'
  }
})
