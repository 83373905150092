<template>
    <div>
        <div v-theme="['wall.text', 'wall.background']" class="relative p-6 leading-normal">
            <div
                v-if="displayTitle"
                v-theme="['wall.title-color', 'wall.title-font', 'wall.title-font-size']"
                class="flex justify-center items-center flex-auto mb-6 font-bold leading-normal"
            >
                <app-icon
                    v-if="icon"
                    :name="icon"
                    class="h-8 w-8 mr-4"
                ></app-icon>

                {{ title }}
            </div>

            <div v-if="showIntroText" class="my-4">
                <tiptap-editor
                    v-model="textHtml"
                    v-theme="'wall.text'"
                    :read-only="!isEditMode"
                    show-embed-video-button
                    class="px-8"
                ></tiptap-editor>
            </div>

            <div class="flex row justify-center">
                <div
                    v-for="store in activeStores"
                    :key="store.id"
                    class="col-4 mb-6 justify-center"
                >
                    <a
                        v-theme="'wall.hoverable-accent-border'"
                        :href="normalizeUrl(store.url)"
                        target="_blank"
                        class="flex items-center justify-center h-full w-40 border hover:shadow-md rounded-md"
                        rel="nofollow"
                    >
                        <img
                            v-if="store.images[0]"
                            class="w-full object-cover rounded-md p-1"
                            :src="store.images[0].src"
                        >
                        <div
                            v-else
                            v-theme="'wall.text'"
                            class="flex text-center min-h-xs break-anywhere items-center font-semibold text-lg"
                        >
                            {{ store.name }}
                        </div>
                    </a>
                </div>
            </div>

            <feature-enabled feature="wall-donations">
                <div v-if="acceptingCashGifts" class="flex flex-col my-4">
                    <div class="mb-4">
                        <tiptap-editor
                            v-model="textHtml"
                            v-theme="'wall.text'"
                            :read-only="!isEditMode"
                            show-embed-video-button
                            class="px-8"
                        ></tiptap-editor>
                    </div>

                    <div class="flex justify-center">
                        <div v-theme="'wall.input-focus-border'" class="relative">
                            <input
                                id="button-text"
                                v-model="cashGiftAmount"
                                class="appearance-none block w-full text-gray-600 font-semibold border-b-2 rounded-none py-1 bg-transparent focus:outline-none"
                                type="number"
                                min="0"
                            >
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-500">
                                USD
                            </div>
                        </div>
                    </div>

                    <div v-if="hasCashGift" class="px-14 py-6">
                        <div class="row mb-8">
                            <div v-theme="'wall.input-focus-border'" class="col-6 flex-col">
                                <input
                                    v-model="donorFirstName"
                                    v-validate="'required'"
                                    name="donor-first-name"
                                    placeholder="First Name"
                                    class="form-field"
                                >

                                <p v-if="errors.has('donor-first-name')" class="text-center text-red text-sm mt-2">
                                    First name is required.
                                </p>
                            </div>

                            <div v-theme="'wall.input-focus-border'" class="col-6 flex-col">
                                <input
                                    v-model="donorLastName"
                                    v-validate="'required'"
                                    name="donor-last-name"
                                    placeholder="Last Name"
                                    class="form-field"
                                >

                                <p v-if="errors.has('donor-last-name')" class="text-center text-red text-sm mt-2">
                                    Last name is required.
                                </p>
                            </div>
                        </div>

                        <form-field-wrapper v-theme="'wall.input-focus-border'">
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-300">
                                <app-icon name="email" class="h-6 w-6"></app-icon>
                            </div>

                            <input
                                v-model="donorEmail"
                                v-validate="'required'"
                                name="donor-email"
                                placeholder="Email Address"
                                class="form-field pr-10"
                            >
                        </form-field-wrapper>
                        <p v-if="errors.has('donor-email')" class="text-center text-red text-sm mt-2 mb-8">
                            Email is required.
                        </p>

                        <form-field-wrapper v-theme="'wall.input-focus-border'">
                            <textarea
                                v-model="donorNote"
                                rows="3"
                                placeholder="Include a note (optional)"
                                class="form-field"
                            ></textarea>
                        </form-field-wrapper>
                    </div>

                    <div v-if="hasCashGift" class="flex justify-center mx-14 py-4">
                        <button v-theme="'wall.button'" class="text-white py-2 px-4 rounded-md font-semibold">
                            {{ buttonText }}
                        </button>
                    </div>
                </div>
            </feature-enabled>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import { get } from 'vuex-pathify';
import SelfEditableBlockMixin from '@/mixins/SelfEditableBlockMixin';
import WallBlock from '@/mixins/WallBlock';
import NormalizesUrls from '@/mixins/NormalizesUrls';

export default {
    name: 'WallGiftRegistryBlock',

    mixins: [
        SelfEditableBlockMixin,
        WallBlock,
        NormalizesUrls
    ],

    props: {
        acceptingCashGifts: {
            type: Boolean,
            required: true
        },
        cashGiftText: {
            type: String,
            required: true
        },
        displayTitle: {
            type: Boolean,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        initialCashGiftAmount: {
            type: Number,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        rawButtonText: {
            type: String,
            required: true
        },
        stores: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            cashGiftAmount: this.initialCashGiftAmount,
            donorFirstName: '',
            donorLastName: '',
            donorEmail: '',
            donorNote: '',
            textHtml: this.text,
            cashGiftTextHtml: this.cashGiftText,
            syncSelfEditableSettings: {
                textHtml: 'text',
                cashGiftTextHtml: 'cashGiftText'
            }
        };
    },

    computed: {
        ...get('Wall/*'),

        activeStores () {
            return this.stores.filter((store) => { return store.active; });
        },

        buttonText () {
            if (!this.cashGiftAmount) {
                return this.rawButtonText;
            }

            return this.rawButtonText.replace('#AMOUNT#', this.cashGiftAmount);
        },

        showIntroText () {
            return this.stores.length > 0;
        },

        hasCashGift () {
            return this.cashGiftAmount;
        }
    }
};
</script>
