<template>
    <div
        class="relative flex"
        :class="{ 'bg-smoke-300': auth().user().impersonated }"
        @mouseenter="toggle"
        @mouseleave="toggle"
    >
        <a
            href="#"
            class="no-underline text-white flex py-3 px-6 items-center space-x-3"
        >
            <img
                v-if="user.avatar_url"
                :src="user.avatar_url"
                class="rounded-full w-9 h-9"
                alt="user avatar image"
            >

            <app-icon
                v-else
                name="user-circle"
                class="w-9 h-9 text-purple-200"
                stroke
            ></app-icon>

            <div class="flex flex-col">
                <span v-if="auth().user().impersonated" class="text-xs text-gray-500 uppercase">Impersonating</span>
                <div class="text-ellipsis overflow-hidden whitespace-nowrap max-w-52"> {{ user.name }} </div>
            </div>

            <app-icon
                name="arrow-down-chevron"
                class="h-4 w-4 ml-6 text-gray-500"
                stroke
            ></app-icon>
        </a>
        <base-nav v-if="show" :links="links"></base-nav>

        <form
            ref="logoutForm"
            :action="route('logout')"
            method="POST"
        >
            <input
                type="hidden"
                name="_token"
                :value="csrfToken"
            >
        </form>
    </div>
</template>

<script>
import ReadsCsrfToken from '@/mixins/ReadsCsrfToken';

export default {
    name: 'NavUser',

    mixins: [ReadsCsrfToken],

    props: {
        user: {
            type: Object,
            default: () => { return ([]); }
        }
    },

    data () {
        return {
            show: false
        };
    },

    computed: {
        hideAdminDashboardOption () {
            return !this.auth().user().isAdmin
                && !this.auth().user().isJuniorAdmin
                && !this.auth().user().isLimitedAdmin;
        },

        links () {
            return [
                {
                    title: 'My Account',
                    icon: 'user-circle-stroke',
                    url: this.route('account.show')
                },
                {
                    title: 'Event Sharing',
                    icon: 'users-location-stroke',
                    url: this.route('account.shared-event-users')
                },
                {
                    title: 'Get Support',
                    icon: 'help-chat-alt-stroke',
                    url: 'https://help.rsvpify.com',
                    target: '_blank'
                },
                {
                    title: 'Community Forum',
                    icon: 'community-chat',
                    url: 'https://community.rsvpify.com',
                    target: '_blank'
                },
                {
                    title: 'Admin Dashboard',
                    icon: 'dashboard-stroke',
                    url: this.route('admin.dashboard'),
                    hidden: this.hideAdminDashboardOption
                },
                {
                    title: 'Back to Admin',
                    icon: 'dashboard-stroke',
                    url: this.route('admin.leave-impersonation'),
                    hidden: !this.auth().user().impersonated
                },
                {
                    title: 'Sign Out',
                    icon: 'power-button-stroke',
                    url: '/logout',
                    action: this.logout
                }
            ];
        }
    },

    methods: {
        toggle () {
            this.show = !this.show;
        },

        logout () {
            this.$refs.logoutForm.submit();
        }
    }
};
</script>
