/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'megaphone-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>announce</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.75 15.25H4.5l-.01-.001c-2.08-.01-3.75-1.68-3.75-3.76s1.67-3.75 3.75-3.75h2.24z"/><path pid="1" d="M6.75 15.25h0c4.4 0 8.7 1.3 12.36 3.74l1.13.75V3.24l-1.14.75h-.01A22.335 22.335 0 016.72 7.73zM23.25 10v3M6.75 15.25h-.01a7.17 7.17 0 002.25 5.24"/></g>'
  }
})
