/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'alert-triangle-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>exclamation warning notification</desc><path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 18.75h-.01c-.21 0-.38.16-.38.37 0 .2.16.37.37.37.2-.01.37-.17.37-.38v0c0-.21-.17-.38-.38-.375v0"/><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none"><path pid="1" d="M12 15.75v-7.5"/><path pid="2" stroke-linejoin="round" d="M13.62 1.76h0C13.18.86 12.09.49 11.2.93c-.36.17-.65.46-.83.82L.89 21.04H.88a1.509 1.509 0 001.36 2.18h19.45a1.52 1.52 0 001.36-2.2z"/></g>'
  }
})
