/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'office-business-card-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M11.25 5.62h11.02-.01c.53-.01.97.43.97.97v14.55-.01c-.01.53-.45.97-.98.97H1.7v-.001a.98.98 0 01-.98-.97V6.56H.71c0-.54.44-.98.97-.98H5.2M12 12.37h8.25M6.75 16.87h13.5"/><path pid="1" d="M3.75 5.62V4.11c0-1.25 1-2.25 2.25-2.25h-.02c1.24-.01 2.25 1 2.25 2.24v6.75c0 .82-.68 1.5-1.5 1.5v0l-.01-.001a1.51 1.51 0 01-1.5-1.5V8.59"/></g>'
  }
})
