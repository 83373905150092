/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'safety-exit-door-left': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.25 1.5L18 3.75 20.25 6M18 3.75h5.25M23.25 18.238a1.5 1.5 0 00-1.5-1.5h-1.639a1.5 1.5 0 01-1.128-.512l-3.738-4.273.594-.424a1.494 1.494 0 01.872-.279h3.539a1.5 1.5 0 000-3h-3.539a4.468 4.468 0 00-2.615.838l-3.685 2.634a1.493 1.493 0 01-.872.278H7.5a1.5 1.5 0 000 3h2.039a4.468 4.468 0 002.615-.838l.638-.456 1.708 1.957-1.565 1.253a4.475 4.475 0 00-1.689 3.513v1.321a1.5 1.5 0 003 0v-1.321a1.487 1.487 0 01.564-1.17l1.668-1.335.244.278a4.5 4.5 0 003.385 1.536h1.643a1.5 1.5 0 001.5-1.5z"/><circle pid="1" cx="9.75" cy="6" r="2.625" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="2" d="M14.25 2.25a1.5 1.5 0 00-1.5-1.5H2.25a1.5 1.5 0 00-1.5 1.5v19.5a1.5 1.5 0 001.5 1.5h4.5" _fill="none" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>'
  }
})
