/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'messages-bubble-double-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>chat</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M23.25 13.5v0c0-2.9-2.36-5.25-5.25-5.25h-3.01a5.25 5.25 0 100 10.5h.75l4.5 4.5v-5.04a5.252 5.252 0 002.99-4.73zM6.75 12.75l-3 3v-5.03l-.01-.01a5.239 5.239 0 01-2.5-6.98A5.229 5.229 0 015.98.72h3L8.97.71A5.25 5.25 0 0114 4.46"/></g>'
  }
})
