<template>
    <div class="flex items-center my-2">
        <div
            v-if="!block.pivot"
            v-class-when-screen:gt.md="'draggable-handle'"
            class="min-h-12 flex grow items-center p-2 pl-3 rounded-sm italic text-sm bg-white border draggable-block mr-7"
        >
            <div class="flex-auto text-center text-gray-400">
                <app-icon name="loading-half" class="spinning h-5 w-5"></app-icon>
            </div>
        </div>
        <div
            v-else
            class="flex w-full items-center"
        >
            <div
                v-class-when-screen:gt.md="'draggable-handle'"
                class="min-h-12 flex grow items-center font-semibold p-2 pl-3 rounded-md text-sm bg-white border border-gray-300 draggable-block"
                :class="draggableBlockClass"
            >
                <div class="flex-auto">
                    {{ menuTitle }}
                </div>

                <div
                    v-if="block.properties.mobileOnly"
                    v-tippy
                    class="shrink mr-2 leading-0"
                    content="This block is only visible on mobile screens. Please switch to the Mobile Preview in order to see it."
                >
                    <app-icon
                        name="technology-mobile"
                        class="shrink h-5 w-5 text-gray-500"
                        stroke
                    ></app-icon>
                </div>

                <div v-if="block.properties.toggleable" class="leading-none mr-2">
                    <toggle-switch
                        :value="block.pivot.settings.isDisplayed"
                        small
                        @input="toggleVisibility"
                    ></toggle-switch>
                </div>

                <settings-button
                    v-if="block.properties.editable && isBlockDisplayed"
                    :block="block"
                    :selection-data="{ scrollToBlock: true }"
                ></settings-button>

                <div
                    v-class-when-screen:lt.md="'draggable-handle'"
                    class="shrink ml-2 text-gray-500"
                    :class="{ invisible: !movable }"
                >
                    <app-icon
                        name="move-vertical-arrows"
                        class="h-5 w-5"
                        stroke
                    ></app-icon>
                </div>
            </div>

            <button
                class="shrink ml-2 text-gray-500 transition duration-150 ease-in-out hover:text-red cursor-pointer"
                :class="{ invisible: !block.properties.removable }"
                @click="deleteBlock"
            >
                <app-icon
                    name="trash"
                    class="h-5 w-5"
                    stroke
                ></app-icon>
            </button>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import DeterminesBlockMenuTitle from '@/mixins/DeterminesBlockMenuTitle';

export default {
    name: 'AddedWallBlockItem',

    mixins: [DeterminesBlockMenuTitle],

    props: {
        block: {
            type: Object,
            required: true
        },
        movable: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        draggableBlockClass () {
            return this.movable ? 'cursor-grab' : 'locked';
        },

        menuTitle () {
            return this.getMenuTitle(this.block);
        },

        isBlockDisplayed () {
            return get(this.block, 'pivot.settings.isDisplayed', true);
        }
    },

    methods: {
        deleteBlock () {
            this.$emit('delete', this.block);
        },

        toggleVisibility (value) {
            App.$emit('modified-block', {
                id: this.block.pivot.id,
                isDisplayed: value
            });
        }
    }
};
</script>
