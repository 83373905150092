/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outdoors-landscape-meadow-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M.75 15.75v7.5M12 15.75v7.5M23.25 15.75v7.5M23.25 18H.75M23.25 21H.75M1.498 12.75l6.75-4.5 6.75 4.5M13.49.75a2.25 2.25 0 100 4.5 2.25 2.25 0 100-4.5z"/><path pid="1" d="M11.998 10.75l6-4 5.25 3.5"/></g>'
  }
})
