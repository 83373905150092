/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party-hat-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M6.9 17.38l7.52-3.76M5.66 23.25l11.12-5.56M11.314 1.01h-.01c.12-.25.41-.34.65-.22.09.04.16.12.21.21l1.01 2.04-.01-.01c.07.14.2.24.36.26l2.27.32h0c.18.02.33.15.39.32h0c.05.17 0 .36-.13.48l-1.65 1.6h-.01c-.12.1-.17.26-.14.42l.38 2.25-.01-.01c.03.18-.05.36-.2.46v-.01c-.16.1-.36.12-.52.03l-2.03-1.06h0a.5.5 0 00-.46-.01L9.38 9.13v-.01a.51.51 0 01-.52-.04h0a.487.487 0 01-.2-.47l.38-2.255v-.01a.516.516 0 00-.14-.43l-1.65-1.6-.01-.01a.478.478 0 01-.13-.49v-.01c.05-.18.2-.3.39-.33l2.27-.33v-.01c.15-.03.28-.13.35-.27zM10.013 12L3.5 23.25H20L13.487 12"/></g>'
  }
})
