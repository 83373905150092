/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'covid-carrier-packages-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<desc>virus</desc><g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M12 11.64a2.85 2.85 0 100 5.71 2.85 2.85 0 100-5.72zM11.52 9.5h.96M12 9.5v2.14M15.2 10.63l.67.67M15.54 10.96l-1.52 1.52M17 14.02v.96M17 14.5h-2.14M15.87 17.7l-.67.67M15.54 18.04l-1.52-1.52M12.48 19.5h-.96M12 19.5v-2.14M8.8 18.37l-.67-.67M8.46 18.04l1.52-1.52M7 14.98v-.96M7 14.5h2.14M8.13 11.3l.67-.67M8.46 10.96l1.52 1.52"/><rect pid="1" width="22" height="16.5" x="1" y="6.5" rx="1.833"/><path pid="2" d="M22.4 6.98l-3.45-5.17h0c-.35-.51-.92-.82-1.53-.82H6.55h0c-.62-.01-1.19.3-1.53.81L1.57 6.96M12 1v5.5"/></g>'
  }
})
