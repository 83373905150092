/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holiday-july4-fireworks-stick-stroke': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g stroke-linecap="round" stroke-width="1.5" _stroke="currentColor" _fill="none" stroke-linejoin="round"><path pid="0" d="M4.12 3.75h-.01c.2-.01.37.16.37.37"/><path pid="1" d="M3.75 4.12h0c-.01-.21.16-.38.37-.38M4.12 4.5h-.01a.386.386 0 01-.38-.38s0 0 0 0M4.5 4.12v0c0 .2-.17.375-.375.375M22.125 9h-.01c.2-.01.37.16.37.37M21.75 9.37h0c-.01-.21.16-.38.37-.38 0 0 0 0 0 0M22.125 9.75h-.01a.386.386 0 01-.38-.38s0 0 0 0M22.5 9.37v0c0 .2-.17.375-.375.375M10.87 19.5h-.01c.2-.01.37.16.37.37"/><path pid="2" d="M10.5 19.875h0c-.01-.21.16-.38.37-.38M10.87 20.25h-.01a.386.386 0 01-.38-.38s0 0 0 0M11.25 19.875v0c0 .2-.17.375-.375.375M9 13.5a1.5 1.5 0 100 3 1.5 1.5 0 100-3zM15 .75v3M16.5 2.25h-3M21 20.25v3M22.5 21.75h-3M7.94 16.06L.75 23.25M14.24 12.75v-.01a5.94 5.94 0 016 .75M11.25 9.751v-.01a5.96 5.96 0 00-.76-6M15.75 8.25h-.01a4.351 4.351 0 014.49-3.01M12.75 15.75s2.25 3 2.25 6M7.5 11.25S4.5 9 1.5 9"/></g>'
  }
})
